import sign from 'tm2sign.macro';
import { saveAs } from 'file-saver';

import { UploadedFile } from '@models/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Role } from '@permissions/core';
import { userForDisplayFields } from 'utils/gql/common-fields';
import { privateApi } from '../../../utils/api';

const downloadFile = async (fileName: string, fileUuid: string): Promise<void> => {
  const response = await privateApi.get(`/downloadFile?uuid=${fileUuid}`, { responseType: 'blob' });
  await saveAs(response.data, fileName);
};

const getUsersByRole = (role): Promise<UserInfo> => {
  const usersFields = sign('usersByRole', [...userForDisplayFields, { issuerInvestor: ['id'] }]);
  const typedValues = { role };
  return stompClient.getData('usersByRole', usersFields, typedValues);
};

const getUsersByRoleShortInfo = (role: Role): Promise<Array<UserShortInfo>> => {
  const usersFields = sign('usersByRole', ['displayName', 'id']);
  const typedValues = { role };
  return stompClient.getData('usersByRole', usersFields, typedValues);
};

const uploadFile = async (target: HTMLInputElement): Promise<UploadedFile> => {
  const formData = new FormData();
  formData.append('file', target.files[0], target.files[0].name);
  const response = await privateApi.post('/uploadFile', formData);
  return response.data;
};

export const sharedApi = {
  downloadFile,
  getUsersByRole,
  getUsersByRoleShortInfo,
  uploadFile,
};

export interface UserInfo {
  company: string;
  displayName: string;
  id: number;
  firstName: string;
  lastName: string;
}

export interface UserShortInfo {
  displayName: string;
  id: number;
}
