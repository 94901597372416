import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { SearchDateRange, SearchForm, SearchItem } from '@components/search';
import { PermissionGroup } from '@permissions/permission-group';
import { DownloadBalancesHistory } from './download';
import { BankAccountsSelect } from './external-banks';
import { maxDate, useModel } from './model';
import { permissionGroup as downloadPermissionGroup } from './download';
import { permissionGroup as externalBanksPermissionGroup } from './external-banks';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [downloadPermissionGroup, externalBanksPermissionGroup],
  marker: 'layout:bank-account-history-page-actions-row',
});

enum Fields {
  bankAccount = 'bankAccountId',
  period = 'period',
}
const F = Fields; // alias

const searchFields: Array<SearchItem> = [
  {
    fields: [],
    id: 'bankAccount',
    label: undefined,
    searchFormField: F.bankAccount,
    type: 'select',
  },
  { fields: [], id: 'period', label: undefined, searchFormField: F.period, type: 'dateRange' },
];

export const ActionsRow: FC = () => {
  const m = useModel();

  return (
    <SearchForm
      className={s.actionsRow}
      onFormChange={m.onFilterChanged}
      fields={searchFields}
      memorize={true}
    >
      <DownloadBalancesHistory className="mb1 mr1" />

      <BankAccountsSelect className={s.bankAccount} name={F.bankAccount} />

      <SearchDateRange
        className={s.period}
        label={<Trans id="bank_account_history.action_row.period">Period</Trans>}
        name={F.period}
        max={maxDate}
        allowClear={false}
      />
    </SearchForm>
  );
};
