import { useContext, useMemo } from 'react';

import { SelectItem } from '@components/form';
import { Context } from '../context';

export { UpdateUserFields as Fields } from '../api';

export const useModel = () => {
  const { state } = useContext(Context);
  const banks: Array<Array<SelectItem>> = useMemo(() => {
    return state.currencies.map((c) =>
      c.bankAccounts.map((b) => ({
        label: b.bankName,
        value: b.id,
      }))
    );
  }, [state.currencies]);

  const currenciesLabels: Array<string> = useMemo(
    () => state.currencies.map((c) => c.code),
    [state.currencies]
  );

  return {
    banks,
    currenciesLabels,
  };
};
