import React, { FC } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { DispatchFn } from '@models/redux';
import { HasAccess, Role } from '@permissions/core';
import { ButtonTextIconBig } from '@components/button';
import { vaultsPageUserActions } from '../store/user.acions';
import { vaultsPageSelectors } from '../store/selectors';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';

const mapStateToProps = (root) => ({
  lockUI: vaultsPageSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onAddVaultClicked: () => dispatch(vaultsPageUserActions.onAddVaultClicked()),
});

const ConnectedVaultsPageHeader: FC<{
  className: string;
  lockUI: boolean;
  onAddVaultClicked: DispatchFn<void>;
}> = ({ className, lockUI, onAddVaultClicked }) => {
  return (
    <div className={classnames('vaults-page-header', className)}>
      <h2 className="my-0 mr-md py-0">
        <Trans id="vaults_page_header.title">Vaults</Trans>
      </h2>

      <HasAccess roles={[Role.admin]}>
        <ButtonTextIconBig
          className="ml-auto"
          disabled={lockUI}
          icon={<AddIcon className="tm2-icon-stroke" />}
          onClick={onAddVaultClicked}
        >
          <Trans id="vaults.add">Add vault</Trans>
        </ButtonTextIconBig>
      </HasAccess>
    </div>
  );
};

export const VaultsPageHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedVaultsPageHeader);
