import React, { FC, ReactNode, useCallback } from 'react';
import classnames from 'classnames';
import { NamePath } from 'rc-field-form/lib/interface';
import { DatePicker, Form as AntForm } from 'antd';
import moment, { Moment } from 'moment';

import './styles.scss';
import { dater } from '@helper/date';
import { DATE_FORMAT, DATE_TIME_FORMAT } from '@constants/date';
import { Label, useLabel } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const InputDate: FC<{
  allowClear?: boolean;
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  label: ReactNode;
  max?: string; // date iso string
  min?: string; // date iso string
  name: NamePath;
  onChange?: (date: string) => void;
  required?: boolean;
  showNow?: boolean;
  showTime?: boolean;
  uat?: string;
}> = ({
  allowClear = true,
  className,
  compact,
  disabled,
  label,
  max,
  min,
  name,
  onChange,
  required,
  showNow = false,
  showTime,
  uat,
  ...props
}) => {
  const uatAttribute = useFieldUat(name, 'date-picker', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-date', className, {
        'tm2-form-field-compact': compact,
      })}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} required={required}>
        <CustomDate
          name={name}
          label={label}
          onCustomChange={onChange}
          max={max}
          min={min}
          allowClear={allowClear}
          required={required}
          disabled={disabled}
          showNow={showNow}
          showTime={showTime}
          {...props}
        />
      </Item>
    </div>
  );
};

const CustomDate: FC<{
  allowClear?: boolean;
  disabled: boolean;
  label: ReactNode;
  max?: string;
  min?: string;
  name: NamePath;
  onChange?: (date: string) => void;
  onCustomChange?: (date: string) => void;
  required: boolean;
  showNow?: boolean;
  showTime?: boolean;
  value?: Moment;
}> = (_props) => {
  const {
    allowClear,
    disabled,
    label,
    max,
    min,
    name,
    onChange,
    onCustomChange,
    required,
    showNow,
    showTime,
    value,
    ...props
  } = _props;
  const { shifted, onBlur, onFocus } = useLabel({ value: value });

  const disabledDate = useCallback(
    (m: Moment) => {
      if (!max && !min) {
        return false;
      }
      const dateCheck: number = new Date(m.format('L')).getTime();
      const dateMin = !min ? 0 : new Date(moment(min).format('L')).getTime();
      const dateMax = !max ? Infinity : new Date(moment(max).format('L')).getTime();
      return !(dateMin <= dateCheck && dateCheck <= dateMax);
    },
    [min, max]
  );

  const handleOnChange = useCallback(
    (m: Moment) => {
      const date = !m ? undefined : !showTime ? dater.toGqlDate(m) : dater.toGqlDateTime(m);
      onChange(date);
      onCustomChange && onCustomChange(date);
    },
    [onChange, onCustomChange]
  );

  return (
    <>
      <Label htmlFor={name} shifted={shifted} label={label} required={required} />

      <DatePicker
        className="tm2-form-field-item-instance"
        dropdownClassName={classnames('tm2-field-picker', {
          'tm2-field-date-dropdown': !showTime,
          'tm2-field-date-time-dropdown': showTime,
          'tm2-field-picker-no-footer': !showTime,
        })}
        value={value && moment(value)}
        onChange={handleOnChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        disabledDate={disabledDate}
        format={showTime ? DATE_TIME_FORMAT : DATE_FORMAT}
        placeholder={null}
        inputReadOnly={false}
        allowClear={allowClear}
        showNow={showNow && showTime}
        showTime={showTime}
        {...props}
      />
    </>
  );
};
