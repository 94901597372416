import { CELL_PADDING_X } from '../constants';
import s from '../index.module.scss';

import type { CSSProperties, ReactNode, Ref } from 'react';
import type { CellAlignment } from '../types';

export type RowCellBoxProps = {
  children: ReactNode;
  alignment: CellAlignment;
  ellipsis: boolean;
  ignorePadding: boolean;
  width: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerRef: Ref<any>;
};

const alignmentStyle: Record<
  CellAlignment,
  {
    justifyContent: string;
    paddingLeft: number;
    paddingRight: number;
  }
> = {
  left: {
    paddingLeft: 0,
    paddingRight: CELL_PADDING_X,
    justifyContent: 'flex-start',
  },
  right: {
    paddingLeft: CELL_PADDING_X,
    paddingRight: 0,
    justifyContent: 'flex-end',
  },
  center: {
    paddingLeft: CELL_PADDING_X,
    paddingRight: CELL_PADDING_X,
    justifyContent: 'center',
  },
};

export function RowCellBox(props: RowCellBoxProps) {
  const ellipsisStyle = props.ellipsis
    ? ({
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      } as const)
    : {};

  const outerHeightStyle = props.ignorePadding
    ? {
        height: '100%',
      }
    : {};

  const innerHeightStyle = props.ignorePadding
    ? {
        paddingTop: 0,
        paddingBottom: 0,
      }
    : {};

  const outerStyle: CSSProperties = {
    width: props.width,
    ...outerHeightStyle,
    ...alignmentStyle[props.alignment],
  };

  const innerStyle = {
    ...innerHeightStyle,
    ...ellipsisStyle,
  };

  return (
    <div ref={props.containerRef} className={s.rowCellBoxOuter} style={outerStyle}>
      <div className={s.rowCellBoxInner} style={innerStyle}>
        {props.children}
      </div>
    </div>
  );
}
