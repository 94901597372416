import React, { FC } from 'react';
import classnames from 'classnames';
import { defineMessage, Trans } from '@lingui/macro';

import { PageTable } from 'components/page-table';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { PageContent, PageTitle } from '@components/page';
import { Permission, PermissionGroup, PermissionRecord } from '@permissions/core';
import { UserProfileLink } from '@components/link';
import { TransactionsActionsRow } from './actions-row';
import { TransactionItem } from './api';
import { TransactionsTableColumnIds as ColumnIds, useTransactionsPageModel } from './model';

// TODO: add permissions
export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [new PermissionRecord(Permission.TRANSACTIONS_VIEW_MY)],
  marker: 'todo:transactions-page',
});

export const TransactionsPage: FC<{
  isNoTitle?: boolean;
}> = ({ isNoTitle }) => {
  const {
    columnSortDirection,
    getTransactions,
    hasMore,
    i18n,
    isCanViewProfile,
    isIssuer,
    isInvestor,
    lockUI,
    onTableSorted,
    transactions,
  } = useTransactionsPageModel();

  return (
    <PageContent className="transactions-page" uat="transactions-page">
      {isNoTitle ? null : (
        <PageTitle
          title={i18n._(
            defineMessage({
              id: 'router.transactions',
              message: 'Transactions',
            })
          )}
        />
      )}

      <TransactionsActionsRow />

      <PageTable
        onLoadMore={getTransactions}
        isLoadingInProgress={lockUI}
        initialLoad={true}
        pageData={{
          data: transactions,
          hasMore: hasMore,
        }}
        columns={[
          {
            cell: (item: TransactionItem) => <span>{item.date}</span>,
            head: {
              body: (
                <span>
                  <Trans id="transactions.table.date">Date</Trans>
                </span>
              ),
              onSort: (direction) => onTableSorted({ id: ColumnIds.date, direction: direction }),
              sortDirection: columnSortDirection(ColumnIds.date),
            },
          },
          {
            cell: (item: TransactionItem) => {
              return <span>{item.opTypeDisplayName}</span>;
            },
            head: (
              <span>
                <Trans id="transactions.table.type">Type</Trans>
              </span>
            ),
          },
          ...(!isInvestor && !isIssuer
            ? [
                {
                  cell: (item: TransactionItem) => {
                    const isLinkSender = isCanViewProfile && item.sender.id;
                    return isLinkSender ? (
                      <UserProfileLink
                        id={item.sender.id}
                        text={item.sender.fullName || item.sender.pubKey}
                      />
                    ) : (
                      <div className="dont-break-out">
                        {item.sender.fullName || item.sender.pubKey}
                      </div>
                    );
                  },
                  head: (
                    <span>
                      <Trans id="transactions.table.sender">Sender</Trans>
                      <br />
                      <Trans id="transactions.table.sender.from">(from)</Trans>
                    </span>
                  ),
                },
                {
                  cell: (item: TransactionItem) => <span>{item.sender.id}</span>,
                  head: (
                    <span>
                      <Trans id="transactions.table.sender_id">Sender ID</Trans>
                    </span>
                  ),
                },
                {
                  cell: (item: TransactionItem) => {
                    const isLinkRecipient = isCanViewProfile && item.recipient.id;
                    return isLinkRecipient ? (
                      <UserProfileLink
                        id={item.recipient.id}
                        text={item.recipient.fullName || item.recipient.pubKey}
                      />
                    ) : (
                      <div className="dont-break-out">
                        {item.recipient.fullName || item.recipient.pubKey}
                      </div>
                    );
                  },
                  head: (
                    <span>
                      <Trans id="transactions.table.recipient">Recipient</Trans>
                      <br />
                      <Trans id="transactions.table.recipient.to">(to)</Trans>
                    </span>
                  ),
                },
                {
                  cell: (item: TransactionItem) => <span>{item.recipient.id}</span>,
                  head: (
                    <span>
                      <Trans id="transactions.table.recipient_id">Recipient ID</Trans>
                    </span>
                  ),
                },
              ]
            : []),
          {
            cell: (item: TransactionItem) => {
              const type = item.type;
              const isMinus = type === 'Sell' || type === 'Redeem' || type === 'Fee';
              const isPlus = type === 'Buy';
              const classNames = classnames({
                'color-red': isMinus,
                'color-green': isPlus,
              });
              return (
                <span className={classNames}>
                  {isMinus ? '-' : isPlus ? '+' : ''}
                  {item.token} {item.stable ? formatMoney(item.amount) : formatToken(item.amount)}
                </span>
              );
            },
            head: {
              body: (
                <span>
                  <Trans id="transactions.table.amount">Amount</Trans>
                </span>
              ),
              onSort: (direction) => onTableSorted({ id: ColumnIds.amount, direction: direction }),
              sortDirection: columnSortDirection(ColumnIds.amount),
            },
          },
          {
            cell: (item: TransactionItem) =>
              item.rate ? (
                <div className="nowrap">
                  {item.currencySymbol} {formatMoney(item.rate)}
                </div>
              ) : (
                <div />
              ),
            head: (
              <span>
                <Trans id="transactions.table.price">Price</Trans>
              </span>
            ),
          },
          {
            cell: (item: TransactionItem) =>
              item.total ? (
                <div className="nowrap">
                  {item.currencySymbol} {formatMoney(item.total)}
                </div>
              ) : (
                <div />
              ),
            head: (
              <span>
                <Trans id="transactions.table.total">Total</Trans>
              </span>
            ),
          },
        ]}
      />
    </PageContent>
  );
};
