import { RowBox } from './row-box';
import type { RowRendererProps } from '../types';

export function RowRenderer(props: RowRendererProps) {
  return (
    <RowBox
      containerRef={props.containerRef}
      style={props.style}
      onClick={props.onClick}
      onMouseLeave={props.onMouseLeave}
      onMouseEnter={props.onMouseEnter}
    >
      {props.children}
    </RowBox>
  );
}
