import classnames from 'classnames';
import s from './index.module.scss';
import { Form, InputDateRange, Select } from '@components/form';
import { Filters, formId } from '../model';
import { Trans } from '@lingui/macro';
import { HasAccess } from '@permissions/hoc.component';
import {
  AddSettlementModal,
  permissionGroup as addSettlementModalPermissionGroup,
} from '../add-settlement-modal';
import { ButtonTextIconBig } from '@components/button';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { Box } from '@components/box';
import React, { FC } from 'react';
import { Fields, maxDate, useModel } from './model';
import { Settlement } from '../add-settlement-modal/api';

export const permissionGroup = addSettlementModalPermissionGroup.createSuperGroup({
  marker: 'layout:listing-brokers-page-actions-row',
});

export const ActionsRow: FC<{
  onSettlementPlaced: (settlement: Settlement) => void;
  onLbsFiltersChanged: (filters: Filters) => void;
}> = ({ onSettlementPlaced, onLbsFiltersChanged }) => {
  const m = useModel(onSettlementPlaced, AddSettlementModal);

  return (
    <Box className={classnames('mb2', s.box)}>
      <Form name={formId} className={s.form} onChange={onLbsFiltersChanged}>
        <InputDateRange
          name={Fields.date}
          label={<Trans id="daterange.label">Date</Trans>}
          max={maxDate}
          disabled={m.lockUI}
          compact={true}
          className={s.filter}
        />

        <Select
          name={Fields.recipientUserId}
          data={m.listingBrokers}
          label={<Trans id="listing_brokers.sender">Sender</Trans>}
          disabled={m.lockUI}
          compact={true}
          allowEmpty={true}
          className={s.filter}
        />
      </Form>

      <HasAccess permissions={{ or: [addSettlementModalPermissionGroup] }}>
        <ButtonTextIconBig
          disabled={m.lockUI}
          icon={<AddIcon className="tm2-icon-stroke" />}
          onClick={m.onAddLBSettlement}
        >
          <Trans id="listing_brokers.add.settlement">Add settlement</Trans>
        </ButtonTextIconBig>
      </HasAccess>
    </Box>
  );
};
