import { useMemo } from 'react';
import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import { useWithdrawalsStatuses } from '../hooks';
import api from './api';

export const useClients = () => {
  const { data: clientsData, isLoading: isClientsLoading } = useQuery(api.getClients, {
    initial: [],
  });

  const clients: Array<SelectItem> = useMemo(() => {
    return clientsData?.map((i) => ({
      label: `${i.displayName} (${i.id})`,
      value: i.id,
    }));
  }, [clientsData]);

  return { isClientsLoading, clients };
};

export const useStatuses = () => {
  const statuses = useWithdrawalsStatuses();

  const withdrawalStatuses: Array<SelectItem> = useMemo(() => {
    return Object.entries(statuses)?.map(([status, label]) => ({
      label: label,
      value: status,
    }));
  }, [statuses]);

  return { withdrawalStatuses };
};
