import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import { traderPermissions, IsHasAccess, permissionsSelectors } from '@permissions/core';
import { Link } from '@components/link';
import { showModalAction } from '@modules/modal';
import { AddFundsModal } from '../../../../deposits/add-funds-modal';

export const NoWalletResultsText: FC<{}> = () => {
  const dispatch = useDispatch();
  const hasAccess: IsHasAccess = useSelector(permissionsSelectors.isHasAccess);
  const showAddFundsModal = () => dispatch(showModalAction({ modal: AddFundsModal }));

  // return hasAccess({ or: traderPermissions }) ? (
  //   <Trans id="wallet_page.table.no_results">
  //     It looks like you haven't made any purchases.
  //     <br />
  //     <Link onClick={showAddFundsModal}>Deposit</Link> funds and start{' '}
  //     <Link to="/primary-market">investing</Link>.
  //   </Trans>
  // ) : (
  //   <Trans id="reusable.table.no_results_default">
  //     You have no documents, records or history in this section
  //   </Trans>
  // );

  return (
    <Trans id="reusable.table.no_results_default">
      You have no documents, records or history in this section
    </Trans>
  );
};
