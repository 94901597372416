import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { dater } from '@helper/date';
import { Filter } from '../types';

export interface PeriodBalances {
  balanceOnPeriodStart: string;
  balanceOnPeriodEnd: string;
}

const getBankAccountPeriodBalancesQuery = 'externalBankTransactionsPeriodBalances';
const getBankAccountPeriodBalancesFields = ['balanceOnPeriodStart', 'balanceOnPeriodEnd'];
const getBankAccountPeriodBalances = makeQuery({
  queryName: getBankAccountPeriodBalancesQuery,
  queryFields: getBankAccountPeriodBalancesFields,
  query: ({ filters }: { filters: Filter }): Promise<PeriodBalances> => {
    const bankAccountFilter = filters?.bankAccountId;
    const period = {
      from: dater.toBackendDate(filters?.period?.[0]),
      to: dater.toBackendDate(filters?.period?.[1]),
    };

    const typedValues = {
      ...v.string({ bankAccountFilter }), // TODO переименовать на бекенде на bankAccountId
      ...v.rangeStringInput({ period }),
    };

    return stompClient.getData<PeriodBalances>(
      getBankAccountPeriodBalancesQuery,
      sign(getBankAccountPeriodBalancesQuery, getBankAccountPeriodBalancesFields),
      typedValues
    );
  },
});

const api = {
  getBankAccountPeriodBalances,
};

export const permissionGroup = PermissionGroup.extract(
  api,
  'api:bank-account-history-page-balances-row'
);

export default api;
