import { useMemo } from 'react';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

import { closeModalAction } from '@modules/modal';
import api from 'containers/pages/deposits/actions-row/api';
import { handleBackendError } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';

export const useModel = (invoiceId) => {
  const onClose = useCallDispatch(closeModalAction);

  const validityDate = useMemo(() => {
    const date = new Date();
    date.setDate(new Date().getDate() + 7);
    return date.toLocaleString();
  }, []);

  const onDownloadClicked = async () => {
    try {
      const details = await api.downloadBankDetails(invoiceId);
      const blob = base64StringToBlob(details, 'application/blob');
      saveAs(blob, `tm2-bank-details.pdf`);
    } catch (error) {
      handleBackendError(error);
    }
  };

  return {
    onClose,
    onDownloadClicked,
    validityDate,
  };
};
