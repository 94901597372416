import React, { FC, memo, ReactNode } from 'react';
import { NamePath } from 'rc-field-form/lib/interface';
import { Select } from '@components/form';
import { Role } from '@permissions/core';
import { useUsersByRole } from './hooks';

type Props = {
  className?: string;
  disabled?: boolean;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  name: NamePath;
  onChange?: (id: number) => void;
  required?: boolean;
  role: Role;
  uat?: string;
};
export const RoledSelect: FC<Props> = memo<Props>((props) => {
  const { usersByRole, isUsersByRoleLoading } = useUsersByRole(props.role);

  return (
    <Select
      className={props.className}
      disabled={props.disabled || isUsersByRoleLoading}
      fieldKey={props.fieldKey}
      label={props.label}
      name={props.name}
      onChange={(id) => {
        props.onChange && props.onChange(id as number);
      }}
      required={props.required}
      data={usersByRole}
      uat={props.uat}
      {...props}
    />
  );
});
