import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { configReducer } from './config/store/reducer';
import { configEffects } from './config/store/effects';
import { modalReducer } from './modules/modal/store/reducer';
import { modalEffects } from './modules/modal/store/effects';
import { reducer as countriesReducer } from './modules/countries/store';
import { reducer as currenciesReducer } from './modules/currencies/store';

export const sharedReducer = combineReducers({
  config: configReducer,
  countries: countriesReducer,
  currencies: currenciesReducer,
  modal: modalReducer,
});

export function* sharedEffects() {
  yield all([fork(configEffects), fork(modalEffects)]);
}
