import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import type { TableResponse } from '@models/core';
import { v } from '@helper/typer/field-typer.helper';
import type { SortOrder as QuerySortOrder } from '@models/sorting';
import { WithdrawalStatus } from '@models/core';

export enum GetWithdrawalsSortKey {
  id = 'id',
  createdAt = 'createdAt',
}

export type GetWithdrawalsSortOrder = QuerySortOrder<GetWithdrawalsSortKey>;

export interface Withdrawal {
  amount: number;
  createdAt: string;
  id: number;
  status: WithdrawalStatus;
  settledAt: string;
  amountSettled: number;
  purpose: string;
  sourceOfFunds: string;
  externalId: string;
  currency: {
    code: number;
  };
  userBankAccount: {
    accountNumber: string;
    bankName: string;
    comment: string;
    ibanNumber: string;
    nickname: string;
    swiftCode: string;
    user: {
      address: string;
      company: string;
      displayName: string;
      id: number;
      firstName: string;
      lastName: string;
    };
  };
}

export const getWithdrawalsPageQuery = 'withdrawalsPage';
export const getWithdrawalsPageFields = [
  {
    data: [
      'amount',
      'createdAt',
      'id',
      'status',
      'settledAt',
      'amountSettled',
      'purpose',
      'sourceOfFunds',
      'externalId',
      { currency: ['code'] },
      {
        userBankAccount: [
          'accountNumber',
          'bankName',
          'comment',
          'ibanNumber',
          'nickname',
          { user: ['id', 'displayName', 'address'] },
          'swiftCode',
        ],
      },
    ],
  },
  'index',
  'hasMore',
];
const getWithdrawalsPage = makeQuery({
  queryName: getWithdrawalsPageQuery,
  queryFields: getWithdrawalsPageFields,
  query: ({
    page,
    filter,
    ordering,
  }: {
    page: number;
    filter: string;
    ordering: GetWithdrawalsSortOrder;
  }): Promise<TableResponse<Withdrawal>> => {
    const typedValues = {
      ...v.int({ page }),
      ...v.string({ rsqlFilter: filter }),
      ...v.orderInput({ ordering }),
    };

    return stompClient.getData<TableResponse<Withdrawal>>(
      getWithdrawalsPageQuery,
      sign(getWithdrawalsPageQuery, getWithdrawalsPageFields),
      typedValues
    );
  },
});

const api = {
  getWithdrawalsPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:withdrawals-page');

export default api;
