import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { closeModalAction, MFC } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { notifySuccess } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { VaultInfo } from '../vaults-api.service';
import api, { Form } from './api';
import { useFormInitial, useUnits } from './hooks';

export const formId = 'confirm-vault-balance-form-id';

export enum Fields {
  amount = 'amount', // detailsList.amount
  asset = 'coin.asset', // detailsList.asset
  coin = 'coin.id', // detailsList.coin
  detailsList = 'details',
  report = 'report.id', // detailsList.report
  unit = 'unit.id', // detailsList.unit
}

export const useModel = (id: number, modal: MFC<{ vault: VaultInfo }>) => {
  const [isSaveBalancesLoading, setSaveBalancesLoading] = useState<boolean>();
  const { data: formInitial, isLoading: isFormLoading } = useFormInitial(id);
  const { data: units, isLoading: isUnitsLoading } = useUnits();
  const lockUI = isFormLoading || isUnitsLoading || isSaveBalancesLoading;

  const closeModal = useCallDispatch(closeModalAction);

  const onFormAction: OnFormAction = {
    finally: () => setSaveBalancesLoading(false),
    submit: async (form: Form) => {
      setSaveBalancesLoading(true);
      await api.saveBalanceDetails(id, form.details);
      await closeModal(modal);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="vault_service.notification.balance_confirmed">
            Balance confirmed successfully
          </Trans>
        ),
      });
    },
  };

  return {
    formInitial,
    lockUI,
    onCloseClicked: () => closeModal(modal),
    onFormAction,
    units,
  };
};
