import React, { FC } from 'react';

import { ButtonColor, ButtonIconPure, ButtonModel } from '@components/button';
import { ReactComponent as BlockedIcon } from '@images/icons/blocked-icon.svg';

export const BlockedButtonIcon: FC<ButtonModel> = (props) => (
  <ButtonIconPure
    icon={<BlockedIcon className="tm2-icon-fill" />}
    color={ButtonColor.danger}
    {...props}
  />
);
