import sign from 'tm2sign.macro';
import { userForDisplayFields } from 'utils/gql/common-fields';

export default {
  coinsPageFields: sign('coinsPage', [
    {
      data: [
        'id',
        { metal: ['label'] },
        'asset',
        { info: ['total', 'balance', 'active'] },
        'positionNegativeDays',
        { issuer: userForDisplayFields },
        {
          vault: [
            {
              lastVaultBalance: [
                { details: ['amount', { unit: ['inGrams', 'label'] }, { coin: ['id'] }] },
              ],
            },
          ],
        },
      ],
    },
    'index',
    'hasMore',
  ]),
  setAssetActiveFields: sign('setAssetActive', ['value']),
};
