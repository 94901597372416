import { CMBankAccountStatus, CMBankAccountType } from '../../../hooks';
import { CMTransactionType } from '../../hooks';

export enum TransactionFields {
  amount = 'amount',
  comment = 'comment',
  externalId = 'externalId',
  currency = 'currency.id',
  method = 'method',
  purpose = 'purpose',
  receiverId = 'receiverBankAccount.id',
  senderId = 'senderBankAccount.id',
  sourceOfFunds = 'sourceOfFunds',
  transactionDateTime = 'transactionDateTime',
  type = 'type',
  valueDateTime = 'valueDateTime',
}
const F = TransactionFields; // alias
export interface TransactionForm {
  [F.amount]: number;
  [F.comment]: number;
  [F.externalId]: string;
  currency: { id: number };
  [F.method]: string;
  [F.purpose]: string;
  receiverBankAccount: { id: number };
  senderBankAccount: { id: number };
  [F.sourceOfFunds]: string;
  [F.transactionDateTime]: string;
  [F.type]: CMTransactionType;
  [F.valueDateTime]: string;
}
// ------------------------------------------------------------------------------------
export type CMBankAccount = {
  legalEntity: string;
  bank: { bankName: string };
  accountNumber: string;
  type: CMBankAccountType;
  currency: { code: string; id?: number };
  currentBalance: number;
  computedFields: { calculatedBalance: number };
  status: CMBankAccountStatus;
  id: number;
};
