import { useCallback, useContext, useEffect, useMemo } from 'react';
import useHash from 'use-hash-param';
import { useSelector } from 'react-redux';

import { handleBackendError } from '@modules/notify';
import { countriesSelectors } from '@modules/countries';

import api, { Currency, Role, UserProfile, UpdateUserForm, Issuer } from './api';
import { Context } from './context';

export const useUserId = () => {
  const [userId, setHashUserId] = useHash('user-id', undefined);

  return {
    isNewUser: !userId,
    setHashUserId: (id: number) => setHashUserId(id ? String(id) : undefined),
    userId: +userId,
  };
};

export const useOnPageOpened = () => {
  const { dispatch } = useContext(Context);
  const { isNewUser, userId } = useUserId();

  useEffect(() => {
    Promise.all([
      api.getCurrencies(),
      api.getIssuers(),
      !isNewUser ? api.getUserProfile(userId) : Promise.resolve(undefined),
      api.getRoles(),
    ])
      .then(([currencies, issuers, profileInitial, roles]) => {
        dispatch({
          payload: {
            currencies: currencies as Array<Currency>,
            issuers: issuers as Array<Issuer>,
            profileInitial: profileInitial as UserProfile,
            roles: roles as Array<Role>,
          },
          type: 'INITIATE_PAGE_DATA',
        });
      })
      .catch((error) => handleBackendError(error));
  }, []);
};

export const useLockUI = () => {
  const { dispatch, state: s } = useContext(Context);
  const isCountriesLoading = useSelector(countriesSelectors.isLoading);

  const lockUI = useMemo(
    () => isCountriesLoading || s.isInitialPageDataLoading || s.isUpdateProfileLoading,
    [isCountriesLoading, s.isInitialPageDataLoading, s.isUpdateProfileLoading]
  );

  const setUpdateUserLoading = useCallback(
    (isLoading: boolean) =>
      dispatch({
        payload: isLoading,
        type: 'SET_UPDATE_PROFILE_LOADING',
      }),
    [dispatch]
  );

  return {
    lockUI,
    setUpdateUserLoading,
  };
};

export const useInitialValues = () => {
  const { state: s } = useContext(Context);
  const initialValues: UpdateUserForm = useMemo(() => {
    return {
      address: s.profileInitial?.address,
      // note: такая сложная логика, чтобы в форме отображались ВСЕ доступные валюты
      // включая не связанные с каким-либо банком
      bankAccounts: s.currencies?.map((c) => ({
        currency: { id: c.id },
        // TODO-2833: fix types
        // @ts-ignore
        id: s.profileInitial?.bankAccounts?.reduce((bankId, account) => {
          return bankId || (account?.currency?.id === c.id ? account.id : undefined);
        }, undefined) as number,
      })),
      building: s.profileInitial?.building,
      city: s.profileInitial?.city,
      company: s.profileInitial?.company,
      complianceOfficerIssuer: { id: s.profileInitial?.complianceOfficerIssuer?.id },
      country: { id: s.profileInitial?.country?.id },
      email: s.profileInitial?.email,
      firstName: s.profileInitial?.firstName,
      id: s.profileInitial?.id,
      investorIssuer: { id: s.profileInitial?.investorIssuer?.id },
      lastName: s.profileInitial?.lastName,
      phone: s.profileInitial?.phone,
      postalCode: s.profileInitial?.postalCode,
      role: { id: s.profileInitial?.role?.id },
      state: s.profileInitial?.state,
      umsId: s.profileInitial?.umsId,
    };
  }, [s.currencies, s.profileInitial]);
  return initialValues;
};
