import { useState } from 'react';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import api from './api';
import { formatToken } from '../../../../services/commonService';

export const useModel = (redeem, replaceRedeem, modal) => {
  const [isBurnRedeemLoading, setBurnRedeemLoading] = useState<boolean>(false);
  const lockUI = isBurnRedeemLoading;

  const onClose = useCallDispatch(closeModalAction);

  const onBurnClicked = async () => {
    try {
      setBurnRedeemLoading(true);
      const burnedRedeem = await api.burnRedeem(redeem.id);
      replaceRedeem(burnedRedeem);
      setBurnRedeemLoading(false);
      onClose(modal);
    } catch (error) {
      handleBackendError(error);
      setBurnRedeemLoading(false);
    }
  };

  const metalAmount = formatToken(
    redeem.optionsQuantity * redeem.optionAmount * redeem.optionUnit.inGrams
  );

  return {
    lockUI,
    metalAmount,
    onBurnClicked,
    onClose,
  };
};
