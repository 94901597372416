import React, { memo } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputNumber, SubmitButton } from '@components/form';
import { Col, Grid } from '@components/grid';
import { DATE_TIME_FORMAT } from '@constants/date';
import { maxDecimals } from '@constants/math';
import { formatMoney } from '../../../services/commonService';
import { Fields, formId, formInitial, useModel } from './model';
import s from './index.module.scss';
import { Deposit } from '../api';

export type Props = {
  deposit: Deposit;
  replaceDeposit: (deposit: Deposit) => void;
};
export const ApproveDepositModal: MFC<Props> = memo(({ deposit, replaceDeposit, isVisible }) => {
  const m = useModel(deposit, replaceDeposit, ApproveDepositModal);

  return (
    <Modal isVisible={isVisible} loading={m.lockUI} disabled={m.lockUI} onClose={m.onClose}>
      <ModalTitle>
        <Trans id="approve_deposit_modal.approve.deposit">Approve invoice</Trans>
      </ModalTitle>

      <ModalContent>
        <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
          <Col sm={6} className={s.col}>
            <Trans id="approve_deposit_modal.deposit_number">Invoice number</Trans>
          </Col>

          <Col sm={6} className={s.col}>
            {deposit.number}
          </Col>
        </Grid>

        <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
          <Col sm={6} className={s.col}>
            <Trans id="approve_deposit_modal.amount">Amount</Trans>
          </Col>

          <Col sm={6} className={s.col}>
            {formatMoney(deposit.amount, { post: ` ${deposit.bankAccount.currency.code}` })}
          </Col>
        </Grid>

        <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
          <Col sm={6} className={s.col}>
            <Trans id="approve_deposit_modal.issued_on">Issued on</Trans>
          </Col>

          <Col sm={6} className={s.col}>
            {moment(deposit.creationDate).format(DATE_TIME_FORMAT)}
          </Col>
        </Grid>

        <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
          <Col sm={6} className={s.col}>
            <Trans id="approve_deposit_modal.customer">Customer</Trans>
          </Col>

          <Col sm={6} className={s.col}>
            {deposit.investorUser.displayName}
          </Col>
        </Grid>

        <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
          <Col sm={6} className={s.col}>
            <Trans id="approve_deposit_modal.paid_amount">Paid amount</Trans>
          </Col>

          <Col sm={6} className={s.col}>
            {formatMoney(deposit.payment.amount, { post: ` ${deposit.bankAccount.currency.code}` })}
          </Col>
        </Grid>

        <div className="mb-md">
          <div className="mb-xss">
            <Trans id="approve_deposit_modal.account_details">Customer bank account details</Trans>:
          </div>

          <div className={s.accountDetails}>{deposit.payment.accountDetails}</div>
        </div>

        <Form name={formId} initialValues={formInitial}>
          <div className="mb-xss">
            <Trans id="approve_deposit_modal.amount_for">Amount for customer balance</Trans>
          </div>

          <div className={classnames(s.textSmall, 'mb-sm')}>
            <Trans id="approve_deposit_modal.please_enter_amount">
              Please, enter amount in {deposit.bankAccount.currency.code} to be credited on the
              customer account
            </Trans>
          </div>

          <InputNumber
            name={Fields.amount}
            label={deposit.bankAccount.currency.code}
            disabled={m.lockUI}
            max={deposit.payment.amount}
            maxDecimals={maxDecimals}
            min={0}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={formId}
          fullWidth={true}
          onAction={m.onFormAction}
          disabled={m.lockUI}
          scrollToFirstError
        >
          <Trans id="button.approve">Approve</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
});
