import React, { FC } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';

export const Text: FC<{
  className?: string;
  color?: 'primary' | 'secondary';
  level?: 1 | 2;
  uat?: string;
}> = ({ children, className, color = 'primary', level = 1, uat }) => {
  return (
    <div
      className={classnames(
        {
          [s.b1]: level === 1,
          [s.b2]: level === 2,
          [s.colorPrimary]: color === 'primary',
          [s.colorSecondary]: color === 'secondary',
        },
        className
      )}
      data-uat={uat}
    >
      {children}
    </div>
  );
};
