import { MoreActions } from '@components/more-actions';
import { Trans } from '@lingui/macro';
import { PermissionGroup } from '@permissions/core';
import { OfferByAll, OfferByMe, OfferStatus } from '../../shared/types';
import {
  cancelOfferPermissionGroup,
  editOfferPermissionGroup,
  useStatusActionsModel,
  ModelParams,
} from './status-actions-model';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [cancelOfferPermissionGroup, editOfferPermissionGroup],
  optional: true,
  marker: 'layout:offer-status-actions',
});

type StatusActionsProps = {
  offer: OfferByAll | OfferByMe;
  onOfferCancelled: ModelParams['onOfferCancelled'];
  onOfferUpdate: ModelParams['onOfferUpdate'];
  className?: string;
};

export function StatusActions(props: StatusActionsProps) {
  const { currentUserId, showCancelOfferModal, showEditOfferModal } = useStatusActionsModel({
    onOfferCancelled: props.onOfferCancelled,
    onOfferUpdate: props.onOfferUpdate,
  });

  return (
    <MoreActions
      triggerClassName={props.className}
      placement={'right'}
      list={[
        {
          access: {
            extraCheck: () =>
              props.offer.status === OfferStatus.active && props.offer.creatorId === currentUserId,
            permissions: { or: [editOfferPermissionGroup] },
          },
          action: () => showEditOfferModal(props.offer),
          content: <Trans id="bids.offer_actions.edit">Edit</Trans>,
        },
        {
          access: {
            extraCheck: () =>
              props.offer.status === OfferStatus.active && props.offer.creatorId === currentUserId,
            permissions: { or: [cancelOfferPermissionGroup] },
          },
          action: () => showCancelOfferModal(props.offer),
          content: <Trans id="bids.offer_actions.cancel">Cancel</Trans>,
        },
      ]}
    />
  );
}
