import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

export const TabShared = {
  accountDetails: '#account-details',
  bankDetails: '#bank-details',
};
type TabKeys = keyof typeof TabShared;
type Tabs = typeof TabShared[TabKeys];

export const useTabChanges = (TabsAll: typeof TabShared) => {
  const history = useHistory();
  const hashInitial = history.location.hash as Tabs;
  const [activeTab, setActiveTab] = useState<Tabs>(
    Object.values(TabsAll)?.includes(hashInitial) ? hashInitial : TabShared.accountDetails
  );

  const onTabChange = (tab: string) => history.push({ hash: tab, search: window.location.search });

  useEffect(() => {
    const unsubscribe = history.listen((e: any) => {
      if (Object.values(TabsAll)?.includes(e.hash) && e.hash !== activeTab) {
        setActiveTab(e.hash);
      }
    });
    return () => unsubscribe();
  }, [activeTab]);

  return {
    activeTab,
    onTabChange,
  };
};
