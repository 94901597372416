import React, { FC, memo, useEffect } from 'react';
import { Layout } from 'antd';

import s from './light.module.scss';
import { LightLayoutHeader } from './header';
import { LightLayoutContent } from './content';
import { LightLayoutFooter } from './footer';

export const LightLayout: FC<{}> = memo(({ children }) => {
  useEffect(() => {
    const authorizedClass = 'tm2-light-theme';
    document.body.classList.add(authorizedClass);
    return () => document.body.classList.remove(authorizedClass);
  }, []);

  return (
    <Layout className={s.layout}>
      <LightLayoutHeader />

      <LightLayoutContent>{children}</LightLayoutContent>

      <LightLayoutFooter />
    </Layout>
  );
});
