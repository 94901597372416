import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from '../transfer.module.scss';
import { HelpCenterTooltip } from '@components/help-info';
import { Form, InputNumber, Select, SelectItem, SubmitButton } from '@components/form';
import { ButtonIconPure, ButtonPrimary } from '@components/button';
import { formatToken } from 'containers/services/commonService';
import { Divider } from '@components/divider';
import { DispatchFn, RootState } from '@models/redux';
import { Box } from '@components/box';
import { ReactComponent as SwitchIcon } from '@images/icons/switch-icon.svg';
import { TransferDirection } from '../model';
import { transferCoinTabSelectors as coinSelectors } from './store/selectors';
import { transferCoinTabUserActions as coinUserActions } from './store/user.acions';
import { maxDecimalsSMCoin, TransferCoinFields, transferCoinFormId } from './store/model';
import { onTransferCoinContinueButtonClicked } from './store/effects';

export { permissionGroup } from './api';

const mapStateToProps = (root: RootState) => ({
  assetInitial: coinSelectors.assetInitial(root),
  assetSelected: coinSelectors.assetSelected(root),
  direction: coinSelectors.transferDirection(root),
  lockUI: coinSelectors.lockUI(root),
  isNoTransferCoins: coinSelectors.isNoTransferCoins(root),
  primaryBalance: coinSelectors.primaryBalance(root),
  secondaryBalance: coinSelectors.secondaryBalance(root),
  sourceBalances: coinSelectors.sourceBalances(root),
});

const mapDispatchToProps = (dispatch) => ({
  onMaximizeButtonClicked: () => dispatch(coinUserActions.onMaximizeCoinAmountTransferClicked()),
  onSelectedValueChanged: (asset) => dispatch(coinUserActions.onSelectedValueChanged(asset)),
  onTabOpened: () => dispatch(coinUserActions.onTabOpened()),
  onToggleTransferDirectionClicked: () =>
    dispatch(coinUserActions.onToggleCoinTransferDirectionClicked()),
});

const ConnectedTransferCoinView: FC<{
  assetInitial: string;
  assetSelected: string;
  direction: string;
  lockUI: boolean;
  isNoTransferCoins: boolean;
  onMaximizeButtonClicked: DispatchFn<void>;
  onSelectedValueChanged: DispatchFn<string | number>;
  onTabOpened: DispatchFn<void>;
  onToggleTransferDirectionClicked: DispatchFn<void>;
  primaryBalance: number;
  secondaryBalance: number;
  sourceBalances: Array<SelectItem>;
}> = ({
  assetInitial,
  assetSelected,
  direction,
  lockUI,
  isNoTransferCoins,
  onMaximizeButtonClicked,
  onSelectedValueChanged,
  onTabOpened,
  onToggleTransferDirectionClicked,
  primaryBalance,
  secondaryBalance,
  sourceBalances,
}) => {
  useEffect(() => {
    onTabOpened();
  }, []);

  return (
    <Form name={transferCoinFormId}>
      <div className={classnames(s.rowDirection, 'mb-md')}>
        <div className={s.directionWay}>
          <p className="txt-center">
            <Trans id="market_transfer_modal.metals.label.from">From</Trans>
          </p>

          <Box className={s.wayGradient}>
            {direction === TransferDirection.fromPrimary ? (
              <Trans id="market_transfer_modal.metals.label.primary_market">Primary Market</Trans>
            ) : (
              <Trans id="market_transfer_modal.metals.label.secondary_market">
                Secondary Market
              </Trans>
            )}
          </Box>
        </div>

        <div className={s.directionSwitch}>
          <ButtonIconPure
            disabled={lockUI}
            className={s.switchButton}
            onClick={onToggleTransferDirectionClicked}
            icon={<SwitchIcon className="tm2-icon-stroke" />}
          />
        </div>

        <div className={s.directionWay}>
          <p className="txt-center">
            <Trans id="market_transfer_modal.metals.label.to">To</Trans>
          </p>

          <Box className={s.wayGradient}>
            {direction === TransferDirection.fromPrimary ? (
              <Trans id="market_transfer_modal.metals.label.secondary_market">
                Secondary Market
              </Trans>
            ) : (
              <Trans id="market_transfer_modal.metals.label.primary_market">Primary Market</Trans>
            )}
          </Box>
        </div>
      </div>

      <div className="mb-md">
        <Trans id="market_transfer_modal.metals.label.symbol_and_quantity">
          Symbol and quantity to transfer
        </Trans>
      </div>

      <div className={s.form}>
        <div className={s.formField}>
          {isNoTransferCoins ? (
            <div className={s.fieldNone}>
              <Trans id="market_transfer_modal.metals.label.none">none</Trans>
              <HelpCenterTooltip className={s.noneInfo} />
            </div>
          ) : (
            <Select
              className="mb-md"
              name={TransferCoinFields.asset}
              label={<Trans id="market_transfer_modal.metals.label.asset">Asset</Trans>}
              disabled={lockUI}
              required={true}
              onChange={(asset) => onSelectedValueChanged(asset)}
              data={sourceBalances}
              compact={true}
            />
          )}
        </div>

        <div className={s.formField}>
          <InputNumber
            className="mb-md"
            name={TransferCoinFields.amount}
            label={<Trans id="market_transfer_modal.metals.label.quantity">Quantity</Trans>}
            disabled={lockUI}
            required={true}
            min={0}
            max={direction === TransferDirection.fromPrimary ? primaryBalance : secondaryBalance}
            maxDecimals={maxDecimalsSMCoin}
            compact={true}
          />
        </div>

        <div className={s.formButton}>
          <ButtonPrimary
            className="mb-md"
            onClick={onMaximizeButtonClicked}
            disabled={lockUI}
            loading={null}
            fullWidth={true}
          >
            <Trans id="market_transfer_modal.funds.button.max">Max</Trans>
          </ButtonPrimary>
        </div>
      </div>

      <Divider />

      <div className={classnames(s.rowTotal, 'mb-md')}>
        <div className={s.totalText}>
          <Trans id="market_transfer_modal.metals.label.available_on_primary_market">
            Primary Market quantity
          </Trans>
        </div>

        <div className={s.totalValue}>
          {formatToken(primaryBalance, { pre: assetSelected || assetInitial })}
        </div>
      </div>

      <div className={classnames(s.rowTotal, 'mb-md')}>
        <div className={s.totalText}>
          <Trans id="market_transfer_modal.metals.label.available_on_secondary_market">
            Secondary Market quantity
          </Trans>
        </div>

        <div className={s.totalValue}>
          {formatToken(secondaryBalance, { pre: assetSelected || assetInitial })}
        </div>
      </div>

      <SubmitButton
        name={transferCoinFormId}
        fullWidth={true}
        disabled={lockUI}
        onAction={onTransferCoinContinueButtonClicked}
      >
        <Trans id="market_transfer_modal.funds.button.continue">Continue</Trans>
      </SubmitButton>
    </Form>
  );
};

export const TransferCoinView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTransferCoinView);
