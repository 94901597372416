import { useBankAccounts } from './hooks';

export const useModel = () => {
  const { bankAccounts, isBankAccountsLoading: lockUI } = useBankAccounts();

  return {
    bankAccounts,
    lockUI,
  };
};
