import { SortBy, SortDirection } from './use-columns';

export type SortItem = {
  field: SortBy;
  direction: SortDirection | undefined;
};

export type SortOrder = SortItem[];

export function addSortItem(order: SortOrder, item: SortItem): SortOrder {
  const isFieldIncluded = order.some((orderItem) => orderItem.field === item.field);

  if (isFieldIncluded && item.direction === undefined) {
    return order.filter((orderItem) => orderItem.field !== item.field);
  }

  if (isFieldIncluded && item.direction !== undefined) {
    return [item, ...order.filter((orderItem) => orderItem.field !== item.field)];
  }
  return [item, ...order];
}

export function isSortOrderEqual(sortOrder1, sortOrder2): boolean {
  if (sortOrder1.length !== sortOrder2.length) {
    return false;
  }
  return sortOrder1.every(
    (item, index) =>
      item.direction === sortOrder2[index].direction && item.field === sortOrder2[index].field
  );
}
