import sign from 'tm2sign.macro';

import { TableData, UploadedFile } from '@models/core';
import { SortOrder } from '@components/table2/autosized';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import {
  TableHeaderSortType,
  TableHeaderSortType as SortType,
} from '../../../components/page-table';

const vaultBalanceFields = [
  // same as in confirm vault balance modal
  { details: ['amount', { coin: ['asset', 'id'] }, { unit: ['id', 'label'] }] },
];

const vaultItemFields = [
  'address',
  'city',
  { coins: ['asset', 'id', 'name'] },
  'contact',
  { country: ['id', 'label'] },
  'id',
  { lastVaultBalance: [...vaultBalanceFields] },
  { vaultManagers: ['id', 'displayName'] },
  'warehouseCompany',
];

class VaultsApiService {
  public deleteVault(id: number): Promise<void> {
    const deleteVaultFields = sign('deleteVaultById', ['value']);
    const typedValues = {
      ...v.long({ id }),
    };
    return stompClient.sendData('deleteVaultById', deleteVaultFields, typedValues);
  }

  public getVaultBalanceDetailsHistory(
    vaultId: number,
    coinId: number
  ): Promise<Array<VaultBalanceDetailsHistoryItem>> {
    const balanceDetailsFields = sign('vaultBalanceDetailsForCoin', [
      'amount',
      'id',
      { report: ['attached', 'contentType', 'createdAt', 'id', 'name', 'path', 'size'] },
      { unit: ['label'] },
      { vaultBalance: ['createdAt', { creator: ['displayName'] }] },
    ]);
    const typedValues = {
      ...v.long({ coinId }),
      ...v.long({ vaultId }),
      ...v.orderInput({
        ordering: [{ direction: SortType.desc, field: 'vaultBalance.createdAt' }],
      }),
    };
    return stompClient.getData('vaultBalanceDetailsForCoin', balanceDetailsFields, typedValues);
  }

  public getVaults(pageNumber: number, sorting: SortOrder): Promise<TableData<VaultInfo>> {
    const vaultsFields = sign('vaultsPage', [{ data: [...vaultItemFields] }, 'hasMore', 'index']);

    const ordering = sorting.map((s) => ({
      field: s.key,
      direction: s.direction === 'ascend' ? TableHeaderSortType.asc : TableHeaderSortType.desc,
    }));

    const typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };

    return stompClient.getData('vaultsPage', vaultsFields, typedValues).then((response) => ({
      isHasMore: response.hasMore,
      data: response.data,
    }));
  }

  public saveVault(vault: VaultInfo): Promise<VaultInfo> {
    const savedVaultFields = sign('saveVault', [...vaultItemFields]);
    const typedValues = {
      ...v.saveVaultInput({ vault }),
    };
    return stompClient.sendData('saveVault', savedVaultFields, typedValues);
  }
}

export const vaultsApiService = new VaultsApiService();

export interface CoinInfo {
  asset: string;
  id: number;
  name: string;
}

export interface VaultInfo {
  address: string;
  city: string;
  coins: Array<CoinInfo>;
  contact: string;
  country: {
    id: number;
    label: string;
  };
  id: number;
  lastVaultBalance: {
    details: Array<VaultBalanceDetails>;
  };
  vaultManagers: Array<{
    id: number;
    displayName: string;
  }>;
  warehouseCompany: string;
}

export interface VaultBalanceDetails {
  amount: number;
  coin: CoinInfo;
  unit: Unit;
}

export interface VaultBalanceDetailsHistoryItem {
  amount: number;
  id: number;
  report: UploadedFile;
  unit: {
    label: string;
  };
  vaultBalance: {
    createdAt: string;
    creator: {
      displayName: string;
    };
  };
}

export interface Unit {
  id: number;
  label: string;
}
