import { ReduxAction } from '@models/redux';
import {
  BankAccount,
  FundsInvestor,
  ExternalClientBankAccount,
} from '../../../deposits-api.service';
import { WireTransferViewActionsTypes as T } from './model';

class GenerateDepositAction extends ReduxAction<T.GENERATE_DEPOSIT, void> {
  public readonly type: T.GENERATE_DEPOSIT = T.GENERATE_DEPOSIT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GenerateDepositFailAction extends ReduxAction<T.GENERATE_DEPOSIT_FAIL, Error> {
  public readonly type: T.GENERATE_DEPOSIT_FAIL = T.GENERATE_DEPOSIT_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GenerateDepositSuccessAction extends ReduxAction<T.GENERATE_DEPOSIT_SUCCESS, void> {
  public readonly type: T.GENERATE_DEPOSIT_SUCCESS = T.GENERATE_DEPOSIT_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetExternalClientBankAccountsAction extends ReduxAction<
  T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS,
  void
> {
  public readonly type: T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS = T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetExternalClientBankAccountsFailAction extends ReduxAction<
  T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_FAIL,
  Error
> {
  public readonly type: T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_FAIL =
    T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GetExternalClientBankAccountsSuccessAction extends ReduxAction<
  T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_SUCCESS,
  Array<ExternalClientBankAccount>
> {
  public readonly type: T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_SUCCESS =
    T.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_SUCCESS;
  public constructor(public payload: Array<ExternalClientBankAccount>) {
    super(payload);
  }
}

class GetInvestorsAction extends ReduxAction<T.GET_INVESTORS, void> {
  public readonly type: T.GET_INVESTORS = T.GET_INVESTORS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetInvestorsFailAction extends ReduxAction<T.GET_INVESTORS_FAIL, Error> {
  public readonly type: T.GET_INVESTORS_FAIL = T.GET_INVESTORS_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GetInvestorsSuccessAction extends ReduxAction<T.GET_INVESTORS_SUCCESS, Array<FundsInvestor>> {
  public readonly type: T.GET_INVESTORS_SUCCESS = T.GET_INVESTORS_SUCCESS;
  public constructor(public payload: Array<FundsInvestor>) {
    super(payload);
  }
}

class SetBankAccountsAction extends ReduxAction<T.SET_BANK_ACCOUNTS, Array<BankAccount>> {
  public readonly type: T.SET_BANK_ACCOUNTS = T.SET_BANK_ACCOUNTS;
  public constructor(public payload: Array<BankAccount>) {
    super(payload);
  }
}

export const wireTransferViewServiceActions = {
  generateDeposit: () => new GenerateDepositAction().get(),
  generateDepositFail: (error: Error) => new GenerateDepositFailAction(error).get(),
  generateDepositSuccess: () => new GenerateDepositSuccessAction().get(),
  getExternalClientBankAccounts: () => new GetExternalClientBankAccountsAction().get(),
  getExternalClientBankAccountsFail: (error: Error) =>
    new GetExternalClientBankAccountsFailAction(error).get(),
  getExternalClientBankAccountsSuccess: (list: Array<ExternalClientBankAccount>) =>
    new GetExternalClientBankAccountsSuccessAction(list).get(),
  getInvestors: () => new GetInvestorsAction().get(),
  getInvestorsFail: (error: Error) => new GetInvestorsFailAction(error).get(),
  getInvestorsSuccess: (list: Array<FundsInvestor>) => new GetInvestorsSuccessAction(list).get(),
  setBankAccounts: (list: Array<BankAccount>) => new SetBankAccountsAction(list).get(),
};

export type WireTransferViewServiceActionsTypes =
  | GenerateDepositAction
  | GenerateDepositFailAction
  | GenerateDepositSuccessAction
  | GetExternalClientBankAccountsAction
  | GetExternalClientBankAccountsFailAction
  | GetExternalClientBankAccountsSuccessAction
  | GetInvestorsAction
  | GetInvestorsFailAction
  | GetInvestorsSuccessAction
  | SetBankAccountsAction;

export declare namespace WireTransferViewServiceActions {
  type generateDeposit = GenerateDepositAction;
  type generateDepositFail = GenerateDepositFailAction;
  type generateDepositSuccess = GenerateDepositSuccessAction;
  type GetExternalClientBankAccounts = GetExternalClientBankAccountsAction;
  type GetExternalClientBankAccountsFail = GetExternalClientBankAccountsFailAction;
  type GetExternalClientBankAccountsSuccess = GetExternalClientBankAccountsSuccessAction;
  type GetInvestors = GetInvestorsAction;
  type GetInvestorsFail = GetInvestorsFailAction;
  type GetInvestorsSuccess = GetInvestorsSuccessAction;
  type setBankAccounts = SetBankAccountsAction;
}
