import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { ColumnType } from '@components/table2/base';
import { dater } from '@helper/date';
import { ProfileLinkCell } from '@components/table2/utils';
import { PermissionGroup } from '@permissions/permission-group';
import { profileOtherPagePermissionsGroup } from '../profile/other/permissions';
import { TransactionTooltip } from './tx-tooltip';
import { Column, OverdraftItem } from './api';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:overdraft-report-table',
});

const noSorting = [];
export const columns: Array<ColumnType<OverdraftItem>> = [
  {
    key: Column.createdAt,
    dataIndex: Column.createdAt,
    headCell: () => <Trans id="overdraft-report.table.date-time">Date & Time</Trans>,
    rowCell: ({ data }) => <>{dater.toLocalDateTime(data.createdAt)}</>,
    width: 145,
  },
  {
    key: Column.clientId,
    dataIndex: Column.clientId,
    headCell: () => <Trans id="overdraft-report.table.client-id">Client ID</Trans>,
    rowCell: ({ data }) => <>{data.user.id}</>,
    width: 100,
  },
  {
    key: Column.clientName,
    dataIndex: Column.clientName,
    headCell: () => <Trans id="overdraft-report.table.client-name">Client name</Trans>,
    rowCell: ({ data }) => <ProfileLinkCell text={data.user.displayName} id={data.user.id} />,
    sorting: noSorting,
    width: 160,
  },
  {
    key: Column.txId,
    dataIndex: Column.txId,
    headCell: () => <Trans id="overdraft-report.table.transaction-id">Transaction ID</Trans>,
    rowCell: ({ data }) => <TransactionTooltip txId={data.txId} />,
    sorting: noSorting,
    width: 150,
  },
  {
    key: Column.previousBalance,
    dataIndex: Column.previousBalance,
    headCell: () => (
      <Trans id="overdraft-report.table.prev-overdraft-balance">Previous overdraft balance</Trans>
    ),
    rowCell: ({ data }) => <>{data.previousBalance}</>,
    width: 265,
  },
  {
    key: Column.changeBalance,
    dataIndex: Column.changeBalance,
    headCell: () => (
      <Trans id="overdraft-report.table.current-overdraft-balance">Current balance change</Trans>
    ),
    rowCell: ({ data }) => (
      <span className={classnames({ [s.negativeBalanceText]: data.changeBalance < 0 })}>
        {data.changeBalance}
      </span>
    ),
    width: 235,
  },
  {
    key: Column.newBalance,
    dataIndex: Column.newBalance,
    headCell: () => (
      <Trans id={'overdraft-report.table.new-overdraft-balance'}>New overdraft balance</Trans>
    ),
    rowCell: ({ data }) => <>{data.newBalance}</>,
    width: 220,
  },
  {
    key: Column.currency,
    dataIndex: Column.currency,
    headCell: () => <Trans id={'overdraft-report.table.currency'}>Currency</Trans>,
    rowCell: ({ data }) => <>{data.currency.asset}</>,
    width: 110,
  },
];
