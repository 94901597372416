import React, { FC, ReactNode } from 'react';

import { HasAccess, traderPermissions } from '@permissions/core';
import { Select } from '@components/form';
import { Fields, useModel } from './model';

const accessPermissions = { or: traderPermissions };
export const OptionalTraderIssuerSelect: FC<{
  className: string;
  disabled: boolean;
  label: ReactNode;
  name: Fields;
  userPermissions: string;
}> = (p) => {
  const m = useModel();

  return (
    <HasAccess userPermissions={p.userPermissions} permissions={accessPermissions}>
      <Select
        className={p.className}
        label={p.label}
        name={p.name as string}
        data={m.issuers}
        disabled={p.disabled || m.disabled}
      />
    </HasAccess>
  );
};
