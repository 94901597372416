import React, { FC, memo, ReactElement } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { HasAccess } from '@permissions/core';
import { VerificationStatus as S } from '@models/core';
import { Text } from '@components/typography/text';
import { Title } from '@components/typography/title';
import { Profile } from '../../../api.shared';
import { useModel } from './model';

export type VerificationProps = {
  profile: Profile;
  verifyButton?: ReactElement;
};
export const Verification: FC<VerificationProps> = memo<VerificationProps>((p) => {
  const m = useModel(p.profile);

  return (
    <HasAccess
      permissions={m.accessPermissions}
      extraCheck={p.profile?.verificationStatus !== S.rejected}
      userPermissions={p.profile?.permissions}
    >
      <div className="mb3">
        <Title
          className={m.statusColorClass}
          level={4}
          color="secondary"
          uat="verification-status-label"
        >
          {m.progressStatusText}
        </Title>

        <div className={s.bar}>
          <div className={s.progress} style={{ width: m.progressValuePct }}>
            {m.progressValuePct}
          </div>
        </div>

        {p.profile?.verificationStatus !== S.processingVerification ? null : (
          <Text className="mt1" level={2}>
            <Trans id="profile.card.verification.label.processing">
              The documents are under consideration
            </Trans>
          </Text>
        )}

        {p.profile?.verificationStatus !== S.resubmissionRequested ? null : (
          <Text className={classnames(s.alert, 'mt1')} level={2}>
            <Trans id="profile.card.verification.label.resubmission">
              Your documents do not meet the requirements, please upload the required documents in
              good quality.
            </Trans>
          </Text>
        )}

        <div className={s.verifyButton}>{p.verifyButton}</div>
      </div>
    </HasAccess>
  );
});
