import React, { FC, memo, useEffect } from 'react';
import classnames from 'classnames';
import { Layout } from 'antd';
import { PageTopBanner } from '@modules/page-top-banner';

import s from './dark.module.scss';
import { AuthorizedSidebar } from './sidebar';
import { AuthorizedHeader } from './header';
import { AuthorizedContent } from './content';

export const DarkLayout: FC<{
  isDeprecatedStyles?: boolean;
  stretchVertically?: boolean;
}> = memo(({ children, isDeprecatedStyles = true, stretchVertically = false }) => {
  useEffect(() => {
    const authorizedClass = 'tm2-dark-theme';
    document.body.classList.add(authorizedClass);
    return () => document.body.classList.remove(authorizedClass);
  }, []);

  return (
    <Layout className={s.layout}>
      <AuthorizedSidebar />

      <Layout>
        <AuthorizedHeader />

        <AuthorizedContent
          className={classnames({
            'tm-dark-theme': isDeprecatedStyles,
            'tm2-deprecated-page-styles': isDeprecatedStyles,
            [s.stretchVertically]: stretchVertically,
          })}
        >
          <PageTopBanner />
          {children}
        </AuthorizedContent>
      </Layout>
    </Layout>
  );
});
