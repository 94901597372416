import { ReduxAction } from '@models/redux';
import { VaultBalanceDetailsHistoryItem } from '../../vaults-api.service';
import { OnModalOpenedPayload, VaultBalanceDetailsActionsTypes as T } from './model';

class GetVaultBalanceDetailsAction extends ReduxAction<
  T.GET_VAULT_BALANCE_DETAILS,
  OnModalOpenedPayload
> {
  public readonly type: T.GET_VAULT_BALANCE_DETAILS = T.GET_VAULT_BALANCE_DETAILS;
  public constructor(public payload: OnModalOpenedPayload) {
    super(payload);
  }
}

class GetVaultBalanceDetailsFailAction extends ReduxAction<
  T.GET_VAULT_BALANCE_DETAILS_FAIL,
  Error
> {
  public readonly type: T.GET_VAULT_BALANCE_DETAILS_FAIL = T.GET_VAULT_BALANCE_DETAILS_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GetVaultBalanceDetailsSuccessAction extends ReduxAction<
  T.GET_VAULT_BALANCE_DETAILS_SUCCESS,
  Array<VaultBalanceDetailsHistoryItem>
> {
  public readonly type: T.GET_VAULT_BALANCE_DETAILS_SUCCESS = T.GET_VAULT_BALANCE_DETAILS_SUCCESS;
  public constructor(public payload: Array<VaultBalanceDetailsHistoryItem>) {
    super(payload);
  }
}

export const vaultBalanceDetailsServiceActions = {
  getVaultBalanceDetailsInfo: (payload: OnModalOpenedPayload) =>
    new GetVaultBalanceDetailsAction(payload).get(),
  getVaultBalanceDetailsInfoFail: (error: Error) =>
    new GetVaultBalanceDetailsFailAction(error).get(),
  getVaultBalanceDetailsInfoSuccess: (history: Array<VaultBalanceDetailsHistoryItem>) =>
    new GetVaultBalanceDetailsSuccessAction(history).get(),
};

export type VaultBalanceDetailsServiceActionsTypes =
  | GetVaultBalanceDetailsAction
  | GetVaultBalanceDetailsFailAction
  | GetVaultBalanceDetailsSuccessAction;

export declare namespace VaultBalanceDetailsServiceActions {
  type GetVaultBalanceDetails = GetVaultBalanceDetailsAction;
  type GetVaultBalanceDetailsFail = GetVaultBalanceDetailsFailAction;
  type GetVaultBalanceDetailsSuccess = GetVaultBalanceDetailsSuccessAction;
}
