import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Permission, permissionsSelectors } from '@permissions/core';
import { configSelectors } from '@config/core';
import { KeyExtractor } from '@components/table2/base';
import { LoadData, useTable } from '@components/table2/simplified';
import api, { Deposit, Filters } from './api';

export const useModel = () => {
  const i18n = useSelector(configSelectors.i18n);
  const isHasAccess = useSelector(permissionsSelectors.isHasAccess);

  const keyExtractor = useCallback<KeyExtractor<Deposit>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallback<LoadData<Deposit, Filters>>(({ nextPage, filter }) => {
    return api.getDepositsPage({
      page: nextPage,
      filter: filter ?? {},
    });
  }, []);

  const { tableInstance, setData, setFilter } = useTable<Deposit>({ keyExtractor, loadData });

  const replaceDeposit = useCallback((deposit: Deposit) => {
    setFilter(() => {});
    setData((currentData) => {
      return currentData.map((d) => (d.number === deposit.number ? deposit : d));
    });
  }, []); // eslint-disable-line

  const addDeposit = useCallback(
    (deposit: Deposit) => {
      setFilter(() => {});
      setData((currentData) => [deposit, ...currentData]);
    },
    [setData]
  ); // eslint-disable-line

  const onFiltersChanged = useCallback(
    (filters: Filters) => {
      setFilter(() => filters);
    },
    [setFilter]
  );

  return {
    addDeposit,
    i18n,
    isHasAccess,
    onFiltersChanged,
    replaceDeposit,
    tableInstance,
  };
};
