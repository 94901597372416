import { useEffect, useState } from 'react';

export const useNotifyModel = () => {
  let [textRef, setTextRef] = useState(null);
  const scrollHeight = textRef?.scrollHeight;
  const clientHeight = textRef?.clientHeight;
  const [isShowMoreClicked, setShowMoreClicked] = useState(false);
  const [isShowMoreExist, setShowMoreExist] = useState(
    !isShowMoreClicked && scrollHeight - 5 > clientHeight
  );

  const onShowMoreClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const notificationRef = textRef.parentNode.parentNode.parentNode.parentNode.parentNode;
    const titleRef = textRef.parentNode.parentNode.parentNode.querySelector('.notification__title');
    const titleHeight = titleRef?.clientHeight || 0;
    let textHeight;
    const timerHeight = 3;
    const notificationSpacing = 16 + 16 + 16; // global pt + text mt + global pb
    const pageFreeSpace = 150;

    setShowMoreClicked(true);
    if (window.innerHeight - pageFreeSpace < textRef.scrollHeight) {
      textHeight = window.innerHeight - pageFreeSpace;
      textRef.style.maxHeight = `${textHeight}px`;
      textRef.style.overflowY = 'scroll';
    } else {
      textHeight = textRef.scrollHeight;
      textRef.style.maxHeight = `${textHeight}px`;
    }
    notificationRef.style.height = `${
      titleHeight + textHeight + timerHeight + notificationSpacing
    }px`;
  };

  useEffect(() => {
    setShowMoreExist(!isShowMoreClicked && scrollHeight - 5 > clientHeight);
  }, [isShowMoreClicked, scrollHeight]); // eslint-disable-line

  return {
    isShowMoreExist,
    onShowMoreClicked,
    setTextRef,
  };
};
