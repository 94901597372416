import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors } from '@config/core';

import { useI18n } from '@hooks/i18n';
import { useTable } from '@components/table2/simplified';

import api from './api';

import type { KeyExtractor } from '@components/table2/core';
import type { LoadData, RowConfig } from '@components/table2/simplified';
import type { Filters, Redemption } from './api';

export const rowConfig: RowConfig<Redemption> = {
  height: 140,
};

export const useModel = () => {
  const { i18n } = useI18n();
  const role = useSelector(configSelectors.userRole);
  const linkedInvestor = useSelector(configSelectors.userIssuerInvestor);
  const linkedInvestorId = linkedInvestor?.id;

  const keyExtractor = useCallback<KeyExtractor<Redemption>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallback<LoadData<Redemption, Filters>>(({ nextPage, filter }) => {
    return api.getRedemptionsPage({ page: nextPage, filter: filter ?? {} });
  }, []);

  const { tableInstance, setFilter, setData } = useTable<Redemption, Filters>({
    keyExtractor,
    loadData,
  });

  const onReplaceRedemption = useCallback(
    (redemption: Redemption) => {
      setData((currentData) => {
        return currentData.map((r) => (r.id === redemption.id ? redemption : r));
      });
    },
    [setData]
  );

  const onFiltersChanged = useCallback(
    (filters: Filters) => {
      setFilter(() => filters);
    },
    [setFilter]
  );

  return {
    i18n,
    linkedInvestorId,
    onFiltersChanged,
    onReplaceRedemption,
    role,
    tableInstance,
  };
};
