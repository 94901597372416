import { RedeemOptionsModalActionsTypes as Types } from './model';

const OnCloseModalClickedAction = () => ({
  payload: null,
  type: Types.ON_CLOSE_MODAL_CLICKED,
});

const OnChooseSelectedRedeemOptionClickedAction = (selectedOption) => ({
  payload: selectedOption,
  type: Types.ON_CHOOSE_SELECTED_REDEEM_OPTION_CLICKED,
});

const OnRedeemOptionSelectedAction = (option) => ({
  payload: option,
  type: Types.ON_REDEEM_OPTION_SELECTED,
});

const OnModalLeftAction = () => ({
  payload: null,
  type: Types.ON_MODAL_LEFT,
});

const OnModalOpenedAction = (coinId) => ({
  payload: coinId,
  type: Types.ON_MODAL_OPENED,
});

export const redeemOptionsModalUserActions = {
  onCloseModalClicked: OnCloseModalClickedAction,
  onChooseSelectedRedeemOptionClicked: OnChooseSelectedRedeemOptionClickedAction,
  onRedeemOptionSelected: OnRedeemOptionSelectedAction,
  onModalLeft: OnModalLeftAction,
  onModalOpened: OnModalOpenedAction,
};
