const finishRedeemModalState = (root) => root.main?.wallet?.redeemModals?.finishRedeemModal;

const isCreateRedeemLoading = (root) => finishRedeemModalState(root).isCreateRedeemLoading;

const lockUI = (root) =>
  finishRedeemModalState(root).isCreateRedeemLoading ||
  finishRedeemModalState(root).isDownloadTransactionLoading;

export const finishRedeemModalSelectors = {
  finishRedeemModalState,
  isCreateRedeemLoading,
  lockUI,
};
