import { MetalTradeInfo } from './types';

type SortBy = 'asset' | 'metal';

type SortDirection = 'ascending' | 'descending';

export function sortMetalTradeInfos(
  items: MetalTradeInfo[],
  sortBy: SortBy,
  sortDirection: SortDirection
): MetalTradeInfo[] {
  switch (sortBy) {
    case 'asset':
      return sortDirection === 'ascending'
        ? [...items].sort((a, b) => (a.asset > b.asset ? 1 : -1))
        : [...items].sort((a, b) => (a.asset > b.asset ? -1 : 1));

    case 'metal':
      return sortDirection === 'ascending'
        ? [...items].sort((a, b) => (a.metal > b.metal ? 1 : -1))
        : [...items].sort((a, b) => (a.metal > b.metal ? -1 : 1));
  }
}
