import { FC, useMemo } from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import classnames from 'classnames';
import s from './index.module.scss';
import { Link, LinkType } from '../components/link';
import { TableHeaderSortType } from 'components/page-table'; // TODO: this type should reside in alt-table
import { formatMoney, useI18n } from 'containers/services/commonService';
import { MetalTradeInfo } from './types';

type SortDirection = 'ascending' | 'descending' | 'unset';

type SortBy = 'asset' | 'metal';

type Column = {
  cell: {
    body: (rowData: MetalTradeInfo, hover: boolean) => JSX.Element;
    classNames?: string;
  };
  head: {
    body: JSX.Element;
    classNames?: string;
    onSort?: (direction: TableHeaderSortType) => void;
    sortDirection?: TableHeaderSortType;
  };
};

export type OnSort = (sortBy: SortBy, direction: SortDirection) => void;

function callSort(sortDirection: TableHeaderSortType, sortBy: SortBy, onSort: OnSort) {
  if (sortDirection === TableHeaderSortType.asc) {
    onSort(sortBy, 'ascending');
    return;
  }
  if (sortDirection === TableHeaderSortType.desc) {
    onSort(sortBy, 'descending');
    return;
  }
  onSort(sortBy, 'unset');
}

function getItemLink(assetCoinId: number, currencyId: number) {
  return (
    '/primary-detailed?' +
    new URLSearchParams({
      coin: String(assetCoinId),
      currency: String(currencyId),
    })
  );
}

const Divider: FC<{
  data: MetalTradeInfo;
  index: number;
}> = ({ data, index }) => {
  if (!(index < data?.bestTradePrice?.length - 1)) {
    return null;
  }

  return <span className={s.divider}>/</span>;
};

export function useColumns(data: MetalTradeInfo[] | null, onSort: OnSort) {
  const { i18n } = useI18n();
  const sortKey = data?.map((info) => info.asset).join('_');

  return useMemo<Column[]>(
    () =>
      data === null
        ? null
        : [
            {
              cell: {
                body: ({ asset }: MetalTradeInfo) => <span>{asset}</span>,
                className: classnames(s.cell, s.symbolCell),
              },
              head: {
                body: (
                  <span>
                    <Trans id={'primary_market.table.symbol'}>Symbol</Trans>
                  </span>
                ),
                className: s.head,
                onSort: (sortDirection: TableHeaderSortType) => {
                  callSort(sortDirection, 'asset', onSort);
                },
                sortDirection: undefined,
              },
            },
            {
              cell: {
                body: ({ metal }: MetalTradeInfo) => <span>{metal}</span>,
                className: classnames(s.cell, s.metalCell),
              },
              head: {
                body: (
                  <span>
                    <Trans id={'primary_market.table.metal'}>Metal</Trans>
                  </span>
                ),
                className: s.head,
                onSort: (sortDirection: TableHeaderSortType) => {
                  callSort(sortDirection, 'metal', onSort);
                },
                sortDirection: undefined,
              },
            },
            {
              cell: {
                body: (rowData: MetalTradeInfo, hover) => (
                  // Disable google auto-translate feature with 'notranslate' class.
                  <span className={'notranslate'}>
                    {rowData.bestTradePrice.map((price, index) => (
                      <span key={index}>
                        <Link
                          className={classnames(
                            s.link,
                            { [s.buttonLink]: hover },
                            { mt1: hover },
                            { mb1: hover }
                          )}
                          to={getItemLink(price.assetCoinId, price.currencyId)}
                          type={hover ? LinkType.button : LinkType.text}
                        >
                          {formatMoney(price.value, {
                            default: i18n._(
                              defineMessage({ id: 'shared.not_available', message: 'N/A' })
                            ),
                            post: price.currency,
                          })}
                        </Link>
                        <Divider data={rowData} index={index} />
                      </span>
                    ))}
                  </span>
                ),
                className: classnames(s.cell, s.priceCell),
              },
              head: {
                body: (
                  <span>
                    <Trans id="primary_market.table.best_price">Best price</Trans>
                  </span>
                ),
                className: s.head,
              },
            },
          ],
    [sortKey, onSort]
  );
}
