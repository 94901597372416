import { createAsyncThunk } from '@reduxjs/toolkit';

import { SortItem } from '@models/core';
import { handleBackendError } from '@modules/notify';
import api from '../table/api';
import selectors from './selectors';
import { ColumnIds, sliceId } from './model';

const loadMoreTableData = createAsyncThunk(
  sliceId('loadMoreTableData'),
  async (p: void, { getState }) => {
    try {
      const root = getState();
      const pageIndex = selectors.pageIndex(root);
      const sort = selectors.sort(root);
      return await api.getPayments(pageIndex, sort);
    } catch (error) {
      handleBackendError(error);
      throw error;
    }
  },
  {
    condition: (p, { getState }) => {
      const isTableLoading = selectors.isTableLoading(getState());
      if (isTableLoading) {
        // our table has double request bug on start
        return false;
      }
    },
  }
);

const onTableScrolled = createAsyncThunk(
  sliceId('onTableScrolled'),
  async (p: void, { dispatch, getState }) => {
    const isLoading = selectors.isTableLoading(getState());
    return !isLoading && dispatch(loadMoreTableData()); // our table has double request bug on start
  }
);

const onTableSorted = createAsyncThunk(
  sliceId('onTableSorted'),
  async (p: SortItem<ColumnIds>, { dispatch }) => dispatch(loadMoreTableData())
);

export default {
  loadMoreTableData,
  onTableScrolled,
  onTableSorted,
};
