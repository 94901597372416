import { all, put, select, takeEvery, takeLatest, delay } from 'redux-saga/effects';

import { handleBackendError } from '@modules/notify';
import { Put } from '@helper/redux';
import { usersApiService as usersApi } from '../../users-api.service';
import { UsersPageActionsTypes as UsersTypes } from './model';
import { usersPageServiceActions } from './service.acions';
import { usersPageSelectors } from './selectors';

const LOAD_USERS_DELAY = 500;

export function* usersPageEffects() {
  yield all([
    takeEvery(UsersTypes.EVENT_USERS_UPDATED, scrollToUpdatedUser),
    takeLatest(UsersTypes.LOAD_MORE_USERS, loadMoreUsers),
    takeEvery(UsersTypes.ON_SEARCH_FILLED, Put(usersPageServiceActions.loadMoreUsers)),
    takeEvery(UsersTypes.ON_TABLE_SCROLLED, Put(usersPageServiceActions.loadMoreUsers)),
    takeEvery(UsersTypes.ON_TABLE_SORTED, Put(usersPageServiceActions.loadMoreUsers)),
  ]);
}

function* loadMoreUsers(action) {
  try {
    yield delay(LOAD_USERS_DELAY);
    const isSortAction = !!action.payload;
    const pageIndex = yield select(usersPageSelectors.pageIndex);
    const search = yield select(usersPageSelectors.search);
    const sort = yield select(usersPageSelectors.sort);
    const usersTable = yield usersApi.getUsers(pageIndex, sort, search);
    yield put(
      usersPageServiceActions.loadMoreUsersSuccess({
        ...usersTable,
        isSort: isSortAction,
      })
    );
  } catch (error) {
    yield put(usersPageServiceActions.loadMoreUsersFail(error));
    handleBackendError(error);
  }
}

function scrollToUpdatedUser(action) {
  const config = action.payload;
  const userId = config.user.id;
  const isNoScroll = config.isNoScroll;
  if (!isNoScroll) {
    const userRowNode = document.querySelector(`.username-cell-${userId}`)?.parentNode?.parentNode;
    userRowNode && userRowNode.scrollIntoView({ behavior: 'smooth' });
  }
}
