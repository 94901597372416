import { useSelector } from 'react-redux';

import { configSelectors } from '../config/store/selectors';

export const useI18n = () => {
  // lingui v3 doesn't update i18n object on language change so, app will not rerender when language is changed
  const i18n = useSelector(configSelectors.i18n);
  const lang = useSelector(configSelectors.lang);
  return { i18n, lang };
};
