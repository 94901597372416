import { mergeFields } from 'shared/helper/gql-fields/merge';
import { Permission } from '@permissions/core';
import Big from 'big.js';

const PERMISSION_ANY = -1;

let currentId = 0;

type QueryPermissions =
  | {
      or: Array<QueryPermissions | Permission>;
      and?: undefined;
    }
  | {
      or?: undefined;
      and: Array<QueryPermissions | Permission>;
    };

type QueryPermissionsParam = QueryPermissions | Permission;

type PermittedQuery<T extends () => Promise<any> = (...args: any[]) => Promise<any>> = T & {
  id: number;
  permissions: QueryPermissionsParam | typeof PERMISSION_ANY;
  queryName: string;
  queryFields: object;
};

type QueryByPermission = PermissionRecordData & { access?: number[] };

export function makeQuery<T extends (...args: any[]) => any>({
  query,
  queryName,
  queryFields,
  permissions,
}: {
  permissions?: QueryPermissionsParam;
  queryName: string;
  queryFields?: object;
  query: T;
}): PermittedQuery<T> {
  const permittedQuery = query as PermittedQuery<T>;

  if (!permissions) {
    permittedQuery.permissions = PERMISSION_ANY;
  } else {
    permittedQuery.permissions = permissions;
  }
  permittedQuery.queryName = queryName;
  permittedQuery.queryFields = queryFields;
  permittedQuery.id = currentId++;

  return query as PermittedQuery<T>;
}

// A group is an array of permissions.
function mergeAndGroups(
  groups: (Permission | typeof PERMISSION_ANY)[][][]
): (Permission | typeof PERMISSION_ANY)[][] {
  if (groups.length === 1) {
    return groups[0];
  }
  return groups[0].reduce<(Permission | typeof PERMISSION_ANY)[][]>((acc, groupEntry) => {
    const mergedFollowingGroups = mergeAndGroups(groups.slice(1));
    const mergedEntryGroups = mergedFollowingGroups.map((permissions) => [
      ...groupEntry,
      ...permissions,
    ]);

    return [...acc, ...mergedEntryGroups];
  }, []);
}

// A group is an array of permissions.
function extractAndGroups(
  permissionsParam: QueryPermissionsParam | typeof PERMISSION_ANY | undefined
): (Permission | typeof PERMISSION_ANY)[][] {
  if (!permissionsParam) {
    return [[PERMISSION_ANY]];
  }
  if (typeof permissionsParam === 'number') {
    return [[permissionsParam]];
  }
  if (permissionsParam.or) {
    return permissionsParam.or.reduce((acc, p) => [...acc, ...extractAndGroups(p)], []);
  }
  if (permissionsParam.and) {
    return mergeAndGroups(permissionsParam.and.map((p) => extractAndGroups(p)));
  }
}

type PermissionRecordData = {
  queryId: number;
  queryName: string;
  queryEndpoint: string;
  queryFields?: object;
  queryPermissions?: QueryPermissionsParam;
};

const PERMISSION_RECORD = 'PERMISSION_RECORD';
const PERMISSION_GROUP = 'PERMISSION_GROUP';

export class PermissionRecord {
  constructor(
    public readonly permission: Permission | typeof PERMISSION_ANY,
    public readonly data?: PermissionRecordData
  ) {}

  serialize() {
    return {
      type: PERMISSION_RECORD,
      permission: this.permission,
      data: this.data,
    };
  }

  static deserialize(data: Record<string, any>) {
    return new PermissionRecord(data.permission, data.data);
  }
}

type PermissionGroupMarkerEntity = 'api' | 'layout' | 'prioritized-layout' | 'todo';

type PermissionGroupMarker = `${PermissionGroupMarkerEntity}:${string}`;

export class PermissionGroup {
  private readonly operator: 'AND' | 'OR';
  private readonly groups: (PermissionGroup | PermissionRecord)[];
  private marker: PermissionGroupMarker;
  private optional: boolean;

  constructor(params: {
    operator: 'AND' | 'OR';
    groups: (PermissionGroup | PermissionRecord)[];
    marker?: PermissionGroupMarker;
    optional?: boolean;
  }) {
    const { operator, groups, marker, optional = false } = params;

    this.operator = operator;
    this.groups = groups;
    this.marker = marker;
    this.optional = optional;
  }

  resolve(permissions: Permission[] | string) {
    if (typeof permissions === 'string') {
      permissions = PermissionGroup.getPermissionsFromString(permissions);
    }
    if (this.operator === 'AND') {
      return this.resolveAnd(permissions);
    }
    if (this.operator === 'OR') {
      return this.resolveOr(permissions);
    }
  }

  setMarker(marker: PermissionGroupMarker) {
    this.marker = marker;
  }

  createSuperGroup({ marker }: { marker: PermissionGroupMarker }) {
    return new PermissionGroup({
      operator: 'AND',
      groups: this.groups,
      marker: marker,
      optional: this.optional,
    });
  }

  serialize(stringify = true) {
    const serializedData = {
      type: PERMISSION_GROUP,
      operator: this.operator,
      marker: this.marker,
      optional: this.optional,
      groups: this.groups.map((g) => g.serialize(false)),
    };
    if (stringify) {
      return JSON.stringify(serializedData);
    }
    return serializedData;
  }

  getQueriesByPermission(
    recordMap: Record<number, PermissionRecordData[]> = {},
    root = true
  ): Record<number, QueryByPermission[]> {
    // Generate recordMap.
    for (let group of this.groups) {
      if (group instanceof PermissionRecord && group.data) {
        const groupPermission = group.permission;
        const groupData = group.data;

        if (recordMap[groupPermission]) {
          recordMap[groupPermission] = recordMap[groupPermission].concat(groupData);
        } else {
          recordMap[groupPermission] = [groupData];
        }
      }
      if (group instanceof PermissionGroup) {
        group.getQueriesByPermission(recordMap, false);
      }
    }

    if (!root) return recordMap;

    // Deduplicate records for each permission.
    const dedupedRecordMap = Object.entries(recordMap).reduce<typeof recordMap>(
      (acc, [permission, records]) => {
        const recordIds: Record<number, boolean> = {};
        const dedupedRecords: PermissionRecordData[] = [];

        for (let record of records) {
          if (recordIds[record.queryId]) {
            continue;
          }
          dedupedRecords.push(record);
          recordIds[record.queryId] = true;
        }
        acc[permission] = dedupedRecords;
        return acc;
      },
      {}
    );

    return Object.entries(dedupedRecordMap).reduce<Record<number, QueryByPermission[]>>(
      (acc, [permission, records]) => {
        const modifiedPermissionRecords = records.reduce<QueryByPermission[]>((acc, record) => {
          const extractedRecords = extractAndGroups(record.queryPermissions)
            .filter((g) => g.includes(Number(permission)))
            .map((g) => g.sort())
            .map((g) => ({ ...record, access: g }));

          return [...acc, ...extractedRecords];
        }, []);
        acc[permission] = modifiedPermissionRecords.sort(
          (a, b) => a.access.length - b.access.length
        );
        return acc;
      },
      {}
    );
  }

  getMergedQueriesByPermission() {
    const queriesByPermission: Record<number, QueryByPermission[]> = this.getQueriesByPermission();

    const result: Record<
      number,
      Array<{
        queryName: string;
        fields: object;
        access: number[];
      }>
    > = {};

    for (let [permission, queries] of Object.entries(queriesByPermission)) {
      const mergeGroups = queries.reduce<
        Record<
          string,
          {
            queryName: string;
            queries: QueryByPermission[];
            access: number[];
          }
        >
      >((acc, q) => {
        const mergeGroupId = `${q.queryEndpoint}_${q?.access.join('_')}`;
        const mergeGroup = {
          queryName: q.queryName,
          queries: [q],
          access: q.access,
        };
        acc[mergeGroupId] ? acc[mergeGroupId].queries.push(q) : (acc[mergeGroupId] = mergeGroup);
        return acc;
      }, {});

      const mergeResults = Object.values(mergeGroups).map((g) => {
        const groupFields = g.queries.reduce<object>((acc, q) => {
          // Rest queries.
          if (q.queryFields === undefined) {
            return acc;
          }
          return mergeFields(acc, q.queryFields);
        }, []);

        const res = {
          fields: groupFields,
          endpoint: g.queryName,
          access: g.access,
        };

        if (!g.access) {
          delete res.access;
        }
        return res;
      });

      result[permission] = mergeResults;
    }
    return result;
  }

  getQueriesWithoutPermissions() {
    return this.getQueriesByPermission()[PERMISSION_ANY] ?? [];
  }

  private resolveAnd(permissions: Permission[]) {
    for (let group of this.groups) {
      if (group instanceof PermissionGroup && group.optional) {
        continue;
      }
      if (group instanceof PermissionGroup && !group.resolve(permissions)) {
        return false;
      }
      if (
        group instanceof PermissionRecord &&
        group.permission !== PERMISSION_ANY &&
        !permissions.includes(group.permission)
      ) {
        return false;
      }
    }
    return true;
  }

  private resolveOr(permissions: Permission[]) {
    for (let group of this.groups) {
      if (group instanceof PermissionGroup && group.optional) {
        continue;
      }
      if (group instanceof PermissionGroup && group.resolve(permissions)) {
        return true;
      }
      if (
        group instanceof PermissionRecord &&
        (group.permission === PERMISSION_ANY || permissions.includes(group.permission))
      ) {
        return true;
      }
    }
    return false;
  }

  static deserialize(rawData: Record<string, any> | string) {
    const data = typeof rawData === 'string' ? JSON.parse(rawData) : rawData;
    if (data.type === PERMISSION_GROUP) {
      return new PermissionGroup({
        operator: data.operator,
        groups: data.groups.map((g) => PermissionGroup.deserialize(g)),
        marker: data.marker,
        optional: data.optional,
      });
    }
    if (data.type === PERMISSION_RECORD) {
      return PermissionRecord.deserialize(data);
    }
  }

  static getPermissionsFromString(permissions: string): Permission[] {
    const result = [];
    let number = new Big(permissions);

    while (number.gt(1)) {
      result.push(number.mod(2).toNumber());
      number = number.div(2).round(0, Big.roundDown);
    }
    result.push(number.toNumber());

    return result.map((flag, index) => (flag ? index : 0)).filter(Boolean);
  }

  static and({
    groups,
    marker,
  }: {
    groups: (PermissionGroup | PermissionRecord)[];
    marker?: PermissionGroupMarker;
  }): PermissionGroup {
    return new PermissionGroup({ operator: 'AND', groups, marker });
  }

  static or({
    groups,
    marker,
  }: {
    groups: (PermissionGroup | PermissionRecord)[];
    marker?: PermissionGroupMarker;
  }): PermissionGroup {
    return new PermissionGroup({ operator: 'OR', groups, marker });
  }

  static getQueryPermissionGroup({
    query,
    queryName,
    resolvedPermissions,
  }: {
    query: PermittedQuery;
    queryName?: string;
    resolvedPermissions?: QueryPermissionsParam;
  }): PermissionGroup | PermissionRecord {
    resolvedPermissions = resolvedPermissions ?? query.permissions;

    const queryData: PermissionRecordData = {
      queryId: query.id,
      queryName: queryName,
      queryEndpoint: query.queryName,
      queryFields: query.queryFields,
      queryPermissions: query.permissions,
    };

    if (!resolvedPermissions) {
      return new PermissionGroup({ operator: 'AND', groups: [] });
    }
    if (typeof resolvedPermissions === 'number') {
      return new PermissionRecord(resolvedPermissions, queryData);
    }
    if (resolvedPermissions.or) {
      const childGroups = resolvedPermissions.or.map((childPermissions) =>
        PermissionGroup.getQueryPermissionGroup({
          query: query,
          queryName,
          resolvedPermissions: childPermissions,
        })
      );
      return new PermissionGroup({ operator: 'OR', groups: childGroups });
    }
    if (resolvedPermissions.and) {
      const childGroups = resolvedPermissions.and.map((childPermissions) =>
        PermissionGroup.getQueryPermissionGroup({
          query: query,
          queryName,
          resolvedPermissions: childPermissions,
        })
      );
      return new PermissionGroup({ operator: 'AND', groups: childGroups });
    }
  }

  static extract(
    api: Record<string, PermittedQuery>,
    marker: PermissionGroupMarker
  ): PermissionGroup {
    const queries = Object.entries(api);
    const queryPermissionGroups = queries.map(([key, q]) =>
      PermissionGroup.getQueryPermissionGroup({ query: q, queryName: key })
    );
    return PermissionGroup.and({ groups: queryPermissionGroups, marker });
  }
}
