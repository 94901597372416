import { ReduxAction } from '@models/redux';
import { SetCoinInfoPayload, TransferCoinTabActionsTypes as T } from './model';

class FinishCoinTransactionAction extends ReduxAction<T.FINISH_COIN_TRANSACTION, void> {
  public readonly type: T.FINISH_COIN_TRANSACTION = T.FINISH_COIN_TRANSACTION;
  public constructor(public payload: void) {
    super(payload);
  }
}

class FinishCoinTransactionFailAction extends ReduxAction<T.FINISH_COIN_TRANSACTION_FAIL, Error> {
  public readonly type: T.FINISH_COIN_TRANSACTION_FAIL = T.FINISH_COIN_TRANSACTION_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class FinishCoinTransactionSuccessAction extends ReduxAction<
  T.FINISH_COIN_TRANSACTION_SUCCESS,
  void
> {
  public readonly type: T.FINISH_COIN_TRANSACTION_SUCCESS = T.FINISH_COIN_TRANSACTION_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class SetCoinInfoAction extends ReduxAction<T.SET_COIN_INFO, SetCoinInfoPayload> {
  public readonly type: T.SET_COIN_INFO = T.SET_COIN_INFO;
  public constructor(public payload: SetCoinInfoPayload) {
    super(payload);
  }
}

class GetCoinInfoAction extends ReduxAction<T.GET_COIN_INFO, void> {
  public readonly type: T.GET_COIN_INFO = T.GET_COIN_INFO;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetCoinInfoFailAction extends ReduxAction<T.GET_COIN_INFO_FAIL, void> {
  public readonly type: T.GET_COIN_INFO_FAIL = T.GET_COIN_INFO_FAIL;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetCoinInfoSuccessAction extends ReduxAction<T.GET_COIN_INFO_SUCCESS, void> {
  public readonly type: T.GET_COIN_INFO_SUCCESS = T.GET_COIN_INFO_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class UpdateFormValuesAction extends ReduxAction<T.UPDATE_FORM_VALUES, void> {
  public readonly type: T.UPDATE_FORM_VALUES = T.UPDATE_FORM_VALUES;
  public constructor(public payload: void) {
    super(payload);
  }
}

class UpdateFormValuesFailAction extends ReduxAction<T.UPDATE_FORM_VALUES_FAIL, Error> {
  public readonly type: T.UPDATE_FORM_VALUES_FAIL = T.UPDATE_FORM_VALUES_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class UpdateFormValuesSuccessAction extends ReduxAction<T.UPDATE_FORM_VALUES_SUCCESS, void> {
  public readonly type: T.UPDATE_FORM_VALUES_SUCCESS = T.UPDATE_FORM_VALUES_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const transferCoinTabServiceActions = {
  finishCoinTransaction: () => new FinishCoinTransactionAction().get(),
  finishCoinTransactionFail: (error: Error) => new FinishCoinTransactionFailAction(error).get(),
  finishCoinTransactionSuccess: () => new FinishCoinTransactionSuccessAction().get(),
  setCoinInfo: (info: SetCoinInfoPayload) => new SetCoinInfoAction(info).get(),
  getCoinInfo: () => new GetCoinInfoAction().get(),
  getCoinInfoFail: () => new GetCoinInfoFailAction().get(),
  getCoinInfoSuccess: () => new GetCoinInfoSuccessAction().get(),
  updateFormValues: () => new UpdateFormValuesAction().get(),
  updateFormValuesFail: (error: Error) => new UpdateFormValuesFailAction(error).get(),
  updateFormValuesSuccess: () => new UpdateFormValuesSuccessAction().get(),
};

export type TransferCoinTabServiceActionsTypes =
  | FinishCoinTransactionAction
  | FinishCoinTransactionFailAction
  | FinishCoinTransactionSuccessAction
  | SetCoinInfoAction
  | GetCoinInfoAction
  | GetCoinInfoFailAction
  | GetCoinInfoSuccessAction
  | UpdateFormValuesAction
  | UpdateFormValuesFailAction
  | UpdateFormValuesSuccessAction;
