import { SessionStatus as Session } from '@services/session';
import {
  BootstrapActions,
  BootstrapActionsTypes as BootstrapTypes,
  BootstrapState,
  bootstrapStateInitial,
} from './model';

export const bootstrapReducer = (
  state: BootstrapState = bootstrapStateInitial,
  action: BootstrapActions
): BootstrapState => {
  switch (action.type) {
    case BootstrapTypes.SET_AUTHORIZED_INFO: {
      const { accessToken, refreshToken, role, sessionStatus, userId } = action.payload;
      return {
        ...state,
        isUserAuthorized: !!(
          accessToken &&
          refreshToken &&
          role &&
          userId &&
          (sessionStatus === Session.changed || sessionStatus === Session.good)
        ),
      };
    }

    default: {
      return state;
    }
  }
};
