import { useDispatch, useSelector } from 'react-redux';
import { configSelectors } from '@config/core';
import { showModalAction, closeModalAction } from '@modules/modal';
import { OfferByAll, OfferByMe } from '../../shared/types';
import { CancelOfferModal, CancelOfferModalProps } from '../../modals/cancel-offer';
import { EditOfferModal, EditOfferModalProps } from '../../modals/edit-offer';

export { permissionGroup as cancelOfferPermissionGroup } from '../../modals/cancel-offer';
export { permissionGroup as editOfferPermissionGroup } from '../../modals/edit-offer';

export type ModelParams = {
  onOfferCancelled: CancelOfferModalProps['onOfferCancelled'];
  onOfferUpdate: EditOfferModalProps['onOfferUpdate'];
};

export const useStatusActionsModel = (params: ModelParams) => {
  const dispatch = useDispatch();
  const currentUserId = useSelector(configSelectors.userId);

  const showCancelOfferModal = (offer: OfferByAll | OfferByMe) =>
    dispatch(
      showModalAction({
        modal: CancelOfferModal,
        props: {
          offerId: offer.id,
          onClose: () => dispatch(closeModalAction(CancelOfferModal)),
          onOfferCancelled: params.onOfferCancelled,
        },
      })
    );

  const showEditOfferModal = (offer: OfferByAll | OfferByMe) =>
    dispatch(
      showModalAction({
        modal: EditOfferModal,
        props: {
          activePrices: offer.activePrices,
          offerId: offer.id,
          onClose: () => dispatch(closeModalAction(EditOfferModal)),
          onOfferUpdate: params.onOfferUpdate,
        },
      })
    );

  return {
    currentUserId,
    showCancelOfferModal,
    showEditOfferModal,
  };
};
