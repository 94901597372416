import React, { FC, useEffect } from 'react';
import { Redirect } from 'react-router';

import { session } from '@services/session';

export const CheckAuthPage: FC<{}> = () => {
  useEffect(() => {
    document.body.classList.add('tm2-light-theme'); // this page has no theme

    return () => {
      document.body.classList.remove('tm2-light-theme');
    };
  }, []); // eslint-disable-line

  const redirectTo: string = session.latestVisitedRoute || '/';

  return <Redirect to={redirectTo} />;
};
