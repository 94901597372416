import { useState } from 'react';

import { handleBackendError } from '@modules/notify';
import { useCurrentRole } from '@config/hooks';
import { Role } from '@permissions/core';

import api from './api';
import { Withdrawal } from '../api';

export enum HandleWithdrawalType {
  onCancel = 'onCancel',
  onDeny = 'onDeny',
  onApprove = 'onApprove',
  onProcessed = 'onProcessed',
  onMarkAsChecked = 'onMarkAsChecked',
}

type OnReplaceWithdrawal = (withdrawal: Withdrawal | undefined) => void;
export const useModel = (onReplaceWithdrawal: OnReplaceWithdrawal) => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const currentRole = useCurrentRole();

  const isOpsClerk = currentRole === Role.opsClerk;

  const handleWithdrawal = async (w: Withdrawal, handleType: HandleWithdrawalType) => {
    setLockUI(true);
    try {
      let withdrawal;
      switch (handleType) {
        case HandleWithdrawalType.onCancel: {
          withdrawal = await api.cancelWithdrawal(w.id);
          break;
        }
        case HandleWithdrawalType.onDeny: {
          withdrawal = await api.denyWithdrawal(w.id);
          break;
        }
        case HandleWithdrawalType.onApprove: {
          withdrawal = await api.approveWithdrawal(w.id);
          break;
        }
        case HandleWithdrawalType.onProcessed: {
          withdrawal = await api.processWithdrawal(w.id);
          break;
        }
        case HandleWithdrawalType.onMarkAsChecked: {
          withdrawal = await api.markWithdrawalAsChecked(w.id);
          break;
        }
        default: {
          break;
        }
      }
      onReplaceWithdrawal(withdrawal);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return {
    isOpsClerk,
    handleWithdrawal,
    lockUI,
  };
};
