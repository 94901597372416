import React, { FC, memo, ReactNode, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Select, SelectItem } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { actions, selectors } from '../store';

export const NationalitySelect: FC<{
  className?: string;
  disabled?: boolean;
  label: ReactNode;
  name: string;
  required?: boolean;
  uat?: string;
}> = memo(({ className, disabled, label, name, required, uat, ...props }) => {
  const getNationalities = useCallDispatch(actions.getNationalities);
  const nationalities = useSelector(selectors.nationalities);
  const lockUI = useSelector(selectors.isLoading);

  useEffect(() => {
    getNationalities();
  }, []); // eslint-disable-line

  const data: Array<SelectItem> = useMemo(
    () =>
      nationalities.map((country) => ({
        label: country.label,
        value: country.id,
      })),
    [nationalities]
  );

  return (
    <Select
      className={className}
      name={name}
      label={label}
      disabled={disabled || lockUI}
      required={required}
      data={data}
      uat={uat}
      {...props}
    />
  );
});
