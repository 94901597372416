import React, { memo } from 'react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';

import { Divider } from '@components/divider';
import { Title } from '@components/typography/title';
import { ButtonPrimary } from '@components/button';
import { PermissionGroup } from '@permissions/permission-group';

import s from './index.module.scss';
import { useModel } from './model';
import { permissionGroup as apiPermissionsGroup } from './api';
import { permissionGroup as createUMSAssetModalPermissionGroup } from './create-ums-asset-modal';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup, createUMSAssetModalPermissionGroup],
  marker: 'layout:ums-asset-row',
});

type Props = {
  isEditMode: boolean;
};
export const UMSAssetRow = memo<Props>((p) => {
  const m = useModel();

  return !p.isEditMode ? null : (
    <>
      <div className={classnames(s.assetRow, 'mb4')}>
        <Title level={4}>
          <Trans id="create_or_edit.tech_info.ums_asset">UMS asset</Trans>
        </Title>
        {!m.umsAssetCode ? (
          <ButtonPrimary onClick={m.showApproveRedeemModal}>
            <Trans id="create_or_edit.tech_info.create_asset_ums">Create asset in UMS</Trans>
          </ButtonPrimary>
        ) : (
          <Title level={4}>{m.umsAssetCode}</Title>
        )}
      </div>
      <Divider className="mb4" />
    </>
  );
});
