import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { PageContent, PageTitle } from '@components/page';
import { configSelectors } from '@config/store/selectors';
import { I18n } from '@lingui/core';
import { DispatchFn, RootState } from '@models/redux';
import { marketingCompaniesUserActions } from './store/user.actions';
import { marketingCompaniesSelectors } from './store/selectors';
import { ColumnSortDirection, OnTableScrolled, OnTableSorted, SortItem } from '@models/core';
import { MarketingTableColumnIds as ColumnIds } from './store/model';
import { MarketingCompaniesItem } from './api';
import { defineMessage, t, Trans } from '@lingui/macro';
import { PageTable, TableHeaderSortType as SortType } from 'components/page-table';
import { Link } from '@components/link';
import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as marketingDetailPermissionGroup } from './marketing-detail';
import { PermissionGroup } from '@permissions/core';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, marketingDetailPermissionGroup],
  marker: 'layout:marketing',
});

const mapStateToProps = (root: RootState) => ({
  i18n: configSelectors.i18n(root),
  columnSortDirection: marketingCompaniesSelectors.columnSortDirection(root),
  isHasMore: marketingCompaniesSelectors.isHasMore(root),
  lockUI: marketingCompaniesSelectors.lockUI(root),
  marketingCompanies: marketingCompaniesSelectors.marketingCompanies(root),
});

const mapDispatchToProps = (dispatch) => ({
  onTableScrolled: () => dispatch(marketingCompaniesUserActions.onTableScrolled()),
  onTableSorted: (columnInfo: SortItem<ColumnIds>) =>
    dispatch(marketingCompaniesUserActions.onTableSorted(columnInfo)),
  onPageLeft: () => dispatch(marketingCompaniesUserActions.onPageLeft()),
});

const ConnectedMarketingPage: FC<{
  i18n: I18n;
  columnSortDirection: ColumnSortDirection<ColumnIds>;
  isHasMore: boolean;
  lockUI: boolean;
  marketingCompanies: Array<MarketingCompaniesItem>;
  onTableScrolled: OnTableScrolled;
  onTableSorted: OnTableSorted<ColumnIds>;
  onPageLeft: DispatchFn<void>;
}> = ({
  i18n,
  columnSortDirection,
  isHasMore,
  lockUI,
  marketingCompanies,
  onTableScrolled,
  onTableSorted,
  onPageLeft,
}) => {
  useEffect(() => {
    return () => onPageLeft();
  }, []); // eslint-disable-line

  const columns = [
    {
      head: {
        body: <Trans id="trading_platform.marketing.company_id">Company ID</Trans>,
        onSort: (direction: SortType) => onTableSorted({ id: ColumnIds.id, direction: direction }),
        sortDirection: columnSortDirection(ColumnIds.id),
      },
      className: 'align-right',
      cell: (company: MarketingCompaniesItem) => company?.id,
    },
    {
      head: {
        body: <Trans id="trading_platform.marketing.company_name">Company name</Trans>,
        onSort: (direction: SortType) =>
          onTableSorted({ id: ColumnIds.name, direction: direction }),
        sortDirection: columnSortDirection(ColumnIds.name),
      },
      cell: (company: MarketingCompaniesItem) => (
        <Link to={`/marketing/${company.id}`}>
          <span>{company.name}</span>
        </Link>
      ),
    },
  ];

  return (
    <PageContent uat="trading-platform-marketing-page">
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.marketing',
            message: 'Marketing',
          })
        )}
      />

      <PageTable
        onLoadMore={onTableScrolled}
        isLoadingInProgress={lockUI}
        isShowLoader={true}
        initialLoad={true}
        columns={columns}
        pageData={{
          data: marketingCompanies,
          hasMore: isHasMore,
        }}
      />
    </PageContent>
  );
};

export const MarketingPage = connect(mapStateToProps, mapDispatchToProps)(ConnectedMarketingPage);
