import { SessionStatus } from '@services/session';
import { Role } from '@permissions/core';
import { BootstrapServiceActionsTypes } from './service.acions';
import { BootstrapUserActionsTypes } from './user.acions';

export interface AuthorizedInfoPayload {
  accessToken: string;
  refreshToken: string;
  role: Role;
  sessionStatus: SessionStatus;
  userId: number;
}

export interface BootstrapState {
  isUserAuthorized: boolean;
}

export const bootstrapStateInitial: BootstrapState = {
  isUserAuthorized: false,
};

export enum BootstrapActionsTypes {
  CHECK_LAST_SESSION_STATUS = 'APP_BOOTSTRAP.CHECK_LAST_SESSION_STATUS',
  CONFIGURE_NEW_KEYCLOAK_SESSION = 'APP_BOOTSTRAP.CONFIGURE_NEW_KEYCLOAK_SESSION',
  INITIATE_APP = 'APP_BOOTSTRAP.INITIATE_APP',
  ON_APP_OPENED = 'APP_BOOTSTRAP.ON_APP_OPENED',
  SET_AUTHORIZED_INFO = 'APP_BOOTSTRAP.SET_AUTHORIZED_INFO',
}

export type BootstrapActions = BootstrapServiceActionsTypes | BootstrapUserActionsTypes;
