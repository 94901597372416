import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { Image } from '../image';
import { TextField } from '../../../components/form';
import { useDownloadFileModel } from './model';
import { Icon } from 'components/icon';
import { maskCfgDark } from '@constants/theme';
import { ButtonIconPure } from '@components/button';
import { ReactComponent as UploadIcon } from '@images/icons/upload-icon.svg';
import { ReactComponent as DeleteIcon } from '@images/icons/delete-icon.svg';
import { UploadedFile } from '@models/core';
import { Link } from '@components/link';

export const DownloadFileWrapper = (props) => {
  return <div className={classnames('upload-previews', props?.className)}>{props.children}</div>;
};

export const DownloadFile: FC<{
  previewHeight?: string;
  formName?: string;
  fieldName?: string;
  uploadFile: UploadedFile;
  isCanRemove?: boolean;
  isVMFile?: boolean;
  isMiniatureOfFile?: boolean;
  notImage?: boolean;
}> = ({
  previewHeight,
  formName,
  fieldName,
  uploadFile,
  isCanRemove = false,
  isVMFile,
  isMiniatureOfFile,
  notImage = false,
}) => {
  const { onDownloadFile, onRemoveFile } = useDownloadFileModel();
  const [isImgError, setImgError] = useState(false);

  return (
    <>
      {isMiniatureOfFile ? (
        <div className="upload-previews__item">
          <div className="preview-item-default">
            <Icon icon={'doc-blue'} mask={maskCfgDark} />
            <div className="preview-item-title">{uploadFile.name}</div>
          </div>
          {isCanRemove && (
            <ButtonIconPure
              className="ml-xss"
              icon={<DeleteIcon className="tm2-icon-stroke" />}
              onClick={() => onRemoveFile(formName, fieldName)}
            ></ButtonIconPure>
          )}
          <TextField name="uploadedFileTitle" label="title" />
        </div>
      ) : (
        <div className={`upload-previews__item ${isVMFile && 'download-vm-s1'}`}>
          {isVMFile ? (
            <Link onClick={() => onDownloadFile(uploadFile)}>{uploadFile.name}</Link>
          ) : !isImgError && uploadFile.contentType.startsWith('image') && notImage === false ? (
            // TODO-2833: fix types
            // @ts-ignore
            <Image
              uploadFile={uploadFile}
              height={previewHeight}
              onError={() => setImgError(true)}
            />
          ) : (
            <ButtonIconPure
              onClick={() => onDownloadFile(uploadFile)}
              icon={<UploadIcon className="tm2-icon-stroke" />}
            ></ButtonIconPure>
          )}
          {!isCanRemove ? null : (
            <ButtonIconPure
              className="ml-xss"
              icon={<DeleteIcon className="tm2-icon-stroke" />}
              onClick={() => onRemoveFile(formName, fieldName)}
            ></ButtonIconPure>
          )}
        </div>
      )}
    </>
  );
};

export default DownloadFile;
