import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { TariffCodes } from '@config/store/model';

// ----------------------------------------------------------------
interface Tariff {
  active: boolean;
  code: TariffCodes;
  defaultDuration: number;
  id: number;
  name: string;
}
export interface UserTariff {
  availableTariffs: Array<Tariff>;
  current: {
    endDate: string;
    id: number;
  };
}
interface UserTariffResponse {
  role: { activeTariffs: Array<Tariff> };
  userTariff: {
    endDate: string;
    tariff: { id: number };
  };
}
const getUserTariffFields = [
  { role: [{ activeTariffs: ['active', 'code', 'defaultDuration', 'id', 'name'] }] },
  { userTariff: ['endDate', { tariff: ['id'] }] },
];
const getUserTariffQuery = 'userProfile';
const getUserTariff = makeQuery({
  queryName: getUserTariffQuery,
  queryFields: getUserTariffFields,
  query: (userId: number): Promise<UserTariff> => {
    return stompClientService
      .getData(
        getUserTariffQuery,
        sign(getUserTariffQuery, getUserTariffFields),
        v.long({ userId })
      )
      .then((response: UserTariffResponse) => ({
        availableTariffs: response.role.activeTariffs.filter((tariff) => tariff.active).reverse(),
        current: !response.userTariff
          ? undefined
          : {
              endDate: response.userTariff.endDate,
              id: response.userTariff.tariff.id,
            },
      }));
  },
});
// ----------------------------------------------------------------
export enum UpdateTariffFields {
  endDate = 'endDate',
  tariffId = 'tariffId',
  userId = 'userId',
}
export interface UpdateTariffForm {
  [UpdateTariffFields.endDate]: string;
  [UpdateTariffFields.tariffId]: number;
  [UpdateTariffFields.userId]?: number;
}
const updateTariffFields = ['id'];
const updateTariffQuery = 'assignTariffToUser';
const updateTariff = makeQuery({
  queryName: updateTariffQuery,
  queryFields: updateTariffFields,
  query: (form: UpdateTariffForm): Promise<void> => {
    return stompClientService.sendData(
      updateTariffQuery,
      sign(updateTariffQuery, updateTariffFields),
      {
        ...v.localDate({ endDate: form.endDate }),
        ...v.long({ tariffId: form.tariffId }),
        ...v.long({ userId: form.userId }),
      }
    );
  },
});
// ----------------------------------------------------------------
const api = {
  getUserTariff,
  updateTariff,
};

export const permissionGroup = PermissionGroup.extract(
  api,
  'api:profile-other-page-edit-tariff-modal'
);

export default api;
