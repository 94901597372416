import { showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { AddBankAccountModal } from '../../modals/add-bank-account';
import { useBankAccounts } from './hooks';

export const useModel = () => {
  const showModal = useCallDispatch(showModalAction);
  const { isBankAccountsLoading, bankAccounts, refreshBankAccounts } = useBankAccounts();

  const onAddBankAccountClicked = () =>
    showModal({
      modal: AddBankAccountModal,
      props: { onAccountCreated: refreshBankAccounts },
    });

  return {
    bankAccounts,
    isBankAccountsLoading,
    onAddBankAccountClicked,
  };
};
