import { Link as RouterLink } from 'react-router-dom';
import classnames from 'classnames';
import s from './index.module.scss';

import type { MouseEventHandler, ReactNode } from 'react';

export type LinkProps = {
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  target?: '_blank';
  to?: string;
  uat?: string;
};

export type LinkTextProps = {
  children: ReactNode;
  className?: string;
};

// Note: LinkText and Link expected to use the same styles for text proportions.
// Use link text when you need link-proportioned text.

export function LinkText({ children, className }: LinkTextProps) {
  return <span className={classnames(className, s.linkText)}>{children}</span>;
}

export function Link({
  children,
  className,
  disabled,
  onClick: onClickProp,
  target,
  to,
  uat,
}: LinkProps) {
  const classes = classnames(className, s.linkText, s.linkDecoration, {
    [s.linkDisabled]: disabled,
  });

  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    if (!disabled) {
      onClickProp?.(e);
    }
  };

  const anchorProps = {
    className: classes,
    ['data-uat']: uat,
    href: to ?? '',
    onClick,
    target,
  };

  if (target) {
    return <a {...anchorProps}>{children}</a>;
  }

  return (
    <RouterLink to={to ?? ''} {...anchorProps}>
      {children}
    </RouterLink>
  );
}
