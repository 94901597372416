import React, { FC, memo } from 'react';

import { ButtonAdd } from '@components/button/add';
import { permissionsGroup as transactionModalPermissionsGroup } from '../../modals/create-transaction';
import { useModel } from './model';

export const permissionsGroup = transactionModalPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-create-transaction-button',
});

type Props = {
  className?: string;
};
export const CreateTransaction: FC<Props> = memo<Props>((p) => {
  const m = useModel();

  return (
    <ButtonAdd
      className={p.className}
      onClick={m.onCreateTransactionClicked}
      disabled={m.disabled}
      compact="all"
    />
  );
});
