import { FinishRedeemModalActionsTypes as Types } from './model';

const CreateRedeemAction = (coinId) => ({
  payload: coinId,
  type: Types.CREATE_REDEEM,
});

const CreateRedeemFailAction = (error) => ({
  payload: error,
  type: Types.CREATE_REDEEM_FAIL,
});

const CreateRedeemSuccessAction = () => ({
  payload: null,
  type: Types.CREATE_REDEEM_SUCCESS,
});

const DownloadTransactionAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION,
});

const DownloadTransactionFailAction = (error) => ({
  payload: error,
  type: Types.DOWNLOAD_TRANSACTION_FAIL,
});

const DownloadTransactionSuccessAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION_SUCCESS,
});

export const finishRedeemModalServiceActions = {
  createRedeem: CreateRedeemAction,
  createRedeemFail: CreateRedeemFailAction,
  createRedeemSuccess: CreateRedeemSuccessAction,
  downloadTransaction: DownloadTransactionAction,
  downloadTransactionFail: DownloadTransactionFailAction,
  downloadTransactionSuccess: DownloadTransactionSuccessAction,
};
