import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import './styles.scss';

export const ModalContent: FC<{
  children: ReactNode;
  className?: string;
  uat?: string;
}> = ({ children, className, uat }) => {
  return (
    <div className={classnames('tm2-modal-content', className)} data-uat={uat}>
      {children}
    </div>
  );
};
