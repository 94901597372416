import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Deposit } from '../api';
import React, { memo } from 'react';
import { Trans } from '@lingui/macro';
import { Text } from '@components/typography/text';
import { ButtonPrimary } from '@components/button';
import { useModel } from './model';

export type Props = {
  deposit: Deposit;
  replaceDeposit: (deposit: Deposit) => void;
};
export const CancelDepositModal: MFC<Props> = memo(({ isVisible, deposit, replaceDeposit }) => {
  const m = useModel(deposit, replaceDeposit, CancelDepositModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(CancelDepositModal)}
    >
      <ModalTitle>
        <Trans id="deposits.cancel_deposit.title">Cancel invoice?</Trans>
      </ModalTitle>

      <ModalContent>
        <Text level={2}>
          <Trans id="deposits.cancel_deposit.text">
            Do you really want to cancel selected invoice?
          </Trans>
        </Text>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} disabled={m.lockUI} onClick={m.onCancelDeposit}>
          <Trans id="button.confirm">Confirm</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
});
