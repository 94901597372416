import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './actions.module.scss';
import { ButtonDownload } from '@components/button/download';
import { Box } from '@components/box';
import { Form, InputDateRange, InputNumberRange, Select } from '@components/form';
import { HasAccess, Permission as P, Role as R } from '@permissions/core';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { TransactionsFiltersFormFields as Fields, useTransactionsPageModel } from './model';

export const TransactionsActionsRow: FC<{}> = () => {
  const { assets, lockUI, onFiltersChanged, showDownloadTransactionModal } =
    useTransactionsPageModel();

  return (
    <div className={s.row}>
      <HasAccess
        permissions={{ or: [P.TRANSACTIONS_DOWNLOAD] }}
        roles={[R.listingBroker, R.opsClerk, R.opsHead]}
      >
        <Box className={classnames(s.downloadCol, 'mb-sm')} compact={true}>
          <Tooltip
            placement="bottom"
            className="mxy-auto"
            trigger={TooltipTrigger.hover}
            title={
              <Trans id="transactions.filter.download_transactions">
                Download transactions list
              </Trans>
            }
          >
            <ButtonDownload compact="all" onClick={showDownloadTransactionModal} />
          </Tooltip>
        </Box>
      </HasAccess>

      <Box className={classnames(s.filtersCol, 'mb-sm')}>
        <Form className={s.form} name={'transactionsFilters'} onChange={onFiltersChanged}>
          <InputDateRange
            className={s.formItem}
            label={<Trans id="daterange.label">Date</Trans>}
            name={Fields.date}
            max={new Date().toISOString()}
            disabled={lockUI}
            compact={true}
          />

          <InputNumberRange
            className={s.formItem}
            label={<Trans id="transactions.filter.amount">Amount</Trans>}
            name={Fields.amount}
            min={0}
            disabled={lockUI}
            compact={true}
          />

          <Select
            className={s.formItem}
            label={<Trans id="transactions.filter.symbol">Symbol</Trans>}
            name={Fields.asset}
            data={assets}
            disabled={lockUI}
            compact={true}
            allowEmpty={true}
          />
        </Form>
      </Box>
    </div>
  );
};
