import React from 'react';
import { Trans } from '@lingui/macro';

import { Modal, MFC, ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { Form, CheckboxGroup, SubmitButton } from '@components/form';
import { BlockMetalModalProps, formId, useModel } from './model';

export const BlockMetalModal: MFC<BlockMetalModalProps> = ({ blockedMetals, isVisible, owner }) => {
  const m = useModel(BlockMetalModal, owner.id, blockedMetals);

  return (
    <Modal
      onClose={m.onCloseClicked}
      isVisible={isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      className="block-metal-modal"
    >
      <ModalTitle>
        <Trans id="block_asset_modal.title">Block {owner.displayName}</Trans>
      </ModalTitle>

      <ModalContent>
        <Form className="block-metal-modal-metals" name={formId} initialValues={m.formInitial}>
          <CheckboxGroup
            pattern={{ xs: 1, sm: 2 }}
            name="blockedMetals"
            data={owner.metals.map((o) => ({ label: o.asset, value: o.asset }))}
            label={
              <Trans id="block_asset_modal.text_v2">
                Please select the products you want to prohibit {owner.displayName} from trading
              </Trans>
            }
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.apply">Apply</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
