import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useVirtual } from 'react-virtual';
import { useResizeDetector } from 'react-resize-detector';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { useSyncRef } from '../utils';
import { LoadableList } from '../loadable-list';
import { RowWrapper } from './row';
import { Overlay } from './overlay';
import { DefaultError } from './default-error';
import { DefaultLoader } from './default-loader';
import { DefaultNoData } from './default-no-data';
import { VirtualPadding } from './virtual-padding';
import { useSmoothScroll } from './use-smooth-scroll';
import s from './index.module.scss';

import type { UIEvent, ComponentType, FunctionComponent, MutableRefObject } from 'react';
import type { BaseItem } from './types';
import type { RowProps } from './row';
import type {
  ListProps as LoadableListProps,
  ListRendererParams as LoadableListRendererParams,
} from '../loadable-list';

// NOTE: estimateRowHeight and keyExtractor should be persistent since they
// trigger rerender.

// ***

// Since we call notifyRender on items' container height change,
// we can get notifyRender during element render, if virtualization
// expected element height is different from the actual rendered
// element height (element will change its height, which, in turn, will
// change container's height). It isn't considered a problem since
// performance impact is relatively low. If we want to opt out while
// preserving backward compatibility, we need to add a prop with the
// default corresponding to current behavior and pass down notifyRender.

// ***

// v2.8.2

// We cannot just pass row data as something like data[virtualRow.index],
// since if several rows update on data (calling measureRef prop) and item
// count between data updates is the same, a problem arises. When call
// measureRef, it will use the current key for the row, which may correspond
// to the previous row data (before data update). This leads to incorrect
// measurement reconstructing (since we can get wrong values for some
// existing measureCache keys) and can lead to a bug.

// Links to responsible code:
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L197
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L92

// To solve this problem we update keyExtractor param. Since it is used as a
// dep to measurements list which is, in turn, used to create virtual items
// list, updating keyExtractor will result in virtual items list reconstruction.
// It means that the list of virtual items will `by index` correspond to the
// list of data. The important note is keyExtractor updates preserve
// internal measureCache. We do not rely on estimateSize, since it drops
// measureCache.

// NOTE: The above approach also yields simple scrollToIndex implementation.

// Key extractor usage as a dep:
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L101

export type { OnLoadMore } from '../loadable-list';

export type { BaseItem } from './types';

export type { RowProps } from './row';

export type ColumnWidthList = number[];

export type OnScrollbarWidthChange = (width: number) => void;

export type EstimateRowHeight<T extends BaseItem> = (data: T) => number;

export type KeyExtractor<T extends BaseItem> = (data: T) => string | number;

export type ListKey = string | number;

export type OnScroll = (e: UIEvent) => void;

export type ScrollToIndexRef = MutableRefObject<(index: number) => void>;

export type ScrollOffsetRef = MutableRefObject<{ left: number }>;

export type ErrorElement = JSX.Element | null;

export type LoaderElement = JSX.Element | null;

export type NoDataElement = JSX.Element | null;

export type OverlayElement = JSX.Element | null;

export type LoadableVirtualListProps<T extends BaseItem, K> = {
  data: T[];
  columnWidthList: ColumnWidthList;
  containerWidth: number;
  estimateRowHeight: EstimateRowHeight<T>;
  keyExtractor: KeyExtractor<T>;
  listKey?: ListKey;
  onScrollbarWidthChange?: OnScrollbarWidthChange;
  onScroll?: OnScroll;
  overscan?: number;
  rowComponent: ComponentType<RowProps<T>>;
  scrollbarWidth?: number;
  scrollOffsetRef: ScrollOffsetRef;
  scrollDebounce?: number;
  scrollToIndexRef?: ScrollToIndexRef;
  updateMeasureOnData?: boolean;
  errorElement?: ErrorElement;
  loaderElement?: LoaderElement;
  noDataElement?: NoDataElement;
  overlayElement?: OverlayElement;
  className?: string;
} & Pick<LoadableListProps<K>, 'onLoadMore' | 'hasMore'>;

const DEFAULT_OVERSCAN = 16;

const defaultErrorElement = <DefaultError />;

const defaultLoaderElement = <DefaultLoader />;

const defaultNoDataElement = <DefaultNoData />;

export const LoadableVirtualList = <T extends BaseItem, K>(
  props: LoadableVirtualListProps<T, K>
) => {
  const scrollbarWidthRef = useSyncRef(props.scrollbarWidth ?? 0);
  const defaultScrollToIndexRef = useRef<(value: number) => void>(() => {});

  const VirtualListMemoized = useCallbackOne<
    FunctionComponent<
      LoadableListRendererParams & {
        columnWidthList: ColumnWidthList;
        data: T[];
        containerWidth: number;
        estimateRowHeight: EstimateRowHeight<T>;
        keyExtractor: KeyExtractor<T>;
        overscan?: number;
        updateMeasureOnData?: boolean;
        rowComponent: ComponentType<RowProps<T>>;
        scrollOffsetRef: ScrollOffsetRef;
        scrollDebounce: number;
        scrollToIndexRef: ScrollToIndexRef;
        errorElement: ErrorElement;
        loaderElement: LoaderElement;
        noDataElement: NoDataElement;
        overlayElement: OverlayElement;
        className?: string;
      }
    >
  >(function VirtualList({
    columnWidthList,
    containerWidth,
    data,
    estimateRowHeight,
    keyExtractor: keyExtractorProp,
    error,
    hasMore,
    loading,
    notifyRender,
    overscan,
    setScrollableRef,
    updateMeasureOnData,
    rowComponent,
    scrollOffsetRef,
    scrollDebounce,
    scrollToIndexRef,
    errorElement,
    loaderElement,
    noDataElement,
    overlayElement,
    className,
  }) {
    const dataRef = useSyncRef(data);
    const outerRef = useRef<HTMLElement | null>(null);
    const centeredContentIndentRef = useRef<HTMLDivElement | null>(null);

    const totalColumnWidth = useMemoOne(() => {
      return columnWidthList.reduce((acc, w) => acc + w, 0);
    }, [columnWidthList]);

    const estimateSize = useCallbackOne(
      (index: number) => {
        return estimateRowHeight(dataRef.current[index]);
      },
      [estimateRowHeight]
    );

    // Usage of data is intentional: see comments for details.
    const keyExtractor = useCallbackOne(
      (index: number) => {
        return keyExtractorProp(data[index]);
      },
      [keyExtractorProp, data]
    );

    const setOuterRef = useCallbackOne(
      (el: HTMLDivElement) => {
        outerRef.current = el;

        if (!outerRef.current) return;

        const originalAddEventListener = el.addEventListener;
        outerRef.current.addEventListener = function addEventListener(
          event: string,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          handler: (...args: any[]) => void,
          options: AddEventListenerOptions & { ignoreDebounce: boolean }
        ) {
          let debouncedHandler = handler;
          if (event === 'scroll' && scrollDebounce > 0 && !options.ignoreDebounce) {
            debouncedHandler = debounce(handler, scrollDebounce, {
              leading: false,
              trailing: true,
            });
          }
          originalAddEventListener.call(this, event, debouncedHandler, options);
        };
        setScrollableRef(el);
      },
      [setScrollableRef]
    );

    const updateCenteredContentIndent = useCallbackOne(
      debounce((offset: number) => {
        if (centeredContentIndentRef.current) {
          centeredContentIndentRef.current.style.width = offset + 'px';
        }
      }, 100),
      []
    );

    const onOuterScroll = useCallbackOne(
      (e: UIEvent<HTMLDivElement>) => {
        props?.onScroll?.(e);
        updateCenteredContentIndent(e.currentTarget.scrollLeft);
      },
      [updateCenteredContentIndent, props?.onScroll]
    );

    const [bottomPadding, setBottomPadding] = useState(0);

    const updateScrollbarWidth = useCallbackOne(() => {
      if (!outerRef.current) return;

      const scrollbarWidth = outerRef.current.offsetWidth - outerRef.current.clientWidth;

      if (scrollbarWidthRef.current !== scrollbarWidth) {
        props.onScrollbarWidthChange?.(scrollbarWidth);
      }
    }, []);

    const { height: innerHeight, ref: innerRef } = useResizeDetector({
      handleWidth: false,
      refreshMode: 'debounce',
      refreshOptions: {
        leading: false,
        trailing: true,
      },
      refreshRate: 100,
    });

    // Use instant (not debounced) resize observer to avoid case when
    // shrinking of the content is too slow and yields horizontal scrollbar
    // for a moment after vertical scrollbar appearance.
    useResizeDetector({
      skipOnMount: true,
      handleWidth: false,
      onResize: updateScrollbarWidth,
      targetRef: innerRef,
    });

    const scrollTo = useSmoothScroll(outerRef);

    const rowVirtualizer = useVirtual({
      estimateSize,
      keyExtractor,
      overscan: overscan ?? DEFAULT_OVERSCAN,
      paddingEnd: bottomPadding,
      parentRef: outerRef,
      scrollToFn: scrollTo,
      size: data.length,
    });

    scrollToIndexRef.current = (index: number) => {
      rowVirtualizer.scrollToIndex(index);
    };

    useEffect(() => {
      if (innerHeight !== undefined) {
        notifyRender();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [innerHeight]);

    // We can as well check height on the outer.
    useEffect(() => {
      window.addEventListener('resize', notifyRender);
      return () => {
        window.removeEventListener('resize', notifyRender);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hasItems = data.length > 0;
    useEffect(() => {
      if (!error && !hasMore && hasItems) {
        setBottomPadding(0);
      }
    }, [error, hasMore, hasItems]);

    // Update initial list position after changing listKey to
    // preserve visible offset.
    useLayoutEffect(() => {
      if (outerRef.current) {
        outerRef.current.scrollLeft = scrollOffsetRef.current.left;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const showLoader = hasMore && !error;
    const showError = error;
    const showNoData = !error && !hasMore && !data.length;
    const showOverlay = Boolean(overlayElement);

    return (
      <div
        ref={setOuterRef}
        className={classnames(s.scrollable, className)}
        style={{ overflow: 'auto', width: containerWidth, position: 'relative' }}
        onScroll={onOuterScroll}
      >
        {showOverlay ? <Overlay containerRef={outerRef}>{overlayElement}</Overlay> : null}
        <div
          ref={innerRef}
          style={{
            position: 'relative',
            height: rowVirtualizer.totalSize,
            width: totalColumnWidth,
          }}
        >
          {rowVirtualizer.virtualItems.map((virtualRow) => (
            <RowWrapper
              key={virtualRow.key}
              columnWidthList={columnWidthList}
              data={data[virtualRow.index]}
              estimatedHeight={estimateRowHeight(data[virtualRow.index])}
              loading={loading}
              measureRef={virtualRow.measureRef}
              rowComponent={rowComponent}
              updateMeasureOnData={updateMeasureOnData}
              // We don't pass height in style object, since it will be equal
              // to estimated size on first render. If a consumer wants to use
              // estimated height as the row height they should specify it
              // explicitly, if they want to use content size for measuring
              // height, they should provide it like so
              // <div ref={containerRef}>{content}</div>.
              style={{
                position: 'absolute',
                top: virtualRow.start,
              }}
            />
          ))}

          {showLoader ? (
            <VirtualPadding
              key={'loader'}
              className={s.centeredWrapper}
              heightSetter={setBottomPadding}
            >
              <div ref={centeredContentIndentRef} />
              <div className={s.centered} style={{ width: containerWidth }}>
                {loaderElement}
              </div>
            </VirtualPadding>
          ) : null}

          {showError ? (
            <VirtualPadding key={'error'} heightSetter={setBottomPadding}>
              {errorElement}
            </VirtualPadding>
          ) : null}

          {showNoData ? (
            <VirtualPadding
              key={'empty-data'}
              className={s.centeredWrapper}
              heightSetter={setBottomPadding}
            >
              <div ref={centeredContentIndentRef} />
              <div
                className={classnames(s.centered, 'pt4', 'pb4')}
                style={{ width: containerWidth }}
              >
                {noDataElement}
              </div>
            </VirtualPadding>
          ) : null}
        </div>
      </div>
    );
  },
  []);

  return (
    <LoadableList key={props.listKey} hasMore={props.hasMore} onLoadMore={props.onLoadMore}>
      {(params: LoadableListRendererParams) => (
        <VirtualListMemoized
          columnWidthList={props.columnWidthList}
          containerWidth={props.containerWidth}
          data={props.data}
          estimateRowHeight={props.estimateRowHeight}
          keyExtractor={props.keyExtractor}
          error={params.error}
          hasMore={params.hasMore}
          loading={params.loading}
          notifyRender={params.notifyRender}
          overscan={props.overscan}
          scrollDebounce={props.scrollDebounce ?? 0}
          setScrollableRef={params.setScrollableRef}
          updateMeasureOnData={props.updateMeasureOnData}
          rowComponent={props.rowComponent}
          scrollOffsetRef={props.scrollOffsetRef}
          scrollToIndexRef={props.scrollToIndexRef ?? defaultScrollToIndexRef}
          errorElement={props.errorElement ?? defaultErrorElement}
          loaderElement={props.loaderElement ?? defaultLoaderElement}
          noDataElement={props.noDataElement ?? defaultNoDataElement}
          overlayElement={props.overlayElement ?? null}
          className={props.className}
        />
      )}
    </LoadableList>
  );
};
