const redeemOptionsModalState = (root) => root.main?.wallet?.redeemModals?.redeemOptionsModal;

const lockUI = (root) => redeemOptionsModalState(root).isRedeemOptionsLoading;

const redeemOptions = (root) => redeemOptionsModalState(root).redeemOptions;

const selectedRedeemOption = (root) => redeemOptionsModalState(root).selectedRedeemOption;

const storedMetalPrice = (root) => redeemOptionsModalState(root).storedData.metalPrice;

const storedRedeemOption = (root) => redeemOptionsModalState(root).storedData.redeemOption;

export const redeemOptionsModalSelectors = {
  lockUI,
  redeemOptions,
  redeemOptionsModalState,
  selectedRedeemOption,
  storedMetalPrice,
  storedRedeemOption,
};
