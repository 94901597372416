import React, { FC, memo } from 'react';
import classnames from 'classnames';

import { useIndividualVerificationStatusTranslations } from '@services/translate/translations';
import { InfoButtonIcon } from '@components/icons';
import { KycState as KS } from '@config/core';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { accountService as account } from '@services/account';
import { VerificationStatus as S } from '@models/core';
import { UserTableItem } from '../../../users-api.service';

export const VerificationStatusCell: FC<{ user: UserTableItem }> = memo(({ user }) => {
  const isUserIndividualTrader: boolean = account.isIndividualTrader(user.permissions);
  const isUserTrader = account.isTrader(user.permissions);
  const individualStatuses = useIndividualVerificationStatusTranslations();

  if (!isUserTrader) {
    return <span>-</span>;
  }

  if (isUserIndividualTrader) {
    return (
      <span
        className={classnames({
          'color-red': user.verificationStatus === S.rejected,
          'color-green': user.verificationStatus === S.approved,
          'color-yellow': user.verificationStatus === S.resubmissionRequested,
        })}
      >
        {individualStatuses[user.verificationStatus]}
      </span>
    );
  }

  return (
    <span
      className={classnames({
        'color-red': user.kyc.state === KS.disapproved,
        'color-green': user.kyc.state === KS.approved,
      })}
    >
      <span>{user.kyc.description}</span>

      {user.kyc.state !== KS.disapproved && user.kyc.state !== KS.retry ? null : (
        <Tooltip
          placement="bottom"
          trigger={[TooltipTrigger.click]}
          title={
            <>
              <p>{user.kyc.date}</p>

              {!user.kyc?.rem ? null : <p>{user.kyc.rem}</p>}

              {!user.kyc?.reason?.length ? null : (
                <ul>
                  {user.kyc.reason.map((reason, i) => (
                    <li
                      key={`${reason.value}-${i}`}
                      className={classnames({ 'color-red': reason.rejectType === 'FINAL' })}
                    >
                      <span>{reason.description}</span>
                    </li>
                  ))}
                </ul>
              )}
            </>
          }
        >
          <InfoButtonIcon />
        </Tooltip>
      )}
    </span>
  );
});
