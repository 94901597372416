import sign from 'tm2sign.macro';
import moment from 'moment';

import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { SortItem, TableResponse } from '@models/core';
import { DATE_TIME_FORMAT } from '@constants/date';
import { TableHeaderSortType as SortType } from '../../../components/page-table';
import { privateApi } from '../../../utils/api';
import { TransactionsFiltersForm } from './actions-row/model';
import { TransactionsTableColumnIds as ColumnIds } from './model';

class TransactionsApiService {
  public downloadTransactions(startDate: string, endDate: string): Promise<string> {
    const downloadTransactionsFields = sign('transactionsAsXLSX', ['value']);

    const typedValues = {
      ...v.localDate({ startDate: startDate }),
      ...v.localDate({ endDate: endDate }),
    };

    return stompClient
      .getData('transactionsAsXLSX', downloadTransactionsFields, typedValues)
      .then((response) => response.value);
  }

  public getFilters(): Promise<TransactionFilters> {
    return privateApi.get('/transactions/filters').then((response) => response.data);
  }

  public getTransactions(
    page: number,
    filter: TransactionsFiltersForm,
    sort: Array<SortItem<ColumnIds>>
  ): Promise<TableResponse<TransactionItem>> {
    const ordering = sort
      .filter((item: SortItem<ColumnIds>) => item.direction !== SortType.notSet)
      .reduce(
        (config, item: SortItem<ColumnIds>) => ({
          ...config,
          [item.fields[0]]: item.direction.toLocaleUpperCase(),
        }),
        {}
      );

    const txFilter = {
      ...filter,
      amount: {
        from: filter?.amount && filter?.amount[0],
        to: filter?.amount && filter?.amount[1],
      },
      date: {
        from: filter?.date && filter?.date[0],
        to: filter?.date && filter?.date[1],
      },
    };

    const transactionsFields = sign('transactions', [
      'hasMore',
      'index',
      {
        data: [
          'date', // 'date'
          {
            computed: [
              'total',
              'calculatedTxType', // 'type'
              {
                senderUser: [
                  'id', // 'sender.id'
                  'displayName', // 'sender.fullName'
                ],
              },
              'senderSystemName', // 'sender.fullName'
              {
                recipientUser: [
                  'id', // 'recipient.id'
                  'displayName', // 'recipient.fullName'
                ],
              },
              'recipientSystemName', // 'recipient.fullName'
              'stable', // 'stable'
              'rate', // 'rate' или 'total'
              {
                offerPrice: [
                  {
                    currency: [
                      'code', // 'currencySymbol'
                    ],
                  },
                ],
              },
              { opTypeInfo: ['displayName'] },
            ],
          },
          'source', // 'sender.pubKey'
          'destination', // 'recipient.pubKey'
          'amount', // 'amount'
          'asset', // 'token'
          'id', // 'id'
          'result', // 'success'
        ],
      },
    ]);

    const typedValues = {
      ...v.int({ page }),
      ...v.txFilterInput({ txFilter }),
      ...v.spcOrderInput({ ordering }),
    };

    return stompClient.getData('transactions', transactionsFields, typedValues).then((response) => {
      const responseAdapter = response.data.map((t) => {
        return {
          date: moment(t?.date).format(DATE_TIME_FORMAT),
          type:
            t?.computed?.calculatedTxType[0] + t?.computed?.calculatedTxType.toLowerCase().slice(1),
          sender: {
            id: t?.computed?.senderUser?.id,
            fullName: t?.computed.senderUser
              ? t?.computed?.senderUser.displayName
              : t?.computed?.senderSystemName,
            pubKey: t?.source,
          },
          recipient: {
            id: t?.computed?.recipientUser?.id,
            fullName: t?.computed?.recipientUser
              ? t?.computed?.recipientUser?.displayName
              : t?.computed?.recipientSystemName,
            pubKey: t?.destination,
          },
          stable: t?.computed?.stable,
          success: t?.result,
          amount: t?.amount,
          token: t?.asset,
          rate: t?.computed?.rate,
          currencySymbol: t?.computed?.offerPrice?.currency?.code,
          total: t?.computed?.total,
          id: t?.id,
          opTypeDisplayName: t?.computed.opTypeInfo.displayName,
        };
      });
      return {
        hasMore: response.hasMore,
        index: response.index,
        data: responseAdapter,
      };
    });
  }
}

export const transactionsApi = new TransactionsApiService();

interface Sender {
  fullName: string;
  id: number;
  pubKey: string;
}

interface Recipient {
  fullName: string;
  id: number;
  pubKey: string;
}

export interface TransactionFilters {
  assets: Array<string>;
}

export enum TransactionType {
  buy = 'Buy',
  discharge = 'Discharge',
  emission = 'Emission',
  fee = 'Fee',
  redeem = 'Redeem',
  sell = 'Sell',
}

export interface TransactionItem {
  amount: number;
  currencySymbol: string;
  date: string;
  id: number;
  rate: number;
  recipient: Recipient;
  sender: Sender;
  stable: boolean;
  success: boolean;
  token: string;
  total: number;
  type: TransactionType;
  opTypeDisplayName: string;
}
