import React, { FC, memo, ReactNode } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { Divider } from '@components/divider';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { HasAccess, Permission } from '@permissions/core';

import { Profile } from '../../../api.shared';
import { LinkedInvestor } from './linked-investor';
import { LinkedIssuer } from './linked-issuer';
import { LinkedSecondaryMarketAccount } from './linked-sma';
import s from './index.module.scss';

type TabProps = {
  className: string;
  editProfileButton: ReactNode;
  profile: Profile;
};
export const AccountDetailsTab = memo<TabProps>((p) => {
  return (
    <div className={classnames(s.tab, p.className)} data-uat="account-details-tab">
      <div className={s.row}>
        <Row
          label={<Trans id="profile.account_details.holder">Account holder name</Trans>}
          value={p.profile.displayName}
          uat="name-label"
        />

        <div className="ml-auto">{p.editProfileButton}</div>
      </div>

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.phone">Phone number</Trans>}
        value={p.profile.phone}
        uat="phone-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.email">Email address</Trans>}
        value={p.profile.email}
        uat="email-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.country">Country</Trans>}
        value={p.profile.country?.label}
        uat="country-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.postal_code">Postal code</Trans>}
        value={p.profile.postalCode}
        uat="postal-code-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.city">City</Trans>}
        value={p.profile.city}
        uat="city-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.state_region">State / Region</Trans>}
        value={p.profile.state}
        uat="region-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={<Trans id="profile.account_details.street_number">Street and number</Trans>}
        value={p.profile.address}
        uat="address-label"
      />

      <Row
        className={classnames('mt2', s.item)}
        label={
          <Trans id="profile.account_details.suite_unit_building">Suite / Unit / Building</Trans>
        }
        value={p.profile.building}
        uat="building-label"
      />

      <Divider className={classnames(s.divider, 'mt3 mb-1')} />

      <HasAccess permissions={{ or: [Permission.PROFILE_VIEW_OTHER] }}>
        <Row
          className="mt2"
          label={<Trans id="profile.account_details.public_key">Public key</Trans>}
          value={p.profile.stablePublicKey}
          uat="public-key-label"
        />
      </HasAccess>

      <LinkedInvestor classname="mt2" profile={p.profile} />

      <LinkedIssuer classname="mt2" profile={p.profile} />

      <LinkedSecondaryMarketAccount classname="mt2" profile={p.profile} />
    </div>
  );
});

const Row: FC<{
  className?: string;
  label: ReactNode;
  value: ReactNode;
  uat: string;
}> = (p) => {
  return !p.value ? null : (
    <div className={p.className}>
      <Title level={4} color="secondary">
        {p.label}
      </Title>
      <Text uat={p.uat}>{p.value}</Text>
    </div>
  );
};
