import React, { FC, Fragment, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from '../index.module.scss';
import { FormList, InputHidden, Select } from '@components/form';
import { Fields, useModel } from './model';

type BanksTabProps = {
  disabled: boolean;
  hidden: boolean;
};
export const BanksTab: FC<BanksTabProps> = memo<BanksTabProps>((p) => {
  const m = useModel();

  return (
    <div className={classnames(s.tab, { [s.hidden]: p.hidden })}>
      <FormList name={Fields.bankAccounts}>
        {(fields) => (
          <>
            {fields.map(({ key, name }) => (
              <Fragment key={key}>
                <InputHidden
                  name={[name, Fields.bankCurrencyId]}
                  fieldKey={[key, Fields.bankCurrencyId]}
                  type="number"
                />

                <Select
                  name={[name, Fields.bankId]}
                  fieldKey={[key, Fields.bankId]}
                  label={
                    <Trans id="handle_user_modal.banks_tab.label.bank">
                      Bank for {m.currenciesLabels[key]}
                    </Trans>
                  }
                  disabled={p.disabled}
                  data={m.banks[key]}
                />
              </Fragment>
            ))}
          </>
        )}
      </FormList>
    </div>
  );
});
