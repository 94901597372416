import React from 'react';
import { Trans } from '@lingui/macro';

import { showNotifyModalAction } from '@modules/modal';
import { DispatchFn } from '@models/redux';

export const showTransferErrorModal: Function = (dispatch: DispatchFn) => {
  return dispatch(
    showNotifyModalAction({
      isClosePrevious: true,
      type: 'fail',
      title: (
        <Trans id="market_transfer_modal.not_available_error.title">
          Transfer is not available
        </Trans>
      ),
      description: (
        <Trans id="market_transfer_modal.not_available_error.text">
          The remaining balance should exceed or be equal to your virtaul balance
        </Trans>
      ),
    })
  );
};
