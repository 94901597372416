import React, { FC } from 'react';
import { defineMessage } from '@lingui/macro';
import { PageTitle } from '@components/page';
import { useI18n } from '@hooks/i18n';

export const ManagementFeeTitle: FC = () => {
  const { i18n } = useI18n();

  return (
    <PageTitle
      backwardLink="/statements"
      title={i18n._(
        defineMessage({ id: 'router.statements.metal_balances', message: 'Metal Balances' })
      )}
    />
  );
};
