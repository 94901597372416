import React, { FC, ReactNode } from 'react';

import { SubmitButton } from '@components/form';
import { formId, useModel } from './model';

export const VerificationUploadSubmit: FC<{
  className?: string;
  fullWidth?: boolean;
  onFinish: () => void;
  onLock: (lock: boolean) => void;
  text: ReactNode;
}> = (p) => {
  const m = useModel(p.onFinish, p.onLock);

  return (
    <SubmitButton
      className={p.className}
      name={formId}
      onAction={m.onFormAction}
      fullWidth={p.fullWidth}
      disabled={m.disabled}
    >
      {p.text}
    </SubmitButton>
  );
};
