import { useBankAccounts } from './hooks';

export const useModel = () => {
  const { isBankAccountsLoading, bankAccounts } = useBankAccounts();

  return {
    bankAccounts,
    isBankAccountsLoading,
  };
};
