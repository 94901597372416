import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { permissionsSelectors } from './store/selectors';
import { accessService as access, AllowedPermissions } from './access.service';
import { Role } from './core';

export const useHasAccess = (
  permissions: AllowedPermissions,
  roles?: Array<Role>,
  extraCheck?: boolean,
  forcedAccess?: boolean
): boolean => {
  const hasAccessFn = useSelector(permissionsSelectors.isHasAccess);
  const isAllowed = useMemo(
    () => hasAccessFn(permissions, extraCheck, forcedAccess, roles),
    [extraCheck, forcedAccess, hasAccessFn, permissions, roles]
  );
  return isAllowed;
};

export const useHasAccessFn = (): ((
  permissions: AllowedPermissions,
  roles?: Array<Role>,
  extraCheck?: boolean,
  forcedAccess?: boolean
) => boolean) => {
  const hasAccessCoreFn = useSelector(permissionsSelectors.isHasAccess);
  const hasAccessFn = useCallback(
    (
      permissions: AllowedPermissions,
      roles?: Array<Role>,
      extraCheck?: boolean,
      forcedAccess?: boolean
    ) => hasAccessCoreFn(permissions, extraCheck, forcedAccess, roles),
    [hasAccessCoreFn]
  );
  return hasAccessFn;
};

export const useUserHasAccess = (
  userPermissions?: string,
  permissions?: AllowedPermissions,
  userRole?: Role,
  roles?: Array<Role>,
  extraCheck: boolean = null,
  forcedAccess: boolean = false
): boolean => {
  const isAllowed = useMemo(() => {
    if (forcedAccess) {
      return true;
    }
    if (extraCheck !== null && !extraCheck) {
      return false;
    }
    return access.isAllowed({
      allowedPermissions: permissions,
      allowedRoles: roles,
      currentPermissions: userPermissions,
      currentRole: userRole,
    });
  }, [extraCheck, forcedAccess, permissions, roles, userPermissions, userRole]);

  return isAllowed;
};

export const useUserHasAccessFn = (userPermissions: string, userRole?: Role) => {
  const hasAccessFn = useCallback(
    (
      permissions: AllowedPermissions,
      roles?: Array<Role>,
      extraCheck?: boolean,
      forcedAccess?: boolean
    ) => {
      if (forcedAccess) {
        return true;
      }
      if (extraCheck !== null && !extraCheck) {
        return false;
      }
      return access.isAllowed({
        allowedPermissions: permissions,
        allowedRoles: roles,
        currentPermissions: userPermissions,
        currentRole: userRole,
      });
    },
    [userPermissions, userRole]
  );
  return hasAccessFn;
};
