import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery, Permission } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { SortItem, TableData, TableResponse } from '@models/core';
import { ColumnIds } from '../store/model';

export interface PaymentItem {
  amount: number;
  currency: string;
  id: number;
  invoiceId: number;
  method: string;
  settledStatus: boolean;
  status: string;
  time: string;
  userId: number;
}
const getPaymentsFields = [
  {
    data: [
      'amount',
      'currency',
      'id',
      'invoiceId',
      'method',
      'settledStatus',
      'status',
      'time',
      'userId',
    ],
  },
  'index',
  'hasMore',
];
const getPaymentsQuery = 'payments';
const getPayments = makeQuery({
  permissions: Permission.PAYMENTS_VIEW_REPORT,
  queryName: getPaymentsQuery,
  queryFields: getPaymentsFields,
  query: (
    pageNumber: number,
    sort: Array<SortItem<ColumnIds>>
  ): Promise<TableData<PaymentItem>> => {
    const ordering = [];
    sort.forEach((col: SortItem<ColumnIds>) =>
      col.fields.forEach((field: string) =>
        ordering.push({ direction: col.direction, field: field })
      )
    );

    return stompClientService
      .getData<TableResponse<PaymentItem>>(
        getPaymentsQuery,
        sign(getPaymentsQuery, getPaymentsFields),
        { ...{ page: pageNumber }, ...v.orderInput({ ordering }) }
      )
      .then((response) => ({
        isHasMore: response.hasMore,
        data: response.data,
      }));
  },
});

const api = {
  getPayments,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:payments-report-table');

export default api;
