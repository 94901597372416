import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { handleBackendError } from '@modules/notify';

export function useMemoDeep<T = any>(nextValue: T, nextDeps: Array<any>): T {
  const [storedValue, setStoredValue] = useState(nextValue);
  const [storedDeps, setStoredDeps] = useState(nextDeps);

  useEffect(() => {
    if (JSON.stringify(nextDeps) === JSON.stringify(storedDeps)) {
      return;
    }
    setStoredDeps(nextDeps);
    setStoredValue(nextValue);
  }, [...nextDeps]);

  return storedValue;
}

export const usePrevValue = <T extends unknown>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const useCallDispatch = <T = void>(action: (payload?: T) => object) => {
  const dispatch = useDispatch();
  const dispatchToStore = useCallback(
    (payload?: T) => dispatch(action(payload)),
    [action, dispatch]
  );
  return dispatchToStore;
};

export const useSyncRef = <T>(param: T) => {
  const syncRef = useRef<T>(param);
  syncRef.current = param;
  return syncRef;
};

type QueryConfig<T, P> = {
  handleError?: boolean;
  initial?: T;
  notAllowed?: boolean;
  onError?: (error: Error) => void;
  params?: P; // TODO это должен быть массив аргументов?
};
type Query<T> = {
  data: T | undefined;
  error: Error | undefined;
  isLoading: boolean;
  refresh?: () => void;
};
const cfgDefault: QueryConfig<any, any> = { handleError: true }; // eslint-disable-line
export const useQuery = <T, P = unknown>(
  request: (p?: P) => Promise<T>,
  config?: QueryConfig<T, P>
): Query<T> => {
  const cfg: QueryConfig<T, P> = { ...cfgDefault, ...config };

  const refresh = async () => {
    // TODO подумать над хорошим решением + отмена прошлого запроса
    if (config?.notAllowed) {
      return;
    }
    let data: T;
    let error: Error;
    !q.isLoading && setQuery({ ...q, isLoading: true });
    try {
      data = await request(params);
    } catch (_error) {
      error = _error as Error;
      cfg.handleError && handleBackendError(error);
      cfg.onError && cfg.onError(error);
    }
    setQuery((state) => ({
      data: data !== undefined ? data : state.data,
      error: error ? error : state.error,
      isLoading: false,
    }));
  };

  const [q, setQuery] = useState<Query<T>>({
    data: cfg.initial,
    error: undefined,
    isLoading: config?.notAllowed ? false : true,
  });
  const params: P = cfg.params;

  useEffect(() => {
    refresh();
  }, [params]); // eslint-disable-line

  return { ...q, refresh };
};
