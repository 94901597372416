import { useSelector } from 'react-redux';

import { Permission, PermissionGroup, Role } from '@permissions/core';
import { configSelectors, UserInfo } from '@config/core';
import { useAuthentication } from '@components/guards/hooks';

export const useAuthorizedGuardModel = (
  forcedAccess: boolean,
  allowedPermissions: Array<Permission | PermissionGroup>,
  allowedRoles: Array<Role>,
  customForcedAccessCheckFn: (u: UserInfo) => boolean,
  extraCheck: (u: UserInfo) => boolean
) => {
  const isInvestor: boolean = useSelector(configSelectors.isInvestor);
  const isRegistrationFinished: boolean = useSelector(configSelectors.isRegistrationFinished);
  const { isHasAccess, redirectUrl } = useAuthentication(
    forcedAccess,
    allowedPermissions,
    allowedRoles,
    customForcedAccessCheckFn,
    extraCheck
  );

  return {
    isHasAccess: isHasAccess && isRegistrationFinished,
    redirectTo: isInvestor && !isRegistrationFinished ? '/signup' : redirectUrl,
  };
};
