import { useCallback } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { Profile } from './api.shared';

export const useGetTariffName = () => {
  const { i18n, lang } = useI18n();

  const getName = useCallback(
    (tariff: Profile['userTariff']['tariff']) => {
      const translations = {
        NO_MEMBERSHIP: i18n._(
          defineMessage({ id: 'profile.tariff.no_membership', message: 'No membership' })
        ),
        STANDART_IM: i18n._(
          defineMessage({ id: 'profile.tariff.im_membership', message: 'St. IM Membership' })
        ),
        STANDART_IND: i18n._(
          defineMessage({ id: 'profile.tariff.ind_membership', message: 'St. IND Membership' })
        ),
        STANDART_IS: i18n._(
          defineMessage({ id: 'profile.tariff.is_membership', message: 'St. IS Membership' })
        ),
        STANDART_LP: i18n._(
          defineMessage({ id: 'profile.tariff.lp_membership', message: 'St. LP Membership' })
        ),
        NO_MEMBERSHIP_IM: i18n._(
          defineMessage({ id: 'profile.tariff.no_membership_im', message: 'No membership' })
        ),
        STANDART_EB: i18n._(
          defineMessage({ id: 'profile.tariff.eb_membership', message: 'St. EB Membership' })
        ),
        NO_MEMBERSHIP_EB: i18n._(
          defineMessage({ id: 'profile.tariff.no_membership_eb', message: 'No membership' })
        ),
      };
      return translations[tariff?.code] || tariff?.name;
    },
    [lang]
  );

  return getName;
};
