import React from 'react';
import { Route, useHistory } from 'react-router';
import { BrowserRouter, Switch } from 'react-router-dom';
import { I18n } from '@lingui/core';
import { connect } from 'react-redux';

import {
  traderPermissions,
  Permission as P,
  Role as R,
  statementsPagePermissions,
} from '@permissions/core';
import { configSelectors, configServiceActions, UserInfo } from '@config/core';
import { DispatchFn, RootState } from '@models/redux';
import { History } from '@models/core';
import { session } from '@services/session';
import { AuthorizedGuard, PostRegistrationGuard } from '@components/guards';
import { DarkLayout, LightLayout } from '@components/layouts';

import { UIKitPage } from './containers/pages/ui-kit';
import { DailyBalanceHistory } from './containers/pages/statements/daily-balance-history';
import PrimaryMarketDetailedPage from './containers/pages/primary-market/detailed';
import IssuerStatementsPage from './containers/pages/issuer-statements';
import { ListingBrokerAccountStatement } from './containers/pages/statements/listing-broker-account-statement';
import { BeneficialOwnersPage } from 'containers/pages/beneficial-owners';
import { OffersPage, permissionGroup as offersPagePermissionGroup } from 'containers/pages/offers2';
import {
  CreateCoinPage,
  createCoinPagePermissionGroup,
  EditCoinPage,
  editCoinPagePermissionGroup,
  CoinDetailsPage,
  coinDetailsPagePermissionGroup,
  CoinsTablePage,
} from '@router/pages/digital-metals';
import {
  DepositsPage,
  permissionGroup as depositsPagePermissionGroup,
} from './containers/pages/deposits';
import { AccessDeniedPage } from 'containers/pages/system/access-denided';
import { NotFoundPage } from 'containers/pages/system/not-found';
import { StartPage } from 'containers/pages/system/start';
import {
  RedemptionsPage,
  permissionGroup as redemptionsPagePermissionGroup,
} from './containers/pages/redemptions';
import {
  SettlementsPage,
  permissionGroup as settlementsPagePermissionGroup,
} from 'containers/pages/statements/settlements';
import { CheckAuthPage } from 'containers/pages/system/check-auth';
import {
  permissionGroup as statementsPermissionGroup,
  StatementsPage,
} from 'containers/pages/statements';
import { TransactionsPage } from 'containers/pages/transactions';
import {
  permissionsGroup as usersPagePermissionsGroup,
  UsersPage,
} from './containers/pages/users/page';
import { VaultsPage } from 'containers/pages/vaults/page';
import {
  WalletPage,
  permissionGroup as walletPagePermissionGroup,
} from 'containers/pages/wallet/page';
import {
  WithdrawalsPage,
  permissionGroup as withdrawalsPermissionGroup,
} from './containers/pages/withdrawals';
import { TransferRequestsPage } from './containers/pages/transfer-requests';
import { MetalsPage } from './containers/pages/metals';
import {
  FeesReportPage,
  permissionGroup as feesReportPermissionGroup,
} from './containers/pages/statements/fees-report';
import {
  ReferralsPage,
  permissionGroup as referralsReportPermissionGroup,
} from 'containers/pages/statements/referrals';
import { PaymentSuccessPage } from './containers/pages/deposits/add-funds-modal/credit-card/payment-success';
import { PaymentsPage } from './containers/pages/statements/payments';
import { ParticipantsPage } from './containers/pages/statements/participants';
import {
  permissionGroup as primaryMarketListPermissionGroup,
  PrimaryMarket,
} from './containers/pages/primary-market/list';
import { ConversionReportPage } from 'containers/pages/conversion-report';
import {
  OverdraftReportPage,
  permissionGroup as overdraftReportPermissionGroup,
} from 'containers/pages/overdraft-report';
import { PostRegistrationPage } from './containers/pages/post-registration/page';
import { MarketingDetail } from './containers/pages/trading-platform/marketing/marketing-detail';
import {
  MarketingPage,
  permissionGroup as marketingPermissionGroup,
} from './containers/pages/trading-platform/marketing';
import {
  VirtualBalancePage,
  permissionGroup as virtualBalancePermissionGroup,
} from './containers/pages/statements/virtual-balance';
import { ManagementFeeReportPage } from './containers/pages/statements/management-fee';
import {
  TutorialPage,
  permissionGroup as tutorialPermissionsGroup,
} from './containers/pages/tutorial';
import { TablePlayground } from './containers/pages/table-playground';
import { profileOtherPagePermissionsGroup } from './containers/pages/profile/other/permissions';
import { ProfileOtherPage } from './containers/pages/profile/other';
import {
  ProfileMyPage,
  permissionsGroup as profileMyPermissionsGroup,
} from './containers/pages/profile/my';
import {
  BankAccountBalancesPage,
  permissionGroup as bankAccountBalancesPermissionGroup,
} from './containers/pages/statements/bank-account-balances';
import {
  CMBankAccountsPage,
  permissionsGroup as CMPermissionsGroup,
} from './containers/pages/cash-management/bank-accounts';
import {
  CMTransactionsPage,
  permissionsGroup as CMTransactionsPermissionsGroup,
} from './containers/pages/cash-management/transactions';
import {
  BankAccountHistoryPage,
  permissionGroup as bankAccountHistoryPermissionGroup,
} from './containers/pages/statements/bank-account-balances/bank-account-history';
import {
  PreferencesPage,
  permissionGroup as preferencesPagePermissionGroup,
} from './containers/pages/preferences';

const WithHistory = connect(
  (root: RootState) => ({
    i18n: configSelectors.i18n(root),
    lang: configSelectors.lang(root), // we need lang here to rerender page titles on language change
    permissions: configSelectors.permissions(root),
  }),
  (dispatch) => ({
    setHistoryInstance: (history) => dispatch(configServiceActions.setHistoryInstance(history)),
  })
)(
  ({
    i18n,
    setHistoryInstance,
    permissions,
  }: {
    i18n: I18n;
    setHistoryInstance: DispatchFn<History>;
    permissions: string;
  }) => {
    const history = useHistory();
    setHistoryInstance(history);

    return (
      <Switch>
        <Route exact path="/" component={StartPage} />

        <Route exact path="/ui-kit" component={UIKitPage} />

        <Route exact path="/ui-kit/table-playground" component={TablePlayground} />

        <Route exact path="/check-auth" component={CheckAuthPage} />

        <Route
          path="/signin"
          render={() => {
            window.location.href = `${
              window.location.origin
            }/sso/auth?locale=${session.getKKLanguage()}`;
            return null;
          }}
        />

        <PostRegistrationGuard
          path="/signup"
          page={PostRegistrationPage}
          layout={LightLayout}
          permissions={[P.SIGNUP_VIEW]}
        />

        <AuthorizedGuard
          exact
          path="/profile"
          page={ProfileMyPage}
          permissions={[profileMyPermissionsGroup]}
          layout={DarkLayout}
        />

        <AuthorizedGuard
          exact
          path="/users"
          page={UsersPage}
          layout={DarkLayout}
          permissions={[usersPagePermissionsGroup]}
        />

        <AuthorizedGuard
          exact
          path="/users/:userId"
          page={ProfileOtherPage}
          layout={DarkLayout}
          roles={[R.issuer, R.complianceOfficer, R.clearanceBroker, R.accountManager]}
          permissions={[profileOtherPagePermissionsGroup]}
        />

        <AuthorizedGuard
          path="/my-wallet"
          page={WalletPage}
          permissions={[walletPagePermissionGroup]}
          layout={DarkLayout}
        />

        <AuthorizedGuard
          path="/offers"
          page={OffersPage}
          layout={DarkLayout}
          permissions={[offersPagePermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          exact
          path="/digital-metals"
          page={CoinsTablePage}
          layout={DarkLayout}
          roles={[R.issuer]}
          permissions={[P.COINS_VIEW_ALL]}
        />

        <AuthorizedGuard
          path="/transfer-requests"
          page={TransferRequestsPage}
          layout={DarkLayout}
          roles={[R.accountManager]}
        />

        <AuthorizedGuard
          exact
          path="/metals"
          page={MetalsPage}
          layout={DarkLayout}
          roles={[R.accountManager]}
        />

        <AuthorizedGuard
          path="/digital-metals/:coinId/edit"
          page={EditCoinPage}
          layout={DarkLayout}
          permissions={[editCoinPagePermissionGroup]}
        />

        <AuthorizedGuard
          path="/digital-metals/:coinId/details"
          page={CoinDetailsPage}
          layout={DarkLayout}
          permissions={[coinDetailsPagePermissionGroup]}
        />

        <AuthorizedGuard
          path="/digital-metals/new"
          page={CreateCoinPage}
          layout={DarkLayout}
          permissions={[createCoinPagePermissionGroup]}
        />

        <AuthorizedGuard
          path="/vaults"
          page={VaultsPage}
          layout={DarkLayout}
          roles={[R.issuer, R.vaultManager]}
          permissions={[P.VAULTS_VIEW_LIST]}
        />

        <AuthorizedGuard
          path="/transactions"
          page={TransactionsPage}
          layout={DarkLayout}
          roles={[R.issuer, R.complianceOfficer]}
          permissions={[P.TRANSACTIONS_VIEW_ALL]}
        />

        <AuthorizedGuard
          path="/beneficial-owners"
          page={BeneficialOwnersPage}
          layout={DarkLayout}
          roles={[R.complianceOfficer]}
        />

        <AuthorizedGuard
          path="/deposits"
          page={DepositsPage}
          layout={DarkLayout}
          permissions={[depositsPagePermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          exact
          forcedAccess
          path="/payment-success"
          page={PaymentSuccessPage} // cannot set data-uat
          layout={DarkLayout}
        />

        <AuthorizedGuard
          path="/redeem-requests"
          page={RedemptionsPage}
          layout={DarkLayout}
          permissions={[redemptionsPagePermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/statements/management-fee"
          page={ManagementFeeReportPage}
          layout={DarkLayout}
          permissions={[P.MANAGEMENT_FEE_VIEW_REPORT]}
        />

        <AuthorizedGuard
          exact
          path="/statements/bank-accounts-balances"
          page={BankAccountBalancesPage}
          layout={DarkLayout}
          permissions={[bankAccountBalancesPermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/statements/bank-accounts-balances/history"
          page={BankAccountHistoryPage}
          layout={DarkLayout}
          permissions={[bankAccountHistoryPermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/statements/payments"
          page={PaymentsPage}
          layout={DarkLayout}
          permissions={[P.PAYMENTS_VIEW_REPORT]}
        />

        <AuthorizedGuard
          path="/primary-detailed"
          page={PrimaryMarketDetailedPage}
          layout={DarkLayout}
          permissions={traderPermissions}
        />

        <AuthorizedGuard
          path="/primary-market"
          page={PrimaryMarket}
          layout={DarkLayout}
          permissions={[primaryMarketListPermissionGroup]}
        />

        <AuthorizedGuard
          path="/withdrawals"
          page={WithdrawalsPage}
          layout={DarkLayout}
          permissions={[
            withdrawalsPermissionGroup,
            P.WITHDRAWALS_VIEW_ALL,
            P.WITHDRAWALS_VIEW_USER,
          ]}
          stretchVertically
        />

        <AuthorizedGuard
          exact
          path="/statements"
          page={StatementsPage}
          layout={DarkLayout}
          permissions={[...statementsPagePermissions, statementsPermissionGroup]}
          roles={[R.admin, R.listingBroker]}
        />

        <AuthorizedGuard
          path="/statements/conversion-report"
          page={ConversionReportPage}
          layout={DarkLayout}
          permissions={[P.EXCHANGE_VIEW_CONVERSION_REPORT]}
        />

        <AuthorizedGuard
          path="/statements/overdraft-report"
          page={OverdraftReportPage}
          layout={DarkLayout}
          permissions={[overdraftReportPermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/statements/listing-brokers"
          page={ListingBrokerAccountStatement}
          layout={DarkLayout}
          roles={[R.listingBroker]}
          permissions={[P.STATEMENT_VIEW_LB_ACCOUNT_REPORT]}
        />

        <AuthorizedGuard
          path="/admin-daily-balance-history"
          page={DailyBalanceHistory}
          layout={DarkLayout}
          permissions={[P.DAILY_BALANCE_VIEW_HISTORY_REPORT]}
        />

        <AuthorizedGuard
          path="/settlements"
          page={SettlementsPage}
          layout={DarkLayout}
          permissions={[settlementsPagePermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/issuer-statements"
          page={IssuerStatementsPage}
          layout={DarkLayout}
          permissions={[P.DAILY_BALANCE_VIEW_HISTORY_REPORT]}
          extraCheckFn={(u: UserInfo) => ([R.issuer] as R[]).includes(u?.role?.name)}
        />

        <AuthorizedGuard
          path="/statements/fees"
          page={FeesReportPage}
          layout={DarkLayout}
          permissions={[feesReportPermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/statements/referrals"
          page={ReferralsPage}
          layout={DarkLayout}
          permissions={[referralsReportPermissionGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/statements/participants"
          page={ParticipantsPage}
          layout={DarkLayout}
          roles={[R.admin]}
          permissions={[P.SECONDARY_MARKET_VIEW_FREE_PARTICIPANTS_REPORT]}
        />

        <AuthorizedGuard
          exact
          path="/marketing"
          page={MarketingPage}
          layout={DarkLayout}
          permissions={[marketingPermissionGroup]}
        />

        <AuthorizedGuard
          exact
          path="/marketing/:companyId"
          page={MarketingDetail}
          layout={DarkLayout}
          permissions={[marketingPermissionGroup]}
        />

        <AuthorizedGuard
          path="/preferences"
          page={PreferencesPage}
          layout={DarkLayout}
          permissions={[preferencesPagePermissionGroup]}
        />

        <AuthorizedGuard
          path="/statements/virtual-balance"
          page={VirtualBalancePage}
          layout={DarkLayout}
          permissions={[virtualBalancePermissionGroup]}
        />

        <AuthorizedGuard
          path="/cash-management/bank-accounts"
          page={CMBankAccountsPage}
          layout={DarkLayout}
          permissions={[CMPermissionsGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/cash-management/transactions"
          page={CMTransactionsPage}
          layout={DarkLayout}
          permissions={[CMTransactionsPermissionsGroup]}
          stretchVertically
        />

        <AuthorizedGuard
          path="/tutorial"
          page={TutorialPage}
          layout={DarkLayout}
          permissions={[tutorialPermissionsGroup]}
        />

        <AuthorizedGuard
          forcedAccess
          path="/accessDenied"
          page={AccessDeniedPage}
          layout={DarkLayout}
        />

        <AuthorizedGuard path="" forcedAccess page={NotFoundPage} layout={DarkLayout} />
      </Switch>
    );
  }
);

export const Router = () => {
  return (
    <BrowserRouter>
      <WithHistory />
    </BrowserRouter>
  );
};
