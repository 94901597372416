import { useEffect } from 'react';

import { Any } from '@models/core';
import { liveUpdateService as liveUpdate } from './service';
import { LiveUpdateEvent } from './index';

const _deps = [];
export const useLiveUpdate = (
  onLiveUpdate: (event: LiveUpdateEvent) => void,
  deps: Array<Any> = _deps
) => {
  useEffect(() => {
    const subscriptionId = liveUpdate.subscribe(onLiveUpdate);
    return () => liveUpdate.unsubscribe(subscriptionId);
  }, deps); // eslint-disable-line
};
