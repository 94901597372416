import { SortOrder } from '@components/table2/autosized';
import { CoinInfo, VaultInfo } from '../../vaults-api.service';
import { VaultsPageServiceActionsTypes } from './service.acions';
import { VaultsPageUserActionsTypes } from './user.acions';

export enum VaultsTableColumnIds {
  actions = 'actions',
  address = 'address',
  city = 'city',
  contact = 'contact',
  country = 'country',
  symbol = 'symbol',
  vaultManager = 'vaultManager',
  warehouseCompany = 'warehouseCompany',
}

export interface VaultsLoadedPayload {
  vaults: Array<VaultInfo>;
  listKey: number;
  hasMore: boolean;
}

export interface VaultsCleanedPayload {
  initialVaults: Array<VaultInfo>;
  sortOrder: SortOrder;
  hasMore: boolean;
}

export interface EventVaultUpdatedPayload {
  isNewVault: boolean;
  vault: VaultInfo;
}

export interface OnVaultsLoadedPayload extends VaultsLoadedPayload {}

export interface OnVaultsSortedPayload extends VaultsCleanedPayload {}

export interface OnShowVaultBalanceDetailsPayload {
  coin: CoinInfo;
  vault: VaultInfo;
}

export interface VaultsPageState {
  vaults: Array<VaultInfo>;
  listKey: number;
  sortOrder: SortOrder;
  hasMore: boolean;
  isDeleteVaultLoading: boolean;
  lastUpdatedVaultId: number | null;
  vaultUpdateCounter: number;
}

export const vaultsPageStateInitial: VaultsPageState = {
  vaults: [],
  hasMore: true,
  listKey: 0,
  sortOrder: [],
  isDeleteVaultLoading: false,
  lastUpdatedVaultId: null,
  vaultUpdateCounter: 0,
};

export enum VaultsPageActionsTypes {
  DELETE_VAULT = 'VAULTS_PAGE.DELETE_VAULT',
  DELETE_VAULT_FAIL = 'VAULTS_PAGE.DELETE_VAULT_FAIL',
  DELETE_VAULT_SUCCESS = 'VAULTS_PAGE.DELETE_VAULT_SUCCESS',
  EVENT_VAULT_UPDATED = 'VAULTS_PAGE.EVENT_VAULT_UPDATED',
  VAULTS_LOADED = 'VAULTS_PAGE.VAULTS_LOADED',
  VAULTS_CLEANED = 'VAULTS_PAGE.VAULTS_CLEANED',
  ON_ADD_VAULT_CLICKED = 'VAULTS_PAGE.ON_ADD_VAULT_CLICKED',
  ON_CONFIRM_VAULT_BALANCE_CLICKED = 'VAULTS_PAGE.ON_CONFIRM_VAULT_BALANCE_CLICKED',
  ON_DELETE_VAULT_CLICKED = 'VAULTS_PAGE.ON_DELETE_VAULT_CLICKED',
  ON_EDIT_VAULT_CLICKED = 'VAULTS_PAGE.ON_EDIT_VAULT_CLICKED',
  ON_PAGE_LEFT = 'VAULTS_PAGE.ON_PAGE_LEFT',
  ON_PAGE_OPENED = 'VAULTS_PAGE.ON_PAGE_OPENED',
  ON_SHOW_VAULT_BALANCE_DETAILS_CLICKED = 'VAULTS_PAGE.ON_SHOW_VAULT_BALANCE_DETAILS_CLICKED',
  ON_VAULTS_LOADED = 'VAULTS_PAGE.ON_VAULTS_LOADED',
  ON_VAULTS_SORTED = 'VAULTS_PAGE.ON_VAULTS_SORTED',
}

export type VaultsPageActions = VaultsPageServiceActionsTypes | VaultsPageUserActionsTypes;
