import React from 'react';
import { all, put, select, takeEvery } from 'redux-saga/effects';

import { handleBackendError } from 'shared/modules/notify';
import { Put } from '@helper/redux';
import { closeModalAction, showModalAction } from 'shared/modules/modal';
import api from '../../api';
import { RedeemConfirmationModal } from '../../redeem-confirmation-modal';
import { RedeemFinishModal } from '../../redeem-finish-modal';
import { RedeemConfirmationModalActionsTypes as RedeemConfirmationTypes } from './model';
import { redeemConfirmationModalServiceActions as redeemConfirmServiceActions } from './service.acions';
import { redeemConfirmationModalSelectors as redeemConfirmSelectors } from './selectors';

export function* redeemConfirmationModalEffects() {
  yield all([
    takeEvery(RedeemConfirmationTypes.GET_STABLE_BALANCE, getStableBalance),
    takeEvery(
      RedeemConfirmationTypes.ON_CLOSE_MODAL_CLICKED,
      Put(closeModalAction, RedeemConfirmationModal)
    ),
    takeEvery(RedeemConfirmationTypes.ON_CONFIRM_REDEEM_CLICKED, switchToFinishRedeemModal),
    takeEvery(
      RedeemConfirmationTypes.ON_MODAL_OPENED,
      Put(redeemConfirmServiceActions.getStableBalance)
    ),
  ]);
}

function* getStableBalance() {
  try {
    const balance = yield api.getPrimaryStableBalance();
    yield put(redeemConfirmServiceActions.getStableBalanceSuccess(balance));
  } catch (error) {
    yield put(redeemConfirmServiceActions.getStableBalanceFail(error));
    handleBackendError(error);
  }
}

function* switchToFinishRedeemModal() {
  const confirmedRedeemData = yield select(redeemConfirmSelectors.confirmedRedeemData);
  yield put(redeemConfirmServiceActions.setConfirmedRedeemData(confirmedRedeemData));
  yield put(
    showModalAction({
      isClosePrevious: true,
      modal: RedeemFinishModal,
    })
  );
}
