import sign from 'tm2sign.macro';
import { Permission, PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { BidOrderByAll, BidOrderStatus, TableData } from '../shared/types';
import { v } from '@helper/typer/field-typer.helper';

type QueryBidOrder = {
  id: number;
  description: string;
  date: string;
  offerPrice: {
    currency: {
      symbol: string;
    };
    offer: {
      coin: {
        asset: string;
      };
      creator: {
        id: number;
        displayName: string;
      };
    };
    quantityPerGram: number;
    unit: {
      inGrams: number;
      label: string;
    };
  };
  quantity: number;
  status: BidOrderStatus;
  user: {
    id: number;
    displayName: string;
  };
};

type QueryBidOrderList = TableData<QueryBidOrder>;

type GetBidOrdersResponse = TableData<BidOrderByAll>;

const getBidOrdersQuery = 'bidOrdersPage';
const getBidOrdersFields = [
  {
    data: [
      'id',
      'date',
      'description',
      {
        offerPrice: [
          { currency: ['symbol'] },
          {
            offer: [{ coin: ['asset'] }, { creator: ['id', 'displayName'] }],
          },
          'quantityPerGram',
          { unit: ['inGrams', 'label'] },
        ],
      },
      'quantity',
      'status',
      { user: ['id', 'displayName'] },
    ],
  },
  'hasMore',
];
const getBidOrders = makeQuery({
  permissions: Permission.BID_ORDERS_VIEW_ALL,
  queryName: getBidOrdersQuery,
  queryFields: getBidOrdersFields,
  query: (params: {
    assetId?: number;
    pageNumber: number;
    status?: BidOrderStatus;
  }): Promise<GetBidOrdersResponse> => {
    const variables = {
      ...v.bidOrdersPageSelectMode({ selectMode: 'ALL' }),
      ...v.long({ coinId: params.assetId }),
      ...v.long({ page: params.pageNumber }),
      ...v.bidOrderStatus({ status: params.status }),
    };
    return stompClient
      .getData<QueryBidOrderList>(
        getBidOrdersQuery,
        sign(getBidOrdersQuery, getBidOrdersFields),
        variables
      )
      .then((res) => ({
        data: res.data.map((bidOrder) => ({
          id: bidOrder.id,
          asset: bidOrder.offerPrice.offer.coin.asset,
          buyerId: bidOrder.user.id,
          buyerName: bidOrder.user.displayName,
          comment: bidOrder.description,
          creatorId: bidOrder.offerPrice.offer.creator.id,
          creatorName: bidOrder.offerPrice.offer.creator.displayName,
          date: bidOrder.date,
          priceCurrency: bidOrder.offerPrice.currency.symbol,
          pricePerGram: bidOrder.offerPrice.quantityPerGram,
          priceUnitInGrams: bidOrder.offerPrice.unit.inGrams,
          priceUnitLabel: bidOrder.offerPrice.unit.label,
          quantity: bidOrder.quantity,
          status: bidOrder.status,
        })),
        hasMore: res.hasMore,
      }));
  },
});

const api = {
  getBidOrders,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:bid-orders-by-all');

export default api;
