import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import e from './effects';
import { sliceId, stateInitial, Form } from './model';

export { default as selectors } from './selectors';

const s = createSlice({
  name: sliceId(null),
  initialState: stateInitial,
  reducers: {
    onFormChanged: (state, action: PayloadAction<Form>) => {
      state.form = {
        ...action.payload,
        amount: +action.payload.amount,
      };
    },
    onModalLeft: () => stateInitial,
  },
  extraReducers: (builder) => {
    builder.addCase(e.getStableBalances.pending, (state) => {
      state.isGetStableBalancesLoading = true;
    });
    builder.addCase(e.getStableBalances.fulfilled, (state, action) => {
      state.isGetStableBalancesLoading = false;
      state.stableBalances = action.payload;
    });
    builder.addCase(e.getStableBalances.rejected, (state) => {
      state.isGetStableBalancesLoading = false;
    });

    builder.addCase(e.sendStableToIssuer.pending, (state) => {
      state.isSendStableLoading = true;
    });
    builder.addCase(e.sendStableToIssuer.fulfilled, (state) => {
      state.isSendStableLoading = false;
    });
    builder.addCase(e.sendStableToIssuer.rejected, (state) => {
      state.isSendStableLoading = false;
    });
  },
});

export const sendStableToInvestorReducer = s.reducer;

export const actions = {
  onAssetChanged: e.onAssetChanged,
  onCloseModalClicked: e.onCloseModalClicked,
  onFormChanged: s.actions.onFormChanged,
  onModalLeft: s.actions.onModalLeft,
  onModalOpened: e.getStableBalances,
  onSendStableForm: e.onSendStableForm,
};
