import { TransferRequestsPageActionsTypes as Types } from './model';

const DownloadTransactionAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION,
});

const DownloadTransactionFailAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION_FAIL,
});

const DownloadTransactionSuccessAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION_SUCCESS,
});

const LoadMoreTransferRequestsAction = (config) => ({
  payload: config,
  type: Types.LOAD_MORE_TRANSFER_REQUESTS,
});

const LoadMoreTransferRequestsFailAction = (error) => ({
  payload: error,
  type: Types.LOAD_MORE_TRANSFER_REQUESTS_FAIL,
});

const LoadMoreTransferRequestsSuccessAction = (transferRequests) => ({
  payload: transferRequests,
  type: Types.LOAD_MORE_TRANSFER_REQUESTS_SUCCESS,
});

const UploadTransactionAction = (file) => ({
  payload: file,
  type: Types.UPLOAD_TRANSACTION,
});

const UploadTransactionFailAction = () => ({
  payload: null,
  type: Types.UPLOAD_TRANSACTION_FAIL,
});

const UploadTransactionSuccessAction = () => ({
  payload: null,
  type: Types.UPLOAD_TRANSACTION_SUCCESS,
});

export const transferRequestsPageServiceActions = {
  downloadTransaction: DownloadTransactionAction,
  downloadTransactionFail: DownloadTransactionFailAction,
  downloadTransactionSuccess: DownloadTransactionSuccessAction,
  loadMoreTransferRequests: LoadMoreTransferRequestsAction,
  loadMoreTransferRequestsFail: LoadMoreTransferRequestsFailAction,
  loadMoreTransferRequestsSuccess: LoadMoreTransferRequestsSuccessAction,
  uploadTransaction: UploadTransactionAction,
  uploadTransactionFail: UploadTransactionFailAction,
  uploadTransactionSuccess: UploadTransactionSuccessAction,
};
