import NodeCache from 'node-cache';
import sign from 'tm2sign.macro';
import { graphqlQuery } from './gql/executor';
import { websocketsService as websockets } from '@services/stomp/core';

export const sendToServer = (client, request) => {
  const json = JSON.stringify(request);
  client.send('/exchange/tm2.topic/Web.Client.Request', { 'user-id': websockets.stompLogin }, json);
};

// TODO: node-cache not rejecting on timeout

let requestsCache = new NodeCache();

requestsCache.on('expired', function (key, value) {
  value.reject(new Error('timeout'));
});

export const sendAsyncRequest = (wsInstance, action, data) =>
  new Promise((resolve, reject) => {
    let uuid = uuidv4();
    requestsCache.set(uuid, { resolve, reject });
    sendRequest(wsInstance, uuid, action, data);
  });

const sendRequest = (wsInstance, uuid, action, data) => {
  let message = {
    id: uuid,
    type: 'REQUEST',
    action,
    body: data,
  };

  sendToServer(wsInstance, message);
};

export const sendSubscribe = (wsInstance, action, data, cbk) => {
  let uuid = uuidv4();
  requestsCache.set(uuid, cbk);

  let message = {
    id: uuid,
    type: 'SUBSCRIBE',
    action,
    body: data,
  };

  sendToServer(wsInstance, message);

  const unsubscribe = async () => {
    await graphqlQuery(wsInstance, 'unsubscribe', sign('unsubscribe', ['value']), {
      subscriptionId: uuid,
    });
    requestsCache.del(uuid);
  };

  return () => unsubscribe();
};

export const onResponseReceived = (responseMessage) => {
  let request = requestsCache.get(responseMessage.id);
  if (!request) return;

  request.resolve(responseMessage.body);
  requestsCache.del(responseMessage.id);
};

export const onGraphQlSubscriptionEvent = (notifyMessage) => {
  let subscription = requestsCache.get(notifyMessage.id);
  if (!subscription) {
    return;
  }

  subscription(notifyMessage.body);
};

export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
