import { useState } from 'react';
import { useCallbackOne } from 'use-memo-one';
import {
  Table as AutosizedTable,
  ColumnType,
  KeyExtractor,
  OnLoadMore,
  ColumnConfig,
  RowConfig,
} from '@components/table2/autosized';
import s from './index.module.scss';

type Item = {
  id: number;
  name: string;
  age: number;
  address: string;
};

function delay(ms: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

function getData(fromIndex: number, batchSize = 20) {
  const data: Item[] = [];
  for (let i = fromIndex; i < fromIndex + batchSize; i++) {
    data.push({
      id: i,
      name: `Edward King ${i}`,
      age: 32,
      address: `London, Park Lane no. ${i}, XXXXXXXXXXXXXXXXXXXXXXXXXXX`,
    });
  }
  return data;
}

async function fetchData(fromIndex: number): Promise<{
  data: Item[];
  hasMore: boolean;
}> {
  const batchSize = 20;
  const data = getData(fromIndex, batchSize);
  await delay(1000);
  return {
    data,
    hasMore: fromIndex + batchSize < 2000,
  };
}

const columns: ColumnType<Item>[] = [
  {
    dataIndex: 'id',
    headCell: () => <>ID</>,
    rowCell: (props) => <>{props.data.id}</>,
    width: 350,
  },
  {
    dataIndex: 'name',
    headCell: () => <>Name</>,
    rowCell: (props) => <>{props.data.name}</>,
    width: 200,
  },
  {
    dataIndex: 'age',
    headCell: () => <>Age</>,
    rowCell: (props) => <>{props.data.age}</>,
    width: 250,
  },
  {
    alignment: 'right',
    // ellipsis: false,
    dataIndex: 'address',
    headCell: () => <>Address</>,
    rowCell: (props) => <>{props.data.address}</>,
    width: 250,
  },
];

const columnConfig: ColumnConfig = {
  alignment: 'left',
} as const;

const rowConfig: RowConfig<Item> = {
  height: 50,
};

export function Table() {
  // const initialData = [];
  // const initialIndex = 0;

  const initialIndex = 0;
  const initialData = getData(0, initialIndex);

  const [dataList, setDataList] = useState<{
    data: Item[];
    hasMore: boolean;
  }>({ data: initialData, hasMore: true });

  const fetchTableData = useCallbackOne<OnLoadMore<{ fromIndex: number }>>(({ lastIndexData }) => {
    const fromIndex = lastIndexData?.fromIndex ?? initialIndex;

    return fetchData(fromIndex).then(({ data: incomingData, hasMore }) => {
      setDataList(({ data }) => ({
        data: data.concat(incomingData),
        hasMore,
      }));
      return {
        indexData: {
          fromIndex: fromIndex + incomingData.length,
        },
      };
    });
  }, []);

  // const estimateRowHeight = useCallbackOne(() => {
  //   return 20;
  // }, []);

  const keyExtractor = useCallbackOne<KeyExtractor<Item>>((item) => {
    return item.id;
  }, []);

  return (
    <AutosizedTable
      className={s.outer}
      columnConfig={columnConfig}
      columns={columns}
      data={dataList.data}
      // estimateRowHeight={estimateRowHeight}
      hasMore={dataList.hasMore}
      onLoadMore={fetchTableData}
      keyExtractor={keyExtractor}
      rowConfig={rowConfig}
    />
  );
}
