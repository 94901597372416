import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { TableResponse } from '@models/core';
import { SortOrder } from '@models/sorting';
import { CMBankAccountStatus, CMBankAccountType } from '../../hooks';
// -------------------------------------------------------------------------------------
const bankAccountFields = [
  'accountNumber',
  { bank: ['bankName', 'swiftBic'] },
  { computedFields: ['calculatedBalance'] },
  'comment',
  'currentBalance',
  { currency: ['code'] },
  'id',
  'legalEntity',
  'minfiniumPublicKey',
  'status',
  'type',
];
// -------------------------------------------------------------------------------------
export enum Column {
  accountNumber = 'accountNumber',
  bankName = 'bank.bankName',
  calculatedBalance = 'computedFields.calculatedBalance',
  comment = 'comment',
  currentBalance = 'currentBalance',
  currency = 'currency.code',
  id = 'id',
  legalEntity = 'legalEntity',
  publicKey = 'minfiniumPublicKey',
  status = 'status',
  swift = 'bank.swiftBic',
  type = 'type',
}

export type CMBankAccount = {
  [Column.accountNumber]: string;
  bank: { bankName: string; swiftBic: string };
  computedFields: { calculatedBalance: number };
  [Column.comment]: string;
  [Column.currentBalance]: number;
  currency: { code: string };
  id: number;
  [Column.legalEntity]: string;
  [Column.publicKey]: string;
  [Column.status]: CMBankAccountStatus;
  [Column.swift]: string;
  [Column.type]: CMBankAccountType;
};

const getBankAccountsQuery = 'backofficeBankAccountsPage';
const getBankAccountsFields = [{ data: bankAccountFields }, 'index', 'hasMore'];
export const getBankAccounts = makeQuery({
  permissions: Permission.BACK_OFFICE_ACCESS,
  queryName: getBankAccountsQuery,
  queryFields: getBankAccountsFields,
  query: (page: number, ordering: SortOrder<Column>): Promise<TableResponse<CMBankAccount>> => {
    const typedValues = {
      ...v.long({ page }),
      ...v.orderInput({ ordering }),
    };

    return stompClient.getData(
      getBankAccountsQuery,
      sign(getBankAccountsQuery, getBankAccountsFields),
      typedValues
    );
  },
});
// -------------------------------------------------------------------------------------
const getBankAccountQuery = 'backofficeBankAccountById';
const getBankAccountFields = bankAccountFields;
export const getBankAccount = makeQuery({
  permissions: Permission.BACK_OFFICE_ACCESS,
  queryName: getBankAccountQuery,
  queryFields: getBankAccountFields,
  query: (id: number): Promise<CMBankAccount> => {
    const typedValues = v.idInput({ id: { id } });
    return stompClient.getData(
      getBankAccountQuery,
      sign(getBankAccountQuery, getBankAccountFields),
      typedValues
    );
  },
});
// -------------------------------------------------------------------------------------
const api = {
  getBankAccount,
  getBankAccounts,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:cm-bank-accounts-table');

export default api;
