import { createSelector } from 'reselect';

import { RootState as RS } from '@models/redux';
import { SelectItem } from '@components/form';
import { Form, State } from './model';

const s = (r: RS): State => r.main?.wallet?.sendFiatToInvestorModal;

const assets: (r: RS) => Array<SelectItem> = createSelector(
  [(r: RS) => s(r).stableBalances],
  (balances) =>
    balances.map((item) => ({
      label: item.asset,
      value: item.asset,
    }))
);

const form = (r: RS): Form => s(r).form;

const formInitial: (r: RS) => Form = createSelector([(r: RS) => assets(r)], (assets) => {
  const asset = assets[0] && (assets[0].value as string);
  return !asset
    ? null
    : {
        amount: undefined,
        asset: asset,
      };
});

const isSendButtonDisabled = (r: RS): boolean => lockUI(r) || form(r).amount > stableAmountMax(r);

const lockUI = (r: RS): boolean => s(r).isGetStableBalancesLoading || s(r).isSendStableLoading;

const stableAmountMax: (r: RS) => number = createSelector(
  [(r: RS) => form(r).asset, (r: RS) => s(r).stableBalances],
  (_asset, _balances) => _balances.filter((item) => item.asset === _asset)[0]?.balance
);

export default {
  assets,
  formInitial,
  isSendButtonDisabled,
  lockUI,
  stableAmountMax,
};
