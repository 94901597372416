import React, { FC, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Form as AntForm, FormInstance } from 'antd';
import { Trans } from '@lingui/macro';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { ButtonPrimary, ButtonSecondary } from '@components/button';
import { Popup } from '@components/popup';
import { Col, Row } from '@components/grid';
import { Form } from '@components/form';
import { maxDecimals as decimalsDefault } from '@constants/math';
import { Label, useLabel } from '../label';
import { InputNumber } from '../input-number';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const InputNumberRange: FC<{
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  label: ReactNode;
  min?: number;
  max?: number;
  maxDecimals?: number;
  name: NamePath;
  onChange?: (range: [number, number]) => void;
  required?: boolean;
  uat?: string;
}> = ({
  className,
  compact,
  disabled,
  label,
  min,
  max,
  maxDecimals = decimalsDefault,
  name,
  onChange,
  required,
  uat,
}) => {
  const uatAttribute = useFieldUat(name, 'input-number-range', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-number-range', className, {
        'tm2-form-field-compact': compact,
      })}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} required={required}>
        <CustomNumberRange
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          max={max}
          maxDecimals={maxDecimals}
          min={min}
          onCustomChange={onChange}
        />
      </Item>
    </div>
  );
};

const CustomNumberRange: FC<{
  disabled: boolean;
  label: ReactNode;
  max?: number;
  maxDecimals?: number;
  min?: number;
  name: NamePath;
  onChange?: (range: [number, number]) => void;
  onCustomChange: (range: [number, number]) => void;
  required: boolean;
  value?: number;
}> = (props) => {
  const [form, setForm] = useState<FormInstance<FormRange>>(null);
  const { element, shifted: shiftedFromHook } = useLabel({ value: props.value });
  const [shifted, setShifted] = useState<boolean>(shiftedFromHook);
  const [isPopupVisible, setPopupVisible] = useState<boolean>(false);

  const formStart: number = props.value && props.value[0];
  const formEnd: number = props.value && props.value[1];
  const [start, setStart] = useState<number>(formStart);
  const [end, setEnd] = useState<number>(formEnd);

  const isUpdateDisabled: boolean =
    (!start && start !== 0) || (!end && end !== 0) || (start === formStart && end === formEnd);

  const isResetDisabled: boolean = !start && start !== 0 && !end && end !== 0;

  useEffect(() => {
    setStart(formStart);
    setEnd(formEnd);
  }, [formStart, formEnd]);

  useEffect(() => {
    setShifted(shiftedFromHook || (isPopupVisible && !props.disabled));
  }, [isPopupVisible, shiftedFromHook]);

  return (
    <Popup
      placement="bottom"
      light={true}
      visible={!props.disabled && isPopupVisible}
      onVisibleChange={setPopupVisible}
      content={
        <Form
          name={`${props.name}-range`}
          onFormInstance={setForm}
          onChange={(f: FormRange) => {
            setStart(f.start);
            setEnd(f.end);
          }}
        >
          <Row className="mb-sm" uat="number-range-popup">
            <Col xxs={6}>
              <InputNumber
                label={<Trans id="range_picker.from">From</Trans>}
                name="start"
                onChange={setStart}
                min={props.min}
                maxDecimals={props.maxDecimals}
                compact={true}
                uat="number-start"
              />
            </Col>

            <Col xxs={6}>
              <InputNumber
                label={<Trans id="range_picker.to">to</Trans>}
                name="end"
                onChange={setEnd}
                max={props.max}
                maxDecimals={props.maxDecimals}
                compact={true}
                uat="number-end"
              />
            </Col>
          </Row>

          <Row>
            <Col xxs={6}>
              <ButtonSecondary
                fullWidth={true}
                icon={null}
                size="sm"
                disabled={isResetDisabled}
                loading={null}
                onClick={() => {
                  props.onChange([undefined, undefined]);
                  props.onCustomChange && props.onCustomChange([undefined, undefined]);
                  form.resetFields();
                  setPopupVisible(false);
                }}
                uat="button-number-reset"
              >
                <Trans id="range_picker.reset">Reset</Trans>
              </ButtonSecondary>
            </Col>

            <Col xxs={6}>
              <ButtonPrimary
                fullWidth={true}
                size="sm"
                disabled={isUpdateDisabled}
                loading={null}
                onClick={() => {
                  props.onChange([start, end]);
                  props.onCustomChange && props.onCustomChange([start, end]);
                  setPopupVisible(false);
                }}
                uat="button-number-update"
              >
                <Trans id="range_picker.update">Update</Trans>
              </ButtonPrimary>
            </Col>
          </Row>
        </Form>
      }
    >
      <div
        className={classnames('tm2-form-field-item-instance', {
          'ant-input-number-disabled': props.disabled,
        })}
        ref={element}
      >
        <Label
          htmlFor={props.name}
          shifted={shifted}
          label={props.label}
          required={props.required}
        />
        {(!formStart && formStart !== 0) || (!formEnd && formEnd !== 0) ? null : (
          <div className="tm2-field-number-range-value">{`${start} — ${end}`}</div>
        )}
      </div>
    </Popup>
  );
};

interface FormRange {
  end: number;
  start: number;
}
