import React from 'react';
import { Trans } from '@lingui/macro';
import { Modal, MFC, ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { ButtonColor, ButtonIconPure } from '@components/button';
import { ReactComponent as WarningIcon } from '@images/icons/warning-icon.svg';
import {
  AddRowButton,
  DeleteRowButton,
  Form,
  FormList,
  InputText,
  SubmitButton,
} from '@components/form';
import { Fields, formId, useModel } from './model';
import { Metal } from '../../create-or-edit/types';
import s from './index.module.scss';

export const EditMetalsModal: MFC<{
  updateMetals: (metals: Array<Metal>) => void;
}> = ({ updateMetals, isVisible }) => {
  const m = useModel(EditMetalsModal, updateMetals);

  return (
    <Modal isVisible={isVisible} disabled={m.lockUI} loading={m.lockUI} onClose={m.onClose}>
      <ModalTitle>
        <Trans id="edit_metals_modal.title">Edit metals</Trans>
      </ModalTitle>

      <ModalContent>
        <Form
          name={formId}
          initialValues={m.formInitial}
          onChange={(formData) => m.setFormInfo(formData)}
        >
          <FormList name={Fields.metalList}>
            {(fields, { add, remove }) => (
              <>
                <div className={s.row}>
                  {fields.map((field, index) => (
                    <div key={field.key} className={s.item}>
                      <InputText
                        name={[field.name, Fields.metalLabel]}
                        fieldKey={[field.key, Fields.metalLabel]}
                        label={<Trans id="edit_metals_modal.input.label.metal">Metal</Trans>}
                        disabled={m.lockUI || !!m.formInfo.metalIds[index]?.haveCoins}
                        required={true}
                      />

                      {!!m.formInfo.metalIds[index]?.haveCoins ? (
                        <ButtonIconPure
                          className="ml2 mr1"
                          color={ButtonColor.primary}
                          icon={<WarningIcon className="tm2-icon-fill" />}
                        />
                      ) : (
                        <DeleteRowButton
                          className="ml1"
                          onClick={() => remove(field.name)}
                          disabled={m.lockUI}
                        />
                      )}
                    </div>
                  ))}
                </div>

                <AddRowButton
                  className="mb3 ml-auto"
                  text={<Trans id="edit_metals_modal.button.add_metal">Add metal</Trans>}
                  disabled={m.lockUI}
                  onClick={() => add()}
                />
              </>
            )}
          </FormList>
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="edit_metals_modal.button.save">Save</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
