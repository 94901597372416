import { DRowCellWrapperProps, DHeaderCellWrapperProps } from '@components/table/autosized';
import s from '../../index.module.scss';

// TODO: pass css var value to estimate function?
export const VERTICAL_PADDING = 10;
export const LINE_HEIGHT_PX = 21;

const rowCellStyle = {
  paddingTop: VERTICAL_PADDING,
  paddingBottom: VERTICAL_PADDING,
  paddingRight: 10,
  lineHeight: LINE_HEIGHT_PX + 'px',
  textAlign: 'left',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
} as const;

export const RowCellContainer = (props: DRowCellWrapperProps<any>) => {
  return <div style={{ ...rowCellStyle, width: props.width }}>{props.children}</div>;
};

export const HeaderCellContainer = (props: DHeaderCellWrapperProps) => {
  return (
    <div className={s.headerCell} style={{ width: props.width }}>
      {props.children}
    </div>
  );
};
