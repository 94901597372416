import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { defineMessage } from '@lingui/macro';

import { PermissionGroup } from '@permissions/core';
import { configSelectors } from '@config/core';
import { PageContent, PageTitle } from '@components/page';
import { useCallDispatch } from '@hooks/core';
import { actions } from './store';
import { permissionGroup as tablePermissionGroup, Table } from './table';
import { ActionsRow, permissionGroup as ActionsPermissionsGroup } from './actions-row';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [ActionsPermissionsGroup, tablePermissionGroup],
  marker: 'layout:payments-report',
});

export const PaymentsPage: FC = memo(() => {
  const i18n = useSelector(configSelectors.i18n);
  const onPageLeft = useCallDispatch(actions.onPageLeft);

  useEffect(() => {
    return () => {
      onPageLeft();
    };
  }, []); // eslint-disable-line

  return (
    <PageContent uat="statements-payments-page">
      <PageTitle
        title={i18n._(defineMessage({ id: 'router.statements.payments', message: 'Payments' }))}
        backwardLink="/statements"
      />
      <ActionsRow className="mb-sm" />
      <Table />
    </PageContent>
  );
});
