import { useEffect, useState } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n, useList, usePage } from 'containers/services/commonService';
import {
  approveBidOrderMutation,
  bidOrdersCoinsQuery,
  bidOrdersPageQuery,
  offersCoinsQuery,
  offersPageQuery,
  cancelOfferMutation,
  denyBidOrderMutation,
} from 'containers/services/graphqlFacade';
import gqlFields from './fields';
import { Offer } from './offers-list/place-offer-modal/api';

const {
  offersCoinsFields,
  bidOrdersCoinsFields,
  offersPageFields,
  bidOrdersPageFields,
  cancelOfferFields,
  approveBidOrderFields,
  denyBidOrderFields,
} = gqlFields;

export const useOffersStatuses = () => {
  const [offerStatuses, setOfferStatuses] = useState({});
  const { i18n, lang } = useI18n();

  useEffect(() => {
    setOfferStatuses({
      ACTIVE_PENDING: i18n._(
        defineMessage({ id: 'bid_status.active_pending', message: 'Active pending' })
      ),
      ACTIVE: i18n._(defineMessage({ id: 'bid_status.active', message: 'Active' })),
      ACTIVE_ERROR: i18n._(
        defineMessage({ id: 'bid_status.active_error', message: 'Active error' })
      ),
      CLOSE_PENDING: i18n._(
        defineMessage({ id: 'bid_status.close_pending', message: 'Close pending' })
      ),
      CLOSED: i18n._(defineMessage({ id: 'bid_status.closed', message: 'Closed' })),
      CLOSE_ERROR: i18n._(defineMessage({ id: 'bid_status.close_error', message: 'Close error' })),
      COMPLETED: i18n._(defineMessage({ id: 'bid_status.completed', message: 'Complete' })),
    });
  }, [lang]); // eslint-disable-line

  return offerStatuses;
};

export const useBidOrderStatuses = () => {
  const [bidOrderStatuses, setBidOrderStatuses] = useState({});
  const { i18n, lang } = useI18n();

  useEffect(() => {
    setBidOrderStatuses({
      CREATED: i18n._(defineMessage({ id: 'bid_order_status.created', message: 'Created' })),
      CREATION_PENDING: i18n._(
        defineMessage({ id: 'bid_order_status.creation_pending', message: 'Creation pending' })
      ),
      CREATION_ERROR: i18n._(
        defineMessage({ id: 'bid_order_status.creation_error', message: 'Creation error' })
      ),
      APPROVE_PENDING: i18n._(
        defineMessage({ id: 'bid_order_status.approve_pending', message: 'Approve pending' })
      ),
      APPROVED: i18n._(defineMessage({ id: 'bid_order_status.approved', message: 'Approved' })),
      APPROVING_ERROR: i18n._(
        defineMessage({ id: 'bid_order_status.approving_error_v2', message: 'Approval error' })
      ),
      DENY_PENDING: i18n._(
        defineMessage({ id: 'bid_order_status.deny_pending', message: 'Deny pending' })
      ),
      DENIED: i18n._(defineMessage({ id: 'bid_order_status.denied', message: 'Denied' })),
      DENYING_ERROR: i18n._(
        defineMessage({ id: 'bid_order_status.denying_error', message: 'Denying error' })
      ),
    });
  }, [lang]); // eslint-disable-line

  return bidOrderStatuses;
};

export const useOffersCoins = () =>
  useList((wsInstance) => offersCoinsQuery(wsInstance, offersCoinsFields), []);
export const useBidOrdersCoins = () =>
  useList((wsInstance) => bidOrdersCoinsQuery(wsInstance, bidOrdersCoinsFields), []);
export const useOffersPage = ({ status, asset: assetId, company: issuerId }: OffersFilter) =>
  usePage<Offer>(offersPageQuery, offersPageFields, [status, assetId, issuerId]);

export const useBidOrdersPage = ({ status, coinId }) =>
  usePage(bidOrdersPageQuery, bidOrdersPageFields, [status, coinId]);
export const cancelOffer = async (wsInstance, id) =>
  cancelOfferMutation(wsInstance, id, cancelOfferFields);
export const approveBidOrder = async (wsInstance, bidOrderId, bankTxId) =>
  approveBidOrderMutation(wsInstance, bidOrderId, bankTxId, approveBidOrderFields);
export const denyBidOrder = async (wsInstance, bidOrderId, reason) =>
  denyBidOrderMutation(wsInstance, bidOrderId, reason, denyBidOrderFields);

export interface OffersFilter {
  asset?: string;
  company?: string;
  status?: string;
}
