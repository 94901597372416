import { useDispatch } from 'react-redux';
import { showModalAction, closeModalAction } from '@modules/modal';
import { BidOrderStatus } from '../../shared/types';
import { ApproveBidOrderModal } from '../../modals/approve-bid-order';
import { DenyBidOrderModal } from '../../modals/deny-bid-order';

export { permissionGroup as approveBidOrderPermissionGroup } from '../../modals/approve-bid-order';
export { permissionGroup as denyBidOrderPermissionGroup } from '../../modals/deny-bid-order';

// TODO: move typings to modals
type OnBidOrderUpdate = (params: { bidOrderId: number; status: BidOrderStatus }) => void;
export type OnBidOrderApproved = OnBidOrderUpdate;
export type OnBidOrderDenied = OnBidOrderUpdate;

export type ModelParams = {
  bidOrderId: number;
  onBidOrderApproved: OnBidOrderApproved;
  onBidOrderDenied: OnBidOrderDenied;
};

export const useStatusActionsModel = (params: ModelParams) => {
  const dispatch = useDispatch();

  const showApproveBidOrderModal = () =>
    dispatch(
      showModalAction({
        modal: ApproveBidOrderModal as any,
        props: {
          bidOrderId: params.bidOrderId,
          onBidOrderApproved: params.onBidOrderApproved,
          onClose: () => dispatch(closeModalAction(ApproveBidOrderModal)),
        },
      })
    );

  const showDenyBidOrderModal = () =>
    dispatch(
      showModalAction({
        modal: DenyBidOrderModal as any,
        props: {
          bidOrderId: params.bidOrderId,
          onBidOrderDenied: params.onBidOrderDenied,
          onClose: () => dispatch(closeModalAction(DenyBidOrderModal)),
        },
      })
    );

  return {
    showApproveBidOrderModal,
    showDenyBidOrderModal,
  };
};
