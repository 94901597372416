import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';

import { ColumnType } from '@components/table2/base';
import { useBankAccountTypeTranslations } from '../../hooks';
import { useBankAccountStatusTranslations } from '../hooks';
import { TableActions } from './actions';
import { CMBankAccount, Column } from './api';

const noSorting = [];
export const useColumns = () => {
  const statuses = useBankAccountStatusTranslations();
  const types = useBankAccountTypeTranslations();

  const columns: Array<ColumnType<CMBankAccount>> = useMemo(
    () => [
      {
        key: 'actions',
        dataIndex: 'actions',
        headCell: () => null,
        rowCell: ({ data }) => <TableActions account={data} />,
        sorting: noSorting,
        width: 30,
      },
      {
        key: Column.legalEntity,
        dataIndex: Column.legalEntity,
        headCell: () => <Trans id="cm_bank_accounts.table.legal_entity">Legal entity</Trans>,
        rowCell: ({ data }) => <>{data.legalEntity}</>,
        width: 135,
      },
      {
        key: Column.swift,
        dataIndex: Column.swift,
        headCell: () => <Trans id="cm_bank_accounts.table.swift_bic">SWIFT/BIC</Trans>,
        rowCell: ({ data }) => <>{data.bank.swiftBic}</>,
        width: 125,
      },
      {
        key: Column.bankName,
        dataIndex: Column.bankName,
        headCell: () => <Trans id="cm_bank_accounts.table.legal bank_name">Bank name</Trans>,
        rowCell: ({ data }) => <>{data.bank.bankName}</>,
        width: 150,
      },
      {
        key: Column.accountNumber,
        dataIndex: Column.accountNumber,
        headCell: () => <Trans id="cm_bank_accounts.table.account_number">Account number</Trans>,
        rowCell: ({ data }) => <>{data.accountNumber}</>,
        width: 175,
      },
      {
        key: Column.type,
        dataIndex: Column.type,
        headCell: () => <Trans id="cm_bank_accounts.table.type">Account type</Trans>,
        rowCell: ({ data }) => <>{types[data.type]}</>,
        width: 150,
      },
      {
        key: Column.currency,
        dataIndex: Column.currency,
        headCell: () => <Trans id="cm_bank_accounts.table.currency">Currency</Trans>,
        rowCell: ({ data }) => <>{data.currency.code}</>,
        width: 115,
      },
      {
        key: Column.currentBalance,
        dataIndex: Column.currentBalance,
        headCell: () => <Trans id="cm_bank_accounts.table.current_balance">Current balance</Trans>,
        rowCell: ({ data }) => <>{data.currentBalance}</>,
        width: 170,
      },
      {
        key: Column.calculatedBalance,
        dataIndex: Column.calculatedBalance,
        headCell: () => (
          <Trans id="cm_bank_accounts.table.calculated_balance">Calculated balance</Trans>
        ),
        rowCell: ({ data }) => <>{data.computedFields.calculatedBalance}</>,
        sorting: noSorting,
        width: 175,
      },
      {
        key: Column.status,
        dataIndex: Column.status,
        headCell: () => <Trans id="cm_bank_accounts.table.status">Status</Trans>,
        rowCell: ({ data }) => <>{statuses[data.status]}</>,
        width: 95,
      },
      {
        key: Column.comment,
        dataIndex: Column.comment,
        headCell: () => <Trans id="cm_bank_accounts.table.comment">Comment</Trans>,
        rowCell: ({ data }) => <>{data.comment}</>,
        tooltip: 'overflow',
        width: 120,
      },
      {
        key: Column.publicKey,
        dataIndex: Column.publicKey,
        headCell: () => <Trans id="cm_bank_accounts.table.public_key">Public key</Trans>,
        rowCell: ({ data }) => <>{data.minfiniumPublicKey}</>,
        tooltip: 'overflow',
        width: 120,
      },
    ],
    [statuses, types]
  );

  return {
    columns,
  };
};
