import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import card from '../index.module.scss';
import s from './index.module.scss';
import { HasAccess } from '@permissions/core';
import { ActionItem, MoreActions } from '@components/more-actions';
import { Title } from '@components/typography/title';
import { Profile } from '../../../api.shared';
import { useModel } from './model';

const _actions = [];
export type TariffInfoProps = {
  actions?: Array<ActionItem>;
  profile: Profile;
};
export const TariffInfo: FC<TariffInfoProps> = memo<TariffInfoProps>(
  ({ actions = _actions, profile }) => {
    const m = useModel(profile);

    return (
      <HasAccess
        permissions={m.componentPermissions}
        roles={m.componentRoles}
        userPermissions={profile?.permissions}
        userRole={profile?.role?.name}
      >
        <Title className="mt3" level={4} color="secondary">
          <Trans id="profile.card.membership">Membership type</Trans>
        </Title>

        <div className={classnames(card.box, s.tariff)}>
          <div>
            <div data-uat="tariff-type-label">{m.tariffName}</div>
            <span data-uat="tariff-expiration-label">
              <Trans id="profile.card.tariff.expire">Valid till {m.expireDate}</Trans>
            </span>
          </div>

          <div className="ml-auto">
            <MoreActions placement="right" list={actions} />
          </div>
        </div>
      </HasAccess>
    );
  }
);
