import React from 'react';
import { Trans } from '@lingui/macro';
import { Form, InputNumber, SubmitButton } from '@components/form';
import { formatToken } from 'containers/services/commonService';
import { dischargeTokenFormId, useDigitalMetalsDischargeModalModel } from './model';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';

export const DischargeTokenModal = ({ coin, isVisible }) => {
  const { hideModal, lockUI, onDischargeTokenFormAction, dischargeLimit } =
    useDigitalMetalsDischargeModalModel({ coin });

  return (
    <Modal isVisible={isVisible} disabled={lockUI} loading={lockUI} onClose={hideModal}>
      <ModalTitle>
        <Trans id="discharge_token_modal.title">Discharge {coin.asset}</Trans>
      </ModalTitle>

      <ModalContent>
        <Trans id="discharge_token_modal.text">
          Please enter the amount of digital metals you want to discharge form the system
        </Trans>
        <Form name={dischargeTokenFormId}>
          <small className="field-tip">
            <Trans id="discharge_token_modal.max_available">
              Max available {formatToken(dischargeLimit.max)}
            </Trans>
          </small>
          <InputNumber
            name="amount"
            label={<Trans id="discharge_token_modal.amount">Amount</Trans>}
            required={true}
            disabled={lockUI}
            min={dischargeLimit.min}
            max={dischargeLimit.max}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={dischargeTokenFormId}
          fullWidth={true}
          disabled={lockUI}
          onAction={onDischargeTokenFormAction}
        >
          <Trans id="discharge_token_modal.discharge">Discharge</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
