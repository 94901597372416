import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { defineMessage, Trans } from '@lingui/macro';
import { configSelectors } from '@config/core';
import { Role } from '@permissions/core';
import { PageContent, PageTitle } from '@components/page';
import { useI18n } from 'containers/services/commonService';
import s from './index.module.scss';
import { Box } from '@components/box';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ButtonTextIconBig } from '@components/button';
import { useHistory } from 'react-router';
import classnames from 'classnames';
import { CoinsTable } from '../../coins-table';

export const CoinsTablePage: FC = () => {
  const { i18n } = useI18n();
  const role: Role = useSelector(configSelectors.userRole);
  const history = useHistory();

  return (
    <PageContent uat="digital-metals-coins-table-page">
      <PageTitle title={i18n._(defineMessage({ id: 'router.metals', message: 'Metals' }))} />

      {role === Role.admin && (
        <Box className={classnames(s.actionsWrapper, 'mb-md')}>
          <ButtonTextIconBig
            className={s.addSymbol}
            icon={<AddIcon className="tm2-icon-stroke" />}
            onClick={() => history.push('/digital-metals/new')}
          >
            <Trans id="digital_metals.button.add">Add digital metal</Trans>
          </ButtonTextIconBig>
        </Box>
      )}

      <CoinsTable />
    </PageContent>
  );
};
