import { FC } from 'react';
import classnames from 'classnames';
import {
  default as InputDateRangeRaw,
  InputDateRangeProps,
} from '@components/form/antd-form/input-date-range';

import shared from '../index.module.scss';
import s from './index.module.scss';

const SearchDateRange: FC<InputDateRangeProps> = (props) => {
  const { className, ...p } = props;

  return (
    <InputDateRangeRaw
      {...p}
      className={className}
      compactClass={shared.compact}
      instanceClass={s.rangePicker}
      itemClass={s.item}
      labelClass={classnames(shared.label, s.label)}
      labelShiftedClass={classnames(shared.labelShifted, s.labelShifted)}
      wrapperClass={classnames(shared.wrapper, { [shared.disabled]: p.disabled })}
    />
  );
};
export default SearchDateRange;
