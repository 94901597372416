import reduceReducers from 'reduce-reducers';
import { all, fork } from 'redux-saga/effects';

import { redeemModalReducer } from './store/reducer';
import { redeemModalEffects } from './store/effects';
import { redeemModalStateInitial } from './store/model';
import { redeemOptionsModalReducer } from './redeem-options-modal/store/reducer';
import { redeemOptionsModalEffects } from './redeem-options-modal/store/effects';
import { redeemOptionsModalStateInitial } from './redeem-options-modal/store/model';
import { redeemConfirmationModalReducer } from './redeem-confirmation-modal/store/reducer';
import { redeemConfirmationModalEffects } from './redeem-confirmation-modal/store/effects';
import { redeemConfirmationModalStateInitial } from './redeem-confirmation-modal/store/model';
import { finishRedeemModalStateInitial } from './redeem-finish-modal/store/model';
import { finishRedeemModalReducer } from './redeem-finish-modal/store/reducer';
import { finishRedeemModalEffects } from './redeem-finish-modal/store/effects';

export const redeemModalsReducer = reduceReducers(
  {
    redeemModal: redeemModalStateInitial,
    finishRedeemModal: finishRedeemModalStateInitial,
    redeemConfirmationModal: redeemConfirmationModalStateInitial,
    redeemOptionsModal: redeemOptionsModalStateInitial,
  },
  redeemModalReducer,
  finishRedeemModalReducer,
  redeemConfirmationModalReducer,
  redeemOptionsModalReducer
);

export function* redeemModalsEffects() {
  yield all([
    fork(redeemModalEffects),
    fork(finishRedeemModalEffects),
    fork(redeemConfirmationModalEffects),
    fork(redeemOptionsModalEffects),
  ]);
}
