import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from '../../finish.module.scss';
import {
  closeModalAction,
  MFC,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from '@modules/modal';
import { Divider } from '@components/divider';
import { SubmitButton } from '@components/form';
import { DispatchFn, RootState } from '@models/redux';
import { formatMoney } from 'containers/services/commonService';
import { transferStableFormId } from '../store/model';
import { onFinishStableTransferButtonClicked } from '../store/effects';
import { transferStableTabSelectors as stableSelectors } from '../store/selectors';
import { transferStableTabUserActions as stableUserActions } from '../store/user.acions';
import { TransferDirection } from '../../model';
import { useFinishModalModel } from './model';

const mapStateToProps = (root: RootState) => ({
  direction: stableSelectors.transferDirection(root),
  lockUI: stableSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onCancelClicked: () => dispatch(stableUserActions.onCancelFinishStableTransferClicked()),
  onCloseModalClicked: () => dispatch(closeModalAction()),
});

const ConnectedFinishStableTransferModal: MFC<{
  direction: string;
  lockUI: boolean;
  onCancelClicked: DispatchFn;
  onCloseModalClicked: DispatchFn<void>;
}> = ({ isVisible, direction, lockUI, onCancelClicked, onCloseModalClicked }) => {
  const {
    primaryMarketSign,
    secondaryMarketSign,
    newPrimaryMarketBalance,
    newSecondaryMarketBalance,
    asset,
    amount,
  } = useFinishModalModel(direction);

  return (
    <Modal isVisible={isVisible} disabled={lockUI} loading={lockUI} onClose={onCloseModalClicked}>
      <ModalTitle onBack={onCancelClicked}>
        <Trans id="finish_transfer_stable_modal.title">Transfer funds</Trans>
      </ModalTitle>

      <ModalContent>
        <div className={classnames(s.title, 'mb-sm')}>
          <Trans id="finish_transfer_stable_modal.label.summary">Summary</Trans>
        </div>

        <div className={classnames(s.row, 'mb-sm')}>
          <div className={s.summaryLabel}>
            <Trans id="finish_transfer_stable_modal.label.from">From</Trans>
          </div>

          <div className={s.summaryValue}>
            {direction === TransferDirection.fromPrimary ? (
              <Trans id="finish_transfer_stable_modal.label.primary_market">Primary Market</Trans>
            ) : (
              <Trans id="finish_transfer_stable_modal.label.secondary_market">
                Secondary Market
              </Trans>
            )}
          </div>
        </div>

        <div className={classnames(s.row, 'mb-sm')}>
          <div className={s.summaryLabel}>
            <Trans id="finish_transfer_metal_modal.label.to">To</Trans>
          </div>

          <div className={s.summaryValue}>
            {direction === TransferDirection.fromPrimary ? (
              <Trans id="finish_transfer_stable_modal.label.secondary_market">
                Secondary Market
              </Trans>
            ) : (
              <Trans id="finish_transfer_stable_modal.label.primary_market">Primary Market</Trans>
            )}
          </div>
        </div>

        <div className={classnames(s.row, 'mb-sm')}>
          <div className={s.summaryLabel}>
            <Trans id="finish_transfer_stable_modal.label.amount">Amount</Trans>
          </div>

          <div className={s.summaryValue}>{formatMoney(amount, { pre: asset })}</div>
        </div>

        <Divider />

        <div className={classnames(s.row, 'mb-sm')}>
          <div className={s.dataLabel}>
            <Trans id="finish_transfer_stable_modal.label.new_primary_stable_balance">
              New primary market funds
            </Trans>
          </div>

          <div className={s.dataValue}>
            {formatMoney(newPrimaryMarketBalance, { pre: asset })} ({primaryMarketSign}
            {amount})
          </div>
        </div>

        <div className={classnames(s.row, 'mb-sm')}>
          <div className={s.dataLabel}>
            <Trans id="finish_transfer_stable_modal.label.new_secondary_stable_balance">
              New secondary market funds
            </Trans>
          </div>

          <div className={s.dataValue}>
            {formatMoney(newSecondaryMarketBalance, { pre: asset })} ({secondaryMarketSign}
            {amount})
          </div>
        </div>

        <Divider />

        <div className={classnames(s.row, 'mb-sm')}>
          <Trans id="finish_transfer_modal.label.note_funds">
            Note: funds transferred to the secondary market will show immediately on your secondary
            market account. All other transactions (funds and metals) may take up to 3 business
            days.
          </Trans>
        </div>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={transferStableFormId}
          fullWidth={true}
          disabled={lockUI}
          onAction={onFinishStableTransferButtonClicked}
        >
          <Trans id="finish_transfer_stable_modal.button.confirm_transfer">Confirm transfer</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};

export const FinishStableTransferModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedFinishStableTransferModal);
