import React from 'react';
import { useI18n } from 'containers/services/commonService';
import { defineMessage } from '@lingui/macro';
import { TransferRequestsActions } from './actions-block';
import { TransferRequestsTable } from './table';
import { PageContent, PageTitle } from '@components/page';

export const TransferRequestsPage = () => {
  const { i18n } = useI18n();

  return (
    <PageContent className="documents-page" uat="transfer-requests-page">
      <PageTitle
        title={i18n._(
          defineMessage({ id: 'router.transfer_requests', message: 'Transfer requests' })
        )}
      />

      <TransferRequestsActions />

      <TransferRequestsTable />
    </PageContent>
  );
};
