import { redeemModalSelectors as redeemSelectors } from '../../store/selectors';
import { redeemOptionsModalSelectors as redeemOptionsSelectors } from '../../redeem-options-modal/store/selectors';

const redeemConfirmationModalState = (root) =>
  root.main?.wallet?.redeemModals?.redeemConfirmationModal;

const asset = (root) => redeemSelectors.redeemBalance(root)?.coin?.asset;

const canConfirm = (root) => {
  const totalFiat = totalInFiat(root);
  const totalMetal = totalInMetal(root);
  const balanceFiat = fiatBalance(root);
  const balanceRedeem = redeemSelectors.redeemBalance(root);
  const redeemOptions = redeemOptionsSelectors.storedRedeemOption(root);
  const minimalQuantity = redeemOptions.minimalQuantity;
  const options = optionsQuantity(root);
  return (
    balanceRedeem.balance >= totalMetal && balanceFiat >= totalFiat && options >= minimalQuantity
  );
};

const confirmedRedeemData = (root) => ({
  asset: asset(root),
  isPriceMetal: isPriceMetal(root),
  redeemOptionId: redeemOptionsSelectors.storedRedeemOption(root).id,
  totalInMetal: totalInMetal(root),
  quantity: optionsQuantity(root),
});

const fiatBalance = (root) => redeemConfirmationModalState(root).fiatBalance;

const isPriceMetal = (root) =>
  redeemOptionsSelectors.storedRedeemOption(root)?.priceType === 'METAL';

const lockUI = (root) => redeemConfirmationModalState(root).isStableBalanceLoading;

const metalQuantity = (root) => redeemConfirmationModalState(root).metalQuantity;

const minimalQuantity = (root) => redeemOptionsSelectors.storedRedeemOption(root).minimalQuantity;

const optionsQuantity = (root) => redeemConfirmationModalState(root).optionsQuantity;

const price = (root) => redeemOptionsSelectors.storedRedeemOption(root).price;

const storedConfirmedRedeemData = (root) =>
  redeemConfirmationModalState(root).storedData.confirmedRedeemData;

const totalInFiat = (root) => {
  const isMetal = isPriceMetal(root);
  const redeemOptions = redeemOptionsSelectors.storedRedeemOption(root);
  const options = optionsQuantity(root);
  const price = redeemOptions.price;
  return isMetal ? 0 : options * price;
};

const totalInMetal = (root) => {
  const isMetal = isPriceMetal(root);
  const metalPrice = redeemOptionsSelectors.storedMetalPrice(root);
  const redeemOptions = redeemOptionsSelectors.storedRedeemOption(root);
  const options = optionsQuantity(root);
  const price = redeemOptions.price;
  return isMetal ? options * (price + metalPrice) : options * metalPrice;
};

export const redeemConfirmationModalSelectors = {
  asset,
  canConfirm,
  confirmedRedeemData,
  fiatBalance,
  isPriceMetal,
  lockUI,
  metalQuantity,
  minimalQuantity,
  optionsQuantity,
  price,
  redeemConfirmationModalState,
  storedConfirmedRedeemData,
  totalInFiat,
  totalInMetal,
};
