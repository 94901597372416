import React, { FC, ReactElement, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { FormInstance } from 'antd';

import { handleBackendError } from '@modules/notify';
import { ButtonPrimary } from '@components/button';
import { ValidationErrorItem } from '@components/form/antd-form/validation-message';
import { ValidationError } from '@services/stomp/errors';
import { formService, handleValidationErrors, OnFormAction } from '../../index';
import { FieldValidationConfig } from '../../validators';

export const SubmitButton: FC<{
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: ReactNode;
  name: string;
  onAction: OnFormAction;
  scrollToFirstError?: boolean;
  uat?: string;
}> = ({
  children,
  className,
  disabled,
  fullWidth,
  icon,
  name,
  onAction,
  uat,
  scrollToFirstError,
}) => {
  const dispatch = useDispatch();

  const onSubmit = () => {
    return async (dispatch, getState) => {
      try {
        await formService.clearErrors(name);
        const instance: FormInstance = formService.get(name);
        const formPure = instance.getFieldsValue();
        const formParsed = formService.convertAntdFlatDataToOur(formPure);
        if (onAction.fieldValidation) {
          await onFieldValidation(formParsed, onAction.fieldValidation);
        }
        await onAction.submit(formParsed, dispatch, getState);
      } catch (error) {
        handleValidationErrors(error, name);
        handleBackendError(error);

        if (error instanceof ValidationError && scrollToFirstError) {
          const form = formService.get(name);
          const errors = form.getFieldsError();
          const fieldName = errors[0].name;
          form.scrollToField(fieldName, {
            behavior: 'smooth',
            block: 'start',
            inline: 'start',
          });
        }
        if (onAction.error) {
          // TODO-2833: fix types
          // @ts-ignore
          await onAction.error(error, dispatch, getState);
        }
      } finally {
        if (onAction.finally) {
          await onAction.finally(dispatch, getState);
        }
      }
    };
  };

  return (
    <ButtonPrimary
      className={className}
      fullWidth={fullWidth}
      icon={icon as ReactElement<HTMLOrSVGElement>}
      disabled={disabled}
      onClick={() => dispatch(onSubmit())}
      uat={uat || `${name}-submit-button`}
    >
      {children}
    </ButtonPrimary>
  );
};

function onFieldValidation(form: object, config: FieldValidationConfig) {
  let errors: Array<ValidationErrorItem> = [];

  Object.entries(config).forEach(([field, validators]) => {
    validators.forEach((validator) => validator(errors, form, field));
  });

  if (errors.length) {
    throw new ValidationError({ data: errors });
  }
}
