import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form as AntForm } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { RadioBox, RadioBoxItem } from '@components/radio-box';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const Radio: FC<{
  className?: string;
  deprecatedStyles?: boolean;
  data: Array<RadioItem>;
  disabled?: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (value) => void;
  required?: boolean;
  uat?: string;
}> = ({
  className,
  deprecatedStyles = true,
  data,
  disabled,
  label,
  name,
  onChange: onCustomChange,
  required,
  uat,
}) => {
  const uatAttribute = useFieldUat(name, 'radio', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-radio-single', className, {
        'tm2-field-radio-single-no-label': !label,
      })}
      data-uat={uatAttribute}
    >
      <Item
        className="tm2-form-field-item tm2-field-radio-single-item"
        name={name}
        required={required}
      >
        <CustomRadio
          name={name}
          label={label}
          required={required}
          deprecatedStyles={deprecatedStyles}
          disabled={disabled}
          onCustomChange={onCustomChange}
          data={data}
        />
      </Item>
    </div>
  );
};

const CustomRadio: FC<{
  data: Array<RadioItem>;
  deprecatedStyles?: boolean;
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (v) => void;
  onCustomChange: (v) => void;
  required: boolean;
  value?: string;
}> = (props) => {
  return (
    <>
      <div className="tm2-form-field-item tm2-field-radio-single-item-label mb-xs">
        {props.label}
        {!props.required ? null : <span className="tm2-form-label-asterisk">&nbsp;*</span>}
      </div>

      <RadioBox
        defaultActiveKey={props.value}
        activeKey={props.value}
        deprecatedStyles={props.deprecatedStyles}
        onChange={(value) => {
          props.onChange(value);
          props.onCustomChange && props.onCustomChange(value);
        }}
      >
        {props.data.map((item: RadioItem) => (
          <RadioBoxItem header={item.label} key={item.value} children={item.extraContent} />
        ))}
      </RadioBox>
    </>
  );
};

export interface RadioItem {
  extraContent?: ReactNode;
  label: ReactNode;
  value: string | number;
}
