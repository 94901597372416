import { useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import {
  AddRowButton,
  FormList,
  InputHidden,
  InputText,
  Select,
  SelectItem,
} from '@components/form';
import { Col, Row } from '@components/grid';
import { permissionsSelectors } from '@permissions/store/selectors';

import { fields, FormContext } from '../../form';
import { RemoveButton } from '../redemption/remove-button';
import { TechInfoContext } from './context';
import s from './index.module.scss';
import { UMSAssetRow } from './ums-asset';
import { permissionGroup as umsAssetPermissionGroup } from './ums-asset';

export const permissionGroup = umsAssetPermissionGroup.createSuperGroup({
  marker: 'layout:create-or-edit-tech-info',
});

type TechInfoTabInternalProps = {
  selectedCurrencyIds: (number | null)[];
  addBtnContainer: HTMLDivElement | null;
  isEditMode: boolean;
};

export function TechInfoTabInternal(props: TechInfoTabInternalProps) {
  const {
    state: { data, lockUI },
  } = useContext(FormContext);

  const currencySelectItems = useMemo<SelectItem[]>(() => {
    return data?.currencies.map((c) => ({
      label: c.code,
      value: c.id,
      disabled: props.selectedCurrencyIds.includes(c.id),
    }));
  }, [data?.currencies, props.selectedCurrencyIds]);

  const isHasAccessFn = useSelector(permissionsSelectors.isHasAccess);

  const isAllowUMSAssetCreation = isHasAccessFn({ and: [umsAssetPermissionGroup] });

  return (
    <div>
      {!isAllowUMSAssetCreation ? null : <UMSAssetRow isEditMode={props.isEditMode} />}
      <FormList name={fields.techInfo.cqgSymbols}>
        {(listFields, { add, remove }) => (
          <>
            {listFields.map(({ key, name }) => (
              <div key={key}>
                <InputHidden
                  name={[name, fields.techInfo.cqgSymbolId]}
                  fieldKey={[key, fields.techInfo.cqgSymbolId]}
                />
                <Row>
                  <Col md={6}>
                    <InputText
                      name={[name, fields.techInfo.cqgSymbol]}
                      fieldKey={[key, fields.techInfo.cqgSymbol]}
                      label={<Trans id="coin_tech_info.cqg.symbol">CQG symbol</Trans>}
                      required
                      disabled={lockUI}
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      name={[name, fields.techInfo.cqgCurrency]}
                      fieldKey={[key, fields.techInfo.cqgCurrency]}
                      label={<Trans id="coin_tech_info.cqg.currency">CQG currency</Trans>}
                      required
                      data={currencySelectItems}
                      disabled={lockUI}
                    />
                  </Col>
                </Row>
                <InputText
                  name={[name, fields.techInfo.cqgContractName]}
                  fieldKey={[key, fields.techInfo.cqgContractName]}
                  label={<Trans id="coin_tech_info.cqg.contract_name">CQG contract name</Trans>}
                  required
                  disabled={lockUI}
                />
                <div className={classnames(s.removeSymbolRow, 'mb5')}>
                  <RemoveButton onClick={() => remove(name)}>
                    <Trans id={'coin_tech_info.remove_cqg_symbol'}>Remove CQG symbol</Trans>
                  </RemoveButton>
                </div>
              </div>
            ))}
            {props.addBtnContainer
              ? createPortal(
                  <AddRowButton
                    text={<Trans id={'coin_tech_info.add_cqg_symbol'}>Add cqg symbol</Trans>}
                    disabled={lockUI}
                    onClick={() => add()}
                  />,
                  props.addBtnContainer
                )
              : null}
          </>
        )}
      </FormList>
    </div>
  );
}

type TechInfoTabProps = {
  addBtnContainer: HTMLDivElement | null;
  isEditMode: boolean;
};

export function TechInfoTab(props: TechInfoTabProps) {
  const { state } = useContext(TechInfoContext);
  const selectedCurrencyIdsKey = state.selectedCqgCurrencyIds.join('_');

  return useMemo(
    () => (
      <TechInfoTabInternal
        addBtnContainer={props?.addBtnContainer}
        isEditMode={props.isEditMode}
        selectedCurrencyIds={state.selectedCqgCurrencyIds}
      />
    ),
    [selectedCurrencyIdsKey, props.addBtnContainer, props.isEditMode]
  );
}
