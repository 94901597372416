import sign from 'tm2sign.macro';

import { stompClientService as stompClient } from '@services/stomp/client';
// ---------------------------------------------------------------------
const sanctions = [
  // https://tm2support.atlassian.net/browse/TM2-2811
  33, // Belarus
  168, // Cuba
  234, // Iran
  108, // Myanmar
  184, // North Korea
  174, // South Sudan
  170, // Syria
];
export interface Country {
  id: number;
  label: string;
}
const countriesFields = sign('countries', ['id', 'label']);
const getCountries: () => Promise<Array<Country>> = () => {
  return stompClient
    .getData<Array<Country>>('countries', countriesFields)
    .then((list) => list.filter((country) => !sanctions.includes(country.id)));
};
// ---------------------------------------------------------------------
export interface Nationality {
  id: number;
  label: string;
}
const nationalitiesFields = sign('countries', ['id', 'label']);
const getNationalities: () => Promise<Array<Nationality>> = () => {
  return stompClient.getData('countries', nationalitiesFields);
};
// ---------------------------------------------------------------------
const api = {
  getCountries,
  getNationalities,
};

export default api;
