import React from 'react';
import {
  CheckboxGroup,
  InputNumber,
  InputText,
  InputTextarea,
  RadioGroup,
  Select,
} from '@components/form';
import { Col, Row } from '@components/grid';
import s from './dynamic-form.module.scss';

export const DynamicForm = ({ formRows, lockUI }) => {
  return formRows.map((row, i) => {
    if (row.richText?.content) {
      return (
        <div
          dangerouslySetInnerHTML={{ __html: row.richText.content }}
          className={s.title}
          key={`key-${i}-text`}
        />
      );
    }

    if (row.fields?.length) {
      return (
        <Row key={`key-${i}-r0`}>
          {row.fields.map((field, j) => (
            <Col sm={row.fields.length > 1 ? 6 : 12} key={`key-${i}-${j}`}>
              {field.fieldType !== 'text' || field.hidden ? null : (
                <InputText
                  name={field.name}
                  label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
                  disabled={lockUI}
                  required={field.required}
                />
              )}

              {field.fieldType !== 'textarea' || field.hidden ? null : (
                <InputTextarea
                  name={field.name}
                  label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
                  disabled={lockUI}
                  required={field.required}
                />
              )}

              {field.fieldType !== 'select' || field.hidden ? null : (
                <Select
                  name={field.name}
                  label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
                  disabled={lockUI}
                  required={field.required}
                  data={field.options}
                />
              )}

              {field.fieldType !== 'number' || field.hidden ? null : (
                <InputNumber
                  name={field.name}
                  label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
                  required={field.required}
                  disabled={lockUI}
                  min={0}
                  maxDecimals={0}
                />
              )}

              {field.fieldType !== 'checkbox' || field.hidden ? null : (
                <CheckboxGroup
                  pattern={{ xs: 1, sm: 2 }}
                  name={field.name}
                  data={field.options}
                  label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
                  required={field.required}
                />
              )}

              {field.fieldType !== 'radio' || field.hidden ? null : (
                <RadioGroup
                  pattern={{ xs: 1, sm: 2 }}
                  name={field.name}
                  data={field.options}
                  label={<span dangerouslySetInnerHTML={{ __html: field.label }} />}
                  required={field.required}
                />
              )}
            </Col>
          ))}
        </Row>
      );
    }
  });
};
