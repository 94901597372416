import {
  createElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
  useRef,
  ComponentType,
  ReactNode,
} from 'react';
import { useCallbackOne } from 'use-memo-one';
import classnames from 'classnames';
import { debounce } from 'lodash';
import { useSyncRef } from '@hooks/core';
import { useTabs, Tabs as BaseTabs, TabsProps as BaseTabsProps, OnInteraction } from './index';
import s from './index.module.scss';
import { useResizeDetector } from 'react-resize-detector';

const RESIZE_DEBOUNCE = 100;

export { TabPane, ForcedRenderPaneWrapper } from './index';
export type { TabPaneProps, TabPaneWrapperProps } from './index';

export type DefaultDataType = ReactNode;

export type TabsHeaderData<T = DefaultDataType> = Array<{ tabKey: string; data: T }>;

export type HeaderContentFit = 'contain' | 'scroll';

export type TabHeaderProps<T = DefaultDataType> = {
  active: boolean;
  data: T;
  contentFit: HeaderContentFit;
  tabKey: string;
  onInteraction: () => void;
};

export type WrapperProps = {
  children: ReactNode;
  uat?: string;
};

export type ContentWrapperProps = {
  children: any;
};

export type HeaderWrapperProps = {
  activeIndex: number;
  children: ReactNode;
  border: boolean;
  contentFit: HeaderContentFit;
};

export type TabsProps<T = DefaultDataType> = {
  border?: boolean;
  data: TabsHeaderData<T>;
  children?: ReactNode;
  headerContentFit?: HeaderContentFit;
  contentWrapperComponent?: ComponentType<ContentWrapperProps>;
  headerWrapperComponent?: ComponentType<HeaderWrapperProps>;
  tabHeaderComponent?: ComponentType<TabHeaderProps<T>>;
  wrapperComponent?: ComponentType<WrapperProps>;
  uat?: string;
} & Pick<
  BaseTabsProps,
  | 'activeKey'
  | 'defaultActiveKey'
  | 'forceRender'
  | 'onChange'
  | 'paneWrapperComponent'
  | 'paneWrapperProps'
>;

export function DefaultTabHeader<T>(props: TabHeaderProps<T> & { className?: string }) {
  return (
    <span
      className={classnames(props.className, s.tabHeader, {
        [s.tabHeaderActive]: props.active,
        [s.tabHeaderScrollable]: props.contentFit === 'scroll',
      })}
      onClick={props.onInteraction}
      data-uat={props.tabKey}
    >
      {props.data}
    </span>
  );
}

type TabHeaderWrapperProps<T> = {
  activeKey: string;
  data: T;
  contentFit: HeaderContentFit;
  onInteraction: OnInteraction;
  tabKey: string;
  tabHeaderComponent: ComponentType<TabHeaderProps<T>>;
};

function TabHeaderWrapper<T>(props: TabHeaderWrapperProps<T>) {
  const tabHeaderProps: TabHeaderProps<T> = {
    active: props.activeKey === props.tabKey,
    data: props.data,
    contentFit: props.contentFit,
    tabKey: props.tabKey,
    onInteraction: () => {
      props.onInteraction(props.tabKey);
    },
  };
  return createElement(props.tabHeaderComponent, tabHeaderProps);
}

export function DefaultHeaderWrapper(props: HeaderWrapperProps & { className?: string }) {
  const activeIndexRef = useSyncRef(props.activeIndex);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollableRef = useRef<HTMLDivElement>(null);

  const [underline, setUnderline] = useState({
    width: 0,
    offset: 0,
  });

  const { width: containerWidth } = useResizeDetector({
    targetRef: containerRef,
    handleHeight: false,
    refreshMode: 'debounce',
    refreshRate: RESIZE_DEBOUNCE,
  });

  const updateUnderline = useCallback(() => {
    if (!containerRef.current) return;

    const children = containerRef.current.children;
    const activeChild = children[activeIndexRef.current] as HTMLElement;

    if (!activeChild) return;

    setUnderline({
      width: activeChild.offsetWidth,
      offset: activeChild.offsetLeft,
    });
  }, []);

  const debouncedUpdateUnderline = useCallbackOne(debounce(updateUnderline, RESIZE_DEBOUNCE), [
    updateUnderline,
  ]);

  useLayoutEffect(() => {
    updateUnderline();
  }, [props.activeIndex, updateUnderline]);

  useEffect(() => {
    debouncedUpdateUnderline();
  }, [containerWidth, debouncedUpdateUnderline]);

  useEffect(() => {
    if (!containerRef.current || !scrollableRef.current) return;

    const children = containerRef.current.children;
    const activeChild = children[activeIndexRef.current] as HTMLElement;

    if (!activeChild) return;

    scrollableRef.current.scrollTo({
      left: activeChild.offsetLeft,
      behavior: 'smooth',
    });
  }, [props.activeIndex]);

  const underlineStyle = {
    width: underline.width,
    transform: `translateX(${underline.offset}px)`,
  };

  const wrapperClasses = classnames(props.className, s.header, {
    [s.headerBordered]: props.border,
  });

  if (props.contentFit === 'scroll') {
    return (
      <div ref={scrollableRef} className={s.headerScrollableContainer}>
        <div ref={containerRef} className={wrapperClasses} style={{ minWidth: 'max-content' }}>
          {props.children}
          <div className={s.underline} style={underlineStyle} />
        </div>
      </div>
    );
  }

  return (
    <div ref={containerRef} className={wrapperClasses}>
      {props.children}
      <div className={s.underline} style={underlineStyle} />
    </div>
  );
}

export function DefaultWrapper(props: WrapperProps & { className?: string }) {
  return (
    <div className={props.className} data-uat={props.uat}>
      {props.children}
    </div>
  );
}

export function DefaultContentWrapper(props: { children: JSX.Element }) {
  return <div>{props.children}</div>;
}

type TabsBindingProps = {
  border: boolean;
  data: TabsHeaderData<any>;
  children: ReactNode;
  headerContentFit: HeaderContentFit;
  contentWrapperComponent?: ComponentType<ContentWrapperProps>;
  headerWrapperComponent: ComponentType<HeaderWrapperProps>;
  tabHeaderComponent: ComponentType<TabHeaderProps<any>>;
  wrapperComponent: ComponentType<WrapperProps>;
  uat?: string;
};

function TabsBinding(props: TabsBindingProps) {
  const { activeKey, onInteraction } = useTabs();
  const activeIndex = props.data.findIndex((entry) => entry.tabKey === activeKey);
  const ContentWrapper = props.contentWrapperComponent;
  const HeaderWrapper = props.headerWrapperComponent;
  const Wrapper = props.wrapperComponent;

  return (
    <Wrapper uat={props.uat}>
      <HeaderWrapper
        activeIndex={activeIndex}
        border={props.border}
        contentFit={props.headerContentFit}
      >
        {props.data.map(({ data, tabKey }) => (
          <TabHeaderWrapper
            key={tabKey}
            activeKey={activeKey}
            data={data}
            contentFit={props.headerContentFit}
            onInteraction={onInteraction}
            tabKey={tabKey}
            tabHeaderComponent={props.tabHeaderComponent}
          />
        ))}
      </HeaderWrapper>
      <ContentWrapper>{props.children}</ContentWrapper>
    </Wrapper>
  );
}

export function Tabs<T = DefaultDataType>(props: TabsProps<T>) {
  const border = props.border ?? false;
  const headerContentFit = props.headerContentFit ?? 'contain';
  const ContentWrapper = props.contentWrapperComponent ?? DefaultContentWrapper;
  const HeaderWrapper = props.headerWrapperComponent ?? DefaultHeaderWrapper;
  const TabHeader = props.tabHeaderComponent ?? DefaultTabHeader;
  const Wrapper = props.wrapperComponent ?? DefaultWrapper;

  return (
    <BaseTabs
      activeKey={props.activeKey}
      defaultActiveKey={props.defaultActiveKey}
      forceRender={props.forceRender}
      onChange={props.onChange}
      paneWrapperComponent={props.paneWrapperComponent}
      paneWrapperProps={props.paneWrapperProps}
    >
      <TabsBinding
        border={border}
        data={props.data}
        headerContentFit={headerContentFit}
        contentWrapperComponent={ContentWrapper}
        headerWrapperComponent={HeaderWrapper}
        tabHeaderComponent={TabHeader}
        wrapperComponent={Wrapper}
        uat={props.uat}
      >
        {props.children}
      </TabsBinding>
    </BaseTabs>
  );
}
