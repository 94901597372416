import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { showModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { transactionsActions } from '../store/actions';
import { transactionsSelectors } from '../store/selectors';
import { DownloadTransactionsModal } from '../download-transactions-modal';
import { transactionsApi } from '../api';

export enum TransactionsFiltersFormFields {
  amount = 'amount',
  asset = 'asset',
  date = 'date',
}

export interface TransactionsFiltersForm {
  [TransactionsFiltersFormFields.amount]: [number, number];
  [TransactionsFiltersFormFields.asset]: string;
  [TransactionsFiltersFormFields.date]: [string, string];
}

export const useTransactionsPageModel = () => {
  const dispatch = useDispatch();
  const lockUI: boolean = useSelector(transactionsSelectors.lockUI);
  const assets = useSelector(transactionsSelectors.assets);

  const onFiltersChanged = (form: TransactionsFiltersForm) =>
    dispatch(transactionsActions.onFiltersChanged(form));

  useEffect(() => {
    dispatch(transactionsActions.onPageOpened());
    dispatch(transactionsActions.getFilters());
    transactionsApi
      .getFilters()
      .then((filters) => dispatch(transactionsActions.getFiltersSuccess(filters)))
      .catch((error) => {
        handleBackendError(error);
        dispatch(transactionsActions.getFiltersFail());
      });
  }, []); // eslint-disable-line

  const showDownloadTransactionModal = () =>
    dispatch(showModalAction({ modal: DownloadTransactionsModal }));

  return {
    assets,
    lockUI,
    onFiltersChanged,
    showDownloadTransactionModal,
  };
};
