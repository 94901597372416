import React from 'react';
import { Trans } from '@lingui/macro';
import { formInitial, Fields, formId, useModel } from './model';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputNumber, SubmitButton } from '@components/form';

export const SendMetalsToInvestorModal: MFC<{
  asset: string;
  balance: number;
}> = ({ asset, balance, isVisible }) => {
  const m = useModel(SendMetalsToInvestorModal, asset);

  return (
    <Modal isVisible={isVisible} loading={m.lockUI} disabled={m.lockUI} onClose={m.onClose}>
      <ModalTitle>
        <Trans id="coins_table.set_metals_to">Send metals to the investor account</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={formInitial}>
          <InputNumber
            name={Fields.amount}
            label={<Trans id="coins_table.please_enter_amount">Please, enter the amount:</Trans>}
            disabled={m.lockUI}
            maxDecimals={0}
            max={balance}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={formId}
          fullWidth={true}
          disabled={m.lockUI}
          onAction={m.onSendMetalsToInvestorFormAction}
        >
          <Trans id="button.send">Send</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
