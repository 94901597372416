import React, { FC, memo, ReactNode } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { ButtonToggle } from '@components/button';
import { Divider } from '@components/divider';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { BankAccountItem } from '../../../../api.shared';
import { useModel } from './model';

export const BankInfoItem: FC<{
  className: string;
  bank: BankAccountItem;
}> = memo(({ className, bank }) => {
  const m = useModel(bank);

  return (
    <div className={classnames(s.bank, className)} style={m.styleHidden} ref={m.componentRef}>
      <div className={s.content} ref={m.contentRef}>
        <div className={s.short} ref={m.shortRef} onClick={m.onToggleClicked}>
          <Title level={3} compact>
            {m.shortInfo}
          </Title>

          <ButtonToggle className="ml-auto" expanded={m.isOpened} />
        </div>

        <div ref={m.fullRef}>
          <Divider className="mt2 mb2" />

          <Title level={3} className="mb3">
            <Trans id="profile.bank_details.full_info.title">Review billing details</Trans>
          </Title>

          <div className={s.box}>
            <div className={s.row}>
              <Item
                label={<Trans id="profile.bank_details.nick">Account nickname:</Trans>}
                value={bank.nickname}
              />

              <Item
                label={<Trans id="profile.bank_details.currency">Currency:</Trans>}
                value={bank.currency?.code}
              />

              <Item
                label={<Trans id="profile.bank_details.account_number">Account number:</Trans>}
                value={bank.accountNumber}
              />

              <Item
                label={<Trans id="profile.bank_details.bank_name">Bank name:</Trans>}
                value={bank.bankName}
              />

              <Item
                label={<Trans id="profile.bank_details.bank_swift">Bank SWIFT or ABA code:</Trans>}
                value={bank.swiftCode}
              />

              <Item
                label={<Trans id="profile.bank_details.route_code">Bank Route code:</Trans>}
                value={bank.routeCode}
              />

              <Item
                label={<Trans id="profile.bank_details.iban">IBAN:</Trans>}
                value={bank.ibanNumber}
              />

              <Item
                label={<Trans id="profile.bank_details.comment">Comment:</Trans>}
                value={bank.comment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

const Item: FC<{
  label: ReactNode;
  value: ReactNode;
}> = (p) =>
  !p.value ? null : (
    <div className={s.item}>
      <Title level={3}>{p.label}</Title>
      <Text>{p.value}</Text>
    </div>
  );
