import React from 'react';
import saveAs from 'file-saver';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { Trans } from '@lingui/macro';

import { handleBackendError, notifySuccess } from '@modules/notify';
import { Put } from '@helper/redux';
import { transferApiService as transferApi } from '../transfer-api.service';
import { TransferRequestsPageActionsTypes as TransferTypes } from './model';
import { transferRequestsPageServiceActions as transferServiceActions } from './service.acions';
import { transferRequestsPageSelectors as transferSelectors } from './selectors';

export function* transferRequestsPageEffects() {
  yield all([
    takeEvery(TransferTypes.DOWNLOAD_TRANSACTION, downloadTransaction),
    takeEvery(TransferTypes.LOAD_MORE_TRANSFER_REQUESTS, loadMoreTransferRequests),
    takeEvery(TransferTypes.ON_DOWNLOAD_CLICKED, Put(transferServiceActions.downloadTransaction)),
    takeEvery(
      TransferTypes.ON_TABLE_SCROLLED,
      Put(transferServiceActions.loadMoreTransferRequests)
    ),
    takeEvery(TransferTypes.ON_TABLE_SORTED, Put(transferServiceActions.loadMoreTransferRequests)),
    takeEvery(TransferTypes.ON_UPLOAD_CLICKED, Put(transferServiceActions.uploadTransaction)),
    takeEvery(TransferTypes.UPLOAD_TRANSACTION, uploadTransaction),
  ]);
}

function* downloadTransaction() {
  try {
    const transaction = yield transferApi.downloadTransaction();
    const blob = new Blob([transaction], { type: 'application/blob' });
    saveAs(blob, 'unsigned_tx.tm2');
    yield put(transferServiceActions.downloadTransactionSuccess());
  } catch (error) {
    yield put(transferServiceActions.downloadTransactionFail(error));
    handleBackendError(error);
  }
}

function* loadMoreTransferRequests(action) {
  try {
    const isSortAction = !!action.payload;
    const pageIndex = yield select(transferSelectors.pageIndex);
    const sort = yield select(transferSelectors.sort);
    const requestsInfo = yield transferApi.getTransferRequests(pageIndex, sort);
    yield put(
      transferServiceActions.loadMoreTransferRequestsSuccess({
        ...requestsInfo,
        isSort: isSortAction,
      })
    );
  } catch (error) {
    yield put(transferServiceActions.loadMoreTransferRequestsFail(error));
    handleBackendError(error);
  }
}

function* uploadTransaction(action) {
  try {
    yield transferApi.uploadTransaction(action.payload);
    yield notifySuccess({
      defaultTitle: true,
      text: (
        <Trans id="transfer_requests_page.notify.success_upload_transaction.description">
          File uploaded successfully
        </Trans>
      ),
    });
    yield put(transferServiceActions.uploadTransactionSuccess());
    yield put(transferServiceActions.loadMoreTransferRequests(true));
  } catch (error) {
    yield put(transferServiceActions.uploadTransactionFail(error));
    handleBackendError(error);
  }
}
