import { useContext, useMemo } from 'react';

import { SelectItem } from '@components/form';
import { useRoleLabels } from '@hooks/roles';
import { Context } from '../../context';

export { UpdateUserFields as Fields } from '../../api';

export const useModel = () => {
  const { state: s } = useContext(Context);
  const roleTranslations = useRoleLabels();

  const roles: Array<SelectItem> = useMemo(
    () =>
      s.roles.map((r) => ({
        label: roleTranslations[r.name],
        value: r.id,
      })),
    [s.roles, roleTranslations]
  );

  return {
    roles,
  };
};
