import { CoinInfo, VaultBalanceDetailsHistoryItem, VaultInfo } from '../../vaults-api.service';
import { VaultBalanceDetailsServiceActionsTypes } from './service.acions';
import { VaultBalanceDetailsUserActionsTypes } from './user.acions';

export interface OnModalOpenedPayload {
  coin: CoinInfo;
  vault: VaultInfo;
}

export interface VaultBalanceDetailsState {
  balanceHistory: Array<VaultBalanceDetailsHistoryItem>;
  coin: CoinInfo;
  isGetBalanceHistoryLoading: boolean;
  vault: VaultInfo;
}

export const vaultBalanceDetailsStateInitial: VaultBalanceDetailsState = {
  balanceHistory: [],
  coin: null,
  isGetBalanceHistoryLoading: false,
  vault: null,
};

export enum VaultBalanceDetailsActionsTypes {
  GET_VAULT_BALANCE_DETAILS = 'VAULT_BALANCE_DETAILS_MODAL.GET_VAULT_BALANCE_DETAILS',
  GET_VAULT_BALANCE_DETAILS_FAIL = 'VAULT_BALANCE_DETAILS_MODAL.GET_VAULT_BALANCE_DETAILS_FAIL',
  GET_VAULT_BALANCE_DETAILS_SUCCESS = 'VAULT_BALANCE_DETAILS_MODAL.GET_VAULT_BALANCE_DETAILS_SUCCESS',
  ON_CLOSE_MODAL_CLICKED = 'VAULT_BALANCE_DETAILS_MODAL.ON_CLOSE_MODAL_CLICKED',
  ON_MODAL_LEFT = 'VAULT_BALANCE_DETAILS_MODAL.ON_MODAL_LEFT',
  ON_MODAL_OPENED = 'VAULT_BALANCE_DETAILS_MODAL.ON_MODAL_OPENED',
}

export type VaultBalanceDetailsActions =
  | VaultBalanceDetailsServiceActionsTypes
  | VaultBalanceDetailsUserActionsTypes;
