import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

export type GeneralPreferences = {
  systemNotification: {
    active: boolean;
    text: string;
  };
};
const getGeneralPreferencesQuery = 'generalPreferences';
const getGeneralPreferencesFields = [{ systemNotification: ['active', 'text'] }];
const getGeneralPreferences = makeQuery({
  queryName: getGeneralPreferencesQuery,
  queryFields: getGeneralPreferencesFields,
  query: (): Promise<GeneralPreferences> => {
    return stompClientService.getData(
      getGeneralPreferencesQuery,
      sign(getGeneralPreferencesQuery, getGeneralPreferencesFields)
    );
  },
});

export type GeneralPreferencesParam = {
  systemNotification?: {
    active: boolean;
    text: string;
  };
};
const updateGeneralPreferencesMutation = 'updateGeneralPreferences';
const updateGeneralPreferencesFields = ['value'];
const updateGeneralPreferences = makeQuery({
  permissions: Permission.GENERAL_PREFERENCES_EDIT,
  queryName: updateGeneralPreferencesMutation,
  queryFields: updateGeneralPreferencesFields,
  query: (param: GeneralPreferencesParam): Promise<GeneralPreferences> => {
    const vars = v.generalPreferencesInput({
      generalPreferences: param,
    });
    return stompClientService.sendData(
      updateGeneralPreferencesMutation,
      sign(updateGeneralPreferencesMutation, updateGeneralPreferencesFields),
      vars
    );
  },
});

const api = {
  getGeneralPreferences,
  updateGeneralPreferences,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:general-preferences');

export default api;
