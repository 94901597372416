import { useCallback } from 'react';
import api, { LbsFilters } from './api';
import { KeyExtractor } from '@components/table2/core';
import { Settlement } from './add-settlement-modal/api';
import { LoadData, RowConfig, useTable } from '@components/table2/simplified';

export type Filters = {
  date: Array<string>;
  recipientUserId: number;
};

export const rowConfig: RowConfig<Settlement> = {
  height: 41,
};

export const formId = 'listingBrokerFilters';

export enum ColumnKey {
  createdAt = 'createdAt',
  recipient = 'recipient',
  amount = 'amount',
  status = 'status',
}

export const useModel = () => {
  const keyExtractor = useCallback<KeyExtractor<Settlement>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallback<LoadData<Settlement, LbsFilters>>(({ nextPage, filter }) => {
    return api.getListingBrokersPage({ page: nextPage, filter: filter ?? {} });
  }, []);

  const { tableInstance, setData, setFilter } = useTable<Settlement>({ keyExtractor, loadData });

  const onSettlementPlaced = useCallback(
    (settlement: Settlement) => {
      setFilter(() => {});
      setData((currentData) => [settlement, ...currentData]);
    },
    [setData]
  ); // eslint-disable-line

  const onLbsFiltersChanged = useCallback(
    (filters: Filters) => {
      setFilter(() => ({
        startDate: filters.date?.[0],
        endDate: filters.date?.[1],
        recipientUserId: filters.recipientUserId,
      }));
    },
    [setFilter]
  );

  return {
    onLbsFiltersChanged,
    onSettlementPlaced,
    tableInstance,
  };
};
