import React from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Text } from '@components/typography/text';
import { permissionGroup as apiPermissionGroup } from './api';
import { useModel } from './model';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:burn-redeem-modal',
});

export const BurnRedeemModal: MFC<{
  redeem: any;
  onBurned: () => void;
}> = ({ isVisible, redeem, onBurned }) => {
  const m = useModel(redeem, onBurned, BurnRedeemModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(BurnRedeemModal)}
    >
      <ModalTitle>
        <Trans id="burn_redeem_modal.burn.digital.metals">Burn digital metals?</Trans>
      </ModalTitle>

      <ModalContent>
        <Text level={1} className="mb2">
          <Trans id="burn_redeem_modal.do.you.really.want.burn">
            Do you really want to burn {m.metalAmount} {redeem.coin.asset}?
          </Trans>
        </Text>

        <Text level={1} className="mb2">
          <Trans id="burn_redeem_modal.burning.digital.metals.text">
            ! Burning digital metals will permanently remove the tokens from the Minfinium
            blockchain. You can't undo this.
          </Trans>
        </Text>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} disabled={m.lockUI} onClick={m.onBurnClicked}>
          <Trans id="burn_redeem_modal.button.burn.digital.metals">Burn digital metals</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
