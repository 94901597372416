import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { Form, InputNumber, Select, SelectItem, InputDateRange } from '@components/form';
import { TableFilter } from './types';
import s from './index.module.scss';

type Props = {
  className?: string;
  filterData: {
    assets: SelectItem[];
    currencies: SelectItem[];
    feeTypes: SelectItem[];
  };
  onChange: (filter: TableFilter) => void;
  initialValues?: TableFilter;
};

enum Fields {
  asset = 'asset',
  clientId = 'clientId',
  currencyId = 'currencyId',
  date = 'date',
  feeType = 'feeType',
}

export function FiltersForm(props: Props) {
  const initialValues = props.initialValues
    ? ({
        [Fields.asset]: props.initialValues.asset,
        [Fields.clientId]: props.initialValues.clientId,
        [Fields.currencyId]: props.initialValues.currencyId,
        [Fields.date]: props.initialValues.date
          ? [props.initialValues.date.from, props.initialValues.date.to]
          : null,
        [Fields.feeType]: props.initialValues.feeType,
      } as object)
    : undefined;

  return (
    <Form
      name="fees-report-filters"
      initialValues={initialValues}
      onChange={(formData) => {
        props.onChange({
          asset: formData[Fields.asset] ?? null,
          clientId: formData[Fields.clientId] ?? null,
          currencyId: formData[Fields.currencyId] ?? null,
          date: formData[Fields.date]
            ? {
                from: formData[Fields.date][0],
                to: formData[Fields.date][1],
              }
            : null,
          feeType: formData[Fields.feeType] ?? null,
        });
      }}
      className={classnames(s.form, props.className)}
    >
      <InputDateRange
        label={<Trans id="fees-report.filter.date">Date</Trans>}
        className={s.dateFilter}
        name={Fields.date}
        compact
      />
      <Select
        label={<Trans id="fees-report.filter.fee-type">Fee type</Trans>}
        className={s.filter}
        name={Fields.feeType}
        allowEmpty
        compact
        data={props.filterData.feeTypes}
      />
      <InputNumber
        label={<Trans id="fees-report.filter.client-id">Client ID</Trans>}
        className={s.filter}
        name={Fields.clientId}
        compact
      />
      <Select
        label={<Trans id="fees-report.filter.asset">Asset</Trans>}
        className={s.filter}
        name={Fields.asset}
        allowEmpty
        compact
        data={props.filterData.assets}
      />
      <Select
        label={<Trans id="fees-report.filter.currency">Currency</Trans>}
        className={s.filter}
        name={Fields.currencyId}
        allowEmpty
        compact
        data={props.filterData.currencies}
      />
    </Form>
  );
}
