import { useState } from 'react';
import { useParams } from 'react-router';
import { defineMessage } from '@lingui/macro';

import { TypedError } from '@services/stomp/errors';
import { handleBackendError, notifyWarn } from '@modules/notify';
import { useI18n } from '@hooks/i18n';
import api from './api';

export const useModel = () => {
  const [isBillLoading, setBillLoading] = useState<boolean>(false);
  const [isPassportLoading, setPassportLoading] = useState<boolean>(false);
  const { userId } = useParams<{ userId: string }>();
  const { i18n } = useI18n();

  const onDownloadBillClicked = async () => {
    setBillLoading(true);
    try {
      await api.downloadVerificationDocument(+userId, 'UTILITY_BILL');
    } catch (error) {
      if (error instanceof TypedError && error.code === 'DOCUMENT_NOT_NOT_FOUND') {
        notifyWarn({
          defaultTitle: true,
          text: i18n._(
            defineMessage({
              id: 'shared.notify.text.document_not_found',
              message: 'Document not found',
            })
          ),
        });
      } else {
        handleBackendError(error);
      }
    }
    setBillLoading(false);
  };

  const onDownloadPassportClicked = async () => {
    setPassportLoading(true);
    try {
      await api.downloadVerificationDocument(+userId, 'IDENTIFIER_DOCUMENT');
    } catch (error) {
      if (error instanceof TypedError && error.code === 'DOCUMENT_NOT_NOT_FOUND') {
        notifyWarn({
          defaultTitle: true,
          text: i18n._(
            defineMessage({
              id: 'shared.notify.text.document_not_found',
              message: 'Document not found',
            })
          ),
        });
      } else {
        handleBackendError(error);
      }
    }
    setPassportLoading(false);
  };

  return {
    isBillLoading,
    isPassportLoading,
    onDownloadBillClicked,
    onDownloadPassportClicked,
  };
};
