import React, { memo } from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { useCallDispatch } from '@hooks/core';
import { SearchClear, SearchForm, SearchItem, SearchText } from '@components/search';
import { usersPageUserActions as actions } from '../store/user.acions';
import { AddUserButton, permissionsGroup as addButtonPermissionsGroup } from './add-user';

export const permissionsGroup = addButtonPermissionsGroup.createSuperGroup({
  marker: 'layout:user-actions-block',
});

enum Fields {
  text = 'text',
}
const F = Fields; // alias

const searchFields: Array<SearchItem> = [
  {
    fields: ['company', 'firstName', 'lastName'],
    id: 'fullName',
    label: <Trans id="users_page.search.label.full_name">Full name</Trans>,
    searchFormField: F.text,
    type: 'computedString',
  },
  {
    fields: ['id'],
    id: 'id',
    label: <Trans id="users_page.search.label.id">ID</Trans>,
    searchFormField: F.text,
    type: 'long',
  },
  {
    fields: ['email'],
    id: 'email',
    label: <Trans id="users_page.search.label.email">Email</Trans>,
    searchFormField: F.text,
    type: 'string',
  },
  {
    fields: ['phone'],
    id: 'phone',
    label: <Trans id="users_page.search.label.phone">Phone</Trans>,
    searchFormField: F.text,
    type: 'string',
  },
  {
    fields: ['role.label'],
    id: 'role',
    label: <Trans id="users_page.search.label.role">Role</Trans>,
    searchFormField: F.text,
    type: 'string',
  },
  {
    fields: ['stablePublicKey'],
    id: 'stablePublicKey',
    label: <Trans id="users_page.search.label.public_key">Public key</Trans>,
    searchFormField: F.text,
    type: 'string',
  },
];

type Props = {
  className?: string;
};
export const UsersActionsBlock = memo<Props>(({ className }) => {
  const onSearchFilled = useCallDispatch(actions.onSearchFilled) as (t: string) => void;

  return (
    <SearchForm
      className={classNames(s.row, className)}
      onRsqlChange={onSearchFilled}
      fields={searchFields}
      memorize={true}
    >
      <div className={s.searchBox}>
        <AddUserButton className="mr1" />

        <SearchText className={s.search} name={F.text} />
      </div>

      <SearchClear className={s.clear} />
    </SearchForm>
  );
});
