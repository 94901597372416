export const redeemOptionsModalStateInitial = {
  isRedeemOptionsLoading: false,
  metalPrice: 0,
  redeemOptions: [],
  selectedRedeemOption: undefined,
  storedData: {
    metalPrice: null,
    redeemOption: null,
  },
};

export const RedeemOptionsModalActionsTypes = {
  GET_REDEEM_OPTIONS: 'REDEEM_OPTIONS_MODAL.GET_REDEEM_OPTIONS',
  GET_REDEEM_OPTIONS_FAIL: 'REDEEM_OPTIONS_MODAL.GET_REDEEM_OPTIONS_FAIL',
  GET_REDEEM_OPTIONS_SUCCESS: 'REDEEM_OPTIONS_MODAL.GET_REDEEM_OPTIONS_SUCCESS',
  ON_CHOOSE_SELECTED_REDEEM_OPTION_CLICKED:
    'REDEEM_OPTIONS_MODAL.ON_CHOOSE_SELECTED_REDEEM_OPTION_CLICKED',
  ON_CLOSE_MODAL_CLICKED: 'REDEEM_OPTIONS_MODAL.ON_CLOSE_MODAL_CLICKED',
  ON_REDEEM_OPTION_SELECTED: 'REDEEM_OPTIONS_MODAL.ON_REDEEM_OPTION_SELECTED',
  ON_MODAL_LEFT: 'REDEEM_OPTIONS_MODAL.ON_MODAL_LEFT',
  ON_MODAL_OPENED: 'REDEEM_OPTIONS_MODAL.ON_MODAL_OPENED',
};
