import { useRef, useState } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { debounce } from 'lodash';
import { useSyncRef } from '@hooks/core.strict';
import { useTable as useInternalTable } from '../autosized';

import type {
  BaseItem,
  KeyExtractor,
  OnLoadMore,
  OnSortOrderChange,
  ScrollToIndex,
  SortOrder,
  UseTableReturnType as OriginalUseTableReturnType,
} from '../autosized';

export type { ScrollToIndex, SortOrder } from '../autosized';

export const TABLE_INSTANCE_SYMBOL: unique symbol = Symbol();
export const TABLE_KEY_EXTRACTOR_SYMBOL: unique symbol = Symbol();
export const TABLE_LOAD_MORE_SYMBOL: unique symbol = Symbol();

const FILTER_SORTING_DEBOUNCE = 300;

export type DataList<T extends BaseItem> = {
  key: number;
  data: T[];
  hasMore: boolean;
  initialLoading: boolean;
};

export type LoadIndex = {
  // loadFinished is different from hasMore. It means that we switched
  // filters/sorting and don't want to load more in the current list
  // (with the current key). It will prevent subsequent loads in case
  // of user scroll events supposed to trigger loading.
  loadFinished: boolean;
  nextPage: number;
};

export type TableInstance<T extends BaseItem> = Readonly<
  DataList<T> & {
    [TABLE_INSTANCE_SYMBOL]: OriginalUseTableReturnType<T>;
    [TABLE_KEY_EXTRACTOR_SYMBOL]: KeyExtractor<T>;
    [TABLE_LOAD_MORE_SYMBOL]: OnLoadMore<LoadIndex>;
  }
>;

export type DataSetter<T extends BaseItem> = (currentData: T[]) => T[];

export type FilterSetter<F> = (currentFilter: F | null) => F | null;

export type UseTableReturnType<T extends BaseItem, F> = {
  tableInstance: TableInstance<T>;
  setData: (param: DataSetter<T>) => void;
  setFilter: (param: FilterSetter<F>) => void;
  scrollToIndex: ScrollToIndex;
};

export type FilterEqualityCheck<F> = (currentFilter: F | null, nextFilter: F | null) => boolean;

export type LoadData<T extends BaseItem, F = unknown> = (params: {
  nextPage: number;
  filter: F | null;
  sortOrder: SortOrder;
}) => Promise<{
  data: T[];
  hasMore: boolean;
}>;

// Leave F as unknown, since the consumer might not use filter.
export type UseTableParams<T extends BaseItem, F = unknown> = {
  ensureUniqueness?: boolean;
  filterEqualityCheck?: FilterEqualityCheck<F>;
  initialFilter?: F;
  initialSortOrder?: SortOrder;
  onAfterSortOrderChange?: (order: SortOrder) => void;
  keyExtractor: KeyExtractor<T>;
  loadData: LoadData<T, F>;
  startPage?: number;
};

function defaultFilterEqualityCheck<F>(filter1: F | null, filter2: F | null) {
  return JSON.stringify(filter1) === JSON.stringify(filter2);
}

export function useTable<T extends BaseItem, F = unknown>(
  params: UseTableParams<T, F>
): UseTableReturnType<T, F> {
  const [dataList, setDataList] = useState<DataList<T>>({
    key: 0,
    data: [],
    hasMore: true,
    initialLoading: false,
  });
  const dataKeyRef = useSyncRef(dataList.key);
  const keyExtractorRef = useSyncRef(params.keyExtractor);
  const ensureUniquenessRef = useSyncRef(params.ensureUniqueness);
  const sortOrderRef = useRef(params.initialSortOrder ?? []);
  const filterRef = useRef<F | null>(params.initialFilter ?? null);
  const latestFilterRef = useRef(filterRef.current);
  const initialLoadKeyRef = useRef(0);

  const startPage = params.startPage ?? 0;
  const initialPageRef = useRef(startPage);

  const loadMoreData = useCallbackOne<OnLoadMore<LoadIndex>>(
    ({ lastIndexData }) => {
      // Use initialPageRef to skip first page loaded by loadInitialItems.
      const page = lastIndexData?.nextPage ?? initialPageRef.current;
      const queryDataKey = dataKeyRef.current;
      const querySortOrder = sortOrderRef.current;
      const queryFilter = filterRef.current;

      // If we already finished loading for the current list, we create
      // promise to skip load attempts related to scroll events.
      if (lastIndexData?.loadFinished) {
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              indexData: {
                loadFinished: true,
                nextPage: page,
              },
            });
          }, 1000);
        });
      }

      return params
        .loadData({
          nextPage: page,
          filter: queryFilter,
          sortOrder: querySortOrder,
        })
        .then(({ data, hasMore }) => {
          const isDataKeyUpdated = dataKeyRef.current !== queryDataKey;
          setDataList((currentDataList) => {
            // Ignore the result if the data was sorted/filtered during the query.
            if (isDataKeyUpdated) {
              return currentDataList;
            }
            const concatData = ensureUniquenessRef.current
              ? data.filter((incomingItem) =>
                  currentDataList.data.every(
                    (currentItem) =>
                      keyExtractorRef.current(currentItem) !== keyExtractorRef.current(incomingItem)
                  )
                )
              : data;
            return {
              ...currentDataList,
              data: currentDataList.data.concat(concatData),
              hasMore,
            };
          });
          return {
            indexData: {
              loadFinished: isDataKeyUpdated,
              nextPage: page + 1,
            },
          };
        });
    },
    [params.loadData]
  );

  const loadInitialData = useCallbackOne(
    debounce((loadParams: { filter: F | null; order: SortOrder }) => {
      const queryInitialLoadKey = initialLoadKeyRef.current;

      params
        .loadData({
          nextPage: startPage,
          filter: loadParams.filter,
          sortOrder: loadParams.order,
        })
        .then(({ data: items, hasMore }) => {
          if (queryInitialLoadKey !== initialLoadKeyRef.current) {
            return;
          }
          initialPageRef.current = startPage + 1;

          setDataList((currentDataList) => {
            // Since loadMoreData uses sortOrderRef and filterRef,
            // we only want to update these along with the list key update.
            sortOrderRef.current = loadParams.order;
            filterRef.current = loadParams.filter;

            return {
              key: currentDataList.key + 1,
              data: items,
              hasMore,
              initialLoading: false,
            };
          });
        });
    }, FILTER_SORTING_DEBOUNCE),
    []
  );

  const onSortOrderChange = useCallbackOne<OnSortOrderChange>((order) => {
    // Do not update dataList key here. It is OK to load list data
    // unless the list changed and we don't want the screen to become
    // blank during table loading (updating listKey on table will do that).
    initialLoadKeyRef.current += 1;
    setDataList((currentDataList) => ({
      ...currentDataList,
      initialLoading: true,
    }));
    loadInitialData({ filter: latestFilterRef.current, order });
    params.onAfterSortOrderChange?.(order);
  }, []);

  const tableInstance = useInternalTable<T>({
    sorter: {
      initialOrder: params.initialSortOrder,
      onAfterOrderChange: onSortOrderChange,
    },
  });

  const setData = useCallbackOne((dataSetter: DataSetter<T>) => {
    setDataList((currentDataList) => ({
      ...currentDataList,
      data: dataSetter(currentDataList.data),
    }));
  }, []);

  const setFilter = useCallbackOne((filterSetter: FilterSetter<F>) => {
    const nextFilter = filterSetter(latestFilterRef.current);
    const equalityCheck = params.filterEqualityCheck ?? defaultFilterEqualityCheck;
    const shouldLoad = !equalityCheck(latestFilterRef.current, nextFilter);

    if (!shouldLoad) return;

    initialLoadKeyRef.current += 1;
    setDataList((currentDataList) => ({
      ...currentDataList,
      initialLoading: true,
    }));
    loadInitialData({ filter: nextFilter, order: sortOrderRef.current });
    latestFilterRef.current = nextFilter;
  }, []);

  return useMemoOne<UseTableReturnType<T, F>>(() => {
    return {
      tableInstance: {
        ...dataList,
        [TABLE_INSTANCE_SYMBOL]: tableInstance,
        [TABLE_KEY_EXTRACTOR_SYMBOL]: params.keyExtractor,
        [TABLE_LOAD_MORE_SYMBOL]: loadMoreData,
      },
      setData,
      setFilter,
      scrollToIndex: tableInstance.scrollToIndex,
    };
  }, [dataList, loadMoreData, params.keyExtractor, tableInstance, setData, setFilter]);
}
