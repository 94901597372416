import sign from 'tm2sign.macro';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

const getUserCurrentBalanceReportQuery = 'usersBalancesAsXLSX';
const getUserCurrentBalanceReportFields = ['value'];
const getUserCurrentBalanceReport = makeQuery({
  permissions: Permission.USERS_BALANCES_VIEW_REPORT,
  queryName: getUserCurrentBalanceReportQuery,
  queryFields: getUserCurrentBalanceReportFields,
  query: (): Promise<string> => {
    return stompClient
      .getData(
        getUserCurrentBalanceReportQuery,
        sign(getUserCurrentBalanceReportQuery, getUserCurrentBalanceReportFields)
      )
      .then((response) => response.value);
  },
});

const api = {
  getUserCurrentBalanceReport,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:user-current-balance-report');

export default api;
