import { Trans } from '@lingui/macro';

// TODO: text and translation
export function DefaultError() {
  return (
    <Trans id={'loadable_virtual_table.default_error'}>
      An error happened during loading the data. No more data will be loaded.
    </Trans>
  );
}
