import React from 'react';
import { Trans } from '@lingui/macro';
import { ButtonColor as Color, ButtonPrimary } from '@components/button';
import { PermissionGroup } from '@permissions/core';
import { useDenyBidOrderModalModel } from './model';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [],
  marker: 'layout:deny-bid-order',
});

// TODO: refactor

export const DenyBidOrderModal = ({ bidOrderId, isVisible, onBidOrderDenied }) => {
  const { closeModal, reason, setReason, inProcess, denyOrder } = useDenyBidOrderModalModel(
    bidOrderId,
    onBidOrderDenied
  );

  return (
    <Modal isVisible={isVisible} disabled={inProcess} loading={inProcess} onClose={closeModal}>
      <ModalTitle>
        <Trans id="deny_bid_order_modal.deny.bid.order">Deny bid order?</Trans>
      </ModalTitle>

      <ModalContent>
        <form name="" method="post" action="">
          <div className="mb-sm">
            <Trans id="deny_bid_order_modal.do.you.really.want">
              Do you really want to deny bid order?
            </Trans>
          </div>

          <div className="mb-lg">
            <label className="field-label">
              <Trans id="deny_bid_order_modal.please.enter.reason">Please, enter the reason</Trans>
              <span className="color-red">&nbsp;*</span>
            </label>

            <div className="text-block textarea--big">
              <textarea
                value={reason}
                onChange={(e) => setReason(e.target.value)}
                disabled={inProcess}
                placeholder="Type here..."
              />
            </div>
          </div>
        </form>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary
          fullWidth={true}
          color={Color.danger}
          disabled={!reason || inProcess}
          onClick={denyOrder}
        >
          <Trans id="deny_bid_order_modal.button.deny.bid.order">Yes, deny bid order</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
