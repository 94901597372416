import {
  createElement,
  useLayoutEffect,
  useMemo,
  useRef,
  ComponentType,
  CSSProperties,
  RefObject,
} from 'react';
import { useCallbackOne } from 'use-memo-one';
import { useSyncRef } from '../utils';
import { BaseItem } from '../loadable-table';

export type RowProps<T extends BaseItem> = {
  columnWidthList: number[];
  containerRef: RefObject<any>;
  data: T;
  loading: boolean;
  measure: () => void;
  style: CSSProperties;
};

export type RowWrapperProps<T extends BaseItem> = {
  columnWidthList: number[];
  data: T;
  loading: boolean;
  measureRef: (el: HTMLElement) => void;
  rowComponent: ComponentType<RowProps<T>>;
  style: CSSProperties;
};

// The main case for measure prop is to update row height if a row gets
// expanded/collapsed. This component relies on fact that the ref to the
// container will only be changed if the data of the row is changed. If
// it's not the case, the consumer will need to call measure prop manually.

// NOTE: On the first render we update measurement inside this component,
// so the consumer doesn't need to call measure prop on first render.

// NOTE: On the data update we update measurement inside this component,
// so the consumer doesn't need to call measure prop on data update.

// measureRef is expected to be new function each time
// an element gets rendered by the virtualizer:
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L182
// Because of this we store reference to measureRef and
// memoize the row.

export const RowWrapper = <T extends BaseItem>(props: RowWrapperProps<T>) => {
  const { columnWidthList, data, loading, measureRef, rowComponent, style } = props;

  const containerRef = useRef<HTMLElement>(null);
  const syncMeasureRef = useSyncRef(measureRef);

  const measure = useCallbackOne(() => {
    syncMeasureRef.current(containerRef.current);
  }, []);

  const memoizedRow = useMemo(() => {
    return createElement(rowComponent, {
      columnWidthList,
      containerRef,
      data,
      loading,
      measure,
      style,
    });
  }, [columnWidthList, data, loading, measure, rowComponent, style.position, style.top]);

  useLayoutEffect(() => {
    if (containerRef.current) {
      measure();
    }
  }, [measure]);

  return memoizedRow;
};
