import { BigInt, BigIntProxy } from '@polyfills/big-int';
import { Permission, PermissionGroup, Role } from './core';

class AccessService {
  public isAllowed({
    allowedPermissions,
    allowedRoles,
    currentPermissions,
    currentRole,
  }: AccessConfig): boolean {
    if (!allowedPermissions && !allowedRoles) {
      return false;
    }

    if (!Object.keys(allowedPermissions || {}).length && !allowedRoles?.length) {
      return false;
    }

    if (!currentPermissions && !currentRole) {
      return false;
    }

    if (allowedRoles && currentRole && allowedRoles.includes(currentRole)) {
      return true;
    }

    if (
      !allowedPermissions?.and?.length &&
      !allowedPermissions?.custom?.length &&
      !allowedPermissions?.or?.length
    ) {
      return false;
    }

    if (currentPermissions && allowedPermissions.or) {
      return !!allowedPermissions.or.filter((allowedPermission: Permission | PermissionGroup) => {
        if (allowedPermission instanceof PermissionGroup) {
          return allowedPermission.resolve(currentPermissions);
        }
        const mask: BigIntProxy = BigInt(1).shiftLeft(allowedPermission);
        const calculatedMask: BigIntProxy = mask.and(currentPermissions);
        return mask.eq(calculatedMask);
      }).length;
    }

    if (currentPermissions && allowedPermissions.custom) {
      return !!allowedPermissions.custom.filter((allowedFn: PermissionsCustomFn) => {
        return allowedFn((_allowedPermissions: Array<Permission | PermissionGroup>) => {
          return !!_allowedPermissions.filter((allowedPermission: Permission | PermissionGroup) => {
            if (allowedPermission instanceof PermissionGroup) {
              return allowedPermission.resolve(currentPermissions);
            }
            const mask: BigIntProxy = BigInt(1).shiftLeft(allowedPermission);
            const calculatedMask: BigIntProxy = mask.and(currentPermissions);
            return mask.eq(calculatedMask);
          }).length;
        });
      }).length;
    }

    if (currentPermissions && allowedPermissions.and) {
      return !allowedPermissions.and.filter((allowedPermission: Permission | PermissionGroup) => {
        if (allowedPermission instanceof PermissionGroup) {
          return !allowedPermission.resolve(currentPermissions);
        }
        const mask = BigInt(1).shiftLeft(allowedPermission);
        const calculatedMask = mask.and(currentPermissions);
        return mask.neq(calculatedMask);
      }).length;
    }

    return false;
  }
}

export const accessService = new AccessService();

export interface AccessConfig {
  allowedPermissions?: AllowedPermissions;
  allowedRoles?: Array<Role>;
  currentPermissions?: string;
  currentRole?: Role;
}

export interface AllowedPermissions {
  and?: Array<Permission | PermissionGroup>;
  custom?: Array<PermissionsCustomFn>;
  or?: Array<Permission | PermissionGroup>;
}

export type PermissionsCustomFn = (
  checkPermFn: (allowedPermissions: Array<Permission | PermissionGroup>) => boolean
) => boolean;
