import { ReactNode } from 'react';
import sign from 'tm2sign.macro';

import { privateApi } from 'utils/api';
import { Role } from '@permissions/core';
import { KycState, OnboardingStep } from '@config/core';
import { SortItem, TableData, TableResponse, VerificationStatus } from '@models/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import {
  kycDisapproveReasonsTranslates,
  kycStatesTranslates,
} from '@services/translate/translations';
import { AddSMTAForm } from './add-smt-account-modal/store/model';

const usersItemFields = [
  'accountOpeningStep',
  'issuedAt',
  { country: ['label'] },
  'displayName',
  'email',
  'id',
  'isActive',
  { kyc: ['date', 'reason', 'rem', 'state'] },
  'permissions',
  'phone',
  { role: ['name', 'label'] },
  'umsId',
  { userTariff: [{ tariff: ['id'] }] },
  'verificationStatus',
];

class UsersApiService {
  public addSMTAccount(userId: number, form: AddSMTAForm): Promise<void> {
    const typedValues = {
      ...v.createTradingAccountRequestInput({
        request: {
          accountCode: form.accountCode,
          participantId: form.participantId,
          userId,
        },
      }),
    };
    const addSMTAFields = ['id'];
    return stompClient.sendData(
      'createUserSecondaryMarketAccount',
      sign('createUserSecondaryMarketAccount', addSMTAFields),
      typedValues
    );
  }

  public getUpdatedUserTableInfo(userId: number): Promise<UserItem> {
    const typedValues = {
      ...v.long({ userId }),
    };
    return stompClient.getData('userById', sign('userById', [...usersItemFields]), typedValues);
  }

  public getUsers(
    pageNumber: number,
    sort: Array<SortItem>,
    filter: string
  ): Promise<TableData<UserTableItem>> {
    const usersPageFields = [{ data: [...usersItemFields] }, 'index', 'hasMore'];
    const ordering = [];
    sort.forEach((column) =>
      column.fields.forEach((field) => ordering.push({ direction: column.direction, field: field }))
    );

    let typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };

    if (filter) {
      typedValues = {
        ...typedValues,
        ...v.string({ filter }),
      };
    }

    return stompClient
      .getData('usersPage', sign('usersPage', usersPageFields), typedValues)
      .then((response: TableResponse<UserItem>) => ({
        data: response.data.map(
          (user: UserItem) =>
            ({
              ...user,
              kyc: {
                ...user.kyc,
                date: user.kyc?.date ? new Date(user.kyc.date).toLocaleString() : user.kyc?.date,
                description: kycStatesTranslates[user.kyc?.state],
                reason: user.kyc?.reason.map((_reason) => ({
                  description: kycDisapproveReasonsTranslates[_reason],
                  rejectType: kycRejectType[_reason],
                  value: _reason,
                })),
              },
            } as UserTableItem)
        ),
        isHasMore: response.hasMore,
      }));
  }
}

export const usersApiService = new UsersApiService();

export default usersApiService;

export interface UserTableItem extends Omit<UserItem, 'kyc'> {
  kyc: {
    date: string;
    description: ReactNode;
    permissions: string;
    reason: Array<UserDeclineReason>;
    rem: string;
    state: KycState;
  };
}

export interface UserDeclineReason {
  description: string;
  rejectType: string;
  value: string;
}

export interface UserItem {
  accountOpeningStep: OnboardingStep;
  issuedAt: string;
  country: {
    label: string;
  };
  displayName: string;
  email: string;
  id: number;
  isActive: boolean;
  kyc: {
    date: string;
    reason: Array<string>;
    rem: string;
    state: KycState;
  };
  permissions: string;
  phone: string;
  role: {
    name: Role;
    label: string;
  };
  umsId: string;
  userTariff: {
    tariff: {
      id: number;
    };
  };
  verificationStatus: VerificationStatus;
}

// https://developers.sumsub.com/api-reference/#getting-verification-results
export const kycRejectType = {
  FORGERY: 'FINAL',
  DOCUMENT_TEMPLATE: 'FINAL',
  LOW_QUALITY: 'RETRY',
  SPAM: 'FINAL',
  NOT_DOCUMENT: 'RETRY',
  SELFIE_MISMATCH: 'FINAL',
  ID_INVALID: 'RETRY',
  FOREIGNER: 'FINAL',
  DUPLICATE: 'FINAL',
  BAD_AVATAR: 'RETRY',
  WRONG_USER_REGION: 'FINAL',
  INCOMPLETE_DOCUMENT: 'RETRY',
  BLACKLIST: 'FINAL',
  UNSATISFACTORY_PHOTOS: 'RETRY',
  DOCUMENT_PAGE_MISSING: 'RETRY',
  DOCUMENT_DAMAGED: 'RETRY',
  REGULATIONS_VIOLATIONS: 'FINAL',
  INCONSISTENT_PROFILE: 'FINAL',
  PROBLEMATIC_APPLICANT_DATA: 'RETRY',
  ADDITIONAL_DOCUMENT_REQUIRED: 'RETRY',
  AGE_REQUIREMENT_MISMATCH: 'FINAL',
  EXPERIENCE_REQUIREMENT_MISMATCH: 'FINAL',
  CRIMINAL: 'FINAL',
  WRONG_ADDRESS: 'RETRY',
  GRAPHIC_EDITOR: 'RETRY',
  DOCUMENT_DEPRIVED: 'RETRY',
  COMPROMISED_PERSONS: 'FINAL',
  PEP: 'FINAL',
  ADVERSE_MEDIA: 'FINAL',
  FRAUDULENT_PATTERNS: 'FINAL',
  SANCTIONS: 'FINAL',
  NOT_ALL_CHECKS_COMPLETED: 'RETRY',
  FRONT_SIDE_MISSING: 'RETRY',
  BACK_SIDE_MISSING: 'RETRY',
  SCREENSHOTS: 'RETRY',
  BLACK_AND_WHITE: 'RETRY',
  INCOMPATIBLE_LANGUAGE: 'RETRY',
  EXPIRATION_DATE: 'RETRY',
  UNFILLED_ID: 'RETRY',
  BAD_SELFIE: 'RETRY',
  BAD_VIDEO_SELFIE: 'RETRY',
  BAD_FACE_MATCHING: 'RETRY',
  BAD_PROOF_OF_IDENTITY: 'RETRY',
  BAD_PROOF_OF_ADDRESS: 'RETRY',
  BAD_PROOF_OF_PAYMENT: 'RETRY',
  SELFIE_WITH_PAPER: 'RETRY',
  OTHER: 'RETRY',
  REQUESTED_DATA_MISMATCH: 'RETRY',
  OK: 'RETRY',
  COMPANY_NOT_DEFINED_STRUCTURE: 'RETRY',
  COMPANY_NOT_DEFINED_BENEFICIARIES: 'RETRY',
  COMPANY_NOT_VALIDATED_BENEFICIARIES: 'RETRY',
  COMPANY_NOT_DEFINED_REPRESENTATIVES: 'RETRY',
  COMPANY_NOT_VALIDATED_REPRESENTATIVES: 'RETRY',
};

interface TradingAccount {
  id: string;
}
