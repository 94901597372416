import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { useIssuers } from './hooks';

export const maxDate = new Date().toISOString();

export { Fields } from '../api';

export type Filters = {
  date: Array<string>;
  recipientId: number;
  senderId: number;
};

export const formId = 'correctionTransactionsFilters';

export const useModel = (replaceTransaction, modal) => {
  const { issuers, isIssuersLoading } = useIssuers();

  const onShowModal = useCallDispatch(showModalAction);

  const onAddCorrectionTransaction = () =>
    onShowModal({
      modal: modal,
      props: { replaceTransaction },
    });

  const lockUI = isIssuersLoading;

  return {
    issuers,
    lockUI,
    onAddCorrectionTransaction,
  };
};
