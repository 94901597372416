import { useContext, useMemo } from 'react';

import { SelectItem } from '@components/form';
import { Context } from '../../context';

export { UpdateUserFields as Fields } from '../../api';

export const useModel = () => {
  const { state: s } = useContext(Context);

  const issuers: Array<SelectItem> = useMemo(
    () =>
      s.issuers.map((i) => ({
        // note: у compliance officer может быть только один issuer, но у issuer может быть
        // несколько compliance officers, поэтому в этом селекте options не блокируются
        // https://tm2support.atlassian.net/browse/TM2-436
        label: i.displayName,
        value: i.id,
      })),
    [s.issuers]
  );

  return {
    issuers,
  };
};
