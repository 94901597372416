import { SortItem } from '@models/core';
import { TableHeaderSortType } from 'components/page-table';
import { PaymentItem } from '../table/api'; // TODO check correct type
import { State, ColumnIds } from './model';

const s = (root): State => root.main?.statements?.payments;

const columnSortDirection = (root) => (columnId: ColumnIds) => {
  return s(root).sort.filter((col) => col.id === columnId)[0].direction;
};

const isHasMore = (root): boolean => s(root).isHasMore;

const isTableLoading = (root): boolean => s(root).isTableLoading;

const pageIndex = (root): number => s(root).pageIndex;

const payments = (root): Array<PaymentItem> => s(root).tableData;

const sort = (root): Array<SortItem<ColumnIds>> => {
  return s(root).sort.filter(
    (_sort) =>
      _sort.direction === TableHeaderSortType.asc || _sort.direction === TableHeaderSortType.desc
  );
};

export default {
  columnSortDirection,
  isHasMore,
  isTableLoading,
  lockUI: isTableLoading,
  pageIndex,
  payments,
  sort,
};
