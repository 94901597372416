import moment, { Moment } from 'moment';

import {
  DATE_FORMAT_BACKEND,
  DATE_FORMAT_GQL,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_GQL,
  LOCAL_DATE,
} from '@constants/date';

export const dater = {
  createGqlDate(year: number, month: number, day: number): string {
    const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    const febDays = isLeapYear ? 29 : 28;
    if (month === 2 && day > febDays) {
      return 'Invalid date';
    }

    const v = (n) => (typeof n !== 'number' ? n : n < 10 ? `0${n}` : n); // safari and mozilla fix
    const date = moment.utc(`${year}-${v(month)}-${v(day)}`);
    return this.toGqlDate(date);
  },

  toBackendDate(date: string | number | Moment | Date): string {
    return moment(date).format(DATE_FORMAT_BACKEND);
  },

  toGqlDate(date: string | number | Moment | Date): string {
    return moment(date).format(DATE_FORMAT_GQL);
  },

  toGqlDateTime(date: string | number | Moment | Date): string {
    return moment(date).format(DATE_TIME_FORMAT_GQL);
  },

  toGqlDateFromMs(ms: number): string {
    return moment(new Date(ms).toISOString()).format(DATE_FORMAT_GQL);
  },

  toLocalDate(date: string | number | Moment | Date): string {
    return moment(date).format(LOCAL_DATE);
  },

  toLocalDateTime(date: string | number | Moment | Date): string {
    return moment(date).format(DATE_TIME_FORMAT);
  },
};
