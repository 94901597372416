import { useContext, useMemo, useState } from 'react';

import { Role as R } from '@permissions/core';
import { Context } from '../context';
import { useLockUI, useUserId } from '../hooks';

export { UpdateUserFields as Fields } from '../api';

export const useModel = () => {
  const { state: s } = useContext(Context);
  const { lockUI } = useLockUI();
  const { isNewUser } = useUserId();

  const lock = useMemo(
    () => ({
      role: !isNewUser || lockUI,
      ui: lockUI,
      umsId: lockUI || (!s.profileInitial?.umsIdEdit && !isNewUser),
    }),
    [isNewUser, lockUI, s.profileInitial]
  );

  return {
    isNewUser,
    lock,
  };
};

export const useFieldsAccess = () => {
  const { state: s } = useContext(Context);
  const [roleSelected, onRoleChanged] = useState<number>(undefined);
  const roleInitial = s.profileInitial?.role?.id;
  const roleIdCurrent: number = roleSelected || roleInitial;

  const permissions: string = useMemo(() => {
    if (!roleIdCurrent) {
      return undefined;
    }
    return s.roles.reduce(
      (acc, r) => acc || (r.id === roleIdCurrent ? r.permissions : undefined),
      undefined as string
    );
  }, [roleIdCurrent, s.roles]);

  const role: R = useMemo(() => {
    if (!roleIdCurrent) {
      return undefined;
    }
    return s.roles.reduce(
      (acc, r) => acc || (r.id === roleIdCurrent ? r.name : undefined),
      undefined as R
    );
  }, [roleIdCurrent, s.roles]);

  return {
    onRoleChanged,
    permissions,
    role,
  };
};
