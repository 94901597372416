import { useState } from 'react';
import { closeModalAction } from '@modules/modal';
import api, { Fields, Form } from './api';
import { OnFormAction } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import v from '@components/form/validators';
import { useCurrencies, useListingBrokers } from './hooks';

export { Fields } from './api';
export type { Form } from './api';

export const formId = 'addSettlement';

export const useModel = (replaceSettlement, modal) => {
  const { currencies, isCurrenciesLoading } = useCurrencies();

  const { listingBrokers, isListingBrokersLoading } = useListingBrokers();

  const [isCreateLBLoading, setCreateLBLoading] = useState(false);
  const onClose = useCallDispatch(closeModalAction);
  const lockUI = isListingBrokersLoading || isCreateLBLoading || isCurrenciesLoading;

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      // TODO: add backend validation
      [Fields.amount]: [v.required, v.numMin(0.01)],
      [Fields.currency]: [v.required],
      [Fields.recipient]: [v.required],
    },
    error: async () => {
      setCreateLBLoading(false);
    },
    submit: async (form) => {
      setCreateLBLoading(true);
      const createdSettlement = await api.createLBSettlement(form);
      replaceSettlement(createdSettlement);
      setCreateLBLoading(false);
      onClose(modal);
    },
  };

  return {
    currencies,
    listingBrokers,
    lockUI,
    onClose,
    onFormAction,
  };
};
