import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery, Permission } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';

const paymentsReportXlsFields = ['value'];
const downloadPaymentsReportQuery = 'paymentReportAsXLSX';
const downloadPaymentsReport = makeQuery({
  permissions: { or: [Permission.PAYMENTS_VIEW_REPORT] },
  queryName: downloadPaymentsReportQuery,
  queryFields: paymentsReportXlsFields,
  query: (): Promise<string> =>
    stompClientService
      .getData(
        downloadPaymentsReportQuery,
        sign(downloadPaymentsReportQuery, paymentsReportXlsFields)
      )
      .then((response) => response.value),
});

const api = {
  downloadPaymentsReport,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:payments-report-download');

export default api;
