import { ReduxAction } from '@models/redux';
import { AddFundsModalActionsTypes as T, AddFundsType } from './model';

class OnAddFundsTypeSelectedAction extends ReduxAction<T.ON_ADD_FUNDS_TYPE_SELECTED, AddFundsType> {
  public readonly type: T.ON_ADD_FUNDS_TYPE_SELECTED = T.ON_ADD_FUNDS_TYPE_SELECTED;
  public constructor(public payload: AddFundsType) {
    super(payload);
  }
}

class OnCloseModalClickedAction extends ReduxAction<T.ON_CLOSE_MODAL_CLICKED, void> {
  public readonly type: T.ON_CLOSE_MODAL_CLICKED = T.ON_CLOSE_MODAL_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnModalLeftAction extends ReduxAction<T.ON_MODAL_LEFT, void> {
  public readonly type: T.ON_MODAL_LEFT = T.ON_MODAL_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnModalOpenedAction extends ReduxAction<T.ON_MODAL_OPENED, void> {
  public readonly type: T.ON_MODAL_OPENED = T.ON_MODAL_OPENED;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const addFundsModalUserActions = {
  onAddFundsTypeSelected: (type: AddFundsType) => new OnAddFundsTypeSelectedAction(type).get(),
  onCloseModalClicked: () => new OnCloseModalClickedAction().get(),
  onModalLeft: () => new OnModalLeftAction().get(),
  onModalOpened: () => new OnModalOpenedAction().get(),
};

export type AddFundsModalUserActionsTypes =
  | OnAddFundsTypeSelectedAction
  | OnCloseModalClickedAction
  | OnModalLeftAction
  | OnModalOpenedAction;

export declare namespace AddFundsModalUserActions {
  type OnAddFundsTypeSelected = OnAddFundsTypeSelectedAction;
  type OnCloseModalClicked = OnCloseModalClickedAction;
  type OnModalLeft = OnModalLeftAction;
  type OnModalOpened = OnModalOpenedAction;
}
