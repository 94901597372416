import { modalSelectors } from 'shared/modules/modal/store/selectors';
import { TableHeaderSortType as Sort } from 'components/page-table';

const transferRequestsPageState = (root) => root.main?.transferRequests;

const columnSortDirection = (root) => (columnId) =>
  transferRequestsPageState(root).sort.filter((col) => col.id === columnId)[0].direction;

const isHasMore = (root) => transferRequestsPageState(root).isHasMore;

const isTableLoading = (root) => transferRequestsPageState(root).isTransferRequestsLoading;

const lockUI = (root) => {
  const isModalOpened = modalSelectors.isModalOpened(root);
  const state = transferRequestsPageState(root);
  return (
    isModalOpened ||
    state.isDownloadTransactionLoading ||
    state.isTransferRequestsLoading ||
    state.isUploadTransactionLoading
  );
};

const pageIndex = (root) => transferRequestsPageState(root).pageIndex;

const sort = (root) =>
  transferRequestsPageState(root).sort.filter(
    (_sort) => _sort.direction === Sort.asc || _sort.direction === Sort.desc
  );

const transferRequests = (root) => transferRequestsPageState(root).transferRequests;

export const transferRequestsPageSelectors = {
  columnSortDirection,
  isHasMore,
  isTableLoading,
  lockUI,
  pageIndex,
  sort,
  transferRequests,
  transferRequestsPageState,
};
