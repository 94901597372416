import { ReactNode } from 'react';

import { ReduxAction } from '@models/redux';
import { ConfirmModalProps, ModalActionsTypes as T, ModalInfo, NotifyModalProps } from './model';

class OnCloseLatestModalClickedAction extends ReduxAction<T.ON_CLOSE_LATEST_MODAL, void> {
  public readonly type: T.ON_CLOSE_LATEST_MODAL = T.ON_CLOSE_LATEST_MODAL;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnCloseModalClickedAction extends ReduxAction<T.ON_CLOSE_MODAL_CLICKED, ReactNode> {
  public readonly type: T.ON_CLOSE_MODAL_CLICKED = T.ON_CLOSE_MODAL_CLICKED;
  public constructor(public payload: ReactNode) {
    super(payload);
  } // if modal param is empty - close all modals
}

class OnShowConfirmationModalClickedAction extends ReduxAction<
  T.ON_SHOW_CONFIRMATION_MODAL_CLICKED,
  ConfirmModalProps
> {
  public readonly type: T.ON_SHOW_CONFIRMATION_MODAL_CLICKED = T.ON_SHOW_CONFIRMATION_MODAL_CLICKED;
  public constructor(public payload: ConfirmModalProps) {
    super(payload);
  }
}

class OnShowModalClickedAction<P = object> extends ReduxAction<
  T.ON_SHOW_MODAL_CLICKED,
  ModalInfo<P>
> {
  public readonly type: T.ON_SHOW_MODAL_CLICKED = T.ON_SHOW_MODAL_CLICKED;
  public constructor(public payload: ModalInfo<P>) {
    super(payload);
  }
}

class OnShowNotifyModalClickedAction extends ReduxAction<
  T.ON_SHOW_NOTIFY_MODAL_CLICKED,
  NotifyModalProps
> {
  public readonly type: T.ON_SHOW_NOTIFY_MODAL_CLICKED = T.ON_SHOW_NOTIFY_MODAL_CLICKED;
  public constructor(public payload: NotifyModalProps) {
    super(payload);
  }
}

export const modalUserActions: {
  onCloseLatestModalClicked: () => OnCloseLatestModalClickedAction;
  onCloseModalClicked: (modal?: ReactNode) => OnCloseModalClickedAction;
  onShowConfirmationModalClicked: (
    modalProps: ConfirmModalProps
  ) => OnShowConfirmationModalClickedAction;
  onShowModalClicked: <P = object>(info: ModalInfo<P>) => OnShowModalClickedAction<P>;
  onShowNotifyModalClicked: (props: NotifyModalProps) => OnShowNotifyModalClickedAction;
} = {
  onCloseLatestModalClicked: () => new OnCloseLatestModalClickedAction().get(),
  onCloseModalClicked: (modal?: ReactNode) => new OnCloseModalClickedAction(modal).get(),
  onShowConfirmationModalClicked: (modalProps: ConfirmModalProps) =>
    new OnShowConfirmationModalClickedAction(modalProps).get(),
  onShowModalClicked: <P = object>(info: ModalInfo<P>) =>
    new OnShowModalClickedAction<P>(info).get(),
  onShowNotifyModalClicked: (props: NotifyModalProps) =>
    new OnShowNotifyModalClickedAction(props).get(),
};

export type ModalUserActionsTypes =
  | OnCloseLatestModalClickedAction
  | OnCloseModalClickedAction
  | OnShowConfirmationModalClickedAction
  | OnShowModalClickedAction
  | OnShowNotifyModalClickedAction;

export declare namespace ModalUserActions {
  type OnCloseLatestModalClicked = OnCloseLatestModalClickedAction;
  type OnCloseModalClicked = OnCloseModalClickedAction;
  type OnShowConfirmationModalClicked = OnShowConfirmationModalClickedAction;
  type OnShowModalClicked<P = object> = OnShowModalClickedAction<P>;
  type OnShowNotifyModalClicked = OnShowNotifyModalClickedAction;
}
