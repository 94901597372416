import { useCallback } from 'react';
import { useHistory } from 'react-router';

import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import type { MFC } from '@modules/modal';

export const useModel = (modal: MFC) => {
  const closeModal = useCallDispatch(closeModalAction);
  const onClose = useCallback(() => closeModal(modal), []);

  const history = useHistory();

  const onAddBankDetails = useCallback(() => {
    onClose();
    history.push('/profile#bank-details');
  }, []);

  return {
    onAddBankDetails,
    onClose,
  };
};
