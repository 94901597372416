import { currenciesFilter } from '@hot-fix/currency';

export type CoinBalance = {
  amount: number;
  coin: { asset: string };
};

export type StableBalance = {
  amount: number;
  currency: { asset: string; id: number };
};

export type NormalizedBalance<T = never> = {
  amount: number;
  asset: string;
  data: T;
};

export const normalizeCoinBalances = <T extends CoinBalance>(
  balances: T[]
): NormalizedBalance<T>[] =>
  balances.map((b) => ({ amount: b.amount, asset: b.coin.asset, data: b }));

export const normalizeStableBalances = <T extends StableBalance>(
  balances: T[]
): NormalizedBalance<T>[] =>
  balances
    // у юзеров есть на счету CHF, этот код тут только для того,
    // чтобы подсветить место, где потенциально нужно выпиливать CHF
    // .filter((balance) => currenciesFilter(balance.currency))
    .map((b) => ({ amount: b.amount, asset: b.currency.asset, data: b }));

export const mergeBalances = <T = never>(
  balances1: NormalizedBalance<T>[],
  balances2: NormalizedBalance<T>[]
): NormalizedBalance<T>[] => {
  const balances = balances1.concat(balances2);
  const balancesValueMap = balances.reduce<Record<string, number>>((acc, b) => {
    acc[b.asset] ? (acc[b.asset] += b.amount) : (acc[b.asset] = b.amount);
    return acc;
  }, {});

  const balancesDataMap = balances.reduce<Record<string, T>>((acc, b) => {
    acc[b.asset] = b.data;
    return acc;
  }, {});

  return Object.entries(balancesValueMap).map(([asset, amount]) => ({
    amount,
    asset,
    data: balancesDataMap[asset],
  }));
};
