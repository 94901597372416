import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { PermissionGroup } from '@permissions/permission-group';
import { ButtonDownload } from '@components/button/download';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { HasAccess } from '@permissions/core';
import { permissionGroup as apiPermissionGroup } from './api';
import { useModel } from './model';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup],
  marker: 'layout:payments-report-download-button',
});

const DownloadButtonComponent: FC = () => {
  const { lockUI, onDownloadClicked } = useModel();

  return (
    <Tooltip
      placement="bottom"
      trigger={TooltipTrigger.hover}
      title={<Trans id="payments_report.button.download">Download payments report xls</Trans>}
    >
      <ButtonDownload compact="all" disabled={lockUI} onClick={onDownloadClicked} />
    </Tooltip>
  );
};

export const DownloadButton: FC = () => (
  <HasAccess permissions={{ or: [permissionGroup] }}>
    <DownloadButtonComponent />
  </HasAccess>
);
