import { TransferDirection } from '../../model';
import { TransferCoinTabServiceActionsTypes } from './service.acions';
import { TransferCoinTabUserActionsTypes } from './user.acions';

// technical limitation of secondary market. It work only with hundredths decimals
export const maxDecimalsSMCoin = 0;

export const transferCoinFormId = 'transfer-coin-form';

export enum TransferCoinFields {
  asset = 'asset',
  amount = 'amount',
}

export interface TransferCoinTabState {
  assetSelected: string;
  coinBalancesPrimary: Array<AssetItem>;
  coinBalancesSecondary: Array<AssetItem>;
  coinsSecondaryAvailable: Array<string>;
  isCoinsInfoLoading: boolean;
  isFinishCoinTransactionLoading: boolean;
  isTabInitiated: boolean;
  isTransferCoinInfoLoading: boolean;
  transferDirection: TransferDirection;
}

export const transferCoinTabStateInitial: TransferCoinTabState = {
  assetSelected: '',
  coinBalancesPrimary: [],
  coinBalancesSecondary: [],
  coinsSecondaryAvailable: [],
  isCoinsInfoLoading: false,
  isFinishCoinTransactionLoading: false,
  isTabInitiated: false,
  isTransferCoinInfoLoading: false,
  transferDirection: TransferDirection.fromPrimary,
};

export enum TransferCoinTabActionsTypes {
  ON_TAB_OPENED = 'TRANSFER_COIN_TAB.ON_TAB_OPENED',
  ON_TAB_LEFT = 'TRANSFER_COIN_TAB.ON_TAB_LEFT',
  SET_COIN_INFO = 'TRANSFER_COIN_TAB.SET_COIN_INFO',
  GET_COIN_INFO = 'TRANSFER_COIN_TAB.GET_COIN_INFO',
  GET_COIN_INFO_FAIL = 'TRANSFER_COIN_TAB.GET_COIN_INFO_FAIL',
  GET_COIN_INFO_SUCCESS = 'TRANSFER_COIN_TAB.GET_COIN_INFO_SUCCESS',
  ON_CANCEL_FINISH_COIN_TRANSFER_CLICKED = 'TRANSFER_COIN_TAB.ON_CANCEL_FINISH_COIN_TRANSFER_CLICKED',
  ON_TOGGLE_COIN_TRANSFER_DIRECTION_CLICKED = 'TRANSFER_COIN_TAB.ON_TOGGLE_COIN_TRANSFER_DIRECTION_CLICKED',
  ON_MAXIMIZE_COIN_AMOUNT_TRANSFER_CLICKED = 'TRANSFER_COIN_TAB.ON_MAXIMIZE_COIN_AMOUNT_TRANSFER_CLICKED',
  ON_SELECTED_VALUE_CHANGED = 'TRANSFER_COIN_TAB.ON_SELECTED_VALUE_CHANGED',
  FINISH_COIN_TRANSACTION = 'TRANSFER_COIN_TAB.FINISH_COIN_TRANSACTION',
  FINISH_COIN_TRANSACTION_FAIL = 'TRANSFER_COIN_TAB.FINISH_COIN_TRANSACTION_FAIL',
  FINISH_COIN_TRANSACTION_SUCCESS = 'TRANSFER_COIN_TAB.FINISH_COIN_TRANSACTION_SUCCESS',
  UPDATE_FORM_VALUES = 'TRANSFER_COIN_TAB.UPDATE_FORM_VALUES',
  UPDATE_FORM_VALUES_FAIL = 'TRANSFER_COIN_TAB.UPDATE_FORM_VALUES_FAIL',
  UPDATE_FORM_VALUES_SUCCESS = 'TRANSFER_COIN_TAB.UPDATE_FORM_VALUES_SUCCESS',
}

export type TransferCoinTabActions =
  | TransferCoinTabUserActionsTypes
  | TransferCoinTabServiceActionsTypes;

export interface AssetItem {
  balance: number;
  asset: string;
  haveSecondaryTrust: boolean;
}

export interface SetCoinInfoPayload {
  coinsSecondaryAvailable: Array<string>;
  balancesPrimary: Array<AssetItem>;
  balancesSecondary: Array<AssetItem>;
}
