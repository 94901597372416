import { useState } from 'react';
import { useSelector } from 'react-redux';

import v from '@components/form/validators';
import { closeModalAction, showModalAction } from '@modules/modal';
import { OnFormAction, useFormById } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { currenciesSelectors } from '@modules/currencies';
import { CMTransactionType } from '../../hooks';
import { ConfirmCMTransactionModal, ConfirmCMTransactionModalProps } from '../confirm-transaction';
import { useBankAccounts, useTransactionTypes } from './hooks';
import api, {
  TransactionFields,
  TransactionFields as Fields,
  TransactionForm as Form,
} from './api';

export { TransactionFields as Fields } from './api';
export const formId = 'checkCMTransactionFormId';

const senderTxTypes: Array<CMTransactionType> = ['TRANSFER', 'WITHDRAW'];
const receiverTxTypes: Array<CMTransactionType> = ['DEPOSIT', 'TRANSFER'];

const initialValues: Form = {
  type: 'DEPOSIT',
} as Form;

export const useModel = (modal, onTransactionCreated: (id: number) => void) => {
  const [isCheckTxLoading, setCheckTxLoading] = useState<boolean>(false);
  const [txType, onTxTypeChanged] = useState<CMTransactionType>(initialValues.type);
  const [currencyId, onCurrencyChanged] = useState<number>();
  const [receiverId, onReceiverChanged] = useState<number>();
  const [senderId, onSenderChanged] = useState<number>();

  const form = useFormById<Form>(formId);
  const isCurrenciesLoading = useSelector(currenciesSelectors.isLoading);
  const transactionTypes = useTransactionTypes();
  const { bankAccounts, getBankAccountInfo, isBanksAccountsLoading } = useBankAccounts(currencyId);
  const closeModal = useCallDispatch(closeModalAction);
  const showModal = useCallDispatch(showModalAction);

  const isShowReceiver = receiverTxTypes.includes(txType);
  const isShowSender = senderTxTypes.includes(txType);
  const receiver = isShowReceiver && getBankAccountInfo(receiverId);
  const sender = isShowSender && getBankAccountInfo(senderId);

  const onCurrencySelected = (id: number) => {
    form.resetFields([Fields.receiverId, Fields.senderId]);
    onReceiverChanged(undefined);
    onSenderChanged(undefined);
    onCurrencyChanged(id);
  };

  const onFormAction: OnFormAction<Form> = {
    error: () => setCheckTxLoading(false),
    fieldValidation: {
      [Fields.amount]: [v.required],
      [Fields.currency]: [v.required],
      [Fields.externalId]: [v.required],
      [Fields.receiverId]: [v.required],
      [Fields.senderId]: [v.required],
      [Fields.type]: [v.required],
    },
    submit: async (transaction) => {
      setCheckTxLoading(true);
      await api.checkTransaction(transaction);
      const props: ConfirmCMTransactionModalProps = {
        onTransactionCreated,
        receiver,
        sender,
        transaction,
      };
      showModal({ modal: ConfirmCMTransactionModal, props });
      setCheckTxLoading(false);
    },
  };

  return {
    bankAccounts,
    initialValues,
    isShowReceiver,
    isShowSender,
    lockUI: isCheckTxLoading || isBanksAccountsLoading || isCurrenciesLoading,
    onCloseModalClicked: () => closeModal(modal),
    onCurrencySelected,
    onFormAction,
    onReceiverChanged,
    onSenderChanged,
    onTxTypeChanged,
    receiver,
    sender,
    transactionTypes,
  };
};
