import React from 'react';
import { Trans } from '@lingui/macro';

export const DirectionAndAssetCell = ({ type }) => {
  const adapter = {
    METAL_TO_PRIMARY: (
      <Trans id="transfer_requests_page.table.cell.direction.metal_to_primary">
        Secondary -> Primary (Metal)
      </Trans>
    ),
    METAL_TO_SECONDARY: (
      <Trans id="transfer_requests_page.table.cell.direction.metal_to_secondary">
        Primary -> Secondary (Metal)
      </Trans>
    ),
    STABLE_TO_PRIMARY: (
      <Trans id="transfer_requests_page.table.cell.direction.fiat_to_primary">
        Secondary -> Primary (Funds)
      </Trans>
    ),
    STABLE_TO_SECONDARY: (
      <Trans id="transfer_requests_page.table.cell.direction.fiat_to_secondary">
        Primary -> Secondary (Funds)
      </Trans>
    ),
  };

  return adapter[type];
};
