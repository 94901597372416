import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';

import { privateApi } from 'utils/api';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { notifySuccess } from '@modules/notify';
import { DischargeTokenModal } from './index';

export const dischargeTokenFormId: string = 'dischargeTokenFormId';

export const useDigitalMetalsDischargeModalModel = ({ coin }) => {
  const [lockUI, setLockUI] = useState(false);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(closeModalAction(DischargeTokenModal));
  const dischargeLimit = { min: 0.01, max: coin?.info?.balance };

  const onDischargeTokenFormAction: OnFormAction = {
    error: () => {
      setLockUI(false);
    },
    submit: async (form: { amount: number }, dispatch) => {
      setLockUI(true);
      await privateApi.post('/coins/' + coin.id + '/discharge', { amount: form.amount });
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="discharge_token_modal.notification.success_discharge.text">
            Coins burned successfully
          </Trans>
        ),
      });
      setLockUI(false);
      await dispatch(closeModalAction(DischargeTokenModal));
    },
  };

  return { hideModal, lockUI, onDischargeTokenFormAction, dischargeLimit };
};
