import { useCallback, useState, useMemo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { defineMessage } from '@lingui/macro';
import classnames from 'classnames';
import { PageContent, PageTitle } from '@components/page';
import { Tabs, TabPane, TabsHeaderData } from '@components/tabs/regular';
import { TablePane } from '@components/table/utils/pane';
import { If, PrioritizedIf } from '@components/if';
import { PermissionGroup } from '@permissions/core';
import { configSelectors } from '@config/store/selectors';
import { useI18n } from 'containers/services/commonService';
import {
  OfferStatus,
  BidOrderStatus,
  OfferByAllList as OfferByAllListType,
  OfferByMeList as OfferByMeListType,
} from './shared/types';
import {
  permissionGroup as bidOrdersByAllPermissionGroup,
  BidOrdersByAll,
} from './bid-orders-by-all';
import {
  permissionGroup as bidOrdersByCreatorPermissionGroup,
  BidOrdersByCreator,
} from './bid-orders-by-creator';
import { permissionGroup as offersByAllPermissionGroup, OffersByAll } from './offers-by-all';
import { permissionGroup as offersByMePermissionGroup, OffersByMe } from './offers-by-me';
import { BidOrdersActions } from './legacy-bid-orders-actions';
import { OffersActions } from './legacy-offers-actions';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    bidOrdersByAllPermissionGroup,
    bidOrdersByCreatorPermissionGroup,
    offersByAllPermissionGroup,
    offersByMePermissionGroup,
  ],
  marker: 'prioritized-layout:offers',
});

enum TabId {
  offers = 'offers',
  history = 'history',
}

const tabsHeaderData: TabsHeaderData = [
  { tabKey: TabId.offers, data: 'Offers' },
  { tabKey: TabId.history, data: 'History' },
];

const VerticalFiller = (props: { children: ReactNode }) => (
  <div className={s.verticalFiller}>{props.children}</div>
);

export const OffersPage = () => {
  const { i18n } = useI18n();
  const userPermissions = useSelector(configSelectors.permissions);
  const docTitle = i18n._(defineMessage({ id: 'router.bids', message: 'Offers' }));

  const [offerListAll, setOfferListAll] = useState<OfferByAllListType>({
    hasMore: true,
    key: '',
    list: [],
  });
  const [offerListMy, setOfferListMy] = useState<OfferByMeListType>({
    hasMore: true,
    key: '',
    list: [],
  });

  const permissions = useMemo(
    () => ({
      bidOrders: {
        all: bidOrdersByAllPermissionGroup.resolve(userPermissions),
        creator: bidOrdersByCreatorPermissionGroup.resolve(userPermissions),
      },
      offers: {
        all: offersByAllPermissionGroup.resolve(userPermissions),
        me: offersByMePermissionGroup.resolve(userPermissions),
      },
    }),
    []
  );

  const [offersFilters, setOffersFilters] = useState<{
    assetId?: number;
    status?: OfferStatus;
    sellerId?: number;
  }>({});
  const onOffersFiltersChange = useCallback((filters) => {
    setOffersFilters({
      assetId: filters.asset,
      sellerId: filters.company,
      status: filters.status,
    });
  }, []);

  const [bidOrdersFilters, setBidOrdersFilters] = useState<{
    assetId?: number;
    status?: BidOrderStatus;
  }>({});
  const onBidOrdersFiltersChange = useCallback((filters) => {
    setBidOrdersFilters({
      assetId: filters.coinId,
      status: filters.status,
    });
  }, []);

  const isOffersTabPermitted = permissions.offers.all || permissions.offers.me;
  const [tabKey, setTabKey] = useState<string>(() => {
    return isOffersTabPermitted ? TabId.offers : TabId.history;
  });
  const onTabKeyChange = useCallback((key: string) => {
    setTabKey(key);
    setBidOrdersFilters({});
    setOffersFilters({});
    setOfferListAll({
      list: [],
      key: '',
      hasMore: true,
    });
    setOfferListMy({
      list: [],
      key: '',
      hasMore: true,
    });
  }, []);

  const onOfferPlaced = useCallback((offer) => {
    setOfferListMy((currentData) => ({
      ...currentData,
      list: [offer, ...currentData.list],
    }));
  }, []);

  return (
    <PageContent className={s.pageWrapper} uat="offers-page">
      <PageTitle documentTitle={docTitle} />

      <If condition={tabKey === TabId.offers}>
        <OffersActions
          onFilterChanged={onOffersFiltersChange}
          showUserFilter={permissions.offers.all}
          showOfferPlacement={permissions.offers.me}
          onOfferPlaced={onOfferPlaced}
        />
      </If>
      <If condition={tabKey === TabId.history}>
        <BidOrdersActions onFilterChanged={onBidOrdersFiltersChange} />
      </If>

      <TablePane className={classnames(s.verticalFiller, 'pt1')}>
        <Tabs
          activeKey={tabKey}
          border
          data={tabsHeaderData}
          contentWrapperComponent={VerticalFiller}
          onChange={onTabKeyChange}
          wrapperComponent={VerticalFiller}
        >
          <PrioritizedIf>
            <If condition={permissions.offers.all}>
              <TabPane tabKey={TabId.offers} key={TabId.offers}>
                <OffersByAll
                  data={offerListAll}
                  updateData={setOfferListAll}
                  assetId={offersFilters.assetId}
                  sellerId={offersFilters.sellerId}
                  status={offersFilters.status}
                />
              </TabPane>
            </If>
            <If condition={permissions.offers.me}>
              <TabPane tabKey={TabId.offers} key={TabId.offers}>
                <OffersByMe
                  data={offerListMy}
                  updateData={setOfferListMy}
                  assetId={offersFilters.assetId}
                  sellerId={offersFilters.sellerId}
                  status={offersFilters.status}
                />
              </TabPane>
            </If>
          </PrioritizedIf>

          <PrioritizedIf>
            <If condition={permissions.bidOrders.all}>
              <TabPane tabKey={TabId.history} key={TabId.history}>
                <BidOrdersByAll
                  assetId={bidOrdersFilters.assetId}
                  status={bidOrdersFilters.status}
                />
              </TabPane>
            </If>
            <If condition={permissions.bidOrders.creator}>
              <TabPane tabKey={TabId.history} key={TabId.history}>
                <BidOrdersByCreator
                  assetId={bidOrdersFilters.assetId}
                  status={bidOrdersFilters.status}
                />
              </TabPane>
            </If>
          </PrioritizedIf>
        </Tabs>
      </TablePane>
    </PageContent>
  );
};
