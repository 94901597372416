import { useEffect, useState } from 'react';
import { defineMessage } from '@lingui/macro';
import { TypedError } from '@services/stomp/errors';
import { notifyWarn } from '@modules/notify';
import { useI18n } from '@hooks/i18n';
import { getFile } from '../../services/commonService';

export const useImageModel = (uploadFile, onError) => {
  const [data, setData] = useState(null);
  const [isError, setError] = useState(false);
  const { i18n } = useI18n();

  useEffect(() => {
    getFile(`/downloadFile/${uploadFile.id}`)
      .then((data) => {
        let reader = new FileReader();
        reader.onload = () => setData(reader.result);
        reader.readAsDataURL(data.data);
      })
      .catch((error) => {
        onError && onError(error);
        setError(true);
        if (error instanceof TypedError && error.code === 'DOCUMENT_NOT_NOT_FOUND') {
          notifyWarn({
            defaultTitle: true,
            text: i18n._(
              defineMessage({
                id: 'shared.notify.text.document_not_found',
                message: 'Document not found',
              })
            ),
          });
        }
      });
  }, []);

  const src = data;

  return { data, isError, src };
};
