import React from 'react';
import { Trans } from '@lingui/macro';

import { Title } from '@components/typography/title';
import { PermissionGroup } from '@permissions/permission-group';
import { CurrencySelect } from '@modules/currencies';
import { Form, InputText, SubmitButton } from '@components/form';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { ButtonModalBack } from '@components/button';

import { permissionGroup as apiPermissionsGroup } from './api';
import { Fields, formId, useModel } from './model';
import s from './index.module.scss';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup],
  marker: 'layout:profile-other-page-add-bank-account-modal',
});

export interface AddBankAccountModalProps {
  onAccountCreated: (id: number) => void;
}
export const AddBankAccountModal: MFC<AddBankAccountModalProps> = ({
  isVisible,
  onAccountCreated,
}) => {
  const m = useModel(AddBankAccountModal, onAccountCreated);

  return (
    <Modal
      className="add-new-billing-details"
      isVisible={isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      onClose={m.onCloseModalClicked}
    >
      <ModalTitle>
        <Trans id="withdraw_modals.add.add_new_billing_details">Add new billing details</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId}>
          <InputText
            name={Fields.nickname}
            label={<Trans id="new_billing_modal.label.nickname">Account nickname</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <CurrencySelect
            name={Fields.currency}
            label={<Trans id="new_billing_modal.label.currency">Currency</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.accountNumber}
            label={<Trans id="new_billing_modal.label.account_number">Account number</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.beneficiaryAccountName}
            label={
              <Trans id="new_billing_modal.label.beneficiary_name">Beneficiary account name</Trans>
            }
            disabled={m.lockUI}
            required={true}
          />

          <Title level={4} className="mb2">
            <Trans id="new_billing_modal.title.beneficiary_bank">Beneficiary bank</Trans>
          </Title>

          <InputText
            name={Fields.bankName}
            label={<Trans id="new_billing_modal.label.bank_name">Bank name</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.swiftCode}
            label={<Trans id="new_billing_modal.label.swift_aba">Bank SWIFT or ABA code</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.routeCode}
            label={<Trans id="new_billing_modal.label.route_code">Bank route code</Trans>}
            disabled={m.lockUI}
          />

          <InputText
            name={Fields.ibanNumber}
            label={<Trans id="new_billing_modal.label.iban">IBAN</Trans>}
            disabled={m.lockUI}
          />

          <Title level={4} className="mb2">
            <Trans id="new_billing_modal.title.beneficiary_bank_address">
              Beneficiary bank address
            </Trans>
          </Title>

          <InputText
            name={Fields.bankAddressStreetAndNumber}
            label={<Trans id="new_billing_modal.label.street_and_number">Street and number</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.bankAddressDetails}
            label={
              <Trans id="new_billing_modal.label.suite_unit_building_floor">
                Suite / Unit / Building / Floor
              </Trans>
            }
            disabled={m.lockUI}
          />

          <div className={s.row}>
            <InputText
              name={Fields.bankAddressCity}
              label={<Trans id="new_billing_modal.label.city">City</Trans>}
              disabled={m.lockUI}
              required={true}
              className={s.control}
            />

            <InputText
              name={Fields.bankAddressPostalCode}
              label={<Trans id="new_billing_modal.label.postal_code">Postal code</Trans>}
              disabled={m.lockUI}
              required={true}
              className={s.control}
            />
          </div>

          <div className={s.row}>
            <InputText
              name={Fields.bankAddressState}
              label={<Trans id="new_billing_modal.label.state">State</Trans>}
              disabled={m.lockUI}
              className={s.control}
            />

            <InputText
              name={Fields.bankAddressCountry}
              label={<Trans id="new_billing_modal.label.country">Country</Trans>}
              disabled={m.lockUI}
              required={true}
              className={s.control}
            />
          </div>

          <Title level={4} className="mb2">
            <Trans id="new_billing_modal.title.other">Other</Trans>
          </Title>

          <InputText
            className="mb2"
            name={Fields.comment}
            label={<Trans id="new_billing_modal.label.comment">Comment</Trans>}
            disabled={m.lockUI}
            compact={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter className={s.footer}>
        <ButtonModalBack className="mr3" disabled={m.lockUI} />

        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.save">Save</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
