import { t } from '@lingui/macro';
import { TransferDirection } from './model';

export const transferModalTranslations = {
  get [TransferDirection.fromPrimary]() {
    return t({
      id: 'market_transfer_modal.funds.notify.success_transfer.primary_market',
      message: 'primary',
    });
  },
  get [TransferDirection.fromSecondary]() {
    return t({
      id: 'market_transfer_modal.funds.notify.success_transfer.secondary_market',
      message: 'secondary',
    });
  },
};
