import { FC, useState } from 'react';
import api from './api';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { base64StringToBlob } from 'blob-util';
import saveAs from 'file-saver';
import { handleBackendError } from '@modules/notify';

export { permissionGroup } from './api';

export const UserCurrentBalanceReport: FC = () => {
  const [loading, setLoading] = useState(false);

  const onDownloadClicked = async () => {
    try {
      setLoading(true);
      const report: string = await api.getUserCurrentBalanceReport();
      const blob = base64StringToBlob(report, 'application/xlsx');
      saveAs(blob, `user_current_balance.xlsx`);
    } catch (error) {
      handleBackendError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ButtonPrimary
      disabled={loading}
      onClick={onDownloadClicked}
      uat="statements-user-current-balance-report"
    >
      <Trans id="statements.user_current_balance_report.get_report">Get report</Trans>
    </ButtonPrimary>
  );
};
