import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Role, PermissionGroup } from '@permissions/core';
import { privateApi } from 'utils/api';

export type Currency = {
  id: number;
  code: string;
};
const getCurrenciesQuery = 'currencies';
const getCurrenciesFields = ['id', 'code'];
const getCurrencies = makeQuery({
  queryName: getCurrenciesQuery,
  queryFields: getCurrenciesFields,
  query: (): Promise<Currency[]> => {
    return stompClient.getData(getCurrenciesQuery, sign(getCurrenciesQuery, getCurrenciesFields));
  },
});

export type Vault = {
  id: number;
  address: string;
  city: string;
};
const getVaultsQuery = 'vaultsPage';
const getVaultsFields = [{ data: ['address', 'city', 'id'] }];
const getVaults = makeQuery({
  queryName: getVaultsQuery,
  queryFields: getVaultsFields,
  query: (): Promise<Array<Vault>> => {
    return stompClient
      .getData(getVaultsQuery, sign(getVaultsQuery, getVaultsFields))
      .then((response) => response.data);
  },
});

export type Unit = {
  id: number;
  label: string;
  inGrams: number;
};
const getUnitsQuery = 'units';
const getUnitsFields = ['id', 'label', 'inGrams'];
const getUnits = makeQuery({
  queryName: getUnitsQuery,
  queryFields: getUnitsFields,
  query: (): Promise<Array<Unit>> => {
    return stompClient.getData(getUnitsQuery, sign(getUnitsQuery, getUnitsFields));
  },
});

export type Metal = {
  id: number;
  label: string;
};
const getMetalsEndpoint = '/metals';
const getMetals = makeQuery({
  queryName: getMetalsEndpoint,
  query: (): Promise<Array<Metal>> => {
    return privateApi.get(getMetalsEndpoint).then((response) => response.data);
  },
});

export type User = {
  id: number;
  displayName: string;
};
const getUsersByRoleQuery = 'usersByRole';
const getUsersByRoleFields = ['id', 'displayName'];
const getUsersByRole = makeQuery({
  // TODO: permissions
  queryName: getUsersByRoleQuery,
  queryFields: getUsersByRoleFields,
  query: (role: Role): Promise<User[]> => {
    const usersFields = sign(getUsersByRoleQuery, getUsersByRoleFields);
    const typedValues = { role };
    return stompClient.getData('usersByRole', usersFields, typedValues);
  },
});

const api = {
  getCurrencies,
  getMetals,
  getUnits,
  getUsersByRole,
  getVaults,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:create-or-edit-metal');

export default api;
