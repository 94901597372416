import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { Deposit } from '../api';

const approveDepositQuery = 'approveInvoice';
const approveDepositFields = [
  'id',
  'number',
  'status',
  'amount',
  'creationDate',
  'clientComment',
  'externalId',
  'bankName',
  'purpose',
  'sourceOfFunds',
  'reference',
  { investorUser: ['displayName', 'id'] },
  { payment: ['amount', 'accountDetails', 'creationDate'] },
  { bankAccount: ['id', { currency: ['code'] }] },
];
const approveDeposit = makeQuery({
  queryName: approveDepositQuery,
  queryFields: approveDepositFields,
  query: ({ id, amount }: { id: number; amount: number }): Promise<Deposit> => {
    const typedValues = {
      ...v.bigDecimal({ amount }),
      ...v.long({ id }),
    };

    return stompClient.sendData(
      approveDepositQuery,
      sign(approveDepositQuery, approveDepositFields),
      typedValues
    );
  },
});

const api = {
  approveDeposit,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:approve-deposit-modal');

export default api;
