import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import moment, { Moment } from 'moment';
import { DatePicker, Form as AntForm } from 'antd';
import { defineMessage } from '@lingui/macro';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { dater } from '@helper/date';
import { useI18n } from '@hooks/i18n';
import { DATE_FORMAT } from '@constants/date';
import Label, { useLabel } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;
const { RangePicker } = DatePicker;

export interface InputDateRangeProps {
  allowClear?: boolean;
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  label: ReactNode;
  max?: string; // date iso string
  min?: string; // date iso string
  name: NamePath;
  onChange?: (date: [string, string]) => void;
  required?: boolean;
  uat?: string;
}

const InputDateRangeRaw: FC<
  InputDateRangeProps & {
    compactClass: string;
    instanceClass: string;
    itemClass: string;
    labelClass: string;
    labelShiftedClass: string;
    wrapperClass: string;
  }
> = ({
  allowClear = true,
  className,
  compact,
  disabled,
  label,
  max,
  min,
  name,
  onChange,
  required,
  uat,
  ...p
}) => {
  const uatAttribute = useFieldUat(name, 'date-range', uat);

  return (
    <div
      className={classnames(p.wrapperClass, className, {
        [p.compactClass]: compact,
      })}
      data-uat={uatAttribute}
    >
      <Item className={p.itemClass} name={name} required={required}>
        <CustomText
          name={name}
          instanceClass={p.instanceClass}
          label={label}
          labelClass={p.labelClass}
          labelShiftedClass={p.labelShiftedClass}
          onCustomChange={onChange}
          max={max}
          min={min}
          required={required}
          disabled={disabled}
          allowClear={allowClear}
        />
      </Item>
    </div>
  );
};

const CustomText: FC<{
  allowClear: boolean;
  disabled: boolean;
  instanceClass: string;
  label: ReactNode;
  labelClass: string;
  labelShiftedClass: string;
  max?: string;
  min?: string;
  name: NamePath;
  onChange?: (date: [string, string]) => void;
  onCustomChange?: (date: [string, string]) => void;
  required: boolean;
  value?: [string, string];
}> = (props) => {
  const { shifted, onBlur, onFocus } = useLabel({ value: props.value });
  const { i18n, lang } = useI18n();

  const [ranges, setRanges] = useState({});
  const pickerWrapper = useRef(null);

  useEffect(() => {
    const month: string = i18n._(defineMessage({ id: 'input.date.range.month', message: 'Month' }));
    const today: string = i18n._(defineMessage({ id: 'input.date.range.today', message: 'Today' }));
    setRanges({
      [today]: [moment(), moment()],
      [month]: [
        moment().startOf('month'),
        moment(maxDateNum > endOfMonthNum ? endOfMonthNum : maxDateNum),
      ],
    });
  }, [lang]);

  const [val1, val2] = props?.value || [];
  const endOfMonthNum: number = moment().endOf('month').valueOf();
  const maxDateNum: number = new Date(props.max).getTime();

  const onDisabledDate = (m: Moment) => {
    if (!props.max && !props.min) {
      return false;
    }
    const dateCheck: number = new Date(m.format('L')).getTime();
    const dateMin = !props.min ? 0 : new Date(moment(props.min).format('L')).getTime();
    const dateMax = !props.max ? Infinity : new Date(moment(props.max).format('L')).getTime();
    return !(dateMin <= dateCheck && dateCheck <= dateMax);
  };

  const onChange = (value: [Moment, Moment]) => {
    const [d1, d2] = value || [];
    const date1 = d1 && dater.toGqlDate(d1);
    const date2 = d2 && dater.toGqlDate(d2);
    props.onChange([date1, date2]);
    props.onCustomChange && props.onCustomChange([date1, date2]);
    setTimeout(() => {
      // focus not removed automatically
      pickerWrapper?.current
        ?.querySelector('.ant-picker-focused')
        ?.classList?.remove('ant-picker-focused');
    }, 1500);
  };

  return (
    <>
      <Label
        htmlFor={props.name}
        shifted={shifted}
        label={props.label}
        required={props.required}
        labelClass={props.labelClass}
        labelShiftedClass={props.labelShiftedClass}
      />

      <div ref={pickerWrapper}>
        <RangePicker
          className={props.instanceClass}
          dropdownClassName="tm2-field-date-range-calendar"
          value={[val1 && moment(val1), val2 && moment(val2)]}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={props.disabled}
          disabledDate={onDisabledDate}
          ranges={ranges}
          format={DATE_FORMAT}
          separator={'—'}
          placeholder={null}
          inputReadOnly={false}
          allowClear={props.allowClear}
          onOpenChange={(isOpen: boolean) => (isOpen ? onFocus() : onBlur())}
          showNow={false}
        />
      </div>
    </>
  );
};

export default InputDateRangeRaw;
export const InputDateRange: FC<InputDateRangeProps> = (props) => (
  <InputDateRangeRaw
    {...props}
    compactClass="tm2-form-field-compact"
    instanceClass="tm2-form-field-item-instance"
    itemClass="tm2-form-field-item"
    labelClass="tm2-form-label"
    labelShiftedClass="tm2-form-label-shifted"
    wrapperClass="tm2-form-field tm2-field-date-range"
  />
);
