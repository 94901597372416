import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Context } from '../../context';
import { configServiceActions } from '@config/store/service.acions';

export const useModel = () => {
  const reduxDispatch = useDispatch();
  const { dispatch } = useContext(Context);

  const goNextStep = () => {
    dispatch({ type: 'ON_STEP_COMPLETED' });
  };
  const finishPostReg = () => {
    reduxDispatch(configServiceActions.checkTutorialEvent(true));
    reduxDispatch(configServiceActions.postRegFinished());
  };
  return {
    goNextStep,
    finishPostReg,
  };
};
