import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { Permission, PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

type QueryCoinBalance = {
  amount: number;
  coin: {
    id: number;
    asset: string;
    haveSecondaryTrust: boolean;
  };
};

type CoinBalance = {
  balance: number;
  asset: string;
  haveSecondaryTrust: boolean;
};

const coinBalanceFields = {
  coinBalance: [
    'amount',
    {
      coin: ['id', 'asset', 'haveSecondaryTrust'],
    },
  ],
};

function mapCoinBalance(queryCoinBalance: QueryCoinBalance): CoinBalance {
  return {
    balance: queryCoinBalance.amount,
    asset: queryCoinBalance.coin.asset,
    haveSecondaryTrust: queryCoinBalance.coin.haveSecondaryTrust,
  };
}

type GetTransferInfoResponse = {
  balancesPrimary: Array<CoinBalance>;
  balancesSecondary: Array<CoinBalance>;
};

const getSecondaryCoinsQuery = 'secondaryAssets';
const getSecondaryCoinsFields = ['code'];
const getSecondaryCoins = makeQuery({
  queryName: getSecondaryCoinsQuery,
  queryFields: getSecondaryCoinsFields,
  query: (): Promise<Array<string>> => {
    type QueryResponse = Array<{ code: string }>;
    const typedValues = { subclassName: 'METALS' };

    return stompClient
      .getData<QueryResponse>(
        getSecondaryCoinsQuery,
        sign(getSecondaryCoinsQuery, getSecondaryCoinsFields),
        typedValues
      )
      .then((response) => response.map((item) => item.code));
  },
});

const getTransferInfoQuery = 'userBalances';
const getTransferInfoFields = [
  {
    primary: [
      {
        active: [{ ...coinBalanceFields }],
      },
    ],
  },
  // { // TM2-2997
  //   secondary: [
  //     {
  //       active: [{ ...coinBalanceFields }],
  //     },
  //   ],
  // },
];
const getTransferInfo = makeQuery({
  permissions: {
    and: [Permission.BALANCE_VIEW_PRIMARY_MY, Permission.BALANCE_VIEW_SECONDARY_MY],
  },
  queryName: getTransferInfoQuery,
  queryFields: getTransferInfoFields,
  query: (): Promise<GetTransferInfoResponse> => {
    type QueryResponse = {
      primary: { active: { coinBalance: QueryCoinBalance[] } };
      secondary: { active: { coinBalance: QueryCoinBalance[] } };
    };
    const typedValues = { showZeroBalances: true };

    return stompClient
      .getData<QueryResponse>(
        getTransferInfoQuery,
        sign(getTransferInfoQuery, getTransferInfoFields),
        typedValues
      )
      .then(({ primary, secondary }) => ({
        balancesPrimary: primary.active.coinBalance.map(mapCoinBalance),
        // TM2-2997
        balancesSecondary: [], // secondary.active.coinBalance.map(mapCoinBalance),
      }));
  },
});

const sendCoinFromPrimaryToSecondaryQuery = 'sendMetalToSecondary';
const sendCoinFromPrimaryToSecondaryFields = ['value'];
const sendCoinFromPrimaryToSecondary = makeQuery({
  queryName: sendCoinFromPrimaryToSecondaryQuery,
  queryFields: sendCoinFromPrimaryToSecondaryFields,
  query: (form) => {
    const { amount, asset } = form;
    const typedValues = {
      asset,
      ...v.bigDecimal({ amount: +amount }),
    };
    return stompClient
      .sendData(
        sendCoinFromPrimaryToSecondaryQuery,
        sign(sendCoinFromPrimaryToSecondaryQuery, sendCoinFromPrimaryToSecondaryFields),
        typedValues
      )
      .then((response) => response.value);
  },
});

const sendCoinFromSecondaryToPrimaryQuery = 'sendMetalToPrimary';
const sendCoinFromSecondaryToPrimaryFields = ['value'];
const sendCoinFromSecondaryToPrimary = makeQuery({
  queryName: sendCoinFromSecondaryToPrimaryQuery,
  queryFields: sendCoinFromSecondaryToPrimaryFields,
  query: (form) => {
    const { amount, asset } = form;
    const typedValues = {
      asset,
      ...v.bigDecimal({ amount: +amount }),
    };
    return stompClient
      .sendData(
        sendCoinFromSecondaryToPrimaryQuery,
        sign(sendCoinFromSecondaryToPrimaryQuery, sendCoinFromSecondaryToPrimaryFields),
        typedValues
      )
      .then((response) => response.value);
  },
});

const api = {
  getSecondaryCoins,
  getTransferInfo,
  sendCoinFromPrimaryToSecondary,
  sendCoinFromSecondaryToPrimary,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:transfer-coin');

export default api;
