import React, { FC, memo } from 'react';

import { Box } from '@components/box';
import {
  CreateTransaction,
  permissionsGroup as createPermissionsGroup,
} from './create-transaction';

export const permissionsGroup = createPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-transaction-actions',
});

type Props = {
  className: string;
};
export const ActionsRow: FC<Props> = memo<Props>((p) => {
  return (
    <Box className={p.className} compact>
      <CreateTransaction />
    </Box>
  );
});
