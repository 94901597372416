import { useQuery } from '@hooks/core';
import api from './api';

export const useBankAccounts = () => {
  const {
    data: bankAccounts,
    isLoading: isBankAccountsLoading,
    refresh: refreshBankAccounts,
  } = useQuery(api.getBankAccounts, { initial: [] });

  return {
    isBankAccountsLoading,
    bankAccounts,
    refreshBankAccounts,
  };
};
