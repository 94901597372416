import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { CreditCardViewState } from './credit-card/store/model';
import { creditCardViewReducer } from './credit-card/store/reducer';
import { WireTransferViewState } from './wire-transfer/store/model';
import { wireTransferViewReducer } from './wire-transfer/store/reducer';
import { wireTransferViewEffects } from './wire-transfer/store/effects';
import { AddFundsModalState } from './store/model';
import { addFundsModalReducer } from './store/reducer';
import { addFundsModalEffects } from './store/effects';

export interface AddFundsStoreState {
  creditCard: CreditCardViewState;
  modal: AddFundsModalState;
  wireTransfer: WireTransferViewState;
}

export const addFundsStoreReducer = combineReducers({
  creditCard: creditCardViewReducer,
  modal: addFundsModalReducer,
  wireTransfer: wireTransferViewReducer,
});

export function* addFundsStoreEffects() {
  yield all([fork(addFundsModalEffects), fork(wireTransferViewEffects)]);
}
