// DEPRECATED - весь этот файл должен быть удален со временем,
//              использовать эти константы можно только в крайнем случае

/* Form */
export const FORM_INIT = 'FORM_INIT';
export const FORM_IS_SEND = 'FORM_IS_SEND';
export const FORM_CHANGE = 'FORM_CHANGE';
export const FORM_CHANGE_CHECKBOX = 'FORM_CHANGE_CHECKBOX';
export const FORM_CHANGE_ALT = 'FORM_CHANGE_ALT';
export const FORM_ERRORS = 'FORM_ERRORS';
export const FORM_REMOVE_ARRAY_ELEMENT = 'FORM_REMOVE_ARRAY_ELEMENT';
export const FORM_ADD_ARRAY_ELEMENT = 'FORM_ADD_ARRAY_ELEMENT';

/* List */
export const LIST_FETCH = 'LIST_FETCH';
export const LIST_SORT = 'LIST_SORT';
export const LIST_FILTER = 'LIST_FILTER';
export const LIST_UPDATE = 'LIST_UPDATE';

export const COINS = {
  EDIT: {
    ON_VAULTS_RECEIVED: 'COINS.EDIT.ON_VAULTS_RECEIVED',
  },
};
