import { useContext, FC } from 'react';
import { Modal } from '@modules/modal';
import { Context } from '../context';
import { CurrencySetting } from './currency-setting';
import { Confirmation } from './confirmation';
import { Completion } from './completion';
import { ExchangeRateChanged } from './exchange-rate-changed';
import { NotEnoughBalance } from './not-enough-balance';
import { UnknownError } from './unknown-error';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const CurrencyExchangeModal: FC<Props> = ({ open, onClose }: Props) => {
  const { state } = useContext(Context);
  let stepScreen = <UnknownError />;

  if (state.step === 'currency-setting') {
    stepScreen = <CurrencySetting />;
  }
  if (state.step === 'confirmation') {
    stepScreen = <Confirmation />;
  }
  if (state.step === 'completion') {
    stepScreen = <Completion onAcknowledge={onClose} />;
  }
  if (state.step === 'not-enough-balance') {
    stepScreen = <NotEnoughBalance />;
  }
  if (state.step === 'exchange-rate-changed') {
    stepScreen = <ExchangeRateChanged />;
  }

  return (
    <Modal isVisible={open} onClose={onClose}>
      {stepScreen}
    </Modal>
  );
};
