import { useDispatch } from 'react-redux';

import { closeModalAction } from '@modules/modal';
import { TextModeForm, SearchItem } from '../context';
import { SearchConfigModal } from './index';

export const formId: string = 'search-form';

export const useModel = (p: SearchConfigModalProps) => {
  const dispatch = useDispatch();
  const closeModal = () => dispatch(closeModalAction(SearchConfigModal));

  const initialValues: TextModeForm = p.form;

  const onSubmitClicked = (form: TextModeForm) => {
    p.onModeChange(form);
    closeModal();
  };

  return {
    closeModal,
    initialValues,
    onSubmitClicked,
  };
};

export interface SearchConfigModalProps {
  fields: Array<SearchItem>;
  form: TextModeForm;
  onModeChange: (form: TextModeForm) => void;
}
