import React, { memo } from 'react';
import { Trans } from '@lingui/macro';
import { Form, InputNumber, Select, SubmitButton } from '@components/form';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Title } from '@components/typography/title';
import { Fields, formId, useModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:currency-exchange-manual-modal',
});

export const CurrencyExchangeManualModal: MFC = memo(({ isVisible }) => {
  const m = useModel();

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(CurrencyExchangeManualModal)}
    >
      <Form name={formId}>
        <ModalTitle>
          <Trans id="my_wallet.exchange_currency.currency_manual.title">Currencies & Amount</Trans>
        </ModalTitle>

        <ModalContent>
          <Title level={4} className="mb2">
            <Trans id="my_wallet.exchange_currency.currency_manual.input_suggestion">
              Please enter the currency and amount you wish to sell
            </Trans>
          </Title>
          <Title level={4} className="mb2">
            <Trans id="my_wallet.exchange_currency.currency_manual.note">
              Note: Currency exchange is available only for the Primary market balance
            </Trans>
          </Title>
          <Select
            name={Fields.currencyToSell}
            disabled={m.lockUI}
            data={m.availableStableBalances}
            required={true}
            label={
              <Trans id="my_wallet.exchange_currency.currency_manual.currency_to_sell">Sell</Trans>
            }
            onChange={(currencyId) => m.onCurrencyToSellSelected(currencyId)}
          />
          <InputNumber
            name={Fields.amountToSell}
            disabled={m.lockUI || !m.isCurrencyToSellSelected}
            min={m.stableLimit.min}
            max={m.maxCurrencyAmount}
            required={true}
            label={
              <Trans id="my_wallet.exchange_currency.currency_manual.amount_to_sell">
                Amount to sell
              </Trans>
            }
          />
          <Select
            name={Fields.currencyToBuy}
            disabled={m.lockUI || !m.isCurrencyToSellSelected}
            data={m.currencies}
            required={true}
            label={
              <Trans id="my_wallet.exchange_currency.currency_manual.currency_to_buy">Buy</Trans>
            }
          />
        </ModalContent>

        <ModalFooter>
          <SubmitButton
            name={formId}
            fullWidth={true}
            disabled={m.lockUI}
            onAction={m.onFormAction}
          >
            <Trans id="my_wallet.exchange_currency.currency_manual.submit">Send request</Trans>
          </SubmitButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
});
