import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from '../index.module.scss';
import { Title } from '@components/typography/title';
import { PermissionGroup } from '@permissions/permission-group';
import { useHasAccess, useUserHasAccess } from '@permissions/hooks';
import { HasAccess, Permission as P } from '@permissions/core';
import { TabPane } from '@components/tabs';
import { BankAccountsTab } from '../../../../components/tabs/bank-accounts';
import { ProfileOther } from '../../../api';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup],
  marker: 'layout:profile-other-page-tab-bank-accounts',
});

const accessMyPermissions = { and: [permissionsGroup] };
const accessUserPermissions = { and: [P.BANK_ACCOUNTS_VIEW_MY] };

export const useCheckShowBankAccountsTab = (profile: ProfileOther) => {
  const isViewedUserHasBankTab = useUserHasAccess(profile.permissions, accessUserPermissions);
  const isAuthorizedUserCanSeeBankTab = useHasAccess(accessMyPermissions);
  return isViewedUserHasBankTab && isAuthorizedUserCanSeeBankTab;
};

export const UserBankAccountsTab: FC<{
  hidden: boolean;
  profile: ProfileOther;
  tabKey: string;
}> = (p) => (
  <HasAccess permissions={accessMyPermissions}>
    <HasAccess userPermissions={p.profile.permissions} permissions={accessUserPermissions}>
      <TabPane tabKey={p.tabKey} key={p.tabKey}>
        <UserBankAccountsTabComponent hidden={p.hidden} />
      </TabPane>
    </HasAccess>
  </HasAccess>
);

export const UserBankAccountsTabComponent: FC<{ hidden: boolean }> = memo(({ hidden }) => {
  const m = useModel();

  return m.isBankAccountsLoading ? null : (
    <div className={classnames(s.tab, { [s.hidden]: hidden })}>
      {!!m.bankAccounts.length ? (
        <BankAccountsTab banks={m.bankAccounts} disabled={m.isBankAccountsLoading} />
      ) : (
        <Title level={3} compact>
          <Trans id="profile.bank_details.no_banks">No linked bank details</Trans>
        </Title>
      )}
    </div>
  );
});
