import sign from 'tm2sign.macro';
import _ from 'lodash';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

import type { BankAccount as BankAccountShared } from '@gql/UserBankAccount.model';

interface WithdrawalDTO {
  amount: number;
  userBankAccount?: { id: number };
  currency: { id: number };
  proofOfAddress?: { id: number };
  userId?: number;
}
const createWithdrawalFields = ['id'];
const createWithdrawalQuery = 'createWithdrawal';
const createWithdrawal = makeQuery({
  permissions: { or: [Permission.WITHDRAWAL_CREATE_MY] },
  queryName: createWithdrawalQuery,
  queryFields: createWithdrawalFields,
  query: (withdrawal: WithdrawalDTO): Promise<number> => {
    return stompClient.sendData<number>(
      createWithdrawalQuery,
      sign(createWithdrawalQuery, createWithdrawalFields),
      v.withdrawalCreateDTOInput({ withdrawal })
    );
  },
});

export interface StableBalance {
  balance: number;
  currency: { code: string; id: number };
}
const getStableBalancesFields = ['balance', { currency: ['code', 'id'] }];
const getStableBalancesQuery = 'stableBalance';
const getStableBalances = makeQuery({
  permissions: { or: [Permission.BANK_ACCOUNTS_VIEW_MY] },
  queryName: getStableBalancesQuery,
  queryFields: getStableBalancesFields,
  query: (): Promise<Array<StableBalance>> => {
    return stompClient
      .getData<Array<StableBalance>>(
        getStableBalancesQuery,
        sign(getStableBalancesQuery, getStableBalancesFields)
      )
      .then((balances) =>
        balances.filter((balance: StableBalance) => currenciesFilter(balance.currency))
      );
  },
});

export type BankAccount = BankAccountShared;
const getBankAccountsFields = [
  'accountNumber',
  'bankName',
  'comment',
  {
    currency: [
      'code',
      'id',
      {
        withdrawalRules: ['minWithdrawalAmount', 'minWithdrawalFeeAmount', 'withdrawalFeePercent'],
      },
    ],
  },
  'ibanNumber',
  'id',
  'nickname',
  'routeCode',
  'swiftCode',
];
const getBankAccountsQuery = 'userBankAccounts';
const getBankAccounts = makeQuery({
  permissions: { or: [Permission.BANK_ACCOUNTS_VIEW_MY] },
  queryName: getBankAccountsQuery,
  queryFields: getBankAccountsFields,
  query: (userId?: number): Promise<Array<BankAccount>> => {
    return stompClient
      .getData<Array<BankAccount>>(
        getBankAccountsQuery,
        sign(getBankAccountsQuery, getBankAccountsFields),
        v.long({ userId })
      )
      .then((accounts) =>
        accounts.filter((account) => {
          return currenciesFilter(account.currency);
        })
      );
  },
});

interface Currency {
  id: number;
  code: string;
}
const getCurrenciesMyQuery = 'currencies';
const getCurrenciesMyFields = ['code', 'id'];
const getCurrenciesMy = makeQuery({
  queryName: getCurrenciesMyQuery,
  queryFields: getCurrenciesMyFields,
  query: (): Promise<Array<Currency>> => {
    return stompClient
      .getData<Array<Currency>>(
        getCurrenciesMyQuery,
        sign(getCurrenciesMyQuery, getCurrenciesMyFields)
      )
      .then((response) => response.filter((c) => currenciesFilter(c)))
      .then((currencies) => _.uniqBy(currencies, 'id'));
  },
});

const api = {
  createWithdrawal,
  getBankAccounts,
  getCurrenciesMy,
  getStableBalances,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:withdraw-request-modal');

export default api;
