import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import { Role } from '@permissions/core';
import { modalSelectors } from '@modules/modal';
import { useShowHandleUserModal } from '../../../../modals/handle-user-modal';

const editPermissionsRoles = [Role.admin];
export const useModel = (refreshProfile: () => void) => {
  const isEditOpened = useSelector(modalSelectors.isModalOpened);
  const { userId } = useParams<{ userId: string }>();
  const { showHandleUserModal } = useShowHandleUserModal(+userId);

  const showEditModal = () => showHandleUserModal({ onUserHandled: refreshProfile });

  return {
    editPermissionsRoles,
    isEditOpened,
    showEditModal,
  };
};
