import { FC, ReactNode, useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from '../page-table/index.module.scss';
import { useAltTableBodyCellModel, useAltTableHeadCellModel } from './model';

const AltTable: FC<AltTableProps> = ({
  columns,
  data,
  disabled,
  headClasses,
  bodyClasses,
  rootClasses,
  noDataText,
  onNoData = () => {},
  trackHover,
  uat,
  ...props
}) => {
  /*
   * NOTE: из-за useMemo блокировка работает "криво". Сейчас UI таблицы блокируетс по клику на сортировку
   * и автоматически разблокируется по приходу данных таблицы с бекенда
   */
  const [lockUI, setLockUI] = useState(disabled);

  useEffect(() => {
    if (lockUI) {
      setLockUI(false);
    }
  }, [data]); // eslint-disable-line

  /*
   * NOTE: жестко закостылено. Эта логика нужна, чтобы убрать мерцание текста "no data" в начальный момент
   */
  const [isInitiated, setInitiated] = useState(false);
  useEffect(() => {
    setInitiated(false);
    const timerId = setTimeout(() => {
      setInitiated(true);
    }, 2000);
    return () => {
      clearTimeout(timerId);
    };
  }, [data.length]); // eslint-disable-line
  const isShowNoData = isInitiated && !lockUI && !data.length;
  useEffect(() => {
    onNoData(isShowNoData);
  }, [isShowNoData]);

  const noDataTextDefault = (
    <span data-uat="table-no-data">
      <Trans id="reusable.table.no_results_default">
        You have no documents, records or history in this section
      </Trans>
    </span>
  );

  return (
    <div className={classnames('table-wrapper', rootClasses)} data-uat={uat} {...props}>
      <table className="default-table" data-uat="table">
        <thead className={headClasses}>
          <tr>
            {columns.map((column, index) => (
              <HeadCell key={index} handleLock={{ lockUI, setLockUI }} params={column.head} />
            ))}
          </tr>
        </thead>

        {!isShowNoData ? (
          <tbody className={bodyClasses}>
            {data.map((dataItem, index) => (
              <TableRow
                key={index}
                data={dataItem}
                index={index}
                columns={columns}
                trackHover={trackHover}
              />
            ))}
          </tbody>
        ) : (
          <tfoot>
            <tr>
              <td colSpan={columns.length}>
                <div className={s.noData}>{noDataText || noDataTextDefault}</div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export interface AltTableProps {
  columns: Array<AltTableColumnProp>;
  data: Array<any>; // TODO нужен норм тип и разобраться, для чего это поле вообще нужно
  disabled: boolean;
  headClasses?: string;
  bodyClasses?: string;
  rootClasses?: string;
  noDataText?: ReactNode;
  onNoData?: (flag: boolean) => void;
  trackHover?: boolean;
  uat?: string;
}

export interface AltTableColumnProp {
  className?: string;
  cell:
    | { body: string | Function | React.Component | React.ReactNode }
    | Function
    | string
    | React.Component;
  head:
    | {
        body: string | Function | React.Component | React.ReactNode;
        onSort?: Function;
        sortDirection?: string;
      }
    | Function
    | string
    | React.Component
    | React.ReactNode;
}

const HeadCell = ({ handleLock, params }) => {
  const { onCellClicked, cellClasses, other, cellContent } = useAltTableHeadCellModel(
    params,
    handleLock
  );
  return (
    <th onClick={onCellClicked} className={cellClasses} {...other}>
      {cellContent}
    </th>
  );
};

const TableRow = ({ data, columns, index: rowIndex, trackHover }) => {
  const [isHovered, setIsHovered] = useState(false);

  const onMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  return (
    <tr
      onMouseEnter={trackHover ? onMouseEnter : undefined}
      onMouseLeave={trackHover ? onMouseLeave : undefined}
      data-uat="table-data-row"
    >
      {columns.map((column, index) => (
        <BodyCell
          key={`${rowIndex}_${index}`}
          className={classnames(column.className, column.cell?.className)}
          params={column.cell}
          data={data}
          hovered={isHovered}
        />
      ))}
    </tr>
  );
};

const BodyCell = ({ className, params, data, hovered }) => {
  const { other, cellContent } = useAltTableBodyCellModel(params, data, hovered);
  return (
    <td {...other} className={className}>
      {cellContent}
    </td>
  );
};

export default AltTable;
