import { FC } from 'react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';
import { ButtonPrimary, ButtonSecondary } from '@components/button';
import { Divider } from '@components/divider';
import themeStyle from '@styles/theme/index.module.scss';
import s from './index.module.scss';

import { useModel } from './model';

export const VerificationSuggestionStep: FC = () => {
  const m = useModel();

  return (
    <div
      className={classnames(s.container, themeStyle.themeContrast)}
      data-uat="post-reg-verification-prompt-page"
    >
      <h2 className={s.title}>
        <Trans id="signup.verification_suggestion_step.title">
          Identify yourself and get verified
        </Trans>
      </h2>
      <p className={s.description}>
        <Trans id="signup.verification_suggestion_step.description_1">
          We are required, by law, to ascertain your identity and verify the information and data
          you provide to us.
        </Trans>
        <span className={classnames(s.descriptionSeparator, 'mb1')} />
        <Trans id="signup.verification_suggestion_step.description_2">
          Provide your identity document (Passport, Photo ID card, Driving licence, Residence
          permit) and Utility bill for visual scanning. Ensure that it is not expired or physically
          damaged.
        </Trans>
      </p>

      <p className={s.instructionsHeader}>
        <Trans id={'signup.verification_suggestion_step.instructions_header'}>
          By verifying your account now you will
        </Trans>
      </p>
      <div className={s.instructionsContainer}>
        <div className={s.instructionsOption}>
          <span className={s.instructionIndex}>1.</span>
          <Trans id={'signup.verification_suggestion_step.instructions_1'}>
            Get a verified checkmark
          </Trans>
        </div>
        <div className={s.instructionsOption}>
          <span className={s.instructionIndex}>2.</span>
          <Trans id={'signup.verification_suggestion_step.instructions_2'}>
            Remove deposit limitations
          </Trans>
        </div>
        <div className={s.instructionsOption}>
          <span className={s.instructionIndex}>3.</span>
          <Trans id="signup.verification_suggestion_step.instructions_3">
            To get access to money withdrawal & metal redemption functionality
          </Trans>
        </div>
      </div>

      <ButtonPrimary
        className={s.continueButton}
        onClick={m.goNextStep}
        fullWidth={true}
        uat="continue-verify-button"
      >
        <Trans id={'signup.verification_suggestion_step.continue_button'}>continue</Trans>
      </ButtonPrimary>

      <Divider className={s.divider} />

      <ButtonSecondary
        className={s.skipButton}
        onClick={m.finishPostReg}
        fullWidth={true}
        uat="skip-verify-button"
      >
        <Trans id={'signup.verification_suggestion_step.skip_to_far_button'}>skip to far</Trans>
      </ButtonSecondary>
    </div>
  );
};
