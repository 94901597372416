import { useCallback } from 'react';

import { useCallDispatch } from '@hooks/core';
import { MFC, showModalAction } from '@modules/modal';

import { CreateUMSAssetModal, ModalProps } from './create-ums-asset-modal';
import { useUMSAssetCode } from './hooks';

export const useModel = () => {
  const { umsAssetCode, onCreateUMSAsset } = useUMSAssetCode();

  // @ts-ignore
  const showModal = useCallDispatch<{ modal: MFC; props: ModalProps }>(showModalAction);

  const showApproveRedeemModal = useCallback(() => {
    const props: ModalProps = { onCreateUMSAsset };
    return showModal({ modal: CreateUMSAssetModal, props });
  }, []);

  return {
    umsAssetCode,
    showApproveRedeemModal,
  };
};
