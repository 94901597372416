import { useResizeDetector } from 'react-resize-detector';
import { Table as BaseTable } from '../base';
import type { BaseItem, TableProps as BaseTableProps } from '../base';

export type {
  CellAlignment,
  ColumnConfig,
  ColumnType,
  RowHeight,
  RowEventHandler,
  RowConfig,
  HeadCellBoxProps,
  HeadCellRendererProps,
  RowCellBoxProps,
  RowCellRendererProps,
  RowBoxProps,
  RowRendererProps,
  BaseItem,
  EstimateRowHeight,
  HeaderProps,
  RowProps,
  KeyExtractor,
  ListKey,
  OnLoadMore,
  InMemorySorter,
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  ScrollToIndex,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterParams,
  TableSorterReturnType,
  UseTableReturnType,
} from '../base';

export {
  areFieldsEqual,
  areOrdersEqual,
  HeadCellBox,
  HeadCellRenderer,
  RowCellBox,
  RowCellRenderer,
  RowBox,
  RowRenderer,
  useTable,
  HEAD_CELL_PADDING_TOP,
  HEAD_CELL_PADDING_BOTTOM,
  ROW_CELL_PADDING_TOP,
  ROW_CELL_PADDING_BOTTOM,
} from '../base';

export type TableProps<T extends BaseItem, K> = Omit<
  BaseTableProps<T, K>,
  'containerHeight' | 'containerWidth'
>;

const REFRESH_RATE = 300;

export function Table<T extends BaseItem, K>(props: TableProps<T, K>) {
  const {
    ref: containerRef,
    height: containerHeight,
    width: containerWidth,
  } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: REFRESH_RATE,
  });

  const { className, ...other } = props;

  const shouldRender = typeof containerWidth === 'number' && typeof containerHeight === 'number';

  return (
    <div ref={containerRef} className={className} style={{ position: 'relative' }}>
      {shouldRender ? (
        <BaseTable containerWidth={containerWidth} containerHeight={containerHeight} {...other} />
      ) : null}
    </div>
  );
}
