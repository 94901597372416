import { useCallback, useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import { useI18n } from '@hooks/i18n';
import { useTransactionTypeTranslations } from '../../hooks';
import api from './api';

export const useTransactionTypes = () => {
  const translations = useTransactionTypeTranslations();
  const transactionTypes: Array<SelectItem> = useMemo(
    () => [
      { label: translations['DEPOSIT'], value: 'DEPOSIT' },
      { label: translations['TRANSFER'], value: 'TRANSFER' },
      { label: translations['WITHDRAW'], value: 'WITHDRAW' },
    ],
    [translations]
  );
  return transactionTypes;
};

export const useBankAccounts = (selectedCurrencyId: number) => {
  const { i18n, lang } = useI18n();
  const { data, isLoading: isBanksAccountsLoading } = useQuery(api.getBankAccounts, {
    initial: [],
  });

  const bankAccounts: Array<SelectItem> = useMemo(
    () =>
      data.map((account) => ({
        disabled: account.currency.id !== selectedCurrencyId,
        label: `${account.accountNumber} (${account.currency.code})`,
        tooltip: !selectedCurrencyId
          ? i18n._(
              defineMessage({
                id: 'cm_transaction_modal.form.select.tooltip.no_currency',
                message: 'Please select transaction currency first',
              })
            )
          : account.currency.id !== selectedCurrencyId
          ? i18n._(
              defineMessage({
                id: 'cm_transaction_modal.form.select.tooltip.wrong_currency',
                message: 'Account currency does not match the selected transaction currency',
              })
            )
          : undefined,
        value: account.id,
      })),
    [data, lang, selectedCurrencyId]
  );

  const getBankAccountInfo = useCallback(
    (id: number) => data.find((account) => id === account.id),
    [data]
  );

  return {
    bankAccounts,
    getBankAccountInfo,
    isBanksAccountsLoading,
  };
};
