import React from 'react';
import { Trans } from '@lingui/macro';

import { ButtonPrimary } from '@components/button';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';

import { useModel } from './model';

type Props = {
  invoiceId: number;
};
export const NewDepositModal: MFC<Props> = ({ invoiceId, isVisible }) => {
  const m = useModel(invoiceId);

  return (
    <Modal isVisible={isVisible} onClose={() => m.onClose(NewDepositModal)}>
      <ModalTitle>
        <Trans id="invoice_modal.header">Deposit request was created</Trans>
      </ModalTitle>

      <ModalContent>
        <Trans id="invoice_modal.content">
          Your deposit request was successfully created and valid till: {m.validityDate}. Please
          also make sure to use the "Payment reference" and "Purpose" for each currency/bank account
          transfer. Failure to mention the “payment reference” in your payment may cause delays in
          topping up your TM2 account or even rejection of incoming funds.
        </Trans>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} onClick={m.onDownloadClicked}>
          <Trans id="invoice_modal.download_bank_details">Download Bank Details</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
