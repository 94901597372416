import React, { ChangeEvent, FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form as AntForm, Input } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import s from './index.module.scss';
import { Label, useLabel } from '../label';
import { useFieldUat } from '../uat';
import { ReactComponent as HiddenIcon } from './images/password-hidden.svg';
import { ReactComponent as ShownIcon } from './images/password-shown.svg';

const { Item } = AntForm;
const { Password } = Input;

export const InputPassword: FC<{
  autoComplete?: 'new-password' | undefined;
  className?: string;
  disabled?: boolean;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  name: NamePath;
  required?: boolean;
  uat?: string;
}> = ({ autoComplete, className, disabled, fieldKey, label, name, required, uat, ...props }) => {
  const uatAttribute = useFieldUat(name, 'input-password', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-text', s.field, className)}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} fieldKey={fieldKey} required={required}>
        <CustomPassword
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          autoComplete={autoComplete}
          {...props}
        />
      </Item>
    </div>
  );
};

const CustomPassword: FC<{
  autoComplete: 'new-password' | undefined;
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required: boolean;
  value?: number;
}> = (_props) => {
  const { autoComplete, disabled, label, name, onChange, required, value, ...props } = _props;
  const { shifted, onBlur, onFocus } = useLabel({ value: value });

  return (
    <>
      <Label htmlFor={name} shifted={shifted} label={label} required={required} />
      <Password
        className={classnames('tm2-form-field-item-instance', s.instance)}
        iconRender={(visible) => (visible ? <ShownIcon /> : <HiddenIcon />)}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        autoComplete={autoComplete}
        {...props}
      />
    </>
  );
};
