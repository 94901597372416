export function mergeFields(fields1: any, fields2: any): object {
  let res = [...fields2];

  for (let field of fields1) {
    if (typeof field === 'string' && !fields2.includes(field)) {
      res.push(field);
    }
    if (typeof field === 'object') {
      const fieldKey = Object.keys(field)[0];

      const mirroredField = fields2.find(
        (f) => typeof f === 'object' && Object.keys(f)[0] === fieldKey
      );

      if (mirroredField) {
        res = res.filter((f) => (typeof f === 'object' ? Object.keys(f)[0] !== fieldKey : true));
        res.push({
          [fieldKey]: mergeFields(field[fieldKey], mirroredField[fieldKey]),
        });
      } else {
        res.push(field);
      }
    }
  }
  return res;
}
