import { Selector } from '@models/redux';
import {
  BankAccount,
  ExternalClientBankAccount,
  FundsInvestor,
} from '../../../deposits-api.service';
import { defaultExternalBankAccount, WireTransferViewState } from './model';

const wireTransferViewState: Selector<WireTransferViewState> = (root) =>
  root.main?.deposits?.addFunds?.wireTransfer;

const bankAccounts: Selector<Array<BankAccount>> = (root) =>
  wireTransferViewState(root).bankAccounts;

const externalClientBankAccounts: Selector<Array<ExternalClientBankAccount>> = (root) =>
  wireTransferViewState(root).externalClientBankAccounts;

const selectedCurrencyId: Selector<number | null> = (root) =>
  wireTransferViewState(root).selectedCurrencyId;

const availableExternalClientBankAccounts: Selector<Array<ExternalClientBankAccount>> = (root) => {
  const externalAccounts = externalClientBankAccounts(root);
  const currencyId = selectedCurrencyId(root);
  return externalAccounts.filter(
    (account) =>
      account.currency?.id === currencyId ||
      account.externalId === defaultExternalBankAccount.externalId
  );
};

const investors: Selector<Array<FundsInvestor>> = (root) => wireTransferViewState(root).investors;

const lockUI: Selector<boolean> = (root) =>
  wireTransferViewState(root).isGenerateDepositLoading ||
  wireTransferViewState(root).isGetInvestorsLoading ||
  wireTransferViewState(root).isGetExternalClientBankAccountsLoading;

export const wireTransferViewSelectors = {
  bankAccounts,
  availableExternalClientBankAccounts,
  investors,
  lockUI,
  selectedCurrencyId,
  wireTransferViewState,
};
