import { RedeemModalActionsTypes as Types } from './model';

const onRedeemClickedAction = (balance) => ({
  payload: balance,
  type: Types.ON_REDEEM_CLICKED,
});

export const redeemModalUserActions = {
  onRedeemClicked: onRedeemClickedAction,
};
