import { useMemo } from 'react';
import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import api from './api';

export const useUsersByRole = (role) => {
  const { data: usersByRoleData, isLoading: isUsersByRoleLoading } = useQuery(
    () => api.getUsersByRole(role),
    { initial: [] }
  );

  let usersByRole: Array<SelectItem> = useMemo(() => {
    return usersByRoleData?.map((u) => ({
      label: u.displayName,
      value: u.id,
    }));
  }, [usersByRoleData]);

  return { usersByRole, isUsersByRoleLoading };
};
