import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configApi, configSelectors, configServiceActions } from '@config/core';
import { handleBackendError } from '@modules/notify';

export const useModel = () => {
  const dispatch = useDispatch();
  const [lockUI, setLockUI] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const isAuthorized = useSelector(configSelectors.isAuthorized);
  const lang = useSelector(configSelectors.lang);

  const onChangeLanguageClicked = async (language) => {
    try {
      setLockUI(true);
      if (isAuthorized) {
        await configApi.changeLanguage(language);
      } else {
        dispatch(configServiceActions.checkAppLanguage(language));
      }
      setExpanded(false);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return {
    expanded,
    lang,
    lockUI,
    onChangeLanguageClicked,
    setExpanded,
  };
};
