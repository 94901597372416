import { MarketingTableColumnIds as ColumnIds, MarketingTableState } from './model';
import { RootState, Selector } from '@models/redux';
import { SortItem } from '@models/core';
import { TableHeaderSortType as Sort } from '../../../../../components/page-table';
import { MarketingCompaniesItem } from '../api';

const marketingCompaniesState: Selector<MarketingTableState> = (root: RootState) =>
  root.main?.tradingPlatform?.marketingCompanies;

const columnSortDirection: Selector<(id: ColumnIds) => Sort> =
  (root: RootState) => (columnId: ColumnIds) => {
    return marketingCompaniesState(root).sort.filter((col) => col.id === columnId)[0].direction;
  };

const isHasMore: Selector<boolean> = (root: RootState) => marketingCompaniesState(root).isHasMore;

const lockUI: Selector<boolean> = (root: RootState) =>
  marketingCompaniesState(root).isMarketingCompaniesLoading;

const pageIndex: Selector<number> = (root: RootState) => marketingCompaniesState(root).pageIndex;

const marketingCompanies: Selector<Array<MarketingCompaniesItem>> = (root: RootState) =>
  marketingCompaniesState(root).marketingCompanies;

const sort: Selector<Array<SortItem<ColumnIds>>> = (root: RootState) =>
  marketingCompaniesState(root).sort;

export const marketingCompaniesSelectors = {
  marketingCompaniesState,
  columnSortDirection,
  isHasMore,
  lockUI,
  pageIndex,
  marketingCompanies,
  sort,
};
