import { ReduxAction } from '@models/redux';
import { VaultInfo } from '../../vaults-api.service';
import {
  OnShowVaultBalanceDetailsPayload,
  OnVaultsLoadedPayload,
  OnVaultsSortedPayload,
  VaultsPageActionsTypes as T,
} from './model';

class OnAddVaultClickedAction extends ReduxAction<T.ON_ADD_VAULT_CLICKED, void> {
  public readonly type: T.ON_ADD_VAULT_CLICKED = T.ON_ADD_VAULT_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnConfirmVaultBalanceClickedAction extends ReduxAction<
  T.ON_CONFIRM_VAULT_BALANCE_CLICKED,
  VaultInfo
> {
  public readonly type: T.ON_CONFIRM_VAULT_BALANCE_CLICKED = T.ON_CONFIRM_VAULT_BALANCE_CLICKED;
  public constructor(public payload: VaultInfo) {
    super(payload);
  }
}

class OnDeleteVaultClickedAction extends ReduxAction<T.ON_DELETE_VAULT_CLICKED, VaultInfo> {
  public readonly type: T.ON_DELETE_VAULT_CLICKED = T.ON_DELETE_VAULT_CLICKED;
  public constructor(public payload: VaultInfo) {
    super(payload);
  }
}

class OnEditVaultClickedAction extends ReduxAction<T.ON_EDIT_VAULT_CLICKED, VaultInfo> {
  public readonly type: T.ON_EDIT_VAULT_CLICKED = T.ON_EDIT_VAULT_CLICKED;
  public constructor(public payload: VaultInfo) {
    super(payload);
  }
}

class OnPageLeftAction extends ReduxAction<T.ON_PAGE_LEFT, void> {
  public readonly type: T.ON_PAGE_LEFT = T.ON_PAGE_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnPageOpenedAction extends ReduxAction<T.ON_PAGE_OPENED, void> {
  public readonly type: T.ON_PAGE_OPENED = T.ON_PAGE_OPENED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnShowVaultBalanceDetailsAction extends ReduxAction<
  T.ON_SHOW_VAULT_BALANCE_DETAILS_CLICKED,
  OnShowVaultBalanceDetailsPayload
> {
  public readonly type: T.ON_SHOW_VAULT_BALANCE_DETAILS_CLICKED =
    T.ON_SHOW_VAULT_BALANCE_DETAILS_CLICKED;
  public constructor(public payload: OnShowVaultBalanceDetailsPayload) {
    super(payload);
  }
}

class OnVaultsLoadedAction extends ReduxAction<T.ON_VAULTS_LOADED, OnVaultsLoadedPayload> {
  public readonly type: T.ON_VAULTS_LOADED = T.ON_VAULTS_LOADED;
  public constructor(public payload: OnVaultsLoadedPayload) {
    super(payload);
  }
}

class OnVaultsSortedAction extends ReduxAction<T.ON_VAULTS_SORTED, OnVaultsSortedPayload> {
  public readonly type: T.ON_VAULTS_SORTED = T.ON_VAULTS_SORTED;
  public constructor(public payload: OnVaultsSortedPayload) {
    super(payload);
  }
}

export const vaultsPageUserActions = {
  onAddVaultClicked: () => new OnAddVaultClickedAction().get(),
  onConfirmVaultBalanceClicked: (info: VaultInfo) =>
    new OnConfirmVaultBalanceClickedAction(info).get(),
  onDeleteVaultClicked: (info: VaultInfo) => new OnDeleteVaultClickedAction(info).get(),
  onEditVaultClicked: (info: VaultInfo) => new OnEditVaultClickedAction(info).get(),
  onPageLeft: () => new OnPageLeftAction().get(),
  onPageOpened: () => new OnPageOpenedAction().get(),
  onShowVaultBalanceDetails: (vault: OnShowVaultBalanceDetailsPayload) =>
    new OnShowVaultBalanceDetailsAction(vault).get(),
  onVaultsLoaded: (data: OnVaultsLoadedPayload) => new OnVaultsLoadedAction(data).get(),
  onVaultsSorted: (data: OnVaultsSortedPayload) => new OnVaultsSortedAction(data).get(),
};

export type VaultsPageUserActionsTypes =
  | OnAddVaultClickedAction
  | OnConfirmVaultBalanceClickedAction
  | OnDeleteVaultClickedAction
  | OnEditVaultClickedAction
  | OnPageLeftAction
  | OnPageOpenedAction
  | OnShowVaultBalanceDetailsAction
  | OnVaultsLoadedAction
  | OnVaultsSortedAction;

export declare namespace VaultsPageUserActions {
  type OnAddVaultClicked = OnAddVaultClickedAction;
  type OnConfirmVaultBalanceClicked = OnConfirmVaultBalanceClickedAction;
  type OnDeleteVaultClicked = OnDeleteVaultClickedAction;
  type OnEditVaultClicked = OnEditVaultClickedAction;
  type OnPageLeft = OnPageLeftAction;
  type OnPageOpened = OnPageOpenedAction;
  type OnShowVaultBalanceDetails = OnShowVaultBalanceDetailsAction;
  type OnVaultsLoaded = OnVaultsLoadedAction;
  type OnVaultsSorted = OnVaultsSortedAction;
}
