import { OpenIdInfo, session, SessionStatus as Session } from '@services/session';

enum OpenIdStatus {
  changed = 'changed',
  error = 'error',
  unchanged = 'unchanged',
}

class OpenIdConnectService {
  public async getSessionStatus(): Promise<Session> {
    const sessionStatus: Session = session.sessionStatus();
    if (sessionStatus === Session.bad) {
      return Promise.resolve(Session.bad);
    }
    const openIdStatus = await new Promise((resolve) => {
      const openId: OpenIdInfo = session.openIdInfo();
      const iframe: HTMLIFrameElement = document.createElement('iframe');
      iframe.src = openId.iframeSrc;
      iframe.style.display = 'none';
      iframe.onload = () => iframe.contentWindow.postMessage(openId.message, openId.originTarget);
      window.addEventListener(
        'message',
        (event) => {
          if (event?.origin !== openId.originTarget) {
            return;
          }
          resolve(event.data);
          iframe.remove();
        },
        false
      );
      document.body.appendChild(iframe);
    });

    if (openIdStatus === OpenIdStatus.error) {
      return Session.bad;
    }
    if (openIdStatus === OpenIdStatus.changed) {
      return Session.changed;
    }
    // TODO-2833: fix types
    return sessionStatus as any;
  }
}

export const openIdConnectService = new OpenIdConnectService();
