import React from 'react';
import { defineMessage } from '@lingui/macro';
import { PageContent, PageTitle } from '@components/page';
import { useI18n } from 'containers/services/commonService';
import { MetalsActions } from './actions-block';
import { MetalsTable } from './table';

/**
 * All digital metals list for account officer users
 */
export const MetalsPage = () => {
  const { i18n } = useI18n();

  return (
    <PageContent className="metals-page" uat="metals-page">
      <PageTitle title={i18n._(defineMessage({ id: 'router.symbols', message: 'Symbols' }))} />

      <MetalsActions />

      <MetalsTable />
    </PageContent>
  );
};
