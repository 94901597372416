import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { defineMessage } from '@lingui/macro';
import { useI18n, usePage } from 'containers/services/commonService';
import { coinsPageRequest, setAssetActiveMutation } from 'containers/services/graphqlFacade';
import { configSelectors } from '@config/core';
import gqlFields from './fields';

const { coinsPageFields, setAssetActiveFields } = gqlFields;

export const useSetAssetActive = () => {
  const wsInstance = useSelector(configSelectors.wsInstance);
  return useCallback(
    (asset, active) => setAssetActiveMutation(wsInstance, setAssetActiveFields, asset, active),
    [wsInstance]
  );
};

export const useCoinsPage = () => usePage(coinsPageRequest, coinsPageFields);

export const useCoinsTableMetals = () => {
  const [coinsTableMetals, setCoinsTableMetals] = useState({});
  const { i18n, lang } = useI18n();

  useEffect(() => {
    setCoinsTableMetals({
      Rhodium: i18n._(defineMessage({ id: 'coins_metal.rhodium', message: 'Rhodium' })),
      Gold: i18n._(defineMessage({ id: 'coins_metal.gold', message: 'Gold' })),
    });
  }, [lang]); // eslint-disable-line

  return coinsTableMetals;
};
