import { handleBackendError } from '@modules/notify';
import { loggerService as logger } from '@services/logger';
import {
  ExceptionError,
  HandledError,
  InfoError,
  OperationError,
  RawError,
  SystemError,
  TypedError,
  UnknownError,
  ValidationError,
} from '../errors';

export type InterceptedErrors =
  | HandledError
  | OperationError
  | TypedError
  | UnknownError
  | ValidationError;

export const stompFailInterceptor = (error: RawError): InterceptedErrors => {
  // TODO: if (instanceof error !== RawError) return error;

  if (error instanceof ValidationError) {
    return error;
  }
  if (error.type === 'VALIDATION_ERROR') {
    return new ValidationError(error);
  }
  if (error instanceof TypedError) {
    return error;
  }
  if (error instanceof HandledError) {
    return error;
  }

  if (error.type === 'OPERATION_ERROR') {
    handleBackendError(new OperationError(error));
    return new HandledError(error);
  }

  if (error.type === 'TYPED_ERROR') {
    return new TypedError(error);
  }

  if (error.type === 'INFO_ERROR') {
    handleBackendError(new InfoError(error));
    return new HandledError(error);
  }

  logger.error('Stomp request responded with an error', {
    location: 'src/shared/services/stomp/fail.interceptor.js:stompFailInterceptor',
    rawError: error,
  });

  if (error.type === 'EXCEPTION_ERROR') {
    handleBackendError(new ExceptionError(error));
    return new HandledError(error);
  }

  if (error.type === 'SYSTEM_ERROR') {
    handleBackendError(new SystemError(error));
    return new HandledError(error);
  }

  return new UnknownError(error);
};
