import Big from 'big.js';

const MIN_TAX = 0;
const MIN_SELL_COMMISSION = 0;

function calcMoneyShare(amount: number, sharePct: number) {
  const amountBig = new Big(amount);
  const sharePctBig = new Big(sharePct);
  const shareBig = sharePctBig.div(100);

  return amountBig.mul(shareBig).round(2, Big.roundUp).toNumber();
}

function calcTax(amount: number, taxPct: number) {
  return taxPct === 0 ? 0 : Math.max(calcMoneyShare(amount, taxPct), MIN_TAX);
}

function calcSellCommission(amount: number, commissionPct: number) {
  return Math.max(calcMoneyShare(amount, commissionPct), MIN_SELL_COMMISSION);
}

export const calculatePrice = (amount, offerPrice) => {
  const {
    quantityPerGram: basePricePerGram,
    offer: { issuerTax: issuerTaxPct, sellCommission: sellCommissionPct },
  } = offerPrice;

  const pricePerGramWithCommission =
    basePricePerGram +
    calcTax(basePricePerGram, issuerTaxPct) +
    calcSellCommission(basePricePerGram, sellCommissionPct);

  const commissionShare = (issuerTaxPct + sellCommissionPct) / 100;
  const pricePerGramWithRawCommission = basePricePerGram * (1 + commissionShare);

  const basePrice = basePricePerGram * amount;
  const basePriceRounded = new Big(basePrice).round(2, Big.roundUp).toNumber();

  const issuerTax = calcTax(basePrice, issuerTaxPct);
  const sellCommission = calcSellCommission(basePrice, sellCommissionPct);

  const priceWithCommission =
    basePrice + calcTax(basePrice, issuerTaxPct) + calcSellCommission(basePrice, sellCommissionPct);

  const priceWithCommissionRounded = new Big(priceWithCommission).round(2, Big.roundUp).toNumber();

  const missingAmountValues = !amount
    ? {
        issuerTax: 0,
        priceWithCommission: 0,
        priceWithoutCommission: 0,
        sellCommission: 0,
      }
    : {};

  return {
    issuerTaxPct,
    issuerTax,
    priceWithCommission: priceWithCommissionRounded,
    priceWithoutCommission: basePriceRounded,
    sellCommission,
    unitPriceWithCommission: pricePerGramWithCommission,
    unitPriceWithoutCommission: basePricePerGram,
    unitPriceWithRawCommission: pricePerGramWithRawCommission,
    ...missingAmountValues,
  };
};
