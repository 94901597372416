import { useSelectedPair } from 'containers/pages/primary-market/detailed/provider';
import {
  productChartOptions,
  useWeekDailyPrice,
} from 'containers/pages/primary-market/detailed/service';
import { formatMoney } from 'containers/services/commonService';
import React from 'react';

export const useProductChartModel = (onLoad) => {
  const [unit, setUnit] = React.useState('WEEKS');
  const selectedPair = useSelectedPair();
  const [weekPrice = [], isWeekPriceLoading] = useWeekDailyPrice(
    selectedPair?.coin?.id,
    selectedPair?.currency?.id,
    unit
  );
  const [chartOptions, setChartOptions] = React.useState(productChartOptions);

  React.useEffect(() => onLoad(isWeekPriceLoading), [isWeekPriceLoading]);

  const updateChartData = () => {
    let weekData = weekPrice.map((d) => {
      let fullDate = new Date(d.date);
      let utcDate = Date.UTC(fullDate.getFullYear(), fullDate.getMonth(), fullDate.getDate());

      return {
        name: utcDate,
        x: utcDate,
        y: +d.price.toFixed(2),
        value: formatMoney(d.price, {}),
      };
    });

    setChartOptions({
      ...chartOptions,
      series: [
        {
          name: selectedPair?.coin.asset,
          color: '#CECECE',
          data: weekData,
        },
      ],
    });
  };

  React.useEffect(updateChartData, [isWeekPriceLoading]);

  return { unit, setUnit, isWeekPriceLoading, chartOptions };
};
