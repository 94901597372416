import sign from 'tm2sign.macro';
import { loggerService as logger } from '@services/logger';

import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { traderPermissions } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

const tickerFields = [
  { coin: ['asset', 'id', { info: ['active'] }] },
  { currency: ['code', 'id', 'order'] },
  { lastTradeInfo: ['activeLowPrice'] }, // bestTradePrice
  'metal',
];

type MetalTradeInfo = {
  asset: string;
  bestTradePrice: Array<{
    assetCoinId: number;
    currency: string;
    currencyId: number;
    order: number;
    value: number;
  }>;
  metal: string;
};

const getMetalTradeInfosQuery = 'ticker';
const getMetalTradeInfos = makeQuery({
  permissions: { or: traderPermissions },
  queryName: getMetalTradeInfosQuery,
  queryFields: tickerFields,
  query: (): Promise<Array<MetalTradeInfo>> => {
    return stompClient
      .getData(getMetalTradeInfosQuery, sign(getMetalTradeInfosQuery, tickerFields))
      .then((tickers) => {
        try {
          const dataInfo = tickers
            .filter((t) => t.coin.info.active && currenciesFilter(t.currency))
            .filter((t) => t.coin.info.active)
            .reduce(
              (data, item) => {
                if (!data.tickers.some((ticker) => ticker.asset === item.coin.asset)) {
                  data.tickers.push({
                    asset: item.coin.asset,
                    bestTradePrice: null,
                    metal: item.metal,
                  });
                }

                data.bestTradePrice[item.coin.asset] = [
                  ...(data.bestTradePrice[item.coin.asset] || []),
                  {
                    assetCoinId: item.coin.id,
                    currency: item.currency.code,
                    currencyId: item.currency.id,
                    order: item.currency.order,
                    value: item?.lastTradeInfo?.activeLowPrice,
                  },
                ].sort(({ order: a }, { order: b }) => a - b);

                return data;
              },
              { bestTradePrice: {}, tickers: [] }
            );

          return dataInfo.tickers.map((ticker) => ({
            ...ticker,
            bestTradePrice: dataInfo.bestTradePrice[ticker.asset],
          }));
        } catch (e) {
          logger.log('Cannot parse getMetalTradeInfos response', { tickers });
          throw e;
        }
      });
  },
});

const hideCustomerSurveyFields = ['id']; // мы меняем User и получаем обновлённого User благодаря liveUpdate, получать значение не надо
const hideCustomerSurveyQuery = 'updateProfileSettings';
const hideCustomerSurvey = makeQuery({
  queryName: hideCustomerSurveyQuery,
  queryFields: hideCustomerSurveyFields,
  query: (): Promise<void> => {
    return stompClient.sendData(
      hideCustomerSurveyQuery,
      sign(hideCustomerSurveyQuery, hideCustomerSurveyFields),
      v.profileDTOInput({ userProfile: { isShowCustomerSurvey: false } })
    );
  },
});

const api = {
  getMetalTradeInfos,
  hideCustomerSurvey,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:primary-market-list');

export default api;
