import { ReduxAction } from '@models/redux';
import { OnModalOpenedPayload, VaultBalanceDetailsActionsTypes as T } from './model';

class OnCloseModalClickedAction extends ReduxAction<T.ON_CLOSE_MODAL_CLICKED, void> {
  public readonly type: T.ON_CLOSE_MODAL_CLICKED = T.ON_CLOSE_MODAL_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnModalLeftAction extends ReduxAction<T.ON_MODAL_LEFT, void> {
  public readonly type: T.ON_MODAL_LEFT = T.ON_MODAL_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnModalOpenedAction extends ReduxAction<T.ON_MODAL_OPENED, OnModalOpenedPayload> {
  public readonly type: T.ON_MODAL_OPENED = T.ON_MODAL_OPENED;
  public constructor(public payload: OnModalOpenedPayload) {
    super(payload);
  }
}

export const vaultBalanceDetailsUserActions = {
  onCloseModalClicked: () => new OnCloseModalClickedAction().get(),
  onModalLeft: () => new OnModalLeftAction().get(),
  onModalOpened: (payload: OnModalOpenedPayload) => new OnModalOpenedAction(payload).get(),
};

export type VaultBalanceDetailsUserActionsTypes =
  | OnCloseModalClickedAction
  | OnModalLeftAction
  | OnModalOpenedAction;

export declare namespace VaultBalanceDetailsUserActions {
  type OnCloseModalClicked = OnCloseModalClickedAction;
  type OnModalLeft = OnModalLeftAction;
  type OnModalOpened = OnModalOpenedAction;
}
