import { createElement, useContext, ComponentType } from 'react';
import { useMemoOne } from 'use-memo-one';
import {
  Table as LoadableTable,
  BaseItem,
  BaseTableProps as LoadableBaseTableProps,
  TableProps as LoadableTableProps,
  HeaderProps as LoadableHeaderProps,
} from '../loadable-virtual-table';
import { Context, ContextValue, Provider } from './context';
import { useTableInternal, UseTableReturnType } from './use-table';
import { TableColumnType } from './types';

export { useTable, areFieldsEqual, areOrdersEqual, sortItems } from './use-table';

export type { BaseItem, TableColumnType as ColumnType } from './types';

export type {
  ErrorElement,
  EstimateRowHeight,
  HasMore,
  KeyExtractor,
  ListKey,
  LoaderElement,
  OnLoadMore,
  RowProps,
} from '../loadable-virtual-table';

export type {
  InMemorySorter,
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterParams,
  TableSorterReturnType,
} from './use-table';

// We use table hook to provide the following:

// I. Ability to control some of the table aspects imperatively (e.g. reset).

// Reset functionality should trigger resets for every aggregated behavior.
// This leads to the conclusion that it would also be good to have functionality
// slices with imperative controls. Which means we can also use hooks for them.

// II. Be able to manage combined state of the aggregated functionalities.

// If we have sorter and row selector, the reset should trigger both their
// resets. Meanwhile, if we have imperative call on just sorter reset, it also
// should trigger row selection reset.

// The hook is also a convenient place to narrow/extend basic functionalities.

// Header component will be overridden by this module.
export type TableComponents<T extends BaseItem> = Omit<
  LoadableBaseTableProps<T>['components'],
  'header'
> & { header?: never };

export type BaseTableProps<T extends BaseItem> = Omit<
  LoadableBaseTableProps<T>,
  'columns' | 'components'
> & {
  // Forbid column group.
  columns: Array<TableColumnType<T>>;

  components?: TableComponents<T>;
};

export type HeaderProps<T extends BaseItem> = LoadableHeaderProps & {
  columns: ContextValue<T>['columns'];
  sorter: ContextValue<T>['sorter'];
};

type TablePropsBase<T extends BaseItem, K> = {
  baseTableProps: BaseTableProps<T>;
  headerComponent: ComponentType<HeaderProps<T>>;
  instance?: UseTableReturnType<T>;

  // Hack to avoid setting header height from outside.
  renderBody?: boolean;
};

export type TableProps<T extends BaseItem, K> = TablePropsBase<T, K> &
  Omit<LoadableTableProps<T, K>, keyof TablePropsBase<T, K>>;

export const Table = <T extends BaseItem, K>(props: TableProps<T, K>) => {
  const { aggregator, data } = useTableInternal({
    data: props.baseTableProps.dataSource as T[],
    instance: props.instance,
  });

  const headerWrapperComponent = useMemoOne<ComponentType<LoadableHeaderProps>>(() => {
    return (headerProps) => {
      const { columns, sorter } = useContext<ContextValue<T>>(Context);
      return createElement(props.headerComponent, {
        columns,
        sorter,
        columnWidthList: headerProps.columnWidthList,
      });
    };
  }, [props.headerComponent]);

  const baseTableProps = useMemoOne(() => {
    return {
      ...props.baseTableProps,
      dataSource: data,
    };
  }, [props.baseTableProps, data]);

  const contextValue = useMemoOne<ContextValue<T>>(
    () => ({
      columns: baseTableProps.columns,
      sorter: aggregator.sorter,
    }),
    [aggregator.sorter, baseTableProps.columns]
  );

  const renderBody = props.renderBody ?? true;

  return (
    <Provider value={contextValue}>
      <LoadableTable
        baseTableProps={baseTableProps}
        errorElement={props.errorElement}
        estimateRowHeight={props.estimateRowHeight}
        hasMore={props.hasMore}
        keyExtractor={props.keyExtractor}
        listKey={props.listKey}
        loaderElement={props.loaderElement}
        onLoadMore={props.onLoadMore}
        headerComponent={headerWrapperComponent}
        rowComponent={props.rowComponent}
        renderBody={renderBody}
      />
    </Provider>
  );
};
