import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery, Permission as P } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { SortItem, TableData, TableResponse } from '@models/core';
import { v } from '@helper/typer/field-typer.helper';
import { ColumnIds } from './store/model';

const feeReportXlsFields = ['value'];
const downloadManagementFeeReportQuery = 'managementFeeRecordReportAsXLSX';
const downloadManagementFeeReport = makeQuery({
  permissions: { or: [P.MANAGEMENT_FEE_VIEW_REPORT] },
  queryName: downloadManagementFeeReportQuery,
  queryFields: feeReportXlsFields,
  query: (): Promise<string> =>
    stompClient
      .getData(
        downloadManagementFeeReportQuery,
        sign(downloadManagementFeeReportQuery, feeReportXlsFields)
      )
      .then((response) => response.value),
});

const feeReportFields = [
  {
    data: [
      'amountOnPrimary',
      'amountOnSecondary',
      'date',
      { coin: ['asset'] },
      { user: ['id', 'displayName'] },
    ],
  },
  'hasMore',
];
const getManagementFeeReportQuery = 'managementFeeRecordsReport';
const getManagementFeeReport = makeQuery({
  permissions: { or: [P.MANAGEMENT_FEE_VIEW_REPORT] },
  queryName: getManagementFeeReportQuery,
  queryFields: feeReportFields,
  query: (
    pageNumber: number,
    sort: Array<SortItem<ColumnIds>>
  ): Promise<TableData<FeeReportItem>> => {
    const ordering = [];
    sort.forEach((col: SortItem<ColumnIds>) =>
      col.fields.forEach((field: string) =>
        ordering.push({ direction: col.direction, field: field })
      )
    );

    const typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };

    return stompClient
      .getData(
        getManagementFeeReportQuery,
        sign(getManagementFeeReportQuery, feeReportFields),
        typedValues
      )
      .then((response: TableResponse) => ({
        data: response.data.map((item) => ({
          amountPrimary: item.amountOnPrimary,
          amountSecondary: item.amountOnSecondary,
          asset: item.coin.asset,
          clientId: item.user.id,
          clientName: item.user.displayName,
          date: item.date?.formatLocalDateTime(),
        })),
        isHasMore: response.hasMore,
      }));
  },
});

const api = {
  downloadManagementFeeReport,
  getManagementFeeReport,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:statements-management-fee-report');

export default api;

export interface FeeReportResponse {
  amountPrimary: number;
  amountSecondary: number;
  asset: string;
  clientId: number;
  clientName: string;
  date: string;
}

export interface FeeReportItem {
  asset: string;
  clientId: number;
  clientName: string;
  date: string;
  amountPrimary: number;
  amountSecondary: number;
}
