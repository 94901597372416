import React, { FC, ReactNode, useCallback, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useResizeDetector } from 'react-resize-detector';

import './styles.scss';
import { GridPattern } from '@constants/theme';

/**
 * NOTE: this grid values is not the same as window width. This is the width of current Row container
 */
export const gridPattern: GridPattern = {
  xss: 200,
  xs: 330,
  sm: 460,
  md: 600,
  lg: 760,
  xl: 950,
  xxl: 1200,
};

const __pattern: Partial<GridPattern> = {};
export const Grid: FC<{
  children: ReactNode;
  className?: string;
  pattern?: Partial<GridPattern>;
  strictWidth?: boolean;
}> = ({ children, className, pattern: _pattern = __pattern, strictWidth = false }) => {
  const patternDefault: GridPattern = { xss: 1, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 };
  const [pattern] = useState<GridPattern>({
    xss: _pattern.xss || patternDefault.xss,
    xs: _pattern.xs || _pattern.xss || patternDefault.xs,
    sm: _pattern.sm || _pattern.xs || _pattern.xss || patternDefault.sm,
    md: _pattern.md || _pattern.sm || _pattern.xs || _pattern.xss || patternDefault.md,
    lg:
      _pattern.lg || _pattern.md || _pattern.sm || _pattern.xs || _pattern.xss || patternDefault.lg,
    xl:
      _pattern.xl ||
      _pattern.lg ||
      _pattern.md ||
      _pattern.sm ||
      _pattern.xs ||
      _pattern.xss ||
      patternDefault.xl,
    xxl:
      _pattern.xxl ||
      _pattern.xl ||
      _pattern.lg ||
      _pattern.md ||
      _pattern.sm ||
      _pattern.xs ||
      _pattern.xss ||
      patternDefault.xxl,
  });

  const [cols, setCols] = useState(1);

  const onResize = useCallback(
    (width) => {
      let colsNew: number;
      if (width <= gridPattern.xss) {
        colsNew = pattern.xss;
      } else if (width > gridPattern.xss && width <= gridPattern.xs) {
        colsNew = pattern.xs;
      } else if (width > gridPattern.xs && width <= gridPattern.sm) {
        colsNew = pattern.sm;
      } else if (width > gridPattern.sm && width <= gridPattern.md) {
        colsNew = pattern.md;
      } else if (width > gridPattern.md && width <= gridPattern.lg) {
        colsNew = pattern.lg;
      } else if (width > gridPattern.lg && width <= gridPattern.xl) {
        colsNew = pattern.xl;
      } else {
        colsNew = pattern.xxl;
      }
      if (colsNew !== cols) {
        setCols(colsNew);
      }
    },
    [cols, pattern]
  );

  const { ref } = useResizeDetector({ onResize });
  const gap: number = ((cols - 1) * 25) / cols;

  return useMemo(
    () => (
      <div
        className={classnames('tm2-grid', className)}
        style={{ gridTemplateColumns: `repeat(${cols}, calc(${100 / cols}% - ${gap}px))` }}
        ref={ref}
      >
        {children}
      </div>
    ),
    [children, className, cols]
  ); // eslint-disable-line
};

export const Row: FC<{
  children: ReactNode;
  className?: string;
  uat?: string;
}> = ({ children, className, uat }) => (
  <div className={classnames('tm2-row', className)} data-uat={uat}>
    {children}
  </div>
);

export const Col: FC<{
  children: ReactNode;
  className?: string;
  lg?: number;
  md?: number;
  sm?: number;
  xl?: number;
  xs?: number;
  xxl?: number;
  xxs?: number;
}> = ({
  children,
  className,
  lg: _lg,
  md: _md,
  sm: _sm,
  xl: _xl,
  xs: _xs,
  xxl: _xxl,
  xxs: _xxs,
}) => {
  const xxl = _xxl || _xl || _lg || _md || _sm || _xs || _xxs || 12;
  const xl = _xl || _lg || _md || _sm || _xs || _xxs || 12;
  const lg = _lg || _md || _sm || _xs || _xxs || 12;
  const md = _md || _sm || _xs || _xxs || 12;
  const sm = _sm || _xs || _xxs || 12;
  const xs = _xs || _xxs || 12;
  const xxs = _xxs || 12;

  const combinedClassName = classnames(
    className,
    'tm2-col',
    `tm2-xxs-${xxs}`,
    `tm2-xs-${xs}`,
    `tm2-sm-${sm}`,
    `tm2-md-${md}`,
    `tm2-lg-${lg}`,
    `tm2-xl-${xl}`,
    `tm2-xxl-${xxl}`
  );

  return useMemo(
    () => <div className={classnames(combinedClassName)}>{children}</div>,
    [children, className, _xxs, _xs, _sm, _md, _lg, _xl, _xxl]
  );
};
