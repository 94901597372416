import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import './styles.scss';

export const ModalFooter: FC<{
  children: ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <div className={classnames('tm2-modal-footer', className)}>{children}</div>;
};
