import { ReduxAction } from '@models/redux';
import { SetStableInfo, TransferStableTabActionsTypes as T } from './model';

class FinishStableTransferAction extends ReduxAction<T.FINISH_STABLE_TRANSFER, void> {
  public readonly type: T.FINISH_STABLE_TRANSFER = T.FINISH_STABLE_TRANSFER;
  public constructor(public payload: void) {
    super(payload);
  }
}

class FinishStableTransferFailAction extends ReduxAction<T.FINISH_STABLE_TRANSFER_FAIL, Error> {
  public readonly type: T.FINISH_STABLE_TRANSFER_FAIL = T.FINISH_STABLE_TRANSFER_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class FinishStableTransferSuccessAction extends ReduxAction<
  T.FINISH_STABLE_TRANSFER_SUCCESS,
  void
> {
  public readonly type: T.FINISH_STABLE_TRANSFER_SUCCESS = T.FINISH_STABLE_TRANSFER_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class SetStableInfoAction extends ReduxAction<T.SET_STABLE_INFO, SetStableInfo> {
  public readonly type: T.SET_STABLE_INFO = T.SET_STABLE_INFO;
  public constructor(public payload: SetStableInfo) {
    super(payload);
  }
}

class GetStableInfoAction extends ReduxAction<T.GET_STABLE_INFO, void> {
  public readonly type: T.GET_STABLE_INFO = T.GET_STABLE_INFO;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetStableInfoFailAction extends ReduxAction<T.GET_STABLE_INFO_FAIL, void> {
  public readonly type: T.GET_STABLE_INFO_FAIL = T.GET_STABLE_INFO_FAIL;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetStableInfoSuccessAction extends ReduxAction<T.GET_STABLE_INFO_SUCCESS, void> {
  public readonly type: T.GET_STABLE_INFO_SUCCESS = T.GET_STABLE_INFO_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class UpdateFormValuesAction extends ReduxAction<T.UPDATE_FORM_VALUES, void> {
  public readonly type: T.UPDATE_FORM_VALUES = T.UPDATE_FORM_VALUES;
  public constructor(public payload: void) {
    super(payload);
  }
}

class UpdateFormValuesFailAction extends ReduxAction<T.UPDATE_FORM_VALUES_FAIL, Error> {
  public readonly type: T.UPDATE_FORM_VALUES_FAIL = T.UPDATE_FORM_VALUES_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class UpdateFormValuesSuccessAction extends ReduxAction<T.UPDATE_FORM_VALUES_SUCCESS, void> {
  public readonly type: T.UPDATE_FORM_VALUES_SUCCESS = T.UPDATE_FORM_VALUES_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const transferStableTabServiceActions = {
  finishStableTransfer: () => new FinishStableTransferAction().get(),
  finishStableTransferFail: (error: Error) => new FinishStableTransferFailAction(error).get(),
  finishStableTransferSuccess: () => new FinishStableTransferSuccessAction().get(),
  setStableInfo: (info: SetStableInfo) => new SetStableInfoAction(info).get(),
  getStableInfo: () => new GetStableInfoAction().get(),
  getStableInfoFail: () => new GetStableInfoFailAction().get(),
  getStableInfoSuccess: () => new GetStableInfoSuccessAction().get(),
  updateFormValues: () => new UpdateFormValuesAction().get(),
  updateFormValuesFail: (error: Error) => new UpdateFormValuesFailAction(error).get(),
  updateFormValuesSuccess: () => new UpdateFormValuesSuccessAction().get(),
};

export type TransferStableTabServiceActionsTypes =
  | FinishStableTransferAction
  | FinishStableTransferFailAction
  | FinishStableTransferSuccessAction
  | SetStableInfoAction
  | GetStableInfoAction
  | GetStableInfoFailAction
  | GetStableInfoSuccessAction
  | UpdateFormValuesAction
  | UpdateFormValuesFailAction
  | UpdateFormValuesSuccessAction;
