import { useCallback, useState } from 'react';
import { createContainer } from 'react-tracked';

import { mutate } from 'containers/services/commonService';

const state = {
  preloaderLineShown: false,
};

const {
  Provider: AuthorizedProvider,
  useUpdate,
  useSelector: useAuthorizedLayoutStateSelector,
} = createContainer(() => useState(state));

const useShowPreloaderLine = () => {
  const update = useUpdate();
  return useCallback(
    (shown) => mutate(update, (draft) => (draft.preloaderLineShown = shown)),
    [update]
  );
};

export { AuthorizedProvider, useAuthorizedLayoutStateSelector, useShowPreloaderLine };
