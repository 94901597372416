import sign from 'tm2sign.macro';
import {
  TableHeaderSortType,
  TableHeaderSortType as TableSortDirection,
} from 'components/page-table';
import { v } from '@helper/typer/field-typer.helper';
import { TableResponse } from '@models/core';
import { stompClientService as stompClient } from '@services/stomp/client';

type QueryConversionCurrency = {
  id: number;
  code: string;
};

type QueryConversionStatus = 'CREATED' | 'COMPLETED';

type QueryConversionUser = {
  id: number;
};

type QueryConversion = {
  amountToBuy: number;
  amountToSell: number;
  currencyToBuy: QueryConversionCurrency;
  currencyToSell: QueryConversionCurrency;
  dateCreated: string;
  datePaid: string;
  fee: number;
  id: number;
  rate: number;
  status: QueryConversionStatus;
  supportId: string;
  user: QueryConversionUser;
};

type QueryConversionPage = TableResponse<QueryConversion>;

type Conversion = {
  amountToBuy: number;
  amountToSell: number;
  currencyToBuy: QueryConversionCurrency;
  currencyToSell: QueryConversionCurrency;
  dateCreated: string;
  datePaid: string;
  fee: number;
  id: number;
  rate: number;
  status: 'COMPLETED' | 'CREATED';
  transactionId: string;
  clientId: number;
};

type ConversionPage = TableResponse<Conversion>;

type SortBy = keyof Conversion;

type SortDirection = 'ascending' | 'descending';

const sortByMap: Record<keyof Conversion, keyof QueryConversion> = {
  amountToBuy: 'amountToBuy',
  amountToSell: 'amountToSell',
  currencyToBuy: 'currencyToBuy',
  currencyToSell: 'currencyToSell',
  dateCreated: 'dateCreated',
  datePaid: 'datePaid',
  fee: 'fee',
  id: 'id',
  rate: 'rate',
  status: 'status',
  transactionId: 'supportId',
  clientId: 'user',
} as const;

const sortDirectionMap: Record<SortDirection, TableSortDirection> = {
  ascending: TableHeaderSortType.asc,
  descending: TableHeaderSortType.desc,
};

type SortOrder = {
  field: SortBy;
  direction: SortDirection;
}[];

export const apiService = {
  getConversionPage(pageNumber: number, sortOrder: SortOrder = []): Promise<ConversionPage> {
    const query = 'exchangePage';

    const conversionFields = [
      'amountToBuy',
      'amountToSell',
      { currencyToBuy: ['id', 'code'] },
      { currencyToSell: ['id', 'code'] },
      'dateCreated',
      'datePaid',
      'fee',
      'id',
      'rate',
      'status',
      'supportId',
      { user: ['id'] },
    ];
    const conversionPageFields = [{ data: [...conversionFields] }, 'index', 'hasMore'];
    const ordering = sortOrder.map((sortItem) => ({
      direction: sortDirectionMap[sortItem.direction] ?? TableSortDirection.notSet,
      field: sortByMap[sortItem.field],
    }));
    const variables = {
      ...v.int({ page: pageNumber }),
      ...v.orderInput({ ordering }),
    };

    return stompClient
      .getData<QueryConversionPage>(query, sign(query, conversionPageFields), variables)
      .then((res) => ({
        data: res.data.map((d) => ({
          amountToBuy: d.amountToBuy,
          amountToSell: d.amountToSell,
          currencyToBuy: d.currencyToBuy,
          currencyToSell: d.currencyToSell,
          dateCreated: d.dateCreated,
          datePaid: d.datePaid,
          fee: d.fee,
          id: d.id,
          rate: d.rate,
          status: d.status,
          transactionId: d.supportId,
          clientId: d.user.id,
        })),
        hasMore: res.hasMore,
        index: res.index,
      }));
  },
};
