import React from 'react';
import { Trans } from '@lingui/macro';
import { ButtonInfo } from '@components/button';
import { MFC, Modal, ModalFooter, ModalTitle } from '@modules/modal';
import { useModel } from './model';
import { Redemption } from '../../api';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:cancel-redeem-modal',
});

export const CancelRedeemModal: MFC<{
  redeem: Redemption;
  onCancelled: () => void;
}> = ({ isVisible, redeem, onCancelled }) => {
  const m = useModel(redeem, onCancelled, CancelRedeemModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(CancelRedeemModal)}
    >
      <ModalTitle>
        <Trans id="cancel_redemption_modal.title">Cancel redemption request?</Trans>
      </ModalTitle>

      <ModalFooter>
        <ButtonInfo fullWidth={true} disabled={m.lockUI} onClick={m.onCancelClicked}>
          <Trans id="button.continue">Continue</Trans>
        </ButtonInfo>
      </ModalFooter>
    </Modal>
  );
};
