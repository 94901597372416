import { createContext, useMemo, useRef, ReactNode } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { useResizeDetector } from 'react-resize-detector';
import { themeContainerId } from '../../../bootstrap/theme';

type OnResize = Parameters<typeof useResizeDetector>[0]['onResize'];

export function useBlockHeight(callback: (height: number) => void) {
  const elementRef = useRef<HTMLElement | null>(null);

  const onResize = useCallbackOne<OnResize>(() => {
    if (!elementRef.current) return;
    callback(elementRef.current.getBoundingClientRect().height);
  }, [callback]);

  return useResizeDetector({
    handleWidth: false,
    refreshMode: 'debounce',
    refreshOptions: {
      leading: false,
    },
    refreshRate: 100,
    targetRef: elementRef,
    onResize,
  });
}

// Theme keys for layout parameters
const layoutParameter = {
  pageHeaderHeight: '--dynamic-page-header-height',
  pageTopBannerHeight: '--dynamic-page-top-banner-height',
  pageFooterHeight: '--dynamic-page-footer-height',
} as const;

type LayoutParameterMapping = typeof layoutParameter;

export type UpdateLayoutValues = (
  params: Partial<Record<keyof LayoutParameterMapping, number>>
) => void;

type LayoutContextValue = {
  updateLayoutValues: UpdateLayoutValues;
};

export const LayoutContext = createContext<LayoutContextValue>({
  updateLayoutValues: () => {},
});

export function LayoutProvider(props: { children: ReactNode }) {
  const contextValue = useMemo<LayoutContextValue>(
    () => ({
      updateLayoutValues(valueMap) {
        const themeContainer = document.getElementById(themeContainerId);
        if (!themeContainer) {
          return;
        }
        Object.entries(valueMap).forEach(([key, value]) => {
          if (value === undefined) return;

          const cssKey = layoutParameter[key];
          themeContainer.style.setProperty(cssKey, value + 'px');
        });
      },
    }),
    []
  );

  return <LayoutContext.Provider value={contextValue}>{props.children}</LayoutContext.Provider>;
}
