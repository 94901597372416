import { metalsPageStateInitial, MetalsPageActionsTypes as TransferTypes } from './model';

export const metalsPageReducer = (state = metalsPageStateInitial, action) => {
  switch (action.type) {
    case TransferTypes.DOWNLOAD_TRANSACTION: {
      return {
        ...state,
        isDownloadTransactionLoading: true,
      };
    }

    case TransferTypes.DOWNLOAD_TRANSACTION_FAIL: {
      return {
        ...state,
        isDownloadTransactionLoading: false,
      };
    }

    case TransferTypes.DOWNLOAD_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isDownloadTransactionLoading: false,
      };
    }

    case TransferTypes.LOAD_MORE_METALS: {
      return {
        ...state,
        isMetalsLoading: true,
      };
    }

    case TransferTypes.LOAD_MORE_METALS_FAIL: {
      return {
        ...state,
        isHasMore: false,
        isMetalsLoading: false,
      };
    }

    case TransferTypes.LOAD_MORE_METALS_SUCCESS: {
      const { isHasMore, isSort, metals } = action.payload;
      return {
        ...state,
        isHasMore: isHasMore,
        isMetalsLoading: false,
        metals: isSort ? metals : [...state.metals, ...metals],
      };
    }

    case TransferTypes.ON_PAGE_LEFT: {
      return {
        ...metalsPageStateInitial,
      };
    }

    case TransferTypes.ON_TABLE_SCROLLED: {
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
      };
    }

    case TransferTypes.ON_TABLE_SORTED: {
      const column = action.payload;
      const sort = [...state.sort];
      const activeColumn = sort.splice(
        sort.findIndex((col) => col.id === column.id),
        1
      )[0];
      activeColumn.direction = column.direction;

      return {
        ...state,
        pageIndex: 0,
        sort: [activeColumn, ...sort],
      };
    }

    case TransferTypes.UPLOAD_TRANSACTION: {
      return {
        ...state,
        isUploadTransactionLoading: true,
      };
    }

    case TransferTypes.UPLOAD_TRANSACTION_FAIL: {
      return {
        ...state,
        isUploadTransactionLoading: false,
      };
    }

    case TransferTypes.UPLOAD_TRANSACTION_SUCCESS: {
      return {
        ...state,
        isUploadTransactionLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
