import { useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { BaseItem, BaseTableProps, DefaultRowCellProps } from '../bound-cells';

export type ResizerBaseTableProps<
  T extends BaseItem,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = Omit<BaseTableProps<T, H, R>, 'scroll'> & {
  // scroll.y and scroll.x will be set by AutosizedTable.
  scroll?: Omit<BaseTableProps<T>['scroll'], 'x' | 'y'> & {
    x?: never;
    y?: never;
  };
};

type TableResizeObserverProps<
  T extends BaseItem,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = {
  className?: string;
  children: (params: {
    baseTableProps: BaseTableProps<T, H, R>;
    height: number;
    width: number;
  }) => JSX.Element;
  baseTableProps: ResizerBaseTableProps<T, H, R>;
  totalYOffset: number;
};

const REFRESH_RATE = 300;

export function TableResizeObserver<
  T extends BaseItem,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
>({ children, className, baseTableProps, totalYOffset }: TableResizeObserverProps<T, H, R>) {
  const {
    ref: containerRef,
    height: containerHeight,
    width: containerWidth,
  } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: REFRESH_RATE,
  });

  const overrideTableProps = useMemo<BaseTableProps<T, H, R>>(() => {
    return {
      ...baseTableProps,
      scroll: {
        ...baseTableProps.scroll,
        y: containerHeight - totalYOffset,
        x: containerWidth,
      },
    };
  }, [containerHeight, containerWidth, baseTableProps, totalYOffset]);

  return (
    <div ref={containerRef} className={className} style={{ position: 'relative' }}>
      {children({
        baseTableProps: overrideTableProps,
        height: containerHeight,
        width: containerWidth,
      })}
    </div>
  );
}
