import { traderPermissions, Role } from '@permissions/core';
import { dater } from '@helper/date';
import { useGetTariffName } from '../../../service';
import { Profile } from '../../../api.shared';

const componentPermissions = { or: traderPermissions };
const componentRoles = [Role.issuer, Role.liquidityProvider];

export const useModel = (profile: Profile) => {
  const getTariffName = useGetTariffName();
  const tariff = profile?.userTariff;
  const expireDate = !tariff?.endDate ? null : dater.toLocalDate(tariff.endDate);
  const tariffName = getTariffName(tariff?.tariff);

  return {
    componentPermissions,
    componentRoles,
    expireDate,
    tariffName,
  };
};
