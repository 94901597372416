import React, { FC } from 'react';
import { Form as AntForm, Input } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import { InputProps } from 'antd/lib/input/Input';

const { Item } = AntForm;

export const InputHidden: FC<{
  fieldKey?: React.Key | React.Key[];
  name: NamePath;
  type?: InputProps['type'];
}> = ({ name, fieldKey, type = 'string' }) => (
  <Item name={name} fieldKey={fieldKey} hidden={true}>
    <Input disabled={true} type={type} />
  </Item>
);
