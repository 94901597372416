import moment from 'moment';
import { useCallback, useMemo } from 'react';

import { DATE_FORMAT_GQL } from '@constants/date';
import { Filter } from './types';

const defaultPeriod: [string, string] = [
  moment().subtract(1, 'years').format(DATE_FORMAT_GQL),
  moment().format(DATE_FORMAT_GQL),
];

export const useDefaultFilters = () => {
  const filterFromHash: Filter = useMemo(() => {
    try {
      const params = new URLSearchParams(window.location.hash.slice(2));
      return JSON.parse(params.get('search-form'));
    } catch (e) {
      return null;
    }
  }, []);

  const getDefaultFilter = useCallback((bankId?: string): Filter => {
    return {
      bankAccountId: bankId || filterFromHash?.bankAccountId,
      period: defaultPeriod,
    };
  }, []);

  const isFilterCorrect = !!filterFromHash?.bankAccountId;

  return {
    isFilterCorrect,
    getDefaultFilter,
  };
};
