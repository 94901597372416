import { useEffect, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { Trans } from '@lingui/macro';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { DTable, DColumnType, DBaseTableProps, OnLoadMore } from '@components/table/autosized';
import { useSyncRef } from '@hooks/core';
import { PermissionGroup } from '@permissions/core';
import { configSelectors } from '@config/core';
import { dater } from '@helper/date';
import { profileOtherPagePermissionsGroup } from '../../../profile/other/permissions';
import { useBidOrderStatusTrans } from '../trans';
import { BidOrderByAll, BidOrderByCreator, BidOrderStatus, DataList, TableData } from '../types';
import { HeaderCellContainer, RowCellContainer } from '../table-components';
import { StatusActions, permissionGroup as statusActionsPermissionGroup } from './status-actions';
import s from '../../index.module.scss';
import { ProfileLinkCell } from '@components/table2/utils';

// Make status actions optional.
const actionColumnPermissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [statusActionsPermissionGroup],
  marker: 'layout:action-column',
  optional: true,
});

export const userLinkPermissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:bid-orders-table-seller-id-link',
});

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [actionColumnPermissionGroup, userLinkPermissionGroup],
  marker: 'layout:bid-order-table',
});

export type BidOrder = BidOrderByAll | BidOrderByCreator;
export type BidOrderList = DataList<BidOrderByAll | BidOrderByCreator>;

type GetBidOrders = (params: {
  assetId?: number;
  pageNumber: number;
  status?: BidOrderStatus;
}) => Promise<TableData<BidOrder>>;

export type BidOrdersProps = {
  assetId?: number;
  getBidOrders: GetBidOrders;
  showBuyer?: boolean;
  showStatusActions?: boolean;
  status?: BidOrderStatus;
};

type Index = {
  assetId?: number;
  status?: string;
  nextPage: number;
};

type OnBidOrderUpdate = (params: { bidOrderId: number; status: BidOrderStatus }) => void;

const ROW_HEIGHT = 41;
const INITIAL_PAGE = 0;

function getListKey(params: { assetId: number; status: BidOrderStatus }) {
  return `${params.assetId}_${params.status}`;
}

function getColumns(params: {
  onBidOrderUpdate: OnBidOrderUpdate;
  showBuyer: boolean;
  showStatusActions: boolean;
}): DColumnType<BidOrder>[] {
  const columns: (DColumnType<BidOrder> | false)[] = [
    params.showStatusActions && {
      dataIndex: null,
      width: 60,
      headerCellComponent: () => null,
      rowCellComponent: (props) => (
        <StatusActions
          className={s.actionsTrigger}
          bidOrderId={props.data.id}
          bidOrderStatus={props.data.status}
          onBidOrderDenied={params.onBidOrderUpdate}
          onBidOrderApproved={params.onBidOrderUpdate}
        />
      ),
    },
    {
      dataIndex: 'id',
      width: 70,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.position'}>№</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{props.data.id}</span>,
    },
    {
      dataIndex: 'status',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.status'}>Status</Trans>
        </span>
      ),
      rowCellComponent: (props) => {
        const trans = useBidOrderStatusTrans();
        return <span>{trans[props.data.status]}</span>;
      },
    },
    {
      dataIndex: 'date',
      width: 200,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.date-created'}>Date created</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{dater.toLocalDateTime(props.data.date)}</span>,
    },
    {
      dataIndex: 'creatorName',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.seller-name'}>Seller</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <ProfileLinkCell text={props.data.creatorName} id={props.data.creatorId} />
      ),
    },
    {
      dataIndex: 'creatorId',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.seller-id'}>Seller ID</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{props.data.creatorId}</span>,
    },
    params.showBuyer && {
      dataIndex: 'buyerName',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.buyer-name'}>Buyer</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <span>{'buyerName' in props.data ? props.data.buyerName : ''}</span>
      ),
    },
    params.showBuyer && {
      dataIndex: 'buyerId',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.buyer-id'}>Buyer ID</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{'buyerId' in props.data ? props.data.buyerId : ''}</span>,
    },
    {
      dataIndex: 'asset',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.symbol'}>Symbol</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{props.data.asset}</span>,
    },
    {
      dataIndex: 'quantity',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.amount'}>Amount</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <span>
          <span>{formatToken(props.data.quantity)}</span> <span>{props.data.priceUnitLabel}</span>
        </span>
      ),
    },
    {
      dataIndex: null,
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.total'}>Total</Trans>
        </span>
      ),
      rowCellComponent: (props) => {
        const { quantity, priceCurrency, pricePerGram, priceUnitInGrams } = props.data;
        const total = quantity * priceUnitInGrams * pricePerGram;
        return <span>{formatMoney(total, { pre: priceCurrency })}</span>;
      },
    },
    {
      dataIndex: 'comment',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.bid-orders-table.comment'}>Comment</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{props.data.comment}</span>,
    },
  ];
  return columns.filter(Boolean) as DColumnType<BidOrder>[];
}

export const BidOrders: FC<BidOrdersProps> = (props) => {
  const userPermissions = useSelector(configSelectors.permissions);
  const [dataList, setDataList] = useState<DataList<BidOrder>>({
    hasMore: true,
    key: '',
    list: [],
  });
  const listKeyRef = useSyncRef(dataList.key);

  const updateBidOrder = useCallbackOne(
    (params: { bidOrderId: number; status: BidOrderStatus }) => {
      setDataList((dataList) => {
        const modifiedList = dataList.list.map((item) =>
          item.id === params.bidOrderId ? { ...item, status: params.status } : item
        );
        return { ...dataList, list: modifiedList };
      });
    },
    []
  );

  const baseTableProps = useMemoOne<DBaseTableProps<BidOrder>>(
    () => ({
      dataSource: dataList.list,
      columns: getColumns({
        onBidOrderUpdate: updateBidOrder,
        showBuyer: props.showBuyer,
        showStatusActions:
          props.showStatusActions && statusActionsPermissionGroup.resolve(userPermissions),
      }),
    }),
    [props.showBuyer, props.showStatusActions, updateBidOrder, userPermissions, dataList.list]
  );

  // TODO: estimateRowHeight
  // Use return value as a default for row height.
  // Use 'dynamic' (false by default) field in config to define if the value
  // can be updated by the content.
  // Update callback, if you need to update based on e.g. screen size.
  // If we set padding for static variations (either on row, or on cell),
  // the layout will break if font size changes. To avoid this we should only
  // set padding in case it's dynamic height. Simple way to implement this
  // behavior would be to set style of {paddingTop: 'none', paddingBottom: 'none}
  // on cell for static height variation. Do not use padding on row.
  const estimateRowHeight = useCallbackOne((data) => {
    return ROW_HEIGHT;
  }, []);

  const keyExtractor = useCallbackOne((bidOrder: BidOrder) => {
    return bidOrder.id;
  }, []);

  const fetchData = useCallbackOne<OnLoadMore<BidOrder, Index>>(
    ({ lastIndexData }) => {
      const page = lastIndexData?.nextPage ?? INITIAL_PAGE;
      const queryKey = listKeyRef.current;

      return props
        .getBidOrders({
          pageNumber: page,
          assetId: props.assetId,
          status: props.status,
        })
        .then(
          ({ data: incomingData, hasMore }): Awaited<ReturnType<OnLoadMore<BidOrder, Index>>> => {
            if (queryKey !== listKeyRef.current) {
              return { indexData: null };
            }
            setDataList((currentData) => ({
              hasMore,
              key: currentData.key,
              list: [...currentData.list, ...incomingData],
            }));
            return { indexData: { nextPage: page + 1 } };
          }
        );
    },
    [props.assetId, props.status]
  );

  useEffect(() => {
    const nextKey = getListKey({
      assetId: props.assetId,
      status: props.status,
    });
    setDataList({
      hasMore: true,
      key: nextKey,
      list: [],
    });
  }, [props.assetId, props.status]);

  return (
    <DTable
      baseTableProps={baseTableProps}
      className={s.verticalFiller}
      estimateRowHeight={estimateRowHeight}
      keyExtractor={keyExtractor}
      hasMore={dataList.hasMore}
      listKey={dataList.key}
      onLoadMore={fetchData}
      headerCellWrapperComponent={HeaderCellContainer}
      rowCellWrapperComponent={RowCellContainer}
    />
  );
};
