import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Box } from '@components/box';
import { Form, Select } from '@components/form';
import { useBidOrdersActionsModel } from './model';
import classnames from 'classnames';

export const BidOrdersActions: FC<{
  className?: string;
  onFilterChanged: (f) => void;
}> = ({ className, onFilterChanged }) => {
  const { statuses, coins } = useBidOrdersActionsModel();

  return (
    <Box className={classnames(className, 'mb1')}>
      <Form className={s.row} name="bid-orders-filters" onChange={onFilterChanged}>
        <Select
          className={s.item}
          label={<Trans id="bid_orders_filter.status">Status</Trans>}
          name="status"
          compact={true}
          allowEmpty={true}
          data={statuses}
        />

        <Select
          className={s.item}
          label={<Trans id="bid_orders_filter.symbol">Symbol</Trans>}
          name="coinId"
          compact={true}
          allowEmpty={true}
          data={coins}
        />
      </Form>
    </Box>
  );
};
