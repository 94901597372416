import { useParams } from 'react-router';
import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { OnFormAction } from '@components/form';
import { handleBackendError, notifySuccess } from '@modules/notify';

import { useInitialValues, useRoles, useSystemEvents } from './hooks';
import api, { Form } from './api';

export { Fields } from './api';

export const formId = 'marketingFormId';

export const useModel = () => {
  const [isSaveLoading, setSaveLoading] = useState<boolean>(false);

  const p = useParams<{ companyId?: string }>();

  const companyId: number = +p.companyId;

  const { i18n } = useI18n();
  const { roles, isRolesLoading } = useRoles();
  const { systemEvents, isSystemEventsLoading } = useSystemEvents();
  const { initialValues, isInitialValuesLoading } = useInitialValues(companyId);

  const numberOfUsersLeft = initialValues?.numberOfUsersLeft;

  const lockUI: boolean =
    isRolesLoading || isSystemEventsLoading || isInitialValuesLoading || isSaveLoading;

  const onFormAction: OnFormAction<Form> = {
    error: (error) => {
      setSaveLoading(false);
      handleBackendError(error);
    },
    submit: async (form) => {
      setSaveLoading(true);
      await api.saveMarketingCompany({ ...form, id: companyId });
      setSaveLoading(false);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="trading_platform.marketing.notification_success.text">
            Settings saved successfully
          </Trans>
        ),
      });
    },
  };

  return {
    i18n,
    initialValues,
    lockUI,
    numberOfUsersLeft,
    onFormAction,
    roles,
    systemEvents,
  };
};
