import React, { FC } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { PageTable } from 'components/page-table';
import { formatToken } from 'containers/services/commonService';
import { AccountType } from '@config/core';
import { DispatchFn } from '@models/redux';
import { accountService as account } from '@services/account';
import { MoreActions } from '@components/more-actions';
import { Role as R } from '@permissions/core';
import { beneficialOwnersSelectors as beneficialSelectors } from '../store/selectors';
import { beneficialOwnersUserActions as beneficialUserActions } from '../store/user.acions';
import { BeneficialOwner } from '../beneficial-api.service';

const mapStateToProps = (root) => ({
  beneficialOwners: beneficialSelectors.beneficialOwnersTable(root),
  isHasMore: beneficialSelectors.isHasMore(root),
  isBeneficialOwnersLoading: beneficialSelectors.isBeneficialOwnersLoading(root),
  isMetalsReverse: beneficialSelectors.isDigitalMetalsReverse(root),
  lockUI: beneficialSelectors.beneficialOwnersPageLocked(root),
});

const mapDispatchToProps = (dispatch) => ({
  onDigitalMetalSortClicked: () => dispatch(beneficialUserActions.onDigitalMetalSortClicked()),
  onTableScrolled: () => dispatch(beneficialUserActions.onBeneficialOwnersTableScrolled()),
  onToggleBlockOwnerClicked: (owner: BeneficialOwner) =>
    dispatch(beneficialUserActions.onToggleBlockOwnerButtonClicked(owner)),
});

const ConnectedBeneficialOwnersTable: FC<{
  beneficialOwners: Array<BeneficialOwner>;
  isBeneficialOwnersLoading: boolean;
  isHasMore: boolean;
  isMetalsReverse: boolean;
  lockUI: boolean;
  onDigitalMetalSortClicked: DispatchFn<void>;
  onTableScrolled: DispatchFn<void>;
  onToggleBlockOwnerClicked: DispatchFn<BeneficialOwner>;
}> = ({
  beneficialOwners,
  isBeneficialOwnersLoading,
  isHasMore,
  isMetalsReverse,
  lockUI,
  onDigitalMetalSortClicked,
  onTableScrolled,
  onToggleBlockOwnerClicked,
}) => {
  return (
    <PageTable
      onLoadMore={() => onTableScrolled()} // TODO standardize page counter (should begin from 0 everywhere)
      isLoadingInProgress={isBeneficialOwnersLoading}
      isShowLoader={true}
      initialLoad={true}
      pageData={{
        data: beneficialOwners,
        hasMore: isHasMore,
      }}
      columns={[
        {
          cell: (cell: BeneficialOwner) => (
            <MoreActions
              placement="right"
              list={[
                {
                  access: { roles: [R.complianceOfficer, R.issuer] },
                  action: () => onToggleBlockOwnerClicked(cell),
                  content: <Trans id="beneficial_owners.block_unblock">Block/Unblock</Trans>,
                },
              ]}
            />
          ),
          className: 'td-controls',
          head: () => null,
        },
        {
          cell: (cell: BeneficialOwner) => <Link to={`/users/${cell.id}`}>{cell.displayName}</Link>,
          head: () => <Trans id="beneficial_owners.table.client">Client</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) => cell.id,
          className: 'align-right',
          head: () => <Trans id="beneficial_owners.table.client_id">Client ID</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) => {
            const accountType = account.getType(cell.permissions);
            if (accountType === AccountType.individual) {
              return <Trans id="role.individual_trader">Individual trader</Trans>;
            }
            if (accountType === AccountType.company) {
              return <Trans id="role.institutional_member">Institutional member</Trans>;
            }
            return <span>&nbsp;</span>;
          },
          head: () => <Trans id="beneficial_owners.table.account_type">Account type</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) =>
            cell.metals.map((item) => <p key={item.asset}>{formatToken(item.balance)}</p>),
          className: 'align-right',
          head: () => <Trans id="beneficial_owners.table.quantity">Quantity</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) =>
            cell.metals.map((item) => (
              <p key={item.asset}>
                {item.asset}
                {item.blocked && (
                  <span className={'color-red'}>
                    &nbsp;(<Trans id="beneficial_owners.blocked">BLOCKED</Trans>)
                  </span>
                )}
              </p>
            )),
          head: () => (
            <div
              style={{ pointerEvents: lockUI ? 'none' : 'all' }}
              onClick={() => onDigitalMetalSortClicked()}
              className={classnames('table-header-sorter', {
                'table-header-sorter--asc': isMetalsReverse,
                'table-header-sorter--desc': !isMetalsReverse,
              })}
            >
              <Trans id="beneficial_owners.table.symbol">Symbol</Trans>
            </div>
          ),
        },
        {
          cell: (cell: BeneficialOwner) => <span>{cell.email}</span>,
          head: () => <Trans id="beneficial_owners.table.e_mail">E-mail</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) => <span>{cell.phone}</span>,
          head: () => <Trans id="beneficial_owners.table.phone">Phone</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) => <span>{cell.country?.label}</span>,
          head: () => <Trans id="beneficial_owners.table.country">Country</Trans>,
        },
        {
          cell: (cell: BeneficialOwner) => <span>{cell.address}</span>,
          head: () => <Trans id="beneficial_owners.table.address">Address</Trans>,
        },
      ]}
    />
  );
};

export const BeneficialOwnersTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBeneficialOwnersTable);
