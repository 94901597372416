import {
  RedeemOptionsModalActionsTypes as RedeemOptionsTypes,
  redeemOptionsModalStateInitial,
} from './model';

export const redeemOptionsModalReducer = (combinedState, action) => {
  const state = combinedState.redeemOptionsModal;
  const newState = (localState) => ({
    ...combinedState,
    redeemOptionsModal: { ...localState },
  });

  switch (action.type) {
    case RedeemOptionsTypes.GET_REDEEM_OPTIONS: {
      return newState({
        ...state,
        isRedeemOptionsLoading: true,
      });
    }

    case RedeemOptionsTypes.GET_REDEEM_OPTIONS_FAIL: {
      return newState({
        ...state,
        isRedeemOptionsLoading: false,
      });
    }

    case RedeemOptionsTypes.GET_REDEEM_OPTIONS_SUCCESS: {
      return newState({
        ...state,
        isRedeemOptionsLoading: false,
        redeemOptions: action.payload,
      });
    }

    case RedeemOptionsTypes.ON_CHOOSE_SELECTED_REDEEM_OPTION_CLICKED: {
      return newState({
        ...state,
        storedData: {
          metalPrice: state.metalPrice,
          redeemOption: state.selectedRedeemOption,
        },
      });
    }

    case RedeemOptionsTypes.ON_MODAL_LEFT: {
      return newState({
        ...redeemOptionsModalStateInitial,
        storedData: state.storedData,
      });
    }

    case RedeemOptionsTypes.ON_MODAL_OPENED: {
      return newState({
        ...redeemOptionsModalStateInitial,
      });
    }

    case RedeemOptionsTypes.ON_REDEEM_OPTION_SELECTED: {
      const redeemOption = action.payload;
      return newState({
        ...state,
        metalPrice:
          redeemOption.unit.label === 'g'
            ? redeemOption.amount
            : redeemOption.unit.inGrams * redeemOption.amount,
        selectedRedeemOption: redeemOption,
      });
    }

    default: {
      return combinedState;
    }
  }
};
