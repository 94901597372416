import React, { FC, ReactNode } from 'react';
import { Form as AntForm, Checkbox as AntCheckbox } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';
import classnames from 'classnames';

import s from './index.module.scss';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const CheckboxSingle: FC<{
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  name: NamePath;
  required?: boolean;
  uat?: string;
}> = ({ className, compact, disabled, fieldKey, label, name, required, uat }) => {
  const uatAttribute = useFieldUat(name, 'checkbox-single', uat);

  return (
    <Item
      className={classnames(compact && s.compact, className)}
      name={name}
      fieldKey={fieldKey}
      required={required}
      data-uat={uatAttribute}
    >
      <CustomCheckbox name={name} label={label} required={required} disabled={disabled} />
    </Item>
  );
};

const CustomCheckbox: FC<{
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (v: boolean) => void;
  required: boolean;
  value?: boolean;
}> = (p) => {
  return (
    <AntCheckbox
      className={s.checkbox}
      checked={p.value}
      disabled={p.disabled}
      onChange={(e) => p.onChange(e.target.checked)}
    >
      <span className={s.label}>
        {p.label}
        {p.required && <span className={s.asterisk}>*</span>}
      </span>
    </AntCheckbox>
  );
};
