import { useSelector } from 'react-redux';

import { configSelectors } from '@config/store/selectors';
import { useCallDispatch } from '@hooks/core';
import { configUserActions } from '@config/store/user.acions';

export const useModel = () => {
  const fullName = useSelector(configSelectors.userDisplayName);
  const onLogoutClicked = useCallDispatch(configUserActions.onLogoutClicked);
  const userInfo = useSelector(configSelectors.userInfo);
  const firstName = userInfo?.firstName;
  const shortName = firstName || fullName;

  return { fullName, onLogoutClicked, shortName };
};
