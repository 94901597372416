import { ReduxAction } from '@models/redux';
import { AddSMTAActionsTypes as T } from './model';

class AddSMTAccountAction extends ReduxAction<T.ADD_SMT_ACCOUNT, void> {
  public readonly type: T.ADD_SMT_ACCOUNT = T.ADD_SMT_ACCOUNT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class AddSMTAccountFailAction extends ReduxAction<T.ADD_SMT_ACCOUNT_FAIL, Error> {
  public readonly type: T.ADD_SMT_ACCOUNT_FAIL = T.ADD_SMT_ACCOUNT_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class AddSMTAccountSuccessAction extends ReduxAction<T.ADD_SMT_ACCOUNT_SUCCESS, void> {
  public readonly type: T.ADD_SMT_ACCOUNT_SUCCESS = T.ADD_SMT_ACCOUNT_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const addSMTAServiceActions = {
  addSMTAccount: () => new AddSMTAccountAction().get(),
  addSMTAccountFail: (error: Error) => new AddSMTAccountFailAction(error).get(),
  addSMTAccountSuccess: () => new AddSMTAccountSuccessAction().get(),
};

export type AddSMTAServiceActionsTypes =
  | AddSMTAccountAction
  | AddSMTAccountFailAction
  | AddSMTAccountSuccessAction;

export declare namespace AddSMTAServiceActions {
  type addSMTAccount = AddSMTAccountAction;
  type addSMTAccountFail = AddSMTAccountFailAction;
  type addSMTAccountSuccess = AddSMTAccountSuccessAction;
}
