import { useCurrentRole } from '@config/hooks';
import { useUsersForDisplayByRole } from 'containers/services/common/service';
import { useI18n } from 'containers/services/commonService';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { configSelectors } from '@config/core';
import { Role } from '@permissions/core';
import api from './api';

export const useDailyBalanceHistoryModel = () => {
  const { i18n } = useI18n();
  const currentRole = useCurrentRole();
  const currentDate = new Date();
  const previousWeekDate = new Date().setDate(new Date().getDate() - 7);
  const issuers = useUsersForDisplayByRole(Role.issuer);
  const isWsConnected = useSelector(configSelectors.isWsConnected);

  const [balances, setBalances] = useState([]);
  const onUpdateTable = async (formData) => {
    if (isWsConnected) {
      const startDate = !!formData.date ? formData.date[0] : previousWeekDate;
      const endDate = !!formData.date ? formData.date[1] : currentDate;
      setBalances(await api.getDailyBalanceHistory(formData.issuerId, startDate, endDate));
    }
  };

  useEffect(() => {
    onUpdateTable({ issuerId: undefined, date: [previousWeekDate, currentDate] });
  }, []);

  const currencies = useMemo(
    () => [...new Set(balances.flatMap((b) => b.balances).map((b) => b.code))],
    [balances]
  );

  const balancesToShow = useMemo(
    () => balances.map((balance) => ({ ...balance, date: balance.date.formatLocalDate() })),
    [balances]
  );

  return { i18n, currencies, currentRole, balances: balancesToShow, issuers, onUpdateTable };
};
