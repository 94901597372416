import {
  LoadMarketingCompaniesSuccessPayload,
  MarketingCompaniesActions,
  MarketingTableActionTypes,
  MarketingTableColumnIds,
  marketingTableInitialState,
  MarketingTableState,
} from './model';
import { SortItem } from '@models/core';

export const marketingCompaniesReducer = (
  state: MarketingTableState = marketingTableInitialState,
  action: MarketingCompaniesActions
): MarketingTableState => {
  switch (action.type) {
    case MarketingTableActionTypes.LOAD_MORE_MARKETING_COMPANIES: {
      return {
        ...state,
        isMarketingCompaniesLoading: true,
      };
    }

    case MarketingTableActionTypes.LOAD_MORE_MARKETING_COMPANIES_FAIL: {
      return {
        ...state,
        isHasMore: false,
        isMarketingCompaniesLoading: false,
      };
    }

    case MarketingTableActionTypes.LOAD_MORE_MARKETING_COMPANIES_SUCCESS: {
      const { isHasMore, isSort, data }: LoadMarketingCompaniesSuccessPayload = action.payload;

      return {
        ...state,
        isHasMore: isHasMore,
        isMarketingCompaniesLoading: false,
        marketingCompanies: isSort ? data : [...state.marketingCompanies, ...data],
      };
    }

    case MarketingTableActionTypes.ON_TABLE_SCROLLED: {
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
      };
    }

    case MarketingTableActionTypes.ON_TABLE_SORTED: {
      const column: SortItem<MarketingTableColumnIds> = action.payload;
      const sort: Array<SortItem<MarketingTableColumnIds>> = [...state.sort];
      const activeColumn: SortItem<MarketingTableColumnIds> = sort.splice(
        sort.findIndex((col) => col.id === column.id),
        1
      )[0];
      activeColumn.direction = column.direction;

      return {
        ...state,
        pageIndex: 0,
        sort: [activeColumn, ...sort],
      };
    }

    case MarketingTableActionTypes.ON_PAGE_LEFT: {
      return {
        ...marketingTableInitialState,
      };
    }

    default: {
      return state;
    }
  }
};
