import sign from 'tm2sign.macro';

import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { fileService } from '@helper/file';
//-----------------------------------------------------------------
export const verifyDocsFormId = 'verificationDocsUpload';
export enum VerifyDocsFields {
  identityFile = 'file', // identity item
  identities = 'identifierDocument', // list
  utilityFile = 'file', // utility item
  utilities = 'utilityBill', // list
}
const F = VerifyDocsFields; // alias
export type VerifyDocsForm = {
  [F.identities]: Array<{ [F.identityFile]: File }>;
  [F.utilities]: Array<{ [F.utilityFile]: File }>;
};
const sendVerificationDocsQuery = 'uploadVerificationDocuments';
const sendVerificationDocsFields = ['value'];
const sendVerificationDocs = makeQuery({
  permissions: { and: [Permission.INDIVIDUAL_USER] },
  queryName: sendVerificationDocsQuery,
  queryFields: sendVerificationDocsFields,
  query: async (form: VerifyDocsForm): Promise<void> => {
    const typedValues = {
      ...v.verificationDocumentUploadDTOInput({
        [F.identities]: await Promise.all(
          form[F.identities]
            .map((subForm) => subForm[F.identityFile])
            .map(async (file) =>
              !file
                ? undefined
                : {
                    fileAsBase64: await fileService.toBase64(file),
                    name: file.name,
                  }
            )
        ),
      }),
      ...v.verificationDocumentUploadDTOInput({
        [F.utilities]: await Promise.all(
          form[F.utilities]
            .map((subForm) => subForm[F.utilityFile])
            .map(async (file) =>
              !file
                ? undefined
                : {
                    fileAsBase64: await fileService.toBase64(file),
                    name: file.name,
                  }
            )
        ),
      }),
    };
    return stompClientService.sendData(
      sendVerificationDocsQuery,
      sign(sendVerificationDocsQuery, sendVerificationDocsFields),
      typedValues
    );
  },
});
//-----------------------------------------------------------------
const api = {
  sendVerificationDocs,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:verification-documents');

export default api;
