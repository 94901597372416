export type SortDirection = 'ascending' | 'descending';

export type SortItem<T> = {
  field: keyof T;
  direction: SortDirection | undefined;
};

export type SortOrder<T> = SortItem<T>[];

export function addSortItem<T>(order: SortOrder<T>, item: SortItem<T>): SortOrder<T> {
  const isFieldIncluded = order.some((orderItem) => orderItem.field === item.field);

  if (isFieldIncluded && item.direction === undefined) {
    return order.filter((orderItem) => orderItem.field !== item.field);
  }

  if (isFieldIncluded && item.direction !== undefined) {
    return [item, ...order.filter((orderItem) => orderItem.field !== item.field)];
  }
  return [item, ...order];
}

export function sortItems<T>(sortItems: T[], order: SortOrder<T>): T[] {
  return [...sortItems].sort((a, b) => {
    let isABeforeB = false;

    for (let orderItem of order) {
      const { direction, field } = orderItem;
      const aField = a[field];
      const bField = b[field];
      let isABeforeBLocal = false;

      if (typeof aField === 'number' && typeof bField === 'number') {
        if (direction === 'ascending') {
          isABeforeBLocal = aField < bField;
        }
        if (direction === 'descending') {
          isABeforeBLocal = aField > bField;
        }
      }
      if (typeof aField === 'string' && typeof bField === 'string') {
        if (direction === 'ascending') {
          isABeforeBLocal = aField.localeCompare(bField) < 0;
        }
        if (direction === 'descending') {
          isABeforeBLocal = aField.localeCompare(bField) > 0;
        }
      }
      if (aField !== bField) {
        isABeforeB = isABeforeBLocal;
        break;
      }
    }
    return isABeforeB ? -1 : 1;
  });
}
