import React from 'react';

import { useProductDropdownModel } from './model';

export const ProductDropdown = ({ button, children }) => {
  const { setIsShown, isShown } = useProductDropdownModel();

  return (
    <div className="tm2-new-dropdown" onClick={() => setIsShown(true)}>
      {button}
      {isShown && children}
    </div>
  );
};
