import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { useDispatch } from 'react-redux';

import { notifySuccess } from '@modules/notify';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { formatToken } from 'containers/services/commonService';
import { sendMetalsToInvestorApiService } from './api.service';

export const formId = 'send-metals-to-investor';
export enum Fields {
  amount = 'amount',
}
export const formInitial = { [Fields.amount]: null };
interface Form {
  [Fields.amount]: number;
}

export const useModel = (modal, asset) => {
  const [lockUI, setLockUI] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModalAction(modal));

  const onSendMetalsToInvestorFormAction: OnFormAction<Form> = {
    finally: () => setLockUI(false),
    submit: async (form: Form) => {
      setLockUI(true);
      const amount = form?.amount;
      await sendMetalsToInvestorApiService.sendToInvestorAccount({ amount, asset });
      onClose();
      notifySuccess({
        title: (
          <Trans id={'coins_table.the_metals_were_sent'}>The metals were successfully sent</Trans>
        ),
        text: (
          <>
            <Trans id="coins_table.you_sent_amount_of_asset">
              You sent {formatToken(amount)} {asset} to your investor account.
            </Trans>
            &nbsp;
            <Trans id="coins_table.you_can_see_the_metal_in_wallet">
              You can see the metal in the Wallet section of your linked investor account, where you
              will have the option to transfer to the secondary market for trading.
            </Trans>
          </>
        ),
      });
    },
  };

  return { lockUI, onClose, onSendMetalsToInvestorFormAction };
};
