import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Moment } from 'moment';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';

interface LabelProps {
  htmlFor: NamePath;
  label: ReactNode;
  required: boolean;
  shifted: boolean;
}

const LabelRaw: FC<
  LabelProps & {
    labelClass: string;
    labelShiftedClass: string;
  }
> = ({ htmlFor, label, labelClass, labelShiftedClass, required, shifted }) => (
  <label
    className={classnames(labelClass, { [labelShiftedClass]: shifted })}
    htmlFor={
      typeof htmlFor === 'string' || typeof htmlFor === 'number'
        ? `${htmlFor}`
        : `${htmlFor[htmlFor.length - 1]}`
    }
  >
    {label}
    {!required ? null : <span className="tm2-form-label-asterisk">&nbsp;*</span>}
  </label>
);

export const useLabel = ({
  value,
}: {
  value: boolean | string | number | Moment | [string | number, string | number];
}) => {
  const [focused, setFocused] = useState(false);
  const [shifted, setShifted] = useState(false);
  const element = useRef(null);

  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  useEffect(() => {
    // here we also need to check empty range values (date range picker and number range)
    setShifted(
      focused ||
        value === 0 ||
        (value instanceof Array
          ? (!!value[0] || value[0] === 0) && (!!value[1] || value[1] === 0)
          : !!value)
    );
  }, [value, focused]);

  useEffect(() => {
    if (element?.current) {
      element.current.addEventListener('focus', onFocus);
      element.current.addEventListener('blur', onBlur);
    }

    return () => {
      if (element?.current) {
        element.current.removeEventListener('focus', onFocus);
        element.current.removeEventListener('blur', onBlur);
      }
    };
  }, [element]);

  return { element, shifted, onBlur, onFocus };
};

export default LabelRaw;
export const Label: FC<LabelProps> = (props) => (
  <LabelRaw {...props} labelClass="tm2-form-label" labelShiftedClass="tm2-form-label-shifted" />
);
