import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';

export type CMBankAccountStatus = 'ACTIVE' | 'NOT_ACTIVE';

export type CMBankAccountType = 'CLIENT' | 'INHOUSE';
export const useBankAccountTypeTranslations = () => {
  const { i18n, lang } = useI18n();
  const translations: { [k in CMBankAccountType]: string } = useMemo(
    () => ({
      CLIENT: i18n._(
        defineMessage({ id: 'cm.bank_accounts.bank_acc_type.label.client', message: 'Client' })
      ),
      INHOUSE: i18n._(
        defineMessage({ id: 'cm.bank_accounts.bank_acc_type.label.inhouse', message: 'Inhouse' })
      ),
    }),
    [lang]
  );
  return translations;
};
