import { Tooltip as AntdTooltip, TooltipProps as AntdTooltipProps } from 'antd';
import classnames from 'classnames';
import themeStyle from '@styles/theme/index.module.scss';
import s from './index.module.scss';

type TooltipProps = Pick<AntdTooltipProps, 'children' | 'onVisibleChange' | 'title' | 'visible'>;

export function TableTooltip(props: TooltipProps) {
  return (
    <AntdTooltip
      overlayClassName={classnames(s.outer, themeStyle.theme)}
      onVisibleChange={props.onVisibleChange}
      placement={'top'}
      title={props.title}
      visible={props.visible}
      arrowPointAtCenter
    >
      {props.children}
    </AntdTooltip>
  );
}
