import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { Settlement } from './add-settlement-modal/api';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

enum LbsFields {
  startDate = 'startDate',
  endDate = 'endDate',
  recipientUserId = 'recipientUserId',
}

export type LbsFilters = {
  [LbsFields.startDate]?: string;
  [LbsFields.endDate]?: string;
  [LbsFields.recipientUserId]?: number;
};

export enum Fields {
  date = 'date',
  recipientUserId = 'recipientUserId',
}

export interface ListingBrokersPage {
  data: Array<Settlement>;
  hasMore: boolean;
  index: number;
}
export const getListingBrokersPageQuery = 'settlementsPage';
export const getListingBrokersPageFields = [
  {
    data: [
      'id',
      'createdAt',
      { recipient: ['id', 'displayName'] },
      'amount',
      'status',
      { currency: ['id', 'code'] },
    ],
  },
  'index',
  'hasMore',
];
const getListingBrokersPage = makeQuery({
  queryName: getListingBrokersPageQuery,
  queryFields: getListingBrokersPageFields,
  query: ({ page, filter }: { page: number; filter: LbsFilters }): Promise<ListingBrokersPage> => {
    const { startDate, endDate, recipientUserId } = filter;

    const typedValues = {
      ...{ page },
      ...v.localDate({ startDate }),
      ...v.localDate({ endDate }),
      ...v.long({ recipientUserId }),
    };

    return stompClient.getData(
      getListingBrokersPageQuery,
      sign(getListingBrokersPageQuery, getListingBrokersPageFields),
      typedValues
    );
  },
});

const api = {
  getListingBrokersPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:listing-brokers-page');

export default api;
