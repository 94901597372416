import { BehaviorSubject, finalize, from, Observable, Subject } from 'rxjs';
import { filter, mergeMap, skipWhile, take, tap } from 'rxjs/operators';
import { CompatClient } from '@stomp/stompjs';

import { graphqlMutation, graphqlQuery, graphqlSubscribe } from 'containers/services/gql/executor';
import { CancelRequestError } from '../errors';
import { stompFailInterceptor } from '../interceptors';

export enum StompEventType {
  connected = 'connected',
  disconnected = 'disconnected',
  initial = 'initial',
  initiated = 'initiated',
  loggedOut = 'loggedOut',
}

class StompClientService {
  private _stompEvents: BehaviorSubject<StompEvent> = new BehaviorSubject({
    type: StompEventType.initial,
  } as StompEvent);
  private _stompClient;
  private _eventsSubscription;

  public connectionEvents = this._stompEvents.pipe(
    filter((e) => e.type === StompEventType.disconnected || e.type === StompEventType.connected)
  );

  public constructor() {
    this._prepareService();
  }

  public initiate(instance: CompatClient) {
    this._stompEvents.next({ instance: instance, type: StompEventType.initiated });
  }

  public logOut() {
    this._stompEvents.next({ type: StompEventType.loggedOut });
    // due to behaviourSubject latest event should be "disconnected" to avoid cancel error on new sign in
    this._stompEvents.next({ type: StompEventType.disconnected });
  }

  public onConnected() {
    this._stompEvents.next({ type: StompEventType.connected });
  }

  public onDisconnected(error: Error) {
    this._stompEvents.next({ error: error, type: StompEventType.disconnected });
  }

  public async getData<T = any>(operation: Query, fields, typedValues?): Promise<T> {
    await this._waitForConnection();
    return graphqlQuery(this._stompClient, operation, fields, typedValues).catch(this._handleError);
  }

  public async sendData<T = any>(operation: Mutation, fields, typedValues?): Promise<T> {
    await this._waitForConnection();
    return graphqlMutation(this._stompClient, operation, fields, typedValues).catch(
      this._handleError
    );
  }

  public subscription$<T = any>(operation: Subscription, fields, typedValues?): Observable<T> {
    const subject = new Subject<T>();
    let graphqlUnsubscribe;

    const connection = from(this._waitForConnection());
    const connectionUnsubscribe = connection
      .pipe(
        tap(() => {
          graphqlUnsubscribe = graphqlSubscribe(
            this._stompClient,
            (response: { data: T; error: Error }) => {
              if (response?.data) {
                subject.next(response.data);
              }
              if (response?.error) {
                subject.error(response.error);
              }
            },
            operation,
            fields,
            typedValues
          );
          connectionUnsubscribe && connectionUnsubscribe.unsubscribe();
        })
      )
      .subscribe();

    return this._stompEvents.pipe(
      skipWhile((event) => event.type !== StompEventType.connected),
      mergeMap(() => subject),
      finalize(() => graphqlUnsubscribe())
    );
  }

  private _prepareService() {
    this._eventsSubscription = this._stompEvents.subscribe((event: StompEvent) => {
      if (event.type === StompEventType.loggedOut) {
        this._stompClient = null;
      } else if (event.type === StompEventType.initiated) {
        this._stompClient = event.instance;
      }
    });
  }

  private _waitForConnection(): Promise<void> {
    return new Promise((resolve, reject) => {
      const subscription = this._stompEvents
        .pipe(
          filter(
            (event) =>
              event.type === StompEventType.loggedOut || event.type === StompEventType.connected
          ),
          take(1),
          tap((event) => {
            if (event.type === StompEventType.loggedOut) {
              reject(new CancelRequestError('request was cancelled'));
            } else if (event.type === StompEventType.connected) {
              subscription && subscription.unsubscribe();
              resolve();
            }
          })
        )
        .subscribe();
    });
  }

  private _handleError(error) {
    stompFailInterceptor(error);
    return Promise.reject(error);
  }
}

export const stompClientService = new StompClientService();

export type Mutation = string; // TODO сделать енум всех доступных мутаций

export type Query = string; // TODO сделать енум всех доступных query

export type Subscription = string; // TODO сделать енум всех доступных subscription

export interface StompEvent {
  error?: Error;
  instance?: CompatClient;
  type: StompEventType;
}
