import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';

import { ColumnType } from '@components/table2/base';
import { dater } from '@helper/date';
import { useTransactionStatusTranslations, useTransactionTypeTranslations } from '../hooks';
import { CMTransaction, Column } from './api';

const noSorting = [];
export const useColumns = () => {
  const statuses = useTransactionStatusTranslations();
  const types = useTransactionTypeTranslations();

  const columns: Array<ColumnType<CMTransaction>> = useMemo(
    () => [
      {
        key: Column.txDate,
        dataIndex: Column.txDate,
        headCell: () => <Trans id="cm_transactions.table.execution_date">Execution date</Trans>,
        rowCell: ({ data }) => (
          <>
            {data.computedFields?.blockchainTransaction?.date &&
              dater.toLocalDateTime(data.computedFields?.blockchainTransaction?.date)}
          </>
        ),
        sorting: noSorting,
        width: 145,
      },
      {
        key: Column.type,
        dataIndex: Column.type,
        headCell: () => <Trans id="cm_transactions.table.type">Type</Trans>,
        rowCell: ({ data }) => <>{types[data.type]}</>,
        width: 85,
      },
      {
        key: Column.senderBankAccountNumber,
        dataIndex: Column.senderBankAccountNumber,
        headCell: () => (
          <Trans id="cm_transactions.table.sender_acc_number">Sender account number</Trans>
        ),
        rowCell: ({ data }) => <>{data.senderBankAccount?.accountNumber}</>,
        width: 240,
      },
      {
        key: Column.receiverBankAccountNumber,
        dataIndex: Column.receiverBankAccountNumber,
        headCell: () => (
          <Trans id="cm_transactions.table.receiver_acc_number">Receiver account number</Trans>
        ),
        rowCell: ({ data }) => <>{data.receiverBankAccount?.accountNumber}</>,
        width: 250,
      },
      {
        key: Column.externalId,
        dataIndex: Column.externalId,
        headCell: () => <Trans id="cm_transactions.table.external_id">External id</Trans>,
        rowCell: ({ data }) => <>{data.externalId}</>,
        width: 125,
      },
      {
        key: Column.valueDateTime,
        dataIndex: Column.valueDateTime,
        headCell: () => <Trans id="cm_transactions.table.value_date_time">Value Date</Trans>,
        rowCell: ({ data }) => (
          <>{data.valueDateTime && dater.toLocalDateTime(data.valueDateTime)}</>
        ),
        width: 145,
      },
      {
        key: Column.transactionDateTime,
        dataIndex: Column.transactionDateTime,
        headCell: () => (
          <Trans id="cm_transactions.table.transaction_date_time">Transaction Date</Trans>
        ),
        rowCell: ({ data }) => (
          <>{data.transactionDateTime && dater.toLocalDateTime(data.transactionDateTime)}</>
        ),
        width: 175,
      },
      {
        key: Column.method,
        dataIndex: Column.method,
        headCell: () => <Trans id="cm_transactions.table.method">Method</Trans>,
        rowCell: ({ data }) => <>{data.method}</>,
        width: 105,
      },
      {
        key: Column.currency,
        dataIndex: Column.currency,
        headCell: () => <Trans id="cm_transactions.table.currency">Currency</Trans>,
        rowCell: ({ data }) => <>{data.currency?.code}</>,
        width: 115,
      },
      {
        key: Column.amount,
        dataIndex: Column.amount,
        headCell: () => <Trans id="cm_transactions.table.amount">Amount</Trans>,
        rowCell: ({ data }) => <>{data.amount}</>,
        width: 110,
      },
      {
        key: Column.purpose,
        dataIndex: Column.purpose,
        headCell: () => <Trans id="cm_transactions.table.purpose">Purpose</Trans>,
        rowCell: ({ data }) => <>{data.purpose}</>,
        width: 110,
      },
      {
        key: Column.sourceOfFunds,
        dataIndex: Column.sourceOfFunds,
        headCell: () => <Trans id="cm_transactions.table.funds_source">Funds source</Trans>,
        rowCell: ({ data }) => <>{data.sourceOfFunds}</>,
        width: 150,
      },
      {
        key: Column.systemStatus,
        dataIndex: Column.systemStatus,
        headCell: () => <Trans id="cm_transactions.table.status">Status</Trans>,
        rowCell: ({ data }) => <>{statuses[data.systemStatus]}</>,
        width: 95,
      },
      {
        key: Column.minfiniumOpId,
        dataIndex: Column.minfiniumOpId,
        headCell: () => <Trans id="cm_transactions.table.minfinium_op_id">Minfinium op id</Trans>,
        rowCell: ({ data }) => <>{data.computedFields?.blockchainTransaction?.txid}</>,
        sorting: noSorting,
        tooltip: 'overflow',
        width: 130,
      },
    ],
    [statuses, types]
  );

  return {
    columns,
  };
};
