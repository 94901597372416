import React from 'react';
import { Trans } from '@lingui/macro';

export const errorCodesTranslations = {
  AMOUNT_NOT_AVAILABLE: {
    text: (
      <Trans id="shared.backend_error.amount_not_available.text">
        The amount requested is not currently available for purchase. Please amend your request.
      </Trans>
    ),
    title: <Trans id="shared.backend_error.amount_not_available.title">Error 1</Trans>,
  },
  METAL_AMOUNT_TO_TRANSFER_NOT_AVAILABLE: {
    text: (
      <Trans id="shared.backend_error.metal_amount_to_transfer_not_available.text">
        You don't have the required metals in your TM2 account or subaccount.
      </Trans>
    ),
    title: (
      <Trans id="shared.backend_error.metal_amount_to_transfer_not_available.title">
        Insufficient metal
      </Trans>
    ),
  },
  NO_E_WALLET_PUBLIC_KEY: {
    text: (
      <Trans id="no_public_key_modal.text">
        User did not specified e-wallet public key in the profile settings.
      </Trans>
    ),
    title: <Trans id="no_public_key_modal.title">Public key is not specified</Trans>,
  },
  NOT_ENOUGH_OFFER_QUANTITY: {
    text: (
      <Trans id="shared.backend_error.not_enough_offer_quantity.text">
        The remaining amount of offer is currently below the minimum purchase amount.
      </Trans>
    ),
    title: <Trans id="shared.backend_error.not_enough_offer_quantity.title">Error 2</Trans>,
  },
  STABLE_AMOUNT_TO_TRANSFER_NOT_AVAILABLE: {
    text: (
      <Trans id="shared.backend_error.stable_amount_to_transfer_not_available.text">
        You don't have the required funds in your TM2 account or subaccount.
      </Trans>
    ),
    title: (
      <Trans id="shared.backend_error.stable_amount_to_transfer_not_available.title">
        Insufficient funds
      </Trans>
    ),
  },
  TARIFF_NO_HAVE_PERMISSION: {
    text: (
      <Trans id="shared.backend_error.membership_type_no_have_permission.text">
        Your membership type does not have permissions for this action.
      </Trans>
    ),
    title: <Trans id="shared.backend_error.tariff_no_have_permission.title">Access denied</Trans>,
  },
  VERIFICATION_NO_IDENTIFIER_DOCUMENTS: {
    text: (
      <Trans id="shared.backend_error.verification_no_identifier_documents.text">
        There are no user identifier documents yet. Probably user has been approved manually.
      </Trans>
    ),
    title: <Trans id="shared.backend_error.operation_error.title">Operation error</Trans>,
  },
  VERIFICATION_NO_UTILITY_BILLS: {
    text: (
      <Trans id="shared.backend_error.verification_no_utility_bills.text">
        There are no user utility bills yet. Probably user has been approved manually.
      </Trans>
    ),
    title: <Trans id="shared.backend_error.operation_error.title">Operation error</Trans>,
  },
};

export const errorTitlesTranslations = {
  connection: <Trans id="shared.backend_error.default_title.connection">Connection error</Trans>,
  exception: <Trans id="shared.backend_error.default_title.exception">Exception error</Trans>,
  info: <Trans id="shared.backend_error.default_title.info">Info error</Trans>,
  operation: <Trans id="shared.backend_error.default_title.operation">Operation error</Trans>,
  system: <Trans id="shared.backend_error.default_title.system">System error</Trans>,
  unknown: (
    <Trans id="shared.backend_error.default_title.unknown">Oops, something went wrong</Trans>
  ),
};

export const errorTextsTranslations = {
  unknown: <Trans id="shared.backend_error.default_text.unknown">Service unavailable</Trans>,
};
