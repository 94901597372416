import type { SortItem, SortOrder } from './use-table-sorter';

export type KeySortOrder = Array<Omit<SortItem, 'key'> & Pick<Required<SortItem>, 'key'>>;

export function isKeySortOrder(order: SortOrder): order is KeySortOrder {
  return order.every((item) => item.key);
}

export type FieldSortOrder = Array<Omit<SortItem, 'field'> & Pick<Required<SortItem>, 'field'>>;

export function isFieldSortOrder(order: SortOrder): order is FieldSortOrder {
  return order.every((item) => item.field);
}
