import React, { FC } from 'react';
import classnames from 'classnames';
import ScrollMemory from 'react-router-scroll-memory';
import { Layout } from 'antd';

import s from './content.module.scss';
import { PreloaderLine } from '@components/preloader';
import { AuthorizedProvider } from '../provider';

const { Content } = Layout;

const ConnectedAuthorizedContent: FC<{
  className?: string;
}> = ({ children, className }) => (
  <Content className={classnames(s.wrapper, className)}>
    <ScrollMemory />
    <PreloaderLine />
    {children}
  </Content>
);

export const AuthorizedContent = (props) => (
  <AuthorizedProvider>
    <ConnectedAuthorizedContent {...props} />
  </AuthorizedProvider>
);
