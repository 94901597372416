import React, { ReactNode } from 'react';
import { ButtonTextIconBig } from '@components/button';
import { ReactComponent as DeleteIcon } from '@images/icons/cross-icon.svg';
import s from './index.module.scss';

type RemoveButtonProps = {
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  children: ReactNode;
};

export function RemoveButton(props: RemoveButtonProps) {
  return (
    <ButtonTextIconBig
      className={props.className}
      icon={
        <span className={s.removeButtonIconWrapper}>
          <DeleteIcon className={s.icon} />
        </span>
      }
      disabled={props.disabled}
      onClick={props.onClick}
    >
      {props.children}
    </ButtonTextIconBig>
  );
}
