import React, { useContext, FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from './header.module.scss';
import { ReactComponent as LogoWideIcon } from '@images/icons/logo-light-wide.svg';
import { ReactComponent as LogoShortIcon } from '@images/icons/logo-light-short.svg';
import { ReactComponent as LogOutLightIcon } from '@images/icons/log-out-light-icon.svg';
import { ButtonTextIconBig } from '@components/button';
import { configUserActions } from '@config/store/user.acions';
import { DispatchFn } from '@models/redux';
import { LanguageSelector } from '@components/language-selector';
import { LayoutContext, useBlockHeight } from '@modules/layout';
import { theme } from '@constants/theme';
import { useCallbackOne } from 'use-memo-one';

const mapDispatchToProps = (dispatch) => ({
  onLogoutClicked: () => dispatch(configUserActions.onLogoutClicked()),
});

export const ConnectedLightLayoutHeader: FC<{
  onLogoutClicked: DispatchFn<void>;
}> = ({ onLogoutClicked }) => {
  const { updateLayoutValues } = useContext(LayoutContext);

  const updateHeaderHeight = useCallbackOne((height: number) => {
    updateLayoutValues({
      pageHeaderHeight: height,
    });
  }, []);
  const { ref: containerRef } = useBlockHeight(updateHeaderHeight);

  useEffect(() => {
    return () => {
      updateLayoutValues({
        pageHeaderHeight: 0,
      });
    };
  }, []);

  return (
    <div ref={containerRef} className={s.header}>
      <div className={s.logo}>
        <LogoShortIcon className={s.logoImage} />
        <LogoWideIcon className={s.logoImage} />
      </div>

      <LanguageSelector className={s.language} theme={theme.light} />

      <ButtonTextIconBig
        className={s.logout}
        icon={<LogOutLightIcon className="tm2-icon-stroke" />}
        onClick={onLogoutClicked}
        uat="logout-button"
      >
        <Trans id="sign_up.header.log_out">Log out</Trans>
      </ButtonTextIconBig>
    </div>
  );
};

export const LightLayoutHeader = connect(null, mapDispatchToProps)(ConnectedLightLayoutHeader);
