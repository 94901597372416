import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';

import { closeModalAction, MFC } from '@modules/modal';
import { ButtonPrimary } from '@components/button';
import { TitleIcon } from '../base/title/icon';
import { NotifyModalProps } from '../store/model';
import { Modal } from '../base/modal';
import { ModalContent } from '../base/content';
import { ModalFooter } from '../base/footer';
import { ModalTitle } from '../base/title';

export const NotifyModal: MFC<{}, NotifyModalProps> = ({
  description,
  isVisible,
  okText,
  onClose,
  type,
  title,
}) => {
  const dispatch = useDispatch();
  const onCloseClicked = () => {
    dispatch(closeModalAction(NotifyModal));
    if (onClose) {
      onClose();
    }
  };

  const text = Array.isArray(description)
    ? description.map((txt, i) => <p data-uat={`[${i + 1}].notification-text`}>{txt}</p>)
    : description;

  return (
    <Modal
      onClose={onCloseClicked}
      loading={false}
      disabled={false}
      isVisible={isVisible}
      className="tm2-modal-notify"
      uat="notification-modal"
    >
      <ModalTitle icon={!type ? null : <TitleIcon type={type} />} uat="notification-title">
        {title}
      </ModalTitle>

      <ModalContent uat="notification-info-all">{text}</ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} onClick={() => onCloseClicked()} uat="ok-button">
          {okText ? okText : <Trans id="button.ok">OK</Trans>}
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
