import React, { useContext, useEffect } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { Trans } from '@lingui/macro';

import s from './footer.module.scss';
import { LayoutContext, useBlockHeight } from '@modules/layout';
import { LINKS } from '@constants/link';

export const LightLayoutFooter = () => {
  const { updateLayoutValues } = useContext(LayoutContext);

  const updateFooterHeight = useCallbackOne((height: number) => {
    updateLayoutValues({
      pageFooterHeight: height,
    });
  }, []);
  const { ref: containerRef } = useBlockHeight(updateFooterHeight);

  useEffect(() => {
    return () => {
      updateLayoutValues({
        pageFooterHeight: 0,
      });
    };
  }, []);

  return (
    <div ref={containerRef} className={s.footer}>
      <div>
        <Trans id="sign_up.footer.tm2_limited">© Technology Metals Market Limited.</Trans>
        &nbsp;&nbsp;
      </div>

      <div>
        {`2020 - ${new Date().getFullYear()}`}&nbsp;
        <Trans id="sign_up.footer.all_rights_reserved">All rights reserved.</Trans>
        &nbsp;&nbsp;
      </div>

      <a href={LINKS.PRIVACY_POLICY} className="tm2-footer-nav-link" target="_blank">
        <Trans id="sign_up.footer.privacy_policy">Privacy Policy</Trans>
      </a>
    </div>
  );
};
