import React, { FC, memo, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors, configServiceActions } from '@config/core';
import { useCallDispatch } from '@hooks/core';

export const CheckTraderTutorial: FC = memo(() => {
  const lastPostRegStep = useSelector(configSelectors.lastPostRegStage);
  const checkTutorialEvent = useCallDispatch(configServiceActions.checkTutorialEvent);

  useEffect(() => {
    checkTutorialEvent();
  }, [lastPostRegStep]);

  return null;
});
