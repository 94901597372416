import { put } from 'redux-saga/effects';

export function Put(actionFn, payload?) {
  return function* (_action) {
    yield put(actionFn(payload || _action.payload));
  };
}

export const SliceId = (name: string) => {
  return (subName?: string) => (!subName ? name : `${name}/${subName}`);
};
