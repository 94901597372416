import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { OfferStatus } from '../../shared/types';
import { Offer } from './types';

type Price = {
  currencyId: number;
  quantity: number;
};

type QueryOffer = {
  id: number;
  activeOfferPrices: Array<{
    id: number;
    quantity: number;
    currency: {
      id: number;
      code: string;
    };
    unit: {
      label: string;
    };
  }>;
  availableQuantity: number;
  coin: {
    asset: string;
    metal: {
      label: string;
    };
  };
  creator: {
    id: number;
    displayName: string;
  };
  date: string;
  quantityUnit: {
    label: string;
  };
  sellCommission: number;
  status: OfferStatus;
};

type ChangeOfferPricesResponse = Offer;

const changeOfferPricesQuery = 'changeOfferPrices';
const changeOfferPricesFields = [
  'id',
  {
    activeOfferPrices: [
      'id',
      'quantity',
      { currency: ['id', 'code', 'symbol'] },
      { unit: ['label'] },
    ],
  },
  'availableQuantity',
  {
    coin: ['asset', { metal: ['label'] }],
  },
  {
    creator: ['id', 'displayName'],
  },
  'date',
  { quantityUnit: ['label'] },
  'sellCommission',
  'status',
];

const changeOfferPrices = makeQuery({
  queryName: changeOfferPricesQuery,
  queryFields: changeOfferPricesFields,
  query: (params: { prices: Price[]; offerId: number }): Promise<ChangeOfferPricesResponse> => {
    const variables = {
      ...v.newOfferPriceInput({ newPrices: params.prices }),
      ...v.long({ offerId: params.offerId }),
    };
    return stompClient
      .sendData<QueryOffer>(
        changeOfferPricesQuery,
        sign(changeOfferPricesQuery, changeOfferPricesFields),
        variables
      )
      .then(
        (offer: QueryOffer): Offer => ({
          id: offer.id,
          activePrices: offer.activeOfferPrices.map((price) => ({
            id: price.id,
            quantity: price.quantity,
            currencyId: price.currency.id,
            currencyCode: price.currency.code,
            unitLabel: price.unit.label,
          })),
          asset: offer.coin.asset,
          availableQuantity: offer.availableQuantity,
          creatorId: offer.creator.id,
          creatorName: offer.creator.displayName,
          date: offer.date,
          metal: offer.coin.metal.label,
          quantityUnitLabel: offer.quantityUnit.label,
          sellCommission: offer.sellCommission,
          status: offer.status,
        })
      );
  },
});

const api = {
  changeOfferPrices,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:edit-offer');

export default api;
