import { MetalsPageActionsTypes as Types } from './model';

const OnDownloadClickedAction = () => ({
  payload: null,
  type: Types.ON_DOWNLOAD_CLICKED,
});

const OnPageLeftAction = () => ({
  payload: null,
  type: Types.ON_PAGE_LEFT,
});

const OnPageOpenedAction = () => ({
  payload: null,
  type: Types.ON_PAGE_OPENED,
});

const OnTableScrolledAction = () => ({
  payload: null,
  type: Types.ON_TABLE_SCROLLED,
});

const OnTableSortedAction = (columnInfo) => ({
  payload: columnInfo,
  type: Types.ON_TABLE_SORTED,
});

const OnUploadClickedAction = (file) => ({
  payload: file,
  type: Types.ON_UPLOAD_CLICKED,
});

export const metalsPageUserActions = {
  onDownloadClicked: OnDownloadClickedAction,
  onPageLeft: OnPageLeftAction,
  onPageOpened: OnPageOpenedAction,
  onTableScrolled: OnTableScrolledAction,
  onTableSorted: OnTableSortedAction,
  onUploadClicked: OnUploadClickedAction,
};
