import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './portfolio.module.scss';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { useBuyMetalsPortfolioModel } from './model';

export const Portfolio = () => {
  const {
    coinBalance,
    getFlagClass,
    isCoinBalanceLoading,
    height,
    stableBalance,
    isStableBalanceLoading,
  } = useBuyMetalsPortfolioModel();

  return (
    <>
      <div className="light-gradient-block gradient-border-block">
        <h3>
          <Trans id="buy_metals.portfolio">Portfolio</Trans>
        </h3>
      </div>

      <div
        className={classnames(s.portfolio, 'light-gradient-block', {
          'block-blurred': isStableBalanceLoading || isCoinBalanceLoading,
        })}
        style={{ height }}
      >
        <div className={s.block}>
          <div className={s.row}>
            <div className={s.cell}>
              <Trans id="buy_metals.currency">Currency</Trans>
            </div>
            <div className={s.cell}>
              <Trans id="buy_metals.available">Available</Trans>
            </div>
          </div>

          {stableBalance?.map((item, index) => (
            <div className={s.row} key={index}>
              <div className={s.cell}>
                <div className={getFlagClass(item.asset)} />
                {item?.asset}
              </div>
              <div className={classnames(s.cell)}>{formatMoney(item.balance, { pre: '' })}</div>
            </div>
          ))}
        </div>

        <div className={classnames(s.block, s.metalsSection)}>
          <div className={classnames(s.row)}>
            <div className={s.cell}>
              <Trans id="buy_metals.symbol">Symbol</Trans>
            </div>
            <div className={s.cell}>
              <Trans id="buy_metals.available">Available</Trans>
            </div>
          </div>

          {coinBalance?.map((item, index) => (
            <div className={classnames(s.row)} key={index}>
              <div className={s.cell}>{item?.asset}</div>
              <div className={classnames(s.cell)}>{formatToken(item.balance, { pre: '' })}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
