import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { Box } from '@components/box';
import { Form, InputDateRange, Select } from '@components/form';
import { HasAccess } from '@permissions/core';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ButtonTextIconBig } from '@components/button';
import { CorrectionTransaction } from '../add-correction-transaction-modal/api';
import { Fields, Filters, formId, maxDate, useModel } from './model';
import {
  AddCorrectionTransactionModal,
  permissionGroup as addCorrectionTransactionModalPermissionGroup,
} from '../add-correction-transaction-modal';
import s from './index.module.scss';

export const permissionGroup = addCorrectionTransactionModalPermissionGroup.createSuperGroup({
  marker: 'layout:correction-transactions-page-actions-row',
});

export const ActionsRow: FC<{
  replaceTransaction: (transaction: CorrectionTransaction) => void;
  onFiltersChanged: (filters: Filters) => void;
}> = ({ replaceTransaction, onFiltersChanged }) => {
  const m = useModel(replaceTransaction, AddCorrectionTransactionModal);

  return (
    <Box className={classnames('mb2', s.box)}>
      <Form name={formId} className={s.form} onChange={onFiltersChanged}>
        <InputDateRange
          name={Fields.date}
          label={<Trans id="daterange.label">Date</Trans>}
          max={maxDate}
          disabled={m.lockUI}
          compact={true}
          className={s.filter}
        />

        <Select
          name={Fields.senderId}
          data={m.issuers}
          label={<Trans id="balance.settlements.filter.label.sender">Sender</Trans>}
          disabled={m.lockUI}
          compact={true}
          allowEmpty={true}
          className={s.filter}
        />

        <Select
          name={Fields.recipientId}
          data={m.issuers}
          label={<Trans id="correction.transactions.recipient">Recipient</Trans>}
          disabled={m.lockUI}
          compact={true}
          allowEmpty={true}
          className={s.filter}
        />
      </Form>

      <HasAccess permissions={{ or: [addCorrectionTransactionModalPermissionGroup] }}>
        <ButtonTextIconBig
          disabled={m.lockUI}
          icon={<AddIcon className="tm2-icon-stroke" />}
          onClick={m.onAddCorrectionTransaction}
        >
          <Trans id="correction.transactions.add.settlement">Add settlement</Trans>
        </ButtonTextIconBig>
      </HasAccess>
    </Box>
  );
};
