import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { TableData, TableResponse } from '@models/core';
import { SortOrder as QuerySortOrder } from '@models/sorting';
import { stompClientService as stompClient } from '@services/stomp/client';

export type VirtualBalanceItem = {
  amount: number;
  balanceOwner: {
    issuedAt: string;
    id: number;
    displayName: string;
  };
  currency: {
    code: string;
  };
};

export enum GetVirtualBalancesSortKey {
  issuedAt = 'balanceOwner.issuedAt',
}

export type GetVirtualBalancesSortOrder = QuerySortOrder<GetVirtualBalancesSortKey>;

const getVirtualBalancesQuery = 'virtualBalanceReport';
const getVirtualBalancesFields = [
  {
    data: ['amount', { balanceOwner: ['issuedAt', 'id', 'displayName'] }, { currency: ['code'] }],
  },
  'index',
  'hasMore',
];
export const getVirtualBalances = makeQuery({
  permissions: Permission.VIRTUAL_BALANCE_VIEW_REPORT,
  queryName: getVirtualBalancesQuery,
  queryFields: getVirtualBalancesFields,
  query: (
    pageNumber: number,
    ordering: GetVirtualBalancesSortOrder
  ): Promise<TableData<VirtualBalanceItem>> => {
    const typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };
    return stompClient
      .getData(
        getVirtualBalancesQuery,
        sign(getVirtualBalancesQuery, getVirtualBalancesFields),
        typedValues
      )
      .then((response: TableResponse<VirtualBalanceItem>) => ({
        data: response.data,
        isHasMore: response.hasMore,
      }));
  },
});

const api = {
  getVirtualBalances,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:virtual-balance-report-page');

export default api;
