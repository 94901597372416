import sign from 'tm2sign.macro';

const coinFields = ['id', 'asset', { metal: ['label'] }];

const bidOrdersFields = ['id', 'status'];

export default {
  offersCoinsFields: sign('offersCoins', coinFields),
  bidOrdersCoinsFields: sign('bidOrdersCoins', coinFields),
  approveBidOrderFields: sign('approveBidOrder', bidOrdersFields),
  denyBidOrderFields: sign('denyBidOrder', bidOrdersFields),
};
