import { useSelector } from 'react-redux';

import { VerificationStatus as S } from '@models/core';
import { configSelectors } from '@config/core';
import { modalSelectors, showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { UploadVerificationDocsModal } from '../modal';

export const useModel = (isClosePrevious) => {
  const isCustomModalOpened = useSelector(modalSelectors.isCustomModalOpened);
  const status = useSelector(configSelectors.verificationStatus);
  const showModal = useCallDispatch(showModalAction);

  const onButtonClicked = () =>
    showModal({
      isClosePrevious: isClosePrevious,
      modal: UploadVerificationDocsModal,
    });

  return {
    disabled: isCustomModalOpened(UploadVerificationDocsModal),
    isShowButton: status === S.documentRequested || status === S.resubmissionRequested,
    onButtonClicked,
    status,
  };
};
