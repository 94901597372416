import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@hooks/core';
import api from './api';

export const useProfileOtherBalances = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: balances,
    isLoading: isBalancesLoading,
    refresh,
  } = useQuery(() => api.getOtherProfileBalances(+userId), {
    initial: { primary: [], secondary: [] },
  });

  useEffect(() => {
    if (!isBalancesLoading) {
      refresh();
    }
  }, [userId]);

  return {
    balances,
    isBalancesLoading,
  };
};
