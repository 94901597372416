import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useI18n } from '@hooks/i18n';
import { configSelectors } from '@config/store/selectors';
import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';

import { SecondaryMarketDisabledModal } from '../wallet/modals/secondary-market-disabled';
import api from './api';

export const useModel = () => {
  const { i18n } = useI18n();
  const hash = window.location.hash;
  const isSMDisabled = useSelector(configSelectors.isSecondaryMarketDisabled);
  const showModal = useCallDispatch(showModalAction);

  const onSecondaryMarketClicked = async () => {
    try {
      if (isSMDisabled) {
        showModal({ modal: SecondaryMarketDisabledModal });
      } else {
        await api.secondaryMarketLogin();
        window.open('/secondary-market', '_blank');
      }
    } catch (error) {
      handleBackendError(error);
    }
  };

  useEffect(() => {
    const element = document.querySelector(hash || '#tutorial-begin'); // default need for browser back btn
    element?.scrollIntoView && element.scrollIntoView({ behavior: 'smooth' });
  }, [hash]);

  return {
    i18n,
    onSecondaryMarketClicked,
  };
};
