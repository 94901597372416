import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { finishRedeemModalSelectors as finishRedeemSelectors } from './store/selectors';
import { finishRedeemModalUserActions as finishRedeemUserActions } from './store/user.acions';
import { ButtonPrimary } from '@components/button';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Link } from '@components/link';

const mapStateToProps = (root) => ({
  isCreateRedeemLoading: finishRedeemSelectors.isCreateRedeemLoading(root),
  lockUI: finishRedeemSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(finishRedeemUserActions.onCloseModalClicked()),
  onCreateRedeemClicked: () => dispatch(finishRedeemUserActions.onCreateRedeemClicked()),
  onGetTransactionClicked: () => dispatch(finishRedeemUserActions.onGetTransactionClicked()),
});

const ConnectedRedeemFinishModal = ({
  isCreateRedeemLoading,
  isVisible,
  lockUI,
  onClose,
  onCreateRedeemClicked,
  onGetTransactionClicked,
}) => {
  return (
    <Modal isVisible={isVisible} loading={lockUI} disabled={lockUI} onClose={onClose}>
      <ModalTitle>
        <Trans id="redeem_finish_modal.need.to.finish.operation">
          Need to finish operation using desktop wallet
        </Trans>
      </ModalTitle>

      <ModalContent>
        <div className="mb-xs">
          <Trans id="redeem_finish_modal.need.to.finish.operation">
            Need to finish operation using desktop wallet
          </Trans>
        </div>

        <div className="mb-xs">
          <Trans id="redeem_finish_modal.to.finish.redeem.operation">
            To finish the redeem operation you need to send the digital assets to the TM2 clearance
            broker.
          </Trans>
        </div>

        <div>
          <Trans id="redeem_finish_modal.in.terms.of.security">
            In terms of security our platform cannot make it automatically.
          </Trans>
        </div>

        <div>
          <Trans id="redeem_finish_modal.so.to.proceed.sending">
            So, to proceed sending digital metal for clearing you need:
          </Trans>
        </div>

        <div>
          <ol>
            <li>
              <Trans id="redeem_finish_modal.download.unsigned.blockchain">
                Download unsigned blockchain transaction file from
              </Trans>
              &nbsp;&nbsp;
              <Link disabled={lockUI} onClick={onGetTransactionClicked}>
                <Trans id="redeem_finish_modal.here">here</Trans>
              </Link>
              .
            </li>
            <li>
              <Trans id="redeem_finish_modal.launch.the.desktop.app">
                Launch the desktop application.
              </Trans>
            </li>
            <li>
              <Trans id="redeem_finish_modal.go.to.send.section">Go to the "Send" section.</Trans>
            </li>
            <li>
              <Trans id="redeem_finish_modal.press.upload.transaction">
                Press "Upload transaction" button and select .tm2 file you downloaded at the step 1.
              </Trans>
            </li>
            <li>
              <Trans id="redeem_finish_modal.press.send.button">
                Press "Send" button and confirm operation.
              </Trans>
            </li>
            <li>
              <Trans id="redeem_finish_modal.tm2.charges.the.redeem">
                The TM2 charges the redeem fee automatically.
              </Trans>
            </li>
          </ol>
        </div>

        <div className="mb-xs">
          <Trans id="redeem_finish_modal.after.successful.transfering">
            After successful transfering our clearance broker will contact you to discuss the
            redemption and shipping details.
          </Trans>
        </div>

        <div className="mb-lg">
          <em>
            <Trans id="redeem_finish_modal.note.if.you.transfer">
              Note. If you transfer inappropriate amount of metals, the TM2 will recalculate options
              amount and return funds to your balance
            </Trans>
          </em>
        </div>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary
          fullWidth={true}
          preloader={isCreateRedeemLoading}
          disabled={lockUI}
          onClick={onCreateRedeemClicked}
        >
          <Trans id="button.ok">OK</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};

export const RedeemFinishModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedRedeemFinishModal);
