import { useCallbackOne } from 'use-memo-one';
import { Trans, defineMessage } from '@lingui/macro';
import { Table, useTable, ColumnType, KeyExtractor, LoadData } from '@components/table2/simplified';
import { tableColCfgDefault, tableRowCfgDefault } from '@components/table2/config/table-simplified';
import { ProfileLinkCell, TablePane } from '@components/table2/utils';
import { PageContent, PageTitle } from '@components/page';
import { mapTableSorting } from '@helper/sorting';
import { PermissionGroup } from '@permissions/core';
import { useI18n } from 'containers/services/commonService';
import { profileOtherPagePermissionsGroup } from '../../profile/other/permissions';
import api, {
  permissionGroup as apiPermissionGroup,
  ReferralItem,
  GetReferralsSortKey,
} from './api';
import s from './index.module.scss';

export const otherProfilePermissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:referrals-report-page-id-cell',
});

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, otherProfilePermissionGroup],
  marker: 'layout:referrals-report-page',
});

enum ColumnKey {
  affiliateId = 'affiliateId',
  clientId = 'id',
  clientEmail = 'clientEmail',
  registered = 'registered',
}

const columns: ColumnType<ReferralItem>[] = [
  {
    key: ColumnKey.clientId,
    dataIndex: 'id',
    headCell: () => <Trans id="referrals.table.client_id">Client ID</Trans>,
    rowCell: ({ data }) => <ProfileLinkCell text={data.id} id={data.id} />,
    width: 200,
  },
  {
    key: ColumnKey.clientEmail,
    dataIndex: 'email',
    headCell: () => <Trans id="referrals.table.client_email">Client email</Trans>,
    rowCell: ({ data }) => <>{data.email}</>,
    width: 300,
  },
  {
    key: ColumnKey.affiliateId,
    dataIndex: 'affiliateId',
    headCell: () => (
      // TODO: translation key/value mismatch
      <Trans id="referrals.table.affiliate_email">Affiliate ID</Trans>
    ),
    rowCell: ({ data }) => <>{data.affiliateId}</>,
    width: 200,
  },
  {
    key: ColumnKey.registered,
    dataIndex: 'issuedAt',
    // TODO: grammar?
    headCell: () => <Trans id="referrals.table.registrated">Registrated</Trans>,
    rowCell: ({ data }) => <>{data.issuedAt?.formatLocalDateTime()}</>,
    width: 200,
  },
];

const sortMap = {
  [ColumnKey.affiliateId]: GetReferralsSortKey.affiliateId,
  [ColumnKey.clientEmail]: GetReferralsSortKey.email,
  [ColumnKey.clientId]: GetReferralsSortKey.id,
  [ColumnKey.registered]: GetReferralsSortKey.issuedAt,
};

export const ReferralsPage = () => {
  const { i18n } = useI18n();

  const keyExtractor = useCallbackOne<KeyExtractor<ReferralItem>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallbackOne<LoadData<ReferralItem>>(({ nextPage, sortOrder }) => {
    const sorting = mapTableSorting(sortOrder, sortMap);
    return api.getReferrals(nextPage, sorting);
  }, []);

  const { tableInstance } = useTable<ReferralItem>({
    ensureUniqueness: true,
    keyExtractor,
    loadData,
  });

  return (
    <PageContent className={s.verticalFiller} uat="statements-referrals-page">
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.referrals',
            message: 'Referral Summary Report',
          })
        )}
        backwardLink={'/statements'}
      />
      <TablePane className={s.verticalFiller}>
        <Table
          className={s.verticalFiller}
          columns={columns}
          columnConfig={tableColCfgDefault}
          instance={tableInstance}
          rowConfig={tableRowCfgDefault}
        />
      </TablePane>
    </PageContent>
  );
};
