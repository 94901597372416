import React, { FC, useMemo, useRef, useState } from 'react';
import { useCallbackOne } from 'use-memo-one';

import s from '../link-cell/index.module.scss';
import { UserProfileLink } from '@components/link';
import { TableTooltip } from '../tooltip';

// TODO: refactor аналогично link-cell

const ProfileLinkCell: FC<{
  disabled?: boolean;
  id: number;
  text: string | number;
}> = ({ disabled = false, id, text }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipContentRef = useRef<HTMLSpanElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onVisibleChange = useCallbackOne((isVisible) => {
    if (!isVisible) {
      setIsTooltipVisible(false);
      return;
    }
    if (!containerRef.current || !tooltipContentRef.current) return;

    const container = containerRef.current;
    const content = tooltipContentRef.current;
    const overflow = container.offsetWidth <= content.offsetWidth;

    if (overflow) {
      setIsTooltipVisible(true);
    }
  }, []);

  const content = useMemo(
    () => <UserProfileLink id={id} text={String(text)} disabled={disabled} />,
    [disabled, id, text]
  );

  return (
    <div ref={containerRef} className={s.linkContainer}>
      <TableTooltip onVisibleChange={onVisibleChange} title={content} visible={isTooltipVisible}>
        <span ref={tooltipContentRef}>{content}</span>
      </TableTooltip>
    </div>
  );
};

export default ProfileLinkCell;
