import { ReactNode } from 'react';

export type RowCellContainerProps = {
  children: ReactNode;
  width: number;
};

export function RowCellContainer(props: RowCellContainerProps) {
  return <div style={{ width: props.width }}>{props.children}</div>;
}
