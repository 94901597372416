import { useSelector } from 'react-redux';

import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import { configSelectors } from '@config/core';

export const useModel = (modal) => {
  const isIndividualTrader = useSelector(configSelectors.isIndividualTrader);
  const status = useSelector(configSelectors.verificationStatus);
  const closeModal = useCallDispatch(closeModalAction);

  const onCloseModal = () => closeModal(modal);

  return {
    isIndividualTrader,
    onCloseModal,
    status,
  };
};
