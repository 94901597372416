import React from 'react';
import { Trans } from '@lingui/macro';

import { formatMoney, formatToken } from 'containers/services/commonService';
import { useTradeHistoryModel } from './model';

export const TradeHistory = () => {
  const { currencyCode, height, lockUI, orderStatuses, statusColors, tradeHistory } =
    useTradeHistoryModel();

  return (
    <>
      <div
        className="light-gradient-block gradient-border-block market-page-trade-history"
        id={'header'}
      >
        <h3>
          <Trans id="buy_metals.trade_history">Trade history</Trans>
        </h3>
      </div>

      <div className={`light-gradient-block padding-0 relative`} id={'table'}>
        <div className="scroll-block" style={{ height }}>
          <table className="small-table-2 table-tr-hover-gradient">
            <thead>
              <tr>
                <th>
                  <Trans id="head_title.status">Status</Trans>
                </th>
                <th>
                  <Trans id="head_title.qty">Qty</Trans>
                </th>
                <th>
                  <Trans id="head_title.price_w_currency_code">Price, {currencyCode}</Trans>
                </th>
                <th>
                  <Trans id="head_title.date_and_time">Date & Time</Trans>
                </th>
              </tr>
            </thead>
            <tbody className={lockUI ? 'block-blurred' : ''}>
              {tradeHistory?.map((order, i) => (
                <tr key={`${order.id}-${i}`}>
                  <td title={orderStatuses[order.status]} className="padding-right-0">
                    <span className={`inline-circle circle-size-5 ${statusColors[order.status]}`} />
                  </td>
                  <td className="align-right">{formatToken(order.quantity)}</td>
                  <td className="align-right">
                    {formatMoney(order.offerPrice.quantityPerGram, {})}
                  </td>
                  <td>{order.dateUpdate.formatLocalDateTime()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
