import React from 'react';
import { Trans } from '@lingui/macro';

import './styles.scss';
import { tokenDecimalsMax } from '@constants/math';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputNumber, SubmitButton } from '@components/form';
import { ButtonPrimary } from '@components/button';
import { Divider } from '@components/divider';
import { formatMoney } from '../../../../../services/commonService';
import { TickerItem, MyStableBalance, OfferPrice } from '../../metals-api.service';
import { MetalPrice } from './price';
import {
  BuyFormFields as FormFields,
  buyFormId as formId,
  minQuantity,
  formInitial,
  useModel,
} from './model';

export interface BuyConfirmModalProps {
  offerPrice: OfferPrice;
  selectedPair: TickerItem;
  stableBalance: MyStableBalance;
}

export const BuyConfirmModal: MFC<BuyConfirmModalProps> = ({
  isVisible,
  offerPrice,
  selectedPair,
  stableBalance,
}) => {
  const {
    currency,
    isBuyButtonDisabled,
    isMaximizeButtonDisabled,
    lockUI,
    maxQuantity,
    onAmountChanged,
    onClose,
    onConfirmBuyAction,
    onMaximizeButtonClicked,
    issuerTaxPct,
    issuerTax,
    priceWithCommission,
    priceWithoutCommission,
    sellCommission,
    unitPriceWithoutCommission,
  } = useModel({ offerPrice, selectedPair, stableBalance });

  return (
    <Modal
      className="buy-confirm-modal"
      onClose={onClose}
      isVisible={isVisible}
      disabled={lockUI}
      loading={lockUI}
    >
      <ModalTitle>
        <span>{selectedPair.metal}</span>&nbsp;
        <span>{selectedPair.coin.asset}</span>&nbsp;
        <span>{currency}</span>
      </ModalTitle>

      <ModalContent>
        <div className="buy-confirm-modal-row mb-md">
          <div className="buy-confirm-modal-row-label">
            <Trans id="buy_metals.label.metal_price">Metal price</Trans>
          </div>

          <div className="buy-confirm-modal-row-description">
            <MetalPrice selectedPair={selectedPair} value={unitPriceWithoutCommission} />
          </div>
        </div>

        <Form name={formId} initialValues={formInitial}>
          <div className="buy-confirm-modal-row mb-md">
            <div className="buy-confirm-modal-row-label">
              <Trans id="buy_metals.label.units">Units</Trans>
            </div>

            <div className="buy-confirm-modal-row-description">
              <InputNumber
                className="buy-confirm-modal-row-description-field mr-md"
                label={<Trans id="buy_metals.label.units">Units</Trans>}
                required={true}
                min={minQuantity}
                max={maxQuantity}
                maxDecimals={tokenDecimalsMax}
                name={FormFields.amount}
                onChange={onAmountChanged}
              />

              <ButtonPrimary
                loading={null}
                icon={null}
                disabled={isMaximizeButtonDisabled}
                onClick={onMaximizeButtonClicked}
              >
                <Trans id="buy_metals.button.max">Max</Trans>
              </ButtonPrimary>
            </div>
          </div>
        </Form>

        <Divider className="mb-md" />

        {priceWithoutCommission === priceWithCommission ? null : (
          <div className="buy-confirm-modal-row mb-md">
            <div className="buy-confirm-modal-row-label">
              <Trans id="buy_metals.label.metal_price">Metal price</Trans>
            </div>

            <div className="buy-confirm-modal-row-description">
              {formatMoney(priceWithoutCommission, { pre: currency })}
            </div>
          </div>
        )}

        {!issuerTaxPct ? null : (
          <div className="buy-confirm-modal-row mb-md">
            <div className="buy-confirm-modal-row-label">
              <Trans id="buy_metals.label.tax">Tax</Trans> ({issuerTaxPct}%)
            </div>
            <div className="buy-confirm-modal-row-description">
              {formatMoney(issuerTax, { pre: currency })}
            </div>
          </div>
        )}

        {!sellCommission ? null : (
          <div className="buy-confirm-modal-row mb-md">
            <div className="buy-confirm-modal-row-label">
              <Trans id="buy_metals.label.execution_fee">Execution fee</Trans>
            </div>

            <div className="buy-confirm-modal-row-description">
              {formatMoney(sellCommission, { pre: currency })}
            </div>
          </div>
        )}

        <div className="buy-confirm-modal-row buy-confirm-modal-row-total mb-md">
          <div className="buy-confirm-modal-row-label">
            <Trans id="buy_metals.label.total_payment">Total payment</Trans>
          </div>

          <div className="buy-confirm-modal-row-description">
            {formatMoney(priceWithCommission, { pre: currency })}
          </div>
        </div>

        <Divider className="mb-md" />

        <div className="buy-confirm-modal-row op-text">
          <div className="buy-confirm-modal-row-label">
            <Trans id="buy_metals.label.funds_available">Funds available</Trans>
          </div>

          <div className="buy-confirm-modal-row-description">
            {formatMoney(stableBalance.balance, { pre: stableBalance.asset })}
          </div>
        </div>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={formId}
          fullWidth={true}
          onAction={onConfirmBuyAction}
          disabled={isBuyButtonDisabled}
        >
          <Trans id="buy_metals.button.confirm">Confirm</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
