import {
  vaultBalanceDetailsStateInitial,
  VaultBalanceDetailsActionsTypes as DetailsTypes,
  VaultBalanceDetailsState,
  VaultBalanceDetailsActions,
} from './model';

export const vaultBalanceDetailsReducer = (
  state: VaultBalanceDetailsState = vaultBalanceDetailsStateInitial,
  action: VaultBalanceDetailsActions
): VaultBalanceDetailsState => {
  switch (action.type) {
    case DetailsTypes.GET_VAULT_BALANCE_DETAILS: {
      return {
        ...state,
        isGetBalanceHistoryLoading: true,
      };
    }

    case DetailsTypes.GET_VAULT_BALANCE_DETAILS_FAIL: {
      return {
        ...state,
        isGetBalanceHistoryLoading: false,
      };
    }

    case DetailsTypes.GET_VAULT_BALANCE_DETAILS_SUCCESS: {
      return {
        ...state,
        balanceHistory: action.payload,
        isGetBalanceHistoryLoading: false,
      };
    }

    case DetailsTypes.ON_MODAL_LEFT: {
      return {
        ...vaultBalanceDetailsStateInitial,
      };
    }

    case DetailsTypes.ON_MODAL_OPENED: {
      return {
        ...vaultBalanceDetailsStateInitial,
        coin: action.payload.coin,
        vault: action.payload.vault,
      };
    }

    default: {
      return state;
    }
  }
};
