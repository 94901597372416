import { TableHeaderSortType as Sort } from 'components/page-table';

export const UsersTableColumnIds = {
  active: 'active',
  country: 'country',
  email: 'email',
  fullName: 'fullName',
  onBoardingStatus: 'onBoardingStatus',
  phone: 'phone',
  role: 'role',
  id: 'id',
  issuedAt: 'issuedAt',
  umsId: 'umsId',
  verification: 'verification',
};

const columnSort = [
  { direction: Sort.notSet, fields: ['isActive'], id: UsersTableColumnIds.active },
  { direction: Sort.notSet, fields: ['country.label'], id: UsersTableColumnIds.country },
  { direction: Sort.notSet, fields: ['email'], id: UsersTableColumnIds.email },
  {
    direction: Sort.notSet,
    fields: ['firstName', 'lastName', 'company'],
    id: UsersTableColumnIds.fullName,
  },
  {
    direction: Sort.notSet,
    fields: ['kyc.state', 'verificationStatus'],
    id: UsersTableColumnIds.verification,
  },
  {
    direction: Sort.notSet,
    fields: ['accountOpeningStep'],
    id: UsersTableColumnIds.onBoardingStatus,
  },
  { direction: Sort.notSet, fields: ['phone'], id: UsersTableColumnIds.phone },
  { direction: Sort.notSet, fields: ['role.label'], id: UsersTableColumnIds.role },
  { direction: Sort.desc, fields: ['id'], id: UsersTableColumnIds.id },
  { direction: Sort.notSet, fields: ['issuedAt'], id: UsersTableColumnIds.issuedAt },
  { direction: Sort.notSet, fields: ['umsId'], id: UsersTableColumnIds.umsId },
];

export const usersPageStateInitial = {
  isHasMore: true,
  isUsersLoading: false,
  pageIndex: -1, // TODO стандартизировать значение
  search: null,
  sort: columnSort,
  users: [],
};

export const UsersPageActionsTypes = {
  EVENT_USERS_UPDATED: 'USERS_PAGE.EVENT_USERS_UPDATED',
  LOAD_MORE_USERS: 'USERS_PAGE.LOAD_MORE_USERS',
  LOAD_MORE_USERS_FAIL: 'USERS_PAGE.LOAD_MORE_USERS_FAIL',
  LOAD_MORE_USERS_SUCCESS: 'USERS_PAGE.LOAD_MORE_USERS_SUCCESS',
  ON_PAGE_LEFT: 'USERS_PAGE.ON_PAGE_LEFT',
  ON_PAGE_OPENED: 'USERS_PAGE.ON_PAGE_OPENED',
  ON_SEARCH_FILLED: 'USERS_PAGE.ON_SEARCH_FILLED',
  ON_TABLE_SCROLLED: 'USERS_PAGE.ON_TABLE_SCROLLED',
  ON_TABLE_SORTED: 'USERS_PAGE.ON_TABLE_SORTED',
};
