import React, { useEffect, useRef, useState } from 'react';
import { ButtonType as AntButtonType } from 'antd/lib/button';
import classnames from 'classnames';

export enum ButtonColor {
  danger = 'danger',
  dark = 'dark',
  light = 'light',
  primary = 'primary',
  secondary = 'secondary',
}

export enum ButtonType {
  button = 'button',
  iconOnlyBordered = 'icon-only-bordered',
  iconOnlyPure = 'icon-only-pure',
  text = 'text',
  textIconBig = 'text-icon-big',
  outline = 'outline',
}

export enum ButtonAction {
  click = 'click',
  file = 'file',
}

export const useButtonModel = ({
  action,
  className,
  color,
  disabled,
  htmlType,
  icon,
  loadingForced,
  onClick,
  size,
  type,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null as any); // eslint-disable-line
  const [loading, setLoading] = useState(loadingForced);
  const disabledRef = useRef(disabled);
  disabledRef.current = disabled;

  useEffect(() => {
    if (!disabled) {
      setLoading(loadingForced);
    }
  }, [disabled, loadingForced]);

  const handleLoadingStatus = () =>
    setTimeout(() => {
      if (disabledRef.current && loadingForced !== null) {
        setLoading(true);
      }
    }, 25);

  const onButtonClick = (event) => {
    if (action !== ButtonAction.file) {
      handleLoadingStatus();
      return onClick && onClick(event);
    }
    fileInputRef?.current?.click();
  };

  const onFileSelected = (event) => {
    onClick && onClick(event.target.files[0]);
    fileInputRef.current.value = null as any; // eslint-disable-line
    handleLoadingStatus();
  };

  const FileLogic =
    action !== ButtonAction.file ? null : (
      <input
        type="file"
        hidden={true}
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={onFileSelected}
      />
    );

  const typeClass = classnames({
    'tm2-button-default': type === ButtonType.button,
    'tm2-button-icon-bordered': type === ButtonType.iconOnlyBordered,
    'tm2-button-icon-pure': type === ButtonType.iconOnlyPure,
    'tm2-button-text': type === ButtonType.text,
    'tm2-button-text-icon-big': type === ButtonType.textIconBig,
    'tm2-button-outline': type === ButtonType.outline,
  });

  const colorClass = classnames({
    'tm2-button-danger': color === ButtonColor.danger,
    'tm2-button-dark': color === ButtonColor.dark,
    'tm2-button-light': color === ButtonColor.light,
    'tm2-button-primary': color === ButtonColor.primary,
    'tm2-button-secondary': color === ButtonColor.secondary,
  });

  const sizeClass = classnames({
    'tm2-button-small': size === 'sm',
    'tm2-button-middle': size === 'md',
    'tm2-button-large': size === 'lg',
  });

  const iconClass = !icon
    ? 'tm2-icon-none'
    : icon?.props?.className?.indexOf('tm2-button-arrow') > -1
    ? 'tm2-icon-arrow'
    : 'tm2-icon-other';
  const combinedClass = classnames(className, 'tm2-button', typeClass, colorClass, sizeClass, {
    'is-disabled': disabled,
    'is-no-events': !onClick && htmlType !== 'submit',
  });

  const antType: AntButtonType = (
    type === ButtonType.outline
      ? 'ghost'
      : type === ButtonType.text
      ? 'link'
      : type === ButtonType.iconOnlyBordered
      ? 'text'
      : type === ButtonType.button
      ? 'primary'
      : ''
  ) as AntButtonType;

  return {
    antType,
    combinedClass,
    iconClass,
    loading,
    FileLogic,
    onButtonClick,
  };
};
