import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

const fetchExchangeRateQuery = 'exchangeRate';
const fetchExchangeRateFields = ['rate'];
const fetchExchangeRate = makeQuery({
  queryName: fetchExchangeRateQuery,
  queryFields: fetchExchangeRateFields,
  query: (buyId: number, sellId: number): Promise<string> => {
    const variables = v.exchangeRateRequestInput({
      request: {
        currencyFrom: { id: sellId },
        currencyTo: { id: buyId },
      },
    });
    return stompClient
      .getData<{ rate: string }>(
        fetchExchangeRateQuery,
        sign(fetchExchangeRateQuery, fetchExchangeRateFields),
        variables
      )
      .then((res) => res.rate);
  },
});

const fetchCurrenciesQuery = 'currencies';
const fetchCurrenciesFields = ['id', 'code'];
const fetchCurrencies = makeQuery({
  queryName: fetchCurrenciesQuery,
  queryFields: fetchCurrenciesFields,
  query: (): Promise<
    Array<{
      id: number;
      code: string;
    }>
  > => {
    return stompClient
      .getData(fetchCurrenciesQuery, sign(fetchCurrenciesQuery, fetchCurrenciesFields))
      .then((list) => list.filter(currenciesFilter));
  },
});

type PreparedExchangeQuery = {
  amountToBuy: number;
  amountToSell: number;
  currencyToBuy: { id: number; code: string };
  currencyToSell: { id: number; code: string };
  rate: number;
};

type PreparedExchange = {
  amountToBuy: number;
  amountToSell: number;
  currencyToBuy: { id: number; code: string };
  currencyToSell: { id: number; code: string };
  rate: number;
};

const validateExchangeDataQuery = 'prepareExchange';
const validateExchangeDataFields = [
  'amountToBuy',
  'amountToSell',
  { currencyToBuy: ['id', 'code'] },
  { currencyToSell: ['id', 'code'] },
  'rate',
];
const validateExchangeData = makeQuery({
  queryName: validateExchangeDataQuery,
  queryFields: validateExchangeDataFields,
  query: ({
    amountToSell,
    currencyToBuyId,
    currencyToSellId,
    rate,
  }: {
    amountToSell: number | undefined;
    currencyToBuyId: number | undefined;
    currencyToSellId: number | undefined;
    rate: string | undefined;
  }): Promise<PreparedExchange> => {
    const variables = v.exchangeRequestInput({
      request: {
        amountToSell,
        currencyFrom: { id: currencyToSellId },
        currencyTo: { id: currencyToBuyId },
        rate,
      },
    });
    return stompClient
      .sendData<PreparedExchangeQuery>(
        validateExchangeDataQuery,
        sign(validateExchangeDataQuery, validateExchangeDataFields),
        variables
      )
      .then((data) => ({
        amountToBuy: data.amountToBuy,
        amountToSell: data.amountToSell,
        currencyToBuy: data.currencyToBuy,
        currencyToSell: data.currencyToSell,
        rate: data.rate,
      }));
  },
});

export enum RequestExchangeErrorCode {
  NotEnoughBalance = 'INSUFFICIENT_FUNDS',
  ExchangeRateChanged = 'RATE_HAS_CHANGED',
}

const requestExchangeQuery = 'proceedExchange';
const requestExchangeFields = [
  'amountToBuy',
  'amountToSell',
  { currencyToBuy: ['id', 'code'] },
  { currencyToSell: ['id', 'code'] },
  'rate',
];
const requestExchange = makeQuery({
  queryName: requestExchangeQuery,
  queryFields: requestExchangeFields,
  query: ({
    amountToSell,
    currencyToBuyId,
    currencyToSellId,
    rate,
  }: {
    amountToSell: number | undefined;
    currencyToBuyId: number | undefined;
    currencyToSellId: number | undefined;
    rate: number | undefined;
  }): Promise<PreparedExchange> => {
    const variables = v.exchangeRequestInput({
      request: {
        amountToSell,
        currencyFrom: { id: currencyToSellId },
        currencyTo: { id: currencyToBuyId },
        rate,
      },
    });
    return stompClient
      .sendData<PreparedExchangeQuery>(
        requestExchangeQuery,
        sign(requestExchangeQuery, requestExchangeFields),
        variables
      )
      .then((data) => ({
        amountToBuy: data.amountToBuy,
        amountToSell: data.amountToSell,
        currencyToBuy: data.currencyToBuy,
        currencyToSell: data.currencyToSell,
        rate: data.rate,
      }));
  },
});

const api = {
  fetchExchangeRate,
  fetchCurrencies,
  validateExchangeData,
  requestExchange,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:currency-exchange-modal');

export default api;
