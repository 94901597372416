import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { modalSelectors } from '../store/selectors';
import { ModalInfo } from '../store/model';

export const ModalsStorage: FC = memo(() => {
  const modals = useSelector(modalSelectors.modals);

  return (
    <>
      {modals.map(({ isVisible, modal: ModalComponent, props }: ModalInfo, i: number) => (
        <div key={i} style={{ display: i !== modals.length - 1 ? 'none' : 'block' }}>
          <ModalComponent {...props} isVisible={isVisible} />
        </div>
      ))}
    </>
  );
});
