import React, { FC, memo, ReactElement, ReactNode } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors } from '@config/core';
import { AllowedPermissions, Role } from './core';
import { accessService as access } from './access.service';

export const HasAccess: FC<{
  children: ReactNode;
  extraCheck?: boolean;
  permissions?: AllowedPermissions;
  roles?: Array<Role>;
  userPermissions?: string; // param to check other user permissions (current authorized user permissions will be ignored)
  userRole?: Role; // param to check other user role (current authorized user role will be ignored)
}> = memo(
  ({
    children,
    extraCheck = null,
    permissions: allowedPermissions,
    roles: allowedRoles,
    userPermissions: otherUserPermissions = null,
    userRole: otherUserRole = null,
  }) => {
    const authorizedRole = useSelector(configSelectors.userRole);
    const currentSessionUserPermissions = useSelector(configSelectors.permissions);

    const currentPermissions = otherUserPermissions || currentSessionUserPermissions;
    const currentRole = otherUserRole || authorizedRole;

    if (extraCheck !== null && !extraCheck) {
      return null;
    }

    if (access.isAllowed({ allowedPermissions, allowedRoles, currentPermissions, currentRole })) {
      return children as ReactElement;
    }

    return null;
  }
);
