import { SortItem, SortOrder } from '@models/sorting';
import { SortOrder as TableSortOrder } from '@components/table2/autosized';

export function mapTableSorting<T extends string, Q extends string>(
  tableSortOrder: TableSortOrder,
  keyMap: Partial<Record<T, Q>>
): SortOrder<Q> {
  const mappedTableKeys = Object.keys(keyMap) as T[];

  const mappedOrder: (false | SortItem<Q>)[] = tableSortOrder.map((tableEntry) => {
    const entryKey = tableEntry.key as T | undefined;
    if (!mappedTableKeys.includes(entryKey)) {
      if (process.env.NODE_ENV === 'development') {
        throw new Error(`mapTableSorting: received unmapped sort key: ${entryKey}`);
      }
      return false;
    }
    const mappedEntry: SortItem<Q> = {
      field: keyMap[entryKey],
      direction: tableEntry.direction === 'ascend' ? 'asc' : 'desc',
    };
    return mappedEntry;
  });
  return mappedOrder.filter(Boolean) as SortOrder<Q>;
}

export type SorterBaseType = Record<string, string>;
export type SortValues<T extends SorterBaseType> = T[keyof T];
export type Sorting<T extends SorterBaseType> = Record<SortValues<T>, SortValues<T>>;
export function TableSortCreator<T extends SorterBaseType>(
  columnKeys: T,
  config?: {
    exclude?: Array<SortValues<T>>;
    override?: never;
  }
): Sorting<T> {
  const exclude: Array<T[keyof T]> = config?.exclude || [];
  // TODO-2833: fix types
  // @ts-ignore
  return Object.values<SortValues<T>>(columnKeys).reduce((acc: Sorting<T>, value: T[keyof T]) => {
    if (exclude.includes(value)) {
      return acc;
    }
    return {
      ...acc,
      [value]: value, // TODO config.override
    };
  }, {}) as Sorting<T>;
}
