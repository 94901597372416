import React, { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Form as AntForm } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { UploadedFile } from '@models/core';
import { handleBackendError } from '@modules/notify';
import { sharedApi } from '@services/api';
import { ReactComponent as CrossIcon } from '@images/icons/cross-icon.svg';
import { ReactComponent as UploadIcon } from '@images/icons/upload-icon.svg';
import { Label } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

/**
 * This component is designed not for choose file. This component automatically upload file and set id in form
 */
export const FileUploader: FC<{
  className?: string;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  name: NamePath;
  disabled?: boolean;
  placeholder: ReactNode;
  required?: boolean;
  uat?: string;
}> = ({
  className,
  disabled,
  fieldKey,
  label,
  name,
  placeholder: placeholderInitial,
  required,
  uat,
}) => {
  const uatAttribute = useFieldUat(name, 'file-uploader', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-file-uploader', className)}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} fieldKey={fieldKey} required={required}>
        <CustomFileUploader
          disabled={disabled}
          label={label}
          name={name}
          placeholderInitial={placeholderInitial}
          required={required}
        />
      </Item>
    </div>
  );
};

const CustomFileUploader: FC<{
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (id: number) => void;
  placeholderInitial: ReactNode;
  required: boolean;
}> = (props) => {
  const [lockUI, setLockUI] = useState(props.disabled);
  const [placeholder, setPlaceholder] = useState(props.placeholderInitial);
  const [isFileSelected, setFileSelected] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setLockUI(props.disabled);
  }, [props.disabled]);

  const onFileSelected = async (event) => {
    const target = event.target;
    if (!target.files.length || target.files.length > 1) {
      return;
    }
    setLockUI(true);
    try {
      const file: UploadedFile = await sharedApi.uploadFile(target);
      setPlaceholder(target.files[0].name);
      props.onChange(file.id);
      setFileSelected(true);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  const onFileRemoved = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setPlaceholder(props.placeholderInitial);
    setFileSelected(false);
    inputRef.current.value = null;
    props.onChange(null);
  };

  return (
    <>
      <Label htmlFor={props.name} label={props.label} shifted={true} required={props.required} />

      <label
        className={classnames('tm2-form-field-item-instance tm2-field-file-uploader-label', {
          'tm2-field-file-uploader-disabled': lockUI,
        })}
      >
        <div
          className={classnames('tm2-field-file-uploader-label-placeholder', {
            'tm2-field-file-uploader-label-placeholder-selected': isFileSelected,
          })}
        >
          {placeholder}
        </div>

        <div className="tm2-field-file-uploader-label-icon ml-auto">
          {isFileSelected ? (
            <CrossIcon className="tm2-field-file-uploader-label-icon-svg" onClick={onFileRemoved} />
          ) : (
            <UploadIcon className="tm2-field-file-uploader-label-icon-svg" />
          )}
        </div>
        <input
          type="file"
          ref={inputRef}
          onChange={onFileSelected}
          disabled={lockUI}
          hidden={true}
        />
      </label>
    </>
  );
};
