import sign from 'tm2sign.macro';

import { Any } from '@models/core';
import { loggerService as logger } from '@services/logger';
import { stompClientService as stompClient } from '@services/stomp/client';
import { ValidationError } from '@services/stomp/errors';
import { Validators } from '@helper/form/form.helper';
import { v } from '@helper/typer/field-typer.helper';

const emailValidationErrorLogger = (error) => {
  // the email on the hubspot forms is hidden and comes from our backend, validation in order to catch an error
  // when the hubspot does not receive an email or email is not correct for hubspot
  if (
    error instanceof ValidationError &&
    error?.data?.length === 1 &&
    error?.data[0]?.field === 'email'
  ) {
    logger.error('Post registration failed with not allowed email', {
      location: 'src/containers/pages/post-registration/services.ts:emailValidationErrorLogger',
      rawError: error,
    });
  }
  return Promise.reject(error);
};
//-----------------------------------------------------------------
const getHubspotForm = (
  operation:
    | 'provideAddressInformationFormStructure'
    | 'companyDetailsFormStructure'
    | 'regulatedOrListedStatusFormStructure'
): Promise<Any> => {
  let formFields;

  if (operation === 'companyDetailsFormStructure') {
    formFields = sign('companyDetailsFormStructure', ['value']);
  }

  if (operation === 'regulatedOrListedStatusFormStructure') {
    formFields = sign('regulatedOrListedStatusFormStructure', ['value']);
  }

  if (operation === 'provideAddressInformationFormStructure') {
    formFields = sign('provideAddressInformationFormStructure', ['value']);
  }

  return stompClient
    .getData(operation, formFields)
    .then((response) => JSON.parse(response.value))
    .then((response) => {
      const formProgress = [];
      const formValidation = [];
      const autoFillInfo = [];
      const existedFields = {};
      let currentFormId = null;

      const formRows =
        response.formFieldGroups?.map((item) => {
          if (!item.richText?.content) {
            item.fields.forEach((field) => {
              existedFields[field.name] = true;
              formValidation.push({
                fieldName: field.name,
                fieldType: field.fieldType,
                formId: currentFormId,
                validators: field.required ? [Validators.required] : [],
              });

              if (field.defaultValue) {
                autoFillInfo.push({
                  // хабспот админка не может проставить не корректное дефолтное значение
                  // в имеил, не пройдет валидация, ниже правка конкретно для имейлов
                  defaultValue: field.defaultValue?.replace('@autofill.com', ''),
                  fieldName: field.name,
                });
              }
              if (field.fieldType === 'select' && field.unselectedLabel) {
                // hubspot doesn't have defaultValue field for select elements
                autoFillInfo.push({
                  defaultValue: field.unselectedLabel,
                  fieldName: field.name,
                });
              }
            });
            return item;
          }

          const textContent = document.createElement('div');
          textContent.innerHTML = item.richText.content;
          const isFormHeader = textContent.querySelector('h2');
          const headerText = isFormHeader?.textContent;

          if (isFormHeader) {
            const formId = (currentFormId =
              'tm-' +
              headerText
                .replace(/[0-9\W_]+/g, ' ')
                .trim()
                .replace(/\s\s+/g, ' ')
                .replace(/ /g, '-'));

            formProgress.push({
              anchor: `#${currentFormId}`,
              isActive: false,
              id: currentFormId,
              isFailed: false,
              isFinished: false,
              isValidationIgnored: true,
              text: headerText,
            });

            return {
              ...item,
              elementId: formId,
              isFormHeader: true,
            };
          }
          return item;
        }) || [];

      const formInitial = formRows.reduce((acc, next) => {
        if (next)
          next.fields.forEach(
            (field) => field.currentValue && (acc[field.name] = field.currentValue)
          );
        return acc;
      }, {});

      return { autoFillInfo, existedFields, formProgress, formRows, formInitial };
    });
};
//-----------------------------------------------------------------
const sendAddressInfoForm = (form, Fields) => {
  const fields = Object.values(Fields);

  const structuredForm = { userFields: {}, hubspotFields: {} };

  Object.entries(form).forEach((item) => {
    const [key, value] = item;

    if (!!fields.some((i) => i === key)) {
      structuredForm.userFields[key] = value;
      if (key.startsWith('country') && !!value) {
        // TODO костыль, без бекенда не поправить
        structuredForm.userFields[key] = { id: value };
      }
    } else {
      structuredForm.hubspotFields[key] = value;
    }
  });

  const typedValues = {
    ...v.mapStringObjectScalar({
      hubspotFields: structuredForm.hubspotFields,
    }),
    ...v.provideAddressInformationInput({
      userFields: structuredForm.userFields,
    }),
  };

  return stompClient
    .sendData(
      'sendProvideAddressInformation',
      sign('sendProvideAddressInformation', ['value']),
      typedValues
    )
    .catch(emailValidationErrorLogger);
};
//-----------------------------------------------------------------
const sendCompanyDetailsForm = (form, Fields) => {
  const fields = Object.values(Fields);
  const structuredForm = { userFields: {}, hubspotFields: {} };

  Object.entries(form).forEach((item) => {
    const [key, value] = item;

    if (!!fields.some((i) => i === key)) {
      structuredForm.userFields[key] = value;
      if (key.startsWith('country') && !!value) {
        // TODO костыль, без бекенда не поправить
        structuredForm.userFields[key] = { id: value };
      }
    } else {
      structuredForm.hubspotFields[key] = value;
    }
  });

  const typedValues = {
    ...v.mapStringObjectScalar({
      hubspotFields: structuredForm.hubspotFields,
    }),
    ...v.companyDetailsInput({
      userFields: structuredForm.userFields,
    }),
  };

  return stompClient
    .sendData('sendCompanyDetails', sign('sendCompanyDetails', ['value']), typedValues)
    .catch(emailValidationErrorLogger);
};
//-----------------------------------------------------------------
const sendContactDetailsForm = (form) => {
  const structuredForm = { userFields: {} };

  Object.entries(form).forEach((item) => {
    const [key, value] = item;
    structuredForm.userFields[key] = value;
  });

  const typedValues = {
    ...v.contactDetailsInput({
      userFields: structuredForm.userFields,
    }),
  };

  return stompClient.sendData(
    'sendContactDetails',
    sign('sendContactDetails', ['value']),
    typedValues
  );
};
//-----------------------------------------------------------------
const sendContactInfoForm = (form, Fields) => {
  const structuredForm = { userFields: {} };

  Object.entries(form).forEach((item) => {
    const [key, value] = item;
    structuredForm.userFields[key] = value;
    if (key.startsWith(Fields.nationality) && !!value) {
      structuredForm.userFields[key] = { id: value };
    }
  });

  const typedValues = {
    ...v.provideContactInformationInput({
      userFields: structuredForm.userFields,
    }),
  };

  return stompClient.sendData(
    'sendProvideContactInformation',
    sign('sendProvideContactInformation', ['value']),
    typedValues
  );
};
//-----------------------------------------------------------------
const sendRegulatedOrListedStatusForm = (form) => {
  const structuredForm = { hubspotFields: {} };

  Object.entries(form).forEach((item) => {
    const [key, value] = item;
    structuredForm.hubspotFields[key] = value;
  });

  const typedValues = {
    ...v.mapStringObjectScalar({
      hubspotFields: structuredForm.hubspotFields,
    }),
  };

  return stompClient
    .sendData(
      'sendRegulatedOrListedStatus',
      sign('sendRegulatedOrListedStatus', ['value']),
      typedValues
    )
    .catch(emailValidationErrorLogger);
};
//-----------------------------------------------------------------
export default {
  getHubspotForm,
  sendAddressInfoForm,
  sendCompanyDetailsForm,
  sendContactDetailsForm,
  sendContactInfoForm,
  sendRegulatedOrListedStatusForm,
};
