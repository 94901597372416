import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form as AntForm, InputNumber as AntInputNumber } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { Any } from '@models/core';
import { maxDecimals as decimalsDefault } from '@constants/math';
import { Label, useLabel } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const InputNumber: FC<{
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  extra?: ReactNode;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  min?: number;
  max?: number;
  maxDecimals?: number;
  name: NamePath;
  onChange?: (v) => void;
  required?: boolean;
  uat?: string;
}> = ({
  className,
  compact,
  disabled,
  extra,
  fieldKey,
  label,
  min,
  max,
  maxDecimals = decimalsDefault,
  name,
  onChange,
  required,
  uat,
  ...props
}) => {
  const uatAttribute = useFieldUat(name, 'input-number', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-number', className, {
        'tm2-form-field-compact': compact,
        'tm2-form-field-extra': !!extra,
      })}
      data-uat={uatAttribute}
    >
      <Item
        className="tm2-form-field-item"
        name={name}
        fieldKey={fieldKey}
        required={required}
        extra={extra}
        {...props}
      >
        <CustomNumber
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          max={max}
          maxDecimals={maxDecimals}
          min={min}
          onCustomChange={onChange}
        />
      </Item>
    </div>
  );
};

const CustomNumber: FC<{
  disabled: boolean;
  id?: string; // This field is set by Item via copying props.
  label: ReactNode;
  max?: number;
  maxDecimals?: number;
  min?: number;
  name: NamePath;
  onChange?: (v: string) => void;
  onCustomChange: (v) => void;
  required: boolean;
  value?: number;
}> = (props) => {
  const { element, shifted } = useLabel({ value: props.value });

  return (
    <>
      <Label htmlFor={props.id} shifted={shifted} label={props.label} required={props.required} />
      <AntInputNumber
        className="tm2-form-field-item-instance"
        ref={element}
        id={props.id}
        value={props.value as Any}
        onChange={(value) => {
          props.onChange(value);
          props.onCustomChange && props.onCustomChange(value);
        }}
        parser={(raw: string) => {
          if (!raw) {
            return '';
          }
          // some users separate thousands with a comma char
          const v = raw.replace(/,/g, '');

          const numbersFind: Array<string> = v.match(/[-?\d|.|\+]+/g);
          if (!numbersFind) {
            return '';
          }

          const numberStr: string = numbersFind[0];
          const numberRaw: number = +numberStr;

          if ((props.min || props.min === 0) && numberRaw < props.min) {
            element.current && element.current.blur();
            element.current && element.current.focus();
            return props.min.toString();
          }

          if ((props.max || props.max === 0) && numberRaw > props.max) {
            element.current && element.current.blur();
            element.current && element.current.focus();
            return props.max.toString();
          }

          const [float, decimals] = numberStr.split('.');
          if (decimals?.length && decimals.length > props.maxDecimals) {
            element.current && element.current.blur();
            element.current && element.current.focus();
            return `${float}.${decimals.slice(0, props.maxDecimals)}`;
          }

          return numberRaw.toString();
        }}
        disabled={props.disabled}
      />
    </>
  );
};
