import { useEffect } from 'react';
import { useParams } from 'react-router';

import { useQuery } from '@hooks/core';
import api from './api';

export const useProfileOther = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: profile,
    isLoading: isProfileLoading,
    refresh: refreshProfile,
  } = useQuery(() => api.getOtherProfile(+userId));

  useEffect(() => {
    if (!isProfileLoading) {
      refreshProfile();
    }
  }, [userId]);

  return {
    isProfileLoading,
    profile,
    refreshProfile,
  };
};
