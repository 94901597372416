import React, { FC, memo } from 'react';

import { PermissionGroup } from '@permissions/core';
import { MoreActions } from '@components/more-actions';
import { permissionsGroup as editUserPermissionsGroup } from '../../../../profile/other/modals/handle-user-modal';
import { UserTableItem } from '../../../users-api.service';
import { permissionsGroup as verificationPermissionGroup } from './verification/api';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [editUserPermissionsGroup, verificationPermissionGroup],
  marker: 'layout:users-page-actions-cell',
});

export const UserActionsCell: FC<{ user: UserTableItem }> = memo(({ user }) => {
  const m = useModel(user);

  return <MoreActions placement="right" disabled={m.lockUI} list={m.actions} />;
});
