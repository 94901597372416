import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { useBankAccountTypeTranslations } from '../../../../hooks';
import { CMBankAccount } from '../api';

// TODO добавить анимацию сворачивания, разворачивания

export const AccountInfo: FC<{
  account: CMBankAccount;
}> = (p) => {
  const accountTypeTranslations = useBankAccountTypeTranslations();

  return !p.account ? null : (
    <div className={s.row}>
      <InfoItem
        className="mb3"
        label={<Trans id="cm_transaction_modal.info.label.entity">Legal entity</Trans>}
        value={p.account.legalEntity}
      />

      <InfoItem
        className="mb3"
        label={<Trans id="cm_transaction_modal.info.label.bank">Bank name</Trans>}
        value={p.account.bank?.bankName}
      />

      <InfoItem
        className="mb3"
        label={<Trans id="cm_transaction_modal.info.label.acc_number">Account number</Trans>}
        value={p.account.accountNumber}
      />

      <InfoItem
        className="mb3"
        label={<Trans id="cm_transaction_modal.info.label.acc_type">Account type</Trans>}
        value={accountTypeTranslations[p.account.type]}
      />

      <InfoItem
        className="mb3"
        label={<Trans id="cm_transaction_modal.info.label.currency">Currency</Trans>}
        value={p.account.currency?.code}
      />

      <InfoItem
        className="mb3"
        label={<Trans id="cm_transaction_modal.info.label.balance_current">Balance in banks</Trans>}
        value={p.account.currentBalance}
      />

      <InfoItem
        className="mb3"
        label={
          <Trans id="cm_transaction_modal.info.label.balance_calculated">Calculated balance</Trans>
        }
        value={p.account.computedFields?.calculatedBalance}
      />
    </div>
  );
};

export const InfoItem: FC<{
  className?: string;
  label: ReactNode;
  value: ReactNode;
}> = (p) =>
  !p.value && p.value !== 0 ? null : (
    <div className={classNames(s.infoField, p.className)}>
      <Title level={3}>{p.label}</Title>
      <Text level={2}>{p.value}</Text>
    </div>
  );
