import './styles.scss';

export { CheckboxSingle } from './checkbox-single';
export { CheckboxGroup } from './checkbox-group';
export { FileUploader } from './file-uploader';
export { Form } from './form-instance';
export { AddRowButton, DeleteRowButton, FormList } from './form-list';
export { InputHidden } from './input-hidden';
export { InputDate } from './input-date';
export { InputDateRange } from './input-date-range';
export { InputDateSimple } from './input-date-simple';
export { InputFile } from './input-file';
export { InputNumber } from './input-number';
export { InputNumberRange } from './input-number-range';
export { InputPassword } from './input-password';
export { InputPhone } from './input-phone';
export { InputText } from './input-text';
export { InputTextarea } from './input-textarea';
export { Radio } from './radio';
export { RadioGroup } from './radio-group';
export { RoledSelect } from './select-roled';
export { Select } from './select';
export { SelectBoolean } from './select-boolean';
export { SubmitButton } from './submit-button';
export { Switch } from './switch';

// TODO:93 не вся бекенд валидация готова, в некоторых запросах нужно чистить пустые поля,
//      например, в saveUserByAdmin
export function clearEmptyFields<T = object>(form: T): T {
  // TODO-2833: fix types
  // @ts-ignore
  return Object.fromEntries<T>(
    Object.entries<T>(form)
      .filter(([k, value]) => value !== null && value !== undefined)
      .map(([key, value]) => {
        if (value instanceof Array) {
          return [key, value.map((item) => clearEmptyFields(item))];
        } else if (value instanceof Object) {
          return [key, clearEmptyFields(form[key])];
        }
        return [key, value];
      })
      .filter(([k, value]) => {
        if (value instanceof Array) {
          return !!value.length;
        } else if (value instanceof Object) {
          return !!Object.keys(value).length;
        }
        return value !== null && value !== undefined;
      })
  ) as T;
}
