import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';
import { accessService as access, Permission as P, PermissionGroup } from '@permissions/core';
import { formatMoney } from 'containers/services/commonService';
import { ColumnType, Table } from '@components/table2/simplified';
import { ProfileLinkCell, TablePane } from '@components/table2/utils';
import { tableRowCfgDefault } from '@components/table2/config/table-simplified';
import { ActionsRow } from './actions-row';
import { CorrectionTransaction } from './add-correction-transaction-modal/api';
import { ColumnKey, useModel } from './model';
import { CTStatusCell } from './status-cell';
import s from './index.module.scss';
import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as actionsRowPermissionGroup } from './actions-row';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, actionsRowPermissionGroup],
  marker: 'layout:correction-transactions-page',
});

export const IssuersTab: FC = memo(() => {
  const m = useModel();

  const columns: ColumnType<CorrectionTransaction>[] = [
    {
      key: ColumnKey.createdAt,
      dataIndex: 'createdAt',
      headCell: () => <Trans id="correction.transactions.datetime_v2">Date & time</Trans>,
      rowCell: ({ data }) => <>{data.createdAt?.formatLocalDateTime()}</>,
      width: 200,
    },
    {
      key: ColumnKey.sender,
      dataIndex: 'sender',
      headCell: () => <Trans id="correction.transactions.sender">Sender</Trans>,
      rowCell: ({ data }) =>
        access.isAllowed({
          allowedPermissions: { or: [P.TRANSACTION_CREATE_CORRECTION] },
          currentPermissions: data.sender.permissions,
        }) ? (
          <>TM2</>
        ) : (
          <ProfileLinkCell text={data.sender.displayName} id={data.sender.id} />
        ),
      width: 200,
    },
    {
      key: ColumnKey.recipient,
      dataIndex: 'recipient',
      headCell: () => <Trans id="correction.transactions.recipient">Recipient</Trans>,
      rowCell: ({ data }) =>
        access.isAllowed({
          allowedPermissions: { or: [P.TRANSACTION_CREATE_CORRECTION] },
          currentPermissions: data.recipient.permissions,
        }) ? (
          <>TM2</>
        ) : (
          <ProfileLinkCell text={data.recipient.displayName} id={data.recipient.id} />
        ),
      width: 200,
    },
    {
      key: ColumnKey.amount,
      dataIndex: 'amount',
      headCell: () => <Trans id="correction.transactions.amount">Amount</Trans>,
      rowCell: ({ data }) => (
        <>
          {formatMoney(data.amount)} {data.currency.code}
        </>
      ),
      width: 200,
    },
    {
      key: ColumnKey.status,
      dataIndex: 'status',
      headCell: () => <Trans id="correction.transactions.status">Status</Trans>,
      rowCell: ({ data }) => <CTStatusCell status={data.status} />,
      width: 200,
    },
  ];

  return (
    <div className={s.verticalFiller}>
      <ActionsRow
        replaceTransaction={m.onTransactionPlaced}
        onFiltersChanged={m.onFiltersChanged}
      />

      <TablePane className={s.verticalFiller}>
        <Table
          columns={columns}
          instance={m.tableInstance}
          rowConfig={tableRowCfgDefault}
          className={s.verticalFiller}
        />
      </TablePane>
    </div>
  );
});
