import { useList } from 'containers/services/commonService';
import { v } from '@helper/typer/field-typer.helper';
import {
  approveBidOrderMutation,
  bidOrdersCoinsQuery,
  offersCoinsQuery,
  denyBidOrderMutation,
} from 'containers/services/graphqlFacade';
import gqlFields from './legacy-fields';

const { offersCoinsFields, bidOrdersCoinsFields, approveBidOrderFields, denyBidOrderFields } =
  gqlFields;

export const useOffersCoins = (selectMode) =>
  useList(
    (wsInstance) => offersCoinsQuery(wsInstance, offersCoinsFields, v.string({ selectMode })),
    []
  );
export const useBidOrdersCoins = () =>
  useList((wsInstance) => bidOrdersCoinsQuery(wsInstance, bidOrdersCoinsFields), []);
export const approveBidOrder = async (wsInstance, bidOrderId, bankTxId) =>
  approveBidOrderMutation(wsInstance, bidOrderId, bankTxId, approveBidOrderFields);
export const denyBidOrder = async (wsInstance, bidOrderId, reason) =>
  denyBidOrderMutation(wsInstance, bidOrderId, reason, denyBidOrderFields);
