import React from 'react';
import { Trans } from '@lingui/macro';

export const StateCell = ({ state }) => {
  const adapter = {
    COMPLETED: <Trans id="transfer_requests_page.table.cell.state.completed">Completed</Trans>,
    CREATED: <Trans id="transfer_requests_page.table.cell.state.created">Created</Trans>,
    ERROR: <Trans id="transfer_requests_page.table.cell.state.error">Error</Trans>,
    IN_PROGRESS: (
      <Trans id="transfer_requests_page.table.cell.state.in_progress">In progress</Trans>
    ),
  };

  return adapter[state];
};
