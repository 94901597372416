import { useContext, useMemo } from 'react';

import { SelectItem } from '@components/form';
import { Context } from '../../context';

export { UpdateUserFields as Fields } from '../../api';

export const useModel = () => {
  const { state: s } = useContext(Context);

  const issuers: Array<SelectItem> = useMemo(
    () =>
      s.issuers.map((i) => ({
        // note: не нужно разблокировать своего выбранного issuer
        // т.к. если он уже выбран, то поле задисаблено
        disabled: !!i.issuerInvestor?.id,
        label: i.displayName,
        value: i.id,
      })),
    [s.issuers]
  );

  const disabled: boolean = !!s.profileInitial?.investorIssuer?.id;

  return {
    disabled,
    issuers,
  };
};
