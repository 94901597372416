import { useCallback, useState } from 'react';
import { createContainer } from 'react-tracked';

import { mutate } from 'containers/services/commonService';
import { MyStableBalance, OfferPrice, TickerItem } from './metals-api.service';

export type StateData<T = unknown> = [T?, boolean?, Function?];

interface BuyMetalsState {
  selectedPair: TickerItem;
  isTickerLoading: boolean;
  activeOfferPrices: StateData<Array<OfferPrice>>;
  stableBalance: StateData<Array<MyStableBalance>>;
}

const state: BuyMetalsState = {
  selectedPair: undefined,
  isTickerLoading: false,
  activeOfferPrices: [],
  stableBalance: [],
};

const {
  Provider: PrimaryMarketDetailedProvider,
  useUpdate,
  useSelector,
} = createContainer(() => useState(state));

const useOnPairSelected = () => useUpdater((draft, value) => (draft.selectedPair = value));
const useSelectedPair = () => useSelector((it) => it.selectedPair);

const useSetActiveOfferPrices = () =>
  useUpdater((draft, value) => (draft.activeOfferPrices = value));
const useActiveOfferPrices = () => useSelector((it) => it.activeOfferPrices);

const useSetStableBalance = () => useUpdater((draft, value) => (draft.stableBalance = value));
const useStableBalance = () => useSelector((it) => it.stableBalance);

const useIsTickerLoading = () => useSelector((it) => it.isTickerLoading);
const useOnTickerLoading = () => useUpdater((draft, value) => (draft.isTickerLoading = value));

const useIsApprovedBidOrdersLoading = () => useSelector((it) => it.isTickerLoading);
const useOnApprovedBidOrdersLoading = () =>
  useUpdater((draft, value) => (draft.isTickerLoading = value));

const useUpdater = (updater) => {
  const update = useUpdate();
  return useCallback((value) => mutate(update, (draft) => updater(draft, value)), [update]);
};

export {
  PrimaryMarketDetailedProvider,
  useSelectedPair,
  useActiveOfferPrices,
  useSetActiveOfferPrices,
  useStableBalance,
  useSetStableBalance,
  useOnPairSelected,
  useIsTickerLoading,
  useOnTickerLoading,
  useIsApprovedBidOrdersLoading,
  useOnApprovedBidOrdersLoading,
};
