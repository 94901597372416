import sign from 'tm2sign.macro';
import { userForDisplayFields } from 'utils/gql/common-fields';

export default {
  tickerFields: sign('ticker', [
    'metal',
    {
      coin: [
        'id',
        'managementFee',
        { info: ['active'] },
        { vault: ['city', 'address'] },
        'asset',
        'technicalSpecification',
        'name',
        'brandName',
        'productInfo',
        'sellCommission',
        { infoFiles: ['title', { file: ['id', 'name'] }] },
        { issuer: userForDisplayFields },
        {
          coinInfo: [
            'unitAndDenomination',
            'physicalRedemption',
            'primaryMarketProductInfo',
            'secondaryMarketFee',
          ],
        },
      ],
    },
    { currency: ['id', 'code', 'order'] },
    'minPrice',
    'lastTradePrice',
  ]),
  activeOfferPricesFields: sign('activeOffersPrices', [
    'id',
    'quantityPerGram',
    {
      offer: [
        'id',
        'date',
        'availableQuantity',
        { quantityUnit: ['inGrams'] },
        'sellCommission',
        'issuerTax',
      ],
    },
  ]),
  coinBalanceFields: sign('coinBalance', [
    'balance',
    'asset',
    { coin: ['hasActiveRedeem', 'id', 'asset', { metal: ['label'] }, 'brandName'] },
  ]),
  stableBalanceFields: sign('stableBalance', ['asset', 'balance', { currency: ['id', 'code'] }]),
  bidOrdersPageFields: sign('bidOrdersPage', [
    { data: ['id', 'status', 'quantity', { offerPrice: ['quantityPerGram'] }, 'dateUpdate'] },
    'index',
    'hasMore',
  ]),
  weekDailyPriceFields: sign('weekDailyPrice', ['price', 'date']),
  basicSMFeeFields: sign('basicSecondaryMarketFee', ['value']),
};
