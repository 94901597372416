import { ReduxAction } from '@models/redux';
import { BeneficialOwner } from '../beneficial-api.service';
import { BeneficialOwnersActionsTypes as T } from './model';

class OnBeneficialOwnersTableScrolledAction extends ReduxAction<
  T.ON_BENEFICIAL_OWNERS_TABLE_SCROLLED,
  void
> {
  public readonly type: T.ON_BENEFICIAL_OWNERS_TABLE_SCROLLED =
    T.ON_BENEFICIAL_OWNERS_TABLE_SCROLLED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnDigitalMetalSelectedAction extends ReduxAction<T.ON_DIGITAL_METAL_SELECTED, string> {
  public readonly type: T.ON_DIGITAL_METAL_SELECTED = T.ON_DIGITAL_METAL_SELECTED;
  public constructor(public payload: string) {
    super(payload);
  }
}

class OnDigitalMetalSortClickedAction extends ReduxAction<T.ON_DIGITAL_METAL_SORT_CLICKED, void> {
  public readonly type: T.ON_DIGITAL_METAL_SORT_CLICKED = T.ON_DIGITAL_METAL_SORT_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnInvestorSelectedAction extends ReduxAction<T.ON_INVESTOR_SELECTED, number> {
  public readonly type: T.ON_INVESTOR_SELECTED = T.ON_INVESTOR_SELECTED;
  public constructor(public payload: number) {
    super(payload);
  }
}

class OnPageLeftAction extends ReduxAction<T.ON_PAGE_LEFT, void> {
  public readonly type: T.ON_PAGE_LEFT = T.ON_PAGE_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnPageOpenedAction extends ReduxAction<T.ON_PAGE_OPENED, void> {
  public readonly type: T.ON_PAGE_OPENED = T.ON_PAGE_OPENED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnToggleBlockOwnerButtonClickedAction extends ReduxAction<
  T.ON_TOGGLE_BLOCK_OWNER_BUTTON_CLICKED,
  BeneficialOwner
> {
  public readonly type: T.ON_TOGGLE_BLOCK_OWNER_BUTTON_CLICKED =
    T.ON_TOGGLE_BLOCK_OWNER_BUTTON_CLICKED;
  public constructor(public payload: BeneficialOwner) {
    super(payload);
  }
}

export const beneficialOwnersUserActions = {
  onBeneficialOwnersTableScrolled: () => new OnBeneficialOwnersTableScrolledAction().get(),
  onDigitalMetalSelected: (name: string) => new OnDigitalMetalSelectedAction(name).get(),
  onDigitalMetalSortClicked: () => new OnDigitalMetalSortClickedAction().get(),
  onInvestorSelected: (id: number) => new OnInvestorSelectedAction(id).get(),
  onPageLeft: () => new OnPageLeftAction().get(),
  onPageOpened: () => new OnPageOpenedAction().get(),
  onToggleBlockOwnerButtonClicked: (owner: BeneficialOwner) =>
    new OnToggleBlockOwnerButtonClickedAction(owner).get(),
};

export type BeneficialOwnersUserActionsTypes =
  | OnBeneficialOwnersTableScrolledAction
  | OnDigitalMetalSelectedAction
  | OnDigitalMetalSortClickedAction
  | OnInvestorSelectedAction
  | OnPageLeftAction
  | OnPageOpenedAction
  | OnToggleBlockOwnerButtonClickedAction;

export declare namespace BeneficialOwnersUserActions {
  type onBeneficialOwnersTableScrolled = OnBeneficialOwnersTableScrolledAction;
  type onDigitalMetalSelected = OnDigitalMetalSelectedAction;
  type onDigitalMetalSortClicked = OnDigitalMetalSortClickedAction;
  type onInvestorSelected = OnInvestorSelectedAction;
  type onPageLeft = OnPageLeftAction;
  type onPageOpened = OnPageOpenedAction;
  type onToggleBlockOwnerButtonClicked = OnToggleBlockOwnerButtonClickedAction;
}
