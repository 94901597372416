import { SortItem } from '@models/core';
import { RootState } from '@models/redux';
import { TableHeaderSortType } from 'components/page-table';
import { FeeReportItem } from '../api';
import { State, ColumnIds } from './model';

const s = (r: RootState): State => r.main?.statements?.managementFees;

const columnSortDirection = (r: RootState) => (columnId: ColumnIds) => {
  return s(r).sort.filter((col) => col.id === columnId)[0].direction;
};

const isHasMore = (r: RootState): boolean => s(r).isHasMore;

const isPaymentsLoading = (r: RootState): boolean => s(r).isPaymentsLoading;

const lockUI = (r: RootState): boolean => s(r).isPaymentsLoading;

const pageIndex = (r: RootState): number => s(r).pageIndex;

const sort = (r: RootState): Array<SortItem<ColumnIds>> =>
  s(r).sort.filter(
    (_sort) =>
      _sort.direction === TableHeaderSortType.asc || _sort.direction === TableHeaderSortType.desc
  );

const tableData = (r): Array<FeeReportItem> => s(r).tableData;

export default {
  columnSortDirection,
  isHasMore,
  isPaymentsLoading,
  lockUI,
  pageIndex,
  sort,
  tableData,
};
