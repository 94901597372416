import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { ButtonDownload } from '@components/button/download';
import { Box } from '@components/box';
import { HasAccess, PermissionGroup, traderPermissions } from '@permissions/core';
import { VerificationStatus as S } from '@models/core';
import { Divider } from '@components/divider';
import { ProfileOther } from '../../api';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup],
  marker: 'layout:profile-other-page-documents',
});

const accessMyPermissions = { and: [permissionsGroup] };
const accessUserPermissions = { or: traderPermissions };
export const VerificationDocuments: FC<{
  className: string;
  profile: ProfileOther;
}> = (p) => (
  <HasAccess
    permissions={accessMyPermissions}
    extraCheck={
      p.profile?.verificationStatus && p.profile?.verificationStatus !== S.documentRequested
    }
  >
    <HasAccess userPermissions={p.profile.permissions} permissions={accessUserPermissions}>
      <VerificationDocumentsComponent className={p.className} />
    </HasAccess>
  </HasAccess>
);

const VerificationDocumentsComponent: FC<{
  className: string;
}> = memo(({ className }) => {
  const m = useModel();

  return (
    <Box className={className}>
      <Title level={2}>
        <Trans id="profile_other.documents.title">Documents</Trans>
      </Title>

      <Divider className="mb3" />

      <div className={classnames(s.row, 'mb2')}>
        <Text className="mr2" level={2}>
          <Trans id="profile_other.documents.text.download_bill">Utility bill</Trans>
        </Text>
        <ButtonDownload
          className="mr-md"
          disabled={m.isBillLoading}
          onClick={m.onDownloadBillClicked}
        />
      </div>

      <div className={s.row}>
        <Text className="mr2" level={2}>
          <Trans id="profile_other.documents.text.download_passport">Passport</Trans>
        </Text>
        <ButtonDownload
          className="mr-md"
          disabled={m.isPassportLoading}
          onClick={m.onDownloadPassportClicked}
        />
      </div>
    </Box>
  );
});
