import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from 'bootstrap';
import { loggerService as logger } from '@services/logger';
import { AppMeta } from '@models/core';
import { session } from '@services/session';
import { uuidv4 } from './containers/services/asyncService';
import './polyfills';

// данная логика необходима, чтобы сбрасывать кеш при каждом релизе,
// т.к. браузеры не всегда делают это корректно самостоятельно
// также сперва мы инициализируем логгер и только потом само приложение, чтобы отлавливать ошибки в начальный момент
// также serviceWorker должен быть выключен, т.к. он периодически сбоит с кешем
logger.init();
logger.log('Before app: application opened');
fetch(`/meta.json?v=${uuidv4()}`, { cache: 'no-cache' })
  .then((response) => response.json())
  .then((meta: AppMeta) => {
    if (!meta?.buildVersion) {
      logger.log('Before app: bad application meta info', { meta });
      throw new Error('Bad application meta info');
    } else if (meta.buildVersion === session.buildVersion) {
      ReactDOM.render(<App />, document.getElementById('root'));
      logger.log('Before app: application rendered');
    } else {
      logger.log('Before app: old version, start clear cache');
      session.saveBuildVersion(meta.buildVersion);
      return Promise.resolve()
        .then(() => {
          if ('caches' in window) {
            return window.caches
              .keys()
              .then((names: Array<string>) => {
                return names.reduce((promise: Promise<boolean>, name: string) => {
                  return promise.then(() => window.caches.delete(name));
                }, Promise.resolve(true));
              })
              .catch((error) => console.error('ServiceWorker Warning', error));
          }
        })
        .then(() => {
          logger.log('Before app: cache cleared, reload application');
          window.location.replace(window.location.href);
          // before fix, when location.replace doesn't reload page (sometimes after auth in incognito mode)
          setTimeout(() => (window as any).location.reload(true), 1000);
        });
    }
  })
  .catch((error) => {
    logger.error('Before app: initiation failed', {
      location: 'src/index.ts',
      rawError: error,
    });
    setTimeout(() => (window as any).location.reload(true), 1000);
  });
