import { LiveUpdateEventType as LiveEvent, useLiveUpdate } from '@services/stomp/live-update';
import { useQuery } from '@hooks/core';
import api from './api';

const updateUserEvents: Array<LiveEvent> = [LiveEvent.kycChanged, LiveEvent.userEdited];
export const useProfileMy = () => {
  const {
    data: profile,
    isLoading: isProfileLoading,
    refresh: refreshProfile,
  } = useQuery(api.getMyProfile);

  useLiveUpdate(
    (event) => {
      if (updateUserEvents.includes(event.type)) {
        refreshProfile();
      }
    },
    [refreshProfile]
  );

  return {
    isProfileLoading,
    profile,
  };
};
