import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';

import { ProfileLinkCell } from '@components/table2/utils';

import { ActionsCell } from './actions-cell';
import { formatMoney, formatToken } from '../../services/commonService';
import { RedeemStatusCell } from './status-cell';
import s from './index.module.scss';

import type { ColumnType } from '@components/table2/base';
import type { Redemption } from './api';

type OnReplaceRedemption = (redemption: Redemption) => void;

export enum ColumnKey {
  actions = 'actions',
  number = 'number',
  createdAt = 'createdAt',
  status = 'status',
  client = 'client',
  clientId = 'clientId',
  symbol = 'symbol',
  amount = 'amount',
  description = 'description',
  redeemPrice = 'redeemPrice',
  comment = 'comment',
}

export const useColumns = (onReplaceRedemption: OnReplaceRedemption) => {
  const columns: Array<ColumnType<Redemption>> = useMemo(() => {
    return [
      {
        key: ColumnKey.actions,
        dataIndex: 'actions',
        headCell: () => <span>&nbsp;</span>,
        rowCell: ({ data }) => (
          <ActionsCell data={data} onReplaceRedemption={onReplaceRedemption} />
        ),
        width: 60,
      },
      {
        key: ColumnKey.number,
        dataIndex: 'number',
        headCell: () => <Trans id="head_title.number">№</Trans>,
        rowCell: ({ data }) => <>{data.id}</>,
        width: 60,
      },
      {
        key: ColumnKey.createdAt,
        dataIndex: 'createdAt',
        headCell: () => <Trans id="head_title.date_created">Date created</Trans>,
        rowCell: ({ data }) => <>{data.createdAt.formatLocalDateTime()}</>,
        width: 140,
      },
      {
        key: ColumnKey.status,
        dataIndex: 'status',
        headCell: () => <Trans id="head_title.status">Status</Trans>,
        rowCell: ({ data }) => <RedeemStatusCell status={data.status} />,
        width: 120,
      },
      {
        key: ColumnKey.client,
        dataIndex: 'client',
        headCell: () => <Trans id="head_title.client">Client</Trans>,
        rowCell: ({ data }) => <ProfileLinkCell text={data.user?.displayName} id={data.user?.id} />,
        width: 140,
      },
      {
        key: ColumnKey.clientId,
        dataIndex: 'clientId',
        headCell: () => <Trans id="head_title.client_id">Client ID</Trans>,
        rowCell: ({ data }) => <>{data.user.id}</>,
        width: 100,
      },
      {
        key: ColumnKey.symbol,
        dataIndex: 'symbol',
        headCell: () => <Trans id="head_title.symbol">Symbol</Trans>,
        rowCell: ({ data }) => <>{data.coin.asset}</>,
        width: 140,
      },
      {
        key: ColumnKey.amount,
        dataIndex: 'amount',
        headCell: () => <Trans id="head_title.amount">Amount</Trans>,
        rowCell: ({ data }) => (
          <>{formatToken(data.optionsQuantity * data.optionAmount * data.optionUnit.inGrams)}</>
        ),
        width: 140,
      },
      {
        key: ColumnKey.description,
        dataIndex: 'description',
        headCell: () => <Trans id="head_title.description">Description</Trans>,
        rowCell: ({ data }) => (
          <div className={s.verticalFiller}>
            <span className="mb1">
              <Trans id="redeem.requests.red_option">Redeem option</Trans>
              {': '}
              {data.optionName}
            </span>
            <span className="mb1">
              <Trans id="redeem.requests.red_option_weight">Redeem option weight</Trans>
              {': '}
              {formatToken(data.optionAmount)}
              {data.optionUnit.label}
            </span>
            <span className="mb1">
              <Trans id="redeem.requests.qty">Qty</Trans>
              {': '}
              {`${data.optionsQuantity} `}
              <Trans id="redeem.requests.qty_options">options</Trans>
            </span>
          </div>
        ),
        width: 240,
      },
      {
        key: ColumnKey.redeemPrice,
        dataIndex: 'redeemPrice',
        headCell: () => <Trans id="head_title.redeem_price_v2">Redemption price</Trans>,
        rowCell: ({ data }) => (
          <>
            {data.priceType === 'FIAT'
              ? formatMoney(data.pricePerOption * data.optionsQuantity)
              : `${data.coin.asset} ${formatToken(data.pricePerOption * data.optionsQuantity)}`}
          </>
        ),
        width: 160,
      },
      {
        key: ColumnKey.comment,
        dataIndex: 'comment',
        headCell: () => <Trans id="head_title.comment">Comment</Trans>,
        rowCell: ({ data }) => <>{data.rem}</>,
        width: 140,
      },
    ];
  }, [onReplaceRedemption]);

  return {
    columns,
  };
};
