import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import {
  marketTransferModalEffects,
  marketTransferModalReducer,
} from './modals/transfer/transfer.store';
import { redeemModalsEffects, redeemModalsReducer } from './modals/redeem/redeem.store';
import { sendStableToInvestorReducer } from './modals/send-stable-to-investor/store';

export const walletReducer = combineReducers({
  marketTransferModal: marketTransferModalReducer,
  redeemModals: redeemModalsReducer,
  sendFiatToInvestorModal: sendStableToInvestorReducer,
});

export function* walletEffects() {
  yield all([fork(marketTransferModalEffects), fork(redeemModalsEffects)]);
}
