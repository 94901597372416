import { useContext } from 'react';
import { Trans } from '@lingui/macro';
import { Col, Row } from '@components/grid';
import { InputNumber } from '@components/form';
import { fields, FormContext } from '../../form';

export function PositionTab() {
  const { state } = useContext(FormContext);
  const { lockUI } = state;

  return (
    <div>
      <Row>
        <Col md={6}>
          <InputNumber
            name={fields.position.positionDays}
            label={<Trans id="coin_position.days_to_zero">Days for returning to 0</Trans>}
            disabled={lockUI}
            required={true}
          />
        </Col>

        <Col md={6}>
          <InputNumber
            name={fields.position.positionPercent}
            label={<Trans id="coin_position.max_proportion">Maximum proportion, %</Trans>}
            disabled={lockUI}
            required={true}
            min={0}
            max={100}
            maxDecimals={3}
          />
        </Col>
      </Row>
    </div>
  );
}
