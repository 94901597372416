import { useMemo, FC } from 'react';
import { Table as AntdTable, TableProps as AntdTableProps } from 'antd';
import { DEPRECATED_STYLE_EXCLUSIVE_MARKER } from '@constants/deprecated';
import s from '../../styles/index.module.scss';

export type BaseItem = object;

export type { TableColumnType, TableColumnGroupType } from 'antd';

export type TableProps<T extends BaseItem> = Pick<
  AntdTableProps<T>,
  'columns' | 'components' | 'dataSource' | 'loading' | 'rowKey' | 'scroll' | 'onChange'
>;

const TableWrapper: FC = ({ children }) => {
  return <div>{children}</div>;
};

const HeaderWrapper: FC = ({ children }) => {
  return <div>{children}</div>;
};

const HeaderRow: FC = ({ children }) => {
  return <div>{children}</div>;
};

const HeaderCell: FC = ({ children }) => {
  return <th>{children}</th>;
};

const BodyWrapper: FC = ({ children }) => {
  return <tbody>{children}</tbody>;
};

const BodyRow: FC = ({ children }) => {
  return <div>{children}</div>;
};

const BodyCell: FC = ({ children }) => {
  return <td>{children}</td>;
};

export const baseTableComponents = {
  table: TableWrapper,
  header: {
    wrapper: HeaderWrapper,
    row: HeaderRow,
    cell: HeaderCell,
  },
  body: {
    wrapper: BodyWrapper,
    row: BodyRow,
    cell: BodyCell,
  },
};

export function mergeComponents<T extends BaseItem>(
  components?: TableProps<T>['components']
): TableProps<T>['components'] {
  if (!components) {
    return baseTableComponents;
  }
  const mergedComponents: TableProps<T>['components'] = { ...baseTableComponents };

  if (components?.table) {
    mergedComponents.table = components.table as FC<any>;
  }
  if (components?.header) {
    Object.assign(mergedComponents.header, components.header);
  }
  if (components?.body) {
    if (typeof components.body === 'function') {
      mergedComponents.body = components.body;
    } else {
      Object.assign(mergedComponents.body, components.body);
    }
  }
  return mergedComponents;
}

export const Table = <T extends BaseItem>(props: TableProps<T>) => {
  const { components, ...other } = props;
  const mergedComponents = useMemo(() => mergeComponents(components), [components]);

  return (
    <AntdTable
      className={`${s.table} ${DEPRECATED_STYLE_EXCLUSIVE_MARKER}`}
      components={mergedComponents}
      pagination={false}
      {...other}
    />
  );
};
