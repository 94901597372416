import { useCallback, useState } from 'react';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Trans } from '@lingui/macro';

import { useCallDispatch } from '@hooks/core';
import { closeModalAction, MFC, showNotifyModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { OnFormAction } from '@components/form';
import { dater } from '@helper/date';
import { TypedError } from '@services/stomp/errors';

import api, { Form, Fields } from './api';

export { Fields } from './api';

export const maxDate = new Date().toISOString();

export const formId = 'accountStatement';

// initial date set to previous day is the requirement of the task
// watch: https://tm2support.atlassian.net/browse/TM2-3007
export const initialDate = new Date(moment().subtract(1, 'days').format()).toISOString();

export const formInitial = {
  [Fields.dateTo]: dater.toGqlDate(initialDate),
};

export const useModel = (modal: MFC) => {
  // The context of search form
  // const {state} = useContext(SearchContext);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState(dater.toGqlDate(initialDate));

  const [lockUI, setLockUI] = useState(false);
  const closeModal = useCallDispatch(closeModalAction);

  // @ts-ignore
  const showNotifyModal = useCallDispatch(showNotifyModalAction);
  const onCloseModal = useCallback(() => {
    // Event for clearing parameters in the url
    // state.events.emit({type: 'clear'});
    closeModal(modal);
  }, []);

  // const [filter, setFilter] = useState<string>('');
  // const onSearchChange = useCallback((rsql: string) => setFilter(() => rsql), [setFilter]);

  // const onDownloadClicked = async () => {
  //     setLockUI(true);
  //     try {
  //         const statement = await api.downloadAccountStatement(form);
  //         const blob = base64StringToBlob(statement, 'application/blob');
  //         saveAs(blob, `tm2-account-statement.pdf`);
  //     } catch (error) {
  //         handleBackendError(error);
  //     } finally {
  //         state.events.emit({type: 'clear'});
  //     }
  //     setLockUI(false);
  // };

  const onFormAction: OnFormAction<Form> = {
    submit: async (params) => {
      setLockUI(true);
      const statement = await api.downloadAccountStatement(params);
      const blob = base64StringToBlob(statement, 'application/blob');
      saveAs(blob, `tm2-account-statement.docx`);
    },
    error: (error) => {
      if (error instanceof TypedError) {
        showNotifyModal({
          type: 'fail',
          title: <Trans id="account_statement_modal.exception.title">Error</Trans>,
          description: (
            <Trans id="account_statement_modal.exception.text">
              The statement cannot be downloaded because the public key is missing in the user's
              profile.
            </Trans>
          ),
        });
      } else {
        handleBackendError(error);
      }
    },
    finally: () => {
      setLockUI(false);
    },
  };

  return {
    dateTo,
    dateFrom,
    setDateFrom,
    lockUI,
    onCloseModal,
    setDateTo,
    onFormAction,
  };
};
