import React, { FC, memo, useContext } from 'react';

import { ButtonBack } from '@components/button';
import { Context } from '../context';

export const PrevStepButton: FC<{
  className?: string;
  disabled?: boolean;
}> = memo(({ className, disabled }) => {
  const { dispatch } = useContext(Context);

  return (
    <ButtonBack
      className={className}
      disabled={disabled}
      onClick={() => dispatch({ type: 'ON_PREV_STEP_CLICKED' })}
      uat="previous-post-reg-step-button"
    />
  );
});
