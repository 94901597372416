import React, { FC } from 'react';
import { useRoleLabels } from '@hooks/roles';

export const RoleCell: FC<{
  role: { name: string };
}> = ({ role }) => {
  const roleLabels = useRoleLabels();

  return <span>{roleLabels[role?.name] || role?.name}</span>;
};
