import { createContext, useMemo, useReducer, FC } from 'react';

type ActionMapBase = Record<string, any>;

export type ExtractAction<A extends ActionMapBase> = {
  [K in keyof A]: [A[K]] extends [never] ? { type: K } : { type: K; payload: A[K] };
}[keyof A];

type ContextValue<S, A extends ActionMapBase> = {
  state: S;
  dispatch: (action: A) => void;
};

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object
    ? RecursivePartial<T[P]>
    : T[P];
};

export type ArrayItem<T extends any[]> = T extends (infer U)[] ? U : never;

export function ContextCreator<S, A extends ActionMapBase>(
  initialState: S,
  reducer: (state: S, action: A) => S
) {
  const Context = createContext<ContextValue<S, A>>({
    state: initialState,
    dispatch: () => {},
  });

  const Provider: FC<{ stateExtra?: Partial<S> }> = (p) => {
    const [state, dispatch] = useReducer(reducer, { ...initialState, ...p.stateExtra });

    const contextValue = useMemo<ContextValue<S, A>>(
      () => ({
        state,
        dispatch,
      }),
      [state, dispatch]
    );

    return <Context.Provider value={contextValue}>{p.children}</Context.Provider>;
  };

  return { Context, Provider };
}

export type ObjectValues<T extends Record<string, unknown>> = T[keyof T];
