import { PermissionGroup } from '@permissions/core';
import {
  Offers,
  permissionGroup as offersTablePermissionGroup,
  OffersProps,
} from '../shared/offers-table';
import api, { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, offersTablePermissionGroup],
  marker: 'layout:offers-by-all',
});

export type OffersByAllProps = Pick<
  OffersProps,
  'data' | 'updateData' | 'assetId' | 'sellerId' | 'status'
>;

export function OffersByAll(props: OffersByAllProps) {
  return <Offers getOffers={api.getOffers} {...props} />;
}
