import React from 'react';
import { Trans } from '@lingui/macro';

import { ButtonPrimary } from '@components/button';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { useOrderBookModel } from './model';

export const OrderBook = () => {
  const {
    getOfferDate,
    isBuyButtonDisabled,
    height,
    orders,
    lockUI,
    onOfferPriceSelected,
    currencyCode,
  } = useOrderBookModel();

  return (
    <>
      <div className="light-gradient-block gradient-border-block" key={'header'}>
        <h3>
          <Trans id="buy_metals.order_book">Order book</Trans>
        </h3>
      </div>
      <div className="light-gradient-block padding-0 relative market-page-order-book" key={'body'}>
        <div className="scroll-block" style={{ height }}>
          <table className="small-table-2 table-tr-hover-gradient table-tr-hover-show-hidden">
            <thead>
              <tr>
                <th>
                  <Trans id="buy_metals.qty">Qty</Trans>
                </th>
                <th>
                  <div className="order-table-header-price">
                    <span className="order-table-header-price-text">
                      <Trans id="buy_metals.price">Price</Trans>
                    </span>
                    (<span className="op-text">{currencyCode}</span>)
                  </div>
                </th>
                <th>
                  <Trans id="buy_metals.date">Date</Trans>
                </th>
              </tr>
            </thead>
            <tbody className={lockUI ? 'block-blurred' : ''}>
              {orders?.map((offerPrice, i) => (
                <tr key={`${offerPrice.id}-${i}`} style={{ height: '30px' }}>
                  <td className="align-right">
                    {formatToken(
                      offerPrice.offer.availableQuantity * offerPrice.offer.quantityUnit.inGrams
                    )}
                  </td>
                  <td className="align-right">{formatMoney(offerPrice.quantityPerGram, {})}</td>
                  <td className="align-center">{getOfferDate(offerPrice)}</td>
                  <td className="align-center px-0">
                    <ButtonPrimary
                      className="buy-metals-page-buy-button"
                      fullWidth={true}
                      size="sm"
                      onClick={() => onOfferPriceSelected(offerPrice, currencyCode)}
                      disabled={isBuyButtonDisabled}
                    >
                      <Trans id="buy_metals.buy">Buy</Trans>
                    </ButtonPrimary>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
