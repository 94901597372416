import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Popup } from '@components/popup';
import { copyToClipboard } from '../../../services/commonService';

export const TransactionTooltip: FC<{ txId: string }> = ({ txId }) => {
  return (
    <Popup
      placement="bottom"
      trigger={['hover', 'focus', 'click']}
      content={
        <div className={s.tooltip}>
          {txId}
          <button className={s.button} onClick={() => copyToClipboard(txId)}>
            <Trans id={'overdraft-report.table.transactionId.copy'}>Copy</Trans>
          </button>
        </div>
      }
    >
      <span className={s.id}>{txId}</span>
    </Popup>
  );
};
