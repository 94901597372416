import sign from 'tm2sign.macro';

import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

export enum Fields {
  clientId = 'clientId',
  limit = 'limit',
  dateFrom = 'dateFrom',
  dateTo = 'dateTo',
}

export interface Form {
  [Fields.clientId]: number;
  [Fields.limit]: number;
  [Fields.dateFrom]: string;
  [Fields.dateTo]: string;
}

const downloadAccountStatementQuery = 'clientAccountStatementAsDOCX';
const downloadAccountStatementFields = ['value'];
const downloadAccountStatement = makeQuery({
  permissions: { and: [Permission.CLIENT_ACCOUNT_STATEMENT_VIEW_REPORT] },
  queryName: downloadAccountStatementQuery,
  queryFields: downloadAccountStatementFields,
  query: (params: Form): Promise<string> => {
    return stompClient
      .getData(
        downloadAccountStatementQuery,
        sign(downloadAccountStatementQuery, downloadAccountStatementFields),
        { ...v.clientAccountStatementReportParamInput({ params }) }
      )
      .then((response) => response.value);
  },
});

const api = {
  downloadAccountStatement,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:account-statement-modal');

export default api;
