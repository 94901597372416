import { userForDisplayFields } from '../../../../utils/gql/common-fields';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { TableData } from '@models/core';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import sign from 'tm2sign.macro';

export type DailyBalanceHistoryItem = any;

const dailyBalanceHistoryQuery = 'dailyBalance';
const dailyBalanceHistoryFields = [
  'date',
  { balances: ['balance', 'code'] },
  { issuer: userForDisplayFields },
];
export const getDailyBalanceHistory = makeQuery({
  permissions: Permission.DAILY_BALANCE_VIEW_HISTORY_REPORT,
  queryName: dailyBalanceHistoryQuery,
  queryFields: dailyBalanceHistoryFields,
  query: (
    issuerId: number,
    startDate: string,
    endDate: string
  ): Promise<TableData<DailyBalanceHistoryItem>> => {
    const typedValues = {
      ...v.long({ issuerId }),
      ...v.localDate({ startDate }),
      ...v.localDate({ endDate }),
    };

    return stompClient.getData(
      dailyBalanceHistoryQuery,
      sign(dailyBalanceHistoryQuery, dailyBalanceHistoryFields),
      typedValues
    );
  },
});

const api = {
  getDailyBalanceHistory,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:daily-balance--report-page');

export default api;
