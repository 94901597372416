import React, { FC, ReactNode, useContext } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';
import { PreloaderTrack } from '@components/preloader';
import { ReactComponent as CloseIcon } from '@images/icons/cross-icon.svg';
import { ReactComponent as ArrowRight } from '@images/icons/arrow-right-wide.svg';
import { ModalContext, modalContext } from '../modal';

export const ModalTitle: FC<{
  children: ReactNode;
  className?: string;
  icon?: ReactNode;
  onBack?: Function;
  uat?: string;
}> = ({ children, className, icon, onBack, uat }) => {
  const { disabled, loading, onClose } = useContext<ModalContext>(modalContext);

  return (
    <div className={classnames(s.title, className)} data-uat={uat}>
      {!loading ? null : <PreloaderTrack />}

      {!icon ? null : <div className={s.icon}>{icon}</div>}

      <div className={classnames(s.content, !!icon && s.withIcon)}>
        {!onBack ? null : <ButtonBack onClick={onBack} />}
        {children}
      </div>

      <div
        className={classnames(s.close, !!disabled && 'is-disabled')}
        onClick={onClose}
        data-uat="close-modal-button"
      >
        <CloseIcon className={s.closeSvg} />
      </div>
    </div>
  );
};

const ButtonBack: FC<{ onClick: Function }> = ({ onClick }) => (
  <button className={classnames(s.back, 'mr1')} onClick={onClick as () => void}>
    <ArrowRight />
  </button>
);
