import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import { currenciesSelectors } from '@modules/currencies';
import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { notifySuccess } from '@modules/notify';
import api, { CreateBankAccountForm } from './api';

export { CreateBankAccountFields as Fields } from './api';
export const formId: string = 'addBankDetailsFormId';

export const useModel = (modal, onAccountCreated: (id: number) => void) => {
  const closeModal = useCallDispatch(closeModalAction);
  const isCurrenciesLoading = useSelector(currenciesSelectors.isLoading);
  const [isCreateAccountLoading, setCreateAccountLoading] = useState(false);

  const onFormAction: OnFormAction<CreateBankAccountForm> = {
    error: () => setCreateAccountLoading(false),
    submit: async (form) => {
      setCreateAccountLoading(true);
      const accountId = await api.createBankAccount(form);
      onAccountCreated && onAccountCreated(accountId);
      await notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="add_billing_modal.notification.success_add.text">
            Your account details have been successfully updated
          </Trans>
        ),
      });
      setCreateAccountLoading(false);
      closeModal(modal);
    },
  };

  return {
    lockUI: isCreateAccountLoading || isCurrenciesLoading,
    onCloseModalClicked: () => closeModal(),
    onFormAction,
  };
};
