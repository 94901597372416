import React, { useMemo } from 'react';
import _ from 'lodash';

import { currenciesFilter } from '@hot-fix/currency';
import { useBrowserSize, useQuery } from 'containers/services/commonService';
import {
  activeOffersPricesQuery,
  coinBalanceQuery,
  basicSMFeeQuery,
  stableBalanceQuery,
  tickerQuery,
  weekDailyPriceQuery,
} from 'containers/services/graphqlFacade';
import gqlFields from './fields';

const {
  basicSMFeeFields,
  tickerFields,
  activeOfferPricesFields,
  coinBalanceFields,
  stableBalanceFields,
  weekDailyPriceFields,
} = gqlFields;

export const useTicker = () => {
  const [ticker = [], isTickerLoading] = useQuery(tickerQuery, tickerFields);
  const tickersActive = ticker.filter((pair) => !!pair?.coin?.info?.active);
  const tickersObj = _.groupBy(tickersActive, 'metal');

  return [
    Object.keys(tickersObj)
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map((metal) => ({
        name: metal,
        list: [...tickersObj[metal]]
          .filter((ticker) => currenciesFilter(ticker.currency))
          .sort((a, b) => a.currency.order - b.currency.order)
          .sort((a, b) => a.coin.asset.toLowerCase().localeCompare(b.coin.asset.toLowerCase())),
      })),
    isTickerLoading,
  ];
};

export const useGetActiveOffersPrices = (coinId, currencyId) =>
  useQuery(
    coinId && currencyId ? activeOffersPricesQuery : () => {},
    activeOfferPricesFields,
    coinId,
    currencyId
  );

export const useWeekDailyPrice = (coinId, currencyId, unit) =>
  useQuery(weekDailyPriceQuery, weekDailyPriceFields, coinId, currencyId, unit);

const height_header = 45,
  middle_paddings = 40,
  height_block_heading = 43,
  content_row_padding = 15;

export const useTradeHistoryHeight = () => {
  const [, height] = useBrowserSize();
  const h = height - height_header - middle_paddings;
  return (h - height_block_heading * 2 - content_row_padding * 3) * 0.7;
};

export const useTabsHeight = () => {
  const [, height] = useBrowserSize();
  const h = height - height_header - middle_paddings;
  return (h - height_block_heading * 2 - content_row_padding * 3) * 0.3;
};

export const usePortfolioHeight = () => {
  const [, height] = useBrowserSize();
  const blockTabsHeight = useTabsHeight();
  const height_product_filtr = 74;
  const h = height - height_header - middle_paddings;
  return (
    (h -
      height_product_filtr -
      blockTabsHeight -
      height_block_heading * 3 -
      content_row_padding * 5) *
    0.5
  );
};

export const useOrderBookHeight = () => usePortfolioHeight();

export const productChartOptions = {
  chart: {
    type: 'line',
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    events: {
      render: function () {
        this.reflow();
      },
    },
  },
  legend: {
    itemStyle: {
      color: '#FFF',
      fontWeight: 'normal',
    },
    itemHoverStyle: {
      color: '#99a8e1',
    },
  },
  title: {
    text: '',
  },
  xAxis: {
    type: 'datetime',
    labels: {
      style: {
        color: '#FFF',
      },
    },
    lineWidth: 0,
    tickWidth: 0,
  },
  yAxis: {
    title: {
      text: '',
      style: {
        color: '#FFF',
      },
    },
    labels: {
      style: {
        color: '#FFF',
      },
    },
    gridLineDashStyle: 'LongDash',
    gridLineColor: '#5a5a5a',
  },
  plotOptions: {
    series: {
      marker: {
        enabled: false,
      },
    },
  },
  tooltip: {
    shared: true,
    crosshairs: true,
    backgroundColor: 'rgba(224, 174, 111, 0.8)',
    borderColor: '#E0AE6F',
    style: {
      color: '#FFF',
    },
    xDateFormat: '%d %B %Y, %a',
    useHTML: true,
    headerFormat: `<div class="bm-service-header-f">{point.key}</div><table><tbody>`,
    pointFormat:
      '<tr><td class="bm-service-point-f">{series.name}: </td><td class="bm-service-point-f-td">{point.y}</td></tr>',
    footerFormat: '</tbody></table>',
  },
};

export const useCoinBalance = () => useQuery(coinBalanceQuery, coinBalanceFields);

export const useStableBalance = () => {
  const [balances, loading, refresh] = useQuery(stableBalanceQuery, stableBalanceFields);
  const hookData = useMemo(
    () => [
      // balance without currency is not fiat, this will be coin
      balances && balances.filter((item) => !item.currency || currenciesFilter(item.currency)),
      loading,
      refresh,
    ],
    [balances, loading, refresh]
  );
  return hookData;
};

export const useBasicSMFee = () => {
  const [basicSMFeeResponse = {}, isBasicSMFeeLoading] = useQuery(
    basicSMFeeQuery,
    basicSMFeeFields
  );
  const basicSMFee = basicSMFeeResponse?.value;

  return [basicSMFee, isBasicSMFeeLoading];
};
