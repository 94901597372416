import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { bankAccountsFields } from '../../../../fields.shared';
import { BankAccountItem } from '../../../../api.shared';
import { currenciesFilter } from '@hot-fix/currency';

// ----------------------------------------------------------------
const getBankAccountsQuery = 'userBankAccounts';
const getBankAccounts = makeQuery({
  permissions: { or: [Permission.BANK_ACCOUNTS_VIEW_OTHER] },
  queryName: getBankAccountsQuery,
  queryFields: bankAccountsFields,
  query: (userId: number): Promise<Array<BankAccountItem>> => {
    return stompClientService
      .getData<Array<BankAccountItem>>(
        getBankAccountsQuery,
        sign(getBankAccountsQuery, bankAccountsFields),
        v.long({ userId })
      )
      .then((accounts) => accounts.filter((account) => currenciesFilter(account.currency)));
  },
});
// ----------------------------------------------------------------
const api = {
  getBankAccounts,
};

export const permissionsGroup = PermissionGroup.extract(
  api,
  'api:profile-other-page-bank-accounts'
);

export default api;
