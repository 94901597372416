import React, { FC, memo } from 'react';

import s from './index.module.scss';
import { BankAccountItem } from '../../../api.shared';
import { BankInfoItem } from './item';

export const BankAccountsTab: FC<{
  banks: Array<BankAccountItem>;
  disabled: boolean;
}> = memo(({ banks, disabled }) => {
  return (
    <div className={s.tab} style={{ pointerEvents: disabled ? 'none' : 'all' }}>
      {banks.map((bank, index) => (
        <BankInfoItem className={banks.length - 1 !== index && 'mb3'} key={bank.id} bank={bank} />
      ))}
    </div>
  );
});
