import { BankAccount } from '../../../deposits-api.service';
import { AddFundsModalActions } from '../../store/model';
import { CreditCardViewServiceActionsTypes } from './service.acions';

export enum CreditCardViewFormFields {
  address = 'address',
  amount = 'amount',
  currency = 'currency.id',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
}

export interface CreditCardBillingInfo {
  [CreditCardViewFormFields.address]: string;
  [CreditCardViewFormFields.email]: string;
  [CreditCardViewFormFields.firstName]: string;
  [CreditCardViewFormFields.lastName]: string;
}

export interface CreditCardViewForm extends CreditCardBillingInfo {
  [CreditCardViewFormFields.amount]: number;
  [CreditCardViewFormFields.currency]: number;
}

export interface CreditCardViewState {
  isPrepareCreditCardLoading: boolean;
  bankAccounts: Array<BankAccount>;
}

export const creditCardViewStateInitial: CreditCardViewState = {
  isPrepareCreditCardLoading: false,
  bankAccounts: [],
};

export enum CreditCardViewActionsTypes {
  PREPARE_CREDIT_CARD = 'CREDIT_CARD_VIEW.PREPARE_CREDIT_CARD',
  PREPARE_CREDIT_CARD_FAIL = 'CREDIT_CARD_VIEW.PREPARE_CREDIT_CARD_FAIL',
  PREPARE_CREDIT_CARD_SUCCESS = 'CREDIT_CARD_VIEW.PREPARE_CREDIT_CARD_SUCCESS',
  SET_BANK_ACCOUNTS = 'CREDIT_CARD_VIEW.SET_BANK_ACCOUNTS',
}

export type CreditCardViewActions = CreditCardViewServiceActionsTypes | AddFundsModalActions;
