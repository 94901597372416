import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { Role } from '@permissions/core';
import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { EditTariffModal } from './modals/edit-tariff';
import { useProfileOther } from './hooks';

const editAccess = { roles: [Role.admin] };
export const useModel = () => {
  const { i18n } = useI18n();
  const showModal = useCallDispatch(showModalAction);
  const { profile, refreshProfile } = useProfileOther();

  const actions = useMemo(
    () => [
      {
        access: editAccess,
        action: () =>
          showModal({
            modal: EditTariffModal,
            props: {
              onTariffUpdated: () => refreshProfile(),
              userId: profile.id,
            },
          }),
        content: <Trans id="profile_other.tariff.action.edit">Edit</Trans>,
      },
    ],
    [profile, refreshProfile, showModal]
  );

  return {
    actions,
    i18n,
    profile,
    refreshProfile,
  };
};
