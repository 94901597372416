import { bankAccountFields } from '@gql/UserBankAccount.fields';

export const profileFields = [
  'address',
  'building', // HubSpot
  'city', // HubSpot
  'displayName',
  'email',
  'id',
  'permissions',
  'phone',
  'postalCode', // HubSpot
  'stablePublicKey',
  'state', // HubSpot
  'umsId',
  'verificationStatus',
  { country: ['label'] },
  { investorIssuer: ['email', 'id'] },
  { issuerInvestor: ['email', 'id'] },
  { role: ['name'] },
  { userTariff: ['endDate', { tariff: ['name', 'code'] }] },
];

export const bankAccountsFields = [...bankAccountFields, { currency: ['code', 'id'] }];
