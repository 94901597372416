import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import './styles.deprecated.scss';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { ProductDropdown } from './dropdown';
import { useProductSelectorModal } from './model';

export const ProductSelector = ({ defaultCoin, defaultCurrency }) => {
  const { selectedPair, onPairSelected, tickerByMetals, isActiveOfferPricesLoading, available } =
    useProductSelectorModal(defaultCoin, defaultCurrency);

  return (
    <div className="light-gradient-block product-selector" style={{ zIndex: 1000 }}>
      <div className="row align-items-center">
        <div className="col-auto product-selector-container" style={{ zIndex: 1000 }}>
          <div className={`text-block select-block bordered-field dropdown-block min-width-180`}>
            <ProductDropdown
              button={
                <div>
                  <div className="input font-weight-500">
                    {!selectedPair ? (
                      <Trans id="primary_market_page.product_selector.label.loading">
                        <span>Loading</span>
                      </Trans>
                    ) : (
                      `${selectedPair?.coin?.asset}(${selectedPair?.metal?.toUpperCase()})`
                    )}
                  </div>
                  <label className="field-label label-small">
                    <Trans id="buy_metals.select_symbol">Select symbol</Trans>
                  </label>
                </div>
              }
            >
              <div className="dropdown-menu">
                {tickerByMetals.map((ticker, index) => (
                  <div className="dropdown-item" key={`${ticker.name}-${index}`}>
                    <div className="small-text">{ticker.name}</div>
                    <table className="small-table-3">
                      <tbody>
                        {ticker.list.map((pair) => (
                          <tr
                            key={`${pair.coin.id}${pair.currency.id}`}
                            className={classnames('tm2-symbol-item', {
                              'color-link': selectedPair === pair,
                            })}
                            onClick={() => onPairSelected(pair)}
                          >
                            <td>
                              {pair.coin.asset}/{pair.currency.code}
                            </td>
                            <td className="align-right">
                              {pair.minPrice ? formatMoney(pair.minPrice, {}) : 'n/a'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ))}
              </div>
            </ProductDropdown>
          </div>
        </div>

        <div className="col-auto">
          <label className="field-label label-small">
            <Trans id="buy_metals.last_trade_price">Last trade price</Trans>
          </label>
          <div className="input big-text color-blue-dodger">
            {selectedPair?.lastTradePrice
              ? formatMoney(selectedPair.lastTradePrice, {
                  post: ` ${selectedPair?.currency?.code}`,
                })
              : 'n/a'}
          </div>
        </div>

        <div className="col-auto">
          <label className="field-label label-small">
            <Trans id="buy_metals.available">Available</Trans>
          </label>
          <div
            className={classnames('input big-text', {
              'block-blurred': isActiveOfferPricesLoading,
            })}
          >
            <span>{formatToken(available)}</span>
            &nbsp;
            <span className="color--grey">{selectedPair?.coin?.asset}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
