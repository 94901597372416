import React, { memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ButtonTextIconBig } from '@components/button';
import { HasAccess, Permission, PermissionGroup } from '@permissions/core';
import { Box } from '@components/box';
import { Form, Select } from '@components/form';
import { formId, useModel, Fields } from './model';
import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as addFundsPermissionGroup } from '../add-funds-modal';
import { Deposit, Filters } from '../api';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, addFundsPermissionGroup],
  marker: 'layout:deposits-page-actions',
});

type Props = {
  addDeposit: (deposit: Deposit) => void;
  onFiltersChanged: (filters: Filters) => void;
};
export const ActionsRow = memo<Props>((p) => {
  const m = useModel(p.addDeposit);

  return (
    <div className={classnames(s.layout, 'mb2')}>
      <Box className={s.actions}>
        <Form name={formId} className={s.form} onChange={p.onFiltersChanged}>
          {m.isCustomersFilterAllowed && (
            <Select
              name={Fields.investor}
              data={m.investors}
              label={<Trans id="deposits_filter.customer">Customer</Trans>}
              disabled={m.lockUI}
              compact={true}
              allowEmpty={true}
              className={s.filter}
            />
          )}

          <Select
            name={Fields.status}
            data={m.depositStatuses}
            label={<Trans id="deposits_filter.status">Status</Trans>}
            disabled={m.lockUI}
            compact={true}
            allowEmpty={true}
            className={s.filter}
          />

          <HasAccess
            permissions={{
              or: [
                // Watch: https://tm2support.atlassian.net/browse/TM2-3134
                // Permission.INVOICE_CREATE_MY,
                Permission.INVOICE_CREATE_EXT,
              ],
            }}
            // Watch: https://tm2support.atlassian.net/browse/TM2-3134
            // extraCheck={m.isShowAddFundsBtnExtra}
          >
            <ButtonTextIconBig
              className={s.addFunds}
              icon={<AddIcon className="tm2-icon-stroke" />}
              onClick={m.showAddDepositModal}
            >
              <Trans id="button.add_funds">Add funds</Trans>
            </ButtonTextIconBig>
          </HasAccess>
        </Form>
      </Box>
    </div>
  );
});
