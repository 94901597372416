import { useContext, FC } from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { Context } from '../context';

export const ExchangeRateChanged: FC = () => {
  const { dispatch } = useContext(Context);
  const goToCurrencySetting = () => {
    dispatch({ type: 'ON_CURRENCY_SETTING_REQUESTED' });
  };

  return (
    <>
      <ModalTitle>
        <Trans id={'my_wallet.exchange_currency.exchange_rate_changed.title'}>
          Currency rate expired
        </Trans>
      </ModalTitle>
      <ModalContent>
        <Trans id={'my_wallet.exchange_currency.exchange_rate_changed.message'}>
          The currency exchange rate has been expired. Please try again.
        </Trans>
      </ModalContent>
      <ModalFooter>
        <ButtonPrimary
          className={'wallet-currency-exchange-submit-button'}
          onClick={goToCurrencySetting}
          fullWidth
        >
          <Trans id={'my_wallet.exchange_currency.exchange_rate_change.go_back'}>Back</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </>
  );
};
