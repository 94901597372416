import sign from 'tm2sign.macro';
import { userForDisplayFields } from 'utils/gql/common-fields';

const invoiceFields = [
  'id',
  'number',
  'status',
  'amount',
  'creationDate',
  'clientComment',
  'amount',
  'reference',
  'externalId',
  'bankName',
  'purpose',
  'sourceOfFunds',
  {
    bankAccount: [
      'id',
      { currency: ['name', 'code'] },
      'beneficiaryName',
      'address',
      'bankName',
      'accountNumber',
      'swiftCode',
      'referenceId',
    ],
  },
  { investorUser: [...userForDisplayFields, 'address', 'phone', 'email'] },
  { payment: ['amount', 'accountDetails', 'creationDate'] },
];

export default {
  currentUserFields: sign('currentUser', [{ bankAccounts: ['id', { currency: ['code', 'id'] }] }]),
  usersWithBankAccountsFields: sign('traders', [
    ...userForDisplayFields,
    { bankAccounts: ['id', { currency: ['id', 'code'] }] },
  ]),
  createInvoiceFields: sign('createInvoice', invoiceFields),
  externalBankAccountBalancesFields: sign('externalBankAccountBalances', [
    { computedFields: ['displayName'] },
    { currency: ['id'] },
    'externalId',
  ]),
};
