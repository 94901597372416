import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { CoinCreateDTOInput } from '../../input-dto';

export type CreateCoinData = CoinCreateDTOInput;

const createCoinQuery = 'createCoin';
const createCoinFields = ['value'];
const createCoin = makeQuery({
  permissions: Permission.ASSET_CREATE,
  queryName: createCoinQuery,
  queryFields: createCoinFields,
  query: (coinData: CoinCreateDTOInput): Promise<{ value: number }> => {
    return stompClient.sendData(
      createCoinQuery,
      sign(createCoinQuery, createCoinFields),
      v.coinCreateDTOInput({ coinCreateDTO: coinData })
    );
  },
});

const api = { createCoin };

export const permissionGroup = PermissionGroup.extract(api, 'api:edit-coin');

export default api;
