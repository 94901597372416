import React, { FC } from 'react';

import { ButtonColor, ButtonIconPure, ButtonModel } from '@components/button';
import { ReactComponent as InfoIcon } from '@images/icons/info-icon.svg';

export const InfoButtonIcon: FC<ButtonModel> = (props) => (
  <ButtonIconPure
    icon={<InfoIcon className="tm2-icon-fill" />}
    color={ButtonColor.primary}
    onClick={() => {}}
    {...props}
  />
);
