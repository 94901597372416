import React, { memo } from 'react';
import { Permission as P } from '@permissions/core';
import { Trans } from '@lingui/macro';
import { MoreActions } from '@components/more-actions';
import { Deposit, DepositStatus } from '../api';
import { useModel } from './model';

type Props = {
  data: Deposit;
  replaceDeposit: (deposit: Deposit) => void;
};
export const ActionsCell = memo<Props>((p) => {
  const m = useModel(p.data, p.replaceDeposit);

  return (
    <MoreActions
      placement="right"
      list={[
        {
          access: {
            extraCheck: () => [DepositStatus.created].includes(p.data.status),
            permissions: { or: [P.INVOICE_CANCEL] },
          },
          action: () => m.showConfirmCancelDepositModal(),
          content: <Trans id="button.cancel">Cancel</Trans>,
        },
        {
          access: {
            extraCheck: () => [DepositStatus.created].includes(p.data.status),
            permissions: { or: [P.INVOICE_MARK_AS_PAID] },
          },
          action: () => m.showMarkAsPaidModal(false),
          content: <Trans id="deposits.actions.mark_as.paid">Mark as paid</Trans>,
        },
        {
          access: {
            extraCheck: () => [DepositStatus.created].includes(p.data.status),
            permissions: { or: [P.INVOICE_MARK_AS_PAID] },
          },
          action: () => m.showMarkAsPaidModal(true),
          content: <Trans id="deposits.actions.mark_as_partly_paid">Mark as partly paid</Trans>,
        },
        {
          access: {
            extraCheck: () =>
              [DepositStatus.paid, DepositStatus.partlyPaid].includes(p.data.status),
            permissions: { or: [P.INVOICE_APPROVE] },
          },
          action: () => m.showApproveDepositModal(),
          content: <Trans id="deposits.actions.approve">Approve</Trans>,
        },
      ]}
    />
  );
});
