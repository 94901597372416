type Subscriber = (asset: string) => void;

class RedeemCreationEventService {
  private subscriberList: Subscriber[] = [];

  subscribe(cb: Subscriber) {
    this.subscriberList.push(cb);
    return () => {
      this.unsubscribe(cb);
    };
  }

  unsubscribe(cb: Subscriber) {
    this.subscriberList = this.subscriberList.filter((s) => s !== cb);
  }

  notify(asset: string) {
    this.subscriberList.forEach((s) => {
      s(asset);
    });
  }
}

export const redeemCreationEventService = new RedeemCreationEventService();
