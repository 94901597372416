import { PURCHASE } from '../actions/purchaseActions';

const initialState = {
  limits: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PURCHASE.ON_LIMITS_RECEIVED:
      return { ...state, limits: action.limits };

    default:
      return state;
  }
};
