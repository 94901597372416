import React, { ChangeEvent, FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form as AntForm, Input } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { Label, useLabel } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const InputText: FC<{
  className?: string;
  compact?: boolean;
  disabled?: boolean;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  name: NamePath;
  required?: boolean;
  maxLength?: number;
  uat?: string;
}> = ({
  className,
  compact,
  disabled,
  fieldKey,
  label,
  name,
  required,
  maxLength,
  uat,
  ...props
}) => {
  const uatAttribute = useFieldUat(name, 'input-text', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-text', className, {
        'tm2-form-field-compact': compact,
      })}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} fieldKey={fieldKey} required={required}>
        <CustomText
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          {...props}
        />
      </Item>
    </div>
  );
};

const CustomText: FC<{
  disabled: boolean;
  label: ReactNode;
  maxLength?: number;
  name: NamePath;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  required: boolean;
  value?: number;
}> = (_props) => {
  const { disabled, label, name, maxLength, onChange, required, value, ...props } = _props;
  const { shifted, onBlur, onFocus } = useLabel({ value: value });

  return (
    <>
      <Label htmlFor={name} shifted={shifted} label={label} required={required} />
      <Input
        className="tm2-form-field-item-instance"
        value={value}
        maxLength={maxLength || 255}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
        {...props}
      />
    </>
  );
};
