import React from 'react';
import { Trans } from '@lingui/macro';

import { Text } from '@components/typography/text';
import { PermissionGroup } from '@permissions/permission-group';
import { Form, InputPassword, SubmitButton } from '@components/form';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { ButtonModalBack } from '@components/button';
import { EditProfileForm } from '../edit-profile/api';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { Fields, formId, useModal } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup],
  marker: 'layout:profile-my-page-confirm-edit-profile-modal',
});

export const PasswordModal: MFC<{ profile: EditProfileForm }> = ({ isVisible, profile }) => {
  const m = useModal(profile);

  return (
    <Modal
      isVisible={isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      onClose={m.onCloseModalClicked}
      uat="confirm-edit-profile-modal"
    >
      <ModalTitle>
        <Trans id="password_modal.title">Password confirmation</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId}>
          <Text className="mb3">
            <Trans id="password_confirmation_modal.text">
              Please, enter your current password to save the changes to your profile.
            </Trans>
          </Text>

          <InputPassword
            label={<Trans id="password_modal.password">Password</Trans>}
            name={Fields.password}
            disabled={m.lockUI}
            required
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <ButtonModalBack className="mr3" disabled={m.lockUI} />

        <SubmitButton name={formId} fullWidth={true} onAction={m.onFormAction} disabled={m.lockUI}>
          <Trans id="button.ok">OK</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
