import { UserInfo as _UserInfo } from './store/model';
import { configServiceActions } from './store/service.acions';
export { default as configApi } from './config-api.service';
export { deprecatedSelectors } from './store/selectors';
export { configEffects } from './store/effects';
export { configReducer } from './store/reducer';
export { configSelectors } from './store/selectors';
export { configUserActions } from './store/user.acions';
export { configServiceActions } from './store/service.acions';
export {
  AccountType,
  KycState,
  OnboardingStep,
  TariffsIds,
  TariffCodes,
  TariffsPermissions,
} from './store/model';
export interface UserInfo extends _UserInfo {}

export const toggleGlobalPreloaderAction = (isShow: boolean) =>
  configServiceActions.toggleGlobalPreloader(isShow);
