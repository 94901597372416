import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Trans } from '@lingui/macro';
import React from 'react';
import { ButtonPrimary } from '@components/button';
import { RadioBox, RadioBoxItem } from '@components/radio-box';
import { Title } from '@components/typography/title';
import { Divider } from '@components/divider';
import { Text } from '@components/typography/text';
import { useModel } from './model';

export enum UserGoal {
  theRiskTaker = 'the-risk-taker',
  theSafeBet = 'the-safe-bet',
  diversified = 'diversified',
  economicHedge = 'economic-hedge',
}

export const UserGoalModal: MFC<{
  onHideCustomerSurvey: Function;
}> = ({ isVisible, onHideCustomerSurvey }) => {
  const m = useModel({ modal: UserGoalModal, onHideCustomerSurvey });

  return (
    <Modal className="user-goal-modal" isVisible={isVisible} onClose={m.onCloseModal}>
      <ModalTitle>
        <Trans id="primary_market.page.helper.modal.title">What is your goal?</Trans>
      </ModalTitle>

      <ModalContent>
        <RadioBox
          className="mb3"
          deprecatedStyles={false}
          defaultActiveKey={UserGoal.economicHedge}
        >
          {m.userGoals.map((goal) => (
            <RadioBoxItem key={goal.key} header={goal.header}>
              <Divider className="mb3" />

              <Title level={3}>{goal.title}</Title>

              <Text>
                <Trans id="primary_market.page.helper.modal.metals_to_watch">
                  Metals to watch:
                </Trans>
                {goal.metals.map((metal) => (
                  <div key={metal}>{metal}</div>
                ))}
                {goal.descriptions.map((description, index) => (
                  <>
                    <div key={index}>{description}</div>
                    <br />
                  </>
                ))}
                <Trans id="primary_market.page.helper.modal.not_advice">
                  Please do your own research before buying. This is not investment advice.
                </Trans>
              </Text>
            </RadioBoxItem>
          ))}
        </RadioBox>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} onClick={m.onOKClicked}>
          <Trans id="primary_market.page.helper.modal.ok">OK</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
