import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from 'shared/helper/typer/field-typer.helper';
import { PageWrapper } from 'shared/models/backend';
import { fileService } from '@helper/file';

class MetalsApiService {
  downloadTransaction() {
    const downloadTrustTransactionsFields = sign('downloadTrustTransactions', ['value']);

    return stompClient
      .getData('downloadTrustTransactions', downloadTrustTransactionsFields)
      .then((response) => response.value);
  }

  getMetalsList(pageNumber, sort) {
    const metalsFields = sign('coinsForTrustPage', [
      ...PageWrapper,
      {
        data: [
          'asset',
          'haveSecondaryTrust',
          { issuer: ['displayName', 'id'] },
          { metal: ['label'] },
        ],
      },
    ]);
    const ordering = [];
    sort.forEach((column) =>
      column.fields.forEach((field) => ordering.push({ direction: column.direction, field: field }))
    );
    const typedValues = {
      ...{ page: pageNumber },
      // ...v.orderInput({ordering}) // TODO для сортировки раскомментить тут и в самой таблице
    };

    return stompClient.getData('coinsForTrustPage', metalsFields, typedValues).then((response) => ({
      isHasMore: response.hasMore,
      metals: response.data,
    }));
  }

  uploadTransaction(file) {
    const uploadTrustTransactionsField = sign('uploadTrustTransactions', ['value']);

    return fileService.toBase64(file).then((base64) =>
      stompClient.sendData('uploadTrustTransactions', uploadTrustTransactionsField, {
        file: base64,
      })
    );
  }
}

export const metalsApiService = new MetalsApiService();
