import React, { memo } from 'react';
import { Fields, formId, useModel } from './model';
import { Trans } from '@lingui/macro';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputNumber, Select, SubmitButton } from '@components/form';
import { permissionGroup as apiPermissionGroup, CreatedSettlement } from './api';
import s from './index.module.scss';
import classnames from 'classnames';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:add-settlement-modal',
});

export const AddSettlementModal: MFC<{
  replaceSettlement: (settlement: CreatedSettlement) => void;
}> = memo(({ isVisible, replaceSettlement }) => {
  const m = useModel(replaceSettlement, AddSettlementModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(AddSettlementModal)}
    >
      <ModalTitle>
        <Trans id="add_settlement.title">Add settlement</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId}>
          <div className={classnames('mb1', s.container)}>
            <Select
              name={Fields.currency}
              label={<Trans id="add_settlement.currency">Currency</Trans>}
              data={m.currencies}
              disabled={m.lockUI}
              required={true}
              className={s.element}
            />

            <Select
              name={Fields.recipient}
              allowEmpty={false}
              label={<Trans id="add_settlement.from">From</Trans>}
              data={m.listingBrokers}
              disabled={m.lockUI}
              required={true}
              className={s.element}
            />
          </div>

          <InputNumber
            name={Fields.amount}
            label={<Trans id="add_settlement.amount">Amount</Trans>}
            maxDecimals={2}
            disabled={m.lockUI}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.add">Add</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
});
