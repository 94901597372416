import { RedeemConfirmationModalActionsTypes as Types } from './model';

const OnCloseModalClickedAction = () => ({
  payload: null,
  type: Types.ON_CLOSE_MODAL_CLICKED,
});

const OnConfirmRedeemClickedAction = () => ({
  payload: null,
  type: Types.ON_CONFIRM_REDEEM_CLICKED,
});

const OnMetalQuantityChangedActions = (quantity) => ({
  payload: quantity,
  type: Types.ON_METAL_QUANTITY_CHANGED,
});

const OnOptionsQuantityChangedActions = (quantity) => ({
  payload: quantity,
  type: Types.ON_OPTIONS_QUANTITY_CHANGED,
});

const OnModalLeftAction = () => ({
  payload: null,
  type: Types.ON_MODAL_LEFT,
});

const OnModalOpenedAction = () => ({
  payload: null,
  type: Types.ON_MODAL_OPENED,
});

export const redeemConfirmationModalUserActions = {
  onCloseModalClicked: OnCloseModalClickedAction,
  onConfirmRedeemClicked: OnConfirmRedeemClickedAction,
  onMetalQuantityChanged: OnMetalQuantityChangedActions,
  onOptionsQuantityChanged: OnOptionsQuantityChangedActions,
  onModalLeft: OnModalLeftAction,
  onModalOpened: OnModalOpenedAction,
};
