import _ from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';

import { showConfirmModalAction, showModalAction } from '@modules/modal';
import { AddAmountCoinModal } from './modals/issue-coin';
import { DischargeTokenModal } from './modals/discharge-coin';
import { SendMetalsToInvestorModal } from './modals/send-metals-to-investor';
import { useCoinsPage, useSetAssetActive } from './service';

export const useCoinsTableModel = () => {
  const [coinsPage, loadMoreCoins, replaceCoin] = useCoinsPage();
  const dispatch = useDispatch();
  const setAssetActive = useSetAssetActive();

  const onAddAmountCoin = (coin) =>
    dispatch(
      showModalAction({
        modal: AddAmountCoinModal,
        props: { coin },
      })
    );

  const onDischargeTokenClicked = (coin) =>
    dispatch(
      showModalAction({
        modal: DischargeTokenModal,
        props: { coin },
      })
    );

  const getLastVaultBalance = (coin) => {
    let lastBalanceDetails = _.get(coin, 'vault.lastVaultBalance.details', []);
    let coinDetails = lastBalanceDetails.find((detail) => detail.coin.id === coin.id);
    return coinDetails
      ? coinDetails.unit.label === 'g'
        ? coinDetails.amount
        : coinDetails.unit.inGrams * coinDetails.amount
      : 0;
  };

  const showBlockSwitchConfirmModal = (coin) =>
    dispatch(
      showConfirmModalAction({
        text: {
          title: coin?.info?.active ? (
            <Trans id="digital_metals.block_metal">Block {coin?.asset}?</Trans>
          ) : (
            <Trans id="digital_metals.unblock_metal">Unblock {coin?.asset}?</Trans>
          ),
          description: coin?.info?.active ? (
            <Trans id="digital_metals.do_you_really_want_to_block">
              Do you really want to block coin {coin?.asset}?
            </Trans>
          ) : (
            <Trans id="digital_metals.do_you_really_want_to_unblock">
              Do you really want to unblock coin {coin?.asset}?
            </Trans>
          ),
        },
        onConfirm: {
          action: async () => {
            const newActive = !coin.info.active;
            const result = await setAssetActive(coin.asset, newActive);
            if (result.value) {
              const newCoin = { ...coin, info: { ...coin.info, active: newActive } };
              replaceCoin(newCoin);
            }
          },
        },
      })
    );

  const requestSendMetalsToAccount = async ({ asset, info: { balance } }) => {
    dispatch(
      showModalAction({
        modal: SendMetalsToInvestorModal,
        props: { asset, balance },
      })
    );
  };

  return {
    coinsPage,
    getLastVaultBalance,
    loadMoreCoins,
    onAddAmountCoin,
    onDischargeTokenClicked,
    requestSendMetalsToAccount,
    showBlockSwitchConfirmModal,
  };
};
