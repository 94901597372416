import {
  AccessConfig as _AccessConfig,
  AllowedPermissions as _AllowedPermissions,
} from './access.service';

export { HasAccess } from './hoc.component';
export { accessService } from './access.service';
export { permissionsSelectors } from './store/selectors';
export { PermissionGroup, PermissionRecord, makeQuery } from './permission-group';

// list of all permissions is here https://docs.google.com/spreadsheets/d/1pIpvZVLpz5E5YcoSNH3qtEM3dKJX7lX7
export enum Permission {
  ASSET_CREATE = 85, // in use

  ASSET_EDIT = 86, // in use

  /**
   * Возможность получать детальную информацию об ассете
   */
  ASSET_VIEW = 88,

  ASSET_VIEW_RESTRICTED = 89,

  /**
   * Доступ к бэк офису
   * Roles: admin, 1-st line support, ops clerk, ops head
   */
  BACK_OFFICE_ACCESS = 84, // in use

  /**
   * Просмотр баланса пользователя в детальном профиле
   * Roles: admin, ops head, ops clerk, custody manager
   */
  BALANCE_VIEW_OTHER = 4, // in use

  /**
   * Просмотр банковских деталей в своем профиле
   * Roles: issuer, individual investor, institutional member, execution broker, listing broker, liquidity provider
   */
  BANK_ACCOUNTS_VIEW_MY = 79, // in use

  /**
   * Просмотр банковских деталей в чужом профиле
   * Roles: admin, 1-st line support, ops head, ops clerk, custody manager
   */
  BANK_ACCOUNTS_VIEW_OTHER = 78, // in use

  /**
   * Просмотр своих балансов по металлу и фиату на праймари маркете
   * Roles: investor, institutional member, issuer, execution broker, listing broker
   */
  BALANCE_VIEW_PRIMARY_MY = 63, // in use

  /**
   * Просмотр своих балансов по металлу и фиату на секондари маркете
   * Roles: investor, institutional member, execution broker
   */
  BALANCE_VIEW_SECONDARY_MY = 64, // in use

  BID_ORDERS_VIEW_ALL = 71, // in use

  BID_ORDERS_VIEW_ALL_WHERE_I_OFFER_CREATOR = 72, // in use

  BID_ORDERS_VIEW_ALL_WHERE_I_BUYER = 73, // in use

  /**
   * Загрузка отчета "Client Account Statement"
   * Roles: admin, ops clerk, ops head, custody manager
   */
  CLIENT_ACCOUNT_STATEMENT_VIEW_REPORT = 92, // in use

  /**
   * Просмотр списка всех металлов (+ статистика: всего, баланас, позиция ишьюера к волту)
   * т.е. пока фактически доступ ко всей странице
   * Roles: admin, 1-st line support, ops clerk, ops head, custody manager
   */
  COINS_VIEW_ALL = 7, // in use

  /**
   * Пермишн для идентификации любого юзера как корпоративного клиента
   * Т.е. использовать там, где у юзера может быть любая роль (этот или INDIVIDUAL_PROFILE пермишн есть у любой роли)
   * отображение соответствующей пермишну роли на странице профиля
   * отображение соответствующей пермишну роли в таблице юзеров
   * отображение company на странице профиля
   * отображение поля company при создании/редактировании пользователя
   * отображение списка доступных тарифов при назначении тарифа юзеру админом
   * Roles: institutional member, execution broker
   */
  CORPORATE_PROFILE = 52, // in use

  /**
   * Пермишн для всей сложной логики доступной корпоративному ИНВЕСТОРУ (смотри таблицу пермишнов).
   * Т.е. использовать там, где логика заточена под текущего залогиненого пользователя
   * Редактирование формы opening account form for corporate
   * Запрет изменения полей, идентифицирующих физлицо (first name, last name, country, address, phone number)
   * запрет изменения полей, идентифицирующих компанию (company, country, address) после прохождения KYB
   * учавствует в дисабле currency селекта в add invoice, как детектор, что юзер не инвестор
   * часть логики отображения колонок sender и recipient на transactions
   * отображение eWallet public key на странице профиля
   * часть логики отображения селекта issuer при создании/редактировании профиля
   * часть логики отображения поля primary eWallet public key при редактировании профиля пользователя
   * часть логики отображения поля secondary eWallet public key при редактировании профиля пользователя
   * часть логики отображения approve/decline KYC кнопок в таблице юзеров
   * часть логики отображения кнопки withdraw на странице wallet
   * часть логики отображения кнопки transfer на странице wallet
   * часть логики блокировки ссылки на client профиль в таблице на странице withdrawals
   * Roles: investor
   */
  CORPORATE_USER = 34, // in use

  /**
   * Просмотр отчета Custody fee Investors
   * Roles: admin, ops clerk, ops head, custody manager
   */
  CUSTODY_FEE_VIEW_INVESTORS_REPORT = 18, // deprecated

  /**
   * Просмотр отчета Daily balance history
   * Roles: admin, ops clerk, ops head
   */
  DAILY_BALANCE_VIEW_HISTORY_REPORT = 16, // in use

  GENERAL_PREFERENCES_EDIT = 90,

  /**
   * Редактирование комиссий на странице fees
   * Roles: admin, ops clerk, ops head
   */
  EDIT_FEES = 42, // in use

  /**
   * Редактирование виртуального баланса пользователю
   * Roles: marketing
   */
  EDIT_USER_VIRTUAL_BALANCE = 62, // in use

  /**
   * Просмотр отчета "Conversion report"
   * Roles: admin, 1-st line support, ops clerk, ops head
   */
  EXCHANGE_VIEW_CONVERSION_REPORT = 56,

  /**
   * Просмотр отчета "Fees report"
   */
  EXECUTION_FEE_VIEW_REPORT = 81,

  /**
   * Просмотр отчета "Bank Accounts Balances"
   */
  EXTERNAL_BANK_ACCOUNT_BALANCES_VIEW_REPORT = 87,

  /**
   * Просмотр отчета "Overdraft report"
   * Roles: admin, ops clerk, ops head
   */
  FEE_VIEW_OVERDRAFT_REPORT = 65,

  /**
   * Одробрение счета
   * Roles: admin, ops head
   */
  INVOICE_APPROVE = 14, // in use

  /**
   * Отмена счета. Отмененный счет не может быть одобрен
   * Roles: admin, account manager, ops clerk, ops head, investor, institutional member, execution broker
   */
  INVOICE_CANCEL = 15, // in use

  /**
   * Создание счета для депозита средств для любого клиента
   * Roles: admin, account manager, ops clerk, ops head
   */
  INVOICE_CREATE_EXT = 12, // in use

  /**
   * Создание счета для депозита на свой аккаунт
   * Roles: investor, institutional member, execution broker
   */
  INVOICE_CREATE_MY = 50, // in use

  /**
   * Отметка в счете о том, что он полностью или частично оплачен
   * Roles: admin, account manager, ops clerk, ops head
   */
  INVOICE_MARK_AS_PAID = 13, // in use

  /**
   * Просмотр списка всех депозитов (ранее инвойсов)
   * Roles: admin, account manager, ops clerk, ops head
   */
  INVOICES_VIEW_ALL = 24, // in use

  /**
   * Просмотр списка инвойсов где получатель (инвестор) = текущий пользователь
   * доступ к странице deposits
   * Roles: investor, institutional member, execution broker
   */
  INVOICES_VIEW_MY = 48, // in use

  /**
   * Пермишн для идентификации любого юзера как индивидуального клиента
   * Т.е. использовать там, где у юзера может быть любая роль (этот или CORPORATE_PROFILE пермишн есть у любой роли)
   * отображение соответствующей пермишну роли на странице профиля
   * отображение соответствующей пермишну роли в таблице юзеров
   * отображение first name и last name на странице профиля
   * отображение полей first name и last name при создании/редактировании пользователя
   * отображение списка доступных тарифов при назначении тарифа юзеру админом
   * Roles: admin. account manager, clearance broker, 1st line support, ops clerk, ops head, issuer, investor,
   *        vault manager, liquidity provider, compliance officer, custody manager
   */
  INDIVIDUAL_PROFILE = 53, // in use

  /**
   * Пермишн для всей сложной логики доступной индивидуальному ИНВЕСТОРУ (смотри таблицу пермишнов).
   * Т.е. использовать там, где логика заточена под текущего залогиненого юзера
   * Редактирование формы opening account form for individuals
   * Прохождение KYC
   * Запрет изменения полей, идентифицирующих физлицо (first name, last name, country, address, phone number) после прохождения KYC
   * учавствует в дисабле currency селекта в add invoice, как детектор, что юзер не инвестор
   * часть логики отображения колонок sender и recipient на transactions
   * отображение eWallet public key на странице профиля
   * часть логики отображения селекта issuer при создании/редактировании профиля
   * часть логики отображения поля primary eWallet public key при редактировании профиля пользователя
   * часть логики отображения поля secondary eWallet public key при редактировании профиля пользователя
   * часть логики отображения approve/decline KYC кнопок в таблице юзеров
   * часть логики отображения кнопки withdraw на странице wallet
   * часть логики отображения кнопки transfer на странице wallet
   * часть логики блокировки ссылки на client профиль в таблице на странице withdrawals
   * Roles: institutional member, execution broker
   */
  INDIVIDUAL_USER = 33, // in use

  /**
   * Просмотр отчета "Management fee"
   * Roles: admin, ops head, custody manager
   */
  MANAGEMENT_FEE_VIEW_REPORT = 66, // in use

  OFFERS_VIEW_ALL = 69, // in use

  OFFERS_VIEW_WHERE_I_CREATOR = 70, // in use

  /**
   * Просмотр списка всех offers
   * Roles: admin, account manager, 1-st line support, ops clerk, ops head, vault manager, issuer
   */
  OFFERS_VIEW = 10, // deprecated

  /**
   * Просмотр списка всех сделок
   * Roles: admin, account manager, 1-st line support, ops clerk, ops head
   */
  OFFERS_VIEW_HISTORY = 11, // deprecated

  /**
   * Восстановление своего пароля
   * Roles: all
   */
  PASSWORD_RECOVERY = 1, // keycloak permission only, not used in front app

  /**
   * Просмотр отчета Payments
   * Roles: admin, ops head, ops clerk
   */
  PAYMENTS_VIEW_REPORT = 46, // in use

  /**
   * Изменение статуса верификации чужого пользователя
   * Roles: admin, ops head, compliance officer, custody manager
   */
  PROFILE_OTHER_VERIFICATION_CHANGE = 83, // in use

  /**
   * Редактирование своего профиля
   * Roles: all
   */
  PROFILE_EDIT_MY = 2, // in use

  /**
   * Редактирование чужого профиля профиля
   * Roles: admin
   */
  PROFILE_EDIT_OTHER = 82, // in use

  /**
   * Просмотр баннера сверху профиля
   * Roles: none
   */
  PROFILE_VIEW_BANNER = 38, // in use

  /**
   * Просмотр детального своего профиля
   * Roles: all
   */
  PROFILE_VIEW_MY = 77, // in use

  /**
   * Просмотр детального профиля чужого пользователя без просмотра баланса
   * offers/bid-orders - просмотр в таблице колонок buyer и buyer id
   * отображение колонок client и client id в таблице на странице invoices
   * отображение колонок client и client id в таблице на странице redeem requests
   * Roles: admin, 1-st line support, ops clerk, ops head, custody manager
   */
  PROFILE_VIEW_OTHER = 3, // in use

  /**
   * Одобрение redeem запроса от клиента
   * Roles: admin, clearance broker, ops clerk, ops head
   */
  REDEEM_APPROVE = 20, // in use

  /**
   * Сжигание токенов, указанных в redeem запросе (после успешной доставки металла клиенту)
   * Roles: admin, clearance broker, ops clerk, ops head
   */
  REDEEM_BURN = 21, // in use

  /**
   * Отклонение redeem запроса, который создал сам
   * Roles: investor, institutional member, execution broker
   */
  REDEEM_CANCEL_MY_REQUEST = 39, // in use

  /**
   * Отклонение redeem запроса от клиента
   * Roles: admin, clearance broker, ops clerk, ops head
   */
  REDEEM_DENY = 20, // in use TODO temporary same value as APPROVE_REDEEM

  /**
   * Просмотр всех запросов на redeem
   * Roles: admin, clearance broker, 1-st line support, ops clerk, ops head, custody manager
   */
  REDEEM_VIEW_REQUESTS = 22, // in use

  /**
   * Просмотр своих запросов на redeem
   * доступ к странице redeem-requests
   * Roles: investor, institutional member, execution broker
   */
  REDEEM_VIEW_MY_REQUESTS = 40, // in use

  /**
   * Просмотр отчета "Referrals"
   * Roles: admin, marketing
   */
  REFERRALS_VIEW_REPORT = 68, // in use

  /**
   * Настройка маркетинговой кампании
   * Roles: marketing
   */
  SETTING_MARKETING_COMPANY = 61, // in use

  /**
   * Массовое добавление trading accounts в UMS
   * Roles: admin
   */
  SECONDARY_MARKET_CREATE_PREPARED_TRADING_ACCOUNT = 43, // in use

  /**
   * Просмотр отчета "Free Secondary Market participants"
   * Roles: admin, 1-st line support
   */
  SECONDARY_MARKET_VIEW_FREE_PARTICIPANTS_REPORT = 47, // in use and latest for now

  /**
   * Доступ к выгрузке продаж с secondary market (tradeslist)
   * Roles: admin, ops clerk, ops head, custody manager
   */
  SECONDARY_MARKET_VIEW_TRADE_LIST = 75, // in use

  /**
   * Доступ к шагам opening account form (светлая тема)
   * Roles: investor, institutional member, execution broker
   */
  SIGNUP_VIEW = 55, // in use

  /**
   * Просмотр отчета Listing brokers account statement
   * Roles: admin, ops clerk, ops head
   */
  STATEMENT_VIEW_LB_ACCOUNT_REPORT = 17, // in use

  /**
   * Просмотр списка всех поставок, истории поставок
   * Roles: admin, 1-st line support, ops clerk, ops head
   */
  SUPPLIES_VIEW = 23, // deprecated not used

  /**
   * Создание корректирующих транзакций к балансам ишьюера (reconciliation) (add settlements)
   * Roles: admin, ops clerk, ops head
   */
  TRANSACTION_CREATE_CORRECTION = 19, // in use

  /**
   * Скачивание списка транзакций в xls формате
   * Roles: admin, ops clerk, ops head, custody manager
   */
  TRANSACTIONS_DOWNLOAD = 35, // in use

  /**
   * Просмотр всех транзакций всех пользователей в фиате и металле
   * Roles: admin, ops clerk, ops head, custody manager
   */
  TRANSACTIONS_VIEW_ALL = 6, // in use

  /**
   * Просмотр списка транзакций с металлом по своему аккаунту
   * Доступ к вкладке транзакций на странице my-wallet
   * Roles: investor, institutional member, execution broker
   */
  TRANSACTIONS_VIEW_MY = 36, // in use

  /**
   * Просмотр отчета "User current balance report"
   * Roles: admin, ops clerk, ops head, custody manager
   */
  USERS_BALANCES_VIEW_REPORT = 80, // in use

  /**
   * Загрузка отчета "Get TM2 Users Report"
   * Roles: admin, ops clerk, ops head
   */
  USERS_INFO_VIEW_REPORT = 91, // in use

  /**
   * Просмотр списка пользователей всех ролей
   * Roles: admin, 1-st line support, ops head, custody manager
   */
  USERS_VIEW_ALL = 5, // in use

  /**
   * Просмотр списка волтов
   * Roles: admin, 1-st line support, ops clerk, ops head, vault manager, custody manager
   */
  VAULTS_VIEW_LIST = 8, // in use

  /**
   * Просмотр списка отчетов волтов
   * Roles: admin, 1-st line support, ops clerk, ops head, custody manager
   */
  VAULT_VIEW_REPORTS = 9, // in use

  /**
   * Просмотр и скачивание документов верификации
   * Roles: admin, compliance officer
   */
  VERIFICATION_DOCUMENTS_VIEW_OTHER = 76, // in use

  /**
   * Просмотр доп информации профиля пользователя
   * Roles: admin, account manager, clearance broker, 1-st line support, ops clerk, ops head, custody manager
   */
  VIEW_ADDITIONAL_PROFILE_INFO = 54, // in use

  /**
   * Просмотр комиссий на странице fees
   * Roles: admin, ops clerk, ops head
   */
  VIEW_FEES = 41, // in use

  /**
   * Просмотр отчета "Virtual Balance"
   * Roles: admin, marketing
   */
  VIRTUAL_BALANCE_VIEW_REPORT = 67, // in use

  /**
   * Одобрение заявки на вывод withdrawal request approve
   * Roles: ops head
   */
  WITHDRAWAL_APPROVE = 27, // in use

  /**
   * Отмена своей заявки (cancel)
   * Roles: investor, execution broker
   */
  WITHDRAWAL_CANCEL = 30, // in use

  /**
   * Отмена своей заявки (cancel)
   * Roles: investor, execution broker
   */
  WITHDRAWAL_CHECK = 31, // in use

  /**
   * Отмена заявки на withdrawal (deny)
   * Roles: account manager, ops clerk, ops head
   */
  WITHDRAWAL_DENY = 29, // in use

  /**
   * Предварительное одобрение заявки на вывод withdrawal request pre-approve
   * Roles: account manager, ops clerk
   */
  WITHDRAWAL_PRE_APPROVE = 26, // in use

  /**
   * Отметка об оплате payment processed
   * Roles: account manager, ops clerk, ops head
   */
  WITHDRAWAL_PROCESS = 28, // in use

  /**
   * Просмотр всех withdrawal requests
   * Roles: admin, account manager, ops clerk, ops head
   */
  WITHDRAWALS_VIEW_ALL = 25, // in use

  /**
   * Создание WR для себя
   * Roles: investor, institutional member, execution broker
   */
  WITHDRAWAL_CREATE_MY = 93, // in use

  /**
   * Создание WR для других пользователей
   * Roles: admin
   */
  WITHDRAWAL_CREATE_FOR_OTHER = 94, // in use

  /**
   * Просмотр withdrawal requests, где я создатель
   * Roles: investor, institutional member, execution broker
   */
  WITHDRAWALS_VIEW_USER = 32, // in use
}

/**
 * Набор пермишнов, который позволяет идентифицировать текущего юзера как инвестора,
 * если присутствует хотя бы один пермишн из списка
 */
export const traderPermissions: Array<Permission> = [
  // TODO рассмотреть вариант с ролями (подробности у Дениса)
  Permission.CORPORATE_USER,
  Permission.INDIVIDUAL_USER,
];

/**
 * Страница Statements содержит в себе только список ссылок на другие страницы. Поэтому на Statements можно
 * попасть, если хотя бы один из пермишнов других страниц из Statements есть у юзера
 */
export const statementsPagePermissions: Array<Permission> = [
  Permission.DAILY_BALANCE_VIEW_HISTORY_REPORT,
  Permission.STATEMENT_VIEW_LB_ACCOUNT_REPORT,
  Permission.SECONDARY_MARKET_VIEW_FREE_PARTICIPANTS_REPORT,
];

export const feesPagePermissions: Array<Permission> = [Permission.VIEW_FEES, Permission.EDIT_FEES];

export enum Role {
  accountManager = 'ACCOUNT_MANAGER',
  admin = 'ADMINISTRATOR',
  auditor = 'AUDITOR', // deprecated
  clearanceBroker = 'CLEARANCE_BROKER',
  complianceOfficer = 'COMPLIANCE_OFFICER',
  custodyManager = 'CUSTODY_MANAGER',
  executionBroker = 'EXECUTION_BROKER',
  institutionalMember = 'INSTITUTIONAL_MEMBER',
  issuer = 'ISSUER',
  firstLineSupport = 'FIRST_LINE_SUPPORT',
  listingBroker = 'LISTING_BROKER',
  liquidityProvider = 'LIQUIDITY_PROVIDER',
  opsClerk = 'OPS_CLERK',
  opsHead = 'OPS_HEAD',
  vaultManager = 'VAULT_MANAGER',
  marketing = 'MARKETING',
  investor = 'INVESTOR',
}

export type IsHasAccess = (
  permissions: AllowedPermissions,
  extraCheck?: boolean,
  forcedAccess?: boolean,
  roles?: Array<Role>
) => boolean;

export interface AllowedPermissions extends _AllowedPermissions {}

export interface AccessConfig extends _AccessConfig {}
