import React from 'react';
import { Trans } from '@lingui/macro';
import { HasAccess, Permission as P, PermissionGroup } from '@permissions/core';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';

import s from './index.module.scss';
import { Form, InputPhone, InputText, SubmitButton } from '@components/form';
import { CountrySelect } from '@modules/countries';
import { permissionsGroup as confirmEditPermissionsGroup } from '../password';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { Fields, formId, useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup, confirmEditPermissionsGroup],
  marker: 'layout:profile-my-page-edit-profile-modal',
});

export const EditProfileModal: MFC = ({ isVisible }) => {
  const m = useModel(EditProfileModal);

  return (
    <Modal
      onClose={m.closeModal}
      isVisible={isVisible}
      disabled={m.lock.ui}
      loading={m.lock.ui}
      uat="edit-my-profile-modal"
    >
      <ModalTitle>
        <Trans id="user_from.edit.title">Edit profile</Trans>
      </ModalTitle>

      <ModalContent>
        <Form className={s.form} name={formId} initialValues={m.formInitial}>
          <HasAccess permissions={{ or: [P.CORPORATE_PROFILE] }}>
            <InputText
              className={s.field}
              label={<Trans id="user_form.company">Company</Trans>}
              name={Fields.company}
              disabled={m.lock.company}
              required
            />
          </HasAccess>

          <HasAccess permissions={{ or: [P.INDIVIDUAL_PROFILE] }}>
            <InputText
              className={s.field}
              label={<Trans id="user_form.first_name">First name</Trans>}
              name={Fields.firstName}
              disabled={m.lock.firstName}
              required
            />
          </HasAccess>

          <HasAccess permissions={{ or: [P.INDIVIDUAL_PROFILE] }}>
            <InputText
              className={s.field}
              label={<Trans id="user_form.last_name">Last name</Trans>}
              name={Fields.lastName}
              disabled={m.lock.lastName}
              required
            />
          </HasAccess>

          <InputText
            className={s.field}
            label={<Trans id="user_form.e_mail">E-mail</Trans>}
            name={Fields.email}
            disabled={m.lock.ui}
            required
          />

          <InputPhone
            className={s.field}
            label={<Trans id="user_form.phone">Phone</Trans>}
            name={Fields.phone}
            disabled={m.lock.phone}
            required
            // We set autocomplete to be a string different
            // than 'off' since if autocomplete="off" UA
            // may override it with user preferences, which
            // can differ from the desired result.
            // https://stackoverflow.com/a/34998771
            autocomplete={'none'}
          />

          <CountrySelect
            className={s.field}
            label={<Trans id="user_form.country">Country</Trans>}
            name={Fields.country}
            disabled={m.lock.country}
            required
          />

          <InputText
            className={s.field} // if last field is odd - it will stretch to full width (see css)
            label={<Trans id="user_form.address">Address</Trans>}
            name={Fields.address}
            disabled={m.lock.address}
            required
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lock.ui} onAction={m.onFormAction}>
          <Trans id="button.save">Save</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
