import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { PageContent } from '@components/page';
import {
  AddRowButton,
  DeleteRowButton,
  Form,
  FormList,
  InputNumber,
  Select,
  SubmitButton,
} from '@components/form';
import { Box } from '@components/box';
import { FeeLimits, Fields, formId, useModel } from './model';

export const SecondaryMarketFeesPage: FC = memo(() => {
  const m = useModel();

  return (
    <PageContent>
      <Box className={s.form}>
        <Form name={formId} initialValues={m.formInitial} onChange={m.onFormChanged}>
          <div className={s.row}>
            <InputNumber
              name={Fields.basicFee}
              label={<Trans id="secondary_market_fees.basic">Basic, %</Trans>}
              min={FeeLimits.min}
              max={FeeLimits.max}
              maxDecimals={FeeLimits.decimals}
              disabled={!m.isEditAllowed || m.lockUI}
              required={true}
            />
          </div>

          <FormList name={Fields.feeList}>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name }) => (
                  <div className={s.row} key={key}>
                    <Select
                      className="mr-sm"
                      name={[name, Fields.asset]}
                      fieldKey={[key, Fields.asset]}
                      label={<Trans id="secondary_market_fees.asset">Asset</Trans>}
                      disabled={!m.isEditAllowed || m.lockUI}
                      required={true}
                      data={m.assets}
                    />

                    <InputNumber
                      name={[name, Fields.fee]}
                      fieldKey={[key, Fields.fee]}
                      label={<Trans id="secondary_market_fees.fee">Fee, %</Trans>}
                      min={FeeLimits.min}
                      max={FeeLimits.max}
                      maxDecimals={FeeLimits.decimals}
                      disabled={!m.isEditAllowed || m.lockUI}
                      required={true}
                    />

                    {m.isEditAllowed && (
                      <DeleteRowButton
                        className="ml-sm"
                        onClick={() => remove(name)}
                        disabled={m.lockUI}
                      />
                    )}
                  </div>
                ))}

                {m.isEditAllowed && (
                  <div className={classnames(s.row, s.actions)}>
                    <AddRowButton
                      text={<Trans id="secondary_market_fees.add_commission">Add commission</Trans>}
                      disabled={m.lockUI}
                      onClick={() => add()}
                    />

                    <SubmitButton
                      className={s.saveButton}
                      name={formId}
                      fullWidth={false}
                      disabled={m.lockUI}
                      onAction={m.onFormAction}
                    >
                      <Trans id="secondary_market_fees.button.save">Save</Trans>
                    </SubmitButton>
                  </div>
                )}
              </>
            )}
          </FormList>
        </Form>
      </Box>
    </PageContent>
  );
});
