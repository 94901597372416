import { createElement, useLayoutEffect, useMemo, useRef } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { useSyncRef } from '../utils';

import type { BaseItem } from './types';
import type { ComponentType, CSSProperties, RefObject } from 'react';

export type RowProps<T extends BaseItem> = {
  columnWidthList: number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerRef: RefObject<any>;
  data: T;
  estimatedHeight: number;
  loading: boolean;
  measure: () => void;
  style: CSSProperties;
};

export type RowWrapperProps<T extends BaseItem> = {
  columnWidthList: number[];
  data: T;
  estimatedHeight: number;
  loading: boolean;
  measureRef: (el: HTMLElement) => void;
  rowComponent: ComponentType<RowProps<T>>;
  style: CSSProperties;
  updateMeasureOnData?: boolean;
};

// The main case for measure prop is to update row height if a row gets
// expanded/collapsed.

// NOTE: On the first render we update measurement inside this component,
// so the consumer doesn't need to call measure prop on first render.

// NOTE: If estimated size of the element equals it's measured size
// (offsetHeight), there will be no rerender.

// NOTE: Since passing loading prop could potentially trigger too much
// rerenders, for now we disable it. Provide as external prop if necessary.

// measureRef is expected to be new function each time
// an element gets rendered by the virtualizer:
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L182
// Because of this we store reference to measureRef and
// memoize the row.

export const RowWrapper = <T extends BaseItem>(props: RowWrapperProps<T>) => {
  const { columnWidthList, data, estimatedHeight, loading, measureRef, rowComponent, style } =
    props;

  const containerRef = useRef<HTMLElement>(null);
  const syncMeasureRef = useSyncRef(measureRef);

  const measure = useCallbackOne(() => {
    if (containerRef.current) {
      syncMeasureRef.current(containerRef.current);
    }
  }, []);

  const memoizedRow = useMemo(() => {
    return createElement(rowComponent, {
      columnWidthList,
      containerRef,
      data,
      estimatedHeight,
      loading,
      measure,
      style,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnWidthList,
    data,
    estimatedHeight,
    // loading,
    measure,
    rowComponent,
    style.position,
    style.top,
  ]);

  const dataMeasureDep = props.updateMeasureOnData === true ? data : null;

  useLayoutEffect(() => {
    if (containerRef.current && dataMeasureDep) {
      measure();
    }
  }, [measure, dataMeasureDep]);

  return memoizedRow;
};
