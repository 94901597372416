import React from 'react';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Trans } from '@lingui/macro';
import { ButtonColor, ButtonPrimary, ButtonText } from '@components/button';
import { useModel } from './model';
import s from './index.module.scss';

type Props = {
  blob: Blob;
  filename: string;
};
export const DownloadStatementModal: MFC<Props> = ({ blob, filename, isVisible }) => {
  const m = useModel(blob, filename, DownloadStatementModal);

  return (
    <Modal
      onClose={() => m.onClose(DownloadStatementModal)}
      disabled={m.lockUI}
      loading={m.lockUI}
      isVisible={isVisible}
    >
      <ModalTitle>
        <Trans id="download_statement.the_account_statement_v2">
          Account statement successfully created
        </Trans>
      </ModalTitle>

      <ModalContent>
        <Trans id="download_statement.you_can_download">You can download it here</Trans>
        <ButtonText
          className={s.downloadFile}
          color={ButtonColor.primary}
          disabled={m.lockUI}
          onClick={m.onDownload}
        >
          {filename}
        </ButtonText>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary
          fullWidth={true}
          disabled={m.lockUI}
          onClick={() => m.onClose(DownloadStatementModal)}
        >
          <Trans id="button.close">Close</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
