import { TableHeaderSortType as Sort } from '../../../../../components/page-table';
import { SortItem, TableData } from '@models/core';
import { MarketingCompaniesItem } from '../api';
import { MarketingCompaniesUserActionsTypes } from './user.actions';
import { MarketingCompaniesServiceActionsTypes } from './service.actions';

export enum MarketingTableColumnIds {
  id = 'id',
  name = 'name',
}

const columnSort: Array<SortItem<MarketingTableColumnIds>> = [
  { direction: Sort.notSet, fields: ['id'], id: MarketingTableColumnIds.id },
  { direction: Sort.notSet, fields: ['name'], id: MarketingTableColumnIds.name },
];

export interface LoadMarketingCompaniesSuccessPayload extends TableData<MarketingCompaniesItem> {
  isSort: boolean;
}

export interface MarketingTableState {
  isHasMore: boolean;
  isMarketingCompaniesLoading: boolean;
  pageIndex: number;
  marketingCompanies: Array<MarketingCompaniesItem>;
  sort: Array<SortItem<MarketingTableColumnIds>>;
}

export const marketingTableInitialState: MarketingTableState = {
  isHasMore: true,
  isMarketingCompaniesLoading: false,
  pageIndex: -1,
  marketingCompanies: [],
  sort: columnSort,
};

export enum MarketingTableActionTypes {
  LOAD_MORE_MARKETING_COMPANIES = 'MARKETING_TABLE.LOAD_MORE_MARKETING_COMPANIES',
  LOAD_MORE_MARKETING_COMPANIES_FAIL = 'MARKETING_TABLE.LOAD_MORE_MARKETING_COMPANIES_FAIL',
  LOAD_MORE_MARKETING_COMPANIES_SUCCESS = 'MARKETING_TABLE.LOAD_MORE_MARKETING_COMPANIES_SUCCESS',
  ON_TABLE_SCROLLED = 'MARKETING_TABLE.ON_TABLE_SCROLLED',
  ON_TABLE_SORTED = 'MARKETING_TABLE.ON_TABLE_SORTED',
  ON_PAGE_LEFT = 'MARKETING_TABLE.ON_PAGE_LEFT',
}

export type MarketingCompaniesActions =
  | MarketingCompaniesUserActionsTypes
  | MarketingCompaniesServiceActionsTypes;
