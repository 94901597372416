import { AddFundsModalActionsTypes as AddFundsTypes } from '../../store/model';
import {
  wireTransferViewStateInitial,
  defaultExternalBankAccount,
  WireTransferViewActionsTypes as WireTypes,
  WireTransferViewState,
  WireTransferViewActions,
} from './model';

export const wireTransferViewReducer = (
  state: WireTransferViewState = wireTransferViewStateInitial,
  action: WireTransferViewActions
): WireTransferViewState => {
  switch (action.type) {
    case WireTypes.GENERATE_DEPOSIT: {
      return {
        ...state,
        isGenerateDepositLoading: true,
      };
    }

    case WireTypes.GENERATE_DEPOSIT_FAIL: {
      return {
        ...state,
        isGenerateDepositLoading: false,
      };
    }

    case WireTypes.GENERATE_DEPOSIT_SUCCESS: {
      return {
        ...state,
        isGenerateDepositLoading: false,
      };
    }

    case WireTypes.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS: {
      return {
        ...state,
        isGetExternalClientBankAccountsLoading: true,
      };
    }

    case WireTypes.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        isGetExternalClientBankAccountsLoading: false,
      };
    }

    case WireTypes.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        externalClientBankAccounts: [...action.payload, defaultExternalBankAccount],
        isGetExternalClientBankAccountsLoading: false,
      };
    }

    case WireTypes.GET_INVESTORS: {
      return {
        ...state,
        isGetInvestorsLoading: true,
      };
    }

    case WireTypes.GET_INVESTORS_FAIL: {
      return {
        ...state,
        isGetInvestorsLoading: false,
      };
    }

    case WireTypes.GET_INVESTORS_SUCCESS: {
      return {
        ...state,
        investors: action.payload,
        isGetInvestorsLoading: false,
      };
    }

    case WireTypes.ON_BANK_ACCOUNT_SELECTED: {
      const selectedBankAccount = state.bankAccounts.find(({ id }) => id === action.payload);
      return {
        ...state,
        selectedCurrencyId: selectedBankAccount.currency?.id ?? null,
      };
    }

    case WireTypes.ON_INVESTOR_SELECTED: {
      const selectedInvestor = state.investors.find(({ id }) => id === action.payload);
      return {
        ...state,
        bankAccounts: selectedInvestor?.bankAccounts || [],
        selectedCurrencyId: null,
      };
    }

    case AddFundsTypes.ON_MODAL_LEFT: {
      return {
        ...wireTransferViewStateInitial,
      };
    }

    case AddFundsTypes.ON_MODAL_OPENED: {
      return {
        ...wireTransferViewStateInitial,
      };
    }

    case WireTypes.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
