/**
 * Represents available events fired from backend and local technical ws events
 */
export enum LiveUpdateEventType {
  /**
   * Old functional, possible need to be refactored.
   * This event is used for cache requests, to avoid multiple same requests send
   */
  asyncResponse = 'ASYNC_RESPONSE',

  /**
   * Old functional, possible need to be refactored.
   */
  balanceChanged = 'BALANCE_CHANGED',

  /**
   * Old functional, possible need to be refactored.
   */
  coinChanged = 'COIN_CHANGED',

  /**
   * Deprecated, but still exist on backend
   */
  coinTrusted = 'COIN_TRUSTED',

  /**
   * local event
   * wallet page => coins (metals) table
   */
  coinBalanceChanged = 'COIN_BALANCE_CHANGED',

  /**
   * local event
   * deposits page => add funds modal => wire transfer view
   * profile page => onBoarding widget => add funds modal => wire transfer view
   */
  depositCreated = 'LOCAL_DEPOSIT_CREATED',

  /**
   * This event is used to notify frontend about new event on backend
   */
  graphQlSubscriptionNotification = 'NOTIFY',

  /**
   * backend event
   * profile page => profile details widget
   */
  kycChanged = 'KYC_CHANGED',

  /**
   * backend event
   * local event
   * shared config effects
   */
  logOut = 'LOG_OUT',

  /**
   * backend event
   * wallet page => coin balances table
   * buy metals page => portfolio
   */
  newTransaction = 'NEW_TRANSACTION',

  /**
   * local and backend event
   * wallet page => markets stable balances widget
   * buy metals page => portfolio
   * profile page for admin => balance widget
   */
  stableBalanceChanged = 'STABLE_BALANCE_CHANGED',

  /**
   * Old functional, possible need to be refactored.
   */
  userEdited = 'USER_EDITED',

  /**
   * Local system event.
   * Websockets connection was closed
   */
  wsClosed = 'wsClosed',

  /**
   * Local system event.
   * Websockets connection established
   */
  wsConnected = 'wsConnected',

  /**
   * Local system event.
   * Websocket responded with core error
   */
  wsError = 'wsError',

  /**
   * Local system event.
   * Refresh token operation during reconnect to websockets failed
   */
  wsRefreshSessionFailed = 'wsRefreshSessionFailed',

  /**
   * Local system event.
   * Websockets trying to connect with bad credentials
   */
  wsUnrecognizedSession = 'wsUnrecognizedSession',
}
