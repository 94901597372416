import React, { ChangeEventHandler, FC } from 'react';
import classnames from 'classnames';
import { Form } from 'antd';
import { Trans } from '@lingui/macro';

import shared from '../index.module.scss';
import s from './index.module.scss';
import Label from '@components/form/antd-form/label';
import { ReactComponent as FilterIconOff } from '@images/icons/filter-icon-off.svg';
import { ReactComponent as FilterIconOn } from '@images/icons/filter-icon-on.svg';
import { SearchTextProps, useModel } from './model';

const { Item } = Form;

const SearchText: FC<SearchTextProps> = (props) => {
  const { className, ...p } = props;

  return (
    <Item
      className={classnames(shared.wrapper, className, { [shared.disabled]: props.disabled })}
      name={props.name}
    >
      {/* TODO-2833: fix types */}
      {/* @ts-ignore */}
      <CustomInput {...p} />
    </Item>
  );
};

const CustomInput: FC<
  SearchTextProps & {
    onChange: ChangeEventHandler<HTMLInputElement>;
    value: string;
  }
> = (p) => {
  const m = useModel(p);

  return (
    <div className={s.wrapper}>
      <Label
        labelClass={shared.label}
        labelShiftedClass={shared.labelShifted}
        htmlFor="search-input"
        shifted={m.shifted}
        label={p.label || <Trans id="reusable.search.input.label">Search</Trans>}
        required={false}
      />
      <input
        className={s.input}
        onChange={p.onChange}
        onKeyPress={(event) => {
          // enter will clear search form
          if (event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        value={p.value}
        maxLength={100}
        onBlur={m.onBlur}
        onFocus={m.onFocus}
        disabled={p.disabled}
      />
      <div className={s.config} onClick={m.onConfigureSearchClicked}>
        {m.isFilterUsed ? (
          <FilterIconOn className={s.configIcon} />
        ) : (
          <FilterIconOff className={s.configIcon} />
        )}
      </div>
    </div>
  );
};

export default SearchText;
