import { useSelector } from 'react-redux';
import { configSelectors } from '@config/store/selectors';
import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import api from '@router/pages/primary-market/list/api';
import { handleBackendError } from '@modules/notify';
import { useState } from 'react';

export const useModel = ({ modal }) => {
  const userInfo = useSelector(configSelectors.userInfo);
  const isShowCustomerSurvey: boolean = userInfo?.isShowCustomerSurvey;
  const [lockUI, setLockUI] = useState<boolean>(false);
  const showModal = useCallDispatch(showModalAction);
  const onToBeginClicked = () => showModal({ modal, props: { onHideCustomerSurvey } });
  const onHideCustomerSurvey = async () => {
    try {
      setLockUI(true);
      await api.hideCustomerSurvey();
    } catch (error) {
      handleBackendError(error);
    } finally {
      setLockUI(false);
    }
  };

  return { isShowCustomerSurvey, onToBeginClicked, onHideCustomerSurvey, lockUI };
};
