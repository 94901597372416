import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { Permission } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

export enum Fields {
  amountToSell = 'amountToSell',
  currencyToBuy = 'currencyToBuy.id',
  currencyToSell = 'currencyToSell.id',
}

export interface Form {
  [Fields.amountToSell]: number;
  [Fields.currencyToBuy]: number;
  [Fields.currencyToSell]: number;
}

type StableData = {
  amount: number;
  currency: {
    asset: string;
    id: number;
  };
};
type GetAvailableStableBalancesResponse = {
  balance: {
    primary: {
      active: {
        stableBalance: StableData[];
      };
    };
  };
};
const getAvailableStableBalancesQuery = 'userProfile';
const getAvailableStableBalancesFields = [
  {
    balance: [
      { primary: [{ active: [{ stableBalance: ['amount', { currency: ['asset', 'id'] }] }] }] },
    ],
  },
];
const getAvailableStableBalances = makeQuery({
  permissions: {
    and: [Permission.BALANCE_VIEW_PRIMARY_MY, Permission.BALANCE_VIEW_SECONDARY_MY],
  },
  queryName: getAvailableStableBalancesQuery,
  queryFields: getAvailableStableBalancesFields,
  query: (): Promise<StableData[]> => {
    return stompClient
      .getData<GetAvailableStableBalancesResponse>(
        getAvailableStableBalancesQuery,
        sign(getAvailableStableBalancesQuery, getAvailableStableBalancesFields)
      )
      .then((response) => {
        return response.balance.primary.active.stableBalance;
      })
      .then((balances) => balances.filter((balance) => currenciesFilter(balance.currency)));
  },
});

type Currency = {
  id: number;
  code: string;
  order: number;
};
const getCurrenciesQuery = 'currencies';
const getCurrenciesFields = ['id', 'code', 'order'];
const getCurrencies = makeQuery({
  queryName: getCurrenciesQuery,
  queryFields: getCurrenciesFields,
  query: (): Promise<Currency[]> => {
    return stompClient
      .getData(getCurrenciesQuery, sign(getCurrenciesQuery, getCurrenciesFields))
      .then((list) => list.filter(currenciesFilter));
  },
});

const sendManualExchangeRequestQuery = 'sendEmailExchangeIsNotAvailable';
const sendManualExchangeRequestFields = ['value'];
const sendManualExchangeRequest = makeQuery({
  queryName: sendManualExchangeRequestQuery,
  queryFields: sendManualExchangeRequestFields,
  query: (userId: number, form: Form): Promise<void> => {
    const typedValues = {
      ...v.exchangeNotAvailableInput({
        exchangeNotAvailable: { ...form, client: { id: userId } },
      }),
    };

    return stompClient.getData(
      sendManualExchangeRequestQuery,
      sign(sendManualExchangeRequestQuery, sendManualExchangeRequestFields),
      typedValues
    );
  },
});

const api = {
  getCurrencies,
  getAvailableStableBalances,
  sendManualExchangeRequest,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:currency-exchange-manual-modal');

export default api;
