import { useEffect, useRef, useState, ComponentType, UIEvent } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useVirtual } from 'react-virtual';
import { useResizeDetector } from 'react-resize-detector';
import { debounce } from 'lodash';
import classnames from 'classnames';
import { useSyncRef } from '../utils';
import {
  BaseItem,
  Table as LoadableTable,
  BaseTableProps as OriginalTableProps,
  BodyComponent as LoadableBodyComponent,
  TableColumnType,
  TableProps as LoadableTableProps,
} from '../loadable-table';
import { DefaultError } from './default-error';
import { DefaultLoader } from './default-loader';
import { DefaultEmptyDataMessage } from './default-empty-data-message';
import { HeaderContext, HeaderWrapper, HeaderProps } from './header';
import { RowWrapper, RowProps } from './row';
import { VirtualPadding } from './virtual-padding';
import s from './index.module.scss';

export type {
  BaseItem,
  HasMore,
  ListKey,
  TableColumnType,
  TableColumnGroupType,
  OnLoadMore,
} from '../loadable-table';

export type { HeaderProps } from './header';
export type { RowProps } from './row';

// NOTE: estimateRowHeight and keyExtractor should be persistent since they
// trigger rerender.

// ***

// Since we use notifyUpdate to watch items' container height
// we can get an update during element render, if virtualization
// expected element height is different from the actual rendered
// element height. It isn't considered a problem since performance
// impact is relatively low. If we want to opt out while preserving
// backward compatibility, we need to add a prop with the default
// corresponding to current behavior and pass down notifyUpdate.

// ***

// v2.8.2

// We cannot pass row data as something like rawData[virtualRow.index],
// since if the row updates on data (calling measureRef prop) and item
// count between data updates is the same, a problem arises. When call
// measureRef, it will use the current key for the row, which may correspond
// to the previous row data (before rawData update). This leads to incorrect
// measurement reconstructing (since we get wrong values for some measureCache
// keys) and can lead to a bug.

// Links to responsible code:
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L197
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L92

// To solve this problem we construct a mapping between keys and data, and
// pass data corresponding to the row key as a prop to the row. To trigger
// updates, but preserve measureCache we update keyExtractor. We do not rely
// on estimateSize, since it drops measureCache.

// Key extractor usage as a dep:
// https://github.com/tannerlinsley/react-virtual/blob/8cb22fb46f8f7d680dfc7749b1aff1ca4e7ccbc3/src/index.js#L101

export type BaseTableProps<T extends BaseItem> = Omit<OriginalTableProps<T>, 'columns'> & {
  columns: Array<Omit<TableColumnType<T>, 'width'> & { width: number }>;
};

export type ErrorElement = JSX.Element;

export type EstimateRowHeight<T extends BaseItem> = (data: T) => number;

export type KeyExtractor<T extends BaseItem> = (data: T) => string | number;

export type LoaderElement = JSX.Element;

export type TableProps<T extends BaseItem, K> = Omit<
  LoadableTableProps<T, K>,
  'baseTableProps' | 'bodyComponent'
> & {
  baseTableProps: BaseTableProps<T>;
  errorElement?: ErrorElement;
  estimateRowHeight: EstimateRowHeight<T>;
  keyExtractor: KeyExtractor<T>;
  loaderElement?: LoaderElement;
  headerComponent?: ComponentType<HeaderProps>;
  rowComponent: ComponentType<RowProps<T>>;

  // Hack to avoid setting header height from outside.
  renderBody?: boolean;
};

const defaultErrorElement = <DefaultError />;

const defaultLoaderElement = <DefaultLoader />;

const defaultEmptyDataMessageElement = <DefaultEmptyDataMessage />;

export const Table = <T extends BaseItem, K>(props: TableProps<T, K>) => {
  const errorElement = props.errorElement ?? defaultErrorElement;
  const loaderElement = props.loaderElement ?? defaultLoaderElement;

  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const scrollbarWidthRef = useSyncRef(scrollbarWidth);

  const columnWidthList = useMemoOne(() => {
    const contentWindowWidth = props.baseTableProps.scroll.x - scrollbarWidth;
    const inherentColumnWidth = props.baseTableProps.columns.reduce((acc, c) => acc + c.width, 0);

    if (contentWindowWidth > inherentColumnWidth) {
      const widthAddition =
        (contentWindowWidth - inherentColumnWidth) / props.baseTableProps.columns.length;
      const widthList = props.baseTableProps.columns.map((c) => c.width + widthAddition);

      widthList[widthList.length - 1] -= 1;
      return widthList;
    }
    return props.baseTableProps.columns.map((c) => c.width);
  }, [props.baseTableProps.columns, props.baseTableProps.scroll.x, scrollbarWidth]);

  const headerContextValue = useMemoOne(
    () => ({
      columnWidthList,
      headerComponent: props.headerComponent,
    }),
    [columnWidthList]
  );

  const baseTableProps = useMemoOne<BaseTableProps<T>>(() => {
    return {
      ...props.baseTableProps,
      components: {
        ...props.baseTableProps?.components,
        header: {
          ...props.baseTableProps?.components?.header,
          wrapper: HeaderWrapper,
        },
      },
    };
  }, [props.baseTableProps]);

  const loadableBodyComponent = useMemoOne(() => {
    const wrappedBodyComponent: LoadableBodyComponent<T> = ({
      columnWidthList,
      error,
      hasMore,
      loading,
      notifyUpdate,
      rawData,
      setScrollableRef,
      tableHeight,
      tableWidth,
    }) => {
      const rawDataRef = useSyncRef(rawData);
      const outerRef = useRef<HTMLElement>(null);
      const centeredContentIndentRef = useRef<HTMLDivElement>(null);

      const totalColumnWidth = useMemoOne(() => {
        return columnWidthList.reduce((acc, w) => acc + w, 0);
      }, [columnWidthList]);

      const estimateSize = useCallbackOne(
        (index: number) => {
          return props.estimateRowHeight(rawDataRef.current[index]);
        },
        [props.estimateRowHeight]
      );

      // Usage of rawData is intentional: see comments for details.
      const keyExtractor = useCallbackOne(
        (index: number) => {
          return props.keyExtractor(rawData[index]);
        },
        [props.keyExtractor, rawData]
      );

      const rawDataMap = useMemoOne(() => {
        return rawData.reduce<Record<string | number, T>>((acc, rowData) => {
          const key = props.keyExtractor(rowData);
          acc[key] = rowData;
          return acc;
        }, {});
      }, [props.keyExtractor, rawData]);

      const setOuterRef = useCallbackOne(
        (el: HTMLElement) => {
          outerRef.current = el;
          setScrollableRef(el);
        },
        [setScrollableRef]
      );

      const updateCenteredContentIndent = useCallbackOne(
        debounce((offset: number) => {
          if (centeredContentIndentRef.current) {
            centeredContentIndentRef.current.style.width = offset + 'px';
          }
        }, 100),
        []
      );

      const onOuterScroll = useCallbackOne(
        (e: UIEvent<HTMLDivElement>) => {
          updateCenteredContentIndent(e.currentTarget.scrollLeft);
        },
        [updateCenteredContentIndent]
      );

      const [bottomPadding, setBottomPadding] = useState(0);

      const updateScrollbarWidth = useCallbackOne(() => {
        if (!outerRef.current) return;

        const scrollbarWidth = outerRef.current.offsetWidth - outerRef.current.clientWidth;

        if (scrollbarWidthRef.current !== scrollbarWidth) {
          setScrollbarWidth(scrollbarWidth);
        }
      }, []);

      const { height: innerHeight, ref: innerRef } = useResizeDetector({
        handleWidth: false,
        refreshMode: 'debounce',
        refreshRate: 100,
      });

      // Use instant (not debounced) resize observer to avoid case where
      // shrinking of the content is too slow and yields horizontal scrollbar
      // for a moment after vertical scrollbar appearance.
      useResizeDetector({
        handleWidth: false,
        onResize: updateScrollbarWidth,
        targetRef: innerRef,
      });

      const rowVirtualizer = useVirtual({
        estimateSize,
        keyExtractor,
        overscan: 2,
        parentRef: outerRef,
        size: rawData.length,
        paddingEnd: bottomPadding,
      });

      useEffect(() => {
        if (innerHeight !== undefined) {
          notifyUpdate();
        }
      }, [innerHeight]);

      // We can as well check height on the outer.
      useEffect(() => {
        window.addEventListener('resize', notifyUpdate);
        return () => {
          window.removeEventListener('resize', notifyUpdate);
        };
      }, []);

      useEffect(() => {
        if (!error && !hasMore && rawData.length > 0) {
          setBottomPadding(0);
        }
      }, [error, hasMore]);

      return (
        <div
          ref={setOuterRef}
          className={s.scrollable}
          style={{ overflow: 'auto', height: tableHeight, width: tableWidth }}
          onScroll={onOuterScroll}
        >
          <div
            ref={innerRef}
            style={{
              position: 'relative',
              height: rowVirtualizer.totalSize,
              width: totalColumnWidth,
            }}
          >
            {rowVirtualizer.virtualItems.map((virtualRow) => (
              <RowWrapper
                key={virtualRow.key}
                columnWidthList={columnWidthList}
                data={rawDataMap[virtualRow.key]}
                loading={loading}
                measureRef={virtualRow.measureRef}
                rowComponent={props.rowComponent}
                // We don't pass height in style object, since it will be equal
                // to estimated size on first render. If a consumer wants to use
                // estimated height as the row height they should specify it
                // explicitly, if they want to use content size for measuring
                // height, they should provide it like so
                // <div ref={containerRef}>{content}</div>.
                style={{
                  position: 'absolute',
                  top: virtualRow.start,
                }}
              />
            ))}
            {hasMore && !error ? (
              <VirtualPadding
                key={'loader'}
                className={s.centeredWrapper}
                heightSetter={setBottomPadding}
              >
                <div ref={centeredContentIndentRef} />
                <div className={s.centered} style={{ width: tableWidth }}>
                  {loaderElement}
                </div>
              </VirtualPadding>
            ) : null}
            {error ? (
              <VirtualPadding key={'error'} heightSetter={setBottomPadding}>
                {errorElement}
              </VirtualPadding>
            ) : null}
            {!error && !hasMore && !rawData.length ? (
              <VirtualPadding
                key={'empty-data'}
                className={s.centeredWrapper}
                heightSetter={setBottomPadding}
              >
                <div ref={centeredContentIndentRef} />
                <div className={classnames(s.centered, 'pt4', 'pb4')} style={{ width: tableWidth }}>
                  {defaultEmptyDataMessageElement}
                </div>
              </VirtualPadding>
            ) : null}
          </div>
        </div>
      );
    };

    return props.renderBody ? wrappedBodyComponent : () => null;
  }, [
    props.estimateRowHeight,
    props.rowComponent,

    // We use list key to update data in case list key changed while item
    // count is the same.
    props.listKey,

    // We change this value after receiving header height.
    props.renderBody,
  ]);

  return (
    <HeaderContext.Provider value={headerContextValue}>
      <LoadableTable
        baseTableProps={baseTableProps}
        bodyComponent={loadableBodyComponent}
        hasMore={props.hasMore}
        listKey={props.listKey}
        onLoadMore={props.onLoadMore}
        columnWidthList={columnWidthList}
      />
    </HeaderContext.Provider>
  );
};
