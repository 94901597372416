import { useQuery } from '@hooks/core';

import api from './api';

export const useBankDetails = () => {
  const { data: bankDetailsIds, isLoading: isBankDetailsIdsLoading } = useQuery(
    api.getBankDetails,
    { initial: [] }
  );

  const isUserHasBankDetails = !!bankDetailsIds?.length;

  return {
    isBankDetailsIdsLoading,
    isUserHasBankDetails,
  };
};
