import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';

type UploadFile = {
  id: number;
  contentType: string;
  name: string;
  size: number;
  uuid: string;
};

export type CoinInfoFile = {
  id?: number;
  file: UploadFile;
  title: string;
};

type CoinInfo = {
  primaryMarketProductInfo: string;
};

type Unit = {
  label: string;
  inGrams: number;
};

type RedeemOption = {
  id: number;
  amount: number;
  description: string;
  minimalQuantity: number;
  name: string;
  price: number;
  priceType: string;
  unit: Unit;
};

export type Coin = {
  id: number;
  asset: string;
  coinInfo: CoinInfo;
  infoFiles: CoinInfoFile[];
  productInfo: string;
  redeemOptions: RedeemOption[];
};

const getCoinQuery = 'coinDetailed';
const getCoinFields = [
  'id',
  'asset',
  { coinInfo: ['primaryMarketProductInfo'] },
  { infoFiles: ['id', { file: ['id', 'contentType', 'name'] }, 'title'] },
  'productInfo',
  {
    redeemOptions: [
      'id',
      'amount',
      'description',
      'minimalQuantity',
      'name',
      'price',
      'priceType',
      { unit: ['label', 'inGrams'] },
    ],
  },
];
const getCoin = makeQuery({
  permissions: Permission.ASSET_VIEW_RESTRICTED,
  queryName: getCoinQuery,
  queryFields: getCoinFields,
  query: (id: number): Promise<Coin> => {
    return stompClient.getData(
      getCoinQuery,
      sign(getCoinQuery, getCoinFields),
      v.idInput({ coin: { id } })
    );
  },
});

const api = {
  getCoin,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:coin-details');

export default api;
