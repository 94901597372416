import React from 'react';
import saveAs from 'file-saver';

import './styles.scss';
import { ReactComponent as Preloader } from '@images/icons/button-preloader.svg';
import { useImageModel } from './model';

export const Image = ({ height, className, downloadable = true, uploadFile, onError }) => {
  const { data, isError, src } = useImageModel(uploadFile, onError);

  if (isError) {
    return null;
  }

  return src ? (
    <img
      className={className + (downloadable && ' cursor-pointer-style')}
      alt={uploadFile.name}
      src={src}
      height={height}
      onClick={() => (downloadable ? saveAs(data, uploadFile.name) : false)}
    />
  ) : (
    <div
      className="tm2-image-preloader"
      style={{ height: height, width: height && (height * 16) / 9 }}
    >
      <Preloader className="tm2-image-preloader-icon tm2-icon-fill" />
    </div>
  );
};

export default Image;
