import { useMemo, useState } from 'react';
import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import api from './api';

export const useCurrencies = () => {
  const [selectedCurrencyToSellId, setSelectedCurrencyToSellId] = useState<number>(0);

  const { data: currenciesData, isLoading: isCurrenciesLoading } = useQuery(api.getCurrencies, {
    initial: [],
  });

  const currencies: Array<SelectItem> = useMemo(() => {
    return currenciesData?.map((currency) => ({
      label: currency.code,
      value: currency.id,
      disabled: currency.id === selectedCurrencyToSellId,
    }));
  }, [currenciesData, selectedCurrencyToSellId]);

  return { currencies, isCurrenciesLoading, currenciesData, setSelectedCurrencyToSellId };
};

export const useAvailableStableBalances = () => {
  const { data: availableStableBalancesData, isLoading: isAvailableStableBalancesLoading } =
    useQuery(api.getAvailableStableBalances);

  const availableStableBalances: Array<SelectItem & { amount: number }> = useMemo(() => {
    return availableStableBalancesData?.map((stableData) => ({
      label: stableData.currency.asset,
      value: stableData.currency.id,
      amount: stableData.amount,
    }));
  }, [availableStableBalancesData]);

  return { availableStableBalances, isAvailableStableBalancesLoading, availableStableBalancesData };
};
