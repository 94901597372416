import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Button, Dropdown, Menu } from 'antd';

import './index.scss';
import { Lang } from '@models/core';
import { ButtonToggle } from '@components/button';
import { ReactComponent as GlobeIcon } from '@images/icons/globe.svg';
import { theme } from '@constants/theme';
import { useModel } from './model';

const { Divider, Item } = Menu;

export const LanguageSelector: FC<{
  className?: string;
  theme: theme;
}> = memo(({ className, theme: _theme }) => {
  const m = useModel();
  const themeClass = _theme === theme.dark ? 'tm2-dark' : 'tm2-light';
  const currentLanguage = m.lang === 'CN' ? '中文简体' : 'English';

  return (
    <Dropdown
      className={classnames({ 'is-disabled': m.lockUI })}
      trigger={['click']}
      onVisibleChange={m.setExpanded}
      overlay={
        <Menu className={classnames('tm2-language-selector-menu', themeClass)} theme={theme.dark}>
          <Item
            className="tm2-language-selector-menu-item"
            key="en"
            style={{ pointerEvents: m.lang === Lang.en ? 'none' : 'all' }}
            onClick={() => m.onChangeLanguageClicked('EN')}
          >
            English
          </Item>

          <Divider className="tm2-language-selector-menu-divider" key="divider-1" />

          <Item
            className="tm2-language-selector-menu-item"
            key="cn"
            style={{ pointerEvents: m.lang === Lang.cn ? 'none' : 'all' }}
            onClick={() => m.onChangeLanguageClicked('CN')}
          >
            中文简体
          </Item>
        </Menu>
      }
    >
      <Button className={classnames('tm2-language-selector', className, themeClass)}>
        <div className="tm2-language-selector-icon-left mr-xs">
          <GlobeIcon />
        </div>
        <span className="mr-auto">{currentLanguage}</span>
        <ButtonToggle expanded={m.expanded} />
      </Button>
    </Dropdown>
  );
});
