import { useSelector } from 'react-redux';
import { useCallback } from 'react';

import { configSelectors } from '@config/core';
import { mapTableSorting } from '@helper/sorting';
import type { KeyExtractor, RowConfig, SortOrder } from '@components/table2/base';
import type { LoadData } from '@components/table2/simplified';
import { useTable } from '@components/table2/simplified';
import { permissionsSelectors } from '@permissions/store/selectors';

import api, { GetWithdrawalsSortKey } from './api';
import type { Withdrawal } from './api';

export const rowConfig: RowConfig<Withdrawal> = {
  height: 240,
};

export enum ColumnKey {
  actions = 'actions',
  number = 'number',
  dateCreated = 'dateCreated',
  settledAt = 'settledAt',
  status = 'status',
  client = 'client',
  clientId = 'clientId',
  amount = 'amount',
  currency = 'currency',
  amountSettled = 'amountSettled',
  billingInfo = 'billingInfo',
  purpose = 'purpose',
  sourceOfFunds = 'sourceOfFunds',
  externalId = 'externalId',
}

const initialSortOrder: SortOrder = [
  { field: ColumnKey.dateCreated, direction: 'descend', key: ColumnKey.dateCreated },
];

const sortMap = {
  [ColumnKey.clientId]: GetWithdrawalsSortKey.id,
  [ColumnKey.dateCreated]: GetWithdrawalsSortKey.createdAt,
};

export type Model = ReturnType<typeof useModel>;
export const useModel = () => {
  const i18n = useSelector(configSelectors.i18n);

  const isInvestor = useSelector(configSelectors.isInvestor);

  const isHasAccess = useSelector(permissionsSelectors.isHasAccess);

  const keyExtractor = useCallback<KeyExtractor<Withdrawal>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallback<LoadData<Withdrawal, string>>(({ nextPage, filter, sortOrder }) => {
    const ordering = mapTableSorting(sortOrder, sortMap);
    return api.getWithdrawalsPage({
      page: nextPage,
      filter: filter ?? null,
      ordering,
    });
  }, []);

  const { tableInstance, setData, setFilter } = useTable<Withdrawal, string>({
    keyExtractor,
    loadData,
    initialSortOrder,
  });

  const onReplaceWithdrawal = useCallback(
    (withdrawal: Withdrawal) => {
      setData((currentData) => {
        return currentData.map((w) => (w.id === withdrawal.id ? withdrawal : w));
      });
    },
    [setData]
  );

  const addWithdrawal = useCallback(
    (withdrawal: Withdrawal) => {
      setData((currentData) => {
        return [withdrawal, ...currentData];
      });
    },
    [setData]
  );

  const onFiltersChanged = useCallback((filter: string) => setFilter(() => filter), [setFilter]);

  return {
    addWithdrawal,
    i18n,
    isHasAccess,
    isInvestor,
    onFiltersChanged,
    onReplaceWithdrawal,
    tableInstance,
  };
};
