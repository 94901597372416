import React, { FC } from 'react';
import { connect } from 'react-redux';

import { configSelectors } from '@config/core';

const mapStateToProps = (root) => ({
  isShowPreloader: configSelectors.isShowGlobalPreloader(root),
});

const ConnectedPreloaderPage: FC<{
  isForceShow?: boolean;
  isShowPreloader: boolean;
}> = ({ isForceShow, isShowPreloader }) => {
  return (
    <div
      className="tm2-global-preloader"
      style={{ display: isShowPreloader || isForceShow ? 'block' : 'none' }}
    />
  );
};

export const PreloaderPage = connect(mapStateToProps)(ConnectedPreloaderPage);
