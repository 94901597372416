import { useCallback, useContext, useEffect, useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { CMBankAccountStatus } from '../hooks';
import { Context } from './context';

export const useBankAccountStatusTranslations = () => {
  const { i18n, lang } = useI18n();
  const translations: { [k in CMBankAccountStatus]: string } = useMemo(
    () => ({
      ACTIVE: i18n._(
        defineMessage({ id: 'cm.bank_accounts.bank_acc_status.label.active', message: 'Active' })
      ),
      NOT_ACTIVE: i18n._(
        defineMessage({
          id: 'cm.bank_accounts.bank_acc_status.label.not_active',
          message: 'Not active',
        })
      ),
    }),
    [lang]
  );
  return translations;
};

export const useEmitBankAccountUpdated = () => {
  const { state } = useContext(Context);
  const events = state.events;

  const emitUpdate = useCallback((id: number) => events.emit(id), [events]);
  return emitUpdate;
};

export const useSubscribeOnBankAccountUpdates = (listener: (id: number) => void) => {
  const { state } = useContext(Context);
  const events = state.events;

  useEffect(() => {
    const subscription = events.subscribe(listener);
    return () => subscription.unsubscribe();
  }, [state.events]);
};
