import { useParams } from 'react-router';
import { useMemo, useState } from 'react';

import { useQuery } from '@hooks/core';

import api from './api';

export const useUMSAssetCode = () => {
  const p = useParams<{ coinId: string }>();

  const { data: umsAssetInitial } = useQuery(() => api.getUMSAsset(+p.coinId));

  const [createdUMSAsset, onCreateUMSAsset] = useState('');

  const umsAssetCode = useMemo(
    () => umsAssetInitial || createdUMSAsset,
    [createdUMSAsset, umsAssetInitial]
  );

  return { umsAssetCode, onCreateUMSAsset };
};
