import React from 'react';
import { sharedApi } from '@services/api';

export const useFileUploaderModel = (onProgress, onFileUploaded) => {
  const [fileField, setFileField] = React.useState();
  const [isProgress, setIsProgress] = React.useState(false);

  React.useEffect(() => onProgress && onProgress(isProgress), [isProgress]);

  const onFileSelected = async (event) => {
    if (!event.target.files.length) return;

    setIsProgress(true);

    try {
      const data = await sharedApi.uploadFile(event.target);
      onFileUploaded && onFileUploaded(data);
    } catch (e) {
    } finally {
      setIsProgress(false);
    }
  };

  return { fileField, setFileField, onFileSelected, isProgress };
};
