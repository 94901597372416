import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

export interface BankDetails {
  currency: {
    code: string;
    id: number;
  };
  id: number;
}

const getBankDetailsQuery = 'userBankAccounts';
const getBankDetailsFields = ['id', { currency: ['code', 'id'] }];
const getBankDetails = makeQuery({
  queryName: getBankDetailsQuery,
  queryFields: getBankDetailsFields,
  query: (): Promise<Array<BankDetails>> => {
    return stompClient.getData(
      getBankDetailsQuery,
      sign(getBankDetailsQuery, getBankDetailsFields)
    );
  },
});

const api = {
  getBankDetails,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:add-funds-modal');

export default api;
