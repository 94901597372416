import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { SortItem, TableData, TableResponse } from '@models/core';
import { MarketingTableColumnIds } from './store/model';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';

export interface MarketingCompaniesItem {
  id: number;
  name: string;
}

export interface MarketingCompanyItem {
  numberOfUsers: number;
  amountOfVirtualMoneyPerClient: number;
  systemEvent: { id: number };
  targetUserRole: { id: number };
  numberOfUsersLeft: number;
}

const getAllMarketingCompaniesQuery = 'allMarketingCompaniesPage';
const getAllMarketingCompaniesFields = [{ data: ['id', 'name'] }, 'index', 'hasMore'];
const getAllMarketingCompanies = makeQuery({
  permissions: Permission.SETTING_MARKETING_COMPANY,
  queryName: getAllMarketingCompaniesQuery,
  queryFields: getAllMarketingCompaniesFields,
  query: (
    pageNumber: number,
    sort: Array<SortItem<MarketingTableColumnIds>>
  ): Promise<TableData<MarketingCompaniesItem>> => {
    const ordering = [];
    sort.forEach((column: SortItem<MarketingTableColumnIds>) =>
      column.fields.forEach((field: string) =>
        ordering.push({ direction: column.direction, field: field })
      )
    );

    const typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };

    return stompClient
      .getData(
        getAllMarketingCompaniesQuery,
        sign(getAllMarketingCompaniesQuery, getAllMarketingCompaniesFields),
        typedValues
      )
      .then((response: TableResponse<MarketingCompaniesItem>) => ({
        data: response.data,
        isHasMore: response.hasMore,
      }));
  },
});

const api = {
  getAllMarketingCompanies,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:marketing');

export default api;
