import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';
import { useI18n } from '@hooks/i18n';

export const useDepositsStatuses = () => {
  const { i18n, lang } = useI18n();

  return useMemo(
    () => ({
      CREATED: i18n._(defineMessage({ id: 'deposit_statuses.created', message: 'Created' })),
      PAID: i18n._(defineMessage({ id: 'deposit_statuses.paid', message: 'Paid' })),
      PARTLY_PAID: i18n._(
        defineMessage({ id: 'deposit_statuses.partly_paid', message: 'Partly paid' })
      ),
      CANCELLED: i18n._(defineMessage({ id: 'deposit_statuses.cancelled', message: 'Cancelled' })),
      BALANCE_UPDATED: i18n._(
        defineMessage({ id: 'deposit_statuses.balance_updated', message: 'Balance updated' })
      ),
    }),
    [lang]
  );
};
