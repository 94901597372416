import React, { ComponentType, FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Permission, Role } from '@permissions/core';
import { UserInfo } from '@config/core';
import { usePostRegistrationGuardModel } from './model';
import { Any } from '@models/core';

export const PostRegistrationGuard: FC<{
  exact?: boolean;
  extraCheckFn?: (user: UserInfo) => boolean;
  forcedAccess?: boolean;
  layout: ComponentType;
  page: ComponentType<Any>;
  path: string;
  permissions?: Array<Permission>;
  roles?: Array<Role>;
}> = ({
  extraCheckFn,
  forcedAccess,
  layout: Layout,
  page: Page,
  path,
  permissions,
  roles,
  ...rest
}) => {
  const { isHasAccess, redirectTo } = usePostRegistrationGuardModel(
    forcedAccess,
    permissions,
    roles,
    null,
    extraCheckFn
  );

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isHasAccess ? (
          <Layout>
            <Page {...matchProps} />
          </Layout>
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};
