import { ReduxAction } from '@models/redux';
import { AuthorizedInfoPayload, BootstrapActionsTypes as T } from './model';

class CheckLastSessionStatusAction extends ReduxAction<T.CHECK_LAST_SESSION_STATUS, void> {
  public readonly type: T.CHECK_LAST_SESSION_STATUS = T.CHECK_LAST_SESSION_STATUS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class ConfigureNewKeycloakSessionAction extends ReduxAction<
  T.CONFIGURE_NEW_KEYCLOAK_SESSION,
  void
> {
  public readonly type: T.CONFIGURE_NEW_KEYCLOAK_SESSION = T.CONFIGURE_NEW_KEYCLOAK_SESSION;
  public constructor(public payload: void) {
    super(payload);
  }
}

class InitiateAppAction extends ReduxAction<T.INITIATE_APP, void> {
  public readonly type: T.INITIATE_APP = T.INITIATE_APP;
  public constructor(public payload: void) {
    super(payload);
  }
}

class SetAuthorizedInfoAction extends ReduxAction<T.SET_AUTHORIZED_INFO, AuthorizedInfoPayload> {
  public readonly type: T.SET_AUTHORIZED_INFO = T.SET_AUTHORIZED_INFO;
  public constructor(public payload: AuthorizedInfoPayload) {
    super(payload);
  }
}

export const bootstrapServiceActions = {
  checkLastSessionStatus: () => new CheckLastSessionStatusAction().get(),
  configureNewKeycloakSession: () => new ConfigureNewKeycloakSessionAction().get(),
  initiateApp: () => new InitiateAppAction().get(),
  setAuthorizedInfo: (info: AuthorizedInfoPayload) => new SetAuthorizedInfoAction(info).get(),
};

export type BootstrapServiceActionsTypes =
  | CheckLastSessionStatusAction
  | ConfigureNewKeycloakSessionAction
  | InitiateAppAction
  | SetAuthorizedInfoAction;

export declare namespace BootstrapServiceActions {
  type checkLastSessionStatus = CheckLastSessionStatusAction;
  type configureNewKeycloakSession = ConfigureNewKeycloakSessionAction;
  type initiateApp = InitiateAppAction;
  type setAuthorizedInfo = SetAuthorizedInfoAction;
}
