import { useContext, useEffect } from 'react';

import { useQuery } from '@hooks/core';
import { session } from '@services/session';
import api from './api';
import { Context } from './context';

export const useFormInfo = (structure: Parameters<typeof api.getHubspotForm>[0]) => {
  const { dispatch } = useContext(Context);

  const {
    data,
    isLoading: isFormLoading,
    refresh: refreshHubspot,
  } = useQuery(
    () =>
      api.getHubspotForm(structure).catch((error) => {
        dispatch({ type: 'ON_TOGGLE_SHOW_HUBSPOT_FAILED', payload: true });
        return Promise.reject(error);
      }),
    { initial: { formInitial: {}, formRows: [] } }
  );

  useEffect(() => {
    const subscription = session.onLangChanged(() => refreshHubspot());
    return () => subscription.unsubscribe();
  }, []); // eslint-disable-line

  return {
    formInitial: data?.formInitial,
    formRows: data?.formRows,
    isFormLoading: isFormLoading,
  };
};
