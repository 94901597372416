import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { NamePath } from 'rc-field-form/lib/interface';

import s from './index.module.scss';
import { ButtonPrimary } from '@components/button';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import {
  AddRowButton,
  DeleteRowButton,
  Form,
  FormList,
  InputNumber,
  Select,
  SubmitButton,
} from '@components/form';
import { Divider } from '@components/divider';
import { If } from '@components/if';
import { CurrencySelect } from '@modules/currencies';
import { maxDecimals } from '@constants/math';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { OfferByMe } from '../../shared/types';
import { Fields, formId, formInitial, useModel } from './model';

export const PlaceOfferModal: MFC<{
  onOfferCreated: (o: OfferByMe) => void;
}> = ({ isVisible, onOfferCreated }) => {
  const m = useModel(onOfferCreated);

  return (
    <Modal onClose={m.closeModal} isVisible={isVisible} loading={m.lockUI} disabled={m.lockUI}>
      <ModalTitle>
        <If condition={m.noCoinsAvailable}>
          <Trans id={'bids.bids_list.place_bid_modal.no_metals_available'}>
            No metals available for trading
          </Trans>
        </If>
        <If condition={!m.noCoinsAvailable}>
          <Trans id="bids.bids_list.place_bid_modal.place.bid">Place offer</Trans>
        </If>
      </ModalTitle>

      <ModalContent>
        <If condition={m.noCoinsAvailable}>
          <Trans id={'bids.bids_list.place_bid_modal.no_metals_available_description'}>
            You have no metals available for trading, please contact Customer support
          </Trans>
        </If>
        <If condition={!m.noCoinsAvailable}>
          <Form name={formId} initialValues={formInitial} onChange={m.onFormChange}>
            <Select
              name={Fields.coin}
              label={<Trans id="bids.bids_list.place_bid_modal.symbol">Symbol</Trans>}
              data={m.coins}
              disabled={m.lockUI}
              required={true}
            />

            <div className="mb-sm">
              <Trans id="place_offer_modal.issuers_price">
                Issuer's price without the execution fee
              </Trans>
            </div>

            <FormList name={Fields.prices}>
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name }) => (
                    <div className={s.row} key={key}>
                      <CurrencySelect
                        name={[name, Fields.pricesCurrency]}
                        fieldKey={[key, Fields.pricesCurrency]}
                        className="mr-md"
                        label={<CurrencyLabel position={name} />}
                        filter={m.currenciesSelected}
                        disabled={m.lockUI}
                        required={true}
                      />

                      <InputNumber
                        name={[name, Fields.pricesQuantity]}
                        fieldKey={[key, Fields.pricesQuantity]}
                        className="mr-xs"
                        label={<Trans id="place_offer_modal.price_per_unit">Price per unit</Trans>}
                        min={0}
                        maxDecimals={maxDecimals}
                        disabled={m.lockUI}
                        required={true}
                      />

                      {fields.length > 1 && <DeleteRowButton onClick={() => remove(name)} />}

                      <UnitHidden name={[name, Fields.pricesUnit]} value={m.selectedUnit?.id} />
                    </div>
                  ))}

                  {m.currencies.length > fields.length && (
                    <AddRowButton
                      className="mb-md"
                      text={<Trans id="bids.bids_list.place_bid_modal.button.add">Add</Trans>}
                      disabled={m.lockUI}
                      onClick={() => add()}
                    />
                  )}
                </>
              )}
            </FormList>

            <InputNumber
              name={Fields.pricesQuantity}
              label={<Trans id="place_offer_modal.units">Units</Trans>}
              extra={<MaxAmount value={m.amountMax} />}
              min={0}
              max={m.amountMax}
              maxDecimals={0}
              disabled={m.lockUI}
              required={true}
            />

            <UnitHidden name={Fields.quantityUnit} value={m.selectedUnit?.id} />
          </Form>

          <Divider />

          {!!m.results.taxPct && (
            <div className={classnames(s.resultRow, 'mb-xs')}>
              <span>
                <Trans id="bids.bids_list.place_bid_modal.tax">Tax</Trans>&nbsp;({m.results.taxPct}
                %)
              </span>
              <span>
                {m.results.costTax && m.results.currency
                  ? formatMoney(m.results.costTax, { pre: m.results.currency })
                  : '-/-'}
              </span>
            </div>
          )}

          <div className={s.resultRow}>
            <Trans id="bids.bids_list.place_bid_modal.total">Total</Trans>
            <span>
              {m.results.costTotal && m.results.currency
                ? formatMoney(m.results.costTotal, { pre: m.results.currency })
                : '-/-'}
            </span>
          </div>
        </If>
      </ModalContent>

      <ModalFooter>
        <If condition={m.noCoinsAvailable}>
          <ButtonPrimary fullWidth={true} disabled={m.lockUI} onClick={m.closeModal}>
            <Trans id="button.close">Close</Trans>
          </ButtonPrimary>
        </If>
        <If condition={!m.noCoinsAvailable}>
          <SubmitButton
            name={formId}
            fullWidth={true}
            disabled={m.lockUI}
            onAction={m.onPlaceOfferAction}
          >
            <Trans id="button.place.bid">Place offer</Trans>
          </SubmitButton>
        </If>
      </ModalFooter>
    </Modal>
  );
};

const CurrencyLabel: FC<{ position: number }> = ({ position }) =>
  position === 0 ? (
    <Trans id="place_offer_modal.main_currency">Main Currency</Trans>
  ) : (
    <Trans id="place_offer_modal.other_currency">Other Currency</Trans>
  );

const MaxAmount: FC<{ value: number }> = ({ value }) => (
  <Trans id="bids.bids_list.place_bid_modal.max_quantity.max">{formatToken(value)} max</Trans>
);

const UnitHidden: FC<{
  // used to automatically add unit id to form
  name: NamePath;
  value: number;
}> = ({ name, value }) =>
  !value ? null : (
    <div style={{ display: 'none' }}>
      <Select name={name} label="" data={[{ label: '', value }]} initialValue={value} />
    </div>
  );
