import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { columnFactory, OnSort as OnSortGeneric, Column } from '../shared/table/columns';
import {
  SortItem as GenericSortItem,
  SortOrder as GenericSortOrder,
} from '../shared/table/sorting';
import tableStyles from '../shared/table/index.module.scss';

export type { SortDirection } from '../shared/table/sorting';

export type TableCoin = {
  amount: number;
  asset: string;
  brandName: string;
  metal: string;
};

export type SortItem = GenericSortItem<TableCoin>;

export type SortOrder = GenericSortOrder<TableCoin>;

export type OnSort = OnSortGeneric<keyof TableCoin>;

const MarketQuantity = () => (
  <Trans id={'wallet.wallet-primary.table.amount.market-quantity'}>Quantity</Trans>
);

export function useColumns(data: TableCoin[] | null, onSort: OnSort) {
  return useMemo<Column<TableCoin>[]>(
    () =>
      data === null
        ? null
        : [
            columnFactory({
              cell: (data: TableCoin) => data.asset,
              field: 'asset',
              head: <Trans id={'wallet.wallet-primary.table.symbol'}>Symbol</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: TableCoin) => data.amount,
              field: 'amount',
              head: (
                <span className={tableStyles.tableHeadCellMultiRowContent}>
                  <MarketQuantity />
                  <small className={tableStyles.tableHeadCellSecondaryText}>
                    <Trans id={'wallet.wallet-primary.table.amount.primary'}>Primary Market</Trans>
                  </small>
                </span>
              ),
              onSort,
              className: tableStyles.tableHeadCellMultiRow,
            }),

            columnFactory({
              cell: (data: TableCoin) => data.brandName,
              field: 'brandName',
              head: <Trans id={'wallet.wallet-primary.table.brand'}>Brand</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: TableCoin) => data.metal,
              field: 'metal',
              head: <Trans id={'wallet.wallet-primary.table.metal'}>Metal</Trans>,
              onSort,
            }),
          ],
    [data, onSort]
  );
}
