import { createSelector } from 'reselect';
import { Selector } from '@models/redux';
import { VaultBalanceDetailsState } from './model';
import { VaultBalanceDetailsHistoryItem as HistoryItem } from '../../vaults-api.service';
import { UploadedFile } from '@models/core';

const vaultBalanceDetailsState: Selector<VaultBalanceDetailsState> = (root) =>
  root.main?.vaults?.vaultBalanceDetailsModal;

// TODO-2833: fix types
// @ts-ignore
const balanceHistory: Selector<Array<HistoryItem>> = createSelector(
  [(r) => vaultBalanceDetailsState(r)?.balanceHistory],
  (history: Array<HistoryItem>) =>
    history.slice(1).map((item: HistoryItem) => ({
      ...item,
      vaultBalance: {
        ...item.vaultBalance,
        createdAt: new Date(item.vaultBalance.createdAt).toLocaleString(),
      },
    }))
);

const latestBalanceCreator: Selector<string> = (root) =>
  latestBalance(root)?.vaultBalance?.creator?.displayName;

// TODO-2833: fix types
// @ts-ignore
const latestBalance: Selector<HistoryItem> = createSelector(
  [(r) => vaultBalanceDetailsState(r)?.balanceHistory],
  (history: Array<HistoryItem>) => history[0]
);

// TODO-2833: fix types
// @ts-ignore
const latestBalanceCreatedTime: Selector<string> = createSelector(
  [(r) => latestBalance(r)?.vaultBalance?.createdAt],
  (time: string) => time && new Date(time).toLocaleString()
);

const latestBalanceReport: Selector<UploadedFile> = (root) => latestBalance(root)?.report;

const lockUI: Selector<boolean> = (root) =>
  vaultBalanceDetailsState(root).isGetBalanceHistoryLoading;

export const vaultBalanceDetailsSelectors = {
  balanceHistory,
  vaultBalanceDetailsState,
  latestBalance,
  latestBalanceCreatedTime,
  latestBalanceCreator,
  latestBalanceReport,
  lockUI,
};
