import { BeneficialOwner } from '../beneficial-api.service';
import { BeneficialOwnersServiceActionsTypes } from './service.acions';
import { BeneficialOwnersUserActionsTypes } from './user.acions';

export interface BlockedMetalsUpdatedEvent {
  metals: Array<string>;
  ownerId: number;
}

export interface LoadMoreBeneficialOwnersPayload {
  metal: string;
  page: number;
}

export interface BeneficialOwnersState {
  beneficialOwners: Array<BeneficialOwner>;
  digitalMetals: Array<string>;
  digitalMetalSelected: string;
  investorSelected: number;
  isHasMore: boolean;
  isBeneficialOwnersLoading: boolean;
  isDigitalMetalsLoading: boolean;
  isDigitalMetalsReverse: boolean;
  pageIndex: number;
}

export const beneficialOwnersStateInitial: BeneficialOwnersState = {
  beneficialOwners: [],
  digitalMetals: [],
  digitalMetalSelected: '',
  investorSelected: null,
  isHasMore: true,
  isBeneficialOwnersLoading: false,
  isDigitalMetalsLoading: false,
  isDigitalMetalsReverse: false,
  pageIndex: 0,
};

export enum BeneficialOwnersActionsTypes {
  EVENT_BLOCKED_METALS_UPDATED = 'BENEFICIAL_OWNERS.EVENT_BLOCKED_METALS_UPDATED',
  GET_DIGITAL_METALS = 'BENEFICIAL_OWNERS.GET_DIGITAL_METALS',
  GET_DIGITAL_METALS_FAIL = 'BENEFICIAL_OWNERS.GET_DIGITAL_METALS_FAIL',
  GET_DIGITAL_METALS_SUCCESS = 'BENEFICIAL_OWNERS.GET_DIGITAL_METALS_SUCCESS',
  LOAD_MORE_BENEFICIAL_OWNERS = 'BENEFICIAL_OWNERS.LOAD_MORE_BENEFICIAL_OWNERS',
  LOAD_MORE_BENEFICIAL_OWNERS_FAIL = 'BENEFICIAL_OWNERS.LOAD_MORE_BENEFICIAL_OWNERS_FAIL',
  LOAD_MORE_BENEFICIAL_OWNERS_SUCCESS = 'BENEFICIAL_OWNERS.LOAD_MORE_BENEFICIAL_OWNERS_SUCCESS',
  ON_BENEFICIAL_OWNERS_TABLE_SCROLLED = 'BENEFICIAL_OWNERS.ON_BENEFICIAL_OWNERS_TABLE_SCROLLED',
  ON_DIGITAL_METAL_SELECTED = 'BENEFICIAL_OWNERS.ON_DIGITAL_METAL_SELECTED',
  ON_DIGITAL_METAL_SORT_CLICKED = 'BENEFICIAL_OWNERS.ON_DIGITAL_METAL_SORT_CLICKED',
  ON_INVESTOR_SELECTED = 'BENEFICIAL_OWNERS.ON_INVESTOR_SELECTED',
  ON_PAGE_LEFT = 'BENEFICIAL_OWNERS.ON_PAGE_LEFT',
  ON_PAGE_OPENED = 'BENEFICIAL_OWNERS.ON_PAGE_OPENED',
  ON_TOGGLE_BLOCK_OWNER_BUTTON_CLICKED = 'BENEFICIAL_OWNERS.ON_TOGGLE_BLOCK_OWNER_BUTTON_CLICKED',
}

export type BeneficialOwnersPageActions =
  | BeneficialOwnersUserActionsTypes
  | BeneficialOwnersServiceActionsTypes;
