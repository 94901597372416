import sign from 'tm2sign.macro';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { TableResponse } from '@models/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Fields } from './actions-row/api';

export type Filters = {
  [Fields.status]?: string;
  [Fields.investor]?: number;
};

export interface BankAccount {
  currency: {
    code: string;
    id: number;
  };
  id: number;
}

export enum DepositStatus {
  balanceUpdated = 'BALANCE_UPDATED',
  cancelled = 'CANCELLED',
  created = 'CREATED',
  paid = 'PAID',
  partlyPaid = 'PARTLY_PAID',
}

export interface Deposit {
  amount: number;
  bankAccount: BankAccount;
  clientComment: string;
  creationDate: string;
  id: number;
  investorUser: {
    id: string;
    displayName: string;
  };
  number: number;
  payment: {
    accountDetails: string;
    amount: number;
    creationDate: string;
    amountSettled: string;
  };
  status: DepositStatus;
  externalId: string;
  bankName: string;
  purpose: string;
  sourceOfFunds: string;
  reference: string;
}

type QueryDepositsPage = TableResponse<Deposit>;

const getDepositsPageQuery = 'invoicesPage';
const getDepositsPageFields = [
  {
    data: [
      'id',
      'number',
      'status',
      'amount',
      'creationDate',
      'clientComment',
      'externalId',
      'bankName',
      'purpose',
      'sourceOfFunds',
      'reference',
      { investorUser: ['displayName', 'id'] },
      { payment: ['amount', 'accountDetails', 'creationDate', 'amountSettled'] },
      { bankAccount: ['id', { currency: ['code'] }] },
    ],
  },
  'index',
  'hasMore',
];
const getDepositsPage = makeQuery({
  permissions: {
    or: [Permission.INVOICES_VIEW_ALL, Permission.INVOICES_VIEW_MY],
  },
  queryName: getDepositsPageQuery,
  queryFields: getDepositsPageFields,
  query: ({ page, filter }: { page: number; filter: Filters }): Promise<QueryDepositsPage> => {
    const { investor, status } = filter;

    const typesValues = {
      ...{ page },
      ...{ investorId: investor },
      ...{ status },
    };

    return stompClient.getData<QueryDepositsPage>(
      getDepositsPageQuery,
      sign(getDepositsPageQuery, getDepositsPageFields),
      typesValues
    );
  },
});

const api = {
  getDepositsPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:deposits-page');

export default api;
