import React from 'react';
import { Trans } from '@lingui/macro';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputTextarea, SubmitButton } from '@components/form';
import { Text } from '@components/typography/text';
import { Fields, formId, useModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:deny-redeem-modal',
});

export const DenyRequestModal: MFC<{
  redeemId: number;
  onDenied: () => void;
}> = ({ isVisible, redeemId, onDenied }) => {
  const m = useModel(redeemId, onDenied, DenyRequestModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(DenyRequestModal)}
    >
      <ModalTitle>
        <Trans id="redeem_requests.deny_request_modal.deny.request">Deny request?</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId}>
          <Text level={1} className="mb2">
            <Trans id="redeem_requests.deny_request_modal.do.you.want.to.deny">
              Do you want to deny redemption request?
            </Trans>
          </Text>

          <InputTextarea
            name={Fields.reason}
            required={true}
            disabled={m.lockUI}
            className="mb2"
            label={
              <Trans id="redeem_requests.deny_request_modal.please.enter.reason">
                Please, enter the reason
              </Trans>
            }
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.deny">Deny</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
