import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { MoreActions } from '@components/more-actions';
import { Permission, PermissionGroup } from '@permissions/core';

import { RedemptionStatus } from '../api';
import { useModel } from './model';
import { permissionGroup as approveRedeemPermissionGroup } from '../modals/approve-redeem';
import { permissionGroup as burnRedeemPermissionGroup } from '../modals/burn-redeem';
import { permissionGroup as cancelRedeemPermissionGroup } from '../modals/cancel-redeem';
import { permissionGroup as denyRedeemPermissionGroup } from '../modals/deny-redeem';

import type { Redemption } from '../api';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    approveRedeemPermissionGroup,
    burnRedeemPermissionGroup,
    cancelRedeemPermissionGroup,
    denyRedeemPermissionGroup,
  ],
  marker: 'layout:redemptions-page-actions-cell',
});

export const ActionsCell: FC<{
  data: Redemption;
  onReplaceRedemption: (redemption: Redemption) => void;
}> = ({ data, onReplaceRedemption }) => {
  const m = useModel(data, onReplaceRedemption);

  return (
    <MoreActions
      placement="right"
      list={[
        {
          access: {
            extraCheck: () => [RedemptionStatus.processing].includes(data.status),
            permissions: { or: [Permission.REDEEM_APPROVE] },
          },
          action: m.showApproveRedeemModal,
          content: <Trans id="button.approve">Approve</Trans>,
        },
        {
          access: {
            extraCheck: () => [RedemptionStatus.processing].includes(data.status),
            permissions: { or: [Permission.REDEEM_DENY] },
          },
          action: m.showDenyRedeemModal,
          content: <Trans id="button.deny">Deny</Trans>,
        },
        {
          access: {
            extraCheck: () => [RedemptionStatus.approved].includes(data.status),
            permissions: { or: [Permission.REDEEM_BURN] },
          },
          action: m.showBurnRedeemModal,
          content: <Trans id="redeem_requests.burn_metals">Burn metals</Trans>,
        },
        {
          access: {
            extraCheck: () => [RedemptionStatus.created].includes(data.status),
            permissions: { or: [Permission.REDEEM_CANCEL_MY_REQUEST] },
          },
          action: m.showCancelRedeemModal,
          content: <Trans id="redeem_requests.cancel_redemption">Cancel redemption</Trans>,
        },
      ]}
    />
  );
};
