import { useState } from 'react';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { handleBackendError } from '@modules/notify';
import api from './api';
import { GetBankAccountBalancesSortOrder } from '../api';

export const useModel = (ordering: GetBankAccountBalancesSortOrder) => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onDownloadClicked = async () => {
    try {
      setLockUI(true);
      const report: string = await api.bankAccountBalancesXlsx(ordering);
      const blob = base64StringToBlob(report, 'application/xlsx');
      saveAs(blob, `bank_account_balances_report.xlsx`);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return {
    lockUI,
    onDownloadClicked,
  };
};
