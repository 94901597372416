import { useLayoutEffect, useRef, FC } from 'react';

type Props = {
  className?: string;
  heightSetter: (height: number) => void;
};

// This component is expected to be rendered synchronously
// and maintain constant height.
export const VirtualPadding: FC<Props> = ({ children, className, heightSetter }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      heightSetter(containerRef.current.offsetHeight);
    }
  }, []);

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        position: 'absolute',
        bottom: 0,
      }}
    >
      {children}
    </div>
  );
};
