import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Role as R } from '@permissions/core';

export enum Fields {
  filterStatus = 'status',
  filterClientId = 'filterClientId',
}

export interface Client {
  displayName: string;
  id: number;
}
const getClientsQuery = 'usersByRole';
const getClientsFields = ['displayName', 'id'];
const getClients = makeQuery({
  queryName: getClientsQuery,
  queryFields: getClientsFields,
  query: (): Promise<Array<Client>> => {
    const investors = stompClient.getData(
      getClientsQuery,
      sign(getClientsQuery, getClientsFields),
      { role: R.investor }
    );

    const institutionalMembers = stompClient.getData(
      getClientsQuery,
      sign(getClientsQuery, getClientsFields),
      { role: R.institutionalMember }
    );

    return Promise.all<Array<Client>>([investors, institutionalMembers]).then((response) => {
      return [...response[0], ...response[1]];
    });
  },
});

const api = {
  getClients,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:withdrawals-actions-row');

export default api;
