import { useMemo, FC } from 'react';
import { Trans } from '@lingui/macro';
import { PermissionGroup } from '@permissions/core';
import { WalletPageLayout } from '../shared/components/layout';
import {
  BalancePane,
  permissionGroup as balancePanePermissionGroup,
} from '../shared/components/balance-pane';
import { WalletTable } from '../shared/table/component';
import { useWalletPrimaryModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, balancePanePermissionGroup],
  marker: 'layout:wallet-primary',
});

export const WalletPrimary: FC = () => {
  const { balancePane, coinTableColumns, coinTableData, loading } = useWalletPrimaryModel();

  const balancePaneSections = useMemo(
    () => [
      {
        title: (
          <Trans id={'wallet.wallet-primary.balance-pane.primary-market'}>Primary market</Trans>
        ),
        records: balancePane.stableBalances,
        key: 'primary_market',
      },
      {
        title: <Trans id={'wallet.wallet-primary.balance-pane.pending'}>Pending</Trans>,
        records: balancePane.pendingBalances,
        key: 'pending',
      },
    ],
    [balancePane.stableBalances, balancePane.pendingBalances]
  );

  return (
    <WalletPageLayout
      sideContent={<BalancePane sections={balancePaneSections} />}
      mainContent={
        <WalletTable columns={coinTableColumns} data={coinTableData} loading={loading} />
      }
    />
  );
};
