import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useLatestBackLink } from '@hooks/url';
import { ReactComponent as ArrowRight } from '@images/icons/arrow-right-wide.svg';
import s from './index.module.scss';

import type { ReactNode } from 'react';

type PageTitleProps = {
  backwardLink?: string;
  children?: JSX.Element;
  className?: string;

  // Use to create different rendered and document title.
  // Document title setting is ignored if documentTitle set to null.
  // Set to title by default.
  documentTitle?: string | null;
  title?: string;
};

export function PageTitle({
  backwardLink: backwardLinkProp,
  children,
  className,
  title = '',
  documentTitle = title,
}: PageTitleProps) {
  const backLinkFromUrl = useLatestBackLink();
  const backwardLink = backLinkFromUrl ?? backwardLinkProp;

  useEffect(() => {
    if (documentTitle !== null) {
      document.title = `TM2\\${documentTitle}`;
    }
  }, [documentTitle]);

  if (children) {
    return children;
  }

  if (!children && !title) {
    return null;
  }

  return (
    <TitleTypography backwardLink={backwardLink} className={className}>
      {title}
    </TitleTypography>
  );
}

export function TitleTypography(props: {
  className?: string;
  backwardLink?: string;
  children: ReactNode;
}) {
  return (
    <span className={classnames(s.title, props.className)}>
      {props.backwardLink ? <BackwardLink to={props.backwardLink} /> : null}
      {props.children}
    </span>
  );
}

export function BackwardLink(props: { to: string }) {
  return (
    <Link className={s.link} to={props.to}>
      <ArrowRight className={s.linkArrow} />
    </Link>
  );
}
