import React from 'react';
import saveAs from 'file-saver';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { Trans } from '@lingui/macro';

import { handleBackendError, notifySuccess } from 'shared/modules/notify';
import { Put } from '@helper/redux';
import { metalsApiService as metalsApi } from '../metals-api.service';
import { MetalsPageActionsTypes as TransferTypes } from './model';
import { metalsPageServiceActions as transferServiceActions } from './service.acions';
import { metalsPageSelectors as transferSelectors } from './selectors';

export function* metalsPageEffects() {
  yield all([
    takeEvery(TransferTypes.DOWNLOAD_TRANSACTION, downloadTransaction),
    takeEvery(TransferTypes.LOAD_MORE_METALS, loadMoreMetals),
    takeEvery(TransferTypes.ON_DOWNLOAD_CLICKED, Put(transferServiceActions.downloadTransaction)),
    takeEvery(TransferTypes.ON_TABLE_SCROLLED, Put(transferServiceActions.loadMoreMetals)),
    takeEvery(TransferTypes.ON_TABLE_SORTED, Put(transferServiceActions.loadMoreMetals)),
    takeEvery(TransferTypes.ON_UPLOAD_CLICKED, Put(transferServiceActions.uploadTransaction)),
    takeEvery(TransferTypes.UPLOAD_TRANSACTION, uploadTransaction),
  ]);
}

function* downloadTransaction() {
  try {
    const transaction = yield metalsApi.downloadTransaction();
    const blob = new Blob([transaction], { type: 'application/blob' });
    saveAs(blob, 'unsigned_tx.tm2');
    yield put(transferServiceActions.downloadTransactionSuccess());
  } catch (error) {
    yield put(transferServiceActions.downloadTransactionFail(error));
    handleBackendError(error);
  }
}

function* loadMoreMetals(action) {
  try {
    const isSortAction = !!action.payload;
    const pageIndex = yield select(transferSelectors.pageIndex);
    const sort = yield select(transferSelectors.sort);
    const requestsInfo = yield metalsApi.getMetalsList(pageIndex, sort);
    yield put(
      transferServiceActions.loadMoreMetalsSuccess({
        ...requestsInfo,
        isSort: isSortAction,
      })
    );
  } catch (error) {
    yield put(transferServiceActions.loadMoreMetalsFail(error));
    handleBackendError(error);
  }
}

function* uploadTransaction(action) {
  try {
    yield metalsApi.uploadTransaction(action.payload);
    yield notifySuccess({
      defaultTitle: true,
      text: (
        <Trans id="metals_page.notify.success_upload_transaction.description">
          File uploaded successfully
        </Trans>
      ),
    });
    yield put(transferServiceActions.uploadTransactionSuccess());
    yield put(transferServiceActions.loadMoreMetals(true));
  } catch (error) {
    yield put(transferServiceActions.uploadTransactionFail(error));
    handleBackendError(error);
  }
}
