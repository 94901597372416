import { createSelector } from 'reselect';

import { RootState, Selector } from '@models/redux';
import { SortItem } from '@models/core';
import { SelectItem } from '@components/form/antd-form/select';
import { TableHeaderSortType as Sort } from '../../../../components/page-table';
import { TransactionFilters, TransactionItem } from '../api';
import { TransactionsTableColumnIds as ColumnIds } from '../model';
import { TransactionsState } from './model';

const s: Selector<TransactionsState> = (root: RootState) => root?.main?.transactions;

const assets: Selector<Array<SelectItem>> = createSelector(
  [(r) => filtersAvailable(r)],
  (filters) => [
    ...filters?.assets?.map((asset: string) => ({
      label: asset,
      value: asset,
    })),
  ]
);

const columnSortDirection: Selector<(id: ColumnIds) => Sort> = (root) => (columnId) =>
  s(root).sort.filter((col) => col.id === columnId)[0].direction;

const filtersAvailable: Selector<TransactionFilters> = (r: RootState) => s(r)?.filtersAvailable;

const filtersSelected: Selector<any> = (r: RootState) => s(r)?.filtersSelected;

const hasMore: Selector<boolean> = (r: RootState) => s(r)?.hasMore;

const lockUI: Selector<boolean> = (r: RootState) =>
  s(r)?.isTransactionsLoading || s(r)?.isFiltersLoading;

const page: Selector<number> = (r: RootState) => s(r)?.page;

const sort: Selector<Array<SortItem<ColumnIds>>> = (r: RootState) => s(r)?.sort;

const transactions: Selector<Array<TransactionItem>> = (r: RootState) => s(r)?.transactions;

export const transactionsSelectors = {
  assets,
  columnSortDirection,
  filtersSelected,
  hasMore,
  lockUI,
  page,
  sort,
  transactions,
};
