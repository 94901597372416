import { createSelector } from 'reselect';

import { configSelectors } from '@config/core';
import { RootState } from '@models/redux';
import { accessService as access, AllowedPermissions } from '../access.service';
import { IsHasAccess, Role } from '../core';

const isHasAccess: (r: RootState) => IsHasAccess =
  // TODO-2833: fix types
  // @ts-ignore
  createSelector(
    [(r) => configSelectors.permissions(r), (r) => configSelectors.role(r)],
    (currentPermissions: string, currentRole: Role) =>
      (
        allowedPermissions: AllowedPermissions,
        extraCheck: boolean = null,
        forcedAccess: boolean = false,
        allowedRoles: Array<Role> = null // roles is latest argument due to deprecated and will be removed
      ) => {
        if (forcedAccess) {
          return true;
        }
        if (extraCheck !== null && !extraCheck) {
          return false;
        }
        return access.isAllowed({
          allowedPermissions,
          allowedRoles,
          currentPermissions,
          currentRole,
        });
      }
  );

export const permissionsSelectors = {
  isHasAccess,
};
