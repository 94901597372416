import { FC } from 'react';
import { useSelector } from 'react-redux';
import { PermissionGroup } from '@permissions/core';
import { configSelectors } from '@config/core';
import { permissionGroup as walletFullPermissionGroup, WalletFull } from './wallet-full';
import { permissionGroup as walletPrimaryPermissionGroup, WalletPrimary } from './wallet-primary';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [walletFullPermissionGroup, walletPrimaryPermissionGroup],
  marker: 'prioritized-layout:wallet',
});

export const Wallet: FC = () => {
  const userPermissions = useSelector(configSelectors.permissions);

  const isWalletFullAllowed = walletFullPermissionGroup.resolve(userPermissions);

  const isWalletPrimaryAllowed = walletPrimaryPermissionGroup.resolve(userPermissions);

  if (isWalletFullAllowed) {
    return <WalletFull />;
  }
  if (isWalletPrimaryAllowed) {
    return <WalletPrimary />;
  }
  return null;
};
