import {
  ConfigActions,
  ConfigActionsTypes as ConfigTypes,
  ConfigState,
  configStateInitial,
  SetAppLanguagePayload,
} from './model';

export const configReducer = (
  state: ConfigState = configStateInitial,
  action: ConfigActions
): ConfigState => {
  switch (action.type) {
    case ConfigTypes.CONFIGURE_APPLICATION_SUCCESS: {
      return {
        ...state,
        isAppConfigured: true,
      };
    }

    case ConfigTypes.GET_USER_INFO: {
      return {
        ...state,
        isUserInfoLoading: true,
      };
    }

    case ConfigTypes.GET_USER_INFO_FAIL: {
      return {
        ...state,
        isUserInfoLoading: false,
      };
    }

    case ConfigTypes.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        isUserInfoLoading: false,
        userInfo: action.payload,
      };
    }

    case ConfigTypes.POST_REG_INITIATED: {
      return {
        ...state,
        isPostRegInProgress: true,
      };
    }

    case ConfigTypes.POST_REG_FINISHED: {
      return {
        ...state,
        isPostRegInProgress: false,
      };
    }

    case ConfigTypes.SET_APP_LANGUAGE: {
      const { i18n, lang }: SetAppLanguagePayload = action.payload;
      return {
        ...state,
        i18n: i18n,
        lang: lang,
      };
    }

    case ConfigTypes.SET_HISTORY_INSTANCE: {
      return {
        ...state,
        history: action.payload,
      };
    }

    case ConfigTypes.SET_SYSTEM_PREFERENCES: {
      return {
        ...state,
        bannerConfig: action.payload.banner,
      };
    }

    case ConfigTypes.TOGGLE_GLOBAL_PRELOADER: {
      return {
        ...state,
        isShowGlobalPreloader: action.payload,
      };
    }

    case ConfigTypes.WEBSOCKET_CONNECTED: {
      return {
        ...state,
        isWSConnected: true,
        wsInstance: action.payload,
      };
    }

    case ConfigTypes.WEBSOCKET_DISCONNECTED: {
      return {
        ...state,
        isWSConnected: false,
      };
    }

    default: {
      return state;
    }
  }
};
