import {
  createElement,
  createContext,
  useContext,
  ComponentType,
  Context as ReactContext,
} from 'react';
import { BaseItem, HeaderProps as OriginalHeaderProps } from '../core';
import { ColumnsContext, ColumnsContextValue } from './columns';
import { ColumnType, HeaderCellWrapperProps, HeaderWrapperProps } from './types';

export type HeaderProps<T extends BaseItem, H extends object = {}> = Omit<
  OriginalHeaderProps<T>,
  'columns'
> & {
  columns: ColumnType<T, H, any>[];
  headerCellWrapperComponent?: ComponentType<HeaderCellWrapperProps<H>>;
  headerWrapperComponent?: ComponentType<HeaderWrapperProps>;
};

export type HeaderContextValue<T extends BaseItem, H extends object = {}> = {
  headerCellWrapperComponent?: ComponentType<HeaderCellWrapperProps<H>>;
  headerComponent: ComponentType<HeaderProps<T, H>>;
  headerWrapperComponent?: ComponentType<HeaderWrapperProps>;
};

export const HeaderContext = createContext<HeaderContextValue<any>>({
  headerComponent: () => null,
});

export function WrappedHeader<T extends BaseItem, H extends object = {}>(
  props: OriginalHeaderProps<T>
) {
  const { columns } = useContext(
    ColumnsContext as unknown as ReactContext<ColumnsContextValue<T, H, any>>
  );
  const { headerCellWrapperComponent, headerComponent, headerWrapperComponent } = useContext(
    HeaderContext as unknown as ReactContext<HeaderContextValue<T, H>>
  );
  return createElement(headerComponent, {
    ...props,
    columns,
    headerCellWrapperComponent,
    headerWrapperComponent,
  });
}
