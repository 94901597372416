import React from 'react';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { FormInstance } from 'antd';

import { formService } from '@components/form';
import { handleBackendError } from '@modules/notify';
import { Permission as P, permissionsSelectors } from '@permissions/core';
import {
  depositsApiService as depositsApi,
  ExternalClientBankAccount,
  FundsInvestor,
} from '../../../deposits-api.service';
import { AddFundsModalActionsTypes, AddFundsType } from '../../store/model';
import {
  defaultExternalBankAccount,
  WireTransferForm,
  WireTransferFormFields,
  WireTransferViewActionsTypes as WireTypes,
} from './model';
import { wireTransferViewServiceActions as wireServiceActions } from './service.acions';

export function* wireTransferViewEffects() {
  yield all([
    takeEvery(WireTypes.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS, getExternalClientBankAccounts),
    takeEvery(WireTypes.GET_INVESTORS, getInvestorsWithBankAccounts),
    takeEvery(WireTypes.ON_INVESTOR_SELECTED, resetBankAccount),
    takeEvery(WireTypes.ON_BANK_ACCOUNT_SELECTED, resetExternalClientBankAccount),
    takeEvery(AddFundsModalActionsTypes.ON_MODAL_OPENED, initiateViewData),
  ]);
}

function* getExternalClientBankAccounts() {
  try {
    const list: Array<ExternalClientBankAccount> =
      yield depositsApi.getExternalClientBankAccounts();
    yield put(wireServiceActions.getExternalClientBankAccountsSuccess(list));
  } catch (error) {
    yield put(wireServiceActions.getExternalClientBankAccountsFail(error as Error));
    handleBackendError(error);
  }
}

function* getInvestorsWithBankAccounts() {
  try {
    const list: Array<FundsInvestor> = yield depositsApi.getInvestors();
    yield put(wireServiceActions.getInvestorsSuccess(list));
  } catch (error) {
    yield put(wireServiceActions.getInvestorsFail(error as Error));
    handleBackendError(error);
  }
}

function* initiateViewData() {
  const hasAccessFn = yield select(permissionsSelectors.isHasAccess);
  if (hasAccessFn({ or: [P.INVOICE_CREATE_EXT] })) {
    yield put(wireServiceActions.getInvestors());
    yield put(wireServiceActions.getExternalClientBankAccounts());
  }
}

function* resetExternalClientBankAccount() {
  const form: FormInstance<WireTransferForm> = formService.get<WireTransferForm>(
    AddFundsType.wireTransfer
  );
  form.setFieldsValue({
    [WireTransferFormFields.externalBankAccountBalanceId]: defaultExternalBankAccount.externalId,
  });
}

function* resetBankAccount() {
  const form: FormInstance<WireTransferForm> = formService.get<WireTransferForm>(
    AddFundsType.wireTransfer
  );
  form.resetFields([WireTransferFormFields.bankAccountId]);
  yield resetExternalClientBankAccount();
}
