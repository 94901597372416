import React, { FC } from 'react';
import { useI18n } from 'containers/services/commonService';
import { defineMessage } from '@lingui/macro';
import { PageContent, PageTitle } from '@components/page';
import { VaultsTable } from './table';
import { VaultsPageHeader } from './header';
import s from './index.module.scss';

export const VaultsPage: FC = () => {
  const { i18n } = useI18n();

  return (
    <PageContent className={s.content} uat="vaults-page">
      <PageTitle
        documentTitle={i18n._(defineMessage({ id: 'router.vaults', message: 'Vaults' }))}
      />
      <VaultsPageHeader className="mb-md" />
      <VaultsTable />
    </PageContent>
  );
};
