import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import InfiniteScroll from 'react-infinite-scroller';

import s from './index.module.scss';
import { Any, Lang } from '@models/core';
import { configSelectors } from '@config/core';
import { PreloaderLine } from '@components/preloader';
import { AltTableColumnProp } from '../alt-table';
import AltTable from '../alt-table';

const _deps = [];
export const PageTable: FC<PageTableProps> = ({
  className,
  columns,
  deps = _deps,
  headClasses = '',
  initialPage = 0,
  isLoadingInProgress,
  isShowLoader = false,
  noDataText,
  onLoadMore,
  onReset,
  pageData,
  initialLoad = false,
  forceLoadMore = false,
  trackHover = false,
  uat,
  ...props
}) => {
  const [isNoData, setNoData] = useState(false);
  const [parentRef, setParentRef] = useState();
  const [page, setPage] = useState(initialPage);
  const { data = [], hasMore = false } = pageData || {};
  const lang: Lang = useSelector(configSelectors.lang);

  useEffect(() => {
    onReset && onReset(() => setPage(initialPage));
  }, [onReset]); // eslint-disable-line

  const MemoizedTable = useMemo(
    () => (
      <InfiniteScroll
        loadMore={() => {
          if (isLoadingInProgress && !forceLoadMore) {
            return Promise.resolve();
          }
          setPage(page + 1);
          return onLoadMore(page + 1);
        }}
        initialLoad={initialLoad}
        hasMore={hasMore}
        useWindow={false}
        getScrollParent={() => parentRef}
        loader={
          !isShowLoader ? null : (
            <div key={'preloader'} className={'relative'}>
              <PreloaderLine show={true} />
            </div>
          )
        }
      >
        <AltTable
          data={data}
          headClasses={headClasses}
          columns={columns}
          disabled={isLoadingInProgress}
          onNoData={setNoData}
          trackHover={trackHover}
          uat={uat}
          {...props}
        />
      </InfiniteScroll>
    ),
    [data, hasMore, lang, isLoadingInProgress && !forceLoadMore, parentRef, ...deps]
  ); // eslint-disable-line

  return (
    <div
      className={classnames('table-max-height table-wrapper', className, isNoData && s.noPadding)}
      ref={setParentRef as Any}
    >
      {MemoizedTable}
    </div>
  );
};

interface PageTableProps<T = object> {
  className?: string;
  columns: Array<AltTableColumnProp>;
  deps?: Array<Any>;
  headClasses?: string;
  initialPage?: number;
  isLoadingInProgress?: boolean;
  isShowLoader?: boolean;
  noDataText?: ReactNode;
  onLoadMore: (page: number) => void;
  onReset?: Function;
  pageData: {
    data: Array<T>;
    hasMore: boolean;
  };
  initialLoad?: boolean;
  forceLoadMore?: boolean;
  trackHover?: boolean;
  uat?: string;
}

export enum TableHeaderSortType {
  asc = 'asc',
  desc = 'desc',
  notExist = 'notExist',
  notSet = 'notSet',
}
