import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { useEmitBankAccountUpdated } from '../../hooks';
import {
  HandleCMBankAccountModal,
  HandleCMBankAccountModalProps,
} from '../../modals/handle-bank-account';

export const useModel = () => {
  const showModal = useCallDispatch(showModalAction);
  const onAccountUpdated = useEmitBankAccountUpdated();

  const onEditClicked = (id: number) => {
    const props: HandleCMBankAccountModalProps = { id, onAccountUpdated };
    showModal({
      modal: HandleCMBankAccountModal,
      props,
    });
  };

  return {
    onEditClicked,
  };
};
