import React from 'react';
import saveAs from 'file-saver';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { redeemCreationEventService } from '../../redeem-creation-event-service';

import { handleBackendError } from 'shared/modules/notify';
import { Put } from '@helper/redux';
import { closeModalAction } from 'shared/modules/modal';
import api from '../../api';
import { redeemConfirmationModalSelectors as redeemConfirmSelectors } from '../../redeem-confirmation-modal/store/selectors';
import { RedeemFinishModal } from '../../redeem-finish-modal';
import { FinishRedeemModalActionsTypes as FinishRedeemTypes } from './model';
import { finishRedeemModalServiceActions as finishRedeemServiceActions } from './service.acions';

export function* finishRedeemModalEffects() {
  yield all([
    takeEvery(FinishRedeemTypes.CREATE_REDEEM, createRedeem),
    takeEvery(FinishRedeemTypes.DOWNLOAD_TRANSACTION, downloadRedeemTransaction),
    takeEvery(FinishRedeemTypes.ON_CLOSE_MODAL_CLICKED, Put(closeModalAction, RedeemFinishModal)),
    takeEvery(
      FinishRedeemTypes.ON_CREATE_REDEEM_CLICKED,
      Put(finishRedeemServiceActions.createRedeem)
    ),
    takeEvery(
      FinishRedeemTypes.ON_GET_TRANSACTION_CLICKED,
      Put(finishRedeemServiceActions.downloadTransaction)
    ),
  ]);
}

function* createRedeem() {
  try {
    const redeemData = yield select(redeemConfirmSelectors.storedConfirmedRedeemData);
    yield api.createRedeem(redeemData);
    yield put(finishRedeemServiceActions.createRedeemSuccess());
    redeemCreationEventService.notify(redeemData.asset);
    yield put(closeModalAction(RedeemFinishModal));
  } catch (error) {
    yield put(finishRedeemServiceActions.createRedeemFail(error));
    handleBackendError(error);
  }
}

function* downloadRedeemTransaction() {
  try {
    const redeemData = yield select(redeemConfirmSelectors.storedConfirmedRedeemData);
    const signedTxResponse = yield api.downloadRedeemTransaction(redeemData);
    const blob = new Blob([signedTxResponse.value], { type: 'application/blob' });
    saveAs(blob, `${redeemData.asset}_redeem_tx.tm2`);
  } catch (error) {
    handleBackendError(error);
  }
}
