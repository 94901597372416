import { useState, useLayoutEffect, ComponentType } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useResizeDetector } from 'react-resize-detector';
import {
  Header,
  HeaderProps,
  HeaderCellWrapper,
  HeaderCellProps,
  HeaderCellWrapperConfig,
  HeaderCellWrapperProps,
} from '../utils/bound-components/base-header';
import { DefaultHeaderContainer } from '../utils/bound-components-containers/default-header-container';
import {
  Row,
  RowProps,
  RowCellWrapper,
  RowCellProps,
  RowCellWrapperConfig,
  RowCellWrapperProps,
} from '../utils/bound-components/base-row';
import { DefaultRowContainer } from '../utils/bound-components-containers/default-row-container';
import {
  ExtensibleTable,
  TableProps,
  BaseItem,
  BaseTableProps,
  ColumnType,
} from './extensible-table';

export type {
  HeaderProps as DHeaderProps,
  HeaderCellProps as DHeaderCellProps,
  HeaderCellWrapperConfig as DHeaderCellWrapperConfig,
  HeaderCellWrapperProps as DHeaderCellWrapperProps,
} from '../utils/bound-components/base-header';

export type {
  RowProps as DRowProps,
  RowCellProps as DRowCellProps,
  RowCellWrapperConfig as DRowCellWrapperConfig,
  RowCellWrapperProps as DRowCellWrapperProps,
} from '../utils/bound-components/base-row';

export * from './extensible-table';

export type DColumnType<T extends BaseItem> = ColumnType<T, HeaderCellProps, RowCellProps<T>>;

export type DBaseTableProps<T extends BaseItem> = BaseTableProps<
  T,
  HeaderCellProps,
  RowCellProps<T>
>;

export type DTableProps<T extends BaseItem, K> = Omit<
  TableProps<T, K, HeaderCellProps, RowCellProps<T>>,
  'headerComponent' | 'rowComponent' | 'totalYOffset'
> & {
  headerComponent?: ComponentType<HeaderProps<T>>;
  rowComponent?: ComponentType<RowProps<T>>;
  totalYOffset?: number;
};

// Header wrapper config helper.
export function hwc(params: HeaderCellWrapperConfig) {
  return params;
}

// Row wrapper config helper.
export function rwc(params: RowCellWrapperConfig) {
  return params;
}

export function DTable<T extends BaseItem, K>(props: DTableProps<T, K>) {
  // headerHeight used as a hack to only
  // render table after setting its height.
  const [headerHeight, setHeaderHeight] = useState(0);

  const onHeaderContainerResize = useCallbackOne((width: number, height: number) => {
    if (typeof height === 'number' && height > 0) {
      setHeaderHeight(height);
    }
  }, []);

  const { ref: containerRef } = useResizeDetector({
    handleHeight: true,
    handleWidth: false,
    refreshMode: 'debounce',
    refreshRate: 100,
    onResize: onHeaderContainerResize,
  });

  const HeaderContainer = useMemoOne<typeof DefaultHeaderContainer>(() => {
    const HeaderWrapper = props.headerWrapperComponent ?? DefaultHeaderContainer;

    return (props: any): any => {
      useLayoutEffect(() => {
        setHeaderHeight(containerRef.current?.offsetHeight);
      }, []);
      return <HeaderWrapper ref={containerRef} {...props} />;
    };
  }, []);

  const shouldRenderBody = typeof headerHeight === 'number' && headerHeight > 0;

  return (
    <ExtensibleTable
      headerCellWrapperComponent={HeaderCellWrapper}
      headerComponent={Header}
      headerWrapperComponent={HeaderContainer}
      rowCellWrapperComponent={RowCellWrapper}
      rowComponent={Row}
      rowWrapperComponent={DefaultRowContainer}
      renderBody={shouldRenderBody}
      totalYOffset={headerHeight}
      {...props}
    />
  );
}
