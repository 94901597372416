import { useEffect, useRef, useState, Dispatch, SetStateAction } from 'react';

export function useSyncRef<T>(param: T) {
  const syncRef = useRef<T>(param);
  syncRef.current = param;
  return syncRef;
}

function useAttached() {
  const attached = useRef(true);
  useEffect(
    () => () => {
      attached.current = false;
    },
    []
  );
  return attached;
}

export function useAttachedState<T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
  const attached = useAttached();
  const [state, setter] = useState<T>(initialState);
  const attachedSetter = useRef<typeof setter>((val) => {
    if (attached.current) {
      setter(val);
    }
  });
  return [state, attachedSetter.current];
}

export function useInitialRender() {
  const state = useRef<boolean | null>(null);
  state.current = state.current === null;
  return state.current;
}

export function useInitialRenderEffect<T extends (...args: any[]) => void>(callback: T) {
  const isInitialRender = useInitialRender();
  if (isInitialRender) {
    callback();
  }
}
