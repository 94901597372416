import { AsyncThunk } from '@reduxjs/toolkit/src/createAsyncThunk';
import { Any } from '@models/core';

export type RootState = Any; // TODO нужна типизация

/**
 * Represents redux action base class
 */
export class ReduxAction<T = string, P = void> {
  public payload: P;

  public readonly type: T;

  public constructor(payload: P) {
    this.payload = payload;
  }

  // this need to convert class to object, but we still can use class as interface
  public get?(): ReduxAction<T, P> {
    return {
      payload: this.payload,
      type: this.type,
    } as ReduxAction<T, P>; // TODO возможно тут не подходит ReduxAction, т.к. у него есть метод get
  }
}

/**
 * Represents interface for redux action received in effect listeners
 */
export class Action<P = void> {
  public payload: P;

  public readonly type: string;
}

/**
 * Represents dynamic type for redux selectors
 */
export type Selector<T> = (root: RootState) => T;

/**
 * Represents dynamic type for redux dispatch callback
 */
export type DispatchFn<T = Any> = (payload: T) => void;

/**
 * Represents type for redux dispatch with thunk middleware
 */
export type DispatchThunkFn = AsyncThunk<any, any, any>; // eslint-disable-line

/**
 * Represents type for redux get state method with thunk middleware
 */
export type GetStateFn = () => RootState;
