import React from 'react';
import { Trans } from '@lingui/macro';

import './styles.scss';
import { Modal, MFC, ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { Form, CheckboxGroup, Radio, SubmitButton } from '@components/form';
import { CaseMode, MatchMode } from '@modules/rsql';
import { FieldsConfig, TextFields as Fields } from '../context';
import { formId, SearchConfigModalProps, useModel } from './model';

export type { SearchConfigModalProps } from './model';

export const SearchConfigModal: MFC<{}, SearchConfigModalProps> = (p) => {
  const m = useModel(p);

  return (
    <Modal
      className="config-search-modal"
      isVisible={p.isVisible}
      disabled={false}
      loading={false}
      onClose={m.closeModal}
    >
      <ModalTitle>
        <Trans id="search_config_modal.title">Configure search</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={m.initialValues}>
          <Radio
            name={Fields.matchMode}
            label={<Trans id="search_config_modal.label.match_mode">Match mode</Trans>}
            deprecatedStyles={false}
            data={[
              {
                label: <Trans id="search_config_modal.radio.partial">Partial match</Trans>,
                value: MatchMode.partial,
              },
              {
                label: <Trans id="search_config_modal.radio.strict">Strict match</Trans>,
                value: MatchMode.strict,
              },
            ]}
          />

          <Radio
            name={Fields.caseMode}
            label={<Trans id="search_config_modal.label.case_mode">Case sensitivity</Trans>}
            deprecatedStyles={false}
            data={[
              {
                label: <Trans id="search_config_modal.radio.insensitive">Insensitive</Trans>,
                value: CaseMode.insensitive,
              },
              {
                label: <Trans id="search_config_modal.radio.sensitive">Sensitive</Trans>,
                value: CaseMode.sensitive,
              },
            ]}
          />

          <Radio
            name={Fields.fieldsConfig}
            label={<Trans id="search_config_modal.label.fields_config">Fields configuration</Trans>}
            deprecatedStyles={false}
            data={[
              {
                label: <Trans id="search_config_modal.radio.all_fields">All fields</Trans>,
                value: FieldsConfig.allFields,
              },
              {
                extraContent: (
                  <CheckboxGroup
                    className="config-search-modal-fields"
                    label={null}
                    pattern={{ xs: 1, sm: 2 }}
                    name={Fields.customFields}
                    data={p.fields.map((item) => ({ label: item.label, value: item.id }))}
                  />
                ),
                label: <Trans id="search_config_modal.radio.custom_fields">Custom fields</Trans>,
                value: FieldsConfig.customFields,
              },
            ]}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} onAction={{ submit: m.onSubmitClicked }}>
          <Trans id="button.apply">Apply</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
