import React, { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';

import { Permission as P } from '@permissions/core';
import { useHasAccess } from '@permissions/hooks';
import { OnFormAction } from '@components/form';
import { handleBackendError, notifySuccess } from '@modules/notify';
import { useBasicFee, useCoinInfo } from '../hooks';
import api, { Form } from '../api';

export const formId = 'secondary-market-fees';

export enum FeeLimits {
  min = 0,
  max = 100,
  decimals = 4,
}

export enum Fields {
  asset = 'assetId', // feeList.asset
  basicFee = 'secondaryMarketBasicFee',
  fee = 'secondaryMarketFee', // feeList.fee
  feeList = 'assetIdsFees',
}
const editAccessPermissions = { or: [P.EDIT_FEES] }; // TODO исплользовать пермишн из setSMFees апи
export const useModel = () => {
  const isEditAllowed = useHasAccess(editAccessPermissions);
  const [assetsSelected, setAssetsSelected] = useState<{ [k: number]: boolean }>({});
  const [isSaveFeesLoading, setSaveFeesLoading] = useState<boolean>(false);
  const { data: basicFee, isLoading: isBasicFeeLoading } = useBasicFee();
  const {
    data: { assets: assetsRaw, fees: coinFees },
    isLoading: isCoinInfoLoading,
  } = useCoinInfo();
  const lockUI = isBasicFeeLoading || isCoinInfoLoading || isSaveFeesLoading;

  const assets = useMemo(
    () =>
      assetsRaw.map((asset) => ({
        ...asset,
        disabled: assetsSelected[asset.value],
      })),
    [assetsRaw, assetsSelected]
  );

  const formInitial = useMemo(
    () => ({
      [Fields.basicFee]: basicFee,
      [Fields.feeList]: coinFees,
    }),
    [basicFee, coinFees]
  );

  const onFormChanged = (f: Form) =>
    setAssetsSelected(
      (f?.assetIdsFees || []).reduce((acc, d) => ({ ...acc, [d.assetId]: true }), {})
    );

  const onFormAction: OnFormAction<Form> = {
    error: async (error: Error) => {
      handleBackendError(error);
    },
    finally: () => setSaveFeesLoading(false),
    submit: async (form: Form) => {
      setSaveFeesLoading(true);
      await api.setSMFees(form);
      notifySuccess({
        defaultTitle: true,
        text: <Trans id="secondary_market_fees.settings_saved">Settings saved successfully</Trans>,
      });
    },
  };

  return {
    assets,
    formInitial,
    isEditAllowed,
    lockUI,
    onFormAction,
    onFormChanged,
  };
};
