import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { Withdrawal } from '../api';

const withdrawalFields = [
  'amount',
  'createdAt',
  'id',
  'status',
  { currency: ['code'] },
  {
    userBankAccount: [
      'accountNumber',
      'bankName',
      'comment',
      'ibanNumber',
      'nickname',
      { user: ['id', 'displayName', 'address'] },
      'swiftCode',
    ],
  },
];
export const cancelWithdrawalQuery = 'cancelWithdrawal';
export const cancelWithdrawalFields = withdrawalFields;
const cancelWithdrawal = makeQuery({
  queryName: cancelWithdrawalQuery,
  queryFields: cancelWithdrawalFields,
  query: (withdrawalId): Promise<Withdrawal> => {
    return stompClient.sendData(
      cancelWithdrawalQuery,
      sign(cancelWithdrawalQuery, cancelWithdrawalFields),
      { ...v.long({ withdrawalId }) }
    );
  },
});

export const denyWithdrawalQuery = 'denyWithdrawal';
export const denyWithdrawalFields = withdrawalFields;
const denyWithdrawal = makeQuery({
  queryName: denyWithdrawalQuery,
  queryFields: denyWithdrawalFields,
  query: (withdrawalId): Promise<Withdrawal> => {
    return stompClient.sendData(
      denyWithdrawalQuery,
      sign(denyWithdrawalQuery, denyWithdrawalFields),
      { ...v.long({ withdrawalId }) }
    );
  },
});

export const approveWithdrawalQuery = 'approveWithdrawal';
export const approveWithdrawalFields = withdrawalFields;
const approveWithdrawal = makeQuery({
  queryName: approveWithdrawalQuery,
  queryFields: approveWithdrawalFields,
  query: (withdrawalId): Promise<Withdrawal> => {
    return stompClient.sendData(
      approveWithdrawalQuery,
      sign(approveWithdrawalQuery, approveWithdrawalFields),
      { ...v.long({ withdrawalId }) }
    );
  },
});

export const markWithdrawalAsCheckedQuery = 'markWithdrawalAsChecked';
export const markWithdrawalAsCheckedFields = withdrawalFields;
const markWithdrawalAsChecked = makeQuery({
  queryName: markWithdrawalAsCheckedQuery,
  queryFields: markWithdrawalAsCheckedFields,
  query: (withdrawalId): Promise<Withdrawal> => {
    return stompClient.sendData(
      markWithdrawalAsCheckedQuery,
      sign(markWithdrawalAsCheckedQuery, markWithdrawalAsCheckedFields),
      { ...v.long({ withdrawalId }) }
    );
  },
});

export const processWithdrawalQuery = 'processWithdrawal';
export const processWithdrawalFields = withdrawalFields;
const processWithdrawal = makeQuery({
  queryName: processWithdrawalQuery,
  queryFields: processWithdrawalFields,
  query: (withdrawalId): Promise<Withdrawal> => {
    return stompClient.sendData(
      processWithdrawalQuery,
      sign(processWithdrawalQuery, processWithdrawalFields),
      { ...v.long({ withdrawalId }) }
    );
  },
});

const api = {
  cancelWithdrawal,
  denyWithdrawal,
  approveWithdrawal,
  markWithdrawalAsChecked,
  processWithdrawal,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:withdrawals-page-actions-cell');

export default api;
