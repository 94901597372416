import { makeQuery, PermissionGroup } from '@permissions/core';
import { privateApi } from '../../../../../../../utils/api';
// ---------------------------------------------------------------------------------
export const blockUserQuery = 'privateApi/blockUser';
export const blockUserFields = ['value'];
const blockUser = makeQuery({
  // TODO пермишн
  queryName: blockUserQuery,
  queryFields: blockUserFields,
  query: (userId: number): Promise<void> => {
    return privateApi.post(`/users/${userId}/block`);
  },
});
// ---------------------------------------------------------------------------------
export const unblockUserQuery = 'privateApi/blockUser';
export const unblockUserFields = ['value'];
const unblockUser = makeQuery({
  // TODO пермишн
  queryName: unblockUserQuery,
  queryFields: unblockUserFields,
  query: (userId: number): Promise<void> => {
    return privateApi.post(`/users/${userId}/unblock`);
  },
});
// ---------------------------------------------------------------------------------

const api = {
  blockUser,
  unblockUser,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:user-access');

export default api;
