import { Children } from 'react';

export function If(props: { condition: boolean; children: any }) {
  return props.condition ? props.children : null;
}

const unsetSymbol = Symbol();
export function PrioritizedIf(props: { children: any }) {
  let result: JSX.Element | Symbol = unsetSymbol;

  Children.forEach(props.children, (child) => {
    if (child.props.condition) {
      result = result === unsetSymbol ? child : result;
    }
  });
  return (result === unsetSymbol ? null : result) as unknown as JSX.Element;
}
