import sign from 'tm2sign.macro';
import {
  normalizeCoinBalances,
  normalizeStableBalances,
  NormalizedBalance,
} from '@helper/balance/index';
import { Permission, PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

type QueryCoinBalance = {
  amount: number;
  coin: {
    asset: string;
    brandName: string;
    metal: {
      label: string;
    };
  };
};

type QueryStableBalance = {
  amount: number;
  currency: {
    asset: string;
    id: number;
  };
  overdraft: number;
};

type CoinData = {
  brandName: string;
  metal: string;
};

type StableData = unknown;

type StableOverdraftData = {
  overdraft: number;
};

const coinBalanceFields = {
  coinBalance: [
    'amount',
    {
      coin: ['asset', 'brandName', { metal: ['label'] }],
    },
  ],
};

const stableBalanceFields = {
  stableBalance: [
    'amount',
    {
      currency: ['asset', 'id'],
    },
    'overdraft',
  ],
};

function mapCoinBalance(queryCoinBalance: QueryCoinBalance): CoinData {
  return {
    brandName: queryCoinBalance.coin.brandName,
    metal: queryCoinBalance.coin.metal.label,
  };
}

type GetPrimaryBalanceResponse = {
  primaryActiveCoinBalances: NormalizedBalance<CoinData>[];
  primaryActiveStableBalances: NormalizedBalance<StableOverdraftData>[];
  primaryHoldCoinBalances: NormalizedBalance<CoinData>[];
  primaryHoldStableBalances: NormalizedBalance<StableData>[];
};

const getPrimaryBalanceQuery = 'userProfile';
const getPrimaryBalanceFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [{ ...coinBalanceFields }, { ...stableBalanceFields }],
          },
          {
            hold: [{ ...coinBalanceFields }, { ...stableBalanceFields }],
          },
        ],
      },
    ],
  },
];
const getPrimaryBalance = makeQuery({
  permissions: Permission.BALANCE_VIEW_PRIMARY_MY,
  queryName: getPrimaryBalanceQuery,
  queryFields: getPrimaryBalanceFields,
  query: (): Promise<GetPrimaryBalanceResponse> => {
    type MixedBalance = {
      coinBalance: QueryCoinBalance[];
      stableBalance: QueryStableBalance[];
    };

    type QueryResponse = {
      balance: {
        primary: {
          active: MixedBalance;
          hold: MixedBalance;
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getPrimaryBalanceQuery,
        sign(getPrimaryBalanceQuery, getPrimaryBalanceFields)
      )
      .then(({ balance }) => {
        const primaryActiveCoinBalances = normalizeCoinBalances(
          balance.primary.active.coinBalance
        ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const primaryActiveStableBalances = normalizeStableBalances(
          balance.primary.active.stableBalance
        ).map((b) => ({ ...b, data: { overdraft: b.data.overdraft } }));

        const primaryHoldCoinBalances = normalizeCoinBalances(balance.primary.hold.coinBalance).map(
          (b) => ({ ...b, data: mapCoinBalance(b.data) })
        );

        const primaryHoldStableBalances = normalizeStableBalances(
          balance.primary.hold.stableBalance
        ).map((b) => ({ ...b, data: null }));

        return {
          primaryActiveCoinBalances,
          primaryActiveStableBalances,
          primaryHoldCoinBalances,
          primaryHoldStableBalances,
        };
      });
  },
});

const getPrimaryStableBalanceQuery = 'userProfile';
const getPrimaryStableBalanceFields = [
  {
    balance: [
      {
        primary: [{ active: [{ ...stableBalanceFields }] }, { hold: [{ ...stableBalanceFields }] }],
      },
    ],
  },
];
const getPrimaryStableBalance = makeQuery({
  permissions: Permission.BALANCE_VIEW_PRIMARY_MY,
  queryName: getPrimaryStableBalanceQuery,
  queryFields: getPrimaryStableBalanceFields,
  query: (): Promise<{
    primaryActiveStableBalances: NormalizedBalance<StableOverdraftData>[];
    primaryHoldStableBalances: NormalizedBalance<StableData>[];
  }> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: { stableBalance: QueryStableBalance[] };
          hold: { stableBalance: QueryStableBalance[] };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getPrimaryStableBalanceQuery,
        sign(getPrimaryStableBalanceQuery, getPrimaryStableBalanceFields)
      )
      .then(({ balance }) => {
        const primaryActiveStableBalances = normalizeStableBalances(
          balance.primary.active.stableBalance
        ).map((b) => ({ ...b, data: { overdraft: b.data.overdraft } }));

        const primaryHoldStableBalances = normalizeStableBalances(
          balance.primary.hold.stableBalance
        ).map((b) => ({ ...b, data: null }));

        return {
          primaryActiveStableBalances,
          primaryHoldStableBalances,
        };
      });
  },
});

const getPrimaryCoinBalanceQuery = 'userProfile';
const getPrimaryCoinBalanceFields = [
  {
    balance: [
      {
        primary: [{ active: [{ ...coinBalanceFields }] }, { hold: [{ ...coinBalanceFields }] }],
      },
    ],
  },
];
const getPrimaryCoinBalance = makeQuery({
  permissions: Permission.BALANCE_VIEW_PRIMARY_MY,
  queryName: getPrimaryCoinBalanceQuery,
  queryFields: getPrimaryCoinBalanceFields,
  query: (): Promise<{
    primaryActiveCoinBalances: NormalizedBalance<CoinData>[];
    primaryHoldCoinBalances: NormalizedBalance<CoinData>[];
  }> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: { coinBalance: QueryCoinBalance[] };
          hold: { coinBalance: QueryCoinBalance[] };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getPrimaryStableBalanceQuery,
        sign(getPrimaryCoinBalanceQuery, getPrimaryCoinBalanceFields)
      )
      .then(({ balance }) => {
        const primaryActiveCoinBalances = normalizeCoinBalances(
          balance.primary.active.coinBalance
        ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const primaryHoldCoinBalances = normalizeCoinBalances(balance.primary.hold.coinBalance).map(
          (b) => ({ ...b, data: mapCoinBalance(b.data) })
        );

        return {
          primaryActiveCoinBalances,
          primaryHoldCoinBalances,
        };
      });
  },
});

const api = {
  getPrimaryBalance,
  getPrimaryCoinBalance,
  getPrimaryStableBalance,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:wallet-primary');

export default api;
