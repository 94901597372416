import { useState } from 'react';
import { handleBackendError } from '@modules/notify';
import { closeModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import api from './api';

export const useModel = (redeemId, replaceRedeem, modal) => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onClose = useCallDispatch(closeModalAction);

  const onApproveClicked = async () => {
    try {
      setLockUI(true);
      const approvedRedeem = await api.approveRedeem(redeemId);
      replaceRedeem(approvedRedeem);
      setLockUI(false);
      onClose(modal);
    } catch (error) {
      handleBackendError(error);
      setLockUI(false);
    }
  };

  return {
    lockUI,
    onApproveClicked,
    onClose,
  };
};
