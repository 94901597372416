import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Form, InputPassword, SubmitButton } from '@components/form';
import { ButtonSecondary } from '@components/button';
import { Fields, formId, useModel } from './model';

export const ChangePasswordForm: FC<{ onClose: () => void }> = memo(({ onClose }) => {
  const m = useModel(onClose);

  return (
    <Form className={s.form} name={formId}>
      <InputPassword
        name={Fields.passwordOld}
        label={<Trans id="profile.security_tab.old_password">Old password</Trans>}
        disabled={m.lockUI}
      />

      <InputPassword
        name={Fields.passwordNew}
        label={<Trans id="profile.security_tab.new_password">New password</Trans>}
        disabled={m.lockUI}
      />

      <InputPassword
        name={Fields.passwordConfirm}
        label={<Trans id="profile.security_tab.confirm_password">Confirm password</Trans>}
        disabled={m.lockUI}
      />

      <div className={s.row}>
        <ButtonSecondary className="mr3" onClick={onClose} icon={null} disabled={m.lockUI}>
          <Trans id="profile.security_tab.button.cancel">Cancel</Trans>
        </ButtonSecondary>

        <SubmitButton name={formId} icon={null} onAction={m.onFormAction} disabled={m.lockUI}>
          <Trans id="profile.security_tab.button.submit">Update</Trans>
        </SubmitButton>
      </div>
    </Form>
  );
});
