import React, { FC, memo } from 'react';
import { Redirect } from 'react-router-dom';
import { PageContent, PageTitle } from '@components/page';
import { defineMessage } from '@lingui/macro';

import s from './index.module.scss';
import { useModel } from './model';
import { BalancesRow } from './balances-row';
import { ActionsRow } from './actions-row';
import { useDefaultFilters } from './hooks';
import { BankBalanceHistoryTable } from './table';
import { Provider } from './context';
import { PermissionGroup } from '@permissions/permission-group';
import { permissionGroup as actionsRowPermissionGroup } from './actions-row';
import { permissionGroup as balancesRowPermissionGroup } from './balances-row';
import { permissionGroup as tablePermissionGroup } from './table';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [actionsRowPermissionGroup, balancesRowPermissionGroup, tablePermissionGroup],
  marker: 'layout:bank-account-history-page',
});

const ConnectedBankAccountHistoryPage: FC = memo(() => {
  const m = useModel();

  return (
    <PageContent
      className={s.verticalFiller}
      uat="statements-bank-account-balances-bank-account-history-page"
    >
      <PageTitle
        title={m.i18n._(
          defineMessage({
            id: 'router.banks.history',
            message: 'Bank account balance history',
          })
        )}
        backwardLink={'/statements/bank-accounts-balances'}
      />

      <ActionsRow />

      <BalancesRow />

      <BankBalanceHistoryTable className={s.verticalFiller} />
    </PageContent>
  );
});

export const BankAccountHistoryPage: FC = () => {
  const { isFilterCorrect } = useDefaultFilters();

  return !isFilterCorrect ? (
    <Redirect to="/statements/bank-accounts-balances" />
  ) : (
    <Provider>
      <ConnectedBankAccountHistoryPage />
    </Provider>
  );
};
