import React, { FC, memo } from 'react';

import { Box } from '@components/box';
import { CreateAccount, permissionsGroup as createPermissionsGroup } from './create-account';

export const permissionsGroup = createPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-bank-account-actions',
});

type Props = {
  className: string;
};
export const ActionsRow: FC<Props> = memo<Props>((p) => {
  return (
    <Box className={p.className} compact>
      <CreateAccount />
    </Box>
  );
});
