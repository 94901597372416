import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import theme from '@styles/theme/index.module.scss';
import s from './index.module.scss';
import { PrevStepButton } from '../../prev-button';
import { VerificationUploadForm } from './fragment/form';
import { VerificationUploadSubmit } from './fragment/submit';
import { useModel } from './model';

export const VerificationUploadStep: FC = () => {
  const m = useModel();

  return (
    <div
      className={classnames(s.form, theme.themeContrast)}
      data-uat="post-reg-upload-verify-docs-page"
    >
      <h2 className={s.title}>
        <Trans id="signup.verification_upload_step.title">Verification</Trans>
      </h2>

      <VerificationUploadForm disabled={m.lockUI} />

      <div className={s.submitSection}>
        <PrevStepButton disabled={m.lockUI} />

        <VerificationUploadSubmit
          className={s.submitButton}
          text={<Trans id="signup.verification_upload_step.submit">Finish</Trans>}
          onFinish={m.onFinish}
          onLock={m.onLockUI}
        />
      </div>
    </div>
  );
};
