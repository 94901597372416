import React from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Text } from '@components/typography/text';
import { permissionGroup as apiPermissionGroup } from './api';
import { useModel } from './model';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:approve-redeem-modal',
});

export const ApproveRequestModal: MFC<{
  redeemId: number;
  onApproved: () => void;
}> = ({ isVisible, redeemId, onApproved }) => {
  const m = useModel(redeemId, onApproved, ApproveRequestModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(ApproveRequestModal)}
    >
      <ModalTitle>
        <Trans id="approve_request_modal.approve.request">Approve request?</Trans>
      </ModalTitle>

      <ModalContent>
        <Text level={1} className="mb2">
          <Trans id="approve_request_modal.do.you.want.approve.request">
            Do you want to approve request?
          </Trans>
        </Text>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} disabled={m.lockUI} onClick={m.onApproveClicked}>
          <Trans id="button.approve">Approve</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
