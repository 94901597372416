import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { useI18n } from 'containers/services/commonService';
import { defineMessage } from '@lingui/macro';
import { configSelectors } from '@config/core';
import { PageContent, PageTitle } from '@components/page';

export const AccessDeniedPage: FC = () => {
  const { i18n } = useI18n();
  const isAuthorized: boolean = useSelector(configSelectors.isAuthorized);

  return !isAuthorized ? (
    <Redirect to="/" />
  ) : (
    <PageContent uat="system-access-denied-page">
      <PageTitle
        title={i18n._(defineMessage({ id: 'router.access_denied', message: 'Access is denied' }))}
      />
      &nbsp;
    </PageContent>
  );
};
