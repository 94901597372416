import React, { useMemo, useState } from 'react';
import { defineMessage, Trans } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { TabsHeaderData } from '@components/tabs/regular';
import v from '@components/form/validators';
import { OnFormAction } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { closeModalAction, MFC } from '@modules/modal';
import { notifySuccess } from '@modules/notify';

import { useInitialValues, useLockUI, useOnPageOpened, useUserId } from './hooks';
import api, { UpdateUserFields as Fields, UpdateUserForm as Form } from './api';

export const formId = 'handle-user-form-id';

export const useModel = (modal: MFC, onUserHandled: (id: number) => void = () => {}) => {
  useOnPageOpened();
  const { isNewUser, setHashUserId } = useUserId();
  const initialValues = useInitialValues();
  const { lockUI, setUpdateUserLoading } = useLockUI();
  const closeModal = useCallDispatch(closeModalAction);

  const onCloseClicked = () => {
    setHashUserId(undefined);
    closeModal(modal);
  };

  const onFormAction: OnFormAction<Form> = {
    error: () => setUpdateUserLoading(false),
    fieldValidation: {
      [Fields.role]: [v.required],
      [Fields.company]: [v.required, v.lengthMax(64)],
      [Fields.country]: [v.required],
      [Fields.email]: [v.required],
      [Fields.firstName]: [v.required, v.lengthMax(64)],
      [Fields.lastName]: [v.required, v.lengthMax(64)],
      [Fields.matchingPassword]: isNewUser
        ? [v.required, v.passwordConfirm(Fields.password)]
        : [v.passwordConfirm(Fields.password)],
      [Fields.password]: isNewUser ? [v.required] : [],
      [Fields.phone]: [v.required],
      [Fields.postalCode]: [v.required],
      [Fields.city]: [v.required],
      [Fields.state]: [v.required],
      [Fields.building]: [v.required],
    },
    submit: async (user) => {
      setUpdateUserLoading(true);
      const userId: number = await api.updateUser(user);
      onUserHandled(userId);
      setUpdateUserLoading(false);
      notifySuccess({
        defaultTitle: true,
        text: <Trans id="handle_user_modal.success_save_notification.title">User saved</Trans>,
      });
      onCloseClicked();
    },
  };

  return {
    initialValues,
    isNewUser,
    lockUI,
    onCloseClicked,
    onFormAction,
  };
};

export const Tab = {
  bankDetails: '#bank-details',
  profile: '#profile',
};
type TabKeys = keyof typeof Tab;
type Tabs = typeof Tab[TabKeys];

export const useTabs = () => {
  const { i18n, lang } = useI18n();
  const [activeTab, onTabChanged] = useState<Tabs>(Tab.profile);

  const headerData = useMemo(() => {
    const tabs: { [k in keyof typeof Tab]: TabsHeaderData[0] } = {
      bankDetails: {
        data: i18n._(defineMessage({ id: 'handle_user_modal.tab.banks', message: 'Banks' })),
        tabKey: Tab.bankDetails,
      },
      profile: {
        data: i18n._(
          defineMessage({ id: 'user_form.handle_user_modal.tab.profile', message: 'Profile' })
        ),
        tabKey: Tab.profile,
      },
    };

    return [tabs.profile, tabs.bankDetails];
  }, [lang]);

  return {
    activeTab,
    headerData,
    onTabChanged,
  };
};
