import _ from 'lodash';

import { COIN_REGEXP, INT_REGEXP } from '@constants/regexp';
import { checkRegExp } from 'containers/services/commonService';
import {
  RedeemConfirmationModalActionsTypes as RedeemConfirmationTypes,
  redeemConfirmationModalStateInitial,
} from './model';

export const redeemConfirmationModalReducer = (combinedState, action) => {
  const state = combinedState.redeemConfirmationModal;
  const redeemOptionsState = combinedState.redeemOptionsModal;
  const newState = (localState) => ({
    ...combinedState,
    redeemConfirmationModal: { ...localState },
  });

  switch (action.type) {
    case RedeemConfirmationTypes.GET_STABLE_BALANCE: {
      return newState({
        ...state,
        isStableBalanceLoading: true,
      });
    }

    case RedeemConfirmationTypes.GET_STABLE_BALANCE_FAIL: {
      return newState({
        ...state,
        isStableBalanceLoading: false,
      });
    }

    case RedeemConfirmationTypes.GET_STABLE_BALANCE_SUCCESS: {
      return newState({
        ...state,
        fiatBalance: _.get(
          action.payload.find(({ currency }) => currency.code === 'USD'),
          'balance',
          0
        ),
        isStableBalanceLoading: false,
        stableBalance: action.payload,
      });
    }

    case RedeemConfirmationTypes.ON_METAL_QUANTITY_CHANGED: {
      const metal = action.payload;
      if (!checkRegExp(metal, COIN_REGEXP)) {
        return combinedState;
      }

      return newState({
        ...state,
        metalQuantity: metal,
        optionsQuantity: metal / redeemOptionsState.storedData.metalPrice,
      });
    }

    case RedeemConfirmationTypes.ON_MODAL_LEFT: {
      return newState({
        ...redeemConfirmationModalStateInitial,
        storedData: state.storedData,
      });
    }

    case RedeemConfirmationTypes.ON_MODAL_OPENED: {
      return newState({
        ...redeemConfirmationModalStateInitial,
      });
    }

    case RedeemConfirmationTypes.ON_OPTIONS_QUANTITY_CHANGED: {
      const options = action.payload;
      if (!checkRegExp(options, INT_REGEXP)) {
        return combinedState;
      }

      return newState({
        ...state,
        metalQuantity: +options * redeemOptionsState.storedData.metalPrice,
        optionsQuantity: options,
      });
    }

    case RedeemConfirmationTypes.SET_CONFIRMED_REDEEM_DATA: {
      return newState({
        ...state,
        storedData: {
          confirmedRedeemData: action.payload,
        },
      });
    }

    default: {
      return combinedState;
    }
  }
};
