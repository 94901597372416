import { useCallback, useEffect, useState, useMemo } from 'react';
import { mergeBalances } from '@helper/balance';
import { useLiveUpdate, LiveUpdateEvent, LiveUpdateEventType } from '@services/stomp/live-update';
import { Awaited } from '../shared/utils';
import { addSortItem, sortItems } from '../shared/table/sorting';
import { useColumns, TableCoin, SortItem, SortOrder, OnSort } from './use-columns';
import api from './api';

export function useWalletPrimaryModel() {
  const [walletData, setWalletData] = useState<Awaited<
    ReturnType<typeof api.getPrimaryBalance>
  > | null>(null);

  useEffect(() => {
    api.getPrimaryBalance().then((data) => {
      setWalletData(data);
    });
  }, []);

  useLiveUpdate((event: LiveUpdateEvent) => {
    if (!walletData) return;

    if (event.type === LiveUpdateEventType.stableBalanceChanged) {
      api
        .getPrimaryStableBalance()
        .then(({ primaryActiveStableBalances, primaryHoldStableBalances }) => {
          setWalletData((currentData) => {
            return {
              ...currentData,
              primaryActiveStableBalances,
              primaryHoldStableBalances,
            };
          });
        });
    }

    if (event.type === LiveUpdateEventType.coinBalanceChanged) {
      api.getPrimaryCoinBalance().then(({ primaryActiveCoinBalances, primaryHoldCoinBalances }) => {
        setWalletData((currentData) => {
          return {
            ...currentData,
            primaryActiveCoinBalances,
            primaryHoldCoinBalances,
          };
        });
      });
    }
  });

  const balancePane = useMemo(() => {
    if (!walletData)
      return {
        stableBalances: [],
        pendingBalances: [],
      };
    const stableBalancesOverdraft = walletData.primaryActiveStableBalances.reduce<
      Record<string, number | undefined>
    >((acc, b) => {
      acc[b.asset] = b.data.overdraft;
      return acc;
    }, {});
    const stableBalances = mergeBalances(
      walletData.primaryActiveStableBalances,
      walletData.primaryHoldStableBalances
    ).map((b) => ({
      ...b,
      amount: b.amount - (stableBalancesOverdraft[b.asset] ?? 0),
    }));
    const pendingBalances = [
      ...walletData.primaryHoldCoinBalances,
      ...walletData.primaryHoldStableBalances,
    ];
    return { stableBalances, pendingBalances };
  }, [
    walletData?.primaryHoldCoinBalances,
    walletData?.primaryHoldStableBalances,
    walletData?.primaryActiveStableBalances,
  ]);

  const [sortOrder, setSortOrder] = useState<SortOrder>([]);

  const onCoinTableSort = useCallback<OnSort>((field, direction) => {
    setSortOrder((currentOrder) => {
      const sortItem: SortItem = { field, direction };
      return addSortItem(currentOrder, sortItem);
    });
  }, []);

  const coinTableData = useMemo(() => {
    if (!walletData) return [];

    const coinBalances: TableCoin[] = mergeBalances(
      walletData.primaryActiveCoinBalances,
      walletData.primaryHoldCoinBalances
    ).map((b) => ({
      amount: b.amount,
      asset: b.asset,
      brandName: b.data.brandName,
      metal: b.data.metal,
    }));
    return sortItems(coinBalances, sortOrder);
  }, [walletData?.primaryActiveCoinBalances, walletData?.primaryHoldCoinBalances, sortOrder]);

  const coinTableColumns = useColumns(coinTableData, onCoinTableSort);

  return {
    balancePane,
    coinTableColumns,
    coinTableData,
    loading: walletData === null,
  };
}
