import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import { SubmitButton } from '@components/form';
import { actions, selectors } from '../../store';
import { formId } from '../../store/model';

export const SendStableToInvButton: FC = memo(() => {
  const disabled = useSelector(selectors.isSendButtonDisabled);

  return (
    <SubmitButton
      name={formId}
      fullWidth={true}
      disabled={disabled}
      onAction={actions.onSendStableForm}
    >
      <Trans id="stable_to_inv_modal.button.send">Send</Trans>
    </SubmitButton>
  );
});
