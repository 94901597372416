import { FC } from 'react';
import s from './index.module.scss';

type Props = {
  sideContent: JSX.Element;
  mainContent: JSX.Element;
};

export const WalletPageLayout: FC<Props> = ({ sideContent, mainContent }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.sideContent}>{sideContent}</div>
      <div className={s.mainContent}>{mainContent}</div>
    </div>
  );
};
