import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { CMBankAccount, TransactionForm } from '../shared/api';
// ----------------------------------------------------------------
export type { TransactionForm } from '../shared/api';
export { TransactionFields } from '../shared/api';
const checkTransactionFields = ['value'];
const checkTransactionQuery = 'backofficeCheckExtendedTransaction';
const checkTransaction = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: checkTransactionQuery,
  queryFields: checkTransactionFields,
  query: (extendedTransaction: TransactionForm): Promise<void> => {
    const variables = v.createExtendedTransactionInput({ extendedTransaction });
    return stompClientService.sendData(
      checkTransactionQuery,
      sign(checkTransactionQuery, checkTransactionFields),
      variables
    );
  },
});
// ----------------------------------------------------------------
export type { CMBankAccount } from '../shared/api';
const getBankAccountsFields = [
  'legalEntity',
  { bank: ['bankName'] },
  'accountNumber',
  'type',
  { currency: ['code', 'id'] },
  'currentBalance',
  { computedFields: ['calculatedBalance'] },
  'status',
  'id',
];
const getBankAccountsQuery = 'backofficeBankAccounts';
const getBankAccounts = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: getBankAccountsQuery,
  queryFields: getBankAccountsFields,
  query: (): Promise<Array<CMBankAccount>> => {
    return stompClientService
      .getData<Array<CMBankAccount>>(
        getBankAccountsQuery,
        sign(getBankAccountsQuery, getBankAccountsFields)
      )
      .then((accounts) => accounts.filter((a) => a.status === 'ACTIVE'));
  },
});
// ----------------------------------------------------------------
const api = {
  checkTransaction,
  getBankAccounts,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:cm-check-transaction');

export default api;
