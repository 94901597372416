import { useContext, FC } from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { Context } from '../context';

export const NotEnoughBalance: FC = () => {
  const { dispatch } = useContext(Context);
  const goToCurrencySetting = () => {
    dispatch({ type: 'ON_CURRENCY_SETTING_REQUESTED' });
  };

  return (
    <>
      <ModalTitle>
        <Trans id={'my_wallet.exchange_currency.not_enough_balance.title'}>
          Error title: not enough balance
        </Trans>
      </ModalTitle>
      <ModalContent>
        <Trans id={'my_wallet.exchange_currency.not_enough_balance.message'}>
          Error message: not enough balance
        </Trans>
      </ModalContent>
      <ModalFooter>
        <ButtonPrimary
          className={'wallet-currency-exchange-submit-button'}
          onClick={goToCurrencySetting}
          fullWidth
        >
          <Trans id={'my_wallet.exchange_currency.not_enough_balance.go_back'}>Back</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </>
  );
};
