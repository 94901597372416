import sign from 'tm2sign.macro';
import {
  normalizeCoinBalances,
  normalizeStableBalances,
  NormalizedBalance,
} from '@helper/balance/index';
import { Permission, PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

type QueryCoinBalance = {
  amount: number;
  coin: {
    id: number;
    asset: string;
    brandName: string;
    hasActiveRedeem: boolean;
    metal: {
      label: string;
    };
    coinInfo?: {
      physicalRedemption: boolean;
    };
  };
};

type QueryStableBalance = {
  amount: number;
  currency: {
    asset: string;
    id: number;
  };
  overdraft: number;
};

type CoinData = {
  id: number;
  brandName: string;
  metal: string;
  hasActiveRedeem: boolean;
  physicalRedemption: boolean;
};

type StableData = unknown;

type StableOverdraftData = {
  overdraft: number;
};

const coinBalanceFields = {
  coinBalance: [
    'amount',
    {
      coin: [
        'id',
        'asset',
        'brandName',
        'hasActiveRedeem',
        { coinInfo: ['physicalRedemption'] },
        { metal: ['label'] },
      ],
    },
  ],
};

const stableBalanceFields = {
  stableBalance: [
    'amount',
    {
      currency: ['asset', 'id'],
    },
    'overdraft',
  ],
};

function mapCoinBalance(queryCoinBalance: QueryCoinBalance): CoinData {
  return {
    id: queryCoinBalance.coin.id,
    brandName: queryCoinBalance.coin.brandName,
    metal: queryCoinBalance.coin.metal.label,
    hasActiveRedeem: queryCoinBalance.coin.hasActiveRedeem,
    physicalRedemption: queryCoinBalance.coin?.coinInfo.physicalRedemption ?? false,
  };
}

type GetBalanceResponse = {
  primaryActiveCoinBalances: NormalizedBalance<CoinData>[];
  primaryActiveStableBalances: NormalizedBalance<StableOverdraftData>[];
  primaryHoldCoinBalances: NormalizedBalance<CoinData>[];
  primaryHoldStableBalances: NormalizedBalance<StableData>[];
  secondaryActiveCoinBalances: NormalizedBalance<CoinData>[];
  secondaryActiveStableBalances: NormalizedBalance<StableData>[];
  secondaryHoldCoinBalances: NormalizedBalance<CoinData>[];
  secondaryHoldStableBalances: NormalizedBalance<StableData>[];
};

const getPrimaryBalanceQuery = 'userProfile';
const getPrimaryBalanceFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [{ ...coinBalanceFields }, { ...stableBalanceFields }],
          },
          {
            hold: [{ ...coinBalanceFields }, { ...stableBalanceFields }],
          },
        ],
      },
      // { // TM2-2997
      //   secondary: [
      //     {
      //       active: [{ ...coinBalanceFields }, { ...stableBalanceFields }],
      //     },
      //     {
      //       hold: [{ ...coinBalanceFields }, { ...stableBalanceFields }],
      //     },
      //   ],
      // },
    ],
  },
];
const getBalance = makeQuery({
  permissions: {
    and: [Permission.BALANCE_VIEW_PRIMARY_MY, Permission.BALANCE_VIEW_SECONDARY_MY],
  },
  queryName: getPrimaryBalanceQuery,
  queryFields: getPrimaryBalanceFields,
  query: (): Promise<GetBalanceResponse> => {
    type MixedBalance = {
      coinBalance: QueryCoinBalance[];
      stableBalance: QueryStableBalance[];
    };

    type QueryResponse = {
      balance: {
        primary: {
          active: MixedBalance;
          hold: MixedBalance;
        };
        secondary: {
          active: MixedBalance;
          hold: MixedBalance;
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getPrimaryBalanceQuery,
        sign(getPrimaryBalanceQuery, getPrimaryBalanceFields)
      )
      .then(({ balance }) => {
        const primaryActiveCoinBalances = normalizeCoinBalances(
          balance.primary.active.coinBalance
        ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const primaryActiveStableBalances = normalizeStableBalances(
          balance.primary.active.stableBalance
        ).map((b) => ({ ...b, data: { overdraft: b.data.overdraft } }));

        const primaryHoldCoinBalances = normalizeCoinBalances(balance.primary.hold.coinBalance).map(
          (b) => ({ ...b, data: mapCoinBalance(b.data) })
        );

        const primaryHoldStableBalances = normalizeStableBalances(
          balance.primary.hold.stableBalance
        ).map((b) => ({ ...b, data: null }));

        const secondaryActiveCoinBalances = []; // TM2-2997
        // const secondaryActiveCoinBalances = normalizeCoinBalances(
        //   balance.secondary.active.coinBalance
        // ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const secondaryActiveStableBalances = []; // TM2-2997
        // const secondaryActiveStableBalances = normalizeStableBalances(
        //   balance.secondary.active.stableBalance
        // ).map((b) => ({ ...b, data: null }));

        const secondaryHoldCoinBalances = []; // TM2-2997
        // const secondaryHoldCoinBalances = normalizeCoinBalances(
        //   balance.secondary.hold.coinBalance
        // ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const secondaryHoldStableBalances = []; // TM2-2997
        // const secondaryHoldStableBalances = normalizeStableBalances(
        //   balance.secondary.hold.stableBalance
        // ).map((b) => ({ ...b, data: null }));

        return {
          primaryActiveCoinBalances,
          primaryActiveStableBalances,
          primaryHoldCoinBalances,
          primaryHoldStableBalances,
          secondaryActiveCoinBalances,
          secondaryActiveStableBalances,
          // TM2-2997: Skip secondary hold balances.
          secondaryHoldCoinBalances: [],
          secondaryHoldStableBalances: [],
        };
      });
  },
});

const getStableBalanceQuery = 'userProfile';
const getStableBalanceFields = [
  {
    balance: [
      {
        primary: [{ active: [{ ...stableBalanceFields }] }, { hold: [{ ...stableBalanceFields }] }],
      },
      // { // TM2-2997
      //   secondary: [
      //     { active: [{ ...stableBalanceFields }] },
      //     { hold: [{ ...stableBalanceFields }] },
      //   ],
      // },
    ],
  },
];
const getStableBalance = makeQuery({
  permissions: {
    and: [Permission.BALANCE_VIEW_PRIMARY_MY, Permission.BALANCE_VIEW_SECONDARY_MY],
  },
  queryName: getStableBalanceQuery,
  queryFields: getStableBalanceFields,
  query: (): Promise<{
    primaryActiveStableBalances: NormalizedBalance<StableOverdraftData>[];
    primaryHoldStableBalances: NormalizedBalance<StableData>[];
    secondaryActiveStableBalances: NormalizedBalance<StableData>[];
    secondaryHoldStableBalances: NormalizedBalance<StableData>[];
  }> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: { stableBalance: QueryStableBalance[] };
          hold: { stableBalance: QueryStableBalance[] };
        };
        secondary: {
          active: { stableBalance: QueryStableBalance[] };
          hold: { stableBalance: QueryStableBalance[] };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getStableBalanceQuery,
        sign(getStableBalanceQuery, getStableBalanceFields)
      )
      .then(({ balance }) => {
        const primaryActiveStableBalances = normalizeStableBalances(
          balance.primary.active.stableBalance
        ).map((b) => ({ ...b, data: { overdraft: b.data.overdraft } }));

        const primaryHoldStableBalances = normalizeStableBalances(
          balance.primary.hold.stableBalance
        ).map((b) => ({ ...b, data: null }));

        const secondaryActiveStableBalances = []; // TM2-2997
        // const secondaryActiveStableBalances = normalizeStableBalances(
        //   balance.secondary.active.stableBalance
        // ).map((b) => ({ ...b, data: null }));

        const secondaryHoldStableBalances = []; // TM2-2997
        // const secondaryHoldStableBalances = normalizeStableBalances(
        //   balance.secondary.hold.stableBalance
        // ).map((b) => ({ ...b, data: null }));

        return {
          primaryActiveStableBalances,
          primaryHoldStableBalances,
          secondaryActiveStableBalances,
          // TM2-2997: Skip secondary hold balances.
          secondaryHoldStableBalances: [],
        };
      });
  },
});

const getCoinBalanceQuery = 'userProfile';
const getCoinBalanceFields = [
  {
    balance: [
      {
        primary: [{ active: [{ ...coinBalanceFields }] }, { hold: [{ ...coinBalanceFields }] }],
      },
      // { // TM2-2997
      //   secondary: [{ active: [{ ...coinBalanceFields }] }, { hold: [{ ...coinBalanceFields }] }],
      // },
    ],
  },
];
const getCoinBalance = makeQuery({
  permissions: {
    and: [Permission.BALANCE_VIEW_PRIMARY_MY, Permission.BALANCE_VIEW_SECONDARY_MY],
  },
  queryName: getStableBalanceQuery,
  queryFields: getStableBalanceFields,
  query: (): Promise<{
    primaryActiveCoinBalances: NormalizedBalance<CoinData>[];
    primaryHoldCoinBalances: NormalizedBalance<CoinData>[];
    secondaryActiveCoinBalances: NormalizedBalance<CoinData>[];
    secondaryHoldCoinBalances: NormalizedBalance<CoinData>[];
  }> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: { coinBalance: QueryCoinBalance[] };
          hold: { coinBalance: QueryCoinBalance[] };
        };
        secondary: {
          active: { coinBalance: QueryCoinBalance[] };
          hold: { coinBalance: QueryCoinBalance[] };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(getCoinBalanceQuery, sign(getCoinBalanceQuery, getCoinBalanceFields))
      .then(({ balance }) => {
        const primaryActiveCoinBalances = normalizeCoinBalances(
          balance.primary.active.coinBalance
        ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const primaryHoldCoinBalances = normalizeCoinBalances(balance.primary.hold.coinBalance).map(
          (b) => ({ ...b, data: mapCoinBalance(b.data) })
        );

        const secondaryActiveCoinBalances = []; // TM2-2997
        // const secondaryActiveCoinBalances = normalizeCoinBalances(
        //   balance.secondary.active.coinBalance
        // ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        const secondaryHoldCoinBalances = []; // TM2-2997
        // const secondaryHoldCoinBalances = normalizeCoinBalances(
        //   balance.secondary.hold.coinBalance
        // ).map((b) => ({ ...b, data: mapCoinBalance(b.data) }));

        return {
          primaryActiveCoinBalances,
          primaryHoldCoinBalances,
          secondaryActiveCoinBalances,
          // TM2-2997: Skip secondary hold balances.
          secondaryHoldCoinBalances: [],
        };
      });
  },
});

const api = {
  getBalance,
  getCoinBalance,
  getStableBalance,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:wallet-full');

export default api;
