import React, { FC } from 'react';
import classnames from 'classnames';
import { defineMessage, Trans } from '@lingui/macro';

import { PermissionGroup } from '@permissions/core';
import { PageContent, PageTitle } from '@components/page';
import { Box } from '@components/box';
import { Divider } from '@components/divider';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { Link } from '@components/link';

import { permissionGroup as modalPermissionGroup } from './modal/api';
import { permissionGroup as apiPermissionGroup } from './api';
import profileImg from './images/profile-screen.png';
import walletImg1 from './images/wallet-screen-1.png';
import walletImg2 from './images/wallet-screen-2.png';
import pmMainImg from './images/pm-main-screen.png';
import pmDetailImg1 from './images/pm-detail-screen-1.png';
import pmDetailImg2 from './images/pm-detail-screen-2.png';
import { useModel } from './model';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [modalPermissionGroup, apiPermissionGroup],
  marker: 'layout:onboard-help-page',
});

enum Hash {
  general = 'general',
  marketDetailed = 'market-detailed',
  marketMain = 'market-main',
  profile = 'profile',
  secondaryMarket = 'secondary-market',
  wallet = 'my-wallet',
}
const createLink = (hash: Hash) => `/tutorial#${hash}`;

// constants below is used for correct autoscroll on page opened
// https://www.afasterweb.com/2019/10/30/a-new-way-to-prevent-layout-jank-during-image-load
const imgWidthDefault = '900';
const imgHeightDefault = '563';

export const TutorialPage: FC = () => {
  const m = useModel();

  return (
    <PageContent id="tutorial-begin" uat="tutorial-page">
      <PageTitle
        title={m.i18n._(defineMessage({ id: 'onboard_help.page.title', message: 'How it works' }))}
      />

      <Box className="mb1">
        <Title level={1}>
          <Trans id="onboard_help.page.guide.title">TM2 Guide</Trans>
        </Title>

        <Divider className="mb3" />

        <Text className="mb2">
          <Trans id="onboard_help.page.guide.description">
            These are general instructions on how to use the Tm2 platform. You can always access it
            later by clicking on your profile icon in the top right corner and then choose the “HOW
            IT WORKS” tab.
          </Trans>
        </Text>
      </Box>

      <Box className="mb1">
        <Title level={1}>
          <Trans id="onboard_help.page.guide.content">Content:</Trans>
        </Title>

        <Divider className="mb3" />

        <div className={s.links}>
          <Link className="mb2" to={createLink(Hash.general)}>
            <Trans id="onboard_help.page.guide.link.general">General</Trans>
          </Link>
          <Link className="mb2" to={createLink(Hash.profile)}>
            <Trans id="onboard_help.page.guide.link.profile">Profile</Trans>
          </Link>
          <Link className="mb2" to={createLink(Hash.wallet)}>
            <Trans id="onboard_help.page.guide.link.wallet">My wallet</Trans>
          </Link>
          <Link className="mb2" to={createLink(Hash.marketMain)}>
            <Trans id="onboard_help.page.guide.link.primary-main">Primary Market (main page)</Trans>
          </Link>
          <Link className="mb2" to={createLink(Hash.marketDetailed)}>
            <Trans id="onboard_help.page.guide.link.primary-detailed">
              Primary Market (detailed page)
            </Trans>
          </Link>
          <Link to={createLink(Hash.secondaryMarket)}>
            <Trans id="onboard_help.page.guide.link.secondary-market">Secondary Market</Trans>
          </Link>
        </div>
      </Box>

      <Box className="mb1" id={Hash.general}>
        <Title level={1}>
          <Trans id="onboard_help.page.general.title">General</Trans>
        </Title>

        <Divider className="mb3" />

        <Text>
          <Trans id="onboard_help.page.general.description">
            Technology Metals Market is the first spot market where direct investment in technology
            metals is possible.
          </Trans>
        </Text>
      </Box>

      <Box className="mb1" id={Hash.profile}>
        <Title level={1}>
          <Trans id="onboard_help.page.profile.title">Profile</Trans>
        </Title>

        <Divider className="mb3" />

        <Text className="mb3">
          <Trans id="onboard_help.page.profile.description-1">
            You can access your <Link to="/profile">Profile</Link> page by clicking on the icon in
            the top right corner of the screen and then click on „Profile“. You can also access it
            by clicking on the TM2 logo in the top left corner of the screen.
          </Trans>
          <br />
          <Trans id="onboard_help.page.profile.description-2">
            The profile page shows your profile and bank details, along with your client id and
            role.
          </Trans>
        </Text>

        <div className={s.imageBox}>
          <img
            className={s.image}
            width={imgWidthDefault}
            height={imgHeightDefault}
            src={profileImg}
            alt="profile-screen"
          />
        </div>
      </Box>

      <Box className="mb1" id={Hash.wallet}>
        <Title level={1}>
          <Trans id="onboard_help.page.wallet.title">Wallet</Trans>
        </Title>

        <Divider className="mb3" />

        <Text className="mb3">
          <Trans id="onboard_help.page.wallet.description">
            In the <Link to="/my-wallet">My wallet</Link> you can see your balance and assets that
            you own. The assets are divided between Primary (for initial purchase) and Secondary
            Market (high-frequency trading), this division is done to distinguish between both
            markets on the platform.
          </Trans>
        </Text>

        <div className={classnames(s.imageBox, 'mb3')}>
          <img
            className={s.image}
            width={imgWidthDefault}
            height={imgHeightDefault}
            src={walletImg1}
            alt="wallet-screen-1"
          />
        </div>

        <Text className="mb1">
          <Trans id="onboard_help.page.wallet.functions">
            Inside the <Link to="/my-wallet">Wallet</Link> section you have access to four
            functions:
          </Trans>
        </Text>

        <ol className={classnames(s.list, 'mb3')} type="1">
          <li>
            <Trans id="onboard_help.page.wallet.fn.add_funds">
              Add funds – for depositing funds via Credit/Debit card or Wire transfer.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.wallet.fn.convert">
              Convert – to get access a currency converter.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.wallet.fn.transfer">
              Transfer – to transfer metals or your funds between the Primary or the Secondary
              Market.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.wallet.fn.withdrawal">
              Withdrawal – Accessing by clicking “...” in the top left menu near word balance.
              Withdrawal allows you to withdraw money from your account.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.wallet.fn.other">
              “…” - for other actions, including creation of redemption requests.
            </Trans>
          </li>
        </ol>

        <div className={classnames(s.imageBox, 'mb3')}>
          <img
            className={s.image}
            width={imgWidthDefault}
            height={imgHeightDefault}
            src={walletImg2}
            alt="wallet-screen-2"
          />
        </div>

        <Title level={2}>
          <Trans id="onboard_help.page.wallet.deposits_title">Deposits</Trans>
        </Title>

        <Text className="mb2">
          <Trans id="onboard_help.page.wallet.deposits_text">
            The <Link to="/deposits">Deposit</Link> section shows all your deposits via wire
            transfer and their details.
          </Trans>
        </Text>

        <Title level={2}>
          <Trans id="onboard_help.page.wallet.withdrawals_title">Withdrawals</Trans>
        </Title>

        <Text className="mb2">
          <Trans id="onboard_help.page.wallet.withdrawals_text">
            The <Link to="/withdrawals">Withdrawal</Link> section shows all your withdrawal requests
            and their details.
          </Trans>
        </Text>

        <Title level={2}>
          <Trans id="onboard_help.page.wallet.redemptions_title">Redemptions</Trans>
        </Title>

        <Text>
          <Trans id="onboard_help.page.wallet.redemptions_text">
            The <Link to="/redeem-requests">Redemption</Link>
            section shows all your redemption requests and their details.
          </Trans>
        </Text>
      </Box>

      <Box className="mb1" id={Hash.marketMain}>
        <Title level={1}>
          <Trans id="onboard_help.page.market_main.title">Primary Market - Main page</Trans>
        </Title>

        <Divider className="mb3" />

        <Text className="mb3">
          <Trans id="onboard_help.page.market_main.description">
            In the Primary Market, you can see all metals listed on the TM2 platform.
            <br />
            You can access each asset traded by a specific currency by pressing on their price,
            which will show more detailed information about each asset.
          </Trans>
        </Text>

        <div className={s.imageBox}>
          <img
            className={s.image}
            width={imgWidthDefault}
            height={imgHeightDefault}
            src={pmMainImg}
            alt="primary-main-screen"
          />
        </div>
      </Box>

      <Box className="mb1" id={Hash.marketDetailed}>
        <Title level={1}>
          <Trans id="onboard_help.page.market_detail.title">Primary Market - Detail page</Trans>
        </Title>

        <Divider className="mb3" />

        <ol className={classnames(s.list, 'mb3')} type="1">
          <li>
            <Trans id="onboard_help.page.market_detail.block.top_banner">
              Top banner – shows the asset and its last traded price, along with how many units are
              available at the moment.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.market_detail.block.trade_history">
              Trade history – shows your trade history of selected asset.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.market_detail.block.portfolio">
              Portfolio – shows the current balance of your metals.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.market_detail.block.price_chart">
              Price Chart – displays the price of a particular asset over a selected period of time.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.market_detail.block.order_book">
              Order book – displays all the bids available for the selected product.
            </Trans>
          </li>
          <li>
            <Trans id="onboard_help.page.market_detail.block.information">
              Information section – shows metals detailed information separated on tab{' '}
              <Link to="/primary-detailed#general">General</Link>,&nbsp;
              <Link to="/primary-detailed#primary">Primary Market information</Link>,&nbsp;
              <Link to="/primary-detailed#info">Product information</Link> along with&nbsp;
              <Link to="/primary-detailed#downloads">downloadable</Link> information such as
              factsheets.
            </Trans>
          </li>
        </ol>

        <div className={classnames(s.imageBox, 'mb3')}>
          <img
            className={s.image}
            width={imgWidthDefault}
            height={imgHeightDefault}
            src={pmDetailImg1}
            alt="primary-detail-screen-1"
          />
        </div>

        <Text className="mb3">
          <Trans id="onboard_help.page.market_detail.select_symbol">
            If you press the “Select symbols” you get a drop-down menu which you can navigate
            through more assets without returning to Primary Market (main) page.
          </Trans>
        </Text>

        <div className={s.imageBox}>
          <img
            className={s.image}
            width={imgWidthDefault}
            height={imgHeightDefault}
            src={pmDetailImg2}
            alt="primary-detail-screen-2"
          />
        </div>
      </Box>

      <Box id={Hash.secondaryMarket}>
        <Title level={1}>
          <Trans id="onboard_help.page.secondary_market.title">Secondary Market</Trans>
        </Title>

        <Divider className="mb3" />

        <Text>
          <Trans id="onboard_help.page.secondary_market.description">
            The <Link onClick={m.onSecondaryMarketClicked}>Secondary Market</Link> is a
            high-performance automated matching engine powered by Nasdaq, integrated with the
            Primary Market. It is open from 9am to 9pm GMT time, allowing it to cover most of the
            world's timetable.
            <br />
            It‘s a fully customizable environment, where you can set up your workplace and save it
            once you're satisfied with your setup.
          </Trans>
        </Text>
      </Box>
    </PageContent>
  );
};
