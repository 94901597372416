import { PageContent } from '@components/page';
import { Table } from './autosized-table';
// import {Table} from './base-table';
// import {Table} from './core-table';
import s from './index.module.scss';

export function TablePlayground() {
  return (
    <PageContent>
      <div className={s.tableWrapper}>
        <Table />
      </div>
    </PageContent>
  );
}
