import { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';

import { configServiceActions } from '@config/store/service.acions';
import { Context } from '../../context';

export const useModel = () => {
  const reduxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const [lockUI, onLockUI] = useState<boolean>(false);

  const onFinish = () => {
    dispatch({ type: 'ON_STEP_COMPLETED' });
    reduxDispatch(configServiceActions.postRegFinished());
  };

  return {
    lockUI,
    onLockUI,
    onFinish,
  };
};
