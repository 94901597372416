import moment from 'moment';
import _ from 'lodash';
import { DATE_FORMAT_GQL } from '@constants/date';
import { graphqlMutation, graphqlQuery, useGraphqlMutation, useGraphqlQuery } from './gql/executor';

const LocalDate = (localDateObject) => {
  const fieldName = key(localDateObject);
  const value = localDateObject[fieldName];
  return !value ? {} : v({ [fieldName]: moment(value).format(DATE_FORMAT_GQL) }, 'LocalDate');
};
const Long = (longObject) => v(longObject, 'Long');

const v = (valueObject, type) => ({
  [key(valueObject)]: { value: valueObject[key(valueObject)], type },
});
const key = (obj) => Object.keys(obj)[0];

export const coinsPageRequest = async (wsInstance, fields, page) =>
  graphqlQuery(wsInstance, 'coinsPage', fields, { page });

export const currenciesRequest = async (wsInstance, fields) =>
  graphqlQuery(wsInstance, 'currencies', fields);

export const cancelOfferMutation = async (wsInstance, id, fields) =>
  graphqlMutation(wsInstance, 'cancelOffer', fields, { id });

export const offersPageQuery = async (wsInstance, fields, page, status, assetId, issuerId) =>
  graphqlQuery(wsInstance, 'offersPage', fields, {
    ...v({ page }, 'Long'),
    ...v({ status }, 'OfferStatus'),
    assetId,
    issuerId,
  });

export const offersCoinsQuery = async (wsInstance, fields, variables) =>
  graphqlQuery(wsInstance, 'offersCoins', fields, variables);

export const bidOrdersCoinsQuery = async (wsInstance, fields, variables) =>
  graphqlQuery(wsInstance, 'bidOrdersCoins', fields, variables);

export const bidOrdersPageQuery = async (wsInstance, fields, page, status, coinId, currencyId) =>
  graphqlQuery(wsInstance, 'bidOrdersPage', fields, {
    ...v({ page }, 'Long'),
    ...v({ status }, 'BidOrderStatus'),
    ...v({ coinId }, 'Long'),
    ...v({ currencyId }, 'Long'),
  });

export const stableBalanceQuery = async (wsInstance, fields) =>
  graphqlQuery(wsInstance, 'stableBalance', fields);

export const coinBalanceQuery = async (wsInstance, fields) =>
  graphqlQuery(wsInstance, 'coinBalance', fields);

export const basicSMFeeQuery = async (wsInstance, fields) =>
  graphqlQuery(wsInstance, 'basicSecondaryMarketFee', fields);

export const denyBidOrderMutation = async (wsInstance, id, reason, fields) =>
  graphqlMutation(wsInstance, 'denyBidOrder', fields, {
    id,
    reason,
  });

export const approveBidOrderMutation = async (wsInstance, id, bankTxId, fields) =>
  graphqlMutation(wsInstance, 'approveBidOrder', fields, {
    id,
    bankTxId,
  });

export const tickerQuery = async (wsInstance, fields) => graphqlQuery(wsInstance, 'ticker', fields);

export const activeOffersPricesQuery = async (wsInstance, fields, coinId, currencyId) =>
  graphqlQuery(wsInstance, 'activeOffersPrices', fields, {
    ...v({ coinId }, 'Long'),
    ...v({ currencyId }, 'Long'),
  }).then((prices) => prices && _.orderBy(prices, 'quantityPerGram'));

export const weekDailyPriceQuery = async (wsInstance, fields, coinId, currencyId, unit) =>
  graphqlQuery(wsInstance, 'weekDailyPrice', fields, {
    ...v({ coinId }, 'Long'),
    ...v({ currencyId }, 'Long'),
    ...v({ unit }, 'ChronoUnit'),
  });

export const setAssetActiveMutation = async (wsInstance, fields, asset, active) =>
  graphqlMutation(wsInstance, 'setAssetActive', fields, { asset, active });

export const usersByRoleQuery = async (wsInstance, role, fields) =>
  graphqlQuery(wsInstance, 'usersByRole', fields, { role });

export const buildStatementQuery = (
  wsInstance,
  fields,
  currencyId,
  listingBrokerId,
  startDate,
  endDate
) =>
  graphqlQuery(wsInstance, 'buildStatement', fields, {
    ...v({ currencyId }, 'Long'),
    ...v({ listingBrokerId }, 'Long'),
    ...v({ startDate }, 'LocalDate'),
    ...v({ endDate }, 'LocalDate'),
  });

export const useUpdateProfileMutation = (fields) =>
  useGraphqlMutation('updateProfile', fields, (userProfile) => ({
    ...v({ userProfile }, 'Map_String_ObjectScalar'),
  }));

export const useIssuerBalanceQuery = (fields) =>
  useGraphqlQuery('issuerBalance', fields, (issuerId, startDate, endDate) => ({
    ...Long({ issuerId }),
    ...LocalDate({ startDate }),
    ...LocalDate({ endDate }),
  }));
