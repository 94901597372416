import { Selector } from '@models/redux';
import { configSelectors } from '@config/core';
import { wireTransferViewSelectors } from '../wire-transfer/store/selectors';
import { creditCardViewSelectors } from '../credit-card/store/selectors';
import { AddFundsModalState, AddFundsType } from './model';

const addFundsModalState: Selector<AddFundsModalState> = (root) =>
  root.main?.deposits?.addFunds?.modal;

const addType: Selector<AddFundsType> = (root) => {
  const type: AddFundsType = addFundsModalState(root).addType;
  return type || AddFundsType.wireTransfer;

  // tm2-3094: don't remove commented code.
  // const isInvestor = configSelectors.isInvestor(root);
  // return type || ((isInvestor) ? AddFundsType.creditCard : AddFundsType.wireTransfer);
};

const lockUI: Selector<boolean> = (root) =>
  creditCardViewSelectors.lockUI(root) ||
  wireTransferViewSelectors.lockUI(root) ||
  addFundsModalState(root).isGetBankAccountsLoading;

export const addFundsModalSelectors = {
  addFundsModalState,
  addType,
  lockUI,
};
