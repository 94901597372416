import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { Role as R } from '@permissions/core';
import { BlockedButtonIcon } from '@components/icons';
import { MoreActions } from '@components/more-actions';
import { PageTable } from 'components/page-table';
import { formatToken } from 'containers/services/commonService';
import { PositionInfoPopUp } from './elements/position-info-popup';
import { MetalCell } from './elements/metal-cell';
import { useCoinsTableModel } from './model';

export const CoinsTable = () => {
  const {
    coinsPage,
    getLastVaultBalance,
    loadMoreCoins,
    onAddAmountCoin,
    onDischargeTokenClicked,
    requestSendMetalsToAccount,
    showBlockSwitchConfirmModal,
  } = useCoinsTableModel();

  const columns = [
    {
      head: '',
      cell: (coin) => (
        <MoreActions
          placement="right"
          list={[
            {
              access: { roles: [R.issuer, R.custodyManager] },
              content: <Trans id="digital_metals.actions.details">Details</Trans>,
              link: `/digital-metals/${coin.id}/details`,
            },
            {
              access: { roles: [R.admin] },
              content: <Trans id="digital_metals.actions.edit">Edit</Trans>,
              link: `/digital-metals/${coin.id}/edit`,
            },
            {
              access: { roles: [R.issuer] },
              action: () => requestSendMetalsToAccount(coin),
              content: (
                <Trans id={'coins_table.send_metals_to_linked'}>
                  Send metals to your linked investor account.
                </Trans>
              ),
              disabled: (user) => !user?.issuerInvestor?.stablePublicKey,
            },
            {
              access: { roles: [R.admin] },
              content: <Trans id="digital_metals.actions.issue">Issue</Trans>,
              action: () => onAddAmountCoin(coin),
            },
            {
              access: { extraCheck: () => !!coin?.info?.balance, roles: [R.admin] },
              content: <Trans id="digital_metals.actions.discharge">Discharge</Trans>,
              action: () => onDischargeTokenClicked(coin),
            },
            {
              access: { extraCheck: () => !!coin.info && coin.info.active, roles: [R.admin] },
              content: <Trans id="digital_metals.actions.block">Block</Trans>,
              action: () => showBlockSwitchConfirmModal(coin),
            },
            {
              access: { extraCheck: () => !!coin.info && !coin.info.active, roles: [R.admin] },
              content: <Trans id="digital_metals.actions.unblock">Unblock</Trans>,
              action: () => showBlockSwitchConfirmModal(coin),
            },
          ]}
        />
      ),
    },
    {
      head: () => <Trans id="digital_metals.table.metal">Metal</Trans>,
      cell: (coin) => <MetalCell label={coin.metal.label} />,
    },
    {
      head: () => <Trans id="digital_metals.table.symbol">Symbol</Trans>,
      cell: (coin) => coin.asset,
    },
    {
      head: () => <Trans id="digital_metals.table.total">Total</Trans>,
      className: 'align-right',
      cell: (coin) => (!coin.info ? '-' : formatToken(coin.info.total)),
    },
    {
      head: () => <Trans id="digital_metals.table.balance">Balance</Trans>,
      className: 'align-right',
      cell: (coin) => (!coin.info ? '-' : formatToken(coin.info.balance)),
    },
    {
      head: () => <Trans id="digital_metals.table.issuer">Issuer</Trans>,
      cell: (coin) => coin.issuer.displayName,
    },
    {
      head: () => (
        <Trans id="digital_metals.table.position_total_balance">Position total/balance</Trans>
      ),
      className: 'align-right',
      cell: (coin) => {
        if (!coin.info) {
          return '- / -';
        }
        let lastVaultBalance = getLastVaultBalance(coin);
        let positionTotal = lastVaultBalance - coin.info.total;
        let positionBalance = lastVaultBalance - coin.info.balance;
        return (
          <div className="flex-row">
            <div className="mr-xss txt-left">
              <span
                className={classnames({
                  'color-red': positionTotal < 0,
                  'color-green': positionTotal >= 0,
                })}
              >
                {formatToken(positionTotal)}
              </span>
              <span> / </span>
              <span
                className={classnames({
                  'color-red': positionBalance < 0,
                  'color-green': positionBalance >= 0,
                })}
              >
                {formatToken(positionBalance)}
              </span>
            </div>

            <PositionInfoPopUp
              className="ml-auto"
              coin={coin}
              lastVaultBalance={lastVaultBalance}
              positionBalance={positionBalance}
              positionTotal={positionTotal}
            />
          </div>
        );
      },
    },
    {
      head: '',
      cell: (coin) => (!coin.info || coin.info?.active ? null : <BlockedButtonIcon />),
      className: 'align-center',
    },
  ];

  return (
    <PageTable
      className="tm2-coins-table"
      onLoadMore={loadMoreCoins}
      columns={columns}
      pageData={coinsPage}
    />
  );
};
