import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors } from '@config/core';
import { getSteps } from '../../steps';
import { Context } from '../../context';

export const useModel = () => {
  const isCorporate = useSelector(configSelectors.isCorporateTrader);
  const isIndividual = useSelector(configSelectors.isIndividualTrader);

  const steps = getSteps(isCorporate, isIndividual).filter((s) => !s.hideStepNumber);
  const { state } = useContext(Context);

  return {
    current: state.currentIndex,
    steps,
  };
};
