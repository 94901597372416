import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Popover, PopoverProps } from 'antd';

import s from './popup.module.scss';

export const Popup: FC<PopupProps> = (props: PopupProps) => {
  const {
    children,
    className,
    closeOnScroll = true,
    setEmitClose,
    light = false,
    trigger,
    visible,
    ...otherProps
  } = props;
  const [isVisible, setVisible] = useState(undefined);

  useEffect(() => {
    setEmitClose && setEmitClose(() => () => setVisible(false));
  }, [setEmitClose, setVisible]);

  useEffect(() => {
    const hidePopup = () => {
      isVisible && setVisible(false);
    };

    if (isVisible && closeOnScroll) {
      document.addEventListener('scroll', hidePopup, true);
    }

    return () => {
      if (closeOnScroll) {
        document.removeEventListener('scroll', hidePopup, true);
      }
    };
  }, [isVisible]);

  return (
    <Popover
      className={className}
      overlayClassName={classnames(
        s.container,
        props.overlayClassName,
        !light && s.themeDark,
        light && s.themeLight,
        light && 'tm2-light-theme'
      )}
      trigger={trigger || 'click'}
      visible={isVisible}
      onVisibleChange={setVisible}
      {...otherProps}
    >
      {children}
    </Popover>
  );
};

export interface PopupProps extends PopoverProps {
  closeOnScroll?: boolean;
  setEmitClose?: Function;
  light?: boolean;
}
