import { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { useModel } from './model';

const SearchClear: FC<{ className?: string }> = (p) => {
  const m = useModel();

  return (
    <button
      className={classnames(p.className, s.button)}
      disabled={m.disabled}
      onClick={m.onClearClicked}
    >
      <Trans id="search.button.clear_filters">Clear filters</Trans>
    </button>
  );
};

export default SearchClear;
