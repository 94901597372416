import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

export enum Fields {
  investor = 'investor',
  status = 'status',
}

export interface DepositInvestor {
  displayName: string;
  id: number;
}
const getDepositInvestorsQuery = 'invoiceInvestors';
const getDepositInvestorsFields = ['displayName', 'id'];
const getDepositInvestors = makeQuery({
  queryName: getDepositInvestorsQuery,
  queryFields: getDepositInvestorsFields,
  query: (): Promise<Array<DepositInvestor>> => {
    return stompClient.getData(
      getDepositInvestorsQuery,
      sign(getDepositInvestorsQuery, getDepositInvestorsFields)
    );
  },
});

const downloadBankDetailsQuery = 'TM2BankDetailsPDF';
const downloadBankDetailsFields = ['value'];
const downloadBankDetails = makeQuery({
  queryName: downloadBankDetailsQuery,
  queryFields: downloadBankDetailsFields,
  query: (invoiceId: number): Promise<string> => {
    return stompClient
      .getData(
        downloadBankDetailsQuery,
        sign(downloadBankDetailsQuery, downloadBankDetailsFields),
        { ...v.long({ invoiceId }) }
      )
      .then((response) => response.value);
  },
});

const api = {
  getDepositInvestors,
  downloadBankDetails,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:deposits-page-actions');

export default api;
