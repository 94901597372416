import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ButtonTextIconBig } from '../index.strict';
import s from './index.module.scss';

import type { FC } from 'react';

type Compact = 'all' | 'mobile' | 'none';
export const ButtonAdd: FC<{
  className?: string;
  compact?: Compact;
  disabled?: boolean;
  onClick: () => void;
  uat?: string;
}> = (p) => {
  const compact: Compact = p.compact || 'none';

  return (
    <ButtonTextIconBig
      className={classnames(p.className, {
        [s.compactAll]: compact === 'all',
        [s.compactMobile]: compact === 'mobile',
      })}
      icon={<AddIcon className="tm2-icon-stroke" />}
      onClick={p.onClick}
      disabled={p.disabled}
      loading={false}
      data-uat={p.uat || 'add-button'}
    >
      <Trans id="shared.button.add">Add</Trans>
    </ButtonTextIconBig>
  );
};
