import { useContext, useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';

import { handleBackendError } from '@modules/notify';
import { Context } from '../context';
import { Filter } from '../types';
import api, { PeriodBalances } from './api';

export const useModel = () => {
  const { state } = useContext(Context);

  const [filters] = useDebounce<Filter>(state.filters, 300);
  const [balances, setBalances] = useState<PeriodBalances>({} as PeriodBalances);

  useEffect(() => {
    if (!filters) {
      return;
    }
    (async () => {
      try {
        const balancesResponse = await api.getBankAccountPeriodBalances({ filters });
        setBalances(balancesResponse);
      } catch (error) {
        handleBackendError(error);
      }
    })();
  }, [filters]);

  return {
    balances,
  };
};
