import { Trans } from '@lingui/macro';

import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputText, SubmitButton } from '@components/form';

import { Fields } from './api';
import { formId, useModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:create-ums-asset-modal',
});

export type ModalProps = {
  onCreateUMSAsset: (createdAssetCode: string) => void;
};
export const CreateUMSAssetModal: MFC<ModalProps> = (p) => {
  const m = useModel(CreateUMSAssetModal, p.onCreateUMSAsset);

  return (
    <Modal isVisible={p.isVisible} onClose={() => m.closeModal(CreateUMSAssetModal)}>
      <ModalTitle>
        <Trans id="creating_ums_asset.title">Create UMS Asset</Trans>
      </ModalTitle>
      <ModalContent>
        <Form name={formId} initialValues={m.initialValues}>
          <InputText
            name={Fields.umsAssetCode}
            maxLength={30}
            label={<Trans id="creating_ums_asset.asset_code">Asset code</Trans>}
            disabled={m.lockUI}
            required={true}
          />
          <InputText
            name={Fields.decimals}
            label={<Trans id="creating_ums_asset.decimals">Decimals</Trans>}
            disabled={m.lockUI}
            required={true}
          />
          <InputText
            name={Fields.assetFullName}
            maxLength={30}
            label={<Trans id="creating_ums_asset.asset_full_name">Asset full name</Trans>}
            disabled={m.lockUI}
            required={true}
          />
          <InputText
            name={Fields.status}
            label={<Trans id="creating_ums_asset.status">Status</Trans>}
            disabled={true}
            required={true}
          />
          <InputText
            name={Fields.assetSubclass}
            label={<Trans id="creating_ums_asset.asset_subclass">Asset subclass</Trans>}
            disabled={true}
            required={true}
          />
          <InputText
            name={Fields.participantId}
            label={<Trans id="creating_ums_asset.participant_id">Participant ID</Trans>}
            disabled={m.lockUI}
            required={true}
          />
        </Form>
      </ModalContent>
      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.create">Create</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
