import React, { FC, memo, useContext } from 'react';
import { Trans } from '@lingui/macro';

import { ButtonPrimary } from '@components/button';
import { Context } from '../../context';

export const HubspotErrorHandler: FC = memo(() => {
  const { dispatch } = useContext(Context);

  return (
    <div>
      <div className="mb3">
        <Trans id="signup.hubspot_error_handler.text">
          Sorry, something went wrong there. Please try again.
        </Trans>
      </div>
      <ButtonPrimary
        fullWidth={true}
        onClick={() => dispatch({ type: 'ON_TOGGLE_SHOW_HUBSPOT_FAILED', payload: false })}
        uat="try-reload-hubspot-button"
      >
        <Trans id="signup.hubspot_error_handler.button">Try again</Trans>
      </ButtonPrimary>
    </div>
  );
});
