import sign from 'tm2sign.macro';

import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';

const getBankDetailsQuery = 'userBankAccounts';
const getBankDetailsFields = ['id'];
const getBankDetails = makeQuery({
  permissions: { and: [Permission.BANK_ACCOUNTS_VIEW_MY] },
  queryName: getBankDetailsQuery,
  queryFields: getBankDetailsFields,
  query: (): Promise<Array<number>> => {
    return stompClientService.getData<Array<number>>(
      getBankDetailsQuery,
      sign(getBankDetailsQuery, getBankDetailsFields)
    );
  },
});

const api = {
  getBankDetails,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:wallet-balance-context-actions');

export default api;
