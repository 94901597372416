import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';

import s from './index.module.scss';
import { PageContent, PageTitle } from '@components/page';
import { Provider } from '../context';
import { PostRegistrationHeader } from './header';
import { HubspotErrorHandler } from './hubspor-error-handler';
import { useModel } from './model';

const ConnectedPostRegistrationPage: FC = memo(() => {
  const m = useModel();

  return (
    <PageContent className={s.page} uat="post-registration-page">
      <PageTitle
        documentTitle={m.i18n._(defineMessage({ id: 'router.signup', message: 'Sign up' }))}
      />

      {!m.step.hideStepNumber ? <PostRegistrationHeader className="mb3" /> : null}

      <div className={s.content}>
        {m.isShowHubspotFailed ? <HubspotErrorHandler /> : <m.step.component />}
      </div>
    </PageContent>
  );
});

export const PostRegistrationPage = () => (
  <Provider>
    <ConnectedPostRegistrationPage />
  </Provider>
);
