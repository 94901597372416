import { SliceId } from '@helper/redux';

import { Country, Nationality } from '../api';

export const sliceId = SliceId('shared.countries');

export interface State {
  countries: Array<Country>;
  isCountriesLoading: boolean;
  isNationalitiesLoading: boolean;
  nationalities: Array<Nationality>;
}

export const stateInitial: State = {
  countries: [],
  isCountriesLoading: false,
  isNationalitiesLoading: false,
  nationalities: [],
};
