import React from 'react';
import { Trans } from '@lingui/macro';

import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { VerificationUploadForm } from '../fragment/form';
import { VerificationUploadSubmit } from '../fragment/submit';
import { useModel } from './model';

export const UploadVerificationDocsModal: MFC = ({ isVisible }) => {
  const m = useModel(UploadVerificationDocsModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={m.onCloseModal}
      uat="verification-documents-modal"
    >
      <ModalTitle>
        <Trans id="verification_modal.title">Verification</Trans>
      </ModalTitle>

      <ModalContent>
        <VerificationUploadForm disabled={m.lockUI} />
      </ModalContent>

      <ModalFooter>
        <VerificationUploadSubmit
          text={<Trans id="verification_modal.button.submit">Submit</Trans>}
          onFinish={m.onCloseModal}
          onLock={m.onLockUI}
          fullWidth
        />
      </ModalFooter>
    </Modal>
  );
};
