import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import v from '@components/form/validators';
import api from './api';

export const formId = 'mark-deposit-as-paid-form';

export enum Fields {
  amount = 'amount',
  accountDetails = 'accountDetails',
}

interface Form {
  [Fields.amount]: number;
  [Fields.accountDetails]: string;
}

export const formInitial: Form = {
  amount: null,
  accountDetails: null,
};

export const useModel = (deposit, replaceDeposit, partly, modal) => {
  const [lockUI, setLockUI] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModalAction(modal));

  const fieldValidation = partly
    ? {
        [Fields.amount]: [v.required],
        [Fields.accountDetails]: [v.required],
      }
    : {
        [Fields.accountDetails]: [v.required],
      };

  const onFormAction: OnFormAction<Form> = {
    finally: () => setLockUI(false),
    submit: async (form: Form) => {
      setLockUI(true);
      const amount: number = form?.amount;
      const accountDetails: string = form?.accountDetails;
      const receivedDeposit = await api.markDepositAsPaid({
        id: deposit.id,
        amount,
        accountDetails,
      });
      replaceDeposit(receivedDeposit);
      await onClose();
    },
    fieldValidation: fieldValidation,
  };

  return { onClose, lockUI, onFormAction };
};
