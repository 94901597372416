import s from '../index.module.scss';
import type { CSSProperties, MouseEventHandler, Ref, ReactNode } from 'react';

export type RowBoxProps = {
  children: ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  containerRef: Ref<any>;
  onClick: MouseEventHandler;
  onMouseEnter: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  style: CSSProperties;
};

export function RowBox(props: RowBoxProps) {
  return (
    <div
      ref={props.containerRef}
      className={s.rowBox}
      style={props.style}
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      data-uat="table-data-row"
    >
      {props.children}
    </div>
  );
}
