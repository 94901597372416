import { useDispatch } from 'react-redux';
import { useCallbackOne } from 'use-memo-one';
import { defineMessage, Trans } from '@lingui/macro';
import { useI18n } from '@hooks/i18n';
import { PermissionGroup } from '@permissions/core';
import { showNotifyModalAction } from '@modules/modal';
import { CreateOrEdit, permissionGroup as formPermissionGroup } from '../../create-or-edit';
import { PageTemplate } from '../template';
import api, { permissionGroup as apiPermissionGroup } from './api';

import type { CreateFormData } from '../../create-or-edit';

export type { CreateCoinData } from './types';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [formPermissionGroup, apiPermissionGroup],
  marker: 'layout:create-coin-page',
});

export const CreateCoinPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useI18n();
  const documentTitle = i18n._(
    defineMessage({ id: 'router_title.edit_coin', message: 'Edit coin' })
  );

  const titleElement = (
    <span>
      <Trans id="digital_metals.add.header">New digital metal</Trans>
    </span>
  );

  const onSubmit = useCallbackOne((data: CreateFormData) => {
    return api.createCoin(data);
  }, []);

  const onInitialFetchError = useCallbackOne(() => {
    dispatch(
      showNotifyModalAction({
        type: 'fail',
        title: (
          <Trans id={'digital_metals.add.initial_fetch_error_modal.title'}>
            Failed to fetch data
          </Trans>
        ),
        description: (
          <Trans id={'digital_metals.add.initial_fetch_error_modal.description'}>
            Some of the data wasn't fetched. Please try to reload the page.
          </Trans>
        ),
      })
    );
  }, []);

  const contentElement = (
    <CreateOrEdit
      mode={'create'}
      onSubmit={onSubmit}
      onInitialFetchTimeoutError={onInitialFetchError}
    />
  );

  return (
    <PageTemplate
      documentTitle={documentTitle}
      titleElement={titleElement}
      contentElement={contentElement}
      uat="digital-metals-create-coin-page"
    />
  );
};
