import { useContext, useMemo } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { defineMessage, Trans } from '@lingui/macro';
import {
  AddRowButton,
  FormList,
  InputNumber,
  InputText,
  InputTextarea,
  Select,
  SelectItem,
} from '@components/form';
import { Col, Row } from '@components/grid';
import { useI18n } from '@hooks/i18n';
import { FormContext, fields } from '../../form';
import { RemoveButton } from './remove-button';
import s from './index.module.scss';

type RedemptionTabProps = {
  addBtnContainer: HTMLDivElement;
};

// We restrict these on client for now.
const availableUnitIds: readonly number[] = [3]; // ['unit']

export function RedemptionTab(props: RedemptionTabProps) {
  const { i18n } = useI18n();
  const { state } = useContext(FormContext);
  const {
    data: { units },
    lockUI,
  } = state;

  const unitSelectItems = useMemo<SelectItem[]>(() => {
    return units
      .filter((unit) => availableUnitIds.includes(unit.id))
      .map((unit) => ({
        label: unit.label,
        value: unit.id,
      }));
  }, [units]);

  const priceTypeSelectItems = useMemo<SelectItem[]>(
    () => [
      { value: 'FIAT', label: 'USD' },
      {
        value: 'METAL',
        label: i18n._(defineMessage({ id: 'coin_redeem_options.metal', message: 'Metal' })),
      },
    ],
    []
  );

  return (
    <div>
      <FormList name={fields.redemption.redeemOptions}>
        {(listFields, { add, remove }) => (
          <>
            {listFields.map(({ key, name }) => (
              <div key={key}>
                <Row>
                  <Col md={6}>
                    <InputText
                      name={[name, fields.redemption.name]}
                      fieldKey={[key, fields.redemption.name]}
                      label={
                        <Trans id="coin_redeem_options.redeemed_metal_form">
                          Redeemed metal form
                        </Trans>
                      }
                      required
                      disabled={lockUI}
                    />
                  </Col>
                  <Col md={6}>
                    <InputNumber
                      name={[name, fields.redemption.amount]}
                      fieldKey={[key, fields.redemption.amount]}
                      label={
                        <Trans id="coin_redeem_options.exchange_rate_units">
                          Exchange rate (units)
                        </Trans>
                      }
                      required
                      min={0}
                      disabled={lockUI}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <Select
                      name={[name, fields.redemption.unit]}
                      fieldKey={[key, fields.redemption.unit]}
                      label={<Trans id="coin_redeem_options.unit">Unit</Trans>}
                      required
                      data={unitSelectItems}
                      disabled={lockUI}
                    />
                  </Col>
                  <Col md={6}>
                    <InputNumber
                      name={[name, fields.redemption.minimalQuantity]}
                      fieldKey={[key, fields.redemption.minimalQuantity]}
                      label={
                        <Trans id="coin_redeem_options.min_redemption_output">
                          Min redemption output
                        </Trans>
                      }
                      required
                      min={0}
                      disabled={lockUI}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <InputNumber
                      name={[name, fields.redemption.price]}
                      fieldKey={[key, fields.redemption.price]}
                      label={<Trans id="coin_redeem_options.redemption_fee">Redemption fee</Trans>}
                      required
                      min={0}
                      disabled={lockUI}
                    />
                  </Col>
                  <Col md={6}>
                    <Select
                      name={[name, fields.redemption.priceType]}
                      fieldKey={[key, fields.redemption.priceType]}
                      label={<Trans id="coin_redeem_options.unit">Unit</Trans>}
                      required
                      data={priceTypeSelectItems}
                      disabled={lockUI}
                    />
                  </Col>
                </Row>

                <InputTextarea
                  name={[name, fields.redemption.description]}
                  fieldKey={[key, fields.redemption.description]}
                  label={
                    <Trans id="coin_redeem_options.product_description">Product description</Trans>
                  }
                  disabled={lockUI}
                />
                <div className={classnames(s.removeRedeemOptionRow, 'mb5')}>
                  <RemoveButton onClick={() => remove(name)}>
                    <Trans id={'coin_redeem_options.remove_redeem_option'}>
                      Remove redeem option
                    </Trans>
                  </RemoveButton>
                </div>
              </div>
            ))}
            {props.addBtnContainer
              ? createPortal(
                  <AddRowButton
                    text={
                      <Trans id={'coin_redeem_options.add_redeem_option'}>Add redeem option</Trans>
                    }
                    disabled={lockUI}
                    onClick={() => add()}
                  />,
                  props.addBtnContainer
                )
              : null}
          </>
        )}
      </FormList>
    </div>
  );
}
