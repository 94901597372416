import { memo } from 'react';
import { Trans } from '@lingui/macro';

import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { ButtonPrimary } from '@components/button';

import { AccountStatementModal } from './account-statement-modal';
import { permissionGroup as modalPermissionsGroup } from './account-statement-modal';

export const permissionsGroup = modalPermissionsGroup.createSuperGroup({
  marker: 'layout:client-account-statement',
});

type Props = {
  className: string;
};
export const ClientAccountStatement = memo<Props>(({ className }) => {
  const showModal = useCallDispatch(showModalAction);

  return (
    <ButtonPrimary
      className={className}
      onClick={() => showModal({ modal: AccountStatementModal })}
    >
      <Trans id="statements.client_account_statement_report.text">Client account statement</Trans>
    </ButtonPrimary>
  );
});
