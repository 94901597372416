import { ReactNode } from 'react';

import { ReduxAction } from '@models/redux';
import { ConfirmModalProps, ModalActionsTypes as T, ModalInfo, NotifyModalProps } from './model';

class CloseLatestModalAction extends ReduxAction<T.CLOSE_LATEST_MODAL, void> {
  public readonly type: T.CLOSE_LATEST_MODAL = T.CLOSE_LATEST_MODAL;
  public constructor(public payload: void) {
    super(payload);
  }
}

class CloseModalAction extends ReduxAction<T.CLOSE_MODAL, ReactNode> {
  public readonly type: T.CLOSE_MODAL = T.CLOSE_MODAL;
  public constructor(public payload: ReactNode) {
    super(payload);
  } // if modal param is empty - close all modals
}

class ShowConfirmationModalAction extends ReduxAction<
  T.SHOW_CONFIRMATION_MODAL,
  ConfirmModalProps
> {
  public readonly type: T.SHOW_CONFIRMATION_MODAL = T.SHOW_CONFIRMATION_MODAL;
  public constructor(public payload: ConfirmModalProps) {
    super(payload);
  }
}

class ShowModalAction<P = object> extends ReduxAction<T.SHOW_MODAL, ModalInfo<P>> {
  public readonly type: T.SHOW_MODAL = T.SHOW_MODAL;
  public constructor(public payload: ModalInfo<P>) {
    super(payload);
  }
}

class ShowNotifyModalAction extends ReduxAction<T.SHOW_NOTIFICATION_MODAL, NotifyModalProps> {
  public readonly type: T.SHOW_NOTIFICATION_MODAL = T.SHOW_NOTIFICATION_MODAL;
  public constructor(public payload: NotifyModalProps) {
    super(payload);
  }
}

class ShowPreviousHiddenModalAction extends ReduxAction<T.SHOW_PREVIOUS_HIDDEN_MODAL, void> {
  public readonly type: T.SHOW_PREVIOUS_HIDDEN_MODAL = T.SHOW_PREVIOUS_HIDDEN_MODAL;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const modalServiceActions: {
  closeLatestModal: () => CloseLatestModalAction;
  closeModal: (modal?: ReactNode) => CloseModalAction;
  showConfirmationModal: (modalProps: ConfirmModalProps) => ShowConfirmationModalAction;
  showModal: <P = object>(modalInfo: ModalInfo<P>) => ShowModalAction<P>;
  showNotifyModal: (modalProps: NotifyModalProps) => ShowNotifyModalAction;
  showPreviousHiddenModalAction: () => ShowPreviousHiddenModalAction;
} = {
  closeLatestModal: () => new CloseLatestModalAction().get(),
  closeModal: (modal: ReactNode) => new CloseModalAction(modal).get(),
  showConfirmationModal: (modalProps: ConfirmModalProps) =>
    new ShowConfirmationModalAction(modalProps).get(),
  showModal: <P = object>(modalInfo: ModalInfo<P>) => new ShowModalAction<P>(modalInfo).get(),
  showNotifyModal: (modalProps: NotifyModalProps) => new ShowNotifyModalAction(modalProps).get(),
  showPreviousHiddenModalAction: () => new ShowPreviousHiddenModalAction().get(),
};

export type ModalServiceActionsTypes =
  | CloseLatestModalAction
  | CloseModalAction
  | ShowConfirmationModalAction
  | ShowModalAction
  | ShowNotifyModalAction
  | ShowPreviousHiddenModalAction;

export declare namespace ModalServiceActions {
  type CloseLatestModal = CloseLatestModalAction;
  type CloseModal = CloseModalAction;
  type ShowConfirmationModal = ShowConfirmationModalAction;
  type ShowModal<P = object> = ShowModalAction<P>;
  type ShowNotifyModal = ShowNotifyModalAction;
  type ShowPreviousHiddenModal = ShowPreviousHiddenModalAction;
}
