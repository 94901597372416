import { createSelector } from 'reselect';

import { RootState as RS } from '@models/redux';
import { ModalInfo, ModalState } from './model';

const modalState = (r: RS): ModalState => r.shared?.modal;

// NOTE: полезно использовать для кнопок,
//       которые открывают модалки и находятся внутри другой модалки
const isCustomModalOpened: (r: RS) => (modal) => boolean = createSelector(
  [(r: RS) => modals(r)],
  (list) => (_modal) => !!list.find((item) => item.modal === _modal)
);

const isModalOpened = (r: RS): boolean => !!modals(r)?.length;

const modals = (r: RS): Array<ModalInfo> => modalState(r).modals;

export const modalSelectors = {
  isCustomModalOpened,
  isModalOpened,
  modals,
  modalState,
};
