import classnames from 'classnames';
import themeStyle from '@styles/theme/index.module.scss';
import type { ReactNode } from 'react';

export const themeContainerId = 'theme-container';

export const ThemeContainer = ({ children }: { children: ReactNode }) => {
  return (
    <div id={themeContainerId} className={classnames(themeStyle.themeContainer, themeStyle.theme)}>
      {children}
    </div>
  );
};
