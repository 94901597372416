import { all, put, takeEvery, select } from 'redux-saga/effects';
import {
  MarketingTableActionTypes as ActionTypes,
  MarketingTableColumnIds as ColumnIds,
} from './model';
import { Put } from '@helper/redux';
import { handleBackendError } from '@modules/notify';
import api, { MarketingCompaniesItem as CompaniesItem } from '../api';
import { marketingCompaniesSelectors } from './selectors';
import {
  MarketingCompaniesServiceActions,
  marketingCompaniesServiceActions as serviceActions,
} from './service.actions';
import { SortItem, TableData } from '@models/core';

export function* marketingCompaniesEffects() {
  yield all([
    takeEvery(ActionTypes.ON_TABLE_SCROLLED, Put(serviceActions.loadMoreMarketingCompanies)),
    takeEvery(ActionTypes.ON_TABLE_SORTED, Put(serviceActions.loadMoreMarketingCompanies)),
    takeEvery(ActionTypes.LOAD_MORE_MARKETING_COMPANIES, loadMoreMarketingCompanies),
  ]);
}

function* loadMoreMarketingCompanies(
  action: MarketingCompaniesServiceActions.LoadMoreMarketingCompanies
) {
  try {
    const isSortAction: boolean = !!action.payload;
    const pageIndex: number = yield select(marketingCompaniesSelectors.pageIndex);
    const sort: Array<SortItem<ColumnIds>> = yield select(marketingCompaniesSelectors.sort);
    const table: TableData<CompaniesItem> = yield api.getAllMarketingCompanies(pageIndex, sort);
    yield put(
      serviceActions.loadMoreMarketingCompaniesSuccess({
        isHasMore: table.isHasMore,
        isSort: isSortAction,
        data: table.data,
      })
    );
  } catch (error) {
    yield put(serviceActions.loadMoreMarketingCompaniesFail(error as Error));
    handleBackendError(error);
  }
}
