import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';

import { RedemptionStatus } from './api';

export const useRedeemStatuses = () => {
  const { i18n, lang } = useI18n();

  return useMemo(() => {
    return {
      [RedemptionStatus.approved]: i18n._(
        defineMessage({ id: 'redeem_status.approved', message: 'Approved' })
      ),
      [RedemptionStatus.approvingError]: i18n._(
        defineMessage({ id: 'redeem_status.approving_error_v2', message: 'Approval error' })
      ),
      [RedemptionStatus.approvingPending]: i18n._(
        defineMessage({ id: 'redeem_status.approving_pending', message: 'Approving pending' })
      ),
      [RedemptionStatus.burned]: i18n._(
        defineMessage({ id: 'redeem_status.burned', message: 'Burned' })
      ),
      [RedemptionStatus.cancelledError]: i18n._(
        defineMessage({ id: 'redeem_status.cancellation_error', message: 'Cancellation error' })
      ),
      [RedemptionStatus.cancelledPending]: i18n._(
        defineMessage({ id: 'redeem_status.cancellation_pending', message: 'Cancellation pending' })
      ),
      [RedemptionStatus.cancelled]: i18n._(
        defineMessage({ id: 'redeem_status.cancelled', message: 'Cancelled' })
      ),
      [RedemptionStatus.created]: i18n._(
        defineMessage({ id: 'redeem_status.created', message: 'Created' })
      ),
      [RedemptionStatus.creationError]: i18n._(
        defineMessage({ id: 'redeem_status.creation_error', message: 'Creation error' })
      ),
      [RedemptionStatus.creationPending]: i18n._(
        defineMessage({ id: 'redeem_status.creation_pending', message: 'Creation pending' })
      ),
      [RedemptionStatus.denied]: i18n._(
        defineMessage({ id: 'redeem_status.denied', message: 'Denied' })
      ),
      [RedemptionStatus.denyingError]: i18n._(
        defineMessage({ id: 'redeem_status.denying_error', message: 'Denying error' })
      ),
      [RedemptionStatus.denyingPending]: i18n._(
        defineMessage({ id: 'redeem_status.denying_pending', message: 'Denying pending' })
      ),
      [RedemptionStatus.processing]: i18n._(
        defineMessage({ id: 'redeem_status.processing', message: 'Processing' })
      ),
      [RedemptionStatus.processingError]: i18n._(
        defineMessage({ id: 'redeem_status.processing_error', message: 'Processing error' })
      ),
      [RedemptionStatus.processingPending]: i18n._(
        defineMessage({ id: 'redeem_status.processing_pending', message: 'Processing pending' })
      ),
    };
  }, [lang]);
};
