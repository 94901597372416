import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { showModalAction } from '@modules/modal';
import { useI18n } from 'containers/services/commonService';
import { IssuerBalanceModal } from 'containers/pages/issuer-balance-modal';
import { useIssuerBalanceQuery } from 'containers/services/graphqlFacade';
import gqlFields from './fields';

const { issuerBalanceFields } = gqlFields;

export const useIssuerBalanceModel = () => {
  const { i18n } = useI18n();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [canShowBalance, setCanShowBalance] = useState(true);
  const issuerBalanceQuery = useIssuerBalanceQuery(issuerBalanceFields);
  const dispatch = useDispatch();

  const requestBalance = async () => {
    const issuerBalance = await issuerBalanceQuery(undefined, startDate, endDate);
    dispatch(
      showModalAction({
        modal: IssuerBalanceModal,
        props: { issuerBalance, startDate, endDate },
      })
    );
  };

  const handleChangeStart = (date) => {
    setStartDate(date);
    setCanShowBalance(date && endDate && date.getTime() <= endDate.getTime());
  };

  const handleChangeEnd = (date) => {
    setEndDate(date);
    setCanShowBalance(startDate && date && startDate.getTime() <= date.getTime());
  };

  return {
    i18n,
    startDate,
    endDate,
    canShowBalance,
    handleChangeEnd,
    handleChangeStart,
    requestBalance,
  };
};
