import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

const getUMSAssetQuery = 'coinDetailed';
const getUMSAssetFields = ['umsAssetCode'];
const getUMSAsset = makeQuery({
  queryName: getUMSAssetQuery,
  queryFields: getUMSAssetFields,
  query: (coinId: number): Promise<string> => {
    return stompClientService
      .getData(getUMSAssetQuery, sign(getUMSAssetQuery, getUMSAssetFields), {
        ...v.idInput({ coin: { id: coinId } }),
      })
      .then((response) => response.umsAssetCode);
  },
});

const api = {
  getUMSAsset,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:ums-asset-row');

export default api;
