import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import { useIsMountedRef } from '@hooks/dom';
import { BankAccountItem } from '../../../../api.shared';

export const useModel = (bank: BankAccountItem) => {
  const [contentEl, contentRef] = useState<HTMLElement>();
  const [shortEl, shortRef] = useState<HTMLElement>();
  const [fullEl, fullRef] = useState<HTMLElement>();
  const [width, setWidth] = useState<number>();
  const [isOpened, setOpened] = useState<boolean>(false);
  const mounted = useIsMountedRef();
  // tmp dirty hack to remove glitches on initiate due to undefined element refs
  const [styleHidden, setHidden] = useState<CSSProperties>({
    maxHeight: '64px',
    overflow: 'hidden',
  });

  const { ref: componentRef } = useResizeDetector({
    handleHeight: false,
    skipOnMount: true,
    onResize: setWidth,
    refreshMode: 'debounce',
    refreshRate: 300,
  });

  const shortInfo = useMemo(() => {
    const number =
      bank.accountNumber.length <= 4
        ? bank.accountNumber
        : bank.accountNumber.substring(bank.accountNumber.length - 4);
    return `${bank.currency?.code} **** ${number}`;
  }, [bank]);

  useEffect(() => {
    setTimeout(() => mounted.current && setHidden(undefined));
  }, []);

  useEffect(() => {
    if (!contentEl || !fullEl || !shortEl) {
      return;
    }
    if (isOpened) {
      contentEl.style.height = `${shortEl.offsetHeight + fullEl.offsetHeight}px`;
    } else {
      // 22 is tmp dirty hack to remove glitches on first tab open due to tab display none
      contentEl.style.height = `${shortEl.offsetHeight || 22}px`;
    }
  }, [contentEl, fullEl, shortEl, isOpened, width]);

  const onToggleClicked = () => setOpened(!isOpened);

  return {
    componentRef,
    contentRef,
    fullRef,
    isOpened,
    onToggleClicked,
    shortInfo,
    shortRef,
    styleHidden,
  };
};
