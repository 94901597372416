import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { ReactComponent as DownloadIcon } from '@images/icons/download-icon.svg';
import { ButtonIconBordered, ButtonSecondary } from '../index';

export const ButtonDownload: FC<{
  className?: string;
  compact?: 'all' | 'mobile' | 'none';
  disabled?: boolean;
  loading?: boolean;
  onClick: Function;
}> = ({ children, className, compact = 'none', disabled, loading, onClick }) => {
  return compact === 'none' ? (
    <ButtonSecondary
      className={className}
      icon={<DownloadIcon className="tm2-icon-stroke" />}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {children || <Trans id="shared.button.download">Download</Trans>}
    </ButtonSecondary>
  ) : (
    // TODO compact = mobile кейс не учтен
    <ButtonIconBordered
      icon={<DownloadIcon className="tm2-icon-stroke" />}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  );
};
