import Big from 'big.js';
import { ExtractAction } from '@helper/ts';

export type Step =
  | 'currency-setting'
  | 'confirmation'
  | 'completion'
  | 'not-enough-balance'
  | 'exchange-rate-changed'
  | 'unknown-error';

export type Currency = { id: number; code: string };

type CompletionData = {
  amountToBuy: number;
  amountToSell: number;
  currencyToBuy: { id: number; code: string };
  currencyToSell: { id: number; code: string };
  rate: number;
};

type ConfirmationData = {
  amountToBuy: number;
  amountToSell: number;
  currencyToBuy: { id: number; code: string };
  currencyToSell: { id: number; code: string };
  rate: number;
};

type ConfirmationFailReason = 'not-enough-balance' | 'exchange-rate-changed' | 'unknown';

export type State = {
  amountToBuy: number | null;
  amountToSell: number | null;
  completionData: CompletionData | null;
  confirmationData: ConfirmationData | null;
  currencies: Currency[];
  currencyToBuy: Currency | null;
  currencyToSell: Currency | null;
  exchangeRate: string | null;
  step: Step;
};

export const initialState: State = {
  amountToBuy: null,
  amountToSell: null,
  completionData: null,
  confirmationData: null,
  currencies: [],
  currencyToBuy: null,
  currencyToSell: null,
  exchangeRate: null,
  step: 'currency-setting',
};

type ActionMap = {
  ON_AMOUNT_TO_BUY_SELECTED: number | null;
  ON_AMOUNT_TO_SELL_SELECTED: number | null;
  CONFIRMATION_COMPLETED: CompletionData;
  CONFIRMATION_FAILED: { reason: ConfirmationFailReason };
  ON_CURRENCY_SETTING_REQUESTED: never;
  CURRENCIES_FETCHED: Currency[];
  ON_CURRENCY_TO_BUY_SELECTED: Currency;
  ON_CURRENCY_TO_SELL_SELECTED: Currency;
  EXCHANGE_RATE_FETCHED: string | null;
  VALIDATION_COMPLETED: ConfirmationData;
};

export type Action = ExtractAction<ActionMap>;

function getAmountToBuy(exchangeRate: string, amountToSell: number) {
  // We round the computed value down - this way the client
  // will at least get the amount they're expect to buy.
  // At the same time they will spent nothing more than the
  // amount they expect to sell.

  const exchangeRateBig = new Big(exchangeRate);
  const amountToSellBig = new Big(amountToSell);

  return amountToSellBig.times(exchangeRateBig).round(2, Big.roundDown).toNumber();
}

function getAmountToSell(exchangeRate: string, amountToBuy: number) {
  // We round the computed value up - this way the client
  // will at max spent the amount they're expect to sell.
  // At the same time they will at least get what they expect
  // to buy.

  const exchangeRateBig = new Big(exchangeRate);
  const amountToBuyBig = new Big(amountToBuy);

  return amountToBuyBig.div(exchangeRateBig).round(2, Big.roundUp).toNumber();
}

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ON_AMOUNT_TO_BUY_SELECTED': {
      const { exchangeRate } = state;
      const amountToBuy = action.payload;
      const shouldRecomputeAmountToSell =
        typeof exchangeRate === 'string' && typeof amountToBuy === 'number';

      const computedAmountToSell = shouldRecomputeAmountToSell
        ? getAmountToSell(exchangeRate, amountToBuy)
        : null;

      return { ...state, amountToBuy, amountToSell: computedAmountToSell };
    }

    case 'ON_AMOUNT_TO_SELL_SELECTED': {
      const { exchangeRate } = state;
      const amountToSell = action.payload;
      const shouldRecomputeAmountToBuy =
        typeof exchangeRate === 'string' && typeof amountToSell === 'number';

      const computedAmountToBuy = shouldRecomputeAmountToBuy
        ? getAmountToBuy(exchangeRate, amountToSell)
        : null;

      return { ...state, amountToBuy: computedAmountToBuy, amountToSell };
    }

    case 'CURRENCIES_FETCHED':
      return { ...state, currencies: action.payload };

    case 'ON_CURRENCY_TO_BUY_SELECTED': {
      const nextState = { ...state, currencyToBuy: action.payload };
      if (action.payload.id === state.currencyToSell?.id) {
        nextState.currencyToSell = null;
        nextState.exchangeRate = null;
      }
      return nextState;
    }

    case 'ON_CURRENCY_TO_SELL_SELECTED': {
      const nextState = { ...state, currencyToSell: action.payload };
      if (action.payload.id === state.currencyToBuy?.id) {
        nextState.currencyToBuy = null;
        nextState.exchangeRate = null;
      }
      return nextState;
    }

    case 'EXCHANGE_RATE_FETCHED': {
      const { amountToSell, amountToBuy } = state;

      const computedAmountToBuy =
        typeof amountToSell === 'number'
          ? getAmountToBuy(action.payload, amountToSell)
          : amountToBuy;

      const computedAmountToSell =
        typeof amountToBuy === 'number' && typeof amountToSell !== 'number'
          ? getAmountToSell(action.payload, amountToBuy)
          : amountToSell;

      return {
        ...state,
        amountToBuy: computedAmountToBuy,
        amountToSell: computedAmountToSell,
        exchangeRate: action.payload,
      };
    }

    case 'VALIDATION_COMPLETED': {
      return {
        ...state,
        confirmationData: { ...action.payload },
        step: 'confirmation',
      };
    }

    case 'CONFIRMATION_COMPLETED': {
      return {
        ...state,
        completionData: { ...action.payload },
        step: 'completion',
      };
    }

    case 'CONFIRMATION_FAILED': {
      if (action.payload.reason === 'not-enough-balance') {
        return { ...state, step: 'not-enough-balance' };
      }
      if (action.payload.reason === 'exchange-rate-changed') {
        return { ...state, step: 'exchange-rate-changed' };
      }
      return { ...state, step: 'unknown-error' };
    }

    case 'ON_CURRENCY_SETTING_REQUESTED':
      return { ...state, step: 'currency-setting' };

    default:
      return state;
  }
};
