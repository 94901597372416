import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from '@modules/modal/base/title/index.module.scss';
import { closeModalAction, MFC, Modal } from '@modules/modal';
import { ReactComponent as CloseIcon } from '@images/icons/cross-icon.svg';
import { PermissionGroup } from '@permissions/core';
import { DispatchFn } from '@models/redux';
import { PreloaderLine } from '@components/preloader';
import { Title } from '@components/typography/title';
import { NewTabs } from 'components/new-tabs';

import { transferCoinTabSelectors } from './transfer-coin/store/selectors';
import { transferStableTabSelectors } from './transfer-stable/store/selectors';
import { transferCoinTabUserActions } from './transfer-coin/store/user.acions';
import { transferStableTabUserActions } from './transfer-stable/store/user.acions';
import { TransferCoinView, permissionGroup as transferCoinPermissionGroup } from './transfer-coin';
import {
  TransferStableView,
  permissionGroup as transferStablePermissionGroup,
} from './transfer-stable';
import './styles.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [transferCoinPermissionGroup, transferStablePermissionGroup],
  marker: 'layout:transfer-modal',
});

export const MarketTransferModal: MFC = ({ isVisible }) => {
  const dispatch = useDispatch();
  const lockCoin: boolean = useSelector(transferCoinTabSelectors.lockUI);
  const lockStable: boolean = useSelector(transferStableTabSelectors.lockUI);
  const onClose: DispatchFn<void> = () => dispatch(closeModalAction(MarketTransferModal));

  useEffect(() => {
    return () => {
      dispatch(transferCoinTabUserActions.onTabLeft());
      dispatch(transferStableTabUserActions.onTabLeft());
    };
  }, []);

  // Task to hide transfer metals tab content and add some text instead of it
  const transferCoinStub = true;

  return (
    <Modal className="wallet-transfer-modal" onClose={onClose} isVisible={isVisible}>
      <PreloaderLine show={lockCoin || lockStable} />

      <NewTabs
        tabs={[
          {
            title: <Trans id="transfer_modal.tab_title.transfer_funds">Transfer funds</Trans>,
            name: 'transfer-funds',
            content: () => <TransferStableView />,
          },
          {
            title: <Trans id="transfer_modal.tab_title.transfer_metals">Transfer metals</Trans>,
            name: 'transfer-metals',
            content: () => (!transferCoinStub ? <TransferCoinView /> : <TransferCoinStub />),
          },
          {
            title: (
              <div
                className={s.close}
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  onClose();
                }}
              >
                <CloseIcon className={s.closeSvg} />
              </div>
            ),
            name: 'close-modal',
            content: null,
          },
        ]}
      />
    </Modal>
  );
};

const TransferCoinStub = () => (
  <div>
    <Title level={3}>
      <Trans id="transfer_modal.transfer_metals.stub">
        The transfer of metals between markets is disabled for technical reasons. Please check our
        further updates. <br /> <br />
        If you would like to transfer metals between markets, please contact{' '}
        <a href="mailto:support@tm2.com">support@tm2.com</a>.
      </Trans>
    </Title>
  </div>
);
