import { useState } from 'react';

import { closeModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';

export const useModel = (modal) => {
  const [lockUI, onLockUI] = useState<boolean>();
  const closeModal = useCallDispatch(closeModalAction);

  return {
    lockUI,
    onCloseModal: () => closeModal(modal),
    onLockUI,
  };
};
