import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import { useCallDispatch } from '@hooks/core';
import { Link, UserProfileLink } from '@components/link';
import { PermissionGroup } from '@permissions/permission-group';
import { HasAccess } from '@permissions/core';
import { PageTable } from 'components/page-table';
import { formatMoney } from '../../../../services/commonService';
import { actions, selectors } from '../store';
import { ColumnIds } from '../store/model';
import { permissionGroup as apiPermissionGroup, PaymentItem } from './api';
import { PaymentStatusCell } from './status-cell';
import { SettledCell } from './settled-cell';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup],
  marker: 'layout:payments-report-table',
});

const TableComponent: FC = memo(() => {
  const columnSortDirection = useSelector(selectors.columnSortDirection);
  const isHasMore = useSelector(selectors.isHasMore);
  const lockUI = useSelector(selectors.lockUI);
  const payments = useSelector(selectors.payments);

  const onTableScrolled = useCallDispatch(actions.onTableScrolled);
  const onTableSorted = useCallDispatch(actions.onTableSorted);

  const columns = [
    {
      head: {
        body: <Trans id="statements.payments.table.date">Date & time</Trans>,
        onSort: (direction) => onTableSorted({ id: ColumnIds.time, direction: direction }),
        sortDirection: columnSortDirection(ColumnIds.time),
      },
      cell: (p: PaymentItem) => p?.time?.formatLocalDateTime(),
    },
    {
      head: <Trans id="statements.payments.table.invoice_id">Invoice ID</Trans>,
      cell: (p: PaymentItem) => p?.invoiceId,
      className: 'align-right',
    },
    {
      head: <Trans id="statements.payments.table.method">Payment method</Trans>,
      cell: (p: PaymentItem) => p?.method,
    },
    {
      head: <Trans id="statements.payments.table.payment_id">Payment ID</Trans>,
      cell: (p: PaymentItem) => p?.id,
      className: 'align-right',
    },
    {
      head: {
        body: <Trans id="statements.payments.table.status">Status</Trans>,
        onSort: (direction) => onTableSorted({ id: ColumnIds.status, direction: direction }),
        sortDirection: columnSortDirection(ColumnIds.status),
      },
      cell: (p: PaymentItem) => <PaymentStatusCell status={p?.status} />,
    },
    {
      head: {
        body: <Trans id="statements.payments.table.client_id">Client ID</Trans>,
        onSort: (direction) => onTableSorted({ id: ColumnIds.userId, direction: direction }),
        sortDirection: columnSortDirection(ColumnIds.userId),
      },
      cell: (p: PaymentItem) => <UserProfileLink id={p?.userId} text={p?.userId} />,
      className: 'align-right',
    },
    {
      head: <Trans id="statements.payments.table.amount">Amount</Trans>,
      cell: (p: PaymentItem) => formatMoney(p?.amount, null),
      className: 'align-right',
    },
    {
      head: <Trans id="statements.payments.table.currency">Currency</Trans>,
      cell: (p: PaymentItem) => p?.currency,
    },
    {
      head: {
        body: <Trans id="statements.payments.table.settled">Settled</Trans>,
        onSort: (direction) => onTableSorted({ id: ColumnIds.settledStatus, direction: direction }),
        sortDirection: columnSortDirection(ColumnIds.settledStatus),
      },
      cell: (p: PaymentItem) => <SettledCell settled={p.settledStatus} />,
    },
  ];

  return (
    <PageTable
      onLoadMore={() => onTableScrolled()}
      isLoadingInProgress={lockUI}
      isShowLoader={true}
      initialLoad={true}
      columns={columns}
      pageData={{
        data: payments,
        hasMore: isHasMore,
      }}
    />
  );
});

export const Table: FC = () => (
  <HasAccess permissions={{ or: [permissionGroup] }}>
    <TableComponent />
  </HasAccess>
);
