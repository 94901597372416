import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { closeModalAction, MFC } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import api from './api';
import { useHistory } from 'react-router';

export enum Fields {
  dontAskAgain = 'dontAskAgain',
}

interface Form {
  [Fields.dontAskAgain]: boolean;
}

export const formInitial = { [Fields.dontAskAgain]: false };

export const useModel = (modal: MFC) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [lockUI, setLockUI] = useState<boolean>(false);
  const [dontAskAgain, setDontAskAgain] = useState<boolean>();

  const onFormChanged = (form: Form) => setDontAskAgain(form.dontAskAgain);

  const onCloseClicked = async () => {
    setLockUI(true);
    if (dontAskAgain) {
      try {
        await api.emitDontAskTutorial();
      } catch (error) {
        handleBackendError(error);
      }
    }
    setLockUI(false);
    dispatch(closeModalAction(modal));
  };

  const onReadInstructionsClicked = async () => {
    await onCloseClicked();
    history.push('/tutorial');
  };

  return {
    lockUI,
    onCloseClicked,
    onFormChanged,
    onReadInstructionsClicked,
  };
};
