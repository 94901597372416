import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useCallDispatch } from '@hooks/core';
import { closeModalAction, showNotifyModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { Trans } from '@lingui/macro';
import { configSelectors } from '@config/core';
import { useAvailableStableBalances, useCurrencies } from './hooks';
import api, { Fields, Form } from './api';

export const formId = 'currencyExchangeManual';

export { Fields } from './api';
export type { Form } from './api';

export const useModel = () => {
  const userId = useSelector(configSelectors.userId);

  const stableLimit = { min: 0.01 };

  const { availableStableBalances, isAvailableStableBalancesLoading } =
    useAvailableStableBalances();

  const { currencies, isCurrenciesLoading, setSelectedCurrencyToSellId } = useCurrencies();

  const [isManualExchangeLoading, setIsManualExchangeLoading] = useState<boolean>(false);

  const [isCurrencyToSellSelected, setIsCurrencyToSellSelected] = useState<boolean>(false);

  const [maxCurrencyAmount, setMaxCurrencyAmount] = useState<number>(0);

  const onCurrencyToSellSelected = (currencyId) => {
    const maxAmount = availableStableBalances?.find((b) => b.value === currencyId).amount;
    setIsCurrencyToSellSelected(true);
    setSelectedCurrencyToSellId(currencyId);
    setMaxCurrencyAmount(maxAmount);
  };

  const onClose = useCallDispatch(closeModalAction);

  const showNotifyModal = useCallDispatch(showNotifyModalAction);

  const lockUI = isCurrenciesLoading || isManualExchangeLoading || isAvailableStableBalancesLoading;

  const onFormAction: OnFormAction<Form> = {
    error: async () => {
      setIsManualExchangeLoading(false);
    },
    submit: async (form) => {
      setIsManualExchangeLoading(true);
      await api.sendManualExchangeRequest(userId, form);
      setIsManualExchangeLoading(false);
      showNotifyModal({
        isClosePrevious: true,
        type: 'success',
        title: (
          <Trans id="my_wallet.exchange_currency.currency_manual.notify_modal.title">
            Your request has been received
          </Trans>
        ),
        description: (
          <Trans id="my_wallet.exchange_currency.currency_manual.notify_modal.text">
            Your request for conversion has been received. A member of our team will revert back to
            you in 1 to 3 business days. Thank you for your kind patience
          </Trans>
        ),
      });
    },
  };

  return {
    availableStableBalances,
    currencies,
    isCurrencyToSellSelected,
    lockUI,
    maxCurrencyAmount,
    onClose,
    onCurrencyToSellSelected,
    onFormAction,
    stableLimit,
  };
};
