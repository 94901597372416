import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import v from '@components/form/validators';
import api from './api';

export const formId = 'approve-deposit-form';
export enum Fields {
  amount = 'amount',
}
export const formInitial = { [Fields.amount]: null };
interface Form {
  [Fields.amount]: number;
}

export const useModel = (deposit, replaceDeposit, modal) => {
  const [lockUI, setLockUI] = useState(false);

  const dispatch = useDispatch();

  const onClose = () => dispatch(closeModalAction(modal));

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      [Fields.amount]: [v.required, v.numMin(0.01)],
    },
    finally: () => setLockUI(false),
    submit: async (form: Form) => {
      setLockUI(true);
      const amount: number = form?.amount;
      const approvedDeposit = await api.approveDeposit({
        id: deposit.id,
        amount,
      });
      replaceDeposit(approvedDeposit);
      await onClose();
    },
  };

  return { onClose, lockUI, onFormAction };
};
