import { BaseItem, RowCellWrapperProps } from './index';
import { RowCellContainer } from '../../bound-components-containers/default-row-cell-container';

export function RowCellWrapperBase<T extends BaseItem>(props: RowCellWrapperProps<T>) {
  return <>{props.children}</>;
}

export function RowCellWrapper<T extends BaseItem>(props: RowCellWrapperProps<T>) {
  return (
    <RowCellContainer width={props.width}>
      <RowCellWrapperBase {...props} />
    </RowCellContainer>
  );
}
