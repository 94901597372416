import { useEffect, useRef, useState, ReactNode } from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import classnames from 'classnames';
import MediumEditor from 'medium-editor';
import { ButtonTextIconBig } from '@components/button';
import { DeleteRowButton } from '@components/form';
import { TextField } from 'components/form';
import { CirclePreloader } from '@components/preloader';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { useI18n } from '@hooks/i18n';
import { ValidationError } from '@services/stomp/errors';
import { download } from 'containers/services/uploadFileService';
import { UploadedFile } from '@models/core';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { FileUploader } from 'components/new-components/file-uploader';
import { fields } from '../../form';
import { InfoFile } from './context';
import s from './index.module.scss';

export type ProductInfoTabViewProps = {
  mode: 'normal' | 'restricted';
  lockUI: boolean;
  dataInit: boolean;
  infoFiles: InfoFile[];
  productInfo: string;
  PMProductInfo: string;
  onProductInfoChange?: (data: string) => void;
  onPMProductInfoChange?: (data: string) => void;
  onInfoFileUpload?: (file: UploadedFile) => void;
  onInfoFileRemove?: (fileId: number) => void;
  onInfoFileTitleChange?: (fileId: number, title: string) => void;
  validationError?: ValidationError | null;
};

function ErrorBlock(props: { error: ReactNode; className?: string }) {
  return (
    <div className={classnames(s.errorContainer, 'mb4')}>
      {props.error ? (
        <Text level={2} className={classnames(s.error, props.className)}>
          {props.error}
        </Text>
      ) : null}
    </div>
  );
}

export function ProductInfoTabView(props: ProductInfoTabViewProps) {
  const { i18n } = useI18n();
  const PMProductInfoRef = useRef<HTMLDivElement>(null);
  const productInfoRef = useRef<HTMLDivElement>(null);
  const [isUploading, setIsUploading] = useState(false);

  const productInfoError = props.validationError?.formErrors[fields.productInfo.productInfo];

  const PMProductInfoError =
    props.validationError?.formErrors[fields.productInfo.primaryMarketProductInfo];

  const infoFileErrors = Object.entries(props.validationError?.formErrors ?? {})
    .filter(([key, message]) => key.startsWith(fields.productInfo.infoFiles + '['))
    .reduce(
      (acc, [key, message]) => {
        const match = key.match(/^.+\[(\d+)]/);
        if (match !== null) {
          const index = match[1];
          if (key.slice(match[0].length).startsWith('.file')) {
            acc.file[index] = message;
          }
          if (key.slice(match[0].length).startsWith('.title')) {
            acc.title[index] = message;
          }
        }
        return acc;
      },
      { title: {}, file: {} }
    );

  useEffect(() => {
    if (!props.dataInit) return;

    const editorConfig = {
      disableEditing: props.mode === 'restricted',
      placeholder: false,
      toolbar: {
        buttons: [
          'bold',
          'italic',
          'underline',
          'h2',
          'h3',
          'quote',
          'orderedlist',
          'unorderedlist',
        ],
        'data-disable-toolbar': true,
      },
    };
    const productInfoEditor = new MediumEditor(productInfoRef.current, editorConfig);
    const PMProductInfoEditor = new MediumEditor(PMProductInfoRef.current, editorConfig);
    productInfoEditor.setContent(props.productInfo);
    productInfoEditor.subscribe('editableInput', () => {
      props?.onProductInfoChange(productInfoEditor.getContent(0));
    });
    PMProductInfoEditor.setContent(props.PMProductInfo);
    PMProductInfoEditor.subscribe('editableInput', () => {
      props?.onPMProductInfoChange(PMProductInfoEditor.getContent(0));
    });
    return () => {
      productInfoEditor.destroy();
      PMProductInfoEditor.destroy();
    };
  }, [props.dataInit]);

  return (
    <div className={s.content}>
      <Title level={4}>
        <Trans id="product_info.product_info">Product info</Trans>
      </Title>
      <div ref={productInfoRef} className={s.textarea} />
      <ErrorBlock error={productInfoError} />

      <Title level={4}>
        <Trans id="product_info.pr_market_info">Primary market info</Trans>
      </Title>
      <div ref={PMProductInfoRef} className={s.textarea} />
      <ErrorBlock error={PMProductInfoError} />

      {props.mode === 'normal' ? (
        <div className={classnames('mb2', s.uploadButtonContainer)}>
          <FileUploader
            accept="*"
            button={({ onClick }) => (
              <ButtonTextIconBig
                className={'mr2'}
                icon={
                  isUploading ? (
                    <CirclePreloader contrast />
                  ) : (
                    <AddIcon className="tm2-icon-stroke" />
                  )
                }
                onClick={onClick}
              >
                <Trans id="product_info.add_file">Add file</Trans>
              </ButtonTextIconBig>
            )}
            onFileUploaded={props?.onInfoFileUpload}
            onProgress={setIsUploading}
          />
        </div>
      ) : null}

      <div className={s.infoFiles}>
        {props.infoFiles?.map((infoFile, index) => (
          <div key={infoFile.file.id}>
            <div className={s.infoFile}>
              <div>
                <label
                  className={s.infoFileLabel}
                  onClick={() => {
                    download(infoFile.file.id, infoFile.file.name);
                  }}
                >
                  {infoFile.file.name}
                </label>
                <ErrorBlock error={infoFileErrors.file[index]} className={'mt1'} />
              </div>
              <div>
                <TextField
                  label={i18n._(
                    defineMessage({
                      id: 'product_info.info_file.text',
                      message: 'Description',
                    })
                  )}
                  value={infoFile.title}
                  disabled={props.mode === 'restricted'}
                  onChange={(e) => {
                    props.onInfoFileTitleChange(infoFile.file.id, e.target.value);
                  }}
                />
                <ErrorBlock error={infoFileErrors.title[index]} />
              </div>
              {props.mode === 'normal' ? (
                <DeleteRowButton
                  className={classnames('ml1', 'mt1', s.removeButton)}
                  onClick={() => props.onInfoFileRemove(infoFile.file.id)}
                />
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
