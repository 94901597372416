import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import './styles.scss';
import { formatMoney, formatToken } from '../../../../../services/commonService';
import { OfferBought } from '../../metals-api.service';
import { calculatePrice } from '../service';

export const BuySuccessContent: FC<{
  offer: OfferBought;
}> = ({ offer }) => {
  const { priceWithoutCommission, issuerTax, issuerTaxPct, sellCommission, priceWithCommission } =
    calculatePrice(offer.quantity, offer.offerPrice);

  return (
    <div className="buy-success-content">
      <div className="buy-success-content-row mb-md">
        <div className="buy-success-content-row-label">
          <Trans id="buy_metals_success_modal.label.units">Units</Trans>
        </div>

        <div className="buy-success-content-row-description">
          {formatToken(offer.quantity, { pre: offer.offerPrice.offer.coin.asset })}
        </div>
      </div>

      <div className="buy-success-content-row mb-md">
        <div className="buy-success-content-row-label">
          <Trans id="buy_metals_success_modal.label.metal_price">Metal price</Trans>
        </div>

        <div className="buy-success-content-row-description">
          {formatMoney(priceWithoutCommission, { pre: offer.offerPrice.currency.code })}
        </div>
      </div>

      {!issuerTaxPct ? null : (
        <div className="buy-success-content-row mb-md">
          <div className="buy-success-content-row-label">
            <Trans id="buy_metals_success_modal.label.tax">Tax</Trans>
          </div>

          <div className="buy-success-content-row-description">
            {formatMoney(issuerTax, { pre: offer.offerPrice.currency.code })}
          </div>
        </div>
      )}

      <div className="buy-success-content-row mb-md">
        <div className="buy-success-content-row-label">
          <Trans id="buy_metals_success_modal.label.execution_fee">Execution fee</Trans>
        </div>

        <div className="buy-success-content-row-description">
          {formatMoney(sellCommission, { pre: offer.offerPrice.currency.code })}
        </div>
      </div>

      <hr className="mb-md" />

      <div className="buy-success-content-row buy-success-content-row-total">
        <div className="buy-success-content-row-label">
          <Trans id="buy_metals_success_modal.label.total_payment">Total payment</Trans>
        </div>

        <div className="buy-success-content-row-description">
          {formatMoney(priceWithCommission, { pre: offer.offerPrice.currency.code })}
        </div>
      </div>
    </div>
  );
};
