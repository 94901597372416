import React, { useCallback, useMemo, useRef, useState } from 'react';
import { PageContent, PageTitle } from '@components/page';
import { PageTable } from 'components/page-table';
import { useI18n } from 'containers/services/commonService';
import { handleBackendError } from '@modules/notify';
import { Permission, PermissionGroup, PermissionRecord } from '@permissions/core';
import { useColumns, OnSort, defaultSortOrder } from './use-columns';
import { SortOrder, addSortItem, isSortOrderEqual } from './sorting';
import { apiService } from './api';
import { Conversion } from './types';
import { defineMessage } from '@lingui/macro';
import './index.scss';

// TODO: add permissions
export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [new PermissionRecord(Permission.EXCHANGE_VIEW_CONVERSION_REPORT)],
  marker: 'layout:conversion-report',
});

export function ConversionReportPage() {
  const { i18n } = useI18n();
  const [tableData, setTableData] = useState<Conversion[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const sortOrderRef = useRef<SortOrder>(defaultSortOrder);
  const currentPageRef = useRef(-1);

  const loadMore = useCallback((pageNumber: number, sortOrder: SortOrder) => {
    setIsPageLoading(true);

    apiService
      .getConversionPage(pageNumber, sortOrder)
      .then((conversionPage) => {
        // With current table logic (disabling after interaction until the
        // response received) this check is unnecessary. It will be needed,
        // though, if we abandon blocking behavior.
        if (!isSortOrderEqual(sortOrderRef.current, sortOrder)) return;

        // loadMore is guaranteed not to be called unless previous call is
        // resolved. Thus, we do not check if the response is related to the
        // current query in terms of item count.

        pageNumber === 0
          ? setTableData(conversionPage.data)
          : setTableData((data) => data.concat(conversionPage.data));
        setHasMore(conversionPage.hasMore);
        setIsPageLoading(false);
      })
      .catch((err) => {
        setIsPageLoading(false);
        handleBackendError(err);
      });
  }, []);

  const onLoadMore = useCallback(() => {
    const nextPage = currentPageRef.current + 1;
    loadMore(nextPage, sortOrderRef.current);
    currentPageRef.current = nextPage;
  }, [loadMore]);

  const onSort = useCallback<OnSort>(
    (field, direction) => {
      const newSortOrder = addSortItem(sortOrderRef.current, { field, direction });
      loadMore(0, newSortOrder);
      currentPageRef.current = 0;
      sortOrderRef.current = newSortOrder;
    },
    [loadMore]
  );

  const columns = useColumns(tableData, onSort);

  const pageTableData = useMemo(
    () => ({
      data: tableData,
      hasMore,
    }),
    [tableData, hasMore]
  );

  return (
    <PageContent className={'tm2-conversion-report'} uat="conversion-report-page">
      <PageTitle
        title={i18n._(
          defineMessage({ id: 'router.conversion-report', message: 'Conversion Report' })
        )}
        backwardLink={'/statements'}
      />

      <PageTable
        columns={columns}
        isShowLoader
        isLoadingInProgress={isPageLoading}
        onLoadMore={onLoadMore}
        pageData={pageTableData}
        initialLoad
        forceLoadMore
      />
    </PageContent>
  );
}
