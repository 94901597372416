import { Selector } from '@models/redux';
import { UserItem } from '../../users-api.service';
import { AddSMTAState } from './model';

const addSMTAState: Selector<AddSMTAState> = (root) => root.main?.users?.addSMTAModal;

const lockUI: Selector<boolean> = (root) => addSMTAState(root).isAddSMTAccountLoading;

const user: Selector<UserItem> = (root) => addSMTAState(root).user;

export const addSMTASelectors = {
  lockUI,
  user,
};
