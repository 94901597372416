import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { mutation, query, subscription } from 'gql-query-builder';
import lodash from 'lodash';
import { CompatClient } from '@stomp/stompjs';

import { ConnectionError, RawError, ValidationError } from '@services/stomp/errors';
import { stompFailInterceptor } from '@services/stomp/interceptors';
import { useShowPreloaderLine } from '@components/layouts/dark/provider';
import { useTranslateErrors } from '@services/translate';
import { configSelectors } from '@config/core';
import { loggerService as logger } from '@services/logger';
import { session } from '@services/session';
import { sendAsyncRequest, sendSubscribe } from '../asyncService';

export const graphqlRequest = async (
  wsInstance: CompatClient,
  operation: string,
  { fields, signature },
  variables,
  builder
) => {
  try {
    if (session.isShowLogs) {
      console.info(operation, 'request', variables, fields);
    }

    logger.storeRequest('graphQL', operation);
    let graphqlQuery = builder({
      operation,
      fields,
      variables: lodash.pickBy(variables, (v) => v !== undefined && v !== null),
    });

    let response = await sendAsyncRequest(wsInstance, 'GRAPHQL_REQUEST', {
      ...graphqlQuery,
      signature,
    });

    if (session.isShowLogs) {
      console.info(operation, 'response', response);
    }

    if (response?.success) {
      return response.data[operation];
    }

    throw response?.data;
  } catch (error) {
    throw stompFailInterceptor(error);
  }
};

export const graphqlSubscribe = (
  wsInstance: CompatClient,
  listener,
  operation,
  { fields, signature },
  variables
) => {
  if (session.isShowLogs) {
    console.info(operation, 'subscription', variables, fields);
  }
  logger.storeRequest('graphQL', operation);
  let graphqlQuery = subscription({
    operation,
    fields,
    variables: lodash.pickBy(variables, (v) => v !== undefined && v !== null),
  });

  return sendSubscribe(
    wsInstance,
    'GRAPHQL_SUBSCRIBE',
    {
      ...graphqlQuery,
      signature,
    },
    (response) => {
      if (response?.success) {
        if (session.isShowLogs) {
          console.info(operation, 'response', response.data[operation]);
        }
        listener({ data: response.data[operation] });
      } else {
        listener({ error: stompFailInterceptor(response?.data) });
      }
    }
  );
};

export const graphqlQuery = (
  wsInstance: CompatClient,
  operation: string,
  signedFields,
  variables?
) => graphqlRequest(wsInstance, operation, signedFields, variables, query);

export const graphqlMutation = (
  wsInstance: CompatClient,
  operation: string,
  signedFields,
  variables?
) => graphqlRequest(wsInstance, operation, signedFields, variables, mutation);

const useGraphqlRequest = (operation: string, fields, variablesTransformer, builder) => {
  const wsInstance = useSelector(configSelectors.wsInstance);
  const isWsConnected = useSelector(configSelectors.isWsConnected);
  const translateErrors = useTranslateErrors();
  const showPreloader = useShowPreloaderLine();

  return useCallback(
    async (...variables) => {
      if (!isWsConnected) {
        throw new ConnectionError('Socket is not connected');
      }
      try {
        showPreloader(true);
        return await builder(wsInstance, operation, fields, variablesTransformer?.(...variables));
      } catch (e) {
        const error: RawError = e;
        if (!(error instanceof ValidationError) && error.type === 'VALIDATION_ERROR') {
          throw new ValidationError(error, translateErrors(error.data));
        }
        throw error;
      } finally {
        showPreloader(false);
      }
    },
    [isWsConnected, translateErrors, showPreloader]
  ); // eslint-disable-line
};

export const useGraphqlQuery = (operation: string, fields, variablesTransformer) =>
  useGraphqlRequest(operation, fields, variablesTransformer, graphqlQuery);

export const useGraphqlMutation = (operation: string, fields, variablesTransformer) =>
  useGraphqlRequest(operation, fields, variablesTransformer, graphqlMutation);
