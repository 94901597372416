import { useEffect, FC } from 'react';
import { useSelector } from 'react-redux';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { Trans } from '@lingui/macro';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { DTable, DColumnType, DBaseTableProps, OnLoadMore } from '@components/table/autosized';
import { useSyncRef } from '@hooks/core';
import { PermissionGroup } from '@permissions/core';
import { dater } from '@helper/date';
import { configSelectors } from '@config/core';
import { profileOtherPagePermissionsGroup } from '../../../profile/other/permissions';
import { useOfferStatusTrans } from '../trans';
import {
  OfferByAll,
  OfferByAllList,
  OfferByMe,
  OfferByMeList,
  OfferStatus,
  TableData,
} from '../types';
import {
  HeaderCellContainer,
  RowCellContainer,
  VERTICAL_PADDING as CELL_VERTICAL_PADDING,
  LINE_HEIGHT_PX,
} from '../table-components';
import { StatusActions, permissionGroup as statusActionsPermissionGroup } from './status-actions';
import s from '../../index.module.scss';
import { ProfileLinkCell } from '@components/table2/utils';

export type Offer = OfferByAll | OfferByMe;
export type OfferList = OfferByAllList | OfferByMeList;

// Make status actions optional.
const actionColumnPermissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [statusActionsPermissionGroup],
  marker: 'layout:action-column',
  optional: true,
});

export const userLinkPermissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:offer-table-seller-id-link',
});

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [actionColumnPermissionGroup, userLinkPermissionGroup],
  marker: 'layout:offer-table',
});

type GetOffers = (params: {
  assetId?: number;
  pageNumber: number;
  creatorId?: number;
  status?: OfferStatus;
}) => Promise<TableData<Offer>>;

type OfferListSetter = (params: OfferList) => OfferList;

export type OffersProps = {
  data: OfferList;
  getOffers: GetOffers;
  updateData: (params: OfferList | OfferListSetter) => void;
  assetId?: number;
  sellerId?: number;
  showStatusActions?: boolean;
  status?: OfferStatus;
};

type Index = {
  assetId?: number;
  sellerId?: number;
  status?: string;
  nextPage: number;
};

type OnOfferUpdate = (offer: Offer) => void;

const INITIAL_PAGE = 0;

function getListKey(params: { assetId: number; sellerId: number; status: OfferStatus }) {
  return `${params.assetId}_${params.sellerId}_${params.status}`;
}

function getColumns(params: {
  showStatusActions: boolean;
  onOfferUpdate: OnOfferUpdate;
}): DColumnType<Offer>[] {
  const columns: (DColumnType<Offer> | false)[] = [
    params.showStatusActions && {
      dataIndex: null,
      width: 60,
      headerCellComponent: () => null,
      rowCellComponent: (props) => (
        <StatusActions
          className={s.actionsTrigger}
          offer={props.data}
          onOfferCancelled={params.onOfferUpdate}
          onOfferUpdate={params.onOfferUpdate}
        />
      ),
    },
    {
      dataIndex: 'id',
      width: 70,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.position'}>№</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{props.data.id}</span>,
    },
    {
      dataIndex: 'status',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.status'}>Status</Trans>
        </span>
      ),
      rowCellComponent: (props) => {
        const trans = useOfferStatusTrans();
        return <span>{trans[props.data.status]}</span>;
      },
    },
    {
      dataIndex: 'date',
      width: 200,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.date-created'}>Date created</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{dater.toLocalDateTime(props.data.date)}</span>,
    },
    {
      dataIndex: 'creatorName',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.seller-name'}>Seller</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <ProfileLinkCell text={props.data.creatorName} id={props.data.creatorId} />
      ),
    },
    {
      dataIndex: 'creatorId',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.seller-id'}>Seller ID</Trans>
        </span>
      ),
      rowCellComponent: (props) => <span>{props.data.creatorId}</span>,
    },
    {
      dataIndex: 'asset',
      width: 220,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.symbol'}>Symbol</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <span>
          <span>{props.data.asset}</span> <span>{props.data.metal}</span>
        </span>
      ),
    },
    {
      dataIndex: 'activePrices',
      width: 200,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.price'}>Price</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <>
          {props.data.activePrices.map((p) => (
            <div key={`${p.currencyCode}_${p.quantity}_${p.unitLabel}`}>
              {formatMoney(p.quantity, {
                pre: p.currencyCode,
                post: `per ${p.unitLabel}`,
              })}
            </div>
          ))}
        </>
      ),
    },
    {
      dataIndex: 'availableQuantity',
      width: 140,
      headerCellComponent: () => (
        <span>
          <Trans id={'offers-page.offers-table.available-quantity'}>Symbol</Trans>
        </span>
      ),
      rowCellComponent: (props) => (
        <span>
          <span>{formatToken(props.data.availableQuantity)}</span>{' '}
          <span>{props.data.quantityUnitLabel}</span>
        </span>
      ),
    },
  ];
  return columns.filter(Boolean) as DColumnType<Offer>[];
}

export const Offers: FC<OffersProps> = (props) => {
  const userPermissions = useSelector(configSelectors.permissions);
  const listKeyRef = useSyncRef(props.data.key);

  const updateOffer = useCallbackOne(
    (offer: Offer) => {
      props.updateData((dataList) => {
        const modifiedList = dataList.list.map((item) => (item.id === offer.id ? offer : item));
        return { ...dataList, list: modifiedList };
      });
    },
    [props.updateData]
  );

  const baseTableProps = useMemoOne<DBaseTableProps<Offer>>(
    () => ({
      dataSource: props.data.list,
      columns: getColumns({
        onOfferUpdate: updateOffer,
        showStatusActions:
          props.showStatusActions && statusActionsPermissionGroup.resolve(userPermissions),
      }),
    }),
    [props.data.list, props.showStatusActions, updateOffer, userPermissions]
  );

  // TODO: estimateRowHeight
  // Use return value as a default for row height.
  // Use 'dynamic' (false by default) field in config to define if the value
  // can be updated by the content.
  // Update callback, if you need to update based on e.g. screen size.
  // If we set padding for static variations (either on row, or on cell),
  // the layout will break if font size changes. To avoid this we should only
  // set padding in case it's dynamic height. Simple way to implement this
  // behavior would be to set style of {paddingTop: 'none', paddingBottom: 'none}
  // on cell for static height variation. Do not use padding on row.
  const estimateRowHeight = useCallbackOne((data: Offer) => {
    return CELL_VERTICAL_PADDING * 2 + (data.activePrices.length - 1) * LINE_HEIGHT_PX;
  }, []);

  // TODO: use measureOnData row config prop when implemented with offer.id key
  const keyExtractor = useCallbackOne((offer: Offer) => {
    return `${offer.id}_${offer.activePrices.length}`;
  }, []);

  const fetchData = useCallbackOne<OnLoadMore<Offer, Index>>(
    ({ lastIndexData }) => {
      const page = lastIndexData?.nextPage ?? INITIAL_PAGE;
      const queryKey = listKeyRef.current;

      return props
        .getOffers({
          pageNumber: page,
          assetId: props.assetId,
          creatorId: props.sellerId,
          status: props.status,
        })
        .then(({ data: incomingData, hasMore }): Awaited<ReturnType<OnLoadMore<Offer, Index>>> => {
          if (queryKey !== listKeyRef.current) {
            return { indexData: null };
          }
          props.updateData((currentData) => ({
            hasMore,
            key: currentData.key,
            list: [...currentData.list, ...incomingData],
          }));
          return { indexData: { nextPage: page + 1 } };
        });
    },
    [props.assetId, props.sellerId, props.status]
  );

  useEffect(() => {
    const nextKey = getListKey({
      assetId: props.assetId,
      sellerId: props.sellerId,
      status: props.status,
    });
    props.updateData({
      hasMore: true,
      key: nextKey,
      list: [],
    });
  }, [props.assetId, props.sellerId, props.status]);

  return (
    <DTable
      baseTableProps={baseTableProps}
      className={s.verticalFiller}
      estimateRowHeight={estimateRowHeight}
      keyExtractor={keyExtractor}
      hasMore={props.data.hasMore}
      listKey={props.data.key}
      onLoadMore={fetchData}
      headerCellWrapperComponent={HeaderCellContainer}
      rowCellWrapperComponent={RowCellContainer}
    />
  );
};
