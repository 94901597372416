import React, { useMemo, FC } from 'react';
import { PermissionGroup } from '@permissions/core';
import { WalletPageLayout } from '../shared/components/layout';
import {
  BalancePane,
  permissionGroup as balancePanePermissionGroup,
} from '../shared/components/balance-pane';
import { WalletTable } from '../shared/table/component';
import { useWalletPrimaryModel } from './model';
import { WalletActions, permissionGroup as walletActionsPermissionGroup } from './wallet-actions';
import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as redeemPermissionGroup } from './redeem-cell';
import { Trans } from '@lingui/macro';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [
    apiPermissionGroup,
    redeemPermissionGroup,
    walletActionsPermissionGroup,
    balancePanePermissionGroup,
  ],
  marker: 'layout:wallet-full',
});

export const WalletFull: FC = () => {
  const { balancePane, coinTableColumns, coinTableData, loading } = useWalletPrimaryModel();

  const balancePaneSections = useMemo(
    () => [
      {
        title: <Trans id={'wallet.wallet-full.balance-pane.primary-market'}>Primary market</Trans>,
        records: balancePane.primaryStableBalances,
        key: 'primary_market',
      },
      {
        title: (
          <Trans id={'wallet.wallet-full.balance-pane.secondary-market-excl-offers'}>
            Secondary market (excl.placed offers)
          </Trans>
        ),
        records: balancePane.secondaryStableBalances,
        key: 'secondary_market',
      },
      {
        title: <Trans id={'wallet.wallet-full.balance-pane.pending'}>Pending</Trans>,
        records: balancePane.pendingBalances,
        key: 'pending',
      },
    ],
    [
      balancePane.primaryStableBalances,
      balancePane.secondaryStableBalances,
      balancePane.pendingBalances,
    ]
  );

  return (
    <WalletPageLayout
      sideContent={<BalancePane sections={balancePaneSections} slotBottom={<WalletActions />} />}
      mainContent={
        <WalletTable columns={coinTableColumns} data={coinTableData} loading={loading} />
      }
    />
  );
};
