import { SortItem } from '@models/core';
import { SliceId } from '@helper/redux';
import { TableHeaderSortType as Sort } from 'components/page-table';
import { PaymentItem } from '../table/api'; // TODO check correct type

export const sliceId = SliceId('payments_report');

export enum ColumnIds {
  amount = 'amount',
  currency = 'currency',
  id = 'id',
  invoiceId = 'invoiceId',
  method = 'method',
  settledStatus = 'settledStatus',
  status = 'status',
  time = 'time',
  userId = 'userId',
}

const columnSort: Array<SortItem<ColumnIds>> = [
  { direction: Sort.desc, fields: ['time'], id: ColumnIds.time },
  { direction: Sort.notSet, fields: ['status'], id: ColumnIds.status },
  { direction: Sort.notSet, fields: ['settledStatus'], id: ColumnIds.settledStatus },
  { direction: Sort.notSet, fields: ['userId'], id: ColumnIds.userId },
];

export interface State {
  isHasMore: boolean;
  isTableLoading: boolean;
  pageIndex: number;
  tableData: Array<PaymentItem>;
  sort: Array<SortItem<ColumnIds>>;
}

export const stateInitial: State = {
  isHasMore: true,
  isTableLoading: false,
  pageIndex: -1,
  tableData: [],
  sort: columnSort,
};
