import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { Permission } from '@permissions/core';
import { dater } from '@helper/date';
import { Filter, HistorySortOrder } from '../../types';
// ----------------------------------------------------------------------------------------
const bankAccountHistoryXlsxQuery = 'externalBankTransactionsAsXLSX';
const bankAccountHistoryXlsxFields = ['value'];
const bankAccountHistoryXlsx = makeQuery({
  permissions: Permission.EXTERNAL_BANK_ACCOUNT_BALANCES_VIEW_REPORT,
  queryName: bankAccountHistoryXlsxQuery,
  queryFields: bankAccountHistoryXlsxFields,
  query: ({
    ordering,
    filter,
  }: {
    ordering: HistorySortOrder;
    filter: Filter;
  }): Promise<string> => {
    const bankAccountFilter = filter.bankAccountId;
    const period = {
      from: dater.toBackendDate(filter.period[0]),
      to: dater.toBackendDate(filter.period[1]),
    };
    const typedValues = {
      ...v.rangeStringInput({ period }),
      ...v.orderInput({ ordering }),
      ...v.string({ bankAccountFilter }), // TODO перименовать на бекенде на bankAccountId
    };

    return stompClient
      .getData(
        bankAccountHistoryXlsxQuery,
        sign(bankAccountHistoryXlsxQuery, bankAccountHistoryXlsxFields),
        typedValues
      )
      .then((response) => response.value);
  },
});
// ----------------------------------------------------------------------------------------
const api = {
  bankAccountHistoryXlsx,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:download-bank-account-history');

export default api;
