import React, { FC, ReactNode } from 'react';

import { HasAccess, Role as R } from '@permissions/core';
import { Select } from '@components/form';
import { Fields, useModel } from './model';

const accessRoles = [R.complianceOfficer];
export const OptionalComplianceOfficerIssuerSelect: FC<{
  className: string;
  disabled: boolean;
  label: ReactNode;
  name: Fields;
  userRole: R;
}> = (p) => {
  const m = useModel();

  return (
    <HasAccess userRole={p.userRole} roles={accessRoles}>
      <Select
        className={p.className}
        label={p.label}
        name={p.name as string}
        data={m.issuers}
        disabled={p.disabled}
      />
    </HasAccess>
  );
};
