import { FC } from 'react';
import classnames from 'classnames';
import { default as SelectRaw, SelectComponentProps } from '@components/form/antd-form/select';

import shared from '../index.module.scss';
import s from './index.module.scss';

const SearchSelect: FC<SelectComponentProps> = (props) => {
  const { className, ...p } = props;

  return (
    <SelectRaw
      {...p}
      className={className}
      compactClass={shared.compact}
      dropdownClass={s.dropdown}
      instanceClass={s.select}
      itemClass={s.item}
      labelClass={classnames(shared.label, s.label)}
      labelShiftedClass={classnames(shared.labelShifted, s.labelShifted)}
      optionClass={s.option}
      wrapperClass={classnames(shared.wrapper, { [shared.disabled]: p.disabled })}
    />
  );
};
export default SearchSelect;
