import { useState } from 'react';
import { handleBackendError } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import api from './api';

export const useModel = (deposit, replaceDeposit, modal) => {
  const onClose = useCallDispatch(closeModalAction);

  const [lockUI, setLockUI] = useState<boolean>(false);
  const onCancelDeposit = async () => {
    try {
      setLockUI(true);
      const cancelledDeposit = await api.cancelDeposit(+deposit.id);
      replaceDeposit(cancelledDeposit);
      onClose(modal);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return { lockUI, onCancelDeposit, onClose };
};
