import { TransferStableTabUserActionsTypes } from './user.acions';
import { TransferStableTabServiceActionsTypes } from './service.acions';
import { TransferDirection } from '../../model';

// technical limitation of secondary market. It work only with hundredths decimals
export const maxDecimalsSMStable = 2;

export const transferStableFormId = 'transfer-fiat-form';

export enum TransferStableFields {
  asset = 'asset',
  amount = 'amount',
}

export interface TransferStableTabState {
  isStableInfoLoading: boolean;
  isFinishStableTransferLoading: boolean;
  isTabInitiated: boolean;
  isTransferStableInfoLoading: boolean;
  primaryStableBalances: Array<AssetItem>;
  secondaryStableBalances: Array<AssetItem>;
  transferDirection: string;
  selectedAsset: string;
}

export const transferStableTabStateInitial: TransferStableTabState = {
  isStableInfoLoading: false,
  isFinishStableTransferLoading: false,
  isTabInitiated: false,
  isTransferStableInfoLoading: false,
  primaryStableBalances: [],
  secondaryStableBalances: [],
  transferDirection: TransferDirection.fromPrimary,
  selectedAsset: '',
};

export enum TransferStableTabActionsTypes {
  ON_TAB_OPENED = 'TRANSFER_STABLE_TAB.ON_TAB_OPENED',
  ON_TAB_LEFT = 'TRANSFER_STABLE_TAB.ON_TAB_LEFT',
  SET_STABLE_INFO = 'TRANSFER_STABLE_TAB.SET_STABLE_INFO',
  GET_STABLE_INFO = 'TRANSFER_STABLE_TAB.GET_STABLE_INFO',
  GET_STABLE_INFO_FAIL = 'TRANSFER_STABLE_TAB.GET_STABLE_INFO_FAIL',
  GET_STABLE_INFO_SUCCESS = 'TRANSFER_STABLE_TAB.GET_STABLE_INFO_SUCCESS',
  SET_INITIAL_ASSET = 'TRANSFER_STABLE_TAB.SET_INITIAL_ASSET',
  ON_CANCEL_FINISH_STABLE_TRANSFER_CLICKED = 'TRANSFER_STABLE_TAB.ON_CANCEL_FINISH_STABLE_TRANSFER_CLICKED',
  ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED = 'TRANSFER_STABLE_TAB.ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED',
  ON_MAXIMIZE_STABLE_AMOUNT_TRANSFER_CLICKED = 'TRANSFER_STABLE_TAB.ON_MAXIMIZE_STABLE_AMOUNT_TRANSFER_CLICKED',
  ON_SELECTED_VALUE_CHANGED = 'TRANSFER_STABLE_TAB.ON_SELECTED_VALUE_CHANGED',
  FINISH_STABLE_TRANSFER = 'TRANSFER_STABLE_TAB.FINISH_STABLE_TRANSFER',
  FINISH_STABLE_TRANSFER_FAIL = 'TRANSFER_STABLE_TAB.FINISH_STABLE_TRANSFER_FAIL',
  FINISH_STABLE_TRANSFER_SUCCESS = 'TRANSFER_STABLE_TAB.FINISH_STABLE_TRANSFER_SUCCESS',
  UPDATE_FORM_VALUES = 'TRANSFER_STABLE_TAB.UPDATE_FORM_VALUES',
  UPDATE_FORM_VALUES_FAIL = 'TRANSFER_STABLE_TAB.UPDATE_FORM_VALUES_FAIL',
  UPDATE_FORM_VALUES_SUCCESS = 'TRANSFER_STABLE_TAB.UPDATE_FORM_VALUES_SUCCESS',
}

export type TransferStableTabActions =
  | TransferStableTabUserActionsTypes
  | TransferStableTabServiceActionsTypes;

export interface AssetItem {
  balance: number;
  asset: string;
}

export interface SetStableInfo {
  primaryBalances: Array<AssetItem>;
  secondaryBalances: Array<AssetItem>;
  isCoinsInfoLoading?: boolean;
}
