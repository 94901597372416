import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { configSelectors } from '@config/core';
import { session } from '@services/session';

export const StartPage: FC<void> = () => {
  const isAuthorized: boolean = useSelector(configSelectors.isAuthorized);
  const isInvestor: boolean = useSelector(configSelectors.isInvestor);
  const isOnboardingFinished: boolean = useSelector(configSelectors.isOnboardingFinished);
  const isRegistrationFinished: boolean = useSelector(configSelectors.isRegistrationFinished);

  const redirectTo: string =
    session.latestVisitedRoute ||
    (isInvestor && isOnboardingFinished ? '/primary-market' : '/profile');

  if (!isAuthorized) {
    return <Redirect to="/signin" />;
  }

  if (isInvestor && !isRegistrationFinished) {
    return <Redirect to="/signup" />;
  }

  return <Redirect to={redirectTo} />;
};
