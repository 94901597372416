import { useCallback } from 'react';
import { KeyExtractor } from '@components/table2/base';
import { LoadData, useTable } from '@components/table2/simplified';
import { dater } from '@helper/date';
import { CorrectionTransaction } from './add-correction-transaction-modal/api';
import { Filters } from './actions-row/model';
import api, { CTFilters } from './api';

export enum ColumnKey {
  createdAt = 'createdAt',
  sender = 'sender',
  recipient = 'recipient',
  amount = 'amount',
  status = 'status',
}

export const useModel = () => {
  const keyExtractor = useCallback<KeyExtractor<CorrectionTransaction>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallback<LoadData<CorrectionTransaction, CTFilters>>(
    ({ nextPage, filter }) => {
      return api.getCorrectionTransactionsPage({ page: nextPage, filter: filter ?? {} });
    },
    []
  );

  const { tableInstance, setData, setFilter } = useTable<CorrectionTransaction>({
    keyExtractor,
    loadData,
  });

  const onTransactionPlaced = useCallback(
    (transaction: CorrectionTransaction) => {
      setFilter(() => {});
      setData((currentData) => [transaction, ...currentData]);
    },
    [setData]
  ); // eslint-disable-line

  const onFiltersChanged = useCallback(
    (filters: Filters) => {
      setFilter(() => ({
        startDate: filters.date?.[0] ? dater.toLocalDate(filters.date?.[0]) : undefined,
        endDate: filters.date?.[1] ? dater.toLocalDate(filters.date?.[1]) : undefined,
        recipientId: filters.recipientId,
        senderId: filters.senderId,
      }));
    },
    [setFilter]
  );

  return {
    onFiltersChanged,
    onTransactionPlaced,
    tableInstance,
  };
};
