import {
  addFundsModalStateInitial,
  AddFundsModalActionsTypes as FundsTypes,
  AddFundsModalState,
  AddFundsModalActions,
} from './model';

export const addFundsModalReducer = (
  state: AddFundsModalState = addFundsModalStateInitial,
  action: AddFundsModalActions
): AddFundsModalState => {
  switch (action.type) {
    case FundsTypes.GET_BANK_ACCOUNTS: {
      return {
        ...state,
        isGetBankAccountsLoading: true,
      };
    }

    case FundsTypes.GET_BANK_ACCOUNTS_FAIL: {
      return {
        ...state,
        isGetBankAccountsLoading: false,
      };
    }

    case FundsTypes.GET_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        isGetBankAccountsLoading: false,
      };
    }

    case FundsTypes.ON_ADD_FUNDS_TYPE_SELECTED: {
      return {
        ...state,
        addType: action.payload,
      };
    }

    case FundsTypes.ON_MODAL_LEFT: {
      return {
        ...addFundsModalStateInitial,
      };
    }

    default: {
      return state;
    }
  }
};
