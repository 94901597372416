import React, { FC, memo, useState } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { ButtonEdit } from '@components/button';
import { Divider } from '@components/divider';
import { Title } from '@components/typography/title';
import { ChangePasswordForm } from './change-password';

export const SecurityTab: FC<{ className: string }> = memo(({ className }) => {
  const [isShowPasswordForm, togglePasswordForm] = useState<boolean>(false);

  return (
    <div className={classnames(s.tab, className)}>
      {!isShowPasswordForm ? (
        <div className={s.row}>
          <Title level={3} compact>
            <Trans id="profile.security.password">Password</Trans>
          </Title>

          <ButtonEdit
            className="ml-auto"
            onClick={() => togglePasswordForm(true)}
            compact="mobile"
          />
        </div>
      ) : (
        <ChangePasswordForm onClose={() => togglePasswordForm(false)} />
      )}

      {/*<Divider className="mt3 mb3" /> TODO logic not implemented yet

            <div className={s.row}>
                <Title level={3} compact>
                    <Trans id="profile.security.google_auth">Google Authentication</Trans>
                </Title>

                <div className="ml-auto">TODO btn</div>
            </div>*/}
    </div>
  );
});
