import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { Role as R } from '@permissions/core';

export const useRoleLabels = () => {
  const { i18n, lang } = useI18n();

  const roles = useMemo(
    () => ({
      [R.accountManager]: i18n._(
        defineMessage({ id: 'role.account_manager', message: 'Account manager' })
      ),
      [R.admin]: i18n._(defineMessage({ id: 'role.administrator', message: 'Administrator' })),
      [R.auditor]: i18n._(defineMessage({ id: 'role.auditor', message: 'Auditor' })),
      [R.clearanceBroker]: i18n._(
        defineMessage({ id: 'role.clearance_broker', message: 'Clearance broker' })
      ),
      [R.complianceOfficer]: i18n._(
        defineMessage({ id: 'role.compliance_officer', message: 'Compliance officer' })
      ),
      [R.custodyManager]: i18n._(
        defineMessage({ id: 'role.custody_manager', message: 'Custody manager' })
      ),
      [R.firstLineSupport]: i18n._(
        defineMessage({ id: 'role.first_line_support', message: '1st line support' })
      ),
      [R.institutionalMember]: i18n._(
        defineMessage({ id: 'role.institutional_member', message: 'Institutional member' })
      ),
      [R.investor]: i18n._(defineMessage({ id: 'role.investor', message: 'Investor' })),
      [R.issuer]: i18n._(defineMessage({ id: 'role.issuer', message: 'Issuer' })),
      [R.listingBroker]: i18n._(
        defineMessage({ id: 'role.listing_broker', message: 'Listing broker' })
      ),
      [R.liquidityProvider]: i18n._(
        defineMessage({ id: 'role.liquidity_provider', message: 'Liquidity provider' })
      ),
      [R.opsClerk]: i18n._(defineMessage({ id: 'role.ops_clerk', message: 'Ops clerk' })),
      [R.opsHead]: i18n._(defineMessage({ id: 'role.ops_head', message: 'Ops head' })),
      [R.vaultManager]: i18n._(
        defineMessage({ id: 'role.vault_manager', message: 'Vault manager' })
      ),
      [R.executionBroker]: i18n._(
        defineMessage({ id: 'role.execution_broker', message: 'Execution broker' })
      ),
      [R.marketing]: i18n._(defineMessage({ id: 'role.marketing', message: 'Marketing' })),
    }),
    [lang]
  );

  return roles;
};
