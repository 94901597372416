import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import shared from '../index.module.scss';
import s from './index.module.scss';

const SearchBox: FC<{
  children: ReactNode;
  className?: string;
}> = ({ className, children }) => (
  <div className={classnames(shared.box, s.box, className)}>{children}</div>
);

export default SearchBox;
