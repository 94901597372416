export const systemLiveBannerLayoutEN: string = `
    <span class="banner-layout">
        <span class="banner-layout-line-1 mt-auto ml-auto">WE ARE</span>
        <span class="banner-layout-line-2 ml-auto">LAUNCHING</span>
        <span class="banner-layout-line-3 mb-auto">
            <span class="banner-layout-line-3-border mr-xs"> </span>
            <span class="banner-layout-line-3-text">SOON</span>
        </span>
    </span>
`;

export const systemLiveBannerLayoutCN: string = `
    <span class="banner-layout">
        <span class="banner-layout-line-1 mt-auto ml-auto">我们是</span>
        <span class="banner-layout-line-2 ml-auto">启动</span>
        <span class="banner-layout-line-3 mb-auto">
            <span class="banner-layout-line-3-border mr-xs"> </span>
            <span class="banner-layout-line-3-text">很快</span>
        </span>
    </span>
`;
