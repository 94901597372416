import { useCallback } from 'react';
import classnames from 'classnames';
import { ReactComponent as ArrowUp } from './icons/sort-arrow-up.svg';
import { ReactComponent as ArrowDown } from './icons/sort-arrow-down.svg';
import s from '../index.module.scss';

import type { ReactNode } from 'react';
import type { HeadCellRendererProps } from '../types';

export type HeadCellSorterProps = Pick<
  HeadCellRendererProps,
  'availableSortDirections' | 'currentSortDirection' | 'onSortClick' | 'sortOrderNumber'
> & {
  children: ReactNode;
};

export function HeadCellSorter(props: HeadCellSorterProps) {
  const ableToSortAsc = props.availableSortDirections.includes('ascend');
  const ableToSortDesc = props.availableSortDirections.includes('descend');
  const ascSelected = props.currentSortDirection === 'ascend';
  const descSelected = props.currentSortDirection === 'descend';

  const onSort = useCallback(() => {
    if (!ableToSortAsc && !ableToSortDesc) return;
    props.onSortClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ableToSortAsc, ableToSortDesc, props.onSortClick]);

  const buttonVisible = ableToSortDesc || ableToSortAsc;

  return (
    <button
      className={classnames(s.headCellSorter, { [s.headCellSorterActive]: buttonVisible })}
      onClick={onSort}
    >
      {props.children}{' '}
      <div
        className={s.headCellArrowContainer}
        style={{ display: buttonVisible ? undefined : 'none' }}
      >
        <ArrowUp
          className={classnames(
            s.headCellSorterArrow,
            { [s.headCellSorterArrowHidden]: !ableToSortAsc },
            { [s.headCellSorterArrowSelected]: ascSelected }
          )}
        />
        <ArrowDown
          className={classnames(
            s.headCellSorterArrow,
            { [s.headCellSorterArrowHidden]: !ableToSortDesc },
            { [s.headCellSorterArrowSelected]: descSelected }
          )}
        />
      </div>
      <span className={s.headCellSorterNumber}>{props.sortOrderNumber}</span>
    </button>
  );
}
