import { useRedeemCoins, useStatuses } from './hooks';

export { Fields } from './api';

export const formId = 'redemptions';

export const useModel = () => {
  const { redeemStatuses } = useStatuses();
  const { redeemCoins, isRedeemCoinsLoading: lockUI } = useRedeemCoins();

  return {
    lockUI,
    redeemCoins,
    redeemStatuses,
  };
};
