import { createContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useAttachedState, useSyncRef } from '../utils';
import { BaseItem } from '../base-table';

export type OnLoadMore<T extends BaseItem, K> = (params: {
  lastIndexData: K | undefined;
}) => Promise<{ indexData: K }>;

export type LoadCheck = () => Promise<boolean>;

export type NotifyUpdate = () => void;

type ContextValue<T extends BaseItem, K> = Readonly<{
  checkAndMaybeLoad: () => void | null;
  columnWidthList: number[];
  configLoadCheck: (check: LoadCheck) => void;
  error: boolean;
  hasMore: boolean;
  loading: boolean;
  notifyUpdate: NotifyUpdate | null;
  tableHeight: number;
  tableWidth?: number;
}>;

export const Context = createContext<ContextValue<any, any>>({
  checkAndMaybeLoad: null,
  columnWidthList: [],
  configLoadCheck: (check: LoadCheck) => {},
  error: false,
  hasMore: false,
  loading: false,
  notifyUpdate: null,
  tableHeight: 0,
});

export type ProviderProps<T extends BaseItem, K> = {
  children: JSX.Element;
  columnWidthList: number[];
  hasMore: boolean;
  listKey?: string | number;
  onLoadMore: OnLoadMore<T, K>;
  tableHeight: number;
  tableWidth?: number;
};

export function Provider<T extends BaseItem, K>({
  children,
  hasMore,
  onLoadMore: onLoadMoreProp,
  tableHeight,
  tableWidth,
  columnWidthList,
}: ProviderProps<T, K>) {
  const [error, setError] = useAttachedState(false);
  const errorRef = useSyncRef(error);

  const [loading, setLoading] = useAttachedState(false);
  const loadingRef = useSyncRef(loading);

  const [visibleLoading, setVisibleLoading] = useAttachedState(loading);

  const indexDataRef = useRef<K | undefined>();
  const hasMoreRef = useSyncRef(hasMore);

  const loadCheckRef = useRef<LoadCheck>(() => Promise.resolve(false));
  const configLoadCheck = useCallbackOne((check: LoadCheck) => {
    loadCheckRef.current = check;
  }, []);

  const isInitialUpdate = useRef({ hasMore: true });
  const loadMoreScheduled = useRef(false);

  const checkAndMaybeLoad = useCallbackOne(async () => {
    const checkPassed = await loadCheckRef.current();

    if (!checkPassed || errorRef.current || !hasMoreRef.current) {
      // If setLoading(true), setLoading(false) will be batched so that it'll
      // not call the effect, we need to reset visibleLoading here.
      setVisibleLoading(false);
      return;
    }

    // Only start visible loading after the check has passed.
    setVisibleLoading(true);

    const { indexData } = await onLoadMoreProp({ lastIndexData: indexDataRef.current });
    indexDataRef.current = indexData;
  }, [onLoadMoreProp]);

  const tryLoadMore = useCallbackOne(() => {
    // If this is a scheduled call and loading === false we need to
    // unset visibleLoading.
    if (loadingRef.current || errorRef.current || !hasMoreRef.current) {
      setVisibleLoading(false);
      return;
    }

    setLoading(true);
    loadingRef.current = true;

    checkAndMaybeLoad()
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setLoading(false);
      });
  }, [checkAndMaybeLoad]);

  const tryLoadMoreRef = useSyncRef(tryLoadMore);

  const notifyUpdate = useCallbackOne<NotifyUpdate>(() => {
    if (loadingRef.current) {
      loadMoreScheduled.current = true;
      return;
    }
    tryLoadMoreRef.current();
  }, []);

  const contextValue: ContextValue<T, K> = useMemoOne(
    () => ({
      checkAndMaybeLoad: tryLoadMore,
      columnWidthList,
      configLoadCheck,
      error,
      hasMore,
      loading: visibleLoading,
      notifyUpdate,
      tableHeight,
      tableWidth,
    }),
    [
      columnWidthList,
      error,
      hasMore,
      notifyUpdate,
      tableHeight,
      tableWidth,
      tryLoadMore,
      visibleLoading,
    ]
  );

  useEffect(() => {
    // We want to ignore first time load, since it's delegated via notifyUpdate.
    if (isInitialUpdate.current.hasMore) {
      isInitialUpdate.current.hasMore = false;
      return;
    }
    if (!hasMore) return;

    tryLoadMoreRef.current();
  }, [hasMore]);

  // During the load promise resolving the data coming from the promise
  // could be rendered before the resolve. In this case we need to initiate
  // another load to get a check with the latest state of the ui.
  useLayoutEffect(() => {
    if (loading) return;
    if (!loadMoreScheduled.current) {
      setVisibleLoading(false);
      return;
    }
    loadMoreScheduled.current = false;
    tryLoadMoreRef.current();
  }, [loading]);

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
}
