import { useCallback, useState } from 'react';
import * as jsonexport from 'jsonexport/dist';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import api from './api';

export { permissionGroup } from './api';

const REPORT_FILENAME = 'secondary_last_trades';

function downloadCsv(filename: string, data: object) {
  jsonexport(data, (err, csv) => {
    if (err) {
      console.error('secondary-trades-report: failed to create .csv from provided data');
      return;
    }
    const reportLink = document.createElement('a');
    reportLink.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(csv));
    reportLink.setAttribute('download', filename + '.csv');
    document.body.appendChild(reportLink);
    reportLink.click();
    document.body.removeChild(reportLink);
  });
}

export const SecondaryTradesReport = () => {
  const [loading, setLoading] = useState(false);
  const downloadReport = useCallback(() => {
    setLoading(true);
    api
      .getSecondaryTradesList()
      .then((data) => {
        downloadCsv(REPORT_FILENAME, data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <ButtonPrimary
      loading={loading}
      onClick={downloadReport}
      uat="statements-secondary-trades-report"
    >
      <Trans id={'statements.secondary_traders_report.get_report'}>Get report</Trans>
    </ButtonPrimary>
  );
};
