import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configSelectors } from '@config/core';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { denyBidOrder } from '../../legacy-service';
import { DenyBidOrderModal } from './index';
import { BidOrderStatus } from 'containers/pages/offers2/shared/types';

export const useDenyBidOrderModalModel = (bidOrderId, onBidOrderDenied) => {
  const dispatch = useDispatch();
  const [inProcess, setInProcess] = useState(false);
  const [reason, setReason] = useState('');
  const wsInstance = useSelector(configSelectors.wsInstance);
  const isWsConnected = useSelector(configSelectors.isWsConnected);

  const closeModal = () => dispatch(closeModalAction(DenyBidOrderModal));

  const denyOrder = async () => {
    try {
      if (!isWsConnected) {
        return;
      }
      setInProcess(true);
      const deniedOrder = await denyBidOrder(wsInstance, bidOrderId, reason);
      onBidOrderDenied({ bidOrderId: deniedOrder.id, status: deniedOrder.status });
      setInProcess(false);
      closeModal();
    } catch (error) {
      handleBackendError(error);
      setInProcess(false);
    }
  };

  return { closeModal, reason, setReason, inProcess, denyOrder };
};
