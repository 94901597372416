import React from 'react';
import { Trans } from '@lingui/macro';
import { Modal, MFC, ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { RadioBox, RadioBoxItem } from '@components/radio-box';
import { Permission, PermissionGroup, PermissionRecord } from '@permissions/core';
import { ReactComponent as VisaIcon } from '@images/icons/visa-icon.svg';
import { ReactComponent as MasterCardIcon } from '@images/icons/mastercard-icon.svg';
import { Text } from '@components/typography/text';
import { ButtonSecondary } from '@components/button';
import { WireTransferView } from './wire-transfer';
import { CreditCardView } from './credit-card';
import { permissionGroup as apiPermissionGroup } from './api';
import { AddFundsType } from './store/model';
import { AddFundsSubmitButton } from './submit-button';
import { useModel } from './model';
import './styles.scss';

// TODO: add permissions
export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    new PermissionRecord(Permission.INVOICE_CREATE_MY),
    new PermissionRecord(Permission.INVOICE_CREATE_EXT),
    apiPermissionGroup,
  ],
  marker: 'todo:layout-add-funds',
});

export const AddFundsModal: MFC = ({ isVisible }) => {
  const m = useModel();

  return (
    <Modal
      className="add-funds-modal"
      isVisible={isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      onClose={m.onCloseModalClicked}
    >
      <ModalTitle>
        <Trans id="add_funds_modal.title">Add funds</Trans>
      </ModalTitle>

      <ModalContent>
        <RadioBox
          className="mb-md"
          defaultActiveKey={m.addType}
          deprecatedStyles={false}
          onChange={(type) => m.onAddFundsTypeSelected(type as AddFundsType)}
        >
          {/*<RadioBoxItem header={(*/}
          {/*    <div className="add-funds-modal-radio-credit-card-header">*/}
          {/*        <div className="add-funds-modal-radio-credit-card-header-text mr-auto">*/}
          {/*            <Trans id="add_funds_modal.accordion.credit_debit_card">Credit/Debit card</Trans>*/}
          {/*        </div>*/}
          {/*        <div className="add-funds-modal-radio-credit-card-header-icon mr-xs">*/}
          {/*            <VisaIcon />*/}
          {/*        </div>*/}
          {/*        <div className="add-funds-modal-radio-credit-card-header-icon">*/}
          {/*            <MasterCardIcon />*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*)}*/}
          {/*              hidden={!m.isInvestor}*/}
          {/*              key={AddFundsType.creditCard}>*/}
          {/*    <CreditCardView />*/}
          {/*</RadioBoxItem>*/}

          <RadioBoxItem
            header={<Trans id="add_funds_modal.accordion.wire_transfer">Wire transfer</Trans>}
            key={AddFundsType.wireTransfer}
          >
            {m.isWireTransferAllowed ? (
              <WireTransferView />
            ) : (
              <div>
                <Text level={1} className="mb1">
                  <Trans id="add_funds_modal.accordion.wire_transfer.no_bank_details">
                    To ensure best possible processing of deposit, Tm2 asks customers to inform
                    about deposit payments to Tm2 account. This can be done by creating a deposit
                    request specifying the *currency* and *amount*. This deposit request will be
                    valid for 7 days.
                    <br />
                    After creating deposit request you will get a Tm2 bank details with the Payment
                    reference, that will help us to identify your payment.
                    <br />
                    <br />
                    Failure to request a deposit, create with the wrong currency or amount, or using
                    not valid Deposit request may result in delayed deposits to your TM2 account,
                    charge additional fees or even rejection of incoming funds. Please allow 2-3
                    business days for incoming funds to appear in your TM2 account.
                  </Trans>
                </Text>
                <ButtonSecondary fullWidth={true} onClick={m.onAddBankDetails}>
                  <Trans id="add_funds_modal.accordion.wire_transfer.add_bank_details">
                    Add bank details
                  </Trans>
                </ButtonSecondary>
              </div>
            )}
          </RadioBoxItem>
        </RadioBox>
      </ModalContent>

      <ModalFooter>
        <AddFundsSubmitButton />
      </ModalFooter>
    </Modal>
  );
};
