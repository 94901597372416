import { useContext, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { Col, Row } from '@components/grid';
import { InputNumber, Select, SelectItem } from '@components/form';
import { Title } from '@components/typography/title';
import { fields, FormContext } from '../../form';

export function FeesTab() {
  const { state } = useContext(FormContext);
  const {
    data: { listingBrokers },
    lockUI,
  } = state;

  const listingBrokersSelectItems = useMemo<SelectItem[]>(() => {
    return listingBrokers.map((provider) => ({
      label: provider.displayName,
      value: provider.id,
    }));
  }, [listingBrokers]);

  return (
    <div>
      <Row>
        <Col md={6}>
          <InputNumber
            name={fields.fees.sellCommission}
            label={<Trans id="digital_metals.general.sell_commission_v2">Selling commission</Trans>}
            disabled={lockUI}
            required
            min={0}
            max={100}
            maxDecimals={3}
          />
        </Col>
        <Col md={6}>
          <InputNumber
            name={fields.fees.transactionCommission}
            label={
              <Trans id="digital_metals.general.transaction_commission">
                Transaction commission
              </Trans>
            }
            disabled={lockUI}
            required
            min={0}
            max={100}
            maxDecimals={3}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <InputNumber
            name={fields.fees.managementFee}
            label={
              <Trans id="digital_metals.general.management_fee_per_annum">
                Management Fee, % per annum
              </Trans>
            }
            disabled={lockUI}
            required
            min={0}
            max={100}
            maxDecimals={3}
          />
        </Col>
      </Row>

      <Title level={2}>
        <Trans id="digital_metals.fees.listing_broker">Listing broker</Trans>
      </Title>

      <Row>
        <Col md={6}>
          <Select
            name={fields.fees.listingBroker}
            label={<Trans id="digital_metals.fees.listing_broker">Listing broker</Trans>}
            disabled={lockUI}
            data={listingBrokersSelectItems}
          />
        </Col>
        <Col md={6}>
          <InputNumber
            name={fields.fees.listingBrokerFee}
            label={<Trans id="digital_metals.fees.fee_percent">Fee, %</Trans>}
            disabled={lockUI}
            min={0}
            max={100}
            maxDecimals={3}
          />
        </Col>
      </Row>
    </div>
  );
}
