import React, { FC, memo } from 'react';

import { HasAccess } from '@permissions/core';
import { ButtonEdit } from '@components/button';
import { permissionsGroup as handleUserModalPermissionsGroup } from '../../../../modals/handle-user-modal';
import { useModel } from './model';

export const permissionsGroup = handleUserModalPermissionsGroup.createSuperGroup({
  marker: 'layout:edit-profile-button',
});

interface EditProfileButtonProps {
  refreshProfile: () => void;
}
export const EditProfileButton: FC<EditProfileButtonProps> = memo<EditProfileButtonProps>((p) => {
  const m = useModel(p.refreshProfile);

  return (
    <HasAccess roles={m.editPermissionsRoles}>
      <ButtonEdit
        onClick={m.showEditModal}
        disabled={m.isEditOpened}
        compact="mobile"
        uat="edit-profile-button"
      />
    </HasAccess>
  );
});
