import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { Box } from '@components/box';
import { Form, Select } from '@components/form';

import { permissionGroup as apiPermissionGroup } from './api';
import { formId, useModel, Fields } from './model';
import s from './index.module.scss';

import type { Filters } from '../api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:redemptions-actions',
});

type Props = {
  onFiltersChanged: (filters: Filters) => void;
};
export const ActionsRow: FC<Props> = (p) => {
  const m = useModel();

  return (
    <Box className={classnames('mb2', s.box)}>
      <Form name={formId} className={s.form} onChange={p.onFiltersChanged}>
        <Select
          name={Fields.coinId}
          data={m.redeemCoins}
          label={<Trans id="redeem_requests_filter.symbol">Symbol</Trans>}
          disabled={m.lockUI}
          compact={true}
          allowEmpty={true}
          className={s.filter}
        />

        <Select
          name={Fields.status}
          data={m.redeemStatuses}
          label={<Trans id="redeem_requests_filter.status">Status</Trans>}
          disabled={m.lockUI}
          compact={true}
          allowEmpty={true}
          className={s.filter}
        />
      </Form>
    </Box>
  );
};
