import classnames from 'classnames';
import {
  DefaultTabHeader,
  TabHeaderProps as OriginalTabHeaderProps,
} from '@components/tabs/regular';
import { TabHeaderData } from './types';
import s from './index.module.scss';

type TabHeaderProps = OriginalTabHeaderProps<TabHeaderData>;

export function TabHeader(props: TabHeaderProps) {
  const { data, ...other } = props;
  const className = classnames(s.tabHeader, {
    [s.tabHeaderWithNotification]: data.showNotification,
  });
  return <DefaultTabHeader className={className} data={data.name} {...other} />;
}
