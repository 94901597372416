import { useCallback, useContext } from 'react';
import { UploadedFile } from '@models/core';
import { FormContext } from '../../form';
import { ProductInfoContext } from './context';
import { ProductInfoTabView } from './product-info-view';

export function ProductInfoTab() {
  const {
    state: { lockUI },
  } = useContext(FormContext);
  const { state, dispatch } = useContext(ProductInfoContext);

  const onProductInfoChange = useCallback((data: string) => {
    dispatch({
      type: 'PRODUCT_INFO_CHANGED',
      payload: data,
    });
  }, []);

  const onPrimaryMarketProductInfoChange = useCallback((data: string) => {
    dispatch({
      type: 'PRIMARY_MARKET_PRODUCT_INFO_CHANGED',
      payload: data,
    });
  }, []);

  const onInfoFileUpload = useCallback((file: UploadedFile) => {
    dispatch({
      type: 'FILE_UPLOADED',
      payload: file,
    });
  }, []);

  const onInfoFileRemove = useCallback((fileId: number) => {
    dispatch({
      type: 'FILE_REMOVED',
      payload: { fileId },
    });
  }, []);

  const onInfoFileTitleChange = useCallback((fileId: number, title: string) => {
    dispatch({
      type: 'FILE_TITLE_CHANGED',
      payload: { fileId, title },
    });
  }, []);

  return (
    <ProductInfoTabView
      mode={'normal'}
      lockUI={lockUI}
      dataInit={state.formInitiated}
      infoFiles={state.infoFiles}
      productInfo={state.productInfo}
      PMProductInfo={state.primaryMarketProductInfo}
      onProductInfoChange={onProductInfoChange}
      onPMProductInfoChange={onPrimaryMarketProductInfoChange}
      onInfoFileUpload={onInfoFileUpload}
      onInfoFileRemove={onInfoFileRemove}
      onInfoFileTitleChange={onInfoFileTitleChange}
      validationError={state.validationError}
    />
  );
}
