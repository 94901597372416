import React, { FC, memo, ReactElement } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Box } from '@components/box';
import { useRoleLabels } from '@hooks/roles';
import { Title } from '@components/typography/title';
import { ReactComponent as Avatar } from '@images/icons/logo-dark-short.svg';
import { Profile } from '../../api.shared';
import { TariffInfoProps } from './tariff';
import { VerificationProps } from './verification';

interface Props {
  profile: Profile;
  tariff: ReactElement<TariffInfoProps>;
  verification: ReactElement<VerificationProps>;
}
export const ProfileCard: FC<Props> = memo<Props>((p) => {
  const roleLabels = useRoleLabels();
  const role = roleLabels[p.profile.role.name];

  return (
    <Box className={s.card}>
      <div className={s.avatar}>
        <Avatar className={s.image} />
      </div>

      <Title className="mb3" level={2}>
        {p.profile.displayName}
      </Title>

      {p.verification}

      <Title level={4} color="secondary">
        <Trans id="profile.card.account_type">Account type</Trans>
      </Title>

      <div className={classnames(s.box, 'mb3')} data-uat="role-label">
        {role}
      </div>

      <Title level={4} color="secondary">
        <Trans id="profile.card.id">ID number</Trans>
      </Title>

      <div className={s.box} data-uat="user-id-label">
        {p.profile.id}
      </div>

      {p.tariff}
    </Box>
  );
});
