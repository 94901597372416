import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  FC,
} from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import { ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { TypedError } from '@services/stomp/errors';
import { formService, Form, InputNumber, SubmitButton, Select } from '@components/form';
import { useI18n } from 'containers/services/commonService';
import { CirclePreloader } from '@components/preloader';
import { Context } from '../context';
import apiService, { RequestExchangeErrorCode } from '../api';

const FORM_ID = 'wallet.currency-exchange.currency-setting';

enum Field {
  AmountToBuy = 'amountToBuy',
  AmountToSell = 'amountToSell',
  CurrencyToBuy = 'currencyTo',
  CurrencyToSell = 'currencyFrom',
}

type FieldValues = {
  [Field.AmountToBuy]: number | null;
  [Field.AmountToSell]: number | null;
  [Field.CurrencyToBuy]: number | null;
  [Field.CurrencyToSell]: number | null;
};

type Currency = { id: number; code: string };

export const CurrencySetting: FC = () => {
  const { i18n } = useI18n();
  const { state, dispatch } = useContext(Context);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { amountToBuy, amountToSell, currencies, currencyToBuy, currencyToSell, exchangeRate } =
    state;

  const selectCurrencyList = useMemo(
    () => currencies.map((c) => ({ label: c.code, value: c.id })),
    [currencies]
  );

  const getCurrencyCode = useCallback(
    (currencyId: number): string =>
      currencies.find((c) => c.id === currencyId)?.code ?? 'CODE_NOT_FOUND',
    [currencies]
  );

  const onAmountToBuySelected = useCallback((amountToBuy: number) => {
    dispatch({ type: 'ON_AMOUNT_TO_BUY_SELECTED', payload: amountToBuy });
  }, []);

  const onAmountToSellSelected = useCallback((amountToSell: number) => {
    dispatch({ type: 'ON_AMOUNT_TO_SELL_SELECTED', payload: amountToSell });
  }, []);

  const onCurrencyToBuySelected = useCallback(
    (currencyToBuy: number | string) => {
      const currencyId = Number(currencyToBuy);
      dispatch({
        type: 'ON_CURRENCY_TO_BUY_SELECTED',
        payload: { id: currencyId, code: getCurrencyCode(currencyId) },
      });
    },
    [getCurrencyCode]
  );

  const onCurrencyToSellSelected = useCallback(
    (currencyToSell: number | string) => {
      const currencyId = Number(currencyToSell);
      dispatch({
        type: 'ON_CURRENCY_TO_SELL_SELECTED',
        payload: { id: currencyId, code: getCurrencyCode(currencyId) },
      });
    },
    [getCurrencyCode]
  );

  const updateFormValues = useCallback(
    (fields: {
      amountToBuy: number | null;
      amountToSell: number | null;
      currencyToBuy: Currency | null;
      currencyToSell: Currency | null;
    }) => {
      const form = formService.get<FieldValues>(FORM_ID);
      form?.setFieldsValue({
        [Field.AmountToBuy]: fields.amountToBuy,
        [Field.AmountToSell]: fields.amountToSell,
        [Field.CurrencyToBuy]: fields.currencyToBuy?.id,
        [Field.CurrencyToSell]: fields.currencyToSell?.id,
      });
    },
    []
  );

  const currencyToBuyId = useRef(currencyToBuy?.id);
  currencyToBuyId.current = currencyToBuy?.id;

  const currencyToSellId = useRef(currencyToSell?.id);
  currencyToSellId.current = currencyToSell?.id;

  const [isFetchingRate, setIsFetchingRate] = useState(false);
  const [rateError, setRateError] = useState(false);

  const updateExchangeRate = useCallback((buyId: number, sellId: number) => {
    setIsFetchingRate(true);

    return apiService.fetchExchangeRate(buyId, sellId).then((exchangeRate) => {
      const isCurrencyToBuyChanged = currencyToBuyId.current !== buyId;
      const isCurrencyToSellChanged = currencyToSellId.current !== sellId;

      if (isCurrencyToBuyChanged || isCurrencyToSellChanged) return;

      setIsFetchingRate(false);
      dispatch({ type: 'EXCHANGE_RATE_FETCHED', payload: exchangeRate });
    });
  }, []);

  useEffect(() => {
    if (currencies.length > 0) return;
    apiService.fetchCurrencies().then((fetchedCurrencies) => {
      dispatch({ type: 'CURRENCIES_FETCHED', payload: fetchedCurrencies });
    });
  }, [currencies]);

  useEffect(() => {
    if (typeof currencyToBuy?.id === 'number' && typeof currencyToSell?.id === 'number') {
      updateExchangeRate(currencyToBuy.id, currencyToSell.id).catch((err) => {
        handleBackendError(err);
      });
    }
  }, [currencyToBuy?.id, currencyToSell?.id]);

  useEffect(() => {
    setRateError(false);
  }, [amountToBuy, amountToSell, currencyToBuy, currencyToSell]);

  // This effect is used to populate initial values
  // when returning from another screen. We cannot do
  // this in layout effect, since form is not defined
  // there during initial render.
  useEffect(() => {
    updateFormValues({
      amountToBuy,
      amountToSell,
      currencyToBuy,
      currencyToSell,
    });
  }, []);

  // This effect is used to update field values in form during the input.
  useLayoutEffect(() => {
    updateFormValues({
      amountToBuy,
      amountToSell,
      currencyToBuy,
      currencyToSell,
    });
  }, [amountToBuy, amountToSell, currencyToBuy, currencyToSell, updateFormValues]);

  const onSubmit = () => {
    const form = formService.get<FieldValues>(FORM_ID);

    if (!form) return;

    // We extract form values from the form and not from the state, since
    // we want to make a conversion with the data the user sees on the screen.
    const formValues = form.getFieldsValue();
    setRateError(false);
    setIsSubmitting(true);

    return apiService
      .validateExchangeData({
        amountToSell: formValues[Field.AmountToSell],
        currencyToBuyId: formValues[Field.CurrencyToBuy],
        currencyToSellId: formValues[Field.CurrencyToSell],
        rate: exchangeRate,
      })
      .then((data) => {
        dispatch({
          type: 'VALIDATION_COMPLETED',
          payload: data,
        });
      })
      .catch((err) => {
        if (
          err instanceof TypedError &&
          err.code === RequestExchangeErrorCode.ExchangeRateChanged
        ) {
          updateExchangeRate(currencyToBuyId.current, currencyToSellId.current)
            .then(() => {
              setRateError(true);
            })
            .catch((err) => {
              handleBackendError(err);
            });
          return;
        }
        throw err;
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const isCurrencySelectDisabled = currencies.length === 0;
  const isFormPopulated =
    amountToBuy !== null &&
    amountToSell !== null &&
    currencyToBuy !== null &&
    currencyToSell !== null;

  const shownRate = exchangeRate ?? (
    <Trans id={'my_wallet.exchange_currency.currency_setting.na'}>N/A</Trans>
  );

  return (
    <Form name={FORM_ID}>
      <ModalTitle>
        <Trans id={'my_wallet.exchange_currency.currency_setting_title'}>Currencies & Amount</Trans>
      </ModalTitle>

      <ModalContent>
        <p>
          <Trans id={'my_wallet.exchange_currency.currency_setting.input_suggestion'}>
            Please enter the currency and amount you wish to sell or buy
          </Trans>
        </p>
        <div className={'wallet-currency-exchange-currency-setting-input-row'}>
          <Select
            data={selectCurrencyList}
            disabled={isCurrencySelectDisabled}
            label={i18n._(
              defineMessage({
                id: 'my_wallet.exchange_currency.currency_setting.sell',
                message: 'Sell',
              })
            )}
            name={Field.CurrencyToSell}
            onChange={onCurrencyToSellSelected}
          />
          <InputNumber
            name={Field.AmountToSell}
            label={i18n._(
              defineMessage({
                id: 'my_wallet.exchange_currency.currency_setting.to_sell',
                message: 'Amount to sell',
              })
            )}
            min={0}
            onChange={onAmountToSellSelected}
          />
        </div>
        <div className={'wallet-currency-exchange-currency-setting-input-row'}>
          <Select
            disabled={isCurrencySelectDisabled}
            data={selectCurrencyList}
            label={i18n._(
              defineMessage({
                id: 'my_wallet.exchange_currency.currency_setting.buy',
                message: 'Buy',
              })
            )}
            name={Field.CurrencyToBuy}
            onChange={onCurrencyToBuySelected}
          />
          <InputNumber
            name={Field.AmountToBuy}
            label={i18n._(
              defineMessage({
                id: 'my_wallet.exchange_currency.currency_setting.to_buy',
                message: 'Amount to buy',
              })
            )}
            min={0}
            onChange={onAmountToBuySelected}
          />
        </div>
        <p className={'wallet-currency-exchange-currency-setting-exchange-row'}>
          <Trans id={'my_wallet.exchange_currency.currency_setting.exchange_rate'}>
            Your exchange rate
          </Trans>
          : <span>{isFetchingRate ? <CirclePreloader /> : shownRate}</span>
        </p>
        {rateError ? (
          <p className={'wallet-currency-exchange-currency-setting-exchange-rate-error'}>
            <Trans id={'my_wallet.exchange_currency.currency_setting.rate_error'}>
              The currency rate was updated due to the expiration of the previous one.
            </Trans>
          </p>
        ) : null}
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={FORM_ID}
          onAction={{ submit: onSubmit }}
          className={'wallet-currency-exchange-submit-button'}
          disabled={isFetchingRate || !isFormPopulated || isSubmitting}
        >
          <Trans id={'my_wallet.exchange_currency.currency_setting.submit'}>Continue</Trans>
        </SubmitButton>
      </ModalFooter>
    </Form>
  );
};
