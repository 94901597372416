import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

export const SettledCell: FC<{
  settled: boolean;
}> = ({ settled }) =>
  settled ? (
    <Trans id="statements.payments.table.settled_status_yes">Yes</Trans>
  ) : (
    <span>&nbsp;</span>
  );
