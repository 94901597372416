import { ReactNode } from 'react';

import { FormTranslatedErrors, translateFormErrors } from '@services/translate';
import {
  errorCodesTranslations as errorCodes,
  errorTextsTranslations as errorTexts,
  errorTitlesTranslations as errorTitles,
} from './translations';

export {
  errorCodesTranslations,
  errorTextsTranslations,
  errorTitlesTranslations,
} from './translations';

type ErrorText = ReactNode | string;

export type RawError = any;

export class CancelRequestError {
  public message: ErrorText;

  public constructor(message) {
    this.message = message;
  }
}

export class ConnectionError {
  public message: ErrorText;
  public title: ErrorText;

  public constructor(message) {
    this.title = errorTitles.connection;
    this.message = message;
  }
}

export class ExceptionError {
  public message: ErrorText;
  public title: ErrorText;
  public raw: RawError;

  public constructor(e) {
    this.title = errorCodes[e.data?.code]?.title || errorTitles.exception;
    this.message =
      errorCodes[e.data?.code]?.text || e.data?.message || e.data || errorTexts.unknown;
    this.raw = e;
  }
}

export class HandledError {
  public raw: RawError;

  public constructor(error) {
    this.raw = error;
  }
}

export class InfoError {
  public message: ErrorText;
  public title: ErrorText;
  public raw: RawError;

  public constructor(e) {
    this.title = errorCodes[e.data?.code]?.title || errorTitles.info;
    this.message = errorCodes[e.data?.code]?.text || e.data?.message || errorTexts.unknown;
    this.raw = e;
  }
}

export class OperationError {
  public message: ErrorText;
  public title: ErrorText;
  public raw: RawError;

  public constructor(e) {
    this.title = errorCodes[e.data?.code]?.title || errorTitles.operation;
    this.message = errorCodes[e.data?.code]?.text || e.data?.message || errorTexts.unknown;
    this.raw = e;
  }
}

export class SystemError {
  public message: ErrorText;
  public title: ErrorText;
  public raw: RawError;

  public constructor(error) {
    this.title = errorTitles.system;
    this.message = error.data;
    this.raw = error;
  }
}

export class TypedError {
  public code: string;
  public data: unknown;
  public raw: RawError;

  public constructor(error) {
    this.code = error.data.code;
    this.data = error.data.data;
    this.raw = error;
  }
}

export class UnknownError {
  public message: ErrorText;
  public title: ErrorText;
  public raw: RawError;

  public constructor(error) {
    this.title = errorTitles.unknown;
    this.message = error.data;
    this.raw = error;
  }
}

export interface ValidationItem {
  code: string;
  field: string;
  message: string;
  params?: Record<string, string | number | null>;
}

export class ValidationError {
  public raw: RawError;
  public type: string;
  public formErrors: FormTranslatedErrors;

  public data: Array<ValidationItem>; // TODO this field is deprecated and used only in old form
  public errors; // TODO this field is deprecated and used only in old form

  public constructor(raw: RawError, errors?: FormTranslatedErrors) {
    this.type = 'VALIDATION_ERROR';
    this.raw = raw;
    this.formErrors = translateFormErrors(raw?.data || []);
    this.data = raw?.data || [];
    this.errors = errors || this.formErrors;
  }
}
