import { createSlice } from '@reduxjs/toolkit';

import e from './effects';
import { sliceId, stateInitial } from './model';

export { default as selectors } from './selectors';

const s = createSlice({
  name: sliceId(null),
  initialState: stateInitial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(e.getCurrencies.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(e.getCurrencies.fulfilled, (state, action) => {
      state.isLoading = false;
      state.currencies = action.payload;
    });
    builder.addCase(e.getCurrencies.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const reducer = s.reducer;

export const actions = {
  getCurrencies: e.getCurrencies,
};
