import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import sign from 'tm2sign.macro';

class SendMetalsToInvestorApiService {
  public sendToInvestorAccount(request) {
    const fields = sign('sendToInvestorAccount', ['value']);

    const typedValues = {
      ...v.sendToInvestorAccountRequestInput({ request }),
    };

    return stompClient.sendData('sendToInvestorAccount', fields, typedValues);
  }
}

export const sendMetalsToInvestorApiService = new SendMetalsToInvestorApiService();
