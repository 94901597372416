import { SortItem } from '@models/core';
import { TableHeaderSortType as Sort } from '../../../../components/page-table';
import { TransactionsFiltersForm } from '../actions-row/model';
import { TransactionsTableColumnIds } from '../model';
import { TransactionFilters, TransactionItem } from '../api';

const columnSort = [
  { direction: Sort.notSet, fields: ['amount'], id: TransactionsTableColumnIds.amount },
  { direction: Sort.notSet, fields: ['date'], id: TransactionsTableColumnIds.date },
];

export interface TransactionsState {
  filtersAvailable: TransactionFilters;
  filtersSelected: TransactionsFiltersForm;
  hasMore: boolean;
  isFiltersLoading: boolean;
  isTransactionsLoading: boolean;
  page: number;
  sort: Array<SortItem<TransactionsTableColumnIds>>;
  transactions: Array<TransactionItem>;
}

export const transactionsInitialState: TransactionsState = {
  filtersAvailable: {
    assets: [],
  },
  filtersSelected: {
    date: [undefined, undefined],
    amount: [undefined, undefined],
    asset: null,
  },
  hasMore: true,
  isFiltersLoading: false,
  isTransactionsLoading: false,
  page: 1,
  sort: columnSort,
  transactions: [],
};

export enum TransactionsActionsTypes {
  GET_FILTERS = 'TRANSACTIONS.GET_FILTERS',
  GET_FILTERS_FAIL = 'TRANSACTIONS.GET_FILTERS_FAIL',
  GET_FILTERS_SUCCESS = 'TRANSACTIONS.GET_FILTERS_SUCCESS',

  GET_TRANSACTIONS = 'TRANSACTIONS.GET_TRANSACTIONS',
  GET_TRANSACTIONS_SUCCESS = 'TRANSACTIONS.GET_TRANSACTIONS_SUCCESS',

  FILTER_CHANGED = 'TRANSACTIONS.FILTER.CHANGED',

  ON_FILTERS_CHANGED = 'TRANSACTIONS.ON_FILTERS_CHANGED',
  ON_PAGE_OPENED = 'TRANSACTIONS.ON_PAGE_OPENED',
  ON_TABLE_SORTED = 'TRANSACTIONS.ON_TABLE_SORTED',
}
