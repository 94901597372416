import sign from 'tm2sign.macro';
import _ from 'lodash';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';
import { WithdrawalStatus } from '@models/core';

import type { BankAccount as BankAccountShared } from '@gql/UserBankAccount.model';

export interface Withdrawal {
  amount: number;
  createdAt: string;
  id: number;
  status: WithdrawalStatus;
  settledAt: string;
  amountSettled: number;
  purpose: string;
  sourceOfFunds: string;
  externalId: string;
  currency: {
    code: number;
  };
  userBankAccount: {
    accountNumber: string;
    bankName: string;
    comment: string;
    ibanNumber: string;
    nickname: string;
    swiftCode: string;
    user: {
      address: string;
      company: string;
      displayName: string;
      id: number;
      firstName: string;
      lastName: string;
    };
  };
}

interface WithdrawalDTO {
  amount: number;
  userBankAccount?: { id: number };
  currency: { id: number };
  proofOfAddress?: { id: number };
  userId?: number;
}
const createWithdrawalFields = [
  'amount',
  'createdAt',
  'id',
  'status',
  'settledAt',
  'amountSettled',
  'purpose',
  'sourceOfFunds',
  'externalId',
  { currency: ['code'] },
  {
    userBankAccount: [
      'accountNumber',
      'bankName',
      'comment',
      'ibanNumber',
      'nickname',
      { user: ['id', 'displayName', 'address'] },
      'swiftCode',
    ],
  },
];
const createWithdrawalQuery = 'createWithdrawal';
const createWithdrawal = makeQuery({
  permissions: { or: [Permission.WITHDRAWAL_CREATE_FOR_OTHER] },
  queryName: createWithdrawalQuery,
  queryFields: createWithdrawalFields,
  query: (withdrawal: WithdrawalDTO): Promise<Withdrawal> => {
    return stompClient.sendData<Withdrawal>(
      createWithdrawalQuery,
      sign(createWithdrawalQuery, createWithdrawalFields),
      v.withdrawalCreateDTOInput({ withdrawal })
    );
  },
});

export type BankAccount = BankAccountShared;
const getBankAccountsFields = [
  'accountNumber',
  'bankName',
  'comment',
  {
    currency: [
      'code',
      'id',
      {
        withdrawalRules: ['minWithdrawalAmount', 'minWithdrawalFeeAmount', 'withdrawalFeePercent'],
      },
    ],
  },
  'ibanNumber',
  'id',
  'nickname',
  'routeCode',
  'swiftCode',
];
const getBankAccountsQuery = 'userBankAccounts';
const getBankAccounts = makeQuery({
  permissions: { or: [Permission.BANK_ACCOUNTS_VIEW_OTHER] },
  queryName: getBankAccountsQuery,
  queryFields: getBankAccountsFields,
  query: (userId?: number): Promise<Array<BankAccount>> => {
    return stompClient
      .getData<Array<BankAccount>>(
        getBankAccountsQuery,
        sign(getBankAccountsQuery, getBankAccountsFields),
        v.long({ userId })
      )
      .then((accounts) =>
        accounts.filter((account) => {
          return currenciesFilter(account.currency);
        })
      );
  },
});

interface StableBalance {
  balance: number;
  currency: { code: string; id: number };
}
const getUserStableBalancesQuery = 'userProfile';
const getUserStableBalancesFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [
              {
                stableBalance: [
                  'amount',
                  {
                    currency: ['id', 'code'],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
const getUserStableBalances = makeQuery({
  queryName: getUserStableBalancesQuery,
  queryFields: getUserStableBalancesFields,
  query: (userId: number): Promise<Array<StableBalance>> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: {
            stableBalance: Array<{
              amount: number;
              currency: {
                code: string;
                id: number;
              };
            }>;
          };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getUserStableBalancesQuery,
        sign(getUserStableBalancesQuery, getUserStableBalancesFields),
        v.long({ userId })
      )
      .then((response) => {
        const stableBalance = response?.balance.primary.active.stableBalance;
        return stableBalance
          ?.filter((balance) => currenciesFilter(balance.currency))
          .map((b) => ({
            balance: b.amount,
            currency: {
              code: b.currency.code,
              id: b.currency.id,
            },
          }));
      });
  },
});

interface Currency {
  id: number;
  code: string;
}
const getCurrenciesOtherQuery = 'currencies';
const getCurrenciesOtherFields = ['code', 'id'];
const getCurrenciesOther = makeQuery({
  queryName: getCurrenciesOtherQuery,
  queryFields: getCurrenciesOtherFields,
  query: (): Promise<Array<Currency>> => {
    return stompClient
      .getData<Array<Currency>>(
        getCurrenciesOtherQuery,
        sign(getCurrenciesOtherQuery, getCurrenciesOtherFields)
      )
      .then((response) => response.filter((c) => currenciesFilter(c)))
      .then((currencies) => _.uniqBy(currencies, 'id'));
  },
});

interface UserDisplayName {
  displayName: string;
}
const getUserDisplayNameQuery = 'userProfile';
const getUserDisplayNameFields = ['displayName'];
const getUserDisplayName = makeQuery({
  queryName: getUserDisplayNameQuery,
  queryFields: getUserDisplayNameFields,
  query: (userId: number): Promise<string> => {
    return stompClient
      .getData<UserDisplayName>(
        getUserDisplayNameQuery,
        sign(getUserDisplayNameQuery, getUserDisplayNameFields),
        v.long({ userId })
      )
      .then((response) => (response ? response.displayName : ''));
  },
});

const api = {
  createWithdrawal,
  getBankAccounts,
  getCurrenciesOther,
  getUserDisplayName,
  getUserStableBalances,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:withdraw-request-modal');

export default api;
