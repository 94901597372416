import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Form, SubmitButton } from '@components/form';
import { CountrySelect } from '@modules/countries';
import { PrevStepButton } from '../../prev-button';
import { DynamicForm } from '../../dynamic-form';
import { Fields, formId, useModel } from './model';

export const ProvideAddressInfoStep: FC = memo(() => {
  const m = useModel();

  return (
    <Form className={s.form} name={formId} initialValues={m.formInitial}>
      <h2>
        <Trans id="signup.address_info_step.title">Welcome!</Trans>
      </h2>

      <div className="mb-md">
        <Trans id="signup.address_info_step.text">Please provide your address information</Trans>
      </div>

      <DynamicForm formRows={m.formRows} lockUI={m.lockUI} />

      <CountrySelect
        name={Fields.country}
        label={<Trans id="signup.address_info_step.label.country">Country</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <div className={s.buttons}>
        <PrevStepButton className="mr3" disabled={m.lockUI} />

        <SubmitButton
          name={formId}
          fullWidth={true}
          disabled={m.lockUI}
          onAction={m.onFormAction}
          className={s.button}
        >
          <Trans id="signup.address_info_step.button.finish">Finish</Trans>
        </SubmitButton>
      </div>
    </Form>
  );
});
