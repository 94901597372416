import { createContext } from 'react';
import { BaseItem } from '../core';
import { ColumnType, DefaultRowCellProps } from './types';

export type ColumnsContextValue<
  T extends BaseItem,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = {
  columns: ColumnType<T, H, R>[];
};

export const ColumnsContext = createContext<ColumnsContextValue<any>>({
  columns: [],
});
