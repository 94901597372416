import { useRef } from 'react';
import { useMemoOne } from 'use-memo-one';
import { BaseItem } from '../loadable-virtual-table';
import {
  useTableSorter,
  SortOrder,
  TableSorterReturnType,
  TableSorterParams as OriginalTableSorterParams,
} from '../table-sorter/use-table-sorter';
import { areOrdersEqual, sortItems } from '../table-sorter/sorting';

export { areOrdersEqual, areFieldsEqual, sortItems } from '../table-sorter/sorting';

const AGGREGATOR_INSTANCE_SYMBOL = Symbol();
const AGGREGATOR_PARAMS_SYMBOL = Symbol();

export type {
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterReturnType,
} from '../table-sorter/use-table-sorter';

export type InMemorySorter<T extends BaseItem> = (data: T[], sortOrder: SortOrder) => T[];

export type TableSorterParams<T extends BaseItem> = Omit<
  OriginalTableSorterParams,
  'inMemorySorter'
> & {
  // This should only be used with non-loadable tables.
  inMemorySorter?: InMemorySorter<T> | boolean;
};

export type UseTableReturnType<T extends BaseItem> = Readonly<{
  sorter: TableSorterReturnType;
  [AGGREGATOR_INSTANCE_SYMBOL]: true;
  [AGGREGATOR_PARAMS_SYMBOL]: UseTableParams<T>;
}>;

// We want to allow passing data as a prop for those not using this hook
// directly. To avoid different ways of setting the data we don't provide
// it as a hook param.
export type UseTableParams<T extends BaseItem> = {
  sorter?: TableSorterParams<T>;
};

export const useTable = <T extends BaseItem>(
  params: UseTableParams<T> | UseTableReturnType<T> = {}
): UseTableReturnType<T> => {
  const isInstantiated = useRef(Boolean(params?.[AGGREGATOR_INSTANCE_SYMBOL]));

  if (process.env.NODE_ENV === 'development') {
    const isInstanceBroken =
      isInstantiated.current !== Boolean(params?.[AGGREGATOR_INSTANCE_SYMBOL]);
    if (isInstanceBroken) {
      throw new Error(
        'use-table-aggregator: instance of the aggregator should be preserved or not used at all'
      );
    }
  }
  if (isInstantiated.current) {
    return params as UseTableReturnType<T>;
  }
  const sorter = useTableSorter(params.sorter);
  return {
    sorter,
    [AGGREGATOR_INSTANCE_SYMBOL]: true,
    [AGGREGATOR_PARAMS_SYMBOL]: params,
  };
};

export type UseTableInternalReturnType<T extends BaseItem> = Readonly<{
  aggregator: UseTableReturnType<T>;
  data: T[];
}>;

export type UseTableInternalParams<T extends BaseItem> = {
  data: T[];
  instance?: UseTableReturnType<T>;
};

function defaultInMemorySorter<T extends BaseItem>(data: T[], sortOrder: SortOrder) {
  return sortItems(data, sortOrder);
}

function getIdentity<T>(param: T): T {
  return param;
}

export const useTableInternal = <T extends BaseItem>(
  params: UseTableInternalParams<T>
): UseTableInternalReturnType<T> => {
  const aggregator = useTable(params.instance);
  const providedInMemorySorter = aggregator[AGGREGATOR_PARAMS_SYMBOL].sorter?.inMemorySorter;

  let inMemorySorter: InMemorySorter<T> = defaultInMemorySorter;
  if (typeof providedInMemorySorter === 'function') {
    inMemorySorter = providedInMemorySorter;
  }
  if (!providedInMemorySorter) {
    inMemorySorter = getIdentity;
  }

  const prevData = useRef(params.data);
  const prevSortedData = useRef(params.data);
  const prevSortOrder = useRef<SortOrder>([]);

  const data = useMemoOne(() => {
    if (!providedInMemorySorter) return params.data;

    const orderChanged = !areOrdersEqual(prevSortOrder.current, aggregator.sorter.order);
    const dataChanged = params.data !== prevData.current;

    if (!orderChanged && !dataChanged) {
      return prevSortedData.current;
    }

    const sortedData = inMemorySorter(params.data, aggregator.sorter.order);
    prevData.current = params.data;
    prevSortedData.current = sortedData;
    prevSortOrder.current = aggregator.sorter.order;

    return sortedData;
  }, [aggregator.sorter.order, params.data, providedInMemorySorter]);

  return { aggregator, data };
};
