import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { CMBankAccountType } from '../../../hooks';

// ----------------------------------------------------------------
export enum BankAccountFields {
  accountNumber = 'accountNumber',
  bankAccountType = 'type',
  bank = 'bank.id',
  comment = 'comment',
  currency = 'currency.id',
  currentBalance = 'currentBalance',
  id = 'id', // not exist for crete bank account
  legalEntity = 'legalEntity',
}
const F = BankAccountFields; // alias
export interface BankAccountForm {
  [F.accountNumber]: string;
  [F.bankAccountType]: CMBankAccountType;
  bank: { id: number };
  [F.comment]?: string;
  currency: { id: number };
  [F.currentBalance]: number;
  [F.id]: number;
  [F.legalEntity]: string;
}
const createBankAccountFields = ['id'];
const createBankAccountQuery = 'backofficeCreateBankAccount';
const createBankAccount = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: createBankAccountQuery,
  queryFields: createBankAccountFields,
  query: (bankAccount: BankAccountForm): Promise<number> => {
    const variables = v.createBankAccountInput({ bankAccount });
    return stompClientService
      .sendData(
        createBankAccountQuery,
        sign(createBankAccountQuery, createBankAccountFields),
        variables
      )
      .then((response) => response.id);
  },
});
// ----------------------------------------------------------------
const editBankAccountFields = ['id'];
const editBankAccountQuery = 'backofficeEditBankAccount';
const editBankAccount = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: editBankAccountQuery,
  queryFields: editBankAccountFields,
  query: (bankAccount: BankAccountForm): Promise<number> => {
    const variables = v.editBankAccountInput({ bankAccount });
    return stompClientService
      .sendData(editBankAccountQuery, sign(editBankAccountQuery, editBankAccountFields), variables)
      .then((response) => response.id);
  },
});
// ----------------------------------------------------------------
const getBankAccountFields = [
  'accountNumber',
  { bank: ['id'] },
  'comment',
  { currency: ['id'] },
  'currentBalance',
  'id',
  'legalEntity',
  'type',
];
const getBankAccountQuery = 'backofficeBankAccountById';
const getBankAccount = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: getBankAccountQuery,
  queryFields: getBankAccountFields,
  query: (id: number): Promise<BankAccountForm> => {
    const variables = v.idInput({ id: { id } });
    return stompClientService.getData(
      getBankAccountQuery,
      sign(getBankAccountQuery, getBankAccountFields),
      variables
    );
  },
});
// ----------------------------------------------------------------
const getBankAccountTypesFields = ['value'];
const getBankAccountTypesQuery = 'backofficeBankAccountTypes';
const getBankAccountTypes = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: getBankAccountTypesQuery,
  queryFields: getBankAccountTypesFields,
  query: (): Promise<Array<CMBankAccountType>> => {
    return stompClientService
      .getData<{ value: Array<CMBankAccountType> }>(
        getBankAccountTypesQuery,
        sign(getBankAccountTypesQuery, getBankAccountTypesFields)
      )
      .then((response) => response.value);
  },
});
// ----------------------------------------------------------------
export type CMBank = {
  bankName: string;
  id: number;
};
const getBanksFields = ['bankName', 'id'];
const getBanksQuery = 'backofficeBanks';
const getBanks = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: getBanksQuery,
  queryFields: getBanksFields,
  query: (): Promise<Array<CMBank>> => {
    return stompClientService.getData(getBanksQuery, sign(getBanksQuery, getBanksFields));
  },
});
// ----------------------------------------------------------------
const api = {
  createBankAccount,
  editBankAccount,
  getBankAccount,
  getBankAccountTypes,
  getBanks,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:cm-handle-bank-account');

export default api;
