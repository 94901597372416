import React, { createContext, ReactNode } from 'react';
import classNames from 'classnames';
import { Modal as AntModal } from 'antd';

import themeStyle from '@styles/theme/index.module.scss';
import './old-theme-fixes.scss';
import './styles.scss';
import { MFC } from '../../index';

export const modalContext = createContext<ModalContext>({
  disabled: false,
  loading: false,
  onClose: Function,
});

const { Provider } = modalContext;

export const Modal: MFC<{
  children: ReactNode;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClose: Function;
  size?: 'big';
  uat?: string;
}> = ({ children, className, disabled, isVisible, loading, onClose, size, uat, ...props }) => {
  return (
    <Provider value={{ disabled, loading, onClose: onClose as () => void }}>
      <AntModal
        title={null}
        footer={null}
        visible={isVisible}
        closable={false}
        destroyOnClose={true}
        forceRender={true}
        wrapClassName={classNames(
          'tm2-modal tm2-light-theme tm2-deprecated-page-styles',
          themeStyle.theme,
          themeStyle.themeContrast,
          className,
          {
            'tm2-modal-big': size === 'big',
          }
        )}
        data-uat={uat}
        {...props}
      >
        {children}
      </AntModal>
    </Provider>
  );
};

export interface ModalContext {
  disabled: boolean;
  loading: boolean;
  onClose: () => void;
}
