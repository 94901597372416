import React, { FC, ReactNode } from 'react';

import { useNotifyModel } from './notify.model';

export const NotifyMessage: FC<{
  text: ReactNode;
  time: string;
}> = ({ text, time }) => {
  const { isShowMoreExist, onShowMoreClicked, setTextRef } = useNotifyModel();

  return (
    <div className="message-container">
      <div className="message-container-text" ref={(textRef) => setTextRef(textRef)}>
        {text}
      </div>

      <div className="message-container-info">
        {!isShowMoreExist ? null : (
          <a
            href="#" // eslint-disable-line
            onClick={onShowMoreClicked}
          >
            Show more
          </a>
        )}

        <span className="message-container-info-time ml-auto">{time}</span>
      </div>
    </div>
  );
};
