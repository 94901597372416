import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { HasAccess, Permission as P } from '@permissions/core';
import { Form, InputNumber, InputTextarea, Select } from '@components/form';
import { Col, Row } from '@components/grid';
import { Title } from '@components/typography/title';
import { BankAccount } from '../../deposits-api.service';
import { AddFundsType } from '../store/model';
import { defaultExternalBankAccount, WireTransferFormFields as Fields } from './store/model';
import { useModel } from './model';
import s from './index.module.scss';

const initialFormValues = {
  [Fields.externalBankAccountBalanceId]: defaultExternalBankAccount.externalId,
};

export const WireTransferView: FC = memo(() => {
  const m = useModel();

  return (
    <div>
      <Form
        name={AddFundsType.wireTransfer}
        onChange={m.onFormChanged}
        initialValues={initialFormValues}
      >
        <HasAccess permissions={{ or: [P.INVOICE_CREATE_EXT] }}>
          <InputNumber
            name={Fields.investorId}
            label={<Trans id="deposit_modal.investor_id">Investor ID</Trans>}
            required={true}
            disabled={m.lockUI}
            maxDecimals={0}
            onChange={(id) => m.onInvestorSelected(Number(id))}
          />
        </HasAccess>

        <Row>
          <Col sm={12}>
            <Select
              name={Fields.bankAccountId}
              label={<Trans id="deposit_modal.currency">Currency</Trans>}
              required={true}
              disabled={m.lockUI}
              data={m.bankAccounts.map((bank: BankAccount) => ({
                label: bank.currency.code,
                value: bank.id,
              }))}
              onChange={(id: number) => m.onBankAccountSelected(Number(id))}
            />
          </Col>

          <Col
            sm={12}
            className={classnames({ [s.hidden]: m.isExternalBankAccountBalanceIdHidden })}
          >
            <Select
              name={Fields.externalBankAccountBalanceId}
              label={<Trans id="deposit_modal.bank_account_balance_id">Bank account ID</Trans>}
              required={true}
              disabled={m.lockExternalBankAccountsUI}
              data={m.availableExternalClientBankAccounts.map((account) => ({
                label: account.computedFields.displayName,
                value: account.externalId,
              }))}
            />
          </Col>

          <Col sm={12}>
            <InputNumber
              name={Fields.amount}
              label={<Trans id="deposit_modal.amount">Amount</Trans>}
              required={true}
              disabled={m.lockUI}
            />
          </Col>
        </Row>

        <div className={classnames(s.feesRow, 'mb2')}>
          <Title level={4}>
            <Trans id="deposit_modal.label.deposit_fee">Deposit fee</Trans>
          </Title>

          <Title level={4}>
            {m.selectedCurrency} {m.invoiceFee}
          </Title>
        </div>

        <InputTextarea
          name={Fields.clientComment}
          label={m.commentaryLabel}
          required={false}
          disabled={m.lockUI}
        />
      </Form>

      <div className="txt-sm">
        <Trans id="deposit_modal.additional_info_part1">
          Bank transfer deposits may take 4-7 business days to process.
        </Trans>
      </div>

      <div className="txt-sm">
        <Trans id="deposit_modal.additional_info_part2">
          Your TM2 trading account will be credited once the transfer has been confirmed by our
          bank.
        </Trans>
      </div>

      <div className="txt-sm">
        <Trans id="deposit_modal.additional_info_part3">
          The exchange rate to USD or other currencies will be calculated on the day the deposit was
          processed to your account.
        </Trans>
      </div>

      <div className="txt-sm">
        <Trans id="deposit_modal.additional_info_part4">
          You may be charged by funding providers involved in the transfer (intermediary bank,
          receiving bank).
        </Trans>
      </div>
    </div>
  );
});
