import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup, Role as R } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

export interface CorrectionTransaction {
  amount: number;
  createdAt: string;
  currency: {
    id: number;
    code: string;
  };
  id: number;
  recipient: {
    displayName: string;
    id: number;
    permissions: string;
  };
  sender: {
    displayName: string;
    id: number;
    permissions: string;
  };
  status: string;
}

export interface CreatedCorrectionTransaction {
  createCorrectionTransaction: CorrectionTransaction;
  id: number;
}

export enum Fields {
  // TODO: эндпионт устаревший, и требует рефакторинга
  amount = 'amount',
  currencyId = 'currency', // currency.id
  recipientId = 'recipient', // recipient.id
  senderId = 'sender', // sender.id
}

export interface Form {
  [Fields.amount]: string;
  [Fields.currencyId]: number;
  [Fields.recipientId]: number;
  [Fields.senderId]: number;
}

const createCorrectionTransactionQuery = 'createCorrectionTransaction';
const createCorrectionTransactionFields = [
  'id',
  'amount',
  'createdAt',
  { sender: ['id', 'displayName'] },
  { recipient: ['id', 'displayName'] },
  { currency: ['id', 'code'] },
  'status',
];
const createCorrectionTransaction = makeQuery({
  permissions: Permission.TRANSACTION_CREATE_CORRECTION,
  queryName: createCorrectionTransactionQuery,
  queryFields: createCorrectionTransactionFields,
  query: (form: Form): Promise<CorrectionTransaction> => {
    const typedValues = {
      ...v.bigDecimal({ amount: form[Fields.amount] }),
      ...v.long({ currencyId: form[Fields.currencyId] }),
      ...v.long({ recipientId: form[Fields.recipientId] }),
      ...v.long({ senderId: form[Fields.senderId] }),
    };

    return stompClient.sendData<CorrectionTransaction>(
      createCorrectionTransactionQuery,
      sign(createCorrectionTransactionQuery, createCorrectionTransactionFields),
      typedValues
    );
  },
});

export interface Issuer {
  displayName: string;
  id: number;
}
const getListingBrokersQuery = 'usersByRole';
const getListingBrokersFields = ['displayName', 'id'];
const getIssuers = makeQuery({
  queryName: getListingBrokersQuery,
  queryFields: getListingBrokersFields,
  query: (): Promise<Array<Issuer>> => {
    return stompClient.getData(
      getListingBrokersQuery,
      sign(getListingBrokersQuery, getListingBrokersFields),
      { role: R.issuer }
    );
  },
});

export interface Currency {
  id: number;
  code: string;
  order: number;
}
const getCurrenciesQuery = 'currencies';
const getCurrenciesFields = ['id', 'code'];
const getCurrencies = makeQuery({
  queryName: getCurrenciesQuery,
  queryFields: getCurrenciesFields,
  query: (): Promise<Array<Currency>> => {
    return stompClient.getData(getCurrenciesQuery, sign(getCurrenciesQuery, getCurrenciesFields));
  },
});

const api = {
  createCorrectionTransaction,
  getIssuers,
  getCurrencies,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:add-correction-transaction-modal');

export default api;
