import { FC } from 'react';
import classnames from 'classnames';

type TablePaneProps = {
  className?: string;
};

export const TablePane: FC<TablePaneProps> = (props) => {
  return <div className={classnames('p3', 'bg2', props.className)}>{props.children}</div>;
};
