import React, { memo } from 'react';
import classnames from 'classnames';
import moment from 'moment';
import { Trans } from '@lingui/macro';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputNumber, InputTextarea, SubmitButton } from '@components/form';
import { Col, Grid } from '@components/grid';
import { DATE_TIME_FORMAT } from '@constants/date';
import { formatMoney } from 'containers/services/commonService';
import { Fields, formId, formInitial, useModel } from './model';
import { Deposit } from '../api';
import s from './index.module.scss';

export type Props = {
  deposit: Deposit;
  replaceDeposit: (deposit: Deposit) => void;
  partly: boolean;
};
export const MarkAsPaidModal: MFC<Props> = memo(
  ({ isVisible, deposit, partly = false, replaceDeposit }) => {
    const m = useModel(deposit, replaceDeposit, partly, MarkAsPaidModal);

    return (
      <Modal isVisible={isVisible} disabled={m.lockUI} loading={m.lockUI} onClose={m.onClose}>
        <ModalTitle>
          <Trans id="mark_as_paid_modal.title">Confirm marking invoice as paid</Trans>
        </ModalTitle>

        <ModalContent>
          <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
            <Col sm={6} className={s.col}>
              <Trans id="mark_as_paid_modal.deposit_number">Invoice number</Trans>
            </Col>

            <Col sm={6} className={s.col}>
              {deposit.number}
            </Col>
          </Grid>

          <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
            <Col sm={6} className={s.col}>
              <Trans id="mark_as_paid_modal.amount">Amount</Trans>
            </Col>

            <Col sm={6} className={s.col}>
              {formatMoney(deposit.amount, { post: ` ${deposit.bankAccount.currency.code}` })}
            </Col>
          </Grid>

          <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
            <Col sm={6} className={s.col}>
              <Trans id="mark_as_paid_modal.issued_on">Issued on</Trans>
            </Col>

            <Col sm={6} className={s.col}>
              {moment(deposit.creationDate).format(DATE_TIME_FORMAT)}
            </Col>
          </Grid>

          <Grid pattern={{ sm: 2 }} className={classnames(s.grid, 'mb-md')}>
            <Col sm={6} className={s.col}>
              <Trans id="mark_as_paid_modal.customer">Customer</Trans>
            </Col>

            <Col sm={6} className={s.col}>
              {deposit.investorUser.displayName}
            </Col>
          </Grid>

          <Form name={formId} initialValues={formInitial}>
            {!partly ? null : (
              <InputNumber
                name={Fields.amount}
                label={<Trans id="mark_as_paid_modal.paid_amount">Paid amount</Trans>}
                disabled={m.lockUI}
                min={1}
                maxDecimals={0}
                required={true}
              />
            )}

            <InputTextarea
              name={Fields.accountDetails}
              disabled={m.lockUI}
              required={true}
              label={
                <Trans id="mark_as_paid_modal.customer_account_details">
                  Customer bank account details
                </Trans>
              }
            />
          </Form>
        </ModalContent>

        <ModalFooter>
          <SubmitButton
            name={formId}
            fullWidth={true}
            onAction={m.onFormAction}
            disabled={m.lockUI}
          >
            {partly ? (
              <Trans id={'mark_as_paid_modal.mark_as_partly_paid'}>Mark as partly paid</Trans>
            ) : (
              <Trans id={'mark_as_paid_modal.mark_as_paid'}>Mark as paid</Trans>
            )}
          </SubmitButton>
        </ModalFooter>
      </Modal>
    );
  }
);
