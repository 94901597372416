import { SortItem, TableResponse } from '@models/core';
import { TransactionsFiltersForm } from '../actions-row/model';
import { TransactionFilters, TransactionItem } from '../api';
import { TransactionsActionsTypes as T } from './model';
import { TransactionsTableColumnIds as ColumnIds } from '../model';

const filterChanged = (event) => ({
  type: T.FILTER_CHANGED,
  name: event.target.name,
  value: event.target.value,
});

const getFilters = () => ({
  type: T.GET_FILTERS,
  payload: null,
});

const getFiltersFail = () => ({
  type: T.GET_FILTERS_FAIL,
  payload: null,
});

const getFiltersSuccess = (filters: TransactionFilters) => ({
  type: T.GET_FILTERS_SUCCESS,
  filters,
});

const getTransactions = () => ({
  type: T.GET_TRANSACTIONS,
  payload: null,
});

const getTransactionsSuccess = (payload: TableResponse<TransactionItem>) => ({
  type: T.GET_TRANSACTIONS_SUCCESS,
  payload: payload,
});

const onFiltersChanged = (form: TransactionsFiltersForm) => ({
  type: T.ON_FILTERS_CHANGED,
  payload: form,
});

const onPageOpened = () => ({
  type: T.ON_PAGE_OPENED,
});

const onTableSorted = (payload: SortItem<ColumnIds>) => ({
  type: T.ON_TABLE_SORTED,
  payload: payload,
});

export const transactionsActions = {
  filterChanged,
  getFilters,
  getFiltersFail,
  getFiltersSuccess,
  getTransactions,
  getTransactionsSuccess,
  onFiltersChanged,
  onPageOpened,
  onTableSorted,
};
