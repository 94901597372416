import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import v from '@components/form/validators';
import { closeModalAction, MFC } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { notifySuccess } from '@modules/notify';
import { EditProfileForm } from '../edit-profile/api';
import api, { ConfirmEditProfileFields as Fields, ConfirmEditProfileForm } from './api';

export { ConfirmEditProfileFields as Fields } from './api';
export const formId = 'editProfileConfirmPasswordForm';

export const useModal = (profile: EditProfileForm) => {
  const closeModal = useCallDispatch(closeModalAction);
  const [isUpdateProfileLoading, setUpdateProfileLoading] = useState(false);

  const onFormAction: OnFormAction<ConfirmEditProfileForm> = {
    error: () => setUpdateProfileLoading(false),
    fieldValidation: {
      [Fields.password]: [v.required],
    },
    submit: async (form) => {
      setUpdateProfileLoading(true);
      await api.confirmEditProfile({
        ...profile,
        ...form,
      });
      setUpdateProfileLoading(true);
      closeModal();
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="confirm_edit_profile_modal.notification.success_edit.text">
            Your profile was successfully updated
          </Trans>
        ),
      });
    },
  };

  return {
    lockUI: isUpdateProfileLoading,
    onCloseModalClicked: () => closeModal(),
    onFormAction,
  };
};
