import React from 'react';
import axios from 'axios';
import { Trans } from '@lingui/macro';

import { configApi, configServiceActions, toggleGlobalPreloaderAction } from '@config/core';
import { session, SessionStatus as Session } from '@services/session';
import { notifyError } from '@modules/notify';
import { loggerService as logger } from '@services/logger';
import { store } from '../bootstrap/store';

export const privateApi = axios.create({
  baseURL: '/api',
});

export const publicApi = axios.create({
  baseURL: '/api',
});

export const staticContentApi = axios.create({
  baseURL: '/',
});

privateApi.interceptors.request.use(async (config) => {
  try {
    if (session.isShowLogs) {
      console.info('private_api request', config);
    }
    if (config.url === '/sso/logout') {
      // this need to avoid infinite loop when user session is empty/bad
      return config;
    }

    logger.storeRequest('http', config.url);
    const status = session.sessionStatus();
    if (status === Session.bad) {
      await Promise.reject(new Error('Bad auth'));
    }
    const token =
      status === Session.expired ? await configApi.refreshAccessToken() : session.getAccessToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  } catch (error) {
    return {
      ...config,
      cancelToken: new axios.CancelToken((cancel) => cancel('Bad auth')),
    };
  }
});

privateApi.interceptors.response.use(
  function (response) {
    store.dispatch(toggleGlobalPreloaderAction(false));
    if (session.isShowLogs) {
      console.info('private_api response', response);
    }
    return response;
  },
  function (error) {
    if (session.isShowLogs) {
      console.info('private_api error', error);
    }

    const response = error.response || {
      status: 500,
    };
    let status = response.status;

    if (axios.isCancel(error) && error.message === 'Bad auth') {
      status = 401;
    }

    if (status !== 400 && status !== 401) {
      logger.error('Private api request responded with an error', {
        location: 'src/utils/api.js:privateApi.interceptors.response.use',
        rawError: error,
      });
    }

    switch (status) {
      case 401: {
        store.dispatch(configServiceActions.logOutSession('internal'));
        break;
      }
      case 500: {
        const errorText = response?.data?.error || error.message;
        const errorDetails = response?.data?.details || [];
        const message = errorDetails.length
          ? errorDetails.join(', ')
          : errorText || (
              <Trans id="bootstrap.private_http.error_500.text">Service unavailable</Trans>
            );
        notifyError({
          text: message,
          title: <Trans id="bootstrap.private_http.error_500.title">Error 85</Trans>,
        });
        break;
      }
    }

    store.dispatch(toggleGlobalPreloaderAction(false));
    return Promise.reject(error);
  }
);

publicApi.interceptors.request.use(async (config) => {
  try {
    if (session.isShowLogs) {
      console.info('public_api request', config);
    }
    logger.storeRequest('http', config.url);
    return config;
  } catch (error) {
    return config;
  }
});

publicApi.interceptors.response.use(
  function (response) {
    if (session.isShowLogs) {
      console.info('public_api response', response);
    }
    return response;
  },
  function (error) {
    if (session.isShowLogs) {
      console.info('public_api error', error);
    }

    const status = error.response?.status;

    if (status !== 400 && status !== 401) {
      logger.error('Public api request responded with an error', {
        location: 'src/utils/api.js:publicApi.interceptors.response.use',
        rawError: error,
      });
    }

    switch (status) {
      case 401:
        store.dispatch(configServiceActions.logOutSession('internal'));
        break;
      case 500:
        const errorText = error.response?.data?.error || error.message;
        const errorDetails = error.response?.data?.details || [];
        const message = errorDetails.length
          ? errorDetails.join(', ')
          : errorText || (
              <Trans id="bootstrap.public_http.error_500.text">Service unavailable</Trans>
            );
        notifyError({
          text: message,
          title: <Trans id="bootstrap.public_http.error_500.title">Error 86</Trans>,
        });
        break;
    }

    store.dispatch(toggleGlobalPreloaderAction(false));
    return Promise.reject(error);
  }
);
