import { MFC } from '@modules/modal';
import { ConfirmationModal } from '../confirmation-modal';
import { NotifyModal } from '../notification-modal';
import {
  modalStateInitial,
  ModalActionsTypes as ModalTypes,
  ModalActions,
  ModalState,
  ModalInfo,
} from './model';

export const modalReducer = (
  state: ModalState = modalStateInitial,
  action: ModalActions
): ModalState => {
  switch (action.type) {
    case ModalTypes.CLOSE_LATEST_MODAL: {
      const modals = [...state.modals];
      modals.pop();
      const modalPrevious = modals.pop();
      return {
        ...state,
        modals: [
          ...modals,
          ...(!modalPrevious
            ? []
            : [
                {
                  ...modalPrevious,
                  isVisible: true,
                },
              ]),
        ],
      };
    }

    case ModalTypes.CLOSE_MODAL: {
      const modal = action.payload;
      return {
        ...state,
        modals: !modal ? [] : state.modals.filter((item) => item.modal !== modal),
      };
    }

    case ModalTypes.ON_CLOSE_LATEST_MODAL:
    case ModalTypes.ON_CLOSE_MODAL_CLICKED: {
      const modals = [...state.modals];
      const latestModal = modals.pop();
      return {
        ...state,
        modals: [
          ...modals,
          {
            ...latestModal,
            isVisible: false,
          },
        ],
      };
    }

    case ModalTypes.ON_SHOW_MODAL_CLICKED:
    case ModalTypes.ON_SHOW_NOTIFY_MODAL_CLICKED: {
      const modals = [...state.modals];
      const latestModal = modals.pop();
      return {
        ...state,
        modals: !latestModal
          ? modals
          : [
              ...modals,
              {
                ...latestModal,
                isVisible: false,
              },
            ],
      };
    }

    case ModalTypes.SHOW_CONFIRMATION_MODAL: {
      return {
        ...state,
        modals: [
          ...state.modals,
          {
            isVisible: true,
            modal: ConfirmationModal as MFC,
            props: action.payload,
          },
        ],
      };
    }

    case ModalTypes.SHOW_MODAL: {
      return {
        ...state,
        modals: [
          ...state.modals.map((modal: ModalInfo) => ({ ...modal, isVisible: false })),
          {
            ...action.payload,
            isVisible: true,
          },
        ],
      };
    }

    case ModalTypes.SHOW_NOTIFICATION_MODAL: {
      return {
        ...state,
        modals: [
          ...state.modals,
          {
            isVisible: true,
            modal: NotifyModal as MFC,
            props: action.payload,
          },
        ],
      };
    }

    case ModalTypes.SHOW_PREVIOUS_HIDDEN_MODAL: {
      const modals = [...state.modals];
      const modalLatest = modals.pop();
      return {
        ...state,
        modals: [
          ...modals,
          {
            ...modalLatest,
            isVisible: true,
          },
        ],
      };
    }

    default: {
      return state;
    }
  }
};
