import React, { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { handleBackendError, notifySuccess } from '@modules/notify';
import v, { nestedName } from '@components/form/validators';
import api, { Fields, Form } from './api';
import { useMetals } from './hooks';

export { Fields } from './api';
export type { Form } from './api';

export const formId = 'editMetalsModal';

export const useModel = (modal, updateMetals) => {
  const closeModal = useCallDispatch(closeModalAction);
  const onClose = () => closeModal(modal);

  const [formInfo, setFormInfo] = useState<Form>({ metalIds: [] });
  const [isSaveMetalsLoading, setSaveMealsLoading] = useState<boolean>(false);
  const { metals, isMetalsLoading } = useMetals();
  const lockUI = isMetalsLoading || isSaveMetalsLoading;

  const formInitial = useMemo(
    () => ({
      [Fields.metalList]: metals,
    }),
    [metals]
  );

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      [nestedName([Fields.metalList, Fields.metalLabel])]: [
        v.required,
        v.dynamicFormDuplicates(Fields.metalLabel),
      ],
    },
    error: async (error: Error) => {
      setSaveMealsLoading(false);
      handleBackendError(error);
    },
    submit: async (form: Form) => {
      setSaveMealsLoading(true);
      const metals = await api.updateMetals(form);
      updateMetals(metals);
      setSaveMealsLoading(false);
      closeModal(modal);
      notifySuccess({
        defaultTitle: true,
        text: <Trans id="edit_metals_modal.success_save_notification.text">Changes saved</Trans>,
      });
    },
  };

  return {
    formInfo,
    formInitial,
    lockUI,
    onClose,
    onFormAction,
    setFormInfo,
  };
};
