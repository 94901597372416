import { Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

import { LiveUpdateEvent } from './index';

class LiveUpdateService {
  private _subject: Subject<LiveUpdateEvent> = new Subject();
  private _subscriptions = [];

  public emit(event: LiveUpdateEvent): void {
    this._subject.next(event);
  }

  public subscribe(listener: (event: LiveUpdateEvent) => void, id?: string): string {
    const subscriptionId = id || uuid();
    const subscription = this._subject.subscribe(listener);
    this._subscriptions.push({ id: subscriptionId, subscription });
    return subscriptionId;
  }

  public unsubscribe(id: string): void {
    this._subscriptions = this._subscriptions.filter((item) => {
      if (item.id === id) {
        item.subscription.unsubscribe();
        return false;
      }
      return true;
    });
  }
}

export const liveUpdateService = new LiveUpdateService();
