import React, { FC, ReactNode, useState } from 'react';
import classnames from 'classnames';
import { Form as AntForm } from 'antd';
import { IMaskInput as IMaskInputNotTyped } from 'react-imask';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { Label, useLabel } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;
// TODO-2833: fix types
const IMaskInput: any = IMaskInputNotTyped;

export const InputPhone: FC<{
  className?: string;
  autocomplete?: string;
  disabled?: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (v: string) => void;
  required?: boolean;
  uat?: string;
}> = ({ className, disabled, label, name, onChange, required, uat, ...props }) => {
  const uatAttribute = useFieldUat(name, 'input-phone', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-phone', className)}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} required={required}>
        <CustomPhone
          name={name}
          label={label}
          onCustomChange={onChange}
          required={required}
          disabled={disabled}
          {...props}
        />
      </Item>
    </div>
  );
};

const CustomPhone: FC<{
  autocomplete?: string;
  disabled: boolean;
  label: ReactNode;
  maxLength?: number;
  name: NamePath;
  onChange?: (v: string) => void;
  onCustomChange?: (v: string) => void;
  required: boolean;
  value?: number;
}> = (_props) => {
  const [maskValue, setMaskValue] = useState('');
  const {
    autocomplete,
    disabled,
    label,
    name,
    onChange,
    onCustomChange,
    required,
    value,
    ...props
  } = _props;
  const { shifted, onBlur, onFocus } = useLabel({ value: value || maskValue }); // for initial values and for "+" value

  return (
    <>
      <Label htmlFor={name} shifted={shifted} label={label} required={required} />

      <IMaskInput
        className="tm2-form-field-item-instance ant-input"
        mask="+000000000000000"
        value={value}
        unmask={true}
        onAccept={(v, mask) => {
          const value: string = v && `+${v}`;
          onChange(value);
          onCustomChange && onCustomChange(value);
          setMaskValue(mask?._value);
        }}
        onBlur={onBlur}
        onFocus={onFocus}
        autocomplete={autocomplete}
        disabled={disabled}
        {...props}
      />
    </>
  );
};
