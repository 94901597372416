import { useMemoOne } from 'use-memo-one';
import {
  Table as BaseTable,
  mergeComponents,
  BaseItem,
  TableProps as OriginalTableProps,
  TableColumnType as OriginalTableColumnType,
  TableColumnGroupType as OriginalTableColumnGroupType,
} from '../base-table';
import { bodyRenderFunctionFactory, BodyComponent, BodyRenderFunction } from './body-component';
import { Provider, OnLoadMore } from './context';

// This module guarantees that the next load call won't be made unless
// the previous is finished (exception for the case when updating listKey).
// If an error happens during the loading, the component will prevent
// following loads until the listKey is updated (error flag will be passed
// to the body component).

export type { BaseItem } from '../base-table';
export type { BodyComponent } from './body-component';
export type { OnLoadMore } from './context';

type OverrideTableComponents<T extends BaseItem> = Omit<
  OriginalTableProps<T>['components'],
  'body'
> & {
  body: BodyRenderFunction<T>;
};

export type TableColumnType<T extends BaseItem> = Omit<OriginalTableColumnType<T>, 'width'> & {
  width: number;
};

export type TableColumnGroupType<T extends BaseItem> = OriginalTableColumnGroupType<T>;

export type BaseTableProps<T extends BaseItem> = Omit<
  OriginalTableProps<T>,
  'columns' | 'components' | 'scroll'
> & {
  // Column width is required by antd table if we want to use render
  // prop for body.
  columns: Array<TableColumnGroupType<T> | TableColumnType<T>>;

  // Body component will be overridden by this module.
  components?: Omit<OriginalTableProps<T>['components'], 'body'> & { body?: never };

  // scroll.y is required by antd table if we want to use render prop for body.
  // scroll.x is required for the table to be able to scroll header.
  scroll: Omit<OriginalTableProps<T>['scroll'], 'x' | 'y'> & {
    x: number;
    y: number;
  };
};

export type HasMore = boolean;

export type ListKey = string | number;

export type TableProps<T extends BaseItem, K> = {
  baseTableProps: BaseTableProps<T>;
  bodyComponent: BodyComponent<T>;
  columnWidthList?: number[];
  hasMore: HasMore;
  listKey?: ListKey;
  onLoadMore: OnLoadMore<T, K>;
};

export const Table = <T extends BaseItem, K>(props: TableProps<T, K>) => {
  const modifiedBodyComponent: BodyRenderFunction<T> = useMemoOne(
    () => bodyRenderFunctionFactory(props.bodyComponent),
    [props.bodyComponent]
  );

  const mergedComponents = useMemoOne(() => {
    const overrideComponents: OverrideTableComponents<T> = {
      ...props.baseTableProps?.components,
      body: modifiedBodyComponent,
    };
    return mergeComponents(overrideComponents);
  }, [props.baseTableProps?.components, modifiedBodyComponent]);

  return (
    <Provider
      key={props.listKey}
      columnWidthList={props.columnWidthList}
      hasMore={props.hasMore}
      onLoadMore={props.onLoadMore}
      tableHeight={props.baseTableProps.scroll.y}
      tableWidth={props.baseTableProps.scroll.x}
    >
      <BaseTable {...props.baseTableProps} components={mergedComponents} />
    </Provider>
  );
};
