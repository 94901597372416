import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { VerificationStatus } from '@models/core';
import { privateApi } from '../../../../../../../utils/api';
// ---------------------------------------------------------------------------------
export const approveKycQuery = 'privateApi/approvedKYC';
export const approveKycFields = ['value'];
const approveKyc = makeQuery({
  // TODO пермишн PROFILE_OTHER_VERIFICATION_CHANGE пока к этому не готов
  queryName: approveKycQuery,
  queryFields: approveKycFields,
  query: (userId: number): Promise<void> => {
    return privateApi.post(`/users/${userId}/approvedKYC`);
  },
});
// ---------------------------------------------------------------------------------
export const rejectKycQuery = 'privateApi/disapproveKYC';
export const rejectKycFields = ['value'];
const rejectKyc = makeQuery({
  // TODO пермишн PROFILE_OTHER_VERIFICATION_CHANGE пока к этому не готов
  queryName: rejectKycQuery,
  queryFields: rejectKycFields,
  query: (userId: number): Promise<void> => {
    return privateApi.post(`/users/${userId}/disapproveKYC`);
  },
});
// ---------------------------------------------------------------------------------
export const resubmitUserVerificationQuery = 'setUserVerificationStatus';
export const resubmitUserVerificationFields = ['value'];
const resubmitUserVerification = makeQuery({
  permissions: { or: [Permission.PROFILE_OTHER_VERIFICATION_CHANGE] },
  queryName: resubmitUserVerificationQuery,
  queryFields: resubmitUserVerificationFields,
  query: (userId: number): Promise<void> => {
    const updateVerificationStatusDTO = {
      userId: { id: userId },
      newStatus: VerificationStatus.resubmissionRequested,
    };

    const typedValues = {
      ...v.updateVerificationStatusDTOInput({ updateVerificationStatusDTO }),
    };

    return stompClient.sendData(
      resubmitUserVerificationQuery,
      sign(resubmitUserVerificationQuery, resubmitUserVerificationFields),
      typedValues
    );
  },
});
// ---------------------------------------------------------------------------------
const api = {
  approveKyc,
  rejectKyc,
  resubmitUserVerification,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:user-verification');

export default api;
