import React, { FC, Fragment, memo } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';
import { useModel } from './model';

export const PostRegistrationHeader: FC<{ className: string }> = memo(({ className }) => {
  const m = useModel();

  return (
    <div className={classnames(className, s.header)}>
      {m.steps.map((step, index) => (
        <Fragment key={index}>
          <div className={s.item}>
            <div className={classnames(s.stepIndex, index < m.current && s.stepFilled)}>
              {index + 1}
            </div>
            <div className={s.label}>{step.header}</div>
          </div>
          {index < m.steps.length - 1 && <div className={s.line} />}
        </Fragment>
      ))}
    </div>
  );
});
