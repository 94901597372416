import React, { FC, memo } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';
import { TabPane, Tabs } from '@components/tabs/regular';
import { PermissionGroup } from '@permissions/core';
import { Box } from '@components/box';
import { AccountDetailsTab } from '../../../components/tabs/account-details';
import { ProfileOther } from '../../api';
import {
  EditProfileButton,
  permissionsGroup as editProfileButtonPermissionsGroup,
} from './account-details/edit-profile-button';
import {
  permissionsGroup as bankAccountsPermissionsGroup,
  UserBankAccountsTab,
} from './bank-accounts';
import { permissionGroup as otherPermissionGroup, UserOtherTab } from './other';
import { Tab, useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [bankAccountsPermissionsGroup, editProfileButtonPermissionsGroup, otherPermissionGroup],
  marker: 'layout:profile-other-page-tabs',
});
interface UserTabsProps {
  profile: ProfileOther;
  refreshProfile: () => void;
}
export const UserTabs: FC<UserTabsProps> = memo<UserTabsProps>((p) => {
  const m = useModel(p.profile);

  return (
    <Box>
      <Tabs
        activeKey={m.activeTab}
        headerContentFit="scroll"
        onChange={m.onTabChange}
        border
        forceRender
        data={m.headerData}
      >
        <TabPane tabKey={Tab.accountDetails} key={Tab.accountDetails}>
          <AccountDetailsTab
            className={classnames(s.tab, { [s.hidden]: m.activeTab !== Tab.accountDetails })}
            profile={p.profile}
            editProfileButton={<EditProfileButton refreshProfile={p.refreshProfile} />}
          />
        </TabPane>

        <UserBankAccountsTab
          tabKey={Tab.bankDetails}
          profile={p.profile}
          hidden={m.activeTab !== Tab.bankDetails}
        />

        <TabPane key={Tab.other} tabKey={Tab.other}>
          <UserOtherTab hidden={m.activeTab !== Tab.other} />
        </TabPane>
      </Tabs>
    </Box>
  );
});
