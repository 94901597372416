import { Subject, Subscription } from 'rxjs';

export class EventsService<E> {
  private _subject = new Subject<E>();

  public emit(event: E): void {
    this._subject.next(event);
  }

  public subscribe(listener: (event: E) => void): Subscription {
    return this._subject.subscribe(listener);
  }
}
