import { useMemo } from 'react';
import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import api from './api';

export const useBankAccounts = () => {
  const { data: bankAccountsResponse, isLoading: isBankAccountsLoading } = useQuery(
    api.getBankAccounts,
    { initial: [] }
  );

  const bankAccounts: Array<SelectItem> = useMemo(() => {
    return bankAccountsResponse?.map((e) => ({
      label: e.displayName,
      value: e.id,
    }));
  }, [bankAccountsResponse]);

  return { bankAccounts, isBankAccountsLoading };
};
