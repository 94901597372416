import React, { useCallback, useEffect, useState } from 'react';
import { defineMessage } from '@lingui/macro';
import s from './index.module.scss';
import { PageContent, PageTitle } from '@components/page';
import { handleBackendError } from '@modules/notify';
import { PermissionGroup } from '@permissions/core';
import { PageTable } from 'components/page-table';
import { useI18n } from 'containers/services/commonService';
import api, { permissionGroup as apiPermissionGroup } from './api';
import { sortMetalTradeInfos } from './utils';
import { MetalTradeInfo } from './types';
import { useColumns, OnSort } from './use-columns';
import { InvestorsHelper } from './components/investors-helper';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup],
  marker: 'layout:primary-market-list',
});

export function PrimaryMarket() {
  const { i18n } = useI18n();
  const [tableData, setTableData] = useState<MetalTradeInfo[] | null>(null);
  const [sortedTableData, setSortedTableData] = useState<MetalTradeInfo[] | null>(null);

  const onSort = useCallback<OnSort>(
    (field, direction) => {
      if (direction === 'ascending' || direction === 'descending') {
        const sortedData = sortMetalTradeInfos(tableData, field, direction);
        setSortedTableData(sortedData);
        return;
      }
      setSortedTableData(null);
      setTableData([...tableData]);
    },
    [tableData]
  );

  const data = sortedTableData ?? tableData;
  const columns = useColumns(data ?? [], onSort);

  useEffect(() => {
    api
      .getMetalTradeInfos()
      .then((metalTradeInfos) => {
        setTableData(metalTradeInfos);
      })
      .catch((err) => {
        handleBackendError(err);
      });
  }, []);

  const pageTableData = {
    data: data ?? [],
    hasMore: false,
  };

  return (
    <PageContent uat="primary-market-list-page">
      <PageTitle
        className={s.title}
        title={i18n._(defineMessage({ id: 'router.primary_market', message: 'Primary Market' }))}
      />

      <InvestorsHelper />

      <PageTable
        className={s.table}
        columns={columns}
        onLoadMore={() => {}}
        pageData={pageTableData}
        trackHover
        uat="metals-table"
      />
    </PageContent>
  );
}
