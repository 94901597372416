import { createElement, FC } from 'react';
import { useMemoOne } from 'use-memo-one';
import {
  areFieldsEqual,
  HeaderProps as BoundHeaderProps,
  HeaderCellWrapperProps as BoundHeaderCellWrapperPros,
  BaseItem,
} from '../../../bound-cells';

export { HeaderCellWrapperBase, HeaderCellWrapper } from './default-cell';

type SortDirection = 'ascend' | 'descend' | null;

export type HeaderCellProps = {
  availableSortDirections: SortDirection[];
  currentSortDirection: SortDirection;
  onSortClick: (direction: SortDirection) => void;
  sortOrderNumber: number;
};

export type HeaderCellWrapperConfig = {
  sortDirections?: NonNullable<SortDirection>[];
};

export type HeaderCellWrapperProps = BoundHeaderCellWrapperPros<HeaderCellWrapperConfig>;

const PassThrough: FC = ({ children }) => <>{children}</>;

export type HeaderProps<T extends BaseItem> = BoundHeaderProps<T, HeaderCellProps>;

export function Header<T extends BaseItem>(props: HeaderProps<T>) {
  const columnsData = useMemoOne(() => {
    return props.columns.map((c) => {
      const normalizedSortDirections = Array.isArray(c.sortDirections)
        ? c.sortDirections
        : [c.sortDirections].filter(Boolean);

      const uniqueSortDirections = [null, ...Array.from(new Set(normalizedSortDirections))];

      const currentSortItem = props.sorter.order.find(
        (sortItem) => sortItem?.key === c.key || areFieldsEqual(c.dataIndex, sortItem.field)
      );
      const currentSortDirection = currentSortItem?.direction ?? null;

      const nextSortDirectionIndex = normalizedSortDirections.indexOf(currentSortDirection) + 1;

      const nextDirection =
        normalizedSortDirections[nextSortDirectionIndex] ?? uniqueSortDirections[0];

      const sortOrderNumber = props.sorter.order.findIndex(
        (sortItem) => sortItem?.key === c.key || areFieldsEqual(c.dataIndex, sortItem.field)
      );

      return {
        availableSortDirections: uniqueSortDirections,
        currentSortDirection,
        onSortClick: () => {
          props.sorter.mergeOrderItem({
            field: c.dataIndex,
            key: c.key,
            direction: nextDirection,
          });
        },
        sortOrderNumber: sortOrderNumber === -1 ? null : sortOrderNumber + 1,
      };
    });
  }, [props.columns, props.sorter.order, props.sorter.mergeOrderItem]);

  const HeaderCellWrapper = props.headerCellWrapperComponent ?? PassThrough;
  const HeaderWrapper = props.headerWrapperComponent ?? PassThrough;

  return (
    <HeaderWrapper>
      {props.columns.map((c, index) => (
        <HeaderCellWrapper
          key={index}
          {...columnsData[index]}
          width={props.columnWidthList[index]}
          config={props.columns[index].headerCellConfig}
        >
          {createElement(c.headerCellComponent, columnsData[index])}
        </HeaderCellWrapper>
      ))}
    </HeaderWrapper>
  );
}
