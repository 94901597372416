import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { usePopper } from 'react-popper';
import { debounce } from 'lodash';
import { useSyncRef } from '@hooks/core.strict';
import { RowCellBox } from './cell-box';
import { themeContainerId } from '../../../../../bootstrap/theme';
import s from '../index.module.scss';

import type { RowCellRendererProps } from '../types';

export function RowCellRenderer(props: RowCellRendererProps) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);

  const containerRef = useSyncRef(referenceElement);
  const tooltipContentRef = useRef<HTMLSpanElement>(null);

  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const isTooltipVisibleRef = useSyncRef(isTooltipVisible);

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
    modifiers: [
      {
        name: 'preventOverflow',
        options: {
          altBoundary: true,
          padding: 10,
        },
      },
      {
        name: 'flip',
        options: {
          altBoundary: true,
          fallbackPlacements: ['bottom'],
          padding: 10,
        },
      },
      {
        name: 'arrow',
        options: {
          element: arrowElement,
          padding: 4,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  });

  const onVisibleChange = useCallbackOne(
    (isVisible) => {
      if (!isVisible) {
        setIsTooltipVisible(false);
        return;
      }
      if (props.tooltip === true) {
        setIsTooltipVisible(isVisible);
      }
      if (!containerRef.current || !tooltipContentRef.current) return;

      const visibleOnOverflow = props.tooltip === 'overflow';
      const container = containerRef.current;
      const content = tooltipContentRef.current;
      const overflow = container.offsetWidth <= content.offsetWidth;

      if (visibleOnOverflow && overflow) {
        setIsTooltipVisible(true);
      }
    },
    [props.tooltip]
  );

  const debouncedOnVisibleChange = useCallbackOne(
    debounce(onVisibleChange, 100, { leading: false, trailing: true }),
    [onVisibleChange]
  );

  const onMouseEnter = useCallbackOne(() => {
    debouncedOnVisibleChange(true);
  }, [debouncedOnVisibleChange]);

  const onMouseLeave = useCallbackOne(() => {
    debouncedOnVisibleChange(false);
  }, [debouncedOnVisibleChange]);

  const onContentClick = useCallbackOne(() => {
    debouncedOnVisibleChange.cancel();
    onVisibleChange(!isTooltipVisibleRef.current);
  }, [debouncedOnVisibleChange, onVisibleChange]);

  const tooltipRoot = useMemoOne(() => {
    return document.getElementById(themeContainerId);
  }, []);

  useEffect(() => {
    if (!tooltipContentRef.current) return;

    const scrollable = tooltipContentRef.current.closest(`.${s.scrollable}`);
    const unsetVisible = () => {
      debouncedOnVisibleChange.cancel();
      onVisibleChange(false);
    };
    // @ts-ignore: Pass ignoreDebounce option so that table internal
    // addEventListener wrapper can avoid debouncing this handler.
    scrollable?.addEventListener('scroll', unsetVisible, { ignoreDebounce: true });
    return () => {
      scrollable?.removeEventListener('scroll', unsetVisible);
    };
  }, [onVisibleChange, debouncedOnVisibleChange]);

  const children = props.tooltip ? (
    <>
      <span
        ref={tooltipContentRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onContentClick}
      >
        {props.children}
      </span>
      {isTooltipVisible && tooltipRoot
        ? createPortal(
            <div
              ref={setPopperElement}
              style={styles.popper}
              className={s.tooltip}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              {...attributes.popper}
            >
              <div ref={setArrowElement} style={styles.arrow} className={s.tooltipArrow}>
                <div className={s.tooltipArrowOuter} />
                <div className={s.tooltipArrowInner} />
              </div>
              {props.children}
            </div>,
            tooltipRoot
          )
        : null}
    </>
  ) : (
    props.children
  );

  return (
    <RowCellBox
      containerRef={setReferenceElement}
      alignment={props.alignment}
      ellipsis={props.ellipsis}
      ignorePadding={props.ignorePadding}
      width={props.width}
    >
      {children}
    </RowCellBox>
  );
}
