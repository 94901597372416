import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';
import { useI18n } from '@hooks/i18n';

export const useSettlementStatuses = () => {
  const { i18n, lang } = useI18n();

  return useMemo(() => {
    return {
      PENDING: i18n._(defineMessage({ id: 'settlement_status.pending', message: 'Pending' })),
      SUCCESS: i18n._(defineMessage({ id: 'settlement_status.success', message: 'Success' })),
      ERROR: i18n._(defineMessage({ id: 'settlement_status.error', message: 'Error' })),
    };
  }, [lang]);
};
