import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Fields } from './model';
import { Permission } from '@permissions/core';
import { Redemption } from '../../api';

export interface Form {
  [Fields.reason]: string;
}

const denyRedeemQuery = 'burnRedeem';
const denyRedeemFields = [
  'id',
  'createdAt',
  'status',
  'optionsQuantity',
  { coin: ['id', 'asset'] },
  'optionName',
  'optionAmount',
  { optionUnit: ['label', 'inGrams'] },
  'rem',
  'priceType',
  'pricePerOption',
  { user: ['id', 'displayName'] },
];
const denyRedeem = makeQuery({
  permissions: Permission.REDEEM_DENY,
  queryName: denyRedeemQuery,
  queryFields: denyRedeemFields,
  query: (redeemId: number, reason: string): Promise<Redemption> => {
    return stompClient.sendData(denyRedeemQuery, sign(denyRedeemQuery, denyRedeemFields), {
      redeemId,
      reason,
    });
  },
});

const api = {
  denyRedeem,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:deny-redeem-modal');

export default api;
