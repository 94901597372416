import React, { ComponentType, FC } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { Permission, PermissionGroup, Role } from '@permissions/core';
import { UserInfo } from '@config/core';
import { Any } from '@models/core';
import { useAuthorizedGuardModel } from './model';

export const AuthorizedGuard: FC<{
  exact?: boolean;
  extraCheckFn?: (user: UserInfo) => boolean;
  forcedAccess?: boolean;
  layout: ComponentType<Any>;
  page: ComponentType<Any>;
  path: string;
  permissions?: Array<Permission | PermissionGroup>;
  roles?: Array<Role>;
  stretchVertically?: boolean;
}> = ({
  extraCheckFn,
  forcedAccess,
  layout: Layout,
  page: Page,
  path,
  permissions,
  roles,
  stretchVertically,
  ...rest
}) => {
  const { isHasAccess, redirectTo } = useAuthorizedGuardModel(
    forcedAccess,
    permissions,
    roles,
    null,
    extraCheckFn
  );

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isHasAccess ? (
          <Layout stretchVertically={stretchVertically}>
            <Page {...matchProps} />
          </Layout>
        ) : (
          <Redirect to={redirectTo} />
        )
      }
    />
  );
};
