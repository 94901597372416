import { LiveUpdateEventType } from '@services/stomp/live-update';

export default (
  state = {
    receivedUser: null,
    receivedUserBalances: {},
  },
  action
) => {
  switch (action.type) {
    case LiveUpdateEventType.balanceChanged: {
      return { ...state, account: { ...state.account, balance: action.balance } };
    }

    default:
      return state;
  }
};
