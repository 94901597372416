import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { Listener } from 'history';

import { handleBackendError } from '@modules/notify';
import { showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { permissionsSelectors } from '@permissions/store/selectors';
import { configSelectors } from '@config/store/selectors';

import { SecondaryMarketDisabledModal } from '../../../../../containers/pages/wallet/modals/secondary-market-disabled';
import api from './api';

export const useModel = () => {
  const history = useHistory();
  const [currentPath, setCurrentPath] = useState(history.location.pathname);
  const [isMenuCollapsed, setMenuCollapsed] = useState(false);
  const [isRequestNodeLoading, setRequestNodeLoading] = useState(false);

  const isHasAccess = useSelector(permissionsSelectors.isHasAccess);
  const isSMDisabled = useSelector(configSelectors.isSecondaryMarketDisabled);
  const permissions = useSelector(configSelectors.permissions);
  const role = useSelector(configSelectors.userRole);
  const showModal = useCallDispatch(showModalAction);

  useEffect(() => {
    // TODO-2833: fix types
    // @ts-ignore
    const historyUnsubscribe = history.listen((({ pathname }) => {
      setCurrentPath(pathname);
      document.querySelectorAll('.tm2-navigation-item').forEach((element) => {
        const elementPath = element.getAttribute('data-path');
        if (elementPath !== pathname) {
          // to remove duplicated active items bug when navigate to profile page from header menu
          setTimeout(() => element.classList.remove('ant-menu-item-selected'), 50);
        }
      });
    }) as Listener);
    return () => historyUnsubscribe();
  }, []); // eslint-disable-line

  const onSecondaryMarketMenuClicked = async () => {
    try {
      if (isSMDisabled) {
        showModal({ modal: SecondaryMarketDisabledModal });
      } else {
        const smWindow = window.open();
        await api.secondaryMarketLogin();
        smWindow.location = '/secondary-market';
      }
    } catch (error) {
      handleBackendError(error);
    }
  };

  const onNodeDownloadClicked = async () => {
    try {
      if (isRequestNodeLoading) {
        return;
      }

      setRequestNodeLoading(true);
      const url = await api.getNodeLink();
      window.open(url, '_blank');
    } catch (error) {
      handleBackendError(error);
    }
    setRequestNodeLoading(false);
  };

  return {
    currentPath,
    isHasAccess,
    isMenuCollapsed,
    isSMDisabled,
    onNodeDownloadClicked,
    onSecondaryMarketMenuClicked,
    permissions,
    role,
    setMenuCollapsed,
  };
};
