import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import t from 'components/page-table/index.module.scss';
import { useCallDispatch } from '@hooks/core';
import { PermissionGroup } from '@permissions/permission-group';
import { ProfileLinkCell } from '@components/table2/utils';
import { PageTable } from 'components/page-table';
import { formatToken } from '../../../../services/commonService';
import { profileOtherPagePermissionsGroup } from '../../../profile/other/permissions';
import { ColumnIds } from '../store/model';
import { actions, selectors } from '../store';
import { FeeReportItem } from '../api';

export const tableIdPermissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:management-fee-report-page-table-id-cell',
});

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [tableIdPermissionGroup],
  marker: 'layout:management-fee-report-page-table',
});

export const ManagementFeeReportTable: FC<{}> = memo(() => {
  const columnSortDirection = useSelector(selectors.columnSortDirection);
  const isHasMore = useSelector(selectors.isHasMore);
  const lockUI = useSelector(selectors.lockUI);
  const tableData = useSelector(selectors.tableData);

  const onTableScrolled = useCallDispatch(actions.onTableScrolled);
  const onTableSorted = useCallDispatch(actions.onTableSorted);

  return (
    <PageTable
      className={s.table}
      headClasses={t.alignLeft}
      onLoadMore={() => setTimeout(onTableScrolled)} // our table has double request bug on start
      isLoadingInProgress={lockUI}
      isShowLoader={true}
      initialLoad={true}
      pageData={{
        data: tableData,
        hasMore: isHasMore,
      }}
      columns={[
        {
          head: {
            body: <Trans id="statements.management_fee.table.date">Date</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.date, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.date),
          },
          cell: (item: FeeReportItem) => item.date,
        },
        {
          head: {
            body: <Trans id="statements.management_fee.table.client_id">Client ID</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.clientId, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.clientId),
          },
          cell: (item: FeeReportItem) => item.clientId,
        },
        {
          head: {
            body: <Trans id="statements.management_fee.table.client_name">Client name</Trans>,
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.clientName, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.clientName),
          },
          cell: (item: FeeReportItem) => (
            <ProfileLinkCell text={item.clientName} id={item.clientId} />
          ),
        },
        {
          head: {
            body: <Trans id="statements.management_fee.table.asset">Asset</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.asset, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.asset),
          },
          cell: (item: FeeReportItem) => item.asset,
        },
        {
          head: {
            body: (
              <Trans id="statements.management_fee.table.amount_primary">Amount on primary</Trans>
            ),
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.amountPrimary, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.amountPrimary),
          },
          cell: (item: FeeReportItem) => formatToken(item.amountPrimary),
        },
        {
          head: {
            body: (
              <Trans id="statements.management_fee.table.amount_secondary">
                Amount on secondary
              </Trans>
            ),
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.amountSecondary, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.amountSecondary),
          },
          cell: (item: FeeReportItem) => formatToken(item.amountSecondary),
        },
      ]}
    />
  );
});
