import { useLayoutEffect, useRef, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

export function useSyncRef<T>(param: T) {
  const syncRef = useRef<T>(param);
  syncRef.current = param;
  return syncRef;
}

function useAttached() {
  const attached = useRef(true);
  useLayoutEffect(
    () => () => {
      attached.current = false;
    },
    []
  );
  return attached;
}

export function useAttachedState<T>(initialState: T | (() => T)): [T, Dispatch<SetStateAction<T>>] {
  const attached = useAttached();
  const [state, setter] = useState<T>(initialState);
  const attachedSetter = useRef<typeof setter>((val) => {
    if (attached.current) {
      setter(val);
    }
  });
  return [state, attachedSetter.current];
}
