import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { CorrectionTransaction } from './add-correction-transaction-modal/api';

enum CTFields {
  startDate = 'startDate',
  endDate = 'endDate',
  recipientId = 'recipientId',
  senderId = 'senderId',
}

export type CTFilters = {
  [CTFields.startDate]?: string;
  [CTFields.endDate]?: string;
  [CTFields.recipientId]?: number;
  [CTFields.senderId]?: number;
};

export enum Fields {
  date = 'date',
  recipientId = 'recipientId',
  senderId = 'senderId',
}

export interface CorrectionTransactionsPage {
  data: Array<CorrectionTransaction>;
  hasMore: boolean;
  index: number;
}
export const getCorrectionTransactionsPageQuery = 'correctionTransactionsPage';
export const getCorrectionTransactionsPageFields = [
  {
    data: [
      'id',
      'createdAt',
      { recipient: ['id', 'displayName', 'permissions'] },
      { sender: ['id', 'displayName', 'permissions'] },
      'amount',
      'status',
      { currency: ['id', 'code'] },
    ],
  },
  'index',
  'hasMore',
];
const getCorrectionTransactionsPage = makeQuery({
  queryName: getCorrectionTransactionsPageQuery,
  queryFields: getCorrectionTransactionsPageFields,
  query: ({
    page,
    filter,
  }: {
    page: number;
    filter: CTFilters;
  }): Promise<CorrectionTransactionsPage> => {
    const { startDate, endDate, recipientId, senderId } = filter;

    const typedValues = {
      ...{ page },
      ...{ startDate },
      ...{ endDate },
      ...{ recipientId },
      ...{ senderId },
    };

    return stompClient.getData(
      getCorrectionTransactionsPageQuery,
      sign(getCorrectionTransactionsPageQuery, getCorrectionTransactionsPageFields),
      typedValues
    );
  },
});

const api = {
  getCorrectionTransactionsPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:correction-transactions-page');

export default api;
