import React, { FC, memo, ReactElement } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { ButtonPrimary } from '@components/button';
import { HasAccess } from '@permissions/core';
import { VerificationStatus as S } from '@models/core';
import { permissionsGroup as apiPermissionsGroup } from '../fragment/api';
import { useModel } from './model';

export const permissionsGroup = apiPermissionsGroup.createSuperGroup({
  marker: 'layout:verify-modal-button',
});

type Props = {
  className?: string;
  fullWidth?: boolean;
  isClosePreviousModal?: boolean;
  icon?: ReactElement<HTMLOrSVGElement>;
  visibility: 'all' | 'desktop' | 'mobile';
  uat?: string;
};
export const VerifyModalButton: FC<Props> = memo<Props>((p) => {
  const m = useModel(p.isClosePreviousModal);

  return (
    <HasAccess permissions={{ and: [permissionsGroup] }} extraCheck={m.isShowButton}>
      <ButtonPrimary
        className={classnames(p.className, s.button, {
          [s.all]: p.visibility === 'all',
          [s.desktop]: p.visibility === 'desktop',
          [s.mobile]: p.visibility === 'mobile',
        })}
        fullWidth={p.fullWidth}
        onClick={m.onButtonClicked}
        icon={p.icon}
        disabled={m.disabled}
        loading={null}
        uat={p.uat}
      >
        {m.status === S.resubmissionRequested ? (
          <Trans id="shared.verification.button.resubmit">Resubmit</Trans>
        ) : (
          <Trans id="shared.verification.button.verify">Verify</Trans>
        )}
      </ButtonPrimary>
    </HasAccess>
  );
});
