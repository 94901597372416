import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { SubmitButton } from '@components/form';
import { onCreditCardFormAction } from '../credit-card/store/effects';
import { AddFundsType as AddType } from '../store/model';
import { useModel } from './model';

export const AddFundsSubmitButton: FC = () => {
  const m = useModel();

  return (
    <SubmitButton
      name={m.addType}
      fullWidth={true}
      disabled={m.lockUI || !m.isSubmitButtonEnabled}
      onAction={
        m.addType === AddType.creditCard ? onCreditCardFormAction : m.onWireTransferFormAction
      }
    >
      {m.addType === AddType.creditCard ? (
        <Trans id="deposit_modal.button.continue">Continue</Trans>
      ) : (
        <Trans id="deposit_modal.button.create_request">Create deposit request</Trans>
      )}
    </SubmitButton>
  );
};
