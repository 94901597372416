import sign from 'tm2sign.macro';
import _ from 'lodash';
import { map, Observable } from 'rxjs';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService, stompClientService as stompClient } from '@services/stomp/client';

const offerPriceFields = [
  'id',
  { currency: ['code'] },
  {
    offer: [
      'availableQuantity',
      { coin: ['asset'] },
      'date',
      'id',
      { quantityUnit: ['inGrams'] },
      'sellCommission',
      'issuerTax',
    ],
  },
  'quantityPerGram',
];

class MetalsApiService {
  public buyOffer(offerId: number, currencyId, quantity: number): Promise<OfferBought> {
    const fields = sign('confirmOffer', [{ offerPrice: offerPriceFields }, 'quantity']);
    return stompClient.sendData('confirmOffer', fields, {
      ...v.long({ offerId }),
      ...v.long({ currencyId }),
      ...v.bigDecimal({ quantity }),
    });
  }

  public getOfferAvailableQuantity(offerId: number): Promise<number> {
    const fields = sign('offerById', ['availableQuantity']);
    return stompClient
      .getData('offerById', fields, {
        ...v.long({ id: offerId }),
      })
      .then((response) => response.availableQuantity);
  }

  public orderPricesChanges$(coinId: number, currencyId: number): Observable<Array<OfferPrice>> {
    const fields = sign('onActiveOffersPricesChanged', offerPriceFields);
    return stompClientService
      .subscription$('onActiveOffersPricesChanged', fields, {
        ...v.long({ coinId }),
        ...v.long({ currencyId }),
      })
      .pipe(
        // @ts-ignore: TODO-2833: fix types
        map((prices) => _.orderBy(prices, 'quantityPerGram'))
      );
  }

  public priceChanges$(offerId: number, currencyId: number): Observable<OfferPrice> {
    const fields = sign('onOfferPriceChanged', offerPriceFields);
    return stompClientService.subscription$('onOfferPriceChanged', fields, {
      ...v.long({ currencyId }),
      ...v.long({ offerId }),
    });
  }

  public tradeHistory$(coinId: number, currencyId: number): Observable<Array<TradeHistoryItem>> {
    const fields = sign('onTradeHistoryChanged', [
      'dateUpdate',
      'id',
      { offerPrice: ['quantityPerGram'] },
      'quantity',
      'status',
    ]);
    return stompClientService
      .subscription$('onTradeHistoryChanged', fields, {
        ...v.long({ coinId }),
        ...v.long({ currencyId }),
      })
      .pipe(
        // TODO-2833: fix types
        // @ts-ignore
        map((history) => _.orderBy(history, 'dateUpdate', 'desc'))
      );
  }
}

export const metalsApiService = new MetalsApiService();

export interface OfferPrice {
  id: number;
  currency: {
    code: string;
  };
  offer: {
    availableQuantity: number;
    coin: {
      asset: string;
    };
    date: string;
    id: number;
    quantityUnit: {
      inGrams: number;
    };
    sellCommission: number;
    issuerTax: number;
  };
  quantityPerGram: number;
}

export interface OfferBought {
  offerPrice: OfferPrice;
  quantity: number;
}

export interface TickerItem {
  coin: {
    asset: string;
    id: number; // need for order book component
    managementFee: number; // need for general info => management fee percent
  };
  currency: {
    code: string;
    id: number;
  };
  metal: string;
}

export interface MyStableBalance {
  asset: string;
  balance: number;
  currency: {
    // need for order book component
    code: string;
  };
}

export interface TradeHistoryItem {
  dateUpdate: string;
  id: number;
  offerPrice: {
    quantityPerGram: number;
  };
  quantity: number;
  status: string;
}
