import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { TableResponse } from '@models/core';
import { SortOrder } from '@models/sorting';

// -------------------------------------------------------------------------------------
export enum Column {
  changeBalance = 'changeBalance',
  clientId = 'user.id',
  clientName = 'user.displayName',
  createdAt = 'createdAt',
  currency = 'currency.asset',
  newBalance = 'newBalance',
  previousBalance = 'previousBalance',
  txId = 'txId',
}

export type OverdraftItem = {
  [Column.changeBalance]: number;
  [Column.createdAt]: string;
  currency: { asset: string };
  [Column.newBalance]: number;
  [Column.previousBalance]: number;
  [Column.txId]: string;
  user: { displayName: string; id: number };
};

const getOverdraftReportQuery = 'overdraftPage';
const getOverdraftReportFields = [
  {
    data: [
      'changeBalance',
      'createdAt',
      { currency: ['asset'] },
      'newBalance',
      'previousBalance',
      'txId',
      { user: ['id', 'displayName'] },
    ],
  },
  'index',
  'hasMore',
];
export const getOverdraftReport = makeQuery({
  permissions: Permission.FEE_VIEW_OVERDRAFT_REPORT,
  queryName: getOverdraftReportQuery,
  queryFields: getOverdraftReportFields,
  query: (
    page: number,
    ordering: SortOrder<Column>,
    rsql: string
  ): Promise<TableResponse<OverdraftItem>> => {
    const filter = rsql ? rsql : undefined;
    const typedValues = {
      ...v.string({ filter }),
      ...v.orderInput({ ordering }),
      ...v.int({ page }),
    };

    return stompClient.getData(
      getOverdraftReportQuery,
      sign(getOverdraftReportQuery, getOverdraftReportFields),
      typedValues
    );
  },
});
const api = {
  getOverdraftReport,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:overdraft-report');

export default api;
