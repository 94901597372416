import axios from 'axios';
import sign from 'tm2sign.macro';
import { Observable } from 'rxjs';

import { UserInfo } from '@config/core';
import { Lang } from '@models/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { session } from '@services/session';
import { systemLiveBannerLayoutCN, systemLiveBannerLayoutEN } from './banner.templates';
// ----------------------------------------------------------------------------------------
const changeLanguage = (language: Lang): Promise<void> => {
  const languageFields = sign('changeLanguage', ['value']);
  const typedValues = {
    lang: language,
  };

  return stompClient.sendData('changeLanguage', languageFields, typedValues);
};
// ----------------------------------------------------------------------------------------
const getSystemPreferences = (): Promise<SystemPreferences> => {
  const systemPreferencesFields = sign('systemPreferences', ['systemLive']);

  return stompClient.getData('systemPreferences', systemPreferencesFields).then((preferences) => ({
    banner: {
      image: '/img/banners/system-live-banner.svg',
      isShow: !preferences?.systemLive,
      view: {
        CN: systemLiveBannerLayoutCN,
        EN: systemLiveBannerLayoutEN,
      },
    },
  }));
};
// ----------------------------------------------------------------------------------------
const getUserInfo = (): Promise<UserInfo> => {
  const userProfileFields = sign('userProfile', [
    // TODO перебрать поля + сделать запрос в пост реге
    'accountingFormSend',
    'accountOpeningStep',
    'address', // need for HubSpot form
    { blockedCoins: ['id'] },
    'birthday',
    'company',
    {
      country: [
        'label', // need for HubSpot form
        'id',
      ],
    },
    'displayName',
    'email', // need for HubSpot form
    'firstName', // need for HubSpot form
    'id',
    { investorIssuer: ['email'] },
    'isShowOnboardHelp',
    'isShowCustomerSurvey',
    { issuerInvestor: ['email', 'stablePublicKey', 'id'] },
    'lang',
    'lastName', // need for HubSpot form
    'lastPostRegStage',
    { nationality: ['label', 'id'] },
    'permissions',
    'phone',
    { kyc: ['state'] },
    { role: ['name'] },
    'umsId',
    { userTariff: [{ tariff: ['permissions'] }] },
    'verificationStatus',
  ]);

  return stompClient.getData('userProfile', userProfileFields).then((userInfo: UserInfo) => {
    session.saveUser(userInfo);
    return userInfo;
  });
};
// ----------------------------------------------------------------------------------------
const refreshAccessToken = (): Promise<string> => {
  const formData = new FormData();
  formData.append('refresh_token', session.getRefreshToken());
  return axios({
    method: 'post',
    url: '/sso/refresh',
    data: formData,
  }).then((response) => {
    const { access_token, refresh_token } = response.data;
    session.saveAccessToken(access_token);
    session.saveRefreshToken(refresh_token);
    return access_token;
  });
};
// ----------------------------------------------------------------------------------------
type EventMapBase = Record<string, any>;
type ExtractEvent<E extends EventMapBase> = {
  [T in keyof E]: [E[T]] extends [never] ? { type: T } : { type: T; data: E[T] };
}[keyof E];

export type WebEvent = ExtractEvent<{
  logout: string; // session id
}>;
const sendWebEventMutation = 'sendWebEvent';
const sendWebEventFields = ['value'];
const sendWebEvent = (event: WebEvent): Promise<void> => {
  return stompClient.sendData(
    sendWebEventMutation,
    sign(sendWebEventMutation, sendWebEventFields),
    v.webEventInput({ event })
  );
};
// ----------------------------------------------------------------------------------------
const webEvent$Subscription = 'onWebEvent';
const webEvent$Fields = ['data', 'type'];
const webEvent$ = (eventTypes: Array<WebEvent['type']>): Observable<WebEvent> => {
  return stompClient.subscription$<WebEvent>(
    webEvent$Subscription,
    sign(webEvent$Subscription, webEvent$Fields),
    v.stringArray({ eventTypes })
  );
};
// ----------------------------------------------------------------------------------------
const configApi = {
  changeLanguage,
  getSystemPreferences,
  getUserInfo,
  refreshAccessToken,
  sendWebEvent,
  webEvent$,
};

export default configApi;

export interface SystemPreferences {
  banner: {
    image: string;
    isShow: boolean;
    view: {
      CN: string;
      EN: string;
    };
  };
}
