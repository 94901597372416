import React, { FC, useContext } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';
import { ReactComponent as CloseIcon } from '@images/icons/cross-icon.svg';
import { PreloaderTrack } from '@components/preloader';
import {
  DefaultDataType,
  DefaultHeaderWrapper,
  DefaultTabHeader,
  HeaderContentFit,
  HeaderWrapperProps,
  TabHeaderProps,
  Tabs,
  TabsHeaderData,
} from '@components/tabs/regular';
import { ModalContext, modalContext } from '../modal';

const TabHeaderWrapper = (props: HeaderWrapperProps) => {
  return (
    <DefaultHeaderWrapper
      {...props} // magic webstorm error but all works fine and type is correct
      className={s.tabHeaderWrapper}
    />
  );
};

const TabHeader = (props: TabHeaderProps) => {
  return <DefaultTabHeader {...props} className={classnames(s.tabHeaderItem)} />;
};

export const ModalTitleTabs: FC<{
  activeKey: string;
  className?: string;
  data: TabsHeaderData<DefaultDataType>;
  forceRender?: boolean;
  headerContentFit: HeaderContentFit;
  onChange: (tabKey: string) => void;
}> = (p) => {
  const { disabled, loading, onClose } = useContext<ModalContext>(modalContext);

  return (
    <div className={classnames(s.title, p.className)}>
      {!loading ? null : <PreloaderTrack />}

      <div className={s.content}>
        <Tabs
          activeKey={p.activeKey}
          headerContentFit={p.headerContentFit}
          onChange={p.onChange}
          border={false}
          forceRender={p.forceRender}
          tabHeaderComponent={TabHeader}
          headerWrapperComponent={TabHeaderWrapper}
          data={p.data}
        />
      </div>

      <div
        className={classnames(s.close, !!disabled && 'is-disabled')}
        onClick={onClose}
        data-uat="close-modal-button"
      >
        <CloseIcon className={s.closeSvg} />
      </div>
    </div>
  );
};
