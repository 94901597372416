import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from '../index.module.scss';
import { HasAccess, traderPermissions } from '@permissions/core';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { Profile } from '../../../../api.shared';

const usersWithSMAPermissions = { or: traderPermissions };

export const LinkedSecondaryMarketAccount: FC<{
  classname: string;
  profile: Profile;
}> = memo(({ classname, profile }) => {
  const umsId = profile.umsId;

  return (
    <HasAccess permissions={usersWithSMAPermissions} userPermissions={profile.permissions}>
      <div className={classnames(s.row, classname)}>
        <div>
          <Title level={4} color="secondary">
            <Trans id="profile.account_details.linked_sma">Linked secondary market account</Trans>
          </Title>

          {umsId ? (
            <Text uat="linked-sma-label">{umsId}</Text>
          ) : (
            <Text uat="linked-sma-label">
              <Trans id="profile.account_details.sma_not_set">Not set</Trans>
            </Text>
          )}
        </div>
      </div>
    </HasAccess>
  );
});
