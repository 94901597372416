import {
  BankAccount,
  ExternalClientBankAccount,
  FundsInvestor,
} from '../../../deposits-api.service';
import { AddFundsModalActions } from '../../store/model';
import { WireTransferViewServiceActionsTypes } from './service.acions';
import { WireTransferViewUserActionsTypes } from './user.acions';

export enum WireTransferFormFields {
  amount = 'amount',
  bankAccountId = 'bankAccountId',
  externalBankAccountBalanceId = 'externalBankAccountBalanceId',
  clientComment = 'clientComment',
  investorId = 'investorId',
}

export interface WireTransferForm {
  [WireTransferFormFields.amount]: number;
  [WireTransferFormFields.bankAccountId]: number;
  [WireTransferFormFields.externalBankAccountBalanceId]: string | null;
  [WireTransferFormFields.clientComment]: string;
  [WireTransferFormFields.investorId]?: number;
}

export interface WireTransferViewState {
  bankAccounts: Array<BankAccount>;
  selectedCurrencyId: number | null;
  externalClientBankAccounts: Array<ExternalClientBankAccount>;
  investors: Array<FundsInvestor>;
  isGenerateDepositLoading: boolean;
  isGetExternalClientBankAccountsLoading: boolean;
  isGetInvestorsLoading: boolean;
}

export const defaultExternalBankAccount: ExternalClientBankAccount = {
  externalId: null,
  computedFields: { displayName: 'Manual' },
};

export const wireTransferViewStateInitial: WireTransferViewState = {
  bankAccounts: [],
  selectedCurrencyId: null,
  externalClientBankAccounts: [defaultExternalBankAccount],
  investors: [],
  isGenerateDepositLoading: false,
  isGetExternalClientBankAccountsLoading: false,
  isGetInvestorsLoading: false,
};

export enum WireTransferViewActionsTypes {
  GENERATE_DEPOSIT = 'WIRE_TRANSFER_VIEW.GENERATE_DEPOSIT',
  GENERATE_DEPOSIT_FAIL = 'WIRE_TRANSFER_VIEW.GENERATE_DEPOSIT_FAIL',
  GENERATE_DEPOSIT_SUCCESS = 'WIRE_TRANSFER_VIEW.GENERATE_DEPOSIT_SUCCESS',
  GET_EXTERNAL_CLIENT_BANK_ACCOUNTS = 'WIRE_TRANSFER_VIEW.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS',
  GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_FAIL = 'WIRE_TRANSFER_VIEW.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_FAIL',
  GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_SUCCESS = 'WIRE_TRANSFER_VIEW.GET_EXTERNAL_CLIENT_BANK_ACCOUNTS_SUCCESS',
  GET_INVESTORS = 'WIRE_TRANSFER_VIEW.GET_INVESTORS',
  GET_INVESTORS_FAIL = 'WIRE_TRANSFER_VIEW.GET_INVESTORS_FAIL',
  GET_INVESTORS_SUCCESS = 'WIRE_TRANSFER_VIEW.GET_INVESTORS_SUCCESS',
  ON_BANK_ACCOUNT_SELECTED = 'WIRE_TRANSFER_VIEW.ON_BANK_ACCOUNT_SELECTED',
  ON_INVESTOR_SELECTED = 'WIRE_TRANSFER_VIEW.ON_INVESTOR_SELECTED',
  SET_BANK_ACCOUNTS = 'WIRE_TRANSFER_VIEW.SET_BANK_ACCOUNTS',
}

export type WireTransferViewActions =
  | WireTransferViewServiceActionsTypes
  | WireTransferViewUserActionsTypes
  | AddFundsModalActions;
