import { SortItem } from '@models/core';
import { ReduxAction } from '@models/redux';
import { MarketingTableActionTypes as T, MarketingTableColumnIds as ColumnIds } from './model';

class OnTableScrolledAction extends ReduxAction<T.ON_TABLE_SCROLLED, void> {
  public readonly type: T.ON_TABLE_SCROLLED = T.ON_TABLE_SCROLLED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnTableSortedAction extends ReduxAction<T.ON_TABLE_SORTED, SortItem<ColumnIds>> {
  public readonly type: T.ON_TABLE_SORTED = T.ON_TABLE_SORTED;
  public constructor(public payload: SortItem<ColumnIds>) {
    super(payload);
  }
}

class OnPageLeftAction extends ReduxAction<T.ON_PAGE_LEFT, void> {
  public readonly type: T.ON_PAGE_LEFT = T.ON_PAGE_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const marketingCompaniesUserActions = {
  onTableScrolled: () => new OnTableScrolledAction().get(),
  onTableSorted: (columnInfo: SortItem<ColumnIds>) => new OnTableSortedAction(columnInfo).get(),
  onPageLeft: () => new OnPageLeftAction().get(),
};

export type MarketingCompaniesUserActionsTypes =
  | OnTableScrolledAction
  | OnTableSortedAction
  | OnPageLeftAction;

export declare namespace MarketingCompaniesUserActions {
  type OnTableScrolled = OnTableScrolledAction;
  type OnTableSorted = OnTableSortedAction;
  type OnPageLeft = OnPageLeftAction;
}
