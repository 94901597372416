import { ComponentType, Context as ReactContext } from 'react';
import { useMemoOne } from 'use-memo-one';
import {
  Table as OriginalTable,
  BaseItem,
  BaseTableProps as OriginalBaseTableProps,
  TableProps as OriginalTableProps,
} from '../core';
import { ColumnsContext, ColumnsContextValue } from './columns';
import { HeaderContext, WrappedHeader, HeaderContextValue, HeaderProps } from './header';
import { RowContext, WrappedRow, RowContextValue, RowProps } from './row';
import {
  ColumnType,
  DefaultRowCellProps,
  HeaderCellWrapperProps,
  HeaderWrapperProps,
  RowCellWrapperProps,
  RowWrapperProps,
} from './types';

export { useTable, areFieldsEqual, areOrdersEqual, sortItems } from '../core';

export type { HeaderProps } from './header';
export type { RowProps } from './row';
export type {
  ColumnType,
  DefaultRowCellProps,
  HeaderCellConfig,
  HeaderCellWrapperProps,
  HeaderWrapperProps,
  RowCellConfig,
  RowCellWrapperProps,
  RowWrapperProps,
} from './types';

// We want to have the following:
// I. Provide a way to set column cell components along with other column data.

// II. Provide a way for different rowComponent and headerComponent to set
// specific row and header cell types. This will allow for describing each
// rowComponent and headerComponent along with a variance of cells, or providing
// an interface for specific cell implementation, which makes rowComponent
// and headerComponent implementations reusable.

export type {
  // General
  BaseItem,
  ErrorElement,
  EstimateRowHeight,
  HasMore,
  KeyExtractor,
  ListKey,
  LoaderElement,
  OnLoadMore,
  TableComponents,
  // Sorter
  InMemorySorter,
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterParams,
  TableSorterReturnType,
} from '../core';

export type BaseTableProps<
  T extends BaseItem,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = Omit<OriginalBaseTableProps<T>, 'columns'> & {
  columns: Array<ColumnType<T, H, R>>;
};

export type TableProps<
  T extends BaseItem,
  K,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = Omit<
  OriginalTableProps<T, K>,
  | 'baseTableProps'
  | 'className'
  | 'headerCellWrapperComponent'
  | 'headerComponent'
  | 'headerWrapperComponent'
  | 'rowCellWrapperComponent'
  | 'rowComponent'
  | 'rowWrapperComponent'
  | 'totalYOffset'
> & {
  baseTableProps: BaseTableProps<T, H, R>;
  headerCellWrapperComponent?: ComponentType<HeaderCellWrapperProps<H>>;
  headerComponent: ComponentType<HeaderProps<T, H>>;
  headerWrapperComponent?: ComponentType<HeaderWrapperProps>;
  rowCellWrapperComponent?: ComponentType<RowCellWrapperProps<T, R>>;
  rowComponent: ComponentType<RowProps<T, R>>;
  rowWrapperComponent?: ComponentType<RowWrapperProps<T>>;

  // TODO: override cell config for header and row.
};

export function Table<
  T extends BaseItem,
  K,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
>({
  baseTableProps,
  headerCellWrapperComponent,
  headerComponent,
  headerWrapperComponent,
  rowCellWrapperComponent,
  rowComponent,
  rowWrapperComponent,
  ...other
}: TableProps<T, K, H, R>) {
  type ColumnsContextValueType = ColumnsContextValue<T, H, R>;
  type HeaderContextValueType = HeaderContextValue<T, H>;
  type RowContextValueType = RowContextValue<T, R>;

  const columnsContextValue = useMemoOne<ColumnsContextValueType>(
    () => ({
      columns: baseTableProps.columns,
    }),
    [baseTableProps.columns]
  );

  const ColumnsContextProvider = (
    ColumnsContext as unknown as ReactContext<ColumnsContextValueType>
  ).Provider;

  const headerContextValue = useMemoOne<HeaderContextValueType>(
    () => ({
      headerCellWrapperComponent,
      headerComponent,
      headerWrapperComponent,
    }),
    [headerCellWrapperComponent, headerComponent, headerWrapperComponent]
  );

  const HeaderContextProvider = (HeaderContext as unknown as ReactContext<HeaderContextValueType>)
    .Provider;

  const rowContextValue = useMemoOne<RowContextValueType>(
    () => ({
      rowCellWrapperComponent,
      rowComponent,
      rowWrapperComponent,
    }),
    [rowCellWrapperComponent, rowComponent, rowWrapperComponent]
  );

  const RowContextProvider = (RowContext as unknown as ReactContext<RowContextValueType>).Provider;

  return (
    <ColumnsContextProvider value={columnsContextValue}>
      <HeaderContextProvider value={headerContextValue}>
        <RowContextProvider value={rowContextValue}>
          <OriginalTable
            {...other}
            baseTableProps={baseTableProps}
            headerComponent={WrappedHeader}
            rowComponent={WrappedRow}
          />
        </RowContextProvider>
      </HeaderContextProvider>
    </ColumnsContextProvider>
  );
}
