import { UserItem } from '../../users-api.service';
import { UsersPageActionsTypes as Types } from './model';

// TODO типизация всех экшенов

type EventUsersUpdatedPayload = {
  isNewUser?: boolean;
  isNoScroll?: boolean;
  user: UserItem; // TODO UserTableItem ???
};
// TODO можно обновлять юзера глобально в сторе, а не каждый раз писать вызов к usersApi в каждом action item
const EventUsersUpdatedAction = (config: EventUsersUpdatedPayload) => ({
  payload: config,
  type: Types.EVENT_USERS_UPDATED,
});

const LoadMoreUsersAction = (config) => ({
  payload: config,
  type: Types.LOAD_MORE_USERS,
});

const LoadMoreUsersFailAction = (error) => ({
  payload: error,
  type: Types.LOAD_MORE_USERS_FAIL,
});

const LoadMoreUsersSuccessAction = (usersInfo) => ({
  payload: usersInfo,
  type: Types.LOAD_MORE_USERS_SUCCESS,
});

export const usersPageServiceActions = {
  eventUsersUpdated: EventUsersUpdatedAction,
  loadMoreUsers: LoadMoreUsersAction,
  loadMoreUsersFail: LoadMoreUsersFailAction,
  loadMoreUsersSuccess: LoadMoreUsersSuccessAction,
};
