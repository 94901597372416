import { createContext, useMemo, useReducer, FC } from 'react';

import { initialState, reducer, Action, State } from './reducer';

export type ContextValue = {
  state: State;
  dispatch: (action: Action) => void;
};

export const Context = createContext<ContextValue>({
  state: initialState,
  dispatch: () => {},
});

export const Provider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const contextValue = useMemo<ContextValue>(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
