import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';

import { closeModalAction, showNotifyModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { roundDown } from '@helper/number';
import { formService } from '@components/form';

import {
  useBankAccounts,
  useCurrenciesOther,
  useStableBalances,
  useUserDisplayName,
} from './hooks';
import api from './api';

import type { MFC } from '@modules/modal';
import type { OnFormAction } from '@components/form';
import type { FormInstance } from 'antd';
import type { Withdrawal } from '@router/pages/withdrawals/api';
import type { BankAccount } from './api';

export const formId = 'createWithdrawalOtherFormId';

export enum Fields {
  amount = 'amount',
  userBankAccount = 'userBankAccount.id',
  currency = 'currency.id',
  userId = 'userId',
}
interface Form {
  amount: number;
  userBankAccount?: { id: number };
  currency: { id: number };
  userId?: number;
}

type AddWithdrawal = (withdrawal: Withdrawal) => void;
export const useModel = (addWithdrawal: AddWithdrawal, modal: MFC) => {
  // TODO: Задача https://tm2support.atlassian.net/browse/TM2-3070
  //  Алгоритм. Кейс 1:
  //  1. Юзер заходит в Wallet -> Withdrawal
  //  2. Открывается src/containers/pages/wallet/modals/no-bank-details/index.tsx
  //  Алгоритм. Кейс 2:
  //  1. Юзер заходит в Wallet -> Withdrawal
  //  2. API запрос ProofOfAddress, boolean const isProofOfAddressExpired (POA < 6 months ago, false)
  //  3. API запрос Currencies -> filter для USD, EUR, GBP -> мы имеем data для Select Currency
  //  4. API запрос BankDetailsList -> мы имеем data для Select Bank Details
  //  5. Юзер выбирает Currency -> если currency !== currency from bank details, то под currency select отображается надпись из ТЗ
  //  6. Юзер пишет сумму в поле Amount -> считается Wire withdrawal fee
  //  7. Юзер выбирает Bank Details -> отображаются поля Currency, Bank name, Account number итд -> под полем Amount max & min withdrawal amount (currency from bank details || currency select)
  //  8. Юзер нажимает кнопку Create Withdrawal Request, форма отправляется в GQL Mutate API
  //  Алгоритм. Кейс 3:
  //  1. Юзер заходит в Wallet -> Withdrawal
  //  2. API запрос ProofOfAddress, boolean const isProofOfAddressExpired (POA > 6 months ago, true)
  //  3. API запрос Currencies -> filter для USD, EUR, GBP -> мы имеем data для Select Currency
  //  4. API запрос BankDetailsList -> мы имеем data для Select Bank Details
  //  5. Юзер выбирает Currency -> если currency !== currency from bank details, то под currency select отображается надпись из ТЗ
  //  6. Юзер пишет сумму в поле Amount -> считается Wire withdrawal fee
  //  7. Юзер выбирает Bank Details -> отображаются поля Currency, Bank name, Account number итд -> под полем Amount max & min withdrawal amount (currency from bank details || currency select)
  //  8. Юзер (опционально) загружает файл (jpeg, png, pdf, doc, jpg) с Proof Of Address -> этот файл попадает в форму
  //  9. Юзер нажимает кнопку Create Withdrawal Request, форма отправляется в GQL Mutate API

  // wire withdrawal fee
  const [withdrawalFee, setWithdrawalFee] = useState(0);
  const calculateFee = useCallback((amount: number) => {
    // aerapass fee
    const formula = 50 + (amount / 100) * 1.5;
    const calculation = +(Math.round(formula * 100) / 100).toFixed(2);
    return !!amount ? calculation : 0;
  }, []);
  const onAmountChanged = useCallback((amount) => {
    const fee = calculateFee(amount);
    setWithdrawalFee(fee);
  }, []);

  // client id input
  const [selectedUserId, setSelectedUserId] = useState<number>();
  const onSelectedUserIdChanged = useCallback(
    (id: number) => setSelectedUserId(id),
    [selectedUserId]
  );

  // full name
  const { userDisplayName, isUserDisplayNameLoading } = useUserDisplayName(selectedUserId);

  // bank details select
  const { bankAccountsRaw, bankAccounts, isBankAccountsLoading } = useBankAccounts(selectedUserId);
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>();
  const [minWithdrawalAmount, setMinWithdrawalAmount] = useState(50);
  const onBankAccountChanged = useCallback(
    (id: number) => {
      const bankAccount = bankAccountsRaw?.find((ba) => ba.id === id);
      setSelectedBankAccount(bankAccount);
      const minAmount = bankAccount?.currency?.withdrawalRules?.minWithdrawalAmount;
      // @ts-ignore
      setMinWithdrawalAmount(minAmount);
    },
    [bankAccountsRaw, selectedBankAccount]
  );

  // currency select
  const { currencies, isCurrenciesLoading } = useCurrenciesOther(selectedUserId);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState<string>();
  const onCurrencyChanged = useCallback(
    (id: number) => {
      const form: FormInstance = formService.get(formId);
      const code = currencies?.find((c) => c.value === id)?.label;
      form.setFieldsValue({ [Fields.amount]: undefined });
      setSelectedCurrencyCode(code);
      setWithdrawalFee(0);
    },
    [currencies, selectedCurrencyCode]
  );

  // amount select
  const { stableBalances, isStableBalancesLoading } = useStableBalances(selectedUserId);
  const maxWithdrawalAmount: number = useMemo(() => {
    if (!stableBalances?.length || !selectedCurrencyCode) {
      return 0;
    }
    const stableCurrent = stableBalances.find((sb) => sb.currency?.code === selectedCurrencyCode);
    return roundDown(stableCurrent?.balance ?? 0);
  }, [selectedCurrencyCode, stableBalances]);

  // shared
  const closeModal = useCallDispatch(closeModalAction);
  const onCloseModalClicked = useCallback(() => closeModal(modal), []);
  const showNotifyModal = useCallDispatch(showNotifyModalAction);

  const isCurrenciesMatched = useMemo(
    () =>
      !selectedCurrencyCode ||
      !selectedBankAccount ||
      selectedCurrencyCode === selectedBankAccount?.currency.code,
    [selectedCurrencyCode, selectedBankAccount]
  );

  const [isCreateWithdrawalLoading, setCreateWithdrawalLoading] = useState(false);

  const lockUI =
    isBankAccountsLoading ||
    isCreateWithdrawalLoading ||
    isStableBalancesLoading ||
    isUserDisplayNameLoading ||
    isCurrenciesLoading;

  // form action
  const onFormAction: OnFormAction<Form> = {
    submit: async (form) => {
      const withdrawalForm: Form = {
        userBankAccount: form.userBankAccount,
        amount: form.amount,
        currency: form.currency,
        userId: form.userId,
      };
      setCreateWithdrawalLoading(true);
      const withdrawal = await api.createWithdrawal(withdrawalForm);
      addWithdrawal(withdrawal);
      closeModal(modal);
      showNotifyModal({
        description: getCreatedWithdrawalNotifyDescription(withdrawal.status),
        isClosePrevious: true,
        type: 'success',
        title: (
          <Trans id="withdraw_modals.add.your_withdrawal_request_v2">
            Withdrawal request successfully created
          </Trans>
        ),
      });
    },
    finally: () => setCreateWithdrawalLoading(false),
  };

  return {
    bankAccounts,
    currencies,
    isCurrenciesMatched,
    lockUI,
    maxWithdrawalAmount,
    minWithdrawalAmount,
    onAmountChanged,
    onBankAccountChanged,
    onCloseModalClicked,
    onCurrencyChanged,
    onFormAction,
    onSelectedUserIdChanged,
    selectedBankAccount,
    selectedCurrencyCode,
    selectedUserId,
    userDisplayName,
    withdrawalFee,
  };
};

const getCreatedWithdrawalNotifyDescription = (status: string) => {
  if (status === 'CHECKED_PENDING') {
    return (
      <Trans id="withdraw_modals.add.withdrawal_requests_submitted_v2">
        Withdrawal requests can take up to 2 business days to process.
      </Trans>
    );
  } else if (status === 'PENDING_PENDING') {
    return (
      <Trans id="withdraw_modals.add.once_approved_please">
        Once approved, please allow an additional 3 to 5 business days for the funds to show in your
        account.
      </Trans>
    );
  }
};
