import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';

type CqgProfile = {
  accountId: number | null;
  loginId: string | null;
  profileId: string | null;
};

export type GetUserInfoResponse = {
  computedFields: {
    participantCode: string;
  };
  cqgProfile: CqgProfile;
  umsId: string;
};

const getUserCqgInfoQuery = 'userById';
const getUserCqgInfoFields = [
  { computedFields: ['participantCode'] },
  { cqgProfile: ['accountId', 'loginId', 'profileId'] },
  'umsId',
];
const getUserInfo = makeQuery({
  permissions: Permission.PROFILE_VIEW_OTHER,
  queryName: getUserCqgInfoQuery,
  queryFields: getUserCqgInfoFields,
  query: (userId: number): Promise<GetUserInfoResponse> => {
    return stompClientService.getData<GetUserInfoResponse>(
      getUserCqgInfoQuery,
      sign(getUserCqgInfoQuery, getUserCqgInfoFields),
      v.long({ userId })
    );
  },
});

const api = {
  getUserInfo,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:profile-user-other-tab');

export default api;
