import { AddFundsModalUserActionsTypes } from './user.acions';
import { AddFundsModalServiceActionsTypes } from './service.acions';

export enum AddFundsType {
  creditCard = 'credit-card-form',
  wireTransfer = 'wire-transfer-form',
}

export interface AddFundsModalState {
  addType: AddFundsType;
  isGetBankAccountsLoading: boolean;
}

export const addFundsModalStateInitial: AddFundsModalState = {
  addType: null,
  isGetBankAccountsLoading: false,
};

export enum AddFundsModalActionsTypes {
  GET_BANK_ACCOUNTS = 'WIRE_TRANSFER_VIEW.GET_BANK_ACCOUNTS',
  GET_BANK_ACCOUNTS_FAIL = 'WIRE_TRANSFER_VIEW.GET_BANK_ACCOUNTS_FAIL',
  GET_BANK_ACCOUNTS_SUCCESS = 'WIRE_TRANSFER_VIEW.GET_BANK_ACCOUNTS_SUCCESS',
  ON_ADD_FUNDS_TYPE_SELECTED = 'WIRE_TRANSFER_VIEW.ON_ADD_FUNDS_TYPE_SELECTED',
  ON_CLOSE_MODAL_CLICKED = 'ADD_FUNDS_MODAL.ON_CLOSE_MODAL_CLICKED',
  ON_MODAL_LEFT = 'ADD_FUNDS_MODAL.ON_MODAL_LEFT',
  ON_MODAL_OPENED = 'ADD_FUNDS_MODAL.ON_MODAL_OPENED',
}

export type AddFundsModalActions = AddFundsModalServiceActionsTypes | AddFundsModalUserActionsTypes;
