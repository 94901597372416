import { useMemo } from 'react';

import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';

import api from './api';

export const useVaultManagers = () => {
  const { data: vaultManagersData, isLoading: isVaultManagersLoading } = useQuery(
    api.getVaultManagers,
    { initial: [] }
  );

  const vaultManagers: Array<SelectItem> = useMemo(() => {
    return vaultManagersData?.map((country) => ({
      label: country.displayName,
      value: country.id,
    }));
  }, [vaultManagersData]);

  return { vaultManagers, isVaultManagersLoading };
};

export const useInitialValues = (vaultId: number) => {
  const { data: initialValues, isLoading: isInitialValuesLoading } = useQuery(() =>
    vaultId ? api.getVaultById(vaultId) : Promise.resolve({})
  );

  return { initialValues, isInitialValuesLoading };
};
