import { FinishRedeemModalActionsTypes as Types } from './model';

const OnCloseModalClickedAction = () => ({
  payload: null,
  type: Types.ON_CLOSE_MODAL_CLICKED,
});

const OnCreateRedeemClickedAction = () => ({
  payload: null,
  type: Types.ON_CREATE_REDEEM_CLICKED,
});

const OnGetTransactionClickedAction = () => ({
  payload: null,
  type: Types.ON_GET_TRANSACTION_CLICKED,
});

export const finishRedeemModalUserActions = {
  onCloseModalClicked: OnCloseModalClickedAction,
  onCreateRedeemClicked: OnCreateRedeemClickedAction,
  onGetTransactionClicked: OnGetTransactionClickedAction,
};
