import BigIntPolyfill from 'bigint-polyfill';

export const BigInt: (base: string | number) => BigIntProxy = (base: string | number) =>
  new BigIntPolyfill(base);

/**
 * Model for bigint based on bigint-polyfill/index.js
 * NOTE: BigInt polyfill based on proxy. To get bigint value just use whole returned instance
 */
export interface BigIntProxy {
  add: (base: BigIntBase) => BigIntProxy;
  and: (base: BigIntBase) => BigIntProxy;
  divide: (base: BigIntBase) => BigIntProxy;
  eq: (base: BigIntBase) => boolean;
  geq: (base: BigIntBase) => boolean;
  gt: (base: BigIntBase) => boolean;
  leq: (base: BigIntBase) => boolean;
  lt: (base: BigIntBase) => boolean;
  multiply: (base: BigIntBase) => BigIntProxy;
  negate: (base: BigIntBase) => BigIntProxy;
  neq: (base: BigIntBase) => boolean;
  next: (base: BigIntBase) => BigIntProxy;
  not: (base: BigIntBase) => BigIntProxy;
  or: (base: BigIntBase) => BigIntProxy;
  pow: (base: BigIntBase) => BigIntProxy;
  prev: (base: BigIntBase) => BigIntProxy;
  remainder: (base: BigIntBase) => BigIntProxy;
  shiftLeft: (base: BigIntBase) => BigIntProxy;
  shiftRight: (base: BigIntBase) => BigIntProxy;
  subtract: (base: BigIntBase) => BigIntProxy;
  // value: bigint; note: do not use this value field, read more about proxy
  xor: (base: BigIntBase) => BigIntProxy;
}

type BigIntBase = string | number | BigIntProxy;
