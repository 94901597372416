import { useMemo, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { useCurrentRole } from '@config/hooks';
import { showModalAction } from '@modules/modal';
import { Role as R } from '@permissions/core';
import { SelectItem } from '@components/form';
import { useUsersForDisplayByRole } from 'containers/services/common/service';
import { useOffersCoins } from '../legacy-service';
import { useOfferStatusTrans } from '../shared/trans';
import { PlaceOfferModal } from '../modals/place-offer';

export const useOffersFilterModel = (onOfferPlaced, showUserFilter = false) => {
  const dispatch = useDispatch();
  const currentRole = useCurrentRole();
  const statusesTranslations = useOfferStatusTrans();
  const coinsResponse = useOffersCoins(showUserFilter ? 'ALL' : 'MY');

  // TODO: rework
  const showUserFilterRef = useRef(showUserFilter);
  const issuersResponse = showUserFilterRef.current ? useUsersForDisplayByRole(R.issuer) : null;

  const showPlaceOfferModal = () =>
    dispatch(
      showModalAction({
        modal: PlaceOfferModal,
        props: { onOfferCreated: onOfferPlaced },
      })
    );

  const statuses: Array<SelectItem> = useMemo(() => {
    return Object.keys(statusesTranslations).map((status) => ({
      label: statusesTranslations[status],
      value: status,
    }));
  }, [statusesTranslations]);

  const coins: Array<SelectItem> = useMemo(() => {
    return coinsResponse.map((coin) => ({
      label: `${coin.asset} (${coin.metal.label})`,
      value: coin.id,
    }));
  }, [coinsResponse]);

  const issuers: Array<SelectItem> = useMemo(() => {
    return issuersResponse === null
      ? []
      : issuersResponse
          .filter((issuer) => !!issuer?.company)
          .map((issuer) => ({ label: issuer.displayName, value: issuer.id }));
  }, [issuersResponse]);

  return {
    coins,
    currentRole,
    issuers,
    showPlaceOfferModal,
    statuses,
  };
};
