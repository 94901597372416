import { useState } from 'react';
import { useSelector } from 'react-redux';

import { useCallDispatch } from '@hooks/core';
import { handleBackendError } from '@modules/notify';
import { useShowHandleUserModal } from '../../../../profile/other/modals/handle-user-modal';
import { usersApiService as api } from '../../../users-api.service';
import { usersPageSelectors as selectors } from '../../store/selectors';
import { usersPageServiceActions as serviceActions } from '../../store/service.acions';

export const useModel = () => {
  const [isUserLoading, setUserLoading] = useState<boolean>(false);
  const lockUI = useSelector(selectors.lockUI) as boolean;
  const { showHandleUserModal } = useShowHandleUserModal(undefined);
  const emitUserUpdated = useCallDispatch(serviceActions.eventUsersUpdated) as (p: {
    isNewUser;
    user;
  }) => void;

  const onUserHandled = async (userId: number) => {
    setUserLoading(true);
    try {
      const user = await api.getUpdatedUserTableInfo(userId);
      emitUserUpdated({ isNewUser: true, user });
    } catch (error) {
      handleBackendError(error);
    }
    setUserLoading(false);
  };

  const onAddUserClicked = () => showHandleUserModal({ onUserHandled });

  return {
    disabled: lockUI || isUserLoading,
    onAddUserClicked,
  };
};
