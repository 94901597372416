import { ReactNode } from 'react';

import { ContextCreator, ExtractAction, ObjectValues } from '@helper/ts';
import { CaseMode, MatchMode } from '@modules/rsql';
import { EventsService } from '@services/events';

export type SearchField =
  | 'long'
  | 'float'
  | 'string'
  | 'dateRange'
  | 'computedString'
  | 'select'
  | 'enum';

export interface SearchItem {
  label: ReactNode;
  fields: Array<string>;
  id: string;
  searchFormField: string;
  type: SearchField;
}

export enum TextFields {
  caseMode = 'caseMode',
  customFields = 'customFields',
  fieldsConfig = 'fieldsConfig',
  matchMode = 'matchMode',
}

export const FieldsConfig = {
  allFields: 'allFields',
  customFields: 'customFields',
};

export type SearchValue = string | number | boolean | [string, string] | undefined;
export type SearchForm = { [k: string]: SearchValue } | undefined;

export type TextModeForm = {
  [TextFields.caseMode]: ObjectValues<typeof CaseMode>;
  [TextFields.customFields]: Array<string>;
  [TextFields.fieldsConfig]: ObjectValues<typeof FieldsConfig>;
  [TextFields.matchMode]: ObjectValues<typeof MatchMode>;
};

export const textModeInitial: TextModeForm = {
  [TextFields.caseMode]: CaseMode.insensitive,
  [TextFields.customFields]: [],
  [TextFields.fieldsConfig]: FieldsConfig.allFields,
  [TextFields.matchMode]: MatchMode.partial,
};
const searchFormInitial: SearchForm = undefined;

type SearchEvent = { type: 'clear' };

type State = {
  events: EventsService<SearchEvent>;
  searchFields: Array<SearchItem>;
  searchForm: SearchForm;
  textMode: TextModeForm;
};

const initialState: State = {
  events: new EventsService(),
  searchFields: [],
  searchForm: searchFormInitial,
  textMode: textModeInitial,
};

export type Action = ExtractAction<{
  ON_FORM_CLEAR: never;
  ON_FORM_CHANGE: State['searchForm'];
  ON_TEXT_MODE_CHANGE: State['textMode'];
  SET_SEARCH_FIELDS: State['searchFields'];
}>;

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ON_FORM_CLEAR': {
      return {
        ...state,
        searchForm: searchFormInitial,
        textMode: textModeInitial,
      };
    }

    case 'ON_FORM_CHANGE': {
      return {
        ...state,
        searchForm: action.payload,
      };
    }

    case 'ON_TEXT_MODE_CHANGE': {
      return {
        ...state,
        textMode: action.payload,
      };
    }

    case 'SET_SEARCH_FIELDS': {
      return {
        ...state,
        searchFields: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const { Context, Provider } = ContextCreator(initialState, reducer);
