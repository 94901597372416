import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import {
  addFundsStoreEffects,
  addFundsStoreReducer,
  AddFundsStoreState,
} from './add-funds-modal/add-funds.store';

export interface DepositsState {
  addFunds: AddFundsStoreState;
}

export const depositsReducer = combineReducers({
  addFunds: addFundsStoreReducer,
});

export function* depositsEffects() {
  yield all([fork(addFundsStoreEffects)]);
}
