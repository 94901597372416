import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleBackendError } from '@modules/notify';
import api from '../api';
import { sliceId } from './model';
import selectors from './selectors';

const getCurrencies = createAsyncThunk(sliceId('getCurrencies'), async (p: void, { getState }) => {
  try {
    let currencies = selectors.currencies(getState());
    if (!currencies.length) {
      currencies = await api.getCurrencies();
    }
    return currencies;
  } catch (error) {
    handleBackendError(error);
    throw error;
  }
});

export default {
  getCurrencies: getCurrencies,
};
