import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Permission } from '@permissions/core';
import { Redemption } from '../../api';

const burnRedeemQuery = 'burnRedeem';
const burnRedeemFields = [
  'id',
  'createdAt',
  'status',
  'optionsQuantity',
  { coin: ['id', 'asset'] },
  'optionName',
  'optionAmount',
  { optionUnit: ['label', 'inGrams'] },
  'rem',
  'priceType',
  'pricePerOption',
  { user: ['id', 'displayName'] },
];
const burnRedeem = makeQuery({
  permissions: Permission.REDEEM_BURN,
  queryName: burnRedeemQuery,
  queryFields: burnRedeemFields,
  query: (redeemId: number): Promise<Redemption> => {
    return stompClient.sendData(burnRedeemQuery, sign(burnRedeemQuery, burnRedeemFields), {
      redeemId,
    });
  },
});

const api = {
  burnRedeem,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:burn-redeem-modal');

export default api;
