import { useParams } from 'react-router';

import { useQuery } from '@hooks/core';
import api from './api';

export const useBankAccounts = () => {
  const { userId } = useParams<{ userId: string }>();
  const {
    data: bankAccounts,
    isLoading: isBankAccountsLoading,
    refresh: updateBankAccounts,
  } = useQuery(() => api.getBankAccounts(+userId), { initial: [] });

  return {
    isBankAccountsLoading,
    bankAccounts,
    updateBankAccounts,
  };
};
