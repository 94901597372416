import { useContext, useState } from 'react';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';

import { handleBackendError } from '@modules/notify';
import { Context } from '../../context';
import api from './api';

export const useModel = () => {
  const { state } = useContext(Context);

  const [lockUI, setLockUI] = useState<boolean>(false);

  const onDownloadClicked = async () => {
    try {
      setLockUI(true);
      const report: string = await api.bankAccountHistoryXlsx({
        ordering: state.ordering,
        filter: state.filters,
      });
      const blob = base64StringToBlob(report, 'application/xlsx');
      saveAs(blob, `bank_account_history_report.xlsx`);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return {
    lockUI,
    onDownloadClicked,
  };
};
