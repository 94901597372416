import { useSelector } from 'react-redux';

import { modalSelectors, showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import {
  HandleCMBankAccountModal,
  HandleCMBankAccountModalProps,
} from '../../modals/handle-bank-account';
import { useEmitBankAccountUpdated } from '../../hooks';

export const useModel = () => {
  const disabled = useSelector(modalSelectors.isModalOpened);
  const showModal = useCallDispatch(showModalAction);
  const onAccountUpdated = useEmitBankAccountUpdated();

  const onCreateAccountClicked = () => {
    const props: HandleCMBankAccountModalProps = { onAccountUpdated };
    showModal({ modal: HandleCMBankAccountModal, props });
  };

  return {
    disabled,
    onCreateAccountClicked,
  };
};
