import { ReduxAction } from '@models/redux';
import { BootstrapActionsTypes as T } from './model';

class OnAppOpenedAction extends ReduxAction<T.ON_APP_OPENED, void> {
  public readonly type: T.ON_APP_OPENED = T.ON_APP_OPENED;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const bootstrapUserActions = {
  onAppOpened: () => new OnAppOpenedAction().get(),
};

export type BootstrapUserActionsTypes = OnAppOpenedAction;

export declare namespace BootstrapUserActions {
  type onAppOpened = OnAppOpenedAction;
}
