import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

export const Icon = (props) => {
  const { icon, mask } = props;
  const iconClass = classnames('icon', `icon--${icon}`, props.className);
  const [ref, setRef] = useState(null);

  useEffect(() => {
    if (!mask) {
      return;
    }

    if (!ref) {
      return;
    }

    const style = getComputedStyle(ref);

    if (style.backgroundImage !== 'none') {
      ref.style['-webkit-mask-image'] = style.backgroundImage;
    }

    ref.style['background'] = mask.color;
    ref.style['-webkit-mask-size'] = 'cover';
  }, [ref]);

  const propsWithRef = mask ? { ref: setRef } : {};

  return <i className={iconClass} {...propsWithRef} />;
};
