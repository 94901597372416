import { createSelector } from 'reselect';
import { I18n } from '@lingui/core';
import { CompatClient } from '@stomp/stompjs';

import { IsHasAccess, Permission as P, permissionsSelectors, Role } from '@permissions/core';
import { accountService as account } from '@services/account';
import { RootState as RS } from '@models/redux';
import { History, Lang, VerificationStatus } from '@models/core';
import { bootstrapSelectors } from '../../../bootstrap/store/selectors';
import { SystemPreferences } from '../config-api.service';
import {
  AccountType,
  BannerConfig,
  BlockedCoin,
  ConfigState,
  InvestorIssuer,
  IssuerInvestor,
  KycState,
  OnboardingStep,
  PostRegSteps,
  UserInfo,
} from './model';

const s = (r: RS): ConfigState => r.shared?.config;

const accountType = (r: RS): AccountType => account.getType(permissions(r));

// TODO-2833: fix types
// @ts-ignore
const bannerConfig: (r: RS) => BannerConfig = createSelector(
  [
    (r) => permissions(r),
    permissionsSelectors.isHasAccess,
    (r) => lang(r),
    (r) => s(r)?.bannerConfig,
  ],
  (p: string, isHasAccessFn: IsHasAccess, language: Lang, config: SystemPreferences['banner']) => ({
    isShow: config?.isShow && isHasAccessFn({ or: [P.PROFILE_VIEW_BANNER] }),
    image: config?.image,
    view: language === Lang.cn ? config?.view?.CN : config?.view?.EN,
  })
);

const _blockedCoins = [];
const blockedCoins = (r: RS): Array<BlockedCoin> => userInfo(r)?.blockedCoins || _blockedCoins;

const history = (r: RS): History => s(r)?.history;

const i18n = (r: RS): I18n => s(r).i18n;

const investorIssuer = (r: RS): InvestorIssuer => userInfo(r)?.investorIssuer;

const isAdmin = (r: RS): boolean => userRole(r) === Role.admin;

const isAppConfigured = (r: RS): boolean => s(r).isAppConfigured;

const isAuthorized = (r: RS): boolean => bootstrapSelectors.isUserAuthorized(r);

// TODO-2833: fix types
// @ts-ignore
const isInvestor: (r: RS) => boolean = createSelector(
  [(r) => permissions(r)],
  (_permissions: string) => account.isTrader(_permissions)
);

// TODO-2833: fix types
// @ts-ignore
const isIndividualTrader: (r: RS) => boolean = createSelector(
  [(r) => permissions(r)],
  (_permissions: string) => account.isIndividualTrader(_permissions)
);

// TODO-2833: fix types
// @ts-ignore
const isCorporateTrader: (r: RS) => boolean = createSelector(
  [(r) => permissions(r)],
  (_permissions: string) => account.isCorporateTrader(_permissions)
);

const isOnboardingFinished = (r: RS): boolean =>
  s(r)?.userInfo?.accountOpeningStep === OnboardingStep.finished;

const isPostRegInProgress = (r: RS): boolean => s(r).isPostRegInProgress;

const isRegistrationFinished = (r: RS): boolean => {
  // Indicates that the user was registered out of the app.
  if (!isInvestor(r)) {
    return true;
  }

  // Indicates that the user is not on some of the post registration screens.
  // If they are, there can be local steps that should be shown even though
  // the user reached final step on backend.
  const isUserAwayFromPostReg = !isPostRegInProgress(r);

  // Indicates that the user reached final step of their registration process
  // on backend.
  const isUserReachedFinalStep =
    lastPostRegStage(r) === PostRegSteps.finish ||
    onboardingStep(r) !== OnboardingStep.hubspotForm ||
    kycState(r) === KycState.approved;

  return isUserAwayFromPostReg && isUserReachedFinalStep;
};

const isSecondaryMarketDisabled = (r: RS): boolean => {
  const user: UserInfo = userInfo(r);
  return !user?.umsId;
};

const isShowGlobalPreloader = (r: RS): boolean => {
  const isForcedShow: boolean = s(r).isShowGlobalPreloader;
  return isForcedShow || (isAppConfigured(r) && isAuthorized(r) && !isWsConnected(r));
};

const isShowTutorialModal = (r: RS): boolean => userInfo(r).isShowOnboardHelp;

const isUserInfoLoading = (r: RS): boolean => s(r).isUserInfoLoading;

const isVerificationFinished: (r: RS) => boolean = createSelector(
  [(r: RS) => isIndividualTrader(r), (r: RS) => kycState(r), (r: RS) => verificationStatus(r)],
  (isIndividual, corporateStatus, individualStatus) => {
    if (!isIndividual) {
      return corporateStatus === KycState.approved;
    }
    return individualStatus === VerificationStatus.approved;
  }
);

const isWsConnected = (r: RS): boolean => s(r).isWSConnected;

const kycState = (r: RS): KycState => userInfo(r)?.kyc?.state;

const lang = (r: RS): Lang => s(r)?.lang;

const lastPostRegStage = (r: RS): PostRegSteps => userInfo(r)?.lastPostRegStage;

const onboardingStep = (r: RS): OnboardingStep =>
  userInfo(r)?.accountOpeningStep || OnboardingStep.finished;

const permissions = (r: RS): string => userInfo(r)?.permissions || '0';

const role = (r: RS): Role => userInfo(r)?.role?.name;

const _perms = [];
const tariffPermissions = (r: RS): Array<string> =>
  userInfo(r)?.userTariff?.tariff?.permissions || _perms;

const userDisplayName = (r: RS): string => userInfo(r)?.displayName;

const userId = (r: RS): number => userInfo(r)?.id;

const userInfo = (r: RS): UserInfo => s(r)?.userInfo;

const userIssuerInvestor = (r: RS): IssuerInvestor => userInfo(r)?.issuerInvestor;

const userRole = (r: RS): Role => userInfo(r)?.role?.name;

const verificationStatus = (r: RS): VerificationStatus => userInfo(r)?.verificationStatus;

const wsInstance = (r: RS): CompatClient => s(r).wsInstance;

export const configSelectors = {
  accountType,
  bannerConfig,
  blockedCoins,
  history,
  i18n,
  investorIssuer,
  isAdmin,
  isAppConfigured,
  isAuthorized,
  isRegistrationFinished,
  lastPostRegStage,
  isInvestor,
  isIndividualTrader,
  isCorporateTrader,
  isSecondaryMarketDisabled,
  isShowGlobalPreloader,
  isShowTutorialModal,
  isOnboardingFinished,
  isUserInfoLoading,
  isVerificationFinished,
  isWsConnected,
  kycState,
  lang,
  onboardingStep,
  permissions,
  role,
  tariffPermissions,
  userDisplayName,
  userId,
  userInfo,
  userIssuerInvestor,
  userRole,
  verificationStatus,
  wsInstance,
};

export const deprecatedSelectors = {
  // TODO все эти селекторы желательно выпилить из проекта постепенно
  form: (r) => r.form,
};
