import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';

import { PageContent, PageTitle } from '@components/page';
import { TablePane } from '@components/table2/utils';
import { Table } from '@components/table2/simplified';
import { PermissionGroup } from '@permissions/permission-group';

import { ActionsRow, permissionGroup as actionsPermissionGroup } from './actions-row';
import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as actionsCellPermissionGroup } from './actions-cell';
import { rowConfig, useModel } from './model';
import { useColumns } from './columns';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, actionsPermissionGroup, actionsCellPermissionGroup],
  marker: 'layout:redemptions-page',
});

export const RedemptionsPage: FC = memo(() => {
  const m = useModel();
  const c = useColumns(m.onReplaceRedemption);

  return (
    <PageContent className={s.verticalFiller} uat="redemptions-page">
      <PageTitle
        title={m.i18n._(defineMessage({ id: 'router.redemption', message: 'Redemption' }))}
      />

      <ActionsRow onFiltersChanged={m.onFiltersChanged} />

      <TablePane className={s.verticalFiller}>
        <Table
          columns={c.columns}
          instance={m.tableInstance}
          rowConfig={rowConfig}
          className={s.verticalFiller}
        />
      </TablePane>
    </PageContent>
  );
});
