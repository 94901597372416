import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ActionItem } from '@components/more-actions';
import { UserTableItem } from '../../../users-api.service';
import { usersPageSelectors } from '../../store/selectors';
import { useVerificationActions } from './verification/hook';
import { useAccessActions } from './access/hook';
import { useEditActions } from './edit/hook';

export const useModel = (user: UserTableItem) => {
  const access = useAccessActions(user);
  const edit = useEditActions(user);
  const verification = useVerificationActions(user);

  const lockGlobal: boolean = useSelector(usersPageSelectors.lockUI);
  const lockUI = lockGlobal || access.lockUI || edit.lockUI || verification.lockUI;

  const actions: Array<ActionItem> = useMemo(
    () => [
      edit.editUserAction,
      verification.approveAction,
      verification.resubmitAction,
      verification.rejectAction,
      access.blockAction,
      access.unblockAction,
      edit.editTariffAction,
      edit.addSMTAAction,
    ],
    [
      edit.editUserAction,
      verification.approveAction,
      verification.resubmitAction,
      verification.rejectAction,
      access.blockAction,
      access.unblockAction,
      edit.editTariffAction,
      edit.addSMTAAction,
    ]
  );

  return {
    actions,
    lockUI,
  };
};
