import { SliceId } from '@helper/redux';
import { StableBalance } from '../api';

export const sliceId = SliceId('main.send_stable_to_investor');

export const formId = 'sendStableToInvestor';

export enum Fields {
  amount = 'amount',
  asset = 'asset',
}

export interface Form {
  [Fields.amount]: number;
  [Fields.asset]: string;
}

export interface State {
  form: Form;
  isGetStableBalancesLoading: boolean;
  isSendStableLoading: boolean;
  stableBalances: Array<StableBalance>;
}

export const stateInitial: State = {
  form: {} as Form,
  isGetStableBalancesLoading: false,
  isSendStableLoading: false,
  stableBalances: [],
};
