import { ReactNode } from 'react';
import { createSelector } from 'reselect';
import { Trans } from '@lingui/macro';

import { RootState, Selector } from '@models/redux';
import { SelectItem } from '@components/form';
import { TransferDirection as Direction } from '../../model';
import { AssetItem, TransferCoinTabState } from './model';

const s: Selector<TransferCoinTabState> = (r: RootState) =>
  r.main?.wallet?.marketTransferModal?.coinTab;

const assetInitial: Selector<string> = createSelector(
  [(r: RootState) => sourceBalances(r)],
  (balances) => balances.filter((item: SelectItem) => !item.disabled)[0]?.value as string
);

const assetSelected: Selector<string> = (r: RootState) => s(r)?.assetSelected;

const coinBalancesPrimary: Selector<Array<AssetItem>> = (r: RootState) => s(r).coinBalancesPrimary;

const coinBalancesSecondary: Selector<Array<AssetItem>> = (r: RootState) =>
  s(r).coinBalancesSecondary;

const isNoTransferCoins: Selector<boolean> = (r: RootState) =>
  isTabInitiated(r) && !sourceBalances(r).length;

const isTabInitiated: Selector<boolean> = (r: RootState) => s(r).isTabInitiated;

const lockUI: Selector<boolean> = (r: RootState) =>
  s(r).isFinishCoinTransactionLoading || s(r).isTransferCoinInfoLoading || s(r).isCoinsInfoLoading;

const primaryBalance: Selector<number> = createSelector(
  [
    (r: RootState) => coinBalancesPrimary(r),
    (r: RootState) => assetSelected(r),
    (r: RootState) => assetInitial(r),
  ],
  (coinBalancesPrimary, assetSelected, assetInitial) =>
    coinBalancesPrimary.filter(
      (el) => el.asset === (assetSelected ? assetSelected : assetInitial)
    )[0]?.balance
);

const secondaryBalance: Selector<number> = createSelector(
  [
    (r: RootState) => coinBalancesSecondary(r),
    (r: RootState) => assetSelected(r),
    (r: RootState) => assetInitial(r),
  ],
  (coinBalancesSecondary, assetSelected, assetInitial) =>
    coinBalancesSecondary.filter(
      (el) => el.asset === (assetSelected ? assetSelected : assetInitial)
    )[0]?.balance
);

const sourceBalances: Selector<Array<SelectItem>> = createSelector(
  [
    (r: RootState) => transferDirection(r),
    (r: RootState) => s(r).coinsSecondaryAvailable,
    (r: RootState) => coinBalancesPrimary(r),
    (r: RootState) => coinBalancesSecondary(r),
  ],
  (
    direction: Direction,
    coinsSecondaryAvailable: Array<string>,
    balancesPrimary: Array<AssetItem>,
    balancesSecondary: Array<AssetItem>
  ) => {
    const balancesSource =
      direction === Direction.fromPrimary ? balancesPrimary : balancesSecondary;
    const noTrustText: ReactNode = (
      <Trans id="transfer_coin_modal.tooltip.no_trust">
        The transfer can not be completed, please contact customer support
      </Trans>
    );
    const notAvailableCoinText: ReactNode = (
      <Trans id="transfer_coin_modal.tooltip.other_market_not_present">
        This asset cannot be transferred to the secondary market
      </Trans>
    );
    const isCoinNotAvailable = (item: AssetItem) => {
      if (direction === Direction.fromSecondary) {
        return false;
      }
      return !coinsSecondaryAvailable.includes(item.asset);
    };

    return balancesSource
      .filter((item: AssetItem) => item.balance)
      .map((item: AssetItem) => ({
        disabled: !item.haveSecondaryTrust || isCoinNotAvailable(item),
        label: item.asset,
        tooltip: !item.haveSecondaryTrust
          ? noTrustText
          : isCoinNotAvailable(item)
          ? notAvailableCoinText
          : null,
        value: item.asset,
      }))
      .sort((a: SelectItem, b: SelectItem) => a.label.localeCompare(b.label));
  }
);

const transferDirection: Selector<Direction> = (r: RootState) => s(r).transferDirection;

export const transferCoinTabSelectors = {
  assetInitial,
  assetSelected,
  coinBalancesPrimary,
  coinBalancesSecondary,
  isNoTransferCoins,
  isTabInitiated,
  lockUI,
  primaryBalance,
  secondaryBalance,
  sourceBalances,
  state: s,
  transferDirection,
};
