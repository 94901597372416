import { createAsyncThunk } from '@reduxjs/toolkit';

import { SortItem } from '@models/core';
import { handleBackendError } from '@modules/notify';
import api from '../api';
import selectors from './selectors';
import { ColumnIds, sliceId } from './model';

const loadMoreTableData = createAsyncThunk(
  sliceId('loadMoreTableData'),
  async (p: void, { getState }) => {
    try {
      const root = getState();
      const pageIndex: number = selectors.pageIndex(root);
      const sort: Array<SortItem<ColumnIds>> = selectors.sort(root);
      return await api.getManagementFeeReport(pageIndex, sort);
    } catch (error) {
      handleBackendError(error);
      throw error;
    }
  }
);

const onTableScrolled = createAsyncThunk(
  sliceId('onTableScrolled'),
  async (p: void, { dispatch, getState }) => {
    const isLoading = selectors.isPaymentsLoading(getState());
    return !isLoading && dispatch(loadMoreTableData()); // our table has double request bug on start
  }
);

const onTableSorted = createAsyncThunk(
  sliceId('onTableSorted'),
  async (p: SortItem<ColumnIds>, { dispatch }) => dispatch(loadMoreTableData())
);

export default {
  loadMoreTableData,
  onTableScrolled,
  onTableSorted,
};
