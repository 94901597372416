import React from 'react';
import { useFileUploaderModel } from './model';

export const FileUploader = ({ button: UploaderButton, accept, onProgress, onFileUploaded }) => {
  const { fileField, setFileField, onFileSelected, isProgress } = useFileUploaderModel(
    onProgress,
    onFileUploaded
  );

  return (
    <div>
      {<UploaderButton onClick={() => fileField.click()} />}
      <input
        type="file"
        disabled={isProgress}
        className={'hidden'}
        onChange={onFileSelected}
        accept={accept}
        ref={setFileField}
      />
    </div>
  );
};
