export const redeemConfirmationModalStateInitial = {
  fiatBalance: 0,
  isStableBalanceLoading: false,
  metalQuantity: undefined,
  optionsQuantity: undefined,
  stableBalance: [],
  storedData: {
    confirmedRedeemData: null,
  },
};

export const RedeemConfirmationModalActionsTypes = {
  GET_STABLE_BALANCE: 'REDEEM_CONFIRMATION_MODAL.GET_STABLE_BALANCE',
  GET_STABLE_BALANCE_FAIL: 'REDEEM_CONFIRMATION_MODAL.GET_STABLE_BALANCE_FAIL',
  GET_STABLE_BALANCE_SUCCESS: 'REDEEM_CONFIRMATION_MODAL.GET_STABLE_BALANCE_SUCCESS',
  SET_CONFIRMED_REDEEM_DATA: 'REDEEM_CONFIRMATION_MODAL.SET_CONFIRMED_REDEEM_DATA',
  ON_CLOSE_MODAL_CLICKED: 'REDEEM_CONFIRMATION_MODAL.ON_CLOSE_MODAL_CLICKED',
  ON_CONFIRM_REDEEM_CLICKED: 'REDEEM_CONFIRMATION_MODAL.ON_CONFIRM_REDEEM_CLICKED',
  ON_METAL_QUANTITY_CHANGED: 'REDEEM_CONFIRMATION_MODAL.ON_METAL_QUANTITY_CHANGED',
  ON_OPTIONS_QUANTITY_CHANGED: 'REDEEM_CONFIRMATION_MODAL.ON_OPTIONS_QUANTITY_CHANGED',
  ON_MODAL_LEFT: 'REDEEM_CONFIRMATION_MODAL.ON_MODAL_LEFT',
  ON_MODAL_OPENED: 'REDEEM_CONFIRMATION_MODAL.ON_MODAL_OPENED',
};
