import { useMemoOne } from 'use-memo-one';
import classnames from 'classnames';
import { Table as CoreTable } from '../core';
import { Header, HeadCellRenderer } from './header';
import { Row, RowRenderer, RowCellRenderer } from './row';
import s from './index.module.scss';

import type { ComponentType } from 'react';
import type {
  TableProps as CoreTableProps,
  HeaderProps as CoreTableHeaderProps,
  RowProps as CoreTableRowProps,
  BaseItem,
} from '../core';
import type { ColumnType, ColumnConfig, RowConfig } from './types';

// NOTE: columns, columnConfig, and rowConfig should be memoized.

export { HeadCellBox, HeadCellRenderer } from './header';
export type { HeadCellBoxProps } from './header';

export { RowCellBox, RowCellRenderer, RowBox, RowRenderer } from './row';
export type { RowCellBoxProps, RowBoxProps } from './row';

export * from './types';

export type {
  BaseItem,
  EstimateRowHeight,
  HeaderProps,
  RowProps,
  KeyExtractor,
  ListKey,
  OnLoadMore,
  ScrollToIndex,
  InMemorySorter,
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterParams,
  TableSorterReturnType,
  UseTableReturnType,
} from '../core';

export { useTable, areFieldsEqual, areOrdersEqual } from '../core';

export {
  HEAD_CELL_PADDING_TOP,
  HEAD_CELL_PADDING_BOTTOM,
  ROW_CELL_PADDING_TOP,
  ROW_CELL_PADDING_BOTTOM,
} from './constants';

type TablePropsBase<T extends BaseItem> = {
  columns: ColumnType<T>[];
  columnConfig?: ColumnConfig;
  rowConfig?: RowConfig<T>;
};

export type TableProps<T extends BaseItem, K> = TablePropsBase<T> &
  Omit<
    CoreTableProps<T, K>,
    | keyof TablePropsBase<T>
    | 'estimateRowHeight'
    | 'updateMeasureOnData'
    | 'headerComponent'
    | 'rowComponent'
  > &
  Partial<Pick<CoreTableProps<T, K>, 'estimateRowHeight' | 'headerComponent' | 'rowComponent'>>;

function noop() {}

function defaultEstimateRowHeight() {
  return 45;
}

export const defaultColumnConfig: ColumnConfig = {
  alignment: 'left',
  ellipsis: true,
  tooltip: false,
  headCellRenderer: HeadCellRenderer,
  rowCellRenderer: RowCellRenderer,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const defaultRowConfig: RowConfig<any> = {
  onClick: noop,
  onMouseEnter: noop,
  onMouseLeave: noop,
  height: 'fixed',
  rowRenderer: RowRenderer,
};

export function Table<T extends BaseItem, K>(props: TableProps<T, K>) {
  const columnConfig = useMemoOne(
    () => ({
      ...defaultColumnConfig,
      ...props.columnConfig,
    }),
    [props.columnConfig]
  );

  const rowConfig = useMemoOne(
    () => ({
      ...defaultRowConfig,
      ...props.rowConfig,
    }),
    [props.rowConfig]
  );

  const BoundHeader = useMemoOne<ComponentType<CoreTableHeaderProps>>(() => {
    return function BoundHeader(headerProps: CoreTableHeaderProps) {
      // Core table header passes corrected column width.
      const columns = useMemoOne(
        () => props.columns.map((c, index) => ({ ...c, width: headerProps.columns[index].width })),
        [props.columns, headerProps.columns]
      );

      return <Header coreHeaderProps={headerProps} columnConfig={columnConfig} columns={columns} />;
    };
  }, [props.columns, columnConfig]);

  const BoundRow = useMemoOne<ComponentType<CoreTableRowProps<T>>>(() => {
    return function BoundRow(rowProps: CoreTableRowProps<T>) {
      // Core table header passes corrected column width.
      const columns = useMemoOne(
        () => props.columns.map((c, index) => ({ ...c, width: rowProps.columns[index].width })),
        [props.columns]
      );

      return (
        <Row
          coreRowProps={rowProps}
          columns={columns}
          columnConfig={columnConfig}
          rowConfig={rowConfig}
        />
      );
    };
  }, [props.columns, columnConfig, rowConfig]);

  const estimateRowHeight = useMemoOne(() => {
    if (typeof rowConfig.height === 'number' && !props.estimateRowHeight) {
      return () => rowConfig.height as number;
    }
    if (!props.estimateRowHeight && process.env.NODE_ENV === 'development') {
      throw new Error(
        'table/base: estimateRowHeight should be provided when ' +
          'rowConfig.height === "fixed" || rowConfig.height === "dynamic"'
      );
    }
    return props.estimateRowHeight ?? defaultEstimateRowHeight;
  }, [props.estimateRowHeight, rowConfig.height]);

  // defaultEstimateRowHeight indicates wrong configuration.
  const shouldUpdateMeasureOnData =
    rowConfig.height === 'dynamic' || estimateRowHeight === defaultEstimateRowHeight;

  return (
    <CoreTable
      data={props.data}
      className={classnames(s.table, props.className)}
      columns={props.columns}
      containerWidth={props.containerWidth}
      containerHeight={props.containerHeight}
      estimateRowHeight={estimateRowHeight}
      hasMore={props.hasMore}
      headerComponent={props.headerComponent ?? BoundHeader}
      instance={props.instance}
      keyExtractor={props.keyExtractor}
      listKey={props.listKey}
      loading={props.loading}
      onLoadMore={props.onLoadMore}
      overscan={props.overscan}
      rowComponent={props.rowComponent ?? BoundRow}
      scrollDebounce={props.scrollDebounce}
      scrollableClassName={classnames(s.scrollable, props.scrollableClassName)}
      updateMeasureOnData={shouldUpdateMeasureOnData}
      errorElement={props.errorElement}
      loaderElement={props.loaderElement}
      noDataElement={props.noDataElement}
    />
  );
}
