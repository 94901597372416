import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

// ----------------------------------------------------------------
export enum PasswordFields {
  passwordConfirm = 'passwordConfirm',
  passwordNew = 'passwordNew',
  passwordOld = 'passwordOld',
}
const F = PasswordFields; // alias
export interface PasswordForm {
  [F.passwordConfirm]: string;
  [F.passwordNew]: string;
  [F.passwordOld]: string;
}
const updatePasswordFields = ['value'];
const updatePasswordQuery = 'updatePassword';
const updatePassword = makeQuery({
  queryName: updatePasswordQuery,
  queryFields: updatePasswordFields,
  query: (form: PasswordForm): Promise<void> => {
    return stompClientService.sendData(
      updatePasswordQuery,
      sign(updatePasswordQuery, updatePasswordFields),
      v.updatePasswordInput({ dto: form })
    );
  },
});
// ----------------------------------------------------------------

const api = {
  updatePassword,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:profile-my-security-tab');

export default api;
