import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { ButtonDownload } from '@components/button/download';
import { Box } from '@components/box';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { GetBankAccountBalancesSortOrder } from '../api';
import { permissionGroup as apiPermissionGroup } from './api';
import { useModel } from './model';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:bank-account-balances-actions-row',
});

export const ActionsRow: FC<{
  ordering: GetBankAccountBalancesSortOrder;
}> = ({ ordering }) => {
  const { lockUI, onDownloadClicked } = useModel(ordering);

  return (
    <Box className="mb1" compact>
      <Tooltip
        placement="bottom"
        trigger={TooltipTrigger.hover}
        title={
          <Trans id="bank-account-balances.button.download">Download payments report xls</Trans>
        }
      >
        <ButtonDownload onClick={onDownloadClicked} compact="all" disabled={lockUI} />
      </Tooltip>
    </Box>
  );
};
