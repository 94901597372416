import { createContext, ReactNode, useMemo, useReducer } from 'react';
import { ExtractAction } from '@helper/ts';
import { FormData } from '../../form';

export type TechInfoContextState = {
  selectedCqgCurrencyIds: (number | null)[];
};

type ActionMap = {
  FORM_INITIATED: {
    data: FormData;
  };
  FORM_CHANGED: {
    data: FormData;
  };
};

type Action = ExtractAction<ActionMap>;

const initialState: TechInfoContextState = {
  selectedCqgCurrencyIds: [],
};

export type TechInfoContextValue = {
  state: TechInfoContextState;
  dispatch: (action: Action) => void;
};

export const TechInfoContext = createContext<TechInfoContextValue>({
  state: initialState,
  dispatch: () => {},
});

function techInfoReducer(state: TechInfoContextState, action: Action): TechInfoContextState {
  switch (action.type) {
    case 'FORM_INITIATED':
    case 'FORM_CHANGED':
      return {
        selectedCqgCurrencyIds:
          action.payload.data?.cqgSymbols?.map((s) => s.currency?.id ?? null) ?? [],
      };
  }
}

export type TechInfoProps = {
  children: ReactNode;
};

export function TechInfoProvider({ children }: TechInfoProps) {
  const [state, dispatch] = useReducer(techInfoReducer, initialState);

  const contextValue = useMemo<TechInfoContextValue>(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <TechInfoContext.Provider value={contextValue}>{children}</TechInfoContext.Provider>;
}
