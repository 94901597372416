import { useCallback } from 'react';
import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { ApproveDepositModal, Props as ApproveModalProps } from '../approve-deposit-modal';
import { MarkAsPaidModal, Props as MarkAsPaidModalProps } from '../mark-as-paid-modal';
import { CancelDepositModal, Props as CancelDepositModalProps } from '../cancel-deposit-modal';

export const useModel = (deposit, replaceDeposit) => {
  const showModal = useCallDispatch(showModalAction);

  const showApproveDepositModal = useCallback(() => {
    const props: ApproveModalProps = { deposit, replaceDeposit };
    showModal({ modal: ApproveDepositModal, props });
  }, []); // eslint-disable-line

  const showMarkAsPaidModal = useCallback((partly) => {
    const props: MarkAsPaidModalProps = { deposit, replaceDeposit, partly };
    showModal({ modal: MarkAsPaidModal, props });
  }, []); // eslint-disable-line

  const showConfirmCancelDepositModal = useCallback(() => {
    const props: CancelDepositModalProps = { deposit, replaceDeposit };
    showModal({ modal: CancelDepositModal, props });
  }, []); // eslint-disable-line

  return {
    showApproveDepositModal,
    showConfirmCancelDepositModal,
    showMarkAsPaidModal,
  };
};
