import React, { FC, ReactNode } from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';

import { InfoButtonIcon } from '@components/icons';
import { Tooltip, TooltipTrigger } from '@components/tooltip';

export const HelpInfo: FC<HelpInfoProps> = (props) => {
  return (
    <Tooltip
      className={props.className}
      placement={props.placement}
      trigger={[TooltipTrigger.click]}
      title={props.content}
    >
      <InfoButtonIcon />
    </Tooltip>
  );
};

interface HelpInfoProps {
  className?: string;
  content: ReactNode;
  placement?: TooltipPlacement;
}
