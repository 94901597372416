import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { sharedApi } from '@services/api';

// ----------------------------------------------------------------
type Document = {
  extension: string;
  uploadFileUuid: string;
};
const getBillsDocumentsFields = ['extension', 'uploadFileUuid'];
const getBillsDocumentsQuery = 'downloadUserVerificationDocument';
const downloadVerificationDocument = makeQuery({
  permissions: { and: [Permission.VERIFICATION_DOCUMENTS_VIEW_OTHER] },
  queryName: getBillsDocumentsQuery,
  queryFields: getBillsDocumentsFields,
  query: (id: number, type: 'IDENTIFIER_DOCUMENT' | 'UTILITY_BILL'): Promise<void> => {
    return stompClientService
      .getData(
        getBillsDocumentsQuery,
        sign(getBillsDocumentsQuery, getBillsDocumentsFields),
        v.verificationDocumentDownloadDTOInput({
          verificationDocumentDownloadDTO: { type, user: { id } },
        })
      )
      .then((d: Document) =>
        sharedApi.downloadFile(
          `${type.toLowerCase()}_user_id_${id}.${d.extension}`,
          d.uploadFileUuid
        )
      );
  },
});
// ----------------------------------------------------------------
const api = {
  downloadVerificationDocument,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:profile-other-page-documents');

export default api;
