import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { closeModalAction, showNotifyModalAction } from '@modules/modal';
import { roundDown } from '@helper/number';
import { handleBackendError } from '@modules/notify';
import { formService, OnFormAction } from '@components/form';
import {
  TickerItem,
  metalsApiService as metalsApi,
  MyStableBalance,
  OfferPrice,
} from '../../metals-api.service';
import { BuySuccessContent } from '../buy-success';
import { calculatePrice } from '../service';
import { BuyConfirmModal } from './index';

export const buyFormId = 'buy-form-id';

export enum BuyFormFields {
  amount = 'amount',
}

interface BuyForm {
  [BuyFormFields.amount]: number;
}

export const minQuantity: number = 1;

export const formInitial = {
  [BuyFormFields.amount]: minQuantity,
};

export const useModel = ({
  offerPrice: offerPriceInitial,
  selectedPair,
  stableBalance,
}: {
  offerPrice: OfferPrice;
  selectedPair: TickerItem;
  stableBalance: MyStableBalance;
}) => {
  const dispatch = useDispatch();
  const [lockUI, setLockUI] = useState(false);
  const [amount, onAmountChanged] = useState(minQuantity);
  const [offerPrice, setOfferPrice] = useState(offerPriceInitial);

  const currency = selectedPair.currency.code;
  const balance = stableBalance?.balance || 0;
  const availableInGrams =
    offerPrice.offer.availableQuantity * offerPrice.offer.quantityUnit.inGrams;
  const {
    issuerTaxPct,
    issuerTax,
    priceWithCommission,
    priceWithoutCommission,
    sellCommission,
    unitPriceWithoutCommission,
    unitPriceWithRawCommission,
  } = calculatePrice(amount, offerPrice);
  const userCanBuy = balance / unitPriceWithRawCommission;
  const maxQuantity = roundDown(Math.min(availableInGrams, userCanBuy), 0);
  const isMaximizeButtonDisabled = amount >= maxQuantity || lockUI;
  const isBuyButtonDisabled = !amount || lockUI;

  useEffect(() => {
    const subscription = metalsApi
      .priceChanges$(offerPrice.offer.id, selectedPair.currency.id)
      .pipe(
        tap((newPrice: OfferPrice) => setOfferPrice(newPrice)),
        catchError((error: Error) => {
          handleBackendError(error);
          return of(undefined);
        })
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, []); // eslint-disable-line

  useEffect(() => {
    const form = formService.get<BuyForm>(buyFormId);
    const amountToBuy = form.getFieldValue(BuyFormFields.amount) || 0;
    if (amountToBuy > maxQuantity) {
      onMaximizeButtonClicked();
    }
  }, [maxQuantity]); // eslint-disable-line

  const onClose = () => dispatch(closeModalAction(BuyConfirmModal));

  const onMaximizeButtonClicked = () => {
    const form = formService.get<BuyForm>(buyFormId);
    form.setFieldsValue({ [BuyFormFields.amount]: maxQuantity });
    onAmountChanged(maxQuantity);
  };

  const onConfirmBuyAction: OnFormAction = {
    error: (error: Error) => {
      handleBackendError(error);
      setLockUI(false);
    },
    submit: async (form: BuyForm) => {
      setLockUI(true);
      const offer = await metalsApi.buyOffer(
        offerPrice.offer.id,
        selectedPair.currency.id,
        form.amount
      );
      setLockUI(false);
      dispatch(
        showNotifyModalAction({
          isClosePrevious: true,
          description: <BuySuccessContent offer={offer} />,
          okText: <Trans id="buy_metals_success_modal.button.ok">Finish</Trans>,
          type: 'success',
          title: <Trans id="buy_metals_success_modal.title">Complete</Trans>,
        })
      );
    },
  };

  return {
    currency,
    isBuyButtonDisabled,
    isMaximizeButtonDisabled,
    lockUI,
    maxQuantity,
    onAmountChanged,
    onClose,
    onConfirmBuyAction,
    onMaximizeButtonClicked,
    issuerTaxPct,
    issuerTax,
    priceWithCommission,
    priceWithoutCommission,
    sellCommission,
    unitPriceWithoutCommission,
  };
};
