import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { TableResponse } from '@models/core';
import { SortOrder } from '@models/sorting';
import { CMTransactionStatus, CMTransactionType } from '../hooks';
// -------------------------------------------------------------------------------------
const transactionsFields = [
  'amount',
  'externalId',
  { currency: ['code'] },
  { computedFields: [{ blockchainTransaction: ['date', 'txid'] }] },
  'id',
  'method',
  'purpose',
  { receiverBankAccount: ['accountNumber'] },
  { senderBankAccount: ['accountNumber'] },
  'sourceOfFunds',
  'systemStatus',
  'transactionDateTime',
  'type',
  'valueDateTime',
];
// -------------------------------------------------------------------------------------
export enum Column {
  amount = 'amount',
  currency = 'currency.code',
  externalId = 'externalId',
  id = 'id',
  method = 'method',
  minfiniumOpId = 'computedFields.blockchainTransaction.txid',
  purpose = 'purpose',
  receiverBankAccountNumber = 'receiverBankAccount.accountNumber',
  senderBankAccountNumber = 'senderBankAccount.accountNumber',
  sourceOfFunds = 'sourceOfFunds',
  systemStatus = 'systemStatus',
  transactionDateTime = 'transactionDateTime',
  txDate = 'computedFields.blockchainTransaction.date',
  type = 'type',
  valueDateTime = 'valueDateTime',
}

export type CMTransaction = {
  [Column.amount]: string;
  computedFields: { blockchainTransaction: { date: string; txid: string } };
  currency: { code: string };
  [Column.externalId]: string;
  id: number;
  [Column.method]: string;
  [Column.purpose]: string;
  receiverBankAccount: { accountNumber: string };
  senderBankAccount: { accountNumber: string };
  [Column.systemStatus]: CMTransactionStatus;
  [Column.sourceOfFunds]: string;
  [Column.transactionDateTime]: string;
  [Column.type]: CMTransactionType;
  [Column.valueDateTime]: string;
};

const getTransactionsQuery = 'backofficeExtendedTransactionsPage';
const getTransactionsFields = [{ data: transactionsFields }, 'index', 'hasMore'];
export const getTransactions = makeQuery({
  permissions: Permission.BACK_OFFICE_ACCESS,
  queryName: getTransactionsQuery,
  queryFields: getTransactionsFields,
  query: (page: number, ordering: SortOrder<Column>): Promise<TableResponse<CMTransaction>> => {
    const typedValues = {
      ...v.long({ page }),
      ...v.orderInput({ ordering }),
    };

    return stompClient.getData(
      getTransactionsQuery,
      sign(getTransactionsQuery, getTransactionsFields),
      typedValues
    );
  },
});
// -------------------------------------------------------------------------------------
const getTransactionQuery = 'backofficeExtendedTransactionById';
const getTransactionFields = transactionsFields;
export const getTransaction = makeQuery({
  permissions: Permission.BACK_OFFICE_ACCESS,
  queryName: getTransactionQuery,
  queryFields: getTransactionFields,
  query: (id: number): Promise<CMTransaction> => {
    const typedValues = v.idInput({ id: { id } });
    return stompClient.getData(
      getTransactionQuery,
      sign(getTransactionQuery, getTransactionFields),
      typedValues
    );
  },
});
// -------------------------------------------------------------------------------------
const api = {
  getTransaction,
  getTransactions,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:cm-transactions-table');

export default api;
