import { createAsyncThunk } from '@reduxjs/toolkit';

import { handleBackendError } from '@modules/notify';
import api, { Country, Nationality } from '../api';
import { sliceId } from './model';
import selectors from './selectors';

const getCountries = createAsyncThunk(sliceId('getCountries'), async (p: void, { getState }) => {
  try {
    let countries: Array<Country> = selectors.countries(getState());
    if (!countries.length) {
      countries = await api.getCountries();
    }
    return countries;
  } catch (error) {
    handleBackendError(error);
    throw error;
  }
});

const getNationalities = createAsyncThunk(
  sliceId('getNationalities'),
  async (p: void, { getState }) => {
    try {
      let nationalities: Array<Nationality> = selectors.nationalities(getState());
      if (!nationalities.length) {
        nationalities = await api.getNationalities();
      }
      return nationalities;
    } catch (error) {
      handleBackendError(error);
      throw error;
    }
  }
);

export default {
  getCountries,
  getNationalities,
};
