import { FC } from 'react';
import { permissionGroup as apiPermissionGroup } from './api';
import { CurrencyExchangeModal as CurrencyExchange } from './modal';
import { Provider } from './context';
import './styles.scss';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:currency-exchange-modal',
});

type Props = {
  onClose: () => void;
  isVisible: boolean;
};

export const CurrencyExchangeModal: FC<Props> = ({ onClose, isVisible }) => {
  return (
    <Provider>
      <CurrencyExchange open={isVisible} onClose={onClose} />
    </Provider>
  );
};
