import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';
import { privateApi } from 'utils/api';

type GetRedeemOptionsResponse = {
  id: number;
  priceType: string;
  image: {
    id: number;
    name: string;
  };
  name: string;
  amount: number;
  unit: {
    inGrams: number;
    label: string;
  };
  description: string;
  minimalQuantity: number;
  price: number;
};

const getRedeemOptionsQuery = 'redeemOptions';
const getRedeemOptionsFields = [
  'id',
  'priceType',
  { image: ['id', 'name'] },
  'name',
  'amount',
  { unit: ['inGrams', 'label'] },
  'description',
  'minimalQuantity',
  'price',
];
const getRedeemOptions = makeQuery({
  queryName: getRedeemOptionsQuery,
  queryFields: getRedeemOptionsFields,
  query: (coinId: number): Promise<GetRedeemOptionsResponse> => {
    const fields = sign(getRedeemOptionsQuery, getRedeemOptionsFields);
    return stompClient.getData<GetRedeemOptionsResponse>(getRedeemOptionsQuery, fields, { coinId });
  },
});

// Support existing structure to avoid changes.
type GetPrimaryStableBalanceResponse = Array<{
  asset: string;
  balance: number;
  currency: {
    code: string;
    id: number;
  };
}>;

const getPrimaryStableBalanceQuery = 'userProfile';
const getPrimaryStableBalanceFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [
              {
                stableBalance: [
                  'amount',
                  {
                    currency: ['asset', 'id', 'code'],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
const getPrimaryStableBalance = makeQuery({
  permissions: Permission.BALANCE_VIEW_PRIMARY_MY,
  queryName: getPrimaryStableBalanceQuery,
  queryFields: getPrimaryStableBalanceFields,
  query: (): Promise<GetPrimaryStableBalanceResponse> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: {
            stableBalance: Array<{
              amount: number;
              currency: {
                asset: string;
                code: string;
                id: number;
              };
            }>;
          };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getPrimaryStableBalanceQuery,
        sign(getPrimaryStableBalanceQuery, getPrimaryStableBalanceFields)
      )
      .then(({ balance }) => {
        const stableBalance = balance.primary.active.stableBalance;
        return stableBalance
          .filter((balance) => currenciesFilter(balance.currency))
          .map((b) => ({
            asset: b.currency.asset,
            balance: b.amount,
            currency: {
              code: b.currency.code,
              id: b.currency.id,
            },
          }));
      });
  },
});

const createRedeemEndpoint = '/wallets/redeem';
const createRedeem = makeQuery({
  queryName: createRedeemEndpoint,
  query: ({
    redeemOptionId,
    quantity,
  }: {
    redeemOptionId: number;
    quantity: number;
  }): Promise<any> => {
    return privateApi.post('/wallets/redeem', { redeemOptionId, quantity });
  },
});

const downloadRedeemTransactionQuery = 'redeemTransaction';
const downloadRedeemTransactionFields = ['value'];
const downloadRedeemTransaction = makeQuery({
  queryName: downloadRedeemTransactionQuery,
  queryFields: downloadRedeemTransactionFields,
  query: ({
    asset,
    totalInMetal,
  }: {
    asset: string;
    totalInMetal: number;
  }): Promise<{ value: number }> => {
    const typedValues = {
      asset,
      ...v.float({ amount: +totalInMetal }),
    };
    const fields = sign(downloadRedeemTransactionQuery, downloadRedeemTransactionFields);
    return stompClient.getData<{ value: number }>(
      downloadRedeemTransactionQuery,
      fields,
      typedValues
    );
  },
});

const api = {
  createRedeem,
  downloadRedeemTransaction,
  getRedeemOptions,
  getPrimaryStableBalance,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:redeem-modal');

export default api;
