export const COIN_REGEXP = '((\\d+)([\\.]\\d{0,7})?)?';
export const INT_REGEXP = '\\d*';
export const PHONE_REGEXP = '(^(\\+)?(?!.*-{2,})([\\d#*()-]+)?$)?';

// export const COIN_AMOUNT_INT_REGEXP = '(^(?!0$)(\\d+)?$)?';
// export const FIAT_REGEXP = '((\\d+)([\\.]\\d{0,2})?)?';
// export const COIN_AMOUNT_REGEXP = '((\\d+)([\\.]\\d{0,4})?)?';
// export const PERCENT_REGEXP = '((\\d{0,2})([\\.]\\d{0,3})?)?';
// export const ASSET_NAME_REGEXP = '([a-zA-Z0-9]{1,12})?';
// export const DEPOSIT_AMOUNT_REGEXP = '(^(?!0$)(\\d+)([\\.]\\d{0,2})?$)?';
