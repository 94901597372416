import { Trans } from '@lingui/macro';
import { ButtonSecondary } from '@components/button';
import { DATE_FORMAT } from '@constants/date';
import { formatMoney, formatToken } from 'containers/services/commonService';
import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { closeModalAction, Modal, ModalContent, ModalTitle } from '@modules/modal';

export const IssuerBalanceModal = ({ issuerBalance, isVisible, startDate, endDate }) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModalAction(IssuerBalanceModal));

  let totalSalesMetal = 0,
    totalCommissionMetal = 0;
  const stableTotal = {};

  let balanceRows = issuerBalance?.map((balance) => {
    totalSalesMetal += balance.sales;
    totalCommissionMetal += balance.tokenCommission;

    balance.stableBalance.forEach((stableBalance) => {
      if (!stableTotal[stableBalance.currencyCode]) {
        stableTotal[stableBalance.currencyCode] = {
          totalSales: 0,
          totalCommission: 0,
        };
      }
      stableTotal[stableBalance.currencyCode].totalSales += stableBalance.sales;
      stableTotal[stableBalance.currencyCode].totalCommission +=
        stableBalance.saleCommission + stableBalance.purchasesCommission;
    });

    return (
      <tbody>
        <tr>
          <td colSpan="3">
            <h3>{balance.asset}</h3>
          </td>
        </tr>
        <tr>
          <td className="op-text" />
          <td className="simple-td op-text">
            <Trans id="issuer_balance_modal.sold">Sold</Trans>
          </td>
          <td className="simple-td op-text">
            <Trans id="issuer_balance_modal.fee">Fee</Trans>
          </td>
        </tr>
        <tr>
          <td className="op-text">
            <Trans id="issuer_balance_modal.in_metal">in metal</Trans>
          </td>
          <td className="simple-td">{formatToken(balance.sales)}</td>
          <td className="simple-td">{formatToken(balance.tokenCommission)}</td>
        </tr>

        {balance.stableBalance.map(({ currencyCode, ...stableBalance }) => (
          <tr key={currencyCode}>
            <td className="op-text">
              <Trans id="issuer_balance_modal.in_currency">in {currencyCode}</Trans>
            </td>
            <td className="simple-td">{formatMoney(stableBalance.sales, {})}</td>
            <td className="simple-td">
              {formatMoney(stableBalance.saleCommission + stableBalance.purchasesCommission, {})}
            </td>
          </tr>
        ))}
      </tbody>
    );
  });

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalTitle>
        <Trans id="issuer_balance_modal.total_balance">Total balance</Trans>
      </ModalTitle>

      <ModalContent>
        <p>
          <Trans id="issuer_balance_modal.for">For</Trans> {moment(startDate).format(DATE_FORMAT)} -{' '}
          {moment(endDate).format(DATE_FORMAT)}
        </p>

        <h3>
          <Trans id="issuer_balance_modal.summary">Summary</Trans>
        </h3>

        <div className="row form-elements">
          <div className="col-12">
            <table className="simple-table">
              {balanceRows}
              <tbody>
                <tr>
                  <td className="td-top-border-blue" colSpan="3">
                    <Trans id="issuer_balance_modal.total">TOTAL</Trans>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Trans id="issuer_balance_modal.in_metal">in metal</Trans>
                  </td>
                  <td className="color-blue-light simple-td">{totalSalesMetal}</td>
                  <td className="color-blue-light simple-td">{totalCommissionMetal}</td>
                </tr>

                {Object.keys(stableTotal).map((currencyCode) => (
                  <tr key={currencyCode}>
                    <td>
                      <Trans id="issuer_balance_modal.in_currency">in {currencyCode}</Trans>
                    </td>
                    <td className="color-blue-light simple-td">
                      {formatMoney(stableTotal[currencyCode].totalSales, {})}
                    </td>
                    <td className="color-blue-light simple-td">
                      {formatMoney(stableTotal[currencyCode].totalCommission, {})}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="col-12">
            <ButtonSecondary fullWidth={true} icon={null} onClick={onClose}>
              <Trans id="button.close">Close</Trans>
            </ButtonSecondary>
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
