import {
  createElement,
  createContext,
  useContext,
  useRef,
  useState,
  ComponentType,
  FC,
  PropsWithChildren,
  ReactNode,
  useMemo,
} from 'react';
import classnames from 'classnames';
import { useCallbackOne, useMemoOne } from 'use-memo-one';

import s from './index.module.scss';

export type OnChange = (tabKey: string) => void;

export type OnInteraction = (tabKey: string) => void;

type TabPaneWrapperPropsBase = {
  active: boolean;
  externalProps: PaneWrapperProps | undefined;
};

export type TabPaneWrapperProps = PropsWithChildren<TabPaneWrapperPropsBase>;

export type PaneWrapperProps = {
  className?: string;
};

export type TabsProps = {
  activeKey?: string;
  children?: ReactNode;
  defaultActiveKey?: string;
  forceRender?: boolean;
  onChange?: OnChange;
  paneWrapperComponent?: ComponentType<TabPaneWrapperPropsBase>;
  paneWrapperProps?: PaneWrapperProps;
};

type ContextValue = {
  activeKey: string | null;
  forceRender: boolean;
  onInteraction: OnInteraction;
  paneWrapperComponent?: ComponentType<TabPaneWrapperPropsBase>;
  paneWrapperProps?: PaneWrapperProps;
};

function noop() {}

const Context = createContext<ContextValue>({
  activeKey: null,
  forceRender: false,
  onInteraction: noop,
});

export const Tabs: FC<TabsProps> = (props) => {
  const isControlledRef = useRef(props.activeKey !== undefined);
  const [activeKey, setActiveKey] = useState(props.activeKey ?? props.defaultActiveKey);

  const onInteraction = useCallbackOne<OnInteraction>(
    (tabKey) => {
      props.onChange?.(tabKey);
      if (!isControlledRef.current) {
        setActiveKey(tabKey);
      }
    },
    [props.onChange]
  );

  // TODO можно сделать типизированную функцию,
  //      которая будет автогенерить тип, на основе только существующих полей
  const paneWrapperProps = useMemo(
    () => props.paneWrapperProps,
    [props.paneWrapperProps?.className]
  );

  const contextValue = useMemoOne<ContextValue>(
    () => ({
      activeKey: isControlledRef.current ? props.activeKey : activeKey,
      forceRender: Boolean(props.forceRender),
      onInteraction,
      paneWrapperComponent: props.paneWrapperComponent,
      paneWrapperProps,
    }),
    [
      activeKey,
      onInteraction,
      props.activeKey,
      props.forceRender,
      props.paneWrapperComponent,
      paneWrapperProps,
    ]
  );

  return <Context.Provider value={contextValue}>{props.children}</Context.Provider>;
};

export type TabPaneProps = {
  tabKey: string;
  children: any;
};

export const TabPane = (props: TabPaneProps) => {
  const { activeKey, forceRender, paneWrapperComponent, paneWrapperProps } = useContext(Context);
  const shouldUseWrapper = paneWrapperComponent;
  const shouldRender = forceRender || props.tabKey === activeKey;
  const children = shouldRender ? props.children : null;

  return shouldUseWrapper
    ? createElement<TabPaneWrapperPropsBase>(
        paneWrapperComponent,
        {
          active: activeKey === props.tabKey,
          externalProps: paneWrapperProps,
        },
        ...(children.length ? children : [children])
      )
    : children;
};

export const useTabs = () => {
  const { activeKey, onInteraction } = useContext(Context);
  return useMemoOne(
    () => ({
      activeKey,
      onInteraction,
    }),
    [activeKey, onInteraction]
  );
};

export const ForcedRenderPaneWrapper: FC<TabPaneWrapperPropsBase> = (p) => (
  <div className={classnames(p.externalProps?.className, { [s.tabHidden]: !p.active })}>
    {p.children}
  </div>
);
