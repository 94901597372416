import React, { FC } from 'react';

import { ReactComponent as CrossIcon } from '@images/icons/cross-icon.svg';
import { Button, ButtonColor, ButtonModel, ButtonType } from './button';

export { Button } from './button';
export { ButtonBack } from './back';
export { ButtonModalBack } from './modal-back';
export { ButtonEdit } from './edit';
export { ButtonToggle } from './toggle';
export type { ButtonModel } from './button';
export { ButtonAction, ButtonColor, ButtonType } from './model';

export const ButtonPrimary: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.button} color={ButtonColor.primary} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonSecondary: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.outline} color={ButtonColor.light} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonInfo: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.outline} color={ButtonColor.light} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonIconPure: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.iconOnlyPure} color={ButtonColor.light} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonIconBordered: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.iconOnlyBordered} color={ButtonColor.light} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonText: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.text} color={ButtonColor.light} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonTextIconBig: FC<ButtonModel> = ({ children, ...props }) => (
  <Button type={ButtonType.textIconBig} color={ButtonColor.light} size="md" {...props}>
    {children}
  </Button>
);

export const ButtonCross: FC<ButtonModel> = ({ children, ...props }) => (
  <Button
    type={ButtonType.iconOnlyPure}
    color={ButtonColor.light}
    size="md"
    icon={<CrossIcon className="tm2-icon-stroke" />}
    {...props}
  />
);
