import React, { FC, ReactNode } from 'react';

import { Select } from '@components/form';
import { Fields, useModel } from './model';

export const RolesSelect: FC<{
  className: string;
  label: ReactNode;
  onChange: (id: number) => void;
  name: Fields;
  disabled: boolean;
  required: boolean;
}> = (p) => {
  const m = useModel();

  return (
    <Select
      className={p.className}
      label={p.label}
      onChange={(id) => p.onChange(id as number)}
      name={p.name as string}
      data={m.roles}
      disabled={p.disabled}
      required={p.required}
    />
  );
};
