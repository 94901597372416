import React from 'react';
import axios from 'axios';
import { all, call, put, select, take, takeEvery } from 'redux-saga/effects';
import { eventChannel, EventChannel } from 'redux-saga';
import { I18n } from '@lingui/core';
import { Trans } from '@lingui/macro';
import { catchError, of, tap } from 'rxjs';

import { Put } from '@helper/redux';
import { setI18nLang } from '@services/translate';
import { loggerService as logger } from '@services/logger';
import { session, SessionStatus } from '@services/session';
import {
  LiveUpdateEvent,
  LiveUpdateEventType as LiveEvent,
  liveUpdateService as liveUpdate,
} from '@services/stomp/live-update';
import { HTMLLang, KKLang, Lang } from '@models/core';
import { handleBackendError, notifyError } from '@modules/notify';
import { stompClientService as stompClient } from '@services/stomp/client';
import { websocketsService as websockets } from '@services/stomp/core';
import { IsHasAccess, permissionsSelectors } from '@permissions/core';
import { showModalAction } from '@modules/modal';
import { onBalanceChangedReceived } from '../../../actions/userAction';
import { listUpdateAction } from '../../../actions/listAction';
import {
  onGraphQlSubscriptionEvent,
  onResponseReceived,
} from '../../../containers/services/asyncService';
import { permissionGroup as onboardHelpPermissionGroup } from '../../../containers/pages/tutorial';
import { StartTutorialModal } from '../../../containers/pages/tutorial/modal';
import configApi, { SystemPreferences, WebEvent } from '../config-api.service';
import { configSelectors, toggleGlobalPreloaderAction } from '../core';
import { configServiceActions, ConfigServiceActions } from './service.acions';
import { ConfigActionsTypes as ConfigTypes, UserInfo } from './model';

export function* configEffects() {
  yield all([
    takeEvery(ConfigTypes.CHECK_APP_LANGUAGE, checkApplicationLanguage),
    takeEvery(ConfigTypes.CHECK_TUTORIAL_EVENT, checkTutorialModal),
    takeEvery(ConfigTypes.CONFIGURE_APPLICATION, configureApplication),
    takeEvery(ConfigTypes.CONFIGURE_APPLICATION, subscribeOnLiveUpdate),
    takeEvery(ConfigTypes.CONFIGURE_APPLICATION_SUCCESS, removeInitialPreloader),
    takeEvery(ConfigTypes.CONFIGURE_APPLICATION_SUCCESS, subscribeOnWebEvents),
    takeEvery(ConfigTypes.GET_USER_INFO, getUserInfo),
    takeEvery(ConfigTypes.LOG_OUT_SESSION, logoutSession),
    takeEvery(
      ConfigTypes.ON_LOG_OUT_CLICKED,
      Put(configServiceActions.logOutSession, 'from-click')
    ),
  ]);
}

function* checkApplicationLanguage(action: ConfigServiceActions.checkAppLanguage) {
  try {
    const previousLanguage: Lang = session.getCurrentLanguage();
    const newLanguage: Lang = action.payload;

    yield prepareAppForLanguage(newLanguage);
    if (previousLanguage !== newLanguage) {
      const i18n: I18n = setI18nLang(newLanguage);
      yield put(
        configServiceActions.setAppLanguage({
          i18n: i18n, // we need to update i18n instance in redux to render new language
          lang: newLanguage,
        })
      );
      session.saveCurrentLanguage(newLanguage);
    }
  } catch (error) {
    handleBackendError(error);
    yield logger.error('Change app language failed', {
      location: 'src/shared/config/store/effects.ts:checkApplicationLanguage',
      rawError: new Error('Change app language failed'),
    });
  }
}

function* checkTutorialModal(action: ConfigServiceActions.checkTutorialEvent) {
  const isForcedShow = action.payload;
  const hasAccessFn: IsHasAccess = yield select(permissionsSelectors.isHasAccess);
  const isUserHasTutorial: boolean = hasAccessFn({ or: [onboardHelpPermissionGroup] });
  const isRegistrationFinished = yield select(configSelectors.isRegistrationFinished);
  const isShowTutorialModal: boolean = yield select(configSelectors.isShowTutorialModal);
  const isAlreadyOnTutorialPage = window.location.pathname === '/tutorial';

  if (
    isUserHasTutorial &&
    isShowTutorialModal &&
    !isAlreadyOnTutorialPage &&
    (isRegistrationFinished || isForcedShow)
  ) {
    yield put(showModalAction({ modal: StartTutorialModal }));
  }
}

function* configureApplication(action: ConfigServiceActions.configureApplication) {
  try {
    logger.log('App config: start configuring');
    if (session.buildVersion !== process.env.REACT_APP_GIT_COMMIT) {
      // NOTE: this logic should newer fire due to clear cache logic in src/index.ts
      logger.log('App config: WARNING app is running from previous build', {
        appVersionCurrent: process.env.REACT_APP_GIT_COMMIT,
        appVersionMeta: session.buildVersion,
      });
      yield logger.error('Clear cache on new deploy failed', {
        appVersionCurrent: process.env.REACT_APP_GIT_COMMIT,
        appVersionMeta: session.buildVersion,
        location: 'src/shared/config/store/effects.ts:configureApplication',
        rawError: new Error('Clear cache on new deploy failed'),
      });
    }

    if (action.payload.sessionStatus === SessionStatus.good) {
      session.saveAccessToken(action.payload.accessToken);
      session.saveRefreshToken(action.payload.refreshToken);
      yield websockets.connect(action.payload); // this line will be resolved only after success connection
      logger.log('App config: ws connection established');

      const [preferences, userInfo]: [SystemPreferences, UserInfo] = yield Promise.all([
        configApi.getSystemPreferences(),
        configApi.getUserInfo(),
      ]);
      logger.log('App config: initial configuration received');
      yield put(configServiceActions.setSystemPreferences(preferences));
      yield put(configServiceActions.getUserInfoSuccess(userInfo));
      yield put(configServiceActions.checkAppLanguage(userInfo.lang));
    } else {
      logger.log('App config: WARNING, configuration failed', action.payload);
    }
    yield put(configServiceActions.configureApplicationSuccess());
  } catch (e) {
    const error: Error = e as Error;
    notifyError(
      {
        text: error?.message || (
          <Trans id="bootstrap.configure_application.error.text">
            Application configuration failed
          </Trans>
        ),
        title: <Trans id="bootstrap.configure_application.error.title">Error 84</Trans>,
      },
      {
        forcedAction: true,
        onClick: () => {
          session.logout();
          window.location.href = `${window.location.origin}/sso/auth`;
        },
      }
    );
    yield logger.error('Application configuration failed', {
      location: 'src/shared/config/store/effects.ts:configureApplication',
      rawError: error,
    });
  }
}

function* getUserInfo() {
  try {
    const userInfo: UserInfo = yield configApi.getUserInfo();
    yield put(configServiceActions.getUserInfoSuccess(userInfo));
    yield put(configServiceActions.checkAppLanguage(userInfo.lang));
  } catch (error) {
    yield put(configServiceActions.getUserInfoFail(error as Error));
    handleBackendError(error);
  }
}

function* logoutSession(action: ConfigServiceActions.logOutSession) {
  try {
    const type = action.payload;
    yield put(toggleGlobalPreloaderAction(true));
    if (type === 'from-click') {
      const formData = new FormData();
      formData.append('refresh_token', session.getRefreshToken());
      yield axios({
        method: 'post',
        url: '/sso/logout',
        data: formData,
      });
    }

    delete axios.defaults.headers.common['Authorization'];
    if (type !== 'web-event') {
      yield configApi.sendWebEvent({ type: 'logout', data: session.sessionId });
    }

    liveUpdate.emit({ type: LiveEvent.logOut });
    websockets.logOut();
    yield stompClient.logOut();
    session.logout();
    window.location.href = `${window.location.origin}/sso/auth`;
  } catch (error) {
    handleBackendError(error);
  }
}

function* prepareAppForLanguage(newLang: Lang) {
  const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
  const kkLang: KKLang = queryParams.get('locale') as KKLang;

  if (newLang === Lang.cn) {
    document.documentElement.lang = HTMLLang.cn;
    queryParams.set('locale', KKLang.cn);
  } else {
    document.documentElement.lang = HTMLLang.en;
    queryParams.set('locale', KKLang.en);
  }

  if (kkLang) {
    // to save selected language for unauthorized users during page manual reload
    const l: Location = window.location;
    const fullUrl: string = `${l.origin}${l.pathname}?${queryParams.toString()}${l.hash}`;
    window.history.replaceState({ path: fullUrl }, '', fullUrl);
  }
}

function* removeInitialPreloader() {
  // NOTE: Так сделано, чтобы не было мелькания белой страницы в самый первый раз загрузки приложения юзером.
  //       Стартовый прелоадер со стилями лежат в index.html.
  //       Стили прелоадера переиспользуются в проекте по имени класса
  const preloader: Element = document.querySelector('.tm2-initial-preloader');
  const root: Element = document.querySelector('#root');
  root.classList.remove('tm2-initial-hidden');
  if (preloader) {
    preloader.classList.add('tm2-hide-preloader');
    setTimeout(() => {
      preloader.remove();
    }, 750);
  }
}

function* subscribeOnLiveUpdate() {
  const liveChannel: EventChannel<LiveUpdateEvent> = yield call(createLiveUpdateChannel);

  while (true) {
    const event: LiveUpdateEvent = yield take(liveChannel);
    const { data, error, type } = event;

    if (type === LiveEvent.asyncResponse) {
      yield onResponseReceived(data);
    } else if (type === LiveEvent.balanceChanged) {
      yield put(onBalanceChangedReceived(data));
    } else if (type === LiveEvent.coinChanged) {
      yield put(listUpdateAction('coins', data, (el) => el.id === data.id));
    } else if (type === LiveEvent.graphQlSubscriptionNotification) {
      yield onGraphQlSubscriptionEvent(data);
    } else if (type === LiveEvent.kycChanged) {
      yield put(configServiceActions.getUserInfo());
    } else if (type === LiveEvent.logOut) {
      liveChannel.close();
      return; // finish "while" listen cycle
    } else if (type === LiveEvent.userEdited) {
      yield put(configServiceActions.getUserInfo());
    } else if (type === LiveEvent.wsClosed) {
      console.warn('STOMP CLOSE', error);
      yield put(configServiceActions.websocketDisconnected());
    } else if (type === LiveEvent.wsConnected) {
      yield put(configServiceActions.websocketConnected(data));
    } else if (type === LiveEvent.wsError) {
      console.warn('STOMP ERROR', error);
      yield handleBackendError(error);
    } else if (type === LiveEvent.wsRefreshSessionFailed) {
      yield handleBackendError(error);
      yield put(configServiceActions.logOutSession('internal'));
      liveChannel.close();
      return; // finish "while" listen cycle
    } else if (type === LiveEvent.wsUnrecognizedSession) {
      if (error) {
        yield logger.error('Unrecognized websocket session', {
          location:
            'src/shared/config/store/effects.ts:subscribeOnLiveUpdate.wsUnrecognizedSession',
          rawError: event,
        });
      }
      yield put(configServiceActions.logOutSession('internal'));
      liveChannel.close();
      return; // finish "while" listen cycle
    } else {
      if (!Object.values(LiveEvent).includes(type)) {
        console.warn('Unknown event', event);
        yield logger.error('Stomp service responded with unknown event', {
          location: 'src/shared/config/store/effects.ts:subscribeOnLiveUpdate.else',
          rawError: event,
        });
      }
    }
  }

  function createLiveUpdateChannel() {
    return eventChannel((emit) => {
      const subscriptionId: string = liveUpdate.subscribe((event: LiveUpdateEvent) => emit(event));
      return () => liveUpdate.unsubscribe(subscriptionId);
    });
  }
}

function* subscribeOnWebEvents() {
  const webChannel: EventChannel<WebEvent> = yield call(createWebEventsChannel);

  while (true) {
    const event: WebEvent = yield take(webChannel);
    if (event.type === 'logout') {
      const eventSessionId = event.data;
      if (!document.hasFocus() && eventSessionId === session.sessionId) {
        yield put(configServiceActions.logOutSession('web-event'));
        webChannel.close();
        return; // finish "while" listen cycle
      }
    }
  }

  function createWebEventsChannel() {
    return eventChannel((emit) => {
      const subscription = configApi
        .webEvent$(['logout'])
        .pipe(
          tap((events) => emit(events)),
          catchError((error: Error) => {
            handleBackendError(error);
            return of(undefined);
          })
        )
        .subscribe();
      return () => subscription.unsubscribe();
    });
  }
}
