import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { Box } from '@components/box';
import { useModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:bank-account-history-page-balances-row',
});

export const BalancesRow: FC = memo(() => {
  const m = useModel();

  return (
    <div className={s.balancesRow}>
      <Box className={s.box}>
        <Title level={4}>
          <Trans id="bank-account-history.balances-row.start">
            Balance at the beginning of the period:
          </Trans>
        </Title>
        {m.balances?.balanceOnPeriodStart}
      </Box>

      <Box className={s.box}>
        <Title level={4}>
          <Trans id="bank-account-history.balances-row.end">
            Balance at the end of the period:
          </Trans>
        </Title>
        {m.balances?.balanceOnPeriodEnd}
      </Box>
    </div>
  );
});
