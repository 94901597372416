import { useMemo } from 'react';
import { useQuery } from '@hooks/core';
import { useCurrentRole } from '@config/hooks';
import { Role } from '@permissions/core';
import { SelectItem } from '@components/form';
import api from './api';
import { useDepositsStatuses } from '../hooks';

export const useDepositInvestors = () => {
  const currentRole = useCurrentRole();

  const isRoleOK = currentRole === Role.admin || currentRole === Role.accountManager;

  const { data: investorsData, isLoading: isInvestorsLoading } = useQuery(
    () => (isRoleOK ? api.getDepositInvestors() : Promise.resolve([])),
    { initial: [] }
  );

  const investors: Array<SelectItem> = useMemo(() => {
    return investorsData?.map((i) => ({
      label: i.displayName,
      value: i.id,
    }));
  }, [investorsData]);

  return { investors, isInvestorsLoading };
};

export const useStatuses = () => {
  const statuses = useDepositsStatuses();

  const depositStatuses: Array<SelectItem> = useMemo(() => {
    return Object.entries(statuses)?.map(([status, label]) => ({
      label: label,
      value: status,
    }));
  }, [statuses]);

  return { depositStatuses };
};
