import { useDispatch } from 'react-redux';
import { downloadFile, removeFile } from '../../services/uploadFileService';

export const useDownloadFileModel = () => {
  const dispatch = useDispatch();
  const onDownloadFile = (uploadFile) => dispatch(downloadFile(uploadFile));
  const onRemoveFile = (formName, fieldName) => dispatch(removeFile(formName, fieldName));

  return { onDownloadFile, onRemoveFile };
};
