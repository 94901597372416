import { modalSelectors } from 'shared/modules/modal/store/selectors';
import { TableHeaderSortType as Sort } from 'components/page-table';

const metalsPageState = (root) => root.main?.metalsPage;

const columnSortDirection = (root) => (columnId) =>
  metalsPageState(root).sort.filter((col) => col.id === columnId)[0].direction;

const isHasMore = (root) => metalsPageState(root).isHasMore;

const isTableLoading = (root) => metalsPageState(root).isMetalsLoading;

const lockUI = (root) => {
  const isModalOpened = modalSelectors.isModalOpened(root);
  const state = metalsPageState(root);
  return (
    isModalOpened ||
    state.isDownloadTransactionLoading ||
    state.isMetalsLoading ||
    state.isUploadTransactionLoading
  );
};

const pageIndex = (root) => metalsPageState(root).pageIndex;

const sort = (root) =>
  metalsPageState(root).sort.filter(
    (_sort) => _sort.direction === Sort.asc || _sort.direction === Sort.desc
  );

const metals = (root) => metalsPageState(root).metals;

export const metalsPageSelectors = {
  columnSortDirection,
  isHasMore,
  isTableLoading,
  lockUI,
  metals,
  pageIndex,
  sort,
  metalsPageState,
};
