import { useProfileOtherBalances } from './hooks';

export const useModel = () => {
  const { balances } = useProfileOtherBalances();

  return {
    primary: balances.primary,
    secondary: balances.secondary,
    hasPrimary: !!balances.primary.length,
    hasSecondary: !!balances.secondary.length,
  };
};
