import { useQuery } from '@hooks/core';
import api from './api';

export const useUserTariff = (userId: number) => {
  const { data: userTariff, isLoading: isUserTariffLoading } = useQuery(() =>
    api.getUserTariff(userId)
  );
  return {
    isUserTariffLoading,
    userTariff,
  };
};
