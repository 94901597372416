import _ from 'lodash';

import { SortItem, TableResponse } from '@models/core';
import { TransactionItem } from '../api';
import { TransactionsTableColumnIds as ColumnIds } from '../model';
import {
  TransactionsActionsTypes as Types,
  transactionsInitialState,
  TransactionsState,
} from './model';

export const transactionsReducer = (
  state: TransactionsState = transactionsInitialState,
  action
): TransactionsState => {
  switch (action.type) {
    case Types.GET_FILTERS: {
      return {
        ...state,
        isFiltersLoading: true,
      };
    }

    case Types.GET_FILTERS_FAIL: {
      return {
        ...state,
        isFiltersLoading: false,
      };
    }

    case Types.GET_FILTERS_SUCCESS: {
      return {
        ...state,
        filtersAvailable: action.filters,
        isFiltersLoading: false,
      };
    }

    case Types.GET_TRANSACTIONS: {
      return {
        ...state,
        isTransactionsLoading: true,
      };
    }

    case Types.GET_TRANSACTIONS_SUCCESS: {
      let page: TableResponse<TransactionItem> = action.payload;

      let currentTransactions = state.transactions;
      let receivedTransactions = page.data.filter((tx) => tx.success);

      let transactions = _.concat(
        currentTransactions.slice(0, page.index),
        receivedTransactions,
        currentTransactions.slice(page.index)
      );

      return {
        ...state,
        hasMore: page.hasMore,
        isTransactionsLoading: false,
        page: state.page + 1,
        transactions: _.uniqBy(transactions, 'id').map(roundDifference),
      };
    }

    case Types.ON_FILTERS_CHANGED: {
      return {
        ...state,
        filtersSelected: action.payload,
        page: 1,
        transactions: [],
        hasMore: true,
      };
    }

    case Types.ON_PAGE_OPENED: {
      return transactionsInitialState;
    }

    case Types.ON_TABLE_SORTED: {
      const column: SortItem<ColumnIds> = action.payload;
      const sort: Array<SortItem<ColumnIds>> = [...state.sort];
      const activeColumn: SortItem<ColumnIds> = sort.splice(
        sort.findIndex((col) => col.id === column.id),
        1
      )[0];
      activeColumn.direction = column.direction;

      return {
        ...state,
        hasMore: true,
        page: 1,
        sort: [activeColumn, ...sort],
        transactions: [],
      };
    }

    default: {
      return state;
    }
  }
};

const roundDifference = (item) => {
  if (item.difference && Math.abs(item.difference) < 0.01) {
    item.difference = 0;
  }
  return item;
};
