import React from 'react';
import { Trans } from '@lingui/macro';
import { Form, InputNumber, SubmitButton } from '@components/form';
import { Modal, MFC, ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { addAmountFormId, useAddAmountCoinModel } from './model';

export const AddAmountCoinModal: MFC<{ coin: object }> = ({ isVisible, coin }) => {
  const { lockUI, onAddAmountAction, onModalHide, issueLimit } = useAddAmountCoinModel({ coin });

  return (
    <Modal isVisible={isVisible} disabled={lockUI} loading={lockUI} onClose={onModalHide}>
      <ModalTitle>
        <Trans id="digital_metals.issue_digital_metals">Issue digital metals</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={addAmountFormId}>
          <InputNumber
            name="amount"
            label={<Trans id="digital_metals.amount">Amount</Trans>}
            required={true}
            disabled={lockUI}
            min={issueLimit.min}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={addAmountFormId}
          fullWidth={true}
          disabled={lockUI}
          onAction={onAddAmountAction}
        >
          <Trans id="button.save">Save</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
