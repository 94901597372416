import {
  AddSMTAState,
  addSMTAStateInitial,
  AddSMTAActionsTypes as SMTTypes,
  AddSMTAActions,
} from './model';

export const addSMTAReducer = (
  state: AddSMTAState = addSMTAStateInitial,
  action: AddSMTAActions
): AddSMTAState => {
  switch (action.type) {
    case SMTTypes.ADD_SMT_ACCOUNT: {
      return {
        ...state,
        isAddSMTAccountLoading: true,
      };
    }

    case SMTTypes.ADD_SMT_ACCOUNT_FAIL: {
      return {
        ...state,
        isAddSMTAccountLoading: false,
      };
    }

    case SMTTypes.ADD_SMT_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isAddSMTAccountLoading: false,
      };
    }

    case SMTTypes.ON_MODAL_OPENED: {
      return {
        ...addSMTAStateInitial,
        user: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
