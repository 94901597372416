import { ReactNode } from 'react';
import { PageContent, PageTitle, TitleTypography } from '@components/page';
import { Box } from '@components/box';

type PageTemplateProps = {
  documentTitle: string;
  titleElement: ReactNode;
  contentElement: ReactNode;
  uat?: string;
};

export function PageTemplate(props: PageTemplateProps) {
  return (
    <PageContent uat={props.uat}>
      <PageTitle documentTitle={props.documentTitle}>
        <TitleTypography backwardLink={'/digital-metals'}>{props.titleElement}</TitleTypography>
      </PageTitle>

      <Box>{props.contentElement}</Box>
    </PageContent>
  );
}
