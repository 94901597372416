import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import { MoreActions } from '@components/more-actions';
import { permissionsGroup as editPermissionsGroup } from '../../modals/handle-bank-account';
import { CMBankAccount } from '../api';
import { useModel } from './model';

const editAccess = { permissions: { and: [editPermissionsGroup] } };
type Props = { account: CMBankAccount };
export const TableActions: FC<Props> = memo<Props>((p) => {
  const m = useModel();

  return (
    <MoreActions
      placement="right"
      list={[
        {
          access: editAccess,
          action: () => m.onEditClicked(p.account.id),
          content: <Trans id="cm_bank_accounts.table.action.edit">Edit</Trans>,
        },
      ]}
    />
  );
});
