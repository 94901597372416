import { formService } from '@components/form';
import { AssetItem, transferCoinFormId } from '../store/model';
import { useSelector } from 'react-redux';
import { transferCoinTabSelectors as selectors } from '../store/selectors';
import { TransferDirection } from '../../model';
import { FormInstance } from 'antd';
import { useMemo } from 'react';

export const useFinishModalModel = (direction: string) => {
  const coinBalancesPrimary: Array<AssetItem> = useSelector(selectors.coinBalancesPrimary);
  const secondaryCoinBalances: Array<AssetItem> = useSelector(selectors.coinBalancesSecondary);

  const form: FormInstance = formService.get(transferCoinFormId);
  const asset = form?.getFieldsValue()?.asset; // TODO: это очень плохая практика
  const amount = form?.getFieldsValue()?.amount; // TODO: это очень плохая практика

  const primaryBalance: number = useMemo(
    () => coinBalancesPrimary.find((el) => el.asset === asset)?.balance || 0,
    [coinBalancesPrimary, asset]
  );

  const secondaryBalance: number = useMemo(
    () => secondaryCoinBalances.find((el) => el.asset === asset)?.balance || 0,
    [secondaryCoinBalances, asset]
  );

  const primaryMarketSign: string = direction === TransferDirection.fromPrimary ? '-' : '+';

  const secondaryMarketSign: string = direction === TransferDirection.fromPrimary ? '+' : '-';

  const newPrimaryMarketBalance: number =
    direction === TransferDirection.fromPrimary ? primaryBalance - amount : primaryBalance + amount;

  const newSecondaryMarketBalance: number =
    direction === TransferDirection.fromPrimary
      ? secondaryBalance + amount
      : secondaryBalance - amount;

  return {
    primaryMarketSign,
    secondaryMarketSign,
    newPrimaryMarketBalance,
    newSecondaryMarketBalance,
    asset,
    amount,
  };
};
