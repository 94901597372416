import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Button, Dropdown, Menu } from 'antd';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import s from './index.module.scss';
import { ReactComponent as ArrowRight } from '@images/icons/arrow-right-wide.svg';
import { ReactComponent as ProfileAvatar } from '@images/icons/profile-avatar-icon.svg';
import { Title } from '@components/typography/title';
import { HasAccess } from '@permissions/core';
import { theme } from '@constants/theme';
import { LINKS } from '@constants/link';
import { VerifyModalButton } from '../../../../../../containers/pages/post-registration/individual-steps/verification-upload/button';
import { permissionGroup as tutorialPermissionGroup } from '../../../../../../containers/pages/tutorial';
import { useModel } from './model';

const { Divider, Item } = Menu;

export const ProfileWidget: FC = memo(() => {
  const m = useModel();

  return (
    <Dropdown
      trigger={['click']}
      overlay={
        <Menu className={s.menu} theme={theme.dark} data-uat="profile-menu-list">
          <Item className="is-no-events text-ellipsis" key="user-name" data-uat="profile-menu-name">
            {m.fullName}
          </Item>

          <VerifyModalButton
            className={s.verification}
            visibility="mobile"
            icon={null}
            uat="profile-menu-verify-button"
          />

          <Divider className={s.divider} key="divider-1" />

          <Item key="profile-link">
            <Link className={s.item} to="/profile" data-uat="profile-menu-profile-item">
              <span className={s.itemText}>
                <Trans id="profile_menu.profile">Profile</Trans>
              </span>
              <ArrowRight className={s.itemIcon} />
            </Link>
          </Item>

          <Item key="help-link">
            <a
              className={s.item}
              href={LINKS.HELP_CENTER}
              target="_blank"
              data-uat="profile-menu-help-item"
            >
              <span className={s.itemText}>
                <Trans id="profile_menu.help_center">Help Center</Trans>
              </span>
              <ArrowRight className={s.itemIcon} />
            </a>
          </Item>

          <HasAccess permissions={{ or: [tutorialPermissionGroup] }}>
            <Item key="tutorial-link">
              <Link className={s.item} to="/tutorial" data-uat="profile-menu-tutorial-item">
                <span className={s.itemText}>
                  <Trans id="profile_menu.tutorial">How it works</Trans>
                </span>
                <ArrowRight className={s.itemIcon} />
              </Link>
            </Item>
          </HasAccess>

          <Divider className={s.divider} key="divider-2" />

          <Item
            key="sign-out-link"
            onClick={() => m.onLogoutClicked()}
            data-uat="profile-menu-logout-item"
          >
            <div className={s.item}>
              <span className={s.itemText}>
                <Trans id="profile_menu.logout">Sign Out</Trans>
              </span>
              <ArrowRight className={s.itemIcon} />
            </div>
          </Item>
        </Menu>
      }
    >
      <div className={s.profileLayout} data-uat="profile-menu">
        <Title
          className={classnames(s.profileText, 'mr2')}
          level={4}
          compact={true}
          uat="header-name"
        >
          {m.shortName}
        </Title>

        <Button className={s.profileButton} data-uat="profile-menu-button">
          <ProfileAvatar className={s.avatar} />
        </Button>
      </div>
    </Dropdown>
  );
});
