import { ExtractAction } from '@helper/ts';

export type State = {
  currentIndex: number | undefined;
  isShowHubspotFailed: boolean;
};

export const initialState: State = {
  currentIndex: undefined,
  isShowHubspotFailed: false,
};

export type Action = ExtractAction<{
  ON_POST_REG_INITIATED: number;
  ON_PREV_STEP_CLICKED: never;
  ON_STEP_COMPLETED: never;
  ON_TOGGLE_SHOW_HUBSPOT_FAILED: boolean;
}>;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ON_POST_REG_INITIATED': {
      return {
        ...state,
        currentIndex: action.payload,
      };
    }

    case 'ON_PREV_STEP_CLICKED': {
      return {
        ...state,
        currentIndex: state.currentIndex - 1,
      };
    }

    case 'ON_STEP_COMPLETED': {
      return {
        ...state,
        currentIndex: state.currentIndex + 1,
      };
    }

    case 'ON_TOGGLE_SHOW_HUBSPOT_FAILED': {
      return {
        ...state,
        isShowHubspotFailed: action.payload,
      };
    }

    default:
      return state;
  }
};
