import {
  createElement,
  createContext,
  useContext,
  ComponentType,
  Context as ReactContext,
} from 'react';
import { BaseItem, RowProps as OriginalRowProps } from '../core';
import { ColumnsContext, ColumnsContextValue } from './columns';
import { ColumnType, DefaultRowCellProps, RowCellWrapperProps, RowWrapperProps } from './types';

export type RowProps<
  T extends BaseItem,
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = Omit<OriginalRowProps<T>, 'columns'> & {
  columns: ColumnType<T, any, R>[];
  rowCellWrapperComponent?: ComponentType<RowCellWrapperProps<T, R>>;
  rowWrapperComponent?: ComponentType<RowWrapperProps<T>>;
};

export type RowContextValue<
  T extends BaseItem,
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = {
  rowCellWrapperComponent?: ComponentType<RowCellWrapperProps<T, R>>;
  rowComponent: ComponentType<RowProps<T, R>>;
  rowWrapperComponent?: ComponentType<RowWrapperProps<T>>;
};

export const RowContext = createContext<RowContextValue<any>>({
  rowComponent: () => null,
});

export function WrappedRow<
  T extends BaseItem,
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
>(props: OriginalRowProps<T>) {
  const { columns } = useContext(
    ColumnsContext as unknown as ReactContext<ColumnsContextValue<T, any, R>>
  );
  const { rowCellWrapperComponent, rowComponent, rowWrapperComponent } = useContext(
    RowContext as unknown as ReactContext<RowContextValue<T, R>>
  );
  return createElement(rowComponent, {
    ...props,
    columns,
    rowCellWrapperComponent,
    rowWrapperComponent,
  });
}
