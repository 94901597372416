import React, { FC } from 'react';

import './styles.scss';
import { useAuthorizedLayoutStateSelector } from '@components/layouts/dark/provider';

export const PreloaderLine: FC<{
  show?: boolean;
}> = ({ show: isForcedShow }) => {
  const isShow = useAuthorizedLayoutStateSelector((state) => state.preloaderLineShown);

  return !isShow && !isForcedShow ? null : (
    <div className="tm2-preloader-line">
      <PreloaderTrack />
    </div>
  );
};

export const PreloaderTrack = () => (
  <div className="tm2-preloader-line-track">
    <div className="tm2-preloader-line-track-runner" />
  </div>
);
