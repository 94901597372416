import { Selector } from '@models/redux';
import { BootstrapState } from './model';

const bootstrapState: Selector<BootstrapState> = (root) => root.bootstrap;

const isUserAuthorized: Selector<boolean> = (root) => bootstrapState(root)?.isUserAuthorized;

export const bootstrapSelectors = {
  isUserAuthorized,
};
