import { FC, ReactNode, Ref, CSSProperties } from 'react';
import {
  BaseItem,
  DefaultRowCellProps,
  RowCellWrapperProps as BoundRowCellWrapperPros,
  RowProps as BoundRowProps,
} from '../../../bound-cells';

export type { BaseItem } from '../../../bound-cells';

export { RowCellWrapperBase, RowCellWrapper } from './default-cell';

export type RowCellProps<T extends BaseItem> = DefaultRowCellProps<T>;

export type RowCellWrapperConfig = {};

export type RowCellWrapperProps<T extends BaseItem> = BoundRowCellWrapperPros<T, RowCellProps<T>>;

export type RowProps<T extends BaseItem> = BoundRowProps<T, RowCellProps<T>>;

const PassThrough: FC = ({ children }) => <>{children}</>;

const DefaultRowWrapper = (props: {
  children: ReactNode;
  containerRef: Ref<any>;
  style: CSSProperties;
}) => (
  <div ref={props.containerRef} style={props.style}>
    {props.children}
  </div>
);

export function Row<T extends BaseItem>(props: RowProps<T>) {
  const RowCellWrapper = props.rowCellWrapperComponent ?? PassThrough;
  const RowWrapper = props.rowWrapperComponent ?? DefaultRowWrapper;

  // TODO: bind callbacks

  return (
    <RowWrapper containerRef={props.containerRef} data={props.data} style={props.style}>
      {props.columns.map((c, index) => {
        const RowCell = c?.rowCellComponent;
        return (
          <RowCellWrapper
            key={index}
            data={props.data}
            config={c.rowCellConfig}
            width={props.columnWidthList[index]}
          >
            <RowCell data={props.data} />
          </RowCellWrapper>
        );
      })}
    </RowWrapper>
  );
}
