import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { TableResponse } from '@models/core';
import { SortOrder as QuerySortOrder } from '@models/sorting';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Asset, Currency, Fee } from './types';

type GetFeesFee = {
  TM2Fee: number;
  allFee: number;
  asset: string | null;
  clientId: number;
  clientName: string;
  currency: string;
  dateTime: string;
  feePercent: number;
  feeType: string;
  total: number;
  totalExcludeFee: number;
  transactionId: string | null;
  units: number | null;
};

export type GetFeesFilter = {
  asset?: string;
  clientId?: number;
  currencyId?: number;
  date?: {
    from: string;
    to: string;
  };
  feeType?: string;
};

export enum GetFeesSortKey {
  asset = 'asset',
  dateTime = 'dateTime',
  fee = 'allFee',
  feePct = 'feePercent',
  tm2Fee = 'TM2Fee',
  total = 'total',
  totalExcludeFee = 'totalExcludeFee',
  transactionId = 'transactionId',
  units = 'units',
}

export type GetFeesSortOrder = QuerySortOrder<GetFeesSortKey>;

const getFeesQuery = 'feesReportAsPage';
const getFeesFields = [
  {
    data: [
      'allFee',
      'asset',
      'clientId',
      'clientName',
      'currency',
      'dateTime',
      'feePercent',
      'feeType',
      'tm2Fee',
      'total',
      'totalExcludeFee',
      'transactionId',
      'units',
    ],
  },
  'index',
  'hasMore',
];
const getFees = makeQuery({
  permissions: Permission.EXECUTION_FEE_VIEW_REPORT,
  queryName: getFeesQuery,
  queryFields: getFeesFields,
  query: (params: {
    filter: GetFeesFilter;
    ordering: GetFeesSortOrder;
    pageNumber: number;
  }): Promise<TableResponse<Fee>> => {
    const filterParam = {
      ...params.filter,
      currency:
        typeof params.filter.currencyId === 'number'
          ? {
              id: params.filter.currencyId,
            }
          : null,
    };
    delete filterParam.currencyId;

    const reducedFilterParam = Object.entries(filterParam).reduce((acc, [key, val]) => {
      if (val !== null) {
        acc[key] = val;
      }
      return acc;
    }, {});

    const typedValues = {
      ...v.feesReportFilterInput({ filter: reducedFilterParam }),
      ...v.orderInput({ ordering: params.ordering }),
      ...{ page: params.pageNumber },
    };

    return stompClient.getData<TableResponse<GetFeesFee>>(
      getFeesQuery,
      sign(getFeesQuery, getFeesFields),
      typedValues
    );
  },
});

type GetAssetsAsset = {
  id: number;
  asset: string;
  metal: {
    label: string;
  };
};

const getAssetsQuery = 'coins';
const getAssetsFields = ['id', 'asset', { metal: ['label'] }];
const getAssets = makeQuery({
  queryName: getAssetsQuery,
  queryFields: getAssetsFields,
  query: (): Promise<Asset[]> => {
    return stompClient
      .getData<GetAssetsAsset[]>(getAssetsQuery, sign(getAssetsQuery, getAssetsFields))
      .then((response) =>
        response.map((coin) => ({
          id: coin.id,
          asset: coin.asset,
          metal: coin.metal.label,
        }))
      );
  },
});

type GetCurrenciesCurrency = {
  id: number;
  code: string;
};

const getCurrenciesQuery = 'currencies';
const getCurrenciesFields = ['id', 'code'];
const getCurrencies = makeQuery({
  queryName: getCurrenciesQuery,
  queryFields: getCurrenciesFields,
  query: (): Promise<Currency[]> => {
    return stompClient.getData<GetCurrenciesCurrency[]>(
      getCurrenciesQuery,
      sign(getCurrenciesQuery, getCurrenciesFields)
    );
  },
});

const getFeeTypesQuery = 'feesReportFeeTypes';
const getFeeTypesFields = ['value'];
const getFeeTypes = makeQuery({
  queryName: getFeeTypesQuery,
  queryFields: getFeeTypesFields,
  query: (): Promise<string[]> => {
    return stompClient
      .getData<{ value: string[] }>(getFeeTypesQuery, sign(getFeeTypesQuery, getFeeTypesFields))
      .then(({ value }) => value);
  },
});

const feesReportXlsFields = ['value'];
const downloadFeesReportQuery = 'feesReportAsXLSX';
const downloadFeesReport = makeQuery({
  permissions: { or: [Permission.EXECUTION_FEE_VIEW_REPORT] },
  queryName: downloadFeesReportQuery,
  queryFields: feesReportXlsFields,
  query: (): Promise<string> =>
    stompClient
      .getData(downloadFeesReportQuery, sign(downloadFeesReportQuery, feesReportXlsFields))
      .then((response) => response.value),
});

const api = {
  getAssets,
  getCurrencies,
  getFees,
  getFeeTypes,
  downloadFeesReport,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:referrals-report-page');

export default api;
