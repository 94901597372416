import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { columnFactory, OnSort as OnSortGeneric, Column } from '../shared/table/columns';
import {
  SortItem as GenericSortItem,
  SortOrder as GenericSortOrder,
} from '../shared/table/sorting';
import tableStyles from '../shared/table/index.module.scss';
import { RedeemCell } from './redeem-cell';
import s from './index.module.scss';
import { formatToken } from '../../../services/commonService';

export type { SortDirection } from '../shared/table/sorting';

export type TableCoin = {
  id: number;
  amountPrimary: number | null;
  amountSecondary: number | null;
  asset: string;
  brandName: string;
  metal: string;
  physicalRedemption: boolean;
  hasActiveRedeem: boolean;
};

export type SortItem = GenericSortItem<TableCoin>;

export type SortOrder = GenericSortOrder<TableCoin>;

export type OnSort = OnSortGeneric<keyof TableCoin>;

const MarketQuantity = () => (
  <Trans id={'wallet.wallet-full.table.amount.market-quantity'}>Quantity</Trans>
);

export function useColumns(data: TableCoin[] | null, onSort: OnSort) {
  return useMemo<Column<TableCoin>[]>(
    () =>
      data === null
        ? null
        : [
            columnFactory({
              cell: (data: TableCoin) => data.asset,
              field: 'asset',
              head: <Trans id={'wallet.wallet-full.table.symbol'}>Symbol</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: TableCoin) =>
                data.amountPrimary ? formatToken(data.amountPrimary) : '-',
              field: 'amountPrimary',
              head: (
                <span className={tableStyles.tableHeadCellMultiRowContent}>
                  <MarketQuantity />
                  <small className={tableStyles.tableHeadCellSecondaryText}>
                    <Trans id={'wallet.wallet-full.table.amount.primary'}>Primary Market</Trans>
                  </small>
                </span>
              ),
              onSort,
              className: tableStyles.tableHeadCellMultiRow,
            }),

            // columnFactory({
            //   cell: (data: TableCoin) =>
            //     data.amountSecondary ? formatToken(data.amountSecondary) : '-',
            //   field: 'amountSecondary',
            //   head: (
            //     <span className={tableStyles.tableHeadCellMultiRowContent}>
            //       <MarketQuantity />
            //       <small className={tableStyles.tableHeadCellSecondaryText}>
            //         <Trans id={'wallet.wallet-full.table.amount.secondary_excl_offers'}>
            //           Secondary market (excl.placed offers)
            //         </Trans>
            //       </small>
            //     </span>
            //   ),
            //   onSort,
            //   className: tableStyles.tableHeadCellMultiRow,
            // }),

            columnFactory({
              cell: (data: TableCoin) => data.brandName,
              field: 'brandName',
              head: <Trans id={'wallet.wallet-full.table.brand'}>Brand</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: TableCoin) => data.metal,
              field: 'metal',
              head: <>Metal</>,
              onSort,
            }),

            columnFactory({
              cell: (data: TableCoin) => <RedeemCell redeemCoin={data} />,
              field: 'physicalRedemption',
              head: null,
              onSort,
              className: s.actionsHeadCell,
            }),
          ],
    [data, onSort]
  );
}
