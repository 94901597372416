import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { OnFormAction } from '@components/form';
import { DispatchThunkFn } from '@models/redux';
import { closeModalAction, MFC } from '@modules/modal';
import { notifySuccess } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { beneficialApiService as api, BeneficialOwner } from '../beneficial-api.service';
import { beneficialOwnersServiceActions as actions } from '../store/service.acions';

export const formId: string = 'blockMetalFormId';

interface Form {
  blockedMetals: Array<string>;
}

export const useModel = (
  modal: MFC<BlockMetalModalProps>,
  ownerId,
  blockedMetals: Form['blockedMetals']
) => {
  const [lockUI, setLockUI] = useState<boolean>(false);
  const [formInitial] = useState<Form>({ blockedMetals });

  const closeModal = useCallDispatch(closeModalAction);

  const onFormAction: OnFormAction = {
    finally: () => setLockUI(false),
    submit: async (form: Form, dispatch: DispatchThunkFn) => {
      setLockUI(true);
      await api.updateOwnerBlockedMetals(ownerId, form.blockedMetals);
      await dispatch(actions.eventBlockedMetalsUpdated({ ownerId, metals: form.blockedMetals }));
      await closeModal(modal);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="block_asset_modal.notification.success_update.text">
            Client product statuses have been successfully updated
          </Trans>
        ),
      });
    },
  };

  return {
    formInitial,
    lockUI,
    onCloseClicked: () => closeModal(modal),
    onFormAction,
  };
};

export interface BlockMetalModalProps {
  blockedMetals: Form['blockedMetals'];
  owner: BeneficialOwner;
}
