import { BidOrderStatus } from '../../shared/types';
import { PermissionGroup } from '@permissions/core';
import { MoreActions } from '@components/more-actions';
import { Trans } from '@lingui/macro';
import {
  approveBidOrderPermissionGroup,
  denyBidOrderPermissionGroup,
  useStatusActionsModel,
  ModelParams,
} from './status-actions-model';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [approveBidOrderPermissionGroup, denyBidOrderPermissionGroup],
  marker: 'layout:status-actions',
});

type StatusActionsProps = {
  className?: string;
  bidOrderId: number;
  bidOrderStatus: BidOrderStatus;
  onBidOrderApproved?: ModelParams['onBidOrderApproved'];
  onBidOrderDenied?: ModelParams['onBidOrderDenied'];
};

export function StatusActions(props: StatusActionsProps) {
  const { showApproveBidOrderModal, showDenyBidOrderModal } = useStatusActionsModel({
    bidOrderId: props.bidOrderId,
    onBidOrderApproved: props.onBidOrderApproved,
    onBidOrderDenied: props.onBidOrderDenied,
  });

  return (
    <MoreActions
      triggerClassName={props.className}
      placement={'right'}
      list={[
        {
          access: {
            extraCheck: () => props.bidOrderStatus === BidOrderStatus.created,
            permissions: { or: [approveBidOrderPermissionGroup] },
          },
          action: () => showApproveBidOrderModal(),
          content: <Trans id="button.approve">Approve</Trans>,
        },
        {
          access: {
            extraCheck: () => props.bidOrderStatus === BidOrderStatus.created,
            permissions: { or: [denyBidOrderPermissionGroup] },
          },
          action: () => showDenyBidOrderModal(),
          content: <Trans id="button.deny">Deny</Trans>,
        },
      ]}
    />
  );
}
