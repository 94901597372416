import { FC, memo, useState } from 'react';
import { base64StringToBlob } from 'blob-util';
import { saveAs } from 'file-saver';
import { handleBackendError } from '@modules/notify';
import { ButtonPrimary } from '@components/button';
import { Trans } from '@lingui/macro';
import api, { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:users-report',
});

export const UsersReport: FC = memo(() => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const handleDownload = async () => {
    try {
      setLockUI(true);
      const report: string = await api.getUsersReport();
      const blob = base64StringToBlob(report, 'application/xlsx');
      saveAs(blob, `users_report.xlsx`);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return (
    <ButtonPrimary
      disabled={lockUI}
      onClick={handleDownload}
      uat="statements-users-report"
      className="uat-statements-item"
    >
      <Trans id="statements.users_report.users_source_data">Users source data</Trans>
    </ButtonPrimary>
  );
});
