import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { Modal, ModalContent, ModalFooter, ModalTitle, MFC } from '@modules/modal';
import { useCancelOfferModalModel } from './model';
import { Offer } from './types';

export { permissionGroup } from './api';

export type { Offer } from './types';

export type CancelOfferModalProps = {
  offerId: number;
  onClose: () => void;
  onOfferCancelled: (offer: Offer) => void;
};

export const CancelOfferModal: MFC<CancelOfferModalProps> = ({
  isVisible,
  offerId,
  onClose,
  onOfferCancelled,
}) => {
  const { cancelling, cancelOffer } = useCancelOfferModalModel({
    offerId,
    onOfferCancelled,
    onClose,
  });

  return (
    <Modal isVisible={isVisible} disabled={cancelling} loading={cancelling} onClose={onClose}>
      <ModalTitle>
        <Trans id="cancel_bid_modal.cancel.bid">Cancel offer?</Trans>
      </ModalTitle>
      <ModalContent>
        <div className="mb4">
          <Trans id="cancel_bid_modal.cancelled.bid.cannot.be.confirmed_v2">
            Cancelled offers cannot be executed by any trader.
          </Trans>
        </div>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} disabled={cancelling} onClick={cancelOffer}>
          <Trans id="offers_list.cancel_bid_modal.button.confirm">Confirm</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
