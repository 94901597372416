import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from '../index.module.scss';
import { Title } from '@components/typography/title';
import { UserProfileLink } from '@components/link';
import { Profile } from '../../../../api.shared';

export const LinkedIssuer: FC<{ classname: string; profile: Profile }> = ({
  classname,
  profile,
}) => {
  const email = profile.investorIssuer?.email;
  const id = profile.investorIssuer?.id;

  return !id || !email ? null : (
    <div className={classnames(s.row, classname)}>
      <div>
        <Title level={4} color="secondary">
          <Trans id="profile.account_details.linked_issuer">Linked issuer account</Trans>
        </Title>

        <UserProfileLink id={id} text={email} uat="linked-issuer-link" />
      </div>
    </div>
  );
};
