import { combineReducers } from 'redux';

import { State as PaymentsState } from './payments/store/model';
import { reducer as paymentsPageReducer } from './payments/store';
import { State as ManagementFeeReportPageState } from './management-fee/store/model';
import { reducer as managementFeeReportPageReducer } from './management-fee/store/index';

export interface StatementsStore {
  managementFees: ManagementFeeReportPageState;
  payments: PaymentsState;
}

export const statementsStoreReducer = combineReducers({
  managementFees: managementFeeReportPageReducer,
  payments: paymentsPageReducer,
});
