import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { Text } from '@components/typography/text';
import { Title } from '@components/typography/title';

import { Divider } from '@components/divider';
import { Modal, ModalContent, ModalTitle } from '@modules/modal';
import { Form, InputNumber, Select, SubmitButton } from '@components/form';
import { formatMoney } from 'containers/services/commonService';
import { maxDecimals } from '@constants/math';
import { PermissionGroup } from '@permissions/core';

import { permissionsGroup as apiPermissionsGroup } from './api';
import { Fields, formId, useModel } from './model';
import s from './index.module.scss';

import type { MFC } from '@modules/modal';
import type { Withdrawal } from './api';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup],
  marker: 'layout:request-withdrawal-modal',
});

type Props = {
  addWithdrawal: (withdrawal: Withdrawal) => void;
};
export const CreateWithdrawalOtherModal: MFC<Props> = (p) => {
  const m = useModel(p.addWithdrawal, CreateWithdrawalOtherModal);

  return (
    <Modal
      isVisible={p.isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      size="big"
      onClose={m.onCloseModalClicked}
      className={s.modal}
    >
      <ModalTitle>
        <Trans id="withdraw_modals.request_withdraw_v2">Withdrawal request</Trans>
      </ModalTitle>

      <ModalContent className="withdrawal-request-wrapper">
        <>
          <Form className="mb1" name={formId}>
            <div className={s.form}>
              <InputNumber
                name={Fields.userId}
                className={s.field}
                label={<Trans id="withdraw_modals.client_id">Client ID</Trans>}
                onChange={m.onSelectedUserIdChanged}
                required={true}
              />

              {!m.selectedUserId ? null : !m.userDisplayName ? (
                <Title level={4}>
                  <Trans id="withdraw_modals.full_name.not_exist">User doesn't exist</Trans>
                </Title>
              ) : (
                <div className={classnames(s.field, s.name)}>
                  <Title level={4}>
                    <Trans id="withdraw_modals.full_name">Full name</Trans>:
                  </Title>
                  <Text level={1}>{m.userDisplayName}</Text>
                </div>
              )}
            </div>

            <div className={s.form}>
              <Select
                name={Fields.currency}
                className={s.field}
                label={<Trans id="withdraw_modals.currency">Currency</Trans>}
                disabled={m.lockUI || !m.selectedUserId}
                data={m.currencies}
                onChange={m.onCurrencyChanged}
                required={true}
              />

              <InputNumber
                name={Fields.amount}
                className={s.field}
                label={<Trans id="withdraw_modals.amount">Amount</Trans>}
                extra={
                  <>
                    <div>
                      <Trans id="create_withdraw_modal.label.amount_min">
                        Minimum withdrawal amount
                      </Trans>
                      {formatMoney(m.minWithdrawalAmount, {
                        post: m.selectedCurrencyCode || 'units',
                      })}
                    </div>
                    {!m.selectedCurrencyCode ? null : (
                      <div>
                        <Trans id="create_withdraw_modal.label.amount_max">
                          Available withdrawal amount
                        </Trans>
                        {formatMoney(m.maxWithdrawalAmount, {
                          post: m.selectedCurrencyCode,
                        })}
                      </div>
                    )}
                  </>
                }
                onChange={m.onAmountChanged}
                min={0}
                max={m.maxWithdrawalAmount}
                maxDecimals={maxDecimals}
                disabled={m.lockUI || !m.selectedUserId}
                required={true}
              />
            </div>

            <Divider className="mb3" />

            <Select
              label={<Trans id="withdraw_modals.bank_details">Bank details</Trans>}
              name={Fields.userBankAccount}
              disabled={m.lockUI || !m.selectedUserId}
              data={m.bankAccounts}
              onChange={m.onBankAccountChanged}
              required={true}
              extra={
                m.isCurrenciesMatched ? null : (
                  <Text level={2} className={classnames(s.unmatchedMessage, 'mt1')}>
                    <Trans id="withdraw_modals.unmatched_currencies">
                      Your selected currency doesn’t match the currency of your bank details, this
                      may cause additional conversion costs.
                    </Trans>
                  </Text>
                )
              }
            />

            {!m.selectedBankAccount ? null : (
              <div className={s.accountInfo}>
                <AccountItem
                  label={<Trans id="create_withdrawal_modal.nickname">Account nickname</Trans>}
                  value={m.selectedBankAccount.nickname}
                />

                <AccountItem
                  label={<Trans id="create_withdrawal_modal.currency">Currency</Trans>}
                  value={m.selectedBankAccount.currency?.code}
                />

                <AccountItem
                  label={<Trans id="create_withdrawal_modal.bank_name">Bank name</Trans>}
                  value={m.selectedBankAccount.bankName}
                />

                <AccountItem
                  label={<Trans id="create_withdrawal_modal.account_number">Account number</Trans>}
                  value={m.selectedBankAccount.accountNumber}
                />

                <AccountItem
                  label={<Trans id="create_withdrawal_modal.route_code">Bank Route Code</Trans>}
                  value={m.selectedBankAccount.routeCode}
                />

                <AccountItem
                  label={
                    <Trans id="create_withdrawal_modal.bank_swift_code">
                      Bank SWIFT or ABA Code
                    </Trans>
                  }
                  value={m.selectedBankAccount.swiftCode}
                />

                <AccountItem
                  label={<Trans id="create_withdrawal_modal.iban">IBAN:</Trans>}
                  value={m.selectedBankAccount.ibanNumber}
                />

                <AccountItem
                  className={s.comments}
                  label={<Trans id="create_withdrawal_modal.comment">Comments</Trans>}
                  value={m.selectedBankAccount.comment}
                />
              </div>
            )}

            <Divider className="mb2" />
          </Form>

          <div className={s.row}>
            <Title level={4}>
              <Trans id="create_withdraw_modal.label.note">
                Please note: Withdrawal fees will be determined at the time of withdrawal. If you
                want to get a fee level - please contact support@tm2.com
              </Trans>
            </Title>
          </div>

          <Divider className="mb2" />

          <div className={classnames(s.row, 'mb2')}>
            <Title className="mr3" level={4}>
              <Trans id="create_withdraw_modal.label.fee">Wire withdrawal fee</Trans>
            </Title>
            {!m.selectedCurrencyCode ? null : (
              <Title className="ml-auto" level={4}>
                {m.withdrawalFee} {m.selectedCurrencyCode}
              </Title>
            )}
          </div>
        </>

        <div className={s.footer}>
          <SubmitButton
            name={formId}
            fullWidth={true}
            disabled={m.lockUI || !m.selectedUserId}
            onAction={m.onFormAction}
          >
            <Trans id="button.create_withdrawal_request">Create withdrawal request</Trans>
          </SubmitButton>
        </div>
      </ModalContent>
    </Modal>
  );
};

const AccountItem: FC<{
  label: ReactNode;
  value: ReactNode;
  className?: string;
}> = (p) => {
  return !p.value ? null : (
    <div className={classnames(p.className, s.accountItem, 'mb2')}>
      <Title level={4}>{p.label}</Title>
      <Text>{p.value}</Text>
    </div>
  );
};
