import sign from 'tm2sign.macro';
import { downloadFile } from 'containers/services/commonService';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import gqlFields from './fields';
import { WireTransferForm } from './add-funds-modal/wire-transfer/store/model';
import { CreditCardViewForm } from './add-funds-modal/credit-card/store/model';
import { currenciesFilter } from '@hot-fix/currency';

const {
  createInvoiceFields,
  currentUserFields,
  externalBankAccountBalancesFields,
  usersWithBankAccountsFields,
} = gqlFields;

class DepositsApiService {
  public generateDeposit(form: WireTransferForm, extId = false): Promise<DepositNew> {
    let typedValues = {
      ...v.createInvoiceRequestInput({ request: form }),
    };
    return stompClient.sendData('createInvoice', createInvoiceFields, typedValues);
  }

  public getBankAccounts(): Promise<Array<BankAccount>> {
    return stompClient
      .getData('currentUser', currentUserFields)
      .then((response) => response.bankAccounts)
      .then((accounts) => accounts.filter((account) => currenciesFilter(account.currency)));
  }

  public getExternalClientBankAccounts(): Promise<Array<ExternalClientBankAccount>> {
    const typedValues = {
      ...v.externalBankAccountsBalanceType({ type: 'CLIENTS' }),
    };
    return stompClient.getData(
      'externalBankAccountBalances',
      externalBankAccountBalancesFields,
      typedValues
    );
  }

  public getInvestors(): Promise<Array<FundsInvestor>> {
    return stompClient.getData('traders', usersWithBankAccountsFields).then((investors) =>
      investors.map((investor) => ({
        ...investor,
        bankAccounts: investor.bankAccounts.filter((account) => currenciesFilter(account.currency)),
      }))
    );
  }

  public preparePayment(form: CreditCardViewForm): Promise<string> {
    const fields = sign('createPayment', ['link']);
    const { address, email, firstName, lastName, ...formSend } = form;
    const typedValues = {
      ...v.createPaymentRequestInput({ request: { ...formSend } }),
    };
    return stompClient
      .sendData('createPayment', fields, typedValues)
      .then((response: { link: string }) => response.link);
  }
}

export const depositsApiService = new DepositsApiService();

export const downloadInvoice = (id) => downloadFile(`invoices/${id}/pdf`, `invoice-${id}.pdf`);

export interface DepositNew extends Omit<DepositItem, 'bankAccount'> {
  bankAccount: {
    accountNumber: string;
    address: string;
    bankName: string;
    beneficiaryName: string;
    currency: {
      id: number;
      code: string;
      name: string;
    };
    id: number;
    swiftCode: string;
    referenceId: string;
  };
  investorUser: {
    address: string;
    displayName: string;
    email: string;
    id: string;
    phone: string;
  };
}

enum DepositStatus {
  balanceUpdated = 'BALANCE_UPDATED',
  cancelled = 'CANCELLED',
  created = 'CREATED',
  paid = 'PAID',
  partlyPaid = 'PARTLY_PAID',
}

export interface DepositItem {
  amount: number;
  bankAccount: BankAccount;
  bankName: string;
  clientComment: string;
  creationDate: string;
  id: number;
  investorUser: {
    id: string;
    displayName: string;
  };
  number: number;
  payment: {
    accountDetails: string;
    amount: number;
    creationDate: string;
  };
  status: DepositStatus;
  reference: string;
  externalId?: string;
  purpose: string;
  sourceOfFunds: string;
}

export interface FundsInvestor {
  bankAccounts: Array<BankAccount>;
  displayName: string;
  id: number;
}

export interface BankAccount {
  currency: {
    code: string;
    id: number;
  };
  id: number;
}

export interface ExternalClientBankAccount {
  computedFields: {
    displayName: string;
  };
  currency?: {
    id: number;
  };
  externalId: string;
}
