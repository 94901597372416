import { ReduxAction } from '@models/redux';
import { UserItem } from '../../users-api.service';
import { AddSMTAActionsTypes as T } from './model';

class OnCloseModalClickedAction extends ReduxAction<T.ON_CLOSE_MODAL_CLICKED, void> {
  public readonly type: T.ON_CLOSE_MODAL_CLICKED = T.ON_CLOSE_MODAL_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnModalOpenedAction extends ReduxAction<T.ON_MODAL_OPENED, UserItem> {
  public readonly type: T.ON_MODAL_OPENED = T.ON_MODAL_OPENED;
  public constructor(public payload: UserItem) {
    super(payload);
  }
}

export const addSMTAUserActions = {
  onCloseModalClicked: () => new OnCloseModalClickedAction().get(),
  onModalOpened: (user: UserItem) => new OnModalOpenedAction(user).get(),
};

export type AddSMTAUserActionsTypes = OnCloseModalClickedAction | OnModalOpenedAction;

export declare namespace AddSMTAUserActions {
  type onCloseModalClicked = OnCloseModalClickedAction;
  type onModalOpened = OnModalOpenedAction;
}
