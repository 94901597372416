import React, { memo, ReactNode } from 'react';
import { Link as LinkDeprecated } from 'react-router-dom';
import classnames from 'classnames';

import { maskCfgDark } from '@constants/theme';
import { Icon } from 'components/icon';

type Props = {
  className?: string;
  children: ReactNode;
  to: string;
  uat?: string;
};
export const StatementsLink = memo<Props>((props) => {
  return (
    <LinkDeprecated to={props.to} data-uat={props.uat} className="uat-statements-item">
      <div className={classnames('long-item-w-link', props.className)}>
        {props.children}
        <Icon icon="right-arrow-big-white" mask={maskCfgDark} />
      </div>
    </LinkDeprecated>
  );
});
