import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { closeModalAction, ModalContent, ModalFooter, ModalTitle } from 'shared/modules/modal';
import { LINKS } from '@constants/link';
import { ButtonPrimary } from '@components/button';
import { Modal } from '@modules/modal';

export const SecondaryMarketDisabledModal = ({ isVisible }) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModalAction(SecondaryMarketDisabledModal));

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <ModalTitle>
        <Trans id="secondary_market_disabled_modal.title">
          Access to the secondary market is limited
        </Trans>
      </ModalTitle>

      <ModalContent>
        <div className="mb-md">
          <Trans id="secondary_market_disabled_modal.text_links_customer">
            Secondary Market key is not specified in the profile settings. Please contact{' '}
            <a href={LINKS.SUPPORT} target="_blank">
              Customer support
            </a>
            .
          </Trans>
        </div>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} onClick={onClose}>
          <Trans id="secondary_market_disabled_modal.button.ok">OK</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
