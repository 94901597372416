import { useEffect, useState } from 'react';
import { of, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { handleBackendError } from '@modules/notify';
import { useBidOrderStatuses } from 'containers/pages/offers/service'; // TODO плохая практика
import {
  useIsApprovedBidOrdersLoading,
  useOnApprovedBidOrdersLoading,
  useSelectedPair,
} from '../provider';
import { useTradeHistoryHeight } from '../service';
import { metalsApiService as metalsApi, TickerItem, TradeHistoryItem } from '../metals-api.service';

export const useTradeHistoryModel = () => {
  const statusColors = {
    CREATION_PENDING: 'bg-color-gray',
    CREATED: 'bg-color-green',
    CREATION_ERROR: 'bg-color-red',
    APPROVE_PENDING: 'bg-color-gray',
    APPROVED: 'bg-color-green',
    APPROVING_ERROR: 'bg-color-red',
    DENY_PENDING: 'bg-color-gray',
    DENIED: 'bg-color-red',
    DENYING_ERROR: 'bg-color-red',
  };

  const lockUI = useIsApprovedBidOrdersLoading();
  const setLockUI = useOnApprovedBidOrdersLoading();
  const [tradeHistory, setTradeHistory] = useState<Array<TradeHistoryItem>>([]);
  const height = useTradeHistoryHeight();
  const orderStatuses = useBidOrderStatuses();
  const selectedPair: TickerItem = useSelectedPair();
  const coinId: number = selectedPair?.coin?.id;
  const currencyId: number = selectedPair?.currency?.id;

  useEffect(() => {
    if (!coinId || !currencyId) {
      return;
    }
    const subscription = metalsApi
      .tradeHistory$(coinId, currencyId)
      .pipe(
        tap((history: Array<TradeHistoryItem>) => {
          setTradeHistory(history);
          setLockUI(false);
        }),
        catchError((error) => {
          handleBackendError(error);
          return of(undefined);
        })
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, [coinId, currencyId, setLockUI]);

  return {
    currencyCode: selectedPair?.currency?.code,
    height,
    lockUI,
    orderStatuses,
    statusColors,
    tradeHistory,
  };
};
