import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import { marketingCompaniesReducer } from './marketing/store/reducer';
import { marketingCompaniesEffects } from './marketing/store/effects';

export const tradingPlatformReducer = combineReducers({
  marketingCompanies: marketingCompaniesReducer,
});

export function* tradingPlatformEffects() {
  yield all([fork(marketingCompaniesEffects)]);
}
