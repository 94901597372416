import { TableHeaderSortType as Sort } from 'components/page-table';

export const MetalsTableColumnIds = {
  issuer: 'issuer',
  metal: 'metal',
  symbol: 'symbol',
};

const columnSort = [
  {
    direction: Sort.asc,
    fields: ['issuer.firstName', 'issuer.lastName', 'issuer.company'],
    id: MetalsTableColumnIds.issuer,
  },
  { direction: Sort.notSet, fields: ['metal.label'], id: MetalsTableColumnIds.metal },
  { direction: Sort.notSet, fields: ['asset'], id: MetalsTableColumnIds.symbol },
];

export const metalsPageStateInitial = {
  isHasMore: true,
  isDownloadTransactionLoading: false,
  isMetalsLoading: false,
  isUploadTransactionLoading: false,
  metals: [],
  pageIndex: -1, // TODO стандартизировать значение
  sort: columnSort,
};

export const MetalsPageActionsTypes = {
  DOWNLOAD_TRANSACTION: 'METALS_PAGE.DOWNLOAD_TRANSACTION',
  DOWNLOAD_TRANSACTION_FAIL: 'METALS_PAGE.DOWNLOAD_TRANSACTION_FAIL',
  DOWNLOAD_TRANSACTION_SUCCESS: 'METALS_PAGE.DOWNLOAD_TRANSACTION_SUCCESS',
  LOAD_MORE_METALS: 'METALS_PAGE.LOAD_MORE_METALS',
  LOAD_MORE_METALS_FAIL: 'METALS_PAGE.LOAD_MORE_METALS_FAIL',
  LOAD_MORE_METALS_SUCCESS: 'METALS_PAGE.LOAD_MORE_METALS_SUCCESS',
  ON_DOWNLOAD_CLICKED: 'METALS_PAGE.ON_DOWNLOAD_CLICKED',
  ON_PAGE_OPENED: 'METALS_PAGE.ON_PAGE_OPENED',
  ON_TABLE_SCROLLED: 'METALS_PAGE.ON_TABLE_SCROLLED',
  ON_TABLE_SORTED: 'METALS_PAGE.ON_TABLE_SORTED',
  ON_UPLOAD_CLICKED: 'METALS_PAGE.ON_UPLOAD_CLICKED',
  UPLOAD_TRANSACTION: 'METALS_PAGE.UPLOAD_TRANSACTION',
  UPLOAD_TRANSACTION_FAIL: 'METALS_PAGE.UPLOAD_TRANSACTION_FAIL',
  UPLOAD_TRANSACTION_SUCCESS: 'METALS_PAGE.UPLOAD_TRANSACTION_SUCCESS',
};
