import { SortItem } from '@models/core';
import { ReduxAction } from '@models/redux';
import {
  LoadMarketingCompaniesSuccessPayload as SuccessPayload,
  MarketingTableActionTypes as T,
  MarketingTableColumnIds as ColumnId,
} from './model';

class LoadMoreMarketingCompaniesAction extends ReduxAction<
  T.LOAD_MORE_MARKETING_COMPANIES,
  SortItem<ColumnId>
> {
  public readonly type: T.LOAD_MORE_MARKETING_COMPANIES = T.LOAD_MORE_MARKETING_COMPANIES;
  public constructor(public payload: SortItem<ColumnId>) {
    super(payload);
  }
}

class LoadMoreMarketingCompaniesFailAction extends ReduxAction<
  T.LOAD_MORE_MARKETING_COMPANIES_FAIL,
  Error
> {
  public readonly type: T.LOAD_MORE_MARKETING_COMPANIES_FAIL = T.LOAD_MORE_MARKETING_COMPANIES_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class LoadMoreMarketingCompaniesSuccessAction extends ReduxAction<
  T.LOAD_MORE_MARKETING_COMPANIES_SUCCESS,
  SuccessPayload
> {
  public readonly type: T.LOAD_MORE_MARKETING_COMPANIES_SUCCESS =
    T.LOAD_MORE_MARKETING_COMPANIES_SUCCESS;
  public constructor(public payload: SuccessPayload) {
    super(payload);
  }
}

export const marketingCompaniesServiceActions = {
  loadMoreMarketingCompanies: (config: SortItem<ColumnId>) =>
    new LoadMoreMarketingCompaniesAction(config).get(),
  loadMoreMarketingCompaniesFail: (error: Error) =>
    new LoadMoreMarketingCompaniesFailAction(error).get(),
  loadMoreMarketingCompaniesSuccess: (info: SuccessPayload) =>
    new LoadMoreMarketingCompaniesSuccessAction(info).get(),
};

export type MarketingCompaniesServiceActionsTypes =
  | LoadMoreMarketingCompaniesAction
  | LoadMoreMarketingCompaniesFailAction
  | LoadMoreMarketingCompaniesSuccessAction;

export declare namespace MarketingCompaniesServiceActions {
  type LoadMoreMarketingCompanies = LoadMoreMarketingCompaniesAction;
  type LoadMoreMarketingCompaniesFail = LoadMoreMarketingCompaniesFailAction;
  type LoadMoreMarketingCompaniesSuccess = LoadMoreMarketingCompaniesSuccessAction;
}
