import { useContext, FC } from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { Divider } from '@components/divider';
import { ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { formatMoney } from 'containers/services/commonService';
import { Context } from '../context';

type Props = { onAcknowledge: () => void };

export const Completion: FC<Props> = ({ onAcknowledge }) => {
  const { state } = useContext(Context);
  const { amountToBuy, amountToSell, currencyToBuy, currencyToSell, rate } = state.confirmationData;

  const selling = formatMoney(amountToSell, { pre: currencyToSell.code + ' ' });
  const buying = formatMoney(amountToBuy, { pre: currencyToBuy.code + ' ' });

  return (
    <>
      <ModalTitle>
        <Trans id={'my_wallet.exchange_currency.completion.title'}>Complete</Trans>
      </ModalTitle>
      <ModalContent>
        <div className={'wallet-currency-exchange-confirmation-item'}>
          <Trans id={'my_wallet.exchange_currency.confirmation.selling'}>Selling</Trans>
          <span>{selling}</span>
        </div>

        <div className={'wallet-currency-exchange-confirmation-item'}>
          <Trans id={'my_wallet.exchange_currency.confirmation.buying'}>Buying</Trans>
          <span>{buying}</span>
        </div>

        <Divider className={'wallet-currency-exchange-delimiter'} />

        <div className={'wallet-currency-exchange-confirmation-item'}>
          <Trans id={'my_wallet.exchange_currency.confirmation.your_exchange_rate'}>
            Your exchange rate
          </Trans>
          <span>{rate}</span>
        </div>
      </ModalContent>
      <ModalFooter>
        <ButtonPrimary
          className={'wallet-currency-exchange-submit-button'}
          onClick={onAcknowledge}
          fullWidth
        >
          <Trans id={'my_wallet.exchange_currency.completion.ok'}>Ok</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </>
  );
};
