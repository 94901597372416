import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { LINKS } from '@constants/link';
import { HelpInfo } from '../base';

export const HelpCenterTooltip: FC<{ className?: string }> = ({ className }) => {
  return (
    <HelpInfo
      className={className}
      placement="bottomLeft"
      content={
        <>
          <Trans id="help_info_center_tooltip.text">
            If you have any questions, please visit our
          </Trans>
          &nbsp;
          <a href={LINKS.HELP_CENTER} target="_blank">
            <Trans id="help_info_center_tooltip.link">Help Center</Trans>
          </a>
        </>
      }
    />
  );
};
