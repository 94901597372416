import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { ArrayItem } from '@helper/ts';
import { TabsHeaderData } from '@components/tabs/regular';
import { TabShared, useTabChanges } from '../../../hooks/tab';
import { ProfileOther } from '../../api';
import { useCheckShowBankAccountsTab } from './bank-accounts';
import { useCheckOtherTab } from './other';

export const Tab = {
  ...TabShared,
  other: '#other',
};

export const useModel = (profile: ProfileOther) => {
  const isShowBankTab = useCheckShowBankAccountsTab(profile);
  const isShowOtherTab = useCheckOtherTab();
  const { activeTab, onTabChange } = useTabChanges(Tab as typeof TabShared);
  const { i18n, lang } = useI18n();

  const headerData = useMemo(() => {
    const tabs: { [k in keyof typeof Tab]: TabsHeaderData[0] } = {
      accountDetails: {
        data: i18n._(
          defineMessage({ id: 'profile.tab.account_details', message: 'Account details' })
        ),
        tabKey: Tab.accountDetails,
      },
      bankDetails: {
        data: i18n._(defineMessage({ id: 'profile.tab.bank_details', message: 'Bank details' })),
        tabKey: Tab.bankDetails,
      },
      other: {
        data: i18n._(defineMessage({ id: 'profile.tab.other', message: 'Other' })),
        tabKey: Tab.other,
      },
    };

    const mixedTabs = [
      tabs.accountDetails,
      isShowBankTab ? tabs.bankDetails : null,
      isShowOtherTab ? tabs.other : null,
    ];
    return mixedTabs.filter(Boolean) as Array<NonNullable<ArrayItem<typeof mixedTabs>>>;
  }, [isShowBankTab, lang]); // eslint-disable-line

  return {
    activeTab,
    headerData,
    onTabChange,
  };
};
