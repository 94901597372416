import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { notifySuccess } from '@modules/notify';
import { closeModalAction, showModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { useHasAccess } from '@permissions/hooks';
import { Permission } from '@permissions/core';
import { LiveUpdateEventType, liveUpdateService as liveUpdate } from '@services/stomp/live-update';
import { DispatchThunkFn } from '@models/redux';
import { DepositNew, depositsApiService as depositsApi } from '../../deposits-api.service';
import { AddFundsModal } from '../../add-funds-modal';
import { NewDepositModal } from '../new-deposit-modal';
import { addFundsModalSelectors as fundsSelectors } from '../store/selectors';
import { useBankDetails } from '../hooks';
import { AddFundsType } from '../store/model';
import { wireTransferViewServiceActions as wireServiceActions } from '../wire-transfer/store/service.acions';
import {
  defaultExternalBankAccount,
  WireTransferForm,
  WireTransferFormFields,
} from '../wire-transfer/store/model';

export const useModel = () => {
  const addType = useSelector(fundsSelectors.addType);

  const isExtendedInvoiceCreationAllowed = useHasAccess({ or: [Permission.INVOICE_CREATE_EXT] });

  const { isBankDetailsExists } = useBankDetails();

  const isSubmitButtonEnabled =
    addType === AddFundsType.creditCard || isBankDetailsExists || isExtendedInvoiceCreationAllowed;

  const lockUI = useSelector(fundsSelectors.lockUI);

  const onWireTransferFormAction: OnFormAction = {
    error: (error: Error, dispatch: DispatchThunkFn) => {
      dispatch(wireServiceActions.generateDepositFail(error));
    },
    submit: async (form: WireTransferForm, dispatch: DispatchThunkFn) => {
      await dispatch(wireServiceActions.generateDeposit());
      const deposit: DepositNew = await depositsApi.generateDeposit(
        form,
        isExtendedInvoiceCreationAllowed
      );
      liveUpdate.emit({
        data: deposit,
        type: LiveUpdateEventType.depositCreated,
      });
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="invoices_page.notification.successful_client_request">
            Client request for funds confirmation was successfully created.
          </Trans>
        ),
      });
      const showDepositModal = isExtendedInvoiceCreationAllowed
        ? form[WireTransferFormFields.externalBankAccountBalanceId] !==
          defaultExternalBankAccount.externalId
        : true;
      if (showDepositModal) {
        dispatch(
          showModalAction({
            modal: NewDepositModal,
            props: { invoiceId: deposit.id },
          })
        );
      }
      await dispatch(wireServiceActions.generateDepositSuccess());
      await dispatch(closeModalAction(AddFundsModal));
    },
  };

  return {
    addType,
    isSubmitButtonEnabled,
    lockUI,
    onWireTransferFormAction,
  };
};
