import { useCallback, useContext, useEffect, useMemo } from 'react';

import { tableRowHeight } from '@components/table2/config/table-simplified';
import { KeyExtractor } from '@components/table2/core';
import { LoadData, useTable } from '@components/table2/simplified';
import { useI18n } from '@hooks/i18n';
import { mapTableSorting } from '@helper/sorting';
import { Context } from '../context';
import { useDefaultFilters } from '../hooks';
import { Filter, HistorySortOrder } from '../types';
import api, { BankHistory, SortKey } from './api';
import { ColumnKey } from './columns';

export enum TableStatuses {
  cancelled = 'cancelled',
  completed = 'completed',
  processing = 'processing',
  pending = 'pending',
}

export const rowConfig = {
  height: tableRowHeight.normal,
};

const sortMap = {
  [ColumnKey.dateTime]: SortKey.dateTime,
  [ColumnKey.currency]: SortKey.currency,
};

export const useModel = () => {
  const { i18n } = useI18n();
  const { dispatch, state } = useContext(Context);
  const { getDefaultFilter } = useDefaultFilters();

  const initialFilter = useMemo(() => getDefaultFilter(), []);

  const loadData = useCallback<LoadData<BankHistory>>(({ nextPage, sortOrder, filter }) => {
    console.log('====INITIAL FILTER=====', filter);
    const ordering: HistorySortOrder = mapTableSorting(sortOrder, sortMap);
    return api.getBankAccountHistoryPage({
      page: nextPage,
      ordering: ordering,
      filter: filter as Filter,
    });
  }, []);

  const onAfterSortOrderChange = useCallback(
    (sortOrder) => {
      dispatch({ payload: mapTableSorting(sortOrder, sortMap), type: 'ON_ORDER_CHANGE' });
    },
    [dispatch]
  );

  const keyExtractor = useCallback<KeyExtractor<BankHistory>>((item) => {
    return item.idFromBank;
  }, []);

  const { tableInstance, setFilter } = useTable<BankHistory>({
    initialFilter,
    keyExtractor,
    loadData,
    onAfterSortOrderChange,
    startPage: 1,
  });

  useEffect(() => {
    if (state.filters) {
      setFilter(() => state.filters);
    }
  }, [setFilter, state.filters]);

  return {
    i18n,
    tableInstance,
  };
};
