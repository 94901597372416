import React from 'react';
import { Trans } from '@lingui/macro';

import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Text } from '@components/typography/text';
import { ButtonSecondary } from '@components/button';

import { useModel } from './model';

import type { MFC } from '@modules/modal';

export const NoBankDetailsModal: MFC = (p) => {
  const m = useModel(NoBankDetailsModal);

  return (
    <Modal isVisible={p.isVisible} onClose={m.onClose}>
      <ModalTitle>
        <Trans id="no_bank_details_modal.title">No bank details added</Trans>
      </ModalTitle>

      <ModalContent>
        <Text level={1} className="mb1">
          <Trans id="no_bank_details_modal.text">
            In accordance with industry regulations, TM2 makes withdrawal payments only to bank
            accounts, that belongs to you and were verified by TM2. Please add Bank details to
            process a withdrawal
          </Trans>
        </Text>
      </ModalContent>

      <ModalFooter>
        <ButtonSecondary fullWidth={true} onClick={m.onAddBankDetails}>
          <Trans id="no_bank_details_modal.add_bank_details">Add bank details</Trans>
        </ButtonSecondary>
      </ModalFooter>
    </Modal>
  );
};
