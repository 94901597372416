import React, { FC, useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from '../transfer.module.scss';
import { HelpCenterTooltip } from '@components/help-info';
import { ButtonIconPure, ButtonPrimary } from '@components/button';
import { Form, InputNumber, Select, SubmitButton } from '@components/form';
import { Divider } from '@components/divider';
import { DispatchFn, RootState } from '@models/redux';
import { Box } from '@components/box';
import { ReactComponent as SwitchIcon } from '@images/icons/switch-icon.svg';
import { formatMoney } from 'containers/services/commonService';
import { TransferDirection } from '../model';
import { onTransferStableContinueButtonClicked } from './store/effects';
import { transferStableTabSelectors as stableSelectors } from './store/selectors';
import { transferStableTabUserActions as stableUserActions } from './store/user.acions';
import {
  AssetItem,
  maxDecimalsSMStable,
  TransferStableFields,
  transferStableFormId,
} from './store/model';

export { permissionGroup } from './api';

const mapStateToProps = (root: RootState) => ({
  direction: stableSelectors.transferDirection(root),
  lockUI: stableSelectors.lockUI(root),
  availableSourceBalances: stableSelectors.sourceBalances(root),
  isNoTransferStable: stableSelectors.isNoTransferStable(root),
  initialAsset: stableSelectors.initialAsset(root),
  selectedAsset: stableSelectors.selectedAsset(root),
  primaryBalance: stableSelectors.primaryBalance(root),
  secondaryBalance: stableSelectors.secondaryBalance(root),
});

const mapDispatchToProps = (dispatch) => ({
  onTabOpened: () => dispatch(stableUserActions.onTabOpened()),
  onToggleTransferDirectionClicked: () =>
    dispatch(stableUserActions.onToggleStableTransferDirectionClicked()),
  onMaximizeButtonClicked: () =>
    dispatch(stableUserActions.onMaximizeStableAmountTransferClicked()),
  onSelectedValueChanged: (asset) => dispatch(stableUserActions.onSelectedValueChanged(asset)),
});

const ConnectedTransferStableView: FC<{
  direction: string;
  lockUI: boolean;
  availableSourceBalances: Array<AssetItem>;
  isNoTransferStable: boolean;
  initialAsset: string;
  onTabOpened: DispatchFn<void>;
  onToggleTransferDirectionClicked: DispatchFn<void>;
  onMaximizeButtonClicked: DispatchFn<void>;
  onSelectedValueChanged: DispatchFn<string | number>;
  selectedAsset: string;
  primaryBalance: number;
  secondaryBalance: number;
}> = ({
  direction,
  lockUI,
  availableSourceBalances,
  isNoTransferStable,
  initialAsset,
  onTabOpened,
  onToggleTransferDirectionClicked,
  onMaximizeButtonClicked,
  onSelectedValueChanged,
  selectedAsset,
  primaryBalance,
  secondaryBalance,
}) => {
  useEffect(() => {
    onTabOpened();
  }, []);

  return (
    <Form name={transferStableFormId}>
      <div className={classnames(s.rowDirection, 'mb-md')}>
        <div className={s.directionWay}>
          <p className="txt-center">
            <Trans id="market_transfer_modal.metals.label.from">From</Trans>
          </p>

          <Box className={s.wayGradient}>
            {direction === TransferDirection.fromPrimary ? (
              <Trans id="market_transfer_modal.metals.label.primary_market">Primary Market</Trans>
            ) : (
              <Trans id="market_transfer_modal.metals.label.secondary_market">
                Secondary Market
              </Trans>
            )}
          </Box>
        </div>

        <div className={s.directionSwitch}>
          <ButtonIconPure
            disabled={lockUI}
            className={s.switchButton}
            onClick={onToggleTransferDirectionClicked}
            icon={<SwitchIcon className="tm2-icon-stroke" />}
          />
        </div>

        <div className={s.directionWay}>
          <p className="txt-center">
            <Trans id="market_transfer_modal.metals.label.to">To</Trans>
          </p>

          <Box className={s.wayGradient}>
            {direction === TransferDirection.fromPrimary ? (
              <Trans id="market_transfer_modal.metals.label.secondary_market">
                Secondary Market
              </Trans>
            ) : (
              <Trans id="market_transfer_modal.metals.label.primary_market">Primary Market</Trans>
            )}
          </Box>
        </div>
      </div>

      <div className="mb-md">
        <Trans id="market_transfer_modal.funds.label.currency_and_amount">
          Currency and amount to transfer
        </Trans>
      </div>

      <div className={s.form}>
        <div className={s.formField}>
          {isNoTransferStable ? (
            <div className={s.fieldNone}>
              <Trans id="market_transfer_modal.metals.label.none">none</Trans>
              <HelpCenterTooltip className={s.noneInfo} />
            </div>
          ) : (
            <Select
              className="mb-md"
              name={TransferStableFields.asset}
              label={<Trans id="market_transfer_modal.metals.label.asset">Asset</Trans>}
              disabled={lockUI}
              required={true}
              onChange={(asset) => onSelectedValueChanged(asset)}
              data={availableSourceBalances.map((balance) => ({
                label: balance.asset,
                value: balance.asset,
              }))}
              compact={true}
            />
          )}
        </div>

        <div className={s.formField}>
          <InputNumber
            className="mb-md"
            name={TransferStableFields.amount}
            label={<Trans id="market_transfer_modal.metals.label.quantity">Quantity</Trans>}
            disabled={lockUI}
            required={true}
            min={0}
            max={direction === TransferDirection.fromPrimary ? primaryBalance : secondaryBalance}
            maxDecimals={maxDecimalsSMStable}
            compact={true}
          />
        </div>

        <div className={s.formButton}>
          <ButtonPrimary
            className="mb-md"
            disabled={lockUI}
            onClick={onMaximizeButtonClicked}
            fullWidth={true}
          >
            <Trans id="market_transfer_modal.funds.button.max">Max</Trans>
          </ButtonPrimary>
        </div>
      </div>

      <Divider />

      <div className={classnames(s.rowTotal, 'mb-md')}>
        <div className={s.totalText}>
          <Trans id="market_transfer_modal.metals.label.available_on_primary_market">
            Primary Market quantity
          </Trans>
        </div>

        <div className={s.totalValue}>
          {formatMoney(primaryBalance, { pre: selectedAsset || initialAsset })}
        </div>
      </div>

      <div className={classnames(s.rowTotal, 'mb-md')}>
        <div className={s.totalText}>
          <Trans id="market_transfer_modal.metals.label.available_on_secondary_market">
            Secondary Market quantity
          </Trans>
        </div>

        <div className={s.totalValue}>
          {formatMoney(secondaryBalance, { pre: selectedAsset || initialAsset })}
        </div>
      </div>

      <SubmitButton
        name={transferStableFormId}
        fullWidth={true}
        disabled={lockUI}
        onAction={onTransferStableContinueButtonClicked}
      >
        <Trans id="market_transfer_modal.funds.button.continue">Continue</Trans>
      </SubmitButton>
    </Form>
  );
};

export const TransferStableView = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTransferStableView);
