import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Form, SubmitButton } from '@components/form';
import { PrevStepButton } from '../../prev-button';
import { DynamicForm } from '../../dynamic-form';
import { formId, useModel } from './model';

export const RegulatedOrListedStatusStep: FC = () => {
  const m = useModel();

  return (
    <Form className={s.form} name={formId} initialValues={m.formInitial}>
      <h2>
        <Trans id="signup.regulated_or_listed_status.title">Welcome!</Trans>
      </h2>

      <div className="mb-md">
        <Trans id="signup.regulated_or_listed_status.text">
          Please provide your Regulated or Listed status
        </Trans>
      </div>

      <DynamicForm formRows={m.formRows} lockUI={m.lockUI} />

      <div className={s.buttons}>
        <PrevStepButton className="mr3" disabled={m.lockUI} />

        <SubmitButton
          name={formId}
          fullWidth={true}
          disabled={m.lockUI}
          onAction={m.onFormAction}
          className={s.button}
        >
          <Trans id="signup.regulated_or_listed_status.button.continue">Continue</Trans>
        </SubmitButton>
      </div>
    </Form>
  );
};
