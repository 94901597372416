import { createSlice } from '@reduxjs/toolkit';

import e from './effects';
import { sliceId, stateInitial } from './model';

export { default as selectors } from './selectors';

const s = createSlice({
  name: sliceId(null),
  initialState: stateInitial,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(e.getCountries.pending, (state) => {
      state.isCountriesLoading = true;
    });
    builder.addCase(e.getCountries.fulfilled, (state, action) => {
      state.isCountriesLoading = false;
      state.countries = action.payload;
    });
    builder.addCase(e.getCountries.rejected, (state) => {
      state.isCountriesLoading = false;
    });

    builder.addCase(e.getNationalities.pending, (state) => {
      state.isNationalitiesLoading = true;
    });
    builder.addCase(e.getNationalities.fulfilled, (state, action) => {
      state.isNationalitiesLoading = false;
      state.nationalities = action.payload;
    });
    builder.addCase(e.getNationalities.rejected, (state) => {
      state.isNationalitiesLoading = false;
    });
  },
});

export const reducer = s.reducer;

export const actions = {
  getCountries: e.getCountries,
  getNationalities: e.getNationalities,
};
