import React, { useContext, useEffect, FC } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { Layout } from 'antd';

import s from './header.module.scss';
import { theme } from '@constants/theme';
import { LayoutContext, useBlockHeight } from '@modules/layout';
import { VerifyModalButton } from '../../../../../containers/pages/post-registration/individual-steps/verification-upload/button';
import { LanguageSelector } from '../../../language-selector';
import { ProfileWidget } from './profile-menu';

const { Header } = Layout;

export const AuthorizedHeader: FC = () => {
  const { updateLayoutValues } = useContext(LayoutContext);

  const updateHeaderHeight = useCallbackOne((height: number) => {
    const borderCorrection = 1;
    updateLayoutValues({
      pageHeaderHeight: height + borderCorrection,
    });
  }, []);
  const { ref: containerRef } = useBlockHeight(updateHeaderHeight);

  useEffect(() => {
    return () => {
      updateLayoutValues({
        pageHeaderHeight: 0,
      });
    };
  }, []);

  return (
    <Header className={s.header} data-uat="header">
      <div ref={containerRef} className={s.headerInner}>
        <LanguageSelector className="mr2 ml-auto" theme={theme.dark} />

        <VerifyModalButton
          className="mr2"
          visibility="desktop"
          icon={null}
          uat="header-verify-button"
        />
        <ProfileWidget />
      </div>
    </Header>
  );
};
