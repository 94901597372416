import { modalSelectors } from 'shared/modules/modal/store/selectors';
import { TableHeaderSortType as Sort } from 'components/page-table';

const usersPageState = (root) => root.main?.users?.usersPage;

const columnSortDirection = (root) => (columnId) =>
  usersPageState(root).sort.filter((col) => col.id === columnId)[0].direction;

const isHasMore = (root) => usersPageState(root).isHasMore;

const isUsersLoading = (root) => usersPageState(root).isUsersLoading;

const lockUI = (root) => {
  const isModalOpened = modalSelectors.isModalOpened(root);
  const state = usersPageState(root);
  return isModalOpened || state.isUsersLoading;
};

const pageIndex = (root) => usersPageState(root).pageIndex;

const search = (root) => usersPageState(root).search;

const sort = (root) =>
  usersPageState(root).sort.filter(
    (_sort) => _sort.direction === Sort.asc || _sort.direction === Sort.desc
  );

const users = (root) => usersPageState(root).users;

export const usersPageSelectors = {
  columnSortDirection,
  isHasMore,
  isUsersLoading,
  lockUI,
  pageIndex,
  search,
  sort,
  users,
  usersPageState,
};
