import { FC } from 'react';
import classnames from 'classnames';
import { Divider } from '@components/divider';
import { BalanceContextActions } from '../../balance-context-actions';
import s from './index.module.scss';
import { formatMoney } from '../../../../../services/commonService';

export { permissionGroup } from '../../balance-context-actions';

type Balance = {
  asset: string;
  amount: number;
};

type BalanceSection = {
  title: string | JSX.Element;
  records: Balance[];
  key?: string;
};

type BalanceProps = {
  sections: BalanceSection[];
  slotBottom?: JSX.Element;
};

export const BalancePane: FC<BalanceProps> = ({ sections, slotBottom }) => {
  return (
    <div className={s.pane}>
      <div className={s.header}>
        <span>Balance</span>
        <BalanceContextActions />
      </div>
      {sections
        .filter((s) => s.records.length)
        .map((section, index) => (
          <div key={section.key ?? index} className={s.section}>
            <Divider />
            <span className={s.secondaryHeader}>{section.title}</span>
            <div>
              {section.records.map((r) => (
                <span
                  key={r.asset}
                  className={classnames(s.sectionRecord, {
                    [s.sectionRecordNegative]: r.amount < 0,
                  })}
                >
                  {r.asset} {formatMoney(r.amount)}
                </span>
              ))}
            </div>
          </div>
        ))}
      {slotBottom ? (
        <>
          <Divider />
          {slotBottom}
        </>
      ) : null}
    </div>
  );
};
