import React, { useMemo } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { ColumnType } from '@components/table2/base';
import { Trans } from '@lingui/macro';
import { DATE_TIME_FORMAT } from '@constants/date';
import { Permission as P, permissionsSelectors } from '@permissions/core';
import { UserProfileLink } from '@components/link';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { Deposit } from './api';
import { ActionsCell } from './actions-cell';
import { formatMoney } from '../../services/commonService';
import { DepositStatusCell } from './status-cell';

type OnReplaceDeposit = (deposit: Deposit) => void;

export enum ColumnKey {
  actions = 'actions',
  number = 'number',
  status = 'status',
  creationDate = 'creationDate',
  paymentDate = 'paymentDate',
  client = 'client',
  clientId = 'clientId',
  amount = 'amount',
  amountPaid = 'amountPaid',
  currency = 'currency',
  clientComment = 'clientComment',
  bankName = 'bankName',
  amountSettled = 'amountSettled',
  externalId = 'externalId',
  reference = 'reference',
  purpose = 'purpose',
}

export const useColumns = (replaceDeposit: OnReplaceDeposit) => {
  const isHasAccess = useSelector(permissionsSelectors.isHasAccess);

  const columns: Array<ColumnType<Deposit>> = useMemo(() => {
    return [
      {
        key: ColumnKey.actions,
        dataIndex: 'actions',
        headCell: () => <span>&nbsp;</span>,
        rowCell: ({ data }) => <ActionsCell data={data} replaceDeposit={replaceDeposit} />,
        width: 60,
      },
      {
        key: ColumnKey.number,
        dataIndex: 'number',
        headCell: () => <Trans id="deposits.table.deposit_number">№</Trans>,
        rowCell: ({ data }) => <>{data.number}</>,
        width: 60,
      },
      {
        key: ColumnKey.status,
        dataIndex: 'status',
        headCell: () => <Trans id="deposits.table.status">Status</Trans>,
        rowCell: ({ data }) => <DepositStatusCell status={data.status} />,
        width: 120,
      },
      {
        key: ColumnKey.bankName,
        dataIndex: 'bankName',
        headCell: () => <Trans id="deposits.table.bank">Bank</Trans>,
        rowCell: ({ data }) => <>{data.bankName}</>,
        width: 180,
      },
      {
        key: ColumnKey.creationDate,
        dataIndex: 'creationDate',
        headCell: () => (
          <Trans id="deposits.table.issued_on__date_created">Issued on (Date created)</Trans>
        ),
        rowCell: ({ data }) => <>{moment(data.creationDate).format(DATE_TIME_FORMAT)}</>,
        width: 240,
      },
      {
        key: ColumnKey.paymentDate,
        dataIndex: 'paymentDate',
        headCell: () => <Trans id="deposits.table.paid_on__date_paid">Paid on (Date paid)</Trans>,
        rowCell: ({ data }) => {
          const creationDate = data.payment?.creationDate;
          return <>{!!creationDate ? moment(creationDate).format(DATE_TIME_FORMAT) : ''}</>;
        },
        width: 240,
      },
      ...(isHasAccess({ or: [P.PROFILE_VIEW_OTHER] })
        ? [
            {
              key: ColumnKey.client,
              dataIndex: 'client',
              headCell: () => <Trans id="deposits.table.client">Client</Trans>,
              rowCell: ({ data }) => (
                <UserProfileLink id={data.investorUser.id} text={data.investorUser.displayName} />
              ),
              width: 160,
            },
            {
              key: ColumnKey.clientId,
              dataIndex: 'clientId',
              headCell: () => <Trans id="deposits.table.client_id">Client ID</Trans>,
              rowCell: ({ data }) => data.investorUser.id,
              width: 140,
            },
          ]
        : []),
      {
        key: ColumnKey.amount,
        dataIndex: 'amount',
        headCell: () => <Trans id="deposits.table.amount_expected">Amount expected</Trans>,
        rowCell: ({ data }) => <>{formatMoney(data.amount)}</>,
        width: 180,
      },
      {
        key: ColumnKey.amountPaid,
        dataIndex: 'amountPaid',
        headCell: () => <Trans id="deposits.table.amount_paid">Amount paid</Trans>,
        rowCell: ({ data }) => <>{formatMoney(data.payment?.amount || 0)}</>,
        width: 140,
      },
      {
        key: ColumnKey.amountSettled,
        dataIndex: 'amountSettled',
        headCell: () => <Trans id="deposits.table.amount_settled">Amount settled</Trans>,
        rowCell: ({ data }) => <>{data.payment?.amountSettled}</>,
        width: 140,
      },
      {
        key: ColumnKey.currency,
        dataIndex: 'currency',
        headCell: () => <Trans id="deposits.table.currency">Currency</Trans>,
        rowCell: ({ data }) => <>{data.bankAccount.currency.code}</>,
        width: 140,
      },
      ...(isHasAccess({ or: [P.PROFILE_VIEW_OTHER] })
        ? [
            {
              key: ColumnKey.externalId,
              dataIndex: 'externalId',
              headCell: () => <Trans id="deposits.table.external_id">External ID</Trans>,
              rowCell: ({ data }) => <>{data.externalId}</>,
              width: 140,
              tooltip: true,
            },
          ]
        : []),
      {
        key: ColumnKey.reference,
        dataIndex: 'reference',
        headCell: () => <Trans id="deposits.table.reference">Reference</Trans>,
        rowCell: ({ data }) => <>{data.reference}</>,
        width: 140,
      },
      {
        key: ColumnKey.purpose,
        dataIndex: 'purpose',
        headCell: () => <Trans id="deposits.table.purpose">Purpose</Trans>,
        rowCell: ({ data }) => <>{data.purpose}</>,
        width: 140,
        tooltip: true,
      },
      {
        key: ColumnKey.clientComment,
        dataIndex: 'clientComment',
        headCell: () => <Trans id="deposits.table.client_comment">Client comment</Trans>,
        rowCell: ({ data }) => <>{data.clientComment}</>,
        width: 180,
        tooltip: 'overflow',
      },
    ];
  }, [replaceDeposit]); // eslint-disable-line

  return {
    columns,
  };
};
