import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';
import { I18n } from '@lingui/core';
import { useI18n } from 'containers/services/commonService';
import { BidOrderStatus, OfferStatus } from './types';

function getOfferStatus(i18n: I18n) {
  return {
    [OfferStatus.activePending]: i18n._(
      defineMessage({
        id: 'bid_status.active_pending',
        message: 'Active pending',
      })
    ),
    [OfferStatus.active]: i18n._(
      defineMessage({
        id: 'bid_status.active',
        message: 'Active',
      })
    ),
    [OfferStatus.activeError]: i18n._(
      defineMessage({
        id: 'bid_status.active_error',
        message: 'Active error',
      })
    ),
    [OfferStatus.closePending]: i18n._(
      defineMessage({
        id: 'bid_status.close_pending',
        message: 'Close pending',
      })
    ),
    [OfferStatus.closed]: i18n._(
      defineMessage({
        id: 'bid_status.closed',
        message: 'Closed',
      })
    ),
    [OfferStatus.closeError]: i18n._(
      defineMessage({
        id: 'bid_status.close_error',
        message: 'Close error',
      })
    ),
    [OfferStatus.completed]: i18n._(
      defineMessage({
        id: 'bid_status.completed',
        message: 'Complete',
      })
    ),
  };
}

function getBidOrderStatus(i18n: I18n) {
  return {
    [BidOrderStatus.created]: i18n._(
      defineMessage({
        id: 'bid_order_status.created',
        message: 'Created',
      })
    ),
    [BidOrderStatus.creationPending]: i18n._(
      defineMessage({
        id: 'bid_order_status.creation_pending',
        message: 'Creation pending',
      })
    ),
    [BidOrderStatus.creationError]: i18n._(
      defineMessage({
        id: 'bid_order_status.creation_error',
        message: 'Creation error',
      })
    ),
    [BidOrderStatus.approvePending]: i18n._(
      defineMessage({
        id: 'bid_order_status.approve_pending',
        message: 'Approve pending',
      })
    ),
    [BidOrderStatus.approved]: i18n._(
      defineMessage({
        id: 'bid_order_status.approved',
        message: 'Approved',
      })
    ),
    [BidOrderStatus.approvingError]: i18n._(
      defineMessage({
        id: 'bid_order_status.approving_error_v2',
        message: 'Approval error',
      })
    ),
    [BidOrderStatus.denyPending]: i18n._(
      defineMessage({
        id: 'bid_order_status.deny_pending',
        message: 'Deny pending',
      })
    ),
    [BidOrderStatus.denied]: i18n._(
      defineMessage({
        id: 'bid_order_status.denied',
        message: 'Denied',
      })
    ),
    [BidOrderStatus.denyingError]: i18n._(
      defineMessage({
        id: 'bid_order_status.denying_error',
        message: 'Denying error',
      })
    ),
  };
}

export const useBidOrderStatusTrans = () => {
  const { i18n, lang } = useI18n();
  return useMemo(() => getBidOrderStatus(i18n), [lang]);
};

export const useOfferStatusTrans = () => {
  const { i18n, lang } = useI18n();
  return useMemo(() => getOfferStatus(i18n), [lang]);
};
