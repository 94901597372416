import { defineMessage, Trans } from '@lingui/macro';
import React from 'react';
//https://reactdatepicker.com/
import DatePicker from 'react-datepicker';
import { ButtonPrimary } from '@components/button';
import { Col, Grid, Row } from '@components/grid';
import { PageContent, PageTitle } from '@components/page';
import 'react-datepicker/dist/react-datepicker.min.css';
import { useIssuerBalanceModel } from './model';

const IssuerBalance = () => {
  const {
    i18n,
    startDate,
    endDate,
    canShowBalance,
    handleChangeEnd,
    handleChangeStart,
    requestBalance,
  } = useIssuerBalanceModel();

  return (
    <PageContent uat="issuer-balance-page">
      <PageTitle
        title={i18n._(defineMessage({ id: 'router.statements', message: 'Statements' }))}
      />
      <form name="" method="post" action="">
        <Grid pattern={{ sm: 2, md: 4 }}>
          <Col className="mb-md">
            <label className="field-label label-small">
              <Trans id="issuer_balance.date_from">Date, from</Trans>
            </label>
            <div className="text-block">
              <DatePicker
                dateFormat={'dd.MM.yyyy'}
                selected={startDate}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                maxDate={endDate}
                onChange={handleChangeStart}
              />
            </div>
          </Col>

          <Col className="mb-md">
            <label className="field-label label-small">
              <Trans id="issuer_balance.date_to">Date, to</Trans>
            </label>
            <div className="text-block">
              <DatePicker
                dateFormat={'dd.MM.yyyy'}
                selected={endDate}
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate}
                maxDate={new Date()}
                onChange={handleChangeEnd}
              />
            </div>
          </Col>
        </Grid>

        <Row>
          <ButtonPrimary className="mx-auto" onClick={requestBalance} disabled={!canShowBalance}>
            <Trans id="issuer_balance.show_balance">Show balance</Trans>
          </ButtonPrimary>
        </Row>
      </form>
    </PageContent>
  );
};

export default IssuerBalance;
