import { saveAs } from 'file-saver';
import { useCallback, useState } from 'react';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';

export const useModel = (blob, filename, modal) => {
  const [lockUI, setLockUI] = useState(false);

  const onClose = useCallDispatch(closeModalAction);

  const onDownload = useCallback(() => {
    try {
      setLockUI(true);
      saveAs(blob, filename);
      setLockUI(false);
      onClose(modal);
    } catch (error) {
      handleBackendError(error);
      setLockUI(false);
    }
  }, []);

  return {
    lockUI,
    onClose,
    onDownload,
  };
};
