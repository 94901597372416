import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';

import {
  closeModalAction,
  MFC,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from '@modules/modal';
import { ButtonPrimary } from '@components/button';
import { DispatchFn, RootState } from '@models/redux';
import { handleBackendError } from '@modules/notify';
import { Any } from '@models/core';
import { ConfirmModalProps } from '../store/model';

export const ConfirmationModal: MFC<{}, ConfirmModalProps> = ({ isVisible, onConfirm, text }) => {
  const dispatch = useDispatch();
  const [lockUI, setLockUI] = useState(false);

  const onCancelClicked = () => dispatch(closeModalAction(ConfirmationModal));

  const onConfirmClicked = () => {
    dispatch(async (dispatch: DispatchFn<Any>, getState: () => RootState) => {
      try {
        setLockUI(true);
        // TODO-2833: fix types
        // @ts-ignore
        await onConfirm.action(dispatch, getState);
        setLockUI(false);
        onCancelClicked();
      } catch (error) {
        handleBackendError(error);
        setLockUI(false);
        if (onConfirm.error) {
          // TODO-2833: fix types
          // @ts-ignore
          await onConfirm.error(error, dispatch, getState);
        }
      } finally {
        if (onConfirm.finally) {
          // TODO-2833: fix types
          // @ts-ignore
          await onConfirm.finally(dispatch, getState);
        }
      }
    });
  };

  return (
    <Modal onClose={onCancelClicked} loading={lockUI} disabled={lockUI} isVisible={isVisible}>
      <ModalTitle>{!text.title ? null : text.title}</ModalTitle>

      <ModalContent>{!text.description ? null : text.description}</ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} disabled={lockUI} onClick={onConfirmClicked}>
          {text.confirmButton || <Trans id="button.confirm">Confirm</Trans>}
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
