import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';

import s from '../index.module.scss';
import { PermissionGroup } from '@permissions/core';
import { PageContent, PageTitle } from '@components/page';
import { useI18n } from '@hooks/i18n';
import { ActionsRow, permissionsGroup as actionsPermissionsGroup } from './actions-row';
import { CMBankAccountsTable, permissionsGroup as tablePermissionsGroup } from './table';
import { Provider } from './context';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [actionsPermissionsGroup, tablePermissionsGroup],
  marker: 'layout:cm-bank-accounts-page',
});

export const CMBankAccountsPage: FC = memo(() => {
  const { i18n } = useI18n();

  return (
    <Provider>
      <PageContent className={s.verticalFiller} uat="cm-bank-accounts-page">
        <PageTitle
          title={i18n._(defineMessage({ id: 'router.cm.banks', message: 'Bank accounts' }))}
        />

        <ActionsRow className="mb1" />

        <CMBankAccountsTable />
      </PageContent>
    </Provider>
  );
});
