import { DispatchThunkFn, GetStateFn } from '@models/redux';
import { Any } from '@models/core';
import hooks from './antd-form/hooks';
import { FieldValidationConfig } from './validators';

export type { SelectItem } from './antd-form/select';
export type { CheckboxItem } from './antd-form/checkbox-group';
export type { RadioItem } from './antd-form/radio';
export type { RadioGroupItem } from './antd-form/radio-group';

export const useForm = hooks.useForm;
export const useFormById = hooks.useFormById;

export { useFormAdapter } from './deprecated';
export {
  AddRowButton,
  CheckboxGroup,
  CheckboxSingle,
  DeleteRowButton,
  FileUploader,
  Form,
  FormList,
  InputDate,
  InputDateRange,
  InputDateSimple,
  InputFile,
  InputHidden,
  InputNumber,
  InputNumberRange,
  InputPhone,
  InputPassword,
  Select,
  SelectBoolean,
  InputText,
  InputTextarea,
  Radio,
  RadioGroup,
  SubmitButton,
  Switch,
  RoledSelect,
} from './antd-form/index';
export { handleValidationErrors, formService } from './antd-form/service/index';

export interface OnFormAction<Form = Any> {
  error?: (error: Error, dispatch?: DispatchThunkFn, getState?: GetStateFn) => Any;
  finally?: (dispatch?: DispatchThunkFn, getState?: GetStateFn) => Any;
  submit: (form: Form, dispatch?: DispatchThunkFn, getState?: GetStateFn) => Any;
  fieldValidation?: FieldValidationConfig;
}
