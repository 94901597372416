import { useSelector } from 'react-redux';

import { useCallDispatch } from '@hooks/core';
import { modalSelectors, showModalAction } from '@modules/modal';
import { EditProfileModal } from '../../../modals/edit-profile';

export const useModel = () => {
  const showModal = useCallDispatch(showModalAction);
  const isEditOpened = useSelector(modalSelectors.isModalOpened);
  const showEditModal = () => showModal({ modal: EditProfileModal });

  return {
    isEditOpened,
    showEditModal,
  };
};
