import { ReduxAction } from '@models/redux';
import { BankAccount } from '../../../deposits-api.service';
import { CreditCardViewActionsTypes as T } from './model';

class PrepareCreditCardAction extends ReduxAction<T.PREPARE_CREDIT_CARD, void> {
  public readonly type: T.PREPARE_CREDIT_CARD = T.PREPARE_CREDIT_CARD;
  public constructor(public payload: void) {
    super(payload);
  }
}

class PrepareCreditCardFailAction extends ReduxAction<T.PREPARE_CREDIT_CARD_FAIL, Error> {
  public readonly type: T.PREPARE_CREDIT_CARD_FAIL = T.PREPARE_CREDIT_CARD_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class PrepareCreditCardSuccessAction extends ReduxAction<T.PREPARE_CREDIT_CARD_SUCCESS, void> {
  public readonly type: T.PREPARE_CREDIT_CARD_SUCCESS = T.PREPARE_CREDIT_CARD_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class SetBankAccountsAction extends ReduxAction<T.SET_BANK_ACCOUNTS, Array<BankAccount>> {
  public readonly type: T.SET_BANK_ACCOUNTS = T.SET_BANK_ACCOUNTS;
  public constructor(public payload: Array<BankAccount>) {
    super(payload);
  }
}

export const creditCardViewServiceActions = {
  prepareCreditCard: () => new PrepareCreditCardAction().get(),
  prepareCreditCardFail: (error: Error) => new PrepareCreditCardFailAction(error).get(),
  prepareCreditCardSuccess: () => new PrepareCreditCardSuccessAction().get(),
  setBankAccounts: (list: Array<BankAccount>) => new SetBankAccountsAction(list).get(),
};

export type CreditCardViewServiceActionsTypes =
  | PrepareCreditCardAction
  | PrepareCreditCardFailAction
  | PrepareCreditCardSuccessAction
  | SetBankAccountsAction;

export declare namespace CreditCardViewServiceActions {
  type prepareCreditCard = PrepareCreditCardAction;
  type prepareCreditCardFail = PrepareCreditCardFailAction;
  type prepareCreditCardSuccess = PrepareCreditCardSuccessAction;
  type setBankAccounts = SetBankAccountsAction;
}
