import { useSelector } from 'react-redux';

import {
  IsHasAccess,
  Permission,
  PermissionGroup,
  permissionsSelectors,
  Role,
} from '@permissions/core';
import { configSelectors, UserInfo } from '@config/core';
import { session } from '@services/session';

export const useAuthentication = (
  forcedAccess: boolean,
  allowedPermissions: Array<Permission | PermissionGroup>,
  allowedRoles: Array<Role>,
  customForcedAccessCheckFn: (u: UserInfo) => boolean,
  extraCheck: (u: UserInfo) => boolean
) => {
  const isAuthorized: boolean = useSelector(configSelectors.isAuthorized);
  const user: UserInfo = useSelector(configSelectors.userInfo);
  const isHasAccessFn: IsHasAccess = useSelector(permissionsSelectors.isHasAccess);

  const isHasAccess =
    isAuthorized &&
    isHasAccessFn(
      { or: allowedPermissions },
      extraCheck ? extraCheck(user) : null,
      forcedAccess || customForcedAccessCheckFn?.(user),
      allowedRoles
    );

  const isFromRedirect: boolean = session.isFromRedirect;

  let redirectUrl;
  if (isAuthorized) {
    if (isFromRedirect) {
      redirectUrl = '/';
    } else {
      redirectUrl = '/accessDenied';
    }
  } else {
    redirectUrl = '/signin';
  }

  return { isHasAccess, redirectUrl };
};
