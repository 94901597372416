import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import { configSelectors } from '@config/core';
import { Tab } from './index';

export const useNewTabsModel = (tabsList: Array<Tab>) => {
  const tabs: Array<Tab> = tabsList.filter((tab) => !!tab);
  const tabsLength = tabs.length;
  const isTabsHasErrors = tabs.filter((tab) => tab.error).length;
  const tabName = window.location.hash.slice(1);
  const tabIndex = useMemo(
    () => tabs.findIndex((tab) => tab.name === tabName),
    [tabsLength, tabName]
  ); // eslint-disable-line
  const lang = useSelector(configSelectors.lang);

  const getTabIndex = tabIndex === -1 ? 0 : tabIndex;

  const [activeTabIndex, setActiveTabIndex] = useState(getTabIndex || 0);

  useEffect(() => {
    setActiveTabIndex(getTabIndex);
  }, [window.location.hash, tabsLength]); // eslint-disable-line

  const tabsContent = useMemo(
    () => tabs.map(({ content }) => (content instanceof Function ? content() : content)),
    [tabs]
  );

  const links = useMemo(
    () =>
      tabs.map(({ isTabDisabled, title, error }, index) => (
        <div
          key={`tab-${index}`}
          onClick={() => {
            if (tabs[index].name) {
              window.location.hash = tabs[index].name;
            }
            setActiveTabIndex(index);
          }}
          className={classnames('link link-icon tab-link', {
            active: activeTabIndex === index,
            'is-disabled': isTabDisabled,
          })}
        >
          {title}
          {error && <div className="styles-for-red-point" />}
        </div>
      )),
    [tabsLength, activeTabIndex, lang, isTabsHasErrors]
  ); // eslint-disable-line

  const currentTabView = tabsContent[activeTabIndex];

  return { currentTabView, links };
};
