import React from 'react';
import { Trans } from '@lingui/macro';

import { Form, InputHidden, InputNumber, InputText, Select, SubmitButton } from '@components/form';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { CurrencySelect } from '@modules/currencies';
import { maxDecimals } from '@constants/math';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { Fields, formId, useModal } from './model';

export const permissionsGroup = apiPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-handle-bank-account-modal',
});

export interface HandleCMBankAccountModalProps {
  id?: number;
  onAccountUpdated: (id: number) => void;
}
export const HandleCMBankAccountModal: MFC<HandleCMBankAccountModalProps> = (p) => {
  const m = useModal(HandleCMBankAccountModal, p.id, p.onAccountUpdated);

  return (
    <Modal
      isVisible={p.isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      onClose={m.onCloseModalClicked}
      uat="cm-handle-bank-account-modal"
    >
      <ModalTitle>
        {p.id ? (
          <Trans id="cm_bank_account.title.edit">Edit account</Trans>
        ) : (
          <Trans id="cm_bank_account.title.add">Add account</Trans>
        )}
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={m.initialValues}>
          {!p.id ? null : <InputHidden name={Fields.id} />}

          <InputText
            label={<Trans id="cm_bank_modal.label.entity">Legal entity</Trans>}
            name={Fields.legalEntity}
            disabled={m.lockUI}
            required
          />

          <Select
            label={<Trans id="cm_bank_modal.label.bank_name">Bank name</Trans>}
            name={Fields.bank}
            disabled={m.lockUI}
            data={m.banks}
            required
          />

          <InputText
            label={<Trans id="cm_bank_modal.label.account_number">Account number</Trans>}
            name={Fields.accountNumber}
            disabled={m.lockUI}
            required
          />

          <Select
            label={<Trans id="cm_bank_modal.label.account_type">Account type</Trans>}
            name={Fields.bankAccountType}
            disabled={m.lockUI}
            data={m.bankAccountTypes}
            required
          />

          <InputNumber
            label={<Trans id="cm_bank_modal.label.balance">Initial balance</Trans>}
            name={Fields.currentBalance}
            min={0}
            maxDecimals={maxDecimals}
            disabled={m.lockUI}
            required
          />

          {p.id ? null : ( // технически запрещено менять валюту уже созданному аккаунту
            <CurrencySelect
              label={<Trans id="cm_bank_modal.label.currency">Currency</Trans>}
              name={Fields.currency}
              disabled={m.lockUI}
              required
            />
          )}

          <InputText
            label={<Trans id="cm_bank_modal.label.comment">Comment</Trans>}
            name={Fields.comment}
            disabled={m.lockUI}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} onAction={m.onFormAction} disabled={m.lockUI}>
          <Trans id="button.confirm">Confirm</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
