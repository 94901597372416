import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { defineMessage } from '@lingui/macro';
import { configSelectors } from '@config/store/selectors';
import { ValidationCode as V } from '@components/form/validators';
import { useI18n } from '@hooks/i18n';
import { VerificationStatus as S } from '@models/core';
import { ValidationMessage } from '../../../components/form/antd-form/validation-message'; // alias import will cause cyclic error
import { hubSpotErrorMessages } from './hubspot.validation';

export const useErrorsTranslates = () => {
  const i18n = useSelector(configSelectors.i18n);
  const lang = useSelector(configSelectors.lang);

  const translations = useMemo(
    () => ({
      [V.confirmPasswordMismatch]: i18n._(
        defineMessage({
          id: 'form.validation_error.confirm_password_mismatch',
          message: 'Please make sure your password match',
        })
      ),
      CHECK_BANK_ACCOUNT_BALANCE_ERROR: i18n._(
        defineMessage({
          id: 'form.validation_error.check_bank_account_balance_error',
          message: 'Bank account balance too low',
        })
      ),
      CHECK_BLOCKCHAIN_ACCOUNT_BALANCE_ERROR: i18n._(
        defineMessage({
          id: 'form.validation_error.check_blockchain_account_balance_error',
          message: 'Blockchain account balance too low',
        })
      ),
      CHECK_EXTENDED_TRANSACTION_CURRENCY_ERROR: i18n._(
        defineMessage({
          id: 'form.validation_error.check_extended_transaction_currency_error',
          message:
            'The selected currency does not match the sender’s or recipient’s account currencies',
        })
      ),
      CHECK_SAME_BANK_ACCOUNTS_ERROR: i18n._(
        defineMessage({
          id: 'form.validation_error.check_same_bank_accounts_error',
          message: 'Sender and receiver bank accounts are same',
        })
      ),
      CONVERSION_INSUFFICIENT_FUNDS: i18n._(
        defineMessage({
          id: 'form.validation_error.not_enough_balance_for_conversion',
          message: 'This user has not enough money to create conversion',
        })
      ),
      'error.currentPassword': i18n._(
        defineMessage({
          id: 'errors.current_password',
          message: 'You have entered an invalid password',
        })
      ),
      EXCHANGE_MIN_ERROR: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.conversion_min_amount',
            message: 'Minimum conversion: {MIN_VALUE}',
          }),
          params
        ),
      DEPOSIT_MIN_ERROR: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.deposit_min_amount',
            message: 'Minimum deposit: {MIN_VALUE}',
          }),
          params
        ),
      FILE_DAMAGED: i18n._(
        defineMessage({ id: 'form.validation_error.file_damaged', message: 'File is damaged' })
      ),
      FILE_IS_TOO_BIG_CODE: i18n._(
        defineMessage({
          id: 'form.validation_error.file_size_is_too_big',
          message: 'File size exceeded',
        })
      ),
      [V.fileSizeExceeded]: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.file_size_exceeded',
            message: 'Maximum file size: {MAX_FILE_SIZE} {UNIT}',
          }),
          params
        ),
      [V.fileWrongExtension]: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.file_wrong_extension',
            message: 'Allowed file formats: {ALLOWED_FORMATS}',
          }),
          params
        ),
      INVALID_CURRENT_PASSWORD: i18n._(
        defineMessage({
          id: 'form.validation_error.invalid_cur_password',
          message: 'You have entered an invalid password',
        })
      ),
      [V.maxLengthMore]: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.max_length_reached',
            message: 'Maximum allowed length: {MAX_LENGTH}',
          }),
          params
        ),
      [V.minLengthLess]: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.min_length_required',
            message: 'Minimum allowed length: {MIN_LENGTH}',
          }),
          params
        ),
      [V.maxNumMore]: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.max_number_reached',
            message: 'Maximum allowed value: {MAX_VALUE}',
          }),
          params
        ),
      [V.minNumLess]: (params) =>
        i18n._(
          defineMessage({
            id: 'form.validation_error.min_number_required',
            message: 'Minimum allowed value: {MIN_VALUE}',
          }),
          params
        ),
      MIN_VALUE_ERROR: i18n._(
        defineMessage({
          id: 'form.validation_error.min_value_not_reached',
          message: 'Minimum value not reached',
        })
      ),
      NOT_ALLOWED_FILE_EXTENSION_CODE: i18n._(
        defineMessage({
          id: 'form.validation_error.wrong_extension',
          message: 'Specified extension is not supported',
        })
      ),
      PARTICIPANT_ID_NOT_EXIST: i18n._(
        defineMessage({
          id: 'form.validation_error.participant_id_not_exist',
          message: 'The Participant ID doesn’t exists',
        })
      ),
      [V.passwordNewEqualPrev]: i18n._(
        defineMessage({
          id: 'form.validation_error.new_pass_equal_prev',
          message: 'New password must be different from the previous one',
        })
      ),
      POSITIVE_ERROR: i18n._(
        defineMessage({
          id: 'form.validation_error.must_be_greater_than_zero',
          message: 'Must be greater than 0',
        })
      ),
      'Required field value': i18n._(
        defineMessage({ id: 'errors.required_field_value', message: 'Required field value' })
      ), // for backward compatibility
      [V.required]: i18n._(
        defineMessage({ id: 'errors.required_field_value', message: 'Required field value' })
      ),
      SECONDARY_KEY_ALREADY_USED: i18n._(
        defineMessage({
          id: 'form.validation_error.secondary_key_already_used',
          message: 'Secondary public key is already used',
        })
      ),
      TRADING_ACCOUNT_CODE_WRONG_LENGTH: i18n._(
        defineMessage({
          id: 'form.validation_error.trading_account_code_wrong_length',
          message: 'The trading account code should not be more than 12 symbols',
        })
      ),
      UMS_ID_ALREADY_USED: i18n._(
        defineMessage({
          id: 'form.validation_error.ums_id_already_used',
          message: 'Ums id is already used',
        })
      ),
      [V.wrongDate]: i18n._(
        defineMessage({ id: 'form.errors.wrong_date', message: 'Wrong date value' })
      ),
      WEAK_PASSWORD: i18n._(
        defineMessage({
          id: 'form.validation_error.weak_password',
          message:
            'This password does not meet the requirements. Password must contain at least 8 characters and include one uppercase letter (A-Z), one lowercase letter (a-z) and one number (0-9)',
        })
      ),
      [V.duplicateValues]: i18n._(
        defineMessage({ id: 'form.errors.duplicate_values', message: 'Duplicate values' })
      ),
      [V.latinAndNumbersViolated]: i18n._(
        defineMessage({
          id: 'form.errors.latin_and_numbers_violated',
          message: 'Only latin characters and numbers can be used',
        })
      ),
      ...hubSpotErrorMessages(i18n),
    }),
    [lang]
  ); // eslint-disable-line

  return useCallback(
    (key, params?) => {
      if (typeof translations[key] === 'function') {
        return translations[key](params);
      }
      return translations[key];
    },
    [translations]
  );
};

export const useTranslateErrors = () => {
  const translates = useErrorsTranslates();
  return useCallback(
    (errors) => {
      const result = {};

      errors.forEach((error) => {
        result[error.field] = <ValidationMessage error={error} />;
      });

      return result;
    },
    [translates]
  );
};

// for corporate traders
export const kycStatesTranslates = {
  UNDEFINED: (
    <span>
      <Trans id="role.Undefined">Undefined</Trans>
    </span>
  ),
  PENDING: (
    <span>
      <Trans id="role.pending">Pending</Trans>
    </span>
  ),
  PRE_APPROVED: (
    <span>
      <Trans id="role.pre_approved">Pre-approved</Trans>
    </span>
  ),
  APPROVED: (
    <span>
      <Trans id="role.approved">Approved</Trans>
    </span>
  ),
  DISAPPROVE: (
    <span>
      <Trans id="role.disapproved">Disapproved</Trans>
    </span>
  ),
  RETRY: (
    <span>
      <Trans id="role.Retry">Retry</Trans>
    </span>
  ),
};

// for individual traders
export const useIndividualVerificationStatusTranslations = () => {
  const { i18n, lang } = useI18n();

  const translations = useMemo(
    () => ({
      [S.approved]: i18n._(
        defineMessage({ id: 'shared.verification.individual.approved', message: 'Approved' })
      ),
      [S.documentRequested]: i18n._(
        defineMessage({
          id: 'shared.verification.individual.document_req',
          message: 'Documents requested',
        })
      ),
      [S.processingVerification]: i18n._(
        defineMessage({ id: 'shared.verification.individual.processing', message: 'Processing' })
      ),
      [S.rejected]: i18n._(
        defineMessage({ id: 'shared.verification.individual.rejected', message: 'Rejected' })
      ),
      [S.resubmissionRequested]: i18n._(
        defineMessage({
          id: 'shared.verification.individual.resubmission',
          message: 'Resubmission',
        })
      ),
    }),
    [lang]
  ); // eslint-disable-line

  return translations;
};

// https://developers.sumsub.com/api-reference/#getting-verification-results
export const kycDisapproveReasonsTranslates = {
  FORGERY: (
    <span>
      <Trans id="kyc.disapprove.reason.forgery">Forgery attempt has been made</Trans>
    </span>
  ),
  DOCUMENT_TEMPLATE: (
    <span>
      <Trans id="kyc.disapprove.reason.document_template">
        Documents supplied are templates, downloaded from internet
      </Trans>
    </span>
  ),
  LOW_QUALITY: (
    <span>
      <Trans id="kyc.disapprove.reason.low_quality">
        Documents have low-quality that does not allow definitive conclusions to be made
      </Trans>
    </span>
  ),
  SPAM: (
    <span>
      <Trans id="kyc.disapprove.reason.spam">
        An applicant has been created by mistake or is just a spam user (irrelevant images were
        supplied)
      </Trans>
    </span>
  ),
  NOT_DOCUMENT: (
    <span>
      <Trans id="kyc.disapprove.reason.not_document">
        Documents supplied are not relevant for the verification procedure
      </Trans>
    </span>
  ),
  SELFIE_MISMATCH: (
    <span>
      <Trans id="kyc.disapprove.reason.selfie_mismatch">
        A user photo (profile image) does not match a photo on the provided documents
      </Trans>
    </span>
  ),
  ID_INVALID: (
    <span>
      <Trans id="kyc.disapprove.reason.id_invalid">
        A document that identifies a person (like a passport or an ID card) is not valid
      </Trans>
    </span>
  ),
  FOREIGNER: (
    <span>
      <Trans id="kyc.disapprove.reason.foreigner">
        When a client does not accept applicants from a different country or e.g. without a
        residence permit
      </Trans>
    </span>
  ),
  DUPLICATE: (
    <span>
      <Trans id="kyc.disapprove.reason.duplicate">
        This applicant was already created for this client, and duplicates are not allowed by the
        regulations
      </Trans>
    </span>
  ),
  BAD_AVATAR: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_avatar">
        When avatar does not meet the client's requirements
      </Trans>
    </span>
  ),
  WRONG_USER_REGION: (
    <span>
      <Trans id="kyc.disapprove.reason.wrong_user_region">
        When applicants from certain regions/countries are not allowed to be registered
      </Trans>
    </span>
  ),
  INCOMPLETE_DOCUMENT: (
    <span>
      <Trans id="kyc.disapprove.reason.incomplete_document">
        Some information is missing from the document, or it's partially visible
      </Trans>
    </span>
  ),
  BLACKLIST: (
    <span>
      <Trans id="kyc.disapprove.reason.blacklist">User is blacklisted</Trans>
    </span>
  ),
  UNSATISFACTORY_PHOTOS: (
    <span>
      <Trans id="kyc.disapprove.reason.unsatisfactory_photos">
        There were problems with the photos, like poor quality or masked information
      </Trans>
    </span>
  ),
  DOCUMENT_PAGE_MISSING: (
    <span>
      <Trans id="kyc.disapprove.reason.document_page_missing">
        Some pages of a document are missing (if applicable)
      </Trans>
    </span>
  ),
  DOCUMENT_DAMAGED: (
    <span>
      <Trans id="kyc.disapprove.reason.document_damaged">Document is damaged</Trans>
    </span>
  ),
  REGULATIONS_VIOLATIONS: (
    <span>
      <Trans id="kyc.disapprove.reason.regulations_violations">Regulations violations</Trans>
    </span>
  ),
  INCONSISTENT_PROFILE: (
    <span>
      <Trans id="kyc.disapprove.reason.inconsistent_profile">
        Data or documents of different persons were uploaded to one applicant
      </Trans>
    </span>
  ),
  PROBLEMATIC_APPLICANT_DATA: (
    <span>
      <Trans id="kyc.disapprove.reason.problematic_applicant_data">
        Applicant data does not match the data in the documents
      </Trans>
    </span>
  ),
  ADDITIONAL_DOCUMENT_REQUIRED: (
    <span>
      <Trans id="kyc.disapprove.reason.additional_document_required">
        Additional documents required to pass the check
      </Trans>
    </span>
  ),
  AGE_REQUIREMENT_MISMATCH: (
    <span>
      <Trans id="kyc.disapprove.reason.age_requirement_mismatch">
        Age requirement is not met (e.g. cannot rent a car to a person below 25yo)
      </Trans>
    </span>
  ),
  EXPERIENCE_REQUIREMENT_MISMATCH: (
    <span>
      <Trans id="kyc.disapprove.reason.experience_requirement_mismatch">
        Not enough experience (e.g. driving experience is not enough)
      </Trans>
    </span>
  ),
  CRIMINAL: (
    <span>
      <Trans id="kyc.disapprove.reason.criminal">The user is involved in illegal actions</Trans>
    </span>
  ),
  WRONG_ADDRESS: (
    <span>
      <Trans id="kyc.disapprove.reason.wrong_address">
        The address from the documents doesn't match the address that the user entered
      </Trans>
    </span>
  ),
  GRAPHIC_EDITOR: (
    <span>
      <Trans id="kyc.disapprove.reason.graphic_editor">
        The document has been edited by a graphical editor
      </Trans>
    </span>
  ),
  DOCUMENT_DEPRIVED: (
    <span>
      <Trans id="kyc.disapprove.reason.document_deprived">
        The user has been deprived of the document
      </Trans>
    </span>
  ),
  COMPROMISED_PERSONS: (
    <span>
      <Trans id="kyc.disapprove.reason.compromised_persons">
        The user does not correspond to Compromised Person Politics
      </Trans>
    </span>
  ),
  PEP: (
    <span>
      <Trans id="kyc.disapprove.reason.pep">The user belongs to the PEP category</Trans>
    </span>
  ),
  ADVERSE_MEDIA: (
    <span>
      <Trans id="kyc.disapprove.reason.adverse_media">
        The user was found in the adverse media
      </Trans>
    </span>
  ),
  FRAUDULENT_PATTERNS: (
    <span>
      <Trans id="kyc.disapprove.reason.fraudulent_patterns">Fraudulent behavior was detected</Trans>
    </span>
  ),
  SANCTIONS: (
    <span>
      <Trans id="kyc.disapprove.reason.sanctions">The user was found on sanction lists</Trans>
    </span>
  ),
  NOT_ALL_CHECKS_COMPLETED: (
    <span>
      <Trans id="kyc.disapprove.reason.not_all_checks_completed">
        All checks were not completed
      </Trans>
    </span>
  ),
  FRONT_SIDE_MISSING: (
    <span>
      <Trans id="kyc.disapprove.reason.front_side_missing">
        Front side of the document is missing
      </Trans>
    </span>
  ),
  BACK_SIDE_MISSING: (
    <span>
      <Trans id="kyc.disapprove.reason.back_side_missing">
        Back side of the document is missing
      </Trans>
    </span>
  ),
  SCREENSHOTS: (
    <span>
      <Trans id="kyc.disapprove.reason.screenshots">The user uploaded screenshots</Trans>
    </span>
  ),
  BLACK_AND_WHITE: (
    <span>
      <Trans id="kyc.disapprove.reason.black_and_white">
        The user uploaded black and white photos of documents
      </Trans>
    </span>
  ),
  INCOMPATIBLE_LANGUAGE: (
    <span>
      <Trans id="kyc.disapprove.reason.incompatible_language">
        The user should upload translation of his document
      </Trans>
    </span>
  ),
  EXPIRATION_DATE: (
    <span>
      <Trans id="kyc.disapprove.reason.expiration_date">The user uploaded expired document</Trans>
    </span>
  ),
  UNFILLED_ID: (
    <span>
      <Trans id="kyc.disapprove.reason.unfilled_id">
        The user uploaded the document without signatures and stamps
      </Trans>
    </span>
  ),
  BAD_SELFIE: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_selfie">The user uploaded a bad selfie</Trans>
    </span>
  ),
  BAD_VIDEO_SELFIE: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_video_selfie">
        The user uploaded a bad video selfie
      </Trans>
    </span>
  ),
  BAD_FACE_MATCHING: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_face_matching">
        Face check between document and selfie failed
      </Trans>
    </span>
  ),
  BAD_PROOF_OF_IDENTITY: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_proof_of_identity">
        The user uploaded a bad ID document
      </Trans>
    </span>
  ),
  BAD_PROOF_OF_ADDRESS: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_proof_of_address">
        The user uploaded a bad proof of address
      </Trans>
    </span>
  ),
  BAD_PROOF_OF_PAYMENT: (
    <span>
      <Trans id="kyc.disapprove.reason.bad_proof_of_payment">
        The user uploaded a bad proof of payment
      </Trans>
    </span>
  ),
  SELFIE_WITH_PAPER: (
    <span>
      <Trans id="kyc.disapprove.reason.selfie_with_paper">
        The user should upload a special selfie (e.g. selfie with paper and date on it)
      </Trans>
    </span>
  ),
  OTHER: (
    <span>
      <Trans id="kyc.disapprove.reason.other">Some unclassified reason</Trans>
    </span>
  ),
  REQUESTED_DATA_MISMATCH: (
    <span>
      <Trans id="kyc.disapprove.reason.requested_data_mismatch">
        Provided info doesn't match with recognized from document data
      </Trans>
    </span>
  ),
  OK: (
    <span>
      <Trans id="kyc.disapprove.reason.ok">Custom reject label</Trans>
    </span>
  ),
  COMPANY_NOT_DEFINED_STRUCTURE: (
    <span>
      <Trans id="kyc.disapprove.reason.company_not_defined_structure">
        Could not establish the entity's control structure
      </Trans>
    </span>
  ),
  COMPANY_NOT_DEFINED_BENEFICIARIES: (
    <span>
      <Trans id="kyc.disapprove.reason.company_not_defined_beneficiaries">
        Could not identify and duly verify the entity's beneficial owners
      </Trans>
    </span>
  ),
  COMPANY_NOT_VALIDATED_BENEFICIARIES: (
    <span>
      <Trans id="kyc.disapprove.reason.company_not_validated_beneficiaries">
        Beneficiaries are not validated
      </Trans>
    </span>
  ),
  COMPANY_NOT_DEFINED_REPRESENTATIVES: (
    <span>
      <Trans id="kyc.disapprove.reason.company_not_defined_representatives">
        Representatives are not defined
      </Trans>
    </span>
  ),
  COMPANY_NOT_VALIDATED_REPRESENTATIVES: (
    <span>
      <Trans id="kyc.disapprove.reason.company_not_validated_representatives">
        Representatives are not validated
      </Trans>
    </span>
  ),
};
