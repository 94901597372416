import { useMemo } from 'react';
import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import api from './api';
import { useRedeemStatuses } from '../hooks';

export const useRedeemCoins = () => {
  const { data: redeemCoinsData, isLoading: isRedeemCoinsLoading } = useQuery(api.getRedeemCoins, {
    initial: [],
  });

  const redeemCoins: Array<SelectItem> = useMemo(() => {
    return redeemCoinsData?.map((r) => ({
      label: `${r.asset} (${r.metal.label})`,
      value: r.id,
    }));
  }, [redeemCoinsData]);

  return { isRedeemCoinsLoading, redeemCoins };
};

export const useStatuses = () => {
  const statuses = useRedeemStatuses();

  const redeemStatuses: Array<SelectItem> = useMemo(() => {
    return Object.entries(statuses)?.map(([status, label]) => ({
      label: label,
      value: status,
    }));
  }, [statuses]);

  return { redeemStatuses };
};
