import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { ButtonUpload } from '@components/button/upload';
import { ButtonDownload } from '@components/button/download';
import { ButtonAction } from '@components/button';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { metalsPageSelectors as requestsSelectors } from '../store/selectors';
import { metalsPageUserActions as requestsUserActions } from '../store/user.acions';

const mapStateToProps = (root) => ({
  lockUI: requestsSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onDownloadClicked: () => dispatch(requestsUserActions.onDownloadClicked()),
  onUploadClicked: (file) => dispatch(requestsUserActions.onUploadClicked(file)),
});

const ConnectedMetalsActions = ({ lockUI, onDownloadClicked, onUploadClicked }) => {
  return (
    <div className="row mx-0 mb-md">
      <Tooltip
        placement="bottom"
        trigger={[TooltipTrigger.hover]}
        title={
          <Trans id="metals_page.tooltip.download">
            Download file for making trust transaction
          </Trans>
        }
      >
        <ButtonDownload className="mr-md" disabled={lockUI} onClick={onDownloadClicked} />
      </Tooltip>

      <Tooltip
        placement="bottom"
        trigger={[TooltipTrigger.hover]}
        title={
          <Trans id="metals_page.tooltip.upload">Upload file for making trust transaction</Trans>
        }
      >
        <ButtonUpload action={ButtonAction.file} disabled={lockUI} onClick={onUploadClicked} />
      </Tooltip>
    </div>
  );
};

export const MetalsActions = connect(mapStateToProps, mapDispatchToProps)(ConnectedMetalsActions);
