import React, { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';

import { dater } from '@helper/date';
import { TariffsIds as Tariffs } from '@config/core';
import { closeModalAction } from '@modules/modal';
import { notifySuccess } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { formService, OnFormAction } from '@components/form';
import { useGetTariffName } from '../../../service';
import { useUserTariff } from './hooks';
import api, { UpdateTariffFields as Fields, UpdateTariffForm as Form } from './api';

export { UpdateTariffFields as Fields } from './api';
export const formId = 'updateTariffFormId';
const minDate = new Date().toISOString();

const isSameTariff = (initial: Form, current: Form) => {
  return `${initial.tariffId}${initial.endDate}` === `${current.tariffId}${current.endDate}`;
};

export const useModel = (modal, userId: number, onTariffUpdated: () => void) => {
  const closeModal = useCallDispatch(closeModalAction);
  const getTariffName = useGetTariffName();

  const [isUpdateTariffLoading, setUpdateTariffLoading] = useState(false);
  const { userTariff, isUserTariffLoading } = useUserTariff(userId);
  const lockUI = isUpdateTariffLoading || isUserTariffLoading;

  const tariffs = useMemo(() => userTariff?.availableTariffs || [], [userTariff]);

  const formInitial = useMemo(
    () => ({
      [Fields.endDate]:
        userTariff?.current?.endDate && dater.toGqlDate(userTariff?.current?.endDate),
      [Fields.tariffId]: userTariff?.current?.id,
      [Fields.userId]: userId,
    }),
    [userTariff]
  );

  const onTariffChanged = (id: string) => {
    if (!id) {
      return;
    }
    const form = formService.get<Form>(formId);
    const tariffId = +id;
    if (tariffId === Tariffs.noMembership) {
      form.setFieldsValue({ [Fields.endDate]: undefined });
    } else {
      const todayMs = new Date().getTime();
      const tariffInitial = userTariff?.current;
      const tariffSelected = tariffs.find((t) => t.id === tariffId);
      const tariffSelectedDurationDefault = tariffSelected?.defaultDuration;
      const endDateSelectedMs =
        tariffSelectedDurationDefault && tariffSelectedDurationDefault * 86400000 + todayMs;
      const endDateInitialMs = tariffInitial?.endDate;
      const endDateMs = tariffInitial.id === tariffId ? endDateInitialMs : endDateSelectedMs;
      form.setFieldsValue({ [Fields.endDate]: dater.toGqlDateFromMs(+endDateMs) });
    }
  };

  const onFormAction: OnFormAction<Form> = {
    error: () => setUpdateTariffLoading(false),
    submit: async (form) => {
      if (isSameTariff(formInitial, form)) {
        return closeModal(modal);
      }
      setUpdateTariffLoading(true);
      await api.updateTariff(form);
      onTariffUpdated && onTariffUpdated();
      setUpdateTariffLoading(true);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="edit_tariff_modal.notification.success_update.text">User’s plan updated</Trans>
        ),
      });
      closeModal(modal);
    },
  };

  return {
    formInitial,
    getTariffName,
    lockUI,
    minDate,
    onCloseModalClicked: () => closeModal(modal),
    onFormAction,
    onTariffChanged,
    tariffs,
  };
};
