import { useRef, useState } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { debounce } from 'lodash';
import { Trans, defineMessage } from '@lingui/macro';
import classnames from 'classnames';
import {
  Table,
  useTable,
  ColumnType,
  KeyExtractor,
  OnLoadMore,
  OnSortOrderChange,
  SortOrder,
  RowConfig,
} from '@components/table2/autosized';
import { ProfileLinkCell, TablePane } from '@components/table2/utils';
import { PageContent, PageTitle } from '@components/page';
import { mapTableSorting } from '@helper/sorting';
import { useSyncRef } from '@hooks/core';
import { PermissionGroup } from '@permissions/core';
import { useI18n } from 'containers/services/commonService';
import { profileOtherPagePermissionsGroup } from '../../profile/other/permissions';
import api, {
  permissionGroup as apiPermissionGroup,
  VirtualBalanceItem,
  GetVirtualBalancesSortKey,
} from './api';
import s from './index.module.scss';

export const otherProfilePermissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:virtual-balance-report-page-id-cell',
});

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, otherProfilePermissionGroup],
  marker: 'layout:virtual-balance-report-page',
});

enum ColumnKey {
  clientId = 'clientId',
  clientName = 'clientName',
  currency = 'currency',
  registerDate = 'registerDate',
  virtualBalance = 'virtualBalance',
}

type DataList = {
  key: number;
  data: VirtualBalanceItem[];
  hasMore: boolean;
  sortOrder: SortOrder;
  initialLoading: boolean;
};

type LoadIndex = {
  nextPage: number;
};

const columns: ColumnType<VirtualBalanceItem>[] = [
  {
    key: ColumnKey.registerDate,
    dataIndex: ['balanceOwner', 'issuedAt'],
    sorting: ['ascend', 'descend'],
    headCell: () => <Trans id="virtual_balance.registration_date">Registration date</Trans>,
    rowCell: ({ data }) => <>{data.balanceOwner.issuedAt.formatLocalDateTime()}</>,
    width: 200,
  },
  {
    key: ColumnKey.clientId,
    dataIndex: ['balanceOwner', 'id'],
    headCell: () => <Trans id="virtual_balance.client_id">Client ID</Trans>,
    rowCell: ({ data }) => <>{data.balanceOwner.id}</>,
    width: 200,
  },
  {
    key: ColumnKey.clientName,
    dataIndex: ['balanceOwner', 'displayName'],
    headCell: () => <Trans id="virtual_balance.client_name">Client name</Trans>,
    rowCell: ({ data }) => (
      <ProfileLinkCell text={data.balanceOwner.displayName} id={data.balanceOwner.id} />
    ),
    width: 200,
  },
  {
    key: ColumnKey.virtualBalance,
    dataIndex: ['amount'],
    headCell: () => <Trans id="virtual_balance.virtual_balance">Virtual Balance</Trans>,
    rowCell: ({ data }) => <>{data.amount}</>,
    width: 200,
  },
  {
    key: ColumnKey.currency,
    dataIndex: ['currency', 'code'],
    headCell: () => <Trans id="virtual_balance.currency">Currency</Trans>,
    rowCell: ({ data }) => <>{data.currency.code}</>,
    width: 200,
  },
];

const rowConfig: RowConfig<VirtualBalanceItem> = {
  height: 41,
};

const sortMap = {
  [ColumnKey.registerDate]: GetVirtualBalancesSortKey.issuedAt,
};

const INITIAL_PAGE = 0;
const SORTING_DEBOUNCE = 400;

export const VirtualBalancePage = () => {
  const { i18n } = useI18n();
  const [dataList, setDataList] = useState<DataList>({
    key: 0,
    data: [],
    hasMore: true,
    sortOrder: [],
    initialLoading: false,
  });
  const dataKeyRef = useSyncRef(dataList.key);
  const sortOrderRef = useSyncRef(dataList.sortOrder);
  const initialLoadKeyRef = useRef(0);
  const initialPageRef = useRef(INITIAL_PAGE);

  const keyExtractor = useCallbackOne<KeyExtractor<VirtualBalanceItem>>((item) => {
    return item.balanceOwner.id;
  }, []);

  const loadMoreItems = useCallbackOne<OnLoadMore<LoadIndex>>(({ lastIndexData }) => {
    const page = lastIndexData?.nextPage ?? initialPageRef.current;
    const queryDataKey = dataKeyRef.current;
    const querySortOrder = mapTableSorting(sortOrderRef.current, sortMap);

    return api
      .getVirtualBalances(page, querySortOrder)
      .then(({ data: incomingData, isHasMore }) => {
        setDataList((currentData) => {
          if (dataKeyRef.current !== queryDataKey) {
            return currentData;
          }
          return {
            ...currentData,
            data: currentData.data.concat(incomingData),
            hasMore: isHasMore,
          };
        });
        return { indexData: { nextPage: page + 1 } };
      });
  }, []);

  const loadInitialItems = useCallbackOne(
    debounce((order: SortOrder) => {
      const queryInitialLoadKey = initialLoadKeyRef.current;
      const mappedOrder = mapTableSorting(order, sortMap);

      api.getVirtualBalances(INITIAL_PAGE, mappedOrder).then(({ data: items, isHasMore }) => {
        if (queryInitialLoadKey !== initialLoadKeyRef.current) {
          return;
        }
        initialPageRef.current = INITIAL_PAGE + 1;
        setDataList((currentData) => ({
          key: currentData.key + 1,
          data: items,
          hasMore: isHasMore,
          initialLoading: false,
          sortOrder: order,
        }));
      });
    }, SORTING_DEBOUNCE),
    []
  );

  const onSortOrderChange = useCallbackOne<OnSortOrderChange>((order) => {
    initialLoadKeyRef.current += 1;
    setDataList((currentData) => ({
      ...currentData,
      initialLoading: true,
    }));
    loadInitialItems(order);
  }, []);

  const tableInstance = useTable<VirtualBalanceItem>({
    sorter: {
      onAfterOrderChange: onSortOrderChange,
    },
  });

  return (
    <PageContent
      className={classnames(s.container, s.verticalFiller)}
      uat="statements-virtual-balance-page"
    >
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.virtual_balance',
            message: 'Virtual Balance',
          })
        )}
        backwardLink={'/statements'}
      />
      <TablePane className={s.verticalFiller}>
        <Table
          className={s.verticalFiller}
          columns={columns}
          data={dataList.data}
          hasMore={dataList.hasMore}
          instance={tableInstance}
          keyExtractor={keyExtractor}
          listKey={dataList.key}
          loading={dataList.initialLoading}
          onLoadMore={loadMoreItems}
          rowConfig={rowConfig}
        />
      </TablePane>
    </PageContent>
  );
};
