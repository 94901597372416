import React, { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import { useCallDispatch } from '@hooks/core';
import { Col, Grid } from '@components/grid';
import { Form, InputNumber, Select } from '@components/form';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { maxDecimalsSMStable as decimals } from '../../transfer/transfer-stable/store/model';
import { actions, selectors } from '../store';
import { Fields, formId } from '../store/model';
import { SendStableToInvButton } from './send-button';

export const SendStableToInvestorModal: MFC = memo(({ isVisible }) => {
  const amountMax = useSelector(selectors.stableAmountMax);
  const formInitial = useSelector(selectors.formInitial);
  const lockUI = useSelector(selectors.lockUI);
  const assets = useSelector(selectors.assets);

  const onAssetChanged = useCallDispatch(actions.onAssetChanged);
  const onCloseModalClicked = useCallDispatch(actions.onCloseModalClicked);
  const onFormChanged = useCallDispatch(actions.onFormChanged);
  const onModalLeft = useCallDispatch(actions.onModalLeft);
  const onModalOpened = useCallDispatch(actions.onModalOpened);

  useEffect(() => {
    onModalOpened();
    return () => {
      onModalLeft();
    };
  }, []); // eslint-disable-line

  return (
    <Modal
      isVisible={isVisible}
      disabled={lockUI}
      className="stable-to-inv-modal"
      onClose={onCloseModalClicked}
    >
      <ModalTitle>
        <Trans id="stable_to_inv_modal.title">Send currency to the investor account</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={formInitial} onChange={onFormChanged}>
          <Grid pattern={{ sm: 2 }}>
            <Col>
              <Select
                name={Fields.asset}
                label={<Trans id="stable_to_inv_modal.label.currency">Select currency</Trans>}
                data={assets}
                disabled={lockUI}
                // reset amount value to simplify max send value validation
                onChange={() => onAssetChanged()}
                required={true}
              />
            </Col>

            <Col>
              <InputNumber
                name={Fields.amount}
                label={<Trans id="stable_to_inv_modal.label.amount">Enter amount</Trans>}
                min={0}
                max={amountMax}
                maxDecimals={decimals}
                disabled={lockUI}
                required={true}
              />
            </Col>
          </Grid>

          <div>
            <Trans id="stable_to_inv_modal.hint.helper_info">
              Currency will be sent into your linked investor account.
            </Trans>
          </div>
        </Form>
      </ModalContent>

      <ModalFooter>
        <SendStableToInvButton />
      </ModalFooter>
    </Modal>
  );
});
