import { FC, useContext, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { configSelectors, configServiceActions } from '@config/core';
import { useI18n } from '../../../services/commonService';
import { Context } from '../context';
import { getCurrentStep, getCurrentStepIndex } from '../steps';

type Step = {
  component: FC;
  hideStepNumber?: boolean;
};

export const useModel = () => {
  const { i18n } = useI18n();
  const reduxDispatch = useDispatch();
  const { state, dispatch } = useContext(Context);
  const lastFinishedStep = useSelector(configSelectors.lastPostRegStage);
  const isCorporate = useSelector(configSelectors.isCorporateTrader);
  const isIndividual = useSelector(configSelectors.isIndividualTrader);
  const stepIndex = state.currentIndex;

  useEffect(() => {
    reduxDispatch(configServiceActions.postRegInitiated());
    const i = getCurrentStepIndex(isCorporate, isIndividual, lastFinishedStep);
    if (i) {
      dispatch({ type: 'ON_POST_REG_INITIATED', payload: i });
    }
  }, []);

  const step: Step = useMemo(() => {
    const currentStep = getCurrentStep(isCorporate, isIndividual, stepIndex);
    return currentStep
      ? {
          component: currentStep.component,
          hideStepNumber: currentStep.hideStepNumber,
        }
      : {
          component: () => null,
        };
  }, [stepIndex]);

  return {
    i18n,
    isShowHubspotFailed: state.isShowHubspotFailed,
    step,
  };
};
