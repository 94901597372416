import ResizeObserver from 'resize-observer-polyfill';

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

// polyfills for IE11
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if ('HTMLCollection' in window && !HTMLCollection.prototype.forEach) {
  HTMLCollection.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if ('Object' in window && !window.Object.values) {
  window.Object.values = (object) =>
    Object.keys(object).map(function (key) {
      return object[key];
    });
}

if ('BigInt' in window) {
  // workaround for JSON.stringify big int
  window.BigInt.prototype.toJSON = function () {
    return this.toString() + 'n';
  };
}
