import classnames from 'classnames';

import { ReactComponent as ArrowRight } from '@images/icons/arrow-right-wide.svg';
import { ButtonIconBordered } from '../index.strict';
import s from './index.module.scss';

import type { FC, MouseEventHandler } from 'react';

export const ButtonBack: FC<{
  className?: string;
  disabled?: boolean;
  onClick: MouseEventHandler;
  uat?: string;
}> = ({ className, disabled, onClick, uat }) => {
  return (
    <ButtonIconBordered
      className={classnames(s.button, className)}
      onClick={onClick}
      disabled={disabled}
      uat={uat}
    >
      <ArrowRight />
    </ButtonIconBordered>
  );
};
