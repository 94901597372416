import { AccountType } from '@config/store/model';
import { accessService as access } from '@permissions/access.service';
import { traderPermissions, Permission } from '@permissions/core';

class AccountService {
  /**
   * Please use this method only when you need to check type for other users (non current).
   * For current authorized user you can use redux selector configSelectors.accountType
   */
  public getType(permissions: string): AccountType {
    if (!permissions) {
      return AccountType.unknown;
    }

    if (
      access.isAllowed({
        allowedPermissions: { or: [Permission.CORPORATE_PROFILE] },
        currentPermissions: permissions,
      })
    ) {
      return AccountType.company;
    }

    if (
      access.isAllowed({
        allowedPermissions: { or: [Permission.INDIVIDUAL_PROFILE] },
        currentPermissions: permissions,
      })
    ) {
      return AccountType.individual;
    }

    return AccountType.unknown;
  }

  /**
   * NOTE: use this method only when you need to check permissions for non current authorized user.
   * For current authorized user use permissionsSelectors.isHasAccess or, in hard cases - this.isAllowed method
   */
  public hasPermission(currentPermissions: string, permissionsList: Array<Permission>): boolean {
    if (!currentPermissions) {
      return false;
    }
    return access.isAllowed({
      currentPermissions: currentPermissions,
      allowedPermissions: { or: permissionsList },
    });
  }

  /**
   * NOTE: use this method only when you need to check role for non current authorized user.
   * For current authorized user use configSelectors.isInvestor
   */
  public isTrader(currentPermissions: string): boolean {
    return this.hasPermission(currentPermissions, traderPermissions);
  }

  /**
   * NOTE: use this method only when you need to check account type of investor.
   * For current authorized investor use configSelectors.isIndividualTrader
   */
  public isIndividualTrader(currentPermissions: string): boolean {
    return this.hasPermission(currentPermissions, [Permission.INDIVIDUAL_USER]);
  }

  /**
   * NOTE: use this method only when you need to check account type of investor.
   * For current authorized investor use configSelectors.isCorporateTrader
   */
  public isCorporateTrader(currentPermissions: string): boolean {
    return this.hasPermission(currentPermissions, [Permission.CORPORATE_USER]);
  }
}

export const accountService = new AccountService();
