import { usersPageStateInitial, UsersPageActionsTypes as UsersTypes } from './model';

export const usersPageReducer = (state = usersPageStateInitial, action) => {
  switch (action.type) {
    case UsersTypes.EVENT_USERS_UPDATED: {
      const config = action.payload;
      const isNewUser = config.isNewUser;
      const user = config.user;
      const users = isNewUser
        ? [user, ...state.users]
        : state.users.map((item) => (item.id === user.id ? user : item));

      return {
        ...state,
        pageIndex: usersPageStateInitial.pageIndex,
        users: users,
      };
    }

    case UsersTypes.LOAD_MORE_USERS: {
      return {
        ...state,
        isUsersLoading: true,
      };
    }

    case UsersTypes.LOAD_MORE_USERS_FAIL: {
      return {
        ...state,
        isHasMore: false,
        isUsersLoading: false,
      };
    }

    case UsersTypes.LOAD_MORE_USERS_SUCCESS: {
      const { isHasMore, isSort, data: users } = action.payload;
      return {
        ...state,
        isHasMore: isHasMore,
        isUsersLoading: false,
        users: isSort ? users : [...state.users, ...users],
      };
    }

    case UsersTypes.ON_SEARCH_FILLED: {
      return {
        ...state,
        pageIndex: 0,
        search: action.payload,
        users: [],
      };
    }

    case UsersTypes.ON_PAGE_LEFT: {
      return {
        ...usersPageStateInitial,
      };
    }

    case UsersTypes.ON_TABLE_SCROLLED: {
      return {
        ...state,
        pageIndex: state.pageIndex + 1,
      };
    }

    case UsersTypes.ON_TABLE_SORTED: {
      const column = action.payload;
      const sort = [...state.sort];
      const activeColumn = sort.splice(
        sort.findIndex((col) => col.id === column.id),
        1
      )[0];
      activeColumn.direction = column.direction;

      return {
        ...state,
        pageIndex: 0,
        sort: [activeColumn, ...sort],
      };
    }

    default: {
      return state;
    }
  }
};
