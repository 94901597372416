import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { CoinDTOInput } from '../../input-dto';

type Currency = {
  id: number;
};

type Issuer = {
  id: number;
};

type LiquidityProvider = {
  id: number;
};

type ListingBroker = {
  id: number;
};

type Metal = {
  id: number;
};

type Unit = {
  id: number;
};

type UploadFile = {
  id: number;
  contentType: string;
  name: string;
  size: number;
  uuid: string;
};

export type CoinInfoFile = {
  id?: number;
  file: UploadFile;
  title: string;
};

type CoinInfo = {
  denominationUnit: number;
  liquidityProviders: LiquidityProvider[];
  primaryMarketProductInfo: string;
  physicalRedemption: boolean;
  unitAndDenomination: string;
  weighMeasurement: Unit;
};

type CqgSymbol = {
  id: number;
  contractName: string;
  currency: Currency;
  symbol: string;
};

type RedeemOption = {
  id: number;
  amount: number;
  description: string;
  minimalQuantity: number;
  name: string;
  price: number;
  priceType: string;
  unit: Unit;
};

type Vault = {
  id: number;
};

export type Coin = {
  id: number;
  asset: string;
  brandName: string;
  coinInfo: CoinInfo; // primaryMarketProductInfo - issuer
  cqgSymbols: CqgSymbol[];
  infoFiles: CoinInfoFile[]; // issuer
  issuer: Issuer;
  listingBroker: ListingBroker;
  listingBrokerFee: number;
  managementFee: number;
  metal: Metal;
  name: string;
  positionDays: number;
  positionNegativeDays: number;
  positionPercent: number;
  productInfo: string; // issuer
  redeemOptions: RedeemOption[]; // issuer
  sellCommission: number;
  technicalSpecification: string;
  transactionCommission: number;
  vault: Vault;
};

const getCoinQuery = 'coinDetailed';
const getCoinFields = [
  'id',
  'asset',
  'brandName',
  {
    coinInfo: [
      'denominationUnit',
      { liquidityProviders: ['id'] },
      'physicalRedemption',
      'primaryMarketProductInfo',
      'unitAndDenomination',
      { weighMeasurement: ['id'] },
    ],
  },
  { cqgSymbols: ['id', 'contractName', { currency: ['id'] }, 'symbol'] },
  { infoFiles: ['id', { file: ['id', 'contentType', 'name'] }, 'title'] },
  { issuer: ['id'] },
  { listingBroker: ['id'] },
  'listingBrokerFee',
  'managementFee',
  { metal: ['id'] },
  'name',
  'positionDays',
  'positionNegativeDays',
  'positionPercent',
  'productInfo',
  {
    redeemOptions: [
      'id',
      'amount',
      'description',
      'minimalQuantity',
      'name',
      'price',
      'priceType',
      { unit: ['id'] },
    ],
  },
  'sellCommission',
  'technicalSpecification',
  'transactionCommission',
  { vault: ['id'] },
];
const getCoin = makeQuery({
  permissions: Permission.ASSET_VIEW,
  queryName: getCoinQuery,
  queryFields: getCoinFields,
  query: (id: number): Promise<Coin> => {
    return stompClient.getData(
      getCoinQuery,
      sign(getCoinQuery, getCoinFields),
      v.idInput({ coin: { id } })
    );
  },
});

export type EditCoinData = CoinDTOInput;

const updateCoinQuery = 'modifyCoin';
const updateCoinFields = ['value'];
const updateCoin = makeQuery({
  permissions: Permission.ASSET_EDIT,
  queryName: updateCoinQuery,
  queryFields: updateCoinFields,
  query: (coinId: string, coinData: CoinDTOInput): Promise<{ value: number }> => {
    const typedValues = {
      ...v.coinDTOInput({ coinDTO: coinData }),
      ...v.idInput({ coin: { id: coinId } }),
    };
    return stompClient.sendData(
      updateCoinQuery,
      sign(updateCoinQuery, updateCoinFields),
      typedValues
    );
  },
});

const api = {
  getCoin,
  updateCoin,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:edit-coin');

export default api;
