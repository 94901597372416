import { forwardRef, ComponentType } from 'react';
import { HeaderWrapperProps } from '../../bound-cells';
import s from './default-header-container.module.scss';

export const DefaultHeaderContainer = forwardRef<HTMLTableSectionElement>(
  function DefaultHeaderContainer(props: HeaderWrapperProps, ref) {
    return (
      <thead ref={ref}>
        <tr>
          <td className={s.wrapper} colSpan={1000}>
            <div className={s.container}>{props.children}</div>
          </td>
        </tr>
      </thead>
    );
  }
) as ComponentType<HeaderWrapperProps>;
