import { ReduxAction } from '@models/redux';
import { TableData } from '@models/core';
import { BeneficialOwner } from '../beneficial-api.service';
import {
  BeneficialOwnersActionsTypes as T,
  LoadMoreBeneficialOwnersPayload,
  BlockedMetalsUpdatedEvent,
} from './model';

class EventBlockedMetalsUpdatedAction extends ReduxAction<
  T.EVENT_BLOCKED_METALS_UPDATED,
  BlockedMetalsUpdatedEvent
> {
  public readonly type: T.EVENT_BLOCKED_METALS_UPDATED = T.EVENT_BLOCKED_METALS_UPDATED;
  public constructor(public payload: BlockedMetalsUpdatedEvent) {
    super(payload);
  }
}

class GetDigitalMetalsAction extends ReduxAction<T.GET_DIGITAL_METALS, void> {
  public readonly type: T.GET_DIGITAL_METALS = T.GET_DIGITAL_METALS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetDigitalMetalsFailAction extends ReduxAction<T.GET_DIGITAL_METALS_FAIL, Error> {
  public readonly type: T.GET_DIGITAL_METALS_FAIL = T.GET_DIGITAL_METALS_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GetDigitalMetalsSuccessAction extends ReduxAction<
  T.GET_DIGITAL_METALS_SUCCESS,
  Array<string>
> {
  public readonly type: T.GET_DIGITAL_METALS_SUCCESS = T.GET_DIGITAL_METALS_SUCCESS;
  public constructor(public payload: Array<string>) {
    super(payload);
  }
}

class LoadMoreBeneficialOwnersAction extends ReduxAction<
  T.LOAD_MORE_BENEFICIAL_OWNERS,
  LoadMoreBeneficialOwnersPayload
> {
  public readonly type: T.LOAD_MORE_BENEFICIAL_OWNERS = T.LOAD_MORE_BENEFICIAL_OWNERS;
  public constructor(public payload: LoadMoreBeneficialOwnersPayload) {
    super(payload);
  }
}

class LoadMoreBeneficialOwnersFailAction extends ReduxAction<
  T.LOAD_MORE_BENEFICIAL_OWNERS_FAIL,
  Error
> {
  public readonly type: T.LOAD_MORE_BENEFICIAL_OWNERS_FAIL = T.LOAD_MORE_BENEFICIAL_OWNERS_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class LoadMoreBeneficialOwnersSuccessAction extends ReduxAction<
  T.LOAD_MORE_BENEFICIAL_OWNERS_SUCCESS,
  TableData<BeneficialOwner>
> {
  public readonly type: T.LOAD_MORE_BENEFICIAL_OWNERS_SUCCESS =
    T.LOAD_MORE_BENEFICIAL_OWNERS_SUCCESS;
  public constructor(public payload: TableData<BeneficialOwner>) {
    super(payload);
  }
}

export const beneficialOwnersServiceActions = {
  eventBlockedMetalsUpdated: (event: BlockedMetalsUpdatedEvent) =>
    new EventBlockedMetalsUpdatedAction(event).get(),
  getDigitalMetals: () => new GetDigitalMetalsAction().get(),
  getDigitalMetalsFail: (error: Error) => new GetDigitalMetalsFailAction(error).get(),
  getDigitalMetalsSuccess: (list: Array<string>) => new GetDigitalMetalsSuccessAction(list).get(),
  loadMoreBeneficialOwners: (payload: LoadMoreBeneficialOwnersPayload) =>
    new LoadMoreBeneficialOwnersAction(payload).get(),
  loadMoreBeneficialOwnersFail: (error: Error) =>
    new LoadMoreBeneficialOwnersFailAction(error).get(),
  loadMoreBeneficialOwnersSuccess: (table: TableData<BeneficialOwner>) =>
    new LoadMoreBeneficialOwnersSuccessAction(table).get(),
};

export type BeneficialOwnersServiceActionsTypes =
  | EventBlockedMetalsUpdatedAction
  | GetDigitalMetalsAction
  | GetDigitalMetalsFailAction
  | GetDigitalMetalsSuccessAction
  | LoadMoreBeneficialOwnersAction
  | LoadMoreBeneficialOwnersFailAction
  | LoadMoreBeneficialOwnersSuccessAction;

export declare namespace BeneficialOwnersServiceActions {
  type eventBlockedMetalsUpdated = EventBlockedMetalsUpdatedAction;
  type getDigitalMetals = GetDigitalMetalsAction;
  type getDigitalMetalsFail = GetDigitalMetalsFailAction;
  type getDigitalMetalsSuccess = GetDigitalMetalsSuccessAction;
  type loadMoreBeneficialOwners = LoadMoreBeneficialOwnersAction;
  type loadMoreBeneficialOwnersFail = LoadMoreBeneficialOwnersFailAction;
  type loadMoreBeneficialOwnersSuccess = LoadMoreBeneficialOwnersSuccessAction;
}
