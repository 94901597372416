import { RootState as RS } from '@models/redux';
import { State } from './model';

const s = (r: RS): State => r.shared?.currencies;

const currencies = (r: RS) => s(r).currencies;

const isLoading = (r: RS) => s(r).isLoading;

export default {
  currencies,
  isLoading,
};
