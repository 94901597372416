import { useState } from 'react';
import { useIsMountedRef } from 'containers/services/commonService';
import api from './api';
import { Offer } from './types';

type ModelParams = {
  offerId: number;
  onClose: () => void;
  onOfferCancelled: (offer: Offer) => void;
};

export const useCancelOfferModalModel = ({ offerId, onOfferCancelled, onClose }: ModelParams) => {
  const isMountedRef = useIsMountedRef();
  const [cancelling, setCancelling] = useState(false);

  const cancelOffer = () => {
    setCancelling(true);
    api
      .cancelOffer(offerId)
      .then((offer) => {
        onClose();
        onOfferCancelled(offer);
      })
      .finally(() => {
        if (isMountedRef.current) {
          setCancelling(false);
        }
      });
  };
  return { cancelling, cancelOffer };
};
