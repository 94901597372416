import { combineReducers } from 'redux';
import coinEditReducer from './coinEditReducer';
import form from './formReducer';
import list from './listReducer';

import { sharedReducer } from 'shared/shared.store';
import { bootstrapReducer } from '../bootstrap/store/reducer';
import { mainReducer } from '../containers/pages/main.store';
import purchaseReducer from './purchaseReducer';
import userReducer from './userReducer';

export const rootReducer = combineReducers({
  bootstrap: bootstrapReducer,
  coinEditReducer,
  form,
  list,
  purchaseReducer,
  main: mainReducer,
  shared: sharedReducer,
  userReducer,
});
