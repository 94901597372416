import { LIST_FETCH, LIST_FILTER, LIST_SORT, LIST_UPDATE } from '@constants/redux';

const initialState = {
  data: {},
  pagination: {},
  sorts: {},
  filters: {},
};

const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_FETCH:
      const data = { ...state.data, [action.listName]: action.data };
      const pagination = { ...state.pagination, [action.listName]: action.pagination };
      return { ...state, data: data, pagination: pagination };
    case LIST_SORT:
      return { ...state, sorts: { ...state.sorts, [action.listName]: action.sorts } };
    case LIST_FILTER:
      return { ...state, filters: { ...state.filters, [action.listName]: action.filters } };
    case LIST_UPDATE: {
      let data = state.data;
      let listData = data[action.listName] || [];
      let newListData = listData.map((current) =>
        action.searchFunc(current) ? action.element : current
      );
      return { ...state, data: { ...data, [action.listName]: newListData } };
    }
    default:
      return state;
  }
};

export default listReducer;
