import React, { FC } from 'react';
import classNames from 'classnames';

import s from './index.module.scss';
import { PermissionGroup } from '@permissions/permission-group';
import { HasAccess } from '@permissions/core';
import { Box } from '@components/box';
import { DownloadButton, permissionGroup as DownloadPermissionsGroup } from './download-button';
import { permissionGroup as UploadPermissionsGroup, UploadButton } from './upload-button';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [DownloadPermissionsGroup, UploadPermissionsGroup],
  marker: 'layout:payments-report-actions-row',
});

const ActionsRowComponent: FC<{ className: string }> = ({ className }) => (
  <div className={classNames(s.row, className)}>
    <Box className="mr-sm" compact={true}>
      <DownloadButton />
    </Box>
    <Box compact={true}>
      <UploadButton />
    </Box>
  </div>
);

export const ActionsRow: FC<{ className: string }> = ({ className }) => (
  <HasAccess permissions={{ or: [permissionGroup] }}>
    <ActionsRowComponent className={className} />
  </HasAccess>
);
