import React from 'react';
import { Trans } from '@lingui/macro';

import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputDate, SubmitButton } from '@components/form';
import { dateMax, Fields, formId, formInitial, useModel } from './model';

export const DownloadTransactionsModal: MFC = ({ isVisible }) => {
  const m = useModel(DownloadTransactionsModal);

  return (
    <Modal isVisible={isVisible} disabled={m.lockUI} loading={m.lockUI} onClose={m.onClose}>
      <ModalTitle>
        <Trans id="download_tsx_modal.title">Download xls</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={formInitial}>
          <InputDate
            name={Fields.startDate}
            label={<Trans id="download_tsx_modal.date_start">Date start</Trans>}
            disabled={m.lockUI}
            max={dateMax}
            required={true}
          />

          <InputDate
            name={Fields.endDate}
            label={<Trans id="download_tsx_modal.end_date">Date end</Trans>}
            disabled={m.lockUI}
            max={dateMax}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="signup.contact_info_step.button.continue">Continue</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
