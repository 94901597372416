import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

export interface StableBalance {
  asset: string;
  balance: number;
}
const getPrimaryStableBalanceQuery = 'userProfile';
const getPrimaryStableBalanceFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [
              {
                stableBalance: [
                  'amount',
                  {
                    currency: ['asset', 'id'],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
const getPrimaryStableBalance = makeQuery({
  permissions: Permission.BALANCE_VIEW_PRIMARY_MY,
  queryName: getPrimaryStableBalanceQuery,
  queryFields: getPrimaryStableBalanceFields,
  query: (): Promise<Array<StableBalance>> => {
    type QueryResponse = {
      balance: {
        primary: {
          active: {
            stableBalance: Array<{
              amount: number;
              currency: {
                asset: string;
                code: string;
                id: number;
              };
            }>;
          };
        };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getPrimaryStableBalanceQuery,
        sign(getPrimaryStableBalanceQuery, getPrimaryStableBalanceFields)
      )
      .then(({ balance }) => {
        const stableBalance = balance.primary.active.stableBalance;
        return stableBalance
          .filter((balance) => currenciesFilter(balance.currency))
          .map((b) => ({
            asset: b.currency.asset,
            balance: b.amount,
          }));
      });
  },
});

const sendStableToInvestorQuery = 'sendToInvestorAccount';
const sendStableToInvestorFields = ['value'];
const sendStableToInvestor = makeQuery({
  queryName: sendStableToInvestorQuery,
  queryFields: sendStableToInvestorFields,
  query: (request: { asset: string; amount: number }): Promise<void> => {
    const stableFields = sign(sendStableToInvestorQuery, sendStableToInvestorFields);
    const typedValues = { ...v.sendToInvestorAccountRequestInput({ request }) };
    return stompClient.sendData('sendToInvestorAccount', stableFields, typedValues);
  },
});

const api = {
  getPrimaryStableBalance,
  sendStableToInvestor,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:send-stable-to-investor-modal');

export default api;
