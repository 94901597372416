import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup, Role as R } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

export interface Settlement {
  amount: number;
  createdAt: string;
  currency: {
    id: number;
    code: string;
  };
  id: number;
  recipient: {
    displayName: string;
    id: number;
  };
  status: string;
}

export interface CreatedSettlement {
  createSettlement: Settlement;
  id: number;
}

export enum Fields {
  amount = 'amount',
  currency = 'currency.id',
  recipient = 'recipient.id',
}

export interface Form {
  [Fields.amount]: string;
  [Fields.currency]: number;
  [Fields.recipient]: number;
}

const createLBSettlementQuery = 'createSettlement';
const createLBSettlementFields = [
  'id',
  'createdAt',
  { recipient: ['id', 'displayName'] },
  'amount',
  'status',
  { currency: ['id', 'code'] },
];
const createLBSettlement = makeQuery({
  queryName: createLBSettlementQuery,
  queryFields: createLBSettlementFields,
  query: (settlement: Form): Promise<Settlement> => {
    return stompClient.sendData<Settlement>(
      createLBSettlementQuery,
      sign(createLBSettlementQuery, createLBSettlementFields),
      v.settlementInput({ settlement })
    );
  },
});

export interface ListingBroker {
  displayName: string;
  id: number;
}
const getListingBrokersQuery = 'usersByRole';
const getListingBrokersFields = ['displayName', 'id'];
const getListingBrokers = makeQuery({
  queryName: getListingBrokersQuery,
  queryFields: getListingBrokersFields,
  query: (): Promise<Array<ListingBroker>> => {
    return stompClient.getData(
      getListingBrokersQuery,
      sign(getListingBrokersQuery, getListingBrokersFields),
      { role: R.listingBroker }
    );
  },
});

export interface Currency {
  id: number;
  code: string;
  order: number;
}
const getCurrenciesQuery = 'currencies';
const getCurrenciesFields = ['id', 'code', 'order'];
const getCurrencies = makeQuery({
  queryName: getCurrenciesQuery,
  queryFields: getCurrenciesFields,
  query: (): Promise<Array<Currency>> => {
    return stompClient
      .getData(getCurrenciesQuery, sign(getCurrenciesQuery, getCurrenciesFields))
      .then((list) => list.filter(currenciesFilter));
  },
});

const api = {
  createLBSettlement,
  getListingBrokers,
  getCurrencies,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:add-settlement-modal');

export default api;
