import { useSelector } from 'react-redux';
import { defineMessage, Trans } from '@lingui/macro';
import { PageContent, PageTitle } from '@components/page';
import { Tabs, TabPane, ForcedRenderPaneWrapper, TabsHeaderData } from '@components/tabs/regular';
import { Box } from '@components/box';
import { If } from '@components/if';
import { useI18n } from '@hooks/i18n';
import { PermissionGroup } from '@permissions/core';
import { configSelectors } from '@config/store/selectors';
import { GeneralTab, permissionGroup as generalTabPermissionGroup } from './tabs/general';
import {
  TradeFeeSecondaryTab,
  permissionGroup as tradeFeeSecondaryTabPermissionGroup,
} from './tabs/trade-fee-secondary';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [generalTabPermissionGroup, tradeFeeSecondaryTabPermissionGroup],
  marker: 'layout:preferences-page',
});

enum TabId {
  general = 'general',
  tradeFeeSecondary = 'tradeFeeSecondary',
}

const tabsHeaderData: TabsHeaderData = [
  {
    tabKey: TabId.general,
    data: <Trans id={'preferences.tabs.general.title'}>General</Trans>,
  },
  {
    tabKey: TabId.tradeFeeSecondary,
    data: <Trans id={'preferences.tabs.trade_fee_secondary.title'}>Trade fee secondary</Trans>,
  },
];

export function PreferencesPage() {
  const { i18n } = useI18n();
  const userPermissions = useSelector(configSelectors.permissions);

  const showGeneralTab = generalTabPermissionGroup.resolve(userPermissions);
  const showTradeFeeSecondaryTab = tradeFeeSecondaryTabPermissionGroup.resolve(userPermissions);
  const defaultTabKey = showGeneralTab ? TabId.general : TabId.tradeFeeSecondary;

  return (
    <PageContent uat="preferences-page">
      <PageTitle
        documentTitle={i18n._(
          defineMessage({
            id: 'router.preferences',
            message: 'Preferences',
          })
        )}
      />

      <Box className={'pt1'}>
        <Tabs
          data={tabsHeaderData}
          defaultActiveKey={defaultTabKey}
          border
          forceRender
          paneWrapperComponent={ForcedRenderPaneWrapper}
        >
          <If condition={showGeneralTab}>
            <TabPane key={TabId.general} tabKey={TabId.general}>
              <GeneralTab />
            </TabPane>
          </If>
          <If condition={showTradeFeeSecondaryTab}>
            <TabPane key={TabId.tradeFeeSecondary} tabKey={TabId.tradeFeeSecondary}>
              <TradeFeeSecondaryTab />
            </TabPane>
          </If>
        </Tabs>
      </Box>
    </PageContent>
  );
}
