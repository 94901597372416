import React, { FC } from 'react';
import classnames from 'classnames';

import widget from '../../../index.module.scss';
import s from './index.module.scss';

export const Separator: FC<{
  activeStep: number;
  index: number;
}> = ({ activeStep, index }) => {
  return (
    <div
      className={classnames(widget.separator, {
        [s.finished]: index < activeStep,
      })}
    />
  );
};
