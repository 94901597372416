import sign from 'tm2sign.macro';
import { makeQuery } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Role } from '@permissions/core';

export interface UserByRole {
  displayName: string;
  id: number;
}
const getUsersByRoleQuery = 'usersByRole';
const getUsersByRoleFields = ['displayName', 'id'];
const getUsersByRole = makeQuery({
  queryName: getUsersByRoleQuery,
  queryFields: getUsersByRoleFields,
  query: (role: Role): Promise<Array<UserByRole>> => {
    return stompClient.getData(
      getUsersByRoleQuery,
      sign(getUsersByRoleQuery, getUsersByRoleFields),
      { role }
    );
  },
});

const api = {
  getUsersByRole,
};

export default api;
