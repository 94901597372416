import { RootState, Selector } from '@models/redux';
import { TransferDirection } from '../../model';
import { AssetItem, TransferStableTabState } from './model';

const s: Selector<TransferStableTabState> = (r: RootState) =>
  r.main?.wallet?.marketTransferModal?.stableTab;

const primaryStableBalances: Selector<Array<AssetItem>> = (r: RootState) =>
  s(r).primaryStableBalances;

const secondaryStableBalances: Selector<Array<AssetItem>> = (r: RootState) =>
  s(r).secondaryStableBalances;

const transferDirection: Selector<string> = (r: RootState) => s(r).transferDirection;

const lockUI: Selector<boolean> = (r: RootState) =>
  s(r).isFinishStableTransferLoading ||
  s(r).isTransferStableInfoLoading ||
  s(r).isStableInfoLoading;

const sourceBalances: Selector<Array<AssetItem>> = (r: RootState) =>
  transferDirection(r) === TransferDirection.fromPrimary
    ? primaryStableBalances(r)
    : secondaryStableBalances(r);

const isNoTransferStable: Selector<boolean> = (r: RootState) =>
  isTabInitiated(r) && !sourceBalances(r).length;

const isTabInitiated: Selector<boolean> = (r: RootState) => s(r).isTabInitiated;

const initialAsset: Selector<string> = (r: RootState) => sourceBalances(r)[0]?.asset;

const selectedAsset: Selector<string> = (r: RootState) => s(r)?.selectedAsset || initialAsset(r);

const primaryBalance: Selector<number> = (r: RootState) =>
  primaryStableBalances(r).filter(
    (el) => el.asset === (selectedAsset(r) ? selectedAsset(r) : initialAsset(r))
  )[0]?.balance;

const secondaryBalance: Selector<number> = (r: RootState) =>
  secondaryStableBalances(r).filter(
    (el) => el.asset === (selectedAsset(r) ? selectedAsset(r) : initialAsset(r))
  )[0]?.balance;

export const transferStableTabSelectors = {
  transferStableTabState: s,
  primaryStableBalances,
  secondaryStableBalances,
  transferDirection,
  lockUI,
  sourceBalances,
  isNoTransferStable,
  isTabInitiated,
  initialAsset,
  selectedAsset,
  primaryBalance,
  secondaryBalance,
};
