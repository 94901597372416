import { useMemoOne } from 'use-memo-one';
import { areFieldsEqual } from '../../core';
import s from '../index.module.scss';

import type { BaseItem, HeaderProps as CoreHeaderProps, SortDirection } from '../../core';
import type { ColumnType, ColumnConfig } from '../types';

export * from './cell-box';

export * from './cell-renderer';

// In this module we do not operate T in any way. It is used to simplify
// picking fields from ColumnType which has BaseItem constraint.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type HeaderProps<T extends BaseItem = any> = {
  coreHeaderProps: CoreHeaderProps;
  columns: Omit<ColumnType<T>, 'rowCellRenderer' | 'rowCell'>[];
  columnConfig: Omit<ColumnConfig, 'rowCellRenderer'>;
};

export function Header<T extends BaseItem>(props: HeaderProps<T>) {
  const sorter = props.coreHeaderProps.sorter;

  const columnsData = useMemoOne(() => {
    return props.columns.map((c) => {
      const sortDirections = c.sorting ?? props.columnConfig.sorting ?? [];

      const normalizedSortDirections: NonNullable<SortDirection>[] = Array.isArray(sortDirections)
        ? sortDirections
        : [sortDirections];

      const uniqueSortDirections = [null, ...Array.from(new Set(normalizedSortDirections))];

      const currentSortItem = sorter.order.find((sortItem) => {
        if (sortItem.key && c.key) {
          return sortItem.key === c.key;
        }
        if (c.dataIndex && sortItem.field) {
          return areFieldsEqual(c.dataIndex, sortItem.field);
        }
        throw new Error(
          'base/header: Header: Cannot detect sort item corresponding to the column - ' +
            'mix or absence of `key` and `dataIndex` properties detected'
        );
      });
      const currentSortDirection = currentSortItem?.direction ?? null;

      const nextSortDirectionIndex =
        currentSortDirection === null
          ? 0
          : normalizedSortDirections.indexOf(currentSortDirection) + 1;

      const nextDirection: SortDirection =
        normalizedSortDirections[nextSortDirectionIndex] ?? uniqueSortDirections[0];

      const sortOrderNumber = sorter.order.findIndex((sortItem) => {
        if (sortItem.key && c.key) {
          return sortItem.key === c.key;
        }
        if (c.dataIndex && sortItem.field) {
          return areFieldsEqual(c.dataIndex, sortItem.field);
        }
        throw new Error(
          'base/header: Header: Cannot detect index of sort item corresponding to the ' +
            'column - mix or absence of `key` and `dataIndex` properties detected'
        );
      });

      return {
        availableSortDirections: uniqueSortDirections,
        currentSortDirection,
        onSortClick: () => {
          sorter.mergeOrderItem({
            field: c.dataIndex,
            key: c.key,
            direction: nextDirection,
          });
        },
        sortOrderNumber: sortOrderNumber === -1 ? null : sortOrderNumber + 1,
      };
    });
  }, [sorter.order, sorter.mergeOrderItem, props.columns, props.columnConfig.sorting]);

  const outerStyle = {
    width: props.coreHeaderProps.outerWidth,
  };
  const innerStyle = {
    width: props.coreHeaderProps.columns.reduce((acc, c) => acc + c.width, 0),
  };
  const innerRef = props.coreHeaderProps.innerRef;

  return (
    <div className={s.headerOuter} style={outerStyle}>
      <div ref={innerRef} className={s.headerInner} style={innerStyle}>
        {props.columns.map((c, index) => {
          const Cell = c.headCell;
          const data = columnsData[index];

          const Renderer = c.headCellRenderer ?? props.columnConfig.headCellRenderer;
          if (!Renderer) {
            throw new Error('base/header: Header: Header cell renderer should be provided');
          }

          const alignment = c.alignment ?? props.columnConfig.alignment;
          if (!alignment) {
            throw new Error('base/header: Header: Alignment should be provided');
          }

          const cellKey = c.key ?? c.dataIndex?.toString();
          if (!cellKey) {
            throw new Error(
              'base/header: Header: Column should either have `key` or `dataIndex` property'
            );
          }
          return (
            <Renderer
              key={cellKey}
              alignment={alignment}
              availableSortDirections={data.availableSortDirections}
              currentSortDirection={data.currentSortDirection}
              onSortClick={data.onSortClick}
              sortOrderNumber={data.sortOrderNumber}
              width={c.width}
            >
              <Cell />
            </Renderer>
          );
        })}
      </div>
    </div>
  );
}
