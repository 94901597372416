import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form } from 'antd';

import s from './index.module.scss';
import { ButtonTextIconBig } from '@components/button';
import { ReactComponent as DeleteIcon } from '@images/icons/cross-icon.svg';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';

export const FormList = Form.List;

export const AddRowButton: FC<{
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  text: ReactNode;
  uat?: string;
}> = (p) => (
  <ButtonTextIconBig
    className={p.className}
    icon={<AddIcon className="tm2-icon-stroke" />}
    disabled={p.disabled}
    onClick={p.onClick}
    uat={p.uat || 'add-row-button'}
  >
    {p.text}
  </ButtonTextIconBig>
);

export const DeleteRowButton: FC<{
  className?: string;
  disabled?: boolean;
  onClick: () => void;
  uat?: string;
}> = (p) => (
  <button
    className={classnames(s.deleteButton, p.className)}
    disabled={p.disabled}
    onClick={p.onClick}
    data-uat={p.uat || 'delete-row-button'}
  >
    <DeleteIcon className={s.icon} />
  </button>
);
