import { RootState as RS } from '@models/redux';
import { Country } from '../api';
import { State } from './model';

const s: (r: RS) => State = (r) => r.shared?.countries;

const countries: (r: RS) => Array<Country> = (r) => s(r).countries;

// if we add custom nationality query, we need to extend this selector
const isLoading: (r: RS) => boolean = (r) => s(r).isCountriesLoading || s(r).isNationalitiesLoading;

const nationalities: (r: RS) => Array<Country> = (r) => s(r).nationalities;

export default {
  countries,
  countriesState: s,
  isLoading,
  nationalities,
};
