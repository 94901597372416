import React, { FC, Ref } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { ButtonPrimary } from '@components/button';
import { OnboardingStep } from '@config/core';
import { ProfileMy } from '../../../../api';
import { Step } from '../step';
import { StepDetailsTitle } from '../details-title';
import { useModel } from './model';

const Details: FC<{
  profile: ProfileMy;
  stepIndex: number;
}> = (props) => {
  const m = useModel(props.profile);

  return (
    <div className={s.actions}>
      <StepDetailsTitle currentStep={props.stepIndex} />

      {/*<ButtonPrimary size="sm" onClick={m.onHandleBankDetailsFormClicked}>*/}
      {/*  {m.step === OnboardingStep.bankDetails && (*/}
      {/*    <Trans id="profile_page.onboarding.button.add_bank_details">Add Bank details</Trans>*/}
      {/*  )}*/}
      {/*  {m.step === OnboardingStep.funds && (*/}
      {/*    <Trans id="profile_page.onboarding.button.add_funds">Add Funds</Trans>*/}
      {/*  )}*/}
      {/*</ButtonPrimary>*/}
      {m.step === OnboardingStep.bankDetails && (
        <ButtonPrimary size="sm" onClick={m.onHandleBankDetailsFormClicked}>
          <Trans id="profile_page.onboarding.button.add_bank_details">Add Bank details</Trans>
        </ButtonPrimary>
      )}
    </div>
  );
};

export const BankDetailsStep: FC<{
  activeStep: number;
  length: number;
  profile: ProfileMy;
  portal: Ref<HTMLDivElement>;
  stepIndex: number;
}> = (p) => {
  return (
    <Step
      activeStep={p.activeStep}
      length={p.length}
      portal={p.portal as any}
      stepIndex={p.stepIndex}
      description={
        <Trans id="profile_page.onboarding.step.description.add_bank_details">
          Add Bank details and make a deposit
        </Trans>
      }
      details={<Details profile={p.profile} stepIndex={p.stepIndex} />}
    />
  );
};
