import { useCallback, useContext, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { ButtonEdit } from '@components/button';
import { Col, Row } from '@components/grid';
import { InputNumber, InputText, Select, SelectBoolean, SelectItem } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { EditMetalsModal } from '../../../modals/edit-metals';
import { fields, FormContext } from '../../form';
import { Metal } from '../../types';
import s from './index.module.scss';

export function GeneralTab() {
  const showModal = useCallDispatch(showModalAction);
  const { state, dispatch } = useContext(FormContext);
  const {
    data: { issuers, liquidityProviders, metals, units, vaults },
    lockUI,
    mode,
  } = state;

  const issuerSelectItems = useMemo<SelectItem[]>(() => {
    return issuers.map((issuer) => ({
      label: issuer.displayName,
      value: issuer.id,
    }));
  }, [issuers]);

  const liquidityProvidersSelectItems = useMemo<SelectItem[]>(() => {
    return liquidityProviders.map((provider) => ({
      label: provider.displayName,
      value: provider.id,
    }));
  }, [liquidityProviders]);

  const metalSelectItems = useMemo<SelectItem[]>(() => {
    return metals.map((metal) => ({
      label: metal.label,
      value: metal.id,
    }));
  }, [metals]);

  const unitSelectItems = useMemo<SelectItem[]>(() => {
    return units.map((unit) => ({
      label: unit.label,
      value: unit.id,
    }));
  }, [units]);

  const vaultSelectItems = useMemo<SelectItem[]>(() => {
    return vaults.map((vault) => ({
      label: `${vault.city} ${vault.address}`,
      value: vault.id,
    }));
  }, [vaults]);

  const updateMetals = useCallback((metals: Metal[]) => {
    dispatch({
      type: 'METALS_UPDATED',
      payload: metals,
    });
  }, []);

  const showEditMetalsModal = useCallback(
    () => showModal({ modal: EditMetalsModal, props: { updateMetals } }),
    [showModal]
  );

  return (
    <div className={s.container}>
      <Row>
        <Col md={6}>
          <InputText
            name={fields.general.name}
            label={<Trans id="digital_metals.general.name_of_product">Name of product</Trans>}
            required
            disabled={lockUI}
          />
        </Col>

        <Col md={6} className={s.col}>
          <Select
            name={fields.general.metal}
            label={<Trans id="digital_metals.general.metal">Metal</Trans>}
            required
            data={metalSelectItems}
            disabled={lockUI}
          />
          <ButtonEdit
            className={'ml3'}
            onClick={showEditMetalsModal}
            compact="all"
            disabled={lockUI}
          />
        </Col>
      </Row>

      {mode === 'create' ? (
        <Row>
          <Col md={6}>
            <InputText
              name={fields.general.asset}
              label={<Trans id="digital_metals.general.tm2_symbol">TM2 Symbol</Trans>}
              required
              disabled={lockUI}
            />
          </Col>

          <Col md={6}>
            <InputNumber
              name={fields.general.amount}
              label={<Trans id="digital_metals.general.amount">Amount</Trans>}
              required
              disabled={lockUI}
            />
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col md={6}>
          <InputText
            name={fields.general.brandName}
            label={<Trans id="digital_metals.general.brand_name">Brand name</Trans>}
            required
            disabled={lockUI}
          />
        </Col>

        <Col md={6}>
          <InputText
            name={fields.general.physicalBacking}
            label={<Trans id="digital_metals.general.phys_backing">Physical backing</Trans>}
            required
            disabled={lockUI}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Select
            name={fields.general.issuer}
            label={
              <Trans id="digital_metals.general.name_of_issuer">
                Legal & Commercial Name of Issuer
              </Trans>
            }
            required
            data={issuerSelectItems}
            disabled={lockUI}
          />
        </Col>

        <Col md={6}>
          <Select
            name={fields.general.vault}
            label={<Trans id="digital_metals.general.vault">Vault</Trans>}
            required
            data={vaultSelectItems}
            disabled={lockUI}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <InputNumber
            name={fields.general.denominationUnit}
            label={
              <Trans id="digital_metals.general.physical_backing_of_unit">
                Physical backing of 1 unit
              </Trans>
            }
            required
            disabled={lockUI}
          />
        </Col>

        <Col md={6}>
          <Select
            name={fields.general.weightMeasurement}
            label={
              <Trans id="digital_metals.general.weight_measurement">
                Weight measurement of 1 unit
              </Trans>
            }
            required
            data={unitSelectItems}
            disabled={lockUI}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <InputText
            name={fields.general.unitAndDenomination}
            label={<Trans id="digital_metals.general.unit_denomination">Unit & Denomination</Trans>}
            required
            disabled={lockUI}
          />
        </Col>

        <Col md={6}>
          <SelectBoolean
            name={fields.general.physicalRedemption}
            label={
              <Trans id="digital_metals.general.physical_redemption">Physical redemption</Trans>
            }
            required
            disabled={lockUI}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <Select
            name={fields.general.liquidityProviders}
            label={
              <Trans id="digital_metals.general.name_of_liquidity">
                Legal & Commercial Name of Liquidity Provider
              </Trans>
            }
            mode={'multiple'}
            data={liquidityProvidersSelectItems}
            disabled={lockUI}
          />
        </Col>
      </Row>
    </div>
  );
}
