import { useState } from 'react';
import moment from 'moment';
import saveAs from 'file-saver';
import { useDispatch } from 'react-redux';
import { base64StringToBlob } from 'blob-util';

import v from '@components/form/validators';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { dater } from '@helper/date';
import { transactionsApi } from '../api';

export const formId = 'download-transactions-form';

export enum Fields {
  endDate = 'endDate',
  startDate = 'startDate',
}

interface Form {
  [Fields.endDate]: string;
  [Fields.startDate]: string;
}

export const formInitial: Form = {
  endDate: new Date(moment().subtract(1, 'days').format()).toISOString(),
  startDate: new Date(moment().subtract(1, 'months').format()).toISOString(),
};

export const dateMax = new Date().toISOString();

export const useModel = (modal) => {
  const dispatch = useDispatch();
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onClose = () => dispatch(closeModalAction(modal));

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      [Fields.endDate]: [v.required],
      [Fields.startDate]: [v.required],
    },
    finally: () => setLockUI(false),
    submit: async (form: Form) => {
      setLockUI(true);
      const { startDate, endDate } = form;
      const currentTimestamp = +new Date();
      const formattedStartDate = dater.toGqlDate(startDate);
      const formattedEndDate = dater.toGqlDate(endDate);
      const transactions = await transactionsApi.downloadTransactions(
        formattedStartDate,
        formattedEndDate
      );
      const blob = base64StringToBlob(transactions, 'application/xlsx');
      saveAs(blob, `txs_${formattedStartDate}_-_${formattedEndDate}__--${currentTimestamp}.xlsx`);
      onClose();
    },
  };

  return { lockUI, onClose, onFormAction };
};
