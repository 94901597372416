import React, { FC } from 'react';
import { connect } from 'react-redux';
import { I18n } from '@lingui/core';
import { defineMessage, Trans } from '@lingui/macro';
import './styles.scss';
import { PermissionGroup } from '@permissions/core';
import { configSelectors } from '@config/store/selectors';
import { PageContent, PageTitle } from '@components/page';
import { NewTabs } from 'components/new-tabs';
import { TransactionsPage, permissionGroup as transactionsPermissionGroup } from '../transactions';
import { Wallet, permissionGroup as walletPermissionGroup } from './wallet';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [walletPermissionGroup, transactionsPermissionGroup],
  marker: 'layout:wallet-page',
});

const mapStateToProps = (root) => ({
  i18n: configSelectors.i18n(root),
  permissions: configSelectors.permissions(root),
});

const ConnectedWalletPage: FC<{
  i18n: I18n;
  permissions: string;
}> = ({ i18n, permissions }) => {
  return (
    <PageContent className="wallet-page" uat="wallet-page">
      <PageTitle
        documentTitle={i18n._(defineMessage({ id: 'router.wallet', message: 'Wallet' }))}
      />
      <NewTabs
        tabs={[
          !walletPermissionGroup.resolve(permissions)
            ? null
            : {
                title: <Trans id="wallet.header_tab.wallet">Wallet</Trans>,
                name: 'list',
                content: () => <Wallet />,
              },
          !transactionsPermissionGroup.resolve(permissions)
            ? null
            : {
                title: <Trans id="wallet.header_tab.transactions">Transactions</Trans>,
                name: 'transactions',
                content: () => <TransactionsPage isNoTitle />,
              },
        ]}
      />
    </PageContent>
  );
};

export const WalletPage = connect(mapStateToProps)(ConnectedWalletPage);
