import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { ReactComponent as UploadIcon } from '@images/icons/upload-icon.svg';
import { ButtonAction, ButtonIconBordered, ButtonSecondary } from '../index';

export const ButtonUpload: FC<{
  action?: ButtonAction;
  className?: string;
  compact?: 'all' | 'mobile' | 'none';
  disabled?: boolean;
  loading?: boolean;
  onClick: Function;
}> = ({ action, children, className, compact = 'none', disabled, loading, onClick }) => {
  return compact === 'none' ? (
    <ButtonSecondary
      className={className}
      action={action}
      icon={<UploadIcon className="tm2-icon-stroke" />}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    >
      {children || <Trans id="shared.button.upload">Upload</Trans>}
    </ButtonSecondary>
  ) : (
    // TODO compact = mobile кейс не учтен
    <ButtonIconBordered
      icon={<UploadIcon className="tm2-icon-stroke" />}
      action={action}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  );
};
