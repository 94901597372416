import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form as AntForm, Input } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { Label, useLabel } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;
const { TextArea } = Input;

export const InputTextarea: FC<{
  className?: string;
  disabled?: boolean;
  label: ReactNode;
  name: NamePath;
  fieldKey?: React.Key | React.Key[];
  required?: boolean;
  uat?: string;
}> = ({ className, disabled, label, name, required, fieldKey, uat }) => {
  const uatAttribute = useFieldUat(name, 'input-textarea', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-textarea', className)}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} fieldKey={fieldKey} required={required}>
        <CustomTextarea name={name} label={label} required={required} disabled={disabled} />
      </Item>
    </div>
  );
};

const CustomTextarea: FC<{
  disabled: boolean;
  label: ReactNode;
  maxLength?: number;
  name: NamePath;
  onChange?: (event) => void;
  required: boolean;
  rows?: number;
  value?: number;
}> = (props) => {
  const { shifted, onBlur, onFocus } = useLabel({ value: props.value });

  return (
    <>
      <Label htmlFor={props.name} shifted={shifted} label={props.label} required={props.required} />
      <TextArea
        className="tm2-form-field-item-instance"
        value={props.value}
        maxLength={props.maxLength || 1000}
        onChange={props.onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        rows={props.rows || 3}
        disabled={props.disabled}
      />
    </>
  );
};
