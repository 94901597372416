import { useCallback, useEffect, useRef } from 'react';
import s from './index.module.scss';

import type { RefObject, ReactNode } from 'react';

type OverlayProps = {
  containerRef: RefObject<HTMLElement>;
  children: ReactNode;
};

export function Overlay(props: OverlayProps) {
  const overlayRef = useRef<HTMLDivElement>(null);
  const rafId = useRef(0);

  const scheduleOffset = useCallback(() => {
    if (props.containerRef.current && overlayRef.current) {
      const offsetLeft = props.containerRef.current.scrollLeft;
      const offsetTop = props.containerRef.current.scrollTop;

      overlayRef.current.style.transform = `translate(${offsetLeft}px, ${offsetTop}px)`;
    }
    rafId.current = window.requestAnimationFrame(scheduleOffset);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scheduleOffset();
    return () => {
      window.cancelAnimationFrame(rafId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={overlayRef} className={s.overlay}>
      {props.children}
    </div>
  );
}
