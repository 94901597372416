import {
  ProductInfoTabView,
  ProductInfoTabViewProps,
} from '../create-or-edit/tabs/product-info/product-info-view';

export type ProductInfoProps = Pick<
  ProductInfoTabViewProps,
  'infoFiles' | 'productInfo' | 'PMProductInfo'
>;

export function ProductInfo(props: ProductInfoProps) {
  return (
    <ProductInfoTabView
      mode={'restricted'}
      lockUI
      dataInit
      infoFiles={props.infoFiles}
      productInfo={props.productInfo}
      PMProductInfo={props.PMProductInfo}
    />
  );
}
