import { Trans } from '@lingui/macro';

export function DefaultNoData() {
  return (
    <span data-uat="table-no-data">
      <Trans id="reusable.table.no_results_default">
        You have no documents, records or history in this section
      </Trans>
    </span>
  );
}
