import { useState } from 'react';
import { useSelector } from 'react-redux';
import { base64StringToBlob } from 'blob-util';
import { showModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import v from '@components/form/validators';
import { useCallDispatch } from '@hooks/core';
import { configSelectors } from '@config/store/selectors';
import { Role } from '@permissions/core';
import { useI18n } from 'containers/services/commonService';
import { DownloadStatementModal } from './download-statement-modal';
import api, { Fields, Form } from './api';

export const formId = 'listingBrokerAccountStatement';

export const maxDate = new Date().toISOString();

export { Fields } from './api';

export type { Form } from './api';

export const useModel = () => {
  const { i18n } = useI18n();

  const [lockUI, setLockUI] = useState<boolean>(false);

  const showModal = useCallDispatch(showModalAction);

  const currentRole = useSelector(configSelectors.role);

  const isListingBroker = currentRole === Role.listingBroker;

  const userId = useSelector(configSelectors.userId);

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      [Fields.listingBrokerId]: !isListingBroker ? [v.required] : [],
      [Fields.date]: [v.required],
      [Fields.currencyId]: [v.required],
    },
    error: () => {
      setLockUI(false);
    },
    submit: async (form) => {
      setLockUI(true);
      const builtStatement: string = await api.buildStatement(form, isListingBroker);
      const blob = base64StringToBlob(builtStatement, 'application/pdf');
      const filename = `lb_${form.listingBrokerId || userId}_${form.currencyId}.pdf`;
      setLockUI(false);
      showModal({ modal: DownloadStatementModal, props: { blob, filename } });
    },
  };

  return {
    i18n,
    isListingBroker,
    lockUI,
    onFormAction,
  };
};
