import React, { FC, useRef, useState, MouseEventHandler } from 'react';
import { useCallbackOne } from 'use-memo-one';
import classnames from 'classnames';
import { AllowedPermissions, IsHasAccess, permissionsSelectors } from '@permissions/core.strict';
import { useSelector } from 'react-redux';
import { Link } from '@components/link';
import { TableTooltip } from '../tooltip';
import s from './index.module.scss';

export { LINK_HEIGHT } from './constants';

// TODO: refactor

export const TableLinkCell: FC<{
  permissions: AllowedPermissions | 'any';
  text: string | number;
  to?: string;
  href?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
}> = ({ permissions, text, to, href, onClick, disabled = false }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipContentRef = useRef<HTMLSpanElement>(null);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const onVisibleChange = useCallbackOne((isVisible) => {
    if (!isVisible) {
      setIsTooltipVisible(false);
      return;
    }
    if (!containerRef.current || !tooltipContentRef.current) return;

    const container = containerRef.current;
    const content = tooltipContentRef.current;
    const overflow = container.offsetWidth <= content.offsetWidth;

    if (overflow) {
      setIsTooltipVisible(true);
    }
  }, []);

  const enabledOnClick = useCallbackOne(
    (e: any) => {
      if (disabled) return () => {};
      return onClick?.(e);
    },
    [disabled]
  );

  const hasAccess: IsHasAccess = useSelector(permissionsSelectors.isHasAccess);
  const isLink = !disabled && (permissions === 'any' || hasAccess(permissions));
  const content = isLink ? (
    <Link className={s.link} to={to} onClick={enabledOnClick}>
      {text}
    </Link>
  ) : (
    <span>{text}</span>
  );

  return (
    <div
      ref={containerRef}
      className={classnames(s.linkContainer, { [s.linkColoredText]: isLink })}
    >
      <TableTooltip onVisibleChange={onVisibleChange} title={content} visible={isTooltipVisible}>
        <span ref={tooltipContentRef}>{content}</span>
      </TableTooltip>
    </div>
  );
};
