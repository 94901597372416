import { Ref, useCallback, useState } from 'react';

import { OnboardingStep, OnboardingStep as Step } from '@config/core';
import { ProfileMy } from '../../api';

const activeStepAdapter = {
  1: 0, // all steps before 5 is moved to post registration
  2: 0,
  3: 0,
  4: 4,
  5: 5,
  6: 5,
};

const stepsIndexes = {
  addWallet: 1,
  accountOpening: 2,
  payMembershipFee: 3,
  kycVerification: 4,
  bankDetails: 5,
};

const stepsLength: number = Object.keys(stepsIndexes).reduce(
  (count, key) => (stepsIndexes[key] ? ++count : count),
  0
);

export const useModel = (profile: ProfileMy) => {
  const step = profile?.accountOpeningStep || OnboardingStep.finished;
  const isBoundToIssuer: boolean = !!profile?.investorIssuer;
  const activeStep: number = activeStepAdapter[step];

  const widgetShowExtraCheck: boolean =
    (!isBoundToIssuer &&
      activeStep &&
      // TODO-2833: fix types
      // @ts-ignore
      step !== Step.finished &&
      step !== Step.unknown) ??
    false;

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const [stepDetailsRef, setStepDetailsRef] = useState<Ref<HTMLDivElement>>();
  const handleStepDetailsRef: (r) => void = useCallback((node) => setStepDetailsRef(node), []);

  return {
    activeStep,
    handleStepDetailsRef,
    stepDetailsRef,
    stepsIndexes,
    stepsLength,
    widgetShowExtraCheck,
  };
};
