import { useContext, useState } from 'react';

import { OnFormAction } from '@components/form';
import api from '../../api';
import { useFormInfo } from '../../hooks';
import { Context } from '../../context';

export const formId = 'regulatedOrListedStatusFormId';

export const useModel = () => {
  const { dispatch } = useContext(Context);
  const [isSendFormLoading, setSendFormLoading] = useState<boolean>();
  const m = useFormInfo('regulatedOrListedStatusFormStructure');
  const lockUI = m.isFormLoading || isSendFormLoading;

  const onFormAction: OnFormAction = {
    error: () => setSendFormLoading(false),
    submit: async (form) => {
      setSendFormLoading(true);
      await api.sendRegulatedOrListedStatusForm(form);
      setSendFormLoading(false);
      dispatch({ type: 'ON_STEP_COMPLETED' });
    },
  };

  return {
    formInitial: m.formInitial,
    formRows: m.formRows,
    lockUI: lockUI,
    onFormAction: onFormAction,
  };
};
