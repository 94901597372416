import _ from 'lodash';
import { ValidationErrorItem } from '../antd-form/validation-message';

export type Validator = (errors: Array<ValidationErrorItem>, form: object, field: string) => void;

export interface FieldValidationConfig {
  [k: string]: Array<Validator>;
}

export enum ValidationCode {
  confirmPasswordMismatch = 'CONFIRM_PASSWORD_MISMATCH',
  maxLengthMore = 'MORE_THAN_MAX_LENGTH_ERROR',
  minLengthLess = 'LESS_THAN_MIN_LENGTH_ERROR',
  maxNumMore = 'MORE_THAN_MAX_VALUE',
  minNumLess = 'LESS_THAN_MIN_VALUE',
  passwordNewEqualPrev = 'NEW_PASSWORD_EQUAL_PREVIOUS',
  required = 'REQUIRED_VALUE',
  fileSizeExceeded = 'FILE_SIZE_EXCEEDED', // frontend validator, backend uses FILE_IS_TOO_BIG_CODE
  fileWrongExtension = 'FILE_WRONG_EXTENSION', // frontend validator, backend uses NOT_ALLOWED_FILE_EXTENSION_CODE
  wrongDate = 'WRONG_DATE',
  duplicateValues = 'DUPLICATE_VALUES',
  latinAndNumbersViolated = 'LATIN_AND_NUMBERS_VIOLATED', // frontend validator
}

const nestedSeparator = '[N].';
export const nestedName = (names: Array<string>) => {
  return names.join(nestedSeparator);
};

export const formHasField = (form, field) => {
  if (field.includes('.')) {
    const paths = field.split('.');
    const key = paths.shift();
    return form.hasOwnProperty(key) ? formHasField(form[key], paths.join('.')) : false;
  } else {
    return form.hasOwnProperty(field);
  }
};

type ValidatorArgs = [errors: Array<ValidationErrorItem>, form: object, field: string];
const createValidator = (
  condition: (
    v,
    f
  ) => { code: ValidationCode; params?: Record<string, string | number | null> } | undefined,
  ...args: ValidatorArgs
) => {
  const [errors, form, field] = args;
  if (field.indexOf(nestedSeparator) < 0) {
    if (formHasField(form, field)) {
      // TODO эта логика нужна, когда поля динамически есть/нет. Нужно расширить ее на все вложенные/списковые случаи
      const value = _.get(form, field);
      const error = condition(value, form);
      if (error) {
        errors.push({ code: error.code, field: field, params: error.params });
      }
    }
  } else {
    const [listName, listField] = field.split(nestedSeparator);
    const list = _.get(form, listName) || [];
    list.forEach((subForm, index) => {
      const value = _.get(subForm, listField);
      const error = condition(value, form);
      if (error) {
        errors.push({
          code: error.code,
          field: `${listName}[${index}].${listField}`,
          params: error.params,
        }); // xxx[i].yyy
      }
    });
  }
};

const date: Validator = (...args: ValidatorArgs) => {
  createValidator((value) => {
    if (value && new Date(value).toString() === 'Invalid Date') {
      return { code: ValidationCode.wrongDate };
    }
  }, ...args);
};

const fileExtensions =
  (extensions: Array<string>): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((file: File) => {
      if (file) {
        const name = file?.name || '';
        const extension = name.includes('.') ? name.split('.').pop() : undefined;
        if (!extension || !extensions.includes(extension)) {
          return {
            code: ValidationCode.fileWrongExtension,
            params: {
              ALLOWED_FORMATS: extensions.join(', '),
            },
          };
        }
      }
    }, ...args);
  };

type Unit = 'bt' | 'kb' | 'mb';
const fileSizeMax =
  (size: number, unit: 'bt' | 'kb' | 'mb'): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((file: File) => {
      const multiplier: { [k in Unit]: number } = {
        bt: 1,
        kb: 1024,
        mb: 1024 * 1024,
      };
      if (file && file.size > multiplier[unit] * size) {
        return {
          code: ValidationCode.fileSizeExceeded,
          params: {
            MAX_FILE_SIZE: size,
            UNIT: unit,
          },
        };
      }
    }, ...args);
  };

const lengthMax =
  (length: number): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((value) => {
      if (value && value.length > length) {
        return {
          code: ValidationCode.maxLengthMore,
          params: { MAX_LENGTH: length },
        };
      }
    }, ...args);
  };

const lengthMin =
  (length: number): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((value) => {
      if (value && value.length < length) {
        return {
          code: ValidationCode.minLengthLess,
          params: { MIN_LENGTH: length },
        };
      }
    }, ...args);
  };

const numMax =
  (valueMax: number): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((value) => {
      if ((value || value === 0) && value > valueMax) {
        return {
          code: ValidationCode.maxNumMore,
          params: { MAX_VALUE: valueMax },
        };
      }
    }, ...args);
  };

const numMin =
  (valueMin: number): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((value) => {
      if ((value || value === 0) && value < valueMin) {
        return {
          code: ValidationCode.minNumLess,
          params: { MIN_VALUE: valueMin },
        };
      }
    }, ...args);
  };

const passwordConfirm =
  (field: string): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((value, form) => {
      // TODO form[field] сейчас не рассчитан на nested формы
      if (value && form[field] && value !== form[field]) {
        return { code: ValidationCode.confirmPasswordMismatch };
      }
    }, ...args);
  };

const passwordNewEqualPrev =
  (field: string): Validator =>
  (...args: ValidatorArgs) => {
    createValidator((value, form) => {
      // TODO form[field] сейчас не рассчитан на nested формы
      if (value && form[field] && value === form[field]) {
        return { code: ValidationCode.passwordNewEqualPrev };
      }
    }, ...args);
  };

const required: Validator = (...args: ValidatorArgs) => {
  createValidator((value) => {
    if (!value && value !== 0 && value !== false) {
      return { code: ValidationCode.required };
    }
  }, ...args);
};

const dynamicFormDuplicates =
  (name: string): Validator =>
  (errors, form, field) => {
    createValidator(
      (value, form) => {
        const key = field.split(nestedSeparator)[0]; // работает только на 1 уровень вложенности
        const isHasDuplicates = form[key]?.filter((item) => item[name] === value).length > 1;

        if (!!value && isHasDuplicates) {
          return { code: ValidationCode.duplicateValues };
        }
      },
      errors,
      form,
      field
    );
  };

const latinAndNumbers: Validator = (...args: ValidatorArgs) => {
  createValidator((value: string) => {
    if (value && !value.match(/^[a-zA-Z0-9]+$/)) {
      return { code: ValidationCode.latinAndNumbersViolated };
    }
  }, ...args);
};

export default {
  date,
  dynamicFormDuplicates,
  fileExtensions,
  fileSizeMax,
  latinAndNumbers,
  lengthMax,
  numMax,
  numMin,
  passwordConfirm,
  passwordNewEqualPrev,
  required,
};
