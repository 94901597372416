import React, { FC, memo, ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { NamePath } from 'rc-field-form/lib/interface';

import { Select } from '@components/form';
import { useCallDispatch } from '@hooks/core';

import { actions, selectors } from '../store';

export const CurrencySelect: FC<{
  className?: string;
  disabled?: boolean;
  extra?: ReactNode;
  fieldKey?: React.Key | React.Key[];
  filter?: Array<number>;
  label: ReactNode;
  name: NamePath;
  onChange?: (newId: number, oldId?: number) => void;
  required?: boolean;
  uat?: string;
}> = memo(
  ({
    className,
    disabled,
    extra,
    filter,
    fieldKey,
    label,
    name,
    required,
    onChange,
    uat,
    ...props
  }) => {
    const getCurrencies = useCallDispatch(actions.getCurrencies);
    const currenciesAll = useSelector(selectors.currencies);
    const lockUI = useSelector(selectors.isLoading);
    const [selectedCurrency, setSelectedCurrency] = useState<number>();

    useEffect(() => {
      getCurrencies();
    }, []);

    const currencies = useMemo(() => {
      if (!filter?.length) {
        return currenciesAll;
      }
      const filters = filter.reduce((acc, f) => {
        return {
          ...acc,
          [f]: true,
        };
      }, {});
      delete filters[selectedCurrency];
      return currenciesAll.filter((c) => !filters[c.value]);
    }, [currenciesAll, filter, selectedCurrency]);

    return (
      <Select
        name={name}
        label={label}
        fieldKey={fieldKey}
        className={className}
        disabled={disabled || lockUI}
        extra={extra}
        required={required}
        data={currencies}
        onChange={(id) => {
          setSelectedCurrency(id as number);
          onChange && onChange(id as number, selectedCurrency);
        }}
        uat={uat}
        {...props}
      />
    );
  }
);
