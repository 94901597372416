import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { OnFormAction } from '@components/form';
import { configSelectors } from '@config/core';
import api from '../../api';
import { useFormInfo } from '../../hooks';
import { Context } from '../../context';

export const formId = 'companyDetailsFormId';

export enum Fields {
  companyName = 'companyName',
  countryOfIncorporation = 'countryOfIncorporation',
}

export interface Form {
  [Fields.companyName]: string;
  [Fields.countryOfIncorporation]: number;
}

export const useModel = () => {
  const { dispatch } = useContext(Context);
  const [isSendFormLoading, setSendFormLoading] = useState<boolean>();
  const userInfo = useSelector(configSelectors.userInfo);
  const isUserInfoLoading = useSelector(configSelectors.isUserInfoLoading);
  const m = useFormInfo('companyDetailsFormStructure');

  const formInitialResponse = m?.formInitial;

  const formInitial = useMemo(
    () => ({
      ...formInitialResponse,
      [Fields.countryOfIncorporation]: userInfo?.country?.id,
      [Fields.companyName]: userInfo?.company,
    }),
    [formInitialResponse, userInfo]
  );

  const onFormAction: OnFormAction<Form> = {
    error: () => setSendFormLoading(false),
    submit: async (form: Form) => {
      setSendFormLoading(true);
      await api.sendCompanyDetailsForm(form, Fields);
      setSendFormLoading(false);
      dispatch({ type: 'ON_STEP_COMPLETED' });
    },
  };

  return {
    formInitial: formInitial,
    formRows: m.formRows,
    lockUI: m.isFormLoading || isUserInfoLoading || isSendFormLoading,
    onFormAction: onFormAction,
  };
};
