import React from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import {
  closeModalAction,
  Modal,
  ModalContent,
  ModalTitle,
  ModalFooter,
  MFC,
} from '@modules/modal';
import { ButtonSecondary } from '@components/button';

export const NoMinimalBalanceModal: MFC<{}> = ({ isVisible }) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeModalAction(NoMinimalBalanceModal));

  return (
    <Modal onClose={onClose} isVisible={isVisible}>
      <ModalTitle>
        <Trans id="bids.no_funds_modal.insufficient_funds">Insufficient funds</Trans>
      </ModalTitle>

      <ModalContent>
        <p className="op-text">
          <Trans id="bids.no_funds_modal.insufficient_funds.text">
            Your wallet has insufficient funds for the selected currency. In order to purchase this
            asset please deposit the required amount of the currency it trades against or use our
            conversion services in the wallet section.
          </Trans>
        </p>
      </ModalContent>

      <ModalFooter>
        <ButtonSecondary fullWidth={true} onClick={onClose}>
          <Trans id="button.ok">OK</Trans>
        </ButtonSecondary>
      </ModalFooter>
    </Modal>
  );
};
