import { useMemo } from 'react';
import { useI18n } from '@hooks/i18n';
import { defineMessage } from '@lingui/macro';

export const useCorrectionTransactionsStatuses = () => {
  const { i18n, lang } = useI18n();

  return useMemo(() => {
    return {
      PENDING: i18n._(
        defineMessage({ id: 'correction_transaction_statuses.pending', message: 'Pending' })
      ),
      SUCCESS: i18n._(
        defineMessage({ id: 'correction_transaction_statuses.success', message: 'Success' })
      ),
      ERROR: i18n._(
        defineMessage({ id: 'correction_transaction_statuses.error', message: 'Error' })
      ),
    };
  }, [lang]);
};
