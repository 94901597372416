import { createElement, useRef, useState } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { LoadableVirtualList } from '../loadable-virtual-list';
import { useTableInternal } from './use-table';
import { Loader } from './loader';

import type { ComponentType, UIEvent, Ref } from 'react';
import type {
  LoadableVirtualListProps,
  BaseItem,
  RowProps as LoadableRowProps,
} from '../loadable-virtual-list';
import type { TableSorterReturnType, UseTableReturnType } from './use-table';
import type { ColumnType } from './types';

export type {
  BaseItem,
  EstimateRowHeight,
  KeyExtractor,
  ListKey,
  OnLoadMore,
} from '../loadable-virtual-list';

export type { ColumnType } from './types';

export type {
  InMemorySorter,
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  ScrollToIndex,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterParams,
  TableSorterReturnType,
  UseTableReturnType,
} from './use-table';

export { useTable, areFieldsEqual, areOrdersEqual } from './use-table';

// We use table hook to provide the following:

// I. Ability to control some of the table aspects imperatively (e.g. reset).

// Reset functionality should trigger resets for every aggregated behavior.
// This leads to the conclusion that it would also be good to have functionality
// slices with imperative controls. Which means we can also use hooks for them.

// II. Be able to manage combined state of the aggregated functionalities.

// If we have sorter and row selector, the reset should trigger both their
// resets. Meanwhile, if we have imperative call on just sorter reset, it also
// should trigger row selection reset.

// This hook is also a convenient place to narrow/extend basic functionalities.

export type HeaderProps = {
  columns: ColumnType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  innerRef: Ref<any>;
  outerWidth: number;
  sorter: TableSorterReturnType;
};

export type RowProps<T extends BaseItem> = Omit<
  LoadableRowProps<T>,
  'columnWidthList' | 'columns'
> & {
  columns: ColumnType[];
};

type TablePropsBase<T extends BaseItem> = {
  data: T[];
  className?: string;
  columns: ColumnType[];
  containerHeight?: number;
  headerComponent: ComponentType<HeaderProps>;
  instance?: UseTableReturnType<T>;
  loading?: boolean;
  rowComponent: ComponentType<RowProps<T>>;
  scrollableClassName?: string;
};

export type TableProps<T extends BaseItem, K> = TablePropsBase<T> &
  Omit<
    LoadableVirtualListProps<T, K>,
    | keyof TablePropsBase<T>
    | 'columnWidthList'
    | 'onScrollbarWidthChange'
    | 'onScroll'
    | 'scrollbarWidth'
    | 'scrollOffsetRef'
    | 'scrollToIndexRef'
  >;

export function Table<T extends BaseItem, K>(props: TableProps<T, K>) {
  const headerRef = useRef<HTMLDivElement>(null);
  const scrollOffsetRef = useRef({ left: 0 });
  const [scrollbarWidth, setScrollbarWidth] = useState(0);

  const columnWidthList = useMemoOne(() => {
    const contentWindowWidth = props.containerWidth - scrollbarWidth;
    const inherentColumnWidth = props.columns.reduce((acc, c) => acc + c.width, 0);

    if (contentWindowWidth > inherentColumnWidth) {
      const widthAddition = (contentWindowWidth - inherentColumnWidth) / props.columns.length;
      const widthList = props.columns.map((c) => c.width + widthAddition);

      widthList[widthList.length - 1] -= 1;
      return widthList;
    }
    return props.columns.map((c) => c.width);
  }, [props.columns, props.containerWidth, scrollbarWidth]);

  const columns = useMemoOne(() => {
    return props.columns.map((c, idx) => ({ ...c, width: columnWidthList[idx] }));
  }, [props.columns, columnWidthList]);

  const { aggregator, data, scrollToIndexRef } = useTableInternal({
    data: props.data,
    instance: props.instance,
  });

  const onTableScroll = useCallbackOne((e: UIEvent) => {
    scrollOffsetRef.current.left = e.currentTarget.scrollLeft;

    if (headerRef.current) {
      headerRef.current.style.transform = `translate(-${e.currentTarget.scrollLeft}px)`;
    }
  }, []);

  const header = useMemoOne(() => {
    return createElement(props.headerComponent, {
      columns,
      innerRef: headerRef,
      outerWidth: props.containerWidth,
      sorter: aggregator.sorter,
    });
  }, [columns, aggregator.sorter, props.containerWidth]);

  const rowComponent = useMemoOne<ComponentType<LoadableRowProps<T>>>(() => {
    return function RowComponent(rowProps: LoadableRowProps<T>) {
      return createElement(props.rowComponent, { ...rowProps, columns });
    };
  }, [columns]);

  const tableLoaderElement = props.loading ? <Loader /> : null;

  return (
    <div className={props.className} style={{ height: props.containerHeight }} data-uat="table">
      {header}
      <LoadableVirtualList
        data={data}
        className={props.scrollableClassName}
        columnWidthList={columnWidthList}
        containerWidth={props.containerWidth}
        estimateRowHeight={props.estimateRowHeight}
        hasMore={props.hasMore}
        keyExtractor={props.keyExtractor}
        listKey={props.listKey}
        onLoadMore={props.onLoadMore}
        onScrollbarWidthChange={setScrollbarWidth}
        onScroll={onTableScroll}
        overscan={props.overscan}
        rowComponent={rowComponent}
        scrollOffsetRef={scrollOffsetRef}
        scrollDebounce={props.scrollDebounce}
        scrollbarWidth={scrollbarWidth}
        updateMeasureOnData={props.updateMeasureOnData}
        scrollToIndexRef={scrollToIndexRef}
        errorElement={props.errorElement}
        loaderElement={props.loaderElement}
        noDataElement={props.noDataElement}
        overlayElement={tableLoaderElement}
      />
    </div>
  );
}
