import saveAs from 'file-saver';
import { useState } from 'react';
import { base64StringToBlob } from 'blob-util';

import { handleBackendError } from '@modules/notify';
import api from '../../api';

export const useModel = () => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onDownloadClicked = async () => {
    try {
      setLockUI(true);
      const report: string = await api.downloadManagementFeeReport();
      const blob = base64StringToBlob(report, 'application/xlsx');
      saveAs(blob, `management_fee_report.xlsx`);
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  return {
    lockUI,
    onDownloadClicked,
  };
};
