import React, { FC } from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import { HasAccess, Permission as P, PermissionGroup, Role } from '@permissions/core';
import { PageContent, PageTitle } from '@components/page';
import { useI18n } from 'containers/services/commonService';
import { Title } from '@components/typography/title';
import {
  permissionGroup as secondaryTradesReportPermissionGroup,
  SecondaryTradesReport,
} from './secondary-trades-report';
import {
  permissionGroup as userCurrentBalanceReportPermissionGroup,
  UserCurrentBalanceReport,
} from './user-current-balance-report';
import {
  permissionsGroup as clientAccountStatementPermissionGroup,
  ClientAccountStatement,
} from './client-account-statement';
import { permissionGroup as usersReportPermissionGroup, UsersReport } from './users-report';
import { permissionGroup as conversionReportPermissionGroup } from '../conversion-report';
import { permissionGroup as feesReportPermissionGroup } from './fees-report';
import { permissionGroup as overdraftReportPermissionGroup } from '../overdraft-report';
import { permissionGroup as paymentsReportPermissionGroup } from './payments';
import { permissionGroup as managementFeeReportPermissionGroup } from './management-fee';
import { permissionGroup as virtualBalancePermissionGroup } from './virtual-balance';
import { permissionGroup as referralsReportPermissionGroup } from './referrals';
import { permissionGroup as settlementsPermissionGroup } from './settlements';
import { permissionGroup as bankAccountBalancesPermissionGroup } from './bank-account-balances';
// import {permissionGroup as participantsPermissionGroup} from './participants';
import { StatementsLink } from './statements-link';
import './styles.deprecated.scss';

const banksPermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [bankAccountBalancesPermissionGroup],
  marker: 'layout:statements-banks-section',
});

const financePermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [feesReportPermissionGroup],
  marker: 'layout:statements-finance-section',
});

const investorPermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    managementFeeReportPermissionGroup,
    referralsReportPermissionGroup,
    clientAccountStatementPermissionGroup,
  ],
  marker: 'layout:statements-investor-section',
});

const paymentsPermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    conversionReportPermissionGroup,
    overdraftReportPermissionGroup,
    paymentsReportPermissionGroup,
  ],
  marker: 'layout:statements-payments-section',
});

const marketingPermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [virtualBalancePermissionGroup],
  marker: 'layout:statements-marketing-section',
});

const optionalSecondaryTradesReportPermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [secondaryTradesReportPermissionGroup],
  marker: 'layout:statements-secondary-trades-report',
  optional: true,
});

const systemPermissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    // TODO: удалить через месяц 19.05.22 participantsPermissionGroup,
    usersReportPermissionGroup,
  ],
  marker: 'layout:statements-participants-report',
});

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [
    banksPermissionGroup,
    investorPermissionGroup,
    paymentsPermissionGroup,
    marketingPermissionGroup,
    optionalSecondaryTradesReportPermissionGroup,
    settlementsPermissionGroup,
    userCurrentBalanceReportPermissionGroup,
  ],
  marker: 'layout:statements',
});

export const StatementsPage: FC = () => {
  const { i18n } = useI18n();

  return (
    <PageContent className="statements-page" uat="statements-page">
      <PageTitle
        title={i18n._(defineMessage({ id: 'router.statements', message: 'Statements' }))}
      />

      <HasAccess permissions={{ or: [P.TRANSACTION_CREATE_CORRECTION] }}>
        <StatementsLink to="/settlements" uat="statements-admin-settlements-link">
          <Trans id="statements.settlements">Settlements</Trans>
        </StatementsLink>
      </HasAccess>

      <HasAccess
        permissions={{ or: [P.STATEMENT_VIEW_LB_ACCOUNT_REPORT] }}
        roles={[Role.listingBroker]}
      >
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.brokers">Brokers</Trans>
        </Title>
        <StatementsLink to="/statements/listing-brokers" uat="statements-listing-brokers-link">
          <Trans id="statements.lb_account_statement">Listing broker account statement</Trans>
        </StatementsLink>
      </HasAccess>

      <HasAccess permissions={{ or: [P.DAILY_BALANCE_VIEW_HISTORY_REPORT] }}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.issuer">Issuer</Trans>
        </Title>
        <StatementsLink to="/admin-daily-balance-history" uat="admin-daily-balance-history-link">
          <Trans id="statements.db_history">Daily balances history</Trans>
        </StatementsLink>
      </HasAccess>

      <HasAccess permissions={{ or: [investorPermissionGroup] }} roles={[Role.admin]}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.investors">Investors</Trans>
        </Title>
        <HasAccess permissions={{ or: [managementFeeReportPermissionGroup] }}>
          <StatementsLink to="/statements/management-fee" uat="statements-management-fee-link">
            <Trans id="statements.metal_balances">Metal Balances</Trans>
          </StatementsLink>
        </HasAccess>
        <HasAccess permissions={{ or: [referralsReportPermissionGroup] }}>
          <StatementsLink
            to="/statements/referrals"
            uat="statements-referrals-link"
            className="mt2"
          >
            <Trans id="statements.referrals">Referral Summary Report</Trans>
          </StatementsLink>
        </HasAccess>
        <HasAccess permissions={{ or: [clientAccountStatementPermissionGroup] }}>
          <ClientAccountStatement className="mt2" />
        </HasAccess>
      </HasAccess>

      <HasAccess permissions={{ or: [paymentsPermissionGroup] }}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.payments">Payments</Trans>
        </Title>
        <HasAccess permissions={{ or: [paymentsReportPermissionGroup] }}>
          <StatementsLink to="/statements/payments" uat="statements-payments-link" className="mt2">
            <Trans id="statements.payments_report">Payments report</Trans>
          </StatementsLink>
        </HasAccess>
        <HasAccess permissions={{ or: [conversionReportPermissionGroup] }}>
          <StatementsLink
            to="/statements/conversion-report"
            uat="statements-conversion-report-link"
            className="mt2"
          >
            <Trans id="statements.conversion_report">Conversion report</Trans>
          </StatementsLink>
        </HasAccess>
        <HasAccess permissions={{ or: [overdraftReportPermissionGroup] }}>
          <StatementsLink
            to="/statements/overdraft-report"
            uat="statements-overdraft-report-link"
            className="mt2"
          >
            <Trans id="statements.overdraft_report">Overdraft report</Trans>
          </StatementsLink>
        </HasAccess>
      </HasAccess>

      <HasAccess permissions={{ or: [systemPermissionGroup] }} roles={[Role.admin]}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.system">System</Trans>
        </Title>
        {/*TODO: удалить через месяц 19.05.22*/}
        {/*<HasAccess permissions={{or: [participantsPermissionGroup]}}>*/}
        {/*    <StatementsLink to="/statements/participants"*/}
        {/*                    uat="statements-participants-link"*/}
        {/*                    className="mb2">*/}
        {/*        <Trans id="statements.free_sm_participants">Free Secondary Market participants</Trans>*/}
        {/*    </StatementsLink>*/}
        {/*</HasAccess>*/}
        <HasAccess permissions={{ or: [usersReportPermissionGroup] }}>
          <UsersReport />
        </HasAccess>
      </HasAccess>

      <HasAccess permissions={{ or: [marketingPermissionGroup] }}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.marketing">Marketing</Trans>
        </Title>
        <HasAccess permissions={{ or: [virtualBalancePermissionGroup] }}>
          <StatementsLink to="/statements/virtual-balance" uat="statements-virtual-balance-link">
            <Trans id="statements.virtual_balance">Virtual Balance</Trans>
          </StatementsLink>
        </HasAccess>
      </HasAccess>

      <HasAccess permissions={{ or: [financePermissionGroup] }}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.finance">Finance</Trans>
        </Title>
        <StatementsLink to="/statements/fees" uat="statements-fees-link">
          <Trans id="statements.fees_report">Fees report</Trans>
        </StatementsLink>
      </HasAccess>

      <HasAccess permissions={{ or: [banksPermissionGroup] }}>
        <Title level={2} className="mt4 mb2">
          <Trans id="statements.banks">Banks</Trans>
        </Title>
        <StatementsLink
          to="/statements/bank-accounts-balances"
          uat="statements-bank-accounts-balances-link"
        >
          <Trans id="statements.bank_account_balances">Bank Account Balances</Trans>
        </StatementsLink>
      </HasAccess>

      <HasAccess permissions={{ or: [secondaryTradesReportPermissionGroup] }}>
        <Title level={2} className="mt4 mb2 uat-statements-item">
          <Trans id="statements.secondary_traders_report">Secondary trades report</Trans>
        </Title>
        <SecondaryTradesReport />
      </HasAccess>

      <HasAccess permissions={{ or: [userCurrentBalanceReportPermissionGroup] }}>
        <Title level={2} className="mt4 mb2 uat-statements-item">
          <Trans id="statements.user_current_balance_report">User current balance report</Trans>
        </Title>
        <UserCurrentBalanceReport />
      </HasAccess>
    </PageContent>
  );
};
