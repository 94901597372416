import { C, CaseMode, filtrify, MatchMode, Operand } from '@modules/rsql';
import { dater } from '@helper/date';

import {
  FieldsConfig,
  SearchForm,
  SearchItem,
  SearchValue,
  TextModeForm,
  textModeInitial,
} from './context';

function isSearchCleared(searchForm: SearchForm, textMode: TextModeForm): boolean {
  // пока тут очень упрощенная логика, чтобы не создавать сложный алгоритм
  if (textModeInitial.caseMode !== textMode.caseMode) {
    return false;
  } else if (textModeInitial.fieldsConfig !== textMode.fieldsConfig) {
    return false;
  } else if (textModeInitial.matchMode !== textMode.matchMode) {
    return false;
  } else if (!searchForm) {
    return true;
  } else {
    return !Object.values(searchForm).filter((v) => (v instanceof Array ? !!v.length : !!v)).length;
  }
}

function parseRsqlHash<T>(memorize: boolean, json: string): T | undefined {
  try {
    return memorize ? JSON.parse(json) : undefined;
  } catch (e) {
    return undefined;
  }
}

function getRsqlString(
  searchFields: Array<SearchItem>,
  searchForm: SearchForm,
  textMode: TextModeForm
): string | null {
  if (!searchForm || Object.values(searchForm).filter((value) => !!value).length < 1) {
    // reset search if no values filled by user
    return null;
  }

  const searchSplit: { [k: string]: Array<SearchItem> } = searchFields
    .filter((item: SearchItem) =>
      !textMode || textMode.fieldsConfig === FieldsConfig.allFields
        ? true
        : textMode.customFields.includes(item.id) || item.type === 'dateRange'
    )
    .reduce((acc, item: SearchItem) => {
      acc[item.searchFormField] = acc[item.searchFormField] || [];
      acc[item.searchFormField].push(item);
      return acc;
    }, {});
  const searchArr = Object.values<Array<SearchItem>>(searchSplit);

  const rsql = filtrify({
    operands: searchArr
      .map((items: Array<SearchItem>) =>
        items
          .map((item) => getOperands(item, searchForm, textMode))
          .flat()
          .filter(filterEmpty)
      )
      .filter(filterEmpty),
  });
  return rsql || null;
}

function getOperands(
  item: SearchItem,
  searchForm: SearchForm,
  textMode: TextModeForm
): Array<Operand> | undefined {
  const raw: SearchValue = searchForm?.[item.searchFormField];
  if (isEmpty(raw)) {
    return undefined;
  }

  const isPartial = textMode?.matchMode === MatchMode.partial;
  const match = isPartial ? '%' : '';
  const operator = textMode?.caseMode === CaseMode.insensitive ? C.ignoreCaseLike : C.like;

  if (item.type === 'dateRange') {
    const [start, end] = raw as [string, string];
    return !start || !end
      ? undefined
      : item.fields.map((selector) => ({
          arguments: undefined,
          operator: undefined,
          rsql: `${selector}${C.greaterThan}'${dater.toGqlDate(start)}T00:00:00';${selector}${
            C.lessThan
          }'${dater.toGqlDate(end)}T23:59:59'`,
          selector: undefined,
        }));
  } else if (item.type === 'computedString') {
    const valTrim = (raw as string).trim();
    const values = isPartial ? valTrim.split(' ') : [valTrim];
    const brIn = values.length > 1 ? '(' : "'";
    const brOut = values.length > 1 ? ')' : "'";
    return [
      {
        arguments: undefined,
        operator: undefined,
        rsql: `${item.fields.join('|')}${operator}${brIn}${values
          .map((v) => `${match}${v}${match}`)
          .join(', ')}${brOut}`,
        selector: undefined,
      },
    ];
  } else if (item.type === 'select') {
    return item.fields.map((selector) => ({
      arguments: raw as string,
      operator: C.like,
      selector: selector,
    }));
  } else if (item.type === 'enum') {
    return !raw
      ? null
      : item.fields.map((selector) => ({
          arguments: raw,
          operator: C.equal,
          selector: selector,
        }));
  } else if (item.type === 'float' || item.type === 'long') {
    const value: SearchValue = +raw;
    return isNaN(value)
      ? undefined
      : item.fields.map((selector) => ({
          arguments: `${match}${value}${match}`,
          operator: operator,
          selector: selector,
        }));
  } else {
    const value: SearchValue = (raw as string).trim();
    return item.fields.map((selector) => ({
      arguments: `${match}${value}${match}`,
      operator: operator,
      selector: selector,
    }));
  }
}

function isEmpty(v: SearchValue) {
  if (v === 0) {
    return false;
  } else if (typeof v === 'string' && !!v.trim().length) {
    return false;
  } else if (typeof v !== 'boolean' && !(v instanceof Array) && +v === 0) {
    return false;
  } else if (!v) {
    return true;
  } else if (v instanceof Array) {
    return !v[0] || !v[1];
  }
  return false;
}

function filterEmpty(item) {
  return (!(item instanceof Array) && !!item) || (item instanceof Array && !!item.length);
}

const tools = {
  getRsqlString,
  isSearchCleared,
  parseRsqlHash,
};
export default tools;
