import { all, takeEvery } from 'redux-saga/effects';
import { Put } from '@helper/redux';
import { showModalAction } from '@modules/modal';
import { RedeemModalActionsTypes as RedeemTypes } from './model';
import { RedeemOptionsModal } from '../redeem-options-modal';

export function* redeemModalEffects() {
  yield all([
    takeEvery(RedeemTypes.ON_REDEEM_CLICKED, Put(showModalAction, { modal: RedeemOptionsModal })),
  ]);
}
