import { useQuery } from '@hooks/core';
import { useMemo } from 'react';
import api from './api';

export const useListingBrokers = () => {
  const { data: listingBrokersData, isLoading: isListingBrokersLoading } = useQuery(
    api.getListingBrokers,
    { initial: [] }
  );

  const listingBrokers = useMemo(() => {
    return listingBrokersData?.map((lb) => ({
      label: lb.displayName,
      value: lb.id,
    }));
  }, [listingBrokersData]);

  return { listingBrokers, isListingBrokersLoading, listingBrokersData };
};

export const useCurrencies = () => {
  const { data: currenciesData, isLoading: isCurrenciesLoading } = useQuery(api.getCurrencies, {
    initial: [],
  });

  const currencies = useMemo(() => {
    return currenciesData?.map((currency) => ({
      label: currency.code,
      value: currency.id,
    }));
  }, [currenciesData]);

  return { currencies, isCurrenciesLoading, currenciesData };
};
