import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { closeModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { handleBackendError, notifySuccess } from '@modules/notify';
import { useTransactionTypeTranslations } from '../../hooks';
import api, { TransactionForm } from './api';

export const useModel = (modal, onTransactionCreated: (id: number) => void) => {
  const [isConfirmTxLoading, setConfirmTxLoading] = useState<boolean>(false);
  const txTypeTranslations = useTransactionTypeTranslations();
  const closeModal = useCallDispatch(closeModalAction);

  const onConfirmClicked = async (transaction: TransactionForm) => {
    setConfirmTxLoading(true);
    try {
      const id = await api.confirmTransaction(transaction);
      onTransactionCreated(id);
      closeModal();
      notifySuccess({
        defaultTitle: true,
        text: <Trans id="shared.notification.35.text">The action was successfully completed</Trans>,
      });
    } catch (error) {
      handleBackendError(error);
      setConfirmTxLoading(false);
    }
  };

  return {
    lockUI: isConfirmTxLoading,
    onBackClicked: () => closeModal(modal),
    onCloseModalClicked: () => closeModal(),
    onConfirmClicked,
    txTypeTranslations,
  };
};
