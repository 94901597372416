import 'moment/locale/zh-cn';
import zh_CN from 'antd/lib/locale/zh_CN';
import en_US from 'antd/lib/locale/en_US';
import React, { FC, useEffect } from 'react';
import { connect, Provider } from 'react-redux';
import { I18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { ConfigProvider } from 'antd';

import 'react-notifications-component/dist/theme.css';
import '../shared/styles/fonts.scss';
import '../assets/styles/main.less';
import '../shared/styles/spacings.scss';
import { DispatchFn, RootState } from '@models/redux';
import { PreloaderPage } from '@components/preloader';
import { configSelectors } from '@config/core';
import { NotificationsContainer } from '@modules/notify';
import { LayoutProvider } from '@modules/layout';
import { Lang } from '@models/core';
import { CheckTraderTutorial } from '../containers/pages/tutorial/check';
import { Router } from '../Router';
import { bootstrapUserActions } from './store/user.acions';
import { store } from './store';
import { ThemeContainer } from './theme';

const mapStateToProps = (root: RootState) => ({
  isAppConfigured: configSelectors.isAppConfigured(root),
  i18n: configSelectors.i18n(root),
  lang: configSelectors.lang(root),
});

const mapDispatchToProps = (dispatch) => ({
  onAppOpened: () => dispatch(bootstrapUserActions.onAppOpened()),
});

const _ConnectedApp: FC<{
  isAppConfigured: boolean;
  i18n: I18n;
  lang: Lang;
  onAppOpened: DispatchFn<void>;
}> = ({ isAppConfigured, i18n, lang, onAppOpened }) => {
  useEffect(() => {
    onAppOpened();
  }, []);

  return !isAppConfigured ? null : (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <ConfigProvider locale={lang === Lang.cn ? zh_CN : en_US}>
        <Router />
      </ConfigProvider>
    </I18nProvider>
  );
};
const ConnectedApp = connect(mapStateToProps, mapDispatchToProps)(_ConnectedApp);

export const App = () => {
  return (
    <Provider store={store}>
      <ThemeContainer>
        <LayoutProvider>
          <ConnectedApp />
          <NotificationsContainer />
          <PreloaderPage />
          <CheckTraderTutorial />
        </LayoutProvider>
      </ThemeContainer>
    </Provider>
  );
};
