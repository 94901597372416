import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { MoreActions } from '@components/more-actions';
import { Permission, PermissionGroup, PermissionRecord, Role } from '@permissions/core';
import { showModalAction } from '@modules/modal';

import {
  CreateWithdrawalMyModal,
  permissionsGroup as withdrawalPermissionGroup,
} from '../../modals/withdrawal-request-my';
import {
  SendStableToInvestorModal,
  permissionGroup as sendStableToInvestorPermissionGroup,
} from '../../modals/send-stable-to-investor';
import { CompleteVerificationModal } from '../../modals/complete-verification';
import { useModel } from './model';
import { NoBankDetailsModal } from '../../modals/no-bank-details';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [withdrawalPermissionGroup, sendStableToInvestorPermissionGroup],
  marker: 'layout:balance-context-actions',
  optional: true,
});

// TODO: permissions: refactor
const withdrawalLegacyPermissionGroups = new PermissionGroup({
  operator: 'OR',
  groups: [
    new PermissionRecord(Permission.INDIVIDUAL_USER),
    new PermissionRecord(Permission.CORPORATE_USER),
  ],
});

const extendedWithdrawalPermissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [withdrawalPermissionGroup, withdrawalLegacyPermissionGroups],
});

export const BalanceContextActions: FC = () => {
  const m = useModel();

  return (
    <MoreActions
      placement="bottomRight"
      disabled={m.isBankDetailsIdsLoading}
      list={[
        {
          access: {
            forcedAccess: (user) => extendedWithdrawalPermissionGroup.resolve(user.permissions),
          },
          action: (dispatch) => {
            if (!m.isUserHasBankDetails) {
              dispatch(showModalAction({ modal: NoBankDetailsModal }));
            } else if (m.isVerificationFinished) {
              dispatch(showModalAction({ modal: CreateWithdrawalMyModal }));
            } else {
              dispatch(showModalAction({ modal: CompleteVerificationModal }));
            }
          },
          content: (
            <Trans id="wallet.sub_title.balance.context-menu.withdrawal_text">Withdrawal</Trans>
          ),
        },
        {
          access: {
            // TODO: permissions: refactor role
            forcedAccess: (user) =>
              sendStableToInvestorPermissionGroup.resolve(user.permissions) &&
              user.role.name === Role.issuer,
          },
          action: (dispatch) => {
            dispatch(showModalAction({ modal: SendStableToInvestorModal }));
          },
          content: (
            <Trans id="coins_table.button.send_stable_to_investor">
              Send currency to the investor account
            </Trans>
          ),
          disabled: (user) => !user?.issuerInvestor?.stablePublicKey,
        },
      ]}
    />
  );
};
