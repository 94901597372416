import React, { FC } from 'react';

import { ModalsStorage } from '@modules/modal';

export const PageContent: FC<{ className?: string; id?: string; uat?: string }> = ({
  children,
  className,
  uat,
  ...props
}) => {
  return (
    <div className={className} data-uat={uat} {...props}>
      {children}
      <ModalsStorage />
    </div>
  );
};
