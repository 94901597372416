import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { configSelectors } from '@config/core';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { approveBidOrder } from '../../legacy-service';
import { ApproveBidOrderModal } from './index';

export const useApproveBidOrderModalModel = (bidOrderId, onBidOrderApproved) => {
  const dispatch = useDispatch();
  const [inProcess, setInProcess] = useState(false);
  const [bankTxId, setBankTxId] = useState('');
  const wsInstance = useSelector(configSelectors.wsInstance);

  const closeModal = () => dispatch(closeModalAction(ApproveBidOrderModal));

  const approveOrder = async () => {
    try {
      setInProcess(true);
      const approvedOrder = await approveBidOrder(wsInstance, bidOrderId, bankTxId);
      onBidOrderApproved({ bidOrderId: approvedOrder.id, status: approvedOrder.status });
      setInProcess(false);
      closeModal();
    } catch (error) {
      handleBackendError(error);
      setInProcess(false);
    }
  };

  return { bankTxId, closeModal, setBankTxId, inProcess, approveOrder };
};
