import { BugfenderClass } from '@bugfender/sdk';

import { session } from '@services/session';
import { Any, TM2Contour } from '@models/core';
import { TM2Config } from '@constants/tm2';

declare const platform: {
  // https://www.npmjs.com/package/platform from cloudflare cdn
  description: string;
  layout: string;
  manufacturer: string;
  name: string;
  os: {
    architecture: number;
    family: string;
    version: string;
  };
  product: string;
  version: string;
};
declare const Bugfender: BugfenderClass;

const logOrigin = console.log;
const infoOrigin = console.info;
const warnOrigin = console.warn;
const errorOrigin = console.error;

// https://bugfender.com
class LoggerService {
  private _bugFender: BugfenderClass = null;
  private _logHistory: Array<Array<Any>> = [];
  private _requestsHistory: Array<string> = [];
  private _isBaseInfoSent: boolean = false;
  private _notErrors: Array<string> = [
    'perform a React state update on an unmounted component', // development error
    'ResizeObserver loop limit exceeded', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
    'Browser detection problem', // ignore detection of google and yandex bots
  ];

  /**
   * Log error to backend
   * @param description - error description text for developers
   * @param customData - object contains at least following fields:
   *            rawError - pure error data
   *            location - path to file and method where error fired
   */
  public async error(
    description: string,
    customData: LogCustomData = {} as LogCustomData
  ): Promise<void> {
    if (session.isUat) {
      console.error('LOGGER ERROR:', description, customData);
    }
    if (!this._bugFender) {
      return;
    }

    const { location, rawError, ...other } = customData;
    const errorMessage: string = rawError?.message || '';
    const isNotError: boolean = this._notErrors.reduce((flag: boolean, notErrorStr: string) => {
      return flag || errorMessage.indexOf(notErrorStr) > -1;
    }, false);

    if (isNotError) {
      return;
    }

    await this._setBaseInfo();

    const requestsHistory = this._requestsHistory.join('; ');
    this._sendLocalLog(
      'ERROR',
      errorMessage,
      description,
      location,
      requestsHistory,
      rawError?.stack,
      JSON.stringify(other)
    );

    const br: string = '\n\n';
    // @ts-ignore
    await this._bugFender.error(
      // all string magic here is for human readable log in bugFender web console
      '==================================================\n',
      `message: ${errorMessage}\n`,
      `developer text: ${description}\n`,
      `code location: ${location}`,
      br,
      `url: ${window.location.href}`,
      br,
      {
        gitCommit: process.env.REACT_APP_GIT_COMMIT,
        platform: {
          name: platform?.name,
          version: platform?.version,
          product: platform?.product,
          manufacturer: platform?.manufacturer,
          layout: platform?.layout,
          os: `${platform?.os?.family} ${platform?.os?.architecture} ${platform?.os?.version}`,
          description: platform?.description,
        },
        userId: session.userId(),
      },
      br,
      rawError && typeof rawError !== 'string' ? this._errorToJSONStr(rawError) : rawError,
      br,
      'additional info: ',
      other,
      br,
      'requests history: \n',
      requestsHistory,
      'console history: \n',
      this._logHistory
    );
    this._logHistory = [];
    this._requestsHistory = [];
  }

  public async log(message: string, obj?: any): Promise<void> {
    if (session.isUat) {
      console.info('LOGGER LOG:', message, obj);
    }
    if (!this._bugFender) {
      return;
    }
    this._sendLocalLog(
      'DEBUG',
      message,
      undefined,
      undefined,
      undefined,
      undefined,
      JSON.stringify(obj)
    );
    if (obj === undefined) {
      await this._bugFender.log(message);
    } else {
      // @ts-ignore
      await this._bugFender.log(message, '\n', obj);
    }
  }

  public init(): void {
    // NOTE: инстанс логгера инициализируется в index.html, чтобы отлавливать ошибки еще до инициализации реакта
    //       инициализация происходит только по флагу production, который проставляется через подгружаемый скрипт
    //       который лежит в cdn/js/tm2-config.js и он свой для каждого контура (подменяется на бекенде)
    if (session.isUat) {
      this._overrideLogsForUat();
    }
    if (TM2Config?.contour !== TM2Contour.production) {
      return;
    }
    this._bugFender = Bugfender;
    this._initGlobalErrorHandling();
    if (!session.isUat) {
      this._overrideLogsForBugfender();
    }
  }

  public storeRequest(type: 'http' | 'graphQL', name: string): void {
    this._requestsHistory.push(`${type}: ${name}\n`);
  }

  private async _setBaseInfo(): Promise<void> {
    if (!this._isBaseInfoSent) {
      this._isBaseInfoSent = true;

      if (this._bugFender) {
        // bugFender not exist on dev contours
        await this._bugFender.setDeviceKey('user_id', session.userId());
      }
    }
  }

  private _overrideLogsForBugfender(): void {
    console.log = (...argsOrigin) => {
      this._logHistory.push(['LOG:', this._getLogArgs(argsOrigin, true)]);
      return logOrigin(...argsOrigin);
    };
    console.info = (...argsOrigin) => {
      this._logHistory.push(['INFO:', this._getLogArgs(argsOrigin, true)]);
      return infoOrigin(...argsOrigin);
    };
    console.warn = (...argsOrigin) => {
      this._logHistory.push(['WARN:', this._getLogArgs(argsOrigin, true)]);
      return warnOrigin(...argsOrigin);
    };
    console.error = (error, ...argsOrigin) => {
      this.error('console error', {
        errorArgs: this._getLogArgs(argsOrigin, false) as Array<Any>,
        location: 'src/shared/services/logger.tsx:LoggerService._overrideConsoleLogs',
        rawError: error,
      });
      return errorOrigin(error, ...argsOrigin);
    };
  }

  private _overrideLogsForUat(): void {
    const deCycle = (obj: unknown, stack: Array<unknown> = []): unknown => {
      if (!obj || typeof obj !== 'object') {
        return obj;
      }
      if (stack.includes(obj)) {
        return null;
      }

      const s = stack.concat([obj]);
      return Array.isArray(obj)
        ? obj.map((x) => deCycle(x, s))
        : Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, deCycle(v, s)]));
    };

    const mapArg = (arg: unknown): unknown => {
      const s = (str: string): string => `<JSON>${str}</JSON>>`;
      if (arg instanceof Error) {
        return s(JSON.stringify(arg, Object.getOwnPropertyNames(arg)));
      }
      if (arg instanceof Object) {
        return s(JSON.stringify(deCycle(arg)));
      }
      return arg;
    };

    console.log = (...args) => logOrigin(...args.map(mapArg));
    console.info = (...args) => infoOrigin(...args.map(mapArg));
    console.warn = (...args) => warnOrigin(...args.map(mapArg));
    console.error = (...args) => errorOrigin(...args.map(mapArg));

    window.addEventListener('error', (e: ErrorEvent) => {
      if (e instanceof Object) {
        const { error, message, ...other } = e || {};
        console.error('GLOBAL ERROR', error, message, other);
      } else {
        console.error(e);
      }
    });
  }

  private _initGlobalErrorHandling(): void {
    window.addEventListener('error', (event) => {
      this.error('global window error', {
        location: 'src/shared/services/logger.tsx:addEventListener.error',
        rawError: event?.error || event,
      });
    });
    window.removeEventListener('error', window['_TM2'].globalErrorHandlerTmp); // tmp handler replaced with normal
    delete window['_TM2'].globalErrorHandlerTmp;
  }

  private _errorToJSONStr(_error: Error = {} as Error) {
    try {
      const { message, stack, ...error } = _error;
      const errorObj: object = this._depersonalizeData(error);
      return `message: ${message}\nstack: ${stack}\notherFields: ` + JSON.stringify(errorObj); // for human readable stack trace in bugFender terminal
    } catch (error) {
      const e: Error = error as Error;
      return `message: Error parsing failed. ${e?.message};`;
    }
  }

  private _getLogArgs(argsOrigin: Array<Any>, isStringify: boolean): string | Array<Any> {
    const args = (argsOrigin || [])
      .filter((item) => {
        // we can't log circular instances
        try {
          if (typeof item === 'function') {
            return false;
          }
          JSON.stringify(item);
          return true;
        } catch (error) {
          return false;
        }
      })
      .map((item) => {
        if (!item) {
          return item;
        }
        if (item instanceof Object && !Array.isArray(item)) {
          return this._depersonalizeData(item);
        }
        if (Array.isArray(item)) {
          return item.map((item) => this._depersonalizeData(item));
        }
        return item;
      });
    return isStringify ? JSON.stringify(args) : args;
  }

  private _depersonalizeData(object: object): object {
    return Object.getOwnPropertyNames(object).reduce((json, key) => {
      let value = object[key];
      if (value instanceof Object && !Array.isArray(value)) {
        value = this._depersonalizeData(value);
      } else if (Array.isArray(value)) {
        value = value.map((item) => this._depersonalizeData(item));
      } else if (isStringObject(value)) {
        value = this._depersonalizeData(JSON.parse(value));
      } else if (
        [
          'password',
          'matchingPassword',
          'address',
          'company',
          'firstName',
          'lastName',
          'name',
          'phone',
        ].includes(key)
      ) {
        value = '***';
      } else if (['email'].includes(key)) {
        const [userName, domainFull] = value?.split('@');
        const parsedDomain: Array<string> = domainFull.split('.');
        const label = parsedDomain[0];
        const zone = parsedDomain[parsedDomain.length - 1];
        if (zone === label) {
          value = `${userName.slice(0, 2)}**${userName.slice(-1)}@${zone.slice(0, 2)}**`;
        } else {
          value = `${userName.slice(0, 2)}**${userName.slice(-1)}@${label.slice(0, 1)}**.${zone}`;
        }
      }
      if (typeof value !== 'function') {
        json[key] = value;
      }
      return json;
    }, {});

    function isStringObject(obj): boolean {
      try {
        JSON.parse(obj);
        return !!obj; // additional check for null
      } catch (error) {
        return false;
      }
    }
  }

  private _sendLocalLog(
    type: 'DEBUG' | 'ERROR' | 'WARN',
    message: string,
    description: string,
    location: string,
    requests: string,
    stackTrace: string,
    other: string
  ): void {
    try {
      fetch('/log', {
        method: 'POST',
        body: window['_TM2'].createLogString(
          type,
          message,
          description,
          location,
          requests,
          stackTrace,
          other
        ),
      }).catch(() => {});
    } catch (error) {}
  }
}

export const loggerService = new LoggerService();

export interface LogCustomData {
  errorArgs?: Array<Any>;
  location: string;
  rawError?: Any;
  [k: string]: Any;
}
