import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { SortOrder } from '@models/sorting';
import { TableResponse } from '@models/core';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { dater } from '@helper/date';
import { Filter } from '../types';

export enum SortKey {
  dateTime = 'dateTime',
  currency = 'currency',
}
export type HistorySortOrder = SortOrder<SortKey>;

export interface BankHistory {
  dateTime: string; // Date & time - время проведенной транзакции. Из API "settledAt".
  idFromBank: string; // Transaction ID (bank) - ID транзакции со стороны банка. Из API "id".
  createdAt: string; // Created - время создания транзакции. Из API "createdAt".
  updatedAt: string; // Updated - время Обновления транзакции. Из API "updatedAt".
  method: string; // Method - Метод транзакции. Из API "method". Пример значений: "internal", "swift".
  senderId: number;
  senderName: string;
  receiverId: number;
  receiverName: string;
  purpose: string; // Transaction purpose - коммент к транзакции. Из API "purpose".
  reference: string; // Transaction reference. Из API "reference".
  sourceOfFunds: string; // Source of funds - Источник происхождения средств. Из API "sourceOfFunds".
  currency: string; // Currency - валюта транзакции. Из API "currencyCode".
  amount: number; // Amount - Сумма транзакции. Из API "amount".
  settledAmount: number; // Amount - Сумма транзакции фактически пришедшая получателю. Из API "settledAmount".
  receiverPreviousBalance: number; // Receiver Previous balance - Баланс получателя ДО транзакции. Вычисляемое поле.
  receiverNewBalance: number; // Receiver New balance - Баланс получателя ПОСЛЕ транзакции. Из API "receiverNewBalance".
  status: string; // Status - статус транзакции. Из API "status".
}

const getBankAccountHistoryPageQuery = 'externalBankTransactionsAsPage';
const getBankAccountHistoryPageFields = [
  {
    data: [
      'dateTime',
      'idFromBank',
      'createdAt',
      'updatedAt',
      'method',
      'senderId',
      'senderName',
      'receiverId',
      'receiverName',
      'purpose',
      'reference',
      'sourceOfFunds',
      'currency',
      'amount',
      'settledAmount',
      'receiverPreviousBalance',
      'receiverNewBalance',
      'status',
    ],
  },
  'index',
  'hasMore',
];
const getBankAccountHistoryPage = makeQuery({
  permissions: Permission.EXTERNAL_BANK_ACCOUNT_BALANCES_VIEW_REPORT,
  queryName: getBankAccountHistoryPageQuery,
  queryFields: getBankAccountHistoryPageFields,
  query: ({
    page,
    ordering,
    filter,
  }: {
    page: number;
    ordering: HistorySortOrder;
    filter: Filter;
  }): Promise<TableResponse<BankHistory>> => {
    const bankAccountFilter = filter.bankAccountId;
    const period = {
      from: dater.toBackendDate(filter.period[0]),
      to: dater.toBackendDate(filter.period[1]),
    };

    const typedValues = {
      ...v.int({ page }),
      ...v.orderInput({ ordering }),
      ...v.string({ bankAccountFilter }),
      ...v.rangeStringInput({ period }),
    };

    return stompClient.getData<TableResponse<BankHistory>>(
      getBankAccountHistoryPageQuery,
      sign(getBankAccountHistoryPageQuery, getBankAccountHistoryPageFields),
      typedValues
    );
  },
});

const api = {
  getBankAccountHistoryPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:bank-account-history-page-table');

export default api;
