import { useEffect, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Tabs, TabPane, TabsHeaderData } from '@components/tabs/regular';
import { handleBackendError } from '@modules/notify';
import { ProductInfo } from './product-info';
import { Redemption } from './redemption';
import type { Coin } from './types';

export type { Coin } from './types';

export type InitData = () => Promise<Coin>;

type CoinDetailsProps = {
  initData: InitData;
};

enum TabId {
  productInfo = 'productInfo',
  redeemOptions = 'redeemOptions',
}

const tabsHeaderData: TabsHeaderData = [
  {
    tabKey: TabId.redeemOptions,
    data: <Trans id={'digital_metals.coin_details.redeem_options'}>Redeem</Trans>,
  },
  {
    tabKey: TabId.productInfo,
    data: <Trans id={'digital_metals.coin_details.product_info'}>Product Info</Trans>,
  },
];

export function CoinDetails(props: CoinDetailsProps) {
  const [coin, setCoin] = useState<Coin>(null);

  useEffect(() => {
    props
      .initData()
      .then((coinData) => {
        setCoin(coinData);
      })
      .catch(handleBackendError);
  }, []);

  return (
    <Tabs data={tabsHeaderData} defaultActiveKey={TabId.redeemOptions} border>
      <TabPane key={TabId.redeemOptions} tabKey={TabId.redeemOptions}>
        <Redemption redeemOptions={coin?.redeemOptions} />
      </TabPane>
      <TabPane key={TabId.productInfo} tabKey={TabId.productInfo}>
        <ProductInfo
          infoFiles={coin?.infoFiles ?? []}
          productInfo={coin?.productInfo ?? ''}
          PMProductInfo={coin?.coinInfo?.primaryMarketProductInfo ?? ''}
        />
      </TabPane>
    </Tabs>
  );
}
