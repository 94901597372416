import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { Permission, PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { OfferByMe, OfferStatus, TableData } from '../shared/types';

type QueryOffer = {
  id: number;
  activeOfferPrices: Array<{
    id: number;
    quantity: number;
    currency: {
      id: number;
      code: string;
    };
    unit: {
      label: string;
    };
  }>;
  availableQuantity: number;
  coin: {
    asset: string;
    metal: {
      label: string;
    };
  };
  creator: {
    id: number;
    displayName: string;
  };
  date: string;
  quantityUnit: {
    label: string;
  };
  sellCommission: number;
  status: OfferStatus;
};

type QueryOfferList = TableData<QueryOffer>;

type GetOffersResponse = TableData<OfferByMe>;

const getOffersQuery = 'offersPage';
const getOffersFields = [
  {
    data: [
      'id',
      {
        activeOfferPrices: [
          'id',
          'quantity',
          { currency: ['id', 'code', 'symbol'] },
          { unit: ['label'] },
        ],
      },
      'availableQuantity',
      {
        coin: ['asset', { metal: ['label'] }],
      },
      { creator: ['id', 'displayName'] },
      'date',
      { quantityUnit: ['label'] },
      'sellCommission',
      'status',
    ],
  },
  'hasMore',
];
const getOffers = makeQuery({
  permissions: Permission.OFFERS_VIEW_WHERE_I_CREATOR,
  queryName: getOffersQuery,
  queryFields: getOffersFields,
  query: (params: {
    assetId?: number;
    creatorId?: number;
    pageNumber: number;
    status?: OfferStatus;
  }): Promise<GetOffersResponse> => {
    const variables = {
      ...v.offerPageSelectMode({ selectMode: 'MY' }),
      assetId: params.assetId,
      creatorId: params.creatorId,
      ...v.long({ page: params.pageNumber }),
      ...v.offerStatus({ status: params.status }),
    };
    return stompClient
      .getData<QueryOfferList>(getOffersQuery, sign(getOffersQuery, getOffersFields), variables)
      .then((res) => ({
        data: res.data.map<OfferByMe>((offer) => ({
          id: offer.id,
          activePrices: offer.activeOfferPrices.map((price) => ({
            id: price.id,
            quantity: price.quantity,
            currencyId: price.currency.id,
            currencyCode: price.currency.code,
            unitLabel: price.unit.label,
          })),
          asset: offer.coin.asset,
          availableQuantity: offer.availableQuantity,
          creatorId: offer.creator.id,
          creatorName: offer.creator.displayName,
          date: offer.date,
          metal: offer.coin.metal.label,
          quantityUnitLabel: offer.quantityUnit.label,
          sellCommission: offer.sellCommission,
          status: offer.status,
        })),
        hasMore: res.hasMore,
      }));
  },
});

const api = { getOffers };

export const permissionGroup = PermissionGroup.extract(api, 'api:offers-my');

export default api;
