import classnames from 'classnames';
import s from './index.module.scss';

export const CirclePreloader = ({ className = '', contrast = false }) => (
  <svg
    className={classnames(s.loader, className)}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <circle
      className={classnames(s.circle, { [s.circleContrast]: contrast })}
      cx="50"
      cy="50"
      r="38"
      strokeDasharray="179.0707812546182 61.690260418206066"
    >
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1.1764705882352942s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"
      />
    </circle>
  </svg>
);
