import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { Profile } from '../api.shared';
import { profileFields } from '../fields.shared';

// ----------------------------------------------------------------
export type ProfileOther = Profile;
const getOtherProfileFields = profileFields;
const getOtherProfileFieldsQuery = 'userProfile';
const getOtherProfile = makeQuery({
  permissions: { or: [Permission.PROFILE_VIEW_OTHER] },
  queryName: getOtherProfileFieldsQuery,
  queryFields: getOtherProfileFields,
  query: (userId: number): Promise<ProfileOther> => {
    return stompClientService.getData(
      getOtherProfileFieldsQuery,
      sign(getOtherProfileFieldsQuery, getOtherProfileFields),
      v.long({ userId })
    );
  },
});
// ----------------------------------------------------------------
const api = {
  getOtherProfile,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:profile-other-page');

export default api;
