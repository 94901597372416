import { useMemo, useState } from 'react';
import { useQuery } from '@hooks/core';
import api from './api';

export const useIssuers = () => {
  const [selectedSenderId, setSelectedSenderId] = useState<number | string>(null);
  const [selectedRecipientId, setSelectedRecipientId] = useState<number | string>(null);

  const { data: issuersData, isLoading: isIssuersLoading } = useQuery(api.getIssuers, {
    initial: [],
  });

  const modifiedIssuersData = [{ displayName: 'TM2', id: -2 }, ...issuersData];

  const issuers = useMemo(() => {
    return modifiedIssuersData?.map((i) => ({
      label: i.displayName,
      value: i.id,
      disabled: !selectedSenderId && !selectedRecipientId ? i.id !== -2 : i.id < 0,
    }));
  }, [issuersData, selectedSenderId, selectedRecipientId]);

  return {
    isIssuersLoading,
    issuers,
    selectedRecipientId,
    selectedSenderId,
    setSelectedRecipientId,
    setSelectedSenderId,
  };
};

export const useCurrencies = () => {
  const { data: currenciesData, isLoading: isCurrenciesLoading } = useQuery(api.getCurrencies, {
    initial: [],
  });

  const currencies = useMemo(() => {
    return currenciesData?.map((currency) => ({
      label: currency.code,
      value: currency.id,
    }));
  }, [currenciesData]);

  return { currencies, isCurrenciesLoading };
};
