import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from '../index.module.scss';
import { HasAccess, Role } from '@permissions/core';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import { UserProfileLink } from '@components/link';
import { Profile } from '../../../../api.shared';

const accessRoles = [Role.issuer];

export const LinkedInvestor: FC<{ classname: string; profile: Profile }> = ({
  classname,
  profile,
}) => {
  const email = profile.issuerInvestor?.email;
  const id = profile.issuerInvestor?.id;

  return (
    <HasAccess userPermissions={profile.permissions} roles={accessRoles}>
      <div className={classnames(s.row, classname)}>
        <div>
          <Title level={4} color="secondary">
            <Trans id="profile.account_details.linked_investor">Linked investor account</Trans>
          </Title>

          {email ? (
            <UserProfileLink id={id} text={email} uat="linked-investor-link" />
          ) : (
            <>
              <Text uat="linked-investor-link">
                <Trans id="profile.account_details.no_linked_investor">
                  There is no linked account.
                </Trans>
              </Text>
              <Text>
                <Trans id="profile.account_details.linked_investor.support">
                  Please contact TM2 support
                </Trans>
              </Text>
            </>
          )}
        </div>
      </div>
    </HasAccess>
  );
};
