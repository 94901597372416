import { ReactNode } from 'react';
import { useParams } from 'react-router';
import { Trans } from '@lingui/macro';
import { useQuery } from '@hooks/core';
import { useHasAccess } from '@permissions/hooks';
import { Title } from '@components/typography/title';
import { Text } from '@components/typography/text';
import api, { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:profile-user-other-tab',
});

export function useCheckOtherTab() {
  return useHasAccess({ and: [permissionGroup] });
}

export type UserOtherTabProps = {
  hidden: boolean;
};

export function UserOtherTab(props: UserOtherTabProps) {
  const showTab = useCheckOtherTab();
  return showTab ? <UserOtherTabComponent {...props} /> : null;
}

function NotProvided() {
  return <Trans id={'profile.tab.other.not_provided'}>Not provided</Trans>;
}

function Row(props: { label: ReactNode; text: ReactNode }) {
  return (
    <div className={'mb2'}>
      <Title level={4} color={'secondary'}>
        {props.label}
      </Title>
      <Text>{props.text}</Text>
    </div>
  );
}

function UserOtherTabComponent(props: UserOtherTabProps) {
  const { userId } = useParams<{ userId: string }>();
  const { data } = useQuery(() => api.getUserInfo(Number(userId)));

  return !props.hidden && data ? (
    <div>
      <Row
        label={<Trans id={'profile.tab.other.ums_participant'}>UMS Participant</Trans>}
        // Can be empty string.
        text={data.computedFields.participantCode || <NotProvided />}
      />
      <Row
        label={<Trans id={'profile.tab.other.ums_trading_account'}>UMS Trading account</Trans>}
        text={data.umsId ?? <NotProvided />}
      />
      <Row
        label={<Trans id={'profile.tab.other.cqg_login_id'}>CQG login id</Trans>}
        text={data.cqgProfile.loginId ?? <NotProvided />}
      />
      <Row
        label={<Trans id={'profile.tab.other.cqg_account_id'}>CQG account id</Trans>}
        text={data.cqgProfile.accountId ?? <NotProvided />}
      />
      <Row
        label={<Trans id={'profile.tab.other.cqg_customer_id'}>CQG customer id</Trans>}
        text={data.cqgProfile.profileId ?? <NotProvided />}
      />
    </div>
  ) : null;
}
