import { FC } from 'react';
import {
  ModalServiceActions as ServiceActions,
  modalServiceActions as serviceActions,
} from './store/service.acions';
import {
  modalUserActions as usersActions,
  ModalUserActions as UserActions,
} from './store/user.acions';
import {
  ConfirmModalProps as _ConfirmModalProps,
  ConfirmModalTexts as _ConfirmModalTexts,
  ModalInfo as _ModalInfo,
  NotifyModalProps,
} from './store/model';

export { Modal } from './base/modal/index';
export { ModalTitle } from './base/title/index';
export { TitleIcon } from './base/title/icon';
export { ModalTitleTabs } from './base/title-tabs/index';
export { ModalContent } from './base/content/index';
export { ModalFooter } from './base/footer/index';
export { modalUserActions } from './store/user.acions';
export { modalServiceActions } from './store/service.acions';
export { modalSelectors } from './store/selectors';
export { ConfirmationModal } from './confirmation-modal';
export { ModalsStorage } from './modals-storage';

export const closeLatestModalAction = usersActions.onCloseLatestModalClicked;

export const closeModalAction = usersActions.onCloseModalClicked;

export const closeModalNoAnimationAction = serviceActions.closeModal;

export const showModalAction: <P = object>(
  payload: ModalInfo<P>
) => UserActions.OnShowModalClicked<P> = usersActions.onShowModalClicked;

export const showConfirmModalAction: (
  payload: ConfirmModalProps
) => ServiceActions.ShowConfirmationModal = serviceActions.showConfirmationModal;

export const showNotifyModalAction: (
  payload: NotifyModalProps
) => UserActions.OnShowNotifyModalClicked = usersActions.onShowNotifyModalClicked;

export interface ConfirmModalTexts extends _ConfirmModalTexts {}
export interface ConfirmModalProps extends _ConfirmModalProps {}
export interface ModalInfo<T> extends _ModalInfo<T> {}

/**
 * Type for modal components to separate modal props from all redux connected props
 */
export type MFC<ConnectedProps = object, ModalProps = object> = FC<
  { isVisible: boolean } & ConnectedProps & ModalProps
>;
