import { useEffect } from 'react';

import {
  useActiveOfferPrices,
  useOnPairSelected,
  useOnTickerLoading,
  useSelectedPair,
} from '../provider';
import { useTicker } from '../service';

export const useProductSelectorModal = (defaultCoin, defaultCurrency) => {
  const selectedPair = useSelectedPair();
  const onTickerLoading = useOnTickerLoading();
  const onPairSelectedUpdater = useOnPairSelected();

  const onPairSelected = (pair) => {
    const coinId = pair?.coin?.id;
    const currencyId = pair?.currency?.id;
    if (coinId && currencyId) {
      // TODO index.js:1 Warning: Received NaN for the `children` attribute
      const urlBase = `${window.location.origin}${window.location.pathname}`;
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('coin', coinId);
      searchParams.set('currency', currencyId);
      const newUrl = `${urlBase}?${searchParams.toString()}`;
      window.history.pushState({ path: newUrl }, '', newUrl);
    }
    return onPairSelectedUpdater(pair);
  };

  const [tickerByMetals, isTickerLoading] = useTicker();

  useEffect(() => onTickerLoading(isTickerLoading), [isTickerLoading]);

  useEffect(() => {
    if (selectedPair) return;

    const defaultPair = tickerByMetals
      .map((t) => t.list)
      .reduce((acc, list) => acc.concat(list), [])
      .find(
        (pair) =>
          String(pair.coin.id) === defaultCoin && String(pair.currency.id) === defaultCurrency
      );

    if (defaultPair) {
      onPairSelectedUpdater(defaultPair);
      return;
    }
    if (tickerByMetals[0]?.list[0]) {
      onPairSelectedUpdater(tickerByMetals[0].list[0]);
    }
  }, [tickerByMetals]);

  const [activeOfferPrices, isActiveOfferPricesLoading] = useActiveOfferPrices();
  const available =
    activeOfferPrices?.reduce(
      (acc, curr) => acc + curr.offer.availableQuantity * curr.offer.quantityUnit.inGrams,
      0
    ) ?? 0;

  return {
    selectedPair,
    onPairSelected,
    tickerByMetals,
    isActiveOfferPricesLoading,
    available,
  };
};
