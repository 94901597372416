import React, { useEffect } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import s from './modal.module.scss';
import Image from 'containers/systems/image';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { redeemOptionsModalSelectors as redeemOptionsSelectors } from '../redeem-options-modal/store/selectors';
import { redeemModalSelectors as redeemSelectors } from '../store/selectors';
import { redeemConfirmationModalUserActions as redeemConfirmUserActions } from './store/user.acions';
import { redeemConfirmationModalSelectors as redeemConfirmSelectors } from './store/selectors';
import { ButtonPrimary } from '@components/button';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Divider } from '@components/divider';

const mapStateToProps = (root) => ({
  asset: redeemConfirmSelectors.asset(root),
  balance: redeemSelectors.redeemBalance(root),
  canConfirm: redeemConfirmSelectors.canConfirm(root),
  fiatBalance: redeemConfirmSelectors.fiatBalance(root),
  isPriceMetal: redeemConfirmSelectors.isPriceMetal(root),
  lockUI: redeemConfirmSelectors.lockUI(root),
  metalPrice: redeemOptionsSelectors.storedMetalPrice(root),
  metalQuantity: redeemConfirmSelectors.metalQuantity(root),
  minimalQuantity: redeemConfirmSelectors.minimalQuantity(root),
  optionsQuantity: redeemConfirmSelectors.optionsQuantity(root),
  price: redeemConfirmSelectors.price(root),
  redeemOption: redeemOptionsSelectors.storedRedeemOption(root),
  totalInFiat: redeemConfirmSelectors.totalInFiat(root),
  totalInMetal: redeemConfirmSelectors.totalInMetal(root),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(redeemConfirmUserActions.onCloseModalClicked()),
  onConfirmRedeemClicked: () => dispatch(redeemConfirmUserActions.onConfirmRedeemClicked()),
  onMetalQuantityChanged: (quantity) =>
    dispatch(redeemConfirmUserActions.onMetalQuantityChanged(quantity)),
  onModalLeft: () => dispatch(redeemConfirmUserActions.onModalLeft()),
  onModalOpened: () => dispatch(redeemConfirmUserActions.onModalOpened()),
  onOptionsQuantityChanged: (quantity) =>
    dispatch(redeemConfirmUserActions.onOptionsQuantityChanged(quantity)),
});

const ConnectedRedeemConfirmationModal = ({
  asset,
  balance,
  canConfirm,
  fiatBalance,
  isPriceMetal,
  isVisible,
  lockUI,
  metalPrice,
  minimalQuantity,
  metalQuantity,
  onClose,
  onConfirmRedeemClicked,
  onMetalQuantityChanged,
  onModalLeft,
  onModalOpened,
  onOptionsQuantityChanged,
  optionsQuantity,
  price,
  redeemOption,
  totalInFiat,
  totalInMetal,
}) => {
  useEffect(() => {
    onModalOpened();
    return () => onModalLeft();
  }, []);

  return (
    <Modal
      className={s.modal}
      isVisible={isVisible}
      disabled={lockUI}
      loading={lockUI}
      onClose={onClose}
    >
      <ModalTitle>
        <Trans id="wallet.redeem_confirm_modal.redeem.confirmation_v2">
          Redemption confirmation
        </Trans>
      </ModalTitle>

      <ModalContent>
        <div className={s.info}>
          {redeemOption.image && (
            <div className={s.infoImage}>
              <Image
                height={120}
                className="label-img"
                downloadable={false}
                uploadFile={redeemOption.image}
              />
            </div>
          )}

          <div
            className={classnames(s.infoDescription, !redeemOption.image && s.noImageDescription)}
          >
            <div className={s.descriptionRow}>
              <div className={s.descriptionText}>
                <Trans id="wallet.redeem_confirm_modal.digital.metal">Digital metal</Trans>
              </div>
              <div className={s.descriptionValue}>{asset}</div>
            </div>

            <div className={s.descriptionRow}>
              <div className={s.descriptionText}>
                <Trans id="wallet.redeem_confirm_modal.redeem.option_v2">Redemption option</Trans>
              </div>
              <div className={s.descriptionValue}>{redeemOption.name}</div>
            </div>

            <div className={s.descriptionRow}>
              <div className={s.descriptionText}>
                <Trans id="wallet.redeem_confirm_modal.redeem.option.weight_v2">
                  Redemption option weight
                </Trans>
              </div>
              <div className={s.descriptionValue}>
                {redeemOption.amount}
                {redeemOption.unit.label}
              </div>
            </div>

            <div className={s.descriptionRow}>
              <div className={s.descriptionText}>
                <Trans id="wallet.redeem_confirm_modal.redeem.price.per.option_v2">
                  Redemption price, per option
                </Trans>
              </div>
              <div className={s.descriptionValue}>
                {`${isPriceMetal ? formatToken(price) : formatMoney(price)} ${
                  isPriceMetal ? asset : ''
                }`}
              </div>
            </div>
          </div>
        </div>

        <Divider />

        <div className={s.formRow}>
          <div className={classnames(s.colAmount, 'mt-auto')}>
            <div className={classnames(s.label, s.labelSmall)}>
              <Trans id="wallet.redeem_confirm_modal.options.amount">Options, amount </Trans>
            </div>

            <div className="input-number input-number-left-align">
              <table className="input-number__text">
                <tbody>
                  <tr>
                    <td>{redeemOption.name}</td>
                  </tr>
                </tbody>
              </table>
              <input
                type="text"
                onChange={(e) => onOptionsQuantityChanged(e.target.value)}
                value={optionsQuantity || ''}
              />
              <div className={classnames(s.label, s.labelSmall)}>
                <Trans id="wallet.redeem_confirm_modal.min.quantity">
                  Min. quantity: {minimalQuantity}
                </Trans>
              </div>
            </div>
          </div>

          <div className={s.colType}>
            <div className={classnames(s.label, s.labelSmall, 'align-right')}>
              1 {redeemOption.name} = {formatToken(metalPrice)} {asset}
            </div>
            <div className="input-number input-number-left-align">
              <table className="input-number__text">
                <tbody>
                  <tr>
                    <td>{asset}</td>
                  </tr>
                </tbody>
              </table>
              <input
                type="text"
                onChange={(e) => onMetalQuantityChanged(e.target.value)}
                value={metalQuantity || ''}
              />
              <div className={classnames(s.label, s.labelSmall)}>&nbsp;</div>
            </div>
          </div>
        </div>

        <div className={s.formRow}>
          <div className={s.label}>
            <Trans id="wallet.redeem_confirm_modal.redeem.price_v2">Redemption price</Trans>
          </div>
          <div className={s.textPrimary}>
            {`${
              isPriceMetal
                ? formatToken(price * optionsQuantity)
                : formatMoney(price * optionsQuantity)
            } ${isPriceMetal ? asset : ''}`}
          </div>
        </div>

        <div>
          <div className={s.formRow}>
            <div className="mr-auto">
              <Trans id="wallet.redeem_confirm_modal.total.td">Total {asset}</Trans>
            </div>
            <div className={s.textPrimary}>{formatToken(totalInMetal)}</div>
          </div>

          <div className={s.formRow}>
            <div className="mr-auto">
              <Trans id="wallet.redeem_confirm_modal.total.usd">Total USD</Trans>
            </div>
            <div className={s.textPrimary}>{formatMoney(totalInFiat)}</div>
          </div>

          <p className="align-center">
            <Trans id="wallet.redeem_confirm_modal.available.balance">Available balance:</Trans>
            {isPriceMetal ? '' : `${formatMoney(fiatBalance)}, `}
            {formatToken(balance.balance)}&nbsp;{asset}
          </p>
        </div>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary
          fullWidth={true}
          disabled={!canConfirm || lockUI}
          onClick={onConfirmRedeemClicked}
        >
          <Trans id="button.confirm">Confirm</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};

export const RedeemConfirmationModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedRedeemConfirmationModal);
