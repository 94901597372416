import React, { FC, ReactNode } from 'react';
import { Trans } from '@lingui/macro';

import { PostRegSteps } from '@config/store/model';
import { loggerService as logger } from '@services/logger';
import { ProvideContactInfoStep } from './individual-steps/contact-information';
import { ProvideAddressInfoStep } from './individual-steps/address-information';
import { VerificationSuggestionStep } from './individual-steps/verification-suggestion';
import { VerificationUploadStep } from './individual-steps/verification-upload';
import { CompanyDetailsStep } from './institutional-steps/company-details';
import { RegulatedOrListedStatusStep } from './institutional-steps/regulated-or-listed-status';
import { ContactDetailsStep } from './institutional-steps/contact-details';

type Step = {
  component: FC;
  header: ReactNode;
  name: PostRegSteps;
  hideStepNumber?: boolean;
};
const stepsIndividual: Array<Step> = [
  {
    component: () => null,
    header: <Trans id="sign_up.header.steps.create_an_account">Create an account</Trans>,
    name: PostRegSteps.indKK,
  },
  {
    component: ProvideContactInfoStep,
    header: <Trans id="sign_up.header.steps.contact_info">Contact information</Trans>,
    name: PostRegSteps.indContact,
  },
  {
    component: ProvideAddressInfoStep,
    header: <Trans id="sign_up.header.steps.address_info">Address information</Trans>,
    name: PostRegSteps.indAddress,
  },
  {
    component: VerificationSuggestionStep,
    header: <Trans id="sign_up.header.steps.verification">Verification</Trans>,
    name: PostRegSteps.indVerificationSuggestion,
    hideStepNumber: true,
  },
  {
    component: VerificationUploadStep,
    header: <Trans id="sign_up.header.steps.verification">Verification</Trans>,
    name: PostRegSteps.indVerificationUpload,
    hideStepNumber: true,
  },
];

const stepsInstitutional: Array<Step> = [
  {
    component: () => null,
    header: <Trans id="sign_up.header.steps.create_an_account">Create an account</Trans>,
    name: PostRegSteps.instKK,
  },
  {
    component: CompanyDetailsStep,
    header: <Trans id="sign_up.header.steps.company_details">Company details</Trans>,
    name: PostRegSteps.instCompany,
  },
  {
    component: RegulatedOrListedStatusStep,
    header: (
      <Trans id="sign_up.header.steps.regulated_or_listed_status">Regulated or listed status</Trans>
    ),
    name: PostRegSteps.instRegulated,
  },
  {
    component: ContactDetailsStep,
    header: <Trans id="sign_up.header.steps.contact_details">Contact details</Trans>,
    name: PostRegSteps.instContact,
  },
];

export const getSteps = (corporate: boolean, individual: boolean) =>
  corporate ? stepsInstitutional : individual ? stepsIndividual : [];

export const getCurrentStep = (
  corporate: boolean,
  individual: boolean,
  index: number | undefined
): Step | null => {
  const steps = getSteps(corporate, individual);
  return typeof index === 'number' ? steps[index] : null;
};

export const getCurrentStepIndex = (corporate: boolean, individual: boolean, lastStepFinished) => {
  const steps = getSteps(corporate, individual);
  const finished = steps.findIndex((step) => step.name === lastStepFinished);
  // после успешной реги в КК lastStep c бека будет приходить null, подробности в PostRegSteps енуме
  // нулевой шаг в массиве шагов - это КК шаг, который по определению уже пройден
  const i = finished < 0 ? 1 : finished + 1;

  if (
    !i ||
    i < 0 ||
    (corporate && i >= stepsInstitutional.length) ||
    (individual && i >= stepsIndividual.length)
  ) {
    logger.error('Wrong current post-reg step detected', {
      location: 'pages/post-registration/steps.tsx',
      corporate: corporate,
      index: i,
      individual: individual,
      lastStepFinished: lastStepFinished,
    });
  }
  return i;
};
