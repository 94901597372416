import { CompatClient, Stomp } from '@stomp/stompjs';

import { configApi } from '@config/core';
import { session, SessionStatus as Session } from '@services/session';
import { loggerService as logger } from '@services/logger';
import { uuidv4 } from '../../../../containers/services/asyncService';
import { AuthorizedInfoPayload } from '../../../../bootstrap/store/model';
import { liveUpdateService as liveUpdate, LiveUpdateEventType as LiveEvent } from '../live-update';
import { stompClientService as stompClient } from '../client';

class WebsocketsService {
  public stompLogin: string = null;

  private _client: CompatClient = null;
  private _config: AuthorizedInfoPayload = null;
  private _queue: string = null;
  private _isUserAuthorized: boolean = false;

  public connect(config: AuthorizedInfoPayload): Promise<void> {
    // NOTE: this method called in config effects only once at app initiation
    //       and only when credentials are checked and session is good
    //       all WS reconnects handled by _beforeConnect and _establishConnection methods
    logger.log('WS: connection start');
    this._isUserAuthorized = true;
    return new Promise((resolve: Function, reject: Function): void => {
      try {
        console.log('Trying WS connect...');
        const url = `${window.document.location.protocol === 'https:' ? 'wss' : 'ws'}://${
          document.location.host
        }/stomp`;
        this._client = Stomp.client(url);
        this._config = config;
        this._client.reconnectDelay = 5000;
        this._client.debug = () => {};
        this._queue = uuidv4();
        stompClient.initiate(this._client);
        this.stompLogin = btoa(
          JSON.stringify({
            queue: this._queue,
            userId: config.userId.toString(), // NOTE тут нужен строковый, а не числовой ID
          })
        );

        console.log('Connecting with session', this.stompLogin);
        this._client.beforeConnect = () => this._beforeConnect();
        this._establishConnection(resolve, reject);
      } catch (error) {
        reject(error);
      }
    });
  }

  public logOut(): void {
    this._isUserAuthorized = false;
    this._client?.deactivate();
  }

  private _establishConnection(resolve?: Function, reject?: Function): void {
    this._client.connect(
      null, // do not send credentials here we always send latest connection credentials in _beforeConnect
      () => {
        this._onConnect();
        resolve && resolve();
      },
      (error) => {
        this._onError(error);
        reject && reject(error);
      },
      (error) => this._onClose(error),
      '/'
    );
  }

  private _beforeConnect(): Promise<void> {
    return new Promise<void>((resolve: Function, reject: Function): void => {
      logger.log('WS: before connection settings', {
        login: this.stompLogin,
        passcode: session.getAccessToken(),
        sessionStatus: session.sessionStatus(),
        _isUserAuthorized: this._isUserAuthorized,
      });

      if (!this._isUserAuthorized) {
        reject(new Error('Trying to connect to WS when user is unauthorized'));
      }
      const status: Session = session.sessionStatus();
      if (status === Session.bad) {
        return reject(new Error('Unable reconnect to WS due to bad session'));
      }

      this._client.connectHeaders.login = this.stompLogin;
      this._client.connectHeaders.passcode = session.getAccessToken();

      if (status === Session.expired) {
        logger.log('WS: Session expired, try to updated token');
        configApi
          .refreshAccessToken()
          .then((token: string) => {
            this._client.connectHeaders.passcode = token;
            logger.log('WS: Token updated successfully');
            resolve();
          })
          .catch((error) => {
            logger.log('WS: Token update failed', error);
            reject(error);
          });
      } else {
        logger.log('WS: Before connection successful');
        resolve();
      }
    }).catch((error: Error) => {
      logger.log('WS: before connection error', error);
      liveUpdate.emit({
        data: this._client,
        error: error,
        type: LiveEvent.wsRefreshSessionFailed,
      });
      this.logOut();
    });
  }

  private _onClose = (error) => {
    stompClient.onDisconnected(error);
    logger.log('WS: connection closed', {
      error,
      _isUserAuthorized: this._isUserAuthorized,
      configUserId: this._config.userId,
      sessionUserId: session.userId(),
    });
    liveUpdate.emit({
      data: this._client,
      error: error,
      type: LiveEvent.wsClosed,
    });

    if (this._isUserAuthorized) {
      if (this._config.userId === session.userId()) {
        this._establishConnection();
      } else {
        this.logOut();
        liveUpdate.emit({
          error: new Error(
            `Previous connected user id "${this._config.userId}" changed to "${session.userId()}"`
          ),
          type: LiveEvent.wsUnrecognizedSession,
        });
      }
    } else {
      this.logOut();
      liveUpdate.emit({ type: LiveEvent.wsUnrecognizedSession });
    }
  };

  private _onConnect = () => {
    const token: string = session.getAccessToken();
    console.log('Connected with session', token);
    logger.log('WS: connection established');

    this._client.subscribe(
      `/exchange/tm2.topic/user.session.${this._queue}`,
      (msg) => this._onMessage(msg.body),
      { 'x-queue-name': this._queue }
    );

    this._client.subscribe(
      `/exchange/tm2.topic/user.id.${this._config.userId}`,
      (msg) => this._onMessage(msg.body),
      { 'x-queue-name': this._queue }
    );

    this._client.subscribe(
      `/exchange/tm2.topic/user.role.${this._config.role}`,
      (msg) => this._onMessage(msg.body),
      { 'x-queue-name': this._queue }
    );

    stompClient.onConnected();
    liveUpdate.emit({
      data: this._client,
      type: LiveEvent.wsConnected,
    });
  };

  private _onError(error) {
    logger.log('WS: connection error', error);
    if (error.body.startsWith('Access refused for user')) {
      this.logOut();
      liveUpdate.emit({ type: LiveEvent.wsUnrecognizedSession });
    } else {
      liveUpdate.emit({ error: error, type: LiveEvent.wsError });
      logger.error('Stomp service failed', {
        location: 'src/containers/services/websocketService.js:webSocketConnect.onError',
        rawError: error,
      });
    }
  }

  private _onMessage(message: string) {
    const { event: type, data } = JSON.parse(message);
    liveUpdate.emit({ data: data, type: type });
  }
}

export const websocketsService: WebsocketsService = new WebsocketsService();
