import Big from 'big.js';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { defineMessage, Trans } from '@lingui/macro';

import v, { nestedName } from '@components/form/validators';
import { OnFormAction, SelectItem } from '@components/form';
import { closeModalAction } from '@modules/modal';
import { currenciesSelectors } from '@modules/currencies';
import { roundDown } from '@helper/number';
import { notifySuccess } from '@modules/notify';
import { maxDecimals } from '@constants/math';
import { useI18n } from '../../../../services/commonService';
import { OfferByMe } from '../../shared/types';
import api, { useCoins, useUnit } from './api';
import { PlaceOfferModal } from './index';
import { transform } from './mapper';

export const formId = 'placeOfferForm';

export enum Fields {
  coin = 'coin.id',
  prices = 'offerPrices',
  pricesCurrency = 'currency.id',
  pricesQuantity = 'quantity',
  pricesUnit = 'unit.id',
  quantity = 'quantity',
  quantityUnit = 'quantityUnit.id',
}
const F = Fields; // alias

export interface Form {
  coin: { id: number };
  offerPrices: Array<{
    currency: { id: number };
    quantity: number;
    unit: { id: number };
  }>;
  quantity: number;
  quantityUnit: { id: number };
}
export const formInitial: Form = {
  coin: undefined,
  offerPrices: [
    {
      currency: undefined,
      quantity: undefined,
      unit: undefined,
    },
  ],
  quantity: undefined,
  quantityUnit: undefined,
};

interface Result {
  costTotal: number;
  currency: string;
  costTax: number;
  taxPct: number;
}

export const useModel = (onOfferCreated: (o: OfferByMe) => void) => {
  const dispatch = useDispatch();
  const { i18n, lang } = useI18n();
  const [form, onFormChange] = useState<Form>(formInitial);
  const currencies = useSelector(currenciesSelectors.currencies);
  const isCurrenciesLoading = useSelector(currenciesSelectors.isLoading);
  const { data: coinsRaw, isLoading: isCoinsLoading } = useCoins();
  const { data: selectedUnit, isLoading: isUnitsLoading } = useUnit();
  const [isCreateOfferLoading, setCreateOfferLoading] = useState<boolean>(false);
  const noCoinsAvailable = !coinsRaw.length && !isCoinsLoading;
  const lockUI = isCurrenciesLoading || isCoinsLoading || isUnitsLoading || isCreateOfferLoading;

  const closeModal = () => dispatch(closeModalAction(PlaceOfferModal));

  const coins: Array<SelectItem> = useMemo(
    () =>
      coinsRaw.map((c) => ({
        disabled: (c.info?.balance || 0) < 1,
        label: `${c.asset} (${c.metal.label})`,
        tooltip:
          (c.info?.balance || 0) < 1 &&
          i18n._(
            defineMessage({
              id: 'bids.bids_list.place_bid_modal.disabled_symbol_label',
              message: 'Insufficient number of Units',
            })
          ),
        value: c.id,
      })),
    [coinsRaw, lang] // eslint-disable-line
  );

  const currenciesSelected = useMemo(
    // used to disallow user to select same currencies for different prices
    () => form.offerPrices.filter((p) => !!p).map((p) => p.currency?.id),
    [form]
  );

  const amountMax = useMemo(() => {
    const id = form?.coin?.id;
    const balance = id && coinsRaw.find((c) => c.id === id)?.info?.balance;
    const inGrams = selectedUnit?.inGrams;
    return !balance || !inGrams ? 0 : roundDown(balance / inGrams, 0);
  }, [coinsRaw, form, selectedUnit]);

  const results: Result = useMemo(() => {
    const coinId = form.coin?.id;
    const amount = form.quantity ?? 0;
    const price = form.offerPrices[0] && (form.offerPrices[0].quantity ?? 0);
    const taxPct = coinsRaw.find((c) => c.id === coinId)?.issuerTax ?? 0;
    const currencyId = form.offerPrices[0] && form.offerPrices[0].currency?.id;

    const baseCost = new Big(price).mul(amount);
    const costTax = baseCost.div(100).mul(taxPct).round(maxDecimals, Big.roundUp);
    const costTotal = baseCost.add(costTax).round(maxDecimals, Big.roundUp);

    return {
      costTotal: costTotal.toNumber(),
      currency: currencyId && currencies.find((c) => c.value === currencyId)?.label,
      costTax: costTax.toNumber(),
      taxPct: taxPct,
    };
  }, [coinsRaw, currencies, form]);

  const onPlaceOfferAction: OnFormAction<Form> = {
    error: () => setCreateOfferLoading(false),
    fieldValidation: {
      [F.coin]: [v.required],
      [nestedName([F.prices, F.pricesCurrency])]: [v.required],
      [nestedName([F.prices, F.pricesQuantity])]: [v.required, v.numMax(999999), v.numMin(0.01)],
      [F.quantity]: [v.required, v.numMin(1)],
    },
    submit: async (form) => {
      setCreateOfferLoading(true);
      const createdOffer = await api.createOffer(form);
      onOfferCreated(transform(createdOffer));
      setCreateOfferLoading(false);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="place_offer_modal.success_place_notify">
            The offer was successfully placed
          </Trans>
        ),
      });
      closeModal();
    },
  };

  return {
    amountMax,
    closeModal,
    coins,
    currencies,
    currenciesSelected,
    lockUI,
    onFormChange,
    onPlaceOfferAction,
    results,
    selectedUnit,
    noCoinsAvailable,
  };
};
