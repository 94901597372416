import sign from 'tm2sign.macro';

import { makeQuery, Permission as P, PermissionGroup } from '@permissions/core';
import { TableResponse } from '@models/core';
import { stompClientService as stompClient } from '@services/stomp/client';

export type QueryBankAccountBalancesPage = TableResponse<Redemption>;

export enum RedemptionStatus {
  approved = 'APPROVED',
  approvingError = 'APPROVING_ERROR',
  approvingPending = 'APPROVING_PENDING',
  burned = 'BURNED',
  cancelled = 'CANCELLED',
  cancelledError = 'CANCELLATION_ERROR',
  cancelledPending = 'CANCELLATION_PENDING',
  created = 'CREATED',
  creationError = 'CREATION_ERROR',
  creationPending = 'CREATION_PENDING',
  denied = 'DENIED',
  denyingError = 'DENYING_ERROR',
  denyingPending = 'DENYING_PENDING',
  processing = 'PROCESSING',
  processingError = 'PROCESSING_ERROR',
  processingPending = 'PROCESSING_PENDING',
}

export interface Redemption {
  id: number;
  createdAt: string;
  status: RedemptionStatus;
  optionsQuantity: number;
  coin: { id: number; asset: string };
  optionName: string;
  optionAmount: number;
  optionUnit: { label: string; inGrams: number };
  rem: string;
  priceType: string;
  pricePerOption: number;
  user: { id: number; displayName: string };
}

export interface Filters {
  status?: RedemptionStatus;
  coinId?: number;
}

const getRedemptionsPageQuery = 'redeemsPage';
const getRedemptionsPageFields = [
  {
    data: [
      'id',
      'createdAt',
      'status',
      'optionsQuantity',
      { coin: ['id', 'asset'] },
      'optionName',
      'optionAmount',
      { optionUnit: ['label', 'inGrams'] },
      'rem',
      'priceType',
      'pricePerOption',
      { user: ['id', 'displayName'] },
    ],
  },
  'index',
  'hasMore',
];
const getRedemptionsPage = makeQuery({
  permissions: { or: [P.REDEEM_VIEW_REQUESTS, P.REDEEM_VIEW_MY_REQUESTS] },
  queryName: getRedemptionsPageQuery,
  queryFields: getRedemptionsPageFields,
  query: ({
    page,
    filter,
  }: {
    page: number;
    filter: Filters;
  }): Promise<QueryBankAccountBalancesPage> => {
    const { coinId, status } = filter;

    return stompClient.getData<QueryBankAccountBalancesPage>(
      getRedemptionsPageQuery,
      sign(getRedemptionsPageQuery, getRedemptionsPageFields),
      { page, coinId, status }
    );
  },
});

const api = {
  getRedemptionsPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:redemptions-page');

export default api;
