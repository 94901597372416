import { useSelector } from 'react-redux';

import { Permission, Role } from '@permissions/core';
import { configSelectors, UserInfo } from '@config/core';
import { useAuthentication } from '@components/guards/hooks';

export const usePostRegistrationGuardModel = (
  forcedAccess: boolean,
  allowedPermissions: Array<Permission>,
  allowedRoles: Array<Role>,
  customForcedAccessCheckFn: (u: UserInfo) => boolean,
  extraCheck: (u: UserInfo) => boolean
) => {
  const isInvestor: boolean = useSelector(configSelectors.isInvestor);
  const isRegistrationFinished: boolean = useSelector(configSelectors.isRegistrationFinished);
  const { isHasAccess, redirectUrl } = useAuthentication(
    forcedAccess,
    allowedPermissions,
    allowedRoles,
    customForcedAccessCheckFn,
    extraCheck
  );

  return {
    isHasAccess: isHasAccess && isInvestor && !isRegistrationFinished,
    redirectTo: isInvestor && isRegistrationFinished ? '/' : redirectUrl,
  };
};
