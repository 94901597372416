import { PermissionGroup } from '@permissions/core';
import {
  BidOrders,
  permissionGroup as bidOrdersTablePermissionGroup,
  BidOrdersProps,
} from '../shared/bid-orders-table';
import api, { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, bidOrdersTablePermissionGroup],
  marker: 'layout:bid-orders-by-all',
});

export type BidOrdersByAllProps = Pick<BidOrdersProps, 'assetId' | 'status'>;

export function BidOrdersByAll(props: BidOrdersByAllProps) {
  return <BidOrders getBidOrders={api.getBidOrders} showBuyer showStatusActions {...props} />;
}
