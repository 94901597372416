import { useSelector } from 'react-redux';
import { configSelectors } from '@config/store/selectors';
import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import { defineMessage } from '@lingui/macro';
import { UserGoal } from './index';
import { useMemo } from 'react';

export const useModel = ({ modal, onHideCustomerSurvey }) => {
  const i18n = useSelector(configSelectors.i18n);

  const closeModal = useCallDispatch(closeModalAction);
  const onCloseModal = () => closeModal(modal);
  const onOKClicked = () => {
    onHideCustomerSurvey();
    onCloseModal();
  };

  const userGoals = useMemo(
    () => [
      {
        key: UserGoal.theRiskTaker,
        metals: ['Platinum', 'Palladium', 'Silver', 'Rhodium', 'Iridium'],
        header: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.the_risk_taker',
            message: 'The risk taker',
          })
        ),
        title: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.more_but_riskier.title',
            message: 'Clear Trend, Clear Drivers, Liquidity, Average Risk, Volatility:',
          })
        ),
        descriptions: [
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.more_but_riskier.description_part1',
              message:
                'Historically, this group of metals has shown good upward trends and good investment savings with continuous liquidity.',
            })
          ),
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.more_but_riskier.description_part2',
              message:
                'The metals listed here are expected to play an increasingly important role in the technology space. Some of them may present higher volatility and risk due to growing global factors.',
            })
          ),
        ],
      },
      {
        key: UserGoal.theSafeBet,
        metals: ['Platinum', 'Palladium', 'Silver', 'Iridium', 'Rhenium'],
        header: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.the_safe_bet',
            message: 'The safe bet',
          })
        ),
        title: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.less_but_safer.title',
            message: 'Clear Trend, Clear Drivers, Liquidity, Average Risk, Perspective:',
          })
        ),
        descriptions: [
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.less_but_safer.description_part1',
              message:
                'These metals have fundamental historical basis and in-demand promising applications.',
            })
          ),
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.less_but_safer.description_part2',
              message:
                'Their prices have not fluctuated that much over the years. This means that the metals listed below are generally a good way to understand the benefits of technology metals without exposing yourself to too much risk.',
            })
          ),
        ],
      },
      {
        key: UserGoal.diversified,
        metals: ['Platinum', 'Palladium', 'Silver', 'Osmium', 'Iridium'],
        header: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.diversify',
            message: 'Diversify your portfolio',
          })
        ),
        title: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.diversified.title',
            message: 'Clear Trend, Clear Drivers, Liquidity, Average Risk, Diversification:',
          })
        ),
        descriptions: [
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.diversified.description_part1',
              message:
                'For many years, portfolio managers have bought gold as one of their main diversification tools.',
            })
          ),
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.diversified.description_part2',
              message:
                'However, there are other products for suitable for diversification purposes based on the development of future technologies and their uses to tackle global climate changes.',
            })
          ),
        ],
      },
      {
        key: UserGoal.economicHedge,
        metals: ['Platinum', 'Palladium', 'Silver'],
        header: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.hedging_the_economy',
            message: 'Hedging the Economy',
          })
        ),
        title: i18n._(
          defineMessage({
            id: 'primary_market.page.helper.modal.economic_hedge.title',
            message: 'Clear Trend, Clear Drivers, Liquidity, Average Risk, Hedge:',
          })
        ),
        descriptions: [
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.economic_hedge.description_part1',
              message: 'Some of these metals, such as gold and silver, are considered safe havens.',
            })
          ),
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.economic_hedge.description_part2',
              message:
                'Over the past decade, platinum and palladium has also become a safe haven for capital protection and inflationary risks.',
            })
          ),
          i18n._(
            defineMessage({
              id: 'primary_market.page.helper.modal.economic_hedge.description_part3',
              message:
                'Owning these metals allows you to hedge against inflation and changes in an economy due to in-demand applications.',
            })
          ),
        ],
      },
    ],
    [i18n]
  );

  return { onCloseModal, onOKClicked, userGoals };
};
