import React, { FC, ReactElement, useMemo } from 'react';
import classnames from 'classnames';
import { Button as AntdButton } from 'antd';
import { ButtonHTMLType } from 'antd/lib/button/button';

import './styles/index.scss';
import { ReactComponent as ArrowRightShort } from '@images/icons/arrow-right-short.svg';
import { ReactComponent as Preloader } from '@images/icons/button-preloader.svg';
import { ButtonAction, ButtonColor, ButtonType, useButtonModel } from './model';

export { ButtonAction, ButtonColor, ButtonType } from './model';

export interface ButtonModel {
  action?: ButtonAction;
  className?: string;
  children?;
  color?: ButtonColor;
  disabled?: boolean;
  fullWidth?: boolean;
  htmlType?: ButtonHTMLType;
  icon?: ReactElement<HTMLOrSVGElement>;
  loading?: boolean;
  onClick?: Function;
  size?: 'sm' | 'md' | 'lg';
  type?: ButtonType;
  uat?: string;
}

/**
 * Represents base button component
 * @param action - on click action. It can be one of the following:
 *                 1. standard on click listener (default)
 *                 2. file select action that fired in onClick listener (ButtonAction.file)
 * @param className - additional css class if required
 * @param children - central button content (text in common cases)
 * @param color - button color (see ui-kit page)
 * @param disabled - flag to disable button
 * @param fullWidth - flag to fit button with 100% of container width
 * @param icon - right from text icon. NOTE: this field accepts 3 types of data:
 *               1. undefined - it will show default arrow right button
 *               2. null - it will show no icon
 *               3. svg file as ReactComponent - it will show custom svg icon. NOTE: svg should be colorized only with
 *                  fill or only with stroke property and not have fill or stroke color values
 *                  and svg element should have css class tm2-icon-fill or tm2-icon-stroke to colorize icon
 * @param loading - external flag to show loading animation on button. NOTE: if you set loading flag as null - button
 *                  will remove loading container (button width will decrease)
 * @param onClick - standard on click event listener. NOTE: if listener is not set - button automatically will
 *                  have pointer-events: none css rule
 * @param size - button size (see ui-kit page)
 * @param type - button type (see ui-kit page)
 */
export const Button: FC<ButtonModel> = ({
  action = ButtonAction.click,
  className,
  children,
  color = ButtonColor.primary,
  disabled,
  fullWidth = false,
  htmlType,
  icon = <ArrowRightShort className="tm2-button-arrow tm2-icon-fill" />,
  loading: loadingForced,
  onClick,
  size = 'md',
  type = ButtonType.button,
  uat,
  ...props
}) => {
  const { antType, combinedClass, iconClass, FileLogic, loading, onButtonClick } = useButtonModel({
    action,
    className,
    color,
    disabled,
    htmlType,
    icon,
    loadingForced,
    onClick,
    size,
    type,
  });

  return useMemo(() => {
    if (type === ButtonType.button || type === ButtonType.outline) {
      return (
        <AntdButton
          className={combinedClass}
          block={fullWidth}
          htmlType={htmlType}
          type={antType}
          disabled={disabled}
          onClick={onButtonClick}
          data-uat={uat}
          {...props}
        >
          <div className="tm2-button-container tm2-button-preloader">
            {!loading ? null : <Preloader className="tm2-icon-stroke" />}
          </div>

          <span className="tm2-button-text">{children}</span>

          {FileLogic}

          <div className={classnames('tm2-button-container', iconClass)}>{icon}</div>
        </AntdButton>
      );
    }

    if (type === ButtonType.iconOnlyBordered || type === ButtonType.iconOnlyPure) {
      return (
        <AntdButton
          className={combinedClass}
          type={antType}
          disabled={disabled}
          onClick={onButtonClick}
          data-uat={uat}
          {...props}
        >
          <div className="tm2-button-icon-container mxy-auto">
            {loading ? <Preloader className="tm2-button-preloader tm2-icon-stroke" /> : icon}
          </div>
          {FileLogic}
        </AntdButton>
      );
    }

    if (type === ButtonType.text) {
      return (
        <AntdButton
          className={combinedClass}
          type={antType}
          disabled={disabled}
          onClick={onButtonClick}
          data-uat={uat}
          {...props}
        >
          {loading === null ? null : (
            <div className="tm2-button-container tm2-button-preloader">
              {!loading ? null : <Preloader className="tm2-icon-stroke" />}
            </div>
          )}

          <span className="tm2-button-text">{children}</span>

          {FileLogic}

          {icon === null ? null : (
            <div className={classnames('tm2-button-container', iconClass)}>{icon}</div>
          )}
        </AntdButton>
      );
    }

    if (type === ButtonType.textIconBig) {
      return (
        <AntdButton
          className={combinedClass}
          type={antType}
          disabled={disabled}
          onClick={onButtonClick}
          data-uat={uat}
          {...props}
        >
          <span className="tm2-button-text">{children}</span>

          <div className="tm2-button-container">
            <div className={classnames('tm2-button-icon', iconClass)}>
              {!loading ? icon : <Preloader className="tm2-icon-stroke" />}
            </div>
          </div>

          {FileLogic}
        </AntdButton>
      );
    }

    return null;
  }, [children, className, disabled, loading, onClick]);
};
