import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { ButtonModalBack, ButtonPrimary } from '@components/button';
import { Divider } from '@components/divider';
import { dater } from '@helper/date';
import { AccountInfo, InfoItem } from '../shared/account-info';
import { CMBankAccount } from '../shared/api';
import { permissionsGroup as apiPermissionsGroup, TransactionForm } from './api';
import { useModel } from './model';

export const permissionsGroup = apiPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-confirm-transaction-modal',
});

export interface ConfirmCMTransactionModalProps {
  onTransactionCreated: (id: number) => void;
  receiver: CMBankAccount;
  sender: CMBankAccount;
  transaction: TransactionForm;
}
export const ConfirmCMTransactionModal: MFC<ConfirmCMTransactionModalProps> = (p) => {
  const m = useModel(ConfirmCMTransactionModal, p.onTransactionCreated);

  return (
    <Modal
      size="big"
      isVisible={p.isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      onClose={m.onCloseModalClicked}
      uat="cm-create-transaction-modal"
    >
      <ModalTitle>
        <Trans id="cm_confirm_tx_modal.title">Confirm transaction</Trans>
      </ModalTitle>

      <ModalContent>
        <div className={classnames(s.row, 'mb3')}>
          <InfoItem
            label={<Trans id="cm_confirm_tx_modal.label.type">Transaction type</Trans>}
            value={m.txTypeTranslations[p.transaction.type]}
          />
        </div>

        <Divider className="mb3" />

        {!p.sender ? null : (
          <Title className="mb3" level={3}>
            <Trans id="cm_confirm_tx_modal.title.sender">Sender details</Trans>
          </Title>
        )}
        <AccountInfo account={p.sender} />
        {!p.sender ? null : <Divider className="mb3" />}

        {!p.receiver ? null : (
          <Title className="mb3" level={3}>
            <Trans id="cm_confirm_tx_modal.title.receiver">Receiver details</Trans>
          </Title>
        )}
        <AccountInfo account={p.receiver} />
        {!p.receiver ? null : <Divider className="mb3" />}

        <div className={s.row}>
          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.currency">Currency</Trans>}
            value={p.transaction.currency.id}
          />

          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.ext_id">External ID</Trans>}
            value={p.transaction.externalId}
          />

          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.val_date_time">Value date & time</Trans>}
            value={
              p.transaction.valueDateTime && dater.toLocalDateTime(p.transaction.valueDateTime)
            }
          />

          <InfoItem
            className="mb3"
            label={
              <Trans id="cm_confirm_tx_modal.label.tx_date_time">Transaction date & time</Trans>
            }
            value={
              p.transaction.transactionDateTime &&
              dater.toLocalDateTime(p.transaction.transactionDateTime)
            }
          />

          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.method">Method</Trans>}
            value={p.transaction.method}
          />

          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.amount">Amount</Trans>}
            value={p.transaction.amount}
          />

          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.purpose">Purpose</Trans>}
            value={p.transaction.purpose}
          />

          <InfoItem
            className="mb3"
            label={<Trans id="cm_confirm_tx_modal.label.funds_src">Source of funds</Trans>}
            value={p.transaction.sourceOfFunds}
          />

          <InfoItem
            className={s.fullWidth}
            label={<Trans id="cm_confirm_tx_modal.label.comment">Comment</Trans>}
            value={p.transaction.comment}
          />
        </div>
      </ModalContent>

      <ModalFooter>
        <ButtonModalBack className="mr3" onClick={m.onBackClicked} disabled={m.lockUI} />

        <ButtonPrimary
          fullWidth={true}
          onClick={() => m.onConfirmClicked(p.transaction)}
          disabled={m.lockUI}
        >
          <Trans id="button.confirm">Confirm</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
