import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
import { formatNumber } from 'fast-number-formatter';
import moment from 'moment';
import produce from 'immer';
import _ from 'lodash';

import { tokenDecimalsMax } from '@constants/math';
import { configSelectors } from '@config/core';
import { useShowPreloaderLine } from '@components/layouts/dark/provider';
import { roundDown } from '@helper/number';
import { listFetchAction } from 'actions/listAction';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from '@constants/date';
import { privateApi, staticContentApi } from 'utils/api';
import { formErrors, formIsSend } from './formService';
import type { TableResponse } from '@models/core';

export { useIsMountedRef } from '../../shared/hooks/dom';
export { useI18n } from '../../shared/hooks/i18n';

export const fetchMetals = () => {
  return (dispatch) => {
    return privateApi.get('/metals').then((response) => {
      dispatch(listFetchAction('metals', response.data));
    });
  };
};

type FormatMoneyOptions = {
  default?: number;
  pre?: string;
  post?: string;
} | null;
export const formatMoney = (value: number, opts: FormatMoneyOptions = null) => {
  const valueDefault = opts?.default || 0;
  if (opts === undefined) {
    // hacks for ts types
    opts = { pre: 'USD ', post: '' };
  }
  return `${opts?.pre || ''} ${!value ? valueDefault : formatNumber(value, 2)} ${opts?.post || ''}`;
};

export const formatToken = (value, opts?) => {
  if (opts === undefined) {
    // hacks for ts types
    opts = { pre: '', post: '' };
  }
  return `${opts?.pre || ''} ${formatNumber(roundDown(value, tokenDecimalsMax) || 0)} ${
    opts?.post || ''
  }`;
};

export const downloadFile = async (url, fileName) => {
  let response = await getFile(url);
  saveAs(new Blob([response.data]), fileName);
};

export const downloadFileWithPost = async (url, data, fileName) => {
  const response = await privateApi.post(url, data, { responseType: 'blob' });
  saveAs(new Blob([response.data]), fileName);
};

export const downloadStaticFile = async (url, fileName) => {
  let response = await getStaticFile(url);
  saveAs(new Blob([response.data]), fileName);
};

export const getFile = async (url) =>
  privateApi({
    url: url,
    method: 'GET',
    responseType: 'blob',
  });

export const getStaticFile = async (url) =>
  staticContentApi({
    url: url,
    method: 'GET',
    responseType: 'blob',
  });

export const checkRegExp = (val, regexp) => {
  return new RegExp(`^${regexp}$`).test(val);
};

export const sendForm = (form, body) => async (dispatch) => {
  dispatch(formIsSend(form, true));
  try {
    return await body();
  } catch (e) {
    const error = e as any;
    if (_.get(error, 'response.status') === 400) {
      dispatch(formErrors('forgotPassword', error.response.data.details));
    }
  } finally {
    dispatch(formIsSend(form, false));
  }
};

export const copyToClipboard = (val) => {
  let textField = document.createElement('textarea');
  textField.innerText = val;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

Number.prototype.minusPercent = function (val) {
  // @ts-ignore
  return this - (this * val) / 100;
};

Number.prototype.plusPercent = function (val) {
  // @ts-ignore
  return this + (this * val) / 100;
};

String.prototype.formatLocalDate = function () {
  // @ts-ignore
  return moment(this).format(DATE_FORMAT);
};
String.prototype.formatLocalTime = function () {
  // @ts-ignore
  return moment(this).format(TIME_FORMAT);
};
String.prototype.formatLocalDateTime = function () {
  // @ts-ignore
  return moment(this).format(DATE_TIME_FORMAT);
};

// NOTE: this hook has experimental type and this type can be wrong
export const usePage = <T extends { id: number }>(
  providerMethod: any,
  fields: any,
  filter: Array<string> = [],
  comparator: (a: T, b: T) => boolean = (a, b) => a.id === b.id
): [TableResponse<T>, () => void, (item: T, equalityFn?: (el: T) => boolean) => void] => {
  const wsInstance = useSelector(configSelectors.wsInstance);
  const isWsConnected = useSelector(configSelectors.isWsConnected);

  const [pageNumber, setPageNumber] = useState(0);

  useEffect(() => {
    setPageNumber(0);
  }, [...filter, isWsConnected]); // eslint-disable-line

  const loadMore = () => setPageNumber(pageNumber + 1);

  const replaceDataElement: (item: T, equalityFn?: (el: T) => boolean) => void = (
    element: T,
    compareFunction: (e: T) => boolean = (current) => comparator(element, current)
  ) =>
    setResult((currentResult) => {
      const [currentData] = currentResult;
      const newData = [];
      let replaced = false;

      currentData.data.forEach((current) => {
        if (compareFunction(current)) {
          newData.push(element);
          replaced = true;
        } else {
          newData.push(current);
        }
      });

      if (!replaced) {
        newData.unshift(element);
      }

      return [{ ...currentData, data: newData }, loadMore, replaceDataElement];
    });

  const prevFilter = usePrevious(filter);
  const showPreloader = useShowPreloaderLine();

  const [result, setResult] = useState<
    [TableResponse<T>, () => void, (item: T, equalityFn?: (el: T) => boolean) => void]
  >([{ data: [], hasMore: false, index: 0 }, loadMore, replaceDataElement]);
  useEffect(() => {
    if (isWsConnected)
      (async () => {
        if (!_.isEqual(filter, prevFilter) && pageNumber !== 0) return;

        try {
          showPreloader(true);
          const pageData = await providerMethod(wsInstance, fields, pageNumber, ...filter);
          if (pageData) {
            const oldData = pageNumber === 0 ? [] : result[0].data;

            const newData = _.concat(
              oldData.slice(0, pageData.index),
              pageData.data,
              oldData.slice(pageData.index)
            );

            setResult([
              { data: newData, hasMore: pageData.hasMore, index: pageData.index },
              loadMore,
              replaceDataElement,
            ]);
          }
        } catch (e) {
          console.log(e);
        } finally {
          showPreloader(false);
        }
      })();
  }, [isWsConnected, ...filter, pageNumber]);

  return result;
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useList = (providerMethod, def, inputs = []) => {
  const [list, setList] = useState(def);
  const wsInstance = useSelector(configSelectors.wsInstance);
  const isWsConnected = useSelector(configSelectors.isWsConnected);

  useEffect(() => {
    const get = async () => {
      setList(def);
      const response = await providerMethod(wsInstance);
      setList(response);
    };

    if (isWsConnected) {
      get();
    }
  }, [isWsConnected, ...inputs]);

  return list;
};

export const useQuery = (providerMethod, fields, ...params) => {
  const wsInstance = useSelector(configSelectors.wsInstance);
  const isWsConnected = useSelector(configSelectors.isWsConnected);
  const [result, setResult] = useState([undefined, true]);
  const showPreloader = useShowPreloaderLine();
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const doRefresh = useCallback(() => setRefreshTrigger((old) => !old), [setRefreshTrigger]);
  useEffect(() => {
    if (isWsConnected) {
      (async () => {
        try {
          // @ts-ignore
          setResult(([data]) => [data, true, doRefresh]);
          showPreloader(true);
          const response = await providerMethod(wsInstance, fields, ...params);
          setResult([response, false, doRefresh]);
        } catch (e) {
          console.log(e);
          // @ts-ignore
          setResult(([data]) => [data, false, doRefresh]);
        } finally {
          showPreloader(false);
        }
      })();
    }
  }, [isWsConnected, fields, ...params, refreshTrigger]);

  return result;
};

export const useBrowserSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    const updateSize = () =>
      setSize([document.documentElement.clientWidth, document.documentElement.clientHeight]);

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return size;
};

export const useFormChange = (defaultValue = {}) => {
  const [formData, setFormData] = useState(defaultValue);

  const onFormChange = useCallback(
    ({ target }) => {
      const { name, value, type, pattern = '.*' } = target;
      const parentPath = getParentPath(name);

      const isArray = name.endsWith(']');

      mutate(setFormData, (draft) => {
        if (type === 'checkbox' && value === 'on') {
          _.set(draft, name, target.checked);
        } else {
          if (value === undefined || value === '\u0000') {
            _.unset(draft, name);
          } else {
            if (pattern) {
              if (checkRegExp(value, pattern)) {
                _.set(draft, name, value);
              }
            } else {
              _.set(draft, name, value);
            }
          }
        }

        if (parentPath) {
          const parent = _.get(draft, parentPath);
          if (_.isEmpty(parent)) {
            _.unset(draft, parentPath);
          }
        }

        if (isArray) {
          const objectNameWithoutIndex = name.slice(0, name.lastIndexOf('['));
          const compacted = _.compact(_.get(draft, objectNameWithoutIndex));
          _.set(draft, objectNameWithoutIndex, compacted);
        }
      });
    },
    [setFormData]
  );

  return [formData, onFormChange, setFormData];
};

const getParentPath = (path) => {
  const splitted = path.split('.');
  splitted.pop();
  return splitted.join('.');
};

export const mutate = (setter, cbk) =>
  setter((oldState) =>
    produce(oldState, (draft) => {
      cbk(draft);
    })
  );
