import { useSelector } from 'react-redux';

import { configSelectors } from '@config/store/selectors';

import { useBankDetails } from './hooks';

export const useModel = () => {
  const isVerificationFinished = useSelector(configSelectors.isVerificationFinished);

  const { isUserHasBankDetails, isBankDetailsIdsLoading } = useBankDetails();

  return {
    isBankDetailsIdsLoading,
    isUserHasBankDetails,
    isVerificationFinished,
  };
};
