import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { ButtonDownload } from '@components/button/download';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { useModel } from './model';

export const DownloadTransactionsButton: FC<{ className: string }> = ({ className }) => {
  const { lockUI, onDownloadClicked } = useModel();

  return (
    <Tooltip
      className={className}
      placement="bottom"
      trigger={TooltipTrigger.hover}
      title={
        <Trans id="management_fee_report.button.download_xls">
          Download management fee report xls
        </Trans>
      }
    >
      <ButtonDownload disabled={lockUI} onClick={onDownloadClicked} compact="all" />
    </Tooltip>
  );
};
