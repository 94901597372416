import React, { FC, memo } from 'react';

import s from '../../index.module.scss';
import { TablePane } from '@components/table2/utils';
import { tableColCfgDefault, tableRowCfgDefault } from '@components/table2/config/table-simplified';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { Table } from '@components/table2/simplified';
import { useColumns } from './columns';
import { useModel } from './model';

export const permissionsGroup = apiPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-bank-accounts-table',
});

export const CMBankAccountsTable: FC = memo(() => {
  const c = useColumns();
  const m = useModel();

  return (
    <TablePane className={s.verticalFiller}>
      <Table
        className={s.verticalFiller}
        columns={c.columns}
        columnConfig={tableColCfgDefault}
        instance={m.tableInstance}
        rowConfig={tableRowCfgDefault}
      />
    </TablePane>
  );
});
