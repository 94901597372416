import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { SelectItem } from '@components/form';
import { Permission } from '@permissions/core';

//--------------------------------------------------------------------------
const getBasicSMFeeFields = ['value'];
const getBasicSMFeeQuery = 'basicSecondaryMarketFee';
const getBasicSMFee = makeQuery({
  queryName: getBasicSMFeeQuery,
  queryFields: getBasicSMFeeFields,
  query: (): Promise<number> => {
    return stompClientService
      .getData<{ value: number }>(getBasicSMFeeQuery, sign(getBasicSMFeeQuery, getBasicSMFeeFields))
      .then((response) => response.value);
  },
});
//--------------------------------------------------------------------------
interface CoinFee {
  id: number;
  asset: string;
  coinInfo: {
    secondaryMarketFee: number;
  };
}
const getCoinInfoFields = ['id', 'asset', { coinInfo: ['secondaryMarketFee'] }];
const getCoinInfoQuery = 'coins';
const getCoinInfo = makeQuery({
  queryName: getCoinInfoQuery,
  queryFields: getCoinInfoFields,
  query: (): Promise<{ assets: Array<SelectItem>; fees: Form['assetIdsFees'] }> => {
    return stompClientService
      .getData<Array<CoinFee>>(getCoinInfoQuery, sign(getCoinInfoQuery, getCoinInfoFields))
      .then((coins) => ({
        assets: coins.map((asset) => ({ label: asset.asset, value: asset.id })),
        fees: coins
          .filter((item) => !!item.coinInfo.secondaryMarketFee)
          .map((item) => ({
            assetId: item.id,
            secondaryMarketFee: item.coinInfo.secondaryMarketFee,
          })),
      }));
  },
});
//--------------------------------------------------------------------------
export interface Form {
  assetIdsFees: Array<{
    assetId: number;
    secondaryMarketFee: number;
  }>;
  secondaryMarketBasicFee: number;
}
const setSMFeesFields = ['value'];
const setSMFeesMutation = 'setSecondaryMarketFees';
const setSMFees = makeQuery({
  permissions: { or: [Permission.EDIT_FEES] }, // TODO этот пермишн нужен в model.ts для isEditAllowed
  queryName: setSMFeesMutation,
  queryFields: setSMFeesFields,
  query: (form: Form): Promise<void> => {
    const { secondaryMarketBasicFee, assetIdsFees = [] } = form;
    return stompClientService.sendData(
      setSMFeesMutation,
      sign(setSMFeesMutation, setSMFeesFields),
      v.secondaryFeesInput({ assetFees: { assetIdsFees, secondaryMarketBasicFee } })
    );
  },
});
//--------------------------------------------------------------------------
const api = {
  getBasicSMFee,
  getCoinInfo,
  setSMFees,
};

// TODO страница с этим апи еще не реекспортит пермишн групы и не юзает их в роутере/сайдбаре
export const permissionGroup = PermissionGroup.extract(api, 'api:fees-page');

export default api;
