import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup, Role } from '@permissions/core';
import { MarketingCompanyItem } from '../api';

export enum Fields {
  numberOfUsers = 'numberOfUsers',
  amountOfVirtualMoneyPerClient = 'amountOfVirtualMoneyPerClient',
  targetUserRole = 'targetUserRole.id',
  systemEvent = 'systemEvent.id',
}

export interface Form {
  [Fields.numberOfUsers]: number;
  [Fields.amountOfVirtualMoneyPerClient]: number;
  [Fields.targetUserRole]: number;
  [Fields.systemEvent]: number;
}

const getMarketingCompanyQuery = 'marketingCompanyById';
const getMarketingCompanyFields = [
  'numberOfUsers',
  'amountOfVirtualMoneyPerClient',
  { targetUserRole: ['id'] },
  { systemEvent: ['id'] },
  'numberOfUsersLeft',
];
const getMarketingCompany = makeQuery({
  permissions: Permission.SETTING_MARKETING_COMPANY,
  queryName: getMarketingCompanyQuery,
  queryFields: getMarketingCompanyFields,
  query: (id: number): Promise<MarketingCompanyItem> => {
    const typedValues = {
      ...v.long({ id }),
    };

    return stompClient.getData(
      getMarketingCompanyQuery,
      sign(getMarketingCompanyQuery, getMarketingCompanyFields),
      typedValues
    );
  },
});

export interface RoleItem {
  id: number;
  label: string;
  name: Role;
  permissions: string;
}
const getRolesQuery = 'roles';
const getRolesFields = ['id', 'label', 'name'];
const getRoles = makeQuery({
  queryName: getRolesQuery,
  queryFields: getRolesFields,
  query: (): Promise<Array<RoleItem>> => {
    return stompClient
      .getData(getRolesQuery, sign(getRolesQuery, getRolesFields))
      .then((response) => response.filter((r) => r.name === Role.investor));
  },
});

export interface SystemEvent {
  id: number;
  label: string;
}
const getSystemEventsQuery = 'allSystemEvents';
const getSystemEventsFields = ['id', 'label'];
const getSystemEvents = makeQuery({
  queryName: getSystemEventsQuery,
  queryFields: getSystemEventsFields,
  query: (): Promise<Array<SystemEvent>> => {
    return stompClient.getData(
      getSystemEventsQuery,
      sign(getSystemEventsQuery, getSystemEventsFields)
    );
  },
});

const saveMarketingCompanyQuery = 'saveMarketingCompany';
const saveMarketingCompanyFields = ['value'];
const saveMarketingCompany = makeQuery({
  permissions: Permission.SETTING_MARKETING_COMPANY,
  queryName: saveMarketingCompanyQuery,
  queryFields: saveMarketingCompanyFields,
  query: (dto): Promise<void> => {
    const typedValues = {
      ...v.marketingCompanyDTOInput({ dto }),
    };

    return stompClient.sendData(
      saveMarketingCompanyQuery,
      sign(saveMarketingCompanyQuery, saveMarketingCompanyFields),
      typedValues
    );
  },
});

const api = {
  getMarketingCompany,
  getRoles,
  getSystemEvents,
  saveMarketingCompany,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:marketing-detail');

export default api;
