import React, { useCallback } from 'react';

export const useProductDropdownModel = () => {
  const [isShown, setIsShown] = React.useState(false);
  const onWindowClicked = useCallback((event) => {
    const isItemClicked = !!event.target.closest('.tm2-symbol-item');
    const isDropdownContainerClicked = !!event.target.closest('.tm2-new-dropdown');
    const dropdownElement = document.querySelector('.dropdown-menu');
    const isDropdownOpened = document.body.contains(dropdownElement);
    const isDropdownMenuClicked = !!event.target.closest('.dropdown-menu');

    if (
      (isDropdownOpened && isDropdownContainerClicked && !isDropdownMenuClicked) ||
      isItemClicked ||
      (!isItemClicked && !isDropdownContainerClicked)
    ) {
      setIsShown(false);
    }
  }, []);

  React.useEffect(() => {
    if (isShown) {
      window.addEventListener('click', onWindowClicked);
    } else {
      window.removeEventListener('click', onWindowClicked);
    }
  }, [isShown]);

  return { setIsShown, isShown };
};
