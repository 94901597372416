import sign from 'tm2sign.macro';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { TableResponse } from '@models/core';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { SortOrder } from '@models/sorting';

export type GetBankAccountBalancesSortOrder = SortOrder<GetBankAccountBalancesSortKey>;

export type QueryBankAccountBalancesPage = TableResponse<BankAccount>;

export enum GetBankAccountBalancesSortKey {
  bankName = 'bankName',
  currency = 'currency',
  currentBalance = 'currentBalance',
}

export interface BankAccount {
  accountNumber: string;
  bankName: string;
  currency: string;
  currentBalance: number;
  externalBankAccountBalanceId: string;
  legalEntity: string;
  typeOfAccount: string;
}

const getBankAccountBalancesPageQuery = 'externalBankAccountBalancesAsPage';
const getBankAccountBalancesPageFields = [
  {
    data: [
      'accountNumber',
      'bankName',
      'currency',
      'currentBalance',
      'externalBankAccountBalanceId',
      'legalEntity',
      'typeOfAccount',
    ],
  },
  'index',
  'hasMore',
];
const getBankAccountBalancesPage = makeQuery({
  permissions: Permission.EXTERNAL_BANK_ACCOUNT_BALANCES_VIEW_REPORT,
  queryName: getBankAccountBalancesPageQuery,
  queryFields: getBankAccountBalancesPageFields,
  query: ({
    page,
    ordering,
  }: {
    page: number;
    ordering: GetBankAccountBalancesSortOrder;
  }): Promise<TableResponse<BankAccount>> => {
    const typedValues = {
      ...v.int({ page }),
      ...v.orderInput({ ordering }),
    };

    return stompClient.getData<QueryBankAccountBalancesPage>(
      getBankAccountBalancesPageQuery,
      sign(getBankAccountBalancesPageQuery, getBankAccountBalancesPageFields),
      typedValues
    );
  },
});

const api = {
  getBankAccountBalancesPage,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:bank-account-balances-page');

export default api;
