import React, { memo } from 'react';
import { Trans } from '@lingui/macro';

import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputText, InputTextarea, SubmitButton, CheckboxGroup } from '@components/form';
import { CountrySelect } from '@modules/countries';

import { Fields, formId, useModel } from './model';

type Props = {
  vaultId: number;
};
export const HandleVaultModal: MFC<Props> = memo(({ isVisible, vaultId }) => {
  const m = useModel(vaultId, HandleVaultModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(HandleVaultModal)}
    >
      <ModalTitle>
        {!vaultId ? (
          <Trans id="vault_add.title">Add vault</Trans>
        ) : (
          <Trans id="vault_add.title_edit">Edit vault</Trans>
        )}
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={m.initialValues}>
          <CountrySelect
            name={Fields.country}
            label={<Trans id="vault_add.country">Country</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.city}
            label={<Trans id="vault_add.city">City</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.warehouseCompany}
            label={<Trans id="vault_add.warehouse_company">Warehouse company</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputText
            name={Fields.address}
            label={<Trans id="vault_add.address">Address</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputTextarea
            name={Fields.contact}
            label={<Trans id="vault_add.contact">Contact</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <CheckboxGroup
            pattern={{ xs: 1, sm: 2 }}
            name={Fields.vaultManagers}
            label={<Trans id="vault_add.vault_manager">Vault manager</Trans>}
            data={m.vaultManagers}
            disabled={m.lockUI}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.save">Save</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
});
