import React, { FC, memo } from 'react';

import { Trans } from '@lingui/macro';
import { SearchSelect } from '@components/search';
import { useModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:external-bank-accounts-filter',
});

type Props = {
  className: string;
  name: string;
};
export const BankAccountsSelect: FC<Props> = memo<Props>((p) => {
  const m = useModel();

  return (
    <SearchSelect
      label={<Trans id="bank_account_history.action_row.ext_id">External bank account ID</Trans>}
      className={p.className}
      name={p.name}
      data={m.bankAccounts}
      disabled={m.lockUI}
    />
  );
});
