import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { base64StringToBlob } from 'blob-util';
import saveAs from 'file-saver';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { Box } from '@components/box';
import { ButtonPrimary } from '@components/button';
import { ButtonDownload } from '@components/button/download';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { CirclePreloader } from '@components/preloader';
import { SelectItem } from '@components/form';
import { handleBackendError } from '@modules/notify';
import { showModalAction } from '@modules/modal';
import { FiltersForm } from './filters-form';
import { FiltersModal } from './filters-modal';
import { TableFilter } from './types';
import api from './api';
import s from './index.module.scss';

type TableFiltersProps = {
  onFilterChange: (filter: TableFilter) => void;
};

export function TableFilters(props: TableFiltersProps) {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<{
    assets: SelectItem[];
    currencies: SelectItem[];
    feeTypes: SelectItem[];
  } | null>(null);

  const onDownloadClicked = async () => {
    try {
      const report: string = await api.downloadFeesReport();
      const blob = base64StringToBlob(report, 'application/xlsx');
      saveAs(blob, `fees_report.xlsx`);
    } catch (error) {
      handleBackendError(error);
    }
  };

  const lastFilterRef = useRef<TableFilter | null>(null);
  const onFilterChange = (filter: TableFilter) => {
    lastFilterRef.current = filter;
    props.onFilterChange(filter);
  };

  useEffect(() => {
    Promise.all([api.getAssets(), api.getCurrencies(), api.getFeeTypes()])
      .then(([assets, currencies, feeTypes]) => {
        setFilterData({
          assets: assets.map((asset) => ({
            label: `${asset.asset} (${asset.metal})`,
            value: asset.asset,
          })),
          currencies: currencies.map((currency) => ({
            label: currency.code,
            value: currency.id,
          })),
          feeTypes: feeTypes.map((feeType) => ({
            label: feeType,
            value: feeType,
          })),
        });
      })
      .catch((error) => {
        handleBackendError(error);
      });
  }, []);

  const openFiltersModal = () => {
    dispatch(
      showModalAction({
        modal: FiltersModal,
        props: {
          filterData,
          onFilterApply: onFilterChange,
          initialValues: lastFilterRef.current,
        },
      })
    );
  };

  return (
    <div className={s.topSection}>
      <Box className={'mb1'} compact>
        <Tooltip
          placement="bottom"
          trigger={TooltipTrigger.hover}
          title={<Trans id="fees-report.download_xls">Download fees report xls</Trans>}
        >
          <ButtonDownload onClick={onDownloadClicked} compact="all" />
        </Tooltip>
      </Box>
      <Box className={classnames('mb1', 'ml1', s.filtersPane)}>
        {!filterData ? (
          <div className={s.filterLoaderContainer}>
            <CirclePreloader contrast />
          </div>
        ) : (
          <>
            <FiltersForm
              className={s.formLg}
              filterData={filterData}
              onChange={props.onFilterChange}
            />
            <ButtonPrimary onClick={openFiltersModal} className={s.filterModalButton}>
              <Trans id="fees-report.filters.modal-button">Filters</Trans>
            </ButtonPrimary>
          </>
        )}
      </Box>
    </div>
  );
}
