import React, { FC } from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';

import { configSelectors } from '@config/core';
import { PageContent } from '@components/page';

export const NotFoundPage: FC = () => {
  const isAuthorized = useSelector(configSelectors.isAuthorized);

  return !isAuthorized ? (
    <Redirect to="/" />
  ) : (
    <PageContent uat="system-not-found-page">
      <h2>
        <Trans id="page_not_found">Page not found 404 error</Trans>
      </h2>
    </PageContent>
  );
};
