import { ExtractAction } from '@helper/ts';
import { Currency, Issuer, Role, UserProfile } from './api';

export type State = {
  currencies: Array<Currency>;
  isInitialPageDataLoading: boolean;
  issuers: Array<Issuer>;
  isUpdateProfileLoading: boolean;
  profileInitial: UserProfile;
  roles: Array<Role>;
};

export const initialState: State = {
  currencies: [],
  isInitialPageDataLoading: true,
  issuers: [],
  isUpdateProfileLoading: false,
  profileInitial: undefined,
  roles: [],
};

export type Action = ExtractAction<{
  INITIATE_PAGE_DATA: {
    currencies: Array<Currency>;
    issuers: Array<Issuer>;
    profileInitial: UserProfile;
    roles: Array<Role>;
  };
  SET_UPDATE_PROFILE_LOADING: boolean;
}>;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'INITIATE_PAGE_DATA': {
      return {
        ...state,
        currencies: action.payload.currencies,
        issuers: action.payload.issuers,
        isInitialPageDataLoading: false,
        profileInitial: action.payload.profileInitial,
        roles: action.payload.roles,
      };
    }

    case 'SET_UPDATE_PROFILE_LOADING': {
      return {
        ...state,
        isUpdateProfileLoading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
