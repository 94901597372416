import { ReactNode } from 'react';

import { Any } from '@models/core';
import { MFC } from '@modules/modal';
import { DispatchThunkFn, GetStateFn } from '@models/redux';
import { TitleIconType } from '../base/title/icon';
import { ModalServiceActionsTypes } from './service.acions';
import { ModalUserActionsTypes } from './user.acions';

export interface ConfirmModalTexts {
  cancelButton?: ReactNode;
  confirmButton?: ReactNode;
  description?: ReactNode;
  title?: ReactNode;
}

export interface OnConfirmAction {
  action: (dispatch?: DispatchThunkFn, getState?: GetStateFn) => Any;
  error?: (error?: Error, dispatch?: DispatchThunkFn, getState?: GetStateFn) => Any;
  finally?: (dispatch?: DispatchThunkFn, getState?: GetStateFn) => Any;
}

export interface ConfirmModalProps {
  text: ConfirmModalTexts;
  onConfirm: OnConfirmAction;
}

export interface NotifyModalProps {
  description: ReactNode | Array<ReactNode>;
  isClosePrevious?: boolean; // need for animated modals switch
  okText?: ReactNode;
  onClose?: Function;
  type: TitleIconType;
  title: ReactNode;
}

export type ModalInfo<T = object> = {
  isClosePrevious?: boolean; // need for animated modals switch
  isVisible?: boolean;
  modal: MFC<T>;
  props?: T;
};

export interface ModalState {
  modals: Array<ModalInfo>;
}

export const modalStateInitial: ModalState = {
  modals: [],
};

export enum ModalActionsTypes {
  CLOSE_LATEST_MODAL = 'SHARED_MODAL.CLOSE_LATEST_MODAL',
  CLOSE_MODAL = 'SHARED_MODAL.CLOSE_MODAL',
  ON_CLOSE_LATEST_MODAL = 'SHARED_MODAL.ON_CLOSE_LATEST_MODAL',
  ON_CLOSE_MODAL_CLICKED = 'SHARED_MODAL.ON_CLOSE_MODAL_CLICKED',
  ON_SHOW_CONFIRMATION_MODAL_CLICKED = 'SHARED_MODAL.ON_SHOW_CONFIRMATION_MODAL_CLICKED',
  ON_SHOW_MODAL_CLICKED = 'SHARED_MODAL.ON_SHOW_MODAL_CLICKED',
  ON_SHOW_NOTIFY_MODAL_CLICKED = 'SHARED_MODAL.ON_SHOW_NOTIFY_MODAL_CLICKED',
  SHOW_CONFIRMATION_MODAL = 'SHARED_MODAL.SHOW_CONFIRMATION_MODAL',
  SHOW_MODAL = 'SHARED_MODAL.SHOW_MODAL',
  SHOW_NOTIFICATION_MODAL = 'SHARED_MODAL.SHOW_NOTIFICATION_MODAL',
  SHOW_PREVIOUS_HIDDEN_MODAL = 'SHARED_MODAL.SHOW_PREVIOUS_HIDDEN_MODAL',
}

export type ModalActions = ModalServiceActionsTypes | ModalUserActionsTypes;
