import classnames from 'classnames';
import { TableHeaderSortType } from 'components/page-table';
import { SortDirection } from './sorting';
import s from './index.module.scss';

export type Column<T> = {
  cell: {
    body: (rowData: T) => JSX.Element;
    className?: string;
  };
  head: {
    body: JSX.Element;
    className?: string;
    onSort: (direction: TableHeaderSortType) => void;
    sortDirection: TableHeaderSortType;
  };
};

export type OnSort<T> = (sortBy: T, direction?: SortDirection) => void;

function callSort<T>(sortDirection: TableHeaderSortType, sortBy: T, onSort: OnSort<T>) {
  if (sortDirection === TableHeaderSortType.asc) {
    onSort(sortBy, 'ascending');
    return;
  }
  if (sortDirection === TableHeaderSortType.desc) {
    onSort(sortBy, 'descending');
    return;
  }
  onSort(sortBy);
}

const cellClasses = s.tableBodyCell;
const headClasses = s.tableHeadCell;

export function columnFactory<T>({
  cell,
  field,
  head,
  onSort,
  sortDirection,
  className,
}: {
  cell: (rowData: T) => string | number | JSX.Element;
  field: keyof T;
  head: JSX.Element;
  onSort: OnSort<keyof T>;
  sortDirection?: TableHeaderSortType;
  className?: string;
}): Column<T> {
  return {
    cell: {
      body: (rowData: T) => <span>{cell(rowData)}</span>,
      className: cellClasses,
    },
    head: {
      body: <span>{head}</span>,
      className: classnames(headClasses, className),
      onSort: (sortDirection: TableHeaderSortType) => {
        callSort<keyof T>(sortDirection, field, onSort);
      },
      sortDirection,
    },
  };
}
