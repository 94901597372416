enum Compare {
  equal = '==',
  notEqual = '!=',
  greaterThan = '=gt=',
  greaterThanOrEqual = '=ge=',
  lessThan = '=lt=',
  lessThanOrEqual = '=le=',
  in = '=in=',
  notIn = '=out=',
  isNull = '=na=',
  notNull = '=nn=',
  like = '=ke=',
  notLike = '=nk=',
  ignoreCase = '=ic=',
  ignoreCaseLike = '=ilike=',
  ignoreCaseNotLike = '=ni=',
  between = '=bt=',
  notBetween = '=nb=',
}

const CaseMode = {
  insensitive: 'insensitive',
  sensitive: 'sensitive',
} as const;

export type Operand = {
  arguments: string | number;
  operator: Compare;
  // note: ниже сразу собранная строка, которая игнорирует все остальные поля, пока нужно только для dateRange
  rsql?: string;
  selector: string;
};

const MatchMode = {
  partial: 'partial',
  strict: 'strict',
} as const;

type BuilderConfig = {
  /**
   * Принимаем на вход массим массивов.
   * Это позволяет разделить поиск по разным полям формы и группировать их в AND
   * Пока нет предпосылок, что потребуется бОльшый уровень вложенности и гибкости
   * Группировка на массив массивов происходит в search/model.ts из fields по полю searchFormField
   */
  operands: Array<Array<Operand>>;
};

const filtrify = (config: BuilderConfig): string => {
  return config.operands
    .map((operands) =>
      operands
        .reduce((acc, _operand) => {
          const o: Operand = _operand as Operand;
          return acc + (o.rsql ? `${o.rsql},` : `${o.selector}${o.operator}'${o.arguments}',`);
        }, '')
        .slice(0, -1)
    )
    .join(';');
};

export default {
  CaseMode,
  Compare,
  filtrify,
  MatchMode,
};
