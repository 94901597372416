import React from 'react';
import { Trans } from '@lingui/macro';

import { TariffsIds as Tariffs } from '@config/core';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputDate, InputHidden, Radio, SubmitButton } from '@components/form';
import { Fields, formId, useModel } from './model';

export const EditTariffModal: MFC<{
  onTariffUpdated: () => void;
  userId: number;
}> = ({ isVisible, onTariffUpdated, userId }) => {
  const {
    formInitial,
    getTariffName,
    lockUI,
    minDate,
    onCloseModalClicked,
    onFormAction,
    onTariffChanged,
    tariffs,
  } = useModel(EditTariffModal, userId, onTariffUpdated);

  return (
    <Modal isVisible={isVisible} loading={lockUI} disabled={lockUI} onClose={onCloseModalClicked}>
      <ModalTitle>
        <Trans id="edit_tariff_modal.title">Upgrade users plan</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={formInitial}>
          <InputHidden name={Fields.userId} type="number" />

          <Radio
            name={Fields.tariffId}
            label={undefined}
            deprecatedStyles={false}
            onChange={onTariffChanged}
            data={tariffs.map((tariff) => ({
              extraContent:
                tariff.id === Tariffs.noMembership ? null : (
                  <InputDate
                    name={Fields.endDate}
                    label={<Trans id="edit_tariff_modal.label.duration">End date</Trans>}
                    min={minDate}
                    allowClear={false}
                    compact
                    required
                  />
                ),
              label: getTariffName(tariff),
              value: tariff.id,
            }))}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={lockUI} onAction={onFormAction}>
          <Trans id="edit_tariff_modal.button.submit">Ok</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
