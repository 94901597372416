import decode from 'jwt-decode';
import { Subject, Subscription } from 'rxjs';

import { KKLang, Lang } from '@models/core';
import { Role } from '@permissions/core';
import { UserInfo } from '@config/core';

export enum SessionStatus {
  bad = 'bad',
  changed = 'changed',
  expired = 'expired',
  good = 'good',
}

class SessionService {
  private _langEmitter = new Subject<Lang>();

  public get buildVersion(): string {
    return localStorage.getItem('tm2:app.build-version');
  }

  public getAccessToken(): string {
    return localStorage.getItem('tm2:session.access-token');
  }

  public getCurrentLanguage(): Lang {
    return (localStorage.getItem('tm2:session.current-language') as Lang) || Lang.en;
  }

  public getKKLanguage(): KKLang {
    // language for keycloak authorization (KK is different independent app)
    const language = this.getCurrentLanguage();
    const adapter = {
      CN: 'zh-CN',
      EN: 'en',
    };
    return adapter[language] as KKLang;
  }

  public getExpandedMenuIds() {
    const id = this.userId();
    const listStr = localStorage.getItem(`tm2:session.${id}.expanded-menu-ids`);
    return listStr && JSON.parse(listStr);
  }

  public getRefreshToken(): string {
    return localStorage.getItem('tm2:session.refresh-token');
  }

  public getUser(): UserInfo {
    const user: string = localStorage.getItem('tm2:session.user');
    return user ? JSON.parse(user) : null;
  }

  public get isFromRedirect(): boolean {
    const isFromRedirect = !!localStorage.getItem('tm2:session.is-from-redirect-flag');
    localStorage.removeItem('tm2:session.is-from-redirect-flag');
    return isFromRedirect;
  }

  public get isShowLogs(): boolean {
    const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const isLocal = window.location.hostname === 'localhost';
    return isDev || isLocal || this.isUat;
  }

  public isUat: boolean = !!document.cookie
    .replace(/\s/g, '')
    .split(';')
    .map((str) => str.split('='))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {} as { [k: string]: string })?.uat;

  public get latestVisitedRoute(): string {
    const authorizedRoute: string = localStorage.getItem(
      `tm2:session.${this.userId()}.latest-visited-route`
    );
    const unauthorizedRoute: string = localStorage.getItem(
      `tm2:session.unauthorized.latest-visited-route`
    );
    localStorage.removeItem(`tm2:session.${this.userId()}.latest-visited-route`);
    localStorage.removeItem('tm2:session.unauthorized.latest-visited-route');
    if (authorizedRoute || unauthorizedRoute) {
      localStorage.setItem('tm2:session.is-from-redirect-flag', 'true');
    }
    return authorizedRoute || unauthorizedRoute;
  }

  public logout(): void {
    const id: string = this.userId() + '';
    localStorage.removeItem('tm2:session.access-token');
    localStorage.removeItem('tm2:session.refresh-token');
    localStorage.removeItem('tm2:session.user');
    localStorage.removeItem('tm2:session.current-user-id');

    localStorage.removeItem(`tm2:session.${id}.latest-visited-route`);
    localStorage.removeItem(`tm2:session.unauthorized.latest-visited-route`);
    localStorage.removeItem('tm2:session.is-from-redirect-flag');
  }

  public onLangChanged(listener: (lang: Lang) => void): Subscription {
    return this._langEmitter.subscribe(listener);
  }

  public openIdInfo(): OpenIdInfo {
    const tokenInfo: TokenInfo = decode(this.getAccessToken());
    const issUrl = new URL(tokenInfo.iss);
    return {
      iframeSrc: tokenInfo.iss + '/protocol/openid-connect/login-status-iframe.html',
      message: tokenInfo.azp + ' ' + tokenInfo.session_state,
      originTarget: issUrl.origin,
    };
  }

  public saveAccessToken(token: string): string {
    localStorage.setItem('tm2:session.access-token', token);
    const tokenInfo: TokenInfo = token && decode(token);
    const id: number = +tokenInfo?.tm2_profile?.id;
    this._saveSessionId(tokenInfo?.session_state);
    if (id) {
      localStorage.setItem('tm2:session.current-user-id', id + '');
    }
    return token;
  }

  public saveBuildVersion(version: string): string {
    localStorage.setItem('tm2:app.build-version', version);
    return version;
  }

  public saveCurrentLanguage(language): void {
    const adapter = {
      CN: 'CN',
      default: 'EN',
      EN: 'EN',
      en: 'EN',
      'zh-CN': 'CN',
    };
    localStorage.setItem('tm2:session.current-language', adapter[language] || adapter.default);
    this._langEmitter.next(adapter[language] || adapter.default);
  }

  public saveLatestVisitedRoute(latestRoute): void {
    const id: string = this.userId() ? this.userId() + '' : 'unauthorized';
    localStorage.setItem(`tm2:session.${id}.latest-visited-route`, latestRoute);
  }

  public saveRefreshToken(token: string): void {
    localStorage.setItem('tm2:session.refresh-token', token);
  }

  public saveUser(user: UserInfo): void {
    localStorage.setItem('tm2:session.user', JSON.stringify(user));
  }

  public get sessionId(): string {
    return localStorage.getItem('tm2:session.current-session-id');
  }

  public sessionStatus(): SessionStatus {
    try {
      if (this._isAccessTokenExpired()) {
        if (this._isRefreshTokenExpired()) {
          return SessionStatus.bad;
        }
        return SessionStatus.expired;
      }
      return SessionStatus.good;
    } catch (error) {
      return SessionStatus.bad;
    }
  }

  public setExpandedMenuIds(list): void {
    const id = this.userId();
    localStorage.setItem(`tm2:session.${id}.expanded-menu-ids`, JSON.stringify(list));
  }

  public userId(): number {
    const id = localStorage.getItem('tm2:session.current-user-id');
    return id ? +id : null;
  }

  private _isAccessTokenExpired(): boolean {
    const token = this.getAccessToken();
    return !token || this._isTokenExpired(token);
  }

  private _isRefreshTokenExpired(): boolean {
    const token = this.getRefreshToken();
    return !token || this._isTokenExpired(token);
  }

  private _isTokenExpired(token): boolean {
    const tokenInfo: TokenInfo = decode(token);
    const expirationTime = new Date(tokenInfo.exp * 1000).getTime();
    const currentTime = new Date().getTime();
    return expirationTime - currentTime < 30000; // if token lifetime is less than 30 sec - it expired
  }

  private _saveSessionId(id: string): void {
    // мы не должны чистить это поле, а только перезаписывать,
    // т.к. localStorage общий и когда на одной вкладке разлогинены,
    // на второй уже не будет этого значения, причем не всегда (race condition)
    localStorage.setItem('tm2:session.current-session-id', id);
  }
}

export const session = new SessionService();

export interface TokenInfo {
  azp: string;
  exp: number;
  iss: string;
  session_state: string;
  tm2_profile: {
    id: number;
    role: Role;
  };
}

export interface OpenIdInfo {
  iframeSrc: string;
  message: string;
  originTarget: string;
}
