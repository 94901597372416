import { useCallback, useEffect, useState, useMemo } from 'react';
import { mergeBalances } from '@helper/balance';
import { useLiveUpdate, LiveUpdateEvent, LiveUpdateEventType } from '@services/stomp/live-update';
import { Awaited } from '../shared/utils';
import { addSortItem, sortItems } from '../shared/table/sorting';
import { useColumns, TableCoin, SortItem, SortOrder, OnSort } from './use-columns';
import api from './api';
import { redeemCreationEventService } from '../modals/redeem/redeem-creation-event-service';

export function useWalletPrimaryModel() {
  const [walletData, setWalletData] = useState<Awaited<ReturnType<typeof api.getBalance>> | null>(
    null
  );

  const updateWalletDataRedeemInfo = useCallback((asset: string, hasActiveRedeem: boolean) => {
    const updateCoinBalances = <
      T extends typeof walletData[
        | 'primaryActiveCoinBalances'
        | 'primaryHoldCoinBalances'
        | 'secondaryActiveCoinBalances'
        | 'secondaryHoldCoinBalances'][number]
    >(
      balances: T[],
      asset: string,
      hasActiveRedeem: boolean
    ): T[] => {
      return balances.map((b) => {
        if (b.asset !== asset) {
          return b;
        }
        return { ...b, data: { ...b.data, hasActiveRedeem } };
      });
    };

    setWalletData((currentData) => {
      if (!currentData) {
        return currentData;
      }
      const {
        primaryActiveCoinBalances,
        primaryHoldCoinBalances,
        secondaryActiveCoinBalances,
        secondaryHoldCoinBalances,
        ...walletData
      } = currentData;

      return {
        ...walletData,
        primaryActiveCoinBalances: updateCoinBalances(
          primaryActiveCoinBalances,
          asset,
          hasActiveRedeem
        ),
        primaryHoldCoinBalances: updateCoinBalances(
          primaryHoldCoinBalances,
          asset,
          hasActiveRedeem
        ),
        secondaryActiveCoinBalances: updateCoinBalances(
          secondaryActiveCoinBalances,
          asset,
          hasActiveRedeem
        ),
        secondaryHoldCoinBalances: updateCoinBalances(
          secondaryHoldCoinBalances,
          asset,
          hasActiveRedeem
        ),
      };
    });
  }, []);

  useEffect(() => {
    api.getBalance().then((data) => {
      setWalletData(data);
    });
  }, []);

  useLiveUpdate((event: LiveUpdateEvent) => {
    if (!walletData) return;

    if (event.type === LiveUpdateEventType.stableBalanceChanged) {
      api
        .getStableBalance()
        .then(
          ({
            primaryActiveStableBalances,
            primaryHoldStableBalances,
            secondaryActiveStableBalances,
            secondaryHoldStableBalances,
          }) => {
            setWalletData((currentData) => {
              return {
                ...currentData,
                primaryActiveStableBalances,
                primaryHoldStableBalances,
                secondaryActiveStableBalances,
                secondaryHoldStableBalances,
              };
            });
          }
        );
    }

    if (event.type === LiveUpdateEventType.coinBalanceChanged) {
      api
        .getCoinBalance()
        .then(
          ({
            primaryActiveCoinBalances,
            primaryHoldCoinBalances,
            secondaryActiveCoinBalances,
            secondaryHoldCoinBalances,
          }) => {
            setWalletData((currentData) => {
              return {
                ...currentData,
                primaryActiveCoinBalances,
                primaryHoldCoinBalances,
                secondaryActiveCoinBalances,
                secondaryHoldCoinBalances,
              };
            });
          }
        );
    }
  });

  useEffect(() => {
    return redeemCreationEventService.subscribe((asset: string) => {
      updateWalletDataRedeemInfo(asset, true);
    });
  }, []);

  const balancePane = useMemo(() => {
    if (!walletData)
      return {
        primaryStableBalances: [],
        secondaryStableBalances: [],
        pendingBalances: [],
      };
    const primaryStableBalancesOverdraft = walletData.primaryActiveStableBalances.reduce<
      Record<string, number | undefined>
    >((acc, b) => {
      acc[b.asset] = b.data.overdraft;
      return acc;
    }, {});
    const primaryStableBalances = mergeBalances(
      walletData.primaryActiveStableBalances,
      walletData.primaryHoldStableBalances
    ).map((b) => ({
      ...b,
      amount: b.amount - (primaryStableBalancesOverdraft[b.asset] ?? 0),
    }));
    const secondaryStableBalances = mergeBalances(
      walletData.secondaryActiveStableBalances,
      walletData.secondaryHoldStableBalances
    );
    const pendingBalances = [
      ...walletData.primaryHoldCoinBalances,
      ...walletData.primaryHoldStableBalances,
    ];
    return { primaryStableBalances, secondaryStableBalances, pendingBalances };
  }, [walletData]);

  const [sortOrder, setSortOrder] = useState<SortOrder>([]);

  const onCoinTableSort = useCallback<OnSort>((field, direction) => {
    setSortOrder((currentOrder) => {
      const sortItem: SortItem = { field, direction };
      return addSortItem(currentOrder, sortItem);
    });
  }, []);

  const coinTableData = useMemo(() => {
    if (!walletData) return [];

    function normalizeCoinBalance(
      balance: typeof walletData[
        | 'primaryActiveCoinBalances'
        | 'primaryHoldCoinBalances'
        | 'secondaryActiveCoinBalances'
        | 'secondaryHoldCoinBalances'][number]
    ) {
      return {
        id: balance.data.id,
        amount: balance.amount,
        asset: balance.asset,
        brandName: balance.data.brandName,
        metal: balance.data.metal,
        hasActiveRedeem: balance.data.hasActiveRedeem,
        physicalRedemption: balance.data.physicalRedemption,
      };
    }

    const primaryCoinBalances = mergeBalances(
      walletData.primaryActiveCoinBalances,
      walletData.primaryHoldCoinBalances
    )
      .map(normalizeCoinBalance)
      .map((b) => ({
        ...b,
        amountPrimary: b.amount,
        amountSecondary: null,
      }));

    const secondaryCoinBalances = mergeBalances(
      walletData.secondaryActiveCoinBalances,
      walletData.secondaryHoldCoinBalances
    )
      .map(normalizeCoinBalance)
      .map((b) => ({
        ...b,
        amountPrimary: null,
        amountSecondary: b.amount,
      }));

    const coinBalancesMap = primaryCoinBalances
      .concat(secondaryCoinBalances)
      .reduce<Record<string, TableCoin>>((acc, b) => {
        if (acc[b.asset]) {
          const amountPrimary = acc[b.asset].amountPrimary ?? b.amountSecondary;
          const amountSecondary = acc[b.asset].amountSecondary ?? b.amountSecondary;

          acc[b.asset] = { ...b, amountPrimary, amountSecondary };

          return acc;
        }
        acc[b.asset] = b;
        return acc;
      }, {});
    const coinBalances: TableCoin[] = Object.values(coinBalancesMap);

    return sortItems(coinBalances, sortOrder);
  }, [
    walletData?.primaryActiveCoinBalances,
    walletData?.primaryHoldCoinBalances,
    walletData?.secondaryActiveCoinBalances,
    walletData?.secondaryHoldCoinBalances,
    sortOrder,
  ]);

  const coinTableColumns = useColumns(coinTableData, onCoinTableSort);

  return {
    balancePane,
    coinTableColumns,
    coinTableData,
    loading: walletData === null,
  };
}
