import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

export enum Fields {
  metalLabel = 'label',
  metalList = 'metalIds',
}

export interface Form {
  [Fields.metalList]: Array<Metal>;
}

export interface Metal {
  id?: number;
  haveCoins?: boolean;
  label: string;
}
const getMetalsQuery = 'metals';
const getMetalsFields = ['id', 'haveCoins', 'label'];
const getMetals = makeQuery({
  queryName: getMetalsQuery,
  queryFields: getMetalsFields,
  query: (): Promise<Array<Metal>> => {
    return stompClient.getData(getMetalsQuery, sign(getMetalsQuery, getMetalsFields));
  },
});

/**
 * NOTE: save metals on backend logic:
 *       - all metals list should be always send to backend
 *       - if metal item has no id - new metal will be created
 *       - if existed metal item will be not present in sent metals list, it will be
 *         deleted if possible (has no wallet connections)
 *       - if metal item has id and changed name - then metal name will be changed on backend
 */
const updateMetalsQuery = 'updateMetals';
const updateMetalsFields = ['id', 'label'];
const updateMetals = makeQuery({
  queryName: updateMetalsQuery,
  queryFields: updateMetalsFields,
  query: (form: Form): Promise<Array<Metal>> => {
    const typedValues = {
      ...v.metalInput({ metals: form.metalIds }),
    };

    return stompClient.sendData(
      updateMetalsQuery,
      sign(updateMetalsQuery, updateMetalsFields),
      typedValues
    );
  },
});

const api = {
  getMetals,
  updateMetals,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:edit-metals-modal');

export default api;
