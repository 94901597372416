import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { ApproveRequestModal } from '../modals/approve-redeem';
import { BurnRedeemModal } from '../modals/burn-redeem';
import { CancelRedeemModal } from '../modals/cancel-redeem';
import { DenyRequestModal } from '../modals/deny-redeem';

export const useModel = (redeem, onReplaceRedemption) => {
  const onShowModal = useCallDispatch(showModalAction);

  const showApproveRedeemModal = () =>
    onShowModal({
      modal: ApproveRequestModal,
      props: { onApproved: onReplaceRedemption, redeemId: redeem.id },
    });

  const showBurnRedeemModal = () =>
    onShowModal({
      modal: BurnRedeemModal,
      props: { onBurned: onReplaceRedemption, redeem },
    });

  const showCancelRedeemModal = () =>
    onShowModal({
      modal: CancelRedeemModal,
      props: { onCancelled: onReplaceRedemption, redeem },
    });

  const showDenyRedeemModal = () =>
    onShowModal({
      modal: DenyRequestModal,
      props: { onDenied: onReplaceRedemption, redeemId: redeem.id },
    });

  return {
    showApproveRedeemModal,
    showBurnRedeemModal,
    showCancelRedeemModal,
    showDenyRedeemModal,
  };
};
