import React, { FC, memo, ReactNode, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import s from './index.module.scss';
import { Popup, PopupProps } from '@components/popup';
import { MoreButtonIcon } from '@components/icons';
import { IsHasAccess, permissionsSelectors, Role } from '@permissions/core';
import { useDispatch, useSelector } from 'react-redux';
import { DispatchFn, DispatchThunkFn, GetStateFn } from '@models/redux';
import { AllowedPermissions } from '@permissions/access.service';
import { configSelectors, UserInfo } from '@config/core';

function noop() {}

export const MoreActions: FC<MoreActionsProps> = memo(
  ({ disabled, list, placement, triggerClassName }) => {
    const dispatch: DispatchFn = useDispatch();
    const isHasAccess: IsHasAccess = useSelector(permissionsSelectors.isHasAccess);
    const user: UserInfo = useSelector(configSelectors.userInfo);

    const actionsList: Array<ActionItem> = list.filter(
      (action) =>
        !!action &&
        (!action.access ||
          isHasAccess(
            action.access.permissions,
            action.access.extraCheck === undefined ? null : action.access.extraCheck(user),
            action.access.forcedAccess && action.access.forcedAccess(user),
            action.access.roles
          ))
    );

    const [emitClose, setEmitClose] = useState<Function>(() => noop);

    return !actionsList.length ? null : (
      <Popup
        placement={placement}
        setEmitClose={setEmitClose}
        content={actionsList.map((item, key: number) => (
          <div
            className={classnames(s.wrapper, item.disabled && item.disabled(user) && 'is-disabled')}
            key={key}
          >
            {item.link ? (
              <Link className={s.item} to={item.link}>
                {item.content}
              </Link>
            ) : (
              <div
                className={s.item}
                onClick={() =>
                  dispatch((dispatch: DispatchThunkFn, getState: GetStateFn) => {
                    emitClose();
                    return item.action(dispatch, getState);
                  })
                }
              >
                {item.content}
              </div>
            )}
          </div>
        ))}
      >
        <MoreButtonIcon className={triggerClassName} disabled={disabled} />
      </Popup>
    );
  }
);

export interface ActionItem {
  access?: {
    extraCheck?: (user: UserInfo) => boolean;
    forcedAccess?: (user: UserInfo) => boolean;
    permissions?: AllowedPermissions;
    roles?: Array<Role>;
  };
  action?: (dispatch: DispatchThunkFn, getState: GetStateFn) => void;
  content: ReactNode;
  link?: string;
  disabled?: (user: UserInfo) => boolean;
}

export interface MoreActionsProps {
  triggerClassName?: string;
  disabled?: boolean;
  list: Array<ActionItem>;
  placement: PopupProps['placement'];
}
