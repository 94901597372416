import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { configSelectors } from '@config/core';
import { HelpInfo } from '@components/help-info';
import { MoreActions } from '@components/more-actions';
import { TableCoin } from './use-columns';
import { redeemModalUserActions } from '../modals/redeem/store/user.actions';
import { permissionGroup as redeemPermissionGroup } from '../modals/redeem';
import { showModalAction } from '@modules/modal';
import { CompleteVerificationModal } from '../modals/complete-verification';

export const permissionGroup = redeemPermissionGroup;

type BlockedCoin = { id: number };

type Props = {
  isVerificationFinished: boolean;
  redeemCoin: TableCoin;
  blockedCoins: BlockedCoin[];
  onRedeemClicked: (redeemCoin: TableCoin) => void;
};

const mapStateToProps = (root) => ({
  blockedCoins: configSelectors.blockedCoins(root),
  isVerificationFinished: configSelectors.isVerificationFinished(root),
});

const mapDispatchToProps = (dispatch) => ({
  onRedeemClicked: (redeemCoin: TableCoin) => {
    // Support existing structure to avoid changes.
    const balance = {
      balance: redeemCoin.amountPrimary,
      asset: redeemCoin.asset,
      coin: {
        hasActiveRedeem: redeemCoin.hasActiveRedeem,
        id: redeemCoin.id,
        asset: redeemCoin.asset,
        metal: {
          label: redeemCoin.metal,
        },
        brandName: redeemCoin.brandName,
        coinInfo: {
          physicalRedemption: redeemCoin.physicalRedemption,
        },
      },
    };
    dispatch(redeemModalUserActions.onRedeemClicked(balance));
  },
});

const ConnectedRedeemCell: FC<Props> = ({
  isVerificationFinished,
  redeemCoin,
  blockedCoins,
  onRedeemClicked,
}) => {
  const isCoinBlocked = blockedCoins.find((blocked) => redeemCoin.id === blocked.id);

  const isRedeemDisabled =
    !redeemCoin.physicalRedemption || redeemCoin.hasActiveRedeem || redeemCoin.amountPrimary <= 0;

  if (isCoinBlocked) {
    return (
      <HelpInfo
        placement="bottomLeft"
        content={
          <Trans id="wallet.prohibited">
            The issuer prohibited you from trading the digital metal. Please contact TM2
            representative
          </Trans>
        }
      />
    );
  }

  return (
    <MoreActions
      placement="left"
      list={[
        {
          action: (dispatch) => {
            if (isVerificationFinished) {
              onRedeemClicked(redeemCoin);
            } else {
              dispatch(showModalAction({ modal: CompleteVerificationModal }));
            }
          },
          content: <Trans id="wallet.redeem">Redeem</Trans>,
          disabled: () => isRedeemDisabled,
        },
      ]}
    />
  );
};

export const RedeemCell = connect(mapStateToProps, mapDispatchToProps)(ConnectedRedeemCell);
