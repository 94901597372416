import { NewTabs } from 'components/new-tabs';
import { Role } from '@permissions/core';
import IssuerBalance from '../issuer-balance';
import { DailyBalanceHistory } from '../statements/daily-balance-history';
import React from 'react';
import { useCurrentRole } from '@config/hooks';
import { PageContent } from '@components/page';
import { Trans } from '@lingui/macro';

const IssuerStatementsPage = () => {
  const currentRole = useCurrentRole();

  return (
    <PageContent uat="issuer-statements-page">
      <NewTabs
        tabs={[
          ...(currentRole === Role.issuer
            ? [
                {
                  title: <Trans id="balance_page.tab.daily">Daily balance history</Trans>,
                  name: 'history',
                  content: () => <DailyBalanceHistory />,
                },
              ]
            : []),
          ...(currentRole === Role.issuer
            ? [
                {
                  title: <Trans id="balance_page.tab.report">Balance report</Trans>,
                  name: 'balanceReport',
                  content: () => <IssuerBalance />,
                },
              ]
            : []),
        ]}
      />
    </PageContent>
  );
};

export default IssuerStatementsPage;
