import { useEffect, useRef, useState } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useSyncRef } from '../utils';
import { areOrdersEqual, areFieldsEqual } from './sorting';

// NOTE: This hook can be used in controlled or uncontrolled manner depending
// on passing order param. If the order is passed, it will be used as a
// controlled value. If not, initialOrder will be used to define initial order.

// NOTE: This hook does not support dynamic switch between
// controlled/uncontrolled variants.

// NOTE: This hook does not support dynamic orderMerger change.

export type SortDirection = 'ascend' | 'descend' | null;

export type SortItem = Readonly<{
  field: string | number | readonly (string | number)[];
  direction: SortDirection;
  key?: string | number;
}>;

export type SortOrder = SortItem[];

export type OnSortItemChange = (params: {
  currentSortOrder: SortOrder;
  nextSortOrder: SortOrder;
  sortItem: SortItem;
}) => void;

export type SortOrderMerger = (currentOrder: SortOrder, nextItem: SortItem) => SortOrder;

export type OnSortOrderChange = (order: SortOrder) => void;

export type TableSorterParams = {
  // Only works in uncontrolled mode.
  initialOrder?: SortOrder;

  onAfterOrderChange?: OnSortOrderChange;
  onSortItemChange?: OnSortItemChange;
  order?: SortOrder;
  orderMerger?: SortOrderMerger;
};

export type MergeOrderItem = (sortItem: SortItem) => void;

export type TableSorterReturnType = {
  mergeOrderItem: MergeOrderItem;
  order: SortOrder;
  resetUncontrolledOrder: () => void;
};

export function defaultOrderMerger(currentOrder: SortOrder, nextItem: SortItem): SortOrder {
  const nextOrder = currentOrder.filter(({ field }) => !areFieldsEqual(field, nextItem.field));
  if (nextItem.direction !== null) {
    nextOrder.unshift(nextItem);
  }
  return nextOrder;
}

export const useTableSorter = (params: TableSorterParams = {}) => {
  const [internalSortOrder, setInternalSortOrder] = useState<SortOrder>(params.initialOrder ?? []);

  const sortOrderRef = useSyncRef(params.order ?? internalSortOrder);
  const isControlledRef = useRef(Boolean(params.order));

  const mergeOrderItem = useCallbackOne<MergeOrderItem>(
    (sortItem) => {
      const orderMerger = params.orderMerger ?? defaultOrderMerger;
      const nextOrder = orderMerger(sortOrderRef.current, sortItem);

      params.onSortItemChange?.({
        currentSortOrder: sortOrderRef.current,
        nextSortOrder: nextOrder,
        sortItem,
      });
      if (!isControlledRef.current) {
        setInternalSortOrder((currentOrder) => {
          return areOrdersEqual(currentOrder, nextOrder) ? currentOrder : nextOrder;
        });
      }
    },
    [params.onSortItemChange]
  );

  const resetUncontrolledOrder = useCallbackOne(() => {
    setInternalSortOrder(params.initialOrder ?? []);
  }, []);

  const resultSortOrder = params.order ?? internalSortOrder;

  const onAfterChangeInitialEffect = useRef(true);
  useEffect(() => {
    if (onAfterChangeInitialEffect.current) {
      onAfterChangeInitialEffect.current = false;
      return;
    }
    params.onAfterOrderChange?.(resultSortOrder);
  }, [resultSortOrder]);

  return useMemoOne<TableSorterReturnType>(
    () => ({
      mergeOrderItem,
      order: resultSortOrder,
      resetUncontrolledOrder,
    }),
    [resultSortOrder]
  );
};
