import { all, put, select, takeEvery } from 'redux-saga/effects';

import { Put } from '@helper/redux';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { permissionsSelectors, Permission as P } from '@permissions/core';
import { BankAccount, depositsApiService as depositsApi } from '../../deposits-api.service';
import { wireTransferViewServiceActions as wireServiceActions } from '../wire-transfer/store/service.acions';
import { creditCardViewServiceActions as cardServiceActions } from '../credit-card/store/service.acions';
import { AddFundsModal } from '../index';
import { AddFundsModalActionsTypes as FundsTypes } from './model';
import { addFundsModalServiceActions as fundsServiceActions } from './service.acions';

export function* addFundsModalEffects() {
  yield all([
    takeEvery(FundsTypes.GET_BANK_ACCOUNTS, getCurrentUserBankAccounts),
    takeEvery(FundsTypes.ON_CLOSE_MODAL_CLICKED, Put(closeModalAction, AddFundsModal)),
    takeEvery(FundsTypes.ON_MODAL_OPENED, initiateModalData),
  ]);
}

function* getCurrentUserBankAccounts() {
  try {
    const list: Array<BankAccount> = yield depositsApi.getBankAccounts();
    yield put(fundsServiceActions.getBankAccountsSuccess(list));
    yield put(wireServiceActions.setBankAccounts(list));
    yield put(cardServiceActions.setBankAccounts(list));
  } catch (error) {
    yield put(fundsServiceActions.getBankAccountsFail(error as Error));
    handleBackendError(error);
  }
}

function* initiateModalData() {
  const hasAccessFn = yield select(permissionsSelectors.isHasAccess);
  if (!hasAccessFn({ or: [P.INVOICE_CREATE_EXT] })) {
    yield put(fundsServiceActions.getBankAccounts());
  }
}
