import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { TabsHeaderData } from '@components/tabs/regular';
import { TabShared, useTabChanges } from '../../hooks/tab';
import { useCheckShowBankAccountsTab } from './bank-accounts';

export const Tab = {
  ...TabShared,
  // preferences: '#preferences',
  security: '#security',
};

export const useModel = () => {
  const { i18n, lang } = useI18n();
  const isShowBankTab = useCheckShowBankAccountsTab();
  const { activeTab, onTabChange } = useTabChanges(Tab as typeof TabShared);

  const headerData = useMemo(() => {
    const tabs: { [k in keyof typeof Tab]: TabsHeaderData[0] } = {
      accountDetails: {
        data: i18n._(
          defineMessage({ id: 'profile.tab.account_details', message: 'Account details' })
        ),
        tabKey: Tab.accountDetails,
      },
      bankDetails: {
        data: i18n._(defineMessage({ id: 'profile.tab.bank_details', message: 'Bank details' })),
        tabKey: Tab.bankDetails,
      },
      /*preferences: {
                data: i18n._(defineMessage({id: 'profile.tab.preferences', message: 'Preferences'})),
                tabKey: Tab.preferences,
            },*/
      security: {
        data: i18n._(defineMessage({ id: 'profile.tab.security', message: 'Security' })),
        tabKey: Tab.security,
      },
    };
    return isShowBankTab
      ? [tabs.accountDetails, tabs.bankDetails, /*tabs.preferences,*/ tabs.security]
      : [tabs.accountDetails, /*tabs.preferences,*/ tabs.security];
  }, [isShowBankTab, lang]);

  return {
    activeTab,
    headerData,
    onTabChange,
  };
};
