import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';

import { showNotifyModalAction } from '@modules/modal';
import { configSelectors } from '@config/core';
import { History } from '@models/core';
import { formatMoney } from '../../../../../services/commonService';

export const PaymentSuccessPage: FC<{}> = () => {
  const dispatch = useDispatch();
  const history: History = useSelector(configSelectors.history);
  const isInvestor: boolean = useSelector(configSelectors.isInvestor);

  useEffect(() => {
    const p: URLSearchParams = new URLSearchParams(window.location.search);
    dispatch(
      showNotifyModalAction({
        description: (
          <Trans id="card_payment_success_modal.description">
            Total payment {formatMoney(+p.get('amount'), { pre: p.get('currency') })}
          </Trans>
        ),
        type: 'success',
        title: <Trans id="card_payment_success_modal.title">Payment successful</Trans>,
      })
    );
    history.push(isInvestor ? '/my-wallet' : '/');
  }, []); // eslint-disable-line

  return null;
};
