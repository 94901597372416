import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { privateApi } from '../../../../../utils/api';
import { Redemption } from '../../api';

const cancelRedeemEndpoint = '/wallets/cancel-redeem';
const cancelRedeem = makeQuery({
  permissions: Permission.REDEEM_CANCEL_MY_REQUEST,
  queryName: cancelRedeemEndpoint,
  query: (id: number): Promise<Redemption> => {
    return privateApi.post(cancelRedeemEndpoint, { id });
  },
});

const api = {
  cancelRedeem,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:cancel-redeem-modal');

export default api;
