import React, { useState } from 'react';
import classnames from 'classnames';

import { TableHeaderSortType as SortType } from 'components/page-table';

const getContent = (params, data, hovered) =>
  typeof params === 'string' ? (
    <span>{params}</span>
  ) : params instanceof Function ? (
    params(data, hovered)
  ) : (
    params
  );

export const useAltTableHeadCellModel = (params, handleLock) => {
  let cellContent;
  let classNames = '';
  let other = {};
  let onSort;
  let sortDirectionGlobal;

  if (typeof params === 'object' && !React.isValidElement(params)) {
    const {
      body,
      className: classNameParam,
      onSort: onSortParam,
      sortDirection,
      ...otherParams
    } = params;
    cellContent = getContent(body);
    classNames = classNameParam;
    other = otherParams;
    onSort = onSortParam;
    sortDirectionGlobal = sortDirection;
  } else {
    cellContent = getContent(params);
  }

  const [sortDirection, setSortDirection] = useState(
    onSort ? sortDirectionGlobal || SortType.notSet : undefined
  );

  const cellClasses = classnames(classNames, {
    'is-disabled': handleLock.lockUI,
    'table-header-sorter': onSort,
    'table-header-sorter--asc': sortDirection === SortType.asc,
    'table-header-sorter--desc': sortDirection === SortType.desc,
  });

  const onCellClicked = () => {
    const newDirection =
      sortDirection === SortType.notSet
        ? SortType.asc
        : sortDirection === SortType.asc
        ? SortType.desc
        : sortDirection === SortType.desc
        ? SortType.notSet
        : undefined;

    if (newDirection) {
      onSort(newDirection);
      setSortDirection(newDirection);
      handleLock.setLockUI(true);
    }
  };

  return { onCellClicked, cellClasses, other, cellContent };
};

export const useAltTableBodyCellModel = (params, data, hovered) => {
  let cellContent;
  let other = {};

  if (typeof params === 'object' && !React.isValidElement(params)) {
    const { body, ...otherParams } = params;
    cellContent = getContent(body, data, hovered);
    other = otherParams;
  } else {
    cellContent = getContent(params, data, hovered);
  }

  return { other, cellContent };
};
