import React, { ReactNode, useMemo } from 'react';
import { Trans } from '@lingui/macro';

import { TableHeaderSortType } from 'components/page-table';
import { dater } from '@helper/date';
import { UserProfileLink } from '@components/link';
import { Conversion } from './types';
import { SortItem } from './sorting';

export type SortDirection = 'ascending' | 'descending';

export type SortBy = keyof Conversion;

// In-component default order sets separately.
export const defaultSortOrder: SortItem[] = [
  {
    field: 'dateCreated',
    direction: 'descending',
  },
];

type Column = {
  cell: {
    body: (rowData: Conversion) => JSX.Element;
    className?: string;
  };
  head: {
    body: JSX.Element;
    className?: string;
    onSort: (direction: TableHeaderSortType) => void;
    sortDirection: TableHeaderSortType;
  };
};

export type OnSort = (sortBy: SortBy, direction?: SortDirection) => void;

function callSort(sortDirection: TableHeaderSortType, sortBy: SortBy, onSort: OnSort) {
  if (sortDirection === TableHeaderSortType.asc) {
    onSort(sortBy, 'ascending');
    return;
  }
  if (sortDirection === TableHeaderSortType.desc) {
    onSort(sortBy, 'descending');
    return;
  }
  onSort(sortBy);
}

const cellClasses = 'tm2-conversion-report-table-cell';
const headClasses = 'tm2-conversion-report-table-head';

type ColumnFactory = (params: {
  cell: (rowData: Conversion) => string | number | ReactNode;
  field: keyof Conversion;
  head: JSX.Element;
  onSort: OnSort;
  sortDirection?: TableHeaderSortType;
}) => Column;

const columnFactory: ColumnFactory = ({ cell, field, head, onSort, sortDirection }) => ({
  cell: {
    body: (rowData: Conversion) => <span>{cell(rowData)}</span>,
    className: cellClasses,
  },
  head: {
    body: <span>{head}</span>,
    className: headClasses,
    onSort: (sortDirection: TableHeaderSortType) => {
      callSort(sortDirection, field, onSort);
    },
    sortDirection,
  },
});

export function useColumns(data: Conversion[] | null, onSort: OnSort) {
  return useMemo<Column[]>(
    () =>
      data === null
        ? null
        : [
            columnFactory({
              cell: (data: Conversion) => dater.toLocalDate(data.dateCreated),
              field: 'dateCreated',
              head: <Trans id={'conversion_report.table.date_created'}>Date & Time created</Trans>,
              onSort,
              sortDirection: TableHeaderSortType.desc,
            }),

            columnFactory({
              cell: (data: Conversion) =>
                data?.datePaid ? dater.toLocalDate(data?.datePaid) : '-',
              field: 'datePaid',
              head: <Trans id={'conversion_report.table.date_paid'}>Date & time paid</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.status,
              field: 'status',
              head: <Trans id={'conversion_report.table.status'}>Status</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.transactionId,
              field: 'transactionId',
              head: <Trans id={'conversion_report.table.transaction_id'}>Transaction ID</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => (
                <UserProfileLink id={data.clientId} text={data.clientId} />
              ),
              field: 'clientId',
              head: <Trans id={'conversion_report.table.client_id'}>Client ID</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.currencyToSell.code,
              field: 'currencyToSell',
              head: <Trans id={'conversion_report.table.currency_to_sell'}>Currency to sell</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.amountToSell,
              field: 'amountToSell',
              head: <Trans id={'conversion_report.table.amount_to_sell'}>Amount to sell</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.currencyToBuy.code,
              field: 'currencyToBuy',
              head: <Trans id={'conversion_report.table.currency_to_buy'}>Currency to buy</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.amountToBuy,
              field: 'amountToBuy',
              head: <Trans id={'conversion_report.table.amount_to_buy'}>Amount to buy</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.rate,
              field: 'rate',
              head: <Trans id={'conversion_report.table.rate'}>Rate</Trans>,
              onSort,
            }),

            columnFactory({
              cell: (data: Conversion) => data.fee,
              field: 'fee',
              head: <Trans id={'conversion_report.table.tm2_fee'}>TM2 fee</Trans>,
              onSort,
            }),
          ],
    [data, onSort]
  );
}
