import reduceReducers from 'reduce-reducers';
import { all, fork } from 'redux-saga/effects';
import { transferCoinTabStateInitial } from './transfer-coin/store/model';
import { transferCoinTabEffects } from './transfer-coin/store/effects';
import { transferCoinTabReducer } from './transfer-coin/store/reducer';
import { transferStableTabStateInitial } from './transfer-stable/store/model';
import { transferStableTabReducer } from './transfer-stable/store/reducer';
import { transferStableTabEffects } from './transfer-stable/store/effects';

export const marketTransferModalReducer = reduceReducers(
  {
    coinTab: transferCoinTabStateInitial,
    stableTab: transferStableTabStateInitial,
  },
  transferCoinTabReducer,
  transferStableTabReducer
);

export function* marketTransferModalEffects() {
  yield all([fork(transferCoinTabEffects), fork(transferStableTabEffects)]);
}
