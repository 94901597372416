import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';

import page from '../index.module.scss';
import { PermissionGroup } from '@permissions/permission-group';
import { PageContent, PageTitle } from '@components/page';
import {
  permissionsGroup as verificationPermissionsGroup,
  VerifyModalButton,
} from '../../post-registration/individual-steps/verification-upload/button';
import { Verification } from '../components/card/verification';
import { ProfileContent } from '../components/content';
import { TariffInfo } from '../components/card/tariff';
import { ProfileCard } from '../components/card';
import { NotificationBanner } from './notification-banner';
import { ProfileExtra, permissionsGroup as extraPermissionsGroup } from './extra-info';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionsGroup, extraPermissionsGroup, verificationPermissionsGroup],
  marker: 'layout:profile-my-page',
});

export const ProfileMyPage: FC = memo(() => {
  const m = useModel();

  return !m.profile ? null : (
    <PageContent className={page.content} uat="my-profile-page">
      <PageTitle title={m.i18n._(defineMessage({ id: 'router.profile', message: 'Profile' }))} />

      <NotificationBanner />

      <ProfileContent
        baseInfo={
          <ProfileCard
            profile={m.profile}
            tariff={<TariffInfo profile={m.profile} />}
            verification={
              <Verification
                profile={m.profile}
                verifyButton={
                  <VerifyModalButton fullWidth={true} visibility="all" uat="verify-button" />
                }
              />
            }
          />
        }
        extraInfo={<ProfileExtra profile={m.profile} />}
      />
    </PageContent>
  );
});
