import React, { FC, ReactNode, useEffect } from 'react';
import classnames from 'classnames';
import { Form as AntForm, FormInstance } from 'antd';

import './styles.scss';
import { Any } from '@models/core';
import { formService as f } from '@components/form';

const { useForm } = AntForm;

export const Form: FC<{
  children: ReactNode;
  className?: string;
  initialValues?: object & { length?: never }; // Store from rc-field-form/lib/interface magically not working here
  name: string;
  onChange?: (formData: Any) => void;
  onFormInstance?: (instance: FormInstance<Any>) => void;
  uat?: string;
}> = ({ children, className, initialValues, name, onFormInstance, onChange, uat, ...props }) => {
  const [form] = useForm();

  useEffect(() => {
    f.set(name, form);
    onFormInstance && onFormInstance(form);
  }, [form]); // eslint-disable-line

  useEffect(() => {
    return () => {
      f.remove(name);
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (initialValues && !!Object.keys(initialValues).length) {
      const antdFlatDataInitial = getInitialFormFlatData(
        form,
        Object.entries({
          // хендлим кейс, когда в initial values приходят
          // не все поля формы и юзер как раз ввел данные в поле, которое не пришло
          ...form.getFieldsValue(),
          ...f.convertOurDataToAntdFlat(initialValues),
        }),
        []
      );

      form.setFieldsValue(antdFlatDataInitial);
      const values = f.convertAntdFlatDataToOur(form.getFieldsValue());
      onChange && onChange(values);
    }
  }, [initialValues]); // eslint-disable-line

  return (
    <AntForm
      className={classnames('tm2-form', className)}
      name={name}
      form={form}
      onValuesChange={() => {
        // we can't use arguments from onValuesChange callback due to https://github.com/ant-design/ant-design/issues/28185
        setTimeout(() => {
          // setTimeout is fast fix for issue above
          const pureValues = form.getFieldsValue();
          const formParsed = f.convertAntdFlatDataToOur(pureValues);
          if (onChange) {
            onChange(formParsed);
          }
        });
      }}
      onFinish={() => {
        f.clearErrors(name);
      }}
      data-uat={uat || `${name}-form`}
      {...props}
    >
      {children}
    </AntForm>
  );
};

type FormValue = string | number | boolean | Array<{ [k: string]: FormValue }>;
function getInitialFormFlatData(
  f: FormInstance,
  formKeyValues: Array<[string, FormValue]>,
  keyPath: Array<string | number>
) {
  return formKeyValues.reduce(
    (formAcc, [key, value]) => ({
      ...formAcc,
      [key]:
        value instanceof Array
          ? value.map((arrItem, arrIndex) =>
              // arrItem будет строкой для случая Radio с кастомными sub филдами в массиве ArrItem будут простыми строками
              // сейчас этот кейс не покрыт проверкой isFieldTouched (скорее всего не понадобится)
              arrItem instanceof Object
                ? getInitialFormFlatData(
                    f,
                    Object.entries(arrItem),
                    keyPath.concat([key, arrIndex])
                  )
                : arrItem
            )
          : !f.isFieldTouched(keyPath.concat([key]))
          ? value
          : f.getFieldValue(keyPath.concat([key])),
    }),
    {}
  );
}
