import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery, traderPermissions } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

const emitDontAskTutorialFields = ['isShowOnboardHelp'];
const emitDontAskTutorialQuery = 'updateProfileSettings';
const emitDontAskTutorial = makeQuery({
  permissions: { or: traderPermissions },
  queryName: emitDontAskTutorialQuery,
  queryFields: emitDontAskTutorialFields,
  query: (): Promise<void> => {
    return stompClientService.sendData(
      emitDontAskTutorialQuery,
      sign(emitDontAskTutorialQuery, emitDontAskTutorialFields),
      v.profileDTOInput({ userProfile: { isShowOnboardHelp: false } })
    );
  },
});

const api = {
  emitDontAskTutorial,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:onboard-help-modal');

export default api;
