import { TransferStableTabActionsTypes as T } from './model';
import { ReduxAction } from '@models/redux';

class OnTabOpened extends ReduxAction<T.ON_TAB_OPENED, void> {
  public readonly type: T.ON_TAB_OPENED = T.ON_TAB_OPENED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnTabLeft extends ReduxAction<T.ON_TAB_LEFT, void> {
  public readonly type: T.ON_TAB_LEFT = T.ON_TAB_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnCancelFinishStableTransferClickedAction extends ReduxAction<
  T.ON_CANCEL_FINISH_STABLE_TRANSFER_CLICKED,
  void
> {
  public readonly type: T.ON_CANCEL_FINISH_STABLE_TRANSFER_CLICKED =
    T.ON_CANCEL_FINISH_STABLE_TRANSFER_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnMaximizeStableAmountTransferClickedAction extends ReduxAction<
  T.ON_MAXIMIZE_STABLE_AMOUNT_TRANSFER_CLICKED,
  void
> {
  public readonly type: T.ON_MAXIMIZE_STABLE_AMOUNT_TRANSFER_CLICKED =
    T.ON_MAXIMIZE_STABLE_AMOUNT_TRANSFER_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnToggleStableTransferDirectionClickedAction extends ReduxAction<
  T.ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED,
  void
> {
  public readonly type: T.ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED =
    T.ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnSelectedValueChangedAction extends ReduxAction<T.ON_SELECTED_VALUE_CHANGED, string> {
  public readonly type: T.ON_SELECTED_VALUE_CHANGED = T.ON_SELECTED_VALUE_CHANGED;
  public constructor(public payload: string) {
    super(payload);
  }
}

export const transferStableTabUserActions = {
  onTabOpened: () => new OnTabOpened().get(),
  onTabLeft: () => new OnTabLeft().get(),
  onCancelFinishStableTransferClicked: () => new OnCancelFinishStableTransferClickedAction().get(),
  onMaximizeStableAmountTransferClicked: () =>
    new OnMaximizeStableAmountTransferClickedAction().get(),
  onToggleStableTransferDirectionClicked: () =>
    new OnToggleStableTransferDirectionClickedAction().get(),
  onSelectedValueChanged: (asset: string) => new OnSelectedValueChangedAction(asset).get(),
};

export type TransferStableTabUserActionsTypes =
  | OnTabOpened
  | OnTabLeft
  | OnCancelFinishStableTransferClickedAction
  | OnMaximizeStableAmountTransferClickedAction
  | OnToggleStableTransferDirectionClickedAction
  | OnSelectedValueChangedAction;
