import sign from 'tm2sign.macro';

import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { Role as R } from '@permissions/core';
import { v } from '@helper/typer/field-typer.helper';

import { CoinInfo, VaultBalanceDetails } from '../vaults-api.service';

export enum Fields {
  country = 'country.id',
  city = 'city',
  warehouseCompany = 'warehouseCompany',
  address = 'address',
  contact = 'contact',
  vaultManagers = 'vaultManagers',
  vaultManager = 'id',
}

export interface Form {
  [Fields.country]: number;
  [Fields.city]: string;
  [Fields.warehouseCompany]: string;
  [Fields.address]: string;
  [Fields.contact]: string;
  [Fields.vaultManagers]: Array<VaultManager>;
}

interface VaultManager {
  id: number;
  displayName: string;
}
const getVaultManagersQuery = 'usersByRole';
const getVaultManagersFields = ['displayName', 'id'];
const getVaultManagers = makeQuery({
  queryName: getVaultManagersQuery,
  queryFields: getVaultManagersFields,
  query: (): Promise<Array<VaultManager>> => {
    return stompClient.getData(
      getVaultManagersQuery,
      sign(getVaultManagersQuery, getVaultManagersFields),
      { role: R.vaultManager }
    );
  },
});

interface VaultById {
  id: number;
  address: string;
  city: string;
  contact: string;
  country: { id: number };
  vaultManagers: Array<{ id: number }>;
  warehouseCompany: string;
}
const getVaultByIdQuery = 'vaultById';
const getVaultByIdFields = [
  'id',
  'address',
  'city',
  'contact',
  { country: ['id', 'label'] },
  { vaultManagers: ['id'] },
  'warehouseCompany',
];
const getVaultById = makeQuery({
  queryName: getVaultByIdQuery,
  queryFields: getVaultByIdFields,
  query: (id: number): Promise<Array<VaultById>> => {
    const typedValues = {
      ...v.long({ id }),
    };

    return stompClient
      .getData(getVaultByIdQuery, sign(getVaultByIdQuery, getVaultByIdFields), typedValues)
      .then((response) => {
        const vmIds = response.vaultManagers.map((vm) => vm.id);
        return { ...response, vaultManagers: vmIds };
      });
  },
});

interface SavedVault {
  address: string;
  city: string;
  coins: Array<CoinInfo>;
  contact: string;
  country: { id: number; label: string };
  id: number;
  lastVaultBalance: { details: Array<VaultBalanceDetails> };
  vaultManagers: Array<{ id: number; displayName: string }>;
  warehouseCompany: string;
}
const saveVaultQuery = 'saveVault';
const saveVaultFields = [
  'address',
  'city',
  { coins: ['asset', 'id', 'name'] },
  'contact',
  { country: ['id', 'label'] },
  'id',
  {
    lastVaultBalance: [
      { details: ['amount', { coin: ['asset', 'id'] }, { unit: ['id', 'label'] }] },
    ],
  },
  { vaultManagers: ['id', 'displayName'] },
  'warehouseCompany',
];
const saveVault = makeQuery({
  queryName: saveVaultQuery,
  queryFields: saveVaultFields,
  query: (form: Form, vaultId: number): Promise<SavedVault> => {
    const vaultManagers = form[Fields.vaultManagers]?.map((id) => ({ id }));

    const vaultAdapter = { ...form, vaultManagers, id: vaultId };

    const typedValues = {
      ...v.saveVaultInput({ vault: vaultAdapter }),
    };

    return stompClient.sendData(saveVaultQuery, sign(saveVaultQuery, saveVaultFields), typedValues);
  },
});

const api = {
  getVaultManagers,
  getVaultById,
  saveVault,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:handle-vault-modal');

export default api;
