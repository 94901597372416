import React, { FC, memo } from 'react';
import { defineMessage, Trans } from '@lingui/macro';

import s from './index.module.scss';
import { PermissionGroup } from '@permissions/core';
import { PageContent, PageTitle } from '@components/page';
import { Table } from '@components/table2/simplified';
import { tableColCfgDefault, tableRowCfgDefault } from '@components/table2/config/table-simplified';
import { TablePane } from '@components/table2/utils';
import {
  SearchClear,
  SearchDateRange,
  SearchForm,
  SearchItem,
  SearchText,
} from '@components/search';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { columns, permissionGroup as tablePermissionsGroup } from './columns';
import { useModel } from './model';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionsGroup, tablePermissionsGroup],
  marker: 'layout:overdraft-report-page',
});

enum Fields {
  dateRange = 'dateRange',
  text = 'text',
}
const F = Fields; // alias

const searchFields: Array<SearchItem> = [
  {
    fields: ['user.id'],
    id: 'clientId',
    label: <Trans id="overdraft-report.search.label.client-id">Client ID</Trans>,
    searchFormField: F.text,
    type: 'long',
  },
  {
    fields: ['currency.asset'],
    id: 'currency',
    label: <Trans id="overdraft-report.search.label.currency">Currency</Trans>,
    searchFormField: F.text,
    type: 'string',
  },
  {
    fields: ['user.company', 'user.firstName', 'user.lastName'],
    id: 'clientName',
    label: <Trans id="overdraft-report.search.label.name">Client name</Trans>,
    searchFormField: F.text,
    type: 'computedString',
  },
  {
    fields: ['createdAt'],
    id: 'createdAt',
    label: <Trans id="daterange.label">Date</Trans>,
    searchFormField: F.dateRange,
    type: 'dateRange',
  },
];

export const OverdraftReportPage: FC = memo(() => {
  const m = useModel();

  return (
    <PageContent className={s.verticalFiller} uat="cm-bank-accounts-page">
      <PageTitle
        title={m.i18n._(
          defineMessage({ id: 'router.overdraft-report', message: 'Overdraft Report' })
        )}
        backwardLink={'/statements'}
      />

      <SearchForm
        className={s.row}
        onRsqlChange={m.onSearchChange}
        fields={searchFields}
        memorize={true}
      >
        <SearchText className={s.searchText} name={F.text} />

        <SearchDateRange
          className={s.searchDate}
          label={<Trans id="daterange.label">Date</Trans>}
          name={Fields.dateRange}
          max={new Date().toISOString()}
        />

        <SearchClear className={s.clear} />
      </SearchForm>

      <TablePane className={s.verticalFiller}>
        <Table
          className={s.verticalFiller}
          columns={columns}
          columnConfig={tableColCfgDefault}
          instance={m.tableInstance}
          rowConfig={tableRowCfgDefault}
        />
      </TablePane>
    </PageContent>
  );
});
