import { useCallback, useContext, useEffect, useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { Context } from './context';

export type CMTransactionType = 'DEPOSIT' | 'TRANSFER' | 'WITHDRAW';
export const useTransactionTypeTranslations = () => {
  const { i18n, lang } = useI18n();
  const translations: { [k in CMTransactionType]: string } = useMemo(
    () => ({
      DEPOSIT: i18n._(
        defineMessage({ id: 'cm.transactions.type.label.deposit', message: 'Deposit' })
      ),
      TRANSFER: i18n._(
        defineMessage({ id: 'cm.transactions.type.label.transfer', message: 'Transfer' })
      ),
      WITHDRAW: i18n._(
        defineMessage({ id: 'cm.transactions.type.label.withdraw', message: 'Withdrawal' })
      ),
    }),
    [lang]
  );
  return translations;
};

export type CMTransactionStatus =
  | 'CREATED'
  | 'TRANSFER_ERROR'
  | 'TRANSFER_PENDING'
  | 'TRANSFER_SUCCESS';
export const useTransactionStatusTranslations = () => {
  const { i18n, lang } = useI18n();
  const translations: { [k in CMTransactionStatus]: string } = useMemo(
    () => ({
      CREATED: i18n._(
        defineMessage({ id: 'cm.transactions.status.label.created', message: 'Created' })
      ),
      TRANSFER_ERROR: i18n._(
        defineMessage({ id: 'cm.transactions.status.label.error', message: 'Error' })
      ),
      TRANSFER_PENDING: i18n._(
        defineMessage({ id: 'cm.transactions.status.label.pending', message: 'Pending' })
      ),
      TRANSFER_SUCCESS: i18n._(
        defineMessage({ id: 'cm.transactions.status.label.success', message: 'Settled' })
      ),
    }),
    [lang]
  );
  return translations;
};

export const useEmitTransactionCreated = () => {
  const { state } = useContext(Context);
  const events = state.events;
  const emitUpdate = useCallback((id: number) => events.emit(id), [events]);
  return emitUpdate;
};

export const useSubscribeOnTransactionsUpdates = (listener: (id: number) => void) => {
  const { state } = useContext(Context);
  const events = state.events;

  useEffect(() => {
    const subscription = events.subscribe(listener);
    return () => subscription.unsubscribe();
  }, [state.events]);
};
