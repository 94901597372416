import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { EditProfileForm } from '../edit-profile/api';

// ----------------------------------------------------------------
export enum ConfirmEditProfileFields {
  password = 'currentPassword',
}
export interface ConfirmEditProfileForm {
  [ConfirmEditProfileFields.password]: string;
}
const confirmEditProfileFields = ['id'];
const confirmEditProfileQuery = 'updateProfile';
const confirmEditProfile = makeQuery({
  permissions: { and: [Permission.PROFILE_EDIT_MY] },
  queryName: confirmEditProfileQuery,
  queryFields: confirmEditProfileFields,
  query: (form: ConfirmEditProfileForm & EditProfileForm): Promise<void> => {
    return stompClientService.sendData(
      confirmEditProfileQuery,
      sign(confirmEditProfileQuery, confirmEditProfileFields),
      v.mapStringObjectScalar({ userProfile: form })
    );
  },
});
// ----------------------------------------------------------------
const api = {
  confirmEditProfile,
};

export const permissionsGroup = PermissionGroup.extract(
  api,
  'api:profile-my-page-confirm-edit-modal'
);

export default api;
