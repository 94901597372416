import { useCallbackOne } from 'use-memo-one';
import { Trans, defineMessage } from '@lingui/macro';
import classnames from 'classnames';
import {
  Table,
  useTable,
  ColumnType,
  KeyExtractor,
  LoadData,
  RowConfig,
} from '@components/table2/simplified';
import { mapTableSorting } from '@helper/sorting';
import { ProfileLinkCell, TablePane } from '@components/table2/utils';
import { PageContent, PageTitle } from '@components/page';
import { formatMoney } from 'containers/services/commonService';
import { PermissionGroup } from '@permissions/core';
import { useI18n } from 'containers/services/commonService';
import { profileOtherPagePermissionsGroup } from 'containers/pages/profile/other/permissions';
import { TableFilters } from './filters';
import api, { permissionGroup as apiPermissionGroup, GetFeesSortKey } from './api';
import { Fee, TableFilter } from './types';
import s from './index.module.scss';

const otherProfilePermissionGroup = new PermissionGroup({
  operator: 'OR',
  optional: true,
  groups: [profileOtherPagePermissionsGroup],
  marker: 'layout:fees-report-page-id-cell',
});

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, otherProfilePermissionGroup],
  marker: 'layout:fees-report-page',
});

enum ColumnKey {
  dateTime = 'dateTime',
  transactionId = 'transactionId',
  feeType = 'feeType',
  clientName = 'clientName',
  clientId = 'clientId',
  asset = 'asset',
  units = 'units',
  currency = 'currency',
  totalExclFee = 'totalExclFee',
  fee = 'fee',
  tm2Fee = 'tm2Fee',
  feePct = 'feePct',
  total = 'total',
}

const sortMap = {
  [ColumnKey.asset]: GetFeesSortKey.asset,
  [ColumnKey.dateTime]: GetFeesSortKey.dateTime,
  [ColumnKey.fee]: GetFeesSortKey.fee,
  [ColumnKey.feePct]: GetFeesSortKey.feePct,
  [ColumnKey.tm2Fee]: GetFeesSortKey.tm2Fee,
  [ColumnKey.total]: GetFeesSortKey.total,
  [ColumnKey.totalExclFee]: GetFeesSortKey.totalExcludeFee,
  [ColumnKey.transactionId]: GetFeesSortKey.transactionId,
  [ColumnKey.units]: GetFeesSortKey.units,
};

function formatMoneyNum(amount: number) {
  return formatMoney(amount, { pre: '', post: '' });
}

const columns: ColumnType<Fee>[] = [
  {
    key: ColumnKey.dateTime,
    dataIndex: 'dateTime',
    headCell: () => <Trans id="fees-report.table.date-time">Date & time</Trans>,
    rowCell: ({ data }) => <>{data.dateTime.formatLocalDateTime()}</>,
    width: 160,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.transactionId,
    dataIndex: 'transactionId',
    headCell: () => <Trans id="fees-report.table.transaction-id">Transaction ID</Trans>,
    rowCell: ({ data }) => <>{data.transactionId ?? '-'}</>,
    tooltip: true,
    width: 180,
  },
  {
    key: ColumnKey.feeType,
    dataIndex: 'feeType',
    headCell: () => <Trans id="fees-report.table.fee-type">Fee type</Trans>,
    rowCell: ({ data }) => <>{data.feeType}</>,
    width: 160,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.clientName,
    dataIndex: 'clientName',
    headCell: () => <Trans id="fees-report.table.client-name">Client name</Trans>,
    rowCell: ({ data }) => <ProfileLinkCell text={data.clientName} id={data.clientId} />,
    width: 150,
  },
  {
    key: ColumnKey.clientId,
    dataIndex: 'clientId',
    headCell: () => <Trans id="fees-report.table.client-id">Client ID</Trans>,
    rowCell: ({ data }) => <>{data.clientId}</>,
    width: 120,
  },
  {
    key: ColumnKey.asset,
    dataIndex: 'asset',
    headCell: () => <Trans id="fees-report.table.asset">Asset</Trans>,
    rowCell: ({ data }) => <>{data.asset ?? '-'}</>,
    width: 120,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.units,
    dataIndex: 'units',
    headCell: () => <Trans id="fees-report.table.units">Units</Trans>,
    rowCell: ({ data }) => <>{data.units ? formatMoneyNum(data.units) : '-'}</>,
    width: 120,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.currency,
    dataIndex: 'currency',
    headCell: () => <Trans id="fees-report.table.currency">Currency</Trans>,
    rowCell: ({ data }) => <>{data.currency}</>,
    width: 120,
  },
  {
    key: ColumnKey.totalExclFee,
    dataIndex: 'totalExcludeFee',
    headCell: () => <Trans id="fees-report.table.total-exclude-fee">Total excl. fee</Trans>,
    rowCell: ({ data }) => <>{formatMoneyNum(data.totalExcludeFee)}</>,
    width: 140,
  },
  {
    key: ColumnKey.fee,
    dataIndex: 'allFee',
    headCell: () => <Trans id="fees-report.table.fee">Fee</Trans>,
    rowCell: ({ data }) => <>{formatMoneyNum(data.allFee)}</>,
    width: 120,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.tm2Fee,
    dataIndex: 'TM2Fee',
    headCell: () => <Trans id="fees-report.table.tm2-fee">TM2 fee</Trans>,
    rowCell: ({ data }) => <>{formatMoneyNum(data.TM2Fee)}</>,
    width: 120,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.feePct,
    dataIndex: 'feePercent',
    headCell: () => (
      <>
        <Trans id="fees-report.table.fee">Fee</Trans> (%)
      </>
    ),
    rowCell: ({ data }) => <>{data.feePercent}%</>,
    width: 120,
    sorting: ['ascend', 'descend'],
  },
  {
    key: ColumnKey.total,
    dataIndex: 'total',
    headCell: () => <Trans id="fees-report.table.total">Total</Trans>,
    rowCell: ({ data }) => <>{formatMoneyNum(data.total)}</>,
    width: 120,
    sorting: ['ascend', 'descend'],
  },
];

const rowConfig: RowConfig<Fee> = {
  height: 41,
};

export const FeesReportPage = () => {
  const { i18n } = useI18n();

  const keyExtractor = useCallbackOne<KeyExtractor<Fee>>((item) => {
    // Some of the items can be without transactionId.
    return `${item.clientName}_${item.feeType}_${item.dateTime}_${item.transactionId}`;
  }, []);

  const loadData = useCallbackOne<LoadData<Fee, TableFilter>>(({ filter, sortOrder, nextPage }) => {
    return api.getFees({
      filter: filter ?? {},
      pageNumber: nextPage,
      ordering: mapTableSorting(sortOrder, sortMap),
    });
  }, []);

  const { tableInstance, setFilter } = useTable<Fee, TableFilter>({
    initialSortOrder: [
      {
        field: 'dateTime',
        direction: 'descend',
        key: ColumnKey.dateTime,
      },
    ],
    keyExtractor,
    loadData,
    startPage: 1,
  });

  return (
    <PageContent
      className={classnames(s.container, s.verticalFiller)}
      uat="statements-fees-report-page"
    >
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.statements.fees',
            message: 'Fees Report',
          })
        )}
        backwardLink={'/statements'}
      />
      <TableFilters
        onFilterChange={(filter) => {
          setFilter(() => filter);
        }}
      />
      <TablePane className={s.verticalFiller}>
        <Table
          className={s.verticalFiller}
          columns={columns}
          instance={tableInstance}
          rowConfig={rowConfig}
        />
      </TablePane>
    </PageContent>
  );
};
