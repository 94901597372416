import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { Permission, PermissionGroup, makeQuery } from '@permissions/core';
import { currenciesFilter } from '@hot-fix/currency';

type QueryStableBalance = {
  amount: number;
  currency: {
    asset: string;
    id: number;
  };
};

type StableData = {
  asset: string;
  balance: number;
};

const stableBalanceFields = {
  stableBalance: [
    'amount',
    {
      currency: ['asset', 'id'],
    },
  ],
};

function mapStableBalance(queryStableBalance: QueryStableBalance): StableData {
  return {
    balance: queryStableBalance.amount,
    asset: queryStableBalance.currency.asset,
  };
}

type GetTransferInfoResponse = {
  primaryStableBalances: StableData[];
  secondaryStableBalances: StableData[];
};

const getTransferInfoQuery = 'userProfile';
const getTransferInfoFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [{ ...stableBalanceFields }],
          },
        ],
      },
      // { // TM2-2997
      //   secondary: [
      //     {
      //       active: [{ ...stableBalanceFields }],
      //     },
      //   ],
      // },
    ],
  },
];
const getTransferInfo = makeQuery({
  permissions: {
    and: [Permission.BALANCE_VIEW_PRIMARY_MY, Permission.BALANCE_VIEW_SECONDARY_MY],
  },
  queryName: getTransferInfoQuery,
  queryFields: getTransferInfoFields,
  query: (): Promise<GetTransferInfoResponse> => {
    type QueryResponse = {
      balance: {
        primary: { active: { stableBalance: QueryStableBalance[] } };
        secondary: { active: { stableBalance: QueryStableBalance[] } };
      };
    };

    return stompClient
      .getData<QueryResponse>(
        getTransferInfoQuery,
        sign(getTransferInfoQuery, getTransferInfoFields)
      )
      .then(({ balance }) => {
        const primaryStableBalances = balance.primary.active.stableBalance
          .filter((balance) => currenciesFilter(balance.currency))
          .map(mapStableBalance);

        const secondaryStableBalances = []; // TM2-2997
        // const secondaryStableBalances = balance.secondary.active.stableBalance
        //   .filter((balance) => currenciesFilter(balance.currency))
        //   .map(mapStableBalance);

        return {
          primaryStableBalances,
          secondaryStableBalances,
        };
      });
  },
});

const sendStableFromPrimaryToSecondaryQuery = 'sendStableToSecondary';
const sendStableFromPrimaryToSecondaryFields = ['value'];
const sendStableFromPrimaryToSecondary = makeQuery({
  queryName: sendStableFromPrimaryToSecondaryQuery,
  queryFields: sendStableFromPrimaryToSecondaryFields,
  query: (form) => {
    const { amount, asset } = form;
    const typedValues = {
      asset,
      ...v.bigDecimal({ amount: +amount }),
    };
    return stompClient
      .sendData(
        sendStableFromPrimaryToSecondaryQuery,
        sign(sendStableFromPrimaryToSecondaryQuery, sendStableFromPrimaryToSecondaryFields),
        typedValues
      )
      .then((response) => response.value);
  },
});

const sendStableFromSecondaryToPrimaryQuery = 'sendStableToPrimary';
const sendStableFromSecondaryToPrimaryFields = ['value'];
const sendStableFromSecondaryToPrimary = makeQuery({
  queryName: sendStableFromSecondaryToPrimaryQuery,
  queryFields: sendStableFromSecondaryToPrimaryFields,
  query: (form) => {
    const { amount, asset } = form;
    const typedValues = {
      asset,
      ...v.bigDecimal({ amount: +amount }),
    };
    return stompClient
      .sendData(
        sendStableFromSecondaryToPrimaryQuery,
        sign(sendStableFromSecondaryToPrimaryQuery, sendStableFromSecondaryToPrimaryFields),
        typedValues
      )
      .then((response) => response.value);
  },
});

const api = {
  getTransferInfo,
  sendStableFromPrimaryToSecondary,
  sendStableFromSecondaryToPrimary,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:transfer-stable');

export default api;
