import React, { FC } from 'react';
import classnames from 'classnames';
import { Collapse, CollapsePanelProps, CollapseProps } from 'antd';

import './styles.scss';
import { ReactComponent as RadioActiveIconDark } from '@images/icons/radio-active-icon-dark.svg';
import { ReactComponent as RadioInactiveIconDark } from '@images/icons/radio-inactive-icon-dark.svg';
import { ReactComponent as RadioActiveIconLight } from '@images/icons/radio-active-icon-light.svg';
import { ReactComponent as RadioInactiveIconLight } from '@images/icons/radio-inactive-icon-light.svg';

const { Panel } = Collapse;

export const RadioBox: FC<RadioBoxProps> = ({
  accordion,
  bordered,
  children,
  className,
  deprecatedStyles,
  expandIcon,
  ...props
}) => {
  return (
    <Collapse
      className={classnames(className, 'tm2-radio-box', { 'is-radio-mode': accordion })}
      accordion={true}
      bordered={false}
      expandIcon={(p) => (
        <div className="tm2-radio-box-dot-icon">
          {deprecatedStyles ? (
            p.isActive ? (
              <RadioActiveIconDark />
            ) : (
              <RadioInactiveIconDark />
            )
          ) : p.isActive ? (
            <RadioActiveIconLight />
          ) : (
            <RadioInactiveIconLight />
          )}
        </div>
      )}
      {...props}
    >
      {children}
    </Collapse>
  );
};

export const RadioBoxItem: FC<RadioBoxItemProps> = ({
  children,
  className,
  header,
  hidden,
  key,
  ...props
}) => {
  return hidden ? null : (
    <Panel
      header={header}
      className={classnames(className, 'tm2-radio-box-item')}
      forceRender={true}
      key={key}
      {...props}
    >
      {children}
    </Panel>
  );
};

export interface RadioBoxItemProps extends CollapsePanelProps {
  hidden?: boolean;
}

export interface RadioBoxProps extends CollapseProps {
  deprecatedStyles?: boolean;
}
