import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';

import { handleBackendError } from '@modules/notify';
import { Put } from '@helper/redux';
import { closeModalAction } from '@modules/modal';
import {
  VaultBalanceDetailsHistoryItem,
  vaultsApiService as vaultsApi,
} from '../../vaults-api.service';
import { VaultBalanceDetailModal } from '../index';
import { VaultBalanceDetailsActionsTypes as BalanceTypes } from './model';
import {
  vaultBalanceDetailsServiceActions as balanceServiceActions,
  VaultBalanceDetailsServiceActions as BalancesServiceActions,
} from './service.acions';

export function* vaultBalanceDetailsEffects() {
  yield all([
    takeEvery(BalanceTypes.GET_VAULT_BALANCE_DETAILS, getVaultBalanceDetails),
    takeEvery(BalanceTypes.ON_CLOSE_MODAL_CLICKED, Put(closeModalAction, VaultBalanceDetailModal)),
    takeEvery(BalanceTypes.ON_MODAL_OPENED, Put(balanceServiceActions.getVaultBalanceDetailsInfo)),
  ]);
}

function* getVaultBalanceDetails(action: BalancesServiceActions.GetVaultBalanceDetails) {
  try {
    const coinId = action.payload.coin.id;
    const vaultId = action.payload.vault.id;
    const history: Array<VaultBalanceDetailsHistoryItem> =
      yield vaultsApi.getVaultBalanceDetailsHistory(vaultId, coinId);
    yield put(balanceServiceActions.getVaultBalanceDetailsInfoSuccess(history));
  } catch (error) {
    yield put(balanceServiceActions.getVaultBalanceDetailsInfoFail(error as Error));
    handleBackendError(error);
  }
}
