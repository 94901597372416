import { MetalsPageActionsTypes as Types } from './model';

const DownloadTransactionAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION,
});

const DownloadTransactionFailAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION_FAIL,
});

const DownloadTransactionSuccessAction = () => ({
  payload: null,
  type: Types.DOWNLOAD_TRANSACTION_SUCCESS,
});

const LoadMoreMetalsAction = (config) => ({
  payload: config,
  type: Types.LOAD_MORE_METALS,
});

const LoadMoreMetalsFailAction = (error) => ({
  payload: error,
  type: Types.LOAD_MORE_METALS_FAIL,
});

const LoadMoreMetalsSuccessAction = (metals) => ({
  payload: metals,
  type: Types.LOAD_MORE_METALS_SUCCESS,
});

const UploadTransactionAction = (file) => ({
  payload: file,
  type: Types.UPLOAD_TRANSACTION,
});

const UploadTransactionFailAction = () => ({
  payload: null,
  type: Types.UPLOAD_TRANSACTION_FAIL,
});

const UploadTransactionSuccessAction = () => ({
  payload: null,
  type: Types.UPLOAD_TRANSACTION_SUCCESS,
});

export const metalsPageServiceActions = {
  downloadTransaction: DownloadTransactionAction,
  downloadTransactionFail: DownloadTransactionFailAction,
  downloadTransactionSuccess: DownloadTransactionSuccessAction,
  loadMoreMetals: LoadMoreMetalsAction,
  loadMoreMetalsFail: LoadMoreMetalsFailAction,
  loadMoreMetalsSuccess: LoadMoreMetalsSuccessAction,
  uploadTransaction: UploadTransactionAction,
  uploadTransactionFail: UploadTransactionFailAction,
  uploadTransactionSuccess: UploadTransactionSuccessAction,
};
