import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import { all, fork } from 'redux-saga/effects';

import { sharedEffects } from 'shared/shared.store';
import { TM2Config } from '@constants/tm2';
import { TM2Contour } from '@models/core';
import { rootReducer } from '../reducers/index';
import { mainEffects } from '../containers/pages/main.store';
import { bootstrapEffects } from './store/effects';

function* rootEffects() {
  yield all([fork(bootstrapEffects), fork(mainEffects), fork(sharedEffects)]);
}

const saga = createSagaMiddleware();

export const store = configureStore({
  devTools: TM2Config?.contour === TM2Contour.development,
  middleware: [thunk, saga],
  reducer: rootReducer,
});

saga.run(rootEffects);
