import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { privateApi } from 'utils/api';
import { closeModalAction } from '@modules/modal';
import { Trans } from '@lingui/macro';
import { notifySuccess } from '@modules/notify';
import { OnFormAction } from '@components/form';
import { AddAmountCoinModal } from './index';

export const addAmountFormId = 'addAmountFormId';

export const useAddAmountCoinModel = ({ coin }) => {
  const [lockUI, setLockUI] = useState(false);
  const dispatch = useDispatch();
  const onModalHide = () => dispatch(closeModalAction(AddAmountCoinModal));
  const issueLimit = { min: 0.01 };

  const onAddAmountAction: OnFormAction = {
    error: () => {
      setLockUI(false);
    },
    submit: async (form: { amount: number }, dispatch) => {
      setLockUI(true);
      await privateApi.post('/coins/' + coin.id + '/addAmount', { amount: form.amount });
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="coin_service.notification.dm_issued_successfully">
            Digital metals issued successfully
          </Trans>
        ),
      });
      setLockUI(false);
      await dispatch(closeModalAction(AddAmountCoinModal));
    },
  };

  return { onModalHide, lockUI, onAddAmountAction, issueLimit };
};
