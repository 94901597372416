import { useCallbackOne, useMemoOne } from 'use-memo-one';
import type { MouseEvent, ReactNode } from 'react';
import type { BaseItem, RowProps as CoreRowProps } from '../../core';
import type { CellAlignment, ColumnType, ColumnConfig, RowConfig } from '../types';

export * from './cell-box';

export * from './cell-renderer';

export * from './row-box';

export * from './row-renderer';

export type RowProps<T extends BaseItem> = {
  coreRowProps: CoreRowProps<T>;
  columns: Omit<ColumnType<T>, 'headCellRenderer' | 'headCell'>[];
  columnConfig: Omit<ColumnConfig, 'headCellRenderer'>;
  rowConfig: RowConfig<T>;
};

function PassThrough({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

export function Row<T extends BaseItem>(props: RowProps<T>) {
  const RowRenderer = props.rowConfig.rowRenderer ?? PassThrough;

  const onClick = useCallbackOne(
    (e: MouseEvent) => {
      props.rowConfig.onClick?.(e, props.coreRowProps.data);
    },
    [props.rowConfig.onClick]
  );

  const onMouseEnter = useCallbackOne(
    (e: MouseEvent) => {
      props.rowConfig.onMouseEnter?.(e, props.coreRowProps.data);
    },
    [props.rowConfig.onMouseEnter]
  );

  const onMouseLeave = useCallbackOne(
    (e: MouseEvent) => {
      props.rowConfig.onMouseLeave?.(e, props.coreRowProps.data);
    },
    [props.rowConfig.onMouseLeave]
  );

  const rowStyle = useMemoOne(() => {
    if (props.rowConfig.height === 'dynamic') {
      return props.coreRowProps.style;
    }
    return {
      ...props.coreRowProps.style,
      height: props.coreRowProps.estimatedHeight,
    };
  }, [props.coreRowProps.style, props.coreRowProps.estimatedHeight, props.rowConfig.height]);

  return (
    <RowRenderer
      containerRef={props.coreRowProps.containerRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={rowStyle}
    >
      {props.columns.map((c) => {
        const ignorePadding =
          typeof props.rowConfig.height === 'number' || props.rowConfig.height === 'fixed';

        const RowCell = c.rowCell;

        const RowCellRenderer = c.rowCellRenderer ?? props.columnConfig.rowCellRenderer;
        if (!RowCellRenderer) {
          throw new Error('base/row: Row: RowCellRenderer should be provided');
        }

        const columnKey = c.key ?? c.dataIndex?.toString();
        if (!columnKey) {
          throw new Error(
            'base/row: Row: Column should have either `key` either `dataIndex` property or both'
          );
        }

        const alignment: CellAlignment = c.alignment ?? props.columnConfig.alignment ?? 'left';
        const ellipsis = c.ellipsis ?? props.columnConfig.ellipsis ?? false;
        const tooltip = c.tooltip ?? props.columnConfig.tooltip ?? false;

        return (
          <RowCellRenderer
            key={columnKey}
            alignment={alignment}
            ellipsis={ellipsis}
            tooltip={tooltip}
            ignorePadding={ignorePadding}
            width={c.width}
          >
            <RowCell data={props.coreRowProps.data} />
          </RowCellRenderer>
        );
      })}
    </RowRenderer>
  );
}
