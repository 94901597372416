import { ReduxAction } from '@models/redux';
import { BankAccount } from '../../deposits-api.service';
import { AddFundsModalActionsTypes as T } from './model';

class GetBankAccountsAction extends ReduxAction<T.GET_BANK_ACCOUNTS, void> {
  public readonly type: T.GET_BANK_ACCOUNTS = T.GET_BANK_ACCOUNTS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetBankAccountsFailAction extends ReduxAction<T.GET_BANK_ACCOUNTS_FAIL, Error> {
  public readonly type: T.GET_BANK_ACCOUNTS_FAIL = T.GET_BANK_ACCOUNTS_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GetBankAccountsSuccessAction extends ReduxAction<
  T.GET_BANK_ACCOUNTS_SUCCESS,
  Array<BankAccount>
> {
  public readonly type: T.GET_BANK_ACCOUNTS_SUCCESS = T.GET_BANK_ACCOUNTS_SUCCESS;
  public constructor(public payload: Array<BankAccount>) {
    super(payload);
  }
}

export const addFundsModalServiceActions = {
  getBankAccounts: () => new GetBankAccountsAction().get(),
  getBankAccountsFail: (error: Error) => new GetBankAccountsFailAction(error).get(),
  getBankAccountsSuccess: (list: Array<BankAccount>) =>
    new GetBankAccountsSuccessAction(list).get(),
};

export type AddFundsModalServiceActionsTypes =
  | GetBankAccountsAction
  | GetBankAccountsFailAction
  | GetBankAccountsSuccessAction;

export declare namespace AddFundsModalServiceActions {
  type GetBankAccounts = GetBankAccountsAction;
  type GetBankAccountsFail = GetBankAccountsFailAction;
  type GetBankAccountsSuccess = GetBankAccountsSuccessAction;
}
