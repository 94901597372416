import { Form, FormInstance } from 'antd';

import { formService } from '../service';

const { useForm } = Form;

const useFormById = <T = unknown>(id: string) => {
  const form: FormInstance<T> = formService.get<T>(id);
  return form;
};

const hooks = {
  useForm,
  useFormById,
};

export default hooks;
