import { isFieldSortOrder, isKeySortOrder } from './utils';
import type { BaseItem } from '../loadable-virtual-list';
import type { SortOrder } from './use-table-sorter';
import type { FieldSortOrder } from './utils';

type Field = string | number | readonly (string | number)[];

function normalizeField(field: Field): readonly (string | number)[] {
  if (typeof field === 'string' || typeof field === 'number') {
    return [field];
  }
  return field;
}

function getNestedValue(obj: object, accessor: Field): string | number | object {
  const normalizedAccessor = normalizeField(accessor);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return normalizedAccessor.reduce((acc, field) => (acc as any)[field], obj);
}

export function areFieldsEqual(field1: Field | null, field2: Field | null) {
  if (field1 === null || field2 === null) return false;

  const normalizedField1 = normalizeField(field1);
  const normalizedField2 = normalizeField(field2);
  if (normalizedField1.length !== normalizedField2.length) {
    return false;
  }
  return normalizedField1.every((entry, index) => entry === normalizedField2[index]);
}

export function areOrdersEqual(sortOrder1: SortOrder, sortOrder2: SortOrder): boolean {
  if (sortOrder1.length !== sortOrder2.length) {
    return false;
  }
  if (isKeySortOrder(sortOrder1) && isKeySortOrder(sortOrder2)) {
    return sortOrder1.every((item1, index) => {
      const item2 = sortOrder2[index];
      const areDirectionsEqual = item1.direction === item2.direction;

      return areDirectionsEqual && item1.key === item2.key;
    });
  }
  if (isFieldSortOrder(sortOrder1) && isFieldSortOrder(sortOrder2)) {
    return sortOrder1.every((item1, index) => {
      const item2 = sortOrder2[index];
      const areDirectionsEqual = item1.direction === item2.direction;

      return areDirectionsEqual && areFieldsEqual(item1.field, item2.field);
    });
  }

  throw new Error(
    'table-sorter/sorting: areOrdersEqual: To check sort orders equality all ' +
      'items should either have `key` or `field` property'
  );
}

export function sortItems<T extends BaseItem>(sortItems: T[], order: FieldSortOrder): T[] {
  return [...sortItems].sort((a, b) => {
    let isABeforeB = false;

    for (const orderItem of order) {
      const { direction, field } = orderItem;
      const aField = getNestedValue(a, field);
      const bField = getNestedValue(b, field);
      let isABeforeBLocal = false;

      if (typeof aField === 'number' && typeof bField === 'number') {
        if (direction === 'ascend') {
          isABeforeBLocal = aField < bField;
        }
        if (direction === 'descend') {
          isABeforeBLocal = aField > bField;
        }
      }
      if (typeof aField === 'string' && typeof bField === 'string') {
        if (direction === 'ascend') {
          isABeforeBLocal = aField.localeCompare(bField) < 0;
        }
        if (direction === 'descend') {
          isABeforeBLocal = aField.localeCompare(bField) > 0;
        }
      }
      if (aField !== bField) {
        isABeforeB = isABeforeBLocal;
        break;
      }
    }
    return isABeforeB ? -1 : 1;
  });
}
