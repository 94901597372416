import React, { FC, memo } from 'react';

import { ButtonAdd } from '@components/button/add';
import { permissionsGroup as bankModalPermissionsGroup } from '../../modals/handle-bank-account';
import { useModel } from './model';

export const permissionsGroup = bankModalPermissionsGroup.createSuperGroup({
  marker: 'layout:cm-create-bank-button',
});

type Props = {
  className?: string;
};
export const CreateAccount: FC<Props> = memo<Props>((p) => {
  const m = useModel();

  return (
    <ButtonAdd
      className={p.className}
      onClick={m.onCreateAccountClicked}
      disabled={m.disabled}
      compact="all"
    />
  );
});
