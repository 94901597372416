import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { Permission as P } from '@permissions/core';
import { MoreActions } from '@components/more-actions';
import { WithdrawalStatus as S } from '@models/core';

import { permissionGroup as apiPermissionGroup } from '../api';
import { HandleWithdrawalType, useModel } from './model';

import type { Withdrawal } from '../api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:withdrawals-actions-cell',
});

export const ActionsCell: FC<{
  data: any;
  onReplaceWithdrawal: (withdrawal: Withdrawal | undefined) => void;
}> = ({ data, onReplaceWithdrawal }) => {
  const m = useModel(onReplaceWithdrawal);

  return (
    <MoreActions
      placement="right"
      disabled={m.lockUI}
      list={[
        {
          access: {
            extraCheck: () => {
              return m.isOpsClerk
                ? [S.checked].includes(data.status)
                : [S.checked, S.preApproved].includes(data.status);
            },
            permissions: { or: [P.WITHDRAWAL_APPROVE, P.WITHDRAWAL_PRE_APPROVE] },
          },
          action: () => m.handleWithdrawal(data, HandleWithdrawalType.onApprove),
          content: <Trans id="invoices.actions.approve">Approve</Trans>,
        },
        {
          access: {
            extraCheck: () => data.status === S.approved,
            permissions: { or: [P.WITHDRAWAL_PROCESS] },
          },
          action: () => m.handleWithdrawal(data, HandleWithdrawalType.onProcessed),
          content: <Trans id="withdrawals.actions.payment_processed">Payment processed</Trans>,
        },
        {
          access: {
            extraCheck: () => data.status === S.pending,
            permissions: { or: [P.WITHDRAWAL_CHECK] },
          },
          action: () => m.handleWithdrawal(data, HandleWithdrawalType.onMarkAsChecked),
          content: <Trans id="withdrawals.actions.mark_as_checked">Mark as checked</Trans>,
        },
        {
          access: {
            extraCheck: () => [S.checked, S.pending, S.preApproved].includes(data.status),
            permissions: { or: [P.WITHDRAWAL_DENY] },
          },
          action: () => m.handleWithdrawal(data, HandleWithdrawalType.onDeny),
          content: <Trans id="withdrawals.actions.deny">Deny</Trans>,
        },
        {
          access: {
            extraCheck: () => [S.checked, S.pending, S.preApproved].includes(data.status),
            permissions: { or: [P.WITHDRAWAL_CANCEL] },
          },
          action: () => m.handleWithdrawal(data, HandleWithdrawalType.onCancel),
          content: <Trans id="button.cancel">Cancel</Trans>,
        },
      ]}
    />
  );
};
