import { SliceId } from '@helper/redux';
import { SelectItem } from '@components/form';

export const sliceId = SliceId('shared.currency');

export interface State {
  currencies: Array<SelectItem>;
  isLoading: boolean;
}

export const stateInitial: State = {
  currencies: [],
  isLoading: false,
};
