import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import s from './index.module.scss';
import { configSelectors } from '@config/core';

export const NotificationBanner: FC = memo(() => {
  const banner = useSelector(configSelectors.bannerConfig);

  return !banner.isShow ? null : (
    <div
      className={s.banner}
      style={{ backgroundImage: `url(${banner.image})` }}
      dangerouslySetInnerHTML={{ __html: banner.view }}
    />
  );
});
