import { useMemo } from 'react';

import { SelectItem } from '@components/form';
import { useBidOrderStatusTrans } from '../shared/trans';
import { useBidOrdersCoins } from '../legacy-service';

export const useBidOrdersActionsModel = () => {
  const statusesTranslations = useBidOrderStatusTrans();
  const coinsResponse = useBidOrdersCoins();

  const statuses: Array<SelectItem> = useMemo(() => {
    return Object.keys(statusesTranslations).map((status) => ({
      label: statusesTranslations[status],
      value: status,
    }));
  }, [statusesTranslations]);

  const coins: Array<SelectItem> = useMemo(() => {
    return coinsResponse.map((coin) => ({
      label: `${coin.asset} (${coin.metal.label})`,
      value: coin.id,
    }));
  }, [coinsResponse]);

  return { statuses, coins };
};
