import React, { FC, memo } from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import { PageContent, PageTitle } from '@components/page';
import { Box } from '@components/box';
import { Form, InputDateRange, RoledSelect, SubmitButton } from '@components/form';
import { CurrencySelect } from '@modules/currencies';
import { Role } from '@permissions/core';
import { formId, maxDate, useModel, Fields } from './model';
import s from './index.module.scss';

export const ListingBrokerAccountStatement: FC = memo(() => {
  const m = useModel();

  return (
    <PageContent uat="statements-listing-broker-account-statement-page">
      <PageTitle
        title={m.i18n._(
          defineMessage({
            id: 'router.list-ing_broker_account_statement',
            message: 'Listing brokers account statement',
          })
        )}
        backwardLink={'/statements'}
      />

      <Box className={s.form}>
        <Form name={formId}>
          {!m.isListingBroker && (
            <RoledSelect
              className="mb1"
              name={Fields.listingBrokerId}
              label={<Trans id="lb-as.listing_broker">Listing broker</Trans>}
              disabled={m.lockUI}
              role={Role.listingBroker}
            />
          )}
          <InputDateRange
            className="mb1"
            name={Fields.date}
            label={<Trans id="lb-as.period">Period</Trans>}
            max={maxDate}
            disabled={m.lockUI}
          />
          <CurrencySelect
            className="mb1"
            name={Fields.currencyId}
            label={<Trans id="statements.lb_account_statement.currency">Currency</Trans>}
            disabled={m.lockUI}
          />
          <SubmitButton
            name={formId}
            fullWidth={true}
            disabled={m.lockUI}
            onAction={m.onFormAction}
          >
            <Trans id="button.create">Create</Trans>
          </SubmitButton>
        </Form>
      </Box>
    </PageContent>
  );
});
