import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { Form, InputText, SubmitButton } from '@components/form';
import { DispatchFn } from '@models/redux';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { UserItem } from '../users-api.service';
import { addSMTAUserActions } from './store/user.acions';
import { addSMTASelectors } from './store/selectors';
import { AddSMTAFormFields as Fields, addSMTAccountFormId as formId } from './store/model';
import { onAddSMTAFormAction } from './store/effects';
import s from './add-smt-account.module.scss';
import classnames from 'classnames';

const mapStateToProps = (root) => ({
  lockUI: addSMTASelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onCloseModalClicked: () => dispatch(addSMTAUserActions.onCloseModalClicked()),
  onModalOpened: (user: UserItem) => dispatch(addSMTAUserActions.onModalOpened(user)),
});

const ConnectedAddSMTAModal: MFC<{
  lockUI: boolean;
  onCloseModalClicked: DispatchFn<void>;
  onModalOpened: DispatchFn<UserItem>;
  user: UserItem;
}> = ({ isVisible, lockUI, onCloseModalClicked, onModalOpened, user }) => {
  useEffect(() => {
    onModalOpened(user);
  }, []); // eslint-disable-line

  return (
    <Modal isVisible={isVisible} loading={lockUI} disabled={lockUI} onClose={onCloseModalClicked}>
      <ModalTitle>
        <Trans id="add_smta_modal.title">Add Secondary Market trading account</Trans>
      </ModalTitle>

      <ModalContent>
        <div>
          <Trans id="add_smta_modal.form.help.title">Please notice</Trans>
        </div>

        <div className={classnames(s.text, 'mb-md')}>
          <Trans id="add_smta_modal.form.help.description">
            Adding Secondary Market trading account requires the preliminary creation of a Secondary
            Market participant. To do this, you need to create a Technical support ticket
          </Trans>
          .
        </div>

        <Form name={formId}>
          <InputText
            name={Fields.participantId}
            label={<Trans id="add_smta_modal.form.label.participant_id">Participant ID</Trans>}
            disabled={lockUI}
            required={true}
          />

          <InputText
            name={Fields.accountCode}
            label={
              <Trans id="add_smta_modal.form.label.trading_account_code">
                Trading account code
              </Trans>
            }
            disabled={lockUI}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          name={formId}
          fullWidth={true}
          disabled={lockUI}
          onAction={onAddSMTAFormAction}
        >
          <Trans id="add_smta_modal.form.button.submit">Confirm</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};

export const AddSMTAModal = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddSMTAModal);
