import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import './styles/index.scss';
import { ReactComponent as ArrowRightShort } from '@images/icons/arrow-right-short.svg';
import { LinkColor, LinkSize, LinkType, useLinkModel } from './model';

export { default as UserProfileLink } from './user-profile';
export { LinkColor, LinkSize, LinkType } from './model';

export interface LinkModel {
  className?: string;
  children?;
  color?: LinkColor;
  disabled?: boolean;
  onClick?: Function;
  size?: LinkSize;
  target?: '_blank';
  to?: string;
  type?: LinkType;
  uat?: string;
}

/**
 * Represents base link component
 * @param className - additional css class if required
 * @param children - link content (text in common cases)
 * @param color - link color (see ui-kit page)
 * @param disabled - flag to disable link
 * @param size - link size (see ui-kit page)
 * @param target - open link target window
 * @param to - url to internal route
 * @param onClick - callback for click action
 * @param type - link type (see ui-kit page)
 * @param uat - marker for uat tests
 */
export const Link: FC<LinkModel> = ({
  className,
  children,
  color = LinkColor.primary,
  disabled,
  onClick,
  size = LinkSize.md,
  target,
  to,
  type = LinkType.text,
  uat,
}) => {
  const { combinedClass } = useLinkModel({ className, color, disabled, size, type });

  const Component = to ? (target === '_blank' ? A : RouterLink) : Span;
  const href: string = target === '_blank' ? to : undefined;

  if (type === LinkType.text) {
    return (
      <Component
        className={combinedClass}
        to={to}
        target={target}
        href={href}
        onClick={onClick}
        data-uat={uat}
      >
        {children}
      </Component>
    );
  }

  if (type === LinkType.button || type === LinkType.outline) {
    return (
      <Component
        className={combinedClass}
        to={to}
        target={target}
        href={href}
        onClick={onClick}
        data-uat={uat}
      >
        <span className="tm2-link-text">{children}</span>
        <div className="tm2-link-container">
          <ArrowRightShort className="tm2-icon-arrow tm2-icon-fill" />
        </div>
      </Component>
    );
  }
};

const Span = ({ children, ...props }) => <span {...props}>{children}</span>;
const A = ({ children, ...props }) => <a {...props}>{children}</a>;
