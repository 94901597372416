import { FC } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { ButtonPrimary, ButtonSecondary } from '@components/button';
import { PermissionGroup } from '@permissions/core';
import { closeModalAction, showModalAction } from '@modules/modal';
import { configSelectors } from '@config/core';
import {
  AddFundsModal,
  permissionGroup as addFundsPermissionGroup,
} from 'containers/pages/deposits/add-funds-modal';
import {
  CurrencyExchangeModal,
  permissionGroup as currencyExchangePermissionGroup,
} from '../modals/currency-exchange';
import {
  MarketTransferModal,
  permissionGroup as marketTransferPermissionGroup,
} from '../modals/transfer';
import {
  CurrencyExchangeManualModal,
  permissionGroup as currencyExchangeManualPermissionGroup,
} from '../modals/currency-exchange-manual';
import { SecondaryMarketDisabledModal } from '../modals/secondary-market-disabled';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [
    addFundsPermissionGroup,
    currencyExchangePermissionGroup,
    currencyExchangeManualPermissionGroup,
    marketTransferPermissionGroup,
  ],
  marker: 'layout:wallet-actions',
});

type StateProps = {
  canTransfer: boolean;
};

type DispatchProps = {
  onAddFundsClicked: () => void;
  openTransferModal: () => void;
  openSecondaryMarketDisabledModal: () => void;
  onConvertClicked: () => void;
  onConvertManualClicked: () => void;
};

const mapStateToProps = (root): StateProps => ({
  canTransfer: !configSelectors.isSecondaryMarketDisabled(root),
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  onAddFundsClicked: () => dispatch(showModalAction({ modal: AddFundsModal })),
  openTransferModal: () => dispatch(showModalAction({ modal: MarketTransferModal })),
  openSecondaryMarketDisabledModal: () =>
    dispatch(showModalAction({ modal: SecondaryMarketDisabledModal })),
  onConvertClicked: () =>
    dispatch(
      showModalAction({
        modal: CurrencyExchangeModal,
        props: {
          onClose: () => {
            dispatch(closeModalAction(CurrencyExchangeModal));
          },
        },
      })
    ),
  onConvertManualClicked: () => dispatch(showModalAction({ modal: CurrencyExchangeManualModal })),
});

const ConnectedWalletActions: FC<StateProps & DispatchProps> = ({
  canTransfer,
  onAddFundsClicked,
  openTransferModal,
  openSecondaryMarketDisabledModal,
  onConvertClicked,
  onConvertManualClicked,
}) => {
  const onTransferClicked = canTransfer ? openTransferModal : openSecondaryMarketDisabledModal;

  return (
    <div className={s.walletActions}>
      {/*Watch: https://tm2support.atlassian.net/browse/TM2-3134*/}
      {/*<ButtonPrimary className="mb-md" fullWidth={true} onClick={onAddFundsClicked}>*/}
      {/*  <Trans id="wallet.button.add_funds">Add funds</Trans>*/}
      {/*</ButtonPrimary>*/}

      <ButtonSecondary className="mb-md" fullWidth={true} onClick={onConvertManualClicked}>
        <Trans id="wallet.convert">Convert</Trans>
      </ButtonSecondary>

      {/*<ButtonSecondary fullWidth={true} onClick={onTransferClicked}>*/}
      {/*  <Trans id="wallet.button.transfer">Transfer</Trans>*/}
      {/*</ButtonSecondary>*/}
    </div>
  );
};

export const WalletActions = connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedWalletActions);
