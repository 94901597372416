import { showModalAction } from '@modules/modal';
import { OnboardingStep } from '@config/core';
import { useCallDispatch } from '@hooks/core';
import {
  LiveUpdateEventType as LiveType,
  liveUpdateService as liveUpdate,
} from '@services/stomp/live-update';
import { AddBankAccountModal, AddBankAccountModalProps } from '../../../../modals/add-bank-account';
import { AddFundsModal } from '../../../../../../deposits/add-funds-modal';
import { ProfileMy } from '../../../../api';

export const useModel = (profile: ProfileMy) => {
  const showModal = useCallDispatch(showModalAction);
  const step: OnboardingStep = profile.accountOpeningStep || OnboardingStep.finished;

  const onHandleBankDetailsFormClicked = () => {
    if (step === OnboardingStep.bankDetails) {
      showModal<AddBankAccountModalProps>({
        props: {
          onAccountCreated: () => liveUpdate.emit({ type: LiveType.kycChanged }),
        },
        modal: AddBankAccountModal,
      });
    } else if (step === OnboardingStep.funds) {
      showModal({ modal: AddFundsModal });
    }
  };

  return { onHandleBankDetailsFormClicked, step };
};
