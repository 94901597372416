import { PermissionGroup, PermissionRecord, Permission } from '@permissions/core';
import { SecondaryMarketFeesPage } from './secondary-market';

// TODO: permissions
export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [new PermissionRecord(Permission.VIEW_FEES), new PermissionRecord(Permission.EDIT_FEES)],
  marker: 'layout:secondary-market-fees-tab',
});

export function TradeFeeSecondaryTab() {
  return <SecondaryMarketFeesPage />;
}
