import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';
import { Form as AntForm, Select as AntSelect } from 'antd';
import { defineMessage } from '@lingui/macro';
import { NamePath } from 'rc-field-form/lib/interface';

import { useI18n } from '../../../../../containers/services/commonService';
import { Label, useLabel } from '../label';
import { useFieldUat, useSelectOptionsUat } from '../uat';

const { Item } = AntForm;
const { Option } = AntSelect;

export interface SelectItem {
  disabled?: boolean;
  label: string;
  tooltip?: ReactNode;
  value: boolean;
}

export const SelectBoolean: FC<{
  className?: string;
  compact?: boolean;
  optionTexts?: { 0: ReactNode; 1: ReactNode };
  disabled?: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (v: boolean) => void;
  required?: boolean;
  uat?: string;
}> = ({
  className,
  compact,
  optionTexts,
  disabled,
  label,
  name,
  onChange,
  required,
  uat,
  ...props
}) => {
  const uatAttribute = useFieldUat(name, 'select-boolean', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-select', className, {
        'tm2-form-field-compact': compact,
      })}
      data-uat={uatAttribute}
    >
      <Item className="tm2-form-field-item" name={name} required={required}>
        <CustomSelect
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          onChangeExternal={onChange}
          optionTexts={optionTexts}
          {...props}
        />
      </Item>
    </div>
  );
};

const CustomSelect: FC<{
  optionTexts?: { 0: ReactNode; 1: ReactNode };
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (v: boolean, options?) => void;
  onChangeExternal?: (v: boolean) => void;
  required: boolean;
  value?: boolean;
  uat: string;
}> = (_props) => {
  const {
    optionTexts,
    disabled,
    label,
    name,
    onChange,
    onChangeExternal,
    required,
    value,
    ...props
  } = _props;
  const { i18n } = useI18n();
  const { shifted, onBlur, onFocus } = useLabel({ value: value === true || value === false });
  const uatClassName = useSelectOptionsUat(name, 'uat-options', props.uat);

  return (
    <>
      <Label htmlFor={name} label={label} shifted={shifted} required={required} />

      <AntSelect
        className="tm2-form-field-item-instance"
        dropdownClassName={`tm2-field-select-dropdown ${uatClassName}`}
        disabled={disabled}
        onBlur={onBlur}
        onChange={(value, options) => {
          onChangeExternal && onChangeExternal(!!value);
          return onChange(!!value, options);
        }}
        onFocus={onFocus}
        showArrow={true}
        value={value !== undefined && value !== null ? +value : undefined}
        {...props}
      >
        <Option className="tm2-field-select-dropdown-option" value={1}>
          {!optionTexts ? i18n._(defineMessage({ id: 'yes', message: 'Yes' })) : optionTexts['0']}
        </Option>
        <Option className="tm2-field-select-dropdown-option" value={0}>
          {!optionTexts ? i18n._(defineMessage({ id: 'no', message: 'No' })) : optionTexts['1']}
        </Option>
      </AntSelect>
    </>
  );
};
