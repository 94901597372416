import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Form, InputText, SubmitButton } from '@components/form';
import { CountrySelect } from '@modules/countries';
import { Col, Row } from '@components/grid';
import { DynamicForm } from '../../dynamic-form';
import { Fields, formId, useModel } from './model';

export const CompanyDetailsStep: FC = memo(() => {
  const m = useModel();

  return (
    <Form className={s.form} name={formId} initialValues={m.formInitial}>
      <h2>
        <Trans id="signup.company_details_step.title">Welcome!</Trans>
      </h2>

      <div className="mb-md">
        <Trans id="signup.company_details_step.text">Please provide your company details</Trans>
      </div>

      <Row>
        <Col sm={6}>
          <InputText
            name={Fields.companyName}
            label={<Trans id="signup.company_details_step.label.company_name">Company name</Trans>}
            disabled={m.lockUI}
            required={true}
          />
        </Col>

        <Col sm={6}>
          <CountrySelect
            name={Fields.countryOfIncorporation}
            label={
              <Trans id="signup.company_details_step.label.country_of_incorporation">
                Country of incorporation
              </Trans>
            }
            disabled={m.lockUI}
            required={true}
          />
        </Col>
      </Row>

      <DynamicForm formRows={m.formRows} lockUI={m.lockUI} />

      <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
        <Trans id="signup.company_details_step.button.continue">Continue</Trans>
      </SubmitButton>
    </Form>
  );
});
