import React, { FC, ReactNode } from 'react';
import classnames from 'classnames';

import s from './box.module.scss';

export const Box: FC<{
  children: ReactNode;
  className?: string;
  compact?: boolean;
  id?: string;
  onClick?: () => void;
  uat?: string;
}> = ({ className, children, compact = false, id, onClick, uat }) => (
  <div
    className={classnames(s.box, className, compact && s.compact)}
    id={id}
    onClick={onClick}
    data-uat={uat}
  >
    {children}
  </div>
);
