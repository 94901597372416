import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { CountrySelect } from '@modules/countries';
import { HasAccess, traderPermissions, Permission as P } from '@permissions/core';
import { InputHidden, InputPassword, InputPhone, InputText } from '@components/form';
import { OptionalComplianceOfficerIssuerSelect } from './compliance-officer-issuer';
import { OptionalTraderIssuerSelect } from './trader-issuer';
import { RolesSelect } from './role';
import { Fields, useFieldsAccess, useModel } from './model';
import t from '../index.module.scss';
import s from './index.module.scss';

export const GeneralTab: FC<{
  hidden: boolean;
}> = (p) => {
  const m = useModel();
  const a = useFieldsAccess();

  return (
    <div className={classnames(t.tab, s.row, { [t.hidden]: p.hidden })}>
      <RolesSelect
        className={s.field}
        label={<Trans id="user_form.role">Role</Trans>}
        onChange={(id: number) => a.onRoleChanged(id)}
        name={Fields.role}
        disabled={m.lock.role}
        required={true}
      />

      <InputText
        className={s.field}
        label={<Trans id="user_form.e_mail">E-mail</Trans>}
        name={Fields.email}
        disabled={m.lock.ui}
        required={true}
      />

      <HasAccess permissions={{ or: [P.INDIVIDUAL_PROFILE] }} userPermissions={a.permissions}>
        <InputText
          className={s.field}
          label={<Trans id="user_form.first_name">First name</Trans>}
          name={Fields.firstName}
          disabled={m.lock.ui}
          required={true}
        />
      </HasAccess>

      <HasAccess permissions={{ or: [P.INDIVIDUAL_PROFILE] }} userPermissions={a.permissions}>
        <InputText
          className={s.field}
          label={<Trans id="user_form.last_name">Last name</Trans>}
          name={Fields.lastName}
          disabled={m.lock.ui}
          required={true}
        />
      </HasAccess>

      <HasAccess permissions={{ or: [P.CORPORATE_PROFILE] }} userPermissions={a.permissions}>
        <InputText
          className={s.field}
          label={<Trans id="user_form.company">Company</Trans>}
          name={Fields.company}
          disabled={m.lock.ui}
          required={true}
        />
      </HasAccess>

      <HasAccess permissions={{ or: traderPermissions }} userPermissions={a.permissions}>
        <InputText
          className={s.field}
          label={<Trans id="user_form.secondary_market_id">Secondary Market id</Trans>}
          name={Fields.umsId}
          disabled={m.lock.umsId}
        />
      </HasAccess>

      <InputPhone
        className={s.field}
        label={<Trans id="user_form.phone">Phone</Trans>}
        name={Fields.phone}
        disabled={m.lock.ui}
        required={true}
      />

      <CountrySelect
        className={s.field}
        label={<Trans id="user_form.country">Country</Trans>}
        name={Fields.country}
        disabled={m.lock.ui}
        required={true}
      />

      <InputText
        className={s.field}
        label={<Trans id="user_form.postal_code">Postal code</Trans>}
        name={Fields.postalCode}
        disabled={m.lock.ui}
        required={true}
      />

      <InputText
        className={s.field}
        label={<Trans id="user_form.city">City</Trans>}
        name={Fields.city}
        disabled={m.lock.ui}
        required={true}
      />

      <InputText
        className={s.field}
        label={<Trans id="user_form.state_region">State / Region</Trans>}
        name={Fields.state}
        disabled={m.lock.ui}
        required={true}
      />

      <OptionalComplianceOfficerIssuerSelect
        className={s.field}
        label={<Trans id="user_form.issuer">Issuer</Trans>}
        name={Fields.complianceOfficerIssuer}
        userRole={a.role}
        disabled={m.lock.ui}
      />

      <OptionalTraderIssuerSelect
        className={s.field}
        label={<Trans id="user_form.issuer">Issuer</Trans>}
        name={Fields.investorIssuer}
        userPermissions={a.permissions}
        disabled={m.lock.ui}
      />

      <InputText
        className={s.field}
        label={<Trans id="user_form.street_and_number">Street and Number</Trans>}
        name={Fields.address}
        disabled={m.lock.ui}
      />

      <InputText
        className={classnames(s.field, s.address)}
        label={<Trans id="user_form.suit_unit_building">Suite / Unit / Building</Trans>}
        name={Fields.building}
        disabled={m.lock.ui}
        required={true}
      />

      <InputPassword
        className={s.field}
        label={<Trans id="user_form.password">Password</Trans>}
        name={Fields.password}
        disabled={m.lock.ui}
        required={m.isNewUser}
        autoComplete="new-password"
      />

      <InputPassword
        className={s.field}
        label={<Trans id="user_form.password_confirmation">Password confirmation</Trans>}
        name={Fields.matchingPassword}
        disabled={m.lock.ui}
        required={m.isNewUser}
      />

      {m.isNewUser ? null : <InputHidden name={Fields.id} type="number" />}
    </div>
  );
};
