import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { PageContent } from '@components/page';
import { PermissionGroup } from '@permissions/permission-group';
import { permissionGroup as tablePermissionGroup } from './table';
import { ManagementFeeReportTable } from './table';
import { ManagementFeeTitle } from './title-row';
import { ManagementFeeActions } from './actions-row';
import { actions } from './store';
import { permissionGroup as managementFeePermissionsGroup } from './api';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [managementFeePermissionsGroup, tablePermissionGroup],
  marker: 'layout:management-fee-report-sub-page',
});

export const ManagementFeeReportPage: FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(actions.onPageLeft());
    };
  }, []); // eslint-disable-line

  return (
    <PageContent uat="statements-management-fee-page">
      <ManagementFeeTitle />
      <ManagementFeeActions className="mb-md" />
      <ManagementFeeReportTable />
    </PageContent>
  );
};
