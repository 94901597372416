import React, { useMemo } from 'react';
import { Trans } from '@lingui/macro';

import type { ColumnType } from '@components/table2/base';
import type { Withdrawal } from '@router/pages/withdrawals/api';
import { ColumnKey } from '@router/pages/withdrawals/model';
import type { Model } from '@router/pages/withdrawals/model';
import { ActionsCell } from '@router/pages/withdrawals/actions-cell';
import { WithdrawalsStatusCell } from '@router/pages/withdrawals/status-cell';
import { ProfileLinkCell } from '@components/table2/utils';
import { Permission as P } from '@permissions/core';

import { formatMoney } from '../../services/commonService';
import s from './index.module.scss';

export const useColumns = (m: Model) => {
  const columns: Array<ColumnType<Withdrawal>> = useMemo(() => {
    return [
      {
        key: ColumnKey.actions,
        dataIndex: 'actions',
        headCell: () => <span>&nbsp;</span>,
        rowCell: ({ data }) => (
          <ActionsCell data={data} onReplaceWithdrawal={m.onReplaceWithdrawal} />
        ),
        width: 60,
      },
      {
        key: ColumnKey.number,
        dataIndex: 'number',
        headCell: () => <Trans id="withdrawals.number">№</Trans>,
        rowCell: ({ data }) => <>{data.id}</>,
        width: 60,
      },
      {
        key: ColumnKey.dateCreated,
        dataIndex: 'dateCreated',
        headCell: () => <Trans id="withdrawals.date_created">Date created</Trans>,
        rowCell: ({ data }) => <>{data.createdAt?.formatLocalDateTime()}</>,
        sorting: ['ascend', 'descend'],
        width: 200,
      },
      {
        key: ColumnKey.settledAt,
        dataIndex: 'settledAt',
        headCell: () => <Trans id="withdrawals.settled_at">Settled at</Trans>,
        rowCell: ({ data }) => <>{data.settledAt}</>,
        width: 200,
      },
      {
        key: ColumnKey.status,
        dataIndex: 'status',
        headCell: () => <Trans id="withdrawals.status">Status</Trans>,
        rowCell: ({ data }) => <WithdrawalsStatusCell status={data.status} />,
        width: 200,
      },
      ...(m.isHasAccess({ or: [P.WITHDRAWALS_VIEW_ALL] })
        ? [
            {
              key: ColumnKey.client,
              dataIndex: 'client',
              headCell: () => <Trans id="withdrawals.client">Client</Trans>,
              rowCell: ({ data }) => (
                <ProfileLinkCell
                  text={data.userBankAccount?.user?.displayName}
                  id={data.userBankAccount?.user?.id}
                />
              ),
              width: 200,
            },
            {
              key: ColumnKey.clientId,
              dataIndex: 'clientId',
              headCell: () => <Trans id="withdrawals.client_id">Client ID</Trans>,
              rowCell: ({ data }) => <>{data.userBankAccount?.user?.id}</>,
              sorting: ['ascend', 'descend'],
              width: 140,
            },
          ]
        : []),
      {
        key: ColumnKey.amount,
        dataIndex: 'amount',
        headCell: () => <Trans id="withdrawals.amount">Amount</Trans>,
        rowCell: ({ data }) => <>{formatMoney(data.amount)}</>,
        width: 140,
      },
      {
        key: ColumnKey.currency,
        dataIndex: 'currency',
        headCell: () => <Trans id="withdrawals.currency">Currency</Trans>,
        rowCell: ({ data }) => <>{data?.currency?.code}</>,
        width: 140,
      },
      {
        key: ColumnKey.amountSettled,
        dataIndex: 'amountSettled',
        headCell: () => <Trans id="withdrawals.amount_settled">Amount settled</Trans>,
        rowCell: ({ data }) => <>{data.amountSettled}</>,
        width: 200,
      },
      {
        key: ColumnKey.billingInfo,
        dataIndex: 'billingInfo',
        headCell: () => <Trans id="withdrawals.billing_info">Billing information</Trans>,
        rowCell: ({ data }) => (
          <div className={s.verticalFiller}>
            {!data.userBankAccount.nickname ? null : (
              <span className="mb1">
                <Trans id="withdrawals.nickname">Account nickname:</Trans>&nbsp;
                {data.userBankAccount.nickname}
              </span>
            )}
            <span className="mb1">
              <Trans id="withdrawals.bank_name">Bank name:</Trans>&nbsp;
              {data.userBankAccount.bankName}
            </span>
            <span className="mb1">
              <Trans id="withdrawals.account_number">Account number:</Trans>
              &nbsp;
              {data.userBankAccount.accountNumber}
            </span>
            <span className="mb1">
              <Trans id="withdrawals.swift_code">Swift code:</Trans>&nbsp;
              {data.userBankAccount.swiftCode}
            </span>
            {!data.userBankAccount.ibanNumber ? null : (
              <span className="mb1">
                <Trans id="withdrawals.iban">IBAN:</Trans>&nbsp;
                {data.userBankAccount.ibanNumber}
              </span>
            )}
            {!data.userBankAccount.comment ? null : (
              <span className="mb1">
                <Trans id="withdrawals.comment">Comment:</Trans>&nbsp;
                {data.userBankAccount.comment}
              </span>
            )}
          </div>
        ),
        width: 300,
      },
      ...(m.isHasAccess({ or: [P.WITHDRAWALS_VIEW_ALL] })
        ? [
            {
              key: ColumnKey.purpose,
              dataIndex: 'purpose',
              headCell: () => <Trans id="withdrawals.purpose">Purpose</Trans>,
              rowCell: ({ data }) => <>{data.purpose}</>,
              width: 200,
            },
            {
              key: ColumnKey.sourceOfFunds,
              dataIndex: 'sourceOfFunds',
              headCell: () => <Trans id="withdrawals.source_of_funds">Source of funds</Trans>,
              rowCell: ({ data }) => <>{data.sourceOfFunds}</>,
              width: 200,
            },
            {
              key: ColumnKey.externalId,
              dataIndex: 'externalId',
              headCell: () => <Trans id="withdrawals.external_id">External ID</Trans>,
              rowCell: ({ data }) => <>{data.externalId}</>,
              width: 200,
            },
          ]
        : []),
    ];
  }, []);

  return {
    columns,
  };
};
