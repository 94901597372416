import { UsersPageActionsTypes as Types } from './model';

const OnPageLeftAction = () => ({
  payload: null,
  type: Types.ON_PAGE_LEFT,
});

const OnPageOpenedAction = () => ({
  payload: null,
  type: Types.ON_PAGE_OPENED,
});

const OnSearchFilledAction = (text) => ({
  payload: text,
  type: Types.ON_SEARCH_FILLED,
});

const OnTableScrolledAction = () => ({
  payload: null,
  type: Types.ON_TABLE_SCROLLED,
});

const OnTableSortedAction = (columnInfo) => ({
  payload: columnInfo,
  type: Types.ON_TABLE_SORTED,
});

export const usersPageUserActions = {
  onPageLeft: OnPageLeftAction,
  onPageOpened: OnPageOpenedAction,
  onSearchFilled: OnSearchFilledAction,
  onTableScrolled: OnTableScrolledAction,
  onTableSorted: OnTableSortedAction,
};
