import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Form, InputPhone, InputText, SubmitButton } from '@components/form';
import { PrevStepButton } from '../../prev-button';
import { formId, Fields, useModel } from './model';

export const ContactDetailsStep: FC = memo(() => {
  const m = useModel();

  return (
    <Form name={formId} initialValues={m.formInitial} className={s.form}>
      <h2>
        <Trans id="signup.contact_details_step.title">Welcome!</Trans>
      </h2>

      <div className="mb-md">
        <Trans id="signup.contact_details_step.text">Please provide your contact details</Trans>
      </div>

      <InputText
        name={Fields.firstName}
        label={<Trans id="signup.contact_details_step.label.first_name">First name</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <InputText
        name={Fields.lastName}
        label={<Trans id="signup.contact_details_step.label.last_name">Last name</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <InputPhone
        name={Fields.phone}
        label={<Trans id="signup.contact_details_step.label.phone_number">Telephone number</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <div className={s.buttons}>
        <PrevStepButton className="mr3" disabled={m.lockUI} />

        <SubmitButton
          name={formId}
          disabled={m.lockUI}
          onAction={m.onFormAction}
          fullWidth={true}
          className={s.button}
        >
          <Trans id="signup.company_details_step.button.continue">Continue</Trans>
        </SubmitButton>
      </div>
    </Form>
  );
});
