import React, { memo } from 'react';
import { Trans } from '@lingui/macro';

import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputDate, InputNumber, SubmitButton } from '@components/form';

import { Fields, formId, formInitial, maxDate, useModel } from './model';
import { permissionGroup as apiPermissionsGroup } from './api';

export const permissionGroup = apiPermissionsGroup.createSuperGroup({
  marker: 'layout:account-statement-modal',
});

// These fields were needed for the rsql adapter, now they are not relevant.
// But they need to be saved for future use in other places on the project
// const F = Fields;
// const searchFields: Array<SearchItem> = [
//     {
//         fields: [Fields.clientId],
//         id: Fields.clientId,
//         label: <Trans id="account_statement_modal.client_id">Client ID</Trans>,
//         searchFormField: F.clientId,
//         type: 'long',
//     },
//     {
//         fields: [Fields.numberOfTransactions],
//         id: Fields.numberOfTransactions,
//         label: <Trans id="account_statement_modal.number_of_transaction">Number of transactions</Trans>,
//         searchFormField: F.numberOfTransactions,
//         type: 'long',
//     },
//     {
//         fields: [Fields.dateFrom],
//         id: Fields.dateFrom,
//         label: <Trans id="account_statement_modal.date_from">Date from:</Trans>,
//         searchFormField: F.dateFrom,
//         type: 'string',
//     },
//     {
//         fields: [Fields.dateTo],
//         id: Fields.dateTo,
//         label: <Trans id="account_statement_modal.date_to">Date to:</Trans>,
//         searchFormField: F.dateTo,
//         type: 'string',
//     },
// ];

export const AccountStatementModal: MFC = memo(({ isVisible }) => {
  const m = useModel(AccountStatementModal);

  return (
    <Modal isVisible={isVisible} loading={m.lockUI} disabled={m.lockUI} onClose={m.onCloseModal}>
      <ModalTitle>
        <Trans id="account_statement_modal.title">Client account statement</Trans>
      </ModalTitle>

      <ModalContent>
        {/*<SearchForm onRsqlChange={m.onSearchChange} fields={searchFields} memorize={true}>*/}
        <Form name={formId} initialValues={formInitial}>
          <InputNumber
            name={Fields.clientId}
            label={<Trans id="account_statement_modal.client_id">Client ID</Trans>}
            disabled={m.lockUI}
            required={true}
          />

          <InputNumber
            name={Fields.limit}
            label={
              <Trans id="account_statement_modal.number_of_transaction">
                Number of transactions
              </Trans>
            }
            disabled={m.lockUI}
          />

          <InputDate
            name={Fields.dateFrom}
            label={<Trans id="account_statement_modal.date_from">Date from:</Trans>}
            disabled={m.lockUI}
            max={m.dateTo}
            onChange={(date) => m.setDateFrom(date)}
          />

          <InputDate
            name={Fields.dateTo}
            label={<Trans id="account_statement_modal.date_to">Date to:</Trans>}
            disabled={m.lockUI}
            min={m.dateFrom}
            max={maxDate}
            required={true}
            onChange={(date) => m.setDateTo(date)}
          />
        </Form>
        {/*</SearchForm>*/}
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.download">Download</Trans>
        </SubmitButton>
        {/*<ButtonPrimary fullWidth={true}*/}
        {/*               disabled={m.lockUI}*/}
        {/*               onClick={m.onDownloadClicked}>*/}
        {/*    <Trans id="button.download">Download</Trans>*/}
        {/*</ButtonPrimary>*/}
      </ModalFooter>
    </Modal>
  );
});
