import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import { OnFormAction } from '@components/form';
import { notifySuccess } from '@modules/notify';
import v from '@components/form/validators';
import api, { PasswordFields as Fields, PasswordForm as Form } from '../api';

export { PasswordFields as Fields } from '../api';

export const formId = 'changePasswordForm';

export const useModel = (closeForm: () => void) => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onFormAction: OnFormAction = {
    error: () => setLockUI(false),
    submit: async (form: Form) => {
      setLockUI(true);
      await api.updatePassword(form);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="profile.security_tab.notification.success_update_password.text">
            Your password was successfully changed
          </Trans>
        ),
      });
      setLockUI(false);
      closeForm();
    },
    fieldValidation: {
      // more info here https://tm2support.atlassian.net/browse/TM2-2699
      [Fields.passwordConfirm]: [v.required, v.passwordConfirm(Fields.passwordNew)],
      [Fields.passwordNew]: [v.required, v.passwordNewEqualPrev(Fields.passwordOld)],
      [Fields.passwordOld]: [v.required],
    },
  };

  return {
    lockUI,
    onFormAction,
  };
};
