import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import s from './index.module.scss';
import { Permission } from '@permissions/core';
import { VerificationStatus as S } from '@models/core';
import { useI18n } from '@hooks/i18n';
import { Profile } from '../../../api.shared';

const progressValuePctAdapter = {
  [S.approved]: '100%',
  [S.documentRequested]: '50%',
  [S.processingVerification]: '75%',
  [S.rejected]: '0%',
  [S.resubmissionRequested]: '75%',
};

const accessPermissions = { or: [Permission.INDIVIDUAL_USER] };
export const useModel = (profile: Profile) => {
  const { i18n, lang } = useI18n();

  const status = profile?.verificationStatus;
  const progressValuePct = progressValuePctAdapter[status];

  const progressStatusText = useMemo(() => {
    const texts = {
      [S.approved]: i18n._(
        defineMessage({
          id: 'profile.card.verification_status.approved',
          message: 'Complete profile',
        })
      ),
      [S.documentRequested]: i18n._(
        defineMessage({
          id: 'profile.card.verification_status.document_requested',
          message: 'Document requested',
        })
      ),
      [S.processingVerification]: i18n._(
        defineMessage({
          id: 'profile.card.verification_status.processing',
          message: 'Processing verification',
        })
      ),
      [S.rejected]: i18n._(
        defineMessage({
          id: 'profile.card.verification_status.rejected',
          message: 'Verification rejected',
        })
      ),
      [S.resubmissionRequested]: i18n._(
        defineMessage({
          id: 'profile.card.verification_status.resubmission',
          message: 'Resubmission requested',
        })
      ),
    };
    return texts[status];
  }, [lang, status]);

  const statusColorClass = status === S.resubmissionRequested ? s.alert : '';

  return {
    accessPermissions,
    progressStatusText,
    progressValuePct,
    statusColorClass,
  };
};
