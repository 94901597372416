import { UserItem } from '../../users-api.service';
import { AddSMTAUserActionsTypes } from './user.acions';
import { AddSMTAServiceActionsTypes } from './service.acions';

export const addSMTAccountFormId = 'addSMTAccountFormId';

export enum AddSMTAFormFields {
  accountCode = 'accountCode',
  participantId = 'participantId',
}

export interface AddSMTAForm {
  [AddSMTAFormFields.accountCode]: string;
  [AddSMTAFormFields.participantId]: string;
}

export interface AddSMTAState {
  isAddSMTAccountLoading: boolean;
  user: UserItem;
}

export const addSMTAStateInitial = {
  isAddSMTAccountLoading: false,
  user: null,
};

export enum AddSMTAActionsTypes {
  ADD_SMT_ACCOUNT = 'ADD_SMT_ACCOUNT_MODAL.ADD_SMT_ACCOUNT',
  ADD_SMT_ACCOUNT_FAIL = 'ADD_SMT_ACCOUNT_MODAL.ADD_SMT_ACCOUNT_FAIL',
  ADD_SMT_ACCOUNT_SUCCESS = 'ADD_SMT_ACCOUNT_MODAL.ADD_SMT_ACCOUNT_SUCCESS',
  ON_CLOSE_MODAL_CLICKED = 'ADD_SMT_ACCOUNT_MODAL.ON_CLOSE_MODAL_CLICKED',
  ON_MODAL_OPENED = 'ADD_SMT_ACCOUNT_MODAL.ON_MODAL_OPENED',
}

export type AddSMTAActions = AddSMTAUserActionsTypes | AddSMTAServiceActionsTypes;
