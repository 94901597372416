import sign from 'tm2sign.macro';

import { stompClientService as stompClient } from '@services/stomp/client';
import { SelectItem } from '@components/form';
import { v } from '@helper/typer/field-typer.helper';
import { CoinInfo } from '../vaults-api.service';

// TODO перевести на makeQuery с пермишнами
//---------------------------------------------------------------------------
export interface Form {
  details: Array<BalanceDetailsItem>;
}
const vaultBalanceFields = [
  // same as in vault page
  { details: ['amount', { coin: ['asset', 'id'] }, { unit: ['id', 'label'] }] },
];
const getBalanceDetails = (id: number): Promise<Form> => {
  const getVaultFields = sign('vaultById', [
    { coins: ['asset', 'id'] },
    { lastVaultBalance: [...vaultBalanceFields] },
  ]);
  const typedValues = {
    ...v.long({ id }),
  };
  return stompClient
    .getData<{
      coins: Array<CoinInfo>;
      lastVaultBalance: {
        details: Array<{
          amount: number;
          coin: CoinInfo;
          unit: Unit;
        }>;
      };
    }>('vaultById', getVaultFields, typedValues)
    .then((response) => {
      const coins = response.coins;
      const details = response?.lastVaultBalance?.details;

      return coins.map((coin) => ({
        amount:
          details &&
          details.reduce((amount, detail) => {
            if (amount) {
              return amount;
            }
            return detail.coin.id === coin.id ? detail.amount : null;
          }, null as number),
        coin: coin,
        unit:
          details &&
          details.reduce((unit, detail) => {
            if (unit) {
              return unit;
            }
            return detail.coin.id === coin.id ? detail.unit : null;
          }, null as Unit),
      }));
    })
    .then((details) => ({
      details: details.map((item) => ({
        amount: item.amount,
        coin: {
          asset: item.coin?.asset,
          id: item.coin?.id,
        },
        report: {
          id: undefined,
        },
        unit: {
          id: item.unit?.id,
        },
      })),
    }));
};
//---------------------------------------------------------------------------
interface Unit {
  id: number;
  label: string;
}
const getUnits = (): Promise<Array<SelectItem>> => {
  const unitsFields = sign('units', ['id', 'label']);
  return stompClient.getData<Array<Unit>>('units', unitsFields).then((units) =>
    units.map((unit) => ({
      label: unit.label,
      value: unit.id,
    }))
  );
};
//---------------------------------------------------------------------------
export interface BalanceDetailsItem {
  amount: number;
  coin: {
    asset?: string;
    id: number;
  };
  report: {
    id: number;
  };
  unit: {
    id: number;
  };
}
const saveBalanceDetails = (vaultId: number, details: Array<BalanceDetailsItem>): Promise<void> => {
  const savedVaultFields = sign('saveVaultBalance', ['id']);
  const typedValues = {
    ...v.saveVaultBalanceInput({
      vaultBalance: {
        details: details.map((detail) => ({
          ...detail, // note: we pass all need object fields due bad backend validation
          amount: detail.amount,
          coin: {
            id: detail.coin.id,
          },
          report: { ...detail.report },
          unit: { ...detail.unit },
        })),
        vault: {
          id: vaultId,
        },
      },
    }),
  };
  return stompClient.sendData('saveVaultBalance', savedVaultFields, typedValues);
};

export default {
  getBalanceDetails,
  getUnits,
  saveBalanceDetails,
};
