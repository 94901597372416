import { useLayoutEffect, useRef } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { useAttachedState, useSyncRef } from '../utils';
import type { RefCallback } from 'react';

// This module guarantees that the next load call won't be made unless
// the previous is finished. If an error happens during the loading, the
// component will prevent following loads until the key is updated (error
// flag will be passed to the renderer component).

type LoadCheck = () => boolean;

export type NotifyRender = () => void;

export type OnLoadMore<T> = (params: { lastIndexData: T | undefined }) => Promise<{ indexData: T }>;

export type SetScrollableRef = RefCallback<HTMLElement>;

export type ListRendererParams = {
  error: boolean;
  hasMore: boolean;
  loading: boolean;
  notifyRender: NotifyRender;
  setScrollableRef: SetScrollableRef;
};

export type ListRenderer = (params: ListRendererParams) => JSX.Element;

export type ListProps<T> = {
  children: ListRenderer;
  hasMore: boolean;
  onLoadMore: OnLoadMore<T>;
  triggerDistance?: number;
};

const DEFAULT_TRIGGER_DISTANCE = 50;

function isScrollAtBottom(el: HTMLElement, diffPx: number) {
  if (el.clientHeight === 0) {
    return true;
  }
  const currentDiffPx = el.scrollHeight - (el.scrollTop + el.clientHeight);
  return currentDiffPx < diffPx;
}

export function LoadableList<T>(props: ListProps<T>) {
  const [state, setState] = useAttachedState({
    error: false,
    loading: false,
  });
  const errorRef = useSyncRef(state.error);
  const iterationLoadingRef = useRef(false);
  const indexDataRef = useRef<T | undefined>();
  const containerRef = useRef<HTMLElement | null>(null);

  const loadCheck = useCallbackOne<LoadCheck>(() => {
    if (!containerRef.current) return false;
    return isScrollAtBottom(
      containerRef.current,
      props.triggerDistance ?? DEFAULT_TRIGGER_DISTANCE
    );
  }, [props.triggerDistance]);
  const loadCheckRef = useSyncRef(loadCheck);

  const loadMoreScheduledRef = useRef(false);
  const hasMoreRef = useSyncRef(props.hasMore);
  const hasMoreEffectRef = useRef({ active: false });

  // During the load promise resolving the data coming from the promise
  // could be rendered before the resolve. In this case we need to initiate
  // another load to get a check with the latest state of the ui.
  const finishIterationLoad = useCallbackOne((params: { error: boolean }) => {
    iterationLoadingRef.current = false;
    if (params.error) {
      setState({ error: true, loading: false });
      return;
    }
    if (!loadMoreScheduledRef.current) {
      setState({ error: false, loading: false });
      return;
    }
    loadMoreScheduledRef.current = false;
    tryLoadMoreRef.current();
  }, []);

  const checkAndMaybeLoad = useCallbackOne(async () => {
    const checkPassed = loadCheck();

    if (!checkPassed || errorRef.current || !hasMoreRef.current) {
      iterationLoadingRef.current = false;
      setState((s) => ({ ...s, loading: false }));
      return;
    }
    // Only set loading after passing load check.
    setState((s) => ({ ...s, loading: true }));

    const { indexData } = await props.onLoadMore({ lastIndexData: indexDataRef.current });
    indexDataRef.current = indexData;
  }, [props.onLoadMore]);

  const tryLoadMore = useCallbackOne(() => {
    if (iterationLoadingRef.current || errorRef.current || !hasMoreRef.current) {
      return;
    }
    iterationLoadingRef.current = true;

    checkAndMaybeLoad()
      .then(() => {
        finishIterationLoad({ error: false });
      })
      .catch((e) => {
        if (process.env.NODE_ENV === 'development') {
          console.error(e);
        }
        finishIterationLoad({ error: true });
      });
  }, [checkAndMaybeLoad, finishIterationLoad]);

  // Prevent notifyRender from changing reference.
  const tryLoadMoreRef = useSyncRef(tryLoadMore);

  const notifyRender = useCallbackOne<NotifyRender>(() => {
    if (iterationLoadingRef.current) {
      loadMoreScheduledRef.current = true;
      return;
    }
    tryLoadMoreRef.current();
  }, []);

  const onScroll = useCallbackOne(() => {
    const checkPassed = loadCheckRef.current();
    const isLoading = iterationLoadingRef.current;

    // After loading and rendering we expect notifyRender call from the
    // renderer. Because of this we do not handle loading state inside onScroll.
    if (!isLoading && checkPassed) {
      tryLoadMoreRef.current();
    }
  }, []);

  const setScrollableRef = useCallbackOne<SetScrollableRef>(
    (el) => {
      containerRef.current?.removeEventListener('scroll', onScroll);
      containerRef.current = el;

      // Use optional chaining to prevent exceptions during hot reload.
      containerRef.current?.addEventListener('scroll', onScroll, { passive: true });
    },
    [onScroll]
  );

  useLayoutEffect(() => {
    // We want to ignore first time load for each key, since it's
    // delegated via notifyRender.
    if (!hasMoreEffectRef.current.active) {
      hasMoreEffectRef.current.active = true;
      return;
    }
    if (!props.hasMore) return;
    tryLoadMoreRef.current();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hasMore]);

  return props.children({
    error: state.error,
    hasMore: props.hasMore,
    loading: state.loading,
    notifyRender,
    setScrollableRef,
  });
}
