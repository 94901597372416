import { useState } from 'react';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { OnFormAction } from '@components/form';
import v from '@components/form/validators';
import api, { Form } from './api';

export const formId = 'denyRedeem';

export enum Fields {
  reason = 'reason',
}

export const useModel = (redeemId, replaceRedeem, modal) => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onClose = useCallDispatch(closeModalAction);

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      [Fields.reason]: [v.required],
    },
    error: (error) => {
      handleBackendError(error);
      setLockUI(false);
    },
    submit: async (form) => {
      setLockUI(true);
      const deniedRedeem = await api.denyRedeem(redeemId, form[Fields.reason]);
      replaceRedeem(deniedRedeem);
      setLockUI(false);
      onClose(modal);
    },
  };

  return {
    lockUI,
    onClose,
    onFormAction,
  };
};
