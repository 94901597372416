import { Dispatch, SetStateAction } from 'react';
import { History as _History } from 'history';
import { TableHeaderSortType } from '../../components/page-table';

// @ts-ignore
export type Any = any; // this type should be used only as temporary solution

export type History = _History;

export enum Lang {
  cn = 'CN',
  en = 'EN',
}

export enum KKLang {
  cn = 'zh-CN',
  en = 'en',
}

export enum HTMLLang {
  cn = 'zh',
  en = 'en',
}

export const AssetType = {
  fiat: 'FIAT_SPOT',
  metal: 'METALS',
};

export interface SortItem<T = string> {
  direction: TableHeaderSortType;
  fields?: Array<string>;
  id: T;
}

export interface TableResponse<T = any> {
  data: Array<T>;
  hasMore: boolean;
  index: number;
}

export interface TableData<T> {
  data: Array<T>;
  isHasMore: boolean;
}

export type OnTableSorted<D> = (config: SortItem<D>) => void;

export type OnTableScrolled = () => void;

export type ColumnSortDirection<T> = (id: T) => void;

export type UseState<T> = [T, Dispatch<SetStateAction<T>>];

export interface UploadedFile {
  attached: boolean;
  contentType: string;
  createdAt: string;
  id: number;
  name: string;
  path: string;
  size: number;
  uuid: string;
}

export enum TM2Contour {
  development = 'development',
  production = 'production',
}

/**
 * Global TM2 object stored in window._TM2
 */
export interface TM2 {
  config: {
    contour: TM2Contour;
  };
  globalErrorHandlerTmp?: Function;
}

export interface AppMeta {
  buildVersion: string;
}

export enum VerificationStatus {
  approved = 'APPROVED',
  documentRequested = 'DOCUMENT_REQUESTED',
  processingVerification = 'PROCESSING_VERIFICATION',
  rejected = 'REJECTED',
  resubmissionRequested = 'RESUBMISSION_REQUESTED',
}

export enum WithdrawalStatus {
  approved = 'APPROVED',
  cancelled = 'CANCELLED',
  cancelledError = 'CANCELLED_ERROR',
  cancelledPending = 'CANCELLED_PENDING',
  checked = 'CHECKED',
  checkedError = 'CHECKED_ERROR',
  checkedPending = 'CHECKED_PENDING',
  creationError = 'CREATION_ERROR',
  denied = 'DENIED',
  pending = 'PENDING',
  pendingError = 'PENDING_ERROR',
  preApproved = 'PRE_APPROVED',
  processed = 'PROCESSED',
  processedError = 'PROCESSED_ERROR',
  processedPending = 'PROCESSED_PENDING',
}
