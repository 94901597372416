import { useContext, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { OnFormAction } from '@components/form';
import { configSelectors } from '@config/core';
import { countriesSelectors } from '@modules/countries';
import api from '../../api';
import { Context } from '../../context';
import { useFormInfo } from '../../hooks';

export const formId = 'addressInfoFormId';

export enum Fields {
  country = 'country',
}

interface Form {
  [Fields.country]: number;
}

export const useModel = () => {
  const { dispatch } = useContext(Context);
  const [isSendFormLoading, setSendFormLoading] = useState<boolean>();
  const userInfo = useSelector(configSelectors.userInfo);
  const isUserInfoLoading = useSelector(configSelectors.isUserInfoLoading);
  const isCountriesLoading = useSelector(countriesSelectors.isLoading);
  const m = useFormInfo('provideAddressInformationFormStructure');

  const countryId = userInfo?.country?.id;
  const formInitialResponse = m?.formInitial;

  const formInitial = useMemo(
    () => ({
      ...formInitialResponse,
      country: countryId,
    }),
    [countryId, formInitialResponse]
  );

  const onFormAction: OnFormAction<Form> = {
    error: () => setSendFormLoading(false),
    submit: async (form: Form) => {
      setSendFormLoading(true);
      await api.sendAddressInfoForm(form, Fields);
      setSendFormLoading(false);
      dispatch({ type: 'ON_STEP_COMPLETED' });
    },
  };

  return {
    formInitial: formInitial,
    formRows: m.formRows,
    lockUI: m.isFormLoading || isUserInfoLoading || isSendFormLoading || isCountriesLoading,
    onFormAction: onFormAction,
  };
};
