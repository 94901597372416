import { Permission as P, permissionsSelectors, Role as R } from '@permissions/core';
import { Selector } from '@models/redux';
import { modalSelectors } from 'shared/modules/modal/store/selectors';
import { SortOrder } from '@components/table2/autosized';
import { VaultInfo } from '../../vaults-api.service';
import { VaultsPageState } from './model';

const vaultsPageState: Selector<VaultsPageState> = (root) => root.main?.vaults?.vaultsPage;

const isCanSeeBalance: Selector<boolean> = (root) =>
  permissionsSelectors.isHasAccess(root)({ or: [P.VAULT_VIEW_REPORTS] }, null, null, [
    R.issuer,
    R.vaultManager,
  ]);

const lastUpdatedVaultId: Selector<number | null> = (root) =>
  vaultsPageState(root).lastUpdatedVaultId;

const vaultUpdateCounter: Selector<number | null> = (root) =>
  vaultsPageState(root).vaultUpdateCounter;

const lockUI: Selector<boolean> = (root) => {
  const isModalOpened = modalSelectors.isModalOpened(root);
  const state = vaultsPageState(root);
  return isModalOpened || state.isDeleteVaultLoading;
};

const vaults: Selector<Array<VaultInfo>> = (root) => vaultsPageState(root).vaults;

const listKey: Selector<number> = (root) => vaultsPageState(root).listKey;

const sortOrder: Selector<SortOrder> = (root) => vaultsPageState(root).sortOrder;

const hasMore: Selector<boolean> = (root) => vaultsPageState(root).hasMore;

export const vaultsPageSelectors = {
  isCanSeeBalance,
  lastUpdatedVaultId,
  lockUI,
  vaults,
  listKey,
  sortOrder,
  hasMore,
  vaultsPageState,
  vaultUpdateCounter,
};
