import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { PageTable } from 'components/page-table';
import { transferRequestsPageSelectors as requestsSelectors } from '../store/selectors';
import { transferRequestsPageUserActions as requestsUserActions } from '../store/user.acions';
import { TransferRequestsTableColumnIds as ColumnIds } from '../store/model';
import { DirectionAndAssetCell } from './direction-cell';
import { StateCell } from './state-cell';
import { ClientCell } from './client-cell';

const mapStateToProps = (root) => ({
  columnSortDirection: requestsSelectors.columnSortDirection(root),
  isHasMore: requestsSelectors.isHasMore(root),
  isTableLoading: requestsSelectors.isTableLoading(root),
  transferRequests: requestsSelectors.transferRequests(root),
});

const mapDispatchToProps = (dispatch) => ({
  onTableScrolled: () => dispatch(requestsUserActions.onTableScrolled()),
  onTableSorted: (columnInfo) => dispatch(requestsUserActions.onTableSorted(columnInfo)),
});

const ConnectedTransferRequestsTable = ({
  columnSortDirection,
  isHasMore,
  isTableLoading,
  onTableScrolled,
  onTableSorted,
  transferRequests,
}) => {
  return (
    <PageTable
      onLoadMore={() => onTableScrolled()}
      isLoadingInProgress={isTableLoading}
      isShowLoader={true}
      initialLoad={true}
      pageData={{
        data: transferRequests,
        hasMore: isHasMore,
      }}
      columns={[
        {
          cell: (request) => <span>{request.id}</span>,
          head: {
            body: <Trans id="transfer_requests_page.table.head.index">№</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.id, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.id),
          },
        },
        {
          cell: (request) => <span>{request.createdAt.formatLocalDateTime()}</span>,
          head: {
            body: <Trans id="transfer_requests_page.table.head.date_created">Date created</Trans>,
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.dateCreated, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.dateCreated),
          },
        },
        {
          cell: (request) => <StateCell state={request.state} />,
          head: {
            body: <Trans id="transfer_requests_page.table.head.status">Status</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.status, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.status),
          },
        },
        {
          cell: (request) => <ClientCell user={request.user} />,
          head: {
            body: <Trans id="transfer_requests_page.table.head.client">Client</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.client, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.client),
          },
        },
        {
          cell: (request) => <span>{request.user.id}</span>,
          head: {
            body: <Trans id="transfer_requests_page.table.head.client_id">Client ID</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.clientId, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.clientId),
          },
        },
        {
          cell: (request) => (
            <div className="txt-center">
              <DirectionAndAssetCell type={request.type} />
            </div>
          ),
          head: {
            body: (
              <Trans id="transfer_requests_page.table.head.direction_and_asset">
                Direction & Asset
              </Trans>
            ),
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.directionAndAsset, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.directionAndAsset),
          },
        },
        {
          cell: (request) => <span>{request.asset}</span>,
          head: {
            body: <Trans id="transfer_requests_page.table.head.asset">Asset</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.asset, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.asset),
          },
        },
        {
          cell: (request) => <span>{request.amount}</span>,
          head: {
            body: <Trans id="transfer_requests_page.table.head.amount">Amount</Trans>,
            onSort: (direction) => onTableSorted({ id: ColumnIds.amount, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.amount),
          },
        },
        {
          cell: (request) => <span>{request.changedAt.formatLocalDateTime()}</span>,
          head: {
            body: (
              <Trans id="transfer_requests_page.table.head.date_changed">
                Date last status change
              </Trans>
            ),
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.dateChanged, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.dateChanged),
          },
        },
      ]}
    />
  );
};

export const TransferRequestsTable = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTransferRequestsTable);
