import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Text } from '@components/typography/text';
import { AddRowButton, DeleteRowButton, Form, FormList, InputFile } from '@components/form';
import { Fields, formId, initialValues } from './model';

export const VerificationUploadForm: FC<{
  className?: string;
  disabled: boolean;
}> = (p) => {
  return (
    <Form name={formId} initialValues={initialValues} className={p.className}>
      <Text className="mb3" level={1}>
        <Trans id="verification_upload_form.title">
          Provide your identity document (Passport, Photo ID card, Driving licence, Residence
          permit) and Utility bill for visual scanning. Ensure that it is not expired or physically
          damaged
        </Trans>
      </Text>

      <FormList name={Fields.identities}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div className={s.row} key={key}>
                <InputFile
                  name={[name, Fields.identityFile]}
                  fieldKey={[key, Fields.identityFile]}
                  label={
                    <Trans id="verification_upload_form.label.identity">Identity document</Trans>
                  }
                  placeholder={
                    <Trans id="verification_upload_form.placeholder.file">Add file</Trans>
                  }
                  disabled={p.disabled}
                  uat="identity"
                  required
                />

                {fields.length < 2 ? null : (
                  <DeleteRowButton className="ml1" onClick={() => remove(name)} />
                )}
              </div>
            ))}

            {/*{fields.length > 2 ? null : ( // TODO загрузка нескольких документов не готова на бекенде
                            <AddRowButton className={s.addDocButton}
                                          text={<Trans id="verification_upload_form.label.add_document">Add document</Trans>}
                                          onClick={() => add()} />
                        )}*/}
          </>
        )}
      </FormList>

      <FormList name={Fields.utilities}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name }) => (
              <div className={s.row} key={key}>
                <InputFile
                  name={[name, Fields.utilityFile]}
                  fieldKey={[key, Fields.utilityFile]}
                  label={
                    <Trans id="verification_upload_form.label.utility_bill">Utility bill</Trans>
                  }
                  extra={
                    <Trans id="verification_upload_form.label.utility_bill.extra">
                      Less than 90 days old
                    </Trans>
                  }
                  placeholder={
                    <Trans id="verification_upload_form.placeholder.file">Add file</Trans>
                  }
                  disabled={p.disabled}
                  uat="utility-bill"
                  required
                />

                {fields.length < 2 ? null : (
                  <DeleteRowButton className="ml1" onClick={() => remove(name)} />
                )}
              </div>
            ))}

            {/*{fields.length > 2 ? null : ( // TODO загрузка нескольких документов не готова на бекенде
                            <AddRowButton className={s.addBillButton}
                                          text={<Trans id="verification_upload_form.label.add_bill">Add bill</Trans>}
                                          onClick={() => add()} />
                        )}*/}
          </>
        )}
      </FormList>
    </Form>
  );
};
