import React, { FC, ReactNode, useMemo } from 'react';
import classnames from 'classnames';
import { Form as AntForm, Select as AntSelect, SelectProps } from 'antd';
import { defineMessage } from '@lingui/macro';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { Tooltip } from '@components/tooltip';
import { useI18n } from '@hooks/i18n';
import Label, { useLabel } from '../label';
import { useFieldUat, useSelectOptionsUat } from '../uat';

const { Item } = AntForm;
const { Option } = AntSelect;

export interface SelectItem {
  disabled?: boolean;
  label: string;
  tooltip?: ReactNode;
  value: string | number;
}

export interface SelectComponentProps {
  allowEmpty?: boolean;
  className?: string;
  compact?: boolean;
  data: Array<SelectItem>;
  disabled?: boolean;
  extra?: ReactNode;
  fieldKey?: React.Key | React.Key[];
  initialValue?: string | number;
  label: ReactNode;
  name: NamePath;
  onChange?: (v) => void;
  required?: boolean;
  mode?: SelectProps<any>['mode'];
  uat?: string;
}
export const SelectRaw: FC<
  SelectComponentProps & {
    compactClass: string;
    dropdownClass: string;
    extraClass: string;
    instanceClass: string;
    itemClass: string;
    labelClass: string;
    labelShiftedClass: string;
    optionClass: string;
    wrapperClass: string;
  }
> = ({
  allowEmpty,
  className,
  compact,
  data,
  disabled,
  extra,
  fieldKey,
  initialValue,
  label,
  name,
  onChange,
  required,
  mode,
  uat,
  ...p
}) => {
  const uatAttribute = useFieldUat(name, 'select', uat);

  return (
    <div
      className={classnames(p.wrapperClass, className, {
        [p.compactClass]: compact,
        [p.extraClass]: !!extra,
      })}
      data-uat={uatAttribute}
    >
      <Item
        className={p.itemClass}
        name={name}
        fieldKey={fieldKey}
        required={required}
        extra={extra}
        initialValue={initialValue}
        {...p}
      >
        <CustomSelect
          name={name}
          instanceClass={p.instanceClass}
          label={label}
          labelClass={p.labelClass}
          labelShiftedClass={p.labelShiftedClass}
          dropdownClass={p.dropdownClass}
          optionClass={p.optionClass}
          required={required}
          disabled={disabled}
          onChangeExternal={onChange}
          allowEmpty={allowEmpty}
          data={data}
          mode={mode}
          uat={uat}
        />
      </Item>
    </div>
  );
};

const CustomSelect: FC<{
  allowEmpty?: boolean;
  data: Array<SelectItem>;
  disabled: boolean;
  dropdownClass: string;
  instanceClass: string;
  label: ReactNode;
  labelClass: string;
  labelShiftedClass: string;
  name: NamePath;
  onChange?: (v: string | number, options?) => void;
  onChangeExternal?: (v: string | number) => void;
  optionClass: string;
  required: boolean;
  value?: string;
  mode?: SelectProps<any>['mode'];
  uat?: string;
}> = (props) => {
  const {
    allowEmpty,
    data,
    disabled,
    label,
    name,
    onChange,
    onChangeExternal,
    required,
    value,
    mode,
  } = props;
  const { i18n } = useI18n();

  const isArrayValue = Boolean(Array.isArray(value) && value.length);
  // TODO: fix typings for CustomSelect - `value` prop of this component is
  //  defined by the parent Item component.
  // @ts-ignore
  const isDataValue = Boolean(data?.some((d) => value === d?.value || value === d));
  const isSetToAny = allowEmpty && value === null;
  const { shifted, onBlur, onFocus } = useLabel({
    value: isArrayValue || isDataValue || isSetToAny,
  });

  const options: Array<SelectItem> = useMemo(() => {
    if (allowEmpty) {
      return [
        {
          label: i18n._(defineMessage({ id: 'option.any', message: 'Any' })),
          value: null,
          disabled: false,
        },
        ...data,
      ];
    }
    return data || [];
  }, [data]);

  const uatClassName = useSelectOptionsUat(name, 'uat-options', props.uat);

  return (
    <>
      <Label
        htmlFor={name}
        label={label}
        labelClass={props.labelClass}
        labelShiftedClass={props.labelShiftedClass}
        shifted={shifted}
        required={required}
      />
      <AntSelect
        className={props.instanceClass}
        dropdownClassName={`${props.dropdownClass || ''} ${uatClassName}`}
        disabled={disabled}
        filterOption={(search, option) => {
          const searchText = search?.toLowerCase();
          // TODO-2833: fix types
          // @ts-ignore
          const optionText = option?.children?.toLowerCase && option.children.toLowerCase();
          return optionText?.indexOf(searchText) > -1;
        }}
        onBlur={onBlur}
        onChange={(value, options) => {
          onChangeExternal && onChangeExternal(value);
          return onChange(value, options);
        }}
        onFocus={onFocus}
        showArrow={true}
        showSearch={true}
        notFoundContent={i18n._(
          defineMessage({ id: 'shared.select.no_results', message: 'No results' })
        )}
        value={value}
        mode={mode}
      >
        {options.map((item: SelectItem, index: number) => (
          <Option
            className={props.optionClass}
            disabled={item.disabled}
            value={item.value}
            key={`${index}-${item.value}`}
          >
            {!item.tooltip ? (
              item.label
            ) : (
              <Tooltip title={item.tooltip} placement="top" rawChild={true} trigger={['hover']}>
                {item.label}
              </Tooltip>
            )}
          </Option>
        ))}
      </AntSelect>
    </>
  );
};

export default SelectRaw;
export const Select: FC<SelectComponentProps> = (props) => (
  <SelectRaw
    {...props}
    compactClass="tm2-form-field-compact"
    dropdownClass="tm2-field-select-dropdown"
    extraClass="tm2-form-field-extra"
    instanceClass="tm2-form-field-item-instance"
    itemClass="tm2-form-field-item"
    labelClass="tm2-form-label"
    labelShiftedClass="tm2-form-label-shifted"
    optionClass="tm2-field-select-dropdown-option"
    wrapperClass="tm2-form-field tm2-field-select"
  />
);
