import React, { FC } from 'react';
import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { Trans } from '@lingui/macro';
import { ButtonIconPure, ButtonPrimary } from '@components/button';
import { ReactComponent as RemoveIcon } from '@images/icons/cross-icon.svg';
import { Text } from '@components/typography/text';
import { UserGoalModal } from '../../modals/user-goal';
import { Box } from '@components/box';
import { useModel } from './model';

export const InvestorsHelper: FC = () => {
  const m = useModel({ modal: UserGoalModal });

  return !m.isShowCustomerSurvey ? null : (
    <Box className="mb1">
      <div className={s.helperHeader}>
        <Title className="mb2" level={1}>
          <Trans id="primary_market.page.helper.title">Don't know which metal to choose?</Trans>
        </Title>

        <ButtonIconPure
          icon={<RemoveIcon className="tm2-icon-stroke" />}
          disabled={m.lockUI}
          onClick={m.onHideCustomerSurvey}
        ></ButtonIconPure>
      </div>

      <Text className="mb1">
        <Trans id="primary_market.page.helper.text">
          TM2 organizes simple products categorized by Tickers, they are meant to represent the
          metal and the issuer providing the metal on the platform. Some tickers are generic meaning
          that they offer direct exposure to high purity standards of each metal.
        </Trans>
      </Text>

      <div className={s.helperFooter}>
        <Text className="mb2">
          <Trans id="primary_market.page.helper.footer_text">
            Answer a few questions and we will select the most suitable options
          </Trans>
        </Text>

        <ButtonPrimary className="mb2" disabled={m.lockUI} onClick={m.onToBeginClicked}>
          <Trans id="primary_market.page.helper.to_begin">To begin</Trans>
        </ButtonPrimary>
      </div>
    </Box>
  );
};
