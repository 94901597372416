import { CirclePreloader } from '@components/preloader/index.strict';
import s from './index.module.scss';

export function Loader() {
  return (
    <div className={s.loaderContainer}>
      <div className={s.loaderBackdrop} />
      <CirclePreloader className={s.loader} contrast />
    </div>
  );
}
