import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, PermissionGroup, Permission } from '@permissions/core';
import { TableResponse } from '@models/core';
import { SortOrder as QuerySortOrder } from '@models/sorting';
import { stompClientService as stompClient } from '@services/stomp/client';

export type ReferralItem = {
  id: number;
  email: string;
  affiliateId: string;
  issuedAt: string;
};

export enum GetReferralsSortKey {
  affiliateId = 'affiliateId',
  email = 'email',
  id = 'id',
  issuedAt = 'issuedAt',
}

export type GetReferralsSortOrder = QuerySortOrder<GetReferralsSortKey>;

const getReferralsQuery = 'affiliatedUsersPage';
const getReferralsFields = [
  {
    data: ['id', 'email', 'affiliateId', 'issuedAt'],
  },
  'index',
  'hasMore',
];
export const getReferrals = makeQuery({
  permissions: Permission.REFERRALS_VIEW_REPORT,
  queryName: getReferralsQuery,
  queryFields: getReferralsFields,
  query: (
    pageNumber: number,
    ordering: GetReferralsSortOrder
  ): Promise<TableResponse<ReferralItem>> => {
    const typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };

    return stompClient.getData(
      getReferralsQuery,
      sign(getReferralsQuery, getReferralsFields),
      typedValues
    );
  },
});

const api = {
  getReferrals,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:referrals-report-page');

export default api;
