import { createSelector } from 'reselect';
import { RootState as RS } from '@models/redux';
import { configSelectors } from '@config/core';
import { CreditCardViewForm, CreditCardViewState as State } from './model';

const s = (r: RS): State => r.main?.deposits?.addFunds?.creditCard;

const bankAccounts = (r: RS) => s(r).bankAccounts;

const formInitial: (r: RS) => CreditCardViewForm = createSelector(
  [(r: RS) => configSelectors.userInfo(r)],
  (user) =>
    ({
      address: user.address,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    } as CreditCardViewForm)
);

const lockUI = (r: RS) => s(r).isPrepareCreditCardLoading;

export const creditCardViewSelectors = {
  bankAccounts,
  formInitial,
  lockUI,
};
