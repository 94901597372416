import { useMemo } from 'react';
import { defineMessage } from '@lingui/macro';

import { useI18n } from '@hooks/i18n';
import { WithdrawalStatus } from '@models/core';

export const useWithdrawalsStatuses = () => {
  const { i18n, lang } = useI18n();

  return useMemo(() => {
    return {
      [WithdrawalStatus.approved]: i18n._(
        defineMessage({ id: 'invoice_statuses.approved', message: 'Approved' })
      ),
      [WithdrawalStatus.cancelled]: i18n._(
        defineMessage({ id: 'invoice_statuses.cancelled', message: 'Cancelled' })
      ),
      [WithdrawalStatus.cancelledError]: i18n._(
        defineMessage({ id: 'invoice_statuses.cancelled_error', message: 'Error cancellation' })
      ),
      [WithdrawalStatus.cancelledPending]: i18n._(
        defineMessage({ id: 'invoice_statuses.cancelled_pending', message: 'Cancelled pending' })
      ),
      [WithdrawalStatus.checked]: i18n._(
        defineMessage({ id: 'invoice_statuses.checked', message: 'Checked' })
      ),
      [WithdrawalStatus.checkedError]: i18n._(
        defineMessage({ id: 'invoice_statuses.checked_error', message: 'Checked error' })
      ),
      [WithdrawalStatus.checkedPending]: i18n._(
        defineMessage({ id: 'invoice_statuses.checked_pending', message: 'Checked pending' })
      ),
      [WithdrawalStatus.creationError]: i18n._(
        defineMessage({ id: 'invoice_statuses.creation_error', message: 'Creation error' })
      ),
      [WithdrawalStatus.denied]: i18n._(
        defineMessage({ id: 'invoice_statuses.denied', message: 'Denied' })
      ),
      [WithdrawalStatus.pending]: i18n._(
        defineMessage({ id: 'invoice_statuses.pending', message: 'Pending' })
      ),
      [WithdrawalStatus.pendingError]: i18n._(
        defineMessage({ id: 'invoice_statuses.pending_error', message: 'Pending error' })
      ),
      [WithdrawalStatus.preApproved]: i18n._(
        defineMessage({ id: 'invoice_statuses.pre_approved', message: 'Pre-approved' })
      ),
      [WithdrawalStatus.processed]: i18n._(
        defineMessage({ id: 'invoice_statuses.processed', message: 'Processed' })
      ),
      [WithdrawalStatus.processedError]: i18n._(
        defineMessage({ id: 'invoice_statuses.processed_error', message: 'Processed error' })
      ),
      [WithdrawalStatus.processedPending]: i18n._(
        defineMessage({ id: 'invoice_statuses.processed_pending', message: 'Processed pending' })
      ),
    };
  }, [lang]);
};
