import { BaseItem, RowWrapperProps } from '../../bound-cells';
import s from './default-row-container.module.scss';

export function DefaultRowContainer<T extends BaseItem>(props: RowWrapperProps<T>) {
  return (
    <div ref={props.containerRef} className={s.container} style={{ ...props.style }}>
      {props.children}
    </div>
  );
}
