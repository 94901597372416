import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { KeyExtractor, RowConfig } from '@components/table2/base';
import { LoadData, useTable } from '@components/table2/simplified';
import { tableRowHeight } from '@components/table2/config/table-simplified';
import { configSelectors } from '@config/core';
import { mapTableSorting } from '@helper/sorting';
import api, { GetBankAccountBalancesSortKey, GetBankAccountBalancesSortOrder } from './api';
import { BankAccount } from './types';
import { ColumnKey } from './columns';

export const rowConfig: RowConfig<BankAccount> = {
  height: tableRowHeight.normal,
};

const sortMap = {
  [ColumnKey.bankName]: GetBankAccountBalancesSortKey.bankName,
  [ColumnKey.currency]: GetBankAccountBalancesSortKey.currency,
  [ColumnKey.currentBalance]: GetBankAccountBalancesSortKey.currentBalance,
};

export const useModel = () => {
  const [ordering, setOrdering] = useState<GetBankAccountBalancesSortOrder>([]);

  const i18n = useSelector(configSelectors.i18n);

  const keyExtractor = useCallback<KeyExtractor<BankAccount>>((item) => {
    return item.externalBankAccountBalanceId;
  }, []);

  const loadData = useCallback<LoadData<BankAccount>>(({ nextPage, sortOrder }) => {
    const ordering: GetBankAccountBalancesSortOrder = mapTableSorting(sortOrder, sortMap);
    return api.getBankAccountBalancesPage({ page: nextPage, ordering });
  }, []);

  const onAfterSortOrderChange = useCallback((sortOrder) => {
    setOrdering(mapTableSorting(sortOrder, sortMap));
  }, []);

  const { tableInstance } = useTable<BankAccount>({
    keyExtractor,
    loadData,
    onAfterSortOrderChange,
    startPage: 1,
  });

  return {
    i18n,
    ordering,
    tableInstance,
  };
};
