import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { Title } from '@components/typography/title';
import { Box } from '@components/box';
import { HasAccess, PermissionGroup, Role as R, traderPermissions } from '@permissions/core';
import { Divider } from '@components/divider';
import { formatMoney } from '../../../../../services/commonService';
import { ProfileOther } from '../../api';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionsGroup],
  marker: 'layout:profile-other-page-balances',
});

const accessMyPermissions = { and: [permissionsGroup] };
const accessUserRoles = [R.issuer, R.listingBroker, R.liquidityProvider];
const accessUserPermissions = { or: traderPermissions };
export const ProfileBalances: FC<{
  className: string;
  profile: ProfileOther;
}> = (p) => (
  <HasAccess permissions={accessMyPermissions}>
    <HasAccess
      userPermissions={p.profile.permissions}
      userRole={p.profile.role.name}
      permissions={accessUserPermissions}
      roles={accessUserRoles}
    >
      <ProfileBalancesComponent className={p.className} />
    </HasAccess>
  </HasAccess>
);

const ProfileBalancesComponent: FC<{
  className: string;
}> = memo(({ className }) => {
  const m = useModel();

  return !m.hasPrimary && !m.hasSecondary ? null : (
    <Box className={className}>
      <Title level={2}>
        <Trans id="profile_other_page.balances.title">Balance</Trans>
      </Title>

      <Divider className="mb3" />

      {!m.hasPrimary ? null : (
        <div className={classnames({ mb3: m.hasSecondary })}>
          <Title level={4} color="secondary">
            <Trans id="profile_other_page.balances.primary_market_title">Primary market</Trans>
          </Title>

          {m.primary.map((b, i) => (
            <Title level={3} key={i + b.asset} compact={m.primary.length - 1 === i}>
              {formatMoney(b.amount, { pre: b.asset })}
            </Title>
          ))}
        </div>
      )}

      {!m.hasSecondary ? null : (
        <div>
          <Title level={4} color="secondary">
            <Trans id="profile_other_page.balances.secondary_market_title_excl_offers">
              Secondary market (excl. placed offers)
            </Trans>
          </Title>

          {m.secondary.map((b, i) => (
            <Title level={3} key={i + b.asset} compact={m.secondary.length - 1 === i}>
              {formatMoney(b.amount, { pre: b.asset })}
            </Title>
          ))}
        </div>
      )}
    </Box>
  );
});
