import React, { FC, ReactNode } from 'react';
import { useNewTabsModel } from './model';
import classnames from 'classnames';

export const NewTabs: FC<{
  tabs: Array<Tab>;
  linkWrapper?: Function;
  className?: string;
}> = ({ tabs, linkWrapper, className }) => {
  const { currentTabView, links } = useNewTabsModel(tabs);

  return (
    <>
      {linkWrapper ? linkWrapper(links) : <div className="tab-links">{links}</div>}

      <div className={classnames(className, 'tab-blocks')}>{currentTabView}</div>
    </>
  );
};

export interface Tab {
  content: Function | ReactNode;
  error?: Boolean;
  isTabDisabled?: Boolean;
  name: string;
  title: ReactNode;
}
