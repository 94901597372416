import React, { FC } from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';
import './styles/index.scss';

export enum TooltipTrigger {
  hover = 'hover',
  focus = 'focus',
  click = 'click',
  contextMenu = 'contextMenu',
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  overlayClassName = 'tm2-tooltip-overlay',
  placement,
  rawChild,
  title = '',
  trigger = 'click',
  ...otherProps
}) => {
  return (
    <AntdTooltip
      overlayClassName={overlayClassName}
      placement={placement}
      title={title}
      trigger={trigger}
      {...otherProps}
    >
      {rawChild ? children : <div>{children}</div>}
    </AntdTooltip>
  );
};

export interface TooltipProps extends TooltipPropsWithTitle {
  rawChild?: boolean;
}
