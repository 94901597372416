import { useState } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { useParams } from 'react-router';
import { defineMessage } from '@lingui/macro';
import { useI18n } from '@hooks/i18n';
import { CoinDetails } from '../../coin-details';
import api, { permissionGroup as apiPermissionGroup } from './api';
import { PageTemplate } from '../template';

import type { InitData } from '../../coin-details';

export type { Coin } from './types';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:coin-details-page',
});

type CoinDetailsPageParams = {
  coinId: string;
};

export const CoinDetailsPage = () => {
  const { i18n } = useI18n();
  const routeParams = useParams<CoinDetailsPageParams>();
  const [asset, setAsset] = useState('');

  const documentTitle = i18n._(
    defineMessage({
      id: 'router_title.symbol_details',
      message: 'Symbol details',
    })
  );

  const coinId = routeParams?.coinId;
  if (!coinId) {
    // TODO: handle error
    throw new Error('EditCoin: Cannot read coin id');
  }
  const normalizedCoinId = Number(coinId);

  const initData = useCallbackOne<InitData>(() => {
    return api.getCoin(normalizedCoinId).then((data) => {
      setAsset(data.asset);
      return data;
    });
  }, []);

  const titleElement = <span>{asset}</span>;
  const contentElement = <CoinDetails initData={initData} />;

  return (
    <PageTemplate
      documentTitle={documentTitle}
      titleElement={titleElement}
      contentElement={contentElement}
      uat="digital-metals-coin-details-page"
    />
  );
};
