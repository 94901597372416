import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';

import { PageContent, PageTitle } from '@components/page';
import { TablePane } from '@components/table2/utils';
import { Table } from '@components/table2/simplified';
import { PermissionGroup } from '@permissions/permission-group';

import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as actionsCellPermissionGroup } from './actions-cell';
import { permissionGroup as actionsRowPermissionGroup } from './actions-row';
import { rowConfig, useModel } from './model';
import { ActionsRow } from './actions-row';
import { useColumns } from './columns';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, actionsCellPermissionGroup, actionsRowPermissionGroup],
  marker: 'layout:withdrawals',
});

export const WithdrawalsPage: FC = memo(() => {
  const m = useModel();
  const c = useColumns(m);

  return (
    <PageContent className={s.verticalFiller} uat="withdrawals-page">
      <PageTitle
        title={m.i18n._(
          defineMessage({ id: 'router.withdrawals', message: 'Withdrawal requests' })
        )}
      />

      <ActionsRow addWithdrawal={m.addWithdrawal} onFiltersChanged={m.onFiltersChanged} />

      <TablePane className={s.verticalFiller}>
        <Table
          columns={c.columns}
          instance={m.tableInstance}
          rowConfig={rowConfig}
          className={s.verticalFiller}
        />
      </TablePane>
    </PageContent>
  );
});
