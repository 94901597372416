import { Table as OriginalTable } from '../autosized';
import {
  TABLE_INSTANCE_SYMBOL,
  TABLE_KEY_EXTRACTOR_SYMBOL,
  TABLE_LOAD_MORE_SYMBOL,
} from './use-table';

import type { TableProps as OriginalTableProps, BaseItem } from '../autosized';
import type { TableInstance, LoadIndex } from './use-table';

export { useTable } from './use-table';

export type {
  ColumnConfig,
  ColumnType,
  EstimateRowHeight,
  KeyExtractor,
  RowConfig,
} from '../autosized';

export type {
  DataSetter,
  FilterEqualityCheck,
  FilterSetter,
  LoadData,
  ScrollToIndex,
  SortOrder,
  UseTableParams,
} from './use-table';

type TableProps<T extends BaseItem> = Pick<
  OriginalTableProps<T, LoadIndex>,
  'className' | 'columns' | 'columnConfig' | 'estimateRowHeight' | 'rowConfig'
> & {
  instance: TableInstance<T>;
};

export function Table<T extends BaseItem>(props: TableProps<T>) {
  return (
    <OriginalTable
      className={props.className}
      columns={props.columns}
      columnConfig={props.columnConfig}
      data={props.instance.data}
      hasMore={props.instance.hasMore}
      instance={props.instance[TABLE_INSTANCE_SYMBOL]}
      keyExtractor={props.instance[TABLE_KEY_EXTRACTOR_SYMBOL]}
      listKey={props.instance.key}
      loading={props.instance.initialLoading}
      onLoadMore={props.instance[TABLE_LOAD_MORE_SYMBOL]}
      rowConfig={props.rowConfig}
      scrollDebounce={75}
    />
  );
}
