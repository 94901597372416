import { SortItem } from '@models/core';
import { SliceId } from '@helper/redux';
import { TableHeaderSortType as Sort } from 'components/page-table';
import { FeeReportItem } from '../api';

export const sliceId = SliceId('statements_management_fee_report');

export enum ColumnIds {
  date = 'date',
  clientId = 'clientId',
  clientName = 'clientName',
  asset = 'asset',
  amountPrimary = 'amountPrimary',
  amountSecondary = 'amountSecondary',
}

const columnSort: Array<SortItem<ColumnIds>> = [
  { direction: Sort.desc, fields: ['date'], id: ColumnIds.date },
  { direction: Sort.notSet, fields: ['user.id'], id: ColumnIds.clientId },
  {
    direction: Sort.notSet,
    fields: ['user.firstName', 'user.lastName', 'user.company'],
    id: ColumnIds.clientName,
  },
  { direction: Sort.notSet, fields: ['coin.asset'], id: ColumnIds.asset },
  { direction: Sort.notSet, fields: ['amountOnPrimary'], id: ColumnIds.amountPrimary },
  { direction: Sort.notSet, fields: ['amountOnSecondary'], id: ColumnIds.amountSecondary },
];

export interface State {
  isHasMore: boolean;
  isPaymentsLoading: boolean;
  pageIndex: number;
  sort: Array<SortItem<ColumnIds>>;
  tableData: Array<FeeReportItem>;
}

export const stateInitial: State = {
  isHasMore: true,
  isPaymentsLoading: false,
  pageIndex: -1,
  sort: columnSort,
  tableData: [],
};
