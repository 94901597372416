import {
  formChangeAction,
  formChangeCheckboxAction,
  formErrorsAction,
  formInitAction,
  formIsSendAction,
} from '../../actions/formAction';

export const formChange = (formName, event) => {
  const target = event.target;
  return (dispatch) =>
    event.target.type === 'checkbox'
      ? dispatch(formChangeCheckboxAction(formName, target.name, target.value, target.type))
      : dispatch(formChangeAction(formName, target.name, target.value));
};

export const formErrors = (formName, errors) => {
  return (dispatch) => dispatch(formErrorsAction(formName, errors));
};

export const formIsSend = (formName, isSend) => {
  return (dispatch) => dispatch(formIsSendAction(formName, isSend));
};

export const formInit = (formName, data = {}) => {
  return (dispatch) => dispatch(formInitAction(formName, data));
};
