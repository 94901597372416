import React, { FC, memo } from 'react';

import { Table } from '@components/table2/simplified';
import { TablePane } from '@components/table2/utils';
import { rowConfig, useModel } from './model';
import { columns } from './columns';
import { permissionGroup as apiPermissionGroup } from './api';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:bank-account-history-page-table',
});

type Props = { className: string };
export const BankBalanceHistoryTable: FC<Props> = memo<Props>((p) => {
  const m = useModel();

  return (
    <TablePane className={p.className}>
      <Table
        columns={columns}
        instance={m.tableInstance}
        rowConfig={rowConfig}
        className={p.className}
      />
    </TablePane>
  );
});
