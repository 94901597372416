import { useContext, useMemo } from 'react';

import t from '../../tools';
import { Context } from '../../context';

export const useModel = () => {
  const { state } = useContext(Context);

  const disabled: boolean = useMemo(
    () => t.isSearchCleared(state.searchForm, state.textMode),
    [state.searchForm, state.textMode]
  );
  const onClearClicked = () => state.events.emit({ type: 'clear' });

  return {
    disabled,
    onClearClicked,
  };
};
