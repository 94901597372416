import React from 'react';
import { Trans } from '@lingui/macro';

import { maxDecimals as _maxDecimals } from '@constants/math';
import { roundDown } from 'shared/helper/number';

export const Validators = {
  required: (errors, value, fieldName) => {
    if (!value && value !== 0) {
      errors = errors || {};
      errors[fieldName] = <Trans id="shared.form.validation.required">Field is required</Trans>;
    }
    return errors;
  },
};

export const isEmailValid = (email: string): boolean => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const isPhoneNumberValid = (phone: string): boolean => {
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(phone);
};

export const onlyNumbers = (prevValue: string, nextValue: string, config: NumCfg = {}) => {
  const cfg = {
    maxDecimals: _maxDecimals,
    ...config,
  };

  if (!nextValue) {
    return nextValue;
  }

  if (isNaN(+nextValue)) {
    const prevLocal = nextValue.slice(0, -1);
    return !isNaN(+prevLocal) || prevLocal === '' ? prevLocal : prevValue;
  }

  if (cfg.min === 0 && nextValue?.indexOf('-') > -1) {
    return nextValue.replace(/-/g, '');
  }

  if (typeof cfg.min === 'number' && +nextValue < cfg.min) {
    return prevValue;
  }

  if ((cfg.max || cfg.max === 0) && +nextValue > cfg.max) {
    return roundDown(cfg.max, config.maxDecimals).toString();
  }

  if (nextValue.indexOf('.') > -1 && (cfg.maxDecimals || cfg.maxDecimals === 0)) {
    const dotCount = (nextValue.match(/\./g) || []).length;
    if (dotCount > 1) {
      return prevValue;
    }

    const decimals = nextValue.split('.')[1];
    if (decimals.length > cfg.maxDecimals) {
      return roundDown(+nextValue, config.maxDecimals);
    }
  }

  return nextValue;
};

interface NumCfg {
  isAntForm?: boolean;
  max?: number;
  maxDecimals?: number;
  min?: number;
}
