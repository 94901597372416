import { CompatClient } from '@stomp/stompjs';

import { ReduxAction } from '@models/redux';
import { History, Lang } from '@models/core';
import { AuthorizedInfoPayload } from '../../../bootstrap/store/model';
import { SystemPreferences } from '../config-api.service';
import { ConfigActionsTypes as T, SetAppLanguagePayload, UserInfo } from './model';

class CheckAppLanguageAction extends ReduxAction<T.CHECK_APP_LANGUAGE, Lang> {
  public readonly type: T.CHECK_APP_LANGUAGE = T.CHECK_APP_LANGUAGE;
  public constructor(public payload: Lang) {
    super(payload);
  }
}

class CheckTutorialEventAction extends ReduxAction<T.CHECK_TUTORIAL_EVENT, boolean> {
  public readonly type: T.CHECK_TUTORIAL_EVENT = T.CHECK_TUTORIAL_EVENT;
  public constructor(public payload: boolean) {
    super(payload);
  }
}

class ConfigureApplicationAction extends ReduxAction<
  T.CONFIGURE_APPLICATION,
  AuthorizedInfoPayload
> {
  public readonly type: T.CONFIGURE_APPLICATION = T.CONFIGURE_APPLICATION;
  public constructor(public payload: AuthorizedInfoPayload) {
    super(payload);
  }
}

class ConfigureApplicationSuccessAction extends ReduxAction<T.CONFIGURE_APPLICATION_SUCCESS, void> {
  public readonly type: T.CONFIGURE_APPLICATION_SUCCESS = T.CONFIGURE_APPLICATION_SUCCESS;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetUserInfoAction extends ReduxAction<T.GET_USER_INFO, void> {
  public readonly type: T.GET_USER_INFO = T.GET_USER_INFO;
  public constructor(public payload: void) {
    super(payload);
  }
}

class GetUserInfoFailAction extends ReduxAction<T.GET_USER_INFO_FAIL, Error> {
  public readonly type: T.GET_USER_INFO_FAIL = T.GET_USER_INFO_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class GetUserInfoSuccessAction extends ReduxAction<T.GET_USER_INFO_SUCCESS, UserInfo> {
  public readonly type: T.GET_USER_INFO_SUCCESS = T.GET_USER_INFO_SUCCESS;
  public constructor(public payload: UserInfo) {
    super(payload);
  }
}

type LogOutType = 'from-click' | 'internal' | 'web-event';
class LogOutSessionAction extends ReduxAction<T.LOG_OUT_SESSION, LogOutType> {
  public readonly type: T.LOG_OUT_SESSION = T.LOG_OUT_SESSION;
  public constructor(public payload: LogOutType) {
    super(payload);
  }
}

class PostRegFinishedAction extends ReduxAction<T.POST_REG_FINISHED> {
  public readonly type: T.POST_REG_FINISHED = T.POST_REG_FINISHED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class PostRegInitiatedAction extends ReduxAction<T.POST_REG_INITIATED> {
  public readonly type: T.POST_REG_INITIATED = T.POST_REG_INITIATED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class SetAppLanguageAction extends ReduxAction<T.SET_APP_LANGUAGE, SetAppLanguagePayload> {
  public readonly type: T.SET_APP_LANGUAGE = T.SET_APP_LANGUAGE;
  public constructor(public payload: SetAppLanguagePayload) {
    super(payload);
  }
}

class SetHistoryInstanceAction extends ReduxAction<T.SET_HISTORY_INSTANCE, History> {
  public readonly type: T.SET_HISTORY_INSTANCE = T.SET_HISTORY_INSTANCE;
  public constructor(public payload: History) {
    super(payload);
  }
}

class SetSystemPreferencesAction extends ReduxAction<T.SET_SYSTEM_PREFERENCES, SystemPreferences> {
  public readonly type: T.SET_SYSTEM_PREFERENCES = T.SET_SYSTEM_PREFERENCES;
  public constructor(public payload: SystemPreferences) {
    super(payload);
  }
}

class ToggleGlobalPreloaderAction extends ReduxAction<T.TOGGLE_GLOBAL_PRELOADER, boolean> {
  public readonly type: T.TOGGLE_GLOBAL_PRELOADER = T.TOGGLE_GLOBAL_PRELOADER;
  public constructor(public payload: boolean) {
    super(payload);
  }
}

class WebsocketConnectedAction extends ReduxAction<T.WEBSOCKET_CONNECTED, CompatClient> {
  public readonly type: T.WEBSOCKET_CONNECTED = T.WEBSOCKET_CONNECTED;
  public constructor(public payload: CompatClient) {
    super(payload);
  }
}

class WebsocketDisconnectedAction extends ReduxAction<T.WEBSOCKET_DISCONNECTED, void> {
  public readonly type: T.WEBSOCKET_DISCONNECTED = T.WEBSOCKET_DISCONNECTED;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const configServiceActions = {
  checkAppLanguage: (lang: Lang) => new CheckAppLanguageAction(lang).get(),
  checkTutorialEvent: (isForcedShow?: boolean) => new CheckTutorialEventAction(isForcedShow).get(),
  configureApplication: (info: AuthorizedInfoPayload) => new ConfigureApplicationAction(info).get(),
  configureApplicationSuccess: () => new ConfigureApplicationSuccessAction().get(),
  getUserInfo: () => new GetUserInfoAction().get(),
  getUserInfoFail: (error: Error) => new GetUserInfoFailAction(error).get(),
  getUserInfoSuccess: (user: UserInfo) => new GetUserInfoSuccessAction(user).get(),
  logOutSession: (type: LogOutType) => new LogOutSessionAction(type).get(),
  postRegFinished: () => new PostRegFinishedAction().get(),
  postRegInitiated: () => new PostRegInitiatedAction().get(),
  setAppLanguage: (payload: SetAppLanguagePayload) => new SetAppLanguageAction(payload).get(),
  setHistoryInstance: (history: History) => new SetHistoryInstanceAction(history).get(),
  setSystemPreferences: (preferences: SystemPreferences) =>
    new SetSystemPreferencesAction(preferences).get(),
  toggleGlobalPreloader: (isShow: boolean) => new ToggleGlobalPreloaderAction(isShow).get(),
  websocketConnected: (wsInstance: CompatClient) => new WebsocketConnectedAction(wsInstance).get(),
  websocketDisconnected: () => new WebsocketDisconnectedAction().get(),
};

export type ConfigServiceActionsTypes =
  | CheckAppLanguageAction
  | CheckTutorialEventAction
  | ConfigureApplicationAction
  | ConfigureApplicationSuccessAction
  | GetUserInfoAction
  | GetUserInfoFailAction
  | GetUserInfoSuccessAction
  | LogOutSessionAction
  | PostRegFinishedAction
  | PostRegInitiatedAction
  | SetAppLanguageAction
  | SetHistoryInstanceAction
  | SetSystemPreferencesAction
  | ToggleGlobalPreloaderAction
  | WebsocketConnectedAction
  | WebsocketDisconnectedAction;

export declare namespace ConfigServiceActions {
  type checkAppLanguage = CheckAppLanguageAction;
  type checkTutorialEvent = CheckTutorialEventAction;
  type configureApplication = ConfigureApplicationAction;
  type configureApplicationSuccess = ConfigureApplicationSuccessAction;
  type getUserInfo = GetUserInfoAction;
  type getUserInfoFail = GetUserInfoFailAction;
  type getUserInfoSuccess = GetUserInfoSuccessAction;
  type logOutSession = LogOutSessionAction;
  type postRegInitiated = PostRegInitiatedAction;
  type setAppLanguage = SetAppLanguageAction;
  type setHistoryInstance = SetHistoryInstanceAction;
  type setSystemPreferences = SetSystemPreferencesAction;
  type toggleGlobalPreloader = ToggleGlobalPreloaderAction;
  type websocketConnected = WebsocketConnectedAction;
  type websocketDisconnected = WebsocketDisconnectedAction;
}
