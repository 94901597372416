import { ExtractAction } from '@helper/ts';
import { CreateFormData, EditFormData, Currency, Metal, Unit, User, Vault } from './types';
import { createContext, useMemo, useReducer, ReactNode } from 'react';

export const formId = 'createOrEditMetal';

export const tabId = {
  general: 'general',
  position: 'position',
  redemption: 'redemption',
  productInfo: 'productInfo',
  fees: 'fees',
  techInfo: 'techInfo',
} as const;

const generalFields = {
  amount: 'amount',
  asset: 'asset',
  brandName: 'brandName',
  denominationUnit: 'coinInfo.denominationUnit',
  issuer: 'issuer.id',
  name: 'name',
  liquidityProviders: 'coinInfo.liquidityProviders',
  metal: 'metal.id',
  physicalBacking: 'technicalSpecification',
  physicalRedemption: 'coinInfo.physicalRedemption',
  unitAndDenomination: 'coinInfo.unitAndDenomination',
  vault: 'vault.id',
  weightMeasurement: 'coinInfo.weighMeasurement.id',
} as const;

const redemptionFields = {
  redeemOptions: 'redeemOptions',
  amount: 'amount',
  description: 'description',
  image: 'image',
  name: 'name',
  unit: 'unit.id',
  minimalQuantity: 'minimalQuantity',
  price: 'price',
  priceType: 'priceType',
} as const;

const positionFields = {
  positionDays: 'positionDays',
  positionPercent: 'positionPercent',
} as const;

const productInfoFields = {
  infoFiles: 'infoFiles',
  productInfo: 'productInfo',
  primaryMarketProductInfo: 'coinInfo.primaryMarketProductInfo',
} as const;

const feesFields = {
  listingBroker: 'listingBroker.id',
  listingBrokerFee: 'listingBrokerFee',
  managementFee: 'managementFee',
  sellCommission: 'sellCommission',
  transactionCommission: 'transactionCommission',
} as const;

const techInfoFields = {
  cqgSymbols: 'cqgSymbols',
  cqgContractName: 'contractName',
  cqgSymbol: 'symbol',
  cqgSymbolId: 'id',
  cqgCurrency: 'currency.id',
} as const;

export const fields = {
  [tabId.general]: generalFields,
  [tabId.redemption]: redemptionFields,
  [tabId.position]: positionFields,
  [tabId.productInfo]: productInfoFields,
  [tabId.fees]: feesFields,
  [tabId.techInfo]: techInfoFields,
} as const;

export type FormMode = 'create' | 'edit';

type FormDataBase = CreateFormData | EditFormData;

// We denote liquidity providers as a number since multi-select accepts a list
// of values, which are used as a selection source set for the provided field
// name 'coinInfo.liquidityProviders'. If the set is of type Array<{id}> and not
// number[], the selection 'coinInfo.liquidityProviders' attempts to select
// values based on referential equality of {id} objects, which results in
// failure. Syntax 'coinInfo.liquidityProviders.id' does not work.
export type FormData = Omit<FormDataBase, 'coinInfo'> & {
  coinInfo?: Omit<FormDataBase['coinInfo'], 'liquidityProviders'> & {
    liquidityProviders?: number[];
  };
};

export type FormContextState = {
  data: {
    formData: Partial<FormData> | null;
    currencies: Currency[];
    issuers: User[];
    listingBrokers: User[];
    liquidityProviders: User[];
    metals: Metal[];
    units: Unit[];
    vaults: Vault[];
  } | null;
  lockUI: boolean;
  mode: FormMode;
};

type ActionMap = {
  FORM_INITIATED: FormContextState['data'];
  FORM_SUBMITTED: never;
  FORM_SUBMISSION_ERROR: never;
  METALS_UPDATED: Metal[];
};

export type FormContextValue = {
  state: FormContextState;
  dispatch: (action: ExtractAction<ActionMap>) => void;
};

const initialState: FormContextState = {
  data: {
    formData: null,
    currencies: [],
    issuers: [],
    listingBrokers: [],
    liquidityProviders: [],
    metals: [],
    units: [],
    vaults: [],
  },
  lockUI: true,
  mode: 'create',
};

function formReducer(state: FormContextState, action: ExtractAction<ActionMap>) {
  switch (action.type) {
    case 'FORM_INITIATED':
      return {
        ...state,
        data: action.payload,
        lockUI: false,
      };
    case 'FORM_SUBMITTED':
      return {
        ...state,
        lockUI: true,
      };
    case 'FORM_SUBMISSION_ERROR':
      return {
        ...state,
        lockUI: false,
      };
    case 'METALS_UPDATED':
      return {
        ...state,
        data: { ...state.data, metals: action.payload },
      };
  }
}

export const FormContext = createContext<FormContextValue>({
  state: initialState,
  dispatch: () => {},
});

export type FormProviderProps = {
  mode: FormMode;
  children: ReactNode;
};

export function FormProvider({ mode, children }: FormProviderProps) {
  const [state, dispatch] = useReducer(formReducer, { ...initialState, mode });

  const contextValue = useMemo<FormContextValue>(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <FormContext.Provider value={contextValue}>{children}</FormContext.Provider>;
}
