import { Trans } from '@lingui/macro';
import { maxDecimals } from '@constants/math';
import { Form, InputNumber, SubmitButton } from '@components/form';
import { Modal, ModalContent, ModalFooter, ModalTitle, MFC } from '@modules/modal';
import { useEditOfferModel, ModelParams } from './model';
import { Offer } from './types';
import s from './index.module.scss';

export { permissionGroup } from './api';

export type { Offer } from './types';

export type EditOfferModalProps = {
  activePrices: ModelParams['activePrices'];
  offerId: number;
  onClose: () => void;
  onOfferUpdate: (offer: Offer) => void;
};

export const EditOfferModal: MFC<EditOfferModalProps> = ({
  isVisible,
  activePrices,
  offerId,
  onClose,
  onOfferUpdate,
}) => {
  const { formId, formInitialValues, isSubmitDisabled, prices, updatePrices } = useEditOfferModel({
    activePrices,
    offerId,
    onClose,
    onOfferUpdate,
  });

  const priceInputLabel = <Trans id="edit_bid_modal.price_input.label">Price</Trans>;

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Form name={formId} initialValues={formInitialValues}>
        <ModalTitle>
          <Trans id="edit_bid_modal.title">Change offer price</Trans>
        </ModalTitle>

        <ModalContent>
          {prices.map((price) => (
            <div key={price.currencyId} className={s.priceRow}>
              <InputNumber
                className={s.priceInput}
                label={priceInputLabel}
                maxDecimals={maxDecimals}
                min={0}
                name={price.name}
              />
              <span>
                {price.currencyCode}
                &nbsp;&nbsp;&nbsp;
                <Trans id="edit_bid_modal.per">per</Trans>
                &nbsp;&nbsp;&nbsp;
                {price.unit}
              </span>
            </div>
          ))}
        </ModalContent>

        <ModalFooter>
          <SubmitButton
            disabled={isSubmitDisabled}
            fullWidth
            name={formId}
            onAction={{ submit: updatePrices }}
          >
            <Trans id={'edit_bid_modal.confirm'}>Confirm</Trans>
          </SubmitButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
