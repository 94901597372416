import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { Box } from '@components/box';
import { Divider } from '@components/divider';
import { HasAccess, traderPermissions } from '@permissions/core';
import { OnboardingStep } from '@config/core';
import { ProfileMy } from '../../api';
import { HelpCenterTooltip } from '@components/help-info';
import { BankDetailsStep } from './steps/bank-details';
import { useModel } from './model';

/*
 * NOTE этот виджет, скорее всего, будет удален, нет смысла его сильно переписывать
 *      раньше тут была логика показа всех шагов, сейчас по быстрому закостылено
 *      чтобы показывать только текущий, начиная с bank details.
 *      Поэтому теперь в Portal нет особого смысла и это легаси.
 *      Я не против, если это кто-то перепишет :)
 **/
const widgetAccessPermissions = { or: traderPermissions };
export const TraderOnboard: FC<{ profile: ProfileMy }> = memo(({ profile }) => {
  const m = useModel(profile);

  return !profile ? null : (
    <HasAccess
      userPermissions={profile.permissions}
      extraCheck={m.widgetShowExtraCheck}
      permissions={widgetAccessPermissions}
    >
      <Box className={s.widget}>
        <div className={s.title}>
          <Title level={3} compact>
            <Trans id="profile_page.onboarding.step.title_what_do_i_need">
              What do I need to start trading?
            </Trans>
          </Title>
          <HelpCenterTooltip className="ml-auto" />
        </div>

        <Divider />

        <div className={s.steps}>
          <BankDetailsStep
            activeStep={m.activeStep}
            length={m.stepsLength}
            profile={profile}
            portal={m.stepDetailsRef}
            stepIndex={m.stepsIndexes.bankDetails}
          />
        </div>

        {/*NOTE: condition beyond is temporary solution for TM2-2197. Divider should be always present*/}
        {m.activeStep === OnboardingStep.sumSubForm ? null : <Divider className="mt1" />}

        <div className={s.portal} ref={m.handleStepDetailsRef} />
      </Box>
    </HasAccess>
  );
});
