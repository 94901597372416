import React, { useEffect, useState } from 'react';
import { Trans } from '@lingui/macro';
import { Form, InputText, SubmitButton, Switch, OnFormAction } from '@components/form';
import v from '@components/form/validators';
import { handleBackendError, notifySuccess } from '@modules/notify';
import api, { permissionGroup as apiPermissionGroup } from './api';
import s from './index.module.scss';
import type { GeneralPreferences } from './types';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:general-tab',
});
const formId = 'general-preferences';

enum Fields {
  systemNotificationText = 'systemNotification.text',
  systemNotificationFlag = 'systemNotification.active',
}

export function GeneralTab() {
  const [lockUI, setLockUI] = useState(true);
  const [lockInput, setLockInput] = useState(true);
  const [initialValues, setInitialValues] = useState<GeneralPreferences | null>(null);

  const onFormAction: OnFormAction = {
    submit: async (data: GeneralPreferences) => {
      await api.updateGeneralPreferences(data);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id={'preferences.general.system_notification.update_success'}>
            System notification has been successfully updated!
          </Trans>
        ),
      });
    },
    fieldValidation: {
      [Fields.systemNotificationText]: [v.required, v.lengthMax(256)],
    },
  };

  const onSwitchChange = (isActive: boolean) => {
    setLockInput(!isActive);
  };

  useEffect(() => {
    api
      .getGeneralPreferences()
      .then((data) => {
        setLockUI(false);
        setInitialValues(data);
        setLockInput(!data.systemNotification.active);
      })
      .catch((err) => {
        handleBackendError(err);
      });
  }, []);

  return (
    <Form className={s.form} name={formId} initialValues={initialValues}>
      <div className={s.switchContainer}>
        <span className={s.switchLabel}>
          <Trans id={'preferences.general.system_notification.label'}>System notification</Trans>
        </span>
        <Switch
          className={s.switch}
          name={Fields.systemNotificationFlag}
          disabled={lockUI}
          onChange={onSwitchChange}
        />
      </div>
      <InputText
        className={s.textInput}
        label={
          <Trans id={'preferences.general.system_notification.text_label'}>Notification text</Trans>
        }
        name={Fields.systemNotificationText}
        disabled={lockInput || lockUI}
        required
        maxLength={1000}
        compact
      />
      <SubmitButton
        className={s.saveButton}
        name={formId}
        disabled={lockUI}
        onAction={onFormAction}
      >
        <Trans id="button.save">Save</Trans>
      </SubmitButton>
    </Form>
  );
}
