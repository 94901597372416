import { ReactNode } from 'react';
import { i18n, I18n } from '@lingui/core';
import { en, zh } from 'make-plural/plurals';

import { Lang } from '@models/core';
import { session } from '@services/session';
import { ValidationItem } from '@services/stomp/errors';
import { messages as catalogEN } from './locales/en/messages';
import { messages as catalogCN } from './locales/zh/messages';
import { ValidationMessage } from '../../components/form/antd-form/validation-message';

i18n.loadLocaleData(Lang.en, { plurals: en });
i18n.loadLocaleData(Lang.cn, { plurals: zh });
i18n.load(Lang.en, catalogEN);
i18n.load(Lang.cn, catalogCN);

function configI18n(i18n: I18n, lang: Lang) {
  i18n.activate(lang);
  return i18n;
}

const startLang: Lang = session.getCurrentLanguage();
export let translate = {
  i18n: configI18n(i18n, startLang),
};

export function setI18nLang(lang: Lang): I18n {
  return (translate.i18n = configI18n(i18n, lang));
}

export const translateFormErrors = (errorsArr: Array<ValidationItem>): FormTranslatedErrors => {
  const errorsObj: FormTranslatedErrors = {};
  errorsArr &&
    errorsArr.forEach(
      (error: ValidationItem) => (errorsObj[error.field] = <ValidationMessage error={error} />)
    );
  return errorsArr ? errorsObj : undefined;
};

export interface FormTranslatedErrors {
  [k: string]: ReactNode | string;
}

export { useErrorsTranslates, useTranslateErrors } from './translations';
