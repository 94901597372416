import { useCallback, useContext, useEffect, useMemo } from 'react';
import useHash from 'use-hash-param';

import t from './tools';
import { Context, TextModeForm, textModeInitial, SearchItem, SearchForm } from './context';

const textModeId = 'search-text-mode';
const searchForId = 'search-form';

export const useRsqlHash = (fields: Array<SearchItem>, memorize: boolean) => {
  const [textModeHash, setTextModeHash] = useHash(textModeId, undefined);
  const [searchFormHash, setSearchFormHash] = useHash(searchForId, undefined);

  const searchFormInitial: SearchForm = useMemo(
    () => t.parseRsqlHash<SearchForm>(memorize, searchFormHash),
    []
  );

  const textModeInitial: TextModeForm = useMemo(
    () => t.parseRsqlHash<TextModeForm>(memorize, textModeHash),
    []
  );

  const rsqlStringInitial: string = useMemo(
    () => t.getRsqlString(fields, searchFormInitial, textModeInitial),
    [searchFormInitial, textModeInitial]
  );

  const updateSearchHash = useCallback(
    (searchForm: SearchForm, textMode: TextModeForm) => {
      if (memorize) {
        if (t.isSearchCleared(searchForm, textMode)) {
          setSearchFormHash(undefined);
          setTextModeHash(undefined);
        } else {
          setSearchFormHash(JSON.stringify(searchForm));
          setTextModeHash(JSON.stringify(textMode));
        }
      }
    },
    [setSearchFormHash, setTextModeHash]
  );

  const clearHash = () => {
    setSearchFormHash(undefined);
    setTextModeHash(undefined);
  };

  return {
    clearHash,
    rsqlStringInitial,
    searchFormInitial,
    textModeInitial,
    updateSearchHash,
  };
};

export const useSubscribeOnClearForm = (listener: () => void) => {
  const { state } = useContext(Context);
  const events = state.events;

  useEffect(() => {
    const subscription = events.subscribe((event) => {
      if (event.type === 'clear') {
        listener();
      }
    });
    return () => subscription.unsubscribe();
  }, [state.events]);
};

const useSearch = () => {
  const createDefaultSearchFormHash = (searchForm: SearchForm, textMode?: TextModeForm) => {
    const params = new URLSearchParams({
      [searchForId]: JSON.stringify(searchForm),
      [textModeId]: JSON.stringify(textMode || textModeInitial),
    });
    return params.toString();
  };

  return {
    createDefaultSearchFormHash,
  };
};

export default useSearch;
