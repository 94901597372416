import { useCallback } from 'react';
import { useCallbackOne } from 'use-memo-one';

import { mapTableSorting, TableSortCreator } from '@helper/sorting';
import { KeyExtractor, SortOrder } from '@components/table2/core';
import { LoadData, useTable } from '@components/table2/simplified';
import { handleBackendError } from '@modules/notify';
import { useSubscribeOnBankAccountUpdates } from '../hooks';
import api, { CMBankAccount, Column } from './api';

const sortMap = TableSortCreator(Column, { exclude: [Column.calculatedBalance] });
const initialSortOrder: SortOrder = [{ field: Column.id, direction: 'descend', key: Column.id }];

export const useModel = () => {
  const keyExtractor = useCallbackOne<KeyExtractor<CMBankAccount>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallbackOne<LoadData<CMBankAccount>>(({ nextPage, sortOrder }) => {
    const sorting = mapTableSorting(sortOrder, sortMap);
    return api.getBankAccounts(nextPage, sorting);
  }, []);

  const { setData, tableInstance } = useTable<CMBankAccount>({
    ensureUniqueness: true,
    initialSortOrder,
    keyExtractor,
    loadData,
  });

  const onBankAccountUpdated = useCallback(
    async (id: number) => {
      try {
        const bankAccount = await api.getBankAccount(id);
        setData((accountsOrigin) => {
          let isEditAction = false;
          const accountsUpdated = accountsOrigin.map((item) => {
            if (item.id === bankAccount.id) {
              isEditAction = true;
              return bankAccount;
            }
            return item;
          });
          return isEditAction ? accountsUpdated : [bankAccount, ...accountsOrigin];
        });
      } catch (error) {
        handleBackendError(error);
      }
    },
    [setData]
  );

  useSubscribeOnBankAccountUpdates(onBankAccountUpdated);

  return {
    tableInstance,
  };
};
