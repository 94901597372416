import React, { FC, memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import tab from '../index.module.scss';
import s from './index.module.scss';
import { Title } from '@components/typography/title';
import { ButtonPrimary } from '@components/button';
import { TabPane } from '@components/tabs';
import { HasAccess } from '@permissions/core';
import { useHasAccess } from '@permissions/hooks';
import { PermissionGroup } from '@permissions/permission-group';
import { BankAccountsTab } from '../../../components/tabs/bank-accounts';
import { permissionsGroup as addBankModalPermissionsGroup } from '../../modals/add-bank-account';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionsGroup, addBankModalPermissionsGroup],
  marker: 'layout:profile-my-page-bank-accounts-tab',
});

const accessPermissions = { and: [apiPermissionsGroup] };

export const useCheckShowBankAccountsTab = () => {
  const isCurrentUserCanSeeBankTab = useHasAccess(accessPermissions);
  return isCurrentUserCanSeeBankTab;
};

export const MyBankAccountsTab: FC<{
  hidden: boolean;
  tabKey: string;
}> = (p) => (
  <HasAccess permissions={accessPermissions}>
    <TabPane tabKey={p.tabKey} key={p.tabKey}>
      <MyBankAccountsTabComponent hidden={p.hidden} />
    </TabPane>
  </HasAccess>
);

const MyBankAccountsTabComponent: FC<{ hidden: boolean }> = memo(({ hidden }) => {
  const m = useModel();

  return m.isBankAccountsLoading ? null : (
    <div className={classnames(tab.tab, { [tab.hidden]: hidden })}>
      {!!m.bankAccounts.length && (
        <BankAccountsTab banks={m.bankAccounts} disabled={m.isBankAccountsLoading} />
      )}

      <div className={s.actionsRow}>
        {!m.bankAccounts.length && (
          <Title className={s.noBanksText} level={3} compact>
            <Trans id="profile.bank_details.no_banks">No linked bank details</Trans>
          </Title>
        )}

        <HasAccess
          permissions={{ and: [addBankModalPermissionsGroup] }}
          extraCheck={m.bankAccounts.length < 5}
        >
          <ButtonPrimary className={s.addButton} onClick={m.onAddBankAccountClicked}>
            <Trans id="profile.bank_details.button.add_bank">Add bank details</Trans>
          </ButtonPrimary>
        </HasAccess>
      </div>
    </div>
  );
});
