import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { KycState as KS } from '@config/store/model';
import { VerificationStatus as VS } from '@models/core';
import { accountService as account } from '@services/account';
import { permissionsSelectors } from '@permissions/core';
import { handleBackendError, notifySuccess } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { configSelectors } from '@config/core';
import usersApi, { UserTableItem } from '../../../../users-api.service';
import { usersPageServiceActions as actions } from '../../../store/service.acions';
import api, { permissionsGroup as apiPermissionsGroup } from './api';

export const useVerificationActions = (user: UserTableItem) => {
  const [lockUI, setLockUI] = useState<boolean>();
  const isHasAccessFn = useSelector(permissionsSelectors.isHasAccess);

  const isAllowResubmitVerification = isHasAccessFn({ and: [apiPermissionsGroup] });
  const isAdmin = useSelector(configSelectors.isAdmin);
  const isUserIndividualTrader: boolean = account.isIndividualTrader(user.permissions);
  const isUserTrader = account.isTrader(user.permissions);

  const emitUserUpdated = useCallDispatch(actions.eventUsersUpdated);

  const onToggleKycClicked = async (user: UserTableItem) => {
    setLockUI(true);
    try {
      if (user.kyc.state !== KS.approved) {
        await api.approveKyc(user.id);
        notifySuccess({
          defaultTitle: true,
          text: <Trans id="users_service.notification.user_approved">User approved</Trans>,
        });
      } else {
        await api.rejectKyc(user.id);
        notifySuccess({
          defaultTitle: true,
          text: <Trans id="users_service.notification.user_refused">User refused</Trans>,
        });
      }

      const userUpdated = await usersApi.getUpdatedUserTableInfo(user.id);
      emitUserUpdated({ isNewUser: false, isNoScroll: true, user: userUpdated });
    } catch (error) {
      handleBackendError(error);
    }
    setLockUI(false);
  };

  const onUserResubmitRequested = useCallback(
    async (userId: number) => {
      setLockUI(true);
      try {
        await api.resubmitUserVerification(userId);
        const userUpdated = await usersApi.getUpdatedUserTableInfo(userId);
        emitUserUpdated({ isNewUser: false, isNoScroll: true, user: userUpdated });
        notifySuccess({
          defaultTitle: true,
          text: (
            <Trans id="users_service.notification.verification_resubmitted">
              User verification was resubmitted
            </Trans>
          ),
        });
      } catch (error) {
        handleBackendError(error);
      }
      setLockUI(false);
    },
    [emitUserUpdated]
  );

  const approveAction = useMemo(() => {
    return !(isAdmin && isUserTrader && user.kyc.state !== KS.approved)
      ? null
      : {
          action: () => onToggleKycClicked(user),
          content: <Trans id="users.table.action.approve_verification">Approve verification</Trans>,
        };
  }, [isAdmin, isUserTrader, onToggleKycClicked, user]);

  const rejectAction = useMemo(() => {
    return !(isAdmin && isUserTrader && user.kyc.state === KS.approved)
      ? null
      : {
          action: () => onToggleKycClicked(user),
          content: <Trans id="users.table.action_reject_verification">Reject verification</Trans>,
        };
  }, [isAdmin, isUserTrader, onToggleKycClicked, user]);

  const resubmitAction = useMemo(() => {
    return !(
      isAllowResubmitVerification &&
      isUserIndividualTrader &&
      user.verificationStatus !== VS.resubmissionRequested
    )
      ? null
      : {
          action: () => onUserResubmitRequested(user.id),
          content: (
            <Trans id="users.table.actions.resubmit_verification">Resubmit verification</Trans>
          ),
        };
  }, [isAllowResubmitVerification, isUserIndividualTrader, onUserResubmitRequested, user]);

  return {
    approveAction,
    lockUI,
    rejectAction,
    resubmitAction,
  };
};
