import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { TableResponse } from '@models/core';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';

export interface ParticipantItem {
  attributes: { code: string };
  id: string;
}

export type QueryParticipantsPage = TableResponse<ParticipantItem>;

const getParticipantsQuery = 'freeParticipantsReport';
const getParticipantsFields = [{ data: [{ attributes: ['code'] }, 'id'] }, 'index', 'hasMore'];
const getParticipants = makeQuery({
  permissions: { and: [Permission.SECONDARY_MARKET_VIEW_FREE_PARTICIPANTS_REPORT] },
  queryName: getParticipantsQuery,
  queryFields: getParticipantsFields,
  query: (pageNumber: number): Promise<QueryParticipantsPage> => {
    const typedValues = {
      ...{ page: pageNumber },
    };

    return stompClient.getData<QueryParticipantsPage>(
      getParticipantsQuery,
      sign(getParticipantsQuery, getParticipantsFields),
      typedValues
    );
  },
});

const api = {
  getParticipants,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:participants-page');

export default api;
