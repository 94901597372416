import React, { FC } from 'react';

import { Box } from '@components/box';
import { DownloadTransactionsButton } from './download-button';

export const ManagementFeeActions: FC<{ className: string }> = ({ className }) => {
  return (
    <Box className={className} compact={true}>
      <DownloadTransactionsButton className="ml-auto" />
    </Box>
  );
};
