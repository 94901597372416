import { useSelector } from 'react-redux';

import { modalSelectors, showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import {
  CreateCMTransactionModal,
  CreateCMTransactionModalProps,
} from '../../modals/create-transaction';
import { useEmitTransactionCreated } from '../../hooks';

export const useModel = () => {
  const disabled = useSelector(modalSelectors.isModalOpened);
  const showModal = useCallDispatch(showModalAction);
  const onTransactionCreated = useEmitTransactionCreated();

  const onCreateTransactionClicked = () => {
    const props: CreateCMTransactionModalProps = { onTransactionCreated };
    showModal({ modal: CreateCMTransactionModal, props });
  };

  return {
    disabled,
    onCreateTransactionClicked,
  };
};
