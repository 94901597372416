import { useMemo } from 'react';

import { useQuery } from '@hooks/core';

import api from './api';

import type { SelectItem } from '@components/form';

export const useStableBalances = () => {
  const { data: stableBalances, isLoading: isStableBalancesLoading } = useQuery(
    api.getStableBalances,
    {
      initial: [],
    }
  );

  return {
    isStableBalancesLoading,
    stableBalances,
  };
};

export const useBankAccounts = () => {
  const { data: bankAccountsRaw, isLoading: isBankAccountsLoading } = useQuery(
    api.getBankAccounts,
    {
      initial: [],
    }
  );

  const bankAccounts: Array<SelectItem> = useMemo(() => {
    return (
      bankAccountsRaw?.map((ba) => ({
        label: `${ba.bankName} (${ba.currency.code})`,
        value: ba.id,
      })) || []
    );
  }, [bankAccountsRaw]);

  return {
    bankAccounts,
    bankAccountsRaw,
    isBankAccountsLoading,
  };
};

export const useCurrenciesMy = () => {
  const { data: currenciesRaw, isLoading: isCurrenciesLoading } = useQuery(api.getCurrenciesMy, {
    initial: [],
  });

  const currencies: Array<SelectItem> | undefined = useMemo(() => {
    return (
      currenciesRaw?.map((currency) => ({
        label: currency.code,
        value: currency.id,
      })) || []
    );
  }, [currenciesRaw]);

  return { currencies, isCurrenciesLoading };
};
