import { useQuery } from '@hooks/core';
import { currenciesFilter } from '@hot-fix/currency';
import { useHasAccess } from '@permissions/hooks';
import { Permission as P } from '@permissions/core';
import api, { BankDetails } from './api';

export const useBankDetails = () => {
  const isUserBankAccountsAllowed = useHasAccess({ or: [P.BANK_ACCOUNTS_VIEW_MY] });

  const { data: bankDetailsData, isLoading: isBankDetailsLoading } = useQuery(
    () => (isUserBankAccountsAllowed ? api.getBankDetails() : Promise.resolve([])),
    { initial: [] }
  );

  const bankDetails: Array<BankDetails> = bankDetailsData.filter((d) =>
    currenciesFilter(d.currency)
  );

  const isBankDetailsExists = !!bankDetails.length;

  return { bankDetails, isBankDetailsExists, isBankDetailsLoading };
};
