import React, { FC, ReactElement } from 'react';
import { Trans } from '@lingui/macro';

const statuses: { [k: string]: ReactElement } = {
  CANCELLED: <Trans id="statements.payments.table.statuses.cancelled">Cancelled</Trans>,
  CONFIRMED: <Trans id="statements.payments.table.statuses.confirmed">Confirmed</Trans>,
  CREATED: <Trans id="statements.payments.table.statuses.created">Created</Trans>,
  PENDING: <Trans id="statements.payments.table.statuses.pending">Pending</Trans>,
  UNKNOWN: <Trans id="statements.payments.table.statuses.unknown">Unknown</Trans>,
};

export const PaymentStatusCell: FC<{
  status: string;
}> = ({ status }) => (statuses[status] || !!status ? <span>{status}</span> : statuses.UNKNOWN);
