import { redeemModalStateInitial, RedeemModalActionsTypes as RedeemModalTypes } from './model';

export const redeemModalReducer = (state = redeemModalStateInitial, action) => {
  switch (action.type) {
    case RedeemModalTypes.ON_REDEEM_CLICKED: {
      return {
        ...state,
        redeemModal: {
          redeemBalance: action.payload,
        },
      };
    }

    default: {
      return state;
    }
  }
};
