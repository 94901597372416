import { createSlice } from '@reduxjs/toolkit';

import e from './effects';
import { sliceId, stateInitial } from './model';

export { default as selectors } from './selectors';

const s = createSlice({
  name: sliceId(null),
  initialState: stateInitial,
  reducers: {
    onPageLeft: () => stateInitial,
    onXlsUploaded: (state) => {
      state.tableData = [];
      state.pageIndex = stateInitial.pageIndex;
      state.isHasMore = true;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(e.loadMoreTableData.pending, (state) => {
      state.isTableLoading = true;
    });
    builder.addCase(e.loadMoreTableData.fulfilled, (state, action) => {
      const { isHasMore, data } = action.payload;
      state.isHasMore = isHasMore;
      state.isTableLoading = false;
      state.tableData = [...state.tableData, ...data];
    });
    builder.addCase(e.loadMoreTableData.rejected, (state, action) => {
      state.isHasMore = false;
      state.isTableLoading = false;
    });
    builder.addCase(e.onTableScrolled.pending, (state) => {
      if (!state.isTableLoading) {
        // our table has double request bug on start
        state.pageIndex = state.pageIndex + 1;
      }
    });
    builder.addCase(e.onTableSorted.pending, (state, action) => {
      const column = action.meta.arg;
      const sort = [...state.sort];
      const activeColumn = sort.splice(
        sort.findIndex((col) => col.id === column.id),
        1
      )[0];
      activeColumn.direction = column.direction;
      state.tableData = [];
      state.pageIndex = 0;
      state.sort = [activeColumn, ...sort];
    });
  },
});

export const reducer = s.reducer;

export const actions = {
  onPageLeft: s.actions.onPageLeft,
  onTableScrolled: e.onTableScrolled,
  onTableSorted: e.onTableSorted,
  onXlsUploaded: s.actions.onXlsUploaded,
};
