import { currenciesFilter } from '@hot-fix/currency';
import { currenciesRequest, usersByRoleQuery } from 'containers/services/graphqlFacade';
import { useList } from 'containers/services/commonService';
import gqlFields from './fields';

const { currenciesFields, userForDisplayFields } = gqlFields;

export const useUsersForDisplayByRole = (role) =>
  useList((wsInstance) => usersByRoleQuery(wsInstance, role, userForDisplayFields), [], [role]);

export const useCurrencies = (isForAntd) =>
  useList(async (wsInstance) => {
    let response = await currenciesRequest(wsInstance, currenciesFields);
    return response
      .filter(currenciesFilter)
      .sort((a, b) => b.order - a.order)
      .map((currency) => (isForAntd ? { label: currency.code, value: currency.id } : currency));
  }, []);
