import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService } from '@services/stomp/client';
import { clearEmptyFields } from '@components/form/antd-form';
import { currenciesFilter } from '@hot-fix/currency';
import { makeQuery, Permission, PermissionGroup, Role as R } from '@permissions/core';

// TODO не все запросы покрыты пермишнами, usersByRole возможно неверный пермишн
// ----------------------------------------------------------------
export interface Currency {
  bankAccounts: Array<{
    bankName: string;
    id: number;
  }>;
  code: string;
  id: number;
  order: number;
}
const getCurrenciesFields = [{ bankAccounts: ['id', 'bankName'] }, 'code', 'id', 'order'];
const getCurrenciesQuery = 'currencies';
const getCurrencies = makeQuery({
  queryName: getCurrenciesQuery,
  queryFields: getCurrenciesFields,
  query: (): Promise<Array<Currency>> => {
    return stompClientService
      .getData<Array<Currency>>(getCurrenciesQuery, sign(getCurrenciesQuery, getCurrenciesFields))
      .then((currencies) => currencies.filter(currenciesFilter))
      .then((currencies) => currencies.sort((a, b) => b.order - a.order));
  },
});
// ----------------------------------------------------------------
export interface Issuer {
  displayName: string;
  id: number;
  issuerInvestor: { id: number };
}
const getIssuersFields = ['displayName', 'id', { issuerInvestor: ['id'] }];
const getIssuersQuery = 'usersByRole';
const getIssuers = makeQuery({
  permissions: { or: [Permission.PROFILE_VIEW_OTHER] },
  queryName: getIssuersQuery,
  queryFields: getIssuersFields,
  query: (): Promise<Array<Issuer>> => {
    return stompClientService.getData(getIssuersQuery, sign(getIssuersQuery, getIssuersFields), {
      role: R.issuer,
    });
  },
});
// ----------------------------------------------------------------
export interface Role {
  id: number;
  label: string;
  name: R;
  permissions: string;
}
const getRolesFields = ['id', 'label', 'name', 'permissions'];
const getRolesQuery = 'roles';
const getRoles = makeQuery({
  queryName: getRolesQuery,
  queryFields: getRolesFields,
  query: (): Promise<Array<Role>> => {
    return stompClientService.getData(getRolesQuery, sign(getRolesQuery, getRolesFields));
  },
});
// ----------------------------------------------------------------
export interface UserProfile {
  address: string;
  bankAccounts: Array<{ id: number; currency: { id: number } }>;
  building: string;
  city: string;
  company?: string;
  complianceOfficerIssuer?: { id?: number };
  country: { id?: number };
  email: string;
  firstName?: string;
  id: number;
  investorIssuer?: { id?: number };
  lastName?: string;
  permissions: string;
  phone: string;
  postalCode: string;
  role: { id: number };
  state: string;
  umsId?: string;
  umsIdEdit?: boolean;
}
const getUserProfileFields = [
  'address',
  'building',
  'city',
  'company',
  'email',
  'firstName',
  'id',
  'lastName',
  'permissions',
  'phone',
  'postalCode',
  'state',
  'umsId',
  'umsIdEdit',
  { bankAccounts: ['id', { currency: ['id'] }] },
  { complianceOfficerIssuer: ['id'] },
  { country: ['id'] },
  { investorIssuer: ['id'] },
  { role: ['id'] },
];
const getUserProfileQuery = 'userById';
const getUserProfile = makeQuery({
  permissions: { or: [Permission.PROFILE_VIEW_OTHER] },
  queryName: getUserProfileQuery,
  queryFields: getUserProfileFields,
  query: (userId: number): Promise<UserProfile> => {
    return stompClientService.getData(
      getUserProfileQuery,
      sign(getUserProfileQuery, getUserProfileFields),
      v.long({ userId })
    );
  },
});
// ----------------------------------------------------------------
export enum UpdateUserFields {
  address = 'address',
  bankAccounts = 'bankAccounts', // list
  bankCurrencyId = 'currency.id', // bankAccounts item
  bankId = 'id', // bankAccounts item
  building = 'building',
  city = 'city',
  company = 'company',
  complianceOfficerIssuer = 'complianceOfficerIssuer.id',
  country = 'country.id',
  email = 'email',
  firstName = 'firstName',
  id = 'id',
  investorIssuer = 'investorIssuer.id',
  lastName = 'lastName',
  matchingPassword = 'matchingPassword',
  password = 'password',
  phone = 'phone',
  postalCode = 'postalCode',
  role = 'role.id',
  state = 'state',
  umsId = 'umsId',
}
export interface UpdateUserForm {
  [UpdateUserFields.address]: string;
  [UpdateUserFields.bankAccounts]: Array<{
    currency: { id: number };
    [UpdateUserFields.bankId]: number;
  }>;
  [UpdateUserFields.building]: string;
  [UpdateUserFields.city]: string;
  [UpdateUserFields.company]?: string;
  complianceOfficerIssuer?: { id: number };
  country: { id: number };
  [UpdateUserFields.email]: string;
  [UpdateUserFields.firstName]?: string;
  [UpdateUserFields.id]?: number;
  investorIssuer?: { id: number };
  [UpdateUserFields.lastName]?: string;
  [UpdateUserFields.matchingPassword]?: string;
  [UpdateUserFields.password]?: string;
  [UpdateUserFields.phone]: string;
  [UpdateUserFields.postalCode]: string;
  role: { id: number };
  [UpdateUserFields.state]: string;
  [UpdateUserFields.umsId]?: string;
}
const updateUserFields = ['id'];
const updateUserQuery = 'saveUserByAdmin';
const updateUser = makeQuery({
  permissions: { and: [Permission.PROFILE_EDIT_OTHER] },
  queryName: updateUserQuery,
  queryFields: updateUserFields,
  query: (form: UpdateUserForm): Promise<number> => {
    const formCleared: UpdateUserForm = clearEmptyFields(form); // TODO:93
    const user: UpdateUserForm = {
      ...formCleared,
      bankAccounts: formCleared.bankAccounts.filter((b) => !!b.id),
    };
    return stompClientService
      .sendData(
        updateUserQuery,
        sign(updateUserQuery, updateUserFields),
        v.saveUserByAdminDTOInput({ user })
      )
      .then((response: { id: number }) => response.id);
  },
});
// ----------------------------------------------------------------
const api = {
  getCurrencies,
  getIssuers,
  getRoles,
  getUserProfile,
  updateUser,
};

export const permissionsGroup = PermissionGroup.extract(
  api,
  'api:profile-other-page-handle-user-modal'
);

export default api;
