import React, { useCallback, useMemo } from 'react';
import { Trans } from '@lingui/macro';
import { useHistory } from 'react-router';

import s from './index.module.scss';
import { ColumnType } from '@components/table2/base';
import { ButtonIconPure } from '@components/button';
import { useSearch } from '@components/search';
import { useDefaultFilters } from './bank-account-history/hooks';
import { Filter } from './bank-account-history/types';
import { BankAccount } from './types';

export enum ColumnKey {
  accountNumber = 'accountNumber',
  bankAccountHistory = 'bankAccountHistory',
  bankName = 'bankName',
  currency = 'currency',
  currentBalance = 'currentBalance',
  externalBankAccountBalanceId = 'externalBankAccountBalanceId',
  legalEntity = 'legalEntity',
  typeOfAccount = 'typeOfAccount',
}

export const useColumns = () => {
  const history = useHistory();
  const { getDefaultFilter } = useDefaultFilters();
  const { createDefaultSearchFormHash } = useSearch();

  const onBankAccountHistoryClicked = useCallback(
    (bankId: string) => {
      const filter: Filter = getDefaultFilter(bankId);
      // TODO-2833: fix types
      // @ts-ignore
      history.push(
        `/statements/bank-accounts-balances/history#?${createDefaultSearchFormHash(filter)}`
      );
    },
    [getDefaultFilter]
  );

  const columns: Array<ColumnType<BankAccount>> = useMemo(() => {
    return [
      {
        key: ColumnKey.bankName,
        dataIndex: 'bankName',
        headCell: () => <Trans id="banks.bank_account_balances.bank_name">Bank name</Trans>,
        rowCell: ({ data }) => <>{data.bankName}</>,
        sorting: ['ascend', 'descend'],
        width: 200,
      },
      {
        key: ColumnKey.typeOfAccount,
        dataIndex: 'typeOfAccount',
        headCell: () => (
          <Trans id="banks.bank_account_balances.type_of_account">Type of account</Trans>
        ),
        rowCell: ({ data }) => <>{data.typeOfAccount}</>,
        width: 200,
      },
      {
        key: ColumnKey.accountNumber,
        dataIndex: 'accountNumber',
        headCell: () => (
          <Trans id="banks.bank_account_balances.account_number">Account number</Trans>
        ),
        rowCell: ({ data }) => <>{data.accountNumber}</>,
        width: 200,
      },
      {
        key: ColumnKey.legalEntity,
        dataIndex: 'legalEntity',
        headCell: () => <Trans id="banks.bank_account_balances.legal_entity">Legal entity</Trans>,
        rowCell: ({ data }) => <>{data.legalEntity}</>,
        width: 200,
      },
      {
        key: ColumnKey.currency,
        dataIndex: 'currency',
        headCell: () => <Trans id="banks.bank_account_balances.currency">Currency</Trans>,
        rowCell: ({ data }) => <>{data.currency}</>,
        sorting: ['ascend', 'descend'],
        width: 200,
      },
      {
        key: ColumnKey.currentBalance,
        dataIndex: 'currentBalance',
        headCell: () => (
          <Trans id="banks.bank_account_balances.current_balance">Current balance</Trans>
        ),
        rowCell: ({ data }) => <div className={s.numberCell}>{data.currentBalance}</div>,
        sorting: ['ascend', 'descend'],
        width: 200,
      },
      {
        key: ColumnKey.bankAccountHistory,
        dataIndex: 'bankAccountHistory',
        headCell: () => <div />,
        rowCell: ({ data }) => (
          <ButtonIconPure
            onClick={() => onBankAccountHistoryClicked(data?.externalBankAccountBalanceId)}
          />
        ),
        width: 40,
      },
    ];
  }, []);

  return {
    columns,
  };
};
