import React, { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';

import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { handleBackendError, notifySuccess } from '@modules/notify';

import api, { Form } from './api';
import { useInitialValues } from './hooks';

export const formId = 'createUMSAssetForm';

type OnCreateUMSAsset = (createdAssetCode: string) => void;
export const useModel = (modal: any, onCreateUMSAsset: OnCreateUMSAsset) => {
  const closeModal = useCallDispatch(closeModalAction);

  const [isCreateLoading, setCreateLoading] = useState(false);

  const { initialValues, isInitialValuesLoading } = useInitialValues();

  const assetCode = useMemo(() => initialValues?.assetCode, [initialValues]);

  const lockUI: boolean = isCreateLoading || isInitialValuesLoading;

  const onFormAction: OnFormAction<Form> = {
    submit: async (form) => {
      setCreateLoading(true);
      const createdAssetCode = await api.createUMSAsset({ ...form, assetCode });
      onCreateUMSAsset(createdAssetCode);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="creating_ums_asset.success_notify">UMS Asset was successfully created</Trans>
        ),
      });
      closeModal(modal);
    },
    error: (error) => {
      handleBackendError(error);
    },
    finally: () => setCreateLoading(false),
  };

  return {
    closeModal,
    lockUI,
    onFormAction,
    initialValues,
  };
};
