import React from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { PageTable } from 'components/page-table';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { Truncate } from '@components/truncate';
import { UserProfileLink } from '@components/link';
import { usersPageSelectors } from '../store/selectors';
import { usersPageUserActions } from '../store/user.acions';
import { UsersTableColumnIds as ColumnIds } from '../store/model';
import { VerificationStatusCell } from './verification-status-cell';
import { OnBoardingStatusCell } from './onboarding-status-cell';
import { permissionsGroup as actionsPermissionsGroup, UserActionsCell } from './actions-cell';
import { RoleCell } from './role-cell';

export const permissionsGroup = actionsPermissionsGroup.createSuperGroup({
  marker: 'layout:users-table',
});

const mapStateToProps = (root) => ({
  columnSortDirection: usersPageSelectors.columnSortDirection(root),
  isHasMore: usersPageSelectors.isHasMore(root),
  isUsersLoading: usersPageSelectors.isUsersLoading(root),
  users: usersPageSelectors.users(root),
});

const mapDispatchToProps = (dispatch) => ({
  onTableScrolled: () => dispatch(usersPageUserActions.onTableScrolled()),
  onTableSorted: (columnInfo) => dispatch(usersPageUserActions.onTableSorted(columnInfo)),
});

const ConnectedUsersTable = ({
  columnSortDirection,
  isHasMore,
  isUsersLoading,
  onTableScrolled,
  onTableSorted,
  users,
}) => {
  return (
    <PageTable
      onLoadMore={() => onTableScrolled()}
      isLoadingInProgress={isUsersLoading}
      isShowLoader={true}
      pageData={{
        data: users,
        hasMore: isHasMore,
      }}
      columns={[
        {
          cell: (user) => <UserActionsCell user={user} />,
          head: () => null,
        },
        {
          cell: (user) => <span>{user.id}</span>,
          className: 'align-right',
          head: {
            body: (
              <span>
                <Trans id="users.table.client_id">Client ID</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.id, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.id),
          },
        },
        {
          cell: (user) => <UserProfileLink id={user.id} text={user.displayName} />,
          head: {
            body: (
              <span>
                <Trans id="users.table.fio">Full name</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.fullName, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.fullName),
          },
        },
        {
          cell: (user) => <span>{user.email}</span>,
          head: {
            body: (
              <span>
                <Trans id="users.table.email">E-mail</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.email, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.email),
          },
        },
        {
          cell: (user) => <span>{user.phone}</span>,
          head: {
            body: (
              <span>
                <Trans id="users.table.phone">Phone</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.phone, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.phone),
          },
        },
        {
          cell: (user) => <RoleCell role={user?.role} />,
          head: {
            body: (
              <span>
                <Trans id="users.table.role">Role</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.role, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.role),
          },
        },
        {
          cell: (user) => <span>{user?.country?.label}</span>,
          head: {
            body: (
              <span>
                <Trans id="users.table.country">Country</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.country, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.country),
          },
        },
        {
          cell: (user) => (
            <span
              className={classnames({ 'color-red': !user.isActive, 'color-green': user.isActive })}
            >
              {user.isActive ? <Trans id="yes">Yes</Trans> : <Trans id="no">No</Trans>}
            </span>
          ),
          head: {
            body: (
              <span>
                <Trans id="users.table.active">Active</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.active, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.active),
          },
        },
        {
          cell: (user) => <OnBoardingStatusCell user={user} />,
          head: {
            body: (
              <span>
                <Trans id="users.table.status">Status</Trans>
              </span>
            ),
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.onBoardingStatus, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.onBoardingStatus),
          },
        },
        {
          cell: (user) => <VerificationStatusCell user={user} />,
          head: {
            body: (
              <span>
                <Trans id="users.table.verification">Verification</Trans>
              </span>
            ),
            onSort: (direction) =>
              onTableSorted({ id: ColumnIds.verification, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.verification),
          },
        },
        {
          cell: (user) => (
            <Tooltip
              placement="bottomLeft"
              title={<Trans id="users.table.ums_actor_code">UMS actor code</Trans>}
              trigger={[TooltipTrigger.hover, TooltipTrigger.focus]}
            >
              <Truncate length={10} text={user.umsId} />
            </Tooltip>
          ),
          head: {
            body: (
              <span>
                <Trans id="users.table.secondary_market_id">Secondary Market ID</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.umsId, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.umsId),
          },
        },
        {
          cell: (user) => <span>{user.issuedAt?.formatLocalDateTime()}</span>,
          head: {
            body: (
              <span>
                <Trans id="users.table.issued_at">Registrated</Trans>
              </span>
            ),
            onSort: (direction) => onTableSorted({ id: ColumnIds.issuedAt, direction: direction }),
            sortDirection: columnSortDirection(ColumnIds.issuedAt),
          },
        },
      ]}
    />
  );
};

export const UsersTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsersTable);
