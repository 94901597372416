import React, { FC } from 'react';

import { useErrorsTranslates } from '@services/translate';

export const ValidationMessage: FC<{
  error: ValidationErrorItem;
}> = ({ error }) => {
  const translations = useErrorsTranslates();

  // translations(error?.message) - for backward compatibility
  return (
    translations(error?.code, error?.params) ||
    translations(error?.message) ||
    error?.message ||
    null
  );
};

export interface ValidationErrorItem {
  code: string;
  field: string;
  message?: string;
  params?: Record<string, string | number | null>;
}
