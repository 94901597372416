import { ReduxAction } from '@models/redux';
import { WireTransferViewActionsTypes as T } from './model';

class OnBankAccountSelected extends ReduxAction<T.ON_BANK_ACCOUNT_SELECTED, number> {
  public readonly type: T.ON_BANK_ACCOUNT_SELECTED = T.ON_BANK_ACCOUNT_SELECTED;
  public constructor(public payload: number) {
    super(payload);
  }
}

class OnInvestorSelectedAction extends ReduxAction<T.ON_INVESTOR_SELECTED, number> {
  public readonly type: T.ON_INVESTOR_SELECTED = T.ON_INVESTOR_SELECTED;
  public constructor(public payload: number) {
    super(payload);
  }
}

export const wireTransferViewUserActions = {
  onBankAccountSelected: (id: number) => new OnBankAccountSelected(id).get(),
  onInvestorSelected: (id: number) => new OnInvestorSelectedAction(id).get(),
};

export type WireTransferViewUserActionsTypes = OnBankAccountSelected | OnInvestorSelectedAction;

export declare namespace WireTransferViewUserActions {
  type onBankAccountSelected = OnBankAccountSelected;
  type onInvestorSelected = OnInvestorSelectedAction;
}
