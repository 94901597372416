import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { PermissionGroup, makeQuery, Permission } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { KycState } from '@config/store/model';

// ----------------------------------------------------------------
export enum EditProfileFields {
  address = 'address',
  company = 'company',
  country = 'country.id',
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
}
export interface EditProfileInfo {
  [EditProfileFields.address]: string;
  [EditProfileFields.company]: string;
  country: { id: number };
  [EditProfileFields.email]: string;
  [EditProfileFields.firstName]: string;
  kyc: { state: KycState };
  [EditProfileFields.lastName]: string;
  [EditProfileFields.phone]: string;
}
const getEditProfileFields = [
  'address',
  'company',
  { country: ['id'] },
  'email',
  'firstName',
  { kyc: ['state'] },
  'lastName',
  'phone',
];
const getEditProfileQuery = 'userProfile';
const getEditProfileInfo = makeQuery({
  permissions: { and: [Permission.PROFILE_VIEW_MY] },
  queryName: getEditProfileQuery,
  queryFields: getEditProfileFields,
  query: (): Promise<EditProfileInfo> => {
    return stompClientService.getData(
      getEditProfileQuery,
      sign(getEditProfileQuery, getEditProfileFields)
    );
  },
});
// ----------------------------------------------------------------
export type EditProfileForm = Omit<EditProfileInfo, 'kyc'>;
const editProfileFields = ['id'];
const editProfileQuery = 'updateProfile';
const editProfile = makeQuery({
  permissions: { and: [Permission.PROFILE_EDIT_MY] },
  queryName: editProfileQuery,
  queryFields: editProfileFields,
  query: (form: EditProfileForm): Promise<void> => {
    return stompClientService.sendData(
      editProfileQuery,
      sign(editProfileQuery, editProfileFields),
      v.mapStringObjectScalar({ userProfile: form })
    );
  },
});
// ----------------------------------------------------------------
const api = {
  editProfile,
  getEditProfileInfo,
};

export const permissionsGroup = PermissionGroup.extract(
  api,
  'api:profile-my-page-edit-profile-modal'
);

export default api;
