import { all, put, select, takeEvery } from 'redux-saga/effects';

import { Put } from '@helper/redux';
import { modalSelectors } from './selectors';
import { modalServiceActions } from './service.acions';
import { ModalUserActions } from './user.acions';
import { ModalActionsTypes as ModalTypes } from './model';

export function* modalEffects() {
  yield all([
    takeEvery(ModalTypes.ON_CLOSE_LATEST_MODAL, closeLatestWithAnimation),
    takeEvery(ModalTypes.ON_CLOSE_MODAL_CLICKED, closeWithAnimation),
    takeEvery(
      ModalTypes.ON_SHOW_CONFIRMATION_MODAL_CLICKED,
      Put(modalServiceActions.showConfirmationModal)
    ),
    takeEvery(ModalTypes.ON_SHOW_MODAL_CLICKED, showWithAnimation),
    takeEvery(ModalTypes.ON_SHOW_NOTIFY_MODAL_CLICKED, showNotifyWithAnimation),
  ]);
}

function* closeLatestWithAnimation() {
  yield new Promise((r: Function) => setTimeout(r, 250));
  yield put(modalServiceActions.closeLatestModal());
}

function* closeWithAnimation(action: ModalUserActions.OnCloseModalClicked) {
  yield new Promise((r: Function) => setTimeout(r, 250));
  yield put(modalServiceActions.closeModal(action.payload));
  const modals = yield select(modalSelectors.modals);
  if (modals.length) {
    yield put(modalServiceActions.showPreviousHiddenModalAction());
  }
}

function* showWithAnimation(action: ModalUserActions.OnShowModalClicked) {
  const isClosePrevious = action.payload?.isClosePrevious;
  const modals = yield select(modalSelectors.modals);
  if (modals.length) {
    yield new Promise((r: Function) => setTimeout(r, 250));
  }
  if (isClosePrevious) {
    yield put(modalServiceActions.closeModal());
  }
  yield put(modalServiceActions.showModal(action.payload));
}

function* showNotifyWithAnimation(action: ModalUserActions.OnShowNotifyModalClicked) {
  const isClosePrevious = action.payload?.isClosePrevious;
  const modals = yield select(modalSelectors.modals);
  if (modals.length) {
    yield new Promise((r: Function) => setTimeout(r, 250));
  }
  if (isClosePrevious) {
    yield put(modalServiceActions.closeModal());
  }
  yield put(modalServiceActions.showNotifyModal(action.payload));
}
