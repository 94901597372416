import {
  EventVaultUpdatedPayload,
  vaultsPageStateInitial,
  VaultsPageActionsTypes as VaultsTypes,
  VaultsPageState,
  VaultsPageActions,
} from './model';
import { VaultInfo } from '../../vaults-api.service';

export const vaultsPageReducer = (
  state: VaultsPageState = vaultsPageStateInitial,
  action: VaultsPageActions
): VaultsPageState => {
  switch (action.type) {
    case VaultsTypes.DELETE_VAULT: {
      return {
        ...state,
        isDeleteVaultLoading: true,
      };
    }

    case VaultsTypes.DELETE_VAULT_FAIL: {
      return {
        ...state,
        isDeleteVaultLoading: false,
      };
    }

    case VaultsTypes.DELETE_VAULT_SUCCESS: {
      const vault: VaultInfo = action.payload;
      return {
        ...state,
        isDeleteVaultLoading: false,
        vaults: state.vaults.filter((item) => item.id !== vault.id),
      };
    }

    case VaultsTypes.EVENT_VAULT_UPDATED: {
      const config: EventVaultUpdatedPayload = action.payload;
      const isNewVault: boolean = config.isNewVault;
      const vault: VaultInfo = config.vault;
      const vaults: Array<VaultInfo> = isNewVault
        ? [vault, ...state.vaults]
        : state.vaults.map((item) => (item.id === vault.id ? vault : item));
      return {
        ...state,
        vaults: vaults,
        lastUpdatedVaultId: vault.id,
        vaultUpdateCounter: state.vaultUpdateCounter + 1,
      };
    }

    case VaultsTypes.VAULTS_LOADED: {
      const { listKey, vaults, hasMore } = action.payload;
      if (listKey !== state.listKey) {
        return state;
      }
      return {
        ...state,
        vaults: state.vaults.concat(vaults),
        hasMore: hasMore,
      };
    }

    case VaultsTypes.VAULTS_CLEANED: {
      const { initialVaults, sortOrder, hasMore } = action.payload;
      return {
        ...state,
        vaults: initialVaults,
        listKey: state.listKey + 1,
        sortOrder: sortOrder,
        hasMore: hasMore,
      };
    }

    case VaultsTypes.ON_PAGE_LEFT: {
      return {
        ...vaultsPageStateInitial,
      };
    }

    default: {
      return state;
    }
  }
};
