import React from 'react';
import { Trans } from '@lingui/macro';
import { ButtonPrimary } from '@components/button';
import { PermissionGroup } from '@permissions/core';
import { useApproveBidOrderModalModel } from './model';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [],
  marker: 'layout:approve-bid-order',
});

// TODO: refactor

export const ApproveBidOrderModal = ({ bidOrderId, isVisible, onBidOrderApproved }) => {
  const { bankTxId, closeModal, setBankTxId, inProcess, approveOrder } =
    useApproveBidOrderModalModel(bidOrderId, onBidOrderApproved);

  return (
    <Modal isVisible={isVisible} disabled={inProcess} loading={inProcess} onClose={closeModal}>
      <ModalTitle>
        <Trans id="approve_bid_order_modal.approve.bid_order">Approve bid order</Trans>
      </ModalTitle>

      <ModalContent>
        <div className="mb-md">
          <Trans id="approve_bid_order_modal.to.approve.bid.order">
            To approve the bid order you need to enter id of the bank transaction from investor bank
            account to issuer bank account.
          </Trans>
        </div>

        <div className="mb-md">
          <label className="field-label label-small">
            <Trans id="approve_bid_order_modal.bank.transaction.id">Bank transaction id</Trans>
            <span className="color-red">&nbsp;*</span>
          </label>
          <div className="text-block">
            <input
              type="text"
              value={bankTxId}
              onChange={(e) => setBankTxId(e.target.value)}
              disabled={inProcess}
            />
          </div>
        </div>
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary fullWidth={true} disabled={!bankTxId || inProcess} onClick={approveOrder}>
          <Trans id="button.approve.bid.order">Approve bid order</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
