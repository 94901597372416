import { all, put, select, takeEvery } from 'redux-saga/effects';

import { handleBackendError } from '@modules/notify';
import { showModalAction } from '@modules/modal';
import { Put } from '@helper/redux';
import {
  beneficialApiService as beneficialApi,
  BeneficialMetal,
  BeneficialOwner,
} from '../beneficial-api.service';
import { BlockMetalModalProps } from '../block-metal-modal/model';
import { BlockMetalModal } from '../block-metal-modal';
import { BeneficialOwnersActionsTypes as BeneficialTypes } from './model';
import { beneficialOwnersServiceActions as beneficialServiceActions } from './service.acions';
import { beneficialOwnersSelectors as beneficialSelectors } from './selectors';

export function* beneficialOwnersEffects() {
  yield all([
    takeEvery(BeneficialTypes.GET_DIGITAL_METALS, getDigitalMetals),
    takeEvery(BeneficialTypes.LOAD_MORE_BENEFICIAL_OWNERS, loadMoreBeneficialOwners),
    takeEvery(BeneficialTypes.ON_BENEFICIAL_OWNERS_TABLE_SCROLLED, onBeneficialOwnersTableScroll),
    takeEvery(BeneficialTypes.ON_PAGE_OPENED, Put(beneficialServiceActions.getDigitalMetals)),
    takeEvery(BeneficialTypes.ON_TOGGLE_BLOCK_OWNER_BUTTON_CLICKED, showBlockOwnerModal),
  ]);
}

function* getDigitalMetals() {
  try {
    const metals = yield beneficialApi.getDigitalMetals();
    yield put(beneficialServiceActions.getDigitalMetalsSuccess(metals));
  } catch (error) {
    yield put(beneficialServiceActions.getDigitalMetalsFail(error as Error));
    handleBackendError(error);
  }
}

function* loadMoreBeneficialOwners(action) {
  try {
    const { metal, page } = action.payload;
    const ownersInfo = yield beneficialApi.getBeneficialOwners(page, metal);
    yield put(beneficialServiceActions.loadMoreBeneficialOwnersSuccess(ownersInfo));
  } catch (error) {
    yield put(beneficialServiceActions.loadMoreBeneficialOwnersFail(error as Error));
    handleBackendError(error);
  }
}

function* onBeneficialOwnersTableScroll() {
  const pageIndex = yield select(beneficialSelectors.pageIndex);
  const metalSelected = yield select(beneficialSelectors.digitalMetalSelected);
  yield put(
    beneficialServiceActions.loadMoreBeneficialOwners({
      metal: metalSelected,
      page: pageIndex,
    })
  );
}

function* showBlockOwnerModal(action) {
  const owner: BeneficialOwner = action.payload;
  yield put(
    showModalAction<BlockMetalModalProps>({
      modal: BlockMetalModal,
      props: {
        blockedMetals: owner.metals
          .filter((item: BeneficialMetal) => item.blocked)
          .map((item: BeneficialMetal) => item.asset),
        owner: owner,
      },
    })
  );
}
