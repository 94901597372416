import { ReactNode, useContext, useMemo } from 'react';

import { useLabel } from '@components/form/antd-form/label';
import { CaseMode, MatchMode } from '@modules/rsql';
import { showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { SearchConfigModal, SearchConfigModalProps } from '../../config-modal';
import { Context, FieldsConfig, SearchForm, TextModeForm } from '../../context';

export interface SearchTextProps {
  className?: string;
  disabled?: boolean;
  label?: ReactNode;
  name: string;
}

export const useModel = (p: SearchTextProps) => {
  const { dispatch, state } = useContext(Context);
  const form: SearchForm = state.searchForm;
  const textMode: TextModeForm = state.textMode;

  const { shifted, onBlur, onFocus } = useLabel({ value: form && form[p.name] });

  const isFilterUsed: boolean = useMemo(
    () =>
      textMode?.caseMode === CaseMode.sensitive ||
      textMode?.fieldsConfig === FieldsConfig.customFields ||
      textMode?.matchMode === MatchMode.strict,
    [textMode?.caseMode, textMode?.fieldsConfig, textMode?.matchMode]
  );

  const showModal = useCallDispatch(showModalAction);

  const onConfigureSearchClicked = () => {
    const props: SearchConfigModalProps = {
      fields: state.searchFields.filter((item) => item.type !== 'dateRange'),
      form: textMode,
      onModeChange: (f: TextModeForm) => dispatch({ payload: f, type: 'ON_TEXT_MODE_CHANGE' }),
    };
    // TODO-2833: fix types
    // @ts-ignore
    showModal({ modal: SearchConfigModal, props });
  };

  return {
    isFilterUsed,
    onBlur,
    onConfigureSearchClicked,
    onFocus,
    shifted,
  };
};
