import { useState } from 'react';
import { closeModalAction } from '@modules/modal';
import { handleBackendError } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import api from './api';

export const useModel = (redeem, replaceRedeem, modal) => {
  const [lockUI, setLockUI] = useState<boolean>(false);

  const onClose = useCallDispatch(closeModalAction);

  const onCancelClicked = async () => {
    try {
      setLockUI(true);
      const cancelledRedeem = await api.cancelRedeem(redeem.coin.id);
      replaceRedeem({ ...redeem, status: 'CANCELLATION_PENDING' });
      setLockUI(false);
      onClose(modal);
    } catch (error) {
      handleBackendError(error);
      setLockUI(false);
    }
  };

  return {
    lockUI,
    onCancelClicked,
    onClose,
  };
};
