import React, { FC } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Form, InputDateSimple, InputPhone, InputText, SubmitButton } from '@components/form';
import { NationalitySelect } from '@modules/countries';
import { Fields, formId, useModel } from './model';

export const ProvideContactInfoStep: FC = () => {
  const m = useModel();

  return (
    <Form
      name={formId}
      initialValues={m.formInitial}
      className={s.form}
      uat="contact-information-page"
    >
      <h2>
        <Trans id="signup.contact_info_step.title">Welcome!</Trans>
      </h2>

      <div className={classnames(s.label, 'mb-md')}>
        <Trans id="signup.contact_info_step.text">Please provide your contact information</Trans>
      </div>

      <InputText
        name={Fields.firstName}
        label={<Trans id="signup.contact_info_step.label.first_name">First name</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <InputText
        name={Fields.lastName}
        label={<Trans id="signup.contact_info_step.label.last_name">Last name</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <InputDateSimple
        name={Fields.birthday}
        label={<Trans id="signup.contact_info_step.label.date_of_birth">Date Of Birth</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <InputPhone
        name={Fields.phone}
        label={<Trans id="signup.contact_info_step.label.phone">Telephone number</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <NationalitySelect
        name={Fields.nationality}
        label={<Trans id="signup.contact_info_step.label.nationality">Nationality</Trans>}
        disabled={m.lockUI}
        required={true}
      />

      <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
        <Trans id="signup.contact_info_step.button.continue">Continue</Trans>
      </SubmitButton>
    </Form>
  );
};
