import { memo, useContext, useRef, ComponentType, ReactNode, Ref, RefCallback } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useInitialRenderEffect, useSyncRef } from '../utils';
import { BaseItem } from '../base-table';
import { Context, NotifyUpdate } from './context';

export type SetScrollableRef = RefCallback<HTMLElement>;

export type BodyComponent<T extends BaseItem> = ComponentType<{
  columnWidthList?: number[];
  error: boolean;
  hasMore: boolean;
  loading: boolean;
  notifyUpdate: NotifyUpdate;
  rawData: readonly T[];
  tableHeight: number;
  tableWidth?: number;
  setScrollableRef: SetScrollableRef;
}>;

type ScrollInfo = {
  scrollbarSize: number;
  ref: Ref<{ scrollLeft: number }>;
  onScroll: (info: { currentTarget?: HTMLElement; scrollLeft?: number }) => void;
};

// This is aligned with CustomizeScrollBody<T> from rc-table (which is
// used by antd). Since antd doesn't reexport the type we just rely on
// type checking to be consistent.
export type BodyRenderFunction<T extends BaseItem> = (
  data: readonly T[],
  info: ScrollInfo
) => ReactNode;

const LOAD_BOTTOM_INDENT = 50;

function isScrollAtBottom(el: HTMLElement, diffPx: number) {
  if (el.clientHeight === 0) {
    return true;
  }
  const currentDiffPx = el.scrollHeight - (el.scrollTop + el.clientHeight);
  return currentDiffPx < diffPx;
}

type WrappedBodyComponentProps<T extends BaseItem> = {
  data: readonly T[];
  bodyComponent: BodyComponent<T>;
  scrollInfo: ScrollInfo;
};

const WrappedBodyComponent = <T extends BaseItem>(props: WrappedBodyComponentProps<T>) => {
  const {
    checkAndMaybeLoad,
    columnWidthList,
    configLoadCheck,
    error,
    hasMore,
    loading,
    notifyUpdate,
    tableHeight,
    tableWidth,
  } = useContext(Context);

  const checkAndMaybeLoadRef = useSyncRef(checkAndMaybeLoad);
  const containerRef = useRef<HTMLElement>(null);

  const checkIfShouldLoad = useCallbackOne(() => {
    if (!containerRef.current) return false;
    return isScrollAtBottom(containerRef.current, LOAD_BOTTOM_INDENT);
  }, []);

  const onScroll = useCallbackOne(
    (e: any) => {
      checkAndMaybeLoadRef.current();
      const scrollLeft = e?.currentTarget?.scrollLeft;
      if (typeof scrollLeft === 'number') {
        props.scrollInfo.onScroll({ scrollLeft });
      }
    },
    [props.scrollInfo.onScroll]
  );

  const setScrollableRef = useCallbackOne<SetScrollableRef>(
    (el) => {
      containerRef.current?.removeEventListener('scroll', onScroll);
      containerRef.current = el;

      // Use optional chaining to prevent exceptions during hot reload.
      containerRef.current?.addEventListener('scroll', onScroll, { passive: true });
    },
    [onScroll]
  );

  useInitialRenderEffect(() => {
    configLoadCheck(() => {
      const shouldLoad = checkIfShouldLoad();
      return Promise.resolve(shouldLoad);
    });
  });

  const MemoizedBodyComponent = useMemoOne(() => {
    return memo(props.bodyComponent);
  }, [props.bodyComponent]);

  return (
    <MemoizedBodyComponent
      columnWidthList={columnWidthList}
      error={error}
      hasMore={hasMore}
      loading={loading}
      notifyUpdate={notifyUpdate}
      rawData={props.data}
      tableHeight={tableHeight}
      tableWidth={tableWidth}
      setScrollableRef={setScrollableRef}
    />
  );
};

export function bodyRenderFunctionFactory<T extends BaseItem>(
  bodyComponent: BodyComponent<T>
): BodyRenderFunction<T> {
  return function bodyRenderFunction(data, info) {
    return <WrappedBodyComponent data={data} bodyComponent={bodyComponent} scrollInfo={info} />;
  };
}
