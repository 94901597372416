import { useContext, FC } from 'react';
import { Trans } from '@lingui/macro';
import { ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { Context } from '../context';
import { ButtonPrimary } from '@components/button';

export const UnknownError: FC = () => {
  const { dispatch } = useContext(Context);
  const goToCurrencySetting = () => {
    dispatch({ type: 'ON_CURRENCY_SETTING_REQUESTED' });
  };

  return (
    <>
      <ModalTitle>
        <Trans id={'my_wallet.exchange_currency.unknown_error.title'}>
          Currency conversion error
        </Trans>
      </ModalTitle>
      <ModalContent>
        <Trans id={'my_wallet.exchange_currency.unknown_error.message'}>
          Your currency conversion cannot be completed at this time. Please try to reduce the amount
          for conversion or try again later. If the problem persists, please contact customer
          support.
        </Trans>
      </ModalContent>
      <ModalFooter>
        <ButtonPrimary
          className={'wallet-currency-exchange-submit-button'}
          onClick={goToCurrencySetting}
          fullWidth
        >
          <Trans id={'my_wallet.exchange_currency.unknown_error.go_back'}>Back</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </>
  );
};
