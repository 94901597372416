import { createContext, createElement, useContext, ComponentType } from 'react';

// We override header component to be able to pass column widths
// filling free space in the container.

export type HeaderProps = {
  columnWidthList: number[];
};

type HeaderContextValue = {
  columnWidthList: number[];
  headerComponent: ComponentType<HeaderProps>;
};

export const HeaderContext = createContext<HeaderContextValue>({
  columnWidthList: [],
  headerComponent: () => null,
});

export const HeaderWrapper = () => {
  const { columnWidthList, headerComponent } = useContext(HeaderContext);
  if (!headerComponent) {
    return null;
  }
  return createElement(headerComponent, { columnWidthList });
};
