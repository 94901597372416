import React, { memo } from 'react';

import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ButtonIconPure } from '@components/button';
import { HasAccess, Role as R } from '@permissions/core';
import { SearchBox } from '@components/search';

import { permissionsGroup as handleUserModalPermissionsGroup } from '../../../../profile/other/modals/handle-user-modal';
import { useModel } from './model';

export const permissionsGroup = handleUserModalPermissionsGroup.createSuperGroup({
  marker: 'layout:add-user-button',
});

const accessRoles = [R.admin];
const accessPermissions = { or: [permissionsGroup] };

type Props = {
  className?: string;
};
export const AddUserButton = memo<Props>(({ className }) => {
  const m = useModel();

  return (
    <HasAccess roles={accessRoles} permissions={accessPermissions}>
      <SearchBox className={className}>
        <ButtonIconPure
          disabled={m.disabled}
          size="lg"
          loading={null}
          icon={<AddIcon className="tm2-icon-stroke" />}
          onClick={m.onAddUserClicked}
        />
      </SearchBox>
    </HasAccess>
  );
});
