import { useMemo } from 'react';
import { useQuery } from '@hooks/core';
import api from '../add-correction-transaction-modal/api';

export const useIssuers = () => {
  const { data: issuersData, isLoading: isIssuersLoading } = useQuery(api.getIssuers, {
    initial: [],
  });

  const modifiedIssuersData = [{ displayName: 'TM2', id: -2 }, ...issuersData];

  const issuers = useMemo(() => {
    return modifiedIssuersData?.map((i) => ({
      label: i.displayName,
      value: i.id,
    }));
  }, [issuersData]);

  return { isIssuersLoading, issuers };
};
