import { createContext, ReactNode } from 'react';
import { BaseItem } from '../loadable-virtual-table';
import { TableSorterReturnType } from './use-table';
import { TableColumnType } from './types';

function noop() {}

export type ContextValue<T extends BaseItem> = {
  columns: TableColumnType<T>[];
  sorter: TableSorterReturnType;
};

export const Context = createContext<ContextValue<any>>({
  columns: [],
  sorter: {
    order: [],
    mergeOrderItem: noop,
    resetUncontrolledOrder: noop,
  },
});

export type ProviderProps<T extends BaseItem> = {
  value: ContextValue<T>;
  children: ReactNode;
};

export const Provider = <T extends BaseItem>(props: ProviderProps<T>) => {
  return <Context.Provider value={props.value}>{props.children}</Context.Provider>;
};
