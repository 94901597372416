import React, { Ref, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from '@lingui/macro';
import { catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';

import { OperationError } from '@services/stomp/errors';
import { handleBackendError } from '@modules/notify';
import { useShowPreloaderLine } from '@components/layouts/dark/provider';
import { configSelectors, TariffsPermissions } from '@config/core';
import { showModalAction, showNotifyModalAction } from '@modules/modal';
import { useIsMountedRef } from 'containers/services/commonService';
import { NoMinimalBalanceModal } from '../no-minimal-balance-modal';
import {
  StateData,
  useActiveOfferPrices,
  useSelectedPair,
  useSetActiveOfferPrices,
  useStableBalance,
} from '../provider';
import { useGetActiveOffersPrices, useOrderBookHeight } from '../service';
import { BuyConfirmModal } from '../modals/buy-confirm';
import { calculatePrice } from '../modals/service';
import {
  metalsApiService as metalsApi,
  MyStableBalance,
  OfferPrice,
  TickerItem,
} from '../metals-api.service';

type OffersPrices = StateData<Array<OfferPrice>>;

export const useOrderBookModel = () => {
  const dispatch = useDispatch();
  const height: number = useOrderBookHeight();
  const selectedPair: TickerItem = useSelectedPair();
  const [isBuyButtonDisabled, setBuyButtonDisabled] = useState<boolean>(false);
  const [offerPricesInitial]: OffersPrices = useGetActiveOffersPrices(
    selectedPair?.coin?.id,
    selectedPair?.currency?.id
  ) as OffersPrices;
  const [orders, lockUI, reloadOrders]: OffersPrices = useActiveOfferPrices();
  const setOrders: (prices: OffersPrices) => void = useSetActiveOfferPrices();

  useEffect(() => {
    setOrders([offerPricesInitial, lockUI, reloadOrders]);
  }, [offerPricesInitial]); // eslint-disable-line

  useEffect(() => {
    if (!selectedPair) {
      return;
    }
    const subscription = metalsApi
      .orderPricesChanges$(selectedPair?.coin?.id, selectedPair?.currency?.id)
      .pipe(
        tap((newOrders) => setOrders([newOrders, lockUI, reloadOrders])),
        catchError((error: Error) => {
          handleBackendError(error);
          return of(undefined);
        })
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, [selectedPair]); // eslint-disable-line

  const [stableBalance]: StateData<Array<MyStableBalance>> = useStableBalance();
  const showPreloaderLine: (v: boolean) => void = useShowPreloaderLine();
  const permissions: Array<string> = useSelector(configSelectors.tariffPermissions);

  useEffect(() => {
    return () => showPreloaderLine(false); // hide preloader on user left page
  }, []); // eslint-disable-line

  const isMountedRef: Ref<boolean> = useIsMountedRef();

  const onOfferPriceSelected = async (offerPrice, currencyCode) => {
    try {
      setBuyButtonDisabled(true);
      showPreloaderLine(true);
      const isHasTariffPermission = permissions?.includes(TariffsPermissions.confirmOffer);

      if (!isMountedRef.current) {
        return;
      }

      if (!isHasTariffPermission) {
        dispatch(
          showNotifyModalAction({
            description: (
              <Trans id="no-membership-modal.text">
                Your membership type does not have permissions for this action
              </Trans>
            ),
            type: 'fail',
            title: <Trans id="no-membership-modal.title">The action is not permitted</Trans>,
          })
        );
        return;
      }

      const currentFiatInfo = stableBalance
        ?.filter((asset) => !!asset.currency)
        .find((asset) => currencyCode === asset.currency.code);
      const existingBalance = currentFiatInfo?.balance || 0;
      const { unitPriceWithCommission } = calculatePrice(1, offerPrice);

      if (existingBalance < unitPriceWithCommission) {
        dispatch(showModalAction({ modal: NoMinimalBalanceModal }));
      } else {
        const quantity = await metalsApi.getOfferAvailableQuantity(offerPrice.offer.id);
        if (quantity < 1) {
          throw new OperationError({ data: { code: 'NOT_ENOUGH_OFFER_QUANTITY' } });
        }
        dispatch(
          showModalAction({
            modal: BuyConfirmModal,
            props: { selectedPair, offerPrice, stableBalance: currentFiatInfo },
          })
        );
      }
    } catch (error) {
      handleBackendError(error);
    } finally {
      showPreloaderLine(false);
      setBuyButtonDisabled(false);
    }
  };

  const getOfferDate = (price: OfferPrice): string => {
    if (window.innerWidth <= 410) {
      // for mobile devices
      return price?.offer?.date?.formatLocalDate();
    }
    return price?.offer?.date?.formatLocalDateTime();
  };

  return {
    getOfferDate,
    isBuyButtonDisabled,
    height,
    orders,
    lockUI,
    onOfferPriceSelected,
    currencyCode: selectedPair?.currency?.code,
  };
};
