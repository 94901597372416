import React from 'react';
import classnames from 'classnames';
import HighCharts from 'highcharts/highstock';
import HighChartsReact from 'highcharts-react-official';
import { useProductChartModel } from './model';
import { Trans } from '@lingui/macro';

export const ProductChart = ({ onLoad }) => {
  const { unit, setUnit, isWeekPriceLoading, chartOptions } = useProductChartModel(onLoad);

  return (
    <div className={`col col-graph-absolute`}>
      <div className="chart-filtr buy-metals-chart">
        <span className="chart-filtr__title mb-0">
          <Trans id="buy_metals.show">Show:</Trans>
        </span>

        <label className="radio-label mb-0 mr-xss">
          <input
            type="radio"
            value="WEEKS"
            checked={unit === 'WEEKS'}
            onChange={(e) => setUnit(e.target.value)}
          />
          <div className="radio-label-text">
            <Trans id="buy_metals.week">week</Trans>
          </div>
        </label>

        <label className="radio-label mb-0 mr-xss">
          <input
            type="radio"
            value="MONTHS"
            checked={unit === 'MONTHS'}
            onChange={(e) => setUnit(e.target.value)}
          />
          <div className="radio-label-text">
            <Trans id="buy_metals.month">month</Trans>
          </div>
        </label>

        <label className="radio-label mb-0 mr-xss">
          <input
            type="radio"
            value="YEARS"
            checked={unit === 'YEARS'}
            onChange={(e) => setUnit(e.target.value)}
          />
          <div className="radio-label-text">
            <Trans id="buy_metals.year">year</Trans>
          </div>
        </label>
      </div>

      <div className="chart-wrapper">
        <HighChartsReact
          containerProps={{
            className: classnames('chart-container', { 'block-blurred': isWeekPriceLoading }),
            style: { height: '100%', maxHeight: '40vh', minHeight: '120px' },
          }}
          highcharts={HighCharts}
          options={chartOptions}
          allowChartUpdate={true}
          immutable={false}
          updateArgs={[true, true, true]}
        />
      </div>
    </div>
  );
};
