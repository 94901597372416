import sign from 'tm2sign.macro';

export default {
  issuerBalanceFields: sign('issuerBalance', [
    'asset',
    'sales',
    'tokenCommission',
    { stableBalance: ['currencyCode', 'sales', 'saleCommission', 'purchasesCommission'] },
  ]),
};
