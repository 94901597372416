import {
  Table as OriginalTable,
  BaseItem,
  DefaultRowCellProps,
  TableProps as OriginalTableProps,
} from '../bound-cells';
import { TableResizeObserver, ResizerBaseTableProps } from './resize-observer';

export { useTable, areFieldsEqual, areOrdersEqual, sortItems } from '../bound-cells';

export type {
  // General
  BaseItem,
  ColumnType,
  ErrorElement,
  EstimateRowHeight,
  HasMore,
  KeyExtractor,
  ListKey,
  LoaderElement,
  OnLoadMore,
  TableComponents,
  // Bound components
  HeaderProps,
  HeaderCellConfig,
  HeaderCellWrapperProps,
  HeaderWrapperProps,
  RowProps,
  RowCellConfig,
  RowCellWrapperProps,
  RowWrapperProps,
  // Sorter
  InMemorySorter,
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterParams,
  TableSorterReturnType,
} from '../bound-cells';

export type BaseTableProps<
  T extends BaseItem,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = ResizerBaseTableProps<T, H, R>;

export type TableProps<
  T extends BaseItem,
  K,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
> = Omit<OriginalTableProps<T, K, H, R>, 'baseTableProps' | 'className' | 'totalYOffset'> & {
  baseTableProps: BaseTableProps<T, H, R>;
  className?: string;

  // Antd table requires to specify scroll.y. It's height of scrollable area,
  // which will be a part of the total height. The other part will consist of
  // header and footer if presented. Thus, to pass correct scroll.y to antd
  // component, we need to know table header and footer heights ahead of time.
  totalYOffset: number;
};

export function ExtensibleTable<
  T extends BaseItem,
  K,
  H extends object = {},
  R extends DefaultRowCellProps<T> = DefaultRowCellProps<T>
>({ className, baseTableProps, totalYOffset, ...other }: TableProps<T, K, H, R>) {
  return (
    <TableResizeObserver
      className={className}
      baseTableProps={baseTableProps}
      totalYOffset={totalYOffset}
    >
      {({ baseTableProps, height, width }) => (
        <div style={{ position: 'absolute', height: '100%', width }}>
          {height ? <OriginalTable {...other} baseTableProps={baseTableProps} /> : null}
        </div>
      )}
    </TableResizeObserver>
  );
}
