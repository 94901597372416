import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';

// ----------------------------------------------------------------
export enum CreateBankAccountFields {
  // these fields are sorting by their view in modal
  // Default
  nickname = 'nickname',
  currency = 'currency.id',
  accountNumber = 'accountNumber',
  beneficiaryAccountName = 'beneficiaryAccountName',
  // Beneficiary bank
  bankName = 'bankName',
  swiftCode = 'swiftCode',
  routeCode = 'routeCode',
  ibanNumber = 'ibanNumber',
  // Beneficiary bank address
  bankAddressStreetAndNumber = 'bankAddressStreetAndNumber',
  bankAddressDetails = 'bankAddressDetails',
  bankAddressCity = 'bankAddressCity',
  bankAddressPostalCode = 'bankAddressPostalCode',
  bankAddressState = 'bankAddressState',
  bankAddressCountry = 'bankAddressCountry',
  // Other
  comment = 'comment',
}
export interface CreateBankAccountForm {
  // these fields are sorting by their view in modal
  // Default
  [CreateBankAccountFields.nickname]: string;
  currency: { id: number };
  [CreateBankAccountFields.accountNumber]: string;
  [CreateBankAccountFields.beneficiaryAccountName]: string;
  // Beneficiary bank
  [CreateBankAccountFields.bankName]: string;
  [CreateBankAccountFields.swiftCode]: string;
  [CreateBankAccountFields.routeCode]?: string;
  [CreateBankAccountFields.ibanNumber]?: string;
  // Beneficiary bank address
  [CreateBankAccountFields.bankAddressStreetAndNumber]: string;
  [CreateBankAccountFields.bankAddressDetails]?: string;
  [CreateBankAccountFields.bankAddressCity]: string;
  [CreateBankAccountFields.bankAddressPostalCode]: string;
  [CreateBankAccountFields.bankAddressState]?: string;
  [CreateBankAccountFields.bankAddressCountry]: string;
  // Other
  [CreateBankAccountFields.comment]?: string;
}
const createBankAccountFields = ['id'];
const createBankAccountQuery = 'createUserBankAccount';
const createBankAccount = makeQuery({
  permissions: { and: [Permission.BANK_ACCOUNTS_VIEW_MY] }, // note, возможно тут нужен новый USER_BANK_ACCOUNTS_EDIT_MY
  queryName: createBankAccountQuery,
  queryFields: createBankAccountFields,
  query: (bankAccount: CreateBankAccountForm): Promise<number> => {
    return stompClientService
      .sendData(
        createBankAccountQuery,
        sign(createBankAccountQuery, createBankAccountFields),
        v.userBankAccountCreateDTOInput({ bankAccount: bankAccount })
      )
      .then((account: { id: number }) => account.id);
  },
});
// ----------------------------------------------------------------
const api = {
  createBankAccount,
};

export const permissionGroup = PermissionGroup.extract(
  api,
  'api:profile-my-page-create-bank-account-modal'
);

export default api;
