import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import Image from 'containers/systems/image';
import { formatMoney, formatToken } from 'containers/services/commonService';
import { redeemModalSelectors as redeemSelectors } from '../store/selectors';
import { redeemOptionsModalUserActions as redeemOptionsUserActions } from './store/user.acions';
import { redeemOptionsModalSelectors as redeemOptionsSelectors } from './store/selectors';
import { ButtonPrimary } from '@components/button';
import { Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';

const mapStateToProps = (root) => ({
  balance: redeemSelectors.redeemBalance(root),
  lockUI: redeemOptionsSelectors.lockUI(root),
  redeemOptions: redeemOptionsSelectors.redeemOptions(root),
  selectedRedeemOption: redeemOptionsSelectors.selectedRedeemOption(root),
});

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(redeemOptionsUserActions.onCloseModalClicked()),
  onChooseClicked: (option) =>
    dispatch(redeemOptionsUserActions.onChooseSelectedRedeemOptionClicked(option)),
  onModalLeft: () => dispatch(redeemOptionsUserActions.onModalLeft()),
  onModalOpened: (coinId) => dispatch(redeemOptionsUserActions.onModalOpened(coinId)),
  onRedeemOptionSelected: (option) =>
    dispatch(redeemOptionsUserActions.onRedeemOptionSelected(option)),
});

const ConnectedRedeemOptionsModal = ({
  balance,
  isVisible,
  lockUI,
  onClose,
  onChooseClicked,
  onModalLeft,
  onModalOpened,
  onRedeemOptionSelected,
  redeemOptions,
  selectedRedeemOption,
}) => {
  useEffect(() => {
    onModalOpened(balance.coin.id);
    return () => onModalLeft();
  }, []); // eslint-disable-line

  return (
    <Modal isVisible={isVisible} loading={lockUI} disabled={lockUI} onClose={onClose}>
      <ModalTitle>
        <Trans id="wallet.redeem.redeem.options_v2">Redemption options</Trans>
      </ModalTitle>

      <ModalContent>
        {!redeemOptions?.length ? (
          <Trans id="wallet.redeem.not_available">
            The redemption is not available for this Asset.
          </Trans>
        ) : (
          redeemOptions.map((redeemOption) => {
            let isPriceMetal = redeemOption.priceType === 'METAL';

            return (
              <div key={redeemOption.id}>
                <label className="form-label">
                  <input
                    type="radio"
                    name={'redeem-option-radio'}
                    onChange={() => onRedeemOptionSelected(redeemOption)}
                  />
                  <span>
                    <i>
                      {redeemOption.image && (
                        <div style={{ maxHeight: '150px', maxWidth: '150px' }}>
                          <Image
                            className={'label-img'}
                            downloadable={false}
                            uploadFile={redeemOption.image}
                          />
                        </div>
                      )}
                      <div className="middle-text">
                        {`${redeemOption.name}, ${redeemOption.amount}${redeemOption.unit.label}`}
                      </div>
                      <div className="op-text">{redeemOption.description}</div>
                      <div className="color-red">
                        <small>
                          <Trans id="wallet.redeem.min.quantity.redeem.price">
                            Min. quantity: {redeemOption.minimalQuantity} {redeemOption.unit.label},
                            redemption price:
                            {`${
                              isPriceMetal
                                ? formatToken(redeemOption.price)
                                : formatMoney(redeemOption.price)
                            } ${isPriceMetal ? balance.coin.asset : ''}`}
                          </Trans>
                        </small>
                      </div>
                    </i>
                  </span>
                </label>
              </div>
            );
          })
        )}
      </ModalContent>

      <ModalFooter>
        <ButtonPrimary
          fullWidth={true}
          disabled={!selectedRedeemOption || lockUI}
          onClick={() => onChooseClicked(selectedRedeemOption)}
        >
          <Trans id="button.redeem">Redeem</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};

export const RedeemOptionsModal = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedRedeemOptionsModal);
