import { useParams } from 'react-router';

import { useQuery } from '@hooks/core';

import api, { Fields } from './api';

export const useInitialValues = () => {
  const defaultValues = {
    [Fields.decimals]: '0',
    [Fields.status]: 'active',
    [Fields.assetSubclass]: 'METALS',
  };

  const p = useParams<{ coinId: string }>();

  const { data: initialValues, isLoading: isInitialValuesLoading } = useQuery(() =>
    api.getInitialValues(+p.coinId).then((v) => {
      return {
        [Fields.assetCode]: v.asset,
        [Fields.umsAssetCode]: v.asset,
        [Fields.decimals]: defaultValues.decimals,
        [Fields.assetFullName]: v.name,
        [Fields.status]: defaultValues.status,
        [Fields.assetSubclass]: defaultValues.assetSubclass,
      };
    })
  );

  return { initialValues, isInitialValuesLoading };
};
