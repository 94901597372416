import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { configSelectors, KycState } from '@config/core';
import { closeModalAction, showModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { countriesSelectors } from '@modules/countries';
import { OnFormAction } from '@components/form';
import v from '@components/form/validators';
import { useEditProfileInfo } from './hooks';
import api, { EditProfileFields, EditProfileForm } from './api';
import { PasswordModal } from '../password';

export { EditProfileFields as Fields } from './api';
export const formId = 'editMyProfileFormId';

export const useModel = (modal) => {
  const closeModal = useCallDispatch(closeModalAction);
  const showModal = useCallDispatch(showModalAction);
  const isCountriesLoading = useSelector(countriesSelectors.isLoading);
  const isAdmin = useSelector(configSelectors.isAdmin);
  const isTrader = useSelector(configSelectors.isInvestor);
  const { isProfileLoading, profileInitial } = useEditProfileInfo();
  const [isUpdateProfileLoading, setUpdateProfileLoading] = useState<boolean>(false);
  const lockUI = isCountriesLoading || isProfileLoading || isUpdateProfileLoading;

  const formInitial = useMemo(() => {
    if (!profileInitial) {
      return undefined;
    }
    const { kyc, ...form } = profileInitial;
    return form;
  }, [profileInitial]);

  const lock = useMemo(() => {
    const isKycApproved = profileInitial?.kyc?.state === KycState.approved;
    return {
      address: lockUI || (!isAdmin && isTrader && isKycApproved),
      company: lockUI || (!isAdmin && isTrader && isKycApproved),
      country: lockUI || (!isAdmin && isTrader && isKycApproved),
      firstName: lockUI || (!isAdmin && isTrader && isKycApproved),
      lastName: lockUI || (!isAdmin && isTrader && isKycApproved),
      phone: lockUI || (!isAdmin && isTrader && isKycApproved),
      ui: lockUI,
    };
  }, [profileInitial, lockUI]);

  const onFormAction: OnFormAction<EditProfileForm> = {
    error: () => setUpdateProfileLoading(false),
    fieldValidation: {
      [EditProfileFields.address]: [v.required],
      [EditProfileFields.company]: [v.required],
      [EditProfileFields.country]: [v.required],
      [EditProfileFields.email]: [v.required],
      [EditProfileFields.firstName]: [v.required],
      [EditProfileFields.lastName]: [v.required],
      [EditProfileFields.phone]: [v.required],
    },
    submit: async (form) => {
      setUpdateProfileLoading(true);
      await api.editProfile(form);
      setUpdateProfileLoading(false);
      showModal({
        modal: PasswordModal,
        props: { profile: form },
      });
    },
  };

  return {
    closeModal: () => closeModal(modal),
    formInitial,
    lock,
    onFormAction,
  };
};
