import {
  FORM_ADD_ARRAY_ELEMENT,
  FORM_CHANGE,
  FORM_CHANGE_ALT,
  FORM_CHANGE_CHECKBOX,
  FORM_ERRORS,
  FORM_INIT,
  FORM_IS_SEND,
  FORM_REMOVE_ARRAY_ELEMENT,
} from '@constants/redux';
import _ from 'lodash';
import { checkRegExp } from '../containers/services/commonService';

const initialState = {
  errors: {},
  data: {},
  isSend: {},
};

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case FORM_INIT:
      return {
        ...state,
        data: { ...state.data, [action.formName]: action.data },
        isSend: { ...state.isSend, [action.formName]: false },
      };
    case FORM_IS_SEND:
      return { ...state, isSend: { ...state.isSend, [action.formName]: action.isSend } };
    case FORM_CHANGE:
      let changeForm = { ...state.data[action.formName] };
      _.set(changeForm, action.fieldName, action.fieldValue);
      if (action.fieldValue == null) _.unset(changeForm, action.fieldName);
      return { ...state, data: { ...state.data, [action.formName]: changeForm } };
    case FORM_CHANGE_CHECKBOX:
      let changeCheckboxForm = { ...state.data[action.formName] };
      const keyObj = action.fieldName.substring(action.fieldName.lastIndexOf('.') + 1);

      const fieldName = action.fieldName.substring(0, action.fieldName.lastIndexOf('['));

      let fieldCheckbox = _.get(changeCheckboxForm, fieldName) || [];

      const pattern = /^[0-9]+$/i;
      const fieldValue = pattern.test(action.fieldValue)
        ? parseInt(action.fieldValue)
        : action.fieldValue;

      const removedItems = _.remove(fieldCheckbox, (i) => i[keyObj] === fieldValue);
      if (removedItems.length === 0) {
        _.set(fieldCheckbox, `${fieldCheckbox.length}`, { [keyObj]: fieldValue });
      }

      changeCheckboxForm = { ...changeCheckboxForm, [fieldName]: fieldCheckbox };

      return { ...state, data: { ...state.data, [action.formName]: changeCheckboxForm } };
    case FORM_CHANGE_ALT: {
      const { formName, event, regexp = '.*' } = action;
      const { name, value, type } = event.target;
      const toChange = { ...state.data[formName] };

      if (type === 'checkbox') {
        if (value === 'on' || value === 'false' || value === 'true') {
          _.set(toChange, name, event.target.checked);
        } else {
          const array = _.get(toChange, name, []);
          if (event.target.checked) {
            _.set(array, `${array.length}`, value);
            _.set(toChange, name, array);
          } else {
            _.remove(array, (v) => v === value);
            const newArray = _.compact(array);
            _.set(toChange, name, newArray);
          }
        }
      } else if (type === 'textarea') {
        _.set(toChange, name, value);
      } else if (type === 'radio') {
        if (value === 'true' || value === 'false') {
          _.set(toChange, name, value === 'true');
        } else {
          _.set(toChange, name, value);
        }
      } else {
        if (checkRegExp(value, regexp)) {
          _.set(toChange, name, value);
        }
      }

      return { ...state, data: { ...state.data, [formName]: toChange } };
    }

    case FORM_REMOVE_ARRAY_ELEMENT: {
      const formData = { ...state.data[action.formName] };
      const array = _.get(formData, action.path);
      _.unset(array, `[${action.index}]`);
      const newArray = _.compact(array);
      _.set(formData, action.path, newArray);
      return { ...state, data: { ...state.data, [action.formName]: formData } };
    }

    case FORM_ADD_ARRAY_ELEMENT: {
      const formData = { ...state.data[action.formName] };
      const array = _.get(formData, action.path);
      _.set(array, `${array.length}`, {});
      _.set(formData, action.path, array);
      return { ...state, data: { ...state.data, [action.formName]: formData } };
    }

    case FORM_ERRORS:
      const errors = { ...state.errors, [action.formName]: action.errors };
      return { ...state, errors: errors };
    default:
      return state;
  }
};

export default formReducer;
