import { useDispatch, useSelector } from 'react-redux';
import { useMemo, useState } from 'react';

import { configSelectors, configServiceActions } from '@config/core';
import { OnFormAction } from '@components/form';
import api from '../../api';

export const formId = 'contactDetailsFormId';

export enum Fields {
  firstName = 'firstName',
  lastName = 'lastName',
  phone = 'phone',
}

interface Form {
  [Fields.firstName]: string;
  [Fields.lastName]: string;
  [Fields.phone]: string;
}

export const useModel = () => {
  const reduxDispatch = useDispatch();
  const user = useSelector(configSelectors.userInfo);
  const isUserInfoLoading = useSelector(configSelectors.isUserInfoLoading);
  const [isSendFormLoading, setSendFormLoading] = useState<boolean>();

  const formInitial = useMemo(
    () => ({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
    }),
    [user]
  );

  const onFormAction: OnFormAction<Form> = {
    error: () => setSendFormLoading(false),
    submit: async (form: Form) => {
      setSendFormLoading(true);
      await api.sendContactDetailsForm(form);
      setSendFormLoading(false);
      reduxDispatch(configServiceActions.postRegFinished());
    },
  };

  return {
    formInitial,
    lockUI: isSendFormLoading || isUserInfoLoading,
    onFormAction,
  };
};
