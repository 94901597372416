import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { defineMessage, Trans } from '@lingui/macro';
import { useI18n } from 'containers/services/commonService';
import {
  IsHasAccess,
  Permission as P,
  PermissionGroup,
  permissionsSelectors,
  Role as R,
} from '@permissions/core';
import { PageContent, PageTitle } from '@components/page';
import { NewTabs, Tab } from 'components/new-tabs';
import { IssuersTab } from './issuers-tab';
import { ListingBrokersTab } from './listing-brokers-tab';
import { permissionGroup as listingBrokerTabPermissionGroup } from './listing-brokers-tab';
import { permissionGroup as issuersTabPermissionGroup } from './issuers-tab';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [listingBrokerTabPermissionGroup, issuersTabPermissionGroup],
  marker: 'layout:settlements-page',
});

export const SettlementsPage: FC = () => {
  const { i18n } = useI18n();

  const hasAccessFn: IsHasAccess = useSelector(permissionsSelectors.isHasAccess);

  const tabs: Array<Tab> = [
    hasAccessFn({ or: [P.TRANSACTION_CREATE_CORRECTION] })
      ? {
          title: <Trans id="settlements.tab.issuers">Issuers</Trans>,
          name: 'correction-transactions',
          content: <IssuersTab />,
        }
      : null,
    hasAccessFn(null, null, null, [R.admin])
      ? {
          title: <Trans id="settlements.tab.listing-brokers">Listing brokers</Trans>,
          name: 'listing-brokers',
          content: <ListingBrokersTab />,
        }
      : null,
  ].filter((tab) => !!tab);

  return (
    <PageContent className={s.verticalFiller} uat="statements-settlements-page">
      <PageTitle
        title={i18n._(
          defineMessage({ id: 'balance_page.rab.settlements', message: 'Settlements' })
        )}
        backwardLink={'/statements'}
      />

      <NewTabs tabs={tabs} className={s.verticalFiller} />
    </PageContent>
  );
};
