import React, { FC } from 'react';
import AltTable, { AltTableProps } from 'components/alt-table';

import s from './index.module.scss';
import { NoWalletResultsText } from './no-results';

export const WalletTable: FC<Omit<AltTableProps, 'disabled'> & { loading: boolean }> = ({
  loading,
  ...altTableProps
}) => {
  return (
    <div className={s.tableWrapper}>
      <AltTable
        disabled={false}
        headClasses={s.tableHead}
        rootClasses={s.tableRoot}
        noDataText={<NoWalletResultsText />}
        {...altTableProps}
      />
    </div>
  );
};
