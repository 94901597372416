import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { useCallDispatch } from '@hooks/core';
import { configSelectors } from '@config/core';
import { handleBackendError } from '@modules/notify';
import { showModalAction } from '@modules/modal';
import { accountService as account } from '@services/account';
import { Permission as P } from '@permissions/core';
import {
  permissionsGroup as editUserPermissionsGroup,
  useShowHandleUserModal,
} from '../../../../../profile/other/modals/handle-user-modal';
import { EditTariffModal } from '../../../../../profile/other/modals/edit-tariff';
import { AddSMTAModal } from '../../../../add-smt-account-modal';
import usersApi, { UserTableItem } from '../../../../users-api.service';
import { usersPageServiceActions as actions } from '../../../store/service.acions';

export const useEditActions = (user: UserTableItem) => {
  const [lockUI, setLockUI] = useState<boolean>();
  const isAdmin = useSelector(configSelectors.isAdmin);

  const { showHandleUserModal } = useShowHandleUserModal(user.id);

  const showModal = useCallDispatch(showModalAction);
  const emitUserUpdated = useCallDispatch(actions.eventUsersUpdated);

  const updateCurrentUser = useCallback(
    async (userId: number) => {
      setLockUI(true);
      try {
        const user = await usersApi.getUpdatedUserTableInfo(userId);
        emitUserUpdated({ isNewUser: false, isNoScroll: true, user });
      } catch (error) {
        handleBackendError(error);
      }
      setLockUI(false);
    },
    [emitUserUpdated]
  );

  const onEditUserClicked = useCallback(
    () => showHandleUserModal({ onUserHandled: updateCurrentUser }),
    [showHandleUserModal, updateCurrentUser]
  );

  const onEditTariffClicked = useCallback(
    (userId: number) => {
      showModal({
        modal: EditTariffModal,
        props: {
          onTariffUpdated: () => updateCurrentUser(userId),
          userId,
        },
      });
    },
    [showModal, updateCurrentUser]
  );

  const onAddSMTAccountClicked = useCallback(
    (user: UserTableItem) => {
      showModal({
        modal: AddSMTAModal as any,
        props: {
          user,
          // TODO колбек обновления текущего юзера вынести сюда
        },
      });
    },
    [showModal]
  );

  const editUserAction = useMemo(() => {
    return {
      access: { permissions: { and: [editUserPermissionsGroup] } },
      action: onEditUserClicked,
      content: <Trans id="users.table.action.edit">Edit</Trans>,
    };
  }, [onEditUserClicked]);

  const editTariffAction = useMemo(() => {
    return !(isAdmin && !!user?.userTariff?.tariff?.id)
      ? null
      : {
          action: () => onEditTariffClicked(user.id),
          content: <Trans id="users.table.action_upgrade_tariff">Upgrade plan</Trans>,
        };
  }, [isAdmin, onEditTariffClicked, user]);

  const addSMTAAction = useMemo(() => {
    return !(!user.umsId && account.isTrader(user.permissions))
      ? null
      : {
          access: { permissions: { or: [P.SECONDARY_MARKET_CREATE_PREPARED_TRADING_ACCOUNT] } },
          action: () => onAddSMTAccountClicked(user),
          content: (
            <Trans id="users.table.actions.add_smt_account">
              Add Secondary Market trading account
            </Trans>
          ),
        };
  }, [onAddSMTAccountClicked, user]);

  return {
    addSMTAAction,
    editTariffAction,
    editUserAction,
    lockUI,
  };
};
