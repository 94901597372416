import { combineReducers } from 'redux';
import { all, fork } from 'redux-saga/effects';
import {
  beneficialOwnersStateInitial,
  BeneficialOwnersState as BeneficialOwnersPageState,
} from './store/model';
import { beneficialOwnersReducer as beneficialOwnersPageReducer } from './store/reducer';
import { beneficialOwnersEffects as beneficialOwnersPageEffects } from './store/effects';

export interface BeneficialOwnersState {
  page: BeneficialOwnersPageState;
}

export const beneficialOwnersState: BeneficialOwnersState = {
  page: { ...beneficialOwnersStateInitial },
};

export const beneficialOwnersReducer = combineReducers({
  page: beneficialOwnersPageReducer,
});

export function* beneficialOwnersEffects() {
  yield all([fork(beneficialOwnersPageEffects)]);
}
