import React, { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Form as AntForm } from 'antd';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { ReactComponent as CrossIcon } from '@images/icons/cross-icon.svg';
import { ReactComponent as UploadIcon } from '@images/icons/upload-icon.svg';
import { Label } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

/**
 * Represents form field to select custom file
 */
export const InputFile: FC<{
  className?: string;
  compact?: boolean;
  extra?: ReactNode;
  fieldKey?: React.Key | React.Key[];
  label: ReactNode;
  name: NamePath;
  disabled?: boolean;
  placeholder: ReactNode;
  required?: boolean;
  uat?: string;
}> = ({
  className,
  compact,
  extra,
  disabled,
  fieldKey,
  label,
  name,
  placeholder: placeholderInitial,
  required,
  uat,
}) => {
  const uatAttribute = useFieldUat(name, 'input-file', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-file', className, {
        'tm2-form-field-compact': compact,
        'tm2-form-field-extra': !!extra,
      })}
      data-uat={uatAttribute}
    >
      <Item
        className="tm2-form-field-item"
        name={name}
        fieldKey={fieldKey}
        extra={extra}
        required={required}
      >
        <CustomFileInput
          disabled={disabled}
          label={label}
          name={name}
          placeholderInitial={placeholderInitial}
          required={required}
        />
      </Item>
    </div>
  );
};

const CustomFileInput: FC<{
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (file: File) => void;
  placeholderInitial: ReactNode;
  required: boolean;
}> = (props) => {
  const [lockUI, setLockUI] = useState(props.disabled);
  const [placeholder, setPlaceholder] = useState(props.placeholderInitial);
  const [isFileSelected, setFileSelected] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setLockUI(props.disabled);
  }, [props.disabled]);

  const onFileSelected = async (event) => {
    const files = event.target.files;
    if (!files.length || files.length > 1) {
      return;
    }

    const file: File = files[0];
    setPlaceholder(file.name);
    props.onChange(file);
    setFileSelected(true);
  };

  const onFileRemoved = (event: MouseEvent<SVGSVGElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setPlaceholder(props.placeholderInitial);
    setFileSelected(false);
    inputRef.current.value = null;
    props.onChange(null);
  };

  return (
    <>
      <Label htmlFor={props.name} label={props.label} shifted={true} required={props.required} />

      <label
        className={classnames('tm2-form-field-item-instance tm2-field-file-label', {
          'tm2-field-file-disabled': lockUI,
        })}
      >
        <div
          className={classnames('tm2-field-file-label-placeholder', {
            'tm2-field-file-label-placeholder-selected': isFileSelected,
          })}
        >
          {placeholder}
        </div>

        <div className="tm2-field-file-label-icon ml-auto">
          {isFileSelected ? (
            <CrossIcon className="tm2-field-file-label-icon-svg" onClick={onFileRemoved} />
          ) : (
            <UploadIcon className="tm2-field-file-label-icon-svg" />
          )}
        </div>
        <input
          type="file"
          ref={inputRef}
          onChange={onFileSelected}
          disabled={lockUI}
          hidden={true}
        />
      </label>
    </>
  );
};
