import sign from 'tm2sign.macro';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

export interface Trade {
  buyer: string;
  lastPx: string;
  lastQty: string;
  seller: string;
  symbol: string;
  tradeId: string;
  transactionTime: string;
}

const getSecondaryTradesListQuery = 'secondaryTradesList';
const getSecondaryTradesListFields = [
  'buyer',
  'lastPx',
  'lastQty',
  'seller',
  'symbol',
  'tradeId',
  'transactionTime',
];
const getSecondaryTradesList = makeQuery({
  permissions: Permission.SECONDARY_MARKET_VIEW_TRADE_LIST,
  queryName: getSecondaryTradesListQuery,
  queryFields: getSecondaryTradesListFields,
  query: (): Promise<Array<Trade>> => {
    return stompClient
      .getData(
        getSecondaryTradesListQuery,
        sign(getSecondaryTradesListQuery, getSecondaryTradesListFields)
      )
      .then((response) => response);
  },
});

const api = {
  getSecondaryTradesList,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:secondary-traders-report');

export default api;
