import React, { FC, ReactNode, useCallback, useState } from 'react';
import classnames from 'classnames';
import { Form as AntForm, Checkbox } from 'antd';
import { useResizeDetector } from 'react-resize-detector';
import { NamePath } from 'rc-field-form/lib/interface';

import './styles.scss';
import { gridPattern } from '@components/grid';
import { GridPattern } from '@constants/theme';
import { useFieldUat } from '../uat';

const { Item } = AntForm;
const { Group } = Checkbox;

export interface CheckboxItem {
  label: ReactNode;
  value: string | number | boolean;
}

export const CheckboxGroup: FC<{
  className?: string;
  data: Array<CheckboxItem>;
  disabled?: boolean;
  label: ReactNode;
  name: NamePath;
  pattern?: Partial<GridPattern>;
  required?: boolean;
  uat?: string;
}> = ({ className, data, disabled, label, name, pattern, required, uat }) => {
  const uatAttribute = useFieldUat(name, 'checkbox-group', uat);

  return (
    <div
      className={classnames('tm2-form-field tm2-field-checkbox-group', className)}
      data-uat={uatAttribute}
    >
      <Item
        className="tm2-form-field-item tm2-field-checkbox-group-item"
        name={name}
        required={required}
      >
        <CustomCheckboxGroup
          name={name}
          label={label}
          required={required}
          disabled={disabled}
          pattern={pattern}
          data={data}
        />
      </Item>
    </div>
  );
};

const __pattern: Partial<GridPattern> = {};
const CustomCheckboxGroup: FC<{
  data: Array<CheckboxItem>;
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (v: Array<CheckboxItem['value']>) => void;
  pattern: Partial<GridPattern>;
  required: boolean;
  value?: Array<CheckboxItem['value']>;
}> = (props) => {
  const _pattern = props.pattern || __pattern;
  const patternDefault: GridPattern = { xss: 1, xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1 };
  const [pattern] = useState<GridPattern>({
    xss: _pattern.xss || patternDefault.xss,
    xs: _pattern.xs || _pattern.xss || patternDefault.xs,
    sm: _pattern.sm || _pattern.xs || _pattern.xss || patternDefault.sm,
    md: _pattern.md || _pattern.sm || _pattern.xs || _pattern.xss || patternDefault.md,
    lg:
      _pattern.lg || _pattern.md || _pattern.sm || _pattern.xs || _pattern.xss || patternDefault.lg,
    xl:
      _pattern.xl ||
      _pattern.lg ||
      _pattern.md ||
      _pattern.sm ||
      _pattern.xs ||
      _pattern.xss ||
      patternDefault.xl,
    xxl:
      _pattern.xxl ||
      _pattern.xl ||
      _pattern.lg ||
      _pattern.md ||
      _pattern.sm ||
      _pattern.xs ||
      _pattern.xss ||
      patternDefault.xxl,
  });

  const [cols, setCols] = useState(1);

  const onResize = useCallback(
    (width) => {
      let colsNew: number;
      if (width <= gridPattern.xss) {
        colsNew = pattern.xss;
      } else if (width > gridPattern.xss && width <= gridPattern.xs) {
        colsNew = pattern.xs;
      } else if (width > gridPattern.xs && width <= gridPattern.sm) {
        colsNew = pattern.sm;
      } else if (width > gridPattern.sm && width <= gridPattern.md) {
        colsNew = pattern.md;
      } else if (width > gridPattern.md && width <= gridPattern.lg) {
        colsNew = pattern.lg;
      } else if (width > gridPattern.lg && width <= gridPattern.xl) {
        colsNew = pattern.xl;
      } else {
        colsNew = pattern.xxl;
      }
      if (colsNew !== cols) {
        setCols(colsNew);
      }
    },
    [cols, pattern]
  );

  const { ref } = useResizeDetector({ onResize });
  const gap: number = ((cols - 1) * 25) / cols;
  return (
    <>
      <div className="tm2-form-field-item tm2-field-checkbox-group-item-label mb-xs">
        {props.label}
        {!props.required ? null : <span className="tm2-form-label-asterisk">&nbsp;*</span>}
      </div>

      <Group
        className="tm2-form-field-item tm2-field-checkbox-group-item-box"
        options={props.data}
        disabled={props.disabled}
        onChange={props.onChange}
        value={props.value}
        style={{ gridTemplateColumns: `repeat(${cols}, calc(${100 / cols}% - ${gap}px))` }}
        ref={ref}
      >
        {props.data.map((item: CheckboxItem, i: number) => (
          <Checkbox value={item.value} key={`${item.value}-${i}`}>
            {item.label}
          </Checkbox>
        ))}
      </Group>
    </>
  );
};
