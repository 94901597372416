import React from 'react';
import classnames from 'classnames';
import { defineMessage, Trans } from '@lingui/macro';
import s from './index.module.scss';
import { formatToken, useI18n } from 'containers/services/commonService';
import { InfoButtonIcon } from '@components/icons';
import { Popup } from '@components/popup';

export const PositionInfoPopUp = ({
  className,
  coin,
  lastVaultBalance,
  positionBalance,
  positionTotal,
}) => {
  const { i18n } = useI18n();
  const unit = i18n._(defineMessage({ id: 'digital-metals.unit', message: ' unit' }));

  return (
    <Popup
      placement="leftTop"
      content={
        <div>
          <div className={classnames(s.row, 'mb-sm')}>
            <div className="mr-sm">
              <Trans id="digital_metals.position.position_total">Position total</Trans>:
            </div>

            <div className="ml-auto">{formatToken(positionTotal, { post: unit })}</div>
          </div>

          <div className={classnames(s.row, 'mb-sm')}>
            <div className="mr-sm">
              <Trans id="digital_metals.position.position_balance">Position balance</Trans>:
            </div>

            <div className="ml-auto">{formatToken(positionBalance, { post: unit })}</div>
          </div>

          <div className={classnames(s.row, 'mb-sm')}>
            <div className="mr-sm">
              <Trans id="digital_metals.position.in_vaults">In vaults</Trans>:
            </div>

            <div className="ml-auto">{formatToken(lastVaultBalance, { post: unit })}</div>
          </div>

          <div className={classnames(s.row, 'mb-sm')}>
            <div className="mr-sm">
              <Trans id="digital_metals.position.emissioned">Emissioned</Trans>:
            </div>

            <div className="ml-auto">{formatToken(coin.info.total, { post: unit })}</div>
          </div>

          <div className={s.row}>
            <div className="mr-sm">
              <Trans id="digital_metals.position.zero_days">Days below Zero</Trans>:
            </div>

            <div className="ml-auto">{coin.positionNegativeDays}</div>
          </div>
        </div>
      }
    >
      <div className={className}>
        <InfoButtonIcon />
      </div>
    </Popup>
  );
};
