import React, { FC, memo } from 'react';
import { useSelector } from 'react-redux';

import { closeLatestModalAction, modalSelectors } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { ButtonBack } from '../back';

export const ButtonModalBack: FC<{
  className?: string;
  disabled?: boolean;
  onClick?: Function;
}> = memo(({ className, disabled, onClick }) => {
  const modals = useSelector(modalSelectors.modals);
  const closeLatestModal = useCallDispatch(closeLatestModalAction);

  return !onClick && modals.length < 2 ? null : (
    <ButtonBack className={className} onClick={onClick || closeLatestModal} disabled={disabled} />
  );
});
