import { ReduxAction } from '@models/redux';
import { ConfigActionsTypes as T } from './model';

class OnLogoutClickedAction extends ReduxAction<T.ON_LOG_OUT_CLICKED, void> {
  public readonly type: T.ON_LOG_OUT_CLICKED = T.ON_LOG_OUT_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

export const configUserActions = {
  onLogoutClicked: () => new OnLogoutClickedAction().get(),
};

export type ConfigUserActionsTypes = OnLogoutClickedAction;

export declare namespace ConfigUserActions {
  type onLogoutClicked = OnLogoutClickedAction;
}
