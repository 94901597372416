export const bankAccountFields = [
  'accountNumber',
  'bankName',
  'comment',
  {
    currency: [
      'code',
      'id',
      {
        withdrawalRules: ['minWithdrawalAmount', 'minWithdrawalFeeAmount', 'withdrawalFeePercent'],
      },
    ],
  },
  'ibanNumber',
  'id',
  'nickname',
  'routeCode',
  'swiftCode',
];
