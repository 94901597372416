import {
  beneficialOwnersStateInitial,
  BeneficialOwnersActionsTypes as BeneficialTypes,
  BeneficialOwnersState,
  BeneficialOwnersPageActions,
} from './model';

export const beneficialOwnersReducer = (
  state: BeneficialOwnersState = beneficialOwnersStateInitial,
  action: BeneficialOwnersPageActions
): BeneficialOwnersState => {
  switch (action.type) {
    case BeneficialTypes.EVENT_BLOCKED_METALS_UPDATED: {
      const { metals, ownerId } = action.payload;
      return {
        ...state,
        beneficialOwners: state.beneficialOwners.map((owner) => {
          if (owner.id !== ownerId) {
            return owner;
          }
          return {
            ...owner,
            metals: owner.metals.map((item) => ({
              ...item,
              blocked: metals.includes(item.asset),
            })),
          };
        }),
      };
    }

    case BeneficialTypes.GET_DIGITAL_METALS: {
      return {
        ...state,
        isDigitalMetalsLoading: true,
      };
    }

    case BeneficialTypes.GET_DIGITAL_METALS_FAIL: {
      return {
        ...state,
        isDigitalMetalsLoading: false,
      };
    }

    case BeneficialTypes.GET_DIGITAL_METALS_SUCCESS: {
      return {
        ...state,
        isDigitalMetalsLoading: false,
        digitalMetals: action.payload,
      };
    }

    case BeneficialTypes.LOAD_MORE_BENEFICIAL_OWNERS: {
      return {
        ...state,
        isBeneficialOwnersLoading: true,
      };
    }

    case BeneficialTypes.LOAD_MORE_BENEFICIAL_OWNERS_FAIL: {
      return {
        ...state,
        isBeneficialOwnersLoading: false,
        isHasMore: false,
      };
    }

    case BeneficialTypes.LOAD_MORE_BENEFICIAL_OWNERS_SUCCESS: {
      return {
        ...state,
        isBeneficialOwnersLoading: false,
        isHasMore: action.payload.isHasMore,
        beneficialOwners: [...state.beneficialOwners, ...action.payload.data],
        pageIndex: state.pageIndex + 1,
      };
    }

    case BeneficialTypes.ON_DIGITAL_METAL_SELECTED: {
      return {
        ...state,
        beneficialOwners: [],
        digitalMetalSelected: action.payload,
        isHasMore: true,
        pageIndex: 0,
      };
    }

    case BeneficialTypes.ON_DIGITAL_METAL_SORT_CLICKED: {
      return {
        ...state,
        isDigitalMetalsReverse: !state.isDigitalMetalsReverse,
      };
    }

    case BeneficialTypes.ON_INVESTOR_SELECTED: {
      return {
        ...state,
        investorSelected: action.payload,
      };
    }

    case BeneficialTypes.ON_PAGE_LEFT: {
      return {
        ...beneficialOwnersStateInitial,
      };
    }

    default: {
      return state;
    }
  }
};

// TODO previous live update logic
// function sort(currentOwners, reverse) {
//     currentOwners.forEach(owner => {
//         if (reverse === undefined ? state.reverse : reverse) {
//             owner.assets = _.sortBy(owner.assets, 'asset').reverse();
//         } else {
//             owner.assets = _.sortBy(owner.assets, 'asset');
//         }
//     });
//
//     return [...currentOwners];
// }
//
// case COIN_TRUSTED: {
//     const beneficialOwnersMap = new Map();
//     state.beneficialOwners.forEach(value => beneficialOwnersMap.set(value.id, value));
//
//     let owner = action.owner;
//
//     let currentOwner = beneficialOwnersMap.get(owner.id);
//     if (currentOwner) {
//         currentOwner.assets = currentOwner.assets.concat(owner.assets);
//     } else {
//         beneficialOwnersMap.set(owner.id, owner);
//     }
//
//
//     return {...state, beneficialOwners: sort(Array.from(beneficialOwnersMap.values()))}
// }
//
// case COIN_UNTRUSTED: {
//     const beneficialOwnersMap = new Map();
//     state.beneficialOwners.forEach(value => beneficialOwnersMap.set(value.id, value));
//
//     let owner = beneficialOwnersMap.get(action.userId);
//     if (!owner)
//         return state;
//
//     owner.assets = owner.assets.filter(asset => asset !== action.asset);
//     if (owner.assets.length === 0)
//         beneficialOwnersMap.delete(owner.id);
//
//     return {...state, beneficialOwners: sort(Array.from(beneficialOwnersMap.values()))}
// }
