import { defineMessage } from '@lingui/macro';

// list of available errors here https://legacydocs.hubspot.com/docs/methods/forms/submit_form
export const hubSpotErrorMessages = (i18n) => ({
  INVALID_EMAIL: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_email',
      message: 'Wrong email format',
    })
  ),
  BLOCKED_EMAIL: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.blocked_email',
      message: 'Current email is blocked',
    })
  ),
  REQUIRED_FIELD: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.required_field',
      message: 'Field is required',
    })
  ),
  INVALID_NUMBER: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_number',
      message: 'Field should be number',
    })
  ),
  INPUT_TOO_LARGE: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.input_too_large',
      message: 'Field is to long',
    })
  ),
  FIELD_NOT_IN_FORM_DEFINITION: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.field_not_in_form_definition',
      message: 'Field is not supported',
    })
  ),
  NUMBER_OUT_OF_RANGE: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.number_out_of_range',
      message: 'Wrong field value',
    })
  ), // this error return both for number fields and phone fields
  VALUE_NOT_IN_FIELD_DEFINITION: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.value_not_in_field_definition',
      message: 'Provided value is not one of the possible options',
    })
  ),
  INVALID_METADATA: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_metadata',
      message: 'Context object contains an unexpected attribute',
    })
  ),
  INVALID_GOTOWEBINAR_WEBINAR_KEY: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_webinar_key',
      message: 'Webinar key in object context is invalid',
    })
  ),
  INVALID_HUTK: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_hutk',
      message: 'hutk field in object context is invalid.',
    })
  ),
  INVALID_IP_ADDRESS: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_ip_address',
      message: 'ipAddress field in the context object is invalid',
    })
  ),
  INVALID_PAGE_URI: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_page_url',
      message: 'pageUri field in the context object is invalid',
    })
  ),
  INVALID_LEGAL_OPTION_FORMAT: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_legal_option_format',
      message:
        'legalConsentOptions was empty or it contains both the consent and legitimateInterest fields',
    })
  ),
  MISSING_PROCESSING_CONSENT: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_missing_processing_consent',
      message: 'consentToProcess field in consent or value field in legitimateInterest was false',
    })
  ),
  MISSING_PROCESSING_CONSENT_TEXT: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_processing_consent_text',
      message: 'Text field for processing consent was missing',
    })
  ),
  MISSING_COMMUNICATION_CONSENT_TEXT: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_communication_consent_text',
      message: 'Communication consent text was missing for a subscription',
    })
  ),
  MISSING_LEGITIMATE_INTEREST_TEXT: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.invalid_legitimate_interest_text',
      message: 'Legitimate interest consent text was missing',
    })
  ),
  DUPLICATE_SUBSCRIPTION_TYPE_ID: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.duplicate_subscription_type_id',
      message: 'communications list contains two or more items with the same subscriptionTypeId',
    })
  ),
  FORM_HAS_RECAPTCHA_ENABLED: i18n._(
    defineMessage({
      id: 'kyc.page.account.form.validation.hubspot.form_has_recaptcha_enabled',
      message: 'This form has reCAPTCHA enabled so programatic submissions are not allowed',
    })
  ),
});
