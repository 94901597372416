import { Offer as ApiOffer } from './api';
import { OfferByMe } from '../../shared/types';

export function transform(offer: ApiOffer): OfferByMe {
  return {
    id: offer.id,
    activePrices: offer.activeOfferPrices.map((price) => ({
      id: price.id,
      quantity: price.quantity,
      currencyId: price.currency.id,
      currencyCode: price.currency.code,
      unitLabel: price.unit.label,
    })),
    asset: offer.coin.asset,
    availableQuantity: offer.availableQuantity,
    creatorId: offer.creator.id,
    creatorName: offer.creator.displayName,
    date: offer.date,
    metal: offer.coin.metal.label,
    quantityUnitLabel: offer.quantityUnit.label,
    sellCommission: offer.sellCommission,
    status: offer.status,
  };
}
