import React, { useEffect, useRef, useState, FC } from 'react';
import { connect } from 'react-redux';
import { useCallbackOne } from 'use-memo-one';
import { Trans } from '@lingui/macro';
import { debounce } from 'lodash';
import { DispatchFn } from '@models/redux';
import {
  Table,
  useTable,
  ColumnType,
  EstimateRowHeight,
  KeyExtractor,
  OnLoadMore,
  OnSortOrderChange,
  SortOrder,
  ColumnConfig,
  RowConfig,
  ROW_CELL_PADDING_TOP,
  ROW_CELL_PADDING_BOTTOM,
} from '@components/table2/autosized';
import { ProfileLinkCell, TablePane } from '@components/table2/utils';
import { useSyncRef } from '@hooks/core';
import { VaultInfo, vaultsApiService } from '../../vaults-api.service';
import {
  VaultsTableColumnIds as ColumnIds,
  OnVaultsLoadedPayload,
  OnVaultsSortedPayload,
} from '../store/model';
import { vaultsPageSelectors } from '../store/selectors';
import { vaultsPageUserActions } from '../store/user.acions';
import { VaultActionsCell } from './actions-cell';
import { CoinCell, ITEM_HEIGHT as COIN_HEIGHT } from './coin-cell';
import s from '../index.module.scss';

type LoadIndex = {
  nextPage: number;
};

const mapStateToProps = (root) => ({
  vaults: vaultsPageSelectors.vaults(root),
  listKey: vaultsPageSelectors.listKey(root),
  sortOrder: vaultsPageSelectors.sortOrder(root),
  hasMore: vaultsPageSelectors.hasMore(root),
  lastUpdatedVaultId: vaultsPageSelectors.lastUpdatedVaultId(root),
  vaultUpdateCounter: vaultsPageSelectors.vaultUpdateCounter(root),
});

const mapDispatchToProps = (dispatch: DispatchFn) => ({
  onVaultsLoaded: (data: OnVaultsLoadedPayload) => {
    dispatch(vaultsPageUserActions.onVaultsLoaded(data));
  },
  onVaultsSorted: (data: OnVaultsSortedPayload) => {
    dispatch(vaultsPageUserActions.onVaultsSorted(data));
  },
});

const columns: ColumnType<VaultInfo>[] = [
  {
    key: ColumnIds.actions,
    dataIndex: null,
    headCell: () => null,
    rowCell: ({ data }) => <VaultActionsCell vault={data} />,
    width: 60,
  },
  {
    key: ColumnIds.country,
    dataIndex: ['country', 'label'],
    headCell: () => <Trans id="vaults.table.country">Country</Trans>,
    rowCell: ({ data }) => <>{data.country?.label}</>,
    sorting: ['ascend', 'descend'],
    width: 200,
  },
  {
    key: ColumnIds.city,
    dataIndex: 'city',
    headCell: () => <Trans id="vaults.table.city">City</Trans>,
    rowCell: ({ data }) => <>{data.city}</>,
    sorting: ['ascend', 'descend'],
    width: 125,
  },
  {
    key: ColumnIds.warehouseCompany,
    dataIndex: 'warehouseCompany',
    headCell: () => <Trans id="vaults.table.warehouse_company">Warehouse company</Trans>,
    rowCell: ({ data }) => <>{data.warehouseCompany}</>,
    sorting: ['ascend', 'descend'],
    width: 250,
  },
  {
    key: ColumnIds.address,
    dataIndex: 'address',
    headCell: () => <Trans id="vaults.table.address">Address</Trans>,
    rowCell: ({ data }) => <>{data.address}</>,
    sorting: ['ascend', 'descend'],
    width: 200,
  },
  {
    key: ColumnIds.symbol,
    dataIndex: 'coins',
    headCell: () => <Trans id="vaults.table.symbol">Symbol</Trans>,
    rowCell: ({ data }) => <CoinCell vault={data} />,
    width: 200,
    tooltip: false,
  },
  {
    key: ColumnIds.vaultManager,
    dataIndex: 'vaultManagers',
    headCell: () => <Trans id="vaults.table.vault_manager">Vault manager</Trans>,
    rowCell: ({ data }) => (
      <>
        {data.vaultManagers?.map((vm) => (
          <ProfileLinkCell text={vm.displayName} id={vm.id} />
        ))}
      </>
    ),
    width: 170,
  },
  {
    key: ColumnIds.contact,
    dataIndex: 'contact',
    headCell: () => <Trans id="vaults.table.contacts">Contacts</Trans>,
    rowCell: ({ data }) => <>{data.contact}</>,
    sorting: ['ascend', 'descend'],
    width: 150,
  },
];

const columnConfig: ColumnConfig = {
  tooltip: 'overflow',
};

const rowConfig: RowConfig<VaultInfo> = {
  height: 'fixed',
};

const INITIAL_PAGE = 0;
const SORTING_DEBOUNCE = 200;

const ConnectedVaultsTable: FC<
  ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
> = ({
  vaults,
  listKey,
  sortOrder,
  hasMore,
  lastUpdatedVaultId,
  onVaultsLoaded,
  onVaultsSorted,
  vaultUpdateCounter,
}) => {
  const listKeyRef = useSyncRef(listKey);
  const sortOrderRef = useSyncRef(sortOrder);
  const vaultsSyncRef = useSyncRef(vaults);
  const initialLoadKeyRef = useRef(0);
  const initialPageRef = useRef(INITIAL_PAGE);
  const [isLoadingInitialVaults, setIsLoadingInitialVaults] = useState(false);

  const estimateRowHeight = useCallbackOne<EstimateRowHeight<VaultInfo>>((vault) => {
    return (
      ROW_CELL_PADDING_BOTTOM +
      ROW_CELL_PADDING_TOP +
      Math.max(1, vault.coins?.length || 0, vault.vaultManagers?.length || 0) * COIN_HEIGHT
    );
  }, []);

  const keyExtractor = useCallbackOne<KeyExtractor<VaultInfo>>((vault) => {
    return vault.id;
  }, []);

  const loadMoreVaults = useCallbackOne<OnLoadMore<LoadIndex>>(({ lastIndexData }) => {
    const page = lastIndexData?.nextPage ?? initialPageRef.current;
    const queryListKey = listKeyRef.current;

    return vaultsApiService
      .getVaults(page, sortOrderRef.current)
      .then(({ data: incomingVaults, isHasMore }) => {
        onVaultsLoaded({
          vaults: incomingVaults,
          listKey: queryListKey,
          hasMore: isHasMore,
        });
        return { indexData: { nextPage: page + 1 } };
      });
  }, []);

  const loadInitialVaults = useCallbackOne(
    debounce((order: SortOrder) => {
      const queryInitialLoadKey = initialLoadKeyRef.current;

      vaultsApiService
        .getVaults(INITIAL_PAGE, order)
        .then(({ data: incomingVaults, isHasMore }) => {
          if (queryInitialLoadKey !== initialLoadKeyRef.current) {
            return;
          }
          onVaultsSorted({
            initialVaults: incomingVaults,
            sortOrder: order,
            hasMore: isHasMore,
          });
          initialPageRef.current = INITIAL_PAGE + 1;
          setIsLoadingInitialVaults(false);
        });
    }, SORTING_DEBOUNCE),
    []
  );

  const onSortOrderChange = useCallbackOne<OnSortOrderChange>((order) => {
    initialLoadKeyRef.current += 1;
    setIsLoadingInitialVaults(true);
    loadInitialVaults(order);
  }, []);

  const tableInstance = useTable<VaultInfo>({
    sorter: {
      onAfterOrderChange: onSortOrderChange,
    },
  });

  useEffect(() => {
    if (lastUpdatedVaultId === null) return;

    const vaultIndex = vaultsSyncRef.current.findIndex((v) => v.id === lastUpdatedVaultId);

    if (vaultIndex === -1) return;

    tableInstance.scrollToIndex(vaultIndex);
  }, [lastUpdatedVaultId, vaultUpdateCounter]);

  return (
    <TablePane className={s.verticalFiller}>
      <Table
        className={s.verticalFiller}
        columns={columns}
        data={vaults}
        estimateRowHeight={estimateRowHeight}
        hasMore={hasMore}
        instance={tableInstance}
        keyExtractor={keyExtractor}
        listKey={listKey}
        loading={isLoadingInitialVaults}
        onLoadMore={loadMoreVaults}
        columnConfig={columnConfig}
        rowConfig={rowConfig}
        scrollDebounce={100}
      />
    </TablePane>
  );
};

export const VaultsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedVaultsTable);
