import React from 'react';
import classnames from 'classnames';

import { Icon } from '../icon';

export const TextField = (prop) => {
  const {
    label = '',
    error = null,
    icon,
    autocomplete = 'off',
    readonly = false,
    value = null,
    rounded = false,
    border = false,
    maxLength,
    ...rest
  } = prop;
  const blockClass = classnames('text-block', {
    'block-error': error !== null,
    'text-block-rounded': rounded === true,
    'text-block--border': border === true,
  });
  return (
    <div className={blockClass}>
      {icon && <Icon icon={icon} />}
      <input
        readOnly={readonly}
        value={value ?? (value === null ? undefined : '')}
        autoComplete={autocomplete}
        maxLength={maxLength}
        type="text"
        placeholder={label}
        {...rest}
      />
      {error !== null && <ControlError>{error}</ControlError>}
    </div>
  );
};

export const FileField = (prop) => {
  const { name, onChange, error = null, accept } = prop;
  const blockClass = classnames('file-box', {
    'block-error': error !== null,
  });
  return (
    <div className={blockClass}>
      <label>
        <input type="file" name={name} className="inputfile" onChange={onChange} accept={accept} />
        <div className="link link-icon">
          <div className="icon-item">
            <i className="icon icon--add" />
            <span className="icon-item__text">{prop.children}</span>
          </div>
        </div>
      </label>
      {error !== null && <ControlError>{error}</ControlError>}
    </div>
  );
};

export const ControlError = (props) => {
  return <span className="error">{props.children}</span>;
};
