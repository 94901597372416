import { all, put, takeEvery } from 'redux-saga/effects';
import { Put } from '@helper/redux';
import { Trans } from '@lingui/macro';
import decode from 'jwt-decode';

import { KKLang, Lang } from '@models/core';
import { Role } from '@permissions/core';
import { loggerService as logger } from '@services/logger';
import { notifyError } from '@modules/notify';
import { session, SessionStatus as Session, TokenInfo } from '@services/session';
import { configApi } from '@config/core';
import { configServiceActions } from '@config/store/service.acions';
import { openIdConnectService as openId } from '@services/open-id-connect';
import { bootstrapServiceActions } from './service.acions';
import { BootstrapActionsTypes as BootstrapTypes } from './model';

// NOTE: изначально в index.html сразу отрисован глобальный прелоадер, чтобы избежать белых бликов страницы
//       старт приложения начинается с ON_APP_OPENED экшена. По нему есть два варианта развития:
//       1. Мы пришли с keycloak - сохраняем новые credentials
//       2. Мы просто открыли приложение - проверяем, была ли старая сессия и валидна ли еще она
//       Далее у нас три варианта развития:
//       1. у нас измененная сессия - мо сохраняем роут и редиректимся в keycloak, чтобы проверить сессию
//       2. У нас плохая сессия - мы сохраняем текущий роут, проставляем статус сессии и диспатчим setAuthorizedInfo
//       3. У нас хорошая сессия - мы проставляем статус сессии и диспатчим setAuthorizedInfo
//       setAuthorizedInfo запускает конфигурацию приложения в shared/config/effects.ts
//       Если юзер авторизирован, то мы коннектимся к WS, подтягиваем все данные юзера с бекенда и проставляем флаг isAppConfigured
//       Если юзер не авторизирован, то мы сразу проставляем флаг isAppConfigured
//       Только когда флаг isAppConfigured проставлен - приложение убирает глобальный прелоадер и начинает рендерить
//       данные. В этот момент рендерится компонент из роутера по урл и возможны четыре варианта:
//       1. роут /check-auth - мы редиректимся на последний сохраненный роут с предыдущей сессии, либо на '/'
//       2. роут '/' - тут два варианта:
//        2.1 мы авторизованы - редиректимся на последний сохраненный роут с предыдущей сессии, либо на '/profile'
//        2.2 мы неавторизованы - редиректимся в keycloak на форму авторизации
//       3. неавторизированный роут - мы проверяем, что юзер неавторизирован и пускаем на страницу, либо редиректим на '/profile'
//       4. авторизированный роут - мы проверяем, что юзер авторизирован. Если нет - редиректим на keycloak.
//          Если авторизован - мы проверяем доступ к странице. Если есть, рендерим страницу, если нет, проверяем:
//        4.1 если мы пришли по роуту с прошлой сессии - редиректим на '/profile'
//        4.2 если мы открыли страницу по урл - редиректим на access-denied
export function* bootstrapEffects() {
  yield all([
    takeEvery(BootstrapTypes.CHECK_LAST_SESSION_STATUS, checkLastSessionStatus),
    takeEvery(BootstrapTypes.CONFIGURE_NEW_KEYCLOAK_SESSION, configureNewKeycloakSession),
    takeEvery(BootstrapTypes.ON_APP_OPENED, Put(bootstrapServiceActions.initiateApp)),
    takeEvery(BootstrapTypes.INITIATE_APP, initiateApplication),
    takeEvery(BootstrapTypes.SET_AUTHORIZED_INFO, Put(configServiceActions.configureApplication)),
  ]);
}

function* checkLastSessionStatus() {
  try {
    logger.log('Bootstrap: check last session status');
    const queryParams: URLSearchParams = new URLSearchParams(window.location.search);
    const language: KKLang = queryParams.get('locale') as KKLang;
    if (language) {
      const adapter: { [k: string]: Lang } = { en: Lang.en, 'zh-CN': Lang.cn };
      yield put(configServiceActions.checkAppLanguage(adapter[language] || Lang.en));
    }

    const refreshToken: string = session.getRefreshToken();
    let userRole: Role = session.getUser()?.role?.name;
    let sessionStatus: Session;

    try {
      sessionStatus = yield openId.getSessionStatus();
      if (sessionStatus === Session.expired) {
        if (refreshToken && userRole) {
          logger.log('Bootstrap: session expired', { refreshToken: refreshToken, userRole });
          yield configApi.refreshAccessToken();
          sessionStatus = Session.good;
          logger.log('Bootstrap: session updated');
        } else {
          throw new Error('Available session info is not enough to identify current user');
        }
      }
    } catch (error) {
      sessionStatus = Session.bad;
    }

    if (([Session.changed, Session.bad] as Array<Session>).includes(sessionStatus)) {
      session.saveLatestVisitedRoute(window.location.pathname);
    }

    if (sessionStatus === Session.changed) {
      logger.log('Bootstrap: session changed, save latest route', {
        latestRoute: window.location.pathname,
      });
      window.location.href = `${window.location.origin}/sso/auth?locale=${session.getKKLanguage()}`;
      return;
    }

    const userId: number = session.userId();
    userRole = session.getUser()?.role?.name;

    logger.log('Bootstrap: setAuthorizedInfo from previous session', { userId, userRole });

    yield put(
      bootstrapServiceActions.setAuthorizedInfo({
        accessToken: session.getAccessToken(),
        refreshToken: session.getRefreshToken(),
        role: userRole,
        sessionStatus: userId && userRole ? sessionStatus : Session.bad,
        userId: userId,
      })
    );
  } catch (e) {
    const error: Error = e as Error;
    notifyError(
      {
        text: error?.message || (
          <Trans id="bootstrap.check_last_session.error.text">
            Check last active session failed
          </Trans>
        ),
        title: <Trans id="bootstrap.check_last_session.error.title">Error 83</Trans>,
      },
      {
        forcedAction: true,
        onClick: () => {
          session.logout();
          window.location.href = `${window.location.origin}/sso/auth`;
        },
      }
    );
    yield logger.error('Check last active session failed', {
      location: 'src/bootstrap/store/effects.ts:checkLastSessionStatus',
      rawError: error,
    });
  }
}

function* configureNewKeycloakSession() {
  try {
    logger.log('Bootstrap: configure new keycloak session');
    const hash = window.location.hash.slice(1);
    const hashDecoded = decodeURIComponent(hash);
    const { access_token, refresh_token } = JSON.parse(hashDecoded);
    const tokenInfo: TokenInfo = decode(access_token);
    const userRole: Role = tokenInfo?.tm2_profile?.role;
    const userId: number = +tokenInfo?.tm2_profile?.id || null;
    const sessionStatus: Session =
      access_token && refresh_token && userId && userRole ? Session.good : Session.bad;

    logger.log('Bootstrap: setAuthorizedInfo from new session', {
      access_token,
      refresh_token,
      sessionStatus,
      userRole,
      userId,
    });
    yield put(
      bootstrapServiceActions.setAuthorizedInfo({
        accessToken: access_token,
        refreshToken: refresh_token,
        role: userRole,
        sessionStatus: sessionStatus,
        userId: userId,
      })
    );
  } catch (e) {
    const error: Error = e as Error;
    notifyError(
      {
        text: error?.message || (
          <Trans id="bootstrap.new_keycloak_session.error.text">
            New keycloak session configuration failed
          </Trans>
        ),
        title: <Trans id="bootstrap.new_keycloak_session.error.title">Error 80</Trans>,
      },
      {
        forcedAction: true,
        onClick: () => {
          session.logout();
          window.location.href = `${window.location.origin}/sso/auth`;
        },
      }
    );
    yield logger.error('New keycloak session configuration failed', {
      location: 'src/bootstrap/store/effects.ts:configureNewKeycloakSession',
      rawError: error,
    });
  }
}

function* initiateApplication() {
  try {
    logger.log('Bootstrap: initiateApplication', { pathname: window.location.pathname });
    if (window.location.pathname === '/check-auth') {
      yield put(bootstrapServiceActions.configureNewKeycloakSession());
    } else {
      yield put(bootstrapServiceActions.checkLastSessionStatus());
    }
  } catch (e) {
    const error: Error = e as Error;
    notifyError(
      {
        text: error?.message || (
          <Trans id="bootstrap.initiate_app.error.text">Application initiation failed</Trans>
        ),
        title: <Trans id="bootstrap.initiate_app.error.title">Error 82</Trans>,
      },
      {
        forcedAction: true,
        onClick: () => {
          session.logout();
          window.location.href = `${window.location.origin}/sso/auth`;
        },
      }
    );
    yield logger.error('React app initiation failed', {
      location: 'src/bootstrap/store/effects.ts:initiateApplication',
      rawError: error,
    });
  }
}
