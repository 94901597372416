import { useCallback } from 'react';

import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import type { Withdrawal } from '@router/pages/withdrawals/api';

import { useClients, useStatuses } from './hooks';
import { CreateWithdrawalOtherModal } from './withdrawal-request-other';

export { Fields } from './api';

type AddWithdrawal = (withdrawal: Withdrawal) => void;
type OnFiltersChanged = (filter: string) => void;
export const useModel = (addWithdrawal: AddWithdrawal, onFiltersChanged: OnFiltersChanged) => {
  const { clients, isClientsLoading: lockUI } = useClients();

  const { withdrawalStatuses } = useStatuses();

  const onSearchChange = useCallback((rsql: string) => onFiltersChanged(rsql), []);

  const showModal = useCallDispatch(showModalAction);
  const onGenerateWithdrawalRequest = () => {
    const props = { addWithdrawal };
    showModal({ modal: CreateWithdrawalOtherModal, props });
  };

  return {
    clients,
    lockUI,
    onGenerateWithdrawalRequest,
    onSearchChange,
    withdrawalStatuses,
  };
};
