import React, { FC } from 'react';

import s from './index.module.scss';
import { ReactComponent as FailIcon } from '@images/icons/notify-fail-icon.svg';
import { ReactComponent as SuccessIcon } from '@images/icons/notify-success-icon.svg';

export type TitleIconType = 'fail' | 'success';
export const TitleIcon: FC<{ type: TitleIconType }> = ({ type }) => (
  <div className={s.icon}>
    {type === 'fail' ? <FailIcon className={s.svg} /> : <SuccessIcon className={s.svg} />}
  </div>
);
