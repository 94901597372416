import {
  FORM_ADD_ARRAY_ELEMENT,
  FORM_CHANGE,
  FORM_CHANGE_ALT,
  FORM_CHANGE_CHECKBOX,
  FORM_ERRORS,
  FORM_INIT,
  FORM_IS_SEND,
  FORM_REMOVE_ARRAY_ELEMENT,
} from '@constants/redux';

export const formInitAction = (formName, data) => ({
  type: FORM_INIT,
  formName: formName,
  data: data,
});

export const formIsSendAction = (formName, isSend) => ({
  type: FORM_IS_SEND,
  formName: formName,
  isSend: isSend,
});

export const formChangeAction = (formName, fieldName, fieldValue) => ({
  type: FORM_CHANGE,
  formName: formName,
  fieldName: fieldName,
  fieldValue: fieldValue,
});

export const formChangeCheckboxAction = (formName, fieldName, fieldValue) => ({
  type: FORM_CHANGE_CHECKBOX,
  formName: formName,
  fieldName: fieldName,
  fieldValue: fieldValue,
});

export const formChangeAltAction = (formName, event, regexp) => {
  return {
    type: FORM_CHANGE_ALT,
    formName: formName,
    event: event,
    regexp: regexp,
  };
};

export const formRemoveArrayElement = (formName, path, index) => ({
  type: FORM_REMOVE_ARRAY_ELEMENT,
  formName: formName,
  path: path,
  index: index,
});

export const formAddArrayElement = (formName, path) => ({
  type: FORM_ADD_ARRAY_ELEMENT,
  formName: formName,
  path: path,
});

export const formErrorsAction = (formName, errors) => ({
  type: FORM_ERRORS,
  formName: formName,
  errors: errors,
});
