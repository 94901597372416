import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { useQuery } from '@hooks/core';
import { OfferStatus } from '../../shared/types';
import { Form } from './model';

// REVIEW: exporting hooks: forbidding imperative approach:
//  - additional renders
//  - debounce/throttle (consider cycling sorter)
//  - useQuery long argument

export interface Offer {
  activeOfferPrices: Array<{
    currency: {
      code: string;
      id: number;
      symbol: string;
    };
    id: number;
    quantity: number;
    unit: {
      inGrams: number;
      label: string;
    };
  }>;
  creator: {
    displayName: string;
    id: number;
  };
  availableQuantity: number;
  coin: {
    asset: string;
    metal: {
      label: string;
    };
  };
  date: string;
  id: number;
  sellCommission: number;
  status: OfferStatus;
  quantityUnit: {
    inGrams: number;
    label: string;
  };
}
const createOfferFields = [
  'id',
  'status',
  'availableQuantity',
  'date',
  'sellCommission',
  { creator: ['displayName', 'id'] },
  { coin: ['asset', { metal: ['label'] }] },
  {
    activeOfferPrices: [
      'id',
      'quantity',
      { currency: ['id', 'code', 'symbol'] },
      { unit: ['label', 'inGrams'] },
    ],
  },
  { quantityUnit: ['label', 'inGrams'] },
];
const createOfferQuery = 'createOffer';
const createOffer = makeQuery({
  queryName: createOfferQuery,
  queryFields: createOfferFields,
  query: (form: Form): Promise<Offer> => {
    return stompClientService.sendData<Offer>(
      createOfferQuery,
      sign(createOfferQuery, createOfferFields),
      v.createOfferDtoInput({ dto: form })
    );
  },
});
// ------------------------------------------------------------------
export interface Coin {
  asset: string;
  id: number;
  issuerTax: number;
  info: {
    balance: number;
  };
  metal: {
    label: string;
  };
}
const coinsFields = [
  { issuerCoins: ['id', 'asset', { metal: ['label'] }, 'issuerTax', { info: ['balance'] }] },
  {
    liquidityProviderCoins: [
      'id',
      'asset',
      { metal: ['label'] },
      'issuerTax',
      { info: ['balance'] },
    ],
  },
];
const getCoinsQuery = 'currentUser';
const getCoins = makeQuery({
  queryName: getCoinsQuery,
  queryFields: coinsFields,
  query: (): Promise<Array<Coin>> => {
    return stompClientService
      .getData<{
        issuerCoins: Array<Coin>;
        liquidityProviderCoins: Array<Coin>;
      }>(getCoinsQuery, sign(getCoinsQuery, coinsFields))
      .then((response) => response.issuerCoins.concat(response.liquidityProviderCoins));
  },
});
export const useCoins = () => useQuery(() => getCoins(), { initial: [] });
// ------------------------------------------------------------------
export interface Unit {
  id: number;
  inGrams: number;
  label: string;
}
const unitsFields = ['id', 'inGrams', 'label'];
const getUnitsQuery = 'units';
const getUnit = makeQuery({
  queryName: getUnitsQuery,
  queryFields: unitsFields,
  query: (): Promise<Unit> => {
    return stompClientService
      .getData(getUnitsQuery, sign(getUnitsQuery, unitsFields))
      .then((units: Array<Unit>) => {
        const unitItem = units?.filter((u) => u.label === 'unit');
        return unitItem[0];
      });
  },
});
export const useUnit = () => useQuery(() => getUnit());

const api = {
  createOffer,
  getCoins,
  getUnit,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:place-offer-modal');

export default api;
