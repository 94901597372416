import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';

import { handleBackendError } from 'shared/modules/notify';
import { Put } from '@helper/redux';
import { closeModalAction, showModalAction } from 'shared/modules/modal';
import api from '../../api';
import { RedeemConfirmationModal } from '../../redeem-confirmation-modal';
import { RedeemOptionsModal } from '../../redeem-options-modal';
import { RedeemOptionsModalActionsTypes as RedeemOptionsTypes } from './model';
import { redeemOptionsModalServiceActions as redeemOptionsServiceActions } from './service.acions';

export function* redeemOptionsModalEffects() {
  yield all([
    takeEvery(RedeemOptionsTypes.GET_REDEEM_OPTIONS, getRedeemOptions),
    takeEvery(RedeemOptionsTypes.ON_CLOSE_MODAL_CLICKED, Put(closeModalAction, RedeemOptionsModal)),
    takeEvery(
      RedeemOptionsTypes.ON_CHOOSE_SELECTED_REDEEM_OPTION_CLICKED,
      switchToConfirmationModal
    ),
    takeEvery(
      RedeemOptionsTypes.ON_MODAL_OPENED,
      Put(redeemOptionsServiceActions.getRedeemOptions)
    ),
  ]);
}

function* getRedeemOptions(action) {
  try {
    const coinId = action.payload;
    const optionsList = yield api.getRedeemOptions(coinId);
    yield put(redeemOptionsServiceActions.getRedeemOptionsSuccess(optionsList));
  } catch (error) {
    yield put(redeemOptionsServiceActions.getRedeemOptionsFail(error));
    handleBackendError(error);
  }
}

function* switchToConfirmationModal() {
  yield put(
    showModalAction({
      isClosePrevious: true,
      modal: RedeemConfirmationModal,
    })
  );
}
