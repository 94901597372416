import { useSelector } from 'react-redux';
import { showModalAction } from '@modules/modal';
import { configSelectors, OnboardingStep } from '@config/core';
import { useCallDispatch } from '@hooks/core';
import { LiveUpdateEventType, useLiveUpdate } from '@services/stomp/live-update';
import { AddFundsModal } from '../add-funds-modal';
import { useDepositInvestors, useStatuses } from './hooks';

export { Fields } from './api';

export const formId = 'depositsFilters';

export const useModel = (addDeposit) => {
  const { investors, isInvestorsLoading } = useDepositInvestors();

  const { depositStatuses } = useStatuses();

  const onShowModal = useCallDispatch(showModalAction);

  const showAddDepositModal = () => onShowModal({ modal: AddFundsModal });

  const onboardingStep = useSelector(configSelectors.onboardingStep);
  const isInvestor = useSelector(configSelectors.isInvestor);
  const isShowAddFundsBtnExtra = !isInvestor ? null : onboardingStep > OnboardingStep.sumSubForm;
  const isCustomersFilterAllowed = investors?.length > 0;

  const lockUI = isInvestorsLoading;

  useLiveUpdate((event) => {
    if (event.type === LiveUpdateEventType.depositCreated) {
      addDeposit(event.data);
    }
  });

  return {
    investors,
    depositStatuses,
    isCustomersFilterAllowed,
    isShowAddFundsBtnExtra,
    lockUI,
    showAddDepositModal,
  };
};
