import React from 'react';
import { Trans } from '@lingui/macro';
import classnames from 'classnames';

import s from './index.module.scss';
import { MFC, Modal, ModalContent, ModalTitle } from '@modules/modal';
import { Box } from '@components/box';
import { CheckboxSingle, Form } from '@components/form';
import { Fields as F, formInitial, useModel } from './model';

export const StartTutorialModal: MFC = ({ isVisible }) => {
  const m = useModel(StartTutorialModal);

  return (
    <Modal
      onClose={m.onCloseClicked}
      isVisible={isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      uat="start-tutorial-modal"
    >
      <ModalTitle>
        <Trans id="onboard_help_modal.title">What would you like to do first?</Trans>
      </ModalTitle>

      <ModalContent>
        <Box
          className={classnames(s.link, 'mb3')}
          onClick={m.onReadInstructionsClicked}
          uat="read-instructions-button"
        >
          <div className={classnames(s.linkTitle, 'mb1')}>
            <Trans id="onboard_help_modal.read_instructions">Read the instructions</Trans>
          </div>
          <div className={s.linkText}>
            <Trans id="onboard_help_modal.quick_tutorial_text">
              Do you need a quick update on how to use the platform? We provide guides to help you
              take your first steps.
            </Trans>
          </div>
        </Box>

        <Box
          className={classnames(s.link, 'mb3')}
          onClick={m.onCloseClicked}
          uat="ignore-instructions-button"
        >
          <div className={classnames(s.linkTitle, 'mb1')}>
            <Trans id="onboard_help_modal.not_read_instructions">I’ll explore by myself</Trans>
          </div>
          <div className={s.linkText}>
            <Trans id="onboard_help_modal.skip_tutorial_text">
              By clicking here you will skip any tutorials for now. You can find them at any moment
              by clicking on your profile icon on the top right corner.
            </Trans>
          </div>
        </Box>

        <Form name="onboard-help" initialValues={formInitial} onChange={m.onFormChanged}>
          <CheckboxSingle
            name={F.dontAskAgain}
            label={<Trans id="onboard_help_modal.label.dont_ask">Don't ask me again</Trans>}
            disabled={m.lockUI}
            compact={true}
          />
        </Form>
      </ModalContent>
    </Modal>
  );
};
