import React, { FC, memo } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';
import { PermissionGroup } from '@permissions/permission-group';
import { TabPane, Tabs } from '@components/tabs/regular';
import { Box } from '@components/box';
import { AccountDetailsTab } from '../../components/tabs/account-details';
import { ProfileMy } from '../api';
import {
  EditProfileButton,
  permissionsGroup as editButtonPermissionsGroup,
} from './account-details/edit-profile-button';
import { TraderOnboard } from './trader-onboard';
import {
  MyBankAccountsTab,
  permissionsGroup as bankAccountPermissionsGroup,
} from './bank-accounts';
import { SecurityTab } from './security';
import { Tab, useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [bankAccountPermissionsGroup, editButtonPermissionsGroup],
  marker: 'layout:profile-my-page-extra-info',
});

export const ProfileExtra: FC<{ profile: ProfileMy }> = memo(({ profile }) => {
  const m = useModel();

  return (
    <>
      <TraderOnboard profile={profile} />

      <Box>
        <Tabs
          activeKey={m.activeTab}
          headerContentFit="scroll"
          onChange={m.onTabChange}
          border
          forceRender
          data={m.headerData}
          uat="my-profile-tabs"
        >
          <TabPane tabKey={Tab.accountDetails} key={Tab.accountDetails}>
            <AccountDetailsTab
              className={classnames(s.tab, { [s.hidden]: m.activeTab !== Tab.accountDetails })}
              profile={profile}
              editProfileButton={<EditProfileButton />}
            />
          </TabPane>

          <MyBankAccountsTab tabKey={Tab.bankDetails} hidden={m.activeTab !== Tab.bankDetails} />

          {/*<TabPane tabKey={Tabs.preferences}
                             key={Tabs.preferences}>
                        <PreferencesTab className={classnames(s.tab, {[s.hidden]: m.activeTab !== Tabs.preferences})}
                                        profile={profile} />
                    </TabPane>*/}

          <TabPane tabKey={Tab.security} key={Tab.security}>
            <SecurityTab
              className={classnames(s.tab, { [s.hidden]: m.activeTab !== Tab.security })}
            />
          </TabPane>
        </Tabs>
      </Box>
    </>
  );
});
