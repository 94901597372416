import { useContext, useState, FC } from 'react';
import { Trans } from '@lingui/macro';
import { ButtonModalBack, ButtonPrimary } from '@components/button';
import { ModalTitle, ModalContent, ModalFooter } from '@modules/modal';
import { TypedError } from '@services/stomp/errors';
import { formatMoney } from 'containers/services/commonService';
import { Context } from '../context';
import apiService, { RequestExchangeErrorCode } from '../api';

export const Confirmation: FC = () => {
  const { state, dispatch } = useContext(Context);
  const [isConfirming, setIsConfirming] = useState(false);
  const { amountToBuy, amountToSell, currencyToBuy, currencyToSell, rate } = state.confirmationData;

  const selling = formatMoney(amountToSell, { pre: currencyToSell.code + ' ' });
  const buying = formatMoney(amountToBuy, { pre: currencyToBuy.code + ' ' });

  const confirm = () => {
    setIsConfirming(true);
    apiService
      .requestExchange({
        amountToSell,
        currencyToSellId: currencyToSell.id,
        currencyToBuyId: currencyToBuy.id,
        rate,
      })
      .then((data) => {
        dispatch({
          type: 'CONFIRMATION_COMPLETED',
          payload: data,
        });
      })
      .catch((err) => {
        const isReasonKnown = err instanceof TypedError;

        if (isReasonKnown && err.code === RequestExchangeErrorCode.NotEnoughBalance) {
          dispatch({
            type: 'CONFIRMATION_FAILED',
            payload: { reason: 'not-enough-balance' },
          });
          return;
        }
        if (isReasonKnown && err.code === RequestExchangeErrorCode.ExchangeRateChanged) {
          dispatch({
            type: 'CONFIRMATION_FAILED',
            payload: { reason: 'exchange-rate-changed' },
          });
          return;
        }
        dispatch({
          type: 'CONFIRMATION_FAILED',
          payload: { reason: 'unknown' },
        });
      });
  };

  const goToCurrencySetting = () => {
    dispatch({ type: 'ON_CURRENCY_SETTING_REQUESTED' });
  };

  return (
    <>
      <ModalTitle>
        <Trans id={'my_wallet.exchange_currency.confirmation.title'}>Confirm</Trans>
      </ModalTitle>
      <ModalContent>
        <div className={'wallet-currency-exchange-confirmation-item'}>
          <Trans id={'my_wallet.exchange_currency.confirmation.selling'}>Selling</Trans>
          <span>{selling}</span>
        </div>

        <div className={'wallet-currency-exchange-confirmation-item'}>
          <Trans id={'my_wallet.exchange_currency.confirmation.buying'}>Buying</Trans>
          <span>{buying}</span>
        </div>

        <div className={'wallet-currency-exchange-confirmation-item'}>
          <Trans id={'my_wallet.exchange_currency.confirmation.your_exchange_rate'}>
            Your exchange rate
          </Trans>
          <span>{rate}</span>
        </div>
      </ModalContent>
      <ModalFooter>
        <ButtonModalBack className="mr3" onClick={goToCurrencySetting} disabled={isConfirming} />
        <ButtonPrimary
          className={'wallet-currency-exchange-submit-button'}
          disabled={isConfirming}
          onClick={confirm}
          fullWidth
        >
          <Trans id={'my_wallet.exchange_currency.confirmation.confirm'}>Confirm</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </>
  );
};
