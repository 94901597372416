import React, { FC, memo, ReactNode, useMemo } from 'react';
import classnames from 'classnames';
import { MenuClickEventHandler } from 'rc-menu/lib/interface';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import './index.scss';
import { breakpoints, gridValues as grid } from '@constants/theme';
import {
  Permission as P,
  PermissionGroup,
  Role as R,
  statementsPagePermissions,
  traderPermissions,
  AllowedPermissions,
} from '@permissions/core';
import { session } from '@services/session';
import { ReactComponent as BackOfficePage } from '@images/icons/sidebar-bank-accounts.svg';
import { ReactComponent as Logo } from '@images/icons/logo-dark-short.svg';
import { ReactComponent as MembersPage } from '@images/icons/sidebar-members.svg';
import { ReactComponent as MarketPage } from '@images/icons/sidebar-market.svg';
import { ReactComponent as AssetsPage } from '@images/icons/sidebar-assets.svg';
import { ReactComponent as MetalsPage } from '@images/icons/sidebar-metals.svg';
import { ReactComponent as StatementsPage } from '@images/icons/sidebar-statements.svg';
import { permissionGroup as walletPermissionGroup } from 'containers/pages/wallet/page';
import { permissionGroup as marketingPermissionGroup } from 'containers/pages/trading-platform/marketing';
import { permissionGroup as primaryMarketListPermissionGroup } from '../../../../../containers/pages/primary-market/list';
import { permissionGroup as statementsPermissionGroup } from 'containers/pages/statements';
import { permissionGroup as offersPermissionGroup } from 'containers/pages/offers2';
import { permissionsGroup as bitchBanksPermissionsGroup } from '../../../../../containers/pages/cash-management/bank-accounts';
import { permissionsGroup as bitchTransactionsPermissionsGroup } from '../../../../../containers/pages/cash-management/transactions';
import { permissionGroup as preferencesPagePermissionGroup } from '../../../../../containers/pages/preferences';
import { useModel } from './model';

const { Sider } = Layout;
const { SubMenu } = Menu;
const { Item } = Menu;

function hasResolvedPermissionGroup(
  allowedPermissions: (P | PermissionGroup)[],
  userPermissions: string
) {
  if (!allowedPermissions || !userPermissions) {
    return false;
  }
  return allowedPermissions.some((p) => {
    if (p instanceof PermissionGroup) {
      return p.resolve(userPermissions);
    }
    return false;
  });
}

const resolvePermissions = (permissions: Array<P | PermissionGroup>): Array<P> =>
  // TODO-2833: fix types
  // @ts-ignore
  (permissions || [])?.filter((item) => !(item instanceof PermissionGroup));

const includesRole = (roles: Array<R>, role: R): boolean => roles.includes(role);

export const AuthorizedSidebar: FC = memo(() => {
  const m = useModel();

  const menuListOriginal: Array<MenuItem> = useMemo(
    () => [
      // NOTE for items with submenu all roles from sub-items should be listed
      {
        icon: <MembersPage />,
        id: 'members-menu',
        name: <Trans id="sidebar.members">Members</Trans>,
        permissions: [P.USERS_VIEW_ALL],
        subMenu: [
          {
            id: 'users-sub-item',
            name: <Trans id="sidebar.users">Users</Trans>,
            path: '/users',
            permissions: [P.USERS_VIEW_ALL],
          },
        ],
      },
      {
        icon: <AssetsPage />,
        id: 'assets-menu',
        name: <Trans id="sidebar.assets">Assets</Trans>,
        permissions: [
          P.INVOICES_VIEW_ALL,
          P.INVOICES_VIEW_MY,
          P.REDEEM_VIEW_REQUESTS,
          P.REDEEM_VIEW_MY_REQUESTS,
          P.TRANSACTIONS_VIEW_ALL,
          P.WITHDRAWALS_VIEW_ALL,
          P.WITHDRAWALS_VIEW_USER,
          walletPermissionGroup,
        ],
        roles: [R.accountManager, R.admin, R.clearanceBroker, R.complianceOfficer, R.issuer],
        subMenu: [
          {
            id: 'wallet-sub-item',
            name: <Trans id="sidebar.wallet">My Wallet</Trans>,
            path: '/my-wallet',
            permissions: [walletPermissionGroup],
          },
          {
            id: 'invoices-sub-item',
            name: <Trans id="sidebar.deposits">Deposits</Trans>,
            path: '/deposits',
            permissions: [P.INVOICES_VIEW_ALL, P.INVOICES_VIEW_MY],
          },
          {
            id: 'transactions-sub-item',
            name: <Trans id="sidebar.transactions">Transactions</Trans>,
            path: '/transactions',
            roles: [R.complianceOfficer, R.issuer],
            permissions: [P.TRANSACTIONS_VIEW_ALL],
          },
          {
            id: 'withdrawals-sub-item',
            name: <Trans id="sidebar.withdrawals">Withdrawals</Trans>,
            path: '/withdrawals',
            permissions: [P.WITHDRAWALS_VIEW_ALL, P.WITHDRAWALS_VIEW_USER],
          },
          {
            id: 'redemption-sub-item',
            name: <Trans id="sidebar.redemptions">Redemptions</Trans>,
            path: '/redeem-requests',
            roles: [R.clearanceBroker, R.issuer],
            permissions: [P.REDEEM_VIEW_REQUESTS, P.REDEEM_VIEW_MY_REQUESTS],
          },
          {
            id: 'custody-fee-sub-item',
            name: <Trans id="sidebar.custody-fee">Custody fee</Trans>,
            path: '/custody-fee', // TODO 27.10.2021 возможно выпилить через месяц ???
            // TODO return when page will be ready for investor, maybe permissions will change (also in router)
            // permissions: traderPermissions,
            roles: [R.accountManager],
          },
          {
            id: 'transfer-requests-sub-item',
            name: <Trans id="sidebar.transfer_requests">Transfer requests</Trans>,
            path: '/transfer-requests',
            roles: [R.accountManager],
          },
        ],
      },
      {
        icon: <MarketPage />,
        id: 'markets-menu',
        name: <Trans id="sidebar.markets">Markets</Trans>,
        roles: [R.accountManager, R.issuer],
        permissions: [
          offersPermissionGroup,
          primaryMarketListPermissionGroup,
          ...traderPermissions,
        ],
        subMenu: [
          {
            id: 'primary-market-sub-item',
            name: <Trans id="sidebar.primary_market">Primary Market</Trans>,
            path: '/primary-market',
            permissions: [primaryMarketListPermissionGroup],
          },
          // {
          //   id: 'secondary-market-sub-item',
          //   name: <Trans id="sidebar.secondary_market">Secondary Market</Trans>,
          //   onClick: () => m.onSecondaryMarketMenuClicked(),
          //   path: null,
          //   permissions: traderPermissions,
          // },
          {
            id: 'offers-sub-item',
            name: <Trans id="sidebar.offers">Offers</Trans>,
            path: '/offers',
            permissions: [offersPermissionGroup],
          },
        ],
      },
      {
        icon: <MetalsPage />,
        id: 'metals-menu',
        name: <Trans id="sidebar.metals">Metals</Trans>,
        permissions: [P.COINS_VIEW_ALL, P.VAULTS_VIEW_LIST],
        roles: [R.accountManager, R.complianceOfficer, R.issuer, R.vaultManager],
        subMenu: [
          {
            id: 'symbols-admin-sub-item',
            name: <Trans id="sidebar.symbols">Symbols</Trans>,
            path: '/digital-metals',
            roles: [R.issuer],
            permissions: [P.COINS_VIEW_ALL],
          },
          {
            id: 'symbols-manager-sub-item',
            name: <Trans id="sidebar.symbols">Symbols</Trans>,
            path: '/metals',
            roles: [R.accountManager],
          },
          {
            id: 'vaults-sub-item',
            name: <Trans id="sidebar.vaults">Vaults</Trans>,
            path: '/vaults',
            roles: [R.issuer, R.vaultManager],
            permissions: [P.VAULTS_VIEW_LIST],
          },
          {
            id: 'beneficial-owners-sub-item',
            name: <Trans id="sidebar.beneficial_owners">Beneficial owners</Trans>,
            path: '/beneficial-owners',
            roles: [R.complianceOfficer],
          },
          {
            id: 'preferences-sub-item',
            name: <Trans id="sidebar.preferences">Preferences</Trans>,
            path: '/preferences',
            permissions: [preferencesPagePermissionGroup],
          },
        ],
      },
      {
        icon: <StatementsPage />,
        id: 'statements-admin-item',
        name: <Trans id="sidebar.statements">Statements</Trans>,
        path: '/statements',
        permissions: [...statementsPagePermissions, statementsPermissionGroup],
        roles: [R.admin, R.listingBroker],
        subMenu: null,
      },
      {
        icon: <StatementsPage />,
        id: 'statements-issuer-item',
        name: <Trans id="sidebar.statements">Statements</Trans>,
        path: '/issuer-statements',
        permissions: [P.DAILY_BALANCE_VIEW_HISTORY_REPORT],
        extraCheck: includesRole([R.issuer], m.role),
        subMenu: null,
      },
      {
        icon: <StatementsPage />,
        id: 'trading-platform-menu',
        name: <Trans id="sidebar.trading_platform">Trading platform</Trans>,
        permissions: [marketingPermissionGroup],
        roles: [R.marketing],
        subMenu: [
          {
            id: 'marketing-sub-item',
            name: <Trans id="sidebar.marketing">Marketing</Trans>,
            path: '/marketing',
            roles: [R.marketing],
            permissions: [marketingPermissionGroup],
          },
        ],
      },
      {
        icon: <BackOfficePage />,
        id: 'cm-back-office-menu',
        name: <Trans id="sidebar.cash_management">Cash management</Trans>,
        permissions: [bitchBanksPermissionsGroup, bitchTransactionsPermissionsGroup],
        subMenu: [
          {
            id: 'cm-bank-accounts-item',
            name: <Trans id="sidebar.bitch.bank_accounts">Bank accounts</Trans>,
            path: '/cash-management/bank-accounts',
            permissions: [bitchBanksPermissionsGroup],
          },
          {
            id: 'cm-transactions-item',
            name: <Trans id="sidebar.bitch.transactions">Transactions</Trans>,
            path: '/cash-management/transactions',
            permissions: [bitchTransactionsPermissionsGroup],
          },
        ],
      },
      /*{ // TODO TM2-1168 , также еще нет иконки и не прописана логика onClick в самой модели меню
            icon: <TODO />,
            id: 'download-node-item',
            name: <Trans id="sidebar.download_node">Download node</Trans>,
            onClick: m.onNodeDownloadClicked,
            roles: [R.issuer, R.vaultManager],
            subMenu: null,
        },*/
    ],
    [m.permissions, m.role]
  );

  const MemoizedMenu = useMemo(() => {
    const menuList = menuListOriginal
      .filter((menu) => {
        return (
          hasResolvedPermissionGroup(menu.permissions, m.permissions) ||
          m.isHasAccess(
            { or: resolvePermissions(menu.permissions) } as AllowedPermissions,
            menu.extraCheck === undefined ? null : menu.extraCheck,
            false,
            menu.roles
          )
        );
      })
      .map((item) => ({
        ...item,
        subMenu:
          item.subMenu &&
          item.subMenu.filter((sMenu) => {
            return (
              hasResolvedPermissionGroup(sMenu.permissions, m.permissions) ||
              m.isHasAccess(
                { or: resolvePermissions(sMenu.permissions) } as AllowedPermissions,
                sMenu.extraCheck === undefined ? null : sMenu.extraCheck,
                false,
                sMenu.roles
              )
            );
          }),
      }));

    const historyExpandedMenuIds = session.getExpandedMenuIds();
    const allSubMenuIds = menuList.filter((item) => !!item.subMenu?.length).map((item) => item.id);
    // check screen size is need to avoid ugly menu on small screen sizes during app start
    let currentExpandedMenuIds =
      window.innerWidth < grid.md ? [] : historyExpandedMenuIds || allSubMenuIds;

    return !m.role || !m.permissions ? null : ( // otherwise Menu defaultOpenKeys will not work
      <Menu
        className="tm2-authorized-sidebar-menu"
        mode="inline"
        defaultOpenKeys={currentExpandedMenuIds}
        data-uat="sidebar"
        // When navigating to profile page previously selected link
        // will again be displayed as selected after hovering it
        // (tm2-3078). To prevent such behavior reset existing state
        // of the menu after navigating to profile page.
        key={Number(m.currentPath === '/profile')}
      >
        {menuList.map((item) => {
          const subMenu = item.subMenu;

          if (!subMenu) {
            return (
              <Item
                className={classnames('tm2-authorized-sidebar-menu-item tm2-navigation-item', {
                  'ant-menu-item-selected': m.currentPath === item.path,
                })}
                key={item.id}
                data-path={item.path}
              >
                <Link className="flex-row" to={item.path} data-uat={item.id}>
                  <span className="ant-menu-item-icon">{item.icon}</span>
                  <span className="tm2-authorized-sidebar-menu-item-text">{item.name}</span>
                </Link>
              </Item>
            );
          }

          return (
            <SubMenu
              className="tm2-authorized-sidebar-menu-submenu"
              key={item.id}
              icon={<span className="ant-menu-item-icon">{item.icon}</span>}
              onTitleClick={({ key, domEvent }) => {
                const isExpanded =
                  (domEvent.currentTarget as HTMLDivElement)?.getAttribute('aria-expanded') ===
                  'false';
                if (isExpanded) {
                  currentExpandedMenuIds.push(key);
                } else {
                  currentExpandedMenuIds = currentExpandedMenuIds.filter((id) => id !== key);
                }
                session.setExpandedMenuIds(currentExpandedMenuIds);
              }}
              title={<span>{item.name}</span>}
              data-uat={item.id}
            >
              {subMenu.map((subItem) =>
                subItem.path ? (
                  <Item
                    className={classnames(
                      'tm2-authorized-sidebar-menu-submenu-item tm2-navigation-item',
                      {
                        'ant-menu-item-selected': m.currentPath === subItem.path,
                      }
                    )}
                    key={subItem.id}
                    data-path={subItem.path}
                  >
                    <Link to={subItem.path} data-uat={subItem.id}>
                      {subItem.name}
                    </Link>
                  </Item>
                ) : (
                  <Item
                    className={classnames(
                      'ant-menu-item ant-menu-item-only-child tm2-authorized-sidebar-menu-submenu-item',
                      {
                        'ant-menu-item-blank': !subItem.path,
                      }
                    )}
                    key={subItem.id}
                    onClick={subItem.onClick}
                    data-uat={subItem.id}
                  >
                    <span>{subItem.name}</span>
                  </Item>
                )
              )}
            </SubMenu>
          );
        })}

        <Item
          className="tm2-authorized-sidebar-menu-item tm2-navigation-item mt-sm"
          key="hidden-block-for-scroll"
          style={{ visibility: 'hidden' }}
        >
          &nbsp;
          {/* NOTE: this item is need for desktop screens with small height
                        otherwise real last menu item will be hidden */}
        </Item>
      </Menu>
    );
  }, [m.currentPath, m.permissions, m.role]); // eslint-disable-line

  return (
    <Sider
      className="tm2-authorized-sidebar"
      collapsible
      breakpoint={breakpoints.lg}
      collapsed={m.isMenuCollapsed}
      onCollapse={() => m.setMenuCollapsed(!m.isMenuCollapsed)}
      width="var(--sidebar-width)"
      collapsedWidth="var(--sidebar-collapsed-width)"
    >
      <Link to={'/profile'}>
        <div className="tm2-authorized-sidebar-logo">
          <Logo />
        </div>
      </Link>

      {MemoizedMenu}
    </Sider>
  );
});

interface MenuItem {
  extraCheck?: boolean;
  icon: ReactNode;
  id: string;
  name: ReactNode;
  path?: string;
  permissions?: Array<P | PermissionGroup>;
  roles?: Array<R>;
  subMenu: Array<{
    extraCheck?: boolean;
    id: string;
    name: ReactNode;
    onClick?: MenuClickEventHandler;
    path: string;
    permissions?: Array<P | PermissionGroup>;
    roles?: Array<R>;
  }>;
}
