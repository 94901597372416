import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import {
  beneficialOwnersEffects,
  beneficialOwnersReducer,
} from './beneficial-owners/beneficial-owners.store';
import { usersReducer } from './users/users.store';
import { usersEffects } from './users/users.store';
import { walletReducer } from './wallet/wallet.store';
import { walletEffects } from './wallet/wallet.store';
import { transferRequestsPageReducer } from './transfer-requests/store/reducer';
import { transferRequestsPageEffects } from './transfer-requests/store/effects';
import { metalsPageReducer } from './metals/store/reducer';
import { metalsPageEffects } from './metals/store/effects';
import { statementsStoreReducer } from './statements/statements.store';
import { vaultsEffects, vaultsReducer } from './vaults/vaults.store';
import { depositsEffects, depositsReducer } from './deposits/store';
import { transactionsReducer } from 'containers/pages/transactions/store/reducer';
import {
  tradingPlatformEffects,
  tradingPlatformReducer,
} from 'containers/pages/trading-platform/trading-platform.store';

export const mainReducer = combineReducers({
  beneficialOwners: beneficialOwnersReducer,
  deposits: depositsReducer,
  metalsPage: metalsPageReducer,
  statements: statementsStoreReducer,
  transactions: transactionsReducer,
  transferRequests: transferRequestsPageReducer,
  users: usersReducer,
  vaults: vaultsReducer,
  wallet: walletReducer,
  tradingPlatform: tradingPlatformReducer,
});

export function* mainEffects() {
  yield all([
    fork(beneficialOwnersEffects),
    fork(depositsEffects),
    fork(metalsPageEffects),
    fork(transferRequestsPageEffects),
    fork(usersEffects),
    fork(vaultsEffects),
    fork(walletEffects),
    fork(tradingPlatformEffects),
  ]);
}
