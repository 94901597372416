import { useCallback, useContext, useEffect } from 'react';

import t from './tools';
import { formService } from '@components/form';
import { Context, SearchForm, SearchItem, TextModeForm } from './context';
import { useRsqlHash, useSubscribeOnClearForm } from './hooks';

export const formId = 'search-form-id';

export type SearchProps = {
  className?: string;
  disabled?: boolean;
  fields: Array<SearchItem>;
  memorize?: boolean;
  onFormChange?: (form: any) => void;
  onRsqlChange?: (rsql: string) => void;
};

export const useModel = (p: SearchProps) => {
  const { state, dispatch } = useContext(Context);
  const r = useRsqlHash(p.fields, p.memorize);

  const onFormChange = useCallback(
    (payload: SearchForm) => dispatch({ payload, type: 'ON_FORM_CHANGE' }),
    [dispatch]
  );

  const setSearchFields = useCallback(
    (payload: Array<SearchItem>) => dispatch({ payload, type: 'SET_SEARCH_FIELDS' }),
    [dispatch]
  );

  const onSearchChange = (searchForm: SearchForm, textMode: TextModeForm) => {
    const rsql = t.getRsqlString(p.fields, searchForm, textMode);
    p.onFormChange && p.onFormChange(searchForm);
    p.onRsqlChange && p.onRsqlChange(rsql);
    r.updateSearchHash(searchForm, textMode);
  };

  useSubscribeOnClearForm(() => {
    const form = formService.get<SearchForm>(formId);
    dispatch({ type: 'ON_FORM_CLEAR' });
    r.clearHash();
    form.resetFields();
  });

  useEffect(() => {
    r.textModeInitial && dispatch({ payload: r.textModeInitial, type: 'ON_TEXT_MODE_CHANGE' });
    r.searchFormInitial && dispatch({ payload: r.searchFormInitial, type: 'ON_FORM_CHANGE' });
  }, []);

  useEffect(() => {
    onSearchChange(state.searchForm, state.textMode);
  }, [state.searchForm, state.textMode]); // eslint-disable-line

  useEffect(() => {
    setSearchFields(p.fields);
  }, [p.fields, setSearchFields]);

  return {
    initialValues: r.searchFormInitial,
    onFormChange: onFormChange,
  };
};
