import { FinishRedeemModalActionsTypes as FinishRedeemTypes } from './model';

export const finishRedeemModalReducer = (combinedState, action) => {
  const state = combinedState.finishRedeemModal;
  const newState = (localState) => ({
    ...combinedState,
    finishRedeemModal: { ...localState },
  });

  switch (action.type) {
    case FinishRedeemTypes.CREATE_REDEEM: {
      return newState({
        ...state,
        isCreateRedeemLoading: true,
      });
    }

    case FinishRedeemTypes.CREATE_REDEEM_FAIL: {
      return newState({
        ...state,
        isCreateRedeemLoading: false,
      });
    }

    case FinishRedeemTypes.CREATE_REDEEM_SUCCESS: {
      return newState({
        ...state,
        isCreateRedeemLoading: false,
      });
    }

    default: {
      return combinedState;
    }
  }
};
