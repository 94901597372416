import { HeaderCellWrapperProps } from './index';
import { HeaderCellContainer } from '../../bound-components-containers/default-header-cell-container';

export function HeaderCellWrapperBase(props: HeaderCellWrapperProps) {
  return <>{props.children}</>;
}

export function HeaderCellWrapper(props: HeaderCellWrapperProps) {
  return (
    <HeaderCellContainer>
      <HeaderCellWrapperBase {...props} />
    </HeaderCellContainer>
  );
}
