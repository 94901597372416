import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { ButtonUpload } from '@components/button/upload';
import { ButtonDownload } from '@components/button/download';
import { ButtonAction } from '@components/button';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { transferRequestsPageSelectors as requestsSelectors } from '../store/selectors';
import { transferRequestsPageUserActions as requestsUserActions } from '../store/user.acions';

const mapStateToProps = (root) => ({
  lockUI: requestsSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onDownloadClicked: () => dispatch(requestsUserActions.onDownloadClicked()),
  onUploadClicked: (file) => dispatch(requestsUserActions.onUploadClicked(file)),
});

const ConnectedTransferRequestsActions = ({ lockUI, onDownloadClicked, onUploadClicked }) => {
  return (
    <div className="row mx-0 mb-md">
      <Tooltip
        placement="bottom"
        trigger={[TooltipTrigger.hover]}
        title={
          <Trans id="transfer_requests_page.tooltip.download">Download unsigned transactions</Trans>
        }
      >
        <ButtonDownload className="mr-md" disabled={lockUI} onClick={onDownloadClicked} />
      </Tooltip>

      <Tooltip
        placement="bottom"
        trigger={[TooltipTrigger.hover]}
        title={<Trans id="transfer_requests_page.tooltip.upload">Upload signed transactions</Trans>}
      >
        <ButtonUpload action={ButtonAction.file} disabled={lockUI} onClick={onUploadClicked} />
      </Tooltip>
    </div>
  );
};

export const TransferRequestsActions = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedTransferRequestsActions);
