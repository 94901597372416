import { useCallDispatch } from '@hooks/core';
import { showModalAction } from '@modules/modal';
import { useListingBrokers } from '../add-settlement-modal/hooks';

export const maxDate = new Date().toISOString();

export { Fields } from '../api';

export const useModel = (replaceSettlement, modal) => {
  const { listingBrokers, isListingBrokersLoading } = useListingBrokers();

  const onShowModal = useCallDispatch(showModalAction);

  const onAddLBSettlement = () =>
    onShowModal({
      modal: modal,
      props: { replaceSettlement },
    });

  const lockUI = isListingBrokersLoading;

  return {
    listingBrokers,
    lockUI,
    onAddLBSettlement,
  };
};
