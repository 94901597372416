import sign from 'tm2sign.macro';

import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from 'shared/helper/typer/field-typer.helper';
import { PageWrapper } from 'shared/models/backend';
import { fileService } from '@helper/file';

class TransferApiService {
  downloadTransaction() {
    const downloadTransferTransactionsFields = sign('downloadTransferTransactions', ['value']);

    return stompClient
      .getData('downloadTransferTransactions', downloadTransferTransactionsFields)
      .then((response) => response.value);
  }

  getTransferRequests(pageNumber, sort) {
    const transferRequestsFields = sign('transferRequests', [
      ...PageWrapper,
      {
        data: [
          'amount',
          'asset',
          'changedAt',
          'createdAt',
          'id',
          'state',
          'type',
          { user: ['id', 'displayName'] },
        ],
      },
    ]);

    const ordering = [];
    sort.forEach((column) =>
      column.fields.forEach((field) => ordering.push({ direction: column.direction, field: field }))
    );
    const typedValues = {
      ...{ page: pageNumber },
      ...v.orderInput({ ordering }),
    };
    return stompClient
      .getData('transferRequests', transferRequestsFields, typedValues)
      .then((response) => ({
        isHasMore: response.hasMore,
        transferRequests: response.data,
      }));
  }

  uploadTransaction(file) {
    const uploadTransactionsFields = sign('uploadTransferTransactions', ['value']);

    return fileService.toBase64(file).then((base64) =>
      stompClient.sendData('uploadTransferTransactions', uploadTransactionsFields, {
        file: base64,
      })
    );
  }
}

export const transferApiService = new TransferApiService();
