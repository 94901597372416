import { FC } from 'react';
import { Trans } from '@lingui/macro';
import { Form as AntForm, Switch as AntSwitch, SwitchProps } from 'antd';
import classnames from 'classnames';
import { NamePath } from 'rc-field-form/lib/interface';

import s from './index.module.scss';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

type OnSwitchChange = SwitchProps['onChange'];

export const Switch: FC<{
  className?: string;
  disabled?: boolean;
  name: NamePath;
  onChange?: OnSwitchChange;
  uat?: string;
}> = ({ className, disabled, name, onChange: onChangeProp, uat }) => {
  const uatAttribute = useFieldUat(name, 'switch', uat);

  return (
    <div className={classnames('tm2-form-field', className)} data-uat={uatAttribute}>
      <Item className="tm2-form-field-item" name={name}>
        <CustomSwitch disabled={disabled} onCustomChange={onChangeProp} />
      </Item>
    </div>
  );
};

const CustomSwitch: FC<{
  disabled?: boolean;
  onChange?: OnSwitchChange;
  onCustomChange?: OnSwitchChange;
  checked?: boolean;
  defaultChecked?: boolean;
  value?: boolean; // passed by form item
}> = (props) => {
  return (
    <div className={s.wrapper}>
      <AntSwitch
        defaultChecked={props.defaultChecked}
        checked={props.checked || props.value}
        onChange={(checked, e) => {
          props.onChange?.(checked, e);
          props.onCustomChange?.(checked, e);
        }}
        disabled={props.disabled}
        checkedChildren={<Trans id={'switch.on'}>On</Trans>}
        unCheckedChildren={<Trans id={'switch.off'}>Off</Trans>}
      />
    </div>
  );
};
