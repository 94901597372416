import sign from 'tm2sign.macro';

import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';
import { OnboardingStep } from '@config/store/model';
import { Profile } from '../api.shared';
import { profileFields } from '../fields.shared';

// ----------------------------------------------------------------
export type ProfileMy = Profile & { accountOpeningStep: OnboardingStep };
const getMyProfileFields = [...profileFields, 'accountOpeningStep'];
const getMyProfileQuery = 'userProfile';
const getMyProfile = makeQuery({
  permissions: { and: [Permission.PROFILE_VIEW_MY] },
  queryName: getMyProfileQuery,
  queryFields: getMyProfileFields,
  query: (): Promise<ProfileMy> => {
    return stompClientService.getData(
      getMyProfileQuery,
      sign(getMyProfileQuery, getMyProfileFields)
    );
  },
});
// ----------------------------------------------------------------
const api = {
  getMyProfile,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:profile-my-page');

export default api;
