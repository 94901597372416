import React, { FC, memo } from 'react';

import { PermissionGroup } from '@permissions/core';
import { ProfileOther } from '../api';
import { permissionsGroup as tabsPermissionsGroup, UserTabs } from './tabs';
import { permissionsGroup as balancesPermissionsGroup, ProfileBalances } from './balances';
import { permissionsGroup as documentsPermissionsGroup, VerificationDocuments } from './documents';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [balancesPermissionsGroup, documentsPermissionsGroup, tabsPermissionsGroup],
  marker: 'layout:profile-other-page-extra-info',
});

interface ProfileExtraProps {
  profile: ProfileOther;
  refreshProfile: () => void;
}
export const ProfileExtra: FC<ProfileExtraProps> = memo<ProfileExtraProps>((p) => {
  return (
    <>
      <UserTabs profile={p.profile} refreshProfile={p.refreshProfile} />

      <ProfileBalances className="mt1" profile={p.profile} />

      <VerificationDocuments className="mt1" profile={p.profile} />
    </>
  );
});
