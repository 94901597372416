import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { mergeBalances, normalizeCoinBalances, normalizeStableBalances } from '@helper/balance';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';

// ----------------------------------------------------------------
type CoinBalance = {
  amount: number;
  coin: {
    asset: string;
  };
};
type StableBalance = {
  amount: number;
  currency: {
    asset: string;
    id: number;
  };
  overdraft: number;
};
type MixedBalance = {
  coinBalance: Array<CoinBalance>;
  stableBalance: Array<StableBalance>;
};
type Balance = {
  active: MixedBalance;
  hold: MixedBalance;
};
type BalanceResponse = {
  balance: {
    primary: Balance;
    secondary: Balance;
  };
};
type BalanceInfo<T> = {
  amount: number;
  asset: string;
  data: T;
};
export type ProfileBalances = {
  primary: Array<BalanceInfo<CoinBalance | StableBalance>>;
  secondary: Array<BalanceInfo<CoinBalance | StableBalance>>;
};
const getOtherProfileBalancesFields = [
  {
    balance: [
      {
        primary: [
          {
            active: [
              {
                coinBalance: [
                  'amount',
                  {
                    coin: ['asset'],
                  },
                ],
              },
              {
                stableBalance: [
                  'amount',
                  {
                    currency: ['asset', 'id'],
                  },
                  'overdraft',
                ],
              },
            ],
          },
          {
            hold: [
              {
                coinBalance: [
                  'amount',
                  {
                    coin: ['asset'],
                  },
                ],
              },
              {
                stableBalance: [
                  'amount',
                  {
                    currency: ['asset', 'id'],
                  },
                  'overdraft',
                ],
              },
            ],
          },
        ],
      },
      // { // TM2-2997
      //   secondary: [
      //     {
      //       active: [
      //         {
      //           coinBalance: [
      //             'amount',
      //             {
      //               coin: ['asset'],
      //             },
      //           ],
      //         },
      //         {
      //           stableBalance: [
      //             'amount',
      //             {
      //               currency: ['asset', 'id'],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //     {
      //       hold: [
      //         {
      //           coinBalance: [
      //             'amount',
      //             {
      //               coin: ['asset'],
      //             },
      //           ],
      //         },
      //         {
      //           stableBalance: [
      //             'amount',
      //             {
      //               currency: ['asset', 'id'],
      //             },
      //           ],
      //         },
      //       ],
      //     },
      //   ],
      // },
    ],
  },
];
const getOtherProfileBalancesQuery = 'userProfile';
const getOtherProfileBalances = makeQuery({
  permissions: { or: [Permission.BALANCE_VIEW_OTHER] },
  queryName: getOtherProfileBalancesQuery,
  queryFields: getOtherProfileBalancesFields,
  query: (userId: number): Promise<ProfileBalances> => {
    return stompClientService
      .getData(
        getOtherProfileBalancesQuery,
        sign(getOtherProfileBalancesQuery, getOtherProfileBalancesFields),
        v.long({ userId })
      )
      .then((response: BalanceResponse) => {
        const primaryBalance = response.balance.primary;
        const secondaryBalance = response.balance.secondary;

        const stableBalancesOverdraft = primaryBalance.active.stableBalance.reduce<
          Record<string, number | undefined>
        >((acc, b) => {
          acc[b.currency.asset] = b.overdraft;
          return acc;
        }, {});
        const primaryStableBalances = mergeBalances(
          normalizeStableBalances(primaryBalance.hold.stableBalance),
          normalizeStableBalances(primaryBalance.active.stableBalance)
        ).map((b) => ({
          ...b,
          amount: b.amount - (stableBalancesOverdraft[b.asset] ?? 0),
        }));
        const primaryCoinBalances = mergeBalances(
          normalizeCoinBalances(primaryBalance.hold.coinBalance),
          normalizeCoinBalances(primaryBalance.active.coinBalance)
        );
        // TM2-2997: Ignore secondary hold balances. Do not remove
        // commented code, since it may be temporary.
        const secondaryStableBalances = []; // TM2-2997
        // const secondaryStableBalances = normalizeStableBalances(
        //   secondaryBalance.active.stableBalance
        // );
        const secondaryCoinBalances = []; // TM2-2997
        // const secondaryCoinBalances = normalizeCoinBalances(secondaryBalance.active.coinBalance);

        // const secondaryStableBalances = mergeBalances(
        //     normalizeStableBalances(secondaryBalance.hold.stableBalance),
        //     normalizeStableBalances(secondaryBalance.active.stableBalance)
        // );
        // const secondaryCoinBalances = mergeBalances(
        //     normalizeCoinBalances(secondaryBalance.hold.coinBalance),
        //     normalizeCoinBalances(secondaryBalance.active.coinBalance)
        // );

        return {
          primary: [...primaryStableBalances, ...primaryCoinBalances],
          secondary: [...secondaryStableBalances, ...secondaryCoinBalances],
        };
      });
  },
});
// ----------------------------------------------------------------
const api = {
  getOtherProfileBalances,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:profile-other-page-balances');

export default api;
