import React, { memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { Box } from '@components/box';
import { Select } from '@components/form';
import { HasAccess, Permission as P } from '@permissions/core';
import { SearchClear, SearchForm } from '@components/search';
import type { SearchItem } from '@components/search';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ButtonTextIconBig } from '@components/button';
import type { Withdrawal } from '@router/pages/withdrawals/api';

import { Fields, useModel } from './model';
import { permissionGroup as apiPermissionGroup } from './api';
import s from './index.module.scss';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:withdrawals-actions-row',
});

const F = Fields;
const searchFields: Array<SearchItem> = [
  {
    fields: [F.filterStatus],
    id: F.filterStatus,
    label: <Trans id="withdrawals.filter.status">Status</Trans>,
    searchFormField: F.filterStatus,
    type: 'enum',
  },
  {
    fields: ['userBankAccount.user.id'],
    id: F.filterClientId,
    label: <Trans id="withdrawals.filter.client_id">Client ID</Trans>,
    searchFormField: F.filterClientId,
    type: 'long',
  },
];

type Props = {
  addWithdrawal: (withdrawal: Withdrawal) => void;
  onFiltersChanged: (filter: string) => void;
};
export const ActionsRow = memo<Props>((p) => {
  const m = useModel(p.addWithdrawal, p.onFiltersChanged);

  return (
    <Box className={classnames('mb2', s.box)}>
      <SearchForm
        onRsqlChange={m.onSearchChange}
        fields={searchFields}
        memorize={true}
        className={s.form}
      >
        <Select
          name={Fields.filterStatus}
          data={m.withdrawalStatuses}
          label={<Trans id="withdrawals.filter.status">Status</Trans>}
          disabled={m.lockUI}
          compact={true}
          allowEmpty={true}
          className={s.filter}
        />

        <HasAccess permissions={{ or: [P.WITHDRAWALS_VIEW_ALL] }}>
          <Select
            name={Fields.filterClientId}
            data={m.clients}
            label={<Trans id="withdrawals.filter.client_id">Client ID</Trans>}
            disabled={m.lockUI}
            compact={true}
            allowEmpty={true}
            className={s.filter}
          />
        </HasAccess>

        <SearchClear className={s.clear} />

        <HasAccess permissions={{ or: [P.WITHDRAWAL_CREATE_FOR_OTHER] }}>
          <ButtonTextIconBig
            icon={<AddIcon className="tm2-icon-stroke" />}
            onClick={m.onGenerateWithdrawalRequest}
          >
            <Trans id="withdrawals.actions.withdraw_money">Withdraw money</Trans>
          </ButtonTextIconBig>
        </HasAccess>
      </SearchForm>
    </Box>
  );
});
