import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { useCallDispatch } from '@hooks/core';
import { configSelectors } from '@config/store/selectors';
import { useHasAccess } from '@permissions/hooks';
import { Permission } from '@permissions/core';
import { useBankDetails } from './hooks';
import { addFundsModalUserActions as fundsUserActions } from './store/user.acions';
import { addFundsModalSelectors as fundsSelectors } from './store/selectors';

export const useModel = () => {
  const addType = useSelector(fundsSelectors.addType);
  const isInvestor = useSelector(configSelectors.isInvestor);
  const lockUI = useSelector(fundsSelectors.lockUI);

  const onModalOpened = useCallDispatch(fundsUserActions.onModalOpened);
  const onModalLeft = useCallDispatch(fundsUserActions.onModalLeft);
  const onCloseModalClicked = useCallDispatch(fundsUserActions.onCloseModalClicked);
  const onAddFundsTypeSelected = useCallDispatch(fundsUserActions.onAddFundsTypeSelected);

  const history = useHistory();

  const isExtendedInvoiceCreationAllowed = useHasAccess({ or: [Permission.INVOICE_CREATE_EXT] });

  const { isBankDetailsExists } = useBankDetails();

  const isWireTransferAllowed = isBankDetailsExists || isExtendedInvoiceCreationAllowed;

  const onAddBankDetails = useCallback(() => {
    onCloseModalClicked();
    history.push('/profile#bank-details');
  }, []);

  useEffect(() => {
    onModalOpened();
    return () => {
      onModalLeft();
    };
  }, []); // eslint-disable-line

  return {
    addType,
    isBankDetailsExists,
    isInvestor,
    isWireTransferAllowed,
    lockUI,
    onAddBankDetails,
    onAddFundsTypeSelected,
    onCloseModalClicked,
  };
};
