import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import { ReactComponent as EditIcon } from '@images/icons/edit-rounded-icon.svg';
import { ButtonTextIconBig } from '../index.strict';
import s from './index.module.scss';

import type { FC } from 'react';

type Compact = 'all' | 'mobile' | 'none';
export const ButtonEdit: FC<{
  className?: string;
  compact?: Compact;
  disabled?: boolean;
  onClick: () => void;
  uat?: string;
}> = (p) => {
  const compact: Compact = p.compact || 'none';

  return (
    <ButtonTextIconBig
      className={classnames(p.className, {
        [s.compactAll]: compact === 'all',
        [s.compactMobile]: compact === 'mobile',
      })}
      icon={<EditIcon className="tm2-icon-stroke" />}
      onClick={p.onClick}
      disabled={p.disabled}
      loading={false}
      data-uat={p.uat}
    >
      <Trans id="shared.button.edit">Edit</Trans>
    </ButtonTextIconBig>
  );
};
