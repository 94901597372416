import { useI18n } from '@hooks/i18n';
import { useProfileMy } from './hooks';

export const useModel = () => {
  const { i18n } = useI18n();

  const { profile } = useProfileMy();

  return {
    i18n,
    profile,
  };
};
