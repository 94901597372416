import {
  TransferCoinTabActions,
  TransferCoinTabActionsTypes as CoinTabTypes,
  TransferCoinTabState,
  transferCoinTabStateInitial,
} from './model';
import { TransferDirection } from '../../model';

export const transferCoinTabReducer = (
  combinedState,
  action: TransferCoinTabActions
): TransferCoinTabState => {
  const state: TransferCoinTabState = combinedState.coinTab;
  const newState = (localState: TransferCoinTabState) => ({
    ...combinedState,
    coinTab: { ...localState },
  });

  switch (action.type) {
    case CoinTabTypes.FINISH_COIN_TRANSACTION: {
      return newState({
        ...state,
        isFinishCoinTransactionLoading: true,
      });
    }

    case CoinTabTypes.FINISH_COIN_TRANSACTION_FAIL: {
      return newState({
        ...state,
        isFinishCoinTransactionLoading: false,
      });
    }

    case CoinTabTypes.FINISH_COIN_TRANSACTION_SUCCESS: {
      return newState({
        ...state,
        isFinishCoinTransactionLoading: false,
      });
    }

    case CoinTabTypes.ON_SELECTED_VALUE_CHANGED: {
      return newState({
        ...state,
        assetSelected: action.payload,
      });
    }

    case CoinTabTypes.GET_COIN_INFO: {
      return newState({
        ...state,
        isCoinsInfoLoading: true,
      });
    }

    case CoinTabTypes.GET_COIN_INFO_FAIL: {
      return newState({
        ...state,
        isCoinsInfoLoading: false,
        isTabInitiated: true,
      });
    }

    case CoinTabTypes.GET_COIN_INFO_SUCCESS: {
      return newState({
        ...state,
        isCoinsInfoLoading: false,
        isTabInitiated: true,
      });
    }

    case CoinTabTypes.ON_TAB_LEFT: {
      return newState({
        ...transferCoinTabStateInitial,
      });
    }

    case CoinTabTypes.ON_TAB_OPENED: {
      return newState({
        ...state,
        assetSelected: '',
      });
    }

    case CoinTabTypes.ON_TOGGLE_COIN_TRANSFER_DIRECTION_CLICKED: {
      const oldDirection = state.transferDirection;
      const newDirection =
        oldDirection === TransferDirection.fromPrimary
          ? TransferDirection.fromSecondary
          : TransferDirection.fromPrimary;

      return newState({
        ...state,
        transferDirection: newDirection,
      });
    }

    case CoinTabTypes.SET_COIN_INFO: {
      const {
        balancesPrimary = [],
        balancesSecondary = [],
        coinsSecondaryAvailable = [],
      } = action.payload;

      return newState({
        ...state,
        coinsSecondaryAvailable: coinsSecondaryAvailable,
        coinBalancesPrimary: balancesPrimary,
        coinBalancesSecondary: balancesSecondary,
      });
    }

    case CoinTabTypes.UPDATE_FORM_VALUES: {
      return newState({
        ...state,
        isTransferCoinInfoLoading: true,
      });
    }

    case CoinTabTypes.UPDATE_FORM_VALUES_FAIL: {
      return newState({
        ...state,
        isTransferCoinInfoLoading: false,
      });
    }

    case CoinTabTypes.UPDATE_FORM_VALUES_SUCCESS: {
      return newState({
        ...state,
        isTransferCoinInfoLoading: false,
      });
    }

    default: {
      return combinedState;
    }
  }
};
