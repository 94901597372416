import { TransferCoinTabActionsTypes as T } from './model';
import { ReduxAction } from '@models/redux';

class OnTabOpened extends ReduxAction<T.ON_TAB_OPENED, void> {
  public readonly type: T.ON_TAB_OPENED = T.ON_TAB_OPENED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnTabLeft extends ReduxAction<T.ON_TAB_LEFT, void> {
  public readonly type: T.ON_TAB_LEFT = T.ON_TAB_LEFT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnCancelFinishCoinTransferClickedAction extends ReduxAction<
  T.ON_CANCEL_FINISH_COIN_TRANSFER_CLICKED,
  void
> {
  public readonly type: T.ON_CANCEL_FINISH_COIN_TRANSFER_CLICKED =
    T.ON_CANCEL_FINISH_COIN_TRANSFER_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnMaximizeCoinAmountTransferClickedAction extends ReduxAction<
  T.ON_MAXIMIZE_COIN_AMOUNT_TRANSFER_CLICKED,
  void
> {
  public readonly type: T.ON_MAXIMIZE_COIN_AMOUNT_TRANSFER_CLICKED =
    T.ON_MAXIMIZE_COIN_AMOUNT_TRANSFER_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnToggleCoinTransferDirectionClickedAction extends ReduxAction<
  T.ON_TOGGLE_COIN_TRANSFER_DIRECTION_CLICKED,
  void
> {
  public readonly type: T.ON_TOGGLE_COIN_TRANSFER_DIRECTION_CLICKED =
    T.ON_TOGGLE_COIN_TRANSFER_DIRECTION_CLICKED;
  public constructor(public payload: void) {
    super(payload);
  }
}

class OnSelectedValueChangedAction extends ReduxAction<T.ON_SELECTED_VALUE_CHANGED, string> {
  public readonly type: T.ON_SELECTED_VALUE_CHANGED = T.ON_SELECTED_VALUE_CHANGED;
  public constructor(public payload: string) {
    super(payload);
  }
}

export const transferCoinTabUserActions = {
  onTabOpened: () => new OnTabOpened().get(),
  onTabLeft: () => new OnTabLeft().get(),
  onCancelFinishCoinTransferClicked: () => new OnCancelFinishCoinTransferClickedAction().get(),
  onMaximizeCoinAmountTransferClicked: () => new OnMaximizeCoinAmountTransferClickedAction().get(),
  onToggleCoinTransferDirectionClicked: () =>
    new OnToggleCoinTransferDirectionClickedAction().get(),
  onSelectedValueChanged: (asset: string) => new OnSelectedValueChangedAction(asset).get(),
};

export type TransferCoinTabUserActionsTypes =
  | OnTabOpened
  | OnTabLeft
  | OnCancelFinishCoinTransferClickedAction
  | OnMaximizeCoinAmountTransferClickedAction
  | OnToggleCoinTransferDirectionClickedAction
  | OnSelectedValueChangedAction;
