import { useCallbackOne } from 'use-memo-one';

import { mapTableSorting, TableSortCreator } from '@helper/sorting';
import { KeyExtractor, SortOrder } from '@components/table2/core';
import { LoadData, useTable } from '@components/table2/simplified';
import { useI18n } from '@hooks/i18n';
import api, { Column, OverdraftItem } from './api';

const sortMap = TableSortCreator(Column, { exclude: [Column.clientName, Column.txId] });
const initialSortOrder: SortOrder = [
  { field: Column.createdAt, direction: 'descend', key: Column.createdAt },
];

export const useModel = () => {
  const { i18n } = useI18n();

  const keyExtractor = useCallbackOne<KeyExtractor<OverdraftItem>>((item) => {
    return item.createdAt;
  }, []);

  const loadData = useCallbackOne<LoadData<OverdraftItem>>(({ filter, nextPage, sortOrder }) => {
    const sorting = mapTableSorting(sortOrder, sortMap);
    return api.getOverdraftReport(nextPage, sorting, filter as string);
  }, []);

  const { setFilter, tableInstance } = useTable<OverdraftItem>({
    ensureUniqueness: true,
    initialSortOrder,
    keyExtractor,
    loadData,
  });

  const onSearchChange = useCallbackOne((rsql: string) => setFilter(() => rsql), [setFilter]);

  return {
    i18n,
    onSearchChange,
    tableInstance,
  };
};
