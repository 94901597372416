import { useLayoutEffect, useRef } from 'react';
import type { ReactNode } from 'react';

type Props = {
  className?: string;
  heightSetter: (height: number) => void;
  children: ReactNode;
};

// This component is expected to be rendered synchronously
// and maintain constant height.
export function VirtualPadding({ children, className, heightSetter }: Props) {
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (containerRef.current) {
      heightSetter(containerRef.current.offsetHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      ref={containerRef}
      className={className}
      style={{
        position: 'absolute',
        bottom: 0,
      }}
    >
      {children}
    </div>
  );
}
