import { useRef } from 'react';
import { useCallbackOne, useMemoOne } from 'use-memo-one';
import { useTableSorter } from '../table-sorter/use-table-sorter';
import { areOrdersEqual, sortItems } from '../table-sorter/sorting';
import { isFieldSortOrder } from '../table-sorter/utils';

import type { BaseItem, ScrollToIndexRef } from '../loadable-virtual-list';
import type {
  SortOrder,
  TableSorterReturnType,
  TableSorterParams as OriginalTableSorterParams,
} from '../table-sorter/use-table-sorter';
import type { FieldSortOrder } from '../table-sorter/utils';

export { areOrdersEqual, areFieldsEqual, sortItems } from '../table-sorter/sorting';

const AGGREGATOR_INSTANCE_SYMBOL = Symbol();
const AGGREGATOR_PARAMS_SYMBOL = Symbol();
const AGGREGATOR_SCROLL_TO_INDEX_REF_SYMBOL = Symbol();

export type {
  MergeOrderItem,
  OnSortItemChange,
  OnSortOrderChange,
  SortDirection,
  SortItem,
  SortOrder,
  SortOrderMerger,
  TableSorterReturnType,
} from '../table-sorter/use-table-sorter';

export type ScrollToIndex = (index: number | undefined) => void;

export type InMemorySorter<T extends BaseItem> = (data: T[], sortOrder: FieldSortOrder) => T[];

export type TableSorterParams<T extends BaseItem> = Omit<
  OriginalTableSorterParams,
  'inMemorySorter'
> & {
  // This should only be used with non-loadable tables.
  inMemorySorter?: InMemorySorter<T> | boolean;
};

export type UseTableReturnType<T extends BaseItem> = Readonly<{
  sorter: TableSorterReturnType;
  scrollToIndex: ScrollToIndex;
  [AGGREGATOR_INSTANCE_SYMBOL]: true;
  [AGGREGATOR_PARAMS_SYMBOL]: UseTableParams<T>;
  [AGGREGATOR_SCROLL_TO_INDEX_REF_SYMBOL]: ScrollToIndexRef;
}>;

// We want to allow passing data as a prop for those not using this hook
// directly. To avoid different ways of setting the data we don't provide
// it as a hook param.
export type UseTableParams<T extends BaseItem> = {
  sorter?: TableSorterParams<T>;
};

function isUseTableReturnedValue<T extends BaseItem>(
  params: UseTableReturnType<T> | UseTableParams<T>
): params is UseTableReturnType<T> {
  if (AGGREGATOR_INSTANCE_SYMBOL in params) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (params as any)[AGGREGATOR_INSTANCE_SYMBOL];
  }
  return false;
}

export const useTable = <T extends BaseItem>(
  params: UseTableParams<T> | UseTableReturnType<T> = {}
): UseTableReturnType<T> => {
  const isInstantiated = isUseTableReturnedValue(params);
  const isInstantiatedRef = useRef(isInstantiated);

  if (process.env.NODE_ENV === 'development') {
    const isInstanceBroken = isInstantiatedRef.current !== isInstantiated;
    if (isInstanceBroken) {
      throw new Error(
        'table/use-table: useTable: Table instance should be preserved or not used at all'
      );
    }
  }
  if (isInstantiated) {
    return params;
  }
  /* eslint-disable react-hooks/rules-of-hooks */
  const sorter = useTableSorter(params.sorter);

  const scrollToIndexRef: ScrollToIndexRef = useRef(() => {});
  const scrollToIndex = useCallbackOne<ScrollToIndex>((index) => {
    if (typeof index === 'undefined') return;
    scrollToIndexRef.current?.(index);
  }, []);
  /* eslint-disable react-hooks/rules-of-hooks */

  return {
    sorter,
    scrollToIndex,
    [AGGREGATOR_INSTANCE_SYMBOL]: true,
    [AGGREGATOR_PARAMS_SYMBOL]: params,
    [AGGREGATOR_SCROLL_TO_INDEX_REF_SYMBOL]: scrollToIndexRef,
  };
};

export type UseTableInternalReturnType<T extends BaseItem> = Readonly<{
  aggregator: UseTableReturnType<T>;
  data: T[];
  scrollToIndexRef: ScrollToIndexRef;
}>;

export type UseTableInternalParams<T extends BaseItem> = {
  data: T[];
  instance?: UseTableReturnType<T>;
};

function defaultInMemorySorter<T extends BaseItem>(data: T[], sortOrder: FieldSortOrder) {
  return sortItems(data, sortOrder);
}

function getIdentity<T>(param: T): T {
  return param;
}

export const useTableInternal = <T extends BaseItem>(
  params: UseTableInternalParams<T>
): UseTableInternalReturnType<T> => {
  const aggregator = useTable(params.instance);
  const providedInMemorySorter = aggregator[AGGREGATOR_PARAMS_SYMBOL].sorter?.inMemorySorter;

  let inMemorySorter: InMemorySorter<T> = defaultInMemorySorter;
  if (typeof providedInMemorySorter === 'function') {
    inMemorySorter = providedInMemorySorter;
  }
  if (!providedInMemorySorter) {
    inMemorySorter = getIdentity;
  }

  const prevData = useRef(params.data);
  const prevSortedData = useRef(params.data);
  const prevSortOrder = useRef<SortOrder>([]);

  const data = useMemoOne(() => {
    if (!providedInMemorySorter) return params.data;

    if (!isFieldSortOrder(aggregator.sorter.order)) {
      throw new Error(
        'table/use-table: useTableInternal: `field` property should be provided for each sort ' +
          'item for in-memory sorter to work'
      );
    }

    const orderChanged = !areOrdersEqual(prevSortOrder.current, aggregator.sorter.order);
    const dataChanged = params.data !== prevData.current;

    if (!orderChanged && !dataChanged) {
      return prevSortedData.current;
    }

    const sortedData = inMemorySorter(params.data, aggregator.sorter.order);
    prevData.current = params.data;
    prevSortedData.current = sortedData;
    prevSortOrder.current = aggregator.sorter.order;

    return sortedData;
  }, [aggregator.sorter.order, params.data, providedInMemorySorter]);

  return {
    aggregator,
    data,
    scrollToIndexRef: aggregator[AGGREGATOR_SCROLL_TO_INDEX_REF_SYMBOL],
  };
};
