import { useState } from 'react';
import { closeModalAction } from '@modules/modal';
import { useCallDispatch } from '@hooks/core';
import { OnFormAction } from '@components/form';
import v from '@components/form/validators';
import api, { Fields, Form } from './api';
import { useCurrencies, useIssuers } from './hooks';

export { Fields } from './api';
export type { Form } from './api';

export const formId = 'addCorrectionTransaction';

export const useModel = (replaceTransaction, modal) => {
  const { currencies, isCurrenciesLoading } = useCurrencies();

  const {
    isIssuersLoading,
    issuers,
    selectedRecipientId,
    selectedSenderId,
    setSelectedRecipientId,
    setSelectedSenderId,
  } = useIssuers();

  const [isCreateCTLoading, setCreateCTLoading] = useState(false);
  const onClose = useCallDispatch(closeModalAction);
  const lockUI = isIssuersLoading || isCreateCTLoading || isCurrenciesLoading;

  const onFormAction: OnFormAction<Form> = {
    fieldValidation: {
      // TODO: add backend validation
      [Fields.amount]: [v.required, v.numMin(0.01)],
      [Fields.currencyId]: [v.required],
      [Fields.recipientId]: [v.required],
      [Fields.senderId]: [v.required],
    },
    error: async () => {
      setCreateCTLoading(false);
    },
    submit: async (form) => {
      setCreateCTLoading(true);
      const createdCorrectionTransaction = await api.createCorrectionTransaction(form);
      replaceTransaction(createdCorrectionTransaction);
      setCreateCTLoading(false);
      onClose(modal);
    },
  };

  return {
    currencies,
    issuers,
    lockUI,
    onClose,
    onFormAction,
    selectedRecipientId,
    selectedSenderId,
    setSelectedRecipientId,
    setSelectedSenderId,
  };
};
