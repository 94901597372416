import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { OfferStatus } from '../../shared/types';
import { Offer } from './types';

type QueryOffer = {
  id: number;
  activeOfferPrices: Array<{
    id: number;
    quantity: number;
    currency: {
      id: number;
      code: string;
    };
    unit: {
      label: string;
    };
  }>;
  availableQuantity: number;
  coin: {
    asset: string;
    metal: {
      label: string;
    };
  };
  creator: {
    id: number;
    displayName: string;
  };
  date: string;
  quantityUnit: {
    label: string;
  };
  sellCommission: number;
  status: OfferStatus;
};

type CancelOfferResponse = Offer;

const cancelOfferQuery = 'cancelOffer';
const cancelOfferFields = [
  'id',
  {
    activeOfferPrices: [
      'id',
      'quantity',
      { currency: ['id', 'code', 'symbol'] },
      { unit: ['label'] },
    ],
  },
  'availableQuantity',
  {
    coin: ['asset', { metal: ['label'] }],
  },
  {
    creator: ['id', 'displayName'],
  },
  'date',
  { quantityUnit: ['label'] },
  'sellCommission',
  'status',
];

const cancelOffer = makeQuery({
  queryName: cancelOfferQuery,
  queryFields: cancelOfferFields,
  query: (offerId: number): Promise<CancelOfferResponse> => {
    const variables = { id: offerId };
    return stompClient
      .sendData<QueryOffer>(cancelOfferQuery, sign(cancelOfferQuery, cancelOfferFields), variables)
      .then(
        (offer: QueryOffer): Offer => ({
          id: offer.id,
          activePrices: offer.activeOfferPrices.map((price) => ({
            id: price.id,
            quantity: price.quantity,
            currencyId: price.currency.id,
            currencyCode: price.currency.code,
            unitLabel: price.unit.label,
          })),
          asset: offer.coin.asset,
          availableQuantity: offer.availableQuantity,
          creatorId: offer.creator.id,
          creatorName: offer.creator.displayName,
          date: offer.date,
          metal: offer.coin.metal.label,
          quantityUnitLabel: offer.quantityUnit.label,
          sellCommission: offer.sellCommission,
          status: offer.status,
        })
      );
  },
});

const api = {
  cancelOffer,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:cancel-offer');

export default api;
