import { Role } from '@permissions/core';
import React from 'react';
import { formatMoney } from 'containers/services/commonService';
import { defineMessage, Trans } from '@lingui/macro';
import { PageContent, PageTitle } from '@components/page';
import { useDailyBalanceHistoryModel } from './model';
import { PageTable } from 'components/page-table';
import { UserProfileLink } from '@components/link';
import { Box } from '@components/box';
import { Form, InputDateRange, Select } from '@components/form';
import classnames from 'classnames';
import s from './index.module.scss';

const dailyBalanceHistoryFormId = 'daily-balance-history';
const FormFields = { issuerId: 'issuerId', date: 'date' };

export const DailyBalanceHistory = () => {
  const { i18n, currencies, currentRole, balances, issuers, onUpdateTable } =
    useDailyBalanceHistoryModel();

  const columns = [
    {
      head: <Trans id="label.date">Date</Trans>,
      cell: (item) => item?.date,
    },
    currentRole === Role.admin && {
      head: <Trans id="label.issuer">Issuer</Trans>,
      cell: (item) => <UserProfileLink id={item?.issuer?.id} text={item?.issuer?.displayName} />,
    },
    ...currencies.map((currency) => {
      return {
        className: 'align-right',
        head: () => <Trans id="daily_balance_history.balance">Balance, {currency}</Trans>,
        cell: (item) => {
          let balance = item?.balances.find((b) => currency === b.code)?.balance;
          return balance ? formatMoney(balance, {}) : '-';
        },
      };
    }),
  ];

  return (
    <PageContent uat="statements-daily-balance-history-page">
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.daily-balance-history',
            message: 'Daily balance history',
          })
        )}
        backwardLink={'/statements'}
      />

      <Box className={classnames(s.filtersCol, 'mb-sm')}>
        <Form
          className={s.form}
          name={dailyBalanceHistoryFormId}
          onChange={(formData) => onUpdateTable(formData)}
        >
          <InputDateRange
            className={s.formItem}
            label={<Trans id="daterange.label">Date</Trans>}
            name={FormFields.date}
            max={new Date().toISOString()}
            compact={true}
          />

          <Select
            className={s.formItem}
            label={<Trans id={'label.issuer'}>Issuer</Trans>}
            name={FormFields.issuerId}
            data={issuers?.map((issuer) => ({ label: issuer.displayName, value: issuer.id }))}
            compact={true}
            allowEmpty={true}
          />
        </Form>
      </Box>

      <PageTable
        columns={columns}
        onLoadMore={() => {}}
        pageData={{
          data: balances,
          hasMore: false,
        }}
      />
    </PageContent>
  );
};
