import React from 'react';
import { all, put, takeEvery } from 'redux-saga/effects';
import { Trans } from '@lingui/macro';

import { showConfirmModalAction, showModalAction } from '@modules/modal';
import { DispatchThunkFn } from '@models/redux';
import { CoinInfo, VaultInfo, vaultsApiService as vaultsApi } from '../../vaults-api.service';
import { VaultsPageActionsTypes as VaultsTypes } from './model';
import { vaultsPageServiceActions as vaultsServiceActions } from './service.acions';
import { VaultsPageUserActions as VaultsUserActions } from './user.acions';
import { ConfirmVaultBalanceModal } from '../../confirm-balance-modal';
import { VaultBalanceDetailModal } from '../../vault-balance-detail-modal';
import { HandleVaultModal } from '../../handle-vault-modal';

export function* vaultsPageEffects() {
  yield all([
    takeEvery(VaultsTypes.ON_VAULTS_LOADED, updateVaults),
    takeEvery(VaultsTypes.ON_VAULTS_SORTED, cleanVaults),
    takeEvery(VaultsTypes.ON_ADD_VAULT_CLICKED, openHandleVaultModal),
    takeEvery(VaultsTypes.ON_CONFIRM_VAULT_BALANCE_CLICKED, openConfirmVaultBalanceModal),
    takeEvery(VaultsTypes.ON_DELETE_VAULT_CLICKED, openConfirmDeleteModal),
    takeEvery(VaultsTypes.ON_EDIT_VAULT_CLICKED, openHandleVaultModal),
    takeEvery(VaultsTypes.ON_SHOW_VAULT_BALANCE_DETAILS_CLICKED, openVaultDetailsModal),
  ]);
}

function* updateVaults(action: VaultsUserActions.OnVaultsLoaded) {
  yield put(vaultsServiceActions.vaultsLoaded(action.payload));
}

function* cleanVaults(action: VaultsUserActions.OnVaultsSorted) {
  yield put(vaultsServiceActions.vaultsCleaned(action.payload));
}

function* openConfirmDeleteModal(action: VaultsUserActions.OnDeleteVaultClicked) {
  const vault: VaultInfo = action.payload;
  yield put(
    showConfirmModalAction({
      onConfirm: {
        action: async (dispatch: DispatchThunkFn) => {
          await dispatch(vaultsServiceActions.deleteVault());
          await vaultsApi.deleteVault(vault.id);
          await dispatch(vaultsServiceActions.deleteVaultSuccess(vault));
        },
        error: async (error: Error, dispatch: DispatchThunkFn) => {
          await dispatch(vaultsServiceActions.deleteVaultFail(error));
        },
      },
      text: {
        title: <Trans id="vaults.question.remove_vault">Remove vault?</Trans>,
      },
    })
  );
}

function* openVaultDetailsModal(action: VaultsUserActions.OnShowVaultBalanceDetails) {
  const coin: CoinInfo = action.payload.coin;
  const vault: VaultInfo = action.payload.vault;
  yield put(
    showModalAction({
      props: { coin, vault },
      modal: VaultBalanceDetailModal,
    })
  );
}

function* openConfirmVaultBalanceModal(action: VaultsUserActions.OnConfirmVaultBalanceClicked) {
  const vault: VaultInfo = action.payload;
  yield put(showModalAction({ modal: ConfirmVaultBalanceModal, props: { vault } }));
}

function* openHandleVaultModal(
  action: VaultsUserActions.OnAddVaultClicked | VaultsUserActions.OnEditVaultClicked
) {
  const vault = action.payload || {};
  // TODO-2833: fix types
  // @ts-ignore
  yield put(showModalAction({ modal: HandleVaultModal, props: { vaultId: vault?.id } }));
}
