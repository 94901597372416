import React, { FC, memo } from 'react';

import { HasAccess, PermissionGroup } from '@permissions/core';
import { ButtonEdit } from '@components/button';
import { permissionsGroup as editProfilePermissionsGroup } from '../../../modals/edit-profile';
import { useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'AND',
  groups: [editProfilePermissionsGroup],
  marker: 'layout:profile-my-page-confirm-edit-profile-modal',
});

const accessPermissions = { or: [permissionsGroup] };
export const EditProfileButton: FC = memo(() => {
  const m = useModel();

  return (
    <HasAccess permissions={accessPermissions}>
      <ButtonEdit
        onClick={m.showEditModal}
        disabled={m.isEditOpened}
        compact="mobile"
        uat="edit-profile-button"
      />
    </HasAccess>
  );
});
