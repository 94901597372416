import { OnFormAction } from '@components/form';
import { DispatchThunkFn } from '@models/redux';
import { depositsApiService as depositsApi } from '../../../deposits-api.service';
import { creditCardViewServiceActions as cardServiceActions } from './service.acions';
import { CreditCardViewForm } from './model';

export const onCreditCardFormAction: OnFormAction = {
  error: (error: Error, dispatch: DispatchThunkFn) => {
    dispatch(cardServiceActions.prepareCreditCardFail(error));
  },
  submit: async (form: CreditCardViewForm, dispatch: DispatchThunkFn) => {
    await dispatch(cardServiceActions.prepareCreditCard());
    const link: string = await depositsApi.preparePayment(form);
    window.open(link, '_self', 'noopener noreferrer');
    await dispatch(cardServiceActions.prepareCreditCardSuccess());
  },
};
