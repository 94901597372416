import { useCallback, useContext, useEffect, useMemo, useRef, useState, ReactNode } from 'react';
import { useHistory } from 'react-router';
import { Trans } from '@lingui/macro';
import { Tabs, TabPane, ForcedRenderPaneWrapper } from '@components/tabs/regular';
import { Form, SubmitButton, OnFormAction } from '@components/form';
import v, { nestedName } from '@components/form/validators';
import { useSyncRef } from '@hooks/core';
import { PermissionGroup, Role } from '@permissions/core';
import { ValidationError } from '@services/stomp/errors';
import { handleBackendError } from '@modules/notify';
import { promiseTimeout, TimeoutError } from './promise-timeout';
import api from './api';
import { formId, tabId, FormContext, FormProvider, fields } from './form';
import { ProductInfoContext, ProductInfoProvider } from './tabs/product-info/context';
import { TechInfoContext, TechInfoProvider } from './tabs/tech-info/context';
import {
  GeneralTab,
  PositionTab,
  RedemptionTab,
  ProductInfoTab,
  FeesTab,
  TechInfoTab,
} from './tabs';
import { TabHeader } from './tab-header';
import s from './index.module.scss';
import { permissionGroup as apiPermissionGroup } from './api';
import { permissionGroup as techInfoPermissionGroup } from './tabs/tech-info';

import type { ObjectValues } from '@helper/ts';
import type { TabsHeaderData } from '@components/tabs/regular';
import type { FormData } from './form';
import type { Coin, CreateFormData, EditFormData, TabHeaderData } from './types';

export type { Coin, CreateFormData, EditFormData } from './types';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup, techInfoPermissionGroup],
  marker: 'layout:create-or-edit',
});

type CreateOrEditProps = {
  onInitialFetchTimeoutError?: () => void;
} & (
  | {
      mode: 'create';
      onSubmit: (data: CreateFormData) => Promise<unknown>;
    }
  | {
      mode: 'edit';
      onSubmit: (data: EditFormData) => Promise<unknown>;
      initData: () => Promise<Coin>;
    }
);

const tabsHeaderNameData: TabsHeaderData<ReactNode> = [
  {
    tabKey: tabId.general,
    data: <Trans id={'digital_metals.add.tabs.general'}>General</Trans>,
  },
  {
    tabKey: tabId.position,
    data: <Trans id={'digital_metals.add.tabs.position'}>Position</Trans>,
  },
  {
    tabKey: tabId.redemption,
    data: <Trans id={'digital_metals.add.tabs.redemption'}>Redemption</Trans>,
  },
  {
    tabKey: tabId.productInfo,
    data: <Trans id={'digital_metals.add.tabs.product_info'}>Product Info</Trans>,
  },
  {
    tabKey: tabId.fees,
    data: <Trans id={'digital_metals.add.tabs.fees'}>Fees</Trans>,
  },
  {
    tabKey: tabId.techInfo,
    data: <Trans id={'digital_metals.add.tabs.tech_info'}>Tech info</Trans>,
  },
];

export function CreateOrEdit(props: CreateOrEditProps) {
  return (
    <FormProvider mode={props.mode}>
      <ProductInfoProvider>
        <TechInfoProvider>
          <CreateOrEditInternal {...props} />
        </TechInfoProvider>
      </ProductInfoProvider>
    </FormProvider>
  );
}

function CreateOrEditInternal(props: CreateOrEditProps) {
  const isEditMode = props.mode === 'edit';
  const history = useHistory();
  const formContext = useContext(FormContext);
  const productInfoContext = useContext(ProductInfoContext);
  const techInfoContext = useContext(TechInfoContext);
  const [activeTab, setActiveTab] = useState<string>(tabId.general);
  const submitRowLeftBtnRef = useRef<HTMLDivElement>(null);
  const productInfoStateRef = useSyncRef(productInfoContext.state);

  const onTabKeyChange = useCallback((key: string) => {
    setActiveTab(key);
  }, []);

  const [tabsHeaderData, setTabsHeaderData] = useState<TabsHeaderData<TabHeaderData>>(() => {
    return tabsHeaderNameData.map((entry) => ({
      ...entry,
      data: { name: entry.data, showNotification: false },
    }));
  });

  const fieldValidation = useMemo(() => {
    return {
      [fields.general.name]: [v.required],
      [fields.general.metal]: [v.required],
      [fields.general.asset]: [v.required, v.latinAndNumbers, v.lengthMax(12)],
      [fields.general.amount]: [v.required],
      [fields.general.brandName]: [v.required],
      [fields.general.physicalBacking]: [v.required],
      [fields.general.issuer]: [v.required],
      [fields.general.vault]: [v.required],
      [fields.general.physicalBacking]: [v.required],
      [fields.general.weightMeasurement]: [v.required],
      [fields.general.unitAndDenomination]: [v.required],
      [fields.general.physicalRedemption]: [v.required],
      [fields.position.positionDays]: [v.required],
      [fields.position.positionPercent]: [v.required],
      [fields.fees.sellCommission]: [v.required],
      [fields.fees.transactionCommission]: [v.required],
      [fields.fees.managementFee]: [v.required],
      [nestedName([fields.redemption.redeemOptions, fields.redemption.name])]: [v.required],
      [nestedName([fields.redemption.redeemOptions, fields.redemption.amount])]: [v.required],
      [nestedName([fields.redemption.redeemOptions, fields.redemption.price])]: [v.required],
      [nestedName([fields.redemption.redeemOptions, fields.redemption.unit])]: [v.required],
      [nestedName([fields.redemption.redeemOptions, fields.redemption.minimalQuantity])]: [
        v.required,
      ],
      [nestedName([fields.redemption.redeemOptions, fields.redemption.priceType])]: [v.required],
      [nestedName([fields.techInfo.cqgSymbols, fields.techInfo.cqgSymbol])]: [v.required],
      [nestedName([fields.techInfo.cqgSymbols, fields.techInfo.cqgContractName])]: [v.required],
      [nestedName([fields.techInfo.cqgSymbols, fields.techInfo.cqgCurrency])]: [v.required],
    };
  }, []);

  const onSubmit = useCallback<OnFormAction<FormData>['submit']>(
    async (data) => {
      // Workaround to get multi-select working.
      const apiLiquidityProviders = data.coinInfo?.liquidityProviders?.map((id) => ({ id })) ?? [];

      const { coinInfo, ...dataWOCoinInfo } = data;
      const { liquidityProviders, ...coinInfoWOLiquidityProviders } = coinInfo;
      const { primaryMarketProductInfo, productInfo, infoFiles } = productInfoStateRef.current;

      const normalizedData: EditFormData = {
        ...dataWOCoinInfo,
        coinInfo: {
          ...coinInfoWOLiquidityProviders,
          liquidityProviders: apiLiquidityProviders,
          primaryMarketProductInfo,
        },
        infoFiles,
        productInfo,
        redeemOptions: data.redeemOptions ?? [],
      };
      setTabsHeaderData((tabsData) => {
        return tabsData.map((entry) => ({
          ...entry,
          data: { ...entry.data, showNotification: false },
        }));
      });

      formContext.dispatch({ type: 'FORM_SUBMITTED' });
      productInfoContext.dispatch({ type: 'FORM_SUBMITTED' });
      await props.onSubmit(normalizedData);
      history.push('/digital-metals');
    },
    [props.mode, props.onSubmit]
  );

  const onSubmitError = useCallback<OnFormAction<any>['error']>((error) => {
    if (!(error instanceof ValidationError)) return;

    productInfoContext.dispatch({ type: 'VALIDATION_FAILED', payload: error });
    formContext.dispatch({ type: 'FORM_SUBMISSION_ERROR' });

    const categoryToNotificationMap = error.data.reduce<
      Partial<Record<ObjectValues<typeof tabId>, boolean>>
    >((acc, { field: errorField }) => {
      // Backend fields can be shaped as <name>[<index>].<name>
      // We handle this cases manually.
      if (errorField.startsWith(fields.redemption.redeemOptions + '[')) {
        acc[tabId.redemption] = true;
        return acc;
      }
      if (errorField.startsWith(fields.productInfo.infoFiles)) {
        acc[tabId.productInfo] = true;
        return acc;
      }
      if (errorField.startsWith(fields.techInfo.cqgSymbols)) {
        acc[tabId.techInfo] = true;
        return acc;
      }
      // Exclude categories with nested fields, since they can be false
      // positive. We only exclude categories with nested fields
      // at root level (e.g. `amount` in `redeemOptions`).
      const checkedFields = Object.entries(fields).filter(
        ([category, _]) => !([tabId.redemption, tabId.techInfo] as string[]).includes(category)
      );

      checkedFields.forEach(([category, fieldSet]) => {
        const fieldArray = Object.values(fieldSet);
        const hasErrorField = fieldArray.some((field) => {
          return field === errorField;
        });
        if (hasErrorField) {
          acc[category] = true;
        }
      });
      return acc;
    }, {});

    setTabsHeaderData((tabsData) => {
      return tabsData.map((entry) => ({
        ...entry,
        data: {
          ...entry.data,
          showNotification: categoryToNotificationMap[entry.tabKey],
        },
      }));
    });

    const notifiedCategories = Object.keys(categoryToNotificationMap);
    if (notifiedCategories.length) {
      const orderedNotifiedCategories = Object.keys(tabId).filter((id) =>
        notifiedCategories.includes(id)
      );
      setActiveTab(orderedNotifiedCategories[0]);
    }
  }, []);

  const onFormAction = useMemo<OnFormAction<any>>(
    () => ({
      fieldValidation,
      submit: onSubmit,
      error: onSubmitError,
    }),
    [fieldValidation, onSubmit, onSubmitError]
  );

  const onFormChange = useCallback((formData: FormData) => {
    techInfoContext.dispatch({
      type: 'FORM_CHANGED',
      payload: { data: formData },
    });
  }, []);

  useEffect(() => {
    promiseTimeout(
      Promise.all([
        api.getCurrencies(),
        api.getMetals(),
        api.getUnits(),
        api.getVaults(),
        api.getUsersByRole(Role.issuer),
        api.getUsersByRole(Role.listingBroker),
        api.getUsersByRole(Role.liquidityProvider),
        isEditMode ? props.initData() : undefined,
      ])
    )
      .then(
        ([
          currencies,
          metals,
          units,
          vaults,
          issuers,
          listingBrokers,
          liquidityProviders,
          coin,
        ]) => {
          const formData: FormData = coin?.coinInfo
            ? {
                ...coin,
                coinInfo: {
                  ...coin.coinInfo,
                  liquidityProviders: coin.coinInfo.liquidityProviders.map(({ id }) => id),
                },
              }
            : {
                coinInfo: {
                  denominationUnit: 1,
                  weighMeasurement: { id: units[0].id },
                  physicalRedemption: true,
                  primaryMarketProductInfo: '',
                },
                productInfo: '',
                positionDays: 10,
                positionPercent: 15,
              };

          formContext.dispatch({
            type: 'FORM_INITIATED',
            payload: {
              formData,
              currencies,
              issuers,
              listingBrokers,
              liquidityProviders,
              metals,
              units,
              vaults,
            },
          });
          productInfoContext.dispatch({
            type: 'FORM_INITIATED',
            payload: {
              infoFiles: coin?.infoFiles ?? [],
              primaryMarketProductInfo: coin?.coinInfo?.primaryMarketProductInfo ?? '',
              productInfo: coin?.productInfo ?? '',
            },
          });
          techInfoContext.dispatch({
            type: 'FORM_INITIATED',
            payload: { data: formData },
          });
        }
      )
      .catch((e) => {
        if (e instanceof TimeoutError) {
          props.onInitialFetchTimeoutError?.();
          return;
        }
        handleBackendError(e);
      });
  }, []);

  // Prevent productInfoContext from rerendering form.
  return useMemo(
    () => (
      <Form name={formId} initialValues={formContext.state.data.formData} onChange={onFormChange}>
        <Tabs
          activeKey={activeTab}
          border
          data={tabsHeaderData}
          forceRender
          onChange={onTabKeyChange}
          paneWrapperComponent={ForcedRenderPaneWrapper}
          tabHeaderComponent={TabHeader}
        >
          <TabPane key={tabId.general} tabKey={tabId.general}>
            <GeneralTab />
          </TabPane>
          <TabPane key={tabId.position} tabKey={tabId.position}>
            <PositionTab />
          </TabPane>
          <TabPane key={tabId.redemption} tabKey={tabId.redemption}>
            <RedemptionTab
              addBtnContainer={activeTab === tabId.redemption ? submitRowLeftBtnRef.current : null}
            />
          </TabPane>
          <TabPane key={tabId.productInfo} tabKey={tabId.productInfo}>
            <ProductInfoTab />
          </TabPane>
          <TabPane key={tabId.fees} tabKey={tabId.fees}>
            <FeesTab />
          </TabPane>
          <TabPane key={tabId.techInfo} tabKey={tabId.techInfo}>
            <TechInfoTab
              addBtnContainer={activeTab === tabId.techInfo ? submitRowLeftBtnRef.current : null}
              isEditMode={isEditMode}
            />
          </TabPane>
        </Tabs>

        <div className={s.submitRow}>
          <div ref={submitRowLeftBtnRef} />
          <SubmitButton
            className={'ml6'}
            name={formId}
            onAction={onFormAction}
            disabled={formContext.state.lockUI}
          >
            <Trans id="button.save">Save</Trans>
          </SubmitButton>
        </div>
      </Form>
    ),
    [activeTab, tabsHeaderData, onFormAction, formContext.state.lockUI]
  );
}
