import React from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { PermissionGroup } from '@permissions/permission-group';
import { Form, InputDate, InputNumber, InputText, Select, SubmitButton } from '@components/form';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { CurrencySelect } from '@modules/currencies';
import { maxDecimals } from '@constants/math';
import { Divider } from '@components/divider';
import { CMTransactionType } from '../../hooks';
import { AccountInfo } from '../shared/account-info';
import { permissionsGroup as confirmPermissionsGroup } from '../confirm-transaction';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { Fields, formId, useModel } from './model';

export const permissionsGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionsGroup, confirmPermissionsGroup],
  marker: 'layout:cm-create-transaction-modal',
});

export interface CreateCMTransactionModalProps {
  onTransactionCreated: (id: number) => void;
}
export const CreateCMTransactionModal: MFC<CreateCMTransactionModalProps> = (p) => {
  const m = useModel(CreateCMTransactionModal, p.onTransactionCreated);

  return (
    <Modal
      size="big"
      isVisible={p.isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
      onClose={m.onCloseModalClicked}
      uat="cm-create-transaction-modal"
    >
      <ModalTitle>
        <Trans id="cm_create_tx_modal.title">Add transaction</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId} initialValues={m.initialValues}>
          <div className={s.row}>
            <Select
              className={s.rowField}
              label={<Trans id="cm_transaction_modal.form.label.tx_type">Transaction type</Trans>}
              name={Fields.type}
              disabled={m.lockUI}
              data={m.transactionTypes}
              onChange={(type: CMTransactionType) => m.onTxTypeChanged(type)}
              required
            />

            <CurrencySelect
              label={
                <Trans id="cm_transaction_modal.form.label.currency">Transaction currency</Trans>
              }
              className={s.rowField}
              name={Fields.currency}
              onChange={(id: number) => m.onCurrencySelected(id)}
              disabled={m.lockUI}
              required
            />
          </div>

          {!m.isShowSender ? null : (
            <>
              <Select
                className={s.rowField}
                label={<Trans id="cm_transaction_modal.form.label.sender">Sender</Trans>}
                name={Fields.senderId}
                disabled={m.lockUI}
                data={m.bankAccounts}
                onChange={(id: number) => m.onSenderChanged(id)}
                required
              />

              <AccountInfo account={m.sender} />
              {!m.sender ? null : <Divider className="mb3" />}
            </>
          )}

          {!m.isShowReceiver ? null : (
            <>
              <Select
                label={<Trans id="cm_transaction_modal.form.label.receiver">Receiver</Trans>}
                className={s.rowField}
                name={Fields.receiverId}
                disabled={m.lockUI}
                data={m.bankAccounts}
                onChange={(id: number) => m.onReceiverChanged(id)}
                required
              />

              <AccountInfo account={m.receiver} />
              {!m.receiver ? null : <Divider className="mb3" />}
            </>
          )}

          <div className={s.row}>
            <InputText
              label={<Trans id="cm_transaction_modal.form.label.external_id">External ID</Trans>}
              className={s.rowField}
              name={Fields.externalId}
              disabled={m.lockUI}
              required
            />

            <InputDate
              label={
                <Trans id="cm_transaction_modal.form.label.val_date_time">Value date & time</Trans>
              }
              className={s.rowField}
              name={Fields.valueDateTime}
              disabled={m.lockUI}
              max={new Date().toISOString()}
              showTime={true}
              showNow={true}
            />

            <InputDate
              label={
                <Trans id="cm_transaction_modal.form.label.tx_date_time">
                  Transaction date & time
                </Trans>
              }
              className={s.rowField}
              name={Fields.transactionDateTime}
              disabled={m.lockUI}
              max={new Date().toISOString()}
              showTime={true}
              showNow={true}
            />

            <InputText
              label={<Trans id="cm_transaction_modal.form.label.method">Method</Trans>}
              className={s.rowField}
              name={Fields.method}
              disabled={m.lockUI}
            />

            <InputNumber
              label={<Trans id="cm_transaction_modal.form.label.amount">Amount</Trans>}
              className={s.rowField}
              name={Fields.amount}
              disabled={m.lockUI}
              min={0}
              maxDecimals={maxDecimals}
              required
            />

            <InputText
              label={<Trans id="cm_transaction_modal.form.label.purpose">Purpose</Trans>}
              className={s.rowField}
              name={Fields.purpose}
              disabled={m.lockUI}
            />

            <InputText
              label={<Trans id="cm_transaction_modal.form.label.funds_source">Funds source</Trans>}
              className={s.rowField}
              name={Fields.sourceOfFunds}
              disabled={m.lockUI}
            />

            <InputText
              label={<Trans id="cm_transaction_modal.form.label.comment">Comment</Trans>}
              className={classnames(s.rowField, s.fullWidth)}
              name={Fields.comment}
              disabled={m.lockUI}
            />
          </div>
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} onAction={m.onFormAction} disabled={m.lockUI}>
          <Trans id="button.continue">Continue</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};
