import { useEffect, useMemo } from 'react';

import { useQuery } from '@hooks/core';

import api from './api';

import type { SelectItem } from '@components/form';

export const useStableBalances = (selectedUserId?: number) => {
  const {
    data: stableBalances,
    isLoading: isStableBalancesLoading,
    refresh: refreshStableBalances,
  } = useQuery(
    () => (selectedUserId ? api.getUserStableBalances(selectedUserId) : Promise.resolve([])),
    {
      initial: [],
    }
  );

  useEffect(() => {
    refreshStableBalances();
  }, [selectedUserId]);

  return {
    isStableBalancesLoading,
    stableBalances,
  };
};

export const useBankAccounts = (selectedUserId?: number) => {
  const {
    data: bankAccountsRaw,
    isLoading: isBankAccountsLoading,
    refresh: refreshBankAccounts,
  } = useQuery(() => (selectedUserId ? api.getBankAccounts(selectedUserId) : Promise.resolve([])), {
    initial: [],
  });

  const bankAccounts: Array<SelectItem> = useMemo(() => {
    return (
      bankAccountsRaw?.map((ba) => ({
        label: `${ba.bankName} (${ba.currency.code})`,
        value: ba.id,
      })) || []
    );
  }, [bankAccountsRaw]);

  useEffect(() => {
    refreshBankAccounts();
  }, [selectedUserId]);

  return {
    bankAccounts,
    bankAccountsRaw,
    isBankAccountsLoading,
  };
};

export const useCurrenciesOther = () => {
  const { data: currenciesRaw, isLoading: isCurrenciesLoading } = useQuery(api.getCurrenciesOther, {
    initial: [],
  });

  const currencies: Array<SelectItem> | undefined = useMemo(() => {
    return (
      currenciesRaw?.map((currency) => ({
        label: currency.code,
        value: currency.id,
      })) || []
    );
  }, [currenciesRaw]);

  return { currencies, isCurrenciesLoading };
};

export const useUserDisplayName = (selectedUserId?: number) => {
  const {
    data: userDisplayName,
    isLoading: isUserDisplayNameLoading,
    refresh: refreshDisplayName,
  } = useQuery(
    () => (selectedUserId ? api.getUserDisplayName(selectedUserId) : Promise.resolve('')),
    {
      initial: '',
    }
  );

  useEffect(() => {
    refreshDisplayName();
  }, [selectedUserId]);

  return {
    userDisplayName,
    isUserDisplayNameLoading,
  };
};
