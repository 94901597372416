import React, { ChangeEvent, FC, ReactNode, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { Form as AntForm } from 'antd';
import { defineMessage } from '@lingui/macro';
import { NamePath } from 'rc-field-form/lib/interface';

import s from './index.module.scss';
import { dater } from '@helper/date';
import { useI18n } from '@hooks/i18n';
import { Label } from '../label';
import { useFieldUat } from '../uat';

const { Item } = AntForm;

export const InputDateSimple: FC<{
  className?: string;
  disabled?: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (date: string) => void;
  required?: boolean;
  uat?: string;
}> = ({ className, disabled, label, name, onChange, required, uat, ...props }) => {
  const uatAttribute = useFieldUat(name, 'date-simple', uat);

  return (
    <div className={classnames('tm2-form-field tm2-field-text', className)} data-uat={uatAttribute}>
      <Item className={classnames('tm2-form-field-item')} name={name} required={required}>
        <CustomDate
          name={name}
          label={label}
          onCustomChange={onChange}
          required={required}
          disabled={disabled}
          {...props}
        />
      </Item>
    </div>
  );
};

const inputClassMarker = 'tm2-simple-date-technical-marker';
const Input: FC<{
  className: string;
  disabled: boolean;
  name: string;
  max: number;
  min: number;
  onChange: (v: string) => void;
  placeholder: string;
  placeholderFocused: string;
  value: string;
}> = ({
  disabled,
  max,
  min,
  onChange: onExternalChange,
  placeholder,
  placeholderFocused,
  value,
  ...props
}) => {
  const [valueLocal, setValueLocal] = useState<string>('');
  const [isFocused, setFocused] = useState<boolean>(false);

  useEffect(() => setValueLocal(value), [value]);

  const onLocalChange = useCallback(
    (v: string) => {
      setValueLocal(v);
      onExternalChange(v);
    },
    [onExternalChange, setValueLocal]
  );

  const onKeyDown = useCallback(
    (e) => {
      if (!/^[0-9]|ArrowLeft|ArrowRight|Backspace|Delete|Tab$/i.test(e.key)) {
        return e.preventDefault();
      }
      const target: HTMLInputElement = e.currentTarget as HTMLInputElement;
      const selection = window?.getSelection();
      const node: HTMLDivElement = selection?.focusNode as HTMLDivElement;
      const isSelectedTextFromInput: boolean = node?.classList?.contains(inputClassMarker);
      const selectedText: string = selection?.toString();
      const isHasSelectedText = isSelectedTextFromInput && !!selectedText;
      const valueNext: string = target.value;

      if (/^[0-9]$/i.test(e.key)) {
        if (isHasSelectedText) {
          const isHasDuplicates: boolean =
            (valueNext.match(new RegExp(selectedText, 'g')) || []).length > 1;
          if (isHasDuplicates) {
            // этот жесткий костыль нужен потому,
            // что нет технической возможности определить позицию каретки
            target.value = '';
            onLocalChange('');
          } else {
            const valueNew = valueNext.replace(selectedText, e.key);
            if (+valueNew <= max) {
              onLocalChange(valueNew);
            }
          }
          return e.preventDefault();
        } else {
          const valueNewStr = valueNext + e.key; // нужно отдельно, т.к. num 01 превратится в 1 с length === 1
          const valueNewNum = +valueNewStr;
          const isLengthExceeded = valueNewStr.length > String(max).length;
          if ((valueNewNum !== 0 && valueNewNum > max) || isLengthExceeded) {
            return e.preventDefault();
          }
        }
      }
    },
    [onLocalChange]
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onLocalChange(e.currentTarget.value || '');
    },
    [onLocalChange]
  );

  const onBlur = useCallback(() => {
    if (+valueLocal === 0 || +valueLocal < min) {
      onLocalChange('');
    } else if (+valueLocal < 10 && valueLocal.length < 2) {
      onLocalChange(`0${valueLocal}`);
    }
    setFocused(false);
  }, [onLocalChange, setFocused, valueLocal]);

  return (
    <input
      type="number"
      onChange={onChange}
      onPaste={(e) => e.preventDefault()}
      onKeyDown={onKeyDown}
      onFocus={() => setFocused(true)}
      onBlur={onBlur}
      placeholder={isFocused ? placeholderFocused : placeholder}
      max={max}
      maxLength={max.toString().length}
      min={min}
      disabled={disabled}
      value={value}
      {...props}
    />
  );
};

const CustomDate: FC<{
  disabled: boolean;
  label: ReactNode;
  name: NamePath;
  onChange?: (date: string) => void;
  onCustomChange?: (date: string) => void;
  required: boolean;
  value?: string;
}> = (_props) => {
  const { disabled, label, name, onChange, onCustomChange, required, value } = _props;

  const { i18n } = useI18n();
  const [day, setDay] = useState<string>('');
  const [month, setMonth] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const [yearMax] = useState<number>(new Date().getFullYear());
  const yearMin = yearMax - 130;

  useEffect(() => {
    if (!value || value === 'Invalid date') {
      return;
    }

    const date = new Date(value);
    if (value.toString() === 'Invalid date') {
      return;
    }

    const _day = String(date.getDate());
    const _month = String(date.getMonth() + 1);
    const _year = String(date.getFullYear());

    if (year !== _year && month !== _month && day !== _day) {
      setDay(_day);
      setMonth(_month);
      setYear(_year);
    }
  }, [value]);

  useEffect(() => {
    // без таймаута этот эффект будет ресетить initial value
    setTimeout(() => {
      let date: string;
      if (!day || !month || !year) {
        date = undefined;
      } else if (+day > 0 && +month > 0 && +year > yearMin) {
        date = dater.createGqlDate(+year, +month, +day);
      } else {
        date = 'Invalid date';
      }
      onChange(date);
      onCustomChange && onCustomChange(date);
    });
  }, [day, month, year]);

  return (
    <>
      <Label htmlFor={name} shifted={true} label={label} required={required} />

      <div className="ant-form-item-control-input">
        <div
          className={classnames(
            s.wrapper,
            inputClassMarker,
            'ant-input tm2-form-field-item-instance',
            disabled && 'ant-input-disabled',
            disabled && s.disabled
          )}
        >
          <Input
            className={s.input}
            onChange={setDay}
            value={day}
            name="day"
            max={31}
            min={1}
            placeholder={i18n._(
              defineMessage({ id: 'shared.form.datepicker.day', message: 'Day' })
            )}
            placeholderFocused="DD"
            disabled={disabled}
            data-uat="day"
          />

          <Input
            className={s.input}
            onChange={setMonth}
            value={month}
            name="month"
            max={12}
            min={1}
            placeholder={i18n._(
              defineMessage({ id: 'shared.form.datepicker.month', message: 'Month' })
            )}
            placeholderFocused="MM"
            disabled={disabled}
            data-uat="month"
          />

          <Input
            className={s.input}
            onChange={setYear}
            value={year}
            name="year"
            max={yearMax}
            min={yearMin}
            placeholder={i18n._(
              defineMessage({ id: 'shared.form.datepicker.year', message: 'Year' })
            )}
            placeholderFocused="YYYY"
            disabled={disabled}
            data-uat="year"
          />
        </div>
      </div>
    </>
  );
};
