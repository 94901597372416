import React, { useCallback, useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { useCallDispatch } from '@hooks/core';
import { configSelectors } from '@config/core';
import { handleBackendError, notifySuccess } from '@modules/notify';
import usersApi, { UserTableItem } from '../../../../users-api.service';
import { usersPageServiceActions as actions } from '../../../store/service.acions';
import api from './api';

export const useAccessActions = (user: UserTableItem) => {
  const [lockUI, setLockUI] = useState<boolean>();
  const isAdmin = useSelector(configSelectors.isAdmin);

  const emitUserUpdated = useCallDispatch(actions.eventUsersUpdated);

  const onToggleBlockUser = useCallback(
    async (user: UserTableItem) => {
      setLockUI(true);
      try {
        if (user.isActive) {
          await api.blockUser(user.id);
          notifySuccess({
            defaultTitle: true,
            text: <Trans id="users_service.notification.user_blocked">The user is blocked</Trans>,
          });
        } else {
          await api.unblockUser(user.id);
          notifySuccess({
            defaultTitle: true,
            text: (
              <Trans id="users_service.notification.user_unblocked">The user is unblocked</Trans>
            ),
          });
        }
        const userUpdated = await usersApi.getUpdatedUserTableInfo(user.id);
        emitUserUpdated({ isNewUser: false, isNoScroll: true, user: userUpdated });
      } catch (error) {
        handleBackendError(error);
      }
      setLockUI(false);
    },
    [emitUserUpdated]
  );

  const blockAction = useMemo(() => {
    return !(isAdmin && !!user.isActive)
      ? null
      : {
          action: () => onToggleBlockUser(user),
          content: <Trans id="users.table.action.block">Block</Trans>,
        };
  }, [isAdmin, onToggleBlockUser, user]);

  const unblockAction = useMemo(() => {
    return !(isAdmin && !user.isActive)
      ? null
      : {
          action: () => onToggleBlockUser(user),
          content: <Trans id="users.table.action.unblock">Unblock</Trans>,
        };
  }, [isAdmin, onToggleBlockUser, user]);

  return {
    blockAction,
    lockUI,
    unblockAction,
  };
};
