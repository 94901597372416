import sign from 'tm2sign.macro';

import { PermissionGroup, makeQuery, Permission } from '@permissions/core';
import { fileService } from '@helper/file';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

const uploadReportXlsFields = ['value'];
const uploadPaymentsReportQuery = 'uploadPayrexxPaymentReport';
const uploadPaymentsReport = makeQuery({
  permissions: { or: [Permission.PAYMENTS_VIEW_REPORT] },
  queryName: uploadPaymentsReportQuery,
  queryFields: uploadReportXlsFields,
  query: async (file: File): Promise<void> => {
    const fileAsString: string = await fileService.toBase64(file);
    return stompClientService.sendData(
      uploadPaymentsReportQuery,
      sign(uploadPaymentsReportQuery, uploadReportXlsFields),
      v.string({ fileAsString })
    );
  },
});

const api = {
  uploadPaymentsReport,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:payments-report-upload');

export default api;
