import React, { FC } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';

export const Title: FC<{
  className?: string;
  color?: 'primary' | 'secondary';
  compact?: boolean;
  level: 1 | 2 | 3 | 4;
  uat?: string;
}> = ({ children, className, color = 'primary', compact = false, level, uat }) => {
  return (
    <div
      className={classnames(
        {
          mb1: !compact, // now all title levels has same default margin
          [s.h1]: level === 1,
          [s.h2]: level === 2,
          [s.h3]: level === 3,
          [s.h4]: level === 4,
          [s.colorPrimary]: color === 'primary',
          [s.colorSecondary]: color === 'secondary',
        },
        className
      )}
      data-uat={uat}
    >
      {children}
    </div>
  );
};
