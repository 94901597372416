import React from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Text } from '@components/typography/text';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle, TitleIcon } from '@modules/modal';
import { ButtonPrimary, ButtonSecondary } from '@components/button';
import { VerificationStatus as S } from '@models/core';
import { VerifyModalButton } from '../../../post-registration/individual-steps/verification-upload/button';
import { useModel } from './model';

export const CompleteVerificationModal: MFC = ({ isVisible }) => {
  const m = useModel(CompleteVerificationModal);

  return (
    <Modal isVisible={isVisible} onClose={m.onCloseModal}>
      <ModalTitle icon={<TitleIcon type="fail" />}>
        {m.isIndividualTrader ? (
          <Trans id="complete_verification_modal.individual.title">
            The action is not permitted
          </Trans>
        ) : (
          <Trans id="complete_verification_modal.institutional.title">
            Please complete verification
          </Trans>
        )}
      </ModalTitle>

      {!m.isIndividualTrader ? null : (
        <ModalContent>
          {m.status !== S.documentRequested ? null : (
            <Text>
              <Trans id="complete_verification_modal.individual.text.docs_requested">
                This action is not available for your account type, verify the account
              </Trans>
            </Text>
          )}

          {m.status !== S.processingVerification ? null : (
            <Text>
              <Trans id="complete_verification_modal.individual.text.action_for_verified">
                This action is available only for verified accounts.
              </Trans>
              <span className={s.textSeparator} />
              <Trans id="complete_verification_modal.individual.text.docs_processing_1">
                Your documents are being checked. It may take up to 3 business days.
              </Trans>
              <span className={s.textSeparator} />
              <Trans id="complete_verification_modal.individual.text.contact_support">
                If the problem persists, please contact{' '}
                <a href="mailto:support@tm2.com">support@tm2.com</a>.
              </Trans>
            </Text>
          )}

          {m.status !== S.resubmissionRequested ? null : (
            <>
              <Text>
                <Trans id="complete_verification_modal.individual.text.docs_resubmission_1">
                  This action is not available for your account type until you finish verification
                  process.
                </Trans>
              </Text>
              <span className={s.textSeparator} />
              <Text className={s.alert}>
                <Trans id="complete_verification_modal.individual.text.docs_resubmission_2">
                  Your documents do not meet the requirements, please upload the documents that fit
                  the requirements.
                </Trans>
              </Text>
              <span className={s.textSeparator} />
              <Text>
                <Trans id="complete_verification_modal.individual.text.contact_support">
                  If the problem persists, please contact{' '}
                  <a href="mailto:support@tm2.com">support@tm2.com</a>.
                </Trans>
              </Text>
            </>
          )}

          {m.status !== S.rejected ? null : (
            <>
              <Text className={s.alert}>
                <Trans id="complete_verification_modal.individual.text.action_for_verified">
                  This action is available only for verified accounts.
                </Trans>
              </Text>
              <span className={s.textSeparator} />
              <Text>
                <Trans id="complete_verification_modal.individual.text.docs_rejected_2">
                  Your documents were rejected.
                </Trans>
              </Text>
              <span className={s.textSeparator} />
              <Text>
                <Trans id="complete_verification_modal.individual.text.docs_rejected_3">
                  If you do not agree, please contact{' '}
                  <a href="mailto:support@tm2.com">support@tm2.com</a>.
                </Trans>
              </Text>
            </>
          )}

          <VerifyModalButton
            className="mt3"
            fullWidth={true}
            isClosePreviousModal={true}
            visibility="all"
            uat="verify-button"
          />

          {![S.documentRequested, S.processingVerification, S.resubmissionRequested].includes(
            m.status
          ) ? null : (
            <ButtonSecondary className="mt3" fullWidth={true} onClick={m.onCloseModal}>
              {m.status === S.processingVerification ? (
                <Trans id="complete_verification_modal.button">OK</Trans>
              ) : (
                <Trans id="complete_verification_modal.individual.button.skip">Later</Trans>
              )}
            </ButtonSecondary>
          )}
        </ModalContent>
      )}

      {m.isIndividualTrader ? null : (
        <>
          <ModalContent>
            <Text>
              <Trans id="complete_verification_modal.institutional.text">
                You need to complete the verification process prior to using this functionality.
                Kindly contact <a href="mailto:support@tm2.com">support@tm2.com</a> to proceed,
                thank you!
              </Trans>
            </Text>
          </ModalContent>

          <ModalFooter>
            <ButtonPrimary fullWidth={true} onClick={m.onCloseModal}>
              <Trans id="complete_verification_modal.button">OK</Trans>
            </ButtonPrimary>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
