import React, { useState } from 'react';
import { Trans } from '@lingui/macro';

import v from '@components/form/validators';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { notifySuccess } from '@modules/notify';
import { useAccountFormInitial, useBankAccountTypes, useBanks } from './hooks';
import api, { BankAccountFields as Fields, BankAccountForm as Form } from './api';

export { BankAccountFields as Fields } from './api';
export const formId = 'handleCMBankAccountFormId';

export const useModal = (modal, id: number, onAccountUpdated: (id: number) => void) => {
  const closeModal = useCallDispatch(closeModalAction);
  const [isHandleAccountLoading, setHandleAccountLoading] = useState<boolean>(false);
  const { initialAccount, isInitialAccountLoading } = useAccountFormInitial(id);
  const { bankAccountTypes, isBankAccountTypesLoading } = useBankAccountTypes();
  const { banks, isBanksLoading } = useBanks();

  const onFormAction: OnFormAction<Form> = {
    error: () => setHandleAccountLoading(false),
    fieldValidation: {
      [Fields.accountNumber]: [v.required],
      [Fields.bank]: [v.required],
      [Fields.bankAccountType]: [v.required],
      [Fields.currentBalance]: [v.required],
      [Fields.currency]: [v.required],
      [Fields.legalEntity]: [v.required],
    },
    submit: async (form) => {
      setHandleAccountLoading(true);
      const _id = !id ? await api.createBankAccount(form) : await api.editBankAccount(form);
      onAccountUpdated(_id);
      closeModal();
      notifySuccess({
        defaultTitle: true,
        text: <Trans id="shared.notification.35.text">The action was successfully completed</Trans>,
      });
    },
  };

  return {
    bankAccountTypes,
    banks,
    initialValues: initialAccount,
    lockUI:
      isHandleAccountLoading ||
      isInitialAccountLoading ||
      isBankAccountTypesLoading ||
      isBanksLoading,
    onCloseModalClicked: () => closeModal(modal),
    onFormAction,
  };
};
