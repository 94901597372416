import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { ButtonDownload } from '@components/button/download';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { SearchBox } from '@components/search';
import { permissionGroup as apiPermissionGroup } from './api';
import { useModel } from './model';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:download-bank-account-history',
});

export const DownloadBalancesHistory: FC<{ className: string }> = (p) => {
  const m = useModel();

  return (
    <SearchBox className={p.className}>
      <Tooltip
        placement="bottom"
        trigger={TooltipTrigger.hover}
        title={
          <Trans id="bank_account_history.action_row.download">
            Download bank account balance history report xls
          </Trans>
        }
      >
        <ButtonDownload onClick={m.onDownloadClicked} compact="all" disabled={m.lockUI} />
      </Tooltip>
    </SearchBox>
  );
};
