import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
// ------------------------------------------------------------------------------------
export interface BankAccount {
  displayName: string;
  id: number;
}
const getBankAccountsQuery = 'externalBankTransactionsFilterContent';
const getBankAccountsFields = ['displayName', 'id'];
const getBankAccounts = makeQuery({
  queryName: getBankAccountsQuery,
  queryFields: getBankAccountsFields,
  query: (): Promise<Array<BankAccount>> => {
    return stompClient.getData(
      getBankAccountsQuery,
      sign(getBankAccountsQuery, getBankAccountsFields)
    );
  },
});
// ------------------------------------------------------------------------------------
const api = {
  getBankAccounts,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:external-bank-accounts-filter');

export default api;
