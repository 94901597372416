import moment from 'moment';

import { DATE_FORMAT_GQL } from '@constants/date';

class V {
  assetCreateOnSecondaryInput(object) {
    return this._v(object, 'AssetCreateOnSecondaryInput');
  }

  bidOrderStatus(object) {
    return this._v(object, 'BidOrderStatus');
  }

  bidOrdersPageSelectMode(object) {
    return this._v(object, 'BidOrdersPageSelectMode');
  }

  bigDecimal(object) {
    return this._v(object, 'BigDecimal');
  }

  coinCreateDTOInput(object) {
    return this._v(object, 'CoinCreateDTOInput');
  }

  coinDTOInput(object) {
    return this._v(object, 'CoinDTOInput');
  }

  clientAccountStatementReportParamInput(object) {
    return this._v(object, 'ClientAccountStatementReportParamInput');
  }

  createBankAccountInput(object) {
    return this._v(object, 'CreateBankAccountInput');
  }

  createExtendedTransactionInput(object) {
    return this._v(object, 'CreateExtendedTransactionInput');
  }

  createInvoiceRequestInput(object) {
    return this._v(object, 'CreateInvoiceRequestInput');
  }

  createPaymentRequestInput(object) {
    return this._v(object, 'CreatePaymentRequestInput');
  }

  createTradingAccountRequestInput(object) {
    return this._v(object, 'CreateTradingAccountRequestInput');
  }

  editBankAccountInput(object) {
    return this._v(object, 'EditBankAccountInput');
  }

  exchangeRateRequestInput(object) {
    return this._v(object, 'ExchangeRateRequestInput');
  }

  exchangeRequestInput(object) {
    return this._v(object, 'ExchangeRequestInput');
  }

  externalBankAccountsBalanceType(object) {
    return this._v(object, 'ExternalBankAccountBalanceTypeCode');
  }

  feesReportFilterInput(object) {
    return this._v(object, 'FeesReportFilterInput');
  }

  float(object) {
    return this._v(object, 'Float');
  }

  generalPreferencesInput(object) {
    return this._v(object, 'GeneralPreferencesInput');
  }

  idInput(object) {
    return this._v(object, 'IdInput');
  }

  int(object) {
    return this._v(object, 'Int');
  }

  localDate(object) {
    const fieldName = this._key(object);
    const value = object[fieldName];
    return !value
      ? {}
      : this._v({ [fieldName]: moment(value).format(DATE_FORMAT_GQL) }, 'LocalDate');
  }

  long(object) {
    return this._v(object, 'Long');
  }

  metalInput(array) {
    return this._v(array, '[MetalInput]');
  }

  mapStringObjectScalar(object) {
    return this._v(object, 'Map_String_ObjectScalar');
  }

  newOfferPriceInput(object) {
    return this._v(object, '[NewOfferPriceInput]');
  }

  orderInput(object) {
    return this._v(object, '[OrderInput]');
  }

  companyDetailsInput(object) {
    return this._v(object, 'CompanyDetailsInput');
  }

  contactDetailsInput(object) {
    return this._v(object, 'ContactDetailsInput');
  }

  createOfferDtoInput(object) {
    return this._v(object, 'CreateOfferDTOInput');
  }

  profileDTOInput(object) {
    return this._v(object, 'ProfileDTOInput');
  }

  provideContactInformationInput(object) {
    return this._v(object, 'ProvideContactInformationInput');
  }

  provideAddressInformationInput(object) {
    return this._v(object, 'ProvideAddressInformationInput');
  }

  saveUserByAdminDTOInput(object) {
    return this._v(object, 'SaveUserByAdminDTOInput');
  }

  saveVaultBalanceInput(object) {
    return this._v(object, 'SaveVaultBalanceInput');
  }

  saveVaultInput(object) {
    return this._v(object, 'SaveVaultInput');
  }

  string(object) {
    return this._v(object, 'String');
  }

  stringArray(object) {
    return this._v(object, '[String]');
  }

  userBankAccountCreateDTOInput(object) {
    return this._v(object, 'UserBankAccountCreateDTOInput');
  }

  marketingCompanyDTOInput(object) {
    return this._v(object, 'MarketingCompanyDTOInput');
  }

  secondaryFeesInput(object) {
    return this._v(object, 'SecondaryFeesInput');
  }

  txFilterInput(object) {
    return this._v(object, 'TxFilterInput');
  }

  spcOrderInput(object) {
    return this._v(object, 'SpcOrderInput');
  }

  sendToInvestorAccountRequestInput(object) {
    return this._v(object, 'SendToInvestorAccountRequestInput');
  }

  settlementInput(object) {
    return this._v(object, 'SettlementInput');
  }

  offerInput(object) {
    return this._v(object, 'OfferInput');
  }

  offerPageSelectMode(object) {
    return this._v(object, 'OfferPageSelectMode');
  }

  offerStatus(object) {
    return this._v(object, 'OfferStatus');
  }

  rangeStringInput(object) {
    return this._v(object, 'Range_StringInput');
  }

  updatePasswordInput(object) {
    return this._v(object, 'UpdatePasswordInput');
  }

  verificationDocumentUploadDTOInput(object) {
    return this._v(object, '[VerificationDocumentUploadDTOInput]');
  }

  verificationDocumentDownloadDTOInput(object) {
    return this._v(object, 'VerificationDocumentDownloadDTOInput');
  }

  withdrawalCreateDTOInput(object) {
    return this._v(object, 'WithdrawalCreateDTOInput');
  }

  webEventInput(object) {
    return this._v(object, 'WebEventInput');
  }

  withdrawalStatus(object) {
    return this._v(object, 'WithdrawalStatus');
  }

  updateVerificationStatusDTOInput(object) {
    return this._v(object, 'UpdateVerificationStatusDTOInput');
  }

  exchangeNotAvailableInput(object) {
    return this._v(object, 'ExchangeNotAvailableInput');
  }

  _v(valueObject, type: string) {
    const v = (valueObject, type) => ({
      [this._key(valueObject)]: { value: valueObject[this._key(valueObject)], type },
    });
    return { ...v(valueObject, type) };
  }

  _key(object) {
    return Object.keys(object)[0];
  }
}

export const v = new V();
