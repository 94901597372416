import { Trans } from '@lingui/macro';
import React from 'react';

// TODO: text and translation
export function DefaultEmptyDataMessage() {
  return (
    <Trans id="reusable.table.no_results_default">
      You have no documents, records or history in this section
    </Trans>
  );
}
