import sign from 'tm2sign.macro';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import { makeQuery, PermissionGroup } from '@permissions/core';
import { Deposit } from '../api';

const markDepositAsPaidQuery = 'invoicePaid';
const markDepositAsPaidFields = [
  'id',
  'number',
  'status',
  'amount',
  'creationDate',
  'clientComment',
  'externalId',
  'bankName',
  'purpose',
  'sourceOfFunds',
  'reference',
  { investorUser: ['displayName', 'id'] },
  { payment: ['amount', 'accountDetails', 'creationDate'] },
  { bankAccount: ['id', { currency: ['code'] }] },
];
const markDepositAsPaid = makeQuery({
  queryName: markDepositAsPaidQuery,
  queryFields: markDepositAsPaidFields,
  query: ({
    id,
    amount,
    accountDetails,
  }: {
    id: number;
    amount: number;
    accountDetails: string;
  }): Promise<Deposit> => {
    const typedValues = {
      ...v.bigDecimal({ amount }),
      accountDetails,
      ...v.long({ id }),
    };

    return stompClient.sendData(
      markDepositAsPaidQuery,
      sign(markDepositAsPaidQuery, markDepositAsPaidFields),
      typedValues
    );
  },
});

const api = {
  markDepositAsPaid,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:deposit-paid-modal');

export default api;
