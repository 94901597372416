import { HeadCellBox } from './cell-box';
import { HeadCellSorter } from './cell-sorter';
import type { HeadCellRendererProps } from '../types';

export function HeadCellRenderer(props: HeadCellRendererProps) {
  return (
    <HeadCellBox alignment={props.alignment} width={props.width}>
      <HeadCellSorter
        availableSortDirections={props.availableSortDirections}
        currentSortDirection={props.currentSortDirection}
        sortOrderNumber={props.sortOrderNumber}
        onSortClick={props.onSortClick}
      >
        {props.children}
      </HeadCellSorter>
    </HeadCellBox>
  );
}
