import React, { useState } from 'react';
import { Trans } from '@lingui/macro';
import { useSelector } from 'react-redux';

import { useCallDispatch } from '@hooks/core';
import { closeModalAction } from '@modules/modal';
import { OnFormAction } from '@components/form';
import { countriesSelectors } from '@modules/countries';
import { handleBackendError, notifySuccess } from '@modules/notify';

import { useInitialValues, useVaultManagers } from './hooks';
import api, { Form } from './api';
import { vaultsPageServiceActions } from '../page/store/service.acions';

export type { Form } from './api';
export { Fields } from './api';

export const formId = 'handleVault';

export const useModel = (vaultId, modal) => {
  const [isVaultSaveLoading, setVaultSaveLoading] = useState<boolean>(false);
  const { vaultManagers, isVaultManagersLoading } = useVaultManagers();
  const { initialValues, isInitialValuesLoading } = useInitialValues(vaultId);
  const isCountriesLoading = useSelector(countriesSelectors.isLoading);

  const onClose = useCallDispatch(closeModalAction);
  const lockUI =
    isCountriesLoading || isVaultManagersLoading || isVaultSaveLoading || isInitialValuesLoading;

  const eventVaultUpdated = useCallDispatch(vaultsPageServiceActions.eventVaultUpdated);

  const onFormAction: OnFormAction<Form> = {
    error: (error) => {
      setVaultSaveLoading(false);
      handleBackendError(error);
    },
    submit: async (form) => {
      setVaultSaveLoading(true);
      const savedVault = await api.saveVault(form, vaultId);
      eventVaultUpdated({ vault: savedVault, isNewVault: !vaultId });
      setVaultSaveLoading(false);
      onClose(modal);
      notifySuccess({
        defaultTitle: true,
        text: <Trans id="vault_service.notification.vault_saved">Vault saved successfully</Trans>,
      });
    },
  };

  return {
    initialValues,
    lockUI,
    onClose,
    onFormAction,
    vaultManagers,
  };
};
