import { useEffect } from 'react';
import classnames from 'classnames';

import s from './portfolio.module.scss';
import icon from '@styles/icon.module.scss';
import { LiveUpdateEventType as LiveEvent, useLiveUpdate } from '@services/stomp/live-update';
import { useSetStableBalance } from 'containers/pages/primary-market/detailed/provider';
import {
  useCoinBalance,
  usePortfolioHeight,
  useStableBalance,
} from 'containers/pages/primary-market/detailed/service';

export const useBuyMetalsPortfolioModel = () => {
  const stableBalanceResponse = useStableBalance();
  const setStableBalance = useSetStableBalance();
  useEffect(() => setStableBalance(stableBalanceResponse), [stableBalanceResponse]);

  const [stableBalance, isStableBalanceLoading, refreshBalances] = stableBalanceResponse;
  const [coinBalance, isCoinBalanceLoading, refreshCoinBalance] = useCoinBalance();

  useLiveUpdate(
    (event) => {
      if (event.type === LiveEvent.newTransaction) {
        refreshCoinBalance();
      } else if (event.type === LiveEvent.stableBalanceChanged) {
        refreshBalances();
      }
    },
    [refreshBalances, refreshCoinBalance]
  );

  const height = usePortfolioHeight();

  const getFlagClass = (currencyCode) =>
    classnames(
      s.icon,
      currencyCode === 'CHF' && icon.flagCH,
      currencyCode === 'USD' && icon.flagUS,
      currencyCode === 'EUR' && icon.flagEU,
      currencyCode === 'GBP' && icon.flagGB,
      currencyCode === 'CAD' && icon.flagCA,
      currencyCode === 'CNY' && icon.flagCN
    );

  return {
    coinBalance,
    getFlagClass,
    isCoinBalanceLoading,
    height,
    stableBalance,
    isStableBalanceLoading,
  };
};
