import { useLayoutEffect, useRef } from 'react';

export const useIsMountedRef = () => {
  const isMountedRef = useRef(null);
  useLayoutEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
};
