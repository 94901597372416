import { useRef } from 'react';
import { useCallbackOne } from 'use-memo-one';

import type { RefObject } from 'react';

// https://react-virtual.tanstack.com/examples/smooth-scroll

function easeInOutQuint(t: number) {
  return t < 0.5 ? 16 * t ** 5 : 1 + 16 * (--t) ** 5;
}

export function useSmoothScroll(scrollableRef: RefObject<HTMLElement>) {
  const scrollingRef = useRef(0);

  return useCallbackOne((offset: number) => {
    if (!scrollableRef.current) return;

    const duration = 1000;
    const start = scrollableRef.current.scrollTop;
    const startTime = (scrollingRef.current = Date.now());

    const run = () => {
      if (scrollingRef.current !== startTime) return;
      const now = Date.now();
      const elapsed = now - startTime;
      const progress = easeInOutQuint(Math.min(elapsed / duration, 1));
      const interpolated = start + (offset - start) * progress;

      if (elapsed < duration) {
        scrollableRef.current?.scrollTo({ top: interpolated });
        requestAnimationFrame(run);
      } else {
        scrollableRef.current?.scrollTo({ top: interpolated });
      }
    };
    requestAnimationFrame(run);
  }, []);
}
