import sign from 'tm2sign.macro';

import { stompClientService } from '@services/stomp/client';
import { currenciesFilter } from '@hot-fix/currency';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { BankAccountItem } from '../../../api.shared';
import { bankAccountsFields } from '../../../fields.shared';

// ----------------------------------------------------------------
const getBankAccountsQuery = 'userBankAccounts';
const getBankAccounts = makeQuery({
  permissions: { and: [Permission.BANK_ACCOUNTS_VIEW_MY] },
  queryName: getBankAccountsQuery,
  queryFields: bankAccountsFields,
  query: (): Promise<Array<BankAccountItem>> => {
    return stompClientService
      .getData<Array<BankAccountItem>>(
        getBankAccountsQuery,
        sign(getBankAccountsQuery, bankAccountsFields)
      )
      .then((accounts) => accounts.filter((account) => currenciesFilter(account.currency)));
  },
});
// ----------------------------------------------------------------

const api = {
  getBankAccounts,
};

export const permissionsGroup = PermissionGroup.extract(
  api,
  'api:profile-my-page-bank-accounts-tab'
);

export default api;
