// style variables
/**
 * Hack to recolor all project icons
 */
export const maskCfgDark = {
  color: 'var(--tm2-white)',
};

/**
 * Available color theme types
 */
export enum theme {
  dark = 'dark',
  light = 'light',
}

/**
 * Available grid breakpoints
 */
export enum breakpoints {
  xss = 'xss',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
  xxl = 'xxl',
}

export interface GridPattern {
  xss: number;
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
  xxl: number;
}

/**
 * Grid breakpoints values
 */
export const gridValues: GridPattern = {
  xss: 360,
  xs: 480,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};
