import sign from 'tm2sign.macro';
import { makeQuery } from '@permissions/core';
import { stompClientService } from '@services/stomp/client';

export type GeneralPreferences = {
  systemNotification: {
    active: boolean;
    text: string;
  };
};
const getSystemNotificationQuery = 'generalPreferences';
const getSystemNotificationFields = [{ systemNotification: ['active', 'text'] }];
const getGeneralPreferences = makeQuery({
  queryName: getSystemNotificationQuery,
  queryFields: getSystemNotificationFields,
  query: (): Promise<GeneralPreferences> => {
    return stompClientService.getData(
      getSystemNotificationQuery,
      sign(getSystemNotificationQuery, getSystemNotificationFields)
    );
  },
});

const api = {
  getGeneralPreferences,
};

export default api;
