import { RedeemOptionsModalActionsTypes as Types } from './model';

const GetRedeemOptionsAction = (coinId) => ({
  payload: coinId,
  type: Types.GET_REDEEM_OPTIONS,
});

const GetRedeemOptionsFailAction = (error) => ({
  payload: error,
  type: Types.GET_REDEEM_OPTIONS_FAIL,
});

const GetRedeemOptionsSuccessAction = (optionsList) => ({
  payload: optionsList,
  type: Types.GET_REDEEM_OPTIONS_SUCCESS,
});

export const redeemOptionsModalServiceActions = {
  getRedeemOptions: GetRedeemOptionsAction,
  getRedeemOptionsFail: GetRedeemOptionsFailAction,
  getRedeemOptionsSuccess: GetRedeemOptionsSuccessAction,
};
