import { useQuery } from '@hooks/core';
import api from './api';

export const useEditProfileInfo = () => {
  const { data: profileInitial, isLoading: isProfileLoading } = useQuery(api.getEditProfileInfo);
  return {
    isProfileLoading,
    profileInitial,
  };
};
