import React, { memo } from 'react';
import classnames from 'classnames';
import { Trans } from '@lingui/macro';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { Form, InputNumber, Select, SubmitButton } from '@components/form';
import { Fields, formId, useModel } from './model';
import { permissionGroup as apiPermissionGroup, CreatedCorrectionTransaction } from './api';
import s from './index.module.scss';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:add-correction-transaction-modal',
});

export const AddCorrectionTransactionModal: MFC<{
  replaceTransaction: (ct: CreatedCorrectionTransaction) => void;
}> = memo(({ isVisible, replaceTransaction }) => {
  const m = useModel(replaceTransaction, AddCorrectionTransactionModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      onClose={() => m.onClose(AddCorrectionTransactionModal)}
    >
      <ModalTitle>
        <Trans id="add_settlement.title">Add settlement</Trans>
      </ModalTitle>

      <ModalContent>
        <Form name={formId}>
          <Select
            name={Fields.currencyId}
            label={<Trans id="add_correction.currency">Currency</Trans>}
            data={m.currencies}
            disabled={m.lockUI}
            required={true}
          />

          <div className={classnames('mb1', s.container)}>
            <Select
              name={Fields.senderId}
              label={<Trans id="add_correction.from">From</Trans>}
              data={m.issuers}
              disabled={m.lockUI || !!m.selectedSenderId}
              required={true}
              className={s.element}
              onChange={(senderId) => m.setSelectedSenderId(senderId)}
            />

            <Select
              name={Fields.recipientId}
              label={<Trans id="add_correction.to">To</Trans>}
              data={m.issuers}
              disabled={m.lockUI || !!m.selectedRecipientId}
              required={true}
              className={s.element}
              onChange={(recipientId) => m.setSelectedRecipientId(recipientId)}
            />
          </div>

          <InputNumber
            name={Fields.amount}
            label={<Trans id="add_correction.amount">Amount</Trans>}
            maxDecimals={2}
            disabled={m.lockUI}
            required={true}
          />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          <Trans id="button.add">Add</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
});
