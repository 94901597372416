import React from 'react';
import { Trans } from '@lingui/macro';

import {
  MFC,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitleTabs,
  showModalAction,
} from '@modules/modal';
import { Form, SubmitButton } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { BanksTab } from './banks-tab';
import { GeneralTab } from './general-tab';
import { permissionsGroup as apiPermissionsGroup } from './api';
import { useUserId } from './hooks';
import { Provider } from './context';
import { formId, Tab, useModel, useTabs } from './model';

export const permissionsGroup = apiPermissionsGroup.createSuperGroup({
  marker: 'layout:handle-user-modal',
});

const ConnectedHandleUserModal: MFC<{
  onUserHandled: (id: number) => void;
}> = (p) => {
  const m = useModel(HandleUserModal, p.onUserHandled);
  const t = useTabs();

  return (
    <Modal
      onClose={m.onCloseClicked}
      isVisible={p.isVisible}
      disabled={m.lockUI}
      loading={m.lockUI}
    >
      <ModalTitleTabs
        activeKey={t.activeTab}
        onChange={t.onTabChanged}
        headerContentFit="scroll"
        forceRender={true}
        data={t.headerData}
      />

      <ModalContent>
        <Form name={formId} initialValues={m.initialValues}>
          <GeneralTab hidden={t.activeTab !== Tab.profile} />

          <BanksTab hidden={t.activeTab !== Tab.bankDetails} disabled={m.lockUI} />
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton name={formId} fullWidth={true} disabled={m.lockUI} onAction={m.onFormAction}>
          {m.isNewUser ? (
            <Trans id="user_form.title_add">Add user</Trans>
          ) : (
            <Trans id="user_form.title_edit">Edit user</Trans>
          )}
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
};

export const HandleUserModal: MFC<{
  onUserHandled: (id: number) => void;
}> = (p) => (
  <Provider>
    <ConnectedHandleUserModal isVisible={p.isVisible} onUserHandled={p.onUserHandled} />
  </Provider>
);

// userId is used to detect add new user or edit existing
export const useShowHandleUserModal = (userId: number) => {
  const { setHashUserId } = useUserId();
  const showModal = useCallDispatch(showModalAction);

  const showHandleUserModal = ({ onUserHandled }: { onUserHandled: (id: number) => void }) => {
    setHashUserId(userId);
    showModal({
      modal: HandleUserModal,
      props: { onUserHandled },
    });
  };

  return {
    showHandleUserModal,
  };
};
