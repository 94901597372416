import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';

import page from '../index.module.scss';
import { PageContent, PageTitle } from '@components/page';
import { ProfileContent } from '../components/content';
import { ProfileCard } from '../components/card';
import { TariffInfo } from '../components/card/tariff';
import { Verification } from '../components/card/verification';
import { ProfileExtra } from './extra-info';
import { useModel } from './model';

// NOTE: permissionsGroup here exported from permissions.ts file to avoid cyclic import collisions
// TODO по хорошему в ProfileCard и в ProfileExtra должны быть свои запросы профиля
//      и они должны экспортировать наверх permissionsGroup, сейчас это технически
//      невозможно без двух отдельных запросов. Сейчас просто из api экспортится permissionsGroup
//      на запрос всех данных профиля
export const ProfileOtherPage: FC = memo(() => {
  const m = useModel();

  return !m.profile ? null : (
    <PageContent className={page.content} uat="other-profile-page">
      <PageTitle
        title={m.i18n._(defineMessage({ id: 'router.user_profile', message: 'User profile' }))}
        backwardLink="/users"
      />

      <ProfileContent
        baseInfo={
          <ProfileCard
            profile={m.profile}
            tariff={<TariffInfo profile={m.profile} actions={m.actions} />}
            verification={<Verification profile={m.profile} />}
          />
        }
        extraInfo={<ProfileExtra profile={m.profile} refreshProfile={m.refreshProfile} />}
      />
    </PageContent>
  );
});
