import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';
import { Box } from '@components/box';
import { Form, Select } from '@components/form';
import { ButtonTextIconBig } from '@components/button';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { useOffersFilterModel } from './model';
import classnames from 'classnames';

export const OffersActions: FC<{
  className?: string;
  onOfferPlaced?: Function;
  onFilterChanged: (f) => void;
  showOfferPlacement?: boolean;
  showUserFilter: boolean;
}> = ({
  className,
  onOfferPlaced,
  onFilterChanged,
  showUserFilter = false,
  showOfferPlacement = false,
}) => {
  const { coins, currentRole, issuers, showPlaceOfferModal, statuses } = useOffersFilterModel(
    onOfferPlaced,
    showUserFilter
  );

  return (
    <Box className={classnames(className, 'mb1')}>
      <Form name="offers-filters" onChange={onFilterChanged} className={s.row}>
        <Select
          label={<Trans id="bids_list.bids_filter.status">Status</Trans>}
          className={s.item}
          name="status"
          allowEmpty={true}
          compact={true}
          data={statuses}
        />

        <Select
          label={<Trans id="bids_list.bids_filter.symbol">Symbol</Trans>}
          className={s.item}
          name="asset"
          allowEmpty={true}
          compact={true}
          data={coins}
        />

        {showUserFilter && (
          <Select
            label={<Trans id="bids.head_title.seller">Seller</Trans>}
            className={s.item}
            name="company"
            allowEmpty={true}
            compact={true}
            data={issuers}
          />
        )}

        {showOfferPlacement && (
          <ButtonTextIconBig
            className={s.placeOffer}
            icon={<AddIcon className="tm2-icon-stroke" />}
            loading={null}
            onClick={showPlaceOfferModal}
          >
            <Trans id="bids.bid_list.place.bid">Place offer</Trans>
          </ButtonTextIconBig>
        )}
      </Form>
    </Box>
  );
};
