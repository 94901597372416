import { ContextCreator } from '@helper/ts';
import { EventsService } from '@services/events';

type State = {
  events: EventsService<number>;
};

const initialState: State = {
  events: new EventsService(),
};

export const { Context, Provider } = ContextCreator(initialState, (s: State): State => s);
