import React, { FC, memo, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import ReactNotification, { store } from 'react-notifications-component'; // TODO обновить модуль, выпилить @types/react-notifications-component
import { Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';

import './index.scss';
import { configSelectors } from '@config/core';
import { session } from '@services/session';
import {
  CancelRequestError,
  ExceptionError,
  HandledError,
  InfoError,
  OperationError,
  SystemError,
  TypedError,
  ValidationError,
} from 'shared/services/stomp/errors';
import { NotifyMessage } from './notify.component';

export enum NotifyType {
  danger = 'danger',
  default = 'default',
  info = 'info',
  success = 'success',
  warning = 'warning',
}

export const DefaultNotificationTitle: FC<{ type: NotifyType }> = ({ type }) => {
  if (type === NotifyType.danger) {
    return <Trans id="reusable.notification.default_title.danger">Error!</Trans>;
  }
  if (type === NotifyType.default) {
    return <Trans id="reusable.notification.default_title.default">Info!</Trans>;
  }
  if (type === NotifyType.info) {
    return <Trans id="reusable.notification.default_title.info">Info!</Trans>;
  }
  if (type === NotifyType.success) {
    return <Trans id="reusable.notification.default_title.success">Success!</Trans>;
  }
  if (type === NotifyType.warning) {
    return <Trans id="reusable.notification.default_title.warning">Warning!</Trans>;
  }
};

interface NotifyInfo {
  defaultTitle?: boolean;
  text: ReactNode;
  title?: ReactNode;
}

interface NotifyConfig {
  duration?: number;
  forcedAction?: boolean;
  onClick?: () => void;
  type?: NotifyType;
}

function showNotification({ defaultTitle, text, title }: NotifyInfo, config: NotifyConfig) {
  store.addNotification({
    animationIn: ['animated', 'slideInRight'],
    animationOut: ['animated', 'slideOutRight'],
    container: 'bottom-right',
    title: defaultTitle ? <DefaultNotificationTitle type={config.type} /> : title,
    message: <NotifyMessage text={text} time={new Date().toLocaleString()} />,
    dismiss: {
      duration: config.duration || 10000,
      pauseOnHover: true,
      onScreen: true,
    },
    insert: 'top',
    onRemoval: (id, removedBy) => {
      // removedBy: 'click' | 'timeout'
      if (config.forcedAction || removedBy === 'click') {
        config.onClick && config.onClick();
      }
    },
    type: config.type,
  });
}

export function notifyDefault(info: NotifyInfo, config: NotifyConfig = {}) {
  return showNotification(info, { ...config, type: NotifyType.default });
}

export function notifyError(info: NotifyInfo, config: NotifyConfig = {}) {
  // фаст фикс. У нас приложение скрыто стилями, пока не инициализируется полностью
  // это сделано, чтобы избежать мерцания. Т.к. в начальный момент есть много мест
  // где, приложение может упасть, требуется большой рефакторинг. На данный момент
  // этого фикса достаточно, чтобы юзер увидел ошибку (которая внутри блока приложения, которое скрыто)
  const root = document.querySelector('.tm2-initial-hidden');
  root?.classList.remove('tm2-initial-hidden');
  return showNotification(info, { ...config, type: NotifyType.danger });
}

export function notifyInfo(info: NotifyInfo, config: NotifyConfig = {}) {
  return showNotification(info, { ...config, type: NotifyType.info });
}

export function notifySuccess(info: NotifyInfo, config: NotifyConfig = {}) {
  return showNotification(info, { ...config, type: NotifyType.success });
}

export function notifyWarn(info: NotifyInfo, config: NotifyConfig = {}) {
  return showNotification(info, { ...config, type: NotifyType.warning });
}

export function handleBackendError(error) {
  if (error.type === 'VALIDATION_ERROR') {
    return;
  }
  if (error.response?.status === 400) {
    return;
  }
  if (error instanceof ValidationError) {
    return;
  }
  if (error instanceof HandledError) {
    return;
  }
  if (error instanceof CancelRequestError) {
    return;
  }
  if (error instanceof TypedError) {
    return;
  }

  if (session.isShowLogs) {
    console.info('HANDLER ERROR', error);
  }

  const title = error.title || error?.target?.error?.name || null;
  const text =
    error.message ||
    error.raw?.data?.message ||
    error.raw?.data ||
    error.response?.data?.details ||
    error.response?.data ||
    error.target?.error?.message;

  if (error instanceof InfoError) {
    return notifyInfo({ text, title });
  }
  if (error instanceof OperationError) {
    return notifyWarn({ text, title });
  }
  if (error instanceof SystemError) {
    return notifyError({ text, title });
  }
  if (error instanceof ExceptionError) {
    return notifyError({ text, title });
  }
  if (error?.target?.error?.name === 'NotFoundError') {
    const extraTitle = 'Uploading Error';
    const extraText = 'File not found on your computer, please re-upload the file.';
    return notifyError({ text: extraText, title: extraTitle });
  }
  return notifyError({ text, title });
}

export const NotificationsContainer: FC = memo(() => {
  const i18n = useSelector(configSelectors.i18n); // this need to show translated notifications on app initiation phase

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
      <ReactNotification />
    </I18nProvider>
  );
});
