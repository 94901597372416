import React from 'react';
import _ from 'lodash';
import { FormInstance } from 'antd';

import { ValidationError } from '@services/stomp/errors';
import { Any } from '@models/core';
import { ValidationMessage } from '../validation-message';

class FormService {
  private _formInstances: { [k: string]: FormInstance<any> } = {};
  private _formsData: { [k: string]: any } = {};

  public clearErrors(id: string): void {
    const form: FormInstance = this._formInstances[id];
    if (!form) {
      return;
    }
    form.setFields(form.getFieldsError().map((item) => ({ ...item, errors: [] })));
  }

  public convertAntdFlatDataToOur(dataAntFlat) {
    let formParsed = {};
    Object.entries(dataAntFlat).forEach(([key, v]) => {
      if (Array.isArray(v)) {
        // for antd Item.List
        const list = v
          .filter((item) => !!item) // new Item.List row has undefined value at start
          .map((item) => {
            if (item instanceof Object) {
              // TODO подумать над нормальным решением (это фикс для конфликта мультиселект и form list)
              const parsed = {};
              Object.entries(item).forEach(([_key, _v]) => _.set(parsed, _key, _v));
              return parsed;
            }
            return item;
          });
        _.set(formParsed, key, list);
      } else {
        _.set(formParsed, key, v);
      }
    });
    return formParsed;
  }

  public convertOurDataToAntdFlat(dataOur) {
    return flatten(dataOur);
    // https://stackoverflow.com/questions/33036487/one-liner-to-flatten-nested-object
    function flatten(obj, roots = [], sep = '.') {
      return Object.keys(obj) // find props of given object
        .reduce(
          (memo, prop) =>
            Object.assign(
              // return an object by iterating props
              {}, // create a new object
              memo, // include previously returned object
              Array.isArray(obj[prop]) && obj[prop][0] instanceof Object // for FormList
                ? { [roots.concat([prop]).join(sep)]: obj[prop].map((p) => flatten(p, [], sep)) }
                : !Array.isArray(obj[prop]) && obj[prop] instanceof Object // check for nested objects
                ? flatten(obj[prop], roots.concat([prop]), sep)
                : { [roots.concat([prop]).join(sep)]: obj[prop] } // simple string fields
            ),
          {}
        );
    }
  }

  public get<T = any>(id: string): FormInstance<T> {
    return this._formInstances[id];
  }

  public remove(id: string): void {
    delete this._formInstances[id];
    delete this._formsData[id];
  }

  public set<T = any>(id: string, form: FormInstance<T>): FormInstance<T> {
    return (this._formInstances[id] = form);
  }
}

export const formService = new FormService();

export const handleValidationErrors = (error, formId: string) => {
  if (
    !(error instanceof ValidationError) &&
    error?.response?.status === 400 &&
    error?.response?.data?.details
  ) {
    // TODO This logic is for old form data send requests. Http requests are deprecated due to graphQL
    const form = formService.get(formId);
    const details = error?.response?.data?.details || {};
    // TODO в старых ошибках и ошибках бекенда логика nested реализована по другому через [] скобки
    //      при этом фронтовые формы там тоже написаны не через FormList, это все нужно переписать
    form.setFields(
      Object.keys(details).map((key) => ({
        name: key,
        errors: [
          (
            <ValidationMessage
              error={{
                code: error[key],
                field: key,
                message: details[key],
              }}
            />
          ) as Any, // ant design api is not allowed to use ReactNode as message, but all works fine
        ],
      }))
    );
  } else if (error instanceof ValidationError) {
    const form = formService.get(formId);
    form.setFields(
      Object.keys(error.formErrors).map((key) => {
        const nested = key.match(/\[\d*?]\./g) && key.match(/\[\d*?]\./g)[0];
        if (!nested) {
          return {
            name: key,
            errors: [error.formErrors[key]] as Array<string>,
          };
        }
        const index = +nested.slice(1, -2); // xxx[i].yyy
        const [list, field] = key.split(nested);
        return {
          name: [list, index, field],
          errors: [error.formErrors[key]] as Array<string>,
        };
      })
    );
  }
};
