import s from '../index.module.scss';
import { CELL_PADDING_X } from '../constants';
import type { CSSProperties, ReactNode } from 'react';
import type { CellAlignment } from '../types';

export type HeadCellBoxProps = {
  alignment: CellAlignment;
  width: number;
  children: ReactNode;
};

const alignmentStyle: Record<
  CellAlignment,
  {
    justifyContent: string;
    paddingLeft: number;
    paddingRight: number;
  }
> = {
  left: {
    paddingLeft: 0,
    paddingRight: CELL_PADDING_X,
    justifyContent: 'flex-start',
  },
  right: {
    paddingLeft: CELL_PADDING_X,
    paddingRight: 0,
    justifyContent: 'flex-end',
  },
  center: {
    paddingLeft: CELL_PADDING_X,
    paddingRight: CELL_PADDING_X,
    justifyContent: 'center',
  },
};

export function HeadCellBox(props: HeadCellBoxProps) {
  const style: CSSProperties = {
    width: props.width,
    ...alignmentStyle[props.alignment],
  };
  return (
    <div className={s.headCellBox} style={style}>
      {props.children}
    </div>
  );
}
