import React, { FC } from 'react';
import { defineMessage } from '@lingui/macro';

import { PageContent, PageTitle } from '@components/page';
import { Permission as P, PermissionGroup, PermissionRecord } from '@permissions/core';
import { useI18n } from '@hooks/i18n';
import { permissionsGroup as tablePermissionsGroup, UsersTable } from './table';
import { permissionsGroup as actionsPermissionsGroup, UsersActionsBlock } from './actions-block';

export const permissionsGroup = new PermissionGroup({
  // TODO P.USERS_VIEW_ALL должен подтягиваться покомпонентно из api
  groups: [new PermissionRecord(P.USERS_VIEW_ALL), actionsPermissionsGroup, tablePermissionsGroup],
  marker: 'layout:users-page',
  operator: 'OR',
});

export const UsersPage: FC = () => {
  const { i18n } = useI18n();

  return (
    <PageContent uat="users-page">
      <PageTitle title={i18n._(defineMessage({ id: 'router.users', message: 'Users' }))} />

      <UsersActionsBlock />

      <UsersTable />
    </PageContent>
  );
};
