import { createSelector } from 'reselect';

import { Selector } from '@models/redux';
import { BeneficialOwner } from '../beneficial-api.service';
import { BeneficialOwnersState } from './model';

const beneficialState: Selector<BeneficialOwnersState> = (root) =>
  root.main?.beneficialOwners?.page;

const beneficialOwners: Selector<Array<BeneficialOwner>> = (root) =>
  beneficialState(root).beneficialOwners;
const investorSelected: Selector<number> = (root) => beneficialState(root).investorSelected;
const isDigitalMetalsReverse: Selector<boolean> = (root) =>
  beneficialState(root).isDigitalMetalsReverse;
const beneficialOwnersTable = createSelector(
  [beneficialOwners, investorSelected, isDigitalMetalsReverse],
  (beneficialOwnersList, investorId, isReverse) => {
    return beneficialOwnersList
      .filter((owner) => !investorId || owner.id === investorId)
      .map((owner) => ({
        ...owner,
        metals: owner.metals.sort((a, b) =>
          isReverse ? b.asset.localeCompare(a.asset) : a.asset.localeCompare(b.asset)
        ),
      }));
  }
);

const beneficialOwnersPageLocked: Selector<boolean> = (root) =>
  beneficialState(root).isBeneficialOwnersLoading || beneficialState(root).isDigitalMetalsLoading;

const digitalMetals: Selector<Array<string>> = (root) => beneficialState(root).digitalMetals;

const digitalMetalSelected: Selector<string> = (root) => beneficialState(root).digitalMetalSelected;

const investors: Selector<Array<BeneficialOwner>> = (root) =>
  beneficialState(root).beneficialOwners;

const isBeneficialOwnersLoading: Selector<boolean> = (root) =>
  beneficialState(root).isBeneficialOwnersLoading;

const isHasMore: Selector<boolean> = (root) => beneficialState(root).isHasMore;

const pageIndex: Selector<number> = (root) => beneficialState(root).pageIndex;

const currentInvestor: Selector<number> = (root) => beneficialState(root)?.investorSelected;

const currentDigitalMetal: Selector<string> = (root) => beneficialState(root)?.digitalMetalSelected;

export const beneficialOwnersSelectors = {
  beneficialOwnersPageLocked,
  beneficialOwnersTable,
  beneficialState,
  currentDigitalMetal,
  currentInvestor,
  digitalMetalSelected,
  digitalMetals,
  investors,
  isBeneficialOwnersLoading,
  isDigitalMetalsReverse,
  isHasMore,
  pageIndex,
};
