import sign from 'tm2sign.macro';

import { stompClientService as stompClient } from '@services/stomp/client';
import { SelectItem } from '@components/form';
import { currenciesFilter } from '@hot-fix/currency';

const getCurrencies: () => Promise<Array<SelectItem>> = () => {
  const currenciesFields = sign('currencies', ['id', 'code', 'order']);
  return stompClient.getData<Array<Currency>>('currencies', currenciesFields).then((list) =>
    list
      .filter(currenciesFilter)
      .sort((a, b) => a.order - b.order)
      .map((item) => ({
        label: item.code,
        value: item.id,
      }))
  );
};

export default {
  getCurrencies,
};

interface Currency {
  id: number;
  code: string;
  order: number;
}
