export const finishRedeemModalStateInitial = {
  isCreateRedeemLoading: false,
  isDownloadTransactionLoading: false,
};

export const FinishRedeemModalActionsTypes = {
  CREATE_REDEEM: 'FINISH_REDEEM_MODAL.CREATE_REDEEM',
  CREATE_REDEEM_FAIL: 'FINISH_REDEEM_MODAL.CREATE_REDEEM_FAIL',
  CREATE_REDEEM_SUCCESS: 'FINISH_REDEEM_MODAL.CREATE_REDEEM_SUCCESS',
  DOWNLOAD_TRANSACTION: 'FINISH_REDEEM_MODAL.DOWNLOAD_TRANSACTION',
  DOWNLOAD_TRANSACTION_FAIL: 'FINISH_REDEEM_MODAL.DOWNLOAD_TRANSACTION_FAIL',
  DOWNLOAD_TRANSACTION_SUCCESS: 'FINISH_REDEEM_MODAL.DOWNLOAD_TRANSACTION_SUCCESS',
  ON_CREATE_REDEEM_CLICKED: 'FINISH_REDEEM_MODAL.ON_CREATE_REDEEM_CLICKED',
  ON_GET_TRANSACTION_CLICKED: 'FINISH_REDEEM_MODAL.ON_GET_TRANSACTION_CLICKED',
  ON_CLOSE_MODAL_CLICKED: 'FINISH_REDEEM_MODAL.ON_CLOSE_MODAL_CLICKED',
};
