import { useQuery } from '@hooks/core';
import api from './api';
import { SelectItem } from '@components/form';
import { useMemo } from 'react';

export const useRoles = () => {
  const { data: rolesData, isLoading: isRolesLoading } = useQuery(api.getRoles, { initial: [] });

  const roles: Array<SelectItem> = useMemo(
    () =>
      rolesData.map((r) => ({
        label: r.label,
        value: r.id,
      })),
    [rolesData]
  );

  return { roles, isRolesLoading };
};

export const useSystemEvents = () => {
  const { data: systemEventsData, isLoading: isSystemEventsLoading } = useQuery(
    api.getSystemEvents,
    { initial: [] }
  );

  const systemEvents: Array<SelectItem> = useMemo(
    () =>
      systemEventsData.map((se) => ({
        label: se.label,
        value: se.id,
      })),
    [systemEventsData]
  );

  return { systemEvents, isSystemEventsLoading };
};

export const useInitialValues = (companyId: number) => {
  const { data: initialValues, isLoading: isInitialValuesLoading } = useQuery(() =>
    api.getMarketingCompany(companyId)
  );

  return { initialValues, isInitialValuesLoading };
};
