import { useSelector } from 'react-redux';
import React, { useCallback, useState } from 'react';
import { useCallDispatch } from '@hooks/core';
import { useHasAccess } from '@permissions/hooks';
import { Permission as P } from '@permissions/core';
import { addFundsModalSelectors as modalSelectors } from '../store/selectors';
import { wireTransferViewSelectors as wireSelectors } from './store/selectors';
import { wireTransferViewUserActions as wireUserActions } from './store/user.acions';
import {
  defaultExternalBankAccount,
  WireTransferFormFields as Fields,
} from '@router/pages/deposits/add-funds-modal/wire-transfer/store/model';
import { Trans } from '@lingui/macro';

export const useModel = () => {
  const bankAccounts = useSelector(wireSelectors.bankAccounts);

  const availableExternalClientBankAccounts = useSelector(
    wireSelectors.availableExternalClientBankAccounts
  );

  const investors = useSelector(wireSelectors.investors);

  const lockUI = useSelector(modalSelectors.lockUI);

  const selectedCurrencyId = useSelector(wireSelectors.selectedCurrencyId);

  const lockExternalBankAccountsUI = selectedCurrencyId === null || lockUI;

  const onBankAccountSelected = useCallDispatch(wireUserActions.onBankAccountSelected);

  const onInvestorSelected = useCallDispatch(wireUserActions.onInvestorSelected);

  const [invoiceFee, setInvoiceFee] = useState<number>(0);

  const [selectedCurrency, setSelectedCurrency] = useState<string>('');

  const [
    isDefaultExternalBankAccountBalanceSelected,
    setIsDefaultExternalBankAccountBalanceSelected,
  ] = useState(false);

  const ableToCreateExtInvoice = useHasAccess({ or: [P.INVOICE_CREATE_EXT] });

  const calculateFee = useCallback((amount) => {
    // aerapass fee
    const formula = 10 + (amount / 100) * 0.5;
    const calculation = +(Math.round(formula * 100) / 100).toFixed(2);
    return !!amount ? calculation : 0;
  }, []);

  const onFormChanged = useCallback(
    (formData) => {
      const bankAccountId = formData.bankAccountId;
      const currency = bankAccounts.find((bank) => bank.id === bankAccountId)?.currency.code;
      setSelectedCurrency(currency);

      const fee = calculateFee(formData.amount);
      setInvoiceFee(fee);

      setIsDefaultExternalBankAccountBalanceSelected(
        formData[Fields.externalBankAccountBalanceId] === defaultExternalBankAccount.externalId
      );
    },
    [bankAccounts]
  );

  const isExternalBankAccountBalanceIdHidden =
    selectedCurrencyId === null || !ableToCreateExtInvoice;

  const commentaryLabel =
    isDefaultExternalBankAccountBalanceSelected && !isExternalBankAccountBalanceIdHidden ? (
      <Trans id="deposit_modal.commentary_manual">Enter sender details and comment</Trans>
    ) : (
      <Trans id="deposit_modal.commentary">Commentary</Trans>
    );

  return {
    bankAccounts,
    availableExternalClientBankAccounts,
    investors,
    onBankAccountSelected,
    onInvestorSelected,
    invoiceFee,
    lockUI,
    lockExternalBankAccountsUI,
    onFormChanged,
    selectedCurrency,
    isExternalBankAccountBalanceIdHidden,
    commentaryLabel,
  };
};
