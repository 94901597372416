import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';

const secondaryMarketLoginQuery = 'onSecondaryMarketLogin';
const secondaryMarketLoginFields = ['value'];
const secondaryMarketLogin = makeQuery({
  queryName: secondaryMarketLoginQuery,
  queryFields: secondaryMarketLoginFields,
  query: (): Promise<string> => {
    return stompClient.sendData(
      secondaryMarketLoginQuery,
      sign(secondaryMarketLoginQuery, secondaryMarketLoginFields)
    );
  },
});

const api = {
  secondaryMarketLogin,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:tutorial');

export default api;
