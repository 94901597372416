import React, { FC, ReactNode } from 'react';

import s from './index.module.scss';

export const ProfileContent: FC<{
  baseInfo: ReactNode;
  extraInfo: ReactNode;
}> = (p) => {
  return (
    <div className={s.content}>
      <div className={s.baseInfo}>{p.baseInfo}</div>

      <div className={s.extraInfo}>{p.extraInfo}</div>
    </div>
  );
};
