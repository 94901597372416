export class TimeoutError extends Error {}

export function promiseTimeout<T>(promise: Promise<T>, ms: number = 40_000): Promise<T> {
  return Promise.race([
    promise,
    new Promise((_, reject) => {
      setTimeout(reject, ms, new TimeoutError());
    }),
  ]) as Promise<T>;
}
