import { TableHeaderSortType as Sort } from 'components/page-table';

export const TransferRequestsTableColumnIds = {
  amount: 'amount',
  asset: 'asset',
  client: 'client',
  clientId: 'clientId',
  dateChanged: 'dateChanged',
  dateCreated: 'dateCreated',
  directionAndAsset: 'directionAndAsset',
  id: 'id',
  status: 'status',
};

const columnSort = [
  { direction: Sort.desc, fields: ['id'], id: TransferRequestsTableColumnIds.id },
  { direction: Sort.notSet, fields: ['createdAt'], id: TransferRequestsTableColumnIds.dateCreated },
  { direction: Sort.notSet, fields: ['state'], id: TransferRequestsTableColumnIds.status },
  {
    direction: Sort.notSet,
    fields: ['user.firstName', 'user.lastName', 'user.company'],
    id: TransferRequestsTableColumnIds.client,
  },
  { direction: Sort.notSet, fields: ['user.id'], id: TransferRequestsTableColumnIds.clientId },
  {
    direction: Sort.notSet,
    fields: ['type'],
    id: TransferRequestsTableColumnIds.directionAndAsset,
  },
  { direction: Sort.notSet, fields: ['asset'], id: TransferRequestsTableColumnIds.asset },
  { direction: Sort.notSet, fields: ['amount'], id: TransferRequestsTableColumnIds.amount },
  { direction: Sort.notSet, fields: ['changedAt'], id: TransferRequestsTableColumnIds.dateChanged },
];

export const transferRequestsPageStateInitial = {
  isHasMore: true,
  isDownloadTransactionLoading: false,
  isTransferRequestsLoading: false,
  isUploadTransactionLoading: false,
  pageIndex: -1, // TODO стандартизировать значение
  sort: columnSort,
  transferRequests: [],
};

export const TransferRequestsPageActionsTypes = {
  DOWNLOAD_TRANSACTION: 'TRANSFER_REQUESTS_PAGE.DOWNLOAD_TRANSACTION',
  DOWNLOAD_TRANSACTION_FAIL: 'TRANSFER_REQUESTS_PAGE.DOWNLOAD_TRANSACTION_FAIL',
  DOWNLOAD_TRANSACTION_SUCCESS: 'TRANSFER_REQUESTS_PAGE.DOWNLOAD_TRANSACTION_SUCCESS',
  LOAD_MORE_TRANSFER_REQUESTS: 'TRANSFER_REQUESTS_PAGE.GET_TRANSFER_REQUESTS',
  LOAD_MORE_TRANSFER_REQUESTS_FAIL: 'TRANSFER_REQUESTS_PAGE.GET_TRANSFER_REQUESTS_FAIL',
  LOAD_MORE_TRANSFER_REQUESTS_SUCCESS: 'TRANSFER_REQUESTS_PAGE.GET_TRANSFER_REQUESTS_SUCCESS',
  ON_DOWNLOAD_CLICKED: 'TRANSFER_REQUESTS_PAGE.ON_DOWNLOAD_CLICKED',
  ON_PAGE_OPENED: 'TRANSFER_REQUESTS_PAGE.ON_PAGE_OPENED',
  ON_TABLE_SCROLLED: 'TRANSFER_REQUESTS_PAGE.ON_TABLE_SCROLLED',
  ON_TABLE_SORTED: 'TRANSFER_REQUESTS_PAGE.ON_TABLE_SORTED',
  ON_UPLOAD_CLICKED: 'TRANSFER_REQUESTS_PAGE.ON_UPLOAD_CLICKED',
  UPLOAD_TRANSACTION: 'TRANSFER_REQUESTS_PAGE.UPLOAD_TRANSACTION',
  UPLOAD_TRANSACTION_FAIL: 'TRANSFER_REQUESTS_PAGE.UPLOAD_TRANSACTION_FAIL',
  UPLOAD_TRANSACTION_SUCCESS: 'TRANSFER_REQUESTS_PAGE.UPLOAD_TRANSACTION_SUCCESS',
};
