import { RedeemConfirmationModalActionsTypes as Types } from './model';

const GetStableBalanceAction = () => ({
  payload: null,
  type: Types.GET_STABLE_BALANCE,
});

const GetStableBalanceFailAction = (error) => ({
  payload: error,
  type: Types.GET_STABLE_BALANCE_FAIL,
});

const GetStableBalanceSuccessAction = (balance) => ({
  payload: balance,
  type: Types.GET_STABLE_BALANCE_SUCCESS,
});

const SetConfirmedRedeemDataAction = (data) => ({
  payload: data,
  type: Types.SET_CONFIRMED_REDEEM_DATA,
});

export const redeemConfirmationModalServiceActions = {
  getStableBalance: GetStableBalanceAction,
  getStableBalanceFail: GetStableBalanceFailAction,
  getStableBalanceSuccess: GetStableBalanceSuccessAction,
  setConfirmedRedeemData: SetConfirmedRedeemDataAction,
};
