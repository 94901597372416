import { useCallback } from 'react';
import { useCallbackOne } from 'use-memo-one';

import { mapTableSorting, TableSortCreator } from '@helper/sorting';
import { KeyExtractor } from '@components/table2/core';
import { LoadData, SortOrder, useTable } from '@components/table2/simplified';
import { handleBackendError } from '@modules/notify';
import { useSubscribeOnTransactionsUpdates } from '../hooks';
import api, { CMTransaction, Column } from './api';

const sortMap = TableSortCreator(Column, { exclude: [Column.minfiniumOpId, Column.txDate] });
const initialSortOrder: SortOrder = [{ field: Column.id, direction: 'descend', key: Column.id }];

export const useModel = () => {
  const keyExtractor = useCallbackOne<KeyExtractor<CMTransaction>>((item) => {
    return item.id;
  }, []);

  const loadData = useCallbackOne<LoadData<CMTransaction>>(
    ({ nextPage, sortOrder }) => api.getTransactions(nextPage, mapTableSorting(sortOrder, sortMap)),
    []
  );

  const { setData, tableInstance } = useTable<CMTransaction>({
    ensureUniqueness: true,
    initialSortOrder,
    keyExtractor,
    loadData,
  });

  const onTransactionCreated = useCallback(
    async (id: number) => {
      try {
        const transaction = await api.getTransaction(id);
        setData((transactions) => [transaction, ...transactions]);
      } catch (error) {
        handleBackendError(error);
      }
    },
    [setData]
  );

  useSubscribeOnTransactionsUpdates(onTransactionCreated);

  return {
    tableInstance,
  };
};
