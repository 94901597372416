import React, { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';

import s from './index.module.scss';
import { Separator } from './separator';

const Portal = ({ children, portal }: { children: ReactNode; portal: Element }) =>
  children && portal ? createPortal(children, portal) : null;

export const Step: FC<{
  activeStep: number;
  description: ReactNode;
  details: ReactNode;
  length: number;
  portal: Element;
  stepIndex: number;
}> = ({ activeStep, description, details, length, portal, stepIndex }) => {
  if (stepIndex === null) {
    return null;
  }

  return (
    <>
      <div className={s.step}>
        <div className={s.counter}>
          <div className={s.icon}>!</div>
        </div>
        <div className={s.description}>{description}</div>
      </div>

      {stepIndex >= length ? null : <Separator activeStep={activeStep} index={stepIndex} />}

      {stepIndex !== activeStep ? null : <Portal portal={portal}>{details}</Portal>}
    </>
  );
};
