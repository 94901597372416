import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';

import { VaultsPageState } from './page/store/model';
import { vaultsPageReducer } from './page/store/reducer';
import { vaultsPageEffects } from './page/store/effects';
import { VaultBalanceDetailsState } from './vault-balance-detail-modal/store/model';
import { vaultBalanceDetailsReducer } from './vault-balance-detail-modal/store/reducer';
import { vaultBalanceDetailsEffects } from './vault-balance-detail-modal/store/effects';

export interface VaultsState {
  vaultBalanceDetailsModal: VaultBalanceDetailsState;
  vaultsPage: VaultsPageState;
}

export const vaultsReducer = combineReducers({
  vaultBalanceDetailsModal: vaultBalanceDetailsReducer,
  vaultsPage: vaultsPageReducer,
});

export function* vaultsEffects() {
  yield all([fork(vaultBalanceDetailsEffects), fork(vaultsPageEffects)]);
}
