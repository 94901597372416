import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@lingui/core';

import { configSelectors } from '@config/core';
import { permissionsSelectors, Permission as P, IsHasAccess, Role } from '@permissions/core';
import { useCurrentRole } from '@config/hooks';
import { handleBackendError } from '@modules/notify';
import { SortItem } from '@models/core';
import { TableHeaderSortType as Sort } from '../../../components/page-table';
import { transactionsActions } from './store/actions';
import { transactionsSelectors } from './store/selectors';
import { TransactionItem, transactionsApi } from './api';

export enum TransactionsTableColumnIds {
  amount = 'amount',
  date = 'date',
}

export const useTransactionsPageModel = () => {
  const dispatch = useDispatch();
  const i18n: I18n = useSelector(configSelectors.i18n);
  const hasAccessFn: IsHasAccess = useSelector(permissionsSelectors.isHasAccess);
  const lockUI: boolean = useSelector(transactionsSelectors.lockUI);
  const hasMore: boolean = useSelector(transactionsSelectors.hasMore);
  const page: number = useSelector(transactionsSelectors.page);
  const transactions: Array<TransactionItem> = useSelector(transactionsSelectors.transactions);
  const filter = useSelector(transactionsSelectors.filtersSelected);
  const sort: Array<SortItem<TransactionsTableColumnIds>> = useSelector(transactionsSelectors.sort);
  const columnSortDirection: (id: TransactionsTableColumnIds) => Sort = useSelector(
    transactionsSelectors.columnSortDirection
  );

  const currentRole: Role = useCurrentRole();
  const isIssuer: boolean = currentRole === Role.issuer;
  const isInvestor: boolean = useSelector(configSelectors.isInvestor);
  const isCanViewProfile: boolean = hasAccessFn({ or: [P.PROFILE_VIEW_OTHER] });

  const getTransactions = () => {
    dispatch(transactionsActions.getTransactions());
    transactionsApi
      .getTransactions(page, filter, sort)
      .then((response) => {
        dispatch(transactionsActions.getTransactionsSuccess(response));
      })
      .catch(handleBackendError);
  };

  const onTableSorted = (item: SortItem<TransactionsTableColumnIds>) =>
    dispatch(transactionsActions.onTableSorted(item));

  return {
    columnSortDirection,
    getTransactions,
    hasMore,
    i18n,
    isCanViewProfile,
    isIssuer,
    isInvestor,
    lockUI,
    onTableSorted,
    transactions,
  };
};
