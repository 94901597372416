import React, { useCallback, FC } from 'react';
import { connect } from 'react-redux';

import { DispatchFn } from '@models/redux';
import { TableLinkCell, LINK_HEIGHT } from '@components/table2/utils/link-cell';
import { CoinInfo, VaultBalanceDetails, VaultInfo } from '../../vaults-api.service';
import { vaultsPageSelectors } from '../store/selectors';
import { vaultsPageUserActions } from '../store/user.acions';
import { OnShowVaultBalanceDetailsPayload } from '../store/model';

export const ITEM_HEIGHT = LINK_HEIGHT;

const mapStateToProps = (root) => ({
  isCanSeeBalance: vaultsPageSelectors.isCanSeeBalance(root),
});

const mapDispatchToProps = (dispatch) => ({
  onShowVaultBalanceDetails: (info) =>
    dispatch(vaultsPageUserActions.onShowVaultBalanceDetails(info)),
});

const ConnectedVaultTableCoinCell: FC<{
  isCanSeeBalance: boolean;
  onShowVaultBalanceDetails: DispatchFn<OnShowVaultBalanceDetailsPayload>;
  vault: VaultInfo;
}> = ({ isCanSeeBalance, onShowVaultBalanceDetails, vault }) => {
  return (
    <div>
      {vault.coins.map((coin: CoinInfo) => {
        const vaultBalanceCoin: VaultBalanceDetails = vault.lastVaultBalance
          ? vault.lastVaultBalance.details.find((details) => details.coin.id === coin.id)
          : null;

        const balanceLabel: string = vaultBalanceCoin
          ? vaultBalanceCoin.amount + '' + vaultBalanceCoin.unit.label
          : '?';

        const coinLabel: string = coin.asset + '[' + balanceLabel + ']';
        const disabled = !isCanSeeBalance || !vaultBalanceCoin;

        const onClick = useCallback(
          (e) => {
            e.preventDefault();
            onShowVaultBalanceDetails({ coin, vault });
          },
          [coin, vault]
        );

        return (
          <div key={coin.id}>
            <TableLinkCell
              permissions={'any'}
              text={coinLabel}
              onClick={onClick}
              disabled={disabled}
            />
          </div>
        );
      })}
    </div>
  );
};

export const CoinCell = connect(mapStateToProps, mapDispatchToProps)(ConnectedVaultTableCoinCell);
