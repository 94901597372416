import React, { Fragment, memo } from 'react';
import { Trans } from '@lingui/macro';
import { Store } from 'rc-field-form/lib/interface';

import {
  Form,
  FormList,
  FileUploader,
  InputHidden,
  InputText,
  InputNumber,
  Select,
  SubmitButton,
} from '@components/form';
import { Col, Grid } from '@components/grid';
import { MFC, Modal, ModalContent, ModalFooter, ModalTitle } from '@modules/modal';
import { tokenDecimalsMax } from '@constants/math';
import { VaultInfo } from '../vaults-api.service';
import { Fields, formId, useModel } from './model';

export const ConfirmVaultBalanceModal: MFC<{
  vault: VaultInfo;
}> = memo(({ isVisible, vault }) => {
  const m = useModel(vault.id, ConfirmVaultBalanceModal);

  return (
    <Modal
      isVisible={isVisible}
      loading={m.lockUI}
      disabled={m.lockUI}
      size="big"
      onClose={m.onCloseClicked}
    >
      <ModalTitle>
        <Trans id="vault_add_balance_modal.title">Confirm balance</Trans>
      </ModalTitle>

      <ModalContent>
        <Form
          className="confirm-balance-modal-form"
          name={formId}
          initialValues={m.formInitial as Store}
        >
          <Grid
            className="confirm-balance-modal-form-fields-container"
            strictWidth={true}
            pattern={{ sm: 2, md: 4 }}
          >
            <FormList name={Fields.detailsList}>
              {(fields) =>
                fields.map(({ key, name }) => (
                  <Fragment key={key}>
                    <Col>
                      <InputText
                        name={[name, Fields.asset]}
                        fieldKey={[key, Fields.asset]}
                        label={<Trans id="vault_add_balance_modal.symbol">Symbol</Trans>}
                        disabled={true}
                      />

                      {/* NOTE: we need to send coin ID, but doesn't need to show it to user */}
                      <InputHidden name={[name, Fields.coin]} fieldKey={[key, Fields.coin]} />
                    </Col>

                    <Col>
                      <InputNumber
                        name={[name, Fields.amount]}
                        fieldKey={[key, Fields.amount]}
                        label={<Trans id="vault_add_balance_modal.amount">Amount</Trans>}
                        min={0}
                        maxDecimals={tokenDecimalsMax}
                        disabled={m.lockUI}
                        required={true}
                      />
                    </Col>

                    <Col>
                      <Select
                        name={[name, Fields.unit]}
                        fieldKey={[key, Fields.unit]}
                        label={<Trans id="vault_add_balance_modal.unit">Unit</Trans>}
                        data={m.units}
                        disabled={m.lockUI}
                        required={true}
                      />
                    </Col>

                    <Col>
                      <FileUploader
                        name={[name, Fields.report]}
                        fieldKey={[key, Fields.report]}
                        label={<Trans id="vault_add_balance_modal.report">Report</Trans>}
                        placeholder={<Trans id="vault_add_balance_modal.add_file">Add file</Trans>}
                        disabled={m.lockUI}
                        required={true}
                      />
                    </Col>
                  </Fragment>
                ))
              }
            </FormList>
          </Grid>
        </Form>
      </ModalContent>

      <ModalFooter>
        <SubmitButton
          className="mt-md"
          name={formId}
          onAction={m.onFormAction}
          fullWidth={true}
          disabled={m.lockUI}
        >
          <Trans id="button.save">Save</Trans>
        </SubmitButton>
      </ModalFooter>
    </Modal>
  );
});
