import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { Permission } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

const getUsersReportQuery = 'usersInfoReportAsXLSX';
const getUsersReportFields = ['value'];
const getUsersReport = makeQuery({
  permissions: Permission.USERS_INFO_VIEW_REPORT,
  queryName: getUsersReportQuery,
  queryFields: getUsersReportFields,
  query: (): Promise<string> => {
    return stompClient
      .getData(getUsersReportQuery, sign(getUsersReportQuery, getUsersReportFields))
      .then((response) => response.value);
  },
});

const api = {
  getUsersReport,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:users-report');

export default api;
