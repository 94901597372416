import { useSelector } from 'react-redux';
import { useReturnableLink } from '@hooks/url';
import { permissionsSelectors } from '@permissions/core.strict';
import { profileOtherPagePermissionsGroup } from 'containers/pages/profile/other/permissions.strict';
import { Link, LinkText } from './regular';

import type { LinkProps } from './regular';

const userProfilePermissions = { or: [profileOtherPagePermissionsGroup] };

type UserProfileLinkPropsBase = {
  id: string | number;
  text: string;
};

export type UserProfileLinkProps = UserProfileLinkPropsBase &
  Omit<LinkProps, keyof UserProfileLinkPropsBase | 'children' | 'to'>;

export function UserProfileLink({
  id,
  text,
  className,
  disabled,
  onClick,
  target,
  uat,
}: UserProfileLinkProps) {
  const hasAccess = useSelector(permissionsSelectors.isHasAccess);
  const backLink = useReturnableLink(`/users/${id}`);

  return hasAccess(userProfilePermissions) ? (
    <Link
      className={className}
      disabled={disabled}
      onClick={onClick}
      target={target}
      to={backLink}
      uat={uat}
    >
      {text}
    </Link>
  ) : (
    <LinkText>{text}</LinkText>
  );
}
