import { useReducer, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';
import { SelectItem } from '@components/form';
import { ButtonPrimary, ButtonSecondary, ButtonType } from '@components/button';
import {
  MFC,
  Modal,
  ModalTitle,
  ModalContent,
  ModalFooter,
  closeModalAction,
} from '@modules/modal';
import { FiltersForm } from './filters-form';
import { TableFilter } from './types';
import s from './index.module.scss';

type Props = {
  filterData: {
    assets: SelectItem[];
    currencies: SelectItem[];
    feeTypes: SelectItem[];
  };
  initialValues: TableFilter;
  onFilterApply: (filter: TableFilter) => void;
};

export const FiltersModal: MFC<Props> = (props) => {
  const dispatch = useDispatch();
  const selectedFiltersRef = useRef<TableFilter | null>(null);

  const [formKey, incrementFormKey] = useReducer((s) => s + 1, 0);
  const formInitialValues = formKey > 0 ? undefined : props.initialValues;

  const resetFilters = () => {
    incrementFormKey();
    selectedFiltersRef.current = {
      asset: null,
      clientId: null,
      currencyId: null,
      date: null,
      feeType: null,
    };
  };

  const updateSelectedFilters = (filter: TableFilter) => (selectedFiltersRef.current = filter);

  const closeModal = () => dispatch(closeModalAction(FiltersModal));

  const applyFiltersAndClose = () => {
    props.onFilterApply(selectedFiltersRef.current);
    closeModal();
  };

  return (
    <Modal isVisible={props.isVisible} onClose={closeModal}>
      <ModalTitle>
        <Trans id="fees-report.modal.title">Filters</Trans>
      </ModalTitle>
      <ModalContent>
        <FiltersForm
          key={formKey}
          filterData={props.filterData}
          onChange={updateSelectedFilters}
          initialValues={formInitialValues}
        />
      </ModalContent>
      <ModalFooter className={s.modalFooter}>
        <ButtonSecondary type={ButtonType.button} icon={null} onClick={resetFilters}>
          <Trans id="fees-report.modal.reset-filters">Reset</Trans>
        </ButtonSecondary>

        <ButtonPrimary onClick={applyFiltersAndClose}>
          <Trans id="fees-report.modal.apply-filters">Apply</Trans>
        </ButtonPrimary>
      </ModalFooter>
    </Modal>
  );
};
