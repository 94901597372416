import { LIST_FETCH, LIST_FILTER, LIST_SORT, LIST_UPDATE } from '@constants/redux';

export const listFetchAction = (listName, data, pagination) => ({
  type: LIST_FETCH,
  listName: listName,
  data: data,
  pagination: pagination,
});

export const listSortAction = (listName, sorts) => ({
  type: LIST_SORT,
  listName: listName,
  sorts: sorts,
});

export const listFilterAction = (listName, filters) => ({
  type: LIST_FILTER,
  listName: listName,
  filters: filters,
});

export const listUpdateAction = (listName, element, searchFunc) => ({
  type: LIST_UPDATE,
  listName,
  element,
  searchFunc,
});
