import React from 'react';
import { useLocation } from 'react-router-dom';
import { defineMessage, Trans } from '@lingui/macro';

import { Link } from '../components/link';
import { PageContent, PageTitle } from '@components/page';

import { NewTabs } from 'components/new-tabs';
import { PrimaryMarketDetailedProvider } from '../detailed/provider';
import { useModel } from './model';
import { OrderBook } from './order-book';
import { Portfolio } from './portfolio';
import { ProductChart } from './product-chart';
import { ProductSelector } from './product-selector';
import { TradeHistory } from './trade-history';
import './styles.deprecated.scss';
import './styles.scss';

const PrimaryMarketDetailedPage = ({ defaultCoin, defaultCurrency }) => {
  const {
    i18n,
    isTickerLoading,
    tabsHeight,
    setIsWeekPriceLoading,
    selectedPair,
    onDownloadInfoFileClicked,
    physicalRedemption,
    secondaryMarketFee,
  } = useModel();

  return (
    <PageContent className="market-page relative buy-metals-page" uat="buy-metals-page">
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.primary_market_detailed',
            message: 'Primary market (detailed)',
          })
        )}
        backwardLink={'/primary-market'}
      />
      <div
        className={`market-page-content row content-row ${isTickerLoading ? 'block-blurred' : ''}`}
      >
        <div className="col padding-bottom-0">
          <ProductSelector defaultCoin={defaultCoin} defaultCurrency={defaultCurrency} />
          <div className="market-page-content-row row content-row nowrap">
            <div className="market-page-content-row-col col-auto min-width-300">
              <Portfolio />
              <OrderBook />
            </div>
            <ProductChart onLoad={setIsWeekPriceLoading} />
          </div>
        </div>
        <div className="market-page-content-trade-history col-auto min-width-300">
          <TradeHistory />
        </div>
        <div className="col-12 market-page-info">
          <NewTabs
            linkWrapper={(links) => (
              <div>
                <div className="light-gradient-block gradient-border-block padding-bottom-0">
                  <div className="tab-links">{links}</div>
                </div>
              </div>
            )}
            tabs={[
              {
                title: (
                  <span>
                    <Trans id="buy_metals.general_tab">General</Trans>
                  </span>
                ),
                name: 'general',
                content: (
                  <div className="light-gradient-block font-size-12">
                    <div className="scroll-block" style={{ height: tabsHeight }}>
                      <div className="tab-block">
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.name_of_issuer">
                              Legal & Commercial Name of Issuer
                            </Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.issuer.displayName}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.name_of_product">
                              Name of product
                            </Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.name}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.brand_name">Brand name</Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.brandName}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.tm2_symbol">TM2 Symbol</Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.asset}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.phys_backing">Physical backing</Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.technicalSpecification}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.unit_denomination">
                              Unit & Denomination
                            </Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.coinInfo?.unitAndDenomination}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.physical_redemption">
                              Physical redemption
                            </Trans>
                          </span>
                          <span>:</span>
                          <div>{physicalRedemption}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.general.vault">Vault</Trans>
                          </span>
                          <span>:</span>
                          <div>{`${selectedPair?.coin?.vault?.city} ${selectedPair?.coin?.vault?.address}`}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: (
                  <span>
                    <Trans id="buy_metals.primary_market_info_tab">Primary market info</Trans>
                  </span>
                ),
                name: 'primary',
                content: (
                  <div className="light-gradient-block font-size-12">
                    <div className="scroll-block" style={{ height: tabsHeight }}>
                      <div className="tab-block">
                        <div
                          className={'text-columns-2'}
                          dangerouslySetInnerHTML={{
                            __html: selectedPair?.coin?.coinInfo?.primaryMarketProductInfo,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: (
                  <span>
                    <Trans id="buy_metals.product_info_tab">Product info</Trans>
                  </span>
                ),
                name: 'info',
                content: (
                  <div className="light-gradient-block font-size-12">
                    <div className="scroll-block" style={{ height: tabsHeight }}>
                      <div className="tab-block">
                        <div
                          className={'text-columns-2'}
                          dangerouslySetInnerHTML={{ __html: selectedPair?.coin?.productInfo }}
                        />
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: (
                  <span>
                    <Trans id="buy_metals.downloads">Downloads</Trans>
                  </span>
                ),
                name: 'downloads',
                content: (
                  <div className="light-gradient-block font-size-12">
                    <div className="scroll-block" style={{ height: tabsHeight }}>
                      <div className="tab-block">
                        <div className="row content-row">
                          {selectedPair?.coin?.infoFiles?.map((infoFile, index) => (
                            <div className="col-md-6" key={`${infoFile.title}-${index}`}>
                              <div className="light-gradient-block">
                                <div className="row">
                                  <div className="col">
                                    {infoFile.title || infoFile?.file?.name}
                                  </div>
                                  <div className="col-auto">
                                    <Link
                                      onClick={() => onDownloadInfoFileClicked(infoFile.file)}
                                      className="link link-icon color-blue"
                                    >
                                      <div className="icon-item">
                                        <i className="icon icon-size-14 icon--download" />
                                        <span className="icon-item__text">
                                          <Trans id="buy_metals_page.download">Download</Trans>
                                        </span>
                                      </div>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
              {
                title: (
                  <span>
                    <Trans id="buy_metals.fee_tab">Fee</Trans>
                  </span>
                ),
                name: 'fee',
                content: (
                  <div className="light-gradient-block font-size-12">
                    <div className="scroll-block" style={{ height: tabsHeight }}>
                      <div className="tab-block">
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.fee.execution">Execution fee, %</Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.sellCommission}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.fee.general">
                              Management Fee, % per annum
                            </Trans>
                          </span>
                          <span>:</span>
                          <div>{selectedPair?.coin?.managementFee}</div>
                        </div>
                        <div className={'text-columns-2'}>
                          <span>
                            <Trans id="digital_metals.fee.secondary">Secondary market fee, %</Trans>
                          </span>
                          <span>:</span>
                          <div>{secondaryMarketFee}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      </div>
    </PageContent>
  );
};

export default (props) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const coin = query.get('coin');
  const currency = query.get('currency');

  return (
    <PrimaryMarketDetailedProvider>
      <PrimaryMarketDetailedPage {...props} defaultCoin={coin} defaultCurrency={currency} />
    </PrimaryMarketDetailedProvider>
  );
};
