import { makeQuery, PermissionGroup } from '@permissions/core';
import { Deposit } from '../api';
import { v } from '@helper/typer/field-typer.helper';
import { stompClientService as stompClient } from '@services/stomp/client';
import sign from 'tm2sign.macro';

const cancelDepositQuery = 'cancelInvoice';
const cancelDepositFields = [
  'id',
  'number',
  'status',
  'amount',
  'creationDate',
  'clientComment',
  { investorUser: ['id', 'displayName'] },
  { payment: ['amount', 'accountDetails', 'creationDate'] },
  { bankAccount: ['id', { currency: ['code'] }] },
];
const cancelDeposit = makeQuery({
  queryName: cancelDepositQuery,
  queryFields: cancelDepositFields,
  query: (id: number): Promise<Deposit> => {
    return stompClient.sendData(cancelDepositQuery, sign(cancelDepositQuery, cancelDepositFields), {
      ...v.long({ id }),
    });
  },
});

const api = {
  cancelDeposit,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:cancel-deposit-modal');

export default api;
