import React, { FC, memo } from 'react';
import { Trans } from '@lingui/macro';
import { formatMoney } from 'containers/services/commonService';
import { ColumnKey, rowConfig, useModel } from './model';
import { LBStatusCell } from './status-cell';
import { permissionGroup as actionsRowPermissionGroup } from './actions-row';
import { permissionGroup as apiPermissionGroup } from './api';
import { ActionsRow } from './actions-row';
import { PermissionGroup } from '@permissions/permission-group';
import { profileOtherPagePermissionsGroup } from '@router/pages/profile/other/permissions';
import { Settlement } from './add-settlement-modal/api';
import { ProfileLinkCell, TablePane } from '@components/table2/utils';
import { Table, ColumnType } from '@components/table2/simplified';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, actionsRowPermissionGroup, profileOtherPagePermissionsGroup],
  marker: 'layout:listing-brokers-page',
});

const columns: ColumnType<Settlement>[] = [
  {
    key: ColumnKey.createdAt,
    dataIndex: 'createdAt',
    headCell: () => <Trans id="listing_brokers.datetime_v2">Date & time</Trans>,
    rowCell: ({ data }) => <>{data.createdAt?.formatLocalDateTime()}</>,
    width: 200,
  },
  {
    key: ColumnKey.recipient,
    dataIndex: 'recipient',
    headCell: () => <Trans id="listing_brokers.sender">Sender</Trans>,
    rowCell: ({ data }) => (
      <ProfileLinkCell text={data.recipient?.displayName} id={data.recipient?.id} />
    ),
    width: 200,
  },
  {
    key: ColumnKey.amount,
    dataIndex: 'amount',
    headCell: () => <Trans id="listing_brokers.amount">Amount</Trans>,
    rowCell: ({ data }) => (
      <>
        {formatMoney(data.amount)} {data.currency.code}
      </>
    ),
    width: 200,
  },
  {
    key: ColumnKey.status,
    dataIndex: 'status',
    headCell: () => <Trans id="listing_brokers.status">Status</Trans>,
    rowCell: ({ data }) => <LBStatusCell status={data.status} />,
    width: 200,
  },
];

export const ListingBrokersTab: FC = memo(() => {
  const m = useModel();

  return (
    <div className={s.verticalFiller}>
      <ActionsRow
        onSettlementPlaced={m.onSettlementPlaced}
        onLbsFiltersChanged={m.onLbsFiltersChanged}
      />

      <TablePane className={s.verticalFiller}>
        <Table
          columns={columns}
          instance={m.tableInstance}
          rowConfig={rowConfig}
          className={s.verticalFiller}
        />
      </TablePane>
    </div>
  );
});
