import { Trans } from '@lingui/macro';
import { Col, Row } from '@components/grid';
import type { Coin } from './types';

export type RedemptionProps = {
  redeemOptions?: Coin['redeemOptions'];
};

export function Redemption(props: RedemptionProps) {
  if (!props.redeemOptions) {
    return null;
  }

  if (!props.redeemOptions.length) {
    return (
      <Trans id={'digital_metals.coin_details.redeem_options.no_options'}>
        No redeem options available
      </Trans>
    );
  }

  return (
    <div className={'mb4'}>
      {props.redeemOptions.map((r) => (
        <div key={r.id}>
          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.redeemed_metal_form">
                  Redeemed metal form
                </Trans>
              </span>
              :
            </Col>
            <Col md={6}>{r.name}</Col>
          </Row>

          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.exchange_rate_units">
                  Exchange rate (units)
                </Trans>
              </span>
              :
            </Col>
            <Col md={6}>{r.amount}</Col>
          </Row>

          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.unit">Unit</Trans>
              </span>
              :
            </Col>
            <Col md={6}>{r.unit.label}</Col>
          </Row>

          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.min_redemption_output">
                  Min redemption output
                </Trans>
              </span>
              :
            </Col>
            <Col md={6}>{r.minimalQuantity}</Col>
          </Row>

          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.redemption_fee">
                  Redemption fee
                </Trans>
              </span>
              :
            </Col>
            <Col md={6}>{r.price}</Col>
          </Row>

          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.price_type">Unit type</Trans>
              </span>
              :
            </Col>
            <Col md={6}>{r.priceType}</Col>
          </Row>

          <Row>
            <Col md={6}>
              <span>
                <Trans id="digital_metals.coin_details.redeem_options.product_description">
                  Product description
                </Trans>
              </span>
              :
            </Col>
            <Col md={6}>
              {r.description ? (
                r.description
              ) : (
                <Trans id={'digital_metals.coin_details.redeem_options.product_description_none'}>
                  none
                </Trans>
              )}
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
}
