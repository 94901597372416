import { useMemo } from 'react';
import { useLocation } from 'react-router';

const backLinksParam = 'back-link';

function normalizeBackLinksFromUrl(links: string | string[]): string[] {
  return typeof links === 'string' ? [links] : links;
}

const useBackLinksFromUrl = (): string[] => {
  const { search: searchString } = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(searchString);
    const backLinks = params.get(backLinksParam);

    if (!backLinks) return [];

    return normalizeBackLinksFromUrl(JSON.parse(backLinks)) || [];
  }, [searchString]);
};

// NOTE: Returns absolute path.
export const useReturnableLink = (to: string): string => {
  const backLinksFromUrl = useBackLinksFromUrl();
  const { pathname: currPathname, search: currSearch, hash: currHash } = useLocation();

  return useMemo(() => {
    const currentLink = [currPathname, currSearch, currHash].join('');
    const returnableLink = new URL(to, window.location.origin);

    const params = new URLSearchParams(returnableLink.search);
    params.set(backLinksParam, JSON.stringify(backLinksFromUrl.concat(currentLink)));

    return [returnableLink.pathname, '?', params.toString(), returnableLink.hash].join('');
  }, [backLinksFromUrl, currPathname, currSearch, currHash, to]);
};

export const useLatestBackLink = (): string | null => {
  const backLinksFromUrl = useBackLinksFromUrl();
  return backLinksFromUrl[backLinksFromUrl.length - 1] ?? null;
};
