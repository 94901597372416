import React from 'react';
import { ColumnType } from '@components/table2/base';
import { Trans } from '@lingui/macro';
import { BankHistory } from './api';
import s from './index.module.scss';
import { dater } from '@helper/date';
import { TableStatuses } from './model';

export enum ColumnKey {
  dateTime = 'dateTime',
  idFromBank = 'idFromBank',
  createdAt = 'createdAt',
  updatedAt = 'updatedAt',
  method = 'method',
  senderId = 'senderId',
  senderName = 'senderName',
  receiverId = 'receiverId',
  receiverName = 'receiverName',
  purpose = 'purpose',
  reference = 'reference',
  sourceOfFunds = 'sourceOfFunds',
  currency = 'currency',
  amount = 'amount',
  settledAmount = 'settledAmount',
  receiverPreviousBalance = 'receiverPreviousBalance',
  receiverNewBalance = 'receiverNewBalance',
  status = 'status',
}

export const columns: Array<ColumnType<BankHistory>> = [
  {
    key: ColumnKey.currency,
    dataIndex: 'currency',
    headCell: () => <Trans id="banks.bank_account_history.currency">Currency</Trans>,
    rowCell: ({ data }) => <>{data.currency}</>,
    sorting: ['ascend', 'descend'],
    width: 200,
  },
  {
    key: ColumnKey.status,
    dataIndex: 'status',
    headCell: () => <Trans id="banks.bank_account_history.status">Status</Trans>,
    rowCell: ({ data }) => <div className={s.numberCell}>{data.status}</div>,
    width: 200,
  },
  {
    key: ColumnKey.amount,
    dataIndex: 'amount',
    headCell: () => <Trans id="banks.bank_account_history.amount">Amount</Trans>,
    rowCell: ({ data }) => <div className={s.numberCell}>{data.amount}</div>,
    width: 200,
  },
  {
    key: ColumnKey.settledAmount,
    dataIndex: 'settledAmount',
    headCell: () => <Trans id="banks.bank_account_history.settled_amount">Settled amount</Trans>,
    rowCell: ({ data }) => <div className={s.numberCell}>{data.settledAmount}</div>,
    width: 200,
  },
  {
    key: ColumnKey.receiverPreviousBalance,
    dataIndex: 'receiverPreviousBalance',
    headCell: () => (
      <Trans id="banks.bank_account_history.receiver_previous_balance">
        Receiver previous balance
      </Trans>
    ),
    rowCell: ({ data }) => <div className={s.numberCell}>{data.receiverPreviousBalance}</div>,
    width: 240,
  },
  {
    key: ColumnKey.receiverNewBalance,
    dataIndex: 'receiverNewBalance',
    headCell: () => (
      <Trans id="banks.bank_account_history.receiver_new_balance">Receiver new balance</Trans>
    ),
    rowCell: ({ data }) => <div className={s.numberCell}>{data.receiverNewBalance}</div>,
    width: 240,
  },
  {
    key: ColumnKey.dateTime,
    dataIndex: 'dateTime',
    headCell: () => <Trans id="banks.bank_account_history.date_settled">Date settled</Trans>,
    rowCell: ({ data }) => (
      <>
        {[TableStatuses.completed].includes(data.status as TableStatuses) &&
          dater.toLocalDateTime(data.dateTime)}
      </>
    ),
    sorting: ['ascend', 'descend'],
    width: 200,
  },
  {
    key: ColumnKey.createdAt,
    dataIndex: 'createdAt',
    headCell: () => <Trans id="banks.bank_account_history.date_created">Date created</Trans>,
    rowCell: ({ data }) => <>{dater.toLocalDateTime(data.createdAt)}</>,
    width: 200,
  },
  {
    key: ColumnKey.updatedAt,
    dataIndex: 'updatedAt',
    headCell: () => <Trans id="banks.bank_account_history.date_updated">Date updated</Trans>,
    rowCell: ({ data }) => <>{dater.toLocalDateTime(data.updatedAt)}</>,
    width: 200,
  },
  {
    key: ColumnKey.method,
    dataIndex: 'method',
    headCell: () => <Trans id="banks.bank_account_history.method">Method</Trans>,
    rowCell: ({ data }) => <>{data.method}</>,
    width: 200,
  },
  {
    key: ColumnKey.senderId,
    dataIndex: 'senderId',
    headCell: () => <Trans id="banks.bank_account_history.sender_id">Sender ID</Trans>,
    rowCell: ({ data }) => <div className={s.numberCell}>{data.senderId}</div>,
    width: 300,
  },
  {
    key: ColumnKey.senderName,
    dataIndex: 'senderName',
    headCell: () => <Trans id="banks.bank_account_history.sender_name">Sender name</Trans>,
    rowCell: ({ data }) => <>{data.senderName}</>,
    width: 200,
  },
  {
    key: ColumnKey.receiverId,
    dataIndex: 'receiverId',
    headCell: () => <Trans id="banks.bank_account_history.receiver_id">Receiver ID</Trans>,
    rowCell: ({ data }) => <div className={s.numberCell}>{data.receiverId}</div>,
    width: 300,
  },
  {
    key: ColumnKey.receiverName,
    dataIndex: 'receiverName',
    headCell: () => <Trans id="banks.bank_account_history.receiver_name">Receiver name</Trans>,
    rowCell: ({ data }) => <div className={s.numberCell}>{data.receiverName}</div>,
    width: 200,
  },
  {
    key: ColumnKey.purpose,
    dataIndex: 'purpose',
    headCell: () => <Trans id="banks.bank_account_history.purpose">Purpose</Trans>,
    rowCell: ({ data }) => <>{data.purpose}</>,
    width: 300,
    tooltip: true,
  },
  {
    key: ColumnKey.reference,
    dataIndex: 'reference',
    headCell: () => <Trans id="banks.bank_account_history.reference">Reference</Trans>,
    rowCell: ({ data }) => <>{data.reference}</>,
    width: 200,
  },
  {
    key: ColumnKey.sourceOfFunds,
    dataIndex: 'sourceOfFunds',
    headCell: () => <Trans id="banks.bank_account_history.source_of_funds">Source of funds</Trans>,
    rowCell: ({ data }) => <>{data.sourceOfFunds}</>,
    width: 300,
    tooltip: true,
  },
  {
    key: ColumnKey.idFromBank,
    dataIndex: 'idFromBank',
    headCell: () => <Trans id="banks.bank_account_history.tx_id">Transaction ID (bank)</Trans>,
    rowCell: ({ data }) => <>{data.idFromBank}</>,
    width: 300,
  },
];
