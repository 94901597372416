import { useMemo } from 'react';

import { useQuery } from '@hooks/core';
import { SelectItem } from '@components/form';
import { useBankAccountTypeTranslations } from '../../../hooks';
import api, { BankAccountForm as Form } from './api';

export const useAccountFormInitial = (id: number) => {
  useBankAccountTypes();
  const { data: initialAccount, isLoading: isInitialAccountLoading } = useQuery(
    () => (id ? api.getBankAccount(id) : Promise.resolve<Form>({} as Form)),
    { initial: {} as Form }
  );
  return {
    initialAccount,
    isInitialAccountLoading,
  };
};

export const useBankAccountTypes = () => {
  const { data, isLoading: isBankAccountTypesLoading } = useQuery(api.getBankAccountTypes, {
    initial: [],
  });
  const translations = useBankAccountTypeTranslations();

  const bankAccountTypes: Array<SelectItem> = useMemo(
    () =>
      data.map((type) => ({
        label: translations[type],
        value: type,
      })),
    [data]
  );

  return {
    bankAccountTypes,
    isBankAccountTypesLoading,
  };
};

export const useBanks = () => {
  const { data, isLoading: isBanksLoading } = useQuery(api.getBanks, { initial: [] });

  const banks: Array<SelectItem> = useMemo(
    () =>
      data.map((bank) => ({
        label: bank.bankName,
        value: bank.id,
      })),
    [data]
  );

  return {
    banks,
    isBanksLoading,
  };
};
