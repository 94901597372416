import sign from 'tm2sign.macro';

import { v } from '@helper/typer/field-typer.helper';
import { stompClientService } from '@services/stomp/client';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { TransactionForm } from '../shared/api';
// ----------------------------------------------------------------
export type { TransactionForm } from '../shared/api';
export { TransactionFields } from '../shared/api';
const confirmTransactionFields = ['id'];
const confirmTransactionQuery = 'backofficeCreateExtendedTransaction';
const confirmTransaction = makeQuery({
  permissions: { and: [Permission.BACK_OFFICE_ACCESS] },
  queryName: confirmTransactionQuery,
  queryFields: confirmTransactionFields,
  query: (extendedTransaction: TransactionForm): Promise<number> => {
    const variables = v.createExtendedTransactionInput({ extendedTransaction });
    return stompClientService
      .sendData(
        confirmTransactionQuery,
        sign(confirmTransactionQuery, confirmTransactionFields),
        variables
      )
      .then((response) => response.id);
  },
});
// ----------------------------------------------------------------
const api = {
  confirmTransaction,
};

export const permissionsGroup = PermissionGroup.extract(api, 'api:cm-confirm-transaction');

export default api;
