import type { BaseItem, ColumnConfig, RowConfig } from '@components/table2/base';

export const tableRowHeight = {
  normal: 41,
};

export const tableRowCfgDefault: RowConfig<BaseItem> = {
  height: tableRowHeight.normal,
};

export const tableColCfgDefault: ColumnConfig = {
  sorting: ['ascend', 'descend'],
};
