import { useMemo } from 'react';
import { NamePath } from 'rc-field-form/lib/interface';

export const useFieldUat = (namePath: NamePath, postfix: string, uat: string) => {
  const uatAttribute = useMemo((): string => {
    const name =
      namePath instanceof Array
        ? namePath.reduce((acc: string, item: NamePath, i: number) => {
            if (Number.isInteger(+item)) {
              return `${acc}[${item}].`;
            }
            return i === namePath.length - 1 ? `${acc}${uat || item}` : `${acc}[${item}]`;
          }, '')
        : namePath;
    return `${name}-${postfix}`.replace(/\./g, '_');
  }, [namePath, postfix, uat]);

  return uatAttribute;
};

export const useSelectOptionsUat = (namePath: NamePath, postfix: string, uat: string) => {
  const uatClassName = useMemo((): string => {
    const name =
      namePath instanceof Array
        ? namePath.reduce((acc: string, item: NamePath, i: number) => {
            if (Number.isInteger(+item)) {
              return `${acc}I${item}I_`;
            }
            return i === namePath.length - 1 ? `${acc}${uat || item}` : `${acc}[${item}]`;
          }, '')
        : namePath;
    return `${name}-${postfix}`.replace(/\./g, '_');
  }, [namePath, postfix, uat]);

  return uatClassName;
};
