import { useContext } from 'react';

import { Filter } from '../types';
import { Context } from '../context';

export const formId = 'bankAccountBalances';

export const maxDate = new Date().toISOString();

export enum Fields {
  bankAccountId = 'bankAccountId',
  period = 'period',
}

export const useModel = () => {
  const { dispatch } = useContext(Context);

  const onFilterChanged = (filters: Filter) =>
    dispatch({ payload: filters, type: 'ON_FILTER_CHANGED' });

  return {
    onFilterChanged,
  };
};
