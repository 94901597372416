import { useContext, useState, useEffect } from 'react';
import { useCallbackOne } from 'use-memo-one';
import classnames from 'classnames';
import { LayoutContext, useBlockHeight } from '@modules/layout';
import { handleBackendError } from '@modules/notify';
import api from './api';
import themeStyle from '@styles/theme/index.module.scss';
import s from './index.module.scss';

export function PageTopBanner() {
  const [notificationText, setNotificationText] = useState('');
  const { updateLayoutValues } = useContext(LayoutContext);

  const updateBannerHeight = useCallbackOne((height: number) => {
    updateLayoutValues({
      pageTopBannerHeight: height,
    });
  }, []);
  const { ref: containerRef } = useBlockHeight(updateBannerHeight);

  useEffect(() => {
    api
      .getGeneralPreferences()
      .then((data) => {
        if (data.systemNotification.active) {
          setNotificationText(data.systemNotification.text);
        }
      })
      .catch(handleBackendError);

    return () => {
      updateLayoutValues({
        pageTopBannerHeight: 0,
      });
    };
  }, []);

  return notificationText ? (
    <div
      ref={containerRef}
      className={classnames('pl3', 'pr3', s.banner, themeStyle.themeContrast)}
    >
      <span className={s.text}>{notificationText}</span>
    </div>
  ) : null;
}
