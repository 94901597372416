import React, { useEffect } from 'react';

import './styles.scss';
import { Col, Row } from '@components/grid';
import {
  Button,
  ButtonColor,
  ButtonIconPure,
  ButtonTextIconBig,
  ButtonPrimary,
  ButtonSecondary,
  ButtonIconBordered,
  ButtonText,
  ButtonType,
  ButtonBack,
} from '@components/button';
import { ReactComponent as AddIcon } from '@images/icons/add-rounded-icon.svg';
import { ReactComponent as TestIcon } from '@images/icons/sidebar-market.svg';
import { ReactComponent as UploadIcon } from '@images/icons/upload-icon.svg';

export const UIKitPage = () => {
  useEffect(() => {
    document.querySelector('.tm2-global-preloader').style.display = 'none';
  }, []);

  return (
    <div className="ui-kit-page py-md">
      <Row className="px-md">
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
        <Col xs={6} sm={4} md={3} lg={2} xl={1}>
          col
        </Col>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <ButtonPrimary className="mr-md mb-md">Primary button</ButtonPrimary>
        <ButtonSecondary className="mr-md mb-md">Secondary button</ButtonSecondary>
        <ButtonText className="mr-md mb-md" disabled={true} loading={true}>
          Text btn
        </ButtonText>
        <ButtonTextIconBig className="mr-md mb-md" icon={<AddIcon className="tm2-icon-stroke" />}>
          Add
        </ButtonTextIconBig>
        <ButtonIconBordered
          className="mr-md mb-md"
          icon={<TestIcon className="tm2-icon-stroke" />}
        />
        <ButtonIconPure className="mr-md mb-md" icon={<TestIcon className="tm2-icon-stroke" />} />
        <ButtonBack onClick={() => alert('button back')} />
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={null}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={false}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={true}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={true}
        >
          Primary
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          disabled={false}
          icon={null}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          disabled={false}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="md"
          disabled={true}
          loading={true}
        >
          Primary
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="sm"
          disabled={false}
          icon={null}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="sm"
          disabled={false}
          loading={false}
        >
          Primary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Primary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.primary}
          size="sm"
          disabled={true}
          loading={true}
        >
          Primary
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={null}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          fullWidth={true}
          disabled={false}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          fullWidth={true}
          disabled={true}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={true}
        >
          Secondary
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          disabled={false}
          icon={null}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          disabled={false}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="md"
          disabled={true}
          loading={true}
        >
          Secondary
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="sm"
          disabled={false}
          icon={null}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="sm"
          disabled={false}
          loading={false}
        >
          Secondary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Secondary
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.secondary}
          size="sm"
          disabled={true}
          loading={true}
        >
          Secondary
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={null}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          fullWidth={true}
          disabled={false}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          fullWidth={true}
          disabled={true}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={true}
        >
          Danger
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          disabled={false}
          icon={null}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          disabled={false}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="md"
          disabled={true}
          loading={true}
        >
          Danger
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="sm"
          disabled={false}
          icon={null}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="sm"
          disabled={false}
          loading={false}
        >
          Danger
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Danger
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.button}
          color={ButtonColor.danger}
          size="sm"
          disabled={true}
          loading={true}
        >
          Danger
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={null}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          fullWidth={true}
          disabled={false}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          fullWidth={true}
          disabled={true}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          icon={null}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="sm"
          disabled={false}
          icon={null}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="sm"
          disabled={false}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={null}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={false}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          fullWidth={true}
          disabled={true}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          disabled={false}
          icon={null}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          disabled={false}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          disabled={false}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="md"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="sm"
          disabled={false}
          icon={null}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="sm"
          disabled={false}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.outline}
          color={ButtonColor.primary}
          size="sm"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyBordered}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyBordered}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyBordered}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyBordered}
          color={ButtonColor.light}
          size="sm"
          disabled={false}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyBordered}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyBordered}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyPure}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyPure}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyPure}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyPure}
          color={ButtonColor.light}
          size="sm"
          disabled={false}
          icon={<UploadIcon className="tm2-icon-stroke" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyPure}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={false}
        >
          Outline
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.iconOnlyPure}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          loading={true}
        >
          Outline
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.textIconBig}
          color={ButtonColor.light}
          size="md"
          icon={<AddIcon className="tm2-icon-stroke" />}
          disabled={false}
          loading={false}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.textIconBig}
          color={ButtonColor.light}
          size="md"
          icon={<AddIcon className="tm2-icon-stroke" />}
          disabled={true}
          loading={true}
        >
          Text btn
        </Button>
      </Row>

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          icon={null}
          loading={false}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="md"
          disabled={false}
          loading={false}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="md"
          disabled={true}
          loading={true}
        >
          Text btn
        </Button>
      </Row>

      <hr className="mb-md" />

      <Row className="px-md mx-0">
        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="sm"
          disabled={false}
          icon={null}
          loading={false}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="sm"
          disabled={false}
          loading={false}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          icon={<TestIcon className="tm2-icon-fill" />}
          loading={true}
        >
          Text btn
        </Button>

        <Button
          className="mr-md mb-md"
          type={ButtonType.text}
          color={ButtonColor.light}
          size="sm"
          disabled={true}
          loading={true}
        >
          Text btn
        </Button>
      </Row>

      <hr className="mb-md" />

      <div className="pt-md" style={{ backgroundColor: '#FFFFFF' }}>
        <Row className="px-md mx-0">
          <Button
            className="mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            fullWidth={true}
            disabled={false}
            icon={null}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            fullWidth={true}
            disabled={false}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            fullWidth={true}
            disabled={false}
            icon={<TestIcon className="tm2-icon-fill" />}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            fullWidth={true}
            disabled={true}
            icon={<UploadIcon className="tm2-icon-stroke" />}
            loading={true}
          >
            Outline
          </Button>
        </Row>

        <hr className="mb-md" />

        <Row className="px-md mx-0">
          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            disabled={false}
            icon={null}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            disabled={false}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            disabled={false}
            icon={<TestIcon className="tm2-icon-fill" />}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="md"
            disabled={true}
            loading={true}
          >
            Outline
          </Button>
        </Row>

        <hr className="mb-md" />

        <Row className="px-md mx-0">
          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="sm"
            disabled={false}
            icon={null}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="sm"
            disabled={false}
            loading={false}
          >
            Outline
          </Button>

          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="sm"
            disabled={true}
            icon={<TestIcon className="tm2-icon-fill" />}
            loading={true}
          >
            Outline
          </Button>

          <Button
            className="mr-md mb-md"
            type={ButtonType.outline}
            color={ButtonColor.dark}
            size="sm"
            disabled={true}
            loading={true}
          >
            Outline
          </Button>
        </Row>
      </div>

      <hr className="mb-md" />
    </div>
  );
};
