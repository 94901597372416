import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

export enum Fields {
  assetCode = 'assetCode',
  umsAssetCode = 'umsAssetCode',
  decimals = 'decimals',
  assetFullName = 'assetFullName',
  status = 'status',
  assetSubclass = 'assetSubclass',
  participantId = 'participantId',
}

export interface Form {
  [Fields.assetCode]: string | undefined;
  [Fields.umsAssetCode]: string;
  [Fields.decimals]: string;
  [Fields.assetFullName]: string;
  [Fields.status]: string;
  [Fields.assetSubclass]: string;
  [Fields.participantId]: string;
}

const createUMSAssetQuery = 'createAssetOnSecondary';
const createUMSAssetFields = ['code'];
const createUMSAsset = makeQuery({
  queryName: createUMSAssetQuery,
  queryFields: createUMSAssetFields,
  query: (dto: Form): Promise<string> => {
    return stompClientService
      .sendData(createUMSAssetQuery, sign(createUMSAssetQuery, createUMSAssetFields), {
        ...v.assetCreateOnSecondaryInput({ assetCreateOnSecondary: dto }),
      })
      .then((response) => response.code);
  },
});

type InitialValues = {
  asset: string;
  name: string;
};
const getInitialValuesQuery = 'coinDetailed';
const getInitialValuesFields = ['asset', 'name'];
const getInitialValues = makeQuery({
  queryName: getInitialValuesQuery,
  queryFields: getInitialValuesFields,
  query: (coinId: number): Promise<InitialValues> => {
    return stompClientService.getData(
      getInitialValuesQuery,
      sign(getInitialValuesQuery, getInitialValuesFields),
      { ...v.idInput({ coin: { id: coinId } }) }
    );
  },
});

const api = {
  createUMSAsset,
  getInitialValues,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:create-ums-asset-modal');

export default api;
