import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { PageTable } from 'components/page-table';
import { ReactComponent as BadTrustIcon } from '../../../../assets/img/admin/icons/bad-trust.svg';
import { metalsPageSelectors as requestsSelectors } from '../store/selectors';
import { metalsPageUserActions as requestsUserActions } from '../store/user.acions';
import { MetalsTableColumnIds as ColumnIds } from '../store/model';
import { IssuerCell } from './issuer-cell';

const mapStateToProps = (root) => ({
  columnSortDirection: requestsSelectors.columnSortDirection(root),
  isHasMore: requestsSelectors.isHasMore(root),
  isTableLoading: requestsSelectors.isTableLoading(root),
  metals: requestsSelectors.metals(root),
});

const mapDispatchToProps = (dispatch) => ({
  onTableScrolled: () => dispatch(requestsUserActions.onTableScrolled()),
  onTableSorted: (columnInfo) => dispatch(requestsUserActions.onTableSorted(columnInfo)),
});

const ConnectedMetalsTable = ({
  columnSortDirection,
  isHasMore,
  isTableLoading,
  metals,
  onTableScrolled,
  onTableSorted,
}) => {
  return (
    <PageTable
      onLoadMore={() => onTableScrolled()}
      isLoadingInProgress={isTableLoading}
      isShowLoader={true}
      initialLoad={true}
      pageData={{
        data: metals,
        hasMore: isHasMore,
      }}
      columns={[
        {
          cell: (item) => <span>{item.metal.label}</span>,
          head: {
            body: <Trans id="metals_page.table.head.metal">Metal</Trans>,
            // TODO для сортировки раскомментить тут во всех ячейках и в апи сервисе
            // onSort: (direction) => onTableSorted({id: ColumnIds.metal, direction: direction}),
            // sortDirection: columnSortDirection(ColumnIds.metal),
          },
        },
        {
          cell: (item) => <span>{item.asset}</span>,
          head: {
            body: <Trans id="metals_page.table.head.symbol">Symbol</Trans>,
            // onSort: (direction) => onTableSorted({id: ColumnIds.symbol, direction: direction}),
            // sortDirection: columnSortDirection(ColumnIds.symbol),
          },
        },
        {
          cell: (item) => <IssuerCell issuer={item.issuer} />,
          head: {
            body: <Trans id="metals_page.table.head.issuer">Issuer</Trans>,
            // onSort: (direction) => onTableSorted({id: ColumnIds.issuer, direction: direction}),
            // sortDirection: columnSortDirection(ColumnIds.issuer),
          },
        },
        {
          cell: (item) =>
            item.haveSecondaryTrust ? null : (
              <Tooltip
                placement="bottomLeft"
                trigger={[TooltipTrigger.hover]}
                title={
                  <Trans id="metals_page.tooltip.bad_trust">
                    The secondary market wallet don't have trust with the asset
                  </Trans>
                }
              >
                <BadTrustIcon />
              </Tooltip>
            ),
        },
      ]}
    />
  );
};

export const MetalsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedMetalsTable);
