import classnames from 'classnames';

export enum LinkColor {
  dark = 'dark',
  primary = 'primary',
  secondary = 'secondary',
}

export enum LinkType {
  button = 'button',
  text = 'text',
  outline = 'outline',
}

export enum LinkSize {
  sm = 'small',
  md = 'middle',
  lg = 'large',
}

export const useLinkModel = ({ className, color, disabled, size, type }) => {
  const typeClass = classnames({
    'tm2-link-button': type === LinkType.button,
    'tm2-link-text': type === LinkType.text,
    'tm2-link-outline': type === LinkType.outline,
  });

  const colorClass = classnames({
    'tm2-link-dark': color === LinkColor.dark,
    'tm2-link-primary': color === LinkColor.primary,
    'tm2-link-secondary': color === LinkColor.secondary,
  });

  const sizeClass = classnames({
    'tm2-link-small': size === LinkSize.sm,
    'tm2-link-middle': size === LinkSize.md,
    'tm2-link-large': size === LinkSize.lg,
  });

  const combinedClass = classnames(className, 'tm2-link', typeClass, colorClass, sizeClass, {
    'is-disabled': disabled,
  });

  return { combinedClass };
};
