import React from 'react';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { FormInstance } from 'antd';
import { Trans } from '@lingui/macro';

import { handleBackendError, notifySuccess } from '@modules/notify';
import { Put } from '@helper/redux';
import { closeModalAction, showModalAction } from '@modules/modal';
import {
  LiveUpdateEventType as LiveEvent,
  liveUpdateService as liveUpdate,
} from '@services/stomp/live-update';
import { formService, OnFormAction } from '@components/form';
import { DispatchThunkFn, GetStateFn, RootState } from '@models/redux';
import v from '@components/form/validators';
import { roundDown } from '@helper/number';
import { TransferDirection } from '../../model';
import { transferModalTranslations as transferTranslations } from '../../translations';
import { FinishStableTransferModal } from '../finish-modal';
import api from '../api';
import { transferStableTabServiceActions } from './service.acions';
import { transferStableTabSelectors as selectors } from './selectors';
import {
  maxDecimalsSMStable,
  TransferStableFields,
  transferStableFormId,
  TransferStableTabActionsTypes as Types,
} from './model';
import { TypedError } from '@services/stomp/errors';
import { showTransferErrorModal } from '../../service';

export function* transferStableTabEffects() {
  yield all([
    takeEvery(Types.ON_TAB_OPENED, onTabOpened),
    takeEvery(
      Types.ON_CANCEL_FINISH_STABLE_TRANSFER_CLICKED,
      Put(closeModalAction, FinishStableTransferModal)
    ),
    takeEvery(Types.UPDATE_FORM_VALUES, updateFormValues),
    takeEvery(Types.ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED, updateFormValues),
    takeEvery(Types.ON_MAXIMIZE_STABLE_AMOUNT_TRANSFER_CLICKED, maximizeStableAmount),
    takeEvery(Types.ON_SELECTED_VALUE_CHANGED, resetStableAmount),
  ]);
}

function* onTabOpened() {
  try {
    const isTabInitiated: boolean = yield select(selectors.isTabInitiated);
    if (!isTabInitiated) {
      yield put(transferStableTabServiceActions.getStableInfo());
      const { primaryStableBalances, secondaryStableBalances } = yield api.getTransferInfo();
      yield put(
        transferStableTabServiceActions.setStableInfo({
          primaryBalances: primaryStableBalances,
          secondaryBalances: secondaryStableBalances,
        })
      );
      yield put(transferStableTabServiceActions.getStableInfoSuccess());
    }
    yield put(transferStableTabServiceActions.updateFormValues());
  } catch (error) {
    yield put(transferStableTabServiceActions.getStableInfoFail());
    handleBackendError(error);
  }
}

function* updateFormValues() {
  try {
    const initialAsset = yield select(selectors.initialAsset);
    const form: FormInstance = yield formService.get(transferStableFormId);
    form?.setFieldsValue({ asset: initialAsset, amount: null });
    yield put(transferStableTabServiceActions.updateFormValuesSuccess());
  } catch (error) {
    yield put(transferStableTabServiceActions.updateFormValuesFail(error as Error));
    handleBackendError(error);
  }
}

function* maximizeStableAmount() {
  try {
    const form: FormInstance = yield formService.get(transferStableFormId);
    const selectedAsset = form?.getFieldsValue()?.asset;
    const currentDirection = yield select(selectors.transferDirection);
    const currentAssets =
      currentDirection === TransferDirection.fromPrimary
        ? yield select(selectors.primaryStableBalances)
        : yield select(selectors.secondaryStableBalances);
    const maximalQuantity = currentAssets.filter((el) => el.asset === selectedAsset)[0]?.balance;
    form?.setFieldsValue({
      asset: selectedAsset,
      amount: roundDown(maximalQuantity, maxDecimalsSMStable),
    });
  } catch (error) {
    handleBackendError(error);
  }
}

function* resetStableAmount() {
  try {
    const form: FormInstance = yield formService.get(transferStableFormId);
    const selectedAsset = form?.getFieldsValue()?.asset;
    form?.setFieldsValue({ asset: selectedAsset, amount: null });
  } catch (error) {
    handleBackendError(error);
  }
}

export const onTransferStableContinueButtonClicked: OnFormAction = {
  error: (error: Error) => {
    handleBackendError(error);
  },
  submit: async (form, dispatch: DispatchThunkFn) => {
    await dispatch(showModalAction({ modal: FinishStableTransferModal }));
  },
  fieldValidation: {
    [TransferStableFields.asset]: [v.required],
    [TransferStableFields.amount]: [v.required],
  },
};

export const onFinishStableTransferButtonClicked: OnFormAction = {
  error: async (error: Error, dispatch: DispatchThunkFn) => {
    await dispatch(transferStableTabServiceActions.finishStableTransferFail(error));
    if (error instanceof TypedError) {
      showTransferErrorModal(dispatch);
    } else {
      handleBackendError(error);
    }
  },
  submit: async (form, dispatch: DispatchThunkFn, getState: GetStateFn) => {
    const root: RootState = getState();
    await dispatch(transferStableTabServiceActions.finishStableTransfer());
    const direction = selectors.transferDirection(root);
    const { asset, amount } = form;
    let fromMarket;
    let toMarket;

    if (direction === TransferDirection.fromPrimary) {
      fromMarket = transferTranslations[TransferDirection.fromPrimary];
      toMarket = transferTranslations[TransferDirection.fromSecondary];
      await api.sendStableFromPrimaryToSecondary({ amount, asset });
    } else {
      fromMarket = transferTranslations[TransferDirection.fromSecondary];
      toMarket = transferTranslations[TransferDirection.fromPrimary];
      await api.sendStableFromSecondaryToPrimary({ amount, asset });
    }

    liveUpdate.emit({ type: LiveEvent.stableBalanceChanged });
    notifySuccess({
      title: (
        <Trans id={'market_transfer_modal.funds.notify.processed_transfer_title'}>
          Transfer processing
        </Trans>
      ),
      text: (
        <Trans id={'market_transfer_modal.funds.notify.processed_transfer_text'}>
          The transfer of {asset} {amount} from the {fromMarket} to the {toMarket} market is being
          processed
        </Trans>
      ),
    });

    await dispatch(transferStableTabServiceActions.finishStableTransferSuccess());
    await dispatch(closeModalAction());
  },
};
