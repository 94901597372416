import { CompatClient } from '@stomp/stompjs';
import { I18n } from '@lingui/core';

import { Role } from '@permissions/core';
import { History, Lang, VerificationStatus } from '@models/core';
import { translate } from '@services/translate';
import { session } from '@services/session';
import { SystemPreferences } from '../config-api.service';
import { ConfigServiceActionsTypes } from './service.acions';
import { ConfigUserActionsTypes } from './user.acions';

/**
 * Represents shared for all app current user info
 */
export interface UserInfo {
  // TODO тут оставить только самые shared поля, всю остальную логику получать локально в апи
  accountingFormSend: boolean;
  accountOpeningStep: OnboardingStep;
  address: string;
  blockedCoins: Array<BlockedCoin>;
  birthday: Date;
  company: string;
  country: {
    label: string;
    id: number;
  };
  displayName: string;
  email: string;
  firstName: string;
  id: number;
  investorIssuer: InvestorIssuer;
  isShowOnboardHelp: boolean;
  isShowCustomerSurvey: boolean;
  issuerInvestor: IssuerInvestor;
  kyc: {
    state: KycState;
  };
  lang: Lang;
  lastName: string;
  lastPostRegStage: PostRegSteps;
  nationality: {
    label: string;
    id: number;
  };
  permissions: string;
  phone: string;
  role: {
    name: Role;
  };
  umsId: number;
  userTariff: {
    tariff: {
      permissions: Array<string>;
    };
  };
  verificationStatus: VerificationStatus;
}

export interface SetAppLanguagePayload {
  i18n: I18n;
  lang: Lang;
}

export interface BlockedCoin {
  id: number;
}

export interface InvestorIssuer {
  email: string;
}

export interface IssuerInvestor {
  email: string;
  id: number;
  stablePublicKey: string;
}

export enum AccountType {
  company = 'COMPANY',
  individual = 'INDIVIDUAL',
  unknown = 'UNKNOWN', // if you see this in UI, than something went wrong
}

// бекенд возвращает не текущий шаг постреги, а последний успешно пройденный,
// поэтому в енуме бека нет первого (авторизация в КК) и последнего шагов.
// вместо них будут возвращаться null или FINISH соответственно
// на фронте в енум добавлены эти "несуществующие" шаги с поефиксом LOCAL_
export enum PostRegSteps {
  indKK = 'LOCAL_INDIVIDUAL_CREATE_ACCOUNT', // 1 keycloak
  indContact = 'PROVIDE_CONTACT_INFORMATION', // 2
  indAddress = 'LOCAL_INDIVIDUAL_ADDRESS_INFO', // 3
  indVerificationSuggestion = 'LOCAL_INDIVIDUAL_VERIFICATION_SUGGESTION', // 4
  indVerificationUpload = 'LOCAL_INDIVIDUAL_VERIFICATION_UPLOAD', // 5 only if a user choose to supply docs

  instKK = 'LOCAL_CORPORATE_CREATE_ACCOUNT', // 1 keycloak
  instCompany = 'COMPANY_DETAILS', // 2
  instRegulated = 'REGULATED_OR_LISTED_STATUS', // 3
  instContact = 'LOCAL_CORPORATE_CONTACT_DETAILS', // 4

  finish = 'FINISH',
}

export enum OnboardingStep {
  bankDetails = 5,
  eWalletKey = 1,
  hubspotForm = 2,
  finished = 7,
  funds = 6,
  noMembership = 3,
  sumSubForm = 4,
  unknown = 0,
}

export enum KycState {
  approved = 'APPROVED',
  disapproved = 'DISAPPROVE',
  pending = 'PENDING',
  preApproved = 'PRE_APPROVED',
  retry = 'RETRY',
  undefined = 'UNDEFINED',
}

export enum TariffsIds {
  noMembership = 1,
}

export enum TariffCodes {
  noMembership = 'NO_MEMBERSHIP',
  standardCompany = 'STANDARD_IM',
  standardIndividual = 'STANDART_IND',
}

export enum TariffsPermissions {
  confirmOffer = 'CONFIRM_OFFER',
  kycCheck = 'KYC_CHECK',
}

export interface BannerConfig {
  image: string;
  isShow: boolean;
  view: string;
}

export interface ConfigState {
  bannerConfig: SystemPreferences['banner'];
  history: History;
  i18n: I18n;
  isAppConfigured: boolean;

  // This flag is used to avoid redirecting to profile page after finishing
  // last post registration step on backend. It is necessary to show
  // verification step for individual user. Without this flag, live
  // reload of the user object (done via subscription) will force opening
  // profile page. It is intentionally not preserved in any kind of
  // storage or on the backend.
  isPostRegInProgress: boolean;

  isShowGlobalPreloader: boolean;
  isUserInfoLoading: boolean;
  isWSConnected: boolean;
  lang: Lang;
  userInfo: UserInfo;
  wsInstance: CompatClient;
}

export const configStateInitial: ConfigState = {
  bannerConfig: {} as SystemPreferences['banner'],
  history: null,
  i18n: translate.i18n,
  isAppConfigured: false,
  isPostRegInProgress: false,
  isShowGlobalPreloader: false,
  isUserInfoLoading: false,
  isWSConnected: false,
  lang: session.getCurrentLanguage(),
  userInfo: {} as UserInfo,
  wsInstance: null,
};

export enum ConfigActionsTypes {
  CHECK_APP_LANGUAGE = 'APP_CONFIG.CHECK_APP_LANGUAGE',
  CHECK_TUTORIAL_EVENT = 'APP_CONFIG.CHECK_TUTORIAL_EVENT',
  CONFIGURE_APPLICATION = 'APP_CONFIG.CONFIGURE_APPLICATION',
  CONFIGURE_APPLICATION_SUCCESS = 'APP_CONFIG.CONFIGURE_APPLICATION_SUCCESS',
  GET_USER_INFO = 'APP_CONFIG.GET_USER_INFO',
  GET_USER_INFO_FAIL = 'APP_CONFIG.GET_USER_INFO_FAIL',
  GET_USER_INFO_SUCCESS = 'APP_CONFIG.GET_USER_INFO_SUCCESS',
  LOG_OUT_SESSION = 'APP_CONFIG.LOG_OUT_SESSION',
  ON_LOG_OUT_CLICKED = 'APP_CONFIG.ON_LOG_OUT_CLICKED',
  POST_REG_FINISHED = 'APP_CONFIG.POST_REG_FINISHED',
  POST_REG_INITIATED = 'APP_CONFIG.POST_REG_INITIATED',
  SET_APP_LANGUAGE = 'APP_CONFIG.SET_APP_LANGUAGE',
  SET_HISTORY_INSTANCE = 'APP_CONFIG.SET_HISTORY_INSTANCE',
  SET_SYSTEM_PREFERENCES = 'APP_CONFIG.SET_SYSTEM_PREFERENCES',
  TOGGLE_GLOBAL_PRELOADER = 'APP_CONFIG.TOGGLE_GLOBAL_PRELOADER',
  WEBSOCKET_CONNECTED = 'APP_CONFIG.WEBSOCKET_CONNECTED',
  WEBSOCKET_DISCONNECTED = 'APP_CONFIG.WEBSOCKET_DISCONNECTED',
}

export type ConfigActions = ConfigServiceActionsTypes | ConfigUserActionsTypes;
