import { useMemo, useState } from 'react';
import { Trans } from '@lingui/macro';
import { formService } from '@components/form';
import { notifySuccess } from '@modules/notify';
import { useIsMountedRef } from 'containers/services/commonService';
import api from './api';
import { Offer } from './types';

export type ModelParams = {
  activePrices: Offer['activePrices'];
  offerId: number;
  onClose: () => void;
  onOfferUpdate: (offer: Offer) => void;
};

const FORM_ID = 'edit-offer-modal';

const priceNameTemplate = (index) => `newPrices[${index}]`;

export const useEditOfferModel = ({
  activePrices: activePricesRaw,
  offerId,
  onClose,
  onOfferUpdate,
}: ModelParams) => {
  const isMountedRef = useIsMountedRef();
  const [activePrices] = useState<Offer['activePrices']>(activePricesRaw);
  const [isProcessingSubmit, setIsProcessingSubmit] = useState(false);

  const prices = useMemo(
    () =>
      activePrices.map((price, index) => ({
        currencyCode: price.currencyCode,
        currencyId: price.currencyId,
        initialPrice: price.quantity,
        name: priceNameTemplate(index),
        unit: price.unitLabel,
      })),
    [activePrices]
  );

  const formInitialValues = useMemo(
    () => prices.reduce((acc, p) => ({ ...acc, [p.name]: p.initialPrice }), {}),
    [prices]
  );

  const updatePrices = () => {
    const formValues = formService.get(FORM_ID)?.getFieldsValue();
    const newPrices = prices.reduce(
      (acc, p) => [
        ...acc,
        {
          currencyId: p.currencyId,
          quantity: formValues[p.name],
        },
      ],
      []
    );

    setIsProcessingSubmit(true);
    api
      .changeOfferPrices({
        offerId,
        prices: newPrices,
      })
      .then((data: Offer) => {
        onClose();
        onOfferUpdate(data);

        notifySuccess({
          defaultTitle: true,
          text: <Trans id={'edit_bid_modal.success_notification'}>Changes saved!</Trans>,
        });
      })
      .finally(() => {
        if (isMountedRef.current) {
          setIsProcessingSubmit(false);
        }
      });
  };

  return {
    formId: FORM_ID,
    formInitialValues,
    isSubmitDisabled: isProcessingSubmit,
    prices,
    updatePrices,
  };
};
