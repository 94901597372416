import { useSelector } from 'react-redux';
import { useContext, useMemo, useState } from 'react';

import v from '@components/form/validators';
import { configSelectors } from '@config/core';
import { OnFormAction } from '@components/form';
import { countriesSelectors } from '@modules/countries';
import api from '../../api';
import { Context } from '../../context';

export const formId = 'contactInfoFormId';

export enum Fields {
  firstName = 'firstName',
  lastName = 'lastName',
  birthday = 'birthday',
  phone = 'phone',
  nationality = 'nationality',
}

interface Form {
  [Fields.firstName]: string;
  [Fields.lastName]: string;
  [Fields.birthday]: Date;
  [Fields.phone]: string;
  [Fields.nationality]: number;
}

export const useModel = () => {
  const { dispatch } = useContext(Context);
  const user = useSelector(configSelectors.userInfo);
  const isUserInfoLoading = useSelector(configSelectors.isUserInfoLoading);
  const isNationalitiesLoading = useSelector(countriesSelectors.isLoading);
  const [isSendFormLoading, setSendFormLoading] = useState<boolean>();

  const formInitial = useMemo(
    () => ({
      firstName: user?.firstName,
      lastName: user?.lastName,
      birthday: user?.birthday,
      phone: user?.phone,
      nationality: user?.nationality?.id,
    }),
    [user]
  );

  const onFormAction: OnFormAction<Form> = {
    error: () => setSendFormLoading(false),
    fieldValidation: {
      [Fields.birthday]: [v.date],
    },
    submit: async (form: Form) => {
      setSendFormLoading(true);
      await api.sendContactInfoForm(form, Fields);
      setSendFormLoading(false);
      dispatch({ type: 'ON_STEP_COMPLETED' });
    },
  };

  return {
    formInitial,
    lockUI: isSendFormLoading || isUserInfoLoading || isNationalitiesLoading,
    onFormAction,
  };
};
