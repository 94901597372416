import { all, fork } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import { usersPageReducer } from './page/store/reducer';
import { usersPageEffects } from './page/store/effects';
import { addSMTAReducer } from './add-smt-account-modal/store/reducer';
import { addSMTAEffects } from './add-smt-account-modal/store/effects';

export const usersReducer = combineReducers({
  addSMTAModal: addSMTAReducer,
  usersPage: usersPageReducer,
});

export function* usersEffects() {
  yield all([fork(addSMTAEffects), fork(usersPageEffects)]);
}
