import React from 'react';
import { Trans } from '@lingui/macro';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { formService, handleValidationErrors, OnFormAction } from '@components/form';
import { DispatchThunkFn } from '@models/redux';
import { handleBackendError, notifySuccess } from '@modules/notify';
import { closeModalAction } from '@modules/modal';
import { formatMoney } from 'containers/services/commonService';
import api from '../api';
import { SendStableToInvestorModal } from '../modal';
import { Form, formId, sliceId } from './model';

const getStableBalances = createAsyncThunk(sliceId('getStableBalances'), async () => {
  try {
    return await api.getPrimaryStableBalance();
  } catch (error) {
    handleBackendError(error);
    throw error;
  }
});

const onAssetChanged = createAsyncThunk(sliceId('onAssetChanged'), async () => {
  const form = formService.get(formId);
  form.setFieldsValue({ amount: undefined });
});

const onCloseModalClicked = createAsyncThunk(
  sliceId('onCloseModalClicked'),
  async (p: void, { dispatch }) => {
    await dispatch(closeModalAction(SendStableToInvestorModal));
  }
);

const onSendStableForm: OnFormAction<Form> = {
  submit: async (form: Form, dispatch: DispatchThunkFn) => {
    await dispatch(sendStableToIssuer(form));
  },
};

const sendStableToIssuer = createAsyncThunk(
  sliceId('sendStableToIssuer'),
  async (payload: Form, { dispatch }) => {
    try {
      await api.sendStableToInvestor({ asset: payload.asset, amount: payload.amount });
      await dispatch(closeModalAction(SendStableToInvestorModal));
      notifySuccess({
        title: (
          <Trans id="stable_to_inv_modal.notification.success_send.title">
            The currency was successfully sent
          </Trans>
        ),
        text: (
          <Trans id="stable_to_inv_modal.notification.success_send.text">
            You send {formatMoney(payload.amount, { pre: `${payload.asset} ` })} to your investor
            account.
          </Trans>
        ),
      });
    } catch (error) {
      handleValidationErrors(error, formId);
      handleBackendError(error);
      throw error;
    }
  }
);

export default {
  getStableBalances,
  sendStableToIssuer,
  onAssetChanged,
  onCloseModalClicked,
  onSendStableForm,
};
