import React, { FC, memo } from 'react';
import { defineMessage, Trans } from '@lingui/macro';
import classnames from 'classnames';

import { PageContent, PageTitle } from '@components/page';
import { Form, InputNumber, Select, SubmitButton } from '@components/form';
import { Divider } from '@components/divider';
import { Box } from '@components/box';
import { PermissionGroup } from '@permissions/core';

import { permissionGroup as apiPermissionGroup } from './api';
import { formId, useModel, Fields } from './model';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup],
  marker: 'layout:marketing-detail',
});

export const MarketingDetail: FC = memo(() => {
  const m = useModel();

  return (
    <PageContent uat="trading-platform-marketing-marketing-detail-page">
      <PageTitle
        title={m.i18n._(
          defineMessage({ id: 'router.marketing_company', message: 'Marketing company' })
        )}
        backwardLink={'/marketing'}
      />

      <Divider className="mb6" />

      <Box className={s.box}>
        <Form name={formId} initialValues={m.initialValues} className={classnames(s.form, 'mt3')}>
          <InputNumber
            name={Fields.numberOfUsers}
            label={<Trans id="marketing_page.number_of_users">Number of users</Trans>}
            required={true}
            disabled={m.lockUI}
            maxDecimals={0}
          />

          <InputNumber
            name={Fields.amountOfVirtualMoneyPerClient}
            label={
              <Trans id="marketing_page.amount_of_virtual_money">
                The amount of virtual money per 1 client, USD
              </Trans>
            }
            required={true}
            disabled={m.lockUI}
            maxDecimals={0}
          />

          <Select
            name={Fields.targetUserRole}
            label={<Trans id="marketing_page.tm2_role">TM2 Role</Trans>}
            disabled={m.lockUI}
            required={true}
            data={m.roles}
          />

          <Select
            name={Fields.systemEvent}
            label={<Trans id="marketing_page.system_event">System event</Trans>}
            disabled={m.lockUI}
            required={true}
            data={m.systemEvents}
          />
        </Form>
      </Box>

      <div className={classnames(s.numberOfAccount, 'pb2', 'mb4')}>
        <span>
          <Trans id="marketing_page.number_of_account">Number of account left</Trans>
        </span>
        <span>{m.numberOfUsersLeft}</span>
      </div>

      <SubmitButton
        name={formId}
        fullWidth={false}
        disabled={m.lockUI}
        onAction={m.onFormAction}
        className={s.saveButton}
      >
        <Trans id="marketing_page.button.save">Save</Trans>
      </SubmitButton>
    </PageContent>
  );
});
