import React, { useState } from 'react';
import { useCallbackOne } from 'use-memo-one';
import { Trans, defineMessage } from '@lingui/macro';
import classnames from 'classnames';
import {
  Table,
  ColumnType,
  KeyExtractor,
  OnLoadMore,
  RowConfig,
} from '@components/table2/autosized';
import { TablePane } from '@components/table/utils';
import { PageContent, PageTitle } from '@components/page';
import { useI18n } from 'containers/services/commonService';
import api, { ParticipantItem, permissionGroup as apiPermissionGroup } from './api';
import s from './index.module.scss';

export const permissionGroup = apiPermissionGroup.createSuperGroup({
  marker: 'layout:participants-page',
});

type DataList = {
  data: ParticipantItem[];
  hasMore: boolean;
};

type LoadIndex = {
  nextPage: number;
};

const columns: ColumnType<ParticipantItem>[] = [
  {
    dataIndex: ['attributes', 'code'],
    headCell: () => (
      <Trans id="participants.table.codes">Free Secondary market Participants codes</Trans>
    ),
    rowCell: ({ data }) => <>{data.attributes.code}</>,
    width: 300,
  },
];

const rowConfig: RowConfig<ParticipantItem> = {
  height: 41,
};

const INITIAL_PAGE = 0;

export const ParticipantsPage = () => {
  const { i18n } = useI18n();
  const [dataList, setDataList] = useState<DataList>({
    data: [],
    hasMore: true,
  });
  const keyExtractor = useCallbackOne<KeyExtractor<ParticipantItem>>((item) => {
    return item.id;
  }, []);

  const loadMoreItems = useCallbackOne<OnLoadMore<LoadIndex>>(({ lastIndexData }) => {
    const page = lastIndexData?.nextPage ?? INITIAL_PAGE;

    return api.getParticipants(page).then(({ data: incomingData, hasMore }) => {
      setDataList((currentData) => {
        return {
          ...currentData,
          data: currentData.data.concat(incomingData),
          hasMore: hasMore,
        };
      });
      return { indexData: { nextPage: page + 1 } };
    });
  }, []);

  return (
    <PageContent
      className={classnames(s.container, s.verticalFiller)}
      uat="statements-participants-page"
    >
      <PageTitle
        title={i18n._(
          defineMessage({
            id: 'router.participants',
            message: 'Free Secondary Market participants',
          })
        )}
        backwardLink={'/statements'}
      />
      <TablePane className={s.verticalFiller}>
        <Table
          className={s.verticalFiller}
          columns={columns}
          data={dataList.data}
          hasMore={dataList.hasMore}
          keyExtractor={keyExtractor}
          onLoadMore={loadMoreItems}
          rowConfig={rowConfig}
        />
      </TablePane>
    </PageContent>
  );
};
