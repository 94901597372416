import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { I18n } from '@lingui/core';

import { configSelectors } from '@config/core';
import { defineMessage } from '@lingui/macro';
import { DispatchFn } from '@models/redux';
import { PageContent, PageTitle } from '@components/page';
import { beneficialOwnersUserActions as beneficialUserActions } from './store/user.acions';
import { BeneficialOwnersTable } from './table';

const mapStateToProps = (root) => ({
  i18n: configSelectors.i18n(root),
});

const mapDispatchToProps = (dispatch) => ({
  onPageLeft: () => dispatch(beneficialUserActions.onPageLeft()),
  onPageOpened: () => dispatch(beneficialUserActions.onPageOpened()),
});

const ConnectedBeneficialOwnersPage: FC<{
  i18n: I18n;
  onPageLeft: DispatchFn<void>;
  onPageOpened: DispatchFn<void>;
}> = ({ i18n, onPageLeft, onPageOpened }) => {
  useEffect(() => {
    onPageOpened();
    return () => onPageLeft();
  }, []); // eslint-disable-line

  return (
    <PageContent uat="beneficial-owners-page">
      <PageTitle
        title={i18n._(
          defineMessage({ id: 'router.beneficial_owners', message: 'Beneficial owners' })
        )}
      />

      <BeneficialOwnersTable />
    </PageContent>
  );
};

export const BeneficialOwnersPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBeneficialOwnersPage);
