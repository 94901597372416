import { ReduxAction } from '@models/redux';
import { VaultInfo } from '../../vaults-api.service';
import {
  EventVaultUpdatedPayload,
  VaultsLoadedPayload,
  VaultsCleanedPayload,
  VaultsPageActionsTypes as T,
} from './model';

class DeleteVaultAction extends ReduxAction<T.DELETE_VAULT, void> {
  public readonly type: T.DELETE_VAULT = T.DELETE_VAULT;
  public constructor(public payload: void) {
    super(payload);
  }
}

class DeleteVaultFailAction extends ReduxAction<T.DELETE_VAULT_FAIL, Error> {
  public readonly type: T.DELETE_VAULT_FAIL = T.DELETE_VAULT_FAIL;
  public constructor(public payload: Error) {
    super(payload);
  }
}

class DeleteVaultSuccessAction extends ReduxAction<T.DELETE_VAULT_SUCCESS, VaultInfo> {
  public readonly type: T.DELETE_VAULT_SUCCESS = T.DELETE_VAULT_SUCCESS;
  public constructor(public payload: VaultInfo) {
    super(payload);
  }
}

class EventVaultUpdatedAction extends ReduxAction<T.EVENT_VAULT_UPDATED, EventVaultUpdatedPayload> {
  public readonly type: T.EVENT_VAULT_UPDATED = T.EVENT_VAULT_UPDATED;
  public constructor(public payload: EventVaultUpdatedPayload) {
    super(payload);
  }
}

class VaultsLoadedAction extends ReduxAction<T.VAULTS_LOADED, VaultsLoadedPayload> {
  public readonly type: T.VAULTS_LOADED = T.VAULTS_LOADED;
  public constructor(public payload: VaultsLoadedPayload) {
    super(payload);
  }
}

class VaultsCleanedAction extends ReduxAction<T.VAULTS_CLEANED, VaultsCleanedPayload> {
  public readonly type: T.VAULTS_CLEANED = T.VAULTS_CLEANED;
  public constructor(public payload: VaultsCleanedPayload) {
    super(payload);
  }
}

export const vaultsPageServiceActions = {
  deleteVault: () => new DeleteVaultAction().get(),
  deleteVaultFail: (error: Error) => new DeleteVaultFailAction(error).get(),
  deleteVaultSuccess: (info: VaultInfo) => new DeleteVaultSuccessAction(info).get(),
  eventVaultUpdated: (e: EventVaultUpdatedPayload) => new EventVaultUpdatedAction(e).get(),
  vaultsLoaded: (data: VaultsLoadedPayload) => new VaultsLoadedAction(data).get(),
  vaultsCleaned: (data: VaultsCleanedPayload) => new VaultsCleanedAction(data).get(),
};

export type VaultsPageServiceActionsTypes =
  | DeleteVaultAction
  | DeleteVaultFailAction
  | DeleteVaultSuccessAction
  | EventVaultUpdatedAction
  | VaultsLoadedAction
  | VaultsCleanedAction;

export declare namespace VaultsPageServiceActions {
  type DeleteVault = DeleteVaultAction;
  type DeleteVaultFail = DeleteVaultFailAction;
  type DeleteVaultSuccess = DeleteVaultSuccessAction;
  type EventVaultUpdated = EventVaultUpdatedAction;
  type VaultsLoaded = VaultsLoadedAction;
  type VaultsCleaned = VaultsCleanedAction;
}
