import { ContextCreator, ExtractAction } from '@helper/ts';
import { Filter, HistorySortOrder } from './types';

type State = {
  filters: Filter;
  ordering: HistorySortOrder;
};

const initialState: State = {
  filters: undefined,
  ordering: [],
};

export type Action = ExtractAction<{
  ON_FILTER_CHANGED: Filter;
  ON_ORDER_CHANGE: HistorySortOrder;
}>;

export const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'ON_FILTER_CHANGED': {
      return {
        ...state,
        filters: action.payload,
      };
    }

    case 'ON_ORDER_CHANGE': {
      return {
        ...state,
        ordering: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const { Context, Provider } = ContextCreator(initialState, reducer);
