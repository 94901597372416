import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { OnboardingStep } from '@config/core';
import { UserItem } from '../../../users-api.service';

export const OnBoardingStatusCell: FC<{ user: UserItem }> = ({ user }) => {
  if (user.kyc.state === 'DISAPPROVE') {
    return <Trans id="users.table.on_boarding_status.kyc.disapprove">OB Disapproved</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.eWalletKey) {
    return <Trans id="users.table.on_boarding_status.wallet">OB Step 1 (wallet)</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.hubspotForm) {
    return <Trans id="users.table.on_boarding_status.form">OB Step 2 (form)</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.noMembership) {
    return <Trans id="users.table.on_boarding_status.membership">OB Step 3 (Membership)</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.sumSubForm) {
    return <Trans id="users.table.on_boarding_status.other">OB Step 4 (KYB/KYC)</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.bankDetails) {
    return <Trans id="users.table.on_boarding_status.bank_details">OB Step 5 (bank details)</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.funds) {
    return <Trans id="users.table.on_boarding_status.funds">OB Step 6 (add funds)</Trans>;
  }

  if (user.accountOpeningStep === OnboardingStep.finished) {
    return <Trans id="users.table.on_boarding_status.kyc.approve">OB Completed</Trans>;
  }

  return <Trans id="users.table.on_boarding_status.unknown">Unknown</Trans>;
};
