import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useCallbackOne } from 'use-memo-one';
import { useParams } from 'react-router';
import { defineMessage, Trans } from '@lingui/macro';
import { useI18n } from '@hooks/i18n';
import { PermissionGroup } from '@permissions/core';
import { showNotifyModalAction } from '@modules/modal';
import { CreateOrEdit, permissionGroup as formPermissionGroup } from '../../create-or-edit';
import { PageTemplate } from '../template';
import api, { permissionGroup as apiPermissionGroup } from './api';

import type { EditFormData } from '../../create-or-edit';

export type { Coin, EditCoinData } from './types';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, formPermissionGroup],
  marker: 'layout:edit-coin-page',
});

type EditCoinPageParams = {
  coinId: string;
};

export const EditCoinPage = () => {
  const dispatch = useDispatch();
  const { i18n } = useI18n();
  const routeParams = useParams<EditCoinPageParams>();
  const [asset, setAsset] = useState('');

  const documentTitle = i18n._(
    defineMessage({ id: 'router_title.edit_coin', message: 'Edit coin' })
  );

  const coinId = routeParams?.coinId;
  if (!coinId) {
    // TODO: handle error
    throw new Error('EditCoin: Cannot read coin id');
  }
  const normalizedCoinId = Number(coinId);

  const titleElement = (
    <span>
      <Trans id="digital_metals_add.header_edit_ver2">Edit metal</Trans>
      <span>&nbsp;&nbsp;&nbsp;</span>
      <span>{asset}</span>
    </span>
  );

  const initData = useCallbackOne(() => {
    return api.getCoin(normalizedCoinId).then((data) => {
      setAsset(data.asset);
      return data;
    });
  }, [coinId]);

  const onSubmit = useCallbackOne((data: EditFormData) => {
    return api.updateCoin(coinId, data);
  }, []);

  const onInitialFetchError = useCallbackOne(() => {
    dispatch(
      showNotifyModalAction({
        type: 'fail',
        title: (
          <Trans id={'digital_metals.edit.initial_fetch_error_modal.title'}>
            Failed to fetch data
          </Trans>
        ),
        description: (
          <Trans id={'digital_metals.edit.initial_fetch_error_modal.description'}>
            Some of the data wasn't fetched. Please try to reload the page.
          </Trans>
        ),
      })
    );
  }, []);

  const contentElement = (
    <CreateOrEdit
      mode={'edit'}
      onSubmit={onSubmit}
      initData={initData}
      onInitialFetchTimeoutError={onInitialFetchError}
    />
  );

  return (
    <PageTemplate
      documentTitle={documentTitle}
      titleElement={titleElement}
      contentElement={contentElement}
      uat="digital-metals-edit-coin-page"
    />
  );
};
