import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';

import { DispatchFn } from '@models/redux';
import { Role as R } from '@permissions/core';
import { MoreActions } from '@components/more-actions';
import { VaultInfo } from '../../../vaults-api.service';
import { vaultsPageSelectors } from '../../store/selectors';
import { vaultsPageUserActions } from '../../store/user.acions';

const mapStateToProps = (root) => ({
  lockUI: vaultsPageSelectors.lockUI(root),
});

const mapDispatchToProps = (dispatch) => ({
  onConfirmBalanceClicked: (vault) =>
    dispatch(vaultsPageUserActions.onConfirmVaultBalanceClicked(vault)),
  onDeleteVaultClicked: (vault) => dispatch(vaultsPageUserActions.onDeleteVaultClicked(vault)),
  onEditVaultClicked: (vault) => dispatch(vaultsPageUserActions.onEditVaultClicked(vault)),
});

const ConnectedVaultActionsCell: FC<{
  lockUI: boolean;
  onConfirmBalanceClicked: DispatchFn<VaultInfo>;
  onDeleteVaultClicked: DispatchFn<VaultInfo>;
  onEditVaultClicked: DispatchFn<VaultInfo>;
  vault: VaultInfo;
}> = ({ lockUI, onConfirmBalanceClicked, onDeleteVaultClicked, onEditVaultClicked, vault }) => {
  return (
    <MoreActions
      placement="right"
      disabled={lockUI}
      list={[
        {
          access: { roles: [R.vaultManager] },
          action: () => onConfirmBalanceClicked(vault),
          content: <Trans id="vaults.action.confirm_balance">Confirm balance</Trans>,
        },
        {
          access: { roles: [R.admin] },
          action: () => onEditVaultClicked(vault),
          content: <Trans id="vaults.action.edit">Edit</Trans>,
        },
        {
          access: { roles: [R.admin] },
          action: () => onDeleteVaultClicked(vault),
          content: <Trans id="vaults.action.remove">Remove</Trans>,
        },
      ]}
    />
  );
};

export const VaultActionsCell = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedVaultActionsCell);
