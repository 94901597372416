import { createContext, ReactNode, useMemo, useReducer } from 'react';
import { ArrayItem, ExtractAction } from '@helper/ts';
import { UploadedFile } from '@models/core';
import { ValidationError } from '@services/stomp/errors';
import { Coin } from '../../types';

export type InfoFile = ArrayItem<Coin['infoFiles']>;

export type ProductInfoContextState = {
  infoFiles: InfoFile[];
  primaryMarketProductInfo: string;
  productInfo: string;
  validationError: ValidationError | null;
  formInitiated: boolean;
};

type ActionMap = {
  FILE_REMOVED: {
    fileId: number;
  };
  FILE_TITLE_CHANGED: {
    fileId: number;
    title: string;
  };
  FORM_INITIATED: {
    primaryMarketProductInfo: string;
    productInfo: string;
    infoFiles: InfoFile[];
  };
  FILE_UPLOADED: UploadedFile;
  FORM_SUBMITTED: never;
  PRIMARY_MARKET_PRODUCT_INFO_CHANGED: string;
  PRODUCT_INFO_CHANGED: string;
  VALIDATION_FAILED: ValidationError;
};

type Action = ExtractAction<ActionMap>;

const initialState: ProductInfoContextState = {
  infoFiles: [],
  primaryMarketProductInfo: '',
  productInfo: '',
  validationError: null,
  formInitiated: false,
};

export type ProductInfoContextValue = {
  state: ProductInfoContextState;
  dispatch: (action: Action) => void;
};

export const ProductInfoContext = createContext<ProductInfoContextValue>({
  state: initialState,
  dispatch: () => {},
});

function productInfoReducer(
  state: ProductInfoContextState,
  action: Action
): ProductInfoContextState {
  switch (action.type) {
    case 'FILE_REMOVED': {
      const removedFileId = action.payload.fileId;
      const updatedFiles = state.infoFiles.filter((f) => f.file.id !== removedFileId);

      return { ...state, infoFiles: updatedFiles, validationError: null };
    }

    case 'FILE_TITLE_CHANGED': {
      const updatedFiles = state.infoFiles.map((f) => {
        return f.file.id === action.payload.fileId ? { ...f, title: action.payload.title } : f;
      });
      return { ...state, infoFiles: updatedFiles };
    }

    case 'FILE_UPLOADED': {
      return {
        ...state,
        infoFiles: [...state.infoFiles, { title: '', file: action.payload }],
        validationError: null,
      };
    }

    case 'FORM_INITIATED': {
      const { primaryMarketProductInfo, productInfo, infoFiles } = action.payload;

      return {
        ...state,
        primaryMarketProductInfo,
        productInfo,
        infoFiles,
        formInitiated: true,
      };
    }

    case 'FORM_SUBMITTED': {
      return {
        ...state,
        validationError: null,
      };
    }

    case 'PRIMARY_MARKET_PRODUCT_INFO_CHANGED': {
      return {
        ...state,
        primaryMarketProductInfo: action.payload,
      };
    }

    case 'PRODUCT_INFO_CHANGED': {
      return {
        ...state,
        productInfo: action.payload,
      };
    }

    case 'VALIDATION_FAILED': {
      return {
        ...state,
        validationError: action.payload,
      };
    }
  }
}

export type ProductInfoProps = {
  children: ReactNode;
};

export function ProductInfoProvider({ children }: ProductInfoProps) {
  const [state, dispatch] = useReducer(productInfoReducer, initialState);

  const contextValue = useMemo<ProductInfoContextValue>(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  return <ProductInfoContext.Provider value={contextValue}>{children}</ProductInfoContext.Provider>;
}
