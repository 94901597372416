import React, { FC, memo } from 'react';
import { defineMessage } from '@lingui/macro';
import { Table } from '@components/table2/simplified';
import { TablePane } from '@components/table2/utils';
import { PageContent, PageTitle } from '@components/page';
import { PermissionGroup } from '@permissions/permission-group';
import { permissionGroup as apiPermissionGroup } from './api';
import { ActionsRow, permissionGroup as actionsRowPermissionGroup } from './actions-row';
import { useColumns } from './columns';
import { rowConfig, useModel } from './model';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, actionsRowPermissionGroup],
  marker: 'layout:bank-account-balances-page',
});

export const BankAccountBalancesPage: FC = memo(() => {
  const m = useModel();
  const c = useColumns();

  return (
    <PageContent className={s.verticalFiller} uat="statements-bank-account-balances-page">
      <PageTitle
        title={m.i18n._(
          defineMessage({ id: 'router.banks', message: 'Bank account actual balances' })
        )}
        backwardLink={'/statements'}
      />

      <ActionsRow ordering={m.ordering} />

      <TablePane className={s.verticalFiller}>
        <Table
          columns={c.columns}
          instance={m.tableInstance}
          rowConfig={rowConfig}
          className={s.verticalFiller}
        />
      </TablePane>
    </PageContent>
  );
});
