import React, { FC } from 'react';
import classnames from 'classnames';

import s from './index.module.scss';
import { ReactComponent as Arrow } from '@images/icons/arrow-down-icon.svg';

export const ButtonToggle: FC<{
  className?: string;
  disabled?: boolean;
  expanded: boolean;
  onClick?: () => void;
  uat?: string;
}> = (p) => {
  return (
    // we can't use button here due to semantic with language selector
    <div
      className={classnames(
        s.button,
        p.expanded && s.expanded,
        p.disabled && 'is-disabled',
        p.className
      )}
      onClick={p.onClick}
      data-uat={p.uat}
    >
      <Arrow className={s.svg} />
    </div>
  );
};
