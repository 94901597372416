import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import { ButtonUpload } from '@components/button/upload';
import { PermissionGroup } from '@permissions/permission-group';
import { Tooltip, TooltipTrigger } from '@components/tooltip';
import { ButtonAction } from '@components/button';
import { HasAccess } from '@permissions/core';
import { permissionGroup as apiPermissionGroup } from './api';
import { useModel } from './model';

export const permissionGroup = new PermissionGroup({
  operator: 'OR',
  groups: [apiPermissionGroup],
  marker: 'layout:payments-report-upload-button',
});

const UploadButtonComponent: FC = () => {
  const { lockUI, onUploadClicked } = useModel();

  return (
    <Tooltip
      placement="bottom"
      trigger={TooltipTrigger.hover}
      title={<Trans id="payments_report.button.upload">Upload payments report xls</Trans>}
    >
      <ButtonUpload
        action={ButtonAction.file}
        disabled={lockUI}
        onClick={onUploadClicked}
        compact="all"
      />
    </Tooltip>
  );
};

export const UploadButton: FC = () => (
  <HasAccess permissions={{ or: [permissionGroup] }}>
    <UploadButtonComponent />
  </HasAccess>
);
