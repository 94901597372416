export enum OfferStatus {
  active = 'ACTIVE',
  activeError = 'ACTIVE_ERROR',
  activePending = 'ACTIVE_PENDING',
  closed = 'CLOSED',
  closeError = 'CLOSE_ERROR',
  closePending = 'CLOSE_PENDING',
  completed = 'COMPLETED',
}

export enum BidOrderStatus {
  created = 'CREATED',
  creationPending = 'CREATION_PENDING',
  creationError = 'CREATION_ERROR',
  approvePending = 'APPROVE_PENDING',
  approved = 'APPROVED',
  approvingError = 'APPROVING_ERROR',
  denyPending = 'DENY_PENDING',
  denied = 'DENIED',
  denyingError = 'DENYING_ERROR',
}

export type DataList<T> = {
  hasMore: boolean;
  key: string;
  list: T[];
};

export type TableData<T> = {
  data: T[];
  hasMore: boolean;
};

type GenericBidOrder = {
  id: number;
  asset: string;
  comment: string;
  creatorId: number;
  creatorName: string;
  date: string;
  priceCurrency: string;
  pricePerGram: number;
  priceUnitInGrams: number;
  priceUnitLabel: string;
  quantity: number;
  status: BidOrderStatus;
};

export type BidOrderByAll = GenericBidOrder & {
  buyerId: number;
  buyerName: string;
};
export type BidOrderByAllList = DataList<BidOrderByAll>;

export type BidOrderByCreator = GenericBidOrder;
export type BidOrderByCreatorList = DataList<BidOrderByCreator>;

type GenericOffer = {
  id: number;
  activePrices: Array<{
    id: number;
    quantity: number;
    currencyId: number;
    currencyCode: string;
    unitLabel: string;
  }>;
  asset: string;
  availableQuantity: number;
  creatorId: number;
  creatorName: string;
  date: string;
  metal: string;
  quantityUnitLabel: string;
  sellCommission: number;
  status: OfferStatus;
};

export type OfferByAll = GenericOffer;
export type OfferByAllList = DataList<OfferByAll>;

export type OfferByMe = GenericOffer;
export type OfferByMeList = DataList<OfferByMe>;
