import React, { FC } from 'react';
import { Trans } from '@lingui/macro';

import s from './index.module.scss';

export const StepDetailsTitle: FC<{ currentStep: number }> = ({ currentStep }) => {
  return (
    <div className={s.description}>
      <Trans id="profile_page.onboarding.step.count_description">
        To complete
        <span className={s.step}> Step {currentStep} </span>
        you need:
      </Trans>
    </div>
  );
};
