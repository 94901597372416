import sign from 'tm2sign.macro';
import { makeQuery, PermissionGroup } from '@permissions/permission-group';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';

export enum Fields {
  listingBrokerId = 'listingBrokerId',
  date = 'date',
  currencyId = 'currencyId',
}

export interface Form {
  [Fields.listingBrokerId]: number;
  [Fields.date]: string;
  [Fields.currencyId]: number;
}

const buildStatementQuery = 'buildStatement';
const buildStatementFields = ['value'];
const buildStatement = makeQuery({
  queryName: buildStatementQuery,
  queryFields: buildStatementFields,
  query: (form: Form, isListingBroker: boolean): Promise<string> => {
    const { currencyId, date, listingBrokerId } = form;

    let typedValues = {
      ...v.long({ currencyId }),
      ...v.localDate({ startDate: date[0] }),
      ...v.localDate({ endDate: date[1] }),
    };

    if (!isListingBroker) {
      typedValues = {
        ...typedValues,
        ...v.long({ listingBrokerId }),
      };
    }

    return stompClient
      .getData(buildStatementQuery, sign(buildStatementQuery, buildStatementFields), typedValues)
      .then((response) => response.value);
  },
});

const api = {
  buildStatement,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:listing-broker-account-statement');

export default api;
