import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { handleBackendError, notifyError, notifySuccess } from '@modules/notify';
import { useCallDispatch } from '@hooks/core';
import { TypedError } from '@services/stomp/errors';
import { actions } from '../../store';
import api from './api';

export const useModel = () => {
  const [lockUI, setLockUI] = useState<boolean>(false);
  const onXlsUploaded = useCallDispatch(actions.onXlsUploaded);

  const onUploadClicked = async (file: File) => {
    try {
      setLockUI(true);
      await api.uploadPaymentsReport(file);
      notifySuccess({
        defaultTitle: true,
        text: (
          <Trans id="payments_report.notification.success_upload.text">
            File uploaded successfully
          </Trans>
        ),
      });
      onXlsUploaded();
    } catch (error) {
      if (error instanceof TypedError) {
        const codes = {
          PAYMENTS_REPORT_INCORRECT_FILE_FORMAT: (
            <Trans id="payments_report.error.incorrect_file_format.text">
              Uploaded report is not a valid Office Open XML file
            </Trans>
          ),
          PAYMENTS_REPORT_INCORRECT_XLS_CONTENT: (
            <Trans id="payments_report.error.incorrect_xls_content.text">
              Uploaded report file has wrong content
            </Trans>
          ),
          UNKNOWN: <Trans id="payments_report.error.unknown.text">Internal service error</Trans>,
        };
        notifyError({
          defaultTitle: true,
          text: codes[error.code] || error.data || codes.UNKNOWN,
        });
      } else {
        handleBackendError(error);
      }
    }
    setLockUI(false);
  };

  return {
    lockUI,
    onUploadClicked,
  };
};
