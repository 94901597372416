import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans } from '@lingui/macro';

import v, { nestedName } from '@components/form/validators';
import { configServiceActions } from '@config/store/service.acions';
import { OnFormAction } from '@components/form';
import { useCallDispatch } from '@hooks/core';
import { showNotifyModalAction } from '@modules/modal';
import api, { VerifyDocsFields as Fields, VerifyDocsForm as Form } from '../api';

export { verifyDocsFormId as formId } from '../api';

const MAX_DOC_SIZE = 20; // megabytes
const DOC_SIZE_UNIT = 'mb';
const ALLOWED_DOC_EXTENSIONS = ['jpeg', 'jpg', 'png', 'pdf'];

export const useModel = (onFinish: () => void, onLock: (lock: boolean) => void) => {
  const dispatch = useDispatch();
  const showNotifyModal = useCallDispatch(showNotifyModalAction);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFormAction: OnFormAction<Form> = {
    error: () => {
      setIsSubmitting(false);
      onLock(false);
    },
    fieldValidation: {
      [nestedName([Fields.identities, Fields.identityFile])]: [
        v.required,
        v.fileSizeMax(MAX_DOC_SIZE, DOC_SIZE_UNIT),
        v.fileExtensions(ALLOWED_DOC_EXTENSIONS),
      ],
      [nestedName([Fields.utilities, Fields.utilityFile])]: [
        v.required,
        v.fileSizeMax(MAX_DOC_SIZE, DOC_SIZE_UNIT),
        v.fileExtensions(ALLOWED_DOC_EXTENSIONS),
      ],
    },
    submit: async (form) => {
      setIsSubmitting(true);
      onLock(true);
      await api.sendVerificationDocs(form);

      dispatch(configServiceActions.checkTutorialEvent(true));
      showNotifyModal({
        title: <Trans id="verification_upload_form.acknowledgement.title">Attention</Trans>,
        description: (
          <Trans id="verification_upload_form.acknowledgement.body">
            The documents are being checked. It may take up to 3 business days.
          </Trans>
        ),
        okText: <Trans id="button.ok">OK</Trans>,
        type: 'fail', // Use fail type to show the icon
      });

      setIsSubmitting(false);
      onLock(false);
      onFinish && onFinish();
    },
  };

  return {
    disabled: isSubmitting,
    onFormAction,
  };
};
