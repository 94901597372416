import sign from 'tm2sign.macro';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { TableData, TableResponse } from '@models/core';
import { privateApi } from 'utils/api';

class BeneficialApiService {
  public getBeneficialOwners(
    page: number,
    selectedAsset: string
  ): Promise<TableData<BeneficialOwner>> {
    const beneficialOwnersFields = sign('beneficialOwners', [
      'hasMore',
      'index',
      {
        data: [
          {
            user: [
              'address',
              { country: ['label'] },
              'displayName',
              'email',
              'id',
              'permissions',
              'phone',
            ],
          },
          { assets: ['asset', 'blocked', 'balance'] },
        ],
      },
    ]);

    let typedValues = {
      ...v.long({ page: page }),
    };
    if (selectedAsset) {
      typedValues = {
        ...typedValues,
        ...v.string({ asset: selectedAsset }),
      };
    }

    return stompClient
      .getData('beneficialOwners', beneficialOwnersFields, typedValues)
      .then((response: BeneficialOwnersResponse) => ({
        data: response.data.map((data) => ({
          ...data.user,
          metals: data.assets,
        })),
        index: response.index,
        isHasMore: response.hasMore,
      }));
  }

  public getDigitalMetals(): Promise<Array<string>> {
    return privateApi.get('/users/assets').then((response) => response.data);
  }

  public updateOwnerBlockedMetals(ownerId: number, metals: Array<string>): Promise<void> {
    return privateApi.post(`/users/${ownerId}/block-assets`, metals);
  }
}

export const beneficialApiService = new BeneficialApiService();

type BeneficialOwnersResponse = TableResponse<{
  assets: Array<BeneficialMetal>;
  user: BeneficialOwner;
}>;

export interface BeneficialMetal {
  asset: string;
  blocked: boolean;
  balance: number;
}

export interface BeneficialOwner {
  address: string;
  country: {
    label: string;
  };
  email: string;
  id: number;
  displayName: string;
  metals: Array<BeneficialMetal>; // user on backend doesn't have this field
  permissions: string;
  phone: string;
}
