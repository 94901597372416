import { TransferDirection } from '../../model';
import {
  TransferStableTabActions,
  TransferStableTabActionsTypes as StableTabTypes,
  TransferStableTabState,
  transferStableTabStateInitial,
} from './model';

export const transferStableTabReducer = (
  combinedState,
  action: TransferStableTabActions
): TransferStableTabState => {
  const state: TransferStableTabState = combinedState.stableTab;
  const newState = (localState: TransferStableTabState) => ({
    ...combinedState,
    stableTab: { ...localState },
  });

  switch (action.type) {
    case StableTabTypes.FINISH_STABLE_TRANSFER: {
      return newState({
        ...state,
        isFinishStableTransferLoading: true,
      });
    }

    case StableTabTypes.FINISH_STABLE_TRANSFER_FAIL: {
      return newState({
        ...state,
        isFinishStableTransferLoading: false,
      });
    }

    case StableTabTypes.FINISH_STABLE_TRANSFER_SUCCESS: {
      return newState({
        ...state,
        isFinishStableTransferLoading: false,
      });
    }

    case StableTabTypes.GET_STABLE_INFO: {
      return newState({
        ...state,
        isStableInfoLoading: true,
      });
    }

    case StableTabTypes.GET_STABLE_INFO_FAIL: {
      return newState({
        ...state,
        isStableInfoLoading: false,
        isTabInitiated: true,
      });
    }

    case StableTabTypes.GET_STABLE_INFO_SUCCESS: {
      return newState({
        ...state,
        isStableInfoLoading: false,
        isTabInitiated: true,
      });
    }

    case StableTabTypes.ON_SELECTED_VALUE_CHANGED: {
      return newState({
        ...state,
        selectedAsset: action.payload,
      });
    }

    case StableTabTypes.ON_TAB_LEFT: {
      return newState({
        ...transferStableTabStateInitial,
      });
    }

    case StableTabTypes.ON_TAB_OPENED: {
      return newState({
        ...state,
        selectedAsset: '',
      });
    }

    case StableTabTypes.ON_TOGGLE_STABLE_TRANSFER_DIRECTION_CLICKED: {
      const oldDirection = state.transferDirection;
      const newDirection =
        oldDirection === TransferDirection.fromPrimary
          ? TransferDirection.fromSecondary
          : TransferDirection.fromPrimary;

      return newState({
        ...state,
        transferDirection: newDirection,
      });
    }

    case StableTabTypes.SET_STABLE_INFO: {
      const { primaryBalances = [], secondaryBalances = [] } = action.payload;

      return newState({
        ...state,
        primaryStableBalances: primaryBalances,
        secondaryStableBalances: secondaryBalances,
      });
    }

    case StableTabTypes.UPDATE_FORM_VALUES: {
      return newState({
        ...state,
        isTransferStableInfoLoading: true,
      });
    }

    case StableTabTypes.UPDATE_FORM_VALUES_FAIL: {
      return newState({
        ...state,
        isTransferStableInfoLoading: false,
      });
    }

    case StableTabTypes.UPDATE_FORM_VALUES_SUCCESS: {
      return newState({
        ...state,
        isTransferStableInfoLoading: false,
      });
    }

    default: {
      return combinedState;
    }
  }
};
