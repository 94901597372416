import React from 'react';
import { all, takeEvery } from 'redux-saga/effects';
import { Trans } from '@lingui/macro';

import { notifySuccess } from '@modules/notify';
import { Put } from '@helper/redux';
import { closeModalAction } from '@modules/modal';
import { DispatchThunkFn, GetStateFn, RootState } from '@models/redux';
import { OnFormAction } from '@components/form';
import { UserItem, usersApiService as usersApi } from '../../users-api.service';
import { usersPageServiceActions } from '../../page/store/service.acions';
import { AddSMTAModal } from '../index';
import { addSMTAServiceActions } from './service.acions';
import { addSMTASelectors } from './selectors';
import { AddSMTAActionsTypes as HandleTypes, AddSMTAForm } from './model';

export function* addSMTAEffects() {
  yield all([takeEvery(HandleTypes.ON_CLOSE_MODAL_CLICKED, Put(closeModalAction, AddSMTAModal))]);
}

export const onAddSMTAFormAction: OnFormAction<AddSMTAForm> = {
  error: (error: Error, dispatch: DispatchThunkFn) => {
    dispatch(addSMTAServiceActions.addSMTAccountFail(error));
  },
  submit: async (form: AddSMTAForm, dispatch: DispatchThunkFn, getState: GetStateFn) => {
    await dispatch(addSMTAServiceActions.addSMTAccount());
    const root: RootState = getState();
    const user: UserItem = addSMTASelectors.user(root);

    await usersApi.addSMTAccount(user.id, form);

    await dispatch(addSMTAServiceActions.addSMTAccountSuccess());
    await dispatch(
      usersPageServiceActions.eventUsersUpdated({
        isNewUser: false,
        isNoScroll: true,
        user: { ...user },
      })
    );
    await notifySuccess({
      defaultTitle: true,
      text: (
        <Trans id="add_smta_modal.notification.success_add.text">
          A Secondary Market ID was successfully added to user's account
        </Trans>
      ),
    });
    await dispatch(closeModalAction(AddSMTAModal));
  },
};
