import React, { FC, memo } from 'react';

import { Form } from '@components/form';
import { Provider } from './context';
import { formId, useModel, SearchProps } from './model';

export type { SearchItem } from './context';
export { default as useSearch } from './hooks';
export { default as SearchBox } from './components/box';
export { default as SearchClear } from './components/clear';
export { default as SearchDateRange } from './components/date-range';
export { default as SearchSelect } from './components/select';
export { default as SearchText } from './components/text';

const ConnectedSearchForm: FC<SearchProps> = memo<SearchProps>((p) => {
  const m = useModel(p);

  return (
    <Form
      className={p.className}
      name={formId}
      initialValues={m.initialValues}
      onChange={m.onFormChange}
    >
      {p.children}
    </Form>
  );
});

export const SearchForm: FC<SearchProps> = (props) => (
  <Provider>
    <ConnectedSearchForm {...props} />
  </Provider>
);
