import sign from 'tm2sign.macro';
import { makeQuery, Permission, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';
import { v } from '@helper/typer/field-typer.helper';
import { GetBankAccountBalancesSortOrder } from '../api';

const bankAccountBalancesXlsxQuery = 'externalBankAccountBalancesAsXLSX';
const bankAccountBalancesXlsxFields = ['value'];
const bankAccountBalancesXlsx = makeQuery({
  permissions: Permission.EXTERNAL_BANK_ACCOUNT_BALANCES_VIEW_REPORT,
  queryName: bankAccountBalancesXlsxQuery,
  queryFields: bankAccountBalancesXlsxFields,
  query: (ordering: GetBankAccountBalancesSortOrder): Promise<string> => {
    return stompClient
      .getData(
        bankAccountBalancesXlsxQuery,
        sign(bankAccountBalancesXlsxQuery, bankAccountBalancesXlsxFields),
        { ...v.orderInput({ ordering }) }
      )
      .then((response) => response.value);
  },
});

const api = {
  bankAccountBalancesXlsx,
};

export const permissionGroup = PermissionGroup.extract(
  api,
  'api:bank-account-balances-actions-row'
);

export default api;
