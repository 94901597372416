import { AddFundsModalActionsTypes as AddFundsTypes } from '../../store/model';
import {
  creditCardViewStateInitial,
  CreditCardViewActionsTypes as CardTypes,
  CreditCardViewState,
  CreditCardViewActions,
} from './model';

export const creditCardViewReducer = (
  state: CreditCardViewState = creditCardViewStateInitial,
  action: CreditCardViewActions
): CreditCardViewState => {
  switch (action.type) {
    case AddFundsTypes.ON_MODAL_LEFT: {
      return {
        ...creditCardViewStateInitial,
      };
    }

    case AddFundsTypes.ON_MODAL_OPENED: {
      return {
        ...creditCardViewStateInitial,
      };
    }

    case CardTypes.PREPARE_CREDIT_CARD: {
      return {
        ...state,
        isPrepareCreditCardLoading: true,
      };
    }

    case CardTypes.PREPARE_CREDIT_CARD_FAIL: {
      return {
        ...state,
        isPrepareCreditCardLoading: false,
      };
    }

    case CardTypes.PREPARE_CREDIT_CARD_SUCCESS: {
      // we don't change loading flag here due to redirect to payrexx app
      return state;
    }

    case CardTypes.SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
