import sign from 'tm2sign.macro';

import { makeQuery, PermissionGroup } from '@permissions/core';
import { stompClientService as stompClient } from '@services/stomp/client';

export enum Fields {
  coinId = 'coinId',
  status = 'status',
}

export interface RedeemCoin {
  id: number;
  asset: string;
  metal: { label: string };
}
const getRedeemCoinsQuery = 'redeemCoins';
const getRedeemCoinsFields = ['id', 'asset', { metal: ['label'] }];
const getRedeemCoins = makeQuery({
  queryName: getRedeemCoinsQuery,
  queryFields: getRedeemCoinsFields,
  query: (): Promise<Array<RedeemCoin>> => {
    return stompClient.getData(
      getRedeemCoinsQuery,
      sign(getRedeemCoinsQuery, getRedeemCoinsFields)
    );
  },
});

const api = {
  getRedeemCoins,
};

export const permissionGroup = PermissionGroup.extract(api, 'api:redemptions-actions');

export default api;
