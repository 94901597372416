import React, { FC, useEffect, useState } from 'react';
import classnames from 'classnames';

import './styles.scss';
import { roundDown } from '@helper/number';
import { ReactComponent as GrowIcon } from '@images/icons/grow-icon.svg';
import { formatMoney } from '../../../../../../services/commonService';
import { useWeekDailyPrice } from '../../../service';
import { TickerItem } from '../../../metals-api.service';

export const MetalPrice: FC<{
  selectedPair: TickerItem;
  value: number;
}> = ({ selectedPair, value: nowValue }) => {
  const currency = selectedPair.currency.code;
  const [isPriceUp, setPriceUp] = useState(false);
  const [isPriceDown, setPriceDown] = useState(false);
  const [changesPercent, setChangesPercent] = useState(0);
  const [weekPrice = []] = useWeekDailyPrice(
    selectedPair?.coin?.id,
    selectedPair?.currency?.id,
    'DAYS'
  );
  const prevDayValue = weekPrice[0]?.price;

  useEffect(() => {
    if (!prevDayValue || !nowValue || prevDayValue === nowValue) {
      return;
    }
    setPriceUp(nowValue > prevDayValue);
    setPriceDown(nowValue < prevDayValue);
    setChangesPercent(roundDown((Math.abs(nowValue - prevDayValue) / prevDayValue) * 100, 2));
  }, [prevDayValue, nowValue]); // eslint-disable-line

  return (
    <div className="buy-confirm-modal-live-price">
      {formatMoney(nowValue, { pre: currency })}

      {!isPriceDown && !isPriceUp ? null : (
        <div
          className={classnames('buy-confirm-modal-live-price-changes', {
            'price-changes-up': isPriceUp,
            'price-changes-down': isPriceDown,
          })}
        >
          <span>
            {isPriceUp ? '+' : '-'}
            {changesPercent}%
          </span>

          <div className="buy-confirm-modal-live-price-changes-icon">
            <GrowIcon className="buy-confirm-modal-live-price-changes-icon-svg" />
          </div>
        </div>
      )}
    </div>
  );
};
