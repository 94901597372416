import React from 'react';
import { defineMessage } from '@lingui/macro';
import { PageContent, PageTitle } from '@components/page';
import { Table } from '@components/table2/simplified';
import { tableRowCfgDefault } from '@components/table2/config/table-simplified';
import { TablePane } from '@components/table2/utils';
import { PermissionGroup } from '@permissions/permission-group';
import { permissionGroup as actionsPermissionGroup } from './actions-row';
import { permissionGroup as apiPermissionGroup } from './api';
import { ActionsRow } from './actions-row';
import { useModel } from './model';
import { useColumns } from './columns';
import s from './index.module.scss';

export const permissionGroup = new PermissionGroup({
  operator: 'AND',
  groups: [apiPermissionGroup, actionsPermissionGroup],
  marker: 'layout:deposits-page',
});

export const DepositsPage = () => {
  const m = useModel();

  const c = useColumns(m.replaceDeposit);

  return (
    <PageContent className={s.verticalFiller} uat="deposits-page">
      <PageTitle
        title={m.i18n._(
          defineMessage({
            id: 'router.deposits',
            message: 'Deposits',
          })
        )}
      />

      <ActionsRow addDeposit={m.addDeposit} onFiltersChanged={m.onFiltersChanged} />

      <TablePane className={s.verticalFiller}>
        <Table
          columns={c.columns}
          instance={m.tableInstance}
          rowConfig={tableRowCfgDefault}
          className={s.verticalFiller}
        />
      </TablePane>
    </PageContent>
  );
};
